const labels = {
  All: {
    'Ref: stamp progress': 'Edistyminen kohti seuraavaa leimaa',
    Reward: 'Palkinto',
    Rewards: 'Palkkiot',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Leimat',
    'Ref: Fulfill conditions': 'Ansaitse postimerkkejä palkkioiden lunastamiseksi!',
    'See Reward': 'Katso palkkio',
    'Ref: No reward': 'Ei palkkiota hetkestä',
  },
  RedeemableProducts: { 'Ref: Page title': 'Uskollisuuspalkkiot' },
};
export default labels;
