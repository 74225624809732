import { Route, Switch } from 'react-router';

import SupplierDetails from '../components/SupplierDetails/SupplierDetails';
import SuppliersList from '../components/SuppliersList/SuppliersList';
import { pagePaths } from '../config';

const Suppliers = () => {
  return (
    <div>
      <Switch>
        <Route exact path={pagePaths.Module} children={<SuppliersList />} />
        <Route path={pagePaths.SupplierDetails} children={<SupplierDetails />} />
      </Switch>
    </div>
  );
};

export default Suppliers;
