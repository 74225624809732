import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';

import ResetSessionWidget from './ResetSessionWidget';

const configuration = {
  isResetSessionWidgetShownInTitleBar: true,
};

jest.mock('../../../config', () => {
  return {
    orderConfig: () => configuration,
  };
});

describe('ResetSessionWidget component', () => {
  describe('Render with no props', () => {
    afterAll(() => cleanup());
    const labelFunc = jest.fn();
    renderComponent(ResetSessionWidget, { label: labelFunc });

    it('Should display widgets section', () => {
      const component = screen.getByTestId('titlebar-widgets');
      expect(component).toBeTruthy();
    });
    it('Should display clickable part with function', () => {
      const component = screen.getByTestId('titlebar-widgets-on-click');
      expect(component).toBeTruthy();
    });
    it('Should display icon', () => {
      const component = screen.getByTestId('title-bar-icon-Ref: Reset your order');
      expect(component).toBeTruthy();
    });
  });
});
