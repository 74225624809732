import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { act, renderHook } from '@testing-library/react';
import { useSelector } from 'react-redux';

import { appInsights } from '../../../../appInsights';
import { useLazyGetPaymentMinimumAmountInfoQuery } from '../../api';

import { useCanPayOnline } from './useCanPayOnline';

const getPaymentMinimumAmountInfoMock = jest.fn();
jest.mock('../../api', () => ({
  ...jest.requireActual('../../api'),
  useLazyGetPaymentMinimumAmountInfoQuery: jest.fn(),
}));

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: jest.fn(),
}));

const siteId = 'siteId';
const mockUseSite = jest.fn();
jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => mockUseSite(),
}));

const mockUseSetupOption = jest.fn();
jest.mock('@/helpers/hooks/useSetupOption/useSetupOption', () => ({
  useSetupOption: () => mockUseSetupOption(),
}));

jest.mock('../../../../appInsights', () => ({
  appInsights: {
    trackTrace: jest.fn(),
  },
}));

const state = {
  Order: {
    cart: {},
  },
};

describe('useCanPayOnline', () => {
  const env = global.process.env;
  beforeEach(() => {
    (useLazyGetPaymentMinimumAmountInfoQuery as jest.Mock).mockReturnValue([
      getPaymentMinimumAmountInfoMock,
      { data: [] },
    ]);
    (useSelector as jest.Mock).mockImplementation((selector) => selector(state));

    mockUseSite.mockReturnValue({
      id: siteId,
      currency: {
        isoCode: 'EUR',
      },
    });

    mockUseSetupOption.mockReturnValue({
      isActive: true,
      value: 'PlSodexoTEST-RetailEU(stripe)',
      setupOption: undefined,
    });
    global.process.env = { ...env, REACT_APP_USE_CHECK_PAYMENT_MIN_AMOUNT: 'true' };
  });

  describe('checkIfOnlinePaymentPossible', () => {
    it('returns true when feature flag is not enabled', async () => {
      global.process.env = { ...env, REACT_APP_USE_CHECK_PAYMENT_MIN_AMOUNT: 'false' };
      const { result } = renderHook(() => useCanPayOnline());

      const checkResult = await act(() =>
        result.current.checkIfOnlinePaymentPossible({ orderTotal: 5 })
      );

      const trackTraceMock = appInsights!.trackTrace as jest.Mock;
      expect(trackTraceMock).not.toHaveBeenCalled();
      expect(checkResult).toBeTruthy();
      expect(result.current.isMinimumAmountError).toBeFalsy();
      expect(result.current.minimumAmount).toBeUndefined();
      expect(getPaymentMinimumAmountInfoMock).not.toHaveBeenCalled();
    });

    it('returns true and logs error to AI when merchantSetupOption is unknown', async () => {
      mockUseSetupOption.mockReturnValue({ isActive: false, value: '', setupOption: undefined });

      const { result } = renderHook(() => useCanPayOnline());

      const checkResult = await act(() =>
        result.current.checkIfOnlinePaymentPossible({ orderTotal: 5 })
      );

      const trackTraceMock = appInsights!.trackTrace as jest.Mock;
      expect(trackTraceMock).toHaveBeenCalled();

      expect(trackTraceMock.mock.calls[0][0]).toEqual({
        message: 'MerchantSetupOption is unknown',
        severityLevel: SeverityLevel.Error,
      });

      expect(checkResult).toBeTruthy();
      expect(result.current.isMinimumAmountError).toBeFalsy();
      expect(result.current.minimumAmount).toBeUndefined();
      expect(getPaymentMinimumAmountInfoMock).not.toHaveBeenCalled();
    });

    it('returns true and logs error to AI when currency isoCode is unknown', async () => {
      mockUseSite.mockReturnValue({
        id: siteId,
        currency: {
          isoCode: '',
        },
      });

      const { result } = renderHook(() => useCanPayOnline());

      const checkResult = await act(() =>
        result.current.checkIfOnlinePaymentPossible({ orderTotal: 5 })
      );

      const trackTraceMock = appInsights!.trackTrace as jest.Mock;
      expect(trackTraceMock).toHaveBeenCalled();

      expect(trackTraceMock.mock.calls[0][0]).toEqual({
        message: 'Site currency iso code is unknown',
        severityLevel: SeverityLevel.Error,
      });

      expect(checkResult).toBeTruthy();
      expect(result.current.isMinimumAmountError).toBeFalsy();
      expect(result.current.minimumAmount).toBeUndefined();
      expect(getPaymentMinimumAmountInfoMock).not.toHaveBeenCalled();
    });

    it('returns true and logs error to AI when merchantSetupOption is invalid', async () => {
      mockUseSetupOption.mockReturnValue({
        isActive: false,
        value: 'invalid merchant value',
        setupOption: undefined,
      });

      const { result } = renderHook(() => useCanPayOnline());

      const checkResult = await act(() =>
        result.current.checkIfOnlinePaymentPossible({ orderTotal: 5 })
      );

      const trackTraceMock = appInsights!.trackTrace as jest.Mock;
      expect(trackTraceMock).toHaveBeenCalled();

      expect(trackTraceMock.mock.calls[0][0]).toEqual({
        message: 'MerchantSetupOption is invalid',
        severityLevel: SeverityLevel.Error,
      });

      expect(checkResult).toBeTruthy();
      expect(result.current.isMinimumAmountError).toBeFalsy();
      expect(result.current.minimumAmount).toBeUndefined();
      expect(getPaymentMinimumAmountInfoMock).not.toHaveBeenCalled();
    });

    it('returns true when minimumAmount is unknown', async () => {
      getPaymentMinimumAmountInfoMock.mockResolvedValueOnce({});

      const { result } = renderHook(() => useCanPayOnline());

      const checkResult = await act(() =>
        result.current.checkIfOnlinePaymentPossible({ orderTotal: 5 })
      );

      expect(checkResult).toBeTruthy();
      expect(result.current.isMinimumAmountError).toBeFalsy();
      expect(result.current.minimumAmount).toBeUndefined();
      expect(getPaymentMinimumAmountInfoMock).toHaveBeenCalled();

      const trackTraceMock = appInsights!.trackTrace as jest.Mock;
      expect(trackTraceMock).not.toHaveBeenCalled();
    });

    it('returns true and sets minimum amount when minimumAmount is lower than order total', async () => {
      getPaymentMinimumAmountInfoMock.mockResolvedValueOnce({ data: { minimumAmount: 10 } });

      const { result } = renderHook(() => useCanPayOnline());

      const checkResult = await act(() =>
        result.current.checkIfOnlinePaymentPossible({ orderTotal: 5 })
      );

      expect(checkResult).toBeFalsy();
      expect(result.current.isMinimumAmountError).toBeTruthy();
      expect(result.current.minimumAmount).toBe(10);
      expect(getPaymentMinimumAmountInfoMock).toHaveBeenCalled();

      expect(getPaymentMinimumAmountInfoMock.mock.calls[0][0]).toEqual({
        pspCode: 'stripe',
        currency: 'EUR',
      });

      act(() => result.current.clearMinimumAmountError());

      expect(result.current.isMinimumAmountError).toBeFalsy();
      expect(result.current.minimumAmount).toBeUndefined();
    });
  });
});
