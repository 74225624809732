import { useMemo } from 'react';

import { MenuItem, SuggestedMenuItem } from '../types/orderState.types';

export const useMenuItemsAvailableForScan = (
  suggestions: SuggestedMenuItem[],
  menuItem?: MenuItem,
  menuItems?: MenuItem[]
) => {
  return useMemo(() => {
    if (suggestions.length === 0) {
      return [menuItem];
    }

    const menuItemsAvailableForScan = suggestions.map((suggestion) => {
      return menuItems?.find((item) => suggestion.menuItemId === item.menuItemId);
    });

    return [...menuItemsAvailableForScan, menuItem];
  }, [menuItem, menuItems, suggestions]);
};
