import { useEffect, useState } from 'react';

import { CartMenuItem, MenuItem, ProductPortion } from '../types/orderState.types';

const usePortion = (menuItem?: MenuItem, selectedCartItem?: CartMenuItem) => {
  let portion: ProductPortion | null;

  if (selectedCartItem) {
    portion =
      menuItem?.productPortions.find(
        (productPortion) => productPortion.uomId === selectedCartItem.uomId
      ) ?? null;
  } else {
    portion =
      menuItem?.productPortions.find((productPortion) => productPortion.isDefault === true) ?? null;
  }
  const [selectedPortion, selectPortion] = useState(portion);

  useEffect(() => {
    if (menuItem && !selectedPortion) {
      selectPortion(menuItem?.productPortions.find((x) => x.isDefault === true) || null);
    }
  }, [menuItem, selectedPortion]);

  useEffect(() => {
    if (!selectedCartItem && !selectedPortion) {
      selectPortion(menuItem?.productPortions.find((x) => x.isDefault === true) || null);
    }
  }, [menuItem, selectedCartItem, selectedPortion]);

  return {
    selectedPortion,
    selectPortion,
  };
};

export default usePortion;
