import { ReorderMenuItem } from '../types/orderState.types';
import { ProductItem } from '../types/productList.types';

export const mapReorderMenuItemToProductItem = (item: ReorderMenuItem): ProductItem | null => {
  const defaultPortion = item.productPortions.find((x) => x.isDefault === true);
  if (!defaultPortion) return null;

  return {
    id: `${item.menuId}_${item.menuItemId}`,
    menuItemId: item.menuItemId,
    uomId: defaultPortion.uomId,
    title: item.name,
    description: item.description,
    imageUrl: item.listImage ?? '',
    price: item.price,
    isVegan: defaultPortion.isVegan,
    isVegetarian: defaultPortion.isVegetarian,
    showAllergensWarning: false,
    genericCategory: item.genericCategory,
  };
};
