import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { History } from 'history';
import moment from 'moment';
import { OsTypes } from 'react-device-detect';

import { APP_NAME } from '../../../constants';
import { TranslationProps } from '../../../localization/localization.types';
import ServiceRequestStatus from '../components/Status/Status';
import { pagePaths, statusMap } from '../config';
import { ContactType, LocationResponseType } from '../types/apiResponse.types';

import { ChevronRightIcon } from '@/assets/icons';
import { TileItem } from '@/components/organisms/Tile';
import { formatDateTime } from '@/helpers/dateTime';
import { SERVICE_REQUEST_STATUS } from '@/modules/ServiceRequest/components/Status/Status.types';
import {
  LocationProps,
  ServiceRequestCreationProps,
  ServiceRequestProps,
  SrTutorial,
} from '@/modules/ServiceRequest/types/types';
import { User } from '@/types/user.types';

const tutorials_key = 'service_request_tutorial';
const localServiceRequestsKey = 'local_service_requests';

export const saveLocalServiceRequest = (localSr: ServiceRequestProps) => {
  const cachedString = localStorage.getItem(localServiceRequestsKey);
  const cachedRequests = cachedString ? JSON.parse(cachedString) : [];
  cachedRequests.push(localSr);
  localStorage.setItem(localServiceRequestsKey, JSON.stringify(cachedRequests));
};

export const getLocalServiceRequest = () => {
  const cachedString = localStorage.getItem(localServiceRequestsKey);
  return cachedString ? JSON.parse(cachedString) : [];
};

export const removeLocalServiceRequest = (localSrId: string) => {
  const cachedString = localStorage.getItem(localServiceRequestsKey);
  const cachedRequests = cachedString ? JSON.parse(cachedString) : [];
  const newResults = cachedRequests.filter((sr: ServiceRequestProps) => sr.id !== localSrId);
  localStorage.setItem(localServiceRequestsKey, JSON.stringify(newResults));
};

export const clearLocalServiceRequest = () => {
  localStorage.removeItem(localServiceRequestsKey);
};

export const srTimeDiff = (localCreationTime: string, serverCreationTime: string): boolean => {
  const momentLocal = moment(localCreationTime);
  const momentServer = moment(serverCreationTime);
  const diff = momentServer.diff(momentLocal, 'seconds');
  // Match with server creation time diff can be up to 10 minutes
  return diff > 0 && diff < 600;
};

export const renderAffectedPersonName = (
  firstName: string,
  lastName: string,
  email: string,
  label: TranslationProps['label'],
  currentUser: boolean,
  asString: boolean = false
): JSX.Element | string => {
  const emailAppend = email ? ` (${email})` : '';

  if (asString === true) {
    const currentUserAppend = currentUser ? label('Ref: you pronoun') : '';
    return `${firstName} ${lastName}${emailAppend} ${currentUserAppend}`;
  }
  return (
    <>
      {firstName} {lastName}
      {emailAppend}{' '}
      {currentUser ? (
        <span className={classNames('colorPrimaryBlueLight')}>{label('Ref: you pronoun')}</span>
      ) : null}
    </>
  );
};
export const formatPossibleAffectedPersonsList = (
  siteContacts: ContactType[] = [],
  label: TranslationProps['label'],
  currentUser: User
) => {
  const formattedList = siteContacts.map((contact: ContactType) => ({
    label: renderAffectedPersonName(
      contact.firstName,
      contact.lastName,
      contact.email,
      label,
      contact.contactId === currentUser.contactId,
      true
    ) as string,
    value: contact.contactId,
  }));

  const sortedformattedAffectedPersonList = formattedList.sort((a: any, b: any) => {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }
    return 0;
  });

  return sortedformattedAffectedPersonList;
};

export const getLocationDisplayName = (sr: ServiceRequestProps) => {
  const locations = [];
  if (sr?.building?.name) {
    locations.push(sr.building.name);
  }
  if (sr?.floor?.name) {
    locations.push(sr.floor.name);
  }
  if (sr?.wing?.name) {
    locations.push(sr.wing.name);
  }
  if (sr?.room?.name) {
    locations.push(sr.room.name);
  }
  if (sr?.property?.name) {
    locations.push(sr.property.name);
  }
  if (sr?.site?.name) {
    locations.push(sr.site.name);
  }
  return locations?.length ? locations.join(' / ') : '';
};

export const defaultSR: ServiceRequestCreationProps = {
  isPreferredLocationCheckboxChecked: false,
  title: '',
  description: '',
  roomText: '',
  category: '',
  additionalFields: [], //will be merged into the description before SR submission
  raisedFor: {
    value: '',
    label: '',
  },
  templateId: '',
  attachments: [],
  phoneNumber: '',
  myLocation: undefined,
};

export const formatSrForTile = (
  sr: ServiceRequestProps,
  label: TranslationProps['label'],
  history: History,
  languageCode: string
): TileItem => {
  const status = statusMap[
    sr.currentStatus.toLowerCase() as keyof typeof statusMap
  ] as SERVICE_REQUEST_STATUS;
  const path = pagePaths['Details'].replace(':id', sr.id);

  return {
    id: sr.id,
    title: sr.displayTitle || sr.title,
    description: formatDateTime(new Date(sr.createdOn), languageCode),
    addendum: <ServiceRequestStatus status={status} displayStatus={label(`status: ${status}`)} />,
    linkPath: path,
    'data-testid': `service-request-tile-${sr.id}`,
    highlightedActions: [
      {
        element: <ChevronRightIcon title={label('see more', { textTransform: 'capitalize' })} />,
        action: () => history.push(path),
      },
    ],
  };
};

export const convertLocationsApiResponseToProps = (loc: LocationResponseType): LocationProps => {
  return {
    id: loc.id,
    name: loc.name,
    title: loc.title,
    searchableTitle: loc.searchableTitle,
    searchable: loc.title?.toUpperCase(), //case insensitive
    level: loc.level?.level || 1,
    hasChildren: loc?.hasChild ?? false,
    parentId: loc.parent?.id,
  };
};

export const saveSRTutorial = (tutorial: SrTutorial) => {
  localStorage.setItem(tutorials_key, JSON.stringify(tutorial));
};

export const getSRTutorial = () => {
  const cachedString = localStorage.getItem(tutorials_key);
  return cachedString ? JSON.parse(cachedString) : {};
};

export const removeSRTutorial = () => {
  localStorage.removeItem(tutorials_key);
};

const storeImages: Record<string, string> = {
  [APP_NAME.MYWAY]: `${process.env.PUBLIC_URL}/assets/storeImages/myway.png`,
  [APP_NAME.WANDO]: `${process.env.PUBLIC_URL}/assets/storeImages/wandoo.png`,
};

export const getAppStoreImagePath = (appName: APP_NAME | '') => {
  return appName ? storeImages[appName] : undefined;
};

const storeUrls = {
  [APP_NAME.MYWAY]: {
    [OsTypes.Android]: 'https://play.google.com/store/apps/details?id=com.sodexo.myway',
    [OsTypes.IOS]: 'https://apps.apple.com/app/myway-by-sodexo/id1461040000',
  },
  [APP_NAME.WANDO]: {
    [OsTypes.Android]: 'https://play.google.com/store/apps/details?id=com.sodexo.wandoapp',
    [OsTypes.IOS]: 'https://apps.apple.com/ie/app/wando-app/id6443917114',
  },
};

export const getAppStoreUrl = (appName: APP_NAME | '', osName: string) => {
  if (Capacitor.isNativePlatform()) return;

  if (appName === APP_NAME.MYWAY || appName === APP_NAME.WANDO) {
    return storeUrls[appName][osName];
  }
};
