const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Uw verzoek',
    'Ref: Summary': 'Samenvatting',
    'Ref: Request category': 'Categorie aanvragen',
    'Ref: Person affected': 'De persoon die door dit verzoek is getroffen',
    'Ref: Your phone number': 'Telefoonnummer',
    'Ref: Description': 'Beschrijving',
    'Ref: Service Requests': 'Serviceaanvragen',
    'Ref: Request Service': 'Aanvraagdienst',
    'Ref: Success title': 'Uw verzoek is gemaakt',
    'Ref: Error title': 'Oeps!',
    'Ref: Error subtitle': 'Er is een fout opgetreden. Probeer het alstublieft nog eens.',
    'Ref: Try again': 'Probeer het nog eens',
    'Ref: you pronoun': '(jij)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Dit is uw werkplek helpdesk',
    'Ref: Tutorial: tabname': 'Aanvragen',
    'Ref: Tutorial: body': 'Wij zijn hier om u te helpen. Log één verzoek per invoer in.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'U vraagt ​​om service op de site {sitename}.',
    'Ref: Building': 'Gebouw',
    'Ref: Room': 'Kamer',
    'Ref: Description help text':
      'Hoe gedetailleerder je bent, hoe beter we je kunnen ondersteunen.',
    'Ref: Next': 'Volgende',
    'Ref: Your request': 'Je aanvraag',
    'Ref: PhoneNumber error msg': 'Het telefoonnummer is niet geldig',
    'Ref: No affected person results': 'Niemand die overeenkomt met gevraagde criteria',
    'Ref: Required': '* Vereist',
  },
  Summary: {
    'Ref: Location': 'Plaats',
    'Ref: Attachment': 'Bijlagen',
    'Ref: Submit': 'Indienen',
    'Ref: No affected person results': 'Niemand die overeenkomt met gevraagde criteria',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Dienstverzoek',
    'Ref: createdOn': 'Gemaakt op',
    'Ref: summary': 'Samenvatting',
    'Ref: category': 'Categorie aanvragen',
    'Ref: request raised for': 'De persoon die door dit verzoek is getroffen',
    'Ref: phoneNumber': 'Telefoonnummer',
    'Ref: location': 'Telefoonnummer',
    'Ref: description': 'Beschrijving',
    'Ref: attachments': 'Bijlagen',
  },
};
export default labels;
