import { useEffect } from 'react';
import { useHistory } from 'react-router';

import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { isKiosk } from '../../../helpers/misc';
import { pagePaths as facilitiesPagePaths } from '../../Facilities/config';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import { KioskOrderVariant } from '../types/KioskOrder.types';

import KioskOrder from './KioskOrder';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { UserSteps } from '@/types/userSteps.types';

type FailOrderProps = {
  facilityId?: string;
};

const FailOrder = ({ facilityId }: FailOrderProps) => {
  const history = useHistory();
  const { label } = useOrderTranslation(__filename);
  const { logUserSteps } = useUserStepsInsightsLogging();

  useEffect(() => {
    logUserSteps({ event: UserSteps.OrderErrorPage, facilityId });
  }, [facilityId, logUserSteps]);

  if (isKiosk) {
    return <KioskOrder variant={KioskOrderVariant.FAIL} label={label} />;
  }

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.ERROR}
      withNavBar={false}
      tabTitle={label('Ref: Ordering failed')}
      title={label('Something went wrong', { textTransform: 'capitalize' })}
      content={label('Ref: Error in cart validation', { textTransform: 'capitalize' })}
      actions={[
        {
          label: label('Facility contact', { textTransform: 'capitalize' }),
          action: () =>
            history.push(facilitiesPagePaths.FacilityDetails.replace(':id', facilityId ?? '')),
        },
        {
          label: label('Go to home', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
    />
  );
};

export default FailOrder;
