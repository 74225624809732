import { FC, useCallback } from 'react';
import { generatePath, useHistory } from 'react-router';

import { RewardIcon } from '../../../../assets/icons';
import { GiftIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import ProgressBar from '../../../../components/atoms/ProgressBar/ProgressBar';
import { Tile } from '../../../../components/molecules/Tile';
import { SIZE } from '../../../../constants';
import { isValidHttpUrl } from '../../../../helpers/urlValidator';
import { pagePaths } from '../../config';

import { StampTileProps } from './StampTile.types';

const StampTile: FC<StampTileProps> = ({
  id,
  name,
  description,
  image,
  stampsRequired,
  stampsActual,
  rewards,
  className,
  label,
}) => {
  const getStickers = (reward: number) => {
    return reward > 0
      ? [
          {
            name: reward === 1 ? `${reward} ${label('Reward')}` : `${reward} ${label('Rewards')}`,
            icon: <RewardIcon />,
          },
        ]
      : undefined;
  };

  const history = useHistory();

  const onSeeRewardClicked = useCallback(() => {
    const redeemableProductsPath = generatePath(pagePaths.RedeemableProducts, {
      id: id.toString(),
    });
    history.push(redeemableProductsPath);
  }, [id, history]);

  const imgInfo =
    image && isValidHttpUrl(image)
      ? {
          src: image,
          alt: '',
        }
      : undefined;

  return (
    <Tile
      key={id}
      data-testid={`StampTile-${id}`}
      className={className}
      title={name}
      description={description}
      stickers={getStickers(rewards)}
      customContent={
        <ProgressBar
          data-testid="stamp-tile"
          value={stampsActual}
          max={stampsRequired}
          round
          labelOnRight
          srOnlyText={label('Ref: stamp progress')}
        />
      }
      image={<ImageWithFallback data-testid="stamp-tile" imgInfo={imgInfo} imgElement={<GiftIllustration />} />}
      onClick={onSeeRewardClicked}
    >
      <Button size={SIZE.SMALL} onClick={onSeeRewardClicked} data-testid='loyalty-see-reward'>
        {label('See Reward')}
      </Button>
    </Tile>
  );
};

export default StampTile;
