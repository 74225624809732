const labels = {
  ReviewForm: {
    'Ref: Page title': 'סקירה',
    'Ref: How was dining with us': 'איך היו אוכלים איתנו?',
    'Ref: Submit': 'שלח',
    'Ref: Filter range': 'טווח סינון',
    'Ref: Some information is missing': 'מידע מסוים חסר',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'שלח בעילום שם',
    'Ref: Your email address': 'כתובת המייל שלך',
    'Ref: Email field placeholder': 'הזן את כתובת הדוא"ל שלך',
    'Ref: Invalid email format': 'פורמט דוא"ל לא חוקי',
  },
  SuccessPage: {
    'Ref: Body': 'תודה שסיימת סקירה זו, זמנך יקר שכן סקירה זו תסייע בשיפור השירותים שלנו.',
    'Ref: Go to Feedback': 'עבור למשוב',
    'Ref: Go to home': 'לך הביתה',
    'Ref: Go to Facilities': 'עבור למתקנים',
    'Ref: Go to Product Details': 'עבור לפרטי מוצר',
    'Ref: Go to Facility Details': 'עבור לפרטי המתקן',
  },
  FacilityPicker: { 'Ref: Page title': 'סקירה', 'Ref: Search facility name': 'שם מתקן חיפוש' },
};
export default labels;
