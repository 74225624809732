const labels = {
  All: { 'Ref: Page title': 'Các nhà cung cấp' },
  SuppliersList: {
    'Ref: Supplier details': 'Xem chi tiết',
    'Missing supplier list': 'Chúng tôi đã không tìm thấy bất kỳ nhà cung cấp.',
    'Try again later': 'Thử lại sau',
  },
  SupplierDetails: {
    'Address label': 'Địa chỉ nhà:',
    'Website label': 'Trang mạng:',
    'Details label': 'Chi tiết ',
  },
};
export default labels;
