const labels = {
  All: { 'Ref: Page title': '供應商' },
  SuppliersList: {
    'Ref: Supplier details': '查看詳細信息',
    'Missing supplier list': '我們沒有找到任何供應商。',
    'Try again later': '稍後再試',
  },
  SupplierDetails: {
    'Address label': '地址：',
    'Website label': '網站：',
    'Details label': '詳情',
  },
};
export default labels;
