const labels = {
  All: {
    'Ref: stamp progress': 'Bir sonraki damgaya doğru ilerleme',
    Reward: 'Ödül',
    Rewards: 'Ödüller',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Pullar',
    'Ref: Fulfill conditions': 'Ödülleri kullanmak için pul kazanın!',
    'See Reward': "Ödül'e bakın",
    'Ref: No reward': 'Şu an için ödül yok',
  },
  RedeemableProducts: { 'Ref: Page title': 'Sadakat ödülleri' },
};
export default labels;
