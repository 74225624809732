import { SETUP_OPTIONS } from '../../../../constants';
import { SERVICE } from '../../../config';

import { useTransactionalFeedbackSetupOptions } from './useTransactionalFeedbackSetupOptions';

const mockServicesList = jest.fn();
jest.mock('../../../../store', () => ({
  store: {
    getState: () => ({
      Core: {
        services: {
          list: mockServicesList(),
        },
      },
    }),
  },
}));

const transactionalFeedbackFromOptionTrue = {
  name: SETUP_OPTIONS.RETAIL.transactionalFeedbackFrom,
  value: '2023-09-22',
};

const transactionalFeedbackFromOptionFormattedFalse = {
  name: SETUP_OPTIONS.RETAIL.transactionalFeedbackFrom,
  value: '2023/09/23',
};

const transactionalFeedbackFromOptionFalse = {
  name: SETUP_OPTIONS.RETAIL.transactionalFeedbackFrom,
  value: '',
};

const transactionalFeedbackTagsOptionTrue = {
  name: SETUP_OPTIONS.RETAIL.transactionalFeedbackTags,
  value: 'true',
};

const transactionalFeedbackTagsOptionFalse = {
  name: SETUP_OPTIONS.RETAIL.transactionalFeedbackTags,
  value: 'false',
};

describe('isTransactionalFeedbackFrom', () => {
  it('returns true when setup option is set to a string', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.RETAIL,
      setupOptions: [transactionalFeedbackFromOptionTrue],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);
    const { transactionalFeedbackFromEnabled } = useTransactionalFeedbackSetupOptions();

    expect(transactionalFeedbackFromEnabled).toBeTruthy();
  });

  it('returns false when setup option is set to a string of wrong format', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.RETAIL,
      setupOptions: [transactionalFeedbackFromOptionFormattedFalse],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);
    const { transactionalFeedbackFromEnabled } = useTransactionalFeedbackSetupOptions();

    expect(transactionalFeedbackFromEnabled).toBeFalsy();
  });

  it('returns false when setup option is set to empty', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.RETAIL,
      setupOptions: [transactionalFeedbackFromOptionFalse],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);

    const { transactionalFeedbackFromEnabled } = useTransactionalFeedbackSetupOptions();
    expect(transactionalFeedbackFromEnabled).toBeFalsy();
  });
});

describe('isTransactionalFeedbackTags', () => {
  it('returns true when setup option is set to true', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.RETAIL,
      setupOptions: [transactionalFeedbackTagsOptionTrue],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);

    const { transactionalFeedbackTagsEnabled } = useTransactionalFeedbackSetupOptions();
    expect(transactionalFeedbackTagsEnabled).toBe(true);
  });

  it('returns false when setup option is set to false', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.RETAIL,
      setupOptions: [transactionalFeedbackTagsOptionFalse],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);

    const { transactionalFeedbackTagsEnabled } = useTransactionalFeedbackSetupOptions();
    expect(transactionalFeedbackTagsEnabled).toBe(false);
  });
});
