import { useSelector } from 'react-redux';

import { State } from '../../../../types/state.types';

import { isTimeSelectionVisible } from './timeSelectionVisible.helper';

export const useIsTimeSelectionVisible = () => {
  const deliveryOptionType = useSelector(
    (state: State) => state.Order?.cart?.selectedFulfillmentType?.type
  );

  return isTimeSelectionVisible(deliveryOptionType);
};
