import classNames from 'classnames';
import React from 'react';

import styles from './ColumnLayout.module.css';

type ColumnLayoutProps = {
  children: React.ReactNode;
  image?: React.ReactNode;
};

const ColumnLayout = ({ children, image }: ColumnLayoutProps) => {
  return (
    <div className={classNames(styles.layoutWrapper)}>
      <div className={classNames(styles.column)}>{children}</div>
      {image && <div className={classNames(styles.imageColumn)}>{image}</div>}
    </div>
  );
};

export default ColumnLayout;
