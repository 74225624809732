const labels = {
  All: {
    'Ref: Redeem coffee description': 'Laukia jūsų nemokamos, šviežiai keptos kavos puodelio',
    'Ref: Congratulations': 'Sveikiname',
    'Ref: Get free coffee': 'Gaukite nemokamos kavos!',
    'Ref: Free coffee description': 'Užpildykite savo taurę, uždirbkite taškų į nemokamą kavą.',
    'Ref: Redeem': 'Atpirk!',
  },
  StampPage: {
    'Ref: Page title': 'Nemokama kava',
    'Ref: seconds left': 'Liko sekundės',
    'Ref: How it works': 'Kaip tai veikia',
    'Ref: How it works description':
      'Kiekvieną kartą pirkdami kavą, paspauskite nuskaitymo mygtuką ir nuskaitykite pateiktą QR kodą kasos aparatas.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Nuskaityti QR kodą',
    'Ref: Collect reward': 'Surinkti atlygį',
    'Ref: Reward collected': 'Atlygis surinktas',
    'Ref: Invalid QR Code': 'Neteisingas QR kodas',
    'Ref: Invalid QR Code description': 'QR kodas nepripažįstamas',
    'Ref: Close': 'Uždaryti',
    'Ref: Redeem button': 'Išpirkti',
    "Ref: The QR Code isn't recognized": 'QR kodas nepripažįstamas',
    'Ref: Cancel': 'Atšaukti',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Sveikiname!',
    'Ref: Confirm redeem description':
      'Ar norite tai naudoti? Jei taip, jūs turite tik 60 sekundžių, kad galėtumėte parodyti savo telefoną kasos aparatas ir užpildyti pirkinį',
    'Ref: Congratulations modal description': 'Paimkite nemokamą kavą dabar!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Nemokama kava!', 'Ref: See All': 'Matyti viską' },
};
export default labels;
