import { Device } from '@capacitor/device';
import { useSelector } from 'react-redux';

import { appInsights } from '../../../../appInsights';
import { State } from '../../../../types/state.types';
import { PageType } from '../../types/menuCartActions.types';

const useProductInsightsLogging = () => {
  const state = useSelector((state: State) => state);

  const logAddingProductToCart = async (
    menuId: number,
    uomId: number,
    pageType: PageType,
    isSuggestions: boolean = false
  ) => {
    const { platform } = await Device.getInfo();
    const isMobile = platform !== 'web';
    const site = state.Core.context.site;
    const siteId = site?.id;
    const siteName = site?.name;
    const menu = state.Order.menus.find((x) => x.id === menuId);

    const facilityName =
      menu?.facilityId && state.Facilities.data[menu?.facilityId]
        ? state.Facilities?.data[menu?.facilityId]?.title
        : '';
    if (!menu) {
      console.error('Menu not found to log adding product to cart page');
      return;
    }

    const product = menu.menuItems.find((x) => x.productPortions.some((pp) => pp.uomId === uomId));

    if (!menu) {
      console.error('Product not found to log adding product to cart page');
      return;
    }

    const productPotion = product?.productPortions.find((x) => x.uomId === uomId);

    appInsights?.trackTrace(
      { message: 'Product added to cart' },
      {
        event: 'ProductAddedToCart',
        siteId: siteId,
        siteName: siteName,
        facilityId: menu.facilityId,
        facilityName: facilityName,
        menuId: menuId.toString(),
        menuName: menu.name,
        productId: product?.menuItemId?.toString(),
        uomId: uomId.toString(),
        productName: product?.name,
        portionName: productPotion?.portion,
        category: product?.section,
        genericCategory: product?.genericCategory,
        price: (productPotion?.price ?? 0).toString(),
        pageType: pageType,
        isSuggestions: isSuggestions,
        isMobile: isMobile,
      }
    );
  };

  return { logAddingProductToCart };
};

export default useProductInsightsLogging;
