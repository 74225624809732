import { TILE_VARIANT } from '../../../components/molecules/Tile';
import { ISite } from '../../Sites/types/sites.types';

import { PageType } from './menuCartActions.types';
import { AllergenType, Cart, FacilityMenu } from './orderState.types';

import { TagProps } from '@/components/molecules/Tile/Tile.types';

export type ProductTileProps = {
  date: string;
  genericCategory: string;
  menu: FacilityMenu;
  site: ISite;
  saveScrollPosition?: () => void;
  cart?: Cart;
  languageCode: string;
  onScanButtonClick?: () => void;
  dark?: boolean;
  variant?: TILE_VARIANT;
  oneLine?: boolean;
  onAddFirstItemToCart?: () => void;
  onAddOrQuantityButtonClick?: () => void;
  isSuggestion?: boolean;
  isKiosk?: boolean;
  isGuest?: boolean;
  pageType?: PageType;
  canAddToFavorite?: boolean;
  tags?: TagProps[];
  focusOnRender?: boolean;
} & ProductItem;

export type ProductListItem = {
  calories: number;
  isMindful: boolean;
  isFavorite: boolean;
  category: string;
  subCategory?: string;
  genericCategory: string;
  moment?: string;
  allergens?: AllergenType[];
} & ProductItem;

export type ProductItem = {
  id: string;
  menuItemId: number;
  uomId: number;
  title: string;
  description: string;
  imageUrl: string;
  price: number | null;
  viewablePrices?: [{ name: string; value: number }];
  isVegan: boolean;
  isVegetarian: boolean;
  isMindful?: boolean;
  showAllergensWarning: boolean;
  productCategory?: string | null;
  genericCategory: string;
};

export interface Section {
  title: string;
  sections?: Section[];
  filter: (items: ProductListItem[]) => ProductListItem[];
}

export interface ProductListConfig {
  displayedItemsNum: number;
  collapsibleSections?: boolean;
  singleExpandedSection?: boolean;
  disableInfiniteScroll?: boolean;
}

export interface SiteCurrency {
  isoCode: string;
  precision: number;
  currencySymbol: string;
}

export type ProductListFiltering = {
  filter_date?: ProductListFilteringItem;
  filter_moment?: ProductListFilteringItem;
  filter_menu?: ProductListFilteringItem;
  filter_category?: ProductListFilteringItem;
  filter_allergens_highlight?: ProductListFilteringItem;
};

export type ProductListFilteringItem = {
  [key: string]: boolean;
};
export type FilterableMenuItemKey = Exclude<keyof ProductListItem, 'imgInfo' | 'bannerInfo'>;

export enum HightlightAllergenFilterValues {
  Hightlight = 'highlight',
  Hide = 'hide',
}

export type ProductListProps = {
  match: {
    params: {
      facilityId: string;
      menuId: string;
      date: string;
      barcode: string;
    };
    path: string;
  };
};

export type ProductCategory =
  | 'Coffee'
  | 'Burger'
  | 'Pizza'
  | 'Kebab'
  | 'Pasta'
  | 'Sandwich'
  | 'Noodle'
  | 'Soup'
  | 'Drink'
  | 'Vegetable'
  | 'Fruit'
  | 'Salad'
  | 'Snacks'
  | 'Chicken'
  | 'Breakfast'
  | 'Fries'
  | 'Wrap'
  | 'Bowl'
  | 'Chocolate'
  | 'Quesadilla'
  | 'Caramel'
  | 'Eggs'
  | 'Turkey'
  | 'Burrito'
  | 'Tea'
  | 'Dish'
  | 'Coke'
  | 'Other';
