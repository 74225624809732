import { useMemo } from 'react';

import { SuggestedMenuItem } from '../../types/orderState.types';

export const useMomentBasedSuggestions = (
  suggestions: SuggestedMenuItem[],
  selectedMoment: string,
  moments: string[]
) => {
  return useMemo(() => {
    if (moments.length > 1) {
      return suggestions.filter((item) => item.mealName?.toLowerCase() === selectedMoment);
    } else {
      return suggestions;
    }
  }, [suggestions, selectedMoment, moments]);
};
