import momentjs from 'moment';

import { store } from '../../../store';
import { SERVICE } from '../../config';
import { Service } from '../../Core/types/State.types';
import { moduleId, pagePaths } from '../config';
import { OrderType } from '../types/cart.types';
import { MenuType } from '../types/menuSelector.types';
import {
  Cart,
  DraftOrderRequest,
  DraftOrderResponse,
  FacilityMenu,
  OrderState,
  SubsidyType,
} from '../types/orderState.types';

import { hasNavigationEnabled } from '@/modules/Core/helpers/availableServices.helper';

export const getCart = () => {
  const orderStore: OrderState = store.getState()[moduleId];
  if (!orderStore) return null;
  const { cart }: { cart?: Cart } = orderStore;
  if (!cart) return;

  return cart || {};
};

export const mapCartToPostDraftOrderRequest = (
  cart: Cart,
  siteId: string,
  theme: Record<string, string>[],
  draftOrderId?: number,
  table?: string,
  orderType?: OrderType
): DraftOrderRequest => {
  return {
    orderId: draftOrderId,
    siteId: siteId,
    facilityId: cart.facilityId,
    menuId: cart.menuId,
    pickupDate: cart.pickupInformation?.pickupTime || momentjs().format(),
    pickupSpotId: cart.pickupInformation?.pickupSpotId,
    pickupSpotName: cart.pickupInformation?.pickupSpotName,
    timeSlotId: +(cart.pickupInformation?.pickupTimeSlotId || '0'),
    instructions: cart.pickupInformation?.pickupInstructions,
    phoneNumber: cart.pickupInformation?.pickupPhoneNumber,
    email: cart.pickupInformation?.email,
    menuPortionItems: cart.menuPortionItems?.map((cartItem) => {
      return {
        cartmenuItemId: cartItem.id,
        menuItemId: cartItem.menuItemId,
        foodItemId: cartItem.foodItemId,
        quantity: cartItem.quantity,
        uomId: cartItem.uomId,
        modifiers: cartItem.modifiers,
        name: cartItem.name,
        modifiersDisplayText: cartItem.modifiersDisplayText,
      };
    }),
    trackingId: cart.submissionTrackingId,
    theme: theme,
    deliveryOptionId: cart.selectedFulfillmentType?.id,
    deliveryOptionType: cart.selectedFulfillmentType?.type,
    table: table,
    orderType: orderType,
  };
};

export const getPrice = (
  price: number | undefined | null,
  languageCode: string,
  isoCode: string
) => {
  if (price === undefined || price === null) return '';

  const priceFormatter = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: isoCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return priceFormatter.format(price);
};

export const getMenuType = (services: Service[], isNonOrderableUrl: boolean) => {
  const isOrderModuleAvailable = hasNavigationEnabled(services, SERVICE.FOOD_ORDER);
  const isMenuModuleAvailable = hasNavigationEnabled(services, SERVICE.FOOD_MENU);

  if (isMenuModuleAvailable && isOrderModuleAvailable) {
    if (isNonOrderableUrl) {
      return MenuType.NonOrderable;
    }
    return MenuType.Orderable;
  } else if (isMenuModuleAvailable && !isOrderModuleAvailable) {
    return MenuType.NonOrderable;
  } else {
    return MenuType.All;
  }
};

export const checkIfMoreThanOnePrice = (
  viewablePrices?: [{ name: string; value: number }] | undefined
) => {
  return (viewablePrices?.length ?? 0) > 1;
};

export const getFacilityMenuPath = (
  menuId: number,
  facilityId: string,
  selectedDate: string,
  isMenuModule: boolean
) => {
  if (isMenuModule) {
    return pagePaths.MenuProductsList.replace(':facilityId', facilityId)
      .replace(':menuId', menuId.toString())
      .replace(':date', selectedDate);
  } else {
    return pagePaths.ProductsList.replace(':facilityId', facilityId)
      .replace(':menuId', menuId.toString())
      .replace(':date', selectedDate);
  }
};

export const findProductByUomId = (menu?: FacilityMenu, uomId?: number) => {
  if (!menu) return;
  return menu.menuItems.find((item) =>
    item.productPortions.some((portion) => portion.uomId === uomId)
  );
};

export const mapDraftOrderResponseToCartRequestModel = (
  draftOrderResponse: DraftOrderResponse,
  siteId: string
) => {
  const cartRequestModel = {
    siteId: siteId,
    orderId: draftOrderResponse.orderId,
    facilityId: draftOrderResponse.facilityId,
    menuId: draftOrderResponse.menuId,
    pickupDate: draftOrderResponse.pickupDate,
    pickupSpotId: draftOrderResponse.pickupSpot?.id,
    pickupSpotName: draftOrderResponse.pickupSpot?.name,
    timeSlotId: draftOrderResponse.timeSlotId,
    menuPortionItems: draftOrderResponse.orderList.map((draftOrderItem) => {
      const menuPortionItem = {
        cartMenuItemId: draftOrderItem.cartMenuItemId,
        menuItemId: draftOrderItem.menuItemId,
        foodItemId: draftOrderItem.foodItemId,
        quantity: draftOrderItem.quantity,
        uomId: draftOrderItem.uomId,
        modifiersDisplayText: draftOrderItem.modifiersDisplayText,
        modifiers: draftOrderItem.modifiers.map((modifier) => {
          const apiModifier = {
            modifierId: modifier.modifierId,
            displayText: modifier.displayText,
            values: (modifier.values || []).map((value) => {
              const modifierValue = {
                valueId: value.valueId,
                price: value.price || 0,
                tax: value.tax || 0,
                quantity: value.quantity,
              };
              return modifierValue;
            }),
          };

          return apiModifier;
        }),
      };

      return menuPortionItem;
    }),
    instructions: draftOrderResponse.instructions,
    phoneNumber: draftOrderResponse.contactPhoneNumber,
    trackingId: draftOrderResponse.trackingId,
    theme: draftOrderResponse.theme,
    deliveryOptionId: draftOrderResponse.deliveryOptionId,
    deliveryOptionType: draftOrderResponse.deliveryOptionType,
    orderType: 'Normal',
  };

  return cartRequestModel;
};

export const checkIfUserIsSubsidised = (subsidy: SubsidyType) => {
  return subsidy !== null && subsidy !== undefined && subsidy >= 0;
};
