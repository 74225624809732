import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { BarcodeIcon, EyeIcon } from '../../../../assets/icons';
import { ImageDefaultFoodImg } from '../../../../assets/images';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { Tile } from '../../../../components/molecules/Tile';
import { SERVICE } from '../../../config';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { MenuTileProps } from '../../types/menuSelector.types';
import { StateWithOrder } from '../../types/orderState.types';

import styles from './MenuTile.module.css';

const MenuTile = ({
  id,
  facilityTitle,
  menu,
  menuImageSource,
  facilityMenuPath,
  selectedDate,
  focusOnRender,
}: MenuTileProps) => {
  const history = useHistory();

  const availableServices = useSelector((state: StateWithOrder) => state.Core?.services.list);
  const { label } = useOrderTranslation(__filename);

  const tags = [];

  if (menu.isScanAndGo) {
    tags.push({
      name: label('Ref: Scan&Go'),
      icon: (
        <span className={classNames(styles.scanIcon)}>
          <BarcodeIcon />
        </span>
      ),
    });
  }

  if (!menu.isOrderable) {
    tags.push({
      name: label('Ref: View only'),
      icon: (
        <span className={classNames(styles.scanIcon)}>
          <EyeIcon />
        </span>
      ),
    });
  }
  const shouldRedirectToMenuModule =
    !menu.isOrderable && availableServices.some((x) => x.name === SERVICE.FOOD_MENU);

  const facilityPath = facilityMenuPath
    ? facilityMenuPath(menu.id, menu.facilityId, selectedDate, shouldRedirectToMenuModule)
    : '';
  const imgInfo = menuImageSource
    ? {
        src: menuImageSource,
        alt: facilityTitle,
      }
    : undefined;
  const image = <ImageWithFallback data-testid="menu-tile" imgInfo={imgInfo} imgElement={<ImageDefaultFoodImg />} />;

  return (
    <Tile
      id={id}
      title={facilityTitle}
      description={menu.name}
      image={image}
      tags={tags}
      onClick={() => history?.push(facilityPath)}
      data-testid={`MenuTile-${id}`}
      focusOnRender={focusOnRender}
    />
  );
};

export default MenuTile;
