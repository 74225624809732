import classNames from 'classnames';
import { useMemo } from 'react';

import { IProgressBarProps } from './ProgressBar.types';

import styles from './ProgressBar.module.css';

const ProgressBar = ({
  value = 0,
  max = 1,
  formatProgressLabel,
  className,
  'data-testid': dataTestId,
  round = false,
  labelOnRight = false,
  srOnlyText,
}: IProgressBarProps) => {
  const progressValue = value > 0 ? value : 0;
  const maxValue = max >= value ? max : value;

  const progressWidth = useMemo(() => (progressValue / maxValue) * 100, [maxValue, progressValue]);

  const label = formatProgressLabel
    ? formatProgressLabel(progressValue, maxValue)
    : `${progressValue}/${maxValue}`;

  return (
    <div className={classNames({ [styles.row]: labelOnRight }, className)} data-testid={`${dataTestId}-progressbar-wrapper`}>
      {label ? (
        <div
          data-testid={`${dataTestId}-progressbar-label-wrapper`}
          className={classNames(styles.progressLabel, 'bodySBold', {
            'mb-S': !labelOnRight,
          })}
        >
          <p data-testid={`${dataTestId}-progressbar-label`} className="paragraph">
            {srOnlyText && <span data-testid={`${dataTestId}-progressbar-sr-text`} className="sr-only">{srOnlyText}</span>}
            {label}
          </p>
        </div>
      ) : null}

      <div data-testid={`${dataTestId}-progressbar-barwrapper`} className={classNames(styles.wrapperBar, { [styles.round]: round })}>
        <div data-testid={`${dataTestId}-progressbar`} className={classNames(styles.progressBar)} style={{ width: `${progressWidth}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
