const labels = {
  All: {
    'Ref: Assembled': 'Lắp ráp',
    'Ref: Submitted': 'Gửi',
    'Ref: Fulfilled': 'Hoàn thành',
    'Ref: Completed': 'Hoàn thành',
    'Ref: Paid': 'Trả',
    'Ref: Approved': 'Đã được phê duyệt',
    'Ref: Ready For Pickup': 'Sẵn sàng cho xe bán tải',
    'Ref: Delivered': 'Đã giao hàng',
    'Ref: Received': 'Nhận',
    'Ref: Rejected': 'Phế phẩm',
    'Ref: Payment Error': 'Lỗi thanh toán',
    'Ref: Pending Payment': 'Trong khi chờ thanh toán',
    'Ref: Preparing': 'Chuẩn bị',
    'Ref: Refunded': 'Hoàn lại tiền',
    'Ref: Cancelled By User': 'Bị hủy bởi người dùng',
    'Ref: Refund Requested': 'Yêu cầu hoàn tiền',
    'Ref: Refund Failed': 'Hoàn lại tiền không thành công',
    'Ref: Payment Processing': 'Xử lý thanh toán',
    'Ref: Not Paid': 'Không được trả tiền',
    'Ref: Total:': 'Toàn bộ:',
    'Ref: Pickup': 'Đón',
    'Ref: Eat-in': 'Ăn vào',
    'Ref: Scan&Go': 'SCAN & GO',
    'Ref: Fulfillment Type': 'Loại thực hiện',
    'Ref: Delivery': 'Vận chuyển',
    'Ref: You have no future orders': 'Bạn không có đơn đặt hàng trong tương lai',
    'Ref: You have no past orders': 'Bạn không có đơn đặt hàng trong quá khứ',
    'Ref: You have no orders': 'Bạn không có đơn đặt hàng',
    'Ref: All orders': 'Tất cả các đơn đặt hàng',
    'Ref: Not past orders': 'Đơn đặt hàng sắp tới',
    'Ref: Past orders': 'Đơn đặt hàng trong quá khứ',
    'Ref: Vegan': 'Chay',
    'Ref: Vegeterian': 'Ăn chay',
    'Ref: See item': 'Xem mục',
    'Ref: Activated': 'Kích hoạt',
    'Ref: No suggestions': 'Không tìm thấy đề xuất',
    'Ref: Get your receipt': 'Nhận biên lai của bạn',
    'Ref: Print': 'In biên lai của bạn',
    'Ref: Email': 'Nhận biên lai của bạn qua e-mail',
    'Ref: Receipt Modal message': 'Bạn muốn nhận biên lai của mình như thế nào?',
    'Ref: Confirm': 'Xác nhận',
    'Ref: Your email address': 'địa chỉ email của bạn',
    Suggestion: 'Gợi ý',
    'Ref: Loyalty Reward': 'Phần thưởng trung thành',
    'Ref: Equation for stamps per item': '1 mua = 1 tem',
    'Ref: Paid by employer': 'Số tiền mà chủ nhân của bạn trả',
    'Ref: Paid by user': 'Số tiền mà bạn đã trả',
  },
  ProductsList: {
    'Ref: Page title': 'Danh sách sản phẩm',
    'Ref: Facility details': 'Chi tiết cơ sở',
    'Ref: Search products list': 'Tìm kiếm các mục',
    'Ref: filter label: menus': 'Thực đơn',
    'Ref: filter label: moment': 'Khoảng khăc',
    'Ref: filter label: category': 'Danh mục',
    'Ref: all': 'tất cả',
    'Ref: today': 'Hôm nay',
    'Ref: tomorrow': 'Ngày mai',
    'Ref: see more': 'Xem thêm',
    'Ref: see less': 'Thấy ít',
    'Ref: reset': 'Cài lại',
    'Ref: View in cart': 'Xem trong giỏ hàng',
    'Ref: You have changed location': 'Bạn đã thay đổi vị trí',
    'Ref: You are now at': 'Bây giờ bạn đang ở',
    'Ref: Menu card': 'Thẻ menu',
    'Ref: Table': 'Số bảng',
    'Service unavailable': 'Dịch vụ Không sẵn có',
    'Ref: Ordering unavailable':
      'Cẩn thận! Đặt hàng tại trang web này là không có sẵn tại thời điểm này, vui lòng thử sau.',
    'Ref: Filters': 'Bộ lọc (chất gây dị ứng và nhiều hơn nữa)',
  },
  ProductScanner: {
    'Ref: cart': 'Xe đẩy',
    'Ref: Product Scanned':
      'đã được thêm vào giỏ hàng. Bạn có thể tiếp tục quét các mã vạch để thêm chúng vào giỏ hàng của bạn.',
    'Ref: Scan or adjust':
      'đã được thêm vào giỏ hàng. Điều chỉnh số lượng hoặc quét một sản phẩm khác.',
    'Ref: Product Not Matched': 'Mã vạch không được công nhận. Vui lòng thử lại',
    'Ref: Scan article code': 'Quét mã vạch',
    'Ref: Native error product cannot be added to cart':
      'Mục từ menu khác nhau hoặc phần ngày không thể được thêm vào giỏ hàng',
    'Ref: product scanner placeholder': 'Vui lòng quét mã vạch để thêm nó vào giỏ hàng của bạn',
  },
  Order: { 'Ref: Page title': 'Gọi món' },
  MenuSelector: {
    'Ref: Menu Page title': 'Thực đơn ',
    'Ref: today': 'Hôm nay',
    'Ref: tomorrow': 'Ngày mai',
    'Ref: Search products list': 'Tìm kiếm các mục',
    'Ref: You have changed location': 'Bạn đã thay đổi vị trí',
    'Ref: You are now at': 'Bây giờ bạn đang ở',
  },
  ProductDetails: {
    'Ref: Page title': 'Thông tin chi tiết sản phẩm',
    'Ref: Serving Size': 'Phục vụ kích thước.',
    'Ref: Nutrition': 'Thông tin dinh dưỡng',
    'Ref: Nutrition adults': 'Người lớn cần khoảng 2000 kcal mỗi ngày',
    'Ref: Nutrition Calories': 'Calo',
    'Ref: Nutrition Calcium': 'Canxi.',
    'Ref: Nutrition Fat': 'chất béo',
    'Ref: Nutrition Saturated Fat': 'Chất béo bão hòa',
    'Ref: Nutrition Carbohydrates': 'Carbohydrates',
    'Ref: Nutrition Sugar': 'Đường',
    'Ref: Nutrition Protein': 'Chất đạm',
    'Ref: Nutrition Sodium': 'Natri',
    'Ref: Nutrition cholesterol': 'Cholesterol.',
    'Ref: Nutrition dietaryFiber': 'Chất xơ',
    'Ref: Nutrition iron': 'Sắt',
    'Ref: Nutrition potassium': 'Kali.',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Additives': 'chất phụ gia',
    'Ref: Emission': 'Lượng khí thải CO2',
    'Ref: This product generates': 'Sản phẩm này tạo ra.',
    'Ref: of CO2': 'của CO2',
    'Ref: emissions, which equals a': 'khí thải, bằng một',
    'Ref: car trip': 'Chuyến xe',
    'Ref: Ingredients': 'Thành phần công thức',
    'Ref: Allergens': 'Dị ứng ',
    'Ref: Always there': 'Luôn luôn ở đó',
    'Ref: Allergens selected': 'Chất gây dị ứng được chọn trên bộ lọc:',
    'Ref: minimum required': 'đòi hỏi tối thiểu',
    'Ref: you have to add at least': 'Bạn phải thêm ít nhất',
    'Ref: ingredients': 'Thành phần',
    'Ref: Ingredient': 'nguyên liệu',
    'Ref: Free': 'Miễn phí',
    'Ref: Based on customization': 'Dựa trên tùy chỉnh của bạn',
    'Ref: Additives eggs': 'trứng',
    'Ref: Additives fish': 'cá',
    'Ref: Additives milk': 'Sữa',
    'Ref: Additives nuts': 'quả hạch',
    'Ref: Additives wheat': 'lúa mì',
    'Ref: Additives peanuts': 'đậu phộng',
    'Ref: Additives soy beans': 'Đậu đậu nành',
    'Ref: Additives sesame seeds': 'Hạt mè',
    'Ref: Review': 'Xét lại',
    'Ref: Per portion': 'Mỗi phần',
    'Ref: Per 100g': 'Mỗi 100g',
    'Ref: Allergen selected': 'Dị ứng được chọn trên bộ lọc tìm kiếm',
    'Ref: Fitbit Log': 'Đăng nhập vào Fitbit',
    'Ref: age verification required on delivery': 'Cần xác minh tuổi khi giao hàng',
    'Ref: alcohol content notification':
      'Bằng cách đặt và đặt hàng cho một trong những mặt hàng này, bạn tuyên bố rằng bạn từ 18 tuổi trở lên. Lạm dụng rượu là nguy hiểm cho sức khỏe của bạn.',
    'Ref: default': 'Mặc định',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} kiếm được nếu bạn đặt hàng sản phẩm này',
    'Ref: Loyalty Card Description':
      '{stamp_form} sẽ được thêm vào lòng trung thành sau {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Chương trình Tem Loyalty cho phép bạn thu thập tem cho phần thưởng.',
    'Ref: Loyalty Modal Description':
      'Khi bạn mua <b> {product_name}, 1 tem </b> sẽ được thêm vào <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'Và',
    'Ref: Stamp': 'Con tem',
    'Ref: Stamps': 'Tem',
    'Ref: Program': 'Chương trình',
    'Ref: Programs': 'Chương trình',
    'Ref: Loyalty Stamps': 'Tem trung thành',
    'Ref: See Loyalty Stamps': 'Xem tem trung thành',
    Favorite: 'Yêu thích',
    'Ref: In favorites': 'Sản phẩm đã có trong danh sách yêu thích. Bấm để loại bỏ nó.',
    'Ref: Not in favorites': 'Sản phẩm chưa có trong danh sách yêu thích. Bấm để thêm nó.',
    'Ref: Added to favorites': 'Sản phẩm đã được thêm vào danh sách yêu thích.',
    'Ref: Removed from favorites': 'Sản phẩm đã được xóa khỏi danh sách yêu thích.',
    'Ref: From': 'Từ',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Thay đổi trong giỏ hàng',
    'Ref: Popup header for customizable item qty update': 'Mục này có thể tùy chỉnh',
    'Ref: add new version': 'Thêm phiên bản mới',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Cập nhật số lượng của một mục có thể tùy chỉnh trực tiếp từ giỏ hàng của bạn.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Cập nhật số lượng của một mục có thể tùy chỉnh trực tiếp từ giỏ hàng của bạn hoặc thêm phiên bản mới.',
    'Ref: another context body':
      'Để thêm các mục từ trang web, cơ sở, khoảnh khắc/menu hoặc ngày bạn cần xóa giỏ hàng',
    'Ref: cancel': 'Hủy bỏ',
    'Ref: add to cart': 'Thêm vào giỏ hàng',
    'Ref: view in cart': 'Xe đẩy',
    'Ref: Products in cart': 'Số lượng {sản phẩm_name} trong giỏ hàng: {sản phẩm_quantity}',
    'Ref: Customize': 'Tùy chỉnh',
    'Ref: Add': 'cộng',
    'Ref: Free': 'Miễn phí',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Hủy bỏ',
    'Ref: notice': 'Lưu ý',
    'Ref: clear cart': 'Giỏ hàng rõ ràng',
    'Ref: Cart addition error message':
      'Hiện tại bạn có các mặt hàng trong giỏ hàng từ {from_selected}. Để thêm các mục từ {from_selected}, bạn cần xóa giỏ hàng',
  },
  Cart: {
    'Ref: Page title': 'Xe đẩy',
    'Ref: The cart is empty': 'Giỏ hàng trống',
    'Ref: Clear cart': 'Giỏ hàng rõ ràng',
    'Ref: Continue shopping': 'Tiếp tục mua sắm',
    'Ref: Start shopping': 'Bắt đầu mua sắm',
    'Ref: Today': 'Hôm nay',
    'Ref: Tomorrow': 'Ngày mai',
    'Ref: Tax:': 'Thuế:',
    'Ref: Promotion:': 'Khuyến mãi:',
    'Ref: Total without promotion:': 'Tổng số không khuyến mãi:',
    'Ref: Delivery:': 'Vận chuyển:',
    'Ref: Pickup informations': 'Thông tin đón',
    'Ref: Location': 'Địa điểm',
    'Ref: Time': 'Thời gian',
    'Ref: Phone': 'Số điện thoại của bạn',
    'Ref: Instructions': 'Hướng dẫn',
    'Ref: Please select': 'Xin hãy lựa chọn',
    'Ref: Enter special requests': 'Nhập các yêu cầu đặc biệt tại đây',
    'Ref: notice': 'Lưu ý',
    'Ref: Product is no more available':
      'Xin lỗi nhưng có vẻ như một số sản phẩm không còn có sẵn. Vui lòng kiểm tra giỏ hàng của bạn.',
    'Ref: ok': 'Được',
    'Ref: Validate': 'Xác thực đơn đặt hàng của tôi',
    'Ref: Pay now': 'Trả tiền ngay',
    'Ref: Charge to my department': 'Tính phí cho bộ phận của tôi',
    'Ref: Pay on pickup': 'Trả tiền cho các mặt hàng này khi nhận',
    'Ref: Accept terms of sale prefix': 'tôi chấp nhận',
    'Ref: Accept terms of sale': 'Những điều khoản bán hàng này',
    'Ref: Continue': 'Tiếp tục',
    'Ref: Please login or sign up to place an order': 'Vui lòng đăng nhập hoặc đăng ký để đặt hàng',
    'Ref: Accept terms of sale suffix': ', và tôi đã đọc và hiểu chúng.',
    'Ref: Payment modality': 'Phương thức thanh toán',
    'Ref: Other information': 'Thông tin khác',
    'Ref: Eat-in': 'Ăn vào',
    'Ref: Pickup': 'Đón',
    'Ref: Table': 'Số bảng',
    'Ref: Required fields': 'Phần bắt buộc',
    'Service unavailable': 'Dịch vụ Không sẵn có',
    'Ref: Cannot order':
      'Xin lỗi, bạn không thể đặt hàng tại trang web này vào lúc này. Vui lòng liên hệ với nhà hàng để biết thêm thông tin về giờ mở cửa và các dịch vụ có sẵn.',
    'Ref: Order Unavailable': 'Đặt hàng cho ngày chọn, loại thực hiện và vị trí không còn nữa!',
    'Ref: No Availability': 'Không có sự sẵn có nữa',
    'Ref: Continue on your device': 'Tiếp tục trên thiết bị của bạn',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} kiếm được nếu bạn hoàn thành đơn đặt hàng',
    'Ref: Loyalty Card Description':
      '{stamp_form} sẽ được thêm vào lòng trung thành sau {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Chương trình Tem Loyalty cho phép bạn thu thập tem cho phần thưởng.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> kiếm được nếu bạn hoàn thành đơn đặt hàng của mình:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> Đã thêm cho <b> {scheme_names} </b>',
    'Ref: Stamp': 'Con tem',
    'Ref: Stamps': 'Tem',
    'Ref: Program': 'Chương trình',
    'Ref: Programs': 'Chương trình',
    'Ref: Loyalty Stamps': 'Tem trung thành',
    'Ref: See Loyalty Stamps': 'Xem tem trung thành',
    'Ref: Loyalty Info': 'Thông tin trung thành',
    'Ref: Email address': 'Địa chỉ email',
    'Ref: and': 'Và',
    'Ref: Order minimum amount': 'Xin lỗi, số tiền thanh toán tối thiểu là {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Tiếp tục trên thiết bị của bạn',
    'Ref: Scan QR to continue':
      'Xin vui lòng, quét mã QR để tiếp tục hành trình trên thiết bị của bạn.',
  },
  OrderHistory: { 'Ref: Orders': 'Đơn đặt hàng', 'Ref: Page Title': 'Đơn đặt hàng của tôi' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Đơn giá',
    'Ref: Download': 'Tải xuống biên lai',
    'Ref: Download error': 'Một lỗi tải xuống đã xảy ra. Cố gắng tải xuống biên lai sau',
    'Ref: Order details title': 'Chi tiết đặt hàng',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Cảm ơn bạn! Đơn đặt hàng của bạn hiện đã được xác nhận',
    'Ref: Text for pickup':
      'Bạn có thể theo trạng thái của nó thông qua lịch sử đặt hàng của bạn. Nếu thông báo của bạn được bật, chúng tôi sẽ cập nhật cho bạn khi nó sẵn sàng để nhận.',
    'Ref: Success Order Title': 'Cảm ơn bạn đã đặt hàng của bạn',
    'Ref: Success Order Description':
      'Vui lòng thanh toán khi thanh toán để xác thực đơn đặt hàng của bạn.',
    'Ref: Success Order ID': 'Id đặt hàng',
    'Ref: Success Order Action': 'Đơn hàng mới',
    'Ref: Text for dinein':
      'Đơn đặt hàng của bạn nên được phục vụ cho bàn của bạn sớm. Chỉ cần hỏi bất kỳ nhân viên thân thiện của chúng tôi về đơn đặt hàng ăn uống của bạn.',
    'Ref: Text for scan&go': 'Đơn hàng của bạn có sẵn trong lịch sử đơn hàng của bạn',
    'Ref: Text for delivery':
      'Bạn có thể theo dõi trạng thái của nó trong lịch sử đơn hàng của bạn. Nếu thông báo của bạn được bật, chúng tôi sẽ thông báo cho bạn khi đơn đặt hàng của bạn đã sẵn sàng.',
    'See details': 'Xem chi tiết',
    'Go to home': 'Đi về nhà',
  },
  FailOrder: {
    'Go to home': 'Đi về nhà',
    'Ref: Kiosk Fail Title': 'Ối! Có gì đó đã sai!',
    'Ref: Kiosk Fail Description':
      'Thật không may, đơn đặt hàng của bạn đã không thành công. Hãy thử lại hoặc yêu cầu một nhân viên giúp bạn.',
    'Ref: Kiosk Fail Back': 'Quay lại trang trước',
    'Ref: Kiosk Fail Action': 'Xóa phiên và đặt hàng lại',
    'Ref: Error in cart validation':
      'Một lỗi đã xảy ra trong quá trình đặt hàng của bạn. Vui lòng liên hệ với cơ sở để giải quyết vấn đề.',
    'Facility contact': 'Liên hệ cơ sở',
    'Ref: Ordering failed': 'Đặt hàng không thành công',
    'Ref: Order confirmed': 'Xác nhận đặt hàng',
  },
  TableCheckIn: {
    'Welcome at': 'Chào mừng tại',
    Table: 'Bàn:',
    'Ref: list title': 'Đơn đặt hàng gần đây cho ngày hôm nay',
    'See the menu': 'Xem menu',
    'Table Check-in': 'Đăng ký bảng',
    'Ref #': 'Tham khảo #',
    Checked: 'Đã kiểm tra',
    'Check in': 'Đăng ký vào',
    'See details': 'Xem chi tiết',
    'Ref: You have changed location': 'Bạn đã thay đổi vị trí',
    'Ref: You are now at': 'Bây giờ bạn đang ở',
    'Order number': 'Số đặt hàng',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Khuyến mãi áp dụng',
    'Ref: Promotion applied description':
      'Bạn được hưởng lợi một chương trình khuyến mãi từ giỏ hàng của bạn.',
    'Ref: Promotion deleted title': 'Khuyến mãi đã xóa',
    'Ref: Promotion deleted description':
      'Bạn không còn được hưởng lợi khuyến mãi vì bạn không còn lấp đầy các điều kiện hoặc chương trình khuyến mãi đã hết hạn.',
  },
  MenuTile: { 'Ref: Scan&Go': 'SCAN & GO', 'Ref: View only': 'Chỉ xem' },
  ProductTile: {
    'Ref: Allergens': 'Chất gây dị ứng',
    'Ref: Presence of allergen': 'Sự hiện diện của chất gây dị ứng',
    'Ref: Scan': 'Quét',
    'Ref: From': 'Từ',
    'Ref: Already in product favorite list. Click to remove it.':
      'Đã có trong danh sách yêu thích của sản phẩm. Bấm để loại bỏ nó.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Hiện không được thêm vào danh sách sản phẩm yêu thích. Bấm để thêm nó.',
    'Ref: Free': 'Miễn phí',
  },
  OrderSummary: {
    'Ref: item': 'mục',
    'Ref: items': 'mặt hàng',
    'Ref: Clear': 'Xa lạ',
    'Ref: Cart': 'Xe đẩy',
    'Ref: Subtotal': 'Tổng phụ',
    'Ref: Total': 'Tổng cộng',
    'Ref: Tax': 'Thuế',
    'Ref: Promotion': 'Khuyến mãi',
    'Ref: Total without promotion': 'Tổng cộng mà không cần thăng tiến',
    'Ref: Number of product in cart': 'Số lượng sản phẩm trong giỏ hàng',
    'Ref: Delivery': 'Vận chuyển',
  },
  CartItemTile: { 'Ref: Vegan': 'Thuần chay', 'Ref: Vegeterian': 'Ăn chay' },
  OrderInfoCard: {
    'Ref: Header': 'Đặt hàng cho',
    'Ref: from': 'từ',
    'Ref: location': 'Địa điểm',
    'Ref: Phone': 'Số điện thoại của bạn',
    'Ref: Instructions': 'Hướng dẫn',
    'Ref: Order type': 'Kiểu đơn hàng',
    'Ref: Pickup': 'Đón',
    'Ref: Eat-in': 'Ăn vào',
    'Ref: Scan&Go': 'SCAN & GO',
  },
  ReorderWidget: {
    'Ref: No items': 'Chưa có đơn đặt hàng nào được thực hiện. Mua sắm ngay!',
    'Ref: Title': 'Đặt hàng một lần nữa',
  },
  MenusWidget: {
    'Ref: no menu': 'Không có menu nào cho thời điểm này',
    'Ref: Modal title': 'Thực đơn cho ngày',
  },
  PromotionInfo: { 'Ref: Discounts available': 'giảm giá có sẵn' },
  PromotionInfoItem: { 'Ref: Activate': 'Hoạt động' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Đơn đặt hàng của bạn',
    'Ref: Widget Title': 'Đơn đặt hàng của tôi',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Đề xuất trong ngày',
    'Ref: Any menu': 'Bất kỳ menu nào',
    Other: 'Khác',
    'Ref: Suggestions calendar button': 'Lịch đề xuất',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Phục vụ kích thước.',
    'Ref: Nutrition': 'Thông tin dinh dưỡng',
    'Ref: Nutrition adults': 'Người lớn cần khoảng 2000 kcal mỗi ngày',
    'Ref: Nutrition Calories': 'Calo',
    'Ref: Nutrition Calcium': 'Canxi.',
    'Ref: Nutrition Fat': 'chất béo',
    'Ref: Nutrition Saturated Fat': 'Chất béo bão hòa',
    'Ref: Nutrition Carbohydrates': 'Carbohydrates',
    'Ref: Nutrition Sugar': 'Đường',
    'Ref: Nutrition Protein': 'Chất đạm',
    'Ref: Nutrition Sodium': 'Natri',
    'Ref: Nutrition cholesterol': 'Cholesterol.',
    'Ref: Nutrition dietaryFiber': 'Chất xơ',
    'Ref: Nutrition iron': 'Sắt',
    'Ref: Nutrition potassium': 'Kali.',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Ingredients': 'Thành phần công thức',
    'Ref: Allergens': 'Dị ứng ',
    'Ref: Always there': 'Luôn luôn ở đó',
    'Ref: Allergens selected': 'Chất gây dị ứng được chọn trên bộ lọc:',
    'Ref: minimum required': 'đòi hỏi tối thiểu',
    'Ref: ingredients': 'Thành phần',
    'Ref: Ingredient': 'nguyên liệu',
    'Ref: Free': 'Miễn phí',
    'Ref: Based on customization': 'Dựa trên tùy chỉnh của bạn',
    'Ref: Additives eggs': 'trứng',
    'Ref: Additives fish': 'cá',
    'Ref: Additives milk': 'Sữa',
    'Ref: Additives nuts': 'quả hạch',
    'Ref: Additives wheat': 'lúa mì',
    'Ref: Additives peanuts': 'đậu phộng',
    'Ref: Additives soy beans': 'Đậu đậu nành',
    'Ref: Additives sesame seeds': 'Hạt mè',
    'Ref: Review': 'Xét lại',
    'Ref: Per portion': 'Mỗi phần',
    'Ref: Per 100g': 'Mỗi 100g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} miễn phí',
    'Ref: Number of free items': 'Số lượng các mặt hàng miễn phí: {number_of_free_items}',
    'Ref: Allergen selected': 'Dị ứng được chọn trên bộ lọc tìm kiếm',
    'Ref: Nutritional info': 'Thông tin dinh dưỡng',
    'Ref: Info': 'Thông tin',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'chương trình khuyến mãi' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Chọn công việc của bạn',
    'Ref: Validate': 'Xác nhận',
    'Ref: Pricing Tiers dialog message': 'Bạn đã được công nhận với địa chỉ email của mình',
    'Ref: Please select your job in the company': 'Vui lòng chọn công việc của bạn trong công ty',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Đón',
    'Ref: Eat-in': 'Ăn vào',
    'Ref: Scan&Go': 'SCAN & GO',
    'Ref: Delivery': 'Vận chuyển',
    'Ref: Total': 'Tổng cộng',
    'Ref: How was facility name service': '{facility_name} dịch vụ như thế nào?',
    'Ref: How do you rate the service': 'Làm thế nào để bạn đánh giá dịch vụ?',
  },
};
export default labels;
