import { MenuItem, ProductPortion } from './orderState.types';
import { ModifierSelections, ModifierItemQuantities } from './productDetails.types';

type PriceInformation = {
  totalPrice: number;
  totalPriceLabel: string;
};

export type ItemPrices = {
  id: number;
  unitPrice: number;
  isFreeOnItemLevel: boolean;
};

export type FinalItemPrices = ItemPrices & {
  isFree: boolean;
};

export enum PageType {
  productDetails = 'productDetails',
  productsList = 'productsList',
  cart = 'cart',
  scanner = 'scanner',
  reorderWidget = 'reorderWidget',
  suggestionWidget = 'suggestionWidget',
  globalSearchModal = 'globalSearchModal',
}

export type Configuration = {
  [pageType: string]: {
    quantitySetter?: boolean;
    addToCartButton?: boolean;
    viewCartButton?: boolean;
  };
};

export type SelectedModifiers = {
  modifierId: number;
  itemQuantities: ModifierItemQuantities;
  itemIds: string[];
};

export type MenuCartActionsProps = {
  menuItem: MenuItem;
  facilityId: string;
  date: Date;
  menuId: number;
  pageType: PageType;
  selectedPortion?: ProductPortion;
  selectedModifiers?: ModifierSelections[];
  priceInformation?: PriceInformation;
  handleValidation?: () => boolean;
  cartMenuItemId?: string;
  disableChange?: boolean;
  saveScrollPosition?: () => void;
  disableIncrement?: boolean;
  isSuggestions?: boolean;
  onAddFirstItemToCart?: () => void;
  onAddOrQuantityButtonClick?: () => void;
  discountPrice?: number;
  redeemableQuantity?: number;
  isLoading?: boolean;
};

export type CartContextInfo = {
  isAnotherOrderContext: boolean;
  reason?: CartDifferentContextTypeEnum;
};

export enum CartDifferentContextTypeEnum {
  DifferentDate = 1,
  DifferentMenu = 2,
  DifferentMoment = 3,
}
