const labels = {
  All: {
    'Ref: Redeem coffee description': 'Su taza de café gratis y recién preparada está esperando',
    'Ref: Congratulations': 'Felicidades',
    'Ref: Get free coffee': '¡Obtenga café gratis!',
    'Ref: Free coffee description': 'Llena tu taza, gana puntos para un café gratis.',
    'Ref: Redeem': '¡Redimir!',
  },
  StampPage: {
    'Ref: Page title': 'Café gratis',
    'Ref: seconds left': 'Segundos restantes',
    'Ref: How it works': '¿Cómo funciona?',
    'Ref: How it works description':
      'Cada vez que compre un café, presione el botón de escaneo y escanee el código QR proporcionado en la caja registradora.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Escanear código QR',
    'Ref: Collect reward': 'Recoger la recompensa',
    'Ref: Reward collected': 'Recompensa recolectada',
    'Ref: Invalid QR Code': 'Código QR no válido',
    'Ref: Invalid QR Code description': 'El código QR no se reconoce',
    'Ref: Close': 'Cerrar',
    'Ref: Redeem button': 'Redimir',
    "Ref: The QR Code isn't recognized": 'El código QR no se reconoce',
    'Ref: Cancel': 'Cancelar',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': '¡Felicidades!',
    'Ref: Confirm redeem description':
      '¿Seguro que quieres usar esto? Si es así, tiene solo 60 segundos para mostrar su teléfono en la caja registradora y completar su compra',
    'Ref: Congratulations modal description': '¡Toma tu café gratis ahora!',
  },
  Widget: { 'Ref: Free Coffee widget title': '¡Café gratis!', 'Ref: See All': 'Ver todo' },
};
export default labels;
