import { connect } from 'react-redux';

import { cleanCart } from '../actions';
import Checkout from '../components/Checkout';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    cleanCart: () => dispatch(cleanCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
