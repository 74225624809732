import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import StampPage from './StampPage';

const Stamps = () => {
  return (
    <Switch>
      <Route exact path={pagePaths.Module} component={StampPage} />
    </Switch>
  );
};

export default Stamps;
