import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { CartIcon, PlusSignIcon } from '../../../assets/icons';
import Button from '../../../components/atoms/Button';
import QuantitySetter from '../../../components/atoms/QuantitySetter/QuantitySetter';
import { Alert } from '../../../components/molecules/Alert';
import { AlertComponentProps } from '../../../components/molecules/Alert/Alert.types';
import { SIZE } from '../../../constants';
import { cleanCart, initializeCart } from '../actions';
import { pagePaths } from '../config';
import { getCartDiscountTotal, getCartOriginalTotal } from '../helpers/cart.helper';
import {
  getCartMenuItems,
  buildProductDetailsPath,
  getMenuItemQuantity,
  increaseItemWithModifiersOrPortions,
  redirectWhenPortionsOrModifiers,
  increaseItem,
  decrementItem,
  buildPopupForRemoveCustomizableItems,
  buildProductTotalPrice,
  updateCartItem,
  hasPortionsOrModifiers,
  getDefaultPortion,
  isCartAnotherContext,
} from '../helpers/menuCartActions.helper';
import { getPrice } from '../helpers/order.helper';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import useProductInsightsLogging from '../hooks/useProductInsightsLogging/useProductInsightsLogging';
import {
  CartContextInfo,
  Configuration,
  MenuCartActionsProps,
  PageType,
} from '../types/menuCartActions.types';
import { MenuItem, ProductPortion, StateWithOrder } from '../types/orderState.types';

import CartAnotherContextPopup from './CartAnotherContextPopup/CartAnotherContextPopup';
import PriceWithDiscount from './PriceWithDiscount';
import { getCartInfo } from './ProductsList/productList.helper';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { UserSteps } from '@/types/userSteps.types';

import styles from './MenuCartActions.module.css';

export const PAGE_TYPES = {
  productsList: 'productsList',
  productDetails: 'productDetails',
  cart: 'cart',
};

const MenuCartActions = ({
  menuItem,
  facilityId,
  date,
  menuId,
  pageType = PageType.productsList,
  selectedPortion,
  selectedModifiers,
  handleValidation,
  cartMenuItemId,
  disableChange,
  saveScrollPosition,
  disableIncrement,
  isSuggestions = false,
  onAddFirstItemToCart,
  onAddOrQuantityButtonClick,
  discountPrice,
  redeemableQuantity = 0,
  isLoading = false,
}: MenuCartActionsProps) => {
  const { id: siteId, currency: { isoCode } = { isoCode: '' } } = useSite({
    throwWhenNoActiveSite: true,
  })!;

  const { label } = useOrderTranslation(__filename);
  const {
    cart,
    draft: orderDraft,
    locks: { createOrderDraft: orderDraftLock },
  } = useSelector((state: StateWithOrder) => state.Order);
  const { currentLanguageCode: languageCode } = useLanguage();

  const dispatch = useDispatch();
  const history = useHistory();

  const defaultPortion = getDefaultPortion(menuItem);
  const disableModification = orderDraftLock || disableChange;
  const { logAddingProductToCart } = useProductInsightsLogging();
  const { logUserSteps } = useUserStepsInsightsLogging();

  const isCartOlderThanToday = useCallback(() => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return cart && cart.date < today;
  }, [cart]);

  if (isCartOlderThanToday()) {
    dispatch(cleanCart());
  }

  let portionsOrModifiers = hasPortionsOrModifiers(menuItem);

  const isProductDetailsView = pageType === PAGE_TYPES.productDetails;

  let cartMenuItems = useMemo(
    () =>
      cart?.menuPortionItems
        ? getCartMenuItems(
            menuItem,
            cart,
            cartMenuItemId,
            selectedPortion,
            selectedModifiers,
            portionsOrModifiers,
            isProductDetailsView
          )
        : [],
    [
      cart,
      cartMenuItemId,
      portionsOrModifiers,
      isProductDetailsView,
      menuItem,
      selectedModifiers,
      selectedPortion,
    ]
  );
  const isItemNotInCart = (cartMenuItems.length || 0) === 0;

  /**
   * Configuration object to define which components should be rendered
   * since MenuCartActions is used in different contexts
   */
  const pageConfiguration: Configuration = {
    [PageType.productDetails]: {
      quantitySetter: !isItemNotInCart,
      addToCartButton: isItemNotInCart,
      viewCartButton: !isItemNotInCart,
    },
    [PageType.productsList]: {
      quantitySetter: true,
    },
    [PageType.cart]: {
      quantitySetter: true,
    },
    [PageType.scanner]: {
      quantitySetter: true,
    },
    [PageType.suggestionWidget]: {
      quantitySetter: true,
    },
    [PageType.reorderWidget]: {
      quantitySetter: true,
    },
    [PageType.globalSearchModal]: {
      quantitySetter: true,
    },
  };

  const components = { ...pageConfiguration[pageType] };

  const redirectToProductDetails = useCallback(() => {
    history.push(
      buildProductDetailsPath({
        menuId: menuId.toString(),
        menuItemId: menuItem.menuItemId.toString(),
        facilityId,
        date,
      })
    );
  }, [date, facilityId, history, menuId, menuItem?.menuItemId]);

  const redirectToCart = useCallback(() => {
    history.push(pagePaths.Cart);
  }, [history]);

  const productQuantity = getMenuItemQuantity(cartMenuItems);

  const [popup, setPopup] = useState<AlertComponentProps | null>(null);
  const [cartContextInfo, setCartContextInfo] = useState<CartContextInfo>();
  let { isAnotherOrderContext, reason } = isCartAnotherContext({
    menuItem,
    menuId,
    date,
    cart,
    isCartView: pageType === PAGE_TYPES.cart,
  });

  const onlyOneCartItemLeft =
    cart?.menuPortionItems?.length === 1 && cart?.menuPortionItems[0].quantity === 1;

  const currentModifiers = selectedModifiers?.map(({ modifierId, itemQuantities, itemIds }) => ({
    modifierId,
    itemQuantities,
    itemIds,
  }));

  const { totalPriceLabel, totalPrice } = buildProductTotalPrice({
    portion: selectedPortion,
    selectedModifiers: currentModifiers || [],
    languageCode,
    isoCode,
    productQuantity,
    redeemedFoodItems: orderDraft?.redeemedFoodItems,
  });

  const cartInfo = getCartInfo(cart, orderDraft);
  const cartTotalWithDiscount = getCartDiscountTotal(cartInfo);
  const cartTotalWithoutDiscount = getCartOriginalTotal(cartInfo);

  const cartItemPrice = totalPrice / (productQuantity || 1);

  const isUpdateCartItemState = cartMenuItemId && cartMenuItems[0]?.id === cartMenuItemId;

  const grayOutIncrement = isAnotherOrderContext;
  const grayOutDecrement =
    (portionsOrModifiers && cartMenuItems?.length > 1) || isAnotherOrderContext;

  const currentSiteIdIsCartSiteId = !cart?.siteId || cart?.siteId === siteId;
  if (isUpdateCartItemState) {
    updateCartItem({
      cartMenuItem: cartMenuItems[0],
      selectedPortion,
      selectedModifiers,
      cartItemPrice,
      dispatch,
      cart,
    });
  }

  const logIncrement = useCallback(
    (portionToAdd?: ProductPortion, menuItemToAdd?: MenuItem) => {
      if (portionToAdd?.uomId) {
        logAddingProductToCart(menuId, portionToAdd?.uomId, pageType, isSuggestions);
        logUserSteps({ event: UserSteps.AddedToCart, menuId });
      } else {
        const defaultPortion = menuItemToAdd?.productPortions.find((x) => x.isDefault);

        if (defaultPortion) {
          logAddingProductToCart(menuId, defaultPortion.uomId, pageType, isSuggestions);
          logUserSteps({ event: UserSteps.AddedToCart, menuId });
        }
      }
    },
    [logAddingProductToCart, menuId, pageType, isSuggestions, logUserSteps]
  );

  const increment = useCallback(() => {
    if (disableModification || disableIncrement) return;
    saveScrollPosition && saveScrollPosition();

    const menuItemToAdd = menuItem;
    const portionToAdd = selectedPortion;
    const canNotIncreaseDirectly =
      portionsOrModifiers &&
      (pageType !== PAGE_TYPES.cart || isSuggestions) &&
      pageType !== PageType.scanner;
    const shouldRedirectToProductDetails =
      canNotIncreaseDirectly && pageType !== PAGE_TYPES.productDetails;

    if (!cart?.menuPortionItems && !shouldRedirectToProductDetails) {
      dispatch(
        initializeCart({
          cart: {
            date: date,
            facilityId: facilityId,
            menuId: menuId,
            siteId: siteId,
            menuPortionItems: cartMenuItems || [],
            moment: menuItemToAdd.mealName,
          },
        })
      );
    }

    if (isAnotherOrderContext) {
      setCartContextInfo({ isAnotherOrderContext, reason });
    } else {
      if (canNotIncreaseDirectly) {
        if (pageType === PAGE_TYPES.productDetails && !isSuggestions) {
          logIncrement(portionToAdd, menuItemToAdd);
          increaseItemWithModifiersOrPortions({
            cartMenuItems,
            menuItem: menuItemToAdd,
            selectedPortion: portionToAdd,
            selectedModifiers,
            cartItemPrice: cartItemPrice,
            dispatch,
          });
        } else {
          redirectWhenPortionsOrModifiers({
            productQuantity,
            redirectToProductDetails,
            setPopup,
            label,
            redirectToCart,
          });
        }
      } else {
        logIncrement(portionToAdd, menuItemToAdd);
        increaseItem({
          cartMenuItems,
          menuItem: menuItemToAdd,
          portion: portionToAdd ?? defaultPortion,
          cartItemPrice: menuItem.price ?? 0,
          onAddFirstItemToCart: onAddFirstItemToCart,
          dispatch,
        });
      }
    }
  }, [
    disableModification,
    disableIncrement,
    saveScrollPosition,
    menuItem,
    selectedPortion,
    portionsOrModifiers,
    pageType,
    isSuggestions,
    cart?.menuPortionItems,
    isAnotherOrderContext,
    reason,
    date,
    facilityId,
    menuId,
    siteId,
    cartMenuItems,
    label,
    logIncrement,
    selectedModifiers,
    cartItemPrice,
    productQuantity,
    redirectToProductDetails,
    redirectToCart,
    defaultPortion,
    onAddFirstItemToCart,
    dispatch,
  ]);

  const decrement = useCallback(() => {
    if (!cartMenuItems?.length || disableModification) return;
    saveScrollPosition && saveScrollPosition();

    if (isAnotherOrderContext) {
      setCartContextInfo({ isAnotherOrderContext, reason });
    } else {
      if (onlyOneCartItemLeft) {
        dispatch(cleanCart());
        return;
      }

      if (portionsOrModifiers && cartMenuItems?.length > 1 && pageType !== PAGE_TYPES.cart) {
        const removePopup = buildPopupForRemoveCustomizableItems({
          label,
          setPopup,
          redirectToCart,
        });
        setPopup(removePopup);
      } else {
        decrementItem({ cartMenuItems, dispatch });
      }
    }
  }, [
    cartMenuItems,
    disableModification,
    saveScrollPosition,
    isAnotherOrderContext,
    reason,
    label,
    onlyOneCartItemLeft,
    portionsOrModifiers,
    pageType,
    redirectToCart,
    dispatch,
  ]);

  const handleAddToCart = useCallback(() => {
    const isValid = handleValidation != null ? handleValidation() : true;

    if (isValid) increment();
  }, [handleValidation, increment]);

  const handleRedirectToCart = useCallback(() => {
    history.push(pagePaths.Cart);
  }, [history]);

  const isDecrementDisabled = (grayOutDecrement || disableModification) && !isCartOlderThanToday();
  const isIncrementDisabled =
    (grayOutIncrement || disableModification || disableIncrement) && !isCartOlderThanToday();

  const ariaLabel = portionsOrModifiers
    ? `${label('Ref: Customize')} ${menuItem?.name}`
    : `${label('Ref: Add')} ${menuItem?.name}`;

  const quantitySetter = (displayBig: boolean) =>
    isItemNotInCart ? (
      <Button
        affix={!portionsOrModifiers ? PlusSignIcon : ''}
        onClick={() => {
          increment();
          onAddOrQuantityButtonClick && onAddOrQuantityButtonClick();
        }}
        size={SIZE.SMALL}
        disabled={disableModification}
        fullWidth
        isMultiline={false}
        data-testid={`menu-cart-actions-add-to-cart-button-${menuItem?.menuItemId}`}
        contentCenterAlign
        aria-label={ariaLabel}
      >
        {portionsOrModifiers ? label('Ref: Customize') : label('Ref: Add')}
      </Button>
    ) : (
      <>
        <QuantitySetter
          data-testid="menu-cart-actions-quantity-setter"
          value={productQuantity}
          increment={increment}
          decrement={decrement}
          preventCallback={disableChange}
          min={0}
          displayInline
          showOnlyParentValue
          displayBig={displayBig}
          disableDecrement={isDecrementDisabled}
          disableIncrement={isIncrementDisabled}
          showDecrementAtMinQuantity={
            pageType === PageType.productsList || pageType === PageType.scanner
          }
          itemName={menuItem?.name}
          increaseLabel={label('add one')}
          decreaseLabel={label('remove one')}
          onAddOrQuantityButtonClick={onAddOrQuantityButtonClick}
        />
        <div aria-live="polite">
          <p className="sr-only">
            {label('Ref: Products in cart', {
              replace: {
                product_name: menuItem?.name,
                product_quantity: (productQuantity || 0).toString(),
              },
            })}
          </p>
        </div>
      </>
    );

  const priceLabel = discountPrice
    ? getPrice(totalPrice - discountPrice, languageCode, isoCode)
    : totalPriceLabel;

  const priceLabelToDisplay = redeemableQuantity > 0 ? label('Ref: Free') : priceLabel;

  return (
    <div>
      {cartContextInfo && (
        <CartAnotherContextPopup
          cartDifferentContextType={cartContextInfo.reason}
          menuId={menuId}
          menuDate={date}
          menuItem={menuItem}
          onDismiss={() => setCartContextInfo(undefined)}
          data-testid="cart-another-context-popup"
        ></CartAnotherContextPopup>
      )}
      {popup && (
        <Alert
          isOpen={true}
          onDismiss={popup.onDismiss}
          className="popup-warning"
          header={popup.header}
          message={popup.message}
          buttons={popup.buttons}
          data-testid="menu-cart-actions-popup-warning"
        />
      )}

      {(components.quantitySetter && !components.viewCartButton && quantitySetter(false)) ||
        (isSuggestions && quantitySetter(false))}

      {components.addToCartButton && !disableIncrement && !isSuggestions && (
        <Button
          fullWidth
          onClick={handleAddToCart}
          loading={isLoading}
          data-testid={`menu-cart-actions-cart-add-${menuItem?.menuItemId}`}
        >
          <div>
            {label('Ref: add to cart')} - {priceLabelToDisplay}
          </div>
        </Button>
      )}
      {components.viewCartButton && currentSiteIdIsCartSiteId && !isSuggestions && (
        <div className={classNames(styles.cartButtonContainer)}>
          {components.quantitySetter && (
            <div className={classNames(styles.quantitySetterInlineContainer)}>
              {quantitySetter(true)}
            </div>
          )}
          <Button
            affix={CartIcon}
            onClick={handleRedirectToCart}
            loading={disableModification}
            data-testid={`menu-cart-actions-cart-redirect-${menuItem?.menuItemId}`}
            fullWidth
            contentCenterAlign
          >
            <PriceWithDiscount
              discountPrice={
                pageType === PAGE_TYPES.productDetails
                  ? totalPrice - (discountPrice as number) * productQuantity
                  : cartTotalWithDiscount
              }
              originalPrice={
                pageType === PAGE_TYPES.productDetails ? totalPrice : cartTotalWithoutDiscount
              }
              languageCode={languageCode}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default MenuCartActions;
