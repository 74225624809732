const labels = {
  All: {
    'Ref: Redeem coffee description': 'Din gratis, nybryggda kopp kaffe väntar',
    'Ref: Congratulations': 'Gratulation',
    'Ref: Get free coffee': 'Få gratis kaffe!',
    'Ref: Free coffee description': 'Fyll din kopp, tjäna poäng mot ett gratis kaffe.',
    'Ref: Redeem': 'Lösa in!',
  },
  StampPage: {
    'Ref: Page title': 'Gratis kaffe',
    'Ref: seconds left': 'sekunder kvar',
    'Ref: How it works': 'Hur det fungerar',
    'Ref: How it works description':
      'Varje gång du köper ett kaffe trycker du på skanningsknappen och skannar den angivna QR -koden i kassan.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Skanna QR kod',
    'Ref: Collect reward': 'Samla belöning',
    'Ref: Reward collected': 'Belöning insamlad',
    'Ref: Invalid QR Code': 'Ogiltig QR -kod',
    'Ref: Invalid QR Code description': 'QR -koden känns inte igen',
    'Ref: Close': 'Stänga',
    'Ref: Redeem button': 'Lösa in',
    "Ref: The QR Code isn't recognized": 'QR -koden känns inte igen',
    'Ref: Cancel': 'Avbryt',
    'Ref: Bite': 'Bita',
    'Ref: Congratulations modal title': 'Grattis!',
    'Ref: Confirm redeem description':
      'Säker på att du vill använda det här? Om så är fallet har du bara 60 sekunder att visa din telefon vid kassan och slutföra ditt köp',
    'Ref: Congratulations modal description': 'Ta tag i ditt gratis kaffe nu!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Gratis kaffe!', 'Ref: See All': 'Se allt' },
};
export default labels;
