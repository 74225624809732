import { RangeValue } from '@ionic/core';

export type SubmitResult = {
  ok: boolean;
};

export type FormState = {
  [question: number]: { answer?: Answer; type: string | undefined; required?: boolean };
};

export type FormAction = {
  type: string;
  question?: number;
  answer?: Answer;
  questionType?: string;
  required?: boolean;
  initialState?: FormState;
};

export type Answer = number | string | RangeValue | undefined;

export type Answers = { surveyQuestionId: number; answer: Answer }[];

export enum PATH_REVIEW_TYPES {
  MENU_ITEM = 'menuItem',
  FACILITY = 'facility',
  OUTLET = 'Outlet',
}

export enum REDIRECTED_PAGES {
  FACILITIES = 'Facilities',
  FACILITY_DETAILS = 'FacilityDetails',
  PRODUCT_DETAILS = 'ProductDetails',
  FEEDBACK = 'Feedback',
}

export enum SURVEY_TYPES {
  OUTLET = 'Outlet',
  FOOD_ITEM = 'FoodItem',
}

export interface ReviewUrlParams {
  reviewType: PATH_REVIEW_TYPES;
  facilityId: string;
  menuId: string;
  foodItemId: string;
}

export interface SubmitSurvey {
  surveyId: number;
  surveyType: string;
  siteId: string;
  facilityId: string;
  menuId: number;
  foodItemId: number;
  questionResponses: Answers;
  contacts: { email: string };
}

export enum REVIEW_QUESTION_TYPES {
  STARS = 'Emoji',
  SLIDER = 'CountableSlider',
  TEXT = 'FullText',
  LINE_TEXT = 'OneRowTextArea',
  TWO_LINE_TEXT = 'TwoRowTextArea',
  THREE_LINE_TEXT = 'ThreeRowTextArea',
  SLIDER_1_TO_5 = 'CountableSlider1to5',
  STARS_1_TO_5 = 'Stars1to5',
}
