const labels = {
  All: {
    'Ref: Assembled': 'Sammansatt',
    'Ref: Submitted': 'Lämnats',
    'Ref: Fulfilled': 'Uppfylld',
    'Ref: Completed': 'Avslutad',
    'Ref: Paid': 'Betalad',
    'Ref: Approved': 'Godkänd',
    'Ref: Ready For Pickup': 'Redo för upphämtning',
    'Ref: Delivered': 'Levererad',
    'Ref: Received': 'Mottagen',
    'Ref: Rejected': 'avvisade',
    'Ref: Payment Error': 'Betalningsfel',
    'Ref: Pending Payment': 'I väntan på betalning',
    'Ref: Preparing': 'Förberedande',
    'Ref: Refunded': 'Återbetalad',
    'Ref: Cancelled By User': 'Avbruten av användaren',
    'Ref: Refund Requested': 'Återbetalning begärs',
    'Ref: Refund Failed': 'Återbetalning misslyckades',
    'Ref: Payment Processing': 'Betalningen behandlas',
    'Ref: Not Paid': 'Inte betalad',
    'Ref: Total:': 'Total:',
    'Ref: Pickup': 'Plocka upp',
    'Ref: Eat-in': 'Ätte',
    'Ref: Scan&Go': 'Skanning & gå',
    'Ref: Fulfillment Type': 'Uppfyllningstyp',
    'Ref: Delivery': 'Leverans',
    'Ref: You have no future orders': 'Du har inga framtida beställningar',
    'Ref: You have no past orders': 'Du har inga tidigare beställningar',
    'Ref: You have no orders': 'Du har inga beställningar',
    'Ref: All orders': 'Alla beställningar',
    'Ref: Not past orders': 'Kommande beställningar',
    'Ref: Past orders': 'Tidigare beställningar',
    'Ref: Vegan': 'Vegan-',
    'Ref: Vegeterian': 'Vegetarian',
    'Ref: See item': 'Se objekt',
    'Ref: Activated': 'Aktiverad',
    'Ref: No suggestions': 'Inga förslag hittades',
    'Ref: Get your receipt': 'Få ditt kvitto',
    'Ref: Print': 'Skriv ut ditt kvitto',
    'Ref: Email': 'Få ditt kvitto via e-post',
    'Ref: Receipt Modal message': 'Hur vill du få ditt kvitto?',
    'Ref: Confirm': 'Bekräfta',
    'Ref: Your email address': 'din e-postadress',
    Suggestion: 'Förslag',
    'Ref: Loyalty Reward': 'Lojalitetsbelöning',
    'Ref: Equation for stamps per item': '1 köp = 1 stämpel',
    'Ref: Paid by employer': 'Det belopp som din arbetsgivare betalar',
    'Ref: Paid by user': 'Det belopp som du betalar',
  },
  ProductsList: {
    'Ref: Page title': 'Beställ',
    'Ref: Facility details': 'Anläggningsinformation',
    'Ref: Search products list': 'Sök',
    'Ref: filter label: menus': 'Meny',
    'Ref: filter label: moment': 'Ögonblick',
    'Ref: filter label: category': 'Kategori',
    'Ref: all': 'Allt',
    'Ref: today': 'I dag',
    'Ref: tomorrow': 'I morgon',
    'Ref: see more': 'Se mer',
    'Ref: see less': 'Se mindre',
    'Ref: reset': 'Återställa',
    'Ref: View in cart': 'Se i varukorg',
    'Ref: You have changed location': 'Du har ändrat plats',
    'Ref: You are now at': 'Du är nu på',
    'Ref: Menu card': 'Menykort',
    'Ref: Table': 'Bordsnummer',
    'Service unavailable': 'Service inte tillgänglig',
    'Ref: Ordering unavailable':
      'Försiktig! Att beställa på denna webbplats är inte tillgänglig just nu, försök senare.',
    'Ref: Filters': 'Filter (allergener och mer)',
  },
  ProductScanner: {
    'Ref: cart': 'Varukorg',
    'Ref: Product Scanned':
      'lades till vagnen. Du kan fortsätta att skanna streckkoderna för att lägga till dem i din vagn.',
    'Ref: Scan or adjust': 'lades till vagnen. Justera kvantitet eller skanna en annan produkt.',
    'Ref: Product Not Matched': 'Streckkod känns inte igen. Var god försök igen',
    'Ref: Scan article code': 'Streckkod',
    'Ref: Native error product cannot be added to cart':
      'Objekt från olika menyer eller dagsdelar kan inte läggas till i vagnen',
    'Ref: product scanner placeholder':
      'Vänligen skanna streckkoden för att lägga till den i din kundvagn',
  },
  Order: { 'Ref: Page title': 'Beställ' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menyer',
    'Ref: today': 'I dag',
    'Ref: tomorrow': 'I morgon',
    'Ref: Search products list': 'Sökartiklar',
    'Ref: You have changed location': 'Du har ändrat plats',
    'Ref: You are now at': 'Du är nu på',
  },
  ProductDetails: {
    'Ref: Page title': 'Produktinformation',
    'Ref: Serving Size': 'Serveringsstorlek',
    'Ref: Nutrition': 'Näringsdeklaration',
    'Ref: Nutrition adults': 'Vuxna behöver cirka 2000 kcal per dag',
    'Ref: Nutrition Calories': 'Kalorier',
    'Ref: Nutrition Calcium': 'Kalcium',
    'Ref: Nutrition Fat': 'Fett',
    'Ref: Nutrition Saturated Fat': 'Mättat fett',
    'Ref: Nutrition Carbohydrates': 'Kolhydrater',
    'Ref: Nutrition Sugar': 'Socker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Kostfiber',
    'Ref: Nutrition iron': 'Järn',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A',
    'Ref: Nutrition vitaminC': 'C-vitamin',
    'Ref: Additives': 'Tillsatser',
    'Ref: Emission': 'Koldioxidutsläpp',
    'Ref: This product generates': 'Denna produkt genererar',
    'Ref: of CO2': 'av CO2',
    'Ref: emissions, which equals a': 'utsläpp, vilket är lika med en',
    'Ref: car trip': 'bilresa',
    'Ref: Ingredients': 'Ingredienser',
    'Ref: Allergens': 'Allergener',
    'Ref: Always there': 'Alltid där',
    'Ref: Allergens selected': 'Allergener valt på filtret:',
    'Ref: minimum required': 'Minsta krävs',
    'Ref: you have to add at least': 'Du måste lägga till åtminstone',
    'Ref: ingredients': 'Ingredienser',
    'Ref: Ingredient': 'ingrediens',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Baserat på din anpassning',
    'Ref: Additives eggs': 'ägg',
    'Ref: Additives fish': 'fisk',
    'Ref: Additives milk': 'mjölk',
    'Ref: Additives nuts': 'nötter',
    'Ref: Additives wheat': 'vete',
    'Ref: Additives peanuts': 'jordnötter',
    'Ref: Additives soy beans': 'sojabönor',
    'Ref: Additives sesame seeds': 'sesamfrön',
    'Ref: Review': 'Recension',
    'Ref: Per portion': 'Per portion',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergen vald på sökfilter',
    'Ref: Fitbit Log': 'Logga till Fitbit',
    'Ref: age verification required on delivery': 'Ålderverifiering krävs vid leveransen',
    'Ref: alcohol content notification':
      'Genom att placera och beställa för en av dessa artiklar förklarar du att du är 18 år eller äldre. Alkoholmissbruk är farligt för din hälsa.',
    'Ref: default': 'Standard',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} tjänat om du beställer den här produkten',
    'Ref: Loyalty Card Description':
      '{stamp_form} kommer att läggas till följande lojalitet {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Programmet för lojalitetsstämplar låter dig samla frimärken för belöningar.',
    'Ref: Loyalty Modal Description':
      'När du köper <b> {product_name} kommer 1 stämpel </b> att läggas till <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'och',
    'Ref: Stamp': 'Stämpel',
    'Ref: Stamps': 'Stämplar',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Program',
    'Ref: Loyalty Stamps': 'Lojalitetsstämplar',
    'Ref: See Loyalty Stamps': 'Se lojalitetsstämplar',
    Favorite: 'Favorit',
    'Ref: In favorites': 'Produkten finns redan i favoritlistan. Klicka för att ta bort det.',
    'Ref: Not in favorites':
      'Produkten finns ännu inte i favoritlistan. Klicka för att lägga till det.',
    'Ref: Added to favorites': 'Produkten har lagts till i favoritlistan.',
    'Ref: Removed from favorites': 'Produkten har tagits bort från favoritlistan.',
    'Ref: From': 'Från',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Ändring i Varukorg',
    'Ref: Popup header for customizable item qty update': 'Denna artikel är anpassningsbar',
    'Ref: add new version': 'Lägg till ny version',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Uppdatera mängden av ett anpassningsbart objekt direkt från din kundvagn.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Uppdatera mängden av ett anpassningsbart objekt direkt från din kundvagn, eller lägg till en ny version.',
    'Ref: another context body':
      'För att lägga till objekt från denna webbplats, anläggning, ögonblick/meny eller datum måste du rensa vagnen',
    'Ref: cancel': 'Avbryt',
    'Ref: add to cart': 'Lägg till i varukorg',
    'Ref: view in cart': 'Varukorg',
    'Ref: Products in cart': 'Antal {product_name} i vagn: {product_quantity}',
    'Ref: Customize': 'Anpassa',
    'Ref: Add': 'Lägg till',
    'Ref: Free': 'Fri',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Avbryt',
    'Ref: notice': 'Notera',
    'Ref: clear cart': 'Rensad varukorg',
    'Ref: Cart addition error message':
      'För närvarande har du föremål i vagn från {from_current}. För att lägga till objekt från {from_selected} måste du rensa vagnen',
  },
  Cart: {
    'Ref: Page title': 'Varukorg',
    'Ref: The cart is empty': 'Varukorgen är tom',
    'Ref: Clear cart': 'Rensad varukorg',
    'Ref: Continue shopping': 'Fortsätt handla',
    'Ref: Start shopping': 'Börja shoppa',
    'Ref: Today': 'I dag',
    'Ref: Tomorrow': 'I morgon',
    'Ref: Tax:': 'Moms:',
    'Ref: Promotion:': 'Kampanj:',
    'Ref: Total without promotion:': 'Totalt utan kampanj:',
    'Ref: Delivery:': 'Leverans:',
    'Ref: Pickup informations': 'Hämtningsinformation',
    'Ref: Location': 'Plats',
    'Ref: Time': 'Tid',
    'Ref: Phone': 'Ditt telefonnummer',
    'Ref: Instructions': 'Instruktioner',
    'Ref: Please select': 'Vänligen välj',
    'Ref: Enter special requests': 'Ange specialförfrågningar här',
    'Ref: notice': 'Notera',
    'Ref: Product is no more available':
      'Ledsen men det verkar som om någon produkt inte är mer tillgänglig. Kontrollera din kundvagn.',
    'Ref: ok': 'Ok',
    'Ref: Validate': 'Validera min beställning',
    'Ref: Pay now': 'Betala nu',
    'Ref: Charge to my department': 'Debitera min avdelning',
    'Ref: Pay on pickup': 'Betala för dessa artiklar vid hämtningen',
    'Ref: Accept terms of sale prefix': 'jag accepterar',
    'Ref: Accept terms of sale': 'Dessa försäljningsvillkor',
    'Ref: Continue': 'Fortsätta',
    'Ref: Please login or sign up to place an order':
      'Logga in eller registrera dig för att göra en beställning',
    'Ref: Accept terms of sale suffix': ', och jag har läst och förstått dem.',
    'Ref: Payment modality': 'Betalningsmetod',
    'Ref: Other information': 'Övrig information',
    'Ref: Eat-in': 'Äta här',
    'Ref: Pickup': 'Pickup',
    'Ref: Table': 'Bordsnummer',
    'Ref: Required fields': 'Obligatoriska fält',
    'Service unavailable': 'Service inte tillgänglig',
    'Ref: Cannot order':
      'Tyvärr kan du inte göra en beställning på den här webbplatsen just nu. Kontakta restaurangen för mer information om öppettider och tillgängliga tjänster.',
    'Ref: Order Unavailable':
      'Beställ för det valda datumet, uppfyllande typ och plats är inte tillgänglig längre!',
    'Ref: No Availability': 'Inget mer tillgänglighet',
    'Ref: Continue on your device': 'Fortsätt på din enhet',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} tjänat om du slutför din beställning',
    'Ref: Loyalty Card Description':
      '{stamp_form} kommer att läggas till följande lojalitet {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Programmet för lojalitetsstämplar låter dig samla frimärken för belöningar.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> tjänat om du slutför din beställning:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> tillagd för <B> {scheme_names} </b>',
    'Ref: Stamp': 'Stämpel',
    'Ref: Stamps': 'Stämplar',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Program',
    'Ref: Loyalty Stamps': 'Lojalitetsstämplar',
    'Ref: See Loyalty Stamps': 'Se lojalitetsstämplar',
    'Ref: Loyalty Info': 'Lojalitetsinfo',
    'Ref: Email address': 'E-postadress',
    'Ref: and': 'och',
    'Ref: Order minimum amount': 'Tyvärr, lägsta betalningsbelopp är {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Fortsätt på din enhet',
    'Ref: Scan QR to continue': 'Snälla, skanna QR -koden för att fortsätta resan på din enhet.',
  },
  OrderHistory: { 'Ref: Orders': 'Order', 'Ref: Page Title': 'Mina beställningar' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Enhetspris',
    'Ref: Download': 'Ladda ner kvittot',
    'Ref: Download error': 'Ett nedladdningsfel har inträffat. Försök att ladda ner kvittot senare',
    'Ref: Order details title': 'Orderdetaljer',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Tack! Din beställning bekräftas nu',
    'Ref: Text for pickup':
      'Du kan följa dess status genom din orderhistorik. Om dina aviseringar är påslagen kommer vi att uppdatera dig när den är redo för hämtning.',
    'Ref: Success Order Title': 'Tack för din beställning',
    'Ref: Success Order Description':
      'Vänligen betala vid kassan för att validera din beställning.',
    'Ref: Success Order ID': 'ID -beställning',
    'Ref: Success Order Action': 'Ny order',
    'Ref: Text for dinein':
      'Din beställning bör snart serveras till ditt bord. Fråga bara någon av våra vänliga anställda om din ätbeställning.',
    'Ref: Text for scan&go': 'Din beställning är tillgänglig i din beställningshistorik',
    'Ref: Text for delivery':
      'Du kan spåra dess status i din orderhistorik. Om dina aviseringar är aktiverade kommer vi att informera dig när din beställning är redo.',
    'See details': 'Se detaljer',
    'Go to home': 'Gå till startsidan',
  },
  FailOrder: {
    'Go to home': 'Gå till startsidan',
    'Ref: Kiosk Fail Title': 'hoppsan! Något gick fel!',
    'Ref: Kiosk Fail Description':
      'Tyvärr var din beställning inte framgångsrik. Försök igen eller be en anställd att hjälpa dig.',
    'Ref: Kiosk Fail Back': 'Tillbaka till föregående sida',
    'Ref: Kiosk Fail Action': 'Rensa sessionen och beställ igen',
    'Ref: Error in cart validation':
      'Ett fel inträffade under din beställning. Kontakta anläggningen för att lösa problemet.',
    'Facility contact': 'Kontakt',
    'Ref: Ordering failed': 'Beställning misslyckades',
    'Ref: Order confirmed': 'Beställning bekräftad',
  },
  TableCheckIn: {
    'Welcome at': 'Välkommen kl',
    Table: 'Tabell:',
    'Ref: list title': 'Nya beställningar för idag',
    'See the menu': 'Se menyn',
    'Table Check-in': 'Bordscheck-in',
    'Ref #': 'Ref #',
    Checked: 'Kontrollerade',
    'Check in': 'Checka in',
    'See details': 'Se detaljer',
    'Ref: You have changed location': 'Du har ändrat plats',
    'Ref: You are now at': 'Du är nu på',
    'Order number': 'Ordernummer',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Kampanj använd',
    'Ref: Promotion applied description': 'Du använder en kampanj från din varukorg.',
    'Ref: Promotion deleted title': 'Kampanj raderad',
    'Ref: Promotion deleted description':
      'Du gynnar inte längre kampanjen eftersom du inte längre fyller villkoren eller att kampanjen har löpt ut.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Skanning & gå', 'Ref: View only': 'Titta enbart' },
  ProductTile: {
    'Ref: Allergens': 'Allergener',
    'Ref: Presence of allergen': 'Närvaro av allergen',
    'Ref: Scan': 'Skanna',
    'Ref: From': 'Från',
    'Ref: Already in product favorite list. Click to remove it.':
      'Redan i produktens favoritlista. Klicka för att ta bort det.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'För närvarande inte läggs till i produktens favoritlista. Klicka för att lägga till det.',
    'Ref: Free': 'Fri',
  },
  OrderSummary: {
    'Ref: item': 'Artikel',
    'Ref: items': 'föremål',
    'Ref: Clear': 'Klar',
    'Ref: Cart': 'Vagn',
    'Ref: Subtotal': 'Delsumma',
    'Ref: Total': 'Total',
    'Ref: Tax': 'Beskatta',
    'Ref: Promotion': 'Befordran',
    'Ref: Total without promotion': 'Totalt utan marknadsföring',
    'Ref: Number of product in cart': 'Antal produkt i kundvagnen',
    'Ref: Delivery': 'Leverans',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegan-', 'Ref: Vegeterian': 'Vegetarian' },
  OrderInfoCard: {
    'Ref: Header': 'Beställd',
    'Ref: from': 'från',
    'Ref: location': 'Plats',
    'Ref: Phone': 'Ditt telefonnummer',
    'Ref: Instructions': 'Instruktioner',
    'Ref: Order type': 'Ordertyp',
    'Ref: Pickup': 'Plocka upp',
    'Ref: Eat-in': 'Ätte',
    'Ref: Scan&Go': 'Skanning & gå',
  },
  ReorderWidget: {
    'Ref: No items': 'Inga beställningar görs ännu. Shoppa nu!',
    'Ref: Title': 'Beställa igen',
  },
  MenusWidget: {
    'Ref: no menu': 'Ingen meny för tillfället',
    'Ref: Modal title': 'Meny för dagen',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Rabatter tillgängliga' },
  PromotionInfoItem: { 'Ref: Activate': 'Aktivera' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Dina beställningar',
    'Ref: Widget Title': 'Mina beställningar',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Dagens förslag',
    'Ref: Any menu': 'Valfri meny',
    Other: 'Övrig',
    'Ref: Suggestions calendar button': 'Förslagskalender',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Serveringsstorlek',
    'Ref: Nutrition': 'Näringsdeklaration',
    'Ref: Nutrition adults': 'Vuxna behöver cirka 2000 kcal per dag',
    'Ref: Nutrition Calories': 'Kalorier',
    'Ref: Nutrition Calcium': 'Kalcium',
    'Ref: Nutrition Fat': 'Fett',
    'Ref: Nutrition Saturated Fat': 'Mättat fett',
    'Ref: Nutrition Carbohydrates': 'Kolhydrater',
    'Ref: Nutrition Sugar': 'Socker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Kostfiber',
    'Ref: Nutrition iron': 'Järn',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A',
    'Ref: Nutrition vitaminC': 'C-vitamin',
    'Ref: Ingredients': 'Ingredienser',
    'Ref: Allergens': 'Allergener',
    'Ref: Always there': 'Alltid där',
    'Ref: Allergens selected': 'Allergener valt på filtret:',
    'Ref: minimum required': 'Minsta krävs',
    'Ref: ingredients': 'Ingredienser',
    'Ref: Ingredient': 'ingrediens',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Baserat på din anpassning',
    'Ref: Additives eggs': 'ägg',
    'Ref: Additives fish': 'fisk',
    'Ref: Additives milk': 'mjölk',
    'Ref: Additives nuts': 'nötter',
    'Ref: Additives wheat': 'vete',
    'Ref: Additives peanuts': 'jordnötter',
    'Ref: Additives soy beans': 'sojabönor',
    'Ref: Additives sesame seeds': 'sesamfrön',
    'Ref: Review': 'Recension',
    'Ref: Per portion': 'Per portion',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} gratis',
    'Ref: Number of free items': 'Antal gratis föremål: {number_of_free_items}',
    'Ref: Allergen selected': 'Allergen vald på sökfilter',
    'Ref: Nutritional info': 'Näringsinformation',
    'Ref: Info': 'Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Befordringar' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Välj ditt jobb',
    'Ref: Validate': 'Bekräfta',
    'Ref: Pricing Tiers dialog message': 'Du har blivit erkänd med din e -postadress',
    'Ref: Please select your job in the company': 'Välj ditt jobb i företaget',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Plocka upp',
    'Ref: Eat-in': 'Ätte',
    'Ref: Scan&Go': 'Skanning & gå',
    'Ref: Delivery': 'Leverans',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Hur var {facility_name} ´s tjänst?',
    'Ref: How do you rate the service': 'Hur betygsätter du tjänsten?',
  },
};
export default labels;
