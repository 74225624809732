import { cleanup, screen, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { FacilityType } from '../../types/types';

import FacilityTile from './FacilityTile';

import { renderedComponent } from '@/helpers/tests/renderComponent';
import { SERVICE } from '@/modules/config';

const initialState = {
  Core: {
    services: {
      list: [],
    },
    user: {
      useDataTracking: true,
    },
  },
  Shared: { language: 'en-GB' },
};

const props = {
  id: 'facility-1',
  title: 'Facility Title',
  imageSrc: { src: 'test-image.jpg', alt: 'test-alt' },
  facilityType: FacilityType.FoodRetail,
  reviewableFacilities: ['facility-1'],
  label: (key: string) => key,
};

const initialHistory = createMemoryHistory();

describe('FacilityTile', () => {
  afterAll(() => cleanup());

  const setup = ({
    storeState = initialState,
    history = createMemoryHistory(),
    properties = props,
  }: {
    storeState?: any;
    history?: any;
    properties?: any;
  }) => {
    return renderedComponent(FacilityTile, properties, storeState, history);
  };

  describe('rendering facility tile', () => {
    setup({});

    it('should display the tile with the correct title', () => {
      const titleElement = screen.getByText('Facility Title');
      expect(titleElement).toBeTruthy();
    });

    it('should display the see the offering action when facilityType is not NonFoodNonRetail', () => {
      setup({});
      const actionElement = screen.getByLabelText(`see the offering`);
      expect(actionElement).toBeInTheDocument();
    });

    it('should not display the see the offering action when facilityType is NonFoodNonRetail', () => {
      setup({
        storeState: initialState,
        history: initialHistory,
        properties: {
          ...props,
          facilityType: FacilityType.NonFoodNonRetail,
        },
      });

      const actionElement = screen.queryByLabelText('see the offering');
      expect(actionElement).not.toBeInTheDocument();
    });

    it('should display the review action when feedback is enabled and facility is mapped', () => {
      const updatedState = {
        ...initialState,
        Core: {
          ...initialState.Core,
          services: {
            list: [
              { name: SERVICE.REVIEW, setupOptions: [{ name: 'facilityFeedback', value: 'true' }] },
            ],
          },
        },
      };
      setup({ storeState: updatedState as any });
      const reviewElement = screen.getByLabelText('Review');
      expect(reviewElement).toBeInTheDocument();
    });

    it('should not display the review action when feedback is not enabled', () => {
      const updatedState = {
        ...initialState,
        Core: {
          ...initialState.Core,
          services: {
            list: [],
          },
        },
      };
      setup(updatedState as any);
      const reviewElement = screen.queryByLabelText('Review');

      expect(reviewElement).not.toBeInTheDocument();
    });
  });

  describe('clicking on the tile', () => {
    it('should navigate to facility details page', () => {
      const history = createMemoryHistory();
      const { getByTestId } = setup({ storeState: initialState, history });
      const tileElement = getByTestId(`facility-tile-${props.id}`);

      fireEvent.click(tileElement);

      expect(history.location.pathname).toContain(`${props.id}/details`);
    });

    it('should navigate to review page when review action is clicked', async () => {
      const updatedState = {
        ...initialState,
        Core: {
          ...initialState.Core,
          services: {
            list: [
              { name: SERVICE.REVIEW, setupOptions: [{ name: 'facilityFeedback', value: 'true' }] },
            ],
          },
        },
      };

      const history = createMemoryHistory();
      setup({ storeState: updatedState as any, history });
      const reviewElement = screen.getByLabelText('Review');

      await fireEvent.click(reviewElement);

      expect(history.location.pathname).toBe(`/review/Outlet/${props.id}`);
    });

    it('should navigate to offering page when see the offering action is clicked', async () => {
      const history = createMemoryHistory();
      setup({ storeState: initialState, history });
      const actionElement = screen.getByLabelText(`see the offering`);

      await fireEvent.click(actionElement);

      expect(history.location.pathname).toBe('/order');
    });

    it('should navigate to /shop offering page when see the offering action is clicked and shop module active', async () => {
      const history = createMemoryHistory();
      const updatedState = {
        ...initialState,
        Core: {
          ...initialState.Core,
          services: {
            list: [{ name: SERVICE.FOOD_MENUS }],
          },
        },
      };

      setup({
        storeState: updatedState as any,
        history,
        properties: { ...props, facilityType: FacilityType.FoodNonRetail },
      });
      const actionElement = screen.getByLabelText(`see the offering`);

      await fireEvent.click(actionElement);

      expect(history.location.pathname).toBe('/shop');
    });

    it('should navigate to /menu offering page when see the offering action is clicked and shop module not active', async () => {
      const history = createMemoryHistory();
      const updatedState = {
        ...initialState,
        Core: {
          ...initialState.Core,
          services: {
            list: [],
          },
        },
      };

      setup({
        storeState: updatedState as any,
        history,
        properties: { ...props, facilityType: FacilityType.FoodNonRetail },
      });
      const actionElement = screen.getByLabelText(`see the offering`);

      await fireEvent.click(actionElement);

      expect(history.location.pathname).toBe('/menu');
    });
  });
});
