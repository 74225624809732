import {
  ThankYouDeliveryIllustration,
  ThankYouLocalIllustration,
  ThankYouPickupIllustration,
  ThankYouScanAndGoIllustration,
} from '../../../assets/illustrations';
import { DeliveryOptionType } from '../types/cart.types';

export const getThankYouLabel = (deliveryOption: DeliveryOptionType) => {
  switch (deliveryOption) {
    case DeliveryOptionType.PickupOption:
      return 'Ref: Text for pickup';
    case DeliveryOptionType.Delivery:
      return 'Ref: Text for delivery';
    case DeliveryOptionType.DineIn:
      return 'Ref: Text for dinein';
    case DeliveryOptionType.ScanAndGo:
      return 'Ref: Text for scan&go';
    default:
      throw Error('deliveryOption not supported');
  }
};

export const getThankYouImage = (deliveryOption: DeliveryOptionType) => {
  switch (deliveryOption) {
    case DeliveryOptionType.PickupOption:
      return <ThankYouPickupIllustration />;
    case DeliveryOptionType.Delivery:
      return <ThankYouDeliveryIllustration />;
    case DeliveryOptionType.DineIn:
      return <ThankYouLocalIllustration />;
    case DeliveryOptionType.ScanAndGo:
      return <ThankYouScanAndGoIllustration />;
    default:
      throw Error('deliveryOption not supported');
  }
};
