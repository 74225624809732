const labels = {
  All: { 'Ref: Page title': 'ספקים' },
  SuppliersList: {
    'Ref: Supplier details': 'ראה פרטים',
    'Missing supplier list': 'לא מצאנו שום ספק.',
    'Try again later': 'נסה שוב מאוחר יותר',
  },
  SupplierDetails: {
    'Address label': 'כתובת:',
    'Website label': 'אתר אינטרנט:',
    'Details label': 'פרטים',
  },
};
export default labels;
