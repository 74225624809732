import classNames from 'classnames';
import { ReactElement } from 'react';
import { useHistory } from 'react-router';

import { FaqIcon } from '../../../assets/icons';
import Button from '../../../components/atoms/Button';
import Card from '../../../components/molecules/Card/Card';
import InfoMessage from '../../../components/molecules/InfoMessage';
import List from '../../../components/organisms/List/List';
import { ListItemProps } from '../../../components/organisms/List/List.types';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import { useGetFaqQuery } from '../api';
import { FaqCategory } from '../api/api.types';
import { pagePaths } from '../config';
import { useFaqTranslation } from '../hooks/useFaqTranslation';

import { ServiceRequestIllustration } from '@/assets/illustrations';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import useSite from '@/modules/Core/hooks/useSite';

import styles from './Faq.module.css';

const FaqCategories = (): ReactElement => {
  const history = useHistory();
  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;
  const { data: { categories = [] } = {}, isLoading } = useGetFaqQuery(siteId);
  const { label } = useFaqTranslation(__filename);
  const hasCategories = categories.length > 0;

  const items: ListItemProps[] = categories.map((category: FaqCategory) => {
    return {
      id: category.faqCategoryId.toString(),
      label: category.name,
      path: pagePaths.FaqCategory.replace(':faqCategoryId', category.faqCategoryId.toString()),
      'data-testid': `faq-category-${category.faqCategoryId}`,
    };
  });

  if (isLoading) return <LoadingPage />;

  return (
    <SimpleFormPage
      hasBackLink={false}
      title={label('Ref: Page title')}
      actions={[]}
      actionsBarTopContent={null}
    >
      <Container>
        <Column.Main className={styles.mainColumn}>
          {hasCategories ? (
            <Card>
              <List data-testid="faq-list" items={items} />
            </Card>
          ) : (
            <div className={classNames(styles.infoMessageContainer)}>
              <InfoMessage
                title={label('Ref: Missing Faq')}
                icon={<FaqIcon />}
                button={
                  <Button
                    data-testid="faq-go-to-home"
                    look="secondary"
                    onClick={() => history.push('/')}
                  >
                    {label('Ref: Go to home page')}
                  </Button>
                }
              ></InfoMessage>
            </div>
          )}
        </Column.Main>
        <Column.Complementary>
          <ServiceRequestIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default FaqCategories;
