const labels = {
  All: {
    'Ref: Assembled': '조립',
    'Ref: Submitted': '제출된',
    'Ref: Fulfilled': '성취',
    'Ref: Completed': '완전한',
    'Ref: Paid': '유급의',
    'Ref: Approved': '승인',
    'Ref: Ready For Pickup': '픽업 준비',
    'Ref: Delivered': '배달',
    'Ref: Received': '받았다',
    'Ref: Rejected': '거부',
    'Ref: Payment Error': '지불 오류',
    'Ref: Pending Payment': '보류중인 지불',
    'Ref: Preparing': '준비',
    'Ref: Refunded': '환불',
    'Ref: Cancelled By User': '사용자가 취소했습니다',
    'Ref: Refund Requested': '요청 된 환불',
    'Ref: Refund Failed': '환불이 실패했습니다',
    'Ref: Payment Processing': '지불 처리',
    'Ref: Not Paid': '미 지불 상태의',
    'Ref: Total:': '총:',
    'Ref: Pickup': '찾다',
    'Ref: Eat-in': '식사',
    'Ref: Scan&Go': '스캔 및 이동',
    'Ref: Fulfillment Type': '이행 유형',
    'Ref: Delivery': '배달',
    'Ref: You have no future orders': '당신은 향후 주문이 없습니다',
    'Ref: You have no past orders': '과거의 주문이 없습니다',
    'Ref: You have no orders': '당신은 명령이 없습니다',
    'Ref: All orders': '모든 주문',
    'Ref: Not past orders': '다가오는 주문',
    'Ref: Past orders': '과거 주문',
    'Ref: Vegan': '채식주의 자의',
    'Ref: Vegeterian': '채식주의 자',
    'Ref: See item': '항목을 참조하십시오',
    'Ref: Activated': '활성화',
    'Ref: No suggestions': '제안이 없습니다',
    'Ref: Get your receipt': '영수증을 받으십시오',
    'Ref: Print': '영수증을 인쇄하십시오',
    'Ref: Email': '이메일로 영수증을 받으십시오',
    'Ref: Receipt Modal message': '영수증을 어떻게 받고 싶습니까?',
    'Ref: Confirm': '확인하다',
    'Ref: Your email address': '귀하의 이메일 주소',
    Suggestion: '제안',
    'Ref: Loyalty Reward': '충성도 보상',
    'Ref: Equation for stamps per item': '1 구매 = 1 스탬프',
    'Ref: Paid by employer': '고용주가 지불 한 금액',
    'Ref: Paid by user': '귀하가 지불 한 금액',
  },
  ProductsList: {
    'Ref: Page title': '상품 목록',
    'Ref: Facility details': '시설 세부 사항',
    'Ref: Search products list': '검색 항목 검색',
    'Ref: filter label: menus': '메뉴',
    'Ref: filter label: moment': '순간',
    'Ref: filter label: category': '범주',
    'Ref: all': '모두',
    'Ref: today': '오늘',
    'Ref: tomorrow': '내일',
    'Ref: see more': '더보기',
    'Ref: see less': '더 적은 것을 보아라',
    'Ref: reset': '초기화',
    'Ref: View in cart': '카트에서보기',
    'Ref: You have changed location': '위치가 변경되었습니다',
    'Ref: You are now at': '당신은 지금 있습니다',
    'Ref: Menu card': '메뉴 카드',
    'Ref: Table': '테이블 번호',
    'Service unavailable': '서비스를 사용할 수 없습니다',
    'Ref: Ordering unavailable':
      '주의 깊은! 이 사이트에서 주문하는 것은 현재 사용할 수 없습니다. 나중에 시도하십시오.',
    'Ref: Filters': '필터 (알레르겐 등)',
  },
  ProductScanner: {
    'Ref: cart': '카트',
    'Ref: Product Scanned':
      '카트에 추가되었습니다. 바코드를 계속 스캔하여 카트에 추가 할 수 있습니다.',
    'Ref: Scan or adjust': '카트에 추가되었습니다. 수량을 조정하거나 다른 제품을 스캔합니다.',
    'Ref: Product Not Matched': '바코드는 인식되지 않았습니다. 다시 시도하십시오',
    'Ref: Scan article code': '스캔 바코드',
    'Ref: Native error product cannot be added to cart':
      '다른 메뉴 또는 일일 부분의 항목은 카트에 추가 할 수 없습니다.',
    'Ref: product scanner placeholder': '바코드를 스캔하여 카트에 추가하십시오.',
  },
  Order: { 'Ref: Page title': '주문하다' },
  MenuSelector: {
    'Ref: Menu Page title': '메뉴',
    'Ref: today': '오늘',
    'Ref: tomorrow': '내일',
    'Ref: Search products list': '검색 항목 검색',
    'Ref: You have changed location': '위치가 변경되었습니다',
    'Ref: You are now at': '당신은 지금 있습니다',
  },
  ProductDetails: {
    'Ref: Page title': '제품 세부 정보',
    'Ref: Serving Size': '서빙 사이즈',
    'Ref: Nutrition': '영양 정보',
    'Ref: Nutrition adults': '성인은 하루에 2000kcal이 필요합니다',
    'Ref: Nutrition Calories': '칼로리',
    'Ref: Nutrition Calcium': '칼슘',
    'Ref: Nutrition Fat': '지방',
    'Ref: Nutrition Saturated Fat': '포화 지방',
    'Ref: Nutrition Carbohydrates': '탄수화물',
    'Ref: Nutrition Sugar': '설탕',
    'Ref: Nutrition Protein': '단백질',
    'Ref: Nutrition Sodium': '나트륨',
    'Ref: Nutrition cholesterol': '콜레스테롤',
    'Ref: Nutrition dietaryFiber': '식이 섬유',
    'Ref: Nutrition iron': '철',
    'Ref: Nutrition potassium': '칼륨',
    'Ref: Nutrition vitaminA': '비타민 A.',
    'Ref: Nutrition vitaminC': '비타민 C',
    'Ref: Additives': '첨가제',
    'Ref: Emission': 'CO2 배출량',
    'Ref: This product generates': '이 제품은 생성됩니다',
    'Ref: of CO2': '이산화탄소의',
    'Ref: emissions, which equals a': 'A.와 같는 배출량',
    'Ref: car trip': '자동차 여행',
    'Ref: Ingredients': '조리법 성분',
    'Ref: Allergens': '알레르기 항원',
    'Ref: Always there': '언제나 그곳에',
    'Ref: Allergens selected': '필터에서 선택한 알레르겐 :',
    'Ref: minimum required': '최소 필수',
    'Ref: you have to add at least': '적어도 추가해야합니다',
    'Ref: ingredients': '재료',
    'Ref: Ingredient': '재료',
    'Ref: Free': '무료',
    'Ref: Based on customization': '사용자 정의에 따라',
    'Ref: Additives eggs': '달걀',
    'Ref: Additives fish': '생선',
    'Ref: Additives milk': '우유',
    'Ref: Additives nuts': '견과류',
    'Ref: Additives wheat': '밀',
    'Ref: Additives peanuts': '땅콩',
    'Ref: Additives soy beans': '대두 콩',
    'Ref: Additives sesame seeds': '참깨',
    'Ref: Review': '검토',
    'Ref: Per portion': '부분 당',
    'Ref: Per 100g': '100g 당',
    'Ref: Allergen selected': '검색 필터에서 선택된 알레르겐',
    'Ref: Fitbit Log': 'Fitbit에 로그인',
    'Ref: age verification required on delivery': '배달에 필요한 연령 검증',
    'Ref: alcohol content notification':
      '이 항목들 중 하나를 배치하고 주문함으로써, 당신은 당신이 18 세 이상이라고 선언합니다. 알코올 남용은 건강에 위험합니다.',
    'Ref: default': '기본',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}이 제품을 주문하면 획득',
    'Ref: Loyalty Card Description':
      '{stamp_form}는 다음 충성도 {program_form} : <b> {scheme_names} </b>에 추가됩니다.',
    'Ref: About Loyalty stamps':
      '로열티 스탬프 프로그램을 사용하면 보상을 위해 우표를 수집 할 수 있습니다.',
    'Ref: Loyalty Modal Description':
      '<b> {product_name}를 구입하면 1 Stamp </b>가 <b> {scheme_names} </b> {program_form}에 추가됩니다.',
    'Ref: and': '그리고',
    'Ref: Stamp': '우표',
    'Ref: Stamps': '우표',
    'Ref: Program': '프로그램',
    'Ref: Programs': '프로그램들',
    'Ref: Loyalty Stamps': '로열티 스탬프',
    'Ref: See Loyalty Stamps': '로열티 스탬프를 참조하십시오',
    Favorite: '가장 좋아하는',
    'Ref: In favorites': '제품은 이미 즐겨 찾기 목록에 있습니다. 제거하려면 클릭하십시오.',
    'Ref: Not in favorites': '이 제품은 아직 즐겨 찾기 목록에 없습니다. 추가하려면 클릭하십시오.',
    'Ref: Added to favorites': '제품이 즐겨 찾기 목록에 추가되었습니다.',
    'Ref: Removed from favorites': '제품은 즐겨 찾기 목록에서 제거되었습니다.',
    'Ref: From': '에서',
  },
  MenuCartActions: {
    'Ref: change in cart': '카트의 변화',
    'Ref: Popup header for customizable item qty update': '이 항목은 사용자 정의 할 수 있습니다',
    'Ref: add new version': '새 버전을 추가하십시오',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      '카트에서 직접 사용자 정의 가능한 항목의 수량을 업데이트하십시오.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      '카트에서 직접 사용자 정의 가능한 항목의 수량을 업데이트하거나 새 버전을 추가하십시오.',
    'Ref: another context body':
      '이 사이트, 시설, 모멘트/메뉴 또는 날짜에서 항목을 추가하려면 카트를 지울 필요가 있습니다.',
    'Ref: cancel': '취소',
    'Ref: add to cart': '카트에 추가하십시오',
    'Ref: view in cart': '카트',
    'Ref: Products in cart': '카트에서 {product_name}의 번호 : {product_quantity}',
    'Ref: Customize': '사용자 정의하십시오',
    'Ref: Add': '추가하다',
    'Ref: Free': '무료',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': '취소',
    'Ref: notice': '알아채다',
    'Ref: clear cart': '명확한 카트',
    'Ref: Cart addition error message':
      '현재 {from_current}의 카트에 항목이 있습니다. {from_selected}에서 항목을 추가하려면 카트를 지우야합니다.',
  },
  Cart: {
    'Ref: Page title': '카트',
    'Ref: The cart is empty': '카트가 비어 있습니다',
    'Ref: Clear cart': '명확한 카트',
    'Ref: Continue shopping': '쇼핑을 계속',
    'Ref: Start shopping': '쇼핑을 시작하십시오',
    'Ref: Today': '오늘',
    'Ref: Tomorrow': '내일',
    'Ref: Tax:': '세:',
    'Ref: Promotion:': '홍보:',
    'Ref: Total without promotion:': '홍보없는 총 :',
    'Ref: Delivery:': '배달:',
    'Ref: Pickup informations': '픽업 정보',
    'Ref: Location': '위치',
    'Ref: Time': '시간',
    'Ref: Phone': '전화 번호',
    'Ref: Instructions': '지침',
    'Ref: Please select': '선택 해주세요',
    'Ref: Enter special requests': '여기에 특별 요청을 입력하십시오',
    'Ref: notice': '알아채다',
    'Ref: Product is no more available':
      '죄송하지만 일부 제품은 더 이상 사용할 수없는 것 같습니다. 카트를 확인하십시오.',
    'Ref: ok': '확인',
    'Ref: Validate': '내 주문을 확인하십시오',
    'Ref: Pay now': '지금 지불하세요',
    'Ref: Charge to my department': '내 부서에 청구',
    'Ref: Pay on pickup': '픽업시 이러한 품목에 대한 비용을 지불하십시오',
    'Ref: Accept terms of sale prefix': '동의 함',
    'Ref: Accept terms of sale': '이 판매 약관',
    'Ref: Continue': '계속하다',
    'Ref: Please login or sign up to place an order': '주문을 위해 로그인하거나 가입하십시오',
    'Ref: Accept terms of sale suffix': '그리고 나는 그것들을 읽고 이해했습니다.',
    'Ref: Payment modality': '지불 방법',
    'Ref: Other information': '기타 정보',
    'Ref: Eat-in': '식사',
    'Ref: Pickup': '찾다',
    'Ref: Table': '테이블 번호',
    'Ref: Required fields': '필수 입력 사항',
    'Service unavailable': '서비스를 사용할 수 없습니다',
    'Ref: Cannot order':
      '죄송합니다. 현재이 사이트에서 주문할 수 없습니다. 개장 시간 및 사용 가능한 서비스에 대한 자세한 내용은 식당에 문의하십시오.',
    'Ref: Order Unavailable': '선택한 날짜, 이행 유형 및 위치를 더 이상 사용할 수 없습니다!',
    'Ref: No Availability': '더 이상 가용성이 없습니다',
    'Ref: Continue on your device': '기기를 계속하십시오',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} 주문을 완료하면 획득',
    'Ref: Loyalty Card Description':
      '{stamp_form}는 다음 충성도 {program_form} : <b> {scheme_names} </b>에 추가됩니다.',
    'Ref: About Loyalty stamps':
      '로열티 스탬프 프로그램을 사용하면 보상을 위해 우표를 수집 할 수 있습니다.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> 주문을 완료하면 얻었습니다.',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> <b> {scheme_names} </b>에 추가',
    'Ref: Stamp': '우표',
    'Ref: Stamps': '우표',
    'Ref: Program': '프로그램',
    'Ref: Programs': '프로그램들',
    'Ref: Loyalty Stamps': '로열티 스탬프',
    'Ref: See Loyalty Stamps': '로열티 스탬프를 참조하십시오',
    'Ref: Loyalty Info': '충성도 정보',
    'Ref: Email address': '이메일 주소',
    'Ref: and': '그리고',
    'Ref: Order minimum amount': '죄송합니다. 최소 지불 금액은 {minimumAmount}입니다.',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': '기기를 계속하십시오',
    'Ref: Scan QR to continue': 'QR 코드를 스캔하여 장치의 여정을 계속하십시오.',
  },
  OrderHistory: { 'Ref: Orders': '명령', 'Ref: Page Title': '내 주문' },
  OrderHistoryDetails: {
    'Ref: Unit price': '단가',
    'Ref: Download': '영수증을 다운로드하십시오',
    'Ref: Download error': '다운로드 오류가 발생했습니다. 나중에 영수증을 다운로드하십시오',
    'Ref: Order details title': '주문 세부 사항',
  },
  SuccessOrder: {
    'Ref: Order confirmed': '고맙습니다! 주문이 확인되었습니다',
    'Ref: Text for pickup':
      '주문 기록을 통해 그 상태를 따를 수 있습니다. 알림이 켜지면 픽업 준비가되면 업데이트하겠습니다.',
    'Ref: Success Order Title': '주문해 주셔서 감사합니다',
    'Ref: Success Order Description': '계산대에서 지불하여 주문을 확인하십시오.',
    'Ref: Success Order ID': 'ID 순서',
    'Ref: Success Order Action': '새로운 질서',
    'Ref: Text for dinein':
      '당신의 주문은 곧 당신의 테이블에 제공되어야합니다. 친절한 직원에게 당신의 식사 주문에 대해 물어보십시오.',
    'Ref: Text for scan&go': '주문은 주문 기록에서 제공됩니다',
    'Ref: Text for delivery':
      '주문 기록에서 상태를 추적 할 수 있습니다. 알림이 활성화되면 주문이 준비되면 알려 드리겠습니다.',
    'See details': '자세히보다',
    'Go to home': '집에 가십시오',
  },
  FailOrder: {
    'Go to home': '집에 가십시오',
    'Ref: Kiosk Fail Title': '죄송합니다! 뭔가 잘못되었습니다!',
    'Ref: Kiosk Fail Description':
      '불행히도, 당신의 주문은 성공하지 못했습니다. 다시 시도하거나 직원에게 도움을 요청하십시오.',
    'Ref: Kiosk Fail Back': '이전 페이지로 돌아갑니다',
    'Ref: Kiosk Fail Action': '세션을 지우고 다시 주문하십시오',
    'Ref: Error in cart validation':
      '주문 중에 오류가 발생했습니다. 문제를 해결하려면 시설에 문의하십시오.',
    'Facility contact': '시설 연락처',
    'Ref: Ordering failed': '주문이 실패했습니다',
    'Ref: Order confirmed': '주문 확인',
  },
  TableCheckIn: {
    'Welcome at': '환영합니다',
    Table: '테이블:',
    'Ref: list title': '오늘의 최근 명령',
    'See the menu': '메뉴를 참조하십시오',
    'Table Check-in': '테이블 체크인',
    'Ref #': '심판 #',
    Checked: '확인',
    'Check in': '체크인',
    'See details': '자세히보다',
    'Ref: You have changed location': '위치가 변경되었습니다',
    'Ref: You are now at': '당신은 지금 있습니다',
    'Order number': '주문 번호',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': '프로모션 적용',
    'Ref: Promotion applied description': '당신은 당신의 장바구니에서 홍보에 도움이됩니다.',
    'Ref: Promotion deleted title': '프로모션이 삭제되었습니다',
    'Ref: Promotion deleted description':
      '더 이상 조건을 채우지 않거나 프로모션이 만료 되었기 때문에 더 이상 승진에 도움이되지 않습니다.',
  },
  MenuTile: { 'Ref: Scan&Go': '스캔 및 이동', 'Ref: View only': '만보십시오' },
  ProductTile: {
    'Ref: Allergens': '알레르겐',
    'Ref: Presence of allergen': '알레르겐의 존재',
    'Ref: Scan': '주사',
    'Ref: From': '에서',
    'Ref: Already in product favorite list. Click to remove it.':
      '이미 제품이 좋아하는 목록에 있습니다. 제거하려면 클릭하십시오.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '현재 제품이 좋아하는 목록에 추가되지 않았습니다. 추가하려면 클릭하십시오.',
    'Ref: Free': '무료',
  },
  OrderSummary: {
    'Ref: item': '안건',
    'Ref: items': '항목',
    'Ref: Clear': '분명한',
    'Ref: Cart': '카트',
    'Ref: Subtotal': '소계',
    'Ref: Total': '총',
    'Ref: Tax': '세',
    'Ref: Promotion': '홍보',
    'Ref: Total without promotion': '승진없이 총계',
    'Ref: Number of product in cart': '카트의 제품 수',
    'Ref: Delivery': '배달',
  },
  CartItemTile: { 'Ref: Vegan': '비건 채식', 'Ref: Vegeterian': '채식' },
  OrderInfoCard: {
    'Ref: Header': '주문',
    'Ref: from': '~에서',
    'Ref: location': '위치',
    'Ref: Phone': '전화 번호',
    'Ref: Instructions': '지침',
    'Ref: Order type': '주문 유형',
    'Ref: Pickup': '찾다',
    'Ref: Eat-in': '식사',
    'Ref: Scan&Go': '스캔 및 이동',
  },
  ReorderWidget: {
    'Ref: No items': '아직 주문이 없습니다. 지금 쇼핑하세요!',
    'Ref: Title': '다시 주문하십시오',
  },
  MenusWidget: { 'Ref: no menu': '지금은 메뉴가 없습니다', 'Ref: Modal title': '오늘의 메뉴' },
  PromotionInfo: { 'Ref: Discounts available': '할인 가능' },
  PromotionInfoItem: { 'Ref: Activate': '활성화' },
  OrderHistoryWidget: { 'Ref: Your Orders': '당신의 주문', 'Ref: Widget Title': '내 주문' },
  SuggestionsWidget: {
    'Ref: Modal title': '오늘의 제안',
    'Ref: Any menu': '모든 메뉴',
    Other: '다른',
    'Ref: Suggestions calendar button': '제안 일정',
  },
  ModifierCard: {
    'Ref: Serving Size': '서빙 사이즈',
    'Ref: Nutrition': '영양 정보',
    'Ref: Nutrition adults': '성인은 하루에 2000kcal이 필요합니다',
    'Ref: Nutrition Calories': '칼로리',
    'Ref: Nutrition Calcium': '칼슘',
    'Ref: Nutrition Fat': '지방',
    'Ref: Nutrition Saturated Fat': '포화 지방',
    'Ref: Nutrition Carbohydrates': '탄수화물',
    'Ref: Nutrition Sugar': '설탕',
    'Ref: Nutrition Protein': '단백질',
    'Ref: Nutrition Sodium': '나트륨',
    'Ref: Nutrition cholesterol': '콜레스테롤',
    'Ref: Nutrition dietaryFiber': '식이 섬유',
    'Ref: Nutrition iron': '철',
    'Ref: Nutrition potassium': '칼륨',
    'Ref: Nutrition vitaminA': '비타민 A.',
    'Ref: Nutrition vitaminC': '비타민 C',
    'Ref: Ingredients': '조리법 성분',
    'Ref: Allergens': '알레르기 항원',
    'Ref: Always there': '언제나 그곳에',
    'Ref: Allergens selected': '필터에서 선택한 알레르겐 :',
    'Ref: minimum required': '최소 필수',
    'Ref: ingredients': '재료',
    'Ref: Ingredient': '재료',
    'Ref: Free': '무료',
    'Ref: Based on customization': '사용자 정의에 따라',
    'Ref: Additives eggs': '달걀',
    'Ref: Additives fish': '생선',
    'Ref: Additives milk': '우유',
    'Ref: Additives nuts': '견과류',
    'Ref: Additives wheat': '밀',
    'Ref: Additives peanuts': '땅콩',
    'Ref: Additives soy beans': '대두 콩',
    'Ref: Additives sesame seeds': '참깨',
    'Ref: Review': '검토',
    'Ref: Per portion': '부분 당',
    'Ref: Per 100g': '100g 당',
    'Ref: Modifier item number of free items': '• 무료로 {number_of_free_items}',
    'Ref: Number of free items': '무료 항목 수 : {number_of_free_items}',
    'Ref: Allergen selected': '검색 필터에서 선택된 알레르겐',
    'Ref: Nutritional info': '영양 정보',
    'Ref: Info': '정보',
  },
  PromotionsMiniWidget: { 'Ref: Title': '프로모션' },
  DiscountTiersModal: {
    'Ref: Choose your job': '직업을 선택하십시오',
    'Ref: Validate': '확인',
    'Ref: Pricing Tiers dialog message': '귀하는 귀하의 이메일 주소로 인정 받았습니다',
    'Ref: Please select your job in the company': '회사에서 직업을 선택하십시오',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '찾다',
    'Ref: Eat-in': '식사',
    'Ref: Scan&Go': '스캔 및 이동',
    'Ref: Delivery': '배달',
    'Ref: Total': '총',
    'Ref: How was facility name service': '{facility_name}의 서비스는 어땠습니까?',
    'Ref: How do you rate the service': '서비스를 어떻게 평가합니까?',
  },
};
export default labels;
