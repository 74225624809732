const labels = {
  PrivacyPolicy: 'Zásady ochrany osobních údajů',
  TermsOfSale: 'Podmínky prodeje',
  TermsOfUse: 'Všeobecné obchodní podmínky',
  home: 'Domov',
  anytime: 'Kdykoliv.',
  anywhere: 'kdekoli',
  Download: 'Stažení',
  scrollRightButton: 'Skartovací kategorie doprava',
  scrollLeftButton: 'Skartovací kategorie doleva',
  calendar: 'Kalendář',
  'See more': 'Vidět víc',
  'See less': 'Viz méně',
  'from (followed by a date, or date and time)': 'z',
  'to (followed by a date, or date and time)': 'na',
  'on (followed by a date)': 'Na',
  'at (followed by time)': 'na',
  'from (followed by a location)': 'z',
  'to (followed by a location)': 'do',
  'on (followed by a site name)': 'Na',
  'from (followed by a person)': 'z',
  previous: 'Předchozí',
  next: 'další',
  success: 'úspěch',
  failure: 'selhání',
  today: 'Dnes',
  tomorrow: 'Zítra',
  Monday: 'pondělí',
  Tuesday: 'úterý',
  Wednesday: 'středa',
  Thursday: 'Čtvrtek',
  Friday: 'pátek',
  Saturday: 'sobota',
  Sunday: 'Neděle',
  everyday: 'Každý den',
  'open all day': 'Otevřeno celý den',
  'opening hours': 'Otevírací doba',
  status: 'postavení',
  'to be confirmed': 'Být potvrzen',
  'only (as in filter only this)': 'pouze',
  edit: 'Upravit',
  submit: 'Předložit',
  cancel: 'zrušení',
  confirm: 'Potvrdit',
  reset: 'Resetovat',
  Delete: 'Vymazat',
  select: 'vybrat',
  switch: 'přepínač',
  close: 'Zavřít',
  clear: 'Průhledná',
  remove: 'odstranit',
  logout: 'odhlásit se',
  go: 'jít',
  'see (as in consult this page or document)': 'vidět',
  'see all (as in see all items)': 'Vidět vše',
  'see more (as in see more details for this item)': 'Vidět víc',
  retry: 'opakovat',
  'Ref: ok': 'OK',
  'yes (as in yes I accept)': 'Ano',
  'no (as in no I do not accept)': 'Ne',
  you: 'vy',
  'thank you': 'Děkuji',
  'sorry (standalone)': 'Promiňte',
  'to home (as in navigate to the homepage)': 'domů',
  map: 'mapa',
  list: 'seznam',
  'Loading...': 'Načítání...',
  'Refreshing...': 'Aktualizace...',
  'Please select': 'Prosím vyberte',
  'Switch site': 'Zde přepněte místo',
  'File size cannot exceed {max file size}': 'Velikost souboru nesmí překročit {max file size}',
  Filters: 'Filtry',
  'Add attachment': 'Přidat přílohu',
  Search: 'Vyhledávání',
  'More (as in: see more info)': 'Více',
  'You can attach a maximum of {max files number} files.':
    'Můžete připojit maximálně {max files number} souborů.',
  'There are no messages to display.': 'Nejsou žádné zprávy k zobrazení.',
  'Your selection did not return any result.': 'Váš výběr nevrátil žádný výsledek.',
  'Ref: Popup header for missing required fields in a form': 'Uveďte prosím další informace',
  'Ref: Required field': '{ref} je povinné pole.',
  email: 'E-mail',
  phone: 'Číslo mobilního telefonu',
  Account: 'Účet',
  Contacts: 'Kontakty',
  Content: 'Obsah',
  Events: 'Události',
  Facilities: 'Zařízení',
  Shop: 'Prodejna',
  Menu: 'Jídelní lístek',
  Menus: 'Nabídky',
  Order: 'Objednat',
  Orders: 'Objednávky',
  Info: 'Informace',
  FAQ: 'FAQ',
  Feedback: 'Zpětná vazba',
  Review: 'Posouzení',
  Requests: 'Požadavky',
  'Request Helpdesk': 'Požádat o helpdesk',
  Location: 'Umístění',
  Safety: 'Bezpečnost',
  FreeCoffee: 'Káva zdarma',
  Newsfeed: 'Newsfeed',
  MyOrders: 'Mé objednávky',
  LoyaltyStamps: 'Věrnostní známky',
  Stamps: 'Známky',
  Stays: 'Pobyty',
  Suppliers: 'Dodavatelé',
  Surveys: 'Průzkumy',
  notifications: 'oznámení',
  cart: 'vozík',
  'Ref: Reset your order': 'Resetovat objednávku',
  'Ref: My language': 'Můj jazyk',
  Validate: 'Ověřit',
  'contact us': 'kontaktujte nás',
  'Ref: Start typing': 'Začněte psát',
  Occupancy: 'Obsazenost',
  'see less': 'Vidět méně',
  'see more': 'Vidět víc',
  contacts: 'Kontakty',
  Imprint: 'Otisk',
  'Ref: camera access request title': 'Povolit přístup k fotoaparátu',
  'Ref: camera access request body':
    'Chcete -li použít QR kódový skener, musíte povolit použití kamery na tomto webu',
  or: 'nebo',
  'Ref: Input required': '{input_name} je vyžadován',
  'Enter a valid email address': 'Zadejte platnou e-mailovou adresu',
  'Ref: Input error E-mail already exists':
    'S tímto e -mailem, který jste zadal, již existuje účet. Místo toho se přihlaste.',
  'Password must contain': 'Heslo musí obsahovat:',
  'Ref: Input password error strength':
    'Heslo musí obsahovat nejméně 8 znaků, 1 číslo, 1 písmeno a 1 speciální znak',
  Password: 'Heslo',
  'New password': 'Nové heslo',
  'Confirm password': 'Potvrďte heslo',
  'Ref: Password does not match': 'Heslo a potvrzovací heslo se neshodují',
  'Ref: Old password does not match': 'Staré heslo se neshoduje',
  'Ref: Password validation min8Characters': 'Nejméně 8 znaků',
  'Ref: Password validation min1Number': 'Alespoň 1 číslice',
  'Ref: Password validation min1Letter': 'Alespoň 1 písmeno',
  'Ref: Password validation min1SpecialChar': 'Alespoň 1 speciální charakter',
  Continue: 'Pokračovat',
  'Enter a valid phone number': 'Zadejte platné telefonní číslo',
  'Phone is required': 'Je vyžadován telefon',
  'Go to home': 'Jít domů',
  'Something went wrong': 'Něco se pokazilo',
  'Something went wrong, data not loaded': 'Něco se pokazilo, data nebyla načtena',
  'Change password': 'Změnit heslo',
  'remove one': 'Odstraňte jeden',
  'add one': 'přidat jeden',
  'Confirm Filters': 'Potvrďte filtry',
  'Reset Filters': 'Resetovat filtry',
  '{selectedNum} active filters': '{selectedNum} aktivní filtry',
  'Wrong input icon': 'Špatná ikona vstupu',
  'Correct input icon': 'Správná ikona vstupu',
  'Show input text icon': 'Zobrazit ikonu vstupního textu',
  'Hide input text icon': 'Skrýt ikonu vstupního textu',
  'Required fields info': '* Požadovaná pole',
  'Ref: Select site on the map': 'Vyberte svůj web na mapě',
  'Ref: Geolocation disabled':
    'Geolokace je deaktivována. Chcete -li zobrazit blízké stránky, povolte to.',
  'Ref: Reaction - LIKE': 'Jako',
  'Ref: Reaction - LOVE': 'Milovat',
  'Ref: Reaction - INTERESTING': 'Zajímavý',
  'Ref: Reaction - CONGRATE': 'Gratulujte',
  'Ref: Share': 'Podíl',
  'Ref: Send by Email': 'Odeslat e -mailem',
  'Ref: Copy the link': 'Zkopírujte odkaz',
  'Ref: Link was copied': 'Odkaz byl zkopírován',
  'Ref: unable to copy url': 'Nelze zkopírovat URL',
  'Ref: External link': 'Externí odkaz',
  'Ref: Step': 'Krok',
  'input field': 'vstupní pole',
  'Ref: Zoom': 'Zvětšení',
  'Increase hours': 'Zvýšit hodiny',
  'Decrease hours': 'Snižovat hodiny',
  Hours: 'Hodiny',
  Minutes: 'Minut',
  'Ref: filter label: favorites': 'Ukažte pouze mé oblíbené',
  'Ref: filter label: max calories': 'Maximální kalorií',
  'Ref: filter label: dishes': 'Nádobí',
  'Ref: filter label: allergens': 'Alergens',
  'Ref: filter label: highlight': 'Zvýrazněte nebo skrýt filtry s nádobím',
  'Ref: Highlight': 'Zvýraznit',
  'Ref: Hide': 'Skrýt',
  'Ref: Vegan': 'Veganský',
  'Ref: Vegeterian': 'Vegetariánský',
  'Ref: Mindful': 'Dbalý',
  'Ref: Highlight text':
    'Zde se můžete rozhodnout o Hightlight nebo skrýt pokrmy v menu, které obsahují určité alergeny',
  'Ref: Just now': 'Právě teď',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} před minutami',
  'Ref: 1 hour ago': 'před 1 hodinou',
  'Ref: {amountHours} hours ago': '{amountHours} před hodinami',
  'Ref: Yesterday at {time}': 'Včera v {time}',
  'Ref: {date} at {time}': '{date} na {time}',
  'Ref: filter label: date': 'Datum',
  'Ref: expand menu': 'rozbalte menu',
  'Ref: collapse menu': 'Nabídka kolapsu',
  'Ref: My site': 'Moje stránka',
  'Ref: Recent': 'Nedávné',
  'Ref: Services': 'Služby',
  'Ref: products in cart': 'Počet produktů v košíku',
  'Ref: no product in cart': 'Žádný produkt v košíku',
  'Ref: Survey': 'Průzkum',
  'Access to camera was denied. Please allow access and try again':
    'Přístup k fotoaparátu byl zamítnut. Povolte prosím přístup a zkuste to znovu',
  'Add an attachment': 'Přidejte přílohu',
  'Take a picture': 'Vyfotit',
  'Add a file from your device': 'Přidejte soubor ze svého zařízení',
  'Ref: Loading page content, please wait': 'Načítání obsahu stránky, počkejte prosím',
  Star: 'Hvězda',
  Stars: 'Hvězdy',
  'Accommodation Request': 'Žádost o ubytování',
  'Ref: Guest Accomodation Request': 'Žádost hosta',
  'Ref: Search a menu, product or facility': 'Prohledejte menu, produkt nebo zařízení',
  ConnectionLost: 'Ztraceno síťové připojení',
  ConnectionRestored: 'Obnoveno síťové připojení',
  'Ref: Skip to main content': 'Přeskočit na hlavní obsah',
  'Ref: No options': 'Žádné možnosti',
  'Ref: Global search modal': 'Globální vyhledávací modální',
  'Ref: Search a facility': 'Hledat zařízení',
  'Ref: Search a menu or product': 'Prohledejte nabídku nebo produkt',
  'Ref: Reaction': 'Reakce',
  'Ref: times': 'časy',
  'Ref: reaction. Change reaction': 'reakce. Změnit reakci',
  'Ref: No reaction. React': 'Žádná reakce. Reagovat',
  'Ref: LIKE': 'Jako',
  'Ref: LOVE': 'Milovat',
  'Ref: INTERESTING': 'Zajímavý',
  'Ref: CONGRATE': 'Gonglate',
  'Ref: Open search': 'Otevřít vyhledávací pole',
  'Ref: Close search': 'Zavřít vyhledávací pole',
  'Ref: Total file size msg': 'Celková velikost souboru nesmí překročit {max file size}',
  'Ref: Oops Title': 'Jejda!',
  'Ref: Well Done': 'Výborně {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigační šipka - Pokračujte',
  'Ref: Reload': 'Znovu načíst',
  'Ref: All Dates': 'Všechna data',
  'Ref: Today': 'Dnes',
  'Ref: Validate': 'Ověřit',
  'Ref: toast error title': 'Jejda!',
  'Ref: toast error subtitle': 'Došlo k chybě, zkuste to znovu.',
  'Ref: Calendar': 'Kalendář',
  'Ref: Selected': 'Vybraný',
  'Ref: Previous Month': 'Předchozí měsíc',
  'Ref: Next Month': 'Příští měsíc',
};
export default labels;
