import { ReviewActionType } from '../types/reviewActions.types';
import { ReviewState } from '../types/reviewState.types';

const defaultState: ReviewState = {
  email: '',
  isAnonymous: true,
};

const reviewReducer = (state = defaultState, action: ReviewActionType): ReviewState => {
  switch (action.type) {
    case 'RESET_STATE':
      return defaultState;
    case 'UPDATE_EMAIL':
      return {
        ...state,
        email: action.email,
      };
    case 'TOGGLE_ANONYMOUS':
      return {
        ...state,
        isAnonymous: action.isAnonymous,
      };
    default:
      return state;
  }
};

export default reviewReducer;
