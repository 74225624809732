import { cleanup, screen } from '@testing-library/react';

import { defaultLoyaltySchemes } from '../../mocks/mock';

import LoyaltyCard from './LoyaltyCard';
import { LoyaltyCardProps } from './LoyaltyCard.types';

import renderComponent from '@/helpers/tests/renderComponent';
import { defaultMenuItem, defaultProductPortion } from '@/modules/Order/__mocks/mock';
import { PageType } from '@/modules/Order/types/menuCartActions.types';

const openLoyaltyInfoModal = jest.fn();
const closeLoyaltyInfoModal = jest.fn();

const loyaltyStampsToBeEarned = [
  { loyaltySchemeId: 1, stampsCount: 1 },
  { loyaltySchemeId: 2, stampsCount: 4 },
];

const props: LoyaltyCardProps = {
  menuId: 3329,
  loyaltyStampsToBeEarned: loyaltyStampsToBeEarned,
  openModal: openLoyaltyInfoModal,
  closeModal: closeLoyaltyInfoModal,
  modalOpen: true,
  loyaltySchemes: defaultLoyaltySchemes,
  pageType: PageType.cart,
  label: (s: string, opts: any) => {
    if (!opts) return s;
    const { replace } = opts;
    if (!replace) return '';
    let toReplace = '{no_stamps} {stamp_form} {scheme_names} {program_form}';

    toReplace = toReplace.replace('{no_stamps}', replace.no_stamps ?? '');
    toReplace = toReplace.replace('{stamp_form}', replace.stamp_form ?? '');
    toReplace = toReplace.replace('{scheme_names}', replace.scheme_names ?? '');
    toReplace = toReplace.replace('{program_form}', replace.program_form ?? '');

    return toReplace;
  },
};

describe('LoyaltyCard component with item providing stamp on Cart Page', () => {
  afterAll(() => cleanup());

  describe('on render', () => {
    renderComponent(LoyaltyCard, {
      ...props,
    });

    it('should display loyalty tile with expected number and Stamp Form', () => {
      const slider = screen.getByTestId('loyalty-tile');
      const title = screen.getAllByText('5 Ref: Stamps');
      const image = screen.getByText('LoyaltyStampsCard.svg');
      expect(slider).toBeTruthy();
      expect(title).toBeTruthy();
      expect(image).toBeTruthy();
    });

    it('should display modal for loyalty module', () => {
      const modal = screen.getByTestId('loyalty-modal');
      const bulletPointList = screen.getByTestId('loyalty-modal-bullet-point-list');
      const navigateToLoyaltyStampsButton = screen.getByTestId('loyalty-modal-button-see-stamps');
      const firstBulletPointItem = screen.getByTestId('loyalty-modal-bullet-point-list-item-1');
      const secondBulletPointItem = screen.getByTestId('loyalty-modal-bullet-point-list-item-2');
      expect(modal).toBeTruthy();
      expect(bulletPointList).toBeTruthy();
      expect(navigateToLoyaltyStampsButton).toBeTruthy();
      expect(firstBulletPointItem).toBeTruthy();
      expect(secondBulletPointItem).toBeTruthy();
    });

    it('should display modal expected text in modal', () => {
      const aboutSection = screen.getByText('Ref: About Loyalty stamps');
      const summaryOfStampsToGet = screen.getAllByText('5 Ref: Stamps');
      const firstBulletPointItem = screen.getByText('1 Ref: Stamp');
      const secondBulletPointItem = screen.getByText('4 Ref: Stamps');
      expect(aboutSection).toBeTruthy();
      expect(summaryOfStampsToGet).toBeTruthy();
      expect(firstBulletPointItem).toBeTruthy();
      expect(secondBulletPointItem).toBeTruthy();
    });
  });
});

describe('LoyaltyCard component with item providing stamp on Product Details Page', () => {
  afterAll(() => cleanup());
  const propsForProductDetails = {
    ...props,
    menuItem: {
      ...defaultMenuItem,
      productPortions: [{ ...defaultProductPortion, uomId: 30551, foodItemId: 28508 }],
    },
    pageType: PageType.productDetails,
  };

  describe('on render', () => {
    renderComponent(LoyaltyCard, {
      ...propsForProductDetails,
    });

    it('should display loyalty tile with expected number and Stamp Form', () => {
      const slider = screen.getByTestId('loyalty-tile');
      const title = screen.getAllByText('1 Ref: Stamp');
      const image = screen.getByText('LoyaltyStampsCard.svg');
      const description = screen.getByText('Exotic Summer Deal');
      expect(slider).toBeTruthy();
      expect(title).toBeTruthy();
      expect(image).toBeTruthy();
      expect(description).toBeTruthy();
    });

    it('should display modal for loyalty module', () => {
      const modal = screen.getByTestId('loyalty-modal');
      const navigateToLoyaltyStampsButton = screen.getByTestId('loyalty-modal-button-see-stamps');
      const image = screen.getByText('LoyaltyStampsRewards.svg');
      expect(modal).toBeTruthy();
      expect(navigateToLoyaltyStampsButton).toBeTruthy();
      expect(image).toBeTruthy();
    });

    it('should display modal expected text in modal', () => {
      const aboutSection = screen.getByText('Ref: About Loyalty stamps');
      const modalDescription = screen.getByText('Exotic Summer Deal Ref: Program');
      expect(aboutSection).toBeTruthy();
      expect(modalDescription).toBeTruthy();
    });
  });
});
