import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { NavArrowRightIcon, LocationIcon, LocationOffIcon } from '../../../assets/icons';
import { ISiteGeoCoordinates } from '../../../modules/Sites/types/sites.types';
import { getZoomForPxByMilesForLat } from '../../atoms/Map/helpers';
import Map from '../../atoms/Map/Map';
import { MapCoordinates, MapMarker, MarkerIcon } from '../../atoms/Map/Map.types';
import { TileProps } from '../../organisms/Tile';
import { TileAction } from '../Tile';
import { Tile } from '../Tile/Tile';
import WidgetPlaceholder from '../WidgetPlaceholder/WidgetPlaceholder';

import { pagePaths } from '@/modules/Suppliers/config';

import styles from './mapWithMarkerDetails.module.css';

const DEFAULT_MILES_TO_SHOW_ON_MAP = 4;

export type MarkerData = Omit<TileProps & { id: string }, 'history' | 'location' | 'match'>;

type MapWithMarkerDetailsProps<MarkerData> = {
  milesToShowOnMap?: number;
  center: MapCoordinates;
  positionCoordinates?: MapCoordinates;
  markers: MapMarker<MarkerData>[];
  inheritStyle?: string;
  activeMarkerIcon?: MarkerIcon | string;
  label: (text: string) => string;
  geolocation?: ISiteGeoCoordinates | null;
};

const MapWithMarkerDetails = ({
  milesToShowOnMap = DEFAULT_MILES_TO_SHOW_ON_MAP,
  center,
  positionCoordinates,
  markers,
  inheritStyle,
  label,
  activeMarkerIcon,
  geolocation,
}: MapWithMarkerDetailsProps<MarkerData>) => {
  const [formattedMarkers, setFormattedMarkers] = useState<MapMarker<MarkerData>[]>(markers);
  const [selectedMarker, setSelectedMarker] = useState<MapMarker<MarkerData> | null>(null);

  const history = useHistory();

  const image = selectedMarker?.data?.imgInfo?.src ? (
    <img
      className={styles.image}
      src={selectedMarker?.data?.imgInfo?.src}
      alt={''}
      style={{
        backgroundColor: selectedMarker?.data?.imgInfo?.bgColor
          ? selectedMarker?.data?.imgInfo?.bgColor
          : '',
      }}
    />
  ) : (
    <></>
  );

  const onMarkerClick = useCallback(
    (markerData: MarkerData) => {
      const markerSelected = markers.find((marker) => marker.id === markerData.id) || null;
      setSelectedMarker(markerSelected);
      if (activeMarkerIcon) {
        setFormattedMarkers(
          markers.map((marker) => {
            if (marker.id === markerData.id) {
              return {
                ...marker,
                icon: activeMarkerIcon,
              };
            }
            return marker;
          })
        );
      }
    },
    [activeMarkerIcon, markers]
  );

  const navigateToSupplierDetails = () => {
    if (!!selectedMarker?.id) {
      history.push(pagePaths.SupplierDetails.replace(':id', selectedMarker.id));
    }
  };

  const tileActions: TileAction[] = [
    {
      name: label('Ref: Navigation arrow - Continue'),
      icon: <NavArrowRightIcon />,
      onClick: navigateToSupplierDetails,
      'data-testid': `supplier-action-${selectedMarker?.id}`,
    },
  ];

  const infoPlaceholder = useMemo(() => {
    if (!geolocation) {
      return (
        <WidgetPlaceholder
          title={label('Ref: Geolocation disabled')}
          icon={<LocationOffIcon width={24} />}
        />
      );
    }

    return (
      <WidgetPlaceholder title={label('Ref: Select site on the map')} icon={<LocationIcon />} />
    );
  }, [geolocation, label]);

  return (
    <>
      <div className={classNames(styles.mapWrapper, inheritStyle)} role="presentation">
        <Map
          data-testid="map-with-marker-details" 
          zoom={getZoomForPxByMilesForLat({
            lat: center.latitude,
            milesToShow: milesToShowOnMap,
            totalPxWidth: window.innerWidth,
          })}
          positionCoordinates={positionCoordinates}
          center={selectedMarker?.coordinates || center}
          markers={formattedMarkers}
          onMarkerClick={onMarkerClick}
        />
      </div>
      {selectedMarker ? (
        <div className={styles.tileWrapper}>
          <Tile
            title={selectedMarker.data?.title}
            description={selectedMarker.data?.description}
            onClick={navigateToSupplierDetails}
            image={image}
            data-testid={`supplier-tile-${selectedMarker.id}`}
            actions={tileActions}
          ></Tile>
        </div>
      ) : (
        infoPlaceholder
      )}
    </>
  );
};

export default MapWithMarkerDetails;
