const labels = {
  ReviewForm: {
    'Ref: Page title': 'Überprüfung',
    'Ref: How was dining with us': 'Wie war das Essen mit uns?',
    'Ref: Submit': 'Einreichen',
    'Ref: Filter range': 'Filterbereich',
    'Ref: Some information is missing': 'Einige Informationen fehlen',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Anonym senden',
    'Ref: Your email address': 'Ihre E-Mail-Adresse',
    'Ref: Email field placeholder': 'Geben sie ihre E-Mailadresse ein',
    'Ref: Invalid email format': 'ungültiges Email-Format',
  },
  SuccessPage: {
    'Ref: Body':
      'Vielen Dank, dass Sie diese Bewertung abgegeben haben. Ihre Zeit ist wertvoll, da diese Bewertung zur Verbesserung unserer Dienstleistungen beitragen wird.',
    'Ref: Go to Feedback': 'Gehen Sie zum Feedback',
    'Ref: Go to home': 'Zur Startseite',
    'Ref: Go to Facilities': 'Zu den Einrichtungen',
    'Ref: Go to Product Details': 'Zu den Produktdetails',
    'Ref: Go to Facility Details': 'Gehen Sie zu den Details der Einrichtung',
  },
  FacilityPicker: {
    'Ref: Page title': 'Überprüfung',
    'Ref: Search facility name': 'Sucheinrichtung Name',
  },
};
export default labels;
