import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { ReceptionIllustration } from '../../../../assets/illustrations';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import { useGetMappedFacilitiesQuery } from '../../../Review/api';
import { useGetFacilitiesListQuery } from '../../api/index';
import { useFacilitiesTranslation } from '../../hooks/useFacilitiesTranslation';
import { Facility } from '../../redux/actions.types';
import { FacilityItem, FACILITY_TYPE, FacilityType } from '../../types/types';
import FacilityTile from '../FacilityTile/FacilityTile';

import { TileSkeleton } from '@/components/molecules/Tile';
import useSite from '@/modules/Core/hooks/useSite';
import { State } from '@/types/state.types';

const search = {
  searchableKeys: ['title'],
};

const FacilitiesList = () => {
  const { label } = useFacilitiesTranslation(__filename);
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const facilitiesState = useSelector((state: State) => state.Facilities);
  const dispatch = useDispatch();

  const { data: facilitiesApiResult, isLoading: isFacilitiesLoading } = useGetFacilitiesListQuery({
    siteId: site.id,
    dispatch: dispatch,
  });
  const { data: mappedFacilities, isLoading: isFetchingMappedFacilities } =
    useGetMappedFacilitiesQuery(site.id);

  const items: FacilityItem[] = useMemo(() => {
    return (
      facilitiesApiResult?.facilities.map((facility: Facility) => {
        const facilityType = FACILITY_TYPE[facility.facilityType?.name] ?? FacilityType.Undefined;

        return {
          id: facility.id,
          imgInfo: facility.imageInfo,
          facilityType,
          title: facility.title,
          addendum: label('Ref: See details'),
          'data-testid': `facility-list-item-${facility.id}`,
          externalUrl: facility.externalUrl || undefined,
          viewCounts: facilitiesState.viewCounts[facility.id] || 0,
        };
      }) || []
    ).sort((a, b) => b.viewCounts - a.viewCounts);
  }, [facilitiesApiResult?.facilities, facilitiesState.viewCounts, label]);

  const renderFacilityItem = (item: FacilityItem) => {
    return (
      <FacilityTile
        key={item.id}
        id={item.id}
        title={item.title}
        data-testid={item['data-testid']}
        facilityType={item.facilityType}
        imgInfo={item.imgInfo}
        reviewableFacilities={mappedFacilities}
        label={label}
        externalUrl={item.externalUrl}
      />
    );
  };

  return (
    <ListPage
      data-testid="facility-list"
      hasBackLink={false}
      title={label('Ref: Page title')}
      items={items}
      search={search}
      itemRenderer={renderFacilityItem}
      isLoading={isFacilitiesLoading}
      aside={
        <Column.Complementary>
          <ReceptionIllustration />
        </Column.Complementary>
      }
    >
      {(isFacilitiesLoading || isFetchingMappedFacilities) &&
        Array.from({ length: 10 }).map(() => (
          <TileSkeleton key={`${uuid()}-skeleton`} withoutActions withoutImage />
        ))}
    </ListPage>
  );
};

export default FacilitiesList;
