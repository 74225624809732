const labels = {
  ReviewForm: {
    'Ref: Page title': 'Arvostelu',
    'Ref: How was dining with us': 'Kuinka ruokailu sujui kanssamme?',
    'Ref: Submit': 'Lähetä',
    'Ref: Filter range': 'Suodatinalue',
    'Ref: Some information is missing': 'Jotkut tiedot puuttuvat',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Lähetä nimettömästi',
    'Ref: Your email address': 'Sähköpostiosoitteesi',
    'Ref: Email field placeholder': 'Syötä sähköpostiosoitteesi',
    'Ref: Invalid email format': 'Virheellinen sähköpostimuoto',
  },
  SuccessPage: {
    'Ref: Body':
      'Kiitos tämän arvostelun suorittamisesta, palautteesi on arvokas, arvostelu auttaa parantamaan palveluitamme.',
    'Ref: Go to Feedback': 'Siirry palauteisiin',
    'Ref: Go to home': 'Palaa alkuun',
    'Ref: Go to Facilities': 'Mennä tiloihin',
    'Ref: Go to Product Details': 'Siirry tuotteen yksityiskohtiin',
    'Ref: Go to Facility Details': 'Siirry laitoksen yksityiskohtiin',
  },
  FacilityPicker: {
    'Ref: Page title': 'Arvostelu',
    'Ref: Search facility name': 'Hakulaitoksen nimi',
  },
};
export default labels;
