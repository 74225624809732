const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': '您的申请',
    'Ref: Summary': '概括',
    'Ref: Request category': '请求类别',
    'Ref: Person affected': '受此要求影响的人',
    'Ref: Your phone number': '电话号码',
    'Ref: Description': '描述',
    'Ref: Service Requests': '服务请求',
    'Ref: Request Service': '请求服务',
    'Ref: Success title': '您的要求已创建',
    'Ref: Error title': '哎呀！',
    'Ref: Error subtitle': '发生了错误。请再试一次。',
    'Ref: Try again': '再试一次',
    'Ref: you pronoun': '（你）',
  },
  Tutorials: {
    'Ref: Tutorial: title': '这是您的工作场所支持中心',
    'Ref: Tutorial: tabname': '要求',
    'Ref: Tutorial: body': '请为不同的请求创建独立的记录，我们随时为您提供帮助。',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.': '您正在网站{SiteName}上要求服务。',
    'Ref: Building': '建筑',
    'Ref: Room': '房间',
    'Ref: Description help text': '您的详细信息越多，我们就越能为您提供支持。',
    'Ref: Next': '下一个',
    'Ref: Your request': '你的申请',
    'Ref: PhoneNumber error msg': '电话号码无效',
    'Ref: No affected person results': '没有人匹配要求的标准',
    'Ref: Required': '* 必需的',
  },
  Summary: {
    'Ref: Location': '地点',
    'Ref: Attachment': '附件',
    'Ref: Submit': '提交',
    'Ref: No affected person results': '没有符合请求条件的人',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': '服务请求',
    'Ref: createdOn': '创建于',
    'Ref: summary': '概括',
    'Ref: category': '请求类别',
    'Ref: request raised for': '受此要求影响的人',
    'Ref: phoneNumber': '电话号码',
    'Ref: location': '电话号码',
    'Ref: description': '描述',
    'Ref: attachments': '附件',
  },
};
export default labels;
