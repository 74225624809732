import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import TransactionalFeedbackModal from './TransactionalFeedbackModal';

import { FACILITY_TYPE } from '@/modules/Review/types/reviewFacility.types';

describe('TransactionalFeedbackModal component', () => {
  afterAll(() => cleanup());

  describe('TransactionalFeedbackModal shown', () => {
    const label = jest.fn();
    const hideModal = jest.fn();
    renderComponent(TransactionalFeedbackModal, {
      order: {
        menuId: 5,
        fulfillmentType: 'DineIn',
        orderId: 16,
        orderNumber: '16',
        pickupDate: new Date().toDateString(),
        total: 9,
      },
      siteId: '1',
      isModalOpen: true,
      languageCode: 'en-EN',
      isoCode: 'any',
      hideModal: hideModal,
      label: label,
      isLoading: false,
      facility: {
        title: 'string',
        name: 'XYZFacility',
        id: 'stringId',
        sequence: 1,
        images: ['img1'],
        facilityType: { id: 'facTypeId', name: FACILITY_TYPE['Food - Retail'] },
        viewCounts: 1,
        externalUrl: null,
      },
      imageData: { key: 'key1', image: 'someImage' },
    });

    it('should display modal and all its content', () => {
      const modal = screen.queryByTestId('transactional-feedback-modal');
      const ratingStar = screen.queryByTestId('transactional-feedback-modal-rating-star');
      const tile = screen.queryByTestId('transactional-feedback-modal-tile');

      expect(modal).toBeTruthy();
      expect(ratingStar).toBeTruthy();
      expect(tile).toBeTruthy();
    });
  });
});
