import { apiPath, dspApiPath } from '../../config';
import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { serviceTypes, modulesConfig } from '../config';

import { PostSurveyArgs } from './api/api.types';
import { SurveyConfigType } from './types/config.typse';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export const moduleId = 'Surveys';
export const cacheVersion = '0';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  Survey: modulePath + '/:id',
  Success: modulePath + '/success',
};

export const QUESTION_TYPES = {
  SHORT_ANSWER: 1,
  LONG_ANSWER: 2,
  SINGLE_CHOICE: 4,
  MULTIPLE_CHOICES: 5,
  RATING: 9,
};

export const shortAnswerMaxLengh = 40;
export const longAnswerMaxLengh = 255;

export const surveysConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      getSurveysListApiPath: '/v2/survey/list',
      getSurveyDetailsRequestData: ({ siteId }: { siteId: string; surveyId: string }) => ({
        apiPath: '/v2/survey/list',
        params: { siteId },
        filterResponse: true,
      }),
      submitSurveyRequestData: ({ survey, questionResponses }: PostSurveyArgs) => ({
        apiPath: '/v2/survey',
        data: { survey, questionResponses },
      }),
      api: baseApi,
      apiBaseUrl: apiPath,
    },
    default: {
      getSurveysListApiPath: '/v1/surveys',
      getSurveyDetailsRequestData: ({ surveyId }: { siteId: string; surveyId: string }) => ({
        apiPath: `/v1/surveys/${surveyId}`,
        undefined,
        filterResponse: false,
      }),
      submitSurveyRequestData: ({ survey, questionResponses }: PostSurveyArgs) => ({
        apiPath: `/v1/surveys/${survey.id}/respond`,
        data: { questionResponses },
      }),
      api: dspApi,
      apiBaseUrl: dspApiPath,
    },
  };

  return getAppSpecificData<SurveyConfigType>(configuration);
};
