const labels = {
  All: { 'Ref: Page title': 'Toimittajat' },
  SuppliersList: {
    'Ref: Supplier details': 'Katso yksityiskohdat',
    'Missing supplier list': 'Emme löytäneet mitään toimittajaa.',
    'Try again later': 'Yritä myöhemmin uudelleen',
  },
  SupplierDetails: {
    'Address label': 'Osoite:',
    'Website label': 'Verkkosivusto:',
    'Details label': 'Yksityiskohdat',
  },
};
export default labels;
