import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { SIZE } from '../../../constants';
import useToggle from '../../../helpers/hooks/useToggle';
import Button from '../../atoms/Button';

import { ShowMoreProps } from './ShowMore.types';

import styles from './ShowMore.module.css';

const ShowMore = ({ text, limit = 100, label, 'data-testid': testId }: ShowMoreProps) => {
  const { state: showMore, toggle: toggleshowMore } = useToggle(false);

  const textRef = useRef<HTMLSpanElement>(null);
  const [isTextClamped, setIsTextClamped] = useState(false);

  useEffect(() => {
    const element = textRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      let entry = entries.length > 0 ? entries[0] : undefined;
      if (entry && !showMore) {
        setIsTextClamped(element.scrollHeight > element.clientHeight);
      }
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, [showMore]);

  return (
    <div className={classNames(styles.wrapper)} data-testid={`${testId}-show-more-wrapper`}>
      <span
        className={classNames({
          [styles.text]: true,
          [styles.showLess]: !showMore,
        })}
        ref={textRef}
      >
        {text}
      </span>
      {isTextClamped ? (
        <div>
          <Button
            look="tertiary"
            onClick={toggleshowMore}
            size={SIZE.SMALL}
            data-testid={`${testId}-show-more-toggle`}
            alignTop
          >
            <span
              className={classNames(styles.textButton)}
              data-testid={`${testId}-show-more-text`}
            >
              {showMore ? label('see less') : label('see more')}
            </span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ShowMore;
