import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { Dispatch } from 'redux';

import { makeActionCreator, makeActionCreatorV2, fetchJsonActionCreator } from '../../actions';
import { TokenRefreshResponse } from '../../axios/tokenRefresh.types';
import { refreshAccessTokenAxios } from '../../axios/tokenRefreshApi';
import { apiPath, clientId, globalRetailApiPath, shouldUseMockData } from '../../config';
import { APP_NAME, LOCAL_STORAGE_KEYS } from '../../constants';
import { defaultThemeSettings } from '../../styles/theme.helper';
import { AuditStateCacheService } from '../Audits/services/AuditStateCacheService';
import { serviceConfiguration, defaultServices, ServiceDefinition } from '../defaultServices';

import userContextApi from './api/account/userContextApi';
import { getLegalDocsConfig, coreConfig } from './apiConfig';
import { moduleId, coreCacheVersion, sharedCacheVersion, languages } from './config';
import { applyTheme, removeTheme } from './helpers/helpers';
import { isKioskUser } from './helpers/login.helper';
import { savePushNotificationRegistrationData } from './helpers/notificationRegistrationHelper';
import { saveTokens, removeTokens } from './helpers/tokensHelpers';
import mockResponse from './mockResponse';
import {
  AcknowledgeLegalDocCustomArgs,
  ApplyLanguageAction,
  ConvertGuestToRegisteredUsersBadRequest,
  GetAcknowledgementsSuccessResponse,
  GetAllLegalDocumentsSuccessResponse,
  GetAvailableServicesSuccessResponse,
  GetIdpAccessTokenCustomArgs,
  GetOneLegalDocumentSuccessResponse,
  GetPublicSitesSuccessResponse,
  GetThemeSuccessResponse,
  GetUserContextCustomArgs,
  GetUserContextSuccessResponse,
  LoginCustomArgs,
  MigrateOldBiteUserCustomArgs,
  MigrateOldBiteUserSuccessResponse,
  RegisterGuestUserCustomArgs,
  RegisterPushNotificationsCustomArg,
  UpdateCommunicationPreferencesCustomArgs,
  UpdateUserContextCustomArgs,
} from './types/actions.types';

import { getAppName, waitSeconds } from '@/helpers/misc';
import notificationsApi from '@/modules/Core/api/notifications/notificationsApi';
import {
  clearLocalServiceRequest,
  removeSRTutorial,
} from '@/modules/ServiceRequest/helpers/helpers';
import { removeSRLTutorial } from '@/modules/ServiceRequestLight/helpers/helpers';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';
import { CacheService } from '@/services/CacheService';
import ModulesManager from '@/services/ModulesManager';
import { OfflineQueueService } from '@/services/OfflineQueueService';
import { PreRequestParams, ThenConfiguration } from '@/types/actionCreator.types';

export const persistLocallyUserSpecific = { moduleId, cacheVersion: coreCacheVersion };
const persistLocallyNonUserSpecific = {
  moduleId: 'Shared',
  cacheVersion: sharedCacheVersion,
  userSpecific: false,
};
const persistCoreLocallyNonUserSpecific = {
  moduleId,
  cacheVersion: coreCacheVersion,
  userSpecific: false,
};

export const setFirebaseTokenStatus = makeActionCreator(
  'FIREBASE_TOKEN_SET_STATUS',
  'isTokenExisting'
);

export const setDataTracking = makeActionCreatorV2(
  'SET_DATA_TRACKING',
  { dataTracking: 'dataTracking' },
  moduleId
);

export const setMsTeamsContext = makeActionCreatorV2(
  'SET_MS_TEAMS_CONTEXT',
  { TeamsContext: 'TeamsContext', shouldRetryInit: 'shouldRetryInit' },
  moduleId
);

export const setGeography = makeActionCreatorV2('GEOGRAPHY_SET', { geoCode: 'geoCode' }, moduleId);
export const setIsSsoUser = makeActionCreatorV2('IS_SSO_SET', { isSSOUser: 'isSSOUser' }, moduleId);

const takeServicesFromConfiguration = (services: ServiceDefinition[]) =>
  serviceConfiguration ? services.filter((as) => serviceConfiguration?.includes(as.name)) : [];

const filterServicesBasedOnConfiguration = (services: ServiceDefinition[]) =>
  serviceConfiguration
    ? services.filter((as) => serviceConfiguration?.includes(as.name))
    : services;

const availableDefaultServices = takeServicesFromConfiguration(defaultServices);

export const setUsername = makeActionCreator('USERNAME_SET', 'username');

export const getSiteByCode = (() => {
  const lockAction = { type: 'CONTRACT_AND_SITE_FETCHING' };
  const urlConstructor = () => baseUrl.concat(getSiteByCodeUrl);

  const argConfig = {
    code: { toQueryString: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (_: {}, dispatch: Function) => {
      await dispatch({
        type: 'CONTRACT_AND_SITE_FETCHED',
      });
    },
    other: async (dispatch: Function) => {
      console.log('Error fetching contract and site');
      await dispatch({ type: 'CONTRACT_AND_SITE_FETCHED' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getSiteByCode;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
  });
})();

export const register = (() => {
  let lockAction = { type: 'REGISTRATION_POSTING' };
  let urlConstructor = () => apiPath.concat('/v4/registrations/users');

  let argConfig = {
    email: { toBody: true },
    firstName: { toBody: true },
    lastName: { toBody: true },
    password: { toBody: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    202: async (_: {}, dispatch: Function) => {
      await dispatch({ type: 'REGISTRATION_POSTED' });
    },
    other: async (dispatch: Function) => {
      console.log('Error registering user');
      await dispatch({ type: 'REGISTRATION_POSTED' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.register;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const convertGuestToRegisteredUsers = (() => {
  let lockAction = { type: 'CONVERT_GUEST_TO_REGISTERED_USER_POSTING' };
  let urlConstructor = () => apiPath.concat('/v2/user/guesttoregisteruser');

  let argConfig = {
    email: { toBody: true },
    firstName: { toBody: true },
    lastName: { toBody: true },
    password: { toBody: true },
    siteId: { toBody: true },
    contractId: { toBody: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    202: async (_: {}, dispatch: Function) => {
      await dispatch({ type: 'CONVERT_GUEST_TO_REGISTERED_USER_POSTED' });
    },
    400: async (json: ConvertGuestToRegisteredUsersBadRequest, dispatch: Function) => {
      await dispatch({
        type: 'CONVERT_GUEST_TO_REGISTERED_USER_ERROR',
        errorMessage: json.Message,
      });
    },
    other: async (dispatch: Function) => {
      console.log('Error converting guest to registered user');
      await dispatch({ type: 'CONVERT_GUEST_TO_REGISTERED_USER_POSTED' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.convertGuestToRegisteredUsers;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
  });
})();

export const registerGuestUser = (() => {
  let lockAction = { type: 'GUEST_USER_REGISTRATION_STARTED' };
  let urlConstructor = () => apiPath.concat('/v3/registrations/guestusers');

  let argConfig = {
    contractId: { toBody: true },
    siteId: { toBody: true },
    currentGeoCode: { toThen: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    200: async (
      json: TokenRefreshResponse,
      dispatch: Function,
      thenCustomArg: RegisterGuestUserCustomArgs
    ) => {
      saveTokens(json);

      await dispatch({ type: 'GUEST_USER_REGISTRATION_FINISHED' });
      await dispatch({
        type: 'LOGGED_IN',
        currentGeoCode: thenCustomArg.currentGeoCode,
        username: 'guest_user',
        isGuest: true,
      });
    },
    other: async (dispatch: Function) => {
      console.log('Error registering user');
      await dispatch({ type: 'GUEST_USER_REGISTRATION_FAILED' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.registerGuestUser;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const login = (() => {
  let lockAction = { type: 'LOGIN_START' };
  let urlConstructor = () => apiPath.concat('/v2/oauth/token');

  let argConfig = {
    geoCode: {
      toThen: true,
    },
    username: {
      toBody: true,
      toThen: true,
    },
    psw: {
      rename: 'password',
      toBody: true,
    },
    client_id: {
      toBody: true,
      defaultValue: () => clientId,
    },
    grant_type: {
      toBody: true,
      defaultValue: () => 'password',
    },
    scope: {
      toBody: true,
    },
    currentLanguageCode: {
      toThen: true,
    },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  };

  let then = {
    200: async (json: TokenRefreshResponse, dispatch: Function, thenCustomArg: LoginCustomArgs) => {
      //persist access and refresh tokens locally
      saveTokens(json);
      // console.log('tokens saved')

      if (getAppName() === APP_NAME.BITEKIOSK) {
        if (!isKioskUser()) {
          console.log('Error logging in');
          return;
        }
      }

      const { geoCode, username, currentLanguageCode } = thenCustomArg;

      await dispatch({ type: 'LOGIN_END' });

      await dispatch({
        type: 'LOGGED_IN',
        currentGeoCode: geoCode,
        username,
      });
      await dispatch(userContextApi.util.invalidateTags(['userContext']));
      await dispatch(getUserContext({ currentLanguageCode }));
    },
    //wrong credentials: directly in the component (no need to persist anything)
    other: async (dispatch: Dispatch) => {
      await dispatch({ type: 'LOGIN_END' });
      console.log('Error logging in');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.login;
  }

  return fetchJsonActionCreator({
    argConfig,
    lockAction,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const migrateOldBiteUser = (() => {
  const lockAction = { type: 'MIGRATION_START' };
  const urlConstructor = () => globalRetailApiPath.concat('/v1/user/migrate');

  const argConfig = {
    email: {
      toBody: true,
      toThen: true,
    },
    password: {
      toBody: true,
      toThen: true,
    },
    newPassword: {
      toBody: true,
    },
    languageId: { toBody: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    200: async (
      json: MigrateOldBiteUserSuccessResponse,
      dispatch: Function,
      thenCustomArg: MigrateOldBiteUserCustomArgs
    ) => {
      const { email, password } = thenCustomArg;
      await dispatch({
        type: 'MIGRATION_END',
        userMigration: {
          email: email,
          oldPassword: password,
          firstName: json.firstName,
          lastName: json.lastName,
        },
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({
        type: 'MIGRATION_END',
      });
      console.log('Error migrating');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.login;
  }

  return fetchJsonActionCreator({
    argConfig,
    lockAction,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const refreshAccessToken = (refreshToken: string) => async (dispatch: Function) => {
  try {
    await dispatch({ type: 'TOKEN_REFRESH_START' });
    const tokenResponse = await refreshAccessTokenAxios(refreshToken);
    saveTokens(tokenResponse);

    return tokenResponse;
  } catch (err) {
    removeTokens();
    console.log('Error refreshing access token', err);
  } finally {
    await dispatch({ type: 'TOKEN_REFRESH_END' });
  }

  return undefined;
};

export const getIdpAccessToken = (() => {
  let lockAction = { type: 'LOGIN_START' };
  let urlConstructor = () => apiPath.concat('/v2/identity/token');

  let argConfig = {
    type: { toBody: true },
    username: { toBody: true, toThen: true },
    geocode: { toThen: true },
    currentLanguageCode: { toThen: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    200: async (
      json: TokenRefreshResponse,
      dispatch: Function,
      thenCustomArg: GetIdpAccessTokenCustomArgs
    ) => {
      saveTokens(json);
      await dispatch({ type: 'LOGIN_END' });

      const { geocode, username, currentLanguageCode } = thenCustomArg;

      await dispatch({
        type: 'LOGGED_IN',
        currentGeoCode: geocode,
        username,
        isSSOUser: true,
      });
      await dispatch(getUserContext({ currentLanguageCode }));
    },

    other: async (dispatch: Function) => {
      await dispatch({ type: 'LOGIN_END' });
      console.log('Error getting token for SSO user');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.login;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const setUserRegionCode = (() => {
  let lockAction = { type: 'USER_REGION_CODE_SET' };
  let urlConstructor = () => apiPath.concat('/v2/identity/userregioncode');

  let argConfig = {
    username: { toBody: true },
    regionCode: { toBody: true },
    callbackUrl: { toBody: true },
    isMobile: { toBody: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    200: async (_: {}, dispatch: Function) => {
      await dispatch({ type: 'USER_REGION_CODE_SET' });
    },

    other: async (dispatch: Function) => {
      await dispatch({ type: 'USER_REGION_CODE_SET' });
      console.log('Error set user region code');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getAcknowledgements;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();

const {
  baseUrl,
  contextUrl,
  themeUrl,
  availableServicesUrl,
  availableServicesArgConfig,
  getSiteByCodeUrl,
  updateSiteAndContractUrl,
  updateSiteAndContractParams,
} = coreConfig();
export const getUserContext = (() => {
  const lockAction = { type: 'USER_CONTEXT_FETCHING' };
  const urlConstructor = () => baseUrl.concat(contextUrl);

  const argConfig = {
    currentLanguageCode: { toThen: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (
      json: GetUserContextSuccessResponse,
      dispatch: Function,
      thenCustomArg: GetUserContextCustomArgs
    ) => {
      const { currentLanguageCode } = thenCustomArg;
      await dispatch({ type: 'USER_CONTEXT_FETCHED' });
      await dispatch({
        type: 'USER_CONTEXT_RETURNED',
        user: {
          qrCode: json.qrCode,
          contactId: json.contactId,
          firstName: json.firstName,
          lastName: json.lastName,
          email: json.email,
          mobile: json.mobile,
          preferredLanguage: json.preferredLanguage,
          communicationPreferences: {
            allowContent: json.allowContent,
            allowOffers: json.allowOffers,
          },
          preferredLocation: json.preferredLocation,
          contract: json.contract,
          theme: json.theme,
        },
        context: {
          site: json.site,
        },
        access: {
          shouldSelectSiteContext: !json.site,
        },
        persistLocally: persistLocallyUserSpecific,
      });

      await dispatch(getDefaultServices(availableDefaultServices));
      if (json.site?.id) await dispatch(getAvailableServices({ siteId: json.site.id }));
      await dispatch(notificationsApi.util.invalidateTags(['notifications']));

      // Removing old site theme
      removeTheme();

      //if a theme is returned, fetch details
      const themeResult = json.theme ? await dispatch(getTheme()) : null;

      if (!themeResult || !themeResult.ok) {
        applyTheme();
      } else {
        applyTheme(themeResult.responseData.settings);
      }
      //update the context as per the current language if need be
      const currentLanguage = languages.find((el) => el.code === currentLanguageCode);
      if (currentLanguage && json.preferredLanguage?.id !== currentLanguage.backLanguageId)
        await dispatch(updateUserContext({ preferredLanguageId: currentLanguage.backLanguageId }));
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'USER_CONTEXT_FETCHED' });
      console.log('Error fetching user context');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getUserContext;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const updateUserContext = (() => {
  const lockAction = { type: 'USER_CONTEXT_UPDATING' };
  const urlConstructor = () => baseUrl.concat(contextUrl);

  const argConfig = {
    mobile: {
      toBody: true,
      toThen: true,
    },
    preferredLanguageCode: {
      toBody: true,
      toThen: true,
    },
    preferredLocationId: {
      toBody: true,
    },
    preferredLocation: {
      toThen: true,
    },
    allowContent: {
      toBody: true,
      toThen: true,
    },
    allowOffers: {
      toBody: true,
      toThen: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    202: async (_: {}, dispatch: Function, thenCustomArg: UpdateUserContextCustomArgs) => {
      await dispatch({
        type: 'USER_CONTEXT_UPDATE_ACCEPTED',
        mobile: thenCustomArg.mobile,
        preferredLanguageId: thenCustomArg.preferredLanguageId,
        preferredLocation: thenCustomArg.preferredLocation,
        communicationPreferences: {
          allowContent: thenCustomArg.allowContent,
          allowOffers: thenCustomArg.allowOffers,
        },
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'USER_CONTEXT_UPDATE_FINISHED' });
      console.log('Error attempting to update user context');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.updateUserContext;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const updateSiteAndContract = (() => {
  const lockAction = { type: 'ONBOARDING_STARTED' };
  const urlConstructor = () => baseUrl.concat(updateSiteAndContractUrl);

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const thenAction = async (_: {}, dispatch: Function, thenCustomArg: { siteId: string }) => {
    const { siteId } = thenCustomArg;

    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || '';
    await dispatch(refreshAccessToken(refreshToken));

    clearLocalServiceRequest();
    await dispatch(clearSiteContext());
    const contextResult = await dispatch(getUserContext());
    if (!contextResult.ok) return;

    //the change was not persisted yet (async processing of site update), wait and retry once
    if (!contextResult.responseData.site?.id || contextResult.responseData.site?.id !== siteId) {
      await waitSeconds(2);

      await dispatch(clearSiteContext());
      await dispatch(getUserContext());
    }

    await dispatch({ type: 'ONBOARDING_FINISHED' });
  };

  const then = {
    202: thenAction,
    204: thenAction,
    other: async (dispatch: Function) => {
      console.log('Error updating site and contract');
      await dispatch({ type: 'ONBOARDING_FINISHED' });
    },
  };

  const mock = shouldUseMockData ? mockResponse.updateSiteAndContract : null;
  return fetchJsonActionCreator({
    argConfig: updateSiteAndContractParams,
    preRequest,
    then,
    mock,
    lockAction,
  });
})();

export const registerPushNotification = (() => {
  const urlConstructor = () => apiPath.concat('/v2/pushnotifications/register');

  let argConfig = {
    contactId: {
      toHeaders: true,
    },
    notificationToken: {
      toBody: true,
      rename: 'pushChannel',
      toThen: true,
    },
    platform: {
      toBody: true,
      defaultValue: ({ deviceInfo }: { deviceInfo: DeviceInfo }) => deviceInfo.platform,
      toThen: true,
    },
    deviceUniqId: {
      toBody: true,
      rename: 'installationId',
      toThen: true,
    },
    notificationAppId: {
      toBody: true,
      rename: 'appId',
      defaultValue: () => process.env.REACT_APP_NOTIFICATION_APP_ID,
      toThen: true,
    },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    201: async (
      _: {},
      dispatch: Function,
      { appId, installationId, platform, pushChannel }: RegisterPushNotificationsCustomArg
    ) => {
      savePushNotificationRegistrationData({
        appId,
        installationId,
        platform,
        pushChannel,
        isSuccess: true,
        at: new Date(),
      });
      await dispatch({ type: 'PUSH_NOTIFICATION_REGISTERED' });
    },
    other: async (
      dispatch: Function,
      { appId, installationId, platform, pushChannel }: RegisterPushNotificationsCustomArg,
      error: Error
    ) => {
      savePushNotificationRegistrationData({
        appId,
        installationId,
        platform,
        pushChannel,
        isSuccess: false,
        at: new Date(),
        error,
      });
      console.error('Error registering push notification');
      await dispatch({ type: 'PUSH_NOTIFICATION_REGISTERED' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.registerPushNotification;
  }

  return fetchJsonActionCreator({
    argConfig,
    preRequest,
    then,
    mock,
  });
})();

export const unregisterPushNotification = (() => {
  const urlConstructor = () => apiPath.concat('/v2/pushnotifications/unregister');

  let argConfig = {
    deviceUniqId: {
      toBody: true,
      rename: 'installationId',
    },
    notificationAppId: {
      toBody: true,
      rename: 'appId',
      defaultValue: () => process.env.REACT_APP_NOTIFICATION_APP_ID,
    },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'DELETE',
    },
  };

  let then = {
    201: async (_: {}, dispatch: Function) => {
      await dispatch({ type: 'PUSH_NOTIFICATION_REGISTERED' });
    },
    other: async (dispatch: Function) => {
      console.error('Error when unregistering push notification');
      await dispatch({ type: 'PUSH_NOTIFICATION_REGISTERED' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.registerPushNotification;
  }

  return fetchJsonActionCreator({
    argConfig,
    preRequest,
    then,
    mock,
  });
})();

export const clearSiteContext = makeActionCreator('CLEAR_SITE_CONTEXT');

const { baseUrlWithVersion: legalDocsBaseUrlWithVersion, acknownledgeSuccessStatusCode } =
  getLegalDocsConfig();
export const getAcknowledgements = (() => {
  const lockAction = { type: 'ACKNOWLEDGMENTS_FETCHING' };
  const urlConstructor = () => `${legalDocsBaseUrlWithVersion}/legaldocuments/acknowledgements`;

  const argConfig = {};

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  let then = {
    200: async (json: GetAcknowledgementsSuccessResponse, dispatch: Function) => {
      await dispatch({
        type: 'ACKNOWLEDGMENTS_RETURNED',
        acknowledgements: json.acknowledgements,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'ACKNOWLEDGMENTS_ERROR' });
      console.error('Error fetching acknowledgements');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getAcknowledgements;
  }

  return fetchJsonActionCreator({ lockAction, argConfig, preRequest, then, mock });
})();

export const getAllLegalDocuments = (() => {
  const lockAction = { type: 'ALL_LEGAL_DOCUMENTS_FETCHING' };
  const urlConstructor = () => `${legalDocsBaseUrlWithVersion}/legaldocuments`;

  const argConfig = {
    geoCode: { toQueryString: true },
    languageCode: { toQueryString: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: GetAllLegalDocumentsSuccessResponse, dispatch: Function) => {
      await dispatch({
        type: 'ALL_LEGAL_DOCUMENTS_RETURNED',
        documents: json.documents,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'ALL_LEGAL_DOCUMENTS_ERROR' });
      console.log('Error fetching legal documents');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getAllLegalDocuments;
  }

  return fetchJsonActionCreator({ lockAction, argConfig, preRequest, then, mock });
})();

export const getOneLegalDocument = (() => {
  const lockAction = { type: 'ONE_LEGAL_DOCUMENTS_FETCHING' };
  const urlConstructor = ({ id }: { id: string }) =>
    `${legalDocsBaseUrlWithVersion}/legaldocuments/${id}`;

  const argConfig = {
    id: {},
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: GetOneLegalDocumentSuccessResponse, dispatch: Function) => {
      await dispatch({
        type: 'ONE_LEGAL_DOCUMENT_RETURNED',
        id: json.id,
        data: json.data,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'ONE_LEGAL_DOCUMENTS_ERROR' });
      console.log('Error fetching legal document content');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getOneLegalDocument;
  }

  return fetchJsonActionCreator({ lockAction, argConfig, preRequest, then, mock });
})();

export const getPublicLegalDocument = (() => {
  const lockAction = { type: 'ONE_PUBLIC_LEGAL_DOCUMENTS_FETCHING' };
  const urlConstructor = ({ id }: { id: string }) =>
    `${legalDocsBaseUrlWithVersion}/publicdocuments/${id}`;

  const argConfig = {
    id: {
      toBody: true,
      toThen: true,
    },
    geoCode: { toQueryString: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (
      json: GetOneLegalDocumentSuccessResponse,
      dispatch: Function,
      thenCustomArg: { id: string }
    ) => {
      await dispatch({
        type: 'ONE_PUBLIC_LEGAL_DOCUMENT_RETURNED',
        id: thenCustomArg.id,
        data: json.data,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'ONE_PUBLIC_LEGAL_DOCUMENTS_ERROR' });
      console.log('Error fetching legal document content');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getOneLegalDocument;
  }

  return fetchJsonActionCreator({ lockAction, argConfig, preRequest, then, mock });
})();
export const acknowledgeLegalDoc = (() => {
  const lockAction = { type: 'ACKNOWLEDGMENT_ACCEPTED_STARTED' };
  const urlConstructor = () => `${legalDocsBaseUrlWithVersion}/legaldocuments/acknowledgements`;

  const argConfig = {
    documentId: { toBody: true, toThen: true, toQueryString: true },
    documentTypeCode: { toThen: true }, // this is just to add the acknowledgement to the store when ACKNOWLEDGMENT_ACCEPTED
    acknowledgedOn: {
      defaultValue: () => {
        let dt = new Date();
        return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
      },
      toBody: true,
      toThen: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor: urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then: ThenConfiguration = {
    [acknownledgeSuccessStatusCode]: async (
      _: {},
      dispatch: Function,
      thenCustomArg: AcknowledgeLegalDocCustomArgs
    ) => {
      await dispatch({
        type: 'ACKNOWLEDGMENT_ACCEPTED',
        documentId: thenCustomArg.documentId,
        documentTypeCode: thenCustomArg.documentTypeCode,
        acknowledgedOn: thenCustomArg.acknowledgedOn,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: () => console.log('Error acknowledging legal document'),
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.acknowledgeLegalDoc;
  }

  return fetchJsonActionCreator({ lockAction, argConfig, preRequest, then, mock });
})();

export const logout = () => async (dispatch: Function) => {
  const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
  if (isPushNotificationsAvailable) {
    const [, deviceUuidId] = await Promise.all([
      PushNotifications.removeAllListeners(),
      Device.getId(),
    ]);
    await dispatch(
      unregisterPushNotification({
        deviceUniqId: deviceUuidId.identifier,
      })
    );
  }

  removeTokens();
  await CacheService.getInstance().flush();
  await AuditStateCacheService.getInstance().flush();
  await OfflineQueueService.getInstance().flush();
  defaultThemeSettings.length > 0 ? applyTheme(defaultThemeSettings) : removeTheme();
  await dispatch({ type: 'RESET_STATE' });
  await dispatch(baseApi.util.resetApiState());
  await dispatch(dspApi.util.resetApiState());
  removeSRLTutorial();
  removeSRTutorial();
};

export const clearErrors = { type: 'CLEAR_ERRORS' };

export const firstLoginCompleted = makeActionCreator(
  'FIRST_LOGIN_COMPLETED',
  'persistLocally'
)(persistLocallyUserSpecific);

//#todo remove this and rely on Sodexo Connect
export const resetPassword = (() => {
  const urlConstructor = () => apiPath.concat('/v2/support/passwordreset');
  let argConfig = {
    email: { toQueryString: true },
    languageCode: { toQueryString: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };
  let then = {
    200: async () => {
      //do nothing: redirection to the returned url is done chaining the promise
    },
    other: async () => console.log('Error getting psw reset url'),
  };

  const mock = shouldUseMockData ? mockResponse.resetPassword : null;

  return fetchJsonActionCreator({ argConfig, preRequest, then, mock });
})();

export const changePassword = (() => {
  const urlConstructor = () => apiPath.concat('/v2/user/password');
  const argConfig = {
    password: { toBody: true },
    old_password: { toBody: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };
  const then = {
    204: async () => {},
    other: async () => console.log('Error changing password'),
  };

  const mock = shouldUseMockData ? mockResponse.changePassword : null;

  return fetchJsonActionCreator({ argConfig, preRequest, then, mock });
})();

//tmp
export const initialize = (token: string) => {
  return {
    type: 'TMP',
  };
};

export const updateCommunicationPreferences = (() => {
  let urlConstructor = () => apiPath.concat('/v2/user/communicationpreferenceslite');

  let argConfig = {
    allowContent: { toBody: true, toThen: true },
    allowOffers: { toBody: true, toThen: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor: urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    202: async (
      _: {},
      dispatch: Function,
      thenCustomArg: UpdateCommunicationPreferencesCustomArgs
    ) => {
      await dispatch({
        type: 'COMMUNICATION_PREFERENCES_UPDATED',
        allowContent: thenCustomArg.allowContent,
        allowOffers: thenCustomArg.allowOffers,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: () => console.log('Error updating communication preferences'),
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.updateCommunicationPreferences;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, then, mock });
})();

const applyLang = makeActionCreatorV2(
  'APPLY_LANGUAGE',
  { code: 'code', isUserSelected: 'isUserSelected', persistLocally: persistLocallyNonUserSpecific },
  moduleId
);

export const applyLanguage = (args: ApplyLanguageAction) => {
  return (dispatch: Function) => {
    document.documentElement.lang = args.code.slice(0, 2);
    return dispatch(applyLang.call(this, args));
  };
};

export const resetLanguage = makeActionCreatorV2(
  'RESET_LANGUAGE',
  { code: 'code', isUserSelected: 'isUserSelected', persistLocally: persistLocallyNonUserSpecific },
  moduleId
);

export const getDefaultServices = makeActionCreator(
  'AVAILABLE_SERVICES_DEFINED',
  'services',
  'persistLocally'
);

export const getAvailableServices = (() => {
  const urlConstructor = () => baseUrl.concat(availableServicesUrl);

  const preRequest: PreRequestParams = {
    urlConstructor: urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: GetAvailableServicesSuccessResponse, dispatch: Function) => {
      const unionServices = [
        ...filterServicesBasedOnConfiguration(json.availableServices),
        ...availableDefaultServices.filter(
          (ds) => !json.availableServices.some((as) => as.name === ds.name)
        ),
      ];

      await ModulesManager().setupFromServices(unionServices);

      await dispatch({
        type: 'AVAILABLE_SERVICES_DEFINED',
        services: unionServices,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: () => console.log('Error updating available services'),
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getAvailableServices;
  }

  return fetchJsonActionCreator({ argConfig: availableServicesArgConfig, preRequest, then, mock });
})();

export const getTheme = (() => {
  const urlConstructor = () => baseUrl.concat(themeUrl);

  const argConfig = {};

  const preRequest: PreRequestParams = {
    urlConstructor: urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: GetThemeSuccessResponse, dispatch: Function) => {
      await dispatch({
        type: 'THEME_RETURNED',
        id: json.id,
        name: json.name,
        settings: json.settings,
        persistLocally: persistCoreLocallyNonUserSpecific,
      });
    },
    other: async () => console.log('Error fetching theme'),
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getTheme;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, then, mock });
})();

export const getPublicSites = (() => {
  let lockAction = { type: 'FETCHING_AVAILABLE_SITES_STARTED' };
  const urlConstructor = () => `${apiPath}/v3/public/available-sites`;

  let argConfig = {};

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  let then = {
    200: async (json: GetPublicSitesSuccessResponse, dispatch: Function) => {
      await dispatch({
        type: 'FETCHING_AVAILABLE_SITES_FINISHED',
        sites: json.sites,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching sites');
      await dispatch({
        type: 'FETCHING_AVAILABLE_SITES_FAILED',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getPublicSites;
  }

  return fetchJsonActionCreator({
    argConfig,
    preRequest,
    lockAction,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const checkEmailExists = (() => {
  const lockAction = { type: 'EMAIL_EXIST_CHECK' };
  const urlConstructor = () => `${apiPath}/v3/registrations/email-exists`;

  const argConfig = {
    email: { toQueryString: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (_: {}, dispatch: Function) => {
      await dispatch({
        type: 'EMAIL_EXIST_CHECK_SUCCESS',
      });
    },
    404: async (_: {}, dispatch: Function) => {
      await dispatch({
        type: 'EMAIL_EXIST_CHECK_SUCCESS',
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error checking email exists');
      await dispatch({
        type: 'EMAIL_EXIST_CHECK_FAILURE',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse['email-exists'];
  }

  return fetchJsonActionCreator({
    argConfig,
    preRequest,
    lockAction,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const sendSupportEmail = (() => {
  const lockAction = { type: 'SEND_SUPPORT_EMAIL_REQUEST' };
  const urlConstructor = () => `${apiPath}/v1/support/email`;

  const argConfig = {
    fullName: { toBody: true },
    email: { toBody: true },
    village: { toBody: true },
    employer: { toBody: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    202: async (_: {}, dispatch: Function) => {
      await dispatch({
        type: 'SEND_SUPPORT_EMAIL_SUCCESS',
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({
        type: 'SEND_SUPPORT_EMAIL_FAILURE',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.sendSupportEmail;
  }

  return fetchJsonActionCreator({
    argConfig,
    preRequest,
    lockAction,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const sendForbiddenEmail = (() => {
  const lockAction = { type: 'SEND_FORGOTTEN_EMAIL_REQUEST' };

  const urlConstructor = () => {
    return `${process.env.REACT_APP_DSP_API_HOST}/v1/account/password/send-reset-email`;
  };

  const argConfig = {
    email: {
      toThen: true,
      toBody: true,
    },
    code: {
      toBody: true,
      defaultValue: () => process.env.REACT_APP_APP_NAME,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    202: async (_: {}, dispatch: Function, { email }: { email: string }) => {
      await dispatch({
        type: 'SEND_FORGOTTEN_EMAIL_SUCCESS',
        email,
      });
    },
    other: async (dispatch: Function, { email }: { email: string }) => {
      await dispatch({
        type: 'SEND_FORGOTTEN_EMAIL_FAILURE',
        email,
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.sendForbiddenEmail;
  }

  return fetchJsonActionCreator({
    argConfig,
    preRequest,
    lockAction,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const resetEmail = (() => {
  const lockAction = { type: 'RESET_EMAIL_REQUEST' };

  const urlConstructor = () => {
    return `${process.env.REACT_APP_DSP_API_HOST}/v1/account/password/reset`;
  };

  const argConfig = {
    id: {
      toBody: true,
    },
    securityCode: {
      toBody: true,
    },
    password: {
      toBody: true,
    },
    code: {
      toBody: true,
      defaultValue: () => process.env.REACT_APP_APP_NAME,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'PUT',
    },
  };

  const then = {
    202: async (_: {}, dispatch: Function) => {
      dispatch({
        type: 'RESET_EMAIL_SUCCESS',
      });
    },
    other: async (dispatch: Function) => {
      dispatch({
        type: 'RESET_EMAIL_FAILURE',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.resetEmail;
  }

  return fetchJsonActionCreator({
    argConfig,
    preRequest,
    lockAction,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const getAppVersionInfo = (() => {
  let lockAction = { type: 'GET_APP_VERSION_REQUEST' };
  const urlConstructor = () => {
    return `${process.env.REACT_APP_DSP_API_HOST}/v1/appupdate`;
  };

  let argConfig = {
    appId: { toQueryString: true },
    platform: { toQueryString: true },
    appVersion: { toQueryString: true },
    contractId: { toQueryString: true },
    siteId: { toQueryString: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  let then = {
    200: async (json: {}, dispatch: Function) => {
      await dispatch({
        type: 'GET_APP_VERSION_SUCCESS',
        appVersionInfo: json,
      });
    },
    204: async (json: {}, dispatch: Function) => {
      await dispatch({
        type: 'GET_APP_VERSION_NO_INFORMATION',
        appVersionInfo: json,
      });
    },
    other: async (dispatch: Function) => {
      console.log('Error fetching app version information');
      await dispatch({ type: 'GET_APP_VERSION_FAILURE' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getAppVersion;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();
