import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setTable } from '../actions';
import SuccessOrder from '../components/SuccessOrder';
import { StateWithOrder } from '../types/orderState.types';

const mapStateToProps = (state: StateWithOrder) => {
  const lastOrder = state.Order.lastOrder;
  const facilityName = lastOrder ? state.Facilities.data[lastOrder?.facilityId ?? '']?.title : '';

  return { lastOrder, facilityName };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetTableNumber: () => dispatch(setTable()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessOrder);
