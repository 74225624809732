const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Sizin isteğiniz',
    'Ref: Summary': 'Özet',
    'Ref: Request category': 'Talep Kategorisi',
    'Ref: Person affected': 'Bu talepten etkilenen kişi',
    'Ref: Your phone number': 'Telefon numarası',
    'Ref: Description': 'Tanım',
    'Ref: Service Requests': 'Hizmet İstekleri',
    'Ref: Request Service': 'Servis Talep',
    'Ref: Success title': 'İsteğiniz oluşturuldu',
    'Ref: Error title': 'Oops!',
    'Ref: Error subtitle': 'Bir hata oluştu. Lütfen tekrar deneyin.',
    'Ref: Try again': 'Tekrar deneyin',
    'Ref: you pronoun': '(sen)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Burası sizin işyeri destek merkeziniz',
    'Ref: Tutorial: tabname': 'Talepler',
    'Ref: Tutorial: body':
      'Size yardımcı olmak için buradayız. Lütfen her giriş için bir talep kaydedin.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      '{Sitename} sitesinde hizmet talep ediyorsunuz.',
    'Ref: Building': 'Bina',
    'Ref: Room': 'Oda',
    'Ref: Description help text':
      'Ne kadar ayrıntılı olursanız, sizi o kadar iyi destekleyebiliriz.',
    'Ref: Next': 'Sonraki',
    'Ref: Your request': 'İsteğin',
    'Ref: PhoneNumber error msg': 'Telefon numarası geçerli değil',
    'Ref: No affected person results': 'İstenen kriterleri eşleştiren kimse',
    'Ref: Required': '* Gerekli',
  },
  Summary: {
    'Ref: Location': 'Konum',
    'Ref: Attachment': 'Ekler',
    'Ref: Submit': 'Göndermek',
    'Ref: No affected person results': 'İstenen kriterlere uyan kişi yok',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Hizmet talebi',
    'Ref: createdOn': 'ON ON',
    'Ref: summary': 'Özet',
    'Ref: category': 'Talep Kategorisi',
    'Ref: request raised for': 'Bu talepten etkilenen kişi',
    'Ref: phoneNumber': 'Telefon numarası',
    'Ref: location': 'Telefon numarası',
    'Ref: description': 'Tanım',
    'Ref: attachments': 'Ekler',
  },
};
export default labels;
