const labels = {
  All: {
    'Ref: Assembled': 'Összeszerelt',
    'Ref: Submitted': 'Benyújtott',
    'Ref: Fulfilled': 'Teljesített',
    'Ref: Completed': 'Befejezett',
    'Ref: Paid': 'Fizetett',
    'Ref: Approved': 'Jóváhagyott',
    'Ref: Ready For Pickup': 'Elvitelre kész',
    'Ref: Delivered': 'Kiszállított',
    'Ref: Received': 'Beérkezett',
    'Ref: Rejected': 'Elutasított',
    'Ref: Payment Error': 'Fizetési hiba',
    'Ref: Pending Payment': 'függő fizetés',
    'Ref: Preparing': 'Előkészítés',
    'Ref: Refunded': 'Visszatérített',
    'Ref: Cancelled By User': 'A felhasználó törli',
    'Ref: Refund Requested': 'A visszatérítés kért',
    'Ref: Refund Failed': 'A visszatérítés sikertelen',
    'Ref: Payment Processing': 'Fizetés feldolgozása',
    'Ref: Not Paid': 'Nem fizetett',
    'Ref: Total:': 'Teljes:',
    'Ref: Pickup': 'Felvenni',
    'Ref: Eat-in': 'Étkezési',
    'Ref: Scan&Go': 'Szkennelj és menj',
    'Ref: Fulfillment Type': 'Teljesítési típus',
    'Ref: Delivery': 'Kézbesítés',
    'Ref: You have no future orders': 'Nincs jövőbeli megrendelése',
    'Ref: You have no past orders': 'Nincsenek korábbi megrendeléseid',
    'Ref: You have no orders': 'Nincs megrendelése',
    'Ref: All orders': 'Minden megrendelés',
    'Ref: Not past orders': 'Ellenőrző megrendelések',
    'Ref: Past orders': 'Korábbi parancsok',
    'Ref: Vegan': 'Vegán',
    'Ref: Vegeterian': 'Vegetáriánus',
    'Ref: See item': 'Lásd',
    'Ref: Activated': 'Aktív',
    'Ref: No suggestions': 'Nincsenek javaslatok',
    'Ref: Get your receipt': 'Szerezd meg a nyugtát',
    'Ref: Print': 'Nyomtassa ki a nyugtát',
    'Ref: Email': 'Kapja meg a nyugtát e-mailben',
    'Ref: Receipt Modal message': 'Hogyan szeretné megszerezni a nyugtát?',
    'Ref: Confirm': 'megerősít',
    'Ref: Your email address': 'az email címed',
    Suggestion: 'Javaslat',
    'Ref: Loyalty Reward': 'Hűség jutalom',
    'Ref: Equation for stamps per item': '1 vásárlás = 1 bélyegző',
    'Ref: Paid by employer': 'A munkáltató által fizetett összeg',
    'Ref: Paid by user': 'Az Ön által fizetett összeg',
  },
  ProductsList: {
    'Ref: Page title': 'Terméklista',
    'Ref: Facility details': 'Létesítmény részletei',
    'Ref: Search products list': 'Keresési elemek',
    'Ref: filter label: menus': 'Menü',
    'Ref: filter label: moment': 'Pillanat',
    'Ref: filter label: category': 'Kategória',
    'Ref: all': 'Összes',
    'Ref: today': 'Ma',
    'Ref: tomorrow': 'Holnap',
    'Ref: see more': 'Mutass többet',
    'Ref: see less': 'Kevesebbet lát',
    'Ref: reset': 'Visszaállítás',
    'Ref: View in cart': 'Kilátás a kosárban',
    'Ref: You have changed location': 'Megváltoztatta a helyét',
    'Ref: You are now at': 'Most vagy',
    'Ref: Menu card': 'Menütkártya',
    'Ref: Table': 'Táblázatszám',
    'Service unavailable': 'A szolgáltatás nem elérhető',
    'Ref: Ordering unavailable':
      'Óvatos! Jelenleg nem érhető el ezen a webhelyen megrendelés, kérjük, próbálja meg később.',
    'Ref: Filters': 'Szűrők (allergének és még sok más)',
  },
  ProductScanner: {
    'Ref: cart': 'Kocsi',
    'Ref: Product Scanned':
      'hozzáadták a kosárhoz. Folytathatja a vonalkódok szkennelését, hogy hozzáadja őket a kosárba.',
    'Ref: Scan or adjust':
      'hozzáadták a kosárhoz. Állítsa be a mennyiséget, vagy szkenneljen egy másik terméket.',
    'Ref: Product Not Matched': 'A vonalkódot nem ismerték fel. Kérlek próbáld újra',
    'Ref: Scan article code': 'Vonalkód beolvasása',
    'Ref: Native error product cannot be added to cart':
      'A különböző menüből vagy napi alkatrészből származó elem nem adható hozzá a kosárba',
    'Ref: product scanner placeholder': 'Kérjük, írja be a vonalkódot, hogy hozzáadja a kosárhoz',
  },
  Order: { 'Ref: Page title': 'Rendelés' },
  MenuSelector: {
    'Ref: Menu Page title': 'menük',
    'Ref: today': 'Ma',
    'Ref: tomorrow': 'Holnap',
    'Ref: Search products list': 'Keresési elemek',
    'Ref: You have changed location': 'Megváltoztatta a helyét',
    'Ref: You are now at': 'Most vagy',
  },
  ProductDetails: {
    'Ref: Page title': 'termék leírás',
    'Ref: Serving Size': 'Adag',
    'Ref: Nutrition': 'Tápanyag-információ',
    'Ref: Nutrition adults': 'A felnőtteknek naponta 2000 kcalra van szükségük',
    'Ref: Nutrition Calories': 'Kalóriák',
    'Ref: Nutrition Calcium': 'Kalcium',
    'Ref: Nutrition Fat': 'Zsír',
    'Ref: Nutrition Saturated Fat': 'Telített zsír',
    'Ref: Nutrition Carbohydrates': 'Szénhidrát',
    'Ref: Nutrition Sugar': 'Cukor',
    'Ref: Nutrition Protein': 'Fehérje',
    'Ref: Nutrition Sodium': 'Nátrium',
    'Ref: Nutrition cholesterol': 'Koleszterin',
    'Ref: Nutrition dietaryFiber': 'Élelmi rost',
    'Ref: Nutrition iron': 'Vas',
    'Ref: Nutrition potassium': 'Kálium',
    'Ref: Nutrition vitaminA': 'A-vitamin',
    'Ref: Nutrition vitaminC': 'C vitamin',
    'Ref: Additives': 'Adalékanyagok',
    'Ref: Emission': 'CO2-kibocsátás',
    'Ref: This product generates': 'Ez a termék generál',
    'Ref: of CO2': 'A CO2',
    'Ref: emissions, which equals a': 'kibocsátás, amely egyenlő a',
    'Ref: car trip': 'autóút',
    'Ref: Ingredients': 'Recept összetevő',
    'Ref: Allergens': 'Allergének',
    'Ref: Always there': 'Mindig ott van',
    'Ref: Allergens selected': 'A szűrőn kiválasztott allergének:',
    'Ref: minimum required': 'minimum szükséges',
    'Ref: you have to add at least': 'Legalább hozzá kell adnia',
    'Ref: ingredients': 'összetevők',
    'Ref: Ingredient': 'hozzávaló',
    'Ref: Free': 'Ingyenes',
    'Ref: Based on customization': 'A testreszabás alapján',
    'Ref: Additives eggs': 'tojás',
    'Ref: Additives fish': 'hal',
    'Ref: Additives milk': 'tej',
    'Ref: Additives nuts': 'diófélék',
    'Ref: Additives wheat': 'búza',
    'Ref: Additives peanuts': 'földimogyoró',
    'Ref: Additives soy beans': 'szójabab',
    'Ref: Additives sesame seeds': 'szezámmag',
    'Ref: Review': 'Felülvizsgálat',
    'Ref: Per portion': 'Részben',
    'Ref: Per 100g': '100 g',
    'Ref: Allergen selected': 'A keresési szűrőn kiválasztott allergén',
    'Ref: Fitbit Log': 'Jelentkezzen be a Fitbitbe',
    'Ref: age verification required on delivery': 'A kézbesítéskor szükséges életkor -ellenőrzés',
    'Ref: alcohol content notification':
      'Ha elhelyezi és megrendeli ezeket az elemeket, kijelenti, hogy 18 éves vagy annál idősebb. Az alkoholfogyasztás veszélyes az egészségére.',
    'Ref: default': 'Mulasztás',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} Megszerezve, ha megrendeli ezt a terméket',
    'Ref: Loyalty Card Description':
      'A {stamp_form} hozzáadódik a következő hűséghez {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'A Hűségbélyegek programja lehetővé teszi a bélyegek gyűjtését a jutalmakhoz.',
    'Ref: Loyalty Modal Description':
      'Amikor vásárol <B> {product_name}, 1 bélyeg </b> hozzáadódik a <b> {scheme_names} </b> {program_form} -hez',
    'Ref: and': 'és',
    'Ref: Stamp': 'Bélyeg',
    'Ref: Stamps': 'Bélyegek',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programok',
    'Ref: Loyalty Stamps': 'Hűségbélyegek',
    'Ref: See Loyalty Stamps': 'Lásd a hűségbélyegeket',
    Favorite: 'Kedvenc',
    'Ref: In favorites':
      'A termék már szerepel a kedvencek listájában. Kattintson a eltávolításhoz.',
    'Ref: Not in favorites':
      'A termék még nem szerepel a kedvencek listájában. Kattintson a hozzáadáshoz.',
    'Ref: Added to favorites': 'A terméket hozzáadták a Kedvencek listájához.',
    'Ref: Removed from favorites': 'A terméket eltávolították a Kedvencek listájáról.',
    'Ref: From': 'Tól től',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Változás a kosárban',
    'Ref: Popup header for customizable item qty update': 'Ez az elem testreszabható',
    'Ref: add new version': 'Új verzió hozzáadása',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Frissítse a testreszabható elem mennyiségét közvetlenül a kosárból.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Frissítse a testreszabható elem mennyiségét közvetlenül a kosárból, vagy adjon hozzá egy új verziót.',
    'Ref: another context body':
      'Tételek hozzáadásához ebből a webhelyről, létesítményről, pillanatról/menüből vagy dátumról, hogy törölje a kosár',
    'Ref: cancel': 'Megszünteti',
    'Ref: add to cart': 'Kosárba',
    'Ref: view in cart': 'Kocsi',
    'Ref: Products in cart': 'A {Product_name} számának száma a kosárban: {Product_quantity}',
    'Ref: Customize': 'Testreszab',
    'Ref: Add': 'Hozzáad',
    'Ref: Free': 'Ingyenes',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Megszünteti',
    'Ref: notice': 'Értesítés',
    'Ref: clear cart': 'Tiszta kocsi',
    'Ref: Cart addition error message':
      'Jelenleg a {from_current} carta -ban van eleme. Tételek hozzáadásához a {from_selected}',
  },
  Cart: {
    'Ref: Page title': 'Kocsi',
    'Ref: The cart is empty': 'A kocsi üres',
    'Ref: Clear cart': 'Tiszta kocsi',
    'Ref: Continue shopping': 'Folytatni a vásárlást',
    'Ref: Start shopping': 'Kezdjen vásárolni',
    'Ref: Today': 'Ma',
    'Ref: Tomorrow': 'Holnap',
    'Ref: Tax:': 'Adó:',
    'Ref: Promotion:': 'Promóció:',
    'Ref: Total without promotion:': 'Összesen promóció nélkül:',
    'Ref: Delivery:': 'Szállítás:',
    'Ref: Pickup informations': 'Felvételi információk',
    'Ref: Location': 'Elhelyezkedés',
    'Ref: Time': 'Idő',
    'Ref: Phone': 'A telefonszámod',
    'Ref: Instructions': 'Utasítás',
    'Ref: Please select': 'Kérlek, válassz',
    'Ref: Enter special requests': 'Írja be a különleges kéréseket ide',
    'Ref: notice': 'Értesítés',
    'Ref: Product is no more available':
      'Sajnálom, de úgy tűnik, hogy valamilyen termék nem áll rendelkezésre. Kérjük, ellenőrizze a kocsiját.',
    'Ref: ok': 'Rendben',
    'Ref: Validate': 'Érvényesítse a megrendelésemet',
    'Ref: Pay now': 'Fizess most',
    'Ref: Charge to my department': 'Vádem az osztályomnak',
    'Ref: Pay on pickup': 'Fizessen ezekért a tételekért',
    'Ref: Accept terms of sale prefix': 'elfogadom',
    'Ref: Accept terms of sale': 'Ezek az értékesítés feltételei',
    'Ref: Continue': 'Folytatni',
    'Ref: Please login or sign up to place an order':
      'Kérjük, jelentkezzen be, vagy iratkozzon fel a megrendelés elhelyezésére',
    'Ref: Accept terms of sale suffix': ', és elolvastam és megértettem őket.',
    'Ref: Payment modality': 'Fizetési mód',
    'Ref: Other information': 'Egyéb információk',
    'Ref: Eat-in': 'Étkezési',
    'Ref: Pickup': 'Felvenni',
    'Ref: Table': 'Táblázatszám',
    'Ref: Required fields': 'Szükséges Mezők',
    'Service unavailable': 'A szolgáltatás nem elérhető',
    'Ref: Cannot order':
      'Sajnálom, jelenleg nem tehet megrendelést ezen a webhelyen. Kérjük, vegye fel a kapcsolatot az étteremmel, ahol további információt szeretne kapni a nyitvatartási órákról és a rendelkezésre álló szolgáltatásokról.',
    'Ref: Order Unavailable':
      'A választott dátum megrendelése, a teljesítés típusa és helye már nem érhető el!',
    'Ref: No Availability': 'Nincs több elérhetőség',
    'Ref: Continue on your device': 'Folytassa a készüléket',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} Megkeresés, ha teljesíti a megrendelését',
    'Ref: Loyalty Card Description':
      'A {stamp_form} hozzáadódik a következő hűséghez {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'A Hűségbélyegek programja lehetővé teszi a bélyegek gyűjtését a jutalmakhoz.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> Megszerezve, ha teljesíti a megrendelést:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> hozzáadott <b> {scheme_names} </b>',
    'Ref: Stamp': 'Bélyeg',
    'Ref: Stamps': 'Bélyegek',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programok',
    'Ref: Loyalty Stamps': 'Hűségbélyegek',
    'Ref: See Loyalty Stamps': 'Lásd a hűségbélyegeket',
    'Ref: Loyalty Info': 'Hűséginformáció',
    'Ref: Email address': 'Email cím',
    'Ref: and': 'és',
    'Ref: Order minimum amount': 'Sajnálom, a minimális fizetési összeg {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Folytassa a készüléket',
    'Ref: Scan QR to continue':
      'Kérjük, írja be a QR -kódot, hogy folytassa az eszközt az eszközön.',
  },
  OrderHistory: { 'Ref: Orders': 'Megrendelés', 'Ref: Page Title': 'rendeléseim' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Egységár',
    'Ref: Download': 'Töltse le a nyugtát',
    'Ref: Download error': 'Letöltési hiba történt. Próbálja meg később letölteni a nyugtát',
    'Ref: Order details title': 'Rendelési részletek',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Köszönöm! Megrendelését most megerősítik',
    'Ref: Text for pickup':
      'A megrendelés előzményei révén követheti annak állapotát. Ha az értesítések bekapcsolnak, akkor frissítjük Önt, amikor készen áll a felvételre.',
    'Ref: Success Order Title': 'köszönöm a rendelését',
    'Ref: Success Order Description':
      'Kérjük, fizessen a pénztárnál a megrendelés érvényesítéséhez.',
    'Ref: Success Order ID': 'Személyi igazolvány',
    'Ref: Success Order Action': 'Új rend',
    'Ref: Text for dinein':
      'Megrendelését hamarosan az asztalhoz kell kiszolgálni. Csak kérdezze meg barátságos alkalmazottunk bármelyikét az étkezési rendről.',
    'Ref: Text for scan&go': 'Megrendelése elérhető a megrendelés előzményeiben',
    'Ref: Text for delivery':
      'Nyomon követheti annak állapotát a megrendelés előzményeiben. Ha az értesítések engedélyezve vannak, akkor tájékoztatjuk Önt, amikor a megrendelés készen áll.',
    'See details': 'Lásd a részleteket',
    'Go to home': 'Menj haza',
  },
  FailOrder: {
    'Go to home': 'Menj haza',
    'Ref: Kiosk Fail Title': 'Hoppá! Valami elromlott!',
    'Ref: Kiosk Fail Description':
      'Sajnos a megrendelése nem volt sikeres. Kérjük, próbálkozzon újra, vagy kérje meg egy alkalmazottat, hogy segítsen Önnek.',
    'Ref: Kiosk Fail Back': 'Vissza az előző oldalra',
    'Ref: Kiosk Fail Action': 'Törölje a munkamenetet, és rendeljen újra',
    'Ref: Error in cart validation':
      'Hiba történt a megrendelés során. Kérjük, vegye fel a kapcsolatot a létesítménnyel a probléma megoldása érdekében.',
    'Facility contact': 'Létesítmény kapcsolattartó',
    'Ref: Ordering failed': 'A megrendelés sikertelen',
    'Ref: Order confirmed': 'Rendelés Megerősítve',
  },
  TableCheckIn: {
    'Welcome at': 'Üdvözöljük a',
    Table: 'Asztal:',
    'Ref: list title': 'A mai legújabb megrendelések',
    'See the menu': 'Lásd a menüt',
    'Table Check-in': 'Bejelentkezés',
    'Ref #': 'Ref #',
    Checked: 'Ellenőrzött',
    'Check in': 'Bejelentkezik',
    'See details': 'Lásd a részleteket',
    'Ref: You have changed location': 'Megváltoztatta a helyét',
    'Ref: You are now at': 'Most vagy',
    'Order number': 'Rendelésszám',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'A promóció alkalmazott',
    'Ref: Promotion applied description': 'A kosár promóciója előnyös.',
    'Ref: Promotion deleted title': 'A promóció törlése',
    'Ref: Promotion deleted description':
      'Már nem haszonnal jár a promóció, mert már nem tölti ki a feltételeket, vagy a promóció lejárt.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Szkennelj és menj', 'Ref: View only': 'Csak megtekintésre' },
  ProductTile: {
    'Ref: Allergens': 'Allergének',
    'Ref: Presence of allergen': 'Allergén jelenléte',
    'Ref: Scan': 'Letapogatás',
    'Ref: From': 'Tól től',
    'Ref: Already in product favorite list. Click to remove it.':
      'Már a termék kedvenc listájában. Kattintson a eltávolításhoz.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Jelenleg nincs hozzáadva a termék kedvenc listájához. Kattintson a hozzáadáshoz.',
    'Ref: Free': 'Ingyenes',
  },
  OrderSummary: {
    'Ref: item': 'tétel',
    'Ref: items': 'tárgyak',
    'Ref: Clear': 'Egyértelmű',
    'Ref: Cart': 'Kocsi',
    'Ref: Subtotal': 'Altotikus',
    'Ref: Total': 'Teljes',
    'Ref: Tax': 'Adó',
    'Ref: Promotion': 'promóció',
    'Ref: Total without promotion': 'Összesen promóció nélkül',
    'Ref: Number of product in cart': 'A termék száma a kosárban',
    'Ref: Delivery': 'Kézbesítés',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegán', 'Ref: Vegeterian': 'Vegetáriánus' },
  OrderInfoCard: {
    'Ref: Header': 'Elrendelte',
    'Ref: from': 'tól től',
    'Ref: location': 'Elhelyezkedés',
    'Ref: Phone': 'A telefonszámod',
    'Ref: Instructions': 'Utasítás',
    'Ref: Order type': 'Rendelési típus',
    'Ref: Pickup': 'Felvenni',
    'Ref: Eat-in': 'Étkezési',
    'Ref: Scan&Go': 'Szkennelj és menj',
  },
  ReorderWidget: {
    'Ref: No items': 'Még nincs megrendelés. Vásárolj most!',
    'Ref: Title': 'Újra elrendel',
  },
  MenusWidget: { 'Ref: no menu': 'Jelenleg nincs menü', 'Ref: Modal title': 'Menü a napra' },
  PromotionInfo: { 'Ref: Discounts available': 'Kapható kedvezmények' },
  PromotionInfoItem: { 'Ref: Activate': 'Aktiválja' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'A rendeléseid', 'Ref: Widget Title': 'rendeléseim' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Javaslat a napra',
    'Ref: Any menu': 'Bármely menü',
    Other: 'Egyéb',
    'Ref: Suggestions calendar button': 'Javaslatok naptár',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Adag',
    'Ref: Nutrition': 'Tápanyag-információ',
    'Ref: Nutrition adults': 'A felnőtteknek naponta 2000 kcalra van szükségük',
    'Ref: Nutrition Calories': 'Kalóriák',
    'Ref: Nutrition Calcium': 'Kalcium',
    'Ref: Nutrition Fat': 'Zsír',
    'Ref: Nutrition Saturated Fat': 'Telített zsír',
    'Ref: Nutrition Carbohydrates': 'Szénhidrát',
    'Ref: Nutrition Sugar': 'Cukor',
    'Ref: Nutrition Protein': 'Fehérje',
    'Ref: Nutrition Sodium': 'Nátrium',
    'Ref: Nutrition cholesterol': 'Koleszterin',
    'Ref: Nutrition dietaryFiber': 'Élelmi rost',
    'Ref: Nutrition iron': 'Vas',
    'Ref: Nutrition potassium': 'Kálium',
    'Ref: Nutrition vitaminA': 'A-vitamin',
    'Ref: Nutrition vitaminC': 'C vitamin',
    'Ref: Ingredients': 'Recept összetevő',
    'Ref: Allergens': 'Allergének',
    'Ref: Always there': 'Mindig ott van',
    'Ref: Allergens selected': 'A szűrőn kiválasztott allergének:',
    'Ref: minimum required': 'minimum szükséges',
    'Ref: ingredients': 'összetevők',
    'Ref: Ingredient': 'hozzávaló',
    'Ref: Free': 'Ingyenes',
    'Ref: Based on customization': 'A testreszabás alapján',
    'Ref: Additives eggs': 'tojás',
    'Ref: Additives fish': 'hal',
    'Ref: Additives milk': 'tej',
    'Ref: Additives nuts': 'diófélék',
    'Ref: Additives wheat': 'búza',
    'Ref: Additives peanuts': 'földimogyoró',
    'Ref: Additives soy beans': 'szójabab',
    'Ref: Additives sesame seeds': 'szezámmag',
    'Ref: Review': 'Felülvizsgálat',
    'Ref: Per portion': 'Részben',
    'Ref: Per 100g': '100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} ingyen',
    'Ref: Number of free items': 'Ingyenes tételek száma: {number_of_free_items}',
    'Ref: Allergen selected': 'A keresési szűrőn kiválasztott allergén',
    'Ref: Nutritional info': 'Táplálkozási információk',
    'Ref: Info': 'Információ',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'akciók' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Válassza ki a munkáját',
    'Ref: Validate': 'Érvényesít',
    'Ref: Pricing Tiers dialog message': 'Elismerték az e -mail címével',
    'Ref: Please select your job in the company': 'Kérjük, válassza ki a munkáját a vállalatban',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Felvenni',
    'Ref: Eat-in': 'Étkezési',
    'Ref: Scan&Go': 'Szkennelj és menj',
    'Ref: Delivery': 'Kézbesítés',
    'Ref: Total': 'Teljes',
    'Ref: How was facility name service': 'Hogyan volt a {facility_name}} szolgáltatás?',
    'Ref: How do you rate the service': 'Hogyan értékeli a szolgáltatást?',
  },
};
export default labels;
