import { act, renderHook } from '@testing-library/react';

import { useGetMenusForSearch } from './useGetMenusForSearch';

const siteId = 'siteId1';
const mockGetMenus = jest.fn();
const mockGetMenusResult = jest.fn();

jest.mock('@/modules/Order/api', () => ({
  useLazyGetMenusQuery: () => [mockGetMenus, mockGetMenusResult()],
}));

const mockUseGetService = jest.fn();
jest.mock('@/helpers/hooks/useGetService/useGetService', () => ({
  useGetService: () => mockUseGetService(),
}));

describe('useGetMenusForSearch', () => {
  describe('when neither menu or order service not available', () => {
    beforeEach(() => {
      mockGetMenusResult.mockReturnValue({ isLoading: false });
      mockUseGetService.mockReturnValue(undefined);
    });

    it('must not load menus', async () => {
      const { result } = await act(() => renderHook(() => useGetMenusForSearch(siteId)));

      expect(result.current.isLoading).toBe(false);
      expect(result.current.menus.length).toBe(0);
      expect(mockGetMenus).not.toBeCalled();
    });
  });

  describe('when order service available', () => {
    beforeEach(() => {
      mockGetMenusResult.mockReturnValue({ isLoading: true });
      mockUseGetService.mockReturnValue({});
      mockGetMenus.mockResolvedValue({ data: [{ name: 'menu1' }, { name: 'menu2' }] });
    });

    it('should show isLoading equal to true, when menus are loaded', async () => {
      const { result } = await act(() => renderHook(() => useGetMenusForSearch(siteId)));

      expect(result.current.isLoading).toBe(true);
    });

    it('should return menus', async () => {
      const { result } = await act(() => renderHook(() => useGetMenusForSearch(siteId)));

      expect(result.current.menus.length).toBe(2);
      expect(result.current.menus[0].name).toBe('menu1');
    });
  });
});
