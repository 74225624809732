import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import ProductsList from '../ProductsList';

const props = {
  site: { id: 'siteId', currency: { isoCode: 'USD' } },
  facility: { id: 'facilityId' },
  label: (value: string) => value,
};

const menus = [
  {
    id: '42982bcf-6ae1-ec11-b5cf-0003ff857a05',
    name: 'Brockman 4 Concept + Crib',
    dayparts: [
      {
        name: 'Lunch',
        sections: [
          {
            id: '',
            name: 'Created by You (MYO)',
            items: [
              {
                id: 'c83be8e8-6ae1-ec11-bb3d-00224892f217',
                name: 'Roast Pork Mustard and Coleslaw Roll',
                description: '',
                price: '',
                displayPrice: '',
                priceInText: '',
                category: 'Created by You (MYO)',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uOMId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Created for You (Grab and Go)',
            items: [
              {
                id: '7c83e0ee-6ae1-ec11-bb3d-00224892f217',
                name: 'Created Vietnamese Noodle Bowl',
                description: '',
                price: '',
                displayPrice: '',
                priceInText: '',
                category: 'Created for You (Grab and Go)',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uOMId: 0,
              },
            ],
          },
        ],
        date: '2022-06-20T00:00:00Z',
      },
      {
        name: 'Dinner',
        sections: [
          {
            id: '',
            name: 'Created by You (MYO)',
            items: [
              {
                id: '673be8e8-6ae1-ec11-bb3d-00224892f217',
                name: 'Mediterranean Veg Cheese and Pesto Focaccia',
                description: 'Mediterranean description',
                price: '6.00',
                displayPrice: '',
                priceInText: '',
                category: 'Created by You (MYO)',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uOMId: 0,
              },
            ],
          },
        ],
      },
    ],
    pickupspotreferenceid: 'a06ad89f-bdd2-e811-8199-e0071b67ccf1',
  },
];

jest.mock('../../../api/api', () => ({
  useGetMenusQuery: () => ({
    data: { menus },
    isLoading: false,
  }),
  useGetFoodFacilitiesQuery: () => ({
    data: {},
    isLoading: false,
  }),
}));

jest.mock('../../../../../components/organisms/Tile', () => ({ title }: any) => (
  <div data-testid="menu-item">{title}</div>
));

describe('ProductsList', () => {
  afterAll(() => cleanup());

  describe('Initial render', () => {
    let menuItems: HTMLElement[];

    renderComponent(ProductsList, { ...props });

    beforeEach(() => {
      menuItems = screen.getAllByTestId(/menu-item/i);
    });

    it('should display correct number of menu items', () => {
      expect(menuItems.length).toEqual(1);
    });
  });
});
