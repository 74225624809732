const labels = {
  All: { 'Ref: Page title': 'サプライヤー' },
  SuppliersList: {
    'Ref: Supplier details': '詳細を見る',
    'Missing supplier list': 'サプライヤーは見つかりませんでした。',
    'Try again later': '時間を空けてもう一度お試しください',
  },
  SupplierDetails: {
    'Address label': '住所：',
    'Website label': 'Webサイト：',
    'Details label': '詳細情報',
  },
};
export default labels;
