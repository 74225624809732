const labels = {
  All: { 'Ref: stamp progress': '走向下一张邮票', Reward: '报酬', Rewards: '奖励' },
  LoyaltyStamps: {
    'Ref: Page title': '邮票',
    'Ref: Fulfill conditions': '赚取邮票以兑换奖励！',
    'See Reward': '见奖励',
    'Ref: No reward': '目前没有奖励',
  },
  RedeemableProducts: { 'Ref: Page title': '忠诚度奖励' },
};
export default labels;
