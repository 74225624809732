import { screen, cleanup } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { useGetMappedFacilitiesQuery } from '../../../Review/api';
import { ISite } from '../../../Sites/types/sites.types';
import { useGetFacilitiesListQuery } from '../../api/index';

import FacilitiesList from './FacilitiesList';

jest.mock('../../../Review/api', () => ({
  ...jest.requireActual('../../../Review/api'),
  useGetMappedFacilitiesQuery: jest.fn().mockReturnValue({
    data: ['1', '2', '3', '4', '5'],
    isLoading: false,
  }),
}));

jest.mock('../../../config', () => {
  return {
    serviceTypes: {
      Facilities: {
        path: '/facilities',
        defaultModule: 'Facilities',
        label: 'Facilities',
      },
      'Food.Order': {
        path: '/order',
        excludeSubPaths: ['/history'],
        defaultModule: 'Order',
        label: 'Order',
      },
      Review: {
        path: '/review',
        defaultModule: 'Review',
        label: 'Feedback',
      },
    },
    modulesConfig: {
      Facilities: {
        serviceType: 'Facilities',
      },
      Order: {
        serviceType: 'Food.Order',
      },
      Review: {
        serviceType: 'Review',
      },
    },
  };
});

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () =>
    ({
      id: siteId,
      currency: {
        isoCode: 'EUR',
      },
    } as ISite),
}));

jest.mock('@/modules/config', () => ({
  ...jest.requireActual('@/modules/config'),
  SERVICE: {
    ...jest.requireActual('@/modules/config').SERVICE,
    REVIEW: 'Review',
  },
}));

const mockSelector = jest.fn();

const mockDispatch = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
  useDispatch: () => mockDispatch,
}));

jest.mock('@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled', () => ({
  useIsSetupOptionEnabled: jest.fn(),
}));

const mostViewedFacility = 'Chillie Restuarant';
const siteId = '123';
const facility1 = {
  id: '1',
  title: 'Mess Menu',
  description: 'Mess Menu (Food-Non-Retail)',
  images: [],
  isFoodFacilityType: true,
  facilityType: {
    id: '555580000',
    name: 'Food - Non-retail',
  },
};
const facility2 = {
  id: '2',
  title: mostViewedFacility,
  description: 'Chillie Restuarant (Food - Retail)',
  images: [],
  isFoodFacilityType: true,
  facilityType: {
    id: '555580001',
    name: 'Food - Retail',
  },
};

const facilitiesFromEndpoint = [
  {
    id: '1',
    title: 'Mess Menu',
    description: 'Mess Menu (Food-Non-Retail)',
    images: [],
    isFoodFacilityType: true,
    facilityType: {
      id: '555580000',
      name: 'Food - Non-retail',
    },
  },
  {
    id: '2',
    title: 'Chillie Restuarant',
    description: 'Chillie Restuarant (Food - Retail)',
    images: [],
    isFoodFacilityType: true,
    facilityType: {
      id: '555580001',
      name: 'Food - Retail',
    },
  },
];
const data = { [facility1.id]: facility1, [facility2.id]: facility2 };
const facilitiesList = { [siteId]: [facility1.id, facility2.id] };
const viewCounts = { [facility1.id]: 10, [facility2.id]: 20 };

const mockState = {
  Facilities: { data, list: facilitiesList, viewCounts },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
    geographies: {
      list: [],
      defaultGeoCode: 'PT',
    },
  },
  Core: {
    access: {},
    services: {
      list: [
        {
          id: '1',
          name: 'Review',
        },
        {
          id: '2',
          name: 'Food.Order',
        },
        {
          id: '3',
          name: 'Facilities',
        },
      ],
    },
  },
};

jest.mock('../../api/index', () => ({
  ...jest.requireActual('../../api/index'),
  useGetFacilitiesListQuery: jest.fn().mockReturnValue({
    data: { facilities: facilitiesFromEndpoint },
    isLoading: false,
  }),
}));

describe('FacilitiesList', () => {
  beforeEach(() => {
    mockSelector.mockReturnValue(mockState);
  });
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let facilities: HTMLElement[];

    beforeEach(() => {
      (useGetMappedFacilitiesQuery as jest.Mock).mockReturnValue({
        data: ['1', '2', '3', '4', '5'],
        isLoading: false,
      });
      (useGetFacilitiesListQuery as jest.Mock).mockReturnValue({
        data: { facilities: facilitiesFromEndpoint },
        isLoading: false,
      });
    });

    renderComponent(FacilitiesList, {}, mockState);

    it('should have the correct number of facilities listed', () => {
      facilities = screen.getAllByTestId(/^facility-tile-\d+$/);
      expect(facilities.length).toEqual(2);
    });

    it('should be sorted by view counts descending', () => {
      facilities = screen.getAllByTestId(/^facility-tile-\d+$/);
      const firstFacility = facilities[0];
      expect(firstFacility).toHaveTextContent(mostViewedFacility);
    });
  });

  describe('on filtering', () => {
    let input: HTMLElement;
    let facilities: HTMLElement[];

    beforeEach(() => {
      mockSelector.mockReturnValue(mockState);
      (useGetMappedFacilitiesQuery as jest.Mock).mockReturnValue({
        data: ['1', '2', '3', '4', '5'],
        isLoading: false,
      });
      (useGetFacilitiesListQuery as jest.Mock).mockReturnValue({
        data: { facilities: facilitiesFromEndpoint },
        isLoading: false,
      });
    });

    renderComponent(FacilitiesList, {}, mockState);

    beforeEach(async () => {
      input = screen.getByTestId(
        'facility-list-search-bar-list-page-search-filter-bar-input-field'
      );
      await userEvent.type(input, 'Mess');
      await new Promise((r) => setTimeout(r, 400));
    });

    it('should have search input', () => {
      expect(screen.getByPlaceholderText('Search')).toBeTruthy();
    });

    it('should have the correct number of facilities listed', () => {
      facilities = screen.getAllByTestId(/^facility-tile-\d+-actions$/);
      expect(facilities.length).toEqual(1);
    });

    it('should filter the correct facility', () => {
      expect(screen.getByText('Mess Menu')).toBeTruthy();
    });
  });
});
