const labels = {
  All: {
    'Ref: Redeem coffee description': '무료로 갓 양발 된 커피 한 잔이 기다리고 있습니다',
    'Ref: Congratulations': '축하합니다',
    'Ref: Get free coffee': '무료 커피를 마시십시오!',
    'Ref: Free coffee description': '컵을 채우고 무료 커피를 향해 포인트를 얻으십시오.',
    'Ref: Redeem': '상환하다!',
  },
  StampPage: {
    'Ref: Page title': '무료 커피',
    'Ref: seconds left': '몇 초 남았습니다',
    'Ref: How it works': '작동 방식',
    'Ref: How it works description':
      '커피를 구매할 때마다 스캔 버튼을 누르고 금전 등록기에서 제공된 QR 코드를 스캔하십시오.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'QR 코드를 스캔하십시오',
    'Ref: Collect reward': '보상을 수집하십시오',
    'Ref: Reward collected': '보상 수집',
    'Ref: Invalid QR Code': '잘못된 QR 코드',
    'Ref: Invalid QR Code description': 'QR 코드는 인식되지 않습니다',
    'Ref: Close': '닫다',
    'Ref: Redeem button': '상환하다',
    "Ref: The QR Code isn't recognized": 'QR 코드는 인식되지 않습니다',
    'Ref: Cancel': '취소',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': '축하합니다!',
    'Ref: Confirm redeem description':
      '이것을 사용하고 싶습니까? 그렇다면 금전 등록기에서 휴대 전화를 표시하고 구매를 완료하는 데 60 초 밖에 걸리지 않습니다.',
    'Ref: Congratulations modal description': '지금 무료 커피를 마시십시오!',
  },
  Widget: { 'Ref: Free Coffee widget title': '무료 커피!', 'Ref: See All': '모두보기' },
};
export default labels;
