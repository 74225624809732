import moment from 'moment';

export const today = moment().format('YYYY-MM-DD');
export const now = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

export const stateFromDev = {
  Core: {
    access: {
      invalidPsw: false,
      isFirstLogin: true,
      termsOfUseToAcknowledge: false,
      privacyPolicyToAcknowledge: false,
      termsOfSaleToAcknowledge: false,
      shouldSelectSiteContext: false,
    },
    context: {
      currentGeoCode: 'BE',
      theme: null,
      site: {
        currency: {
          currencyName: 'US Dollar',
          currencySymbol: '$',
          isoCode: 'USD',
          exchangeRate: 1,
          precision: 2,
        },
        id: '71a8dc31-1337-e811-a955-000d3a2bcac2',
        code: 'SNRIDGE',
        name: 'Sanofi Ridgefield',
        isActive: true,
      },
      siteIdBeforeChange: null,
      previousSite: {},
      siteHasChanged: false,
    },
    firebase: {},
    legalDocs: {
      list: [
        {
          id: 'f4aff6bd-b518-ed11-b83e-000d3aad9559',
          type: {
            name: 'Terms and Conditions',
            value: 224960000,
          },
          version: 'V1.1',
          publishDate: '2022-08-09T08:00:00Z',
          geolocation: 'EU',
          language: {
            name: 'English',
            code: 'en-US',
          },
        },
        {
          id: 'ea5ed940-7719-ed11-b83e-000d3aad9206',
          type: {
            name: 'Privacy Policy',
            value: 224960001,
          },
          version: 'V2.0',
          publishDate: null,
          geolocation: 'EU',
          language: {
            name: 'English',
            code: 'en-US',
          },
        },
        {
          id: 'f07d31da-7619-ed11-b83e-000d3aad9206',
          type: {
            name: 'Imprint',
            value: 224960003,
          },
          version: '1',
          publishDate: '2022-08-09T08:00:00Z',
          geolocation: 'EU',
          language: {
            name: 'English',
            code: 'en-US',
          },
        },
        {
          id: 'f7e2a61d-19c2-eb11-bacc-000d3ac22e37',
          type: {
            name: 'Terms of Sales',
            value: 224960002,
          },
          version: '1',
          publishDate: '2021-06-01T08:00:00Z',
          geolocation: 'EU',
          language: {
            name: 'English',
            code: 'en-US',
          },
        },
      ],
      detailed: {},
      fetchedAt: '2024-01-24T09:36:04.396Z',
    },
    locks: {
      getUserContext: false,
      getAcknowledgements: false,
      getAllLegalDocuments: false,
    },
    errors: {},
    notifications: {
      items: [],
      pageIndex: 0,
      total: 0,
      unreadNotificationCount: 0,
    },
    services: {
      list: [
        {
          id: '1ed28a70-73c9-ed11-9ac8-0003ff69197e',
          name: 'Food.Menu',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Food Menu',
            isEnabled: true,
            orderIndex: 1,
          },
          setupOptions: [
            {
              name: 'ShareSiteIdentifier',
              value:
                'jrja859v90gvnt78k2y6ivcrmo9wdo8dagakh43tovpw2o2km12nearvchg4pnvhwbxjovv7m3r40d1vq56snll5kqnk6lm2fq6fdnuitzybzaus8oek453yc3nccx5r',
              code: 'ShareSiteIdentifier',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: '70a743b1-73c9-ed11-9ac8-0003ff69197e',
          name: 'Food.Order',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Food Order',
            isEnabled: true,
            orderIndex: 2,
          },
          setupOptions: [
            {
              name: 'ShareSiteIdentifier',
              value:
                'jrja859v90gvnt78k2y6ivcrmo9wdo8dagakh43tovpw2o2km12nearvchg4pnvhwbxjovv7m3r40d1vq56snll5kqnk6lm2fq6fdnuitzybzaus8oek453yc3nccx5r',
              code: 'ShareSiteIdentifier',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'enableGuestOrdering',
              value: 'true',
              code: 'enableGuestOrdering',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'merchant',
              value: 'SodexoMyWayTEST-ENREU(ingenico)',
              code: 'merchant',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'paymentOnPickup',
              value: 'true',
              code: 'paymentOnPickup',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'useGetMenuBeforeSubmit',
              value: 'true',
              code: 'useGetMenuBeforeSubmit',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'useUserDiscountTier',
              value: 'false',
              code: 'useUserDiscountTier',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: '2d6bc22c-5cd4-ed11-9ac8-0003ff69197e',
          name: 'Content',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'App Content',
            isEnabled: true,
            orderIndex: 3,
          },
          setupOptions: [
            {
              name: 'displayContentCategories',
              value: 'true',
              code: 'displayContentCategories',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: '711cbd3d-5ed4-ed11-9ac8-0003ff69197e',
          name: 'Feedback',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Feedback',
            isEnabled: true,
            orderIndex: 4,
          },
          setupOptions: [
            {
              name: 'categories',
              value:
                'Village Services; Food & Dining; House Keeping & Cleaning; Retail Services; Gym & Recreation',
              code: 'categories',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: 'd0a89252-5dd4-ed11-9ac8-0003ff69197e',
          name: 'Contact',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Contact Us',
            isEnabled: true,
            orderIndex: 5,
          },
          setupOptions: [],
          contentPageId: null,
        },
        {
          id: '533ed8d4-5ed4-ed11-9ac8-0003ff69197e',
          name: 'Faq',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'FAQ',
            isEnabled: true,
            orderIndex: 6,
          },
          setupOptions: [],
          contentPageId: null,
        },
        {
          id: '3d91877c-74c9-ed11-9ac8-0003ff69197e',
          name: 'Facilities',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Facilities',
            isEnabled: true,
            orderIndex: 7,
          },
          setupOptions: [],
          contentPageId: null,
        },
        {
          id: '22aed1ec-73c9-ed11-9ac8-0003ff69197e',
          name: 'Food.Orders',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Food.Orders',
            isEnabled: true,
            orderIndex: 7,
          },
          setupOptions: [],
          contentPageId: null,
        },
        {
          id: 'cf599ba1-62d4-ed11-9ac8-0003ff69197e',
          name: 'Fitbit',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Fitbit',
            isEnabled: true,
            orderIndex: 8,
          },
          setupOptions: [
            {
              name: 'foodLog',
              value: 'true',
              code: 'foodLog',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: '760bb589-64d4-ed11-9ac8-0003ff69197e',
          name: 'Review',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Review',
            isEnabled: true,
            orderIndex: 11,
          },
          setupOptions: [
            {
              name: 'foodFeedback',
              value: 'true',
              code: 'foodFeedback',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'facilityFeedback',
              value: 'true',
              code: 'facilityFeedback',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: '11770ddc-6504-ee11-913a-0003ff691794',
          name: 'LoyaltyStamps',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Stamps',
            isEnabled: true,
            orderIndex: 14,
          },
          setupOptions: [],
          contentPageId: null,
        },
        {
          id: '3c21456c-796e-ee11-a81c-00224887c0e3',
          name: 'GlobalSearch',
          navigation: null,
          setupOptions: [],
          contentPageId: null,
        },
        {
          id: 'f7576cca-6088-ee11-a81c-00224887c0e3',
          name: 'MyAccount',
          navigation: null,
          setupOptions: [
            {
              name: 'disableAcknowledgements',
              value: 'true',
              code: 'disableAcknowledgements',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: '42d7708b-73c9-ed11-9ac8-0003ff69197e',
          name: 'Retail',
          navigation: null,
          setupOptions: [
            {
              name: 'disableTimeSelection',
              value: 'true',
              code: 'disableTimeSelection',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'paymentOnPickup',
              value: 'true',
              code: 'paymentOnPickup',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'merchant',
              value: 'SodexoMyWayTEST-ENREU(ingenico)',
              code: 'merchant',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'pspcode',
              value: 'ingenico',
              code: 'pspcode',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'allowMenuDownload',
              value: 'true',
              code: 'allowMenuDownload',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'ShareSiteIdentifier',
              value:
                'Jrja859v90gvnt78k2y6ivcrmo9wdo8dagakh43tovpw2o2km12nearvchg4pnvhwbxjovv7m3r40d1vq56snll5kqnk6lm2fq6fdnuitzybzaus8oek453yc3nccx5r',
              code: 'ShareSiteIdentifier',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'transactionalFeedbackFrom',
              value: '2023-10-01',
              code: 'transactionalFeedbackFrom',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
        {
          id: '10e60086-6bcd-ed11-9ac8-0003ff69197e',
          name: 'Stamps',
          navigation: null,
          setupOptions: [],
          contentPageId: null,
        },
      ],
      visited: [],
    },
    sites: {
      list: [],
      visited: {},
    },
    user: {
      acknowledgements: [
        {
          documentId: 'f4aff6bd-b518-ed11-b83e-000d3aad9559',
          type: {
            name: 'Terms and Conditions',
            value: 224960000,
          },
          acknowledgedOn: '2022-08-18T00:00:00Z',
        },
        {
          documentId: 'f4aff6bd-b518-ed11-b83e-000d3aad9559',
          type: {
            name: 'Terms and Conditions',
            value: 224960000,
          },
          acknowledgedOn: '2022-08-17T00:00:00Z',
        },
        {
          documentId: 'af5a5c5b-c832-ea11-a813-000d3a25c7c2',
          type: {
            name: 'Privacy Policy',
            value: 224960001,
          },
          acknowledgedOn: '2021-12-09T00:00:00Z',
        },
        {
          documentId: 'a85fbb8b-bce0-eb11-bacb-0022487f0ddb',
          type: {
            name: 'Terms and Conditions',
            value: 224960000,
          },
          acknowledgedOn: '2021-12-09T00:00:00Z',
        },
        {
          documentId: 'f7e2a61d-19c2-eb11-bacc-000d3ac22e37',
          type: {
            name: 'Terms of Sales',
            value: 224960002,
          },
          acknowledgedOn: '2022-06-17T00:00:00Z',
        },
        {
          documentId: 'f4aff6bd-b518-ed11-b83e-000d3aad9559',
          type: {
            name: 'Terms and Conditions',
            value: 224960000,
          },
          acknowledgedOn: '2022-08-11T00:00:00Z',
        },
        {
          documentId: 'ea5ed940-7719-ed11-b83e-000d3aad9206',
          type: {
            name: 'Privacy Policy',
            value: 224960001,
          },
          acknowledgedOn: '2022-08-12T00:00:00Z',
        },
        {
          documentId: 'fb9d5be3-0202-ea11-a811-000d3a25c942',
          type: {
            name: 'Privacy Policy',
            value: 224960001,
          },
          acknowledgedOn: '2023-06-14T00:00:00Z',
        },
        {
          documentId: '9c2e7879-c702-ea11-a811-000d3a25c942',
          type: {
            name: 'Terms and Conditions',
            value: 224960000,
          },
          acknowledgedOn: '2023-06-14T00:00:00Z',
        },
        {
          documentId: 'ea5ed940-7719-ed11-b83e-000d3aad9206',
          type: {
            name: 'Privacy Policy',
            value: 224960001,
          },
          acknowledgedOn: '2022-09-13T00:00:00Z',
        },
        {
          documentId: 'ea5ed940-7719-ed11-b83e-000d3aad9206',
          type: {
            name: 'Privacy Policy',
            value: 224960001,
          },
          acknowledgedOn: '2023-07-19T00:00:00Z',
        },
      ],
      contract: {
        id: '9ee44da8-f47b-e911-a97d-000d3a2bcc69',
        code: 'FMGWA',
        name: 'FMG',
      },
      preferredLanguage: {
        id: '4bba9048-623f-4d2f-aa81-64da7fac16a4',
        name: 'English',
        displayName: 'English',
        systemLanguage: 1033,
        languageCode: 'en-US',
        lcid: 1033,
      },
      theme: null,
      username: 'apptestam@myway.com',
      firstName: 'Andrej',
      qrCode: '',
      contactId: '38cab6e5-fa57-ec11-8f8f-000d3a49640f',
      lastName: 'Milhalsky',
      email: 'apptestAM@myway.com',
      mobile: '+421911',
      communicationPreferences: {
        allowContent: '224960001',
        allowOffers: '224960001',
      },
      preferredLocation: null,
    },
    identity: {
      client: null,
      type: null,
      provider: null,
      tokenUrl: null,
    },
    msTeamsContext: {
      TeamsContext: null,
      shouldRetryInit: false,
    },
  },
  Shared: {
    geographies: {
      list: [
        {
          name: 'Algeria',
          code: 'DZ',
          regionCode: 'EU',
        },
        {
          name: 'Argentina',
          code: 'AR',
          regionCode: 'EU',
        },
        {
          name: 'Australia / Pacific',
          code: 'AU',
          regionCode: 'EU',
        },
        {
          name: 'Austria',
          code: 'AT',
          regionCode: 'EU',
        },
        {
          name: 'Bahrain',
          code: 'BH',
          regionCode: 'EU',
        },
        {
          name: 'Belgium',
          code: 'BE',
          regionCode: 'EU',
        },
        {
          name: 'Brazil',
          code: 'BR',
          regionCode: 'EU',
        },
        {
          name: 'Canada',
          code: 'CA',
          regionCode: 'EU',
        },
        {
          name: 'Chile',
          code: 'CL',
          regionCode: 'EU',
        },
        {
          name: 'China',
          code: 'CN',
          regionCode: 'EU',
        },
        {
          name: 'Colombia',
          code: 'CO',
          regionCode: 'EU',
        },
        {
          name: 'Costa Rica',
          code: 'CR',
          regionCode: 'EU',
        },
        {
          name: 'Czech Republic',
          code: 'CZ',
          regionCode: 'EU',
        },
        {
          name: 'Denmark',
          code: 'DK',
          regionCode: 'EU',
        },
        {
          name: 'Egypt',
          code: 'EG',
          regionCode: 'EU',
        },
        {
          name: 'Finland',
          code: 'FI',
          regionCode: 'EU',
        },
        {
          name: 'France',
          code: 'FR',
          regionCode: 'EU',
        },
        {
          name: 'Germany',
          code: 'DE',
          regionCode: 'EU',
        },
        {
          name: 'Hong Kong',
          code: 'HK',
          regionCode: 'EU',
        },
        {
          name: 'Hungary',
          code: 'HU',
          regionCode: 'EU',
        },
        {
          name: 'India',
          code: 'IN',
          regionCode: 'EU',
        },
        {
          name: 'Indonesia',
          code: 'ID',
          regionCode: 'EU',
        },
        {
          name: 'Ireland',
          code: 'IE',
          regionCode: 'EU',
        },
        {
          name: 'Israel',
          code: 'IL',
          regionCode: 'EU',
        },
        {
          name: 'Italy',
          code: 'IT',
          regionCode: 'EU',
        },
        {
          name: 'Japan',
          code: 'JP',
          regionCode: 'EU',
        },
        {
          name: 'Jordan',
          code: 'JO',
          regionCode: 'EU',
        },
        {
          name: 'Kuwait',
          code: 'KW',
          regionCode: 'EU',
        },
        {
          name: 'Lebanon',
          code: 'LB',
          regionCode: 'EU',
        },
        {
          name: 'Malaysia',
          code: 'MY',
          regionCode: 'EU',
        },
        {
          name: 'México',
          code: 'MX',
          regionCode: 'EU',
        },
        {
          name: 'Netherlands',
          code: 'NL',
          regionCode: 'EU',
        },
        {
          name: 'New Zealand',
          code: 'NZ',
          regionCode: 'EU',
        },
        {
          name: 'Norway',
          code: 'NO',
          regionCode: 'EU',
        },
        {
          name: 'Oman',
          code: 'OM',
          regionCode: 'EU',
        },
        {
          name: 'Panama',
          code: 'PA',
          regionCode: 'EU',
        },
        {
          name: 'Peru',
          code: 'PE',
          regionCode: 'EU',
        },
        {
          name: 'Philippines',
          code: 'PH',
          regionCode: 'EU',
        },
        {
          name: 'Poland',
          code: 'PL',
          regionCode: 'EU',
        },
        {
          name: 'Portugal',
          code: 'PT',
          regionCode: 'EU',
        },
        {
          name: 'Qatar',
          code: 'QA',
          regionCode: 'EU',
        },
        {
          name: 'Romania',
          code: 'RO',
          regionCode: 'EU',
        },
        {
          name: 'Saudi Arabia',
          code: 'SA',
          regionCode: 'EU',
        },
        {
          name: 'Singapore',
          code: 'SG',
          regionCode: 'EU',
        },
        {
          name: 'South Africa',
          code: 'ZA',
          regionCode: 'EU',
        },
        {
          name: 'South Korea',
          code: 'KR',
          regionCode: 'EU',
        },
        {
          name: 'Spain',
          code: 'ES',
          regionCode: 'EU',
        },
        {
          name: 'Sweden',
          code: 'SE',
          regionCode: 'EU',
        },
        {
          name: 'Switzerland',
          code: 'CH',
          regionCode: 'EU',
        },
        {
          name: 'Taiwan',
          code: 'TW',
          regionCode: 'EU',
        },
        {
          name: 'Thailand',
          code: 'TH',
          regionCode: 'EU',
        },
        {
          name: 'Turkey',
          code: 'TR',
          regionCode: 'EU',
        },
        {
          name: 'United Arab Emirates',
          code: 'AE',
          regionCode: 'EU',
        },
        {
          name: 'United Kingdom',
          code: 'UK',
          regionCode: 'EU',
        },
        {
          name: 'United States of America',
          code: 'NA',
          regionCode: 'EU',
        },
        {
          name: 'Vietnam',
          code: 'VN',
          regionCode: 'EU',
        },
      ],
      defaultGeoCode: 'BE',
    },
    language: {
      currentLanguageCode: 'en-US',
      isUserSelected: false,
    },
    locks: {},
  },
  Sites: {
    list: [],
    visited: [
      {
        currency: {
          currencyName: 'US Dollar',
          currencySymbol: '$',
          isoCode: 'USD',
          exchangeRate: 1,
          precision: 2,
        },
        id: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
        code: 'SNALSTN',
        name: 'Sanofi Allston',
        isActive: true,
      },
    ],
    loading: false,
    locks: {
      siteUpdateLock: false,
    },
  },
  Content: {
    data: {},
    list: [],
    locks: {
      getList: false,
      getOne: false,
      postReaction: false,
    },
  },
  Order: {
    menus: [
      {
        id: 9504,
        facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
        name: 'ATAutomation Bite Menu',
        message: undefined,
        isOrderable: true,
        logoUrl:
          'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20231108104231-auto.png',
        date: now,
        isFutureOrdering: true,
        isConferenceCatering: false,
        fulfillmentTypes: [
          {
            id: 1313,
            type: 'PickupOption',
          },
          {
            id: 19007,
            type: 'Delivery',
          },
        ],
        menuItems: [
          {
            name: 'Bite apple',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite apple description',
            isOrderable: true,
            sortOrder: 0,
            price: 4.8,
            minimalPrice: 4.8,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201679_37951',
                foodItemSourceKey: '',
                name: 'Bite apple',
                description: 'Bite apple description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: false,
                isDefault: true,
                price: 4.8,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37951,
                foodItemId: 37013,
                additives: null,
                uomBarcodes: ['123123'],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201679,
            dayPart: 'Breakfast',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
          {
            name: 'Bite wine',
            mealName: 'Dinner',
            foodSubCategoryDescription: null,
            description: 'Bite wine description',
            isOrderable: true,
            sortOrder: 0,
            price: 42,
            minimalPrice: 7.2,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201688_37960',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '200g',
                portion: 'glass',
                gramWeight: '200',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 7.2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37960,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
              {
                portionId: '17201688_37959',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '500g',
                portion: 'bottle',
                gramWeight: '500',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: false,
                price: 42,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37959,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201688,
            dayPart: 'Dinner',
            section: 'Wines',
            subSection: null,
            genericCategory: 'Other',
          },
          {
            name: 'Bite wine',
            mealName: 'Dinner',
            foodSubCategoryDescription: null,
            description: 'Bite wine description',
            isOrderable: true,
            sortOrder: 0,
            price: 7.2,
            minimalPrice: 7.2,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201688_37960',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '200g',
                portion: 'glass',
                gramWeight: '200',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 7.2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37960,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
              {
                portionId: '17201688_37959',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '500g',
                portion: 'bottle',
                gramWeight: '500',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: false,
                price: 42,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37959,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201688,
            dayPart: 'Dinner',
            section: 'Wines',
            subSection: null,
            genericCategory: 'Other',
          },
          {
            name: 'Bite cake',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite cake description',
            isOrderable: true,
            sortOrder: 0,
            price: 3,
            minimalPrice: 3,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201680_37952',
                foodItemSourceKey: '',
                name: 'Bite cake',
                description: 'Bite cake description',
                ingredients: null,
                allergens: [
                  {
                    id: 'Milk',
                    name: 'Milk',
                    contains: true,
                  },
                  {
                    id: 'Eggs',
                    name: 'Eggs',
                    contains: true,
                  },
                  {
                    id: 'Nuts',
                    name: 'Nuts',
                    contains: true,
                  },
                ],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 3,
                viewablePrices: null,
                milkAdditive: true,
                soyBeansAdditive: false,
                eggsAdditive: true,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: true,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37952,
                foodItemId: 37014,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201680,
            dayPart: 'Breakfast',
            section: 'Cakes',
            subSection: null,
            genericCategory: 'Other',
          },
          {
            name: 'Bite coffee',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite coffee description',
            isOrderable: true,
            sortOrder: 0,
            price: 3.6,
            minimalPrice: 3.6,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201681_37953',
                foodItemSourceKey: '',
                name: 'Bite coffee',
                description: 'Bite coffee description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [
                  {
                    id: 14582,
                    name: 'Syrups',
                    description: 'Syrups',
                    type: 2,
                    selectionLimit: 2,
                    isRequired: false,
                    min: 1,
                    max: 2,
                    firstFree: 0,
                    modifierItems: [
                      {
                        id: 40468,
                        name: 'mint',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 1,
                        maximumQuantity: 0,
                        description: 'mint',
                        min: 0,
                        max: 0,
                        firstFree: 0,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                      {
                        id: 40469,
                        name: 'vanilia',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 2,
                        maximumQuantity: 0,
                        description: 'vanilia',
                        min: 0,
                        max: 0,
                        firstFree: 0,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                      {
                        id: 40470,
                        name: 'chocolate',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 3,
                        maximumQuantity: 0,
                        description: 'chocolate',
                        min: 0,
                        max: 0,
                        firstFree: 0,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                      {
                        id: 40471,
                        name: 'caramel',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 4,
                        maximumQuantity: 0,
                        description: 'caramel',
                        min: 0,
                        max: 0,
                        firstFree: 0,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                    ],
                  },
                ],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 3.6,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37953,
                foodItemId: 37015,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201681,
            dayPart: 'Breakfast',
            section: 'Coffee',
            subSection: null,
            genericCategory: 'Coffee',
          },
          {
            name: 'Bite sandwich',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite sandwich description',
            isOrderable: true,
            sortOrder: 0,
            price: 7.19,
            minimalPrice: 7.19,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201682_37957',
                foodItemSourceKey: '',
                name: 'Bite sandwich',
                description: 'Bite sandwich description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [
                  {
                    id: 14583,
                    name: 'Vegetable',
                    description: 'Vegetable',
                    type: 2,
                    selectionLimit: 5,
                    isRequired: false,
                    min: 0,
                    max: 5,
                    firstFree: 2,
                    modifierItems: [
                      {
                        id: 40472,
                        name: 'carrot',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 1,
                        maximumQuantity: 0,
                        description: 'carrot',
                        min: 0,
                        max: 2,
                        firstFree: 1,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                      {
                        id: 40473,
                        name: 'lettuce',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 2,
                        maximumQuantity: 0,
                        description: 'lettuce',
                        min: 0,
                        max: 2,
                        firstFree: 1,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                      {
                        id: 40474,
                        name: 'beetroot',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 3,
                        maximumQuantity: 0,
                        description: 'beetroot',
                        min: 0,
                        max: 2,
                        firstFree: 0,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                      {
                        id: 40475,
                        name: 'spinach',
                        price: 4.8,
                        priceLabel: '£0.00',
                        sortOrder: 4,
                        maximumQuantity: 0,
                        description: 'spinach',
                        min: 0,
                        max: 2,
                        firstFree: 0,
                        isChild: false,
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        allergens: [],
                      },
                    ],
                  },
                ],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 7.19,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37957,
                foodItemId: 37019,
                additives: null,
                uomBarcodes: ['34324324324'],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201682,
            dayPart: 'Breakfast',
            section: 'Sandwiches',
            subSection: null,
            genericCategory: 'Sandwich',
          },
          {
            name: 'Bite pasta',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite pasta description',
            isOrderable: true,
            sortOrder: 0,
            price: 3,
            minimalPrice: 3,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201683_37955',
                foodItemSourceKey: '',
                name: 'Bite pasta',
                description: 'Bite pasta description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 600,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 10,
                  potassium: 5,
                  iron: 2,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '250g',
                portion: 'standard',
                gramWeight: '250',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 3,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: true,
                isFavorite: false,
                uomId: 37955,
                foodItemId: 37017,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201683,
            dayPart: 'Breakfast',
            section: 'Pasta',
            subSection: null,
            genericCategory: 'Pasta',
          },
          {
            name: 'Bite wine',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite wine description',
            isOrderable: true,
            sortOrder: 0,
            price: 42,
            minimalPrice: 7.2,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201684_37960',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '200g',
                portion: 'glass',
                gramWeight: '200',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 7.2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37960,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
              {
                portionId: '17201684_37959',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '500g',
                portion: 'bottle',
                gramWeight: '500',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: false,
                price: 42,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37959,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201684,
            dayPart: 'Breakfast',
            section: 'Wines',
            subSection: null,
            genericCategory: 'Other',
          },
          {
            name: 'Bite wine',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite wine description',
            isOrderable: true,
            sortOrder: 0,
            price: 7.2,
            minimalPrice: 7.2,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201684_37960',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '200g',
                portion: 'glass',
                gramWeight: '200',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 7.2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37960,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
              {
                portionId: '17201684_37959',
                foodItemSourceKey: '',
                name: 'Bite wine',
                description: 'Bite wine description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '500g',
                portion: 'bottle',
                gramWeight: '500',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: false,
                price: 42,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37959,
                foodItemId: 37021,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 12,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201684,
            dayPart: 'Breakfast',
            section: 'Wines',
            subSection: null,
            genericCategory: 'Other',
          },
          {
            name: 'Bite free',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite free description',
            isOrderable: true,
            sortOrder: 0,
            price: 0,
            minimalPrice: 0,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201685_37954',
                foodItemSourceKey: '',
                name: 'Bite free',
                description: 'Bite free description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 0,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37954,
                foodItemId: 37016,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201685,
            dayPart: 'Breakfast',
            section: 'Loyalty Scheme',
            subSection: null,
            genericCategory: 'Other',
          },
          {
            name: 'Bite reward',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite reward description',
            isOrderable: true,
            sortOrder: 1,
            price: 4.8,
            minimalPrice: 4.8,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201686_37956',
                foodItemSourceKey: '',
                name: 'Bite reward',
                description: 'Bite reward description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 4.8,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37956,
                foodItemId: 37018,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201686,
            dayPart: 'Breakfast',
            section: 'Loyalty Scheme',
            subSection: null,
            genericCategory: 'Other',
          },
          {
            name: 'Bite stamp',
            mealName: 'Breakfast',
            foodSubCategoryDescription: null,
            description: 'Bite stamp description',
            isOrderable: true,
            sortOrder: 2,
            price: 3.6,
            minimalPrice: 3.6,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17201687_37958',
                foodItemSourceKey: '',
                name: 'Bite stamp',
                description: 'Bite stamp description',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 3.6,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 37958,
                foodItemId: 37020,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17201687,
            dayPart: 'Breakfast',
            section: 'Loyalty Scheme',
            subSection: null,
            genericCategory: 'Other',
          },
        ],
        source: 'Rr',
        isScanAndGo: false,
      },
      {
        id: 3348,
        facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
        name: 'Contract Cyclic menu',
        message: 'Menu description',
        isOrderable: true,
        logoUrl: null,
        date: moment().format(),
        isFutureOrdering: false,
        isConferenceCatering: false,
        fulfillmentTypes: [
          {
            id: 1313,
            type: 'PickupOption',
          },
        ],
        menuItems: [
          {
            name: 'Granny Smith Apple',
            mealName: 'Fruits and Vegetables',
            foodSubCategoryDescription: null,
            description: null,
            isOrderable: true,
            sortOrder: 0,
            price: 2.4,
            minimalPrice: 1.2,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206431_30631',
                foodItemSourceKey: '',
                name: 'Granny Smith Apple',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 2.4,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30631,
                foodItemId: 28583,
                additives: null,
                uomBarcodes: ['1111'],
                alcoholContent: null,
              },
              {
                portionId: '17206431_30644',
                foodItemSourceKey: '',
                name: 'Granny Smith Apple',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'Small',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: false,
                price: 1.2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30644,
                foodItemId: 28583,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206431,
            dayPart: 'Fruits and Vegetables',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
          {
            name: 'Garlic Soup',
            mealName: 'Soups',
            foodSubCategoryDescription: null,
            description: null,
            isOrderable: true,
            sortOrder: 0,
            price: 4.2,
            minimalPrice: 4.2,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206431_306319',
                foodItemSourceKey: '',
                name: 'Garlic Soup',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 2.4,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 306319,
                foodItemId: 285839,
                additives: null,
                uomBarcodes: ['1111'],
                alcoholContent: null,
              },
              {
                portionId: '17206431_306449',
                foodItemSourceKey: '',
                name: 'Garlic Soup',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'Small',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: false,
                price: 1.2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 306449,
                foodItemId: 285839,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 172064319,
            dayPart: 'Fruits and Vegetables',
            section: 'Soups',
            subSection: null,
            genericCategory: 'Soup',
          },
          {
            name: 'Abacaxi Pineapple',
            mealName: 'Fruits and Vegetables',
            foodSubCategoryDescription: null,
            description: null,
            isOrderable: true,
            sortOrder: 1,
            price: 3.6,
            minimalPrice: 2.4,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206432_30632',
                foodItemSourceKey: '',
                name: 'Abacaxi Pineapple',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 3.6,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30632,
                foodItemId: 28584,
                additives: null,
                uomBarcodes: ['2222'],
                alcoholContent: null,
              },
              {
                portionId: '17206432_30634',
                foodItemSourceKey: '',
                name: 'Abacaxi Pineapple',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'small',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: false,
                price: 2.4,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30634,
                foodItemId: 28584,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206432,
            dayPart: 'Fruits and Vegetables',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
          {
            name: 'Abacaxi Pineapple',
            mealName: 'Fruits and Vegetables',
            foodSubCategoryDescription: null,
            description: null,
            isOrderable: true,
            sortOrder: 1,
            price: 2.4,
            minimalPrice: 2.4,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206432_30632',
                foodItemSourceKey: '',
                name: 'Abacaxi Pineapple',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 3.6,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30632,
                foodItemId: 28584,
                additives: null,
                uomBarcodes: ['2222'],
                alcoholContent: null,
              },
              {
                portionId: '17206432_30634',
                foodItemSourceKey: '',
                name: 'Abacaxi Pineapple',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'small',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: false,
                price: 2.4,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30634,
                foodItemId: 28584,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206432,
            dayPart: 'Fruits and Vegetables',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
          {
            name: 'Alice Grape',
            mealName: 'Fruits and Vegetables',
            foodSubCategoryDescription: null,
            description: null,
            isOrderable: true,
            sortOrder: 2,
            price: 4.8,
            minimalPrice: 4.8,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206433_30633',
                foodItemSourceKey: '',
                name: 'Alice Grape',
                description: null,
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'standard',
                gramWeight: '0',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 4.8,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30633,
                foodItemId: 28585,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206433,
            dayPart: 'Fruits and Vegetables',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Fruit',
          },
        ],
        source: 'Rr',
        isScanAndGo: false,
      },
      {
        id: 5390,
        facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
        name: 'Scan&Go',
        message: 'Scan and go menu loaded descrp',
        isOrderable: true,
        logoUrl: null,
        date: moment().format(),
        isFutureOrdering: false,
        isConferenceCatering: false,
        fulfillmentTypes: [
          {
            id: 1261,
            type: 'ScanAndGo',
          },
        ],
        menuItems: [
          {
            name: 'Red Bull Energy Drink ',
            mealName: 'Drinks',
            foodSubCategoryDescription: null,
            description: 'NO',
            isOrderable: true,
            sortOrder: 0,
            price: 2,
            minimalPrice: 2,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206131_31706',
                foodItemSourceKey: '',
                name: 'Red Bull Energy Drink ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '100g',
                portion: 'NO',
                gramWeight: '100',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 2,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 31706,
                foodItemId: 30652,
                additives: null,
                uomBarcodes: ['11111'],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206131,
            dayPart: 'Drinks',
            section: 'Chilled Drinks',
            subSection: null,
            genericCategory: 'Drink',
          },
          {
            name: 'Red Bull Energy Zero Drink ',
            mealName: 'Drinks',
            foodSubCategoryDescription: null,
            description: 'NO',
            isOrderable: true,
            sortOrder: 1,
            price: 3,
            minimalPrice: 3,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206132_31707',
                foodItemSourceKey: '',
                name: 'Red Bull Energy Zero Drink ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '250g',
                portion: 'NO',
                gramWeight: '250',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 3,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 31707,
                foodItemId: 30653,
                additives: null,
                uomBarcodes: ['22222'],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206132,
            dayPart: 'Drinks',
            section: 'Chilled Drinks',
            subSection: null,
            genericCategory: 'Drink',
          },
          {
            name: 'Red Bull Sugar Free Drink ',
            mealName: 'Drinks',
            foodSubCategoryDescription: null,
            description: 'NO',
            isOrderable: true,
            sortOrder: 2,
            price: 4,
            minimalPrice: 0,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206133_31708',
                foodItemSourceKey: '',
                name: 'Red Bull Sugar Free Drink ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '150g',
                portion: 'NO',
                gramWeight: '150',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 4,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 31708,
                foodItemId: 30654,
                additives: null,
                uomBarcodes: ['33123'],
                alcoholContent: null,
              },
              {
                portionId: '17206133_41260',
                foodItemSourceKey: '',
                name: 'Red Bull Sugar Free Drink ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'NO2',
                gramWeight: '0',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: false,
                price: 0,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 41260,
                foodItemId: 30654,
                additives: null,
                uomBarcodes: ['456674'],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206133,
            dayPart: 'Drinks',
            section: 'Chilled Drinks',
            subSection: null,
            genericCategory: 'Drink',
          },
          {
            name: 'Red Bull Sugar Free Drink ',
            mealName: 'Drinks',
            foodSubCategoryDescription: null,
            description: 'NO',
            isOrderable: true,
            sortOrder: 2,
            price: 0,
            minimalPrice: 0,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206133_31708',
                foodItemSourceKey: '',
                name: 'Red Bull Sugar Free Drink ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '150g',
                portion: 'NO',
                gramWeight: '150',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 4,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 31708,
                foodItemId: 30654,
                additives: null,
                uomBarcodes: ['33123'],
                alcoholContent: null,
              },
              {
                portionId: '17206133_41260',
                foodItemSourceKey: '',
                name: 'Red Bull Sugar Free Drink ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '',
                portion: 'NO2',
                gramWeight: '0',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: false,
                price: 0,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 41260,
                foodItemId: 30654,
                additives: null,
                uomBarcodes: ['456674'],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206133,
            dayPart: 'Drinks',
            section: 'Chilled Drinks',
            subSection: null,
            genericCategory: 'Drink',
          },
          {
            name: 'Ribena Blackcurrant ',
            mealName: 'Drinks',
            foodSubCategoryDescription: null,
            description: 'NO',
            isOrderable: true,
            sortOrder: 3,
            price: 5,
            minimalPrice: 5,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206134_31709',
                foodItemSourceKey: '',
                name: 'Ribena Blackcurrant ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '225g',
                portion: 'NO',
                gramWeight: '225',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 5,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 31709,
                foodItemId: 30655,
                additives: null,
                uomBarcodes: ['33333'],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206134,
            dayPart: 'Drinks',
            section: 'Chilled Drinks',
            subSection: null,
            genericCategory: 'Drink',
          },
          {
            name: 'Tenzing Natural Energy Drink ',
            mealName: 'Drinks',
            foodSubCategoryDescription: null,
            description: 'NO',
            isOrderable: true,
            sortOrder: 4,
            price: 6,
            minimalPrice: 6,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206135_31710',
                foodItemSourceKey: '',
                name: 'Tenzing Natural Energy Drink ',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '250g',
                portion: 'NO',
                gramWeight: '250',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 6,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 31710,
                foodItemId: 30656,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206135,
            dayPart: 'Drinks',
            section: 'Chilled Drinks',
            subSection: null,
            genericCategory: 'Drink',
          },
          {
            name: 'Tenzing Natural Energy Raspberry & Yuzu Drink',
            mealName: 'Drinks',
            foodSubCategoryDescription: null,
            description: 'NO',
            isOrderable: true,
            sortOrder: 5,
            price: 7,
            minimalPrice: 7,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17206136_31711',
                foodItemSourceKey: '',
                name: 'Tenzing Natural Energy Raspberry & Yuzu Drink',
                description: 'NO',
                ingredients: null,
                allergens: [],
                nutritions: {
                  calories: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '100g',
                portion: 'NO',
                gramWeight: '100',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 7,
                viewablePrices: null,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 31711,
                foodItemId: 30657,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage: null,
            detailsImage: null,
            menuItemId: 17206136,
            dayPart: 'Drinks',
            section: 'Chilled Drinks',
            subSection: null,
            genericCategory: 'Drink',
          },
        ],
        source: 'Rr',
        isScanAndGo: true,
      },
    ],
    allergens: [
      {
        name: 'Milk',
        allergen: 'Milk',
      },
      {
        name: 'Eggs',
        allergen: 'Eggs',
      },
      {
        name: 'Treenuts',
        allergen: 'Treenuts',
      },
      {
        name: 'Peanuts',
        allergen: 'Peanut',
      },
      {
        name: 'Shellfish',
        allergen: 'Shellfish',
      },
      {
        name: 'Wheat',
        allergen: 'Wheat',
      },
      {
        name: 'Soybean',
        allergen: 'Soybean',
      },
      {
        name: 'Fish',
        allergen: 'Fish',
      },
      {
        name: 'Mustard',
        allergen: 'Mustard',
      },
      {
        name: 'Sesame',
        allergen: 'Sesame',
      },
      {
        name: 'Gluten',
        allergen: 'Gluten',
      },
    ],
    discountTiersStatus: {},
    discountTiers: {},
    locks: {
      getMenusForFacility: false,
      getAllergens: false,
      createOrderDraft: false,
      getPickupLocations: false,
      getPickupTimeSlots: false,
      payForOrder: false,
    },
    hasSiteChanged: false,
    hasSiteChangeModalClosed: false,
    menusType: 1,
    errors: {
      fetchingSiteId: false,
      payForOrder: false,
    },
    availablePromotions: [
      {
        id: 7564,
        name: '[Contract Test] $2 promotion for Bite Cake',
        description: "Contract test promotion please don't change details.",
        discount: 0,
        threshold: null,
        items: [
          {
            discount: 2,
            uomId: 37952,
            menuIds: [9504],
          },
        ],
      },
      {
        id: 3514,
        name: '[Contract Test] $2 discount on entire cart for order more than $10',
        description: "Contract test promotion please don't change details.",
        discount: 2,
        threshold: 10,
        items: [],
      },
    ],
    cart: {
      date: now,
      facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
      submissionTrackingId: '0136f80b-eb08-4509-a3b8-957c47c9dc2a',
      menuId: 3348,
      siteId: '71a8dc31-1337-e811-a955-000d3a2bcac2',
      menuPortionItems: [
        {
          id: '6f5d9f5d-fe2d-4caf-8f7f-7e03625429fe',
          img: null,
          menuItemId: 17206433,
          name: 'Alice Grape',
          price: 4.8,
          quantity: 2,
          uomId: 30633,
          foodItemId: 28585,
          description: '',
          isVegan: true,
          isVegetarian: true,
          genericCategory: 'Fruit',
        },
        {
          id: '9104e9e7-ed11-4d0f-83fe-5ff09528855a',
          img: null,
          menuItemId: 17206432,
          name: 'Abacaxi Pineapple',
          price: 3.6,
          quantity: 1,
          uomId: 30632,
          foodItemId: 28584,
          modifiers: [],
          modifiersDisplayText: '',
          description: '',
          isVegan: true,
          isVegetarian: true,
          genericCategory: 'Fruit',
        },
      ],
      moment: 'Fruits and Vegetables',
      pickupInformation: {
        pickupPhoneNumber: '+421911',
        pickupSpotId: null,
        pickupSpotName: null,
        pickupTimeSlotId: null,
        pickupTime: null,
      },
      selectedFulfillmentType: {
        id: 1313,
        type: 'PickupOption',
      },
      pickupLocations: [
        {
          pickupSpotId: 2186,
          order: 0,
          name: "Maciej's timeSet",
        },
      ],
    },
    draft: {
      orderId: 312011,
      subtotal: 11,
      tax: 2.2,
      total: 11.2,
      promotionDiscount: 2,
      delivery: 0,
      subsidy: null,
      promotionalDiscountForItems: [],
      promotionsApplied: [
        {
          id: 3514,
          name: '[Contract Test] $2 discount on entire cart for order more than $10',
          discount: 2,
        },
      ],
      suggestedPromotions: [],
      redeemedFoodItems: [],
      loyaltyStampsToBeEarned: [],
    },
    tableQRScanTime: null,
    lastOrder: {
      orderId: 312004,
      facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
      pickupInformation: {
        pickupPhoneNumber: '+421911',
        pickupSpotId: 6378,
        pickupSpotName: 'Delivery spot 2',
        pickupTimeSlotId: '712545',
        pickupTime: '2024-01-24T09:20:00',
      },
      deliveryOptionType: 'Delivery',
      orderItems: [
        {
          name: 'Bite pasta',
          price: 3,
          quantity: 1,
          promotionDiscount: 0,
          isVegan: false,
          isVegetarian: false,
        },
        {
          name: 'Bite cake',
          price: 3,
          quantity: 1,
          promotionDiscount: 2,
          isVegan: false,
          isVegetarian: false,
        },
      ],
      total: 6,
      subTotal: 3,
      tax: 1,
      subsidy: null,
      delivery: 2,
      promotionDiscount: 2,
      promotionsApplied: [
        {
          id: 7564,
          name: '[Contract Test] $2 promotion for Bite Cake',
          discount: 2,
        },
      ],
    },
  },
  Feedback: {
    locks: {},
  },
  Occupancy: {
    url: '',
    locks: {
      getOccupancyUrl: false,
    },
  },
  ContentPage: {
    content: {},
    locks: {},
  },
  Facilities: {
    data: {
      '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea': {
        title: 'Mess Menu Facility',
        email: 'Testcheck@gmail.com',
        phone: '',
        name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston',
        description: 'Mess menus',
        id: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '23:55',
            name: 'Mess Menu Facility -Allston',
            openTime: '07:00',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580000',
          name: 'Food - Non-retail',
        },
        menuNote: 'Note here!',
        externalUrl: '',
      },
      'f92d1442-4398-eb11-89ee-0003ff4cb810': {
        title: 'Food Bazaar',
        email: 'testuser@myway.com',
        phone: '23234543',
        name: 'Food Bazaar - Sanofi Pharmaceutical - Sanofi Allston',
        description: 'Welcome to our new outlet',
        id: 'f92d1442-4398-eb11-89ee-0003ff4cb810',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '23:55',
            name: 'Food B',
            openTime: '10:00',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: false,
        facilityType: {
          id: '555580002',
          name: 'Non-food - Non-retail',
        },
        menuNote: 'All are fresh Items',
        externalUrl: '',
      },
      '0d465323-4f67-ed11-97b0-0003ff4ccd9a': {
        title: 'Coffee shop',
        email: '',
        phone: '',
        name: 'Coffee shop - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: ['f24e1311-2d93-ed11-aad1-6045bd8c5dba'],
        openingHours: [
          {
            closeTime: '',
            name: 'Open hours',
            openTime: '',
            isOpenAllDay: true,
            day: [],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      '63188e76-4f67-ed11-97b0-0003ff4ccd9a': {
        title: 'CM Test facility',
        email: '',
        phone: '',
        name: 'CM Test facility - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: '63188e76-4f67-ed11-97b0-0003ff4ccd9a',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: ['f24e1311-2d93-ed11-aad1-6045bd8c5dba'],
        openingHours: [
          {
            closeTime: '',
            name: 'All day',
            openTime: '',
            isOpenAllDay: true,
            day: [],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      'b9f6fbc4-4f67-ed11-97b0-0003ff4ccd9a': {
        title: 'Cycle Facility',
        email: '',
        phone: '',
        name: 'Cycle Facility - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: 'b9f6fbc4-4f67-ed11-97b0-0003ff4ccd9a',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'All day',
            openTime: '',
            isOpenAllDay: true,
            day: [],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      '353f7938-53a9-ed11-9ac4-0003ff692eca': {
        title: 'Conference catering',
        email: 'Krysztof.Szepietowski.ext@sodexo.com',
        phone: '',
        name: 'Conference catering - Sanofi Pharmaceutical - Sanofi Allston',
        description: 'This is facility for conference catering',
        id: '353f7938-53a9-ed11-9ac4-0003ff692eca',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '17:00',
            name: 'Conference Catering CC',
            openTime: '10:00',
            isOpenAllDay: true,
            day: [],
          },
        ],
        isFoodFacilityType: false,
        facilityType: {
          id: '555580004',
          name: 'Conference Catering',
        },
        menuNote: '',
        externalUrl: '',
      },
      '80540dec-2c7e-ee11-a81c-00224887c0e3': {
        title: 'Coffee shop1',
        email: '',
        phone: '',
        name: 'Coffee shop1 - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: '80540dec-2c7e-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'fefe',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580000',
          name: 'Food - Non-retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      'e5f17e34-2d7e-ee11-a81c-00224887c0e3': {
        title: 'Coffee shop2',
        email: '',
        phone: '',
        name: 'Coffee shop2 - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: 'e5f17e34-2d7e-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'ttt',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      '5827615b-2d7e-ee11-a81c-00224887c0e3': {
        title: 'Coffee shop3',
        email: '',
        phone: '',
        name: 'Coffee shop3 - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: '5827615b-2d7e-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'eww',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: false,
        facilityType: {
          id: '555580002',
          name: 'Non-food - Non-retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      'c60c048a-2d7e-ee11-a81c-00224887c0e3': {
        title: 'Coffee shop4',
        email: '',
        phone: '',
        name: 'Coffee shop4 - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: 'c60c048a-2d7e-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'ee',
            openTime: '',
            isOpenAllDay: true,
            day: [],
          },
        ],
        isFoodFacilityType: false,
        facilityType: {
          id: '555580003',
          name: 'Non-food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      'fdcbcff9-2d7e-ee11-a81c-00224887c0e3': {
        title: 'Coffee shop5',
        email: '',
        phone: '',
        name: 'Coffee shop5 - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: 'fdcbcff9-2d7e-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'grgr',
            openTime: '',
            isOpenAllDay: true,
            day: [],
          },
        ],
        isFoodFacilityType: false,
        facilityType: {
          id: '555580004',
          name: 'Conference Catering',
        },
        menuNote: '',
        externalUrl: '',
      },
      'ad40d04d-2e7e-ee11-a81c-00224887c0e3': {
        title: 'Coffee shop7',
        email: '',
        phone: '',
        name: 'Coffee shop7 - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: 'ad40d04d-2e7e-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'gerge',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: false,
        facilityType: {
          id: '555580002',
          name: 'Non-food - Non-retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      '83b2c074-2e7e-ee11-a81c-00224887c0e3': {
        title: 'bite facility',
        email: '',
        phone: '',
        name: 'bite facility - Sanofi Pharmaceutical - Sanofi Allston',
        description: '',
        id: '83b2c074-2e7e-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'grgr',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      '6257cf2e-b6d3-ed11-9ac8-0003ff69197e': {
        title: 'EMEA AT Facility',
        email: 'foodretail@facility.com',
        phone: '123456789',
        name: 'EMEA AT Facility - Sanofi Pharmaceutical - Sanofi Ridgefield',
        description: 'FoodRetail FacilityDescription',
        id: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
        sequence: 3,
        access: '',
        longitude: '',
        latitude: '',
        images: ['f1514dbe-a545-ee11-be6e-6045bd8c50c5'],
        openingHours: [
          {
            closeTime: '',
            name: '24',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: 'MenuTestNote',
        externalUrl: '',
      },
      '6afbbfc1-07d9-ed11-9ac8-0003ff69197e': {
        title: 'Rooftop Menu 1',
        email: '',
        phone: '',
        name: 'Rooftop Menu 1 - Sanofi Pharmaceutical - Sanofi Ridgefield',
        description: '',
        id: '6afbbfc1-07d9-ed11-9ac8-0003ff69197e',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'Rooftop Scan and Go',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      'be2d93ef-ebd9-ed11-9ac8-0003ff69197e': {
        title: 'Naturalmente Rico',
        email: '',
        phone: '',
        name: 'Naturalmente Rico - Sanofi Pharmaceutical - Sanofi Ridgefield',
        description: '',
        id: 'be2d93ef-ebd9-ed11-9ac8-0003ff69197e',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: '24',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: '',
        externalUrl: '',
      },
      '28a171e5-ad74-ee11-a81c-00224887c0e3': {
        title: 'NonFoodNonRetail FacilityTest',
        email: 'nonfoodnonretail@facility.com',
        phone: '123456789',
        name: 'NonFoodNonRetail FacilityTest - Sanofi Pharmaceutical - Sanofi Ridgefield',
        description: 'NonFoodNonRetail FacilityDescription',
        id: '28a171e5-ad74-ee11-a81c-00224887c0e3',
        sequence: 2,
        access: '',
        longitude: '',
        latitude: '',
        images: ['f1514dbe-a545-ee11-be6e-6045bd8c50c5'],
        openingHours: [
          {
            closeTime: '23:00',
            name: 'everyday',
            openTime: '06:00',
            isOpenAllDay: false,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: false,
        facilityType: {
          id: '555580002',
          name: 'Non-food - Non-retail',
        },
        menuNote: 'MenuTestNote',
        externalUrl: '',
      },
      'f58350b9-6f7d-ee11-a81c-00224887c0e3': {
        title: 'FoodNonRetail FacilityTest (M)',
        email: 'foodnonretail@facility.com',
        phone: '123456789',
        name: 'FoodNonRetail FacilityTest (M) - Sanofi Pharmaceutical - Sanofi Ridgefield',
        description: 'FoodNonRetail FacilityDescription',
        id: 'f58350b9-6f7d-ee11-a81c-00224887c0e3',
        sequence: 1,
        access: '',
        longitude: '',
        latitude: '',
        images: ['f1514dbe-a545-ee11-be6e-6045bd8c50c5'],
        openingHours: [
          {
            closeTime: '22:00',
            name: 'everyday',
            openTime: '08:00',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580000',
          name: 'Food - Non-retail',
        },
        menuNote: 'MenuTestNote',
        externalUrl: '',
      },
      '484e1fe6-d183-ee11-a81c-00224887c0e3': {
        title: 'FoodNonRetail FacilityTest (T)',
        email: '',
        phone: '',
        name: 'FoodNonRetail FacilityTest (T) - Sanofi Pharmaceutical - Sanofi Ridgefield',
        description: 'This is a Food-Non-Retail facility with Tavern Menu',
        id: '484e1fe6-d183-ee11-a81c-00224887c0e3',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: [],
        openingHours: [
          {
            closeTime: '',
            name: 'Open 24h',
            openTime: '',
            isOpenAllDay: true,
            day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580000',
          name: 'Food - Non-retail',
        },
        menuNote: '',
        externalUrl: '',
      },
    },
    list: {
      '5a8da332-1237-e811-a95b-000d3a2bc5c1': [
        '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        'f92d1442-4398-eb11-89ee-0003ff4cb810',
        '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
        '63188e76-4f67-ed11-97b0-0003ff4ccd9a',
        'b9f6fbc4-4f67-ed11-97b0-0003ff4ccd9a',
        '353f7938-53a9-ed11-9ac4-0003ff692eca',
        '80540dec-2c7e-ee11-a81c-00224887c0e3',
        'e5f17e34-2d7e-ee11-a81c-00224887c0e3',
        '5827615b-2d7e-ee11-a81c-00224887c0e3',
        'c60c048a-2d7e-ee11-a81c-00224887c0e3',
        'fdcbcff9-2d7e-ee11-a81c-00224887c0e3',
        'ad40d04d-2e7e-ee11-a81c-00224887c0e3',
        '83b2c074-2e7e-ee11-a81c-00224887c0e3',
      ],
      '71a8dc31-1337-e811-a955-000d3a2bcac2': [
        '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
        '6afbbfc1-07d9-ed11-9ac8-0003ff69197e',
        'be2d93ef-ebd9-ed11-9ac8-0003ff69197e',
        '28a171e5-ad74-ee11-a81c-00224887c0e3',
        'f58350b9-6f7d-ee11-a81c-00224887c0e3',
        '484e1fe6-d183-ee11-a81c-00224887c0e3',
      ],
    },
    viewCounts: {},
    locks: {
      getAll: false,
    },
  },
  Review: {
    email: '',
    isAnonymous: true,
  },
  api: {
    queries: {
      getNotifications: {
        status: 'fulfilled',
        endpointName: 'getNotifications',
        requestId: 'LshPQ7jsJ_OAPl2WNUMPc',
        originalArgs: {
          pageIndex: 0,
        },
        startedTimeStamp: 1706090551882,
        data: {
          items: [],
          data: [],
          total: 0,
          pageIndex: 0,
          pageSize: 10,
          unreadNotificationCount: 0,
        },
        fulfilledTimeStamp: 1706090552467,
      },
    },
    mutations: {},
    provided: {
      notifications: {
        __internal_without_id: ['getNotifications'],
      },
      sites: {
        __internal_without_id: [],
      },
      account: {
        __internal_without_id: [],
      },
      content: {
        __internal_without_id: [],
      },
    },
    subscriptions: {
      getNotifications: {
        LshPQ7jsJ_OAPl2WNUMPc: {
          pollingInterval: 0,
        },
      },
    },
    config: {
      online: true,
      focused: true,
      middlewareRegistered: true,
      refetchOnFocus: false,
      refetchOnReconnect: false,
      refetchOnMountOrArgChange: false,
      keepUnusedDataFor: 60,
      reducerPath: 'api',
    },
  },
  biteApi: {
    queries: {
      'checkCanOrder({"facilityId":"6257cf2e-b6d3-ed11-9ac8-0003ff69197e","menuId":3348,"orderPickupDate":"2024-01-24","siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          status: 'fulfilled',
          endpointName: 'checkCanOrder',
          requestId: 'L0VXICqxPD5FS4mwRGXu5',
          originalArgs: {
            siteId: '71a8dc31-1337-e811-a955-000d3a2bcac2',
            menuId: 3348,
            facilityId: '6257cf2e-b6d3-ed11-9ac8-0003ff69197e',
            orderPickupDate: today,
            useErrorBoundary: false,
          },
          startedTimeStamp: 1706090539693,
          data: {
            forbidenReasons: [],
            isOrderingPossible: true,
          },
          fulfilledTimeStamp: 1706090542393,
        },
      'getLoyaltySchemes({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          status: 'fulfilled',
          endpointName: 'getLoyaltySchemes',
          requestId: 'raby0QE2piqtExSCXPESJ',
          originalArgs: {
            siteId: '71a8dc31-1337-e811-a955-000d3a2bcac2',
            useErrorBoundary: false,
          },
          startedTimeStamp: 1706090551913,
          data: [
            {
              id: 1067,
              name: 'Dps contract test loyality scheme',
              description: 'test if changes are visible 1',
              productsProvidingStamp: [
                {
                  uomId: 30631,
                  foodItemId: 28583,
                },
              ],
              productsRedeemable: [
                {
                  uomId: 30632,
                  foodItemId: 28584,
                },
              ],
              imageUrl: null,
              stampsRequiredForRedeem: 5,
              menuIdsProvidingProductsRedeemable: [3348],
              menuIdsProvidingProductsProvidingStamp: [3348],
            },
          ],
          fulfilledTimeStamp: 1706090552263,
        },
      'getUserLoyaltySchemesProgress({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          status: 'fulfilled',
          endpointName: 'getUserLoyaltySchemesProgress',
          requestId: 'TcvArMxwyWzZ_tSDxjncJ',
          originalArgs: {
            siteId: '71a8dc31-1337-e811-a955-000d3a2bcac2',
            useErrorBoundary: false,
          },
          startedTimeStamp: 1706090551914,
          data: [
            {
              loyaltySchemeId: 1067,
              stampsCollected: 0,
            },
          ],
          fulfilledTimeStamp: 1706090552307,
        },
      'getAvailablePromotions({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          status: 'fulfilled',
          endpointName: 'getAvailablePromotions',
          requestId: 'uIum8lvkYfQqGz55nxjno',
          originalArgs: {
            siteId: '71a8dc31-1337-e811-a955-000d3a2bcac2',
            useErrorBoundary: false,
          },
          startedTimeStamp: 1706090418535,
          data: [
            {
              id: 3514,
              name: '[Contract Test] $2 discount on entire cart for order more than $10',
              description: "Contract test promotion please don't change details.",
              discount: 2,
              threshold: 10,
              items: [],
            },
            {
              id: 7564,
              name: '[Contract Test] $2 promotion for Bite Cake',
              description: "Contract test promotion please don't change details.",
              discount: 0,
              threshold: null,
              items: [
                {
                  discount: 2,
                  uomId: 37952,
                  menuIds: [9504],
                },
              ],
            },
          ],
          fulfilledTimeStamp: 1706090419242,
        },
      'getFilterPreferences({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          status: 'fulfilled',
          endpointName: 'getFilterPreferences',
          requestId: 'qhZxkccLz3L-OYp4J7UmB',
          originalArgs: {
            siteId: '71a8dc31-1337-e811-a955-000d3a2bcac2',
            useErrorBoundary: false,
          },
          startedTimeStamp: 1706090539692,
          data: {
            selectedAllergens: ['Milk', 'Gluten'],
            selectedDietaries: [],
            maxCalories: null,
            hideFilteredDishes: false,
            displayFavoritesOnly: false,
          },
          fulfilledTimeStamp: 1706090551830,
        },
    },
    mutations: {},
    provided: {
      filterPreferences: {
        __internal_without_id: [
          'getFilterPreferences({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})',
        ],
      },
      loyaltyStamps: {
        __internal_without_id: [
          'getLoyaltySchemes({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})',
          'getUserLoyaltySchemesProgress({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})',
        ],
      },
      promotions: {
        __internal_without_id: [
          'getAvailablePromotions({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})',
        ],
      },
    },
    subscriptions: {
      'getAvailablePromotions({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {},
      'checkCanOrder({"facilityId":"6257cf2e-b6d3-ed11-9ac8-0003ff69197e","menuId":3348,"orderPickupDate":"2024-01-24","siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          L0VXICqxPD5FS4mwRGXu5: {
            pollingInterval: 0,
          },
        },
      'getLoyaltySchemes({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          raby0QE2piqtExSCXPESJ: {
            pollingInterval: 0,
          },
          JaE3pdhx6S2U90jiUI9xF: {
            pollingInterval: 0,
          },
          EpFuxjMdzOx9KAracGqpL: {
            pollingInterval: 0,
          },
          '8hrnTjMg9-hG8RdC6vwGp': {
            pollingInterval: 0,
          },
          'j5Kc9DEkIaTU-4BBX60si': {
            pollingInterval: 0,
          },
          w8pNWi5cS1SqgV3mbBI5p: {
            pollingInterval: 0,
          },
          Ss4P85RQmoI5v2SEnNPbb: {
            pollingInterval: 0,
          },
          '2FH-ri8yJoxSRXi-3w37y': {
            pollingInterval: 0,
          },
          peJ5ddU8fdr27kQmYrlFZ: {
            pollingInterval: 0,
          },
          cqchIYG1RwRH9X7C2ieBH: {
            pollingInterval: 0,
          },
          '7smX2xHl7pTo50sieCPzu': {
            pollingInterval: 0,
          },
          QkDAVLVyP7a3rK7Ut2n12: {
            pollingInterval: 0,
          },
          esMwOhrdc5I0zqnC82buf: {
            pollingInterval: 0,
          },
        },
      'getUserLoyaltySchemesProgress({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          TcvArMxwyWzZ_tSDxjncJ: {
            pollingInterval: 0,
          },
          S5lF9eceHM4HyiKP44oGJ: {
            pollingInterval: 0,
          },
          iq7GdtyrtQT1ZMA4M_vKZ: {
            pollingInterval: 0,
          },
          '5BGKVnPE-paSzvnvb7jDR': {
            pollingInterval: 0,
          },
          oXwKw_c3ZOoi9QtXVCcNN: {
            pollingInterval: 0,
          },
          hUoJWm0yBMBhacp0Y3doF: {
            pollingInterval: 0,
          },
          kvfl_zp4Qqthu_f6EKt1g: {
            pollingInterval: 0,
          },
          c7e3Cb5ucd_g9L6tiqi5M: {
            pollingInterval: 0,
          },
          '9WFx9qBETWHEYnBt-AU3i': {
            pollingInterval: 0,
          },
          Lwu6S_xOMVbREIEtYrubl: {
            pollingInterval: 0,
          },
          '6rwJlN-IVJjGJq6zPvRcX': {
            pollingInterval: 0,
          },
          'SzkWwOnc-PteIetN_qSZI': {
            pollingInterval: 0,
          },
          XGQl2SWssJncrQeVDVsCB: {
            pollingInterval: 0,
          },
        },
      'getFilterPreferences({"siteId":"71a8dc31-1337-e811-a955-000d3a2bcac2","useErrorBoundary":false})':
        {
          'qhZxkccLz3L-OYp4J7UmB': {
            pollingInterval: 0,
          },
        },
    },
    config: {
      online: true,
      focused: true,
      middlewareRegistered: true,
      refetchOnFocus: false,
      refetchOnReconnect: false,
      refetchOnMountOrArgChange: false,
      keepUnusedDataFor: 60,
      reducerPath: 'biteApi',
    },
  },
  dspApi: {
    queries: {},
    mutations: {},
    provided: {},
    subscriptions: {},
    config: {
      online: true,
      focused: true,
      middlewareRegistered: true,
      refetchOnFocus: false,
      refetchOnReconnect: false,
      refetchOnMountOrArgChange: false,
      keepUnusedDataFor: 60,
      reducerPath: 'dspApi',
    },
  },
};

export let useFilteringData = {
  filterItems: [
    {
      id: '3348_17206431',
      menuItemId: 17206431,
      uomId: 30631,
      title: 'Granny Smith Apple',
      description: null,
      imageUrl: null,
      price: 2.4,
      viewablePrices: null,
      calories: 0,
      isVegan: true,
      isVegetarian: true,
      isMindful: false,
      isFavorite: false,
      category: 'Fruits',
      subCategory: undefined,
      moment: 'Fruits and Vegetables',
      showAllergensWarning: false,
      allergens: [],
      genericCategory: 'Fruit',
      'data-testid': 'product-list-item-3348_17206431',
    },
    {
      id: '3348_172064319',
      menuItemId: 172064319,
      uomId: 306319,
      title: 'Garlic Soup',
      description: null,
      imageUrl: null,
      price: 4.2,
      viewablePrices: null,
      calories: 0,
      isVegan: true,
      isVegetarian: true,
      isMindful: false,
      isFavorite: false,
      category: 'Soups',
      subCategory: undefined,
      moment: 'Soups',
      showAllergensWarning: false,
      allergens: [
        {
          id: 'Milk',
          name: 'Milk',
          contains: true,
        },
      ],
      genericCategory: 'Soup',
      'data-testid': 'product-list-item-3348_172064319',
    },
    {
      id: '3348_17206432',
      menuItemId: 17206432,
      uomId: 30632,
      title: 'Abacaxi Pineapple',
      description: null,
      imageUrl: null,
      price: 3.6,
      viewablePrices: null,
      calories: 0,
      isVegan: true,
      isVegetarian: true,
      isMindful: false,
      isFavorite: false,
      category: 'Fruits',
      subCategory: undefined,
      moment: 'Fruits and Vegetables',
      showAllergensWarning: false,
      allergens: [],
      genericCategory: 'Fruit',
      'data-testid': 'product-list-item-3348_17206432',
    },
    {
      id: '3348_172064321',
      menuItemId: 17206432,
      uomId: 30632,
      title: 'Abacaxi Pineapple',
      description: null,
      imageUrl: null,
      price: 2.4,
      viewablePrices: null,
      calories: 0,
      isVegan: true,
      isVegetarian: true,
      isMindful: false,
      isFavorite: false,
      category: 'Fruits',
      subCategory: undefined,
      moment: 'Fruits and Vegetables',
      showAllergensWarning: false,
      allergens: [],
      genericCategory: 'Fruit',
      'data-testid': 'product-list-item-3348_172064321',
    },
    {
      id: '3348_17206433',
      menuItemId: 17206433,
      uomId: 30633,
      title: 'Alice Grape',
      description: null,
      imageUrl: null,
      price: 4.8,
      viewablePrices: null,
      calories: 0,
      isVegan: true,
      isVegetarian: true,
      isMindful: false,
      isFavorite: false,
      category: 'Fruits',
      subCategory: undefined,
      moment: 'Fruits and Vegetables',
      showAllergensWarning: false,
      allergens: [],
      genericCategory: 'Fruit',
      'data-testid': 'product-list-item-3348_17206433',
    },
  ],
  hasFilters: true,
  selectedNum: 0,
  hasAtLeastOneFilterInModal: true,
  searchString: '',
  filtering: {},
  handleSearchChange: () => jest.fn(),
  handleFilteringChange: () => jest.fn(),
};
