import { Dispatch } from 'redux';

import { fetchJsonActionCreator } from '../../../actions';
import { shouldUseMockData } from '../../../config';
import { PreRequestParams } from '../../../types/actionCreator.types';
import { getApiEndpoints } from '../config';
import mockResponse from '../mockResponse';

import { Facility } from './actions.types';

import { isMyVillage } from '@/modules/Core/helpers/helpers';

const { baseUrl, getAllFacilities, getOneFacility } = getApiEndpoints();
export const getAll = (() => {
  const lockAction = { type: 'FACILITIES_FETCHING_ALL' };
  const urlConstructor = () => `${baseUrl}${getAllFacilities}`;

  const argConfig = {
    siteId: { toQueryString: true, toThen: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (
      json: { facilities: Facility[] },
      dispatch: Dispatch,
      thenCustomArg: { siteId: string }
    ) => {
      const { siteId } = thenCustomArg;
      await dispatch({
        type: 'FACILITIES_FETCHED_ALL',
      });
      await dispatch({
        type: 'FACILITIES_SUCCESS_ALL',
        facilities: json.facilities,
        siteId,
      });
    },
    other: async (dispatch: Dispatch) => {
      console.log('Error fetching content list');
      await dispatch({
        type: 'FACILITIES_FETCHED_ALL',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse['getAllFacilities'];
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getOne = (() => {
  const lockAction = { type: 'FACILITIES_FETCHING_ONE' };

  const urlConstructor = () => `${baseUrl}${getOneFacility}`;

  const argConfig = {
    id: isMyVillage() ? { toQueryString: true } : { toRoute: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: Facility, dispatch: Dispatch) => {
      await dispatch({
        type: 'FACILITIES_FETCHED_ONE',
      });
      await dispatch({
        type: 'FACILITIES_SUCCESS_ONE',
        id: json.id,
        item: json,
      });
    },
    other: async (dispatch: Dispatch) => {
      console.log('Error fetching content details');
      await dispatch({
        type: 'FACILITIES_FETCHED_ONE',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse['getOneFacility'];
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();
