/*!
 * Clamp.js 0.5.1
 *
 * Copyright 2011-2013, Joseph Schmitt http://joe.sh
 * Released under the WTFPL license
 * http://sam.zoy.org/wtfpl/
 */

type ClampOptions = {
  clamp?: number | string;
  useNativeClamp?: boolean;
  splitOnChars?: string[];
  animate?: boolean;
  truncationChar?: string;
  truncationHTML?: string;
};

function clamp(element: HTMLElement, options: ClampOptions) {
  options = options || {};

  let win = window,
    opt = {
      clamp: options.clamp || 2,
      useNativeClamp: typeof options.useNativeClamp !== 'undefined' ? options.useNativeClamp : true,
      splitOnChars: options.splitOnChars || ['.', '-', '–', '—', ' '], //Split on sentences (periods), hypens, en-dashes, em-dashes, and words (spaces).
      animate: options.animate || false,
      truncationChar: options.truncationChar || '…',
      truncationHTML: options.truncationHTML,
    },
    sty = element.style,
    originalText = element.innerHTML,
    supportsNativeClamp = typeof element.style.webkitLineClamp !== 'undefined',
    clampValue = opt.clamp,
    isCSSValue =
      typeof clampValue === 'string' &&
      (clampValue.indexOf('px') > -1 || clampValue.indexOf('em') > -1),
    truncationHTMLContainer: HTMLElement | undefined;

  if (opt.truncationHTML) {
    truncationHTMLContainer = document.createElement('span');
    truncationHTMLContainer.innerHTML = opt.truncationHTML;
  }

  // UTILITY FUNCTIONS __________________________________________________________

  function computeStyle(elem: HTMLElement, prop: string): string {
    return win.getComputedStyle(elem, null).getPropertyValue(prop);
  }

  /**
   * Returns the maximum number of lines of text that should be rendered based
   * on the current height of the element and the line-height of the text.
   */
  function getMaxLines(height?: number) {
    const availHeight = height || element.clientHeight,
      lineHeight = getLineHeight(element);

    return Math.max(Math.floor(availHeight / lineHeight), 0);
  }

  /**
   * Returns the maximum height a given element should have based on the line-
   * height of the text and the given clamp value.
   */
  function getMaxHeight(clmp: number) {
    const lineHeight = getLineHeight(element);
    return lineHeight * clmp;
  }

  /**
   * Returns the line-height of an element as an integer.
   */
  function getLineHeight(elem: HTMLElement) {
    let lh: string | number = computeStyle(elem, 'line-height');
    if (lh.toString() === 'normal') {
      // Normal line heights vary from browser to browser. The spec recommends
      // a value between 1.0 and 1.2 of the font size. Using 1.1 to split the diff.
      lh = parseInt(computeStyle(elem, 'font-size')) * 1.2;
    }
    return typeof lh === 'number' ? lh : parseInt(lh);
  }

  // MEAT AND POTATOES (MMMM, POTATOES...) ______________________________________
  let splitOnChars = opt.splitOnChars.slice(0),
    splitChar: string = splitOnChars[0],
    chunks: string[] | null | undefined,
    lastChunk: string | undefined;

  /**
   * Gets an element's last child. That may be another node or a node's contents.
   */
  function getLastChild(elem: HTMLElement): ChildNode {
    const lastChild = elem.lastChild as HTMLElement;
    //Current element has children, need to go deeper and get last child as a text node
    if (lastChild?.children && lastChild?.children.length > 0) {
      return getLastChild(Array.prototype.slice.call(elem.children).pop());
    }
    //This is the absolute last child, a text node, but something's wrong with it. Remove it and keep trying
    else if (
      !elem.lastChild ||
      !elem.lastChild.nodeValue ||
      elem.lastChild.nodeValue === '' ||
      elem.lastChild.nodeValue === opt.truncationChar
    ) {
      if (elem.lastChild?.parentNode) {
        elem.lastChild.parentNode.removeChild(elem.lastChild);
      }
      return getLastChild(element);
    }
    //This is the last child we want, return it
    else {
      return elem.lastChild;
    }
  }

  /**
   * Removes one character at a time from the text until its width or
   * height is beneath the passed-in max param.
   */
  function truncate(target: ChildNode, maxHeight: number): string | undefined {
    if (!maxHeight || !target.nodeValue) {
      return;
    }

    /**
     * Resets global variables.
     */
    function reset() {
      splitOnChars = opt.splitOnChars.slice(0);
      splitChar = splitOnChars[0];
      chunks = null;
      lastChunk = undefined;
    }

    const nodeValue = target.nodeValue.replace(opt.truncationChar, '');

    //Grab the next chunks
    if (!chunks) {
      //If there are more characters to try, grab the next one
      if (splitOnChars.length > 0) {
        splitChar = splitOnChars.shift() as string;
      }
      //No characters to chunk by. Go character-by-character
      else {
        splitChar = '';
      }

      chunks = nodeValue.split(splitChar);
    }

    //If there are chunks left to remove, remove the last one and see if
    // the nodeValue fits.
    if (chunks.length > 1) {
      lastChunk = chunks.pop();
      applyEllipsis(target, chunks.join(splitChar));
    }
    //No more chunks can be removed using this character
    else {
      chunks = null;
    }

    //Insert the custom HTML before the truncation character
    if (truncationHTMLContainer) {
      target.nodeValue = target.nodeValue.replace(opt.truncationChar, '');
      element.innerHTML =
        target.nodeValue + ' ' + truncationHTMLContainer.innerHTML + opt.truncationChar;
    }

    //Search produced valid chunks
    if (chunks) {
      //It fits
      if (element.clientHeight <= maxHeight) {
        //There's still more characters to try splitting on, not quite done yet
        if (splitChar !== '') {
          applyEllipsis(target, chunks.join(splitChar) + splitChar + lastChunk);
          chunks = null;
        }
        //Finished!
        else {
          return element.innerHTML;
        }
      }
    }
    //No valid chunks produced
    else {
      //No valid chunks even when splitting by letter, time to move
      //on to the next node
      if (splitChar === '') {
        applyEllipsis(target, '');
        target = getLastChild(element);

        reset();
      }
    }

    //If you get here it means still too big, let's keep truncating
    if (opt.animate) {
      setTimeout(
        function () {
          truncate(target, maxHeight);
        },
        opt.animate === true ? 10 : opt.animate
      );
    } else {
      return truncate(target, maxHeight);
    }
  }

  function applyEllipsis(elem: ChildNode, str: string) {
    elem.nodeValue = str + opt.truncationChar;
  }

  // CONSTRUCTOR ________________________________________________________________

  if (clampValue === 'auto') {
    clampValue = getMaxLines();
  } else if (isCSSValue) {
    clampValue = getMaxLines(parseInt(clampValue.toString()));
  }

  let clampedText;
  if (supportsNativeClamp && opt.useNativeClamp) {
    sty.overflow = 'hidden';
    sty.textOverflow = 'ellipsis';
    sty.webkitBoxOrient = 'vertical';
    sty.display = '-webkit-box';
    sty.webkitLineClamp = clampValue.toString();

    if (isCSSValue) {
      sty.height = opt.clamp + 'px';
    }
  } else {
    const height = getMaxHeight(parseInt(clampValue.toString()));
    if (height <= element.clientHeight) {
      clampedText = truncate(getLastChild(element), height);
    }
  }

  return {
    original: originalText,
    clamped: clampedText,
  };
}

export default clamp;
