import { Modifier, NutritionFacts } from '../types/orderState.types';
import { ModifierSelections } from '../types/productDetails.types';

export const calculateNutritionSum = (
  selectedModifiers: ModifierSelections[],
  modifiers: Modifier[] | undefined
): NutritionFacts | null => {
  if (!modifiers) {
    return null;
  }

  const modifierList = selectedModifiers.map((element) => {
    let nutritionFactsArray: (NutritionFacts | undefined)[] = [];
    const selectedModifierItems = modifiers
      .find((modifier) => modifier.id === element.modifierId)
      ?.modifierItems.filter((modifierItem) =>
        element.itemIds.includes(modifierItem.id.toString())
      );

    selectedModifierItems?.forEach((modItem) => {
      if (modItem.nutritions) {
        nutritionFactsArray.push(
          multiplyNutritions(modItem.nutritions, element.itemQuantities[modItem.id])
        );
      }
    });
    return nutritionFactsArray;
  });

  const nutritionFactsList = modifierList
    .flat(1)
    .filter((nutritionFact) => !!nutritionFact) as NutritionFacts[];

  return sumPropertiesOfNutritions(nutritionFactsList);
};

const sumPropertiesOfNutritions = (objs: NutritionFacts[]) => {
  return objs.reduce((a: NutritionFacts, b: NutritionFacts) => {
    for (let k in b) {
      if (b.hasOwnProperty(k))
        a[k as keyof typeof a] = (a[k as keyof typeof a] || 0) + (b[k as keyof typeof a] || 0);
    }
    return a;
  }, {} as NutritionFacts);
};

const multiplyNutritions = (nutritionFacts: NutritionFacts, multiplier: number) => {
  const result = { ...nutritionFacts };
  Object.keys(result).forEach(function (key) {
    result[key as keyof typeof result] *= multiplier;
  });
  return result;
};
