const labels = {
  All: { 'Ref: Page title': 'Fornitori' },
  SuppliersList: {
    'Ref: Supplier details': 'Guarda i detagli',
    'Missing supplier list': 'Non abbiamo trovato nessun fornitore.',
    'Try again later': 'Riprovare più tardi',
  },
  SupplierDetails: {
    'Address label': 'Indirizzo:',
    'Website label': 'Sito web:',
    'Details label': 'Dettagli',
  },
};
export default labels;
