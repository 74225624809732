import moment from 'moment';

import { ImageDefaultFoodImg } from '../../../assets/images';
import {
  Filter,
  FilterPosition,
  FilterType,
} from '../../../components/atoms/Filters/Filters.types';
import ImageWithFallback from '../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { HistoricalOrderType, orderTypeFilterId } from '../types/orderHistory.types';
import { OrderHistory } from '../types/orderState.types';

export const mapListItems = (
  orders: OrderHistory[] | undefined,
  orderType: HistoricalOrderType
) => {
  return (
    orders?.map((order) => {
      return {
        order: order,
        id: order.orderId.toString(),
        'data-testid': `order-history-tile-${order.orderId}`,
      };
    }) ?? []
  ).sort((orderA, orderB) =>
    orderType === HistoricalOrderType.CurrentAndFuture
      ? new Date(orderA.order.pickupDate).getTime() - new Date(orderB.order.pickupDate).getTime()
      : new Date(orderB.order.pickupDate).getTime() - new Date(orderA.order.pickupDate).getTime()
  );
};

const isOrderInThePast = (order: { pickupDate: string }) =>
  moment(order.pickupDate).isBefore(moment());

const isOrderInFuture = (order: { pickupDate: string }) =>
  moment(order.pickupDate).isAfter(moment());

export const prepareFilters = (
  selectedOrderType: HistoricalOrderType,
  label: Function,
  listItems: Array<{
    order: OrderHistory;
  }>
): Filter[] => {
  return [
    {
      id: orderTypeFilterId,
      multiple: false,
      position: FilterPosition.NOT_IN_MODAL,
      options: [
        {
          value: HistoricalOrderType.CurrentAndFuture,
          label: label('Ref: Not past orders'),
          default: selectedOrderType === HistoricalOrderType.CurrentAndFuture,
        },
        {
          value: HistoricalOrderType.Past,
          label: label('Ref: Past orders'),
          default: selectedOrderType === HistoricalOrderType.Past,
        },
      ],
      displayType: FilterType.TAB,
      apply: () =>
        listItems
          .filter(
            (orderDetails) =>
              (HistoricalOrderType.Past === selectedOrderType &&
                isOrderInThePast(orderDetails.order)) ||
              (HistoricalOrderType.CurrentAndFuture === selectedOrderType &&
                isOrderInFuture(orderDetails.order))
          )
          .map((order) => order.order.orderId.toString()),
    },
  ];
};

export const getImageForOrder = (
  getFacilityImage: (facilityId: string) => string | undefined,
  order: OrderHistory
) => {
  const facilityImage = getFacilityImage(order.facilityId);

  const imgInfo = !!facilityImage
    ? {
        src: facilityImage,
        alt: order.orderId.toString(),
      }
    : undefined;

  return <ImageWithFallback data-testid="order-history" imgInfo={imgInfo} imgElement={<ImageDefaultFoodImg />} />;
};
