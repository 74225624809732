const labels = {
  All: {
    'Ref: Redeem coffee description': '您免费的，新鲜酿造的咖啡正在等待',
    'Ref: Congratulations': '恭喜',
    'Ref: Get free coffee': '获得免费咖啡！',
    'Ref: Free coffee description': '装满您的杯子，赚取积分以享用免费咖啡。',
    'Ref: Redeem': '兑换！',
  },
  StampPage: {
    'Ref: Page title': '免费咖啡',
    'Ref: seconds left': '剩下几秒钟',
    'Ref: How it works': '这个怎么运作',
    'Ref: How it works description': '每次购买咖啡时，请按扫描按钮并在收银机上扫描提供的QR码。',
  },
  StampActionButton: {
    'Ref: Scan QR Code': '扫描二维码',
    'Ref: Collect reward': '收集奖励',
    'Ref: Reward collected': '收集的奖励',
    'Ref: Invalid QR Code': '无效的QR码',
    'Ref: Invalid QR Code description': 'QR码未识别',
    'Ref: Close': '关闭',
    'Ref: Redeem button': '兑换',
    "Ref: The QR Code isn't recognized": 'QR码未识别',
    'Ref: Cancel': '取消',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': '恭喜！',
    'Ref: Confirm redeem description':
      '确定要使用这个？如果是这样，您只有60秒钟可以在收银机上显示手机并完成购买',
    'Ref: Congratulations modal description': '立即获取您的免费咖啡！',
  },
  Widget: { 'Ref: Free Coffee widget title': '免费咖啡！', 'Ref: See All': '查看全部' },
};
export default labels;
