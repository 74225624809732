import { screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { useGetAvailablePromotionsQuery } from '../../api';

import PromotionInfo from './PromotionInfo';

const state = {
  Order: {
    availablePromotions: [
      {
        id: 1,
        name: 'Promotion 1',
        description: 'Description 1',
        discount: 1,
        threshold: 1,
        items: [
          {
            discount: 0,
            uomId: 2099,
            menuIds: [120, 160],
          },
        ],
      },
      {
        id: 2,
        name: 'Promotion 2',
        description: 'Description 2',
        discount: 1,
        threshold: 1,
        items: [
          {
            discount: 0,
            uomId: 2099,
            menuIds: [120, 160],
          },
        ],
      },
      {
        id: 3,
        name: 'Promotion 3',
        description: 'Description 3',
        discount: 1,
        threshold: 1,
        items: [
          {
            discount: 0,
            uomId: 2099,
            menuIds: [120, 160],
          },
          {
            discount: 0,
            uomId: 2099,
            menuIds: [120, 160],
          },
        ],
      },
    ],
    draft: {
      promotionsApplied: [
        {
          id: 2,
        },
      ],
    },
    menus: [
      {
        id: 160,
        facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
        name: 'IFMS Breakfast Tavern Menu',
        isOrderable: true,
        menuItems: [
          {
            name: 'BBQ Brekky Wrap',
            startTime: 1672702252016,
            endTime: 1672785052016,
            dayPart: 'Breakfast',
            section: 'Breakfast Favourites',
            productPortions: [
              {
                portionId: '4cb91217-aa68-453c-8c03-452975cfaee1',
                uomId: 2099,
              },
            ],
            menuItemId: 4400666,
          },
        ],
      },
    ],
  },
  Shared: { language: { currentLanguageCode: 'en' } },
};

const availablePromotions = [
  {
    id: 1,
    name: 'Promotion 1',
    description: 'Description 1',
    discount: 1,
    threshold: 1,
    items: [
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
    ],
  },
  {
    id: 2,
    name: 'Promotion 2',
    description: 'Description 2',
    discount: 1,
    threshold: 1,
    items: [
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
    ],
  },
  {
    id: 3,
    name: 'Promotion 3',
    description: 'Description 3',
    discount: 1,
    threshold: 1,
    items: [
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
    ],
  },
];

const mockDispatch = jest.fn();
const mockSelector = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
  useDispatch: () => mockDispatch,
}));

jest.mock('@/modules/Order/api/api', () => ({
  useGetAvailablePromotionsQuery: jest.fn(),
}));

describe('PromotionInfo', () => {
  beforeEach(() => {
    mockSelector.mockReturnValue(state);

    (useGetAvailablePromotionsQuery as jest.Mock).mockReturnValue({
      data: availablePromotions,
    });
  });

  const site = { id: 'site1' };
  renderComponent(PromotionInfo, { site }, state);

  it('renders the correct number of promotion items', async () => {
    expect(screen.getByText('Promotion 1')).toBeTruthy();
    expect(screen.getByText('Promotion 2')).toBeTruthy();
    expect(screen.getByText('Promotion 3')).toBeTruthy();
  });

  it('renders correct activated label for promotion', async () => {
    const activated = screen.getAllByText('Activated');
    expect(activated.length).toBe(1);
  });

  it('renders correct See item label for promotion', async () => {
    const seeItem = screen.getAllByText('See item');
    expect(seeItem.length).toBe(1);
  });

  it('renders promotions in correct order', async () => {
    const promotions = await screen.findAllByText(/Promotion/);
    expect(promotions[0].innerHTML).toBe('Promotion 2');
    expect(promotions[1].innerHTML).toBe('Promotion 1');
  });
});
