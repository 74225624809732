import { act, cleanup, renderHook } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import { defaultSite } from '../../../../Sites/__mocks/mocks';
import { useFetchSuggestions } from '../useFetchSuggestions';

const mockGetProductSuggestionsQuery = jest.fn();

jest.mock('../../../api', () => {
  return {
    getProductSuggestionsQuery: () => mockGetProductSuggestionsQuery(),
  };
});

describe('useFetchSuggestions hook', () => {
  const env = global.process.env;

  afterAll(() => cleanup());

  describe('When is Kiosk', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: APP_DISPLAY_NAME.BITEKIOSK,
        REACT_APP_PORTAL_ENV_CODE: 'prod',
      };
    });

    beforeEach(async () => {
      await act(async () => {
        renderHook(() =>
          useFetchSuggestions(defaultSite.id, new Date().toString(), 111, true, true)
        );
      });
    });

    it('should not fetch sugestions', () => {
      expect(mockGetProductSuggestionsQuery).toHaveBeenCalledTimes(0);
    });
  });

  describe('When is Bite', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: APP_DISPLAY_NAME.BITE,
        REACT_APP_PORTAL_ENV_CODE: 'prod',
      };
    });

    describe('and menu is not from RR or not orderable', () => {
      beforeEach(async () => {
        await act(async () => {
          renderHook(() =>
            useFetchSuggestions(defaultSite.id, new Date().toString(), 111, false, true)
          );
        });
      });

      it('should not fetch suggestions', () => {
        expect(mockGetProductSuggestionsQuery).toHaveBeenCalledTimes(0);
      });
    });

    describe('and menu is from RR and orderable', () => {
      beforeEach(async () => {
        await act(async () => {
          renderHook(() =>
            useFetchSuggestions(defaultSite.id, new Date().toString(), 111, true, true)
          );
        });
      });

      it('should fetch suggestions', () => {
        expect(mockGetProductSuggestionsQuery).toHaveBeenCalledTimes(1);
      });
    });

    describe('and menu is not defined', () => {
      beforeEach(async () => {
        await act(async () => {
          renderHook(() => useFetchSuggestions(defaultSite.id, new Date().toString()));
        });
      });

      it('should fetch suggestions', () => {
        expect(mockGetProductSuggestionsQuery).toHaveBeenCalledTimes(1);
      });
    });
  });
});
