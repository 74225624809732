const labels = {
  All: {
    'Ref: Assembled': 'Montado',
    'Ref: Submitted': 'Submetido',
    'Ref: Fulfilled': 'Realizada',
    'Ref: Completed': 'Concluído',
    'Ref: Paid': 'Pago',
    'Ref: Approved': 'Aprovado',
    'Ref: Ready For Pickup': 'Pronto para pegar',
    'Ref: Delivered': 'Entregue',
    'Ref: Received': 'Recebido',
    'Ref: Rejected': 'Rejeitado',
    'Ref: Payment Error': 'Erro de pagamento',
    'Ref: Pending Payment': 'Pagamento Pendente',
    'Ref: Preparing': 'Preparando',
    'Ref: Refunded': 'Devolveu',
    'Ref: Cancelled By User': 'Cancelado pelo usuário',
    'Ref: Refund Requested': 'Reembolso solicitado',
    'Ref: Refund Failed': 'O reembolso falhou',
    'Ref: Payment Processing': 'Processo de pagamento',
    'Ref: Not Paid': 'Não pago',
    'Ref: Total:': 'Total:',
    'Ref: Pickup': 'Pegar',
    'Ref: Eat-in': 'Comer',
    'Ref: Scan&Go': 'Digitalize e vá',
    'Ref: Fulfillment Type': 'Tipo de atendimento',
    'Ref: Delivery': 'Entrega',
    'Ref: You have no future orders': 'Você não tem ordens futuras',
    'Ref: You have no past orders': 'Você não tem ordens anteriores',
    'Ref: You have no orders': 'Você não tem ordens',
    'Ref: All orders': 'Todos os pedidos',
    'Ref: Not past orders': 'Ordens próximas',
    'Ref: Past orders': 'Ordens anteriores',
    'Ref: Vegan': 'Vegan.',
    'Ref: Vegeterian': 'Vegetariano',
    'Ref: See item': 'Veja o item',
    'Ref: Activated': 'ativado',
    'Ref: No suggestions': 'Nenhuma sugestão encontrada',
    'Ref: Get your receipt': 'Obtenha seu recibo',
    'Ref: Print': 'Imprima seu recibo',
    'Ref: Email': 'Receba seu recibo por e-mail',
    'Ref: Receipt Modal message': 'Como você gostaria de obter seu recibo?',
    'Ref: Confirm': 'confirme',
    'Ref: Your email address': 'Seu endereço de email',
    Suggestion: 'Sugestão',
    'Ref: Loyalty Reward': 'Recompensa de lealdade',
    'Ref: Equation for stamps per item': '1 compra = 1 carimbo',
    'Ref: Paid by employer': 'O valor pago pelo seu empregador',
    'Ref: Paid by user': 'O valor pago por você',
  },
  ProductsList: {
    'Ref: Page title': 'Lista de produtos',
    'Ref: Facility details': 'Detalhes da instalação',
    'Ref: Search products list': 'Itens de pesquisa',
    'Ref: filter label: menus': 'Cardápio',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoria',
    'Ref: all': 'Tudo',
    'Ref: today': 'Hoje',
    'Ref: tomorrow': 'Amanhã',
    'Ref: see more': 'Ver mais',
    'Ref: see less': 'Veja menos',
    'Ref: reset': 'Redefinir',
    'Ref: View in cart': 'Vista no carrinho',
    'Ref: You have changed location': 'Você mudou o local',
    'Ref: You are now at': 'Você está agora em',
    'Ref: Menu card': 'Cardápio',
    'Ref: Table': 'Número da tabela',
    'Service unavailable': 'Serviço não disponível',
    'Ref: Ordering unavailable':
      'Cuidado! O pedido neste site não está disponível no momento, tente mais tarde.',
    'Ref: Filters': 'Filtros (alérgenos e muito mais)',
  },
  ProductScanner: {
    'Ref: cart': 'Carrinho',
    'Ref: Product Scanned':
      'foi adicionado ao carrinho. Você pode continuar a digitalizar os códigos de barras para adicioná -los no seu carrinho.',
    'Ref: Scan or adjust':
      'foi adicionado ao carrinho. Ajuste a quantidade ou digitalize outro produto.',
    'Ref: Product Not Matched': 'Código de barras não foi reconhecido. Por favor, tente novamente',
    'Ref: Scan article code': 'Digitalize código de barras',
    'Ref: Native error product cannot be added to cart':
      'Item de diferente menu ou parte do dia não pode ser adicionado ao carrinho',
    'Ref: product scanner placeholder':
      'Por favor, digitalize o código de barras para adicioná -lo ao seu carrinho',
  },
  Order: { 'Ref: Page title': 'Pedido' },
  MenuSelector: {
    'Ref: Menu Page title': 'menus',
    'Ref: today': 'Hoje',
    'Ref: tomorrow': 'Amanhã',
    'Ref: Search products list': 'Itens de pesquisa',
    'Ref: You have changed location': 'Você mudou o local',
    'Ref: You are now at': 'Você está agora em',
  },
  ProductDetails: {
    'Ref: Page title': 'Detalhes do produto',
    'Ref: Serving Size': 'Porção',
    'Ref: Nutrition': 'Informação nutricional',
    'Ref: Nutrition adults': 'Os adultos precisam de cerca de 2000 kcal por dia',
    'Ref: Nutrition Calories': 'calorias',
    'Ref: Nutrition Calcium': 'Cálcio',
    'Ref: Nutrition Fat': 'Gordura',
    'Ref: Nutrition Saturated Fat': 'Gordura saturada',
    'Ref: Nutrition Carbohydrates': 'Carboidratos',
    'Ref: Nutrition Sugar': 'Açúcar',
    'Ref: Nutrition Protein': 'Proteína',
    'Ref: Nutrition Sodium': 'Sódio',
    'Ref: Nutrition cholesterol': 'Colesterol',
    'Ref: Nutrition dietaryFiber': 'Fibra dietética',
    'Ref: Nutrition iron': 'Ferro',
    'Ref: Nutrition potassium': 'Potássio',
    'Ref: Nutrition vitaminA': 'Vitamina A',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Additives': 'Aditivos',
    'Ref: Emission': 'Emissões de CO2',
    'Ref: This product generates': 'Este produto gera',
    'Ref: of CO2': 'de CO2.',
    'Ref: emissions, which equals a': 'emissões, que é igual a um',
    'Ref: car trip': 'viagem de carro',
    'Ref: Ingredients': 'Ingrediente da receita',
    'Ref: Allergens': 'alérgenos',
    'Ref: Always there': 'Sempre lá',
    'Ref: Allergens selected': 'Alérgenos selecionados no filtro:',
    'Ref: minimum required': 'Mínimo requerido',
    'Ref: you have to add at least': 'Você tem que adicionar pelo menos',
    'Ref: ingredients': 'ingredientes',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Livre',
    'Ref: Based on customization': 'Com base em sua personalização',
    'Ref: Additives eggs': 'ovos',
    'Ref: Additives fish': 'peixe',
    'Ref: Additives milk': 'leite',
    'Ref: Additives nuts': 'nozes',
    'Ref: Additives wheat': 'trigo',
    'Ref: Additives peanuts': 'amendoim',
    'Ref: Additives soy beans': 'Beans de soja',
    'Ref: Additives sesame seeds': 'sementes de Sesamo',
    'Ref: Review': 'Análise',
    'Ref: Per portion': 'Por porção',
    'Ref: Per 100g': 'Por 100g',
    'Ref: Allergen selected': 'Alérgeno selecionado no filtro de pesquisa',
    'Ref: Fitbit Log': 'Log para fitbit',
    'Ref: age verification required on delivery': 'Verificação de idade necessária na entrega',
    'Ref: alcohol content notification':
      'Ao colocar e pedir um desses itens, você declara que tem 18 anos ou mais. O abuso de álcool é perigoso para sua saúde.',
    'Ref: default': 'Predefinição',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ganho se você pedir este produto',
    'Ref: Loyalty Card Description':
      'O {stamp_form} será adicionado à seguinte lealdade {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'O programa de selos de fidelidade permite que você colete selos para recompensas.',
    'Ref: Loyalty Modal Description':
      'Quando você compra <b> {product_name}, 1 carimbo </b> será adicionado ao <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'e',
    'Ref: Stamp': 'Carimbo',
    'Ref: Stamps': 'Selos',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programas',
    'Ref: Loyalty Stamps': 'Selos de lealdade',
    'Ref: See Loyalty Stamps': 'Veja selos de fidelidade',
    Favorite: 'Favorito',
    'Ref: In favorites': 'O produto já está na lista de favoritos. Clique para removê -lo.',
    'Ref: Not in favorites':
      'O produto ainda não está na lista dos favoritos. Clique para adicioná -lo.',
    'Ref: Added to favorites': 'O produto foi adicionado à lista de favoritos.',
    'Ref: Removed from favorites': 'O produto foi removido da lista de favoritos.',
    'Ref: From': 'A partir de',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Mudança no carrinho',
    'Ref: Popup header for customizable item qty update': 'Este item é personalizável',
    'Ref: add new version': 'Adicione nova versão',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Atualize a quantidade de um item personalizável diretamente do seu carrinho.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Atualize a quantidade de um item personalizável diretamente do seu carrinho ou adicione uma nova versão.',
    'Ref: another context body':
      'Para adicionar itens deste site, instalação, momento/menu ou data, você precisa limpar o carrinho',
    'Ref: cancel': 'Cancelar',
    'Ref: add to cart': 'Adicionar ao carrinho',
    'Ref: view in cart': 'Carrinho',
    'Ref: Products in cart': 'Número de {product_name} no carrinho: {product_quantity}',
    'Ref: Customize': 'Customizar',
    'Ref: Add': 'Adicionar',
    'Ref: Free': 'Livre',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Cancelar',
    'Ref: notice': 'Perceber',
    'Ref: clear cart': 'Limpar carrinho',
    'Ref: Cart addition error message':
      'Atualmente, você tem itens no carrinho de {from_current}. Para adicionar itens de {from_selected}, você precisa limpar o carrinho',
  },
  Cart: {
    'Ref: Page title': 'Carrinho',
    'Ref: The cart is empty': 'O carrinho está vazio',
    'Ref: Clear cart': 'Limpar carrinho',
    'Ref: Continue shopping': 'Continue comprando',
    'Ref: Start shopping': 'Comece a fazer compras',
    'Ref: Today': 'Hoje',
    'Ref: Tomorrow': 'Amanhã',
    'Ref: Tax:': 'Imposto:',
    'Ref: Promotion:': 'Promoção:',
    'Ref: Total without promotion:': 'Total sem promoção:',
    'Ref: Delivery:': 'Entrega:',
    'Ref: Pickup informations': 'Informações sobre coleta',
    'Ref: Location': 'Localização',
    'Ref: Time': 'Tempo',
    'Ref: Phone': 'Seu número de telefone',
    'Ref: Instructions': 'Instruções',
    'Ref: Please select': 'Por favor selecione',
    'Ref: Enter special requests': 'Insira solicitações especiais aqui',
    'Ref: notice': 'Perceber',
    'Ref: Product is no more available':
      'Desculpe, mas parece que algum produto não está mais disponível. Por favor, verifique seu carrinho.',
    'Ref: ok': 'OK',
    'Ref: Validate': 'Validar meu pedido',
    'Ref: Pay now': 'Pague agora',
    'Ref: Charge to my department': 'Cobrar pelo meu departamento',
    'Ref: Pay on pickup': 'Pague por esses itens em busca',
    'Ref: Accept terms of sale prefix': 'eu aceito',
    'Ref: Accept terms of sale': 'Estes Termos de Vendas',
    'Ref: Continue': 'Continuar',
    'Ref: Please login or sign up to place an order':
      'Faça login ou inscreva -se para fazer um pedido',
    'Ref: Accept terms of sale suffix': ', e eu os li e os entendi.',
    'Ref: Payment modality': 'Forma de pagamento',
    'Ref: Other information': 'Outra informação',
    'Ref: Eat-in': 'Comer',
    'Ref: Pickup': 'Pegar',
    'Ref: Table': 'Número da tabela',
    'Ref: Required fields': 'Os campos obrigatórios',
    'Service unavailable': 'Serviço não disponível',
    'Ref: Cannot order':
      'Desculpe, você não pode fazer um pedido neste site no momento. Entre em contato com o restaurante para obter mais informações sobre o horário de funcionamento e os serviços disponíveis.',
    'Ref: Order Unavailable':
      'Ordem para a data escolhida, o tipo de atendimento e a localização não estão mais disponíveis!',
    'Ref: No Availability': 'Não há mais disponibilidade',
    'Ref: Continue on your device': 'Continue no seu dispositivo',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ganho se você concluir seu pedido',
    'Ref: Loyalty Card Description':
      'O {stamp_form} será adicionado à seguinte lealdade {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'O programa de selos de fidelidade permite que você colete selos para recompensas.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> ganho se você concluir seu pedido:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> adicionado para <b> {scheme_names} </b>',
    'Ref: Stamp': 'Carimbo',
    'Ref: Stamps': 'Selos',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programas',
    'Ref: Loyalty Stamps': 'Selos de lealdade',
    'Ref: See Loyalty Stamps': 'Veja selos de fidelidade',
    'Ref: Loyalty Info': 'Informações de lealdade',
    'Ref: Email address': 'Endereço de email',
    'Ref: and': 'e',
    'Ref: Order minimum amount': 'Desculpe, o valor mínimo de pagamento é {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Continue no seu dispositivo',
    'Ref: Scan QR to continue':
      'Por favor, digitalize o código QR para continuar a jornada em seu dispositivo.',
  },
  OrderHistory: { 'Ref: Orders': 'Ordens', 'Ref: Page Title': 'minhas ordens' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Preço unitário',
    'Ref: Download': 'Baixe o recibo',
    'Ref: Download error': 'Ocorreu um erro de download. Tente baixar o recibo mais tarde',
    'Ref: Order details title': 'detalhes do pedido',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Obrigada! Seu pedido agora está confirmado',
    'Ref: Text for pickup':
      'Você pode seguir seu status através do histórico do seu pedido. Se suas notificações estiverem ativadas, o atualizaremos quando estiver pronto para a coleta.',
    'Ref: Success Order Title': 'Obrigado pelo seu pedido',
    'Ref: Success Order Description':
      'Por favor, pague na finalização da compra para validar seu pedido.',
    'Ref: Success Order ID': 'Pedido de identificação',
    'Ref: Success Order Action': 'Nova ordem',
    'Ref: Text for dinein':
      'Seu pedido deve ser servido à sua mesa em breve. Basta perguntar a qualquer um dos nossos funcionários amigáveis ​​sobre o seu pedido de alimentação.',
    'Ref: Text for scan&go': 'Seu pedido está disponível no histórico do seu pedido',
    'Ref: Text for delivery':
      'Você pode rastrear seu status no histórico do seu pedido. Se suas notificações estiverem ativadas, informaremos você quando seu pedido estiver pronto.',
    'See details': 'Veja detalhes',
    'Go to home': 'Vá para casa',
  },
  FailOrder: {
    'Go to home': 'Vá para casa',
    'Ref: Kiosk Fail Title': 'Opa! Algo deu errado!',
    'Ref: Kiosk Fail Description':
      'Infelizmente, seu pedido não foi bem -sucedido. Tente novamente ou peça a um membro da equipe para ajudá -lo.',
    'Ref: Kiosk Fail Back': 'Voltar para a página anterior',
    'Ref: Kiosk Fail Action': 'Limpe a sessão e encomende novamente',
    'Ref: Error in cart validation':
      'Um erro ocorreu durante o seu pedido. Entre em contato com a instalação para resolver o problema.',
    'Facility contact': 'Contato da instalação',
    'Ref: Ordering failed': 'Pedidos falharam',
    'Ref: Order confirmed': 'Pedido confirmado',
  },
  TableCheckIn: {
    'Welcome at': 'Bem -vindo em',
    Table: 'Mesa:',
    'Ref: list title': 'Ordens recentes para hoje',
    'See the menu': 'Veja o menu',
    'Table Check-in': 'Tabela de check-in',
    'Ref #': 'Referência #',
    Checked: 'Verificado',
    'Check in': 'Check-in',
    'See details': 'Veja detalhes',
    'Ref: You have changed location': 'Você mudou o local',
    'Ref: You are now at': 'Você está agora em',
    'Order number': 'Número do pedido',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promoção aplicada',
    'Ref: Promotion applied description': 'Você beneficia uma promoção do seu carrinho.',
    'Ref: Promotion deleted title': 'Promoção excluída',
    'Ref: Promotion deleted description':
      'Você não beneficia mais a promoção porque não preenche as condições ou a promoção expirou.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Digitalize e vá', 'Ref: View only': 'Vista apenas' },
  ProductTile: {
    'Ref: Allergens': 'Alérgenos',
    'Ref: Presence of allergen': 'Presença de alérgenos',
    'Ref: Scan': 'Varredura',
    'Ref: From': 'A partir de',
    'Ref: Already in product favorite list. Click to remove it.':
      'Já na lista favorita do produto. Clique para removê -lo.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Atualmente não é adicionado à lista favorita do produto. Clique para adicioná -lo.',
    'Ref: Free': 'Livre',
  },
  OrderSummary: {
    'Ref: item': 'item',
    'Ref: items': 'Itens',
    'Ref: Clear': 'Claro',
    'Ref: Cart': 'Carrinho',
    'Ref: Subtotal': 'Subtotal',
    'Ref: Total': 'Total',
    'Ref: Tax': 'Imposto',
    'Ref: Promotion': 'Promoção',
    'Ref: Total without promotion': 'Total sem promoção',
    'Ref: Number of product in cart': 'Número de produto no carrinho',
    'Ref: Delivery': 'Entrega',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegano', 'Ref: Vegeterian': 'Vegetariano' },
  OrderInfoCard: {
    'Ref: Header': 'Ordenado para',
    'Ref: from': 'a partir de',
    'Ref: location': 'Localização',
    'Ref: Phone': 'Seu número de telefone',
    'Ref: Instructions': 'Instruções',
    'Ref: Order type': 'Tipo de pedido',
    'Ref: Pickup': 'Pegar',
    'Ref: Eat-in': 'Comer',
    'Ref: Scan&Go': 'Digitalize e vá',
  },
  ReorderWidget: {
    'Ref: No items': 'Nenhuma ordens ainda. Compre agora!',
    'Ref: Title': 'Pedido novamente',
  },
  MenusWidget: { 'Ref: no menu': 'Sem menu para o momento', 'Ref: Modal title': 'Menu para o dia' },
  PromotionInfo: { 'Ref: Discounts available': 'descontos disponíveis' },
  PromotionInfoItem: { 'Ref: Activate': 'Ativar' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Seus pedidos', 'Ref: Widget Title': 'minhas ordens' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Sugestão para o dia',
    'Ref: Any menu': 'Qualquer menu',
    Other: 'De outros',
    'Ref: Suggestions calendar button': 'Calendário de sugestões',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Porção',
    'Ref: Nutrition': 'Informação nutricional',
    'Ref: Nutrition adults': 'Os adultos precisam de cerca de 2000 kcal por dia',
    'Ref: Nutrition Calories': 'calorias',
    'Ref: Nutrition Calcium': 'Cálcio',
    'Ref: Nutrition Fat': 'Gordura',
    'Ref: Nutrition Saturated Fat': 'Gordura saturada',
    'Ref: Nutrition Carbohydrates': 'Carboidratos',
    'Ref: Nutrition Sugar': 'Açúcar',
    'Ref: Nutrition Protein': 'Proteína',
    'Ref: Nutrition Sodium': 'Sódio',
    'Ref: Nutrition cholesterol': 'Colesterol',
    'Ref: Nutrition dietaryFiber': 'Fibra dietética',
    'Ref: Nutrition iron': 'Ferro',
    'Ref: Nutrition potassium': 'Potássio',
    'Ref: Nutrition vitaminA': 'Vitamina A',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Ingredients': 'Ingrediente da receita',
    'Ref: Allergens': 'alérgenos',
    'Ref: Always there': 'Sempre lá',
    'Ref: Allergens selected': 'Alérgenos selecionados no filtro:',
    'Ref: minimum required': 'Mínimo requerido',
    'Ref: ingredients': 'ingredientes',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Livre',
    'Ref: Based on customization': 'Com base em sua personalização',
    'Ref: Additives eggs': 'ovos',
    'Ref: Additives fish': 'peixe',
    'Ref: Additives milk': 'leite',
    'Ref: Additives nuts': 'nozes',
    'Ref: Additives wheat': 'trigo',
    'Ref: Additives peanuts': 'amendoim',
    'Ref: Additives soy beans': 'Beans de soja',
    'Ref: Additives sesame seeds': 'sementes de Sesamo',
    'Ref: Review': 'Análise',
    'Ref: Per portion': 'Por porção',
    'Ref: Per 100g': 'Por 100g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} gratuitamente',
    'Ref: Number of free items': 'Número de itens gratuitos: {number_of_free_items}',
    'Ref: Allergen selected': 'Alérgeno selecionado no filtro de pesquisa',
    'Ref: Nutritional info': 'Informações nutricionais',
    'Ref: Info': 'Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'promoções' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Escolha o seu trabalho',
    'Ref: Validate': 'Validar',
    'Ref: Pricing Tiers dialog message': 'Você foi reconhecido com seu endereço de e -mail',
    'Ref: Please select your job in the company': 'Selecione seu trabalho na empresa',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Pegar',
    'Ref: Eat-in': 'Comer',
    'Ref: Scan&Go': 'Digitalize e vá',
    'Ref: Delivery': 'Entrega',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Como foi o serviço {facility_name} »?',
    'Ref: How do you rate the service': 'Como você avalia o serviço?',
  },
};
export default labels;
