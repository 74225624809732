import { CoreState } from '../../Core/types/State.types';
import { FacilityState } from '../../Facilities/types/types';

import { DeliveryOptionType, OrderType } from './cart.types';
import { DiscountTiersModel, DiscountTiersStatus, MenuType } from './menuSelector.types';
import { HistoricalOrderType } from './orderHistory.types';

export interface StateWithOrder {
  Order: OrderState;
  Shared: SharedState;
  Facilities: FacilityState;
  Core: CoreState;
}

export interface SharedState {
  language: {
    currentLanguageCode: string;
  };
}

export interface OrderState {
  menus: FacilityMenu[];
  allergens: Allergen[];
  discountTiersStatus: DiscountTiersStatus;
  discountTiers: DiscountTiersModel;
  cart?: Cart;
  draft?: OrderDraft;
  orderHistory?: OrderHistory[];
  orderDetails?: OrderDetails;
  pendingOrders?: PendingOrders[];
  lastOrder?: LastOrder;
  menuDownloadUrl: string | undefined;
  preselectedFacilityId?: string;
  preselectedSiteId?: string;
  preselectedLocationId?: string;
  hasSiteChanged: boolean;
  hasSiteChangeModalClosed: boolean;
  tableNumber?: string | null;
  tableQRScanTime?: number | null;
  menusType: MenuType;
  errors?: {
    payForOrder?: boolean;
    fetchingSiteId?: boolean;
  };
  locks: {
    getMenusForFacility?: boolean;
    getAllergens?: boolean;
    getPickupLocations?: boolean;
    getPickupTimeSlots?: boolean;
    createOrderDraft?: boolean;
    getPendingOrders?: boolean;
    payForOrder?: boolean;
    getMenuDownloadUrl?: boolean;
    getTiersStatus?: boolean;
    getDiscountTiers?: boolean;
    getPreselectedFacilityId?: boolean;
    getPreselectedSiteId?: boolean;
    retrieveOrderSession?: boolean;
  };
}

export interface Allergen {
  allergen: AllergenType;
  name: string;
}

export enum AllergenType {
  Other = 'Other',
  Milk = 'Milk',
  Eggs = 'Eggs',
  Treenuts = 'Treenuts',
  Peanut = 'Peanut',
  Shellfish = 'Shellfish',
  Wheat = 'Wheat',
  Soybean = 'Soybean',
  Fish = 'Fish',
  Mustard = 'Mustard',
  Celery = 'Celery',
  Gluten = 'Gluten',
  Crustaceans = 'Crustaceans',
  Lupin = 'Lupin',
  Molluscs = 'Molluscs',
  Nuts = 'Nuts',
  Sesame = 'Sesame',
  Sulphites = 'Sulphites',
  Walnuts = 'Walnuts',
  MSG = 'MSG',
  Rye = 'Rye',
  Barley = 'Barley',
  Oats = 'Oats',
  Spelt = 'Spelt',
  Kamut = 'Kamut',
  Strains = 'Strains',
  Almonds = 'Almonds',
  Hazelnuts = 'Hazelnuts',
  Cashews = 'Cashews',
  PecanNuts = 'PecanNuts',
  BrazilNuts = 'BrazilNuts',
  PistachioNuts = 'PistachioNuts',
  MacadamiaNuts = 'MacadamiaNuts',
  Alcohol = 'Alcohol',
  Beef = 'Beef',
  Pork = 'Pork',
  Poultry = 'Poultry',
  QueenslandNuts = 'QueenslandNuts',
}

export interface FacilityMenuJson {
  id: number;
  facilityId: string;
  name: string;
  date: string;
  message?: string;
  logoUrl?: string;
  isConferenceCatering: boolean;
  isOrderable: boolean;
  fulfillmentTypes: FulfillmentType[];
  menuItems: MenuItem[];
  source: FacilityMenuSource;
}

export enum FacilityMenuSource {
  Rr = 'Rr',
  Ifm = 'Ifm',
}

export interface FacilityMenu extends FacilityMenuJson {
  isScanAndGo: boolean;
  isFutureOrdering?: boolean;
}

export interface FulfillmentType {
  id: number;
  type: DeliveryOptionType;
}

export interface PickupLocation {
  pickupSpotId: number;
  name: string;
  order: number;
}

export interface PickupTimeSlot {
  timeSlotId: number;
  time: string;
}

export interface MenuItem {
  name: string;
  mealName: string;
  foodSubCategoryDescription: string;
  description: string;
  isOrderable: boolean;
  price: number | null;
  viewablePrices?: [{ name: string; value: number }];
  startTime: Date;
  endTime: Date;
  listImage: string;
  detailsImage: string;
  menuItemId: number;
  productPortions: ProductPortion[];
  dayPart: string | null;
  section: string | null;
  subSection: string | null;
  genericCategory: string | null;
  minimalPrice: number | null;
}

export interface ProductPortion {
  foodItemSourceKey: string;
  foodItemId: number;
  name: string;
  description: string;
  ingredients: string;
  portionSize: string;
  portion: string;
  gramWeight: string;
  isVegan: boolean;
  isVegetarian: boolean;
  isMindful: boolean;
  isDefault: boolean;
  price: number | null;
  viewablePrices: [{ name: string; value: number }];
  allergens: Allergen[];
  nutritions: NutritionFacts;
  modifiers: Modifier[];
  portionId: string;
  fishAdditive?: boolean;
  eggsAdditive?: boolean;
  peanutsAdditive?: boolean;
  sesameSeedsAdditive?: boolean;
  soyBeansAdditive?: boolean;
  wheatAdditive?: boolean;
  nutsAdditive?: boolean;
  milkAdditive?: boolean;
  co2Rating: string;
  co2Value: string;
  uomId: number;
  isFavorite: boolean;
  additives?: string;
  uomBarcodes: string[];
  alcoholContent?: number | null;
}

export interface Allergen {
  id: AllergenType;
  name: string;
  contains: boolean;
}

export interface DownloadMenuUrl {
  url: string;
}

export interface NutritionFacts {
  calories: number;
  caloriesUnits: number;
  kilojoules: number;
  carbohydrates: number;
  fat: number;
  protein: number;
  saturatedFat: number;
  transFat: number;
  polyunsaturatedFat: number;
  cholesterol: number;
  sodium: number;
  dietaryFiber: number;
  sugar: number;
  potassium: number;
  iron: number;
  calcium: number;
  vitaminA: number;
  vitaminC: number;
}

export interface Modifier {
  id: number;
  name: string;
  description: string;
  type: number;
  selectionLimit: number;
  isRequired: boolean;
  min: number;
  max: number;
  firstFree: number;
  usedFirstFree?: number;
  modifierItems: ModifierItem[];
}

export interface ModifierItem {
  id: number;
  name: string;
  price: number;
  priceLabel: string;
  sortOrder: number;
  maximumQuantity: number;
  description: string;
  min: number;
  max: number;
  firstFree: number;
  usedFirstFree?: number;
  isChild: boolean;
  nutritions: NutritionFacts;
  allergens: Allergen[];
}

export interface PickupInformation {
  pickupSpotId?: number | null;
  pickupSpotName?: string | null;
  pickupTimeSlotId?: string | null;
  pickupInstructions?: string | null;
  pickupPhoneNumber?: string;
  pickupTime?: string | null;
  email?: string;
}

export interface Cart {
  siteId: string;
  facilityId: string;
  submissionTrackingId?: string;
  menuId: number;
  date: Date;
  menuPortionItems?: CartMenuItem[];
  moment: string;
  pickupInformation?: PickupInformation;
  pickupLocations?: PickupLocation[];
  pickupTimeSlots?: PickupTimeSlot[];
  selectedFulfillmentType?: FulfillmentType;
  table?: string;
}

export interface CartMenuItem {
  id: string;
  menuItemId: number;
  uomId: number;
  foodItemId: number;
  img: string;
  name: string;
  price: number;
  quantity: number;
  modifiers?: ICartModifier[];
  modifiersDisplayText?: string;
  description: string;
  isFirstSelected?: Boolean;
  isVegan: boolean;
  isVegetarian: boolean;
  genericCategory: string;
}

export interface ICartModifier {
  modifierId: number;
  firstFree?: number;
  values?: ICartModifierValue[];
  displayText?: string;
}

export interface ICartModifierValue {
  valueId: number;
  quantity: number;
  price?: number;
  tax?: number;
  firstFree?: number;
  isFirstSelected?: boolean;
}

export type SubsidyType = number | null | undefined;
export interface OrderDraft {
  orderId: number;
  subtotal: number;
  total: number;
  tax: number;
  subsidy: SubsidyType;
  delivery: number;
  promotionDiscount?: number;
  promotionalDiscountForItems: PromotionalDiscountForItem[];
  promotionsApplied?: AppliedPromotion[];
  redeemedFoodItems?: RedeemedFoodItem[];
  loyaltyStampsToBeEarned?: LoyaltyStampToBeEarned[];
}

export interface ActivatedPromotion {
  id: number;
  name: string;
  discount: number;
}

export interface PromotionalDiscountForItem {
  uomId: number;
  foodItemId: number;
  discount: number;
  price: number;
  promotedItemModifiers: PromotedItemModifier[];
}

export interface PromotedItemModifier {
  modifierId: number;
  quantity: number;
}

export interface OrderHistory {
  delivery?: number;
  tax?: number;
  facilityId: string;
  orderId: number;
  orderNumber?: string;
  pickupDate: string;
  status: string;
  totalAmount: number;
  subsidy: SubsidyType;
  orderItems?: OrderHistoryItem[];
  taxArray?: OrderTaxLine[];
  orderType: HistoricalOrderType;
  deliveryOptionType: DeliveryOptionType;
  isPaid?: Boolean;
}

export interface OrderDetails {
  delivery: number;
  tax?: number;
  facilityId: string;
  orderId: number;
  pickupLocation: string;
  pickupDate: string;
  status: string;
  totalAmount: number;
  subTotal: number;
  subsidy: SubsidyType;
  instructions?: string;
  phoneNumber?: string;
  orderItems?: OrderDetailsItem[];
  deliveryOptionType: DeliveryOptionType;
  isPaid: boolean;
  promotionDiscount: number;
  appliedPromotions: AppliedPromotion[];
}

export interface AppliedPromotion {
  id: number;
  name?: string;
  discount: number;
}

export interface PendingOrders {
  outletId: number;
  pickupTime: string;
  orderNumber: string;
  orderStatus: string;
  deliveryOptionId: number;
  isCheckedIn: boolean;
  tableNumber?: string;
}

export interface LastOrder {
  orderId: number;
  facilityId: string;
  pickupInformation: PickupInformation;
  deliveryOptionType: DeliveryOptionType;
  orderItems: OrderDetailsItem[];
  total: number;
  subTotal: number;
  tax: number;
  subsidy: SubsidyType;
  delivery: number;
  promotionDiscount?: number;
  promotionsApplied?: AppliedPromotion[];
}

export interface OrderTaxLine {
  label: string;
  applicableOn: number;
  amount: number;
}

export interface OrderHistoryItem {
  name: string;
  price: number;
  quantity: number;
}

export interface OrderDetailsItem {
  name: string;
  price: number;
  quantity: number;
  promotionDiscount: number;
  isVegan: boolean;
  isVegetarian: boolean;
  imageUrl?: string;
  modifiers?: OrderDetailsItemModifier[];
  genericCategory?: string;
}
export interface OrderDetailsItemModifier {
  name: string;
  values: ModifierValue[];
}
export interface ModifierValue {
  name: string;
  price: number;
  tax: number;
  valueId: number;
  quantity: number;
}

export interface IDraftSubmitParams {
  cart: Cart;
  siteId: string;
  theme: Record<string, string>[];
  draftOrderId?: number;
  timeSlotDate?: string;
  table?: string;
  orderType?: OrderType;
}

export interface ReorderMenuItem extends ScannableMenuItem {
  menuItemId: number;
  name: string;
  mealName?: string;
  foodSubCategoryDescription?: string;
  description: string;
  price: number;
  productPortions: ProductPortion[];
  listImage?: string | null;
  detailsImage?: string | null;
  genericCategory: string;
}

export interface SuggestedMenuItem extends ReorderMenuItem {
  productCategory?: string | null;
}

export interface ScannableMenuItem {
  menuId: number;
}

export interface Suggestions {
  suggestionsEnabled: boolean;
  suggestions: SuggestedMenuItem[];
}

export type PromotionInfo = {
  id: number;
  name: string;
  description: string;
  discount: number;
  threshold: number;
  items: PromotionInfoItems[];
};

export type PromotionInfoItems = {
  discount: number;
  uomId: number;
  menuIds: number[];
};

export type DraftOrderResponseItem = {
  cartMenuItemId: string;
  menuItemId: number;
  foodItemId: number;
  quantity: number;
  modifiers: ICartModifier[];
  uomId: number;
  modifiersDisplayText?: string;
};

export type DraftOrderRequest = {
  orderId: number | undefined;
  siteId: string;
  facilityId: string;
  menuId: number;
  pickupDate: string;
  pickupSpotId: number | null | undefined;
  pickupSpotName: string | null | undefined;
  timeSlotId: number;
  instructions: string | null | undefined;
  phoneNumber: string | undefined;
  menuPortionItems: DraftOrderPortionItem[] | undefined;
  trackingId: string | undefined;
  theme: Record<string, string>[];
  deliveryOptionId: number | undefined;
  deliveryOptionType: DeliveryOptionType | undefined;
  table: string | undefined;
  orderType: OrderType | undefined;
  email: string | undefined;
};

export type DraftOrderPortionItem = {
  cartmenuItemId: string;
  menuItemId: number;
  foodItemId: number;
  quantity: number;
  uomId: number;
  modifiers: ICartModifier[] | undefined;
  name: string;
  modifiersDisplayText: string | undefined;
};

export type DraftOrderResponse = {
  orderId: number;
  siteId: string;
  facilityId: string;
  orderTotal: number;
  contactId: string;
  contactPhoneNumber: string;
  menuId: number;
  timeSlotId: number;
  pickupSpot?: PickupSpotDraftOrderResponse;
  pickupDate: string;
  orderList: DraftOrderResponseItem[];
  instructions: string;
  trackingId: string;
  theme: string[];
  deliveryOptionId?: number;
  deliveryOptionType?: DeliveryOptionType;
};

export type PickupSpotDraftOrderResponse = {
  id: number;
  name: string;
};

export type RedeemedFoodItem = {
  loyaltySchemeId: number;
  uomId: number;
  foodItemId: number;
  quantity: number;
};

export type LoyaltyStampToBeEarned = {
  loyaltySchemeId: number;
  stampsCount: number;
};
