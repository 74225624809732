import { cleanup, fireEvent, screen } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { TAG_COLOR, TileProps, TILE_VARIANT } from '../../../../components/molecules/Tile';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultSite } from '../../../Sites/__mocks/mocks';
import { defaultCart, defaultFacilityMenu } from '../../__mocks/mock';
import { Cart } from '../../types/orderState.types';
import { ProductTileProps } from '../../types/productList.types';

import ProductTile from './ProductTile';
let tilePropsMock: TileProps;
jest.mock('../../../../components/molecules/Tile', () => {
  const actual = jest.requireActual('../../../../components/molecules/Tile');
  return {
    ...actual,
    Tile: (props: any) => {
      tilePropsMock = props;
      return <actual.Tile {...props} />;
    },
  };
});
jest.mock('../ProductCornerAction', () => () => <></>);
jest.mock('../../helpers//order.helper', () => ({
  ...jest.requireActual('../../helpers//order.helper'),
  getPrice: (price: any) => price.toString(),
}));

jest.mock('../../hooks/useProductPortion/useProductPortion', () => ({
  useProductPortion: () => ({
    productPortion: { isFavorite: false },
    addToFavorites: jest.fn(),
    removeFromFavorites: jest.fn(),
  }),
}));

const mockSelectorFunction = jest.fn();

jest.mock('react-redux', () => {
  const actual = jest.requireActual('react-redux');
  return {
    ...actual,
    useSelector: () => {
      return mockSelectorFunction();
    },
  };
});

const mockProvidingPortion = jest.fn();
const mockRedeemableAsReward = jest.fn();

jest.mock('../../hooks/useLoyaltyReward/useLoyaltyReward', () => ({
  useLoyaltyReward: () => ({
    portionsFreeItemsInfo: [],
    isLoading: false,
    isPortionProvidingStamp: mockProvidingPortion(),
    isPortionRedeemableAsReward: mockRedeemableAsReward(),
  }),
}));

describe('ProductTile component', () => {
  afterAll(() => cleanup());
  const menuItemIdInCart = 123;
  const foodItemId = 223;
  const uomId = 323;
  const languageCode = 'pl-PL';
  const cart: Cart = {
    ...defaultCart,
    menuPortionItems: [
      {
        ...defaultCart.menuPortionItems![0],
        menuItemId: menuItemIdInCart,
        foodItemId: foodItemId,
        uomId: uomId,
      },
    ],
  };
  const props: ProductTileProps = {
    id: '123',
    menuItemId: menuItemIdInCart,
    uomId: uomId,
    title: 'title',
    description: 'description',
    imageUrl: '',
    price: 10,
    isVegan: false,
    isVegetarian: false,
    showAllergensWarning: false,
    date: '2022-11-29',
    menu: defaultFacilityMenu,
    site: defaultSite,
    cart,
    languageCode,
    dark: true,
    variant: TILE_VARIANT.BIG,
    oneLine: true,
    genericCategory: 'Fruit',
  };

  describe('on render', () => {
    renderComponent(ProductTile, {
      ...props,
      showAllergensWarning: true,
      isVegan: true,
      isVegetarian: true,
    });
    it('should have basic props', () => {
      expect(tilePropsMock.id).toBe(props.id);
      expect(tilePropsMock.title).toBe(props.title);
      expect(tilePropsMock.description).toBe(props.description);
      expect(tilePropsMock.dark).toBe(true);
      expect(tilePropsMock.variant).toBe(TILE_VARIANT.BIG);
      expect(tilePropsMock.oneLineTile).toBe(true);
      expect(tilePropsMock.oneLineDescription).toBe(true);
    });
  });
  describe('menu item contains selected alergens', () => {
    renderComponent(ProductTile, {
      ...props,
      showAllergensWarning: true,
      isVegan: true,
      isVegetarian: true,
    });
    it('should have allergens chip', () => {
      expect(tilePropsMock.chips).toHaveLength(2);
      expect(tilePropsMock!.chips![0].name).toContain('Allergens');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.DANGER);
    });
  });
  describe('menu item is vegan', () => {
    beforeEach(() => {
      mockProvidingPortion.mockReturnValue(false);
      mockRedeemableAsReward.mockReturnValue(false);
    });
    renderComponent(ProductTile, { ...props, isVegan: true, isVegetarian: true });
    it('should have vegan chip', () => {
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('Vegan');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SUCCESS);
    });
  });
  describe('menu item is vegetarian', () => {
    beforeEach(() => {
      mockProvidingPortion.mockReturnValue(false);
      mockRedeemableAsReward.mockReturnValue(false);
    });
    renderComponent(ProductTile, { ...props, isVegan: false, isVegetarian: true });
    it('should have vegetarian chip', () => {
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('Vegetarian');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SUCCESS);
    });
  });
  describe('menu item is loyalty reward', () => {
    beforeEach(() => {
      mockProvidingPortion.mockReturnValue(false);
      mockRedeemableAsReward.mockReturnValue(true);
    });
    renderComponent(ProductTile, { ...props });
    it('should have loyalty reward chip', () => {
      mockProvidingPortion.mockReturnValue(false);
      mockRedeemableAsReward.mockReturnValue(true);
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('Loyalty Reward');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SECONDARY);
    });
  });
  describe('menu item is providing stamps', () => {
    beforeEach(() => {
      mockProvidingPortion.mockReturnValue(true);
      mockRedeemableAsReward.mockReturnValue(false);
    });
    renderComponent(ProductTile, { ...props, isProvidingLoyaltyStamps: true });
    it('should have providing stamps chip', () => {
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('1 Purchase = 1 Stamp');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SECONDARY);
    });
  });
  describe('has promotion for menu item', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue([
        {
          uomId: uomId,
          foodItemId: foodItemId,
          price: 8,
          discount: 2,
        },
      ]);
    });

    renderComponent(ProductTile, {
      ...props,
    });
    it('should have price without discount and price with discount', () => {
      expect(tilePropsMock!.childText).toContain('8');
      expect(tilePropsMock!.strikethroughChildText).toContain('10');
      const discountPrice = screen.getByText('8');
      expect(discountPrice).toBeTruthy();
      const originalPrice = screen.getByText('10');
      expect(originalPrice).toBeTruthy();
    });
  });
  describe('promotion not shown for non-orderable menu item', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue([
        {
          uomId: uomId,
          foodItemId: foodItemId,
          price: 8,
          discount: 2,
        },
      ]);
    });

    renderComponent(ProductTile, {
      ...props,
      menu: {
        ...defaultFacilityMenu,
        isOrderable: false,
      },
    });
    it('should have price without discount only', () => {
      expect(tilePropsMock!.childText).toContain('10');
      expect(tilePropsMock!.strikethroughChildText).toBe(undefined);
    });
  });
  describe('has no promotion for menu item', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue([]);
    });
    renderComponent(ProductTile, {
      ...props,
    });
    it('should have price with discount undefined', () => {
      expect(tilePropsMock!.childText).toContain('10');
      expect(tilePropsMock!.strikethroughChildText).toBeUndefined();
      const price = screen.getByText('10');
      expect(price).toBeTruthy();
    });
  });
  describe('with viewable menu and many prices', () => {
    renderComponent(ProductTile, {
      ...props,
      menu: {
        ...defaultFacilityMenu,
        isOrderable: false,
      },
      viewablePrices: [
        { name: 'Price1', value: 22 },
        { name: 'Price2', value: 5 },
      ],
    });
    it('should show lower price with From keyword', () => {
      expect(tilePropsMock!.childText).toContain('From 5');
      expect(tilePropsMock!.strikethroughChildText).toBe(undefined);
      const price = screen.getByText('From 5');
      expect(price).toBeTruthy();
    });
  });
  describe('with viewable menu and one price', () => {
    renderComponent(ProductTile, {
      ...props,
      menu: {
        ...defaultFacilityMenu,
        isOrderable: false,
      },
      viewablePrices: [{ name: 'Price1', value: 22 }],
    });
    it('should show one price without From keyword', () => {
      expect(tilePropsMock!.childText).toContain('22');
      expect(tilePropsMock!.strikethroughChildText).toBe(undefined);
      const price = screen.getByText('22');
      expect(price).toBeTruthy();
    });
  });
  describe('onClick redirect url', () => {
    const history = createMemoryHistory();
    renderComponent(
      ProductTile,
      {
        ...props,
      },
      undefined,
      history
    );
    it('should have correct url', () => {
      fireEvent.click(screen.getByTestId(`ProductTile-${props.id}`));
      expect(history.location.pathname).toContain(`${props.date}/${props.menuItemId}/no-cart`);
    });
  });
});
