const labels = {
  All: {
    'Ref: Assembled': '组装',
    'Ref: Submitted': '提交',
    'Ref: Fulfilled': '实现',
    'Ref: Completed': '完全的',
    'Ref: Paid': '付费的',
    'Ref: Approved': '已确认',
    'Ref: Ready For Pickup': '已准备好取货',
    'Ref: Delivered': '已送达',
    'Ref: Received': '已收到',
    'Ref: Rejected': '已拒绝',
    'Ref: Payment Error': '付款错误',
    'Ref: Pending Payment': '待付款',
    'Ref: Preparing': '准备中',
    'Ref: Refunded': '退款',
    'Ref: Cancelled By User': '由用户取消',
    'Ref: Refund Requested': '要求退款',
    'Ref: Refund Failed': '退款失败',
    'Ref: Payment Processing': '付款处理中',
    'Ref: Not Paid': '未支付',
    'Ref: Total:': '全部：',
    'Ref: Pickup': '打包',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '扫描和去',
    'Ref: Fulfillment Type': '履行类型',
    'Ref: Delivery': '送货',
    'Ref: You have no future orders': '您没有未来的订单',
    'Ref: You have no past orders': '您没有历史订单',
    'Ref: You have no orders': '您还没有订单',
    'Ref: All orders': '所有订单',
    'Ref: Not past orders': '即将到来的订单',
    'Ref: Past orders': '历史订单',
    'Ref: Vegan': '素食主义者',
    'Ref: Vegeterian': '素食者',
    'Ref: See item': '请参阅项目',
    'Ref: Activated': '已激活',
    'Ref: No suggestions': '找不到建议',
    'Ref: Get your receipt': '获取收据',
    'Ref: Print': '打印收据',
    'Ref: Email': '通过电子邮件收到收据',
    'Ref: Receipt Modal message': '您想如何获得收据？',
    'Ref: Confirm': '确认',
    'Ref: Your email address': '您的电子邮件地址',
    Suggestion: '建议',
    'Ref: Loyalty Reward': '忠诚度奖励',
    'Ref: Equation for stamps per item': '1购买= 1邮票',
    'Ref: Paid by employer': '您的雇主支付的金额',
    'Ref: Paid by user': '您支付的金额',
  },
  ProductsList: {
    'Ref: Page title': '商品列表',
    'Ref: Facility details': '设施的详细信息',
    'Ref: Search products list': '搜索项目',
    'Ref: filter label: menus': '菜单',
    'Ref: filter label: moment': '片刻',
    'Ref: filter label: category': '类别',
    'Ref: all': '所有',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: see more': '查看详情',
    'Ref: see less': '隐藏详情',
    'Ref: reset': '重置',
    'Ref: View in cart': '在购物车中查看',
    'Ref: You have changed location': '您已经更改了位置',
    'Ref: You are now at': '你现在在',
    'Ref: Menu card': '菜单卡',
    'Ref: Table': '表号',
    'Service unavailable': '暂停服务',
    'Ref: Ordering unavailable': '小心！目前在此站点上订购是不可用的，请稍后再试。',
    'Ref: Filters': '过滤器（过敏原等）',
  },
  ProductScanner: {
    'Ref: cart': '大车',
    'Ref: Product Scanned': '被添加到购物车中。您可以继续扫描条形码以将其添加到购物车中。',
    'Ref: Scan or adjust': '被添加到购物车中。调整数量或扫描其他商品。',
    'Ref: Product Not Matched': '条形码未被认可。请再试一次',
    'Ref: Scan article code': '扫描条形码',
    'Ref: Native error product cannot be added to cart':
      '来自不同菜单或白天部分的项目无法添加到购物车中',
    'Ref: product scanner placeholder': '请扫描条形码以将其添加到您的购物车中',
  },
  Order: { 'Ref: Page title': '命令' },
  MenuSelector: {
    'Ref: Menu Page title': '菜单',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: Search products list': '搜索项目',
    'Ref: You have changed location': '位置已更改',
    'Ref: You are now at': '你现在位于',
  },
  ProductDetails: {
    'Ref: Page title': '产品详情',
    'Ref: Serving Size': '分量',
    'Ref: Nutrition': '营养信息',
    'Ref: Nutrition adults': '成年人每天需要大约2000千卡',
    'Ref: Nutrition Calories': '卡路里',
    'Ref: Nutrition Calcium': '钙',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '饱和脂肪',
    'Ref: Nutrition Carbohydrates': '碳水化合物',
    'Ref: Nutrition Sugar': '糖',
    'Ref: Nutrition Protein': '蛋白',
    'Ref: Nutrition Sodium': '钠',
    'Ref: Nutrition cholesterol': '胆固醇',
    'Ref: Nutrition dietaryFiber': '膳食纤维',
    'Ref: Nutrition iron': '铁',
    'Ref: Nutrition potassium': '钾',
    'Ref: Nutrition vitaminA': '维生素A',
    'Ref: Nutrition vitaminC': '维生素C',
    'Ref: Additives': '添加剂',
    'Ref: Emission': '二氧化碳排放量',
    'Ref: This product generates': '该产品产生',
    'Ref: of CO2': '二氧化碳',
    'Ref: emissions, which equals a': '排放，等于a',
    'Ref: car trip': '汽车旅行',
    'Ref: Ingredients': '食谱成分',
    'Ref: Allergens': '过敏原',
    'Ref: Always there': '总是在那里',
    'Ref: Allergens selected': '在过滤器上选择过敏原：',
    'Ref: minimum required': '最低要求',
    'Ref: you have to add at least': '您必须至少添加',
    'Ref: ingredients': '配料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '自由',
    'Ref: Based on customization': '根据您的自定义',
    'Ref: Additives eggs': '蛋',
    'Ref: Additives fish': '鱼',
    'Ref: Additives milk': '牛奶',
    'Ref: Additives nuts': '坚果',
    'Ref: Additives wheat': '小麦',
    'Ref: Additives peanuts': '花生',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': '芝麻',
    'Ref: Review': '审查',
    'Ref: Per portion': '每部分',
    'Ref: Per 100g': '每100克',
    'Ref: Allergen selected': '在搜索过滤器上选择的过敏原',
    'Ref: Fitbit Log': '登录到Fitbit',
    'Ref: age verification required on delivery': '交货需要的年龄验证',
    'Ref: alcohol content notification':
      '通过为其中一个项目订购并订购，您声明您年龄在18岁或以上。酗酒对您的健康是危险的。',
    'Ref: default': '默认',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}如果您订购此产品',
    'Ref: Loyalty Card Description':
      '{stamp_form}将添加到以下忠诚度{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': '忠诚邮票程序使您可以收集邮票以获得奖励。',
    'Ref: Loyalty Modal Description':
      '当您购买<b> {product_name}时，将添加1张邮票</b>将添加到<b> {scheme_names} </b> {program_form}',
    'Ref: and': '和',
    'Ref: Stamp': '邮票',
    'Ref: Stamps': '邮票',
    'Ref: Program': '程序',
    'Ref: Programs': '程式',
    'Ref: Loyalty Stamps': '忠诚邮票',
    'Ref: See Loyalty Stamps': '查看忠诚邮票',
    Favorite: '最喜欢的',
    'Ref: In favorites': '该产品已经在收藏夹列表中。单击以删除它。',
    'Ref: Not in favorites': '该产品尚未在收藏夹列表中。单击以添加。',
    'Ref: Added to favorites': '该产品已添加到收藏夹列表中。',
    'Ref: Removed from favorites': '该产品已从收藏夹列表中删除。',
    'Ref: From': '从',
  },
  MenuCartActions: {
    'Ref: change in cart': '更改购物车',
    'Ref: Popup header for customizable item qty update': '此项目是可自定义的',
    'Ref: add new version': '添加新版本',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      '直接从购物车直接更新可自定义物品的数量。',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      '直接从购物车直接更新可自定义项目的数量，或添加新版本。',
    'Ref: another context body': '要从该站点、设施、时刻/菜单或日期添加项目，您需要清除购物车',
    'Ref: cancel': '取消',
    'Ref: add to cart': '添加到购物车',
    'Ref: view in cart': '购物车',
    'Ref: Products in cart': '{product_name}在购物车中的数量：{product_quantity}',
    'Ref: Customize': '定制',
    'Ref: Add': '添加',
    'Ref: Free': '自由的',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': '取消',
    'Ref: notice': '注意',
    'Ref: clear cart': '清除购物车',
    'Ref: Cart addition error message':
      '当前，您从{from_current}中的购物车中有项目。要添加{from_selected}的项目，您需要清除购物车',
  },
  Cart: {
    'Ref: Page title': '购物车',
    'Ref: The cart is empty': '购物车为空',
    'Ref: Clear cart': '清除购物车',
    'Ref: Continue shopping': '继续购物',
    'Ref: Start shopping': '开始购物',
    'Ref: Today': '今天',
    'Ref: Tomorrow': '明天',
    'Ref: Tax:': '税：',
    'Ref: Promotion:': '促销：',
    'Ref: Total without promotion:': '无促销总计：',
    'Ref: Delivery:': '交货：',
    'Ref: Pickup informations': '打包信息',
    'Ref: Location': '地点',
    'Ref: Time': '时间',
    'Ref: Phone': '你的电话号码',
    'Ref: Instructions': '指示',
    'Ref: Please select': '请选择',
    'Ref: Enter special requests': '在此处输入特殊请求',
    'Ref: notice': '注意',
    'Ref: Product is no more available': '抱歉，似乎某些商品不再可选。请检查您的购物车。',
    'Ref: ok': '确认',
    'Ref: Validate': '验证我的订单',
    'Ref: Pay now': '现在付款',
    'Ref: Charge to my department': '向我的部门收费',
    'Ref: Pay on pickup': '收拾东西付这些物品',
    'Ref: Accept terms of sale prefix': '我接受',
    'Ref: Accept terms of sale': '这些销售条款',
    'Ref: Continue': '继续',
    'Ref: Please login or sign up to place an order': '请登录或注册以继续',
    'Ref: Accept terms of sale suffix': '，我已经阅读并理解了它们。',
    'Ref: Payment modality': '付款方式',
    'Ref: Other information': '其他信息',
    'Ref: Eat-in': '堂食',
    'Ref: Pickup': '外带',
    'Ref: Table': '桌号',
    'Ref: Required fields': '必需的字段',
    'Service unavailable': '服务不可用',
    'Ref: Cannot order':
      '抱歉，您目前无法在此地点下单。请联系餐厅，以获取有关开放时间和可用服务的更多信息。',
    'Ref: Order Unavailable': '订购所选日期，履行类型和位置不再可用！',
    'Ref: No Availability': '没有更多的可用性',
    'Ref: Continue on your device': '继续使用您的设备',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}如果您完成订单',
    'Ref: Loyalty Card Description':
      '{stamp_form}将添加到以下忠诚度{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': '忠诚邮票程序使您可以收集邮票以获得奖励。',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b>如果您完成订单，则获得了：',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b>为<b> {scheme_names} </b>添加',
    'Ref: Stamp': '邮票',
    'Ref: Stamps': '邮票',
    'Ref: Program': '程序',
    'Ref: Programs': '程式',
    'Ref: Loyalty Stamps': '忠诚邮票',
    'Ref: See Loyalty Stamps': '查看忠诚邮票',
    'Ref: Loyalty Info': '忠诚度信息',
    'Ref: Email address': '电子邮件地址',
    'Ref: and': '和',
    'Ref: Order minimum amount': '抱歉，最低付款金额为{minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': '继续使用您的设备',
    'Ref: Scan QR to continue': '请扫描QR码以继续在设备上的旅程。',
  },
  OrderHistory: { 'Ref: Orders': '命令', 'Ref: Page Title': '我的订单' },
  OrderHistoryDetails: {
    'Ref: Unit price': '单价',
    'Ref: Download': '下载收据',
    'Ref: Download error': '下载错误发生了。稍后尝试下载收据',
    'Ref: Order details title': '订单详细信息',
  },
  SuccessOrder: {
    'Ref: Order confirmed': '谢谢！您的订单现在已确认',
    'Ref: Text for pickup':
      '您可以通过订单历史记录遵循其状态。如果您的通知已打开，我们将在准备拾取时更新您。',
    'Ref: Success Order Title': '谢谢您的订单',
    'Ref: Success Order Description': '请在结帐时付款以验证您的订单。',
    'Ref: Success Order ID': 'ID订单',
    'Ref: Success Order Action': '新命令',
    'Ref: Text for dinein':
      '您点的菜很快就会送达您的餐桌。 只需向我们友好的工作人员询问您的就餐订单即可。',
    'Ref: Text for scan&go': '您的订单可在您的订单历史记录中可用',
    'Ref: Text for delivery':
      '您可以在订单历史记录中跟踪其状态。如果启用了您的通知，我们将在订单准备就绪时通知您。',
    'See details': '查看详情',
    'Go to home': '返回主页',
  },
  FailOrder: {
    'Go to home': '返回主页',
    'Ref: Kiosk Fail Title': '哎呀！出了些问题！',
    'Ref: Kiosk Fail Description': '不幸的是，您的订单没有成功。请重试或要求工作人员为您提供帮助。',
    'Ref: Kiosk Fail Back': '返回上一页',
    'Ref: Kiosk Fail Action': '清除会话并再次订购',
    'Ref: Error in cart validation': '下单时发生错误。请联系该机构以解决问题。',
    'Facility contact': '机构联系方式',
    'Ref: Ordering failed': '订购失败',
    'Ref: Order confirmed': '订单已确认',
  },
  TableCheckIn: {
    'Welcome at': '欢迎来',
    Table: '桌子：',
    'Ref: list title': '今天的最新订单',
    'See the menu': '查看菜单',
    'Table Check-in': '餐桌登记',
    'Ref #': '参考＃',
    Checked: '检查',
    'Check in': '签到',
    'See details': '阅读详情',
    'Ref: You have changed location': '您的位置已更改',
    'Ref: You are now at': '您现在位于',
    'Order number': '订单号',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': '应用促销',
    'Ref: Promotion applied description': '您使购物车的促销活动受益。',
    'Ref: Promotion deleted title': '促销已删除',
    'Ref: Promotion deleted description':
      '由于您不再满足条件或促销活动已过期，您不再享受促销活动。',
  },
  MenuTile: { 'Ref: Scan&Go': '扫描和去', 'Ref: View only': '只读' },
  ProductTile: {
    'Ref: Allergens': '过敏原',
    'Ref: Presence of allergen': '过敏原的存在',
    'Ref: Scan': '扫描',
    'Ref: From': '从',
    'Ref: Already in product favorite list. Click to remove it.':
      '已经在产品最喜欢的列表中了。单击以删除它。',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '目前尚未添加到产品喜欢的列表中。单击以添加。',
    'Ref: Free': '自由的',
  },
  OrderSummary: {
    'Ref: item': '物品',
    'Ref: items': '项目',
    'Ref: Clear': '清除',
    'Ref: Cart': '大车',
    'Ref: Subtotal': '小计',
    'Ref: Total': '全部的',
    'Ref: Tax': '税',
    'Ref: Promotion': '促销',
    'Ref: Total without promotion': '无促销总计',
    'Ref: Number of product in cart': '购物车中的商品数量',
    'Ref: Delivery': '送货',
  },
  CartItemTile: { 'Ref: Vegan': '素食主义者', 'Ref: Vegeterian': '素食主义者' },
  OrderInfoCard: {
    'Ref: Header': '订购',
    'Ref: from': '从',
    'Ref: location': '地点',
    'Ref: Phone': '你的电话号码',
    'Ref: Instructions': '指示',
    'Ref: Order type': '订单类型',
    'Ref: Pickup': '打包',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '扫描和去',
  },
  ReorderWidget: { 'Ref: No items': '尚未下达命令。现在去购物！', 'Ref: Title': '再次订购' },
  MenusWidget: { 'Ref: no menu': '目前没有菜单', 'Ref: Modal title': '一天的菜单' },
  PromotionInfo: { 'Ref: Discounts available': '可用的折扣' },
  PromotionInfoItem: { 'Ref: Activate': '激活' },
  OrderHistoryWidget: { 'Ref: Your Orders': '您的订单', 'Ref: Widget Title': '我的订单' },
  SuggestionsWidget: {
    'Ref: Modal title': '一天的建议',
    'Ref: Any menu': '任何菜单',
    Other: '其他',
    'Ref: Suggestions calendar button': '建议日历',
  },
  ModifierCard: {
    'Ref: Serving Size': '分量',
    'Ref: Nutrition': '营养信息',
    'Ref: Nutrition adults': '成年人每天需要大约2000千卡',
    'Ref: Nutrition Calories': '卡路里',
    'Ref: Nutrition Calcium': '钙',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '饱和脂肪',
    'Ref: Nutrition Carbohydrates': '碳水化合物',
    'Ref: Nutrition Sugar': '糖',
    'Ref: Nutrition Protein': '蛋白',
    'Ref: Nutrition Sodium': '钠',
    'Ref: Nutrition cholesterol': '胆固醇',
    'Ref: Nutrition dietaryFiber': '膳食纤维',
    'Ref: Nutrition iron': '铁',
    'Ref: Nutrition potassium': '钾',
    'Ref: Nutrition vitaminA': '维生素A',
    'Ref: Nutrition vitaminC': '维生素C',
    'Ref: Ingredients': '食谱成分',
    'Ref: Allergens': '过敏原',
    'Ref: Always there': '总是在那里',
    'Ref: Allergens selected': '在过滤器上选择过敏原：',
    'Ref: minimum required': '最低要求',
    'Ref: ingredients': '配料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '自由',
    'Ref: Based on customization': '根据您的自定义',
    'Ref: Additives eggs': '蛋',
    'Ref: Additives fish': '鱼',
    'Ref: Additives milk': '牛奶',
    'Ref: Additives nuts': '坚果',
    'Ref: Additives wheat': '小麦',
    'Ref: Additives peanuts': '花生',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': '芝麻',
    'Ref: Review': '审查',
    'Ref: Per portion': '每部分',
    'Ref: Per 100g': '每100克',
    'Ref: Modifier item number of free items': '•{number_of_free_items}免费',
    'Ref: Number of free items': '免费项目数： {number_of_free_items}',
    'Ref: Allergen selected': '在搜索过滤器上选择的过敏原',
    'Ref: Nutritional info': '营养信息',
    'Ref: Info': '信息',
  },
  PromotionsMiniWidget: { 'Ref: Title': '促销活动' },
  DiscountTiersModal: {
    'Ref: Choose your job': '选择你的工作',
    'Ref: Validate': '确认',
    'Ref: Pricing Tiers dialog message': '您已通过电子邮件地址认可',
    'Ref: Please select your job in the company': '请选择您在公司的岗位',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '打包',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '扫描和去',
    'Ref: Delivery': '送货',
    'Ref: Total': '全部的',
    'Ref: How was facility name service': '{facility_name}的服务如何？',
    'Ref: How do you rate the service': '您如何评价服务？',
  },
};
export default labels;
