import biteApi from '../../../../services/api/biteApi';
import mockResponse from '../../mockResponse';

import { FiltersModel, SaveFilterPreferences } from './accountApi.types';

const accountPreferencesApi = biteApi
  .enhanceEndpoints({ addTagTypes: ['filterPreferences'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getFilterPreferences: build.query<
        FiltersModel,
        { siteId: string; useErrorBoundary?: boolean }
      >({
        query: ({ siteId, useErrorBoundary }) => ({
          url: '/v1/user/filterPreferences',
          method: 'get',
          params: { siteId: siteId },
          mockData: mockResponse.getFilterPreferences,
          opts: { useErrorBoundary: useErrorBoundary },
        }),
        providesTags: ['filterPreferences'],
      }),
      saveFilterPreferences: build.mutation<void, SaveFilterPreferences>({
        query: (postArgs) => ({
          url: '/v1/user/filterPreferences',
          method: 'post',
          data: postArgs.filters,
          params: { siteId: postArgs.siteId },
          mockData: mockResponse.saveFilterPreferences,
        }),
        invalidatesTags: ['filterPreferences'],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetFilterPreferencesQuery,
  useSaveFilterPreferencesMutation,
  useLazyGetFilterPreferencesQuery,
} = accountPreferencesApi;
export default accountPreferencesApi;
