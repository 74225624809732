const labels = {
  All: { 'Ref: Page title': 'Tiekėjai' },
  SuppliersList: {
    'Ref: Supplier details': 'Žr. Išsamią informaciją',
    'Missing supplier list': 'Mes neradome jokio tiekėjo.',
    'Try again later': 'Pabandyti dar kartą vėliau',
  },
  SupplierDetails: {
    'Address label': 'Adresas:',
    'Website label': 'Interneto svetainė:',
    'Details label': 'detalės',
  },
};
export default labels;
