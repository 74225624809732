import { cleanup, screen } from '@testing-library/react';

import LoyaltyInfoButton from './LoyaltyInfoButton';

import renderComponent from '@/helpers/tests/renderComponent';

describe('CartItemTile component', () => {
  afterAll(() => cleanup());

  describe('on render', () => {
    renderComponent(LoyaltyInfoButton, {
      onClick: () => jest.fn(),
      title: 'Title',
      inheritStyle: 'ingeritStyle',
    });

    it('should render component', () => {
      const component = screen.getByTestId('loyalty-info-button');
      const children = screen.getByTestId('loyalty-info-button-children');
      expect(children).toHaveTextContent('Title');
      expect(component).toBeTruthy();
    });
  });
});
