import { TestingPropsV2 } from 'src/types';

export enum FacilityType {
  Undefined,
  FoodRetail,
  FoodNonRetail,
  NonFoodNonRetail,
  NonFoodRetail,
}

export const FACILITY_TYPE = {
  'Food - Non-retail': FacilityType.FoodNonRetail,
  'Food - Retail': FacilityType.FoodRetail,
  'Non-food - Non-retail': FacilityType.NonFoodNonRetail,
  'Non-food - Retail': FacilityType.NonFoodRetail,
};

export interface IReviewFacility {
  id: string;
  title: string;
  images: string[];
  facilityType: {
    name: keyof typeof FACILITY_TYPE;
  };
}

export type FacilityImage = { key: string; image: string };

export type FacilityListItem = {
  id: string;
  title: string;
  imgElement: JSX.Element;
  imgInfo?: FacilityListItemImgInfo;
  highlightedActions: FacilityListItemAction[];
} & TestingPropsV2;

export type FacilityListItemImgInfo = {
  src?: string;
  alt: string;
};

export type FacilityListItemAction = {
  element: JSX.Element;
  action: () => void | (() => void);
};
