const labels = {
  All: {
    'Ref: Redeem coffee description': 'Ref: Redeem coffee description',
    'Ref: Congratulations': 'Ref: Congratulations',
    'Ref: Get free coffee': 'Ref: Get free coffee',
    'Ref: Free coffee description': 'Ref: Free coffee description',
    'Ref: Redeem': 'Ref: Redeem',
  },
  StampPage: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: seconds left': 'Ref: seconds left',
    'Ref: How it works': 'Ref: How it works',
    'Ref: How it works description': 'Ref: How it works description',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Ref: Scan QR Code',
    'Ref: Collect reward': 'Ref: Collect reward',
    'Ref: Reward collected': 'Ref: Reward collected',
    'Ref: Invalid QR Code': 'Ref: Invalid QR Code',
    'Ref: Invalid QR Code description': 'Ref: Invalid QR Code description',
    'Ref: Close': 'Ref: Close',
    'Ref: Redeem button': 'Ref: Redeem button',
    "Ref: The QR Code isn't recognized": "Ref: The QR Code isn't recognized",
    'Ref: Cancel': 'Ref: Cancel',
    'Ref: Bite': 'Ref: Bite',
    'Ref: Congratulations modal title': 'Ref: Congratulations modal title',
    'Ref: Confirm redeem description': 'Ref: Confirm redeem description',
    'Ref: Congratulations modal description': 'Ref: Congratulations modal description',
  },
  Widget: {
    'Ref: Free Coffee widget title': 'Ref: Free Coffee widget title',
    'Ref: See All': 'Ref: See All',
  },
};
export default labels;
