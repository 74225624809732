const mockData = {
  getSurveyReviewQuestions: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              surveyId: 6,
              surveyTypeId: 'FoodItem',
              questions: [
                {
                  surveyQuestionId: 43,
                  questionText: 'Please rate your experience:',
                  sortingOrder: 1,
                  questionType: 'Emoji',
                },
                {
                  surveyQuestionId: 16,
                  questionText: 'Did you enjoy this dish?',
                  sortingOrder: 2,
                  questionType: 'CountableSlider',
                },
                {
                  surveyQuestionId: 17,
                  questionText: 'Was it a good value?',
                  sortingOrder: 3,
                  questionType: 'CountableSlider',
                },
                {
                  surveyQuestionId: 18,
                  questionText: 'Would you eat it again?',
                  sortingOrder: 4,
                  questionType: 'CountableSlider',
                },
                {
                  surveyQuestionId: 20,
                  questionText: 'How likely are you to recommend?',
                  sortingOrder: 5,
                  questionType: 'CountableSlider',
                },
                {
                  surveyQuestionId: 21,
                  questionText: 'Tell us about your experience',
                  sortingOrder: 6,
                  questionType: 'FullText',
                },
              ],
            }),
          1000
        )
      );
    },
  },
  submitSurvey: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 1000));
    },
  },
  getMappedFacilities: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve(['facility1', 'facility2']), 1000));
    },
  },
};

export default mockData;
