const labels = {
  All: {
    'Ref: Redeem coffee description': 'กาแฟฟรีที่ชงสดของคุณกำลังรออยู่',
    'Ref: Congratulations': 'ยินดีด้วย',
    'Ref: Get free coffee': 'รับกาแฟฟรี!',
    'Ref: Free coffee description': 'เติมถ้วยของคุณรับคะแนนต่อกาแฟฟรี',
    'Ref: Redeem': 'ไถ่ถอน!',
  },
  StampPage: {
    'Ref: Page title': 'กาแฟฟรี',
    'Ref: seconds left': 'เหลือวินาที',
    'Ref: How it works': 'มันทำงานอย่างไร',
    'Ref: How it works description':
      'ทุกครั้งที่คุณซื้อกาแฟกดปุ่มสแกนและสแกนรหัส QR ที่ให้ไว้ที่เครื่องบันทึกเงินสด',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'สแกนรหัส QR',
    'Ref: Collect reward': 'รวบรวมรางวัล',
    'Ref: Reward collected': 'รางวัลที่เก็บรวบรวม',
    'Ref: Invalid QR Code': 'รหัส QR ที่ไม่ถูกต้อง',
    'Ref: Invalid QR Code description': 'ไม่รู้จักรหัส QR',
    'Ref: Close': 'ปิด I',
    'Ref: Redeem button': 'ไถ่ถอน',
    "Ref: The QR Code isn't recognized": 'ไม่รู้จักรหัส QR',
    'Ref: Cancel': 'ยกเลิก',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'ยินดีด้วย!',
    'Ref: Confirm redeem description':
      'แน่ใจว่าคุณต้องการใช้สิ่งนี้หรือไม่? ถ้าเป็นเช่นนั้นคุณมีเวลาเพียง 60 วินาทีในการแสดงโทรศัพท์ของคุณที่เครื่องบันทึกเงินสดและทำการซื้อให้เสร็จสิ้น',
    'Ref: Congratulations modal description': 'คว้ากาแฟฟรีของคุณตอนนี้!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'กาแฟฟรี!', 'Ref: See All': 'ดูทั้งหมด' },
};
export default labels;
