const labels = {
  All: {
    'Ref: Redeem coffee description': 'Ваша бесплатная свежевываренная чашка кофе ждет',
    'Ref: Congratulations': 'Поздравляю',
    'Ref: Get free coffee': 'Получите бесплатный кофе!',
    'Ref: Free coffee description':
      'Заполните свою чашку, заработайте очки в сторону бесплатного кофе.',
    'Ref: Redeem': 'Выкупать!',
  },
  StampPage: {
    'Ref: Page title': 'Бесплатный кофе',
    'Ref: seconds left': 'секунды остались',
    'Ref: How it works': 'Как это устроено',
    'Ref: How it works description':
      'Каждый раз, когда вы покупаете кофе, нажимайте кнопку сканирования и сканируйте предоставленный QR -код в кассовом аппарате.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Сканировать QR -код',
    'Ref: Collect reward': 'Собрать вознаграждение',
    'Ref: Reward collected': 'Награда собрана',
    'Ref: Invalid QR Code': 'Неверный QR -код',
    'Ref: Invalid QR Code description': 'QR -код не распознается',
    'Ref: Close': 'Закрывать',
    'Ref: Redeem button': 'Выкупать',
    "Ref: The QR Code isn't recognized": 'QR -код не распознается',
    'Ref: Cancel': 'Отмена',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Поздравляю!',
    'Ref: Confirm redeem description':
      'Конечно, вы хотите использовать это? Если это так, у вас есть всего 60 секунд, чтобы показать свой телефон на кассовом реестре и заполнить покупку',
    'Ref: Congratulations modal description': 'Возьмите свой бесплатный кофе прямо сейчас!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Бесплатный кофе!', 'Ref: See All': 'Увидеть все' },
};
export default labels;
