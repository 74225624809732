const labels = {
  All: {
    'Ref: Assembled': 'מורכב',
    'Ref: Submitted': 'הוגש',
    'Ref: Fulfilled': 'ממלא',
    'Ref: Completed': 'הושלם',
    'Ref: Paid': 'שולם',
    'Ref: Approved': 'אושר',
    'Ref: Ready For Pickup': 'מוכן לאיסוף',
    'Ref: Delivered': 'נמסר',
    'Ref: Received': 'קיבלו',
    'Ref: Rejected': 'נִדחֶה',
    'Ref: Payment Error': 'שגיאת תשלום',
    'Ref: Pending Payment': 'תשלום ממתין',
    'Ref: Preparing': 'מכין',
    'Ref: Refunded': 'הוחזר',
    'Ref: Cancelled By User': 'בוטל על ידי המשתמש',
    'Ref: Refund Requested': 'התבקש החזר כספי',
    'Ref: Refund Failed': 'החזר כספי נכשל',
    'Ref: Payment Processing': 'עיבוד תשלום',
    'Ref: Not Paid': 'לא בתשלום',
    'Ref: Total:': 'סך הכל:',
    'Ref: Pickup': 'לאסוף',
    'Ref: Eat-in': 'לאכול',
    'Ref: Scan&Go': 'סרוק & Go',
    'Ref: Fulfillment Type': 'סוג הגשמה',
    'Ref: Delivery': 'מְסִירָה',
    'Ref: You have no future orders': 'אין לך הזמנות עתידיות',
    'Ref: You have no past orders': 'אין לך הזמנות עבר',
    'Ref: You have no orders': 'אין לך הזמנות',
    'Ref: All orders': 'כל ההזמנות',
    'Ref: Not past orders': 'הזמנות מגיעות',
    'Ref: Past orders': 'הוראות עבר',
    'Ref: Vegan': 'טִבעוֹנִי',
    'Ref: Vegeterian': 'צִמחוֹנִי',
    'Ref: See item': 'ראה פריט',
    'Ref: Activated': 'מוּפעָל',
    'Ref: No suggestions': 'לא נמצאו הצעות',
    'Ref: Get your receipt': 'קבל את הקבלה שלך',
    'Ref: Print': 'הדפיס את הקבלה שלך',
    'Ref: Email': 'קבל את הקבלה שלך באמצעות דואר אלקטרוני',
    'Ref: Receipt Modal message': 'איך תרצה לקבל את הקבלה שלך?',
    'Ref: Confirm': 'לְאַשֵׁר',
    'Ref: Your email address': 'כתובת הדוא"ל שלך',
    Suggestion: 'הַצָעָה',
    'Ref: Loyalty Reward': 'תגמול נאמנות',
    'Ref: Equation for stamps per item': '1 רכישה = חותמת 1',
    'Ref: Paid by employer': 'הסכום ששילם המעסיק שלך',
    'Ref: Paid by user': 'הסכום ששולם על ידך',
  },
  ProductsList: {
    'Ref: Page title': 'רשימת מוצרים',
    'Ref: Facility details': 'פרטי מתקן',
    'Ref: Search products list': 'חיפוש פריטים.',
    'Ref: filter label: menus': 'תַפרִיט',
    'Ref: filter label: moment': 'רֶגַע',
    'Ref: filter label: category': 'קטגוריה',
    'Ref: all': 'את כל',
    'Ref: today': 'היום',
    'Ref: tomorrow': 'מָחָר',
    'Ref: see more': 'ראה עוד',
    'Ref: see less': 'ראה פחות',
    'Ref: reset': 'אִתחוּל',
    'Ref: View in cart': 'נוף בעגלה',
    'Ref: You have changed location': 'שינית את המיקום',
    'Ref: You are now at': 'אתה עכשיו ב',
    'Ref: Menu card': 'תפריט',
    'Ref: Table': 'מספר טבלה',
    'Service unavailable': 'שירותים לא זמינים',
    'Ref: Ordering unavailable': 'זָהִיר! ההזמנה באתר זה אינה זמינה כרגע, אנא נסה בהמשך.',
    'Ref: Filters': 'פילטרים (אלרגנים ועוד)',
  },
  ProductScanner: {
    'Ref: cart': 'עֲגָלָה',
    'Ref: Product Scanned':
      'התווסף לעגלה. אתה יכול להמשיך לסרוק את הברקודים כדי להוסיף אותם לעגלה שלך.',
    'Ref: Scan or adjust': 'התווסף לעגלה. התאם כמות או סרוק מוצר אחר.',
    'Ref: Product Not Matched': 'ברקוד לא הוכר. בבקשה נסה שוב',
    'Ref: Scan article code': 'סרוק ברקוד',
    'Ref: Native error product cannot be added to cart':
      'לא ניתן להוסיף פריט מתפריט או חלק שונה לעגלה',
    'Ref: product scanner placeholder': 'אנא סרוק את הברקוד כדי להוסיף אותו לעגלה שלך',
  },
  Order: { 'Ref: Page title': 'להזמין' },
  MenuSelector: {
    'Ref: Menu Page title': 'תפריטים',
    'Ref: today': 'היום',
    'Ref: tomorrow': 'מָחָר',
    'Ref: Search products list': 'חיפוש פריטים.',
    'Ref: You have changed location': 'שינית את המיקום',
    'Ref: You are now at': 'אתה עכשיו ב',
  },
  ProductDetails: {
    'Ref: Page title': 'פרטי מוצר',
    'Ref: Serving Size': 'גודל מנה',
    'Ref: Nutrition': 'מידע תזונתי',
    'Ref: Nutrition adults': 'מבוגרים צריכים סביב 2000 קקאל ביום',
    'Ref: Nutrition Calories': 'קלוריות',
    'Ref: Nutrition Calcium': 'סִידָן',
    'Ref: Nutrition Fat': 'שמן',
    'Ref: Nutrition Saturated Fat': 'שומן רווי',
    'Ref: Nutrition Carbohydrates': 'פחמימות',
    'Ref: Nutrition Sugar': 'סוכר',
    'Ref: Nutrition Protein': 'חלבון',
    'Ref: Nutrition Sodium': 'נתרן',
    'Ref: Nutrition cholesterol': 'סוונסטרול',
    'Ref: Nutrition dietaryFiber': 'סיבים תזונתיים',
    'Ref: Nutrition iron': 'בַּרזֶל',
    'Ref: Nutrition potassium': 'אֶשׁלָגָן',
    'Ref: Nutrition vitaminA': 'ויטמין',
    'Ref: Nutrition vitaminC': 'ויטמין סי',
    'Ref: Additives': 'תוספים',
    'Ref: Emission': 'פליטת CO2',
    'Ref: This product generates': 'מוצר זה מייצר',
    'Ref: of CO2': 'של CO2.',
    'Ref: emissions, which equals a': 'פליטות, אשר שווה',
    'Ref: car trip': 'טיול ברכב',
    'Ref: Ingredients': 'מרכיב מתכון',
    'Ref: Allergens': 'אלרגנים',
    'Ref: Always there': 'תמיד שם',
    'Ref: Allergens selected': 'אלרגנים שנבחרו על המסנן:',
    'Ref: minimum required': 'מינימום חובה',
    'Ref: you have to add at least': 'אתה צריך להוסיף לפחות',
    'Ref: ingredients': 'רכיבים',
    'Ref: Ingredient': 'מַרכִּיב',
    'Ref: Free': 'חינם',
    'Ref: Based on customization': 'מבוסס על ההתאמה האישית שלך',
    'Ref: Additives eggs': 'ביצים',
    'Ref: Additives fish': 'דג',
    'Ref: Additives milk': 'חלב',
    'Ref: Additives nuts': 'אֱגוֹזִים',
    'Ref: Additives wheat': 'חיטה',
    'Ref: Additives peanuts': 'בוטנים',
    'Ref: Additives soy beans': 'שעועית סויה',
    'Ref: Additives sesame seeds': 'שומשום',
    'Ref: Review': 'סקירה',
    'Ref: Per portion': 'לכל חלק',
    'Ref: Per 100g': 'לכל 100 גרם',
    'Ref: Allergen selected': 'אלרגן שנבחר במסנן חיפוש',
    'Ref: Fitbit Log': 'יומן ל- Fitbit',
    'Ref: age verification required on delivery': 'אימות גיל הנדרש עם הלידה',
    'Ref: alcohol content notification':
      'על ידי הצבת והזמנה לאחד מהפריטים הללו, אתה מצהיר שאתה בן 18 ומעלה. שימוש לרעה באלכוהול מסוכן לבריאותך.',
    'Ref: default': 'בְּרִירַת מֶחדָל',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} הרוויח אם אתה מזמין מוצר זה',
    'Ref: Loyalty Card Description':
      'ה- {stamp_form} יתווסף לנאמנות הבאה {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'תוכנית חותמות הנאמנות מאפשרת לך לאסוף בולים לתגמולים.',
    'Ref: Loyalty Modal Description':
      'כשאתה קונה <b> {product_name}, בול אחד </b> יתווסף ל <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'וכן',
    'Ref: Stamp': 'חותמת',
    'Ref: Stamps': 'בולים',
    'Ref: Program': 'תכנית',
    'Ref: Programs': 'תוכניות',
    'Ref: Loyalty Stamps': 'חותמות נאמנות',
    'Ref: See Loyalty Stamps': 'ראו חותמות נאמנות',
    Favorite: 'אהוב',
    'Ref: In favorites': 'המוצר כבר נמצא ברשימת המועדפים. לחץ כדי להסיר אותו.',
    'Ref: Not in favorites': 'המוצר עדיין לא נמצא ברשימת המועדפים. לחץ כדי להוסיף אותו.',
    'Ref: Added to favorites': 'המוצר נוסף לרשימת המועדפים.',
    'Ref: Removed from favorites': 'המוצר הוסר מרשימת המועדפים.',
    'Ref: From': 'מ',
  },
  MenuCartActions: {
    'Ref: change in cart': 'שינוי בעגלה',
    'Ref: Popup header for customizable item qty update': 'פריט זה ניתן להתאמה אישית',
    'Ref: add new version': 'הוסף גרסה חדשה',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'עדכן את הכמות של פריט הניתן להתאמה אישית ישירות מהעגלה שלך.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'עדכן את הכמות של פריט הניתן להתאמה אישית ישירות מהעגלה שלך, או הוסף גרסה חדשה.',
    'Ref: another context body':
      'כדי להוסיף פריטים מאתר זה, מתקן, רגע/תפריט או תאריך אתה צריך לפנות עגלה',
    'Ref: cancel': 'לְבַטֵל',
    'Ref: add to cart': 'הוסף לעגלה',
    'Ref: view in cart': 'עֲגָלָה',
    'Ref: Products in cart': 'מספר {product_name} בעגלה: {product_quantity}',
    'Ref: Customize': 'התאמה אישית',
    'Ref: Add': 'לְהוֹסִיף',
    'Ref: Free': 'חינם',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'לְבַטֵל',
    'Ref: notice': 'הודעה',
    'Ref: clear cart': 'עגלה נקייה',
    'Ref: Cart addition error message':
      'נכון לעכשיו יש לך פריטים בעגלה מ- {from_current}. כדי להוסיף פריטים מ- {from_selected} אתה צריך לנקות את העגלה',
  },
  Cart: {
    'Ref: Page title': 'עֲגָלָה',
    'Ref: The cart is empty': 'העגלה ריקה',
    'Ref: Clear cart': 'עגלה נקייה',
    'Ref: Continue shopping': 'המשך בקניות',
    'Ref: Start shopping': 'התחל לקניות',
    'Ref: Today': 'היום',
    'Ref: Tomorrow': 'מָחָר',
    'Ref: Tax:': 'מַס:',
    'Ref: Promotion:': 'קידום:',
    'Ref: Total without promotion:': 'סה"כ ללא קידום:',
    'Ref: Delivery:': 'מְסִירָה:',
    'Ref: Pickup informations': 'מידע על איסוף',
    'Ref: Location': 'מקום',
    'Ref: Time': 'זְמַן',
    'Ref: Phone': 'מספר הטלפון שלך',
    'Ref: Instructions': 'הוראות',
    'Ref: Please select': 'בבקשה תבחר',
    'Ref: Enter special requests': 'הזן כאן בקשות מיוחדות',
    'Ref: notice': 'הודעה',
    'Ref: Product is no more available':
      'סליחה אבל נראה שמוצר כלשהו אינו זמין יותר. אנא בדוק את העגלה שלך.',
    'Ref: ok': 'בסדר',
    'Ref: Validate': 'לאמת את ההזמנה שלי',
    'Ref: Pay now': 'שלם עכשיו',
    'Ref: Charge to my department': 'תשלום למחלקה שלי',
    'Ref: Pay on pickup': 'שלם עבור פריטים אלה באיסוף',
    'Ref: Accept terms of sale prefix': 'אני מקבל',
    'Ref: Accept terms of sale': 'תנאי מכירות אלה',
    'Ref: Continue': 'לְהַמשִׁיך',
    'Ref: Please login or sign up to place an order': 'אנא התחבר או הירשם כדי לבצע הזמנה',
    'Ref: Accept terms of sale suffix': 'וקראתי והבנתי אותם.',
    'Ref: Payment modality': 'אמצעי תשלום',
    'Ref: Other information': 'מידע אחר',
    'Ref: Eat-in': 'לאכול',
    'Ref: Pickup': 'לאסוף',
    'Ref: Table': 'מספר טבלה',
    'Ref: Required fields': 'שדות חובה',
    'Service unavailable': 'שירותים לא זמינים',
    'Ref: Cannot order':
      'סליחה, אינך יכול להזמין באתר זה כרגע. אנא צור קשר עם המסעדה למידע נוסף על שעות פתיחה ושירותים זמינים.',
    'Ref: Order Unavailable': 'הזמנה לתאריך שנבחר, סוג ההגשמה והמיקום כבר לא זמין!',
    'Ref: No Availability': 'אין יותר זמינות',
    'Ref: Continue on your device': 'המשך במכשיר שלך',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} הרוויח אם אתה משלים את ההזמנה שלך',
    'Ref: Loyalty Card Description':
      'ה- {stamp_form} יתווסף לנאמנות הבאה {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'תוכנית חותמות הנאמנות מאפשרת לך לאסוף בולים לתגמולים.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> הרוויח אם אתה משלים את ההזמנה שלך:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> נוסף עבור <b> {scheme_names} </b>',
    'Ref: Stamp': 'חותמת',
    'Ref: Stamps': 'בולים',
    'Ref: Program': 'תכנית',
    'Ref: Programs': 'תוכניות',
    'Ref: Loyalty Stamps': 'חותמות נאמנות',
    'Ref: See Loyalty Stamps': 'ראו חותמות נאמנות',
    'Ref: Loyalty Info': 'מידע נאמנות',
    'Ref: Email address': 'כתובת דוא"ל',
    'Ref: and': 'וכן',
    'Ref: Order minimum amount': 'סליחה, סכום התשלום המינימלי הוא {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'המשך במכשיר שלך',
    'Ref: Scan QR to continue': 'אנא, סרוק את קוד ה- QR כדי להמשיך במסע במכשיר שלך.',
  },
  OrderHistory: { 'Ref: Orders': 'הזמנות', 'Ref: Page Title': 'ההזמנות שלי' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'מחיר ליחידה',
    'Ref: Download': 'הורד את הקבלה',
    'Ref: Download error': 'אירעה שגיאת הורדה. נסה להוריד את הקבלה מאוחר יותר',
    'Ref: Order details title': 'פרטי הזמנה',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'תודה! ההזמנה שלך מאושרת כעת',
    'Ref: Text for pickup':
      'אתה יכול לעקוב אחר הסטטוס שלו באמצעות היסטוריית ההזמנה שלך. אם ההודעות שלך מופעלות, אנו נעדכן אותך כאשר הם מוכנים לאיסוף.',
    'Ref: Success Order Title': 'תודה על הזמנתך',
    'Ref: Success Order Description': 'אנא שלם בקופה כדי לאמת את ההזמנה שלך.',
    'Ref: Success Order ID': 'הזמנת תעודת זהות',
    'Ref: Success Order Action': 'הזמנה חדשה',
    'Ref: Text for dinein':
      'בקרוב יש להגיש את ההזמנה שלך לשולחן שלך. פשוט שאל את כל אחד מאנשי הצוות הידידותיים שלנו לגבי הזמנת האוכל שלך.',
    'Ref: Text for scan&go': 'ההזמנה שלך זמינה בהיסטוריית ההזמנה שלך',
    'Ref: Text for delivery':
      'אתה יכול לעקוב אחר הסטטוס שלו בהיסטוריית ההזמנות שלך. אם ההודעות שלך מופעלות, אנו נודיע לך מתי ההזמנה שלך מוכנה.',
    'See details': 'ראה פרטים',
    'Go to home': 'לך הביתה',
  },
  FailOrder: {
    'Go to home': 'לך הביתה',
    'Ref: Kiosk Fail Title': 'אופס! משהו השתבש!',
    'Ref: Kiosk Fail Description':
      'למרבה הצער, ההזמנה שלך לא הייתה מוצלחת. אנא נסה שוב או בקש מאיש צוות שיעזור לך.',
    'Ref: Kiosk Fail Back': 'חזרה לדף הקודם',
    'Ref: Kiosk Fail Action': 'נקה את הפגישה והזמנה שוב',
    'Ref: Error in cart validation':
      'שגיאה התרחשה במהלך ההזמנה שלך. אנא צור קשר עם המתקן כדי לפתור את הבעיה.',
    'Facility contact': 'איש קשר במתקן',
    'Ref: Ordering failed': 'ההזמנה נכשלה',
    'Ref: Order confirmed': 'ההזמנה אושרה',
  },
  TableCheckIn: {
    'Welcome at': 'ברוך הבא ב',
    Table: 'שולחן:',
    'Ref: list title': 'הזמנות אחרונות להיום',
    'See the menu': 'ראה את התפריט',
    'Table Check-in': "צ'ק-אין בטבלה",
    'Ref #': 'Ref #',
    Checked: 'בָּדוּק',
    'Check in': 'קבלה',
    'See details': 'ראה פרטים',
    'Ref: You have changed location': 'שינית את המיקום',
    'Ref: You are now at': 'אתה עכשיו ב',
    'Order number': 'מספר הזמנה',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'קידום מיושם',
    'Ref: Promotion applied description': 'אתה נהנה מקידום מהעגלה שלך.',
    'Ref: Promotion deleted title': 'קידום נמחק',
    'Ref: Promotion deleted description':
      'אתה כבר לא מועיל לקידום מכיוון שאתה כבר לא ממלא את התנאים או שהקידום פג.',
  },
  MenuTile: { 'Ref: Scan&Go': 'סרוק & Go', 'Ref: View only': 'צפייה בלבד' },
  ProductTile: {
    'Ref: Allergens': 'אלרגנים',
    'Ref: Presence of allergen': 'נוכחות של אלרגן',
    'Ref: Scan': 'לִסְרוֹק',
    'Ref: From': 'מ',
    'Ref: Already in product favorite list. Click to remove it.':
      'כבר ברשימה המועדפת על המוצר. לחץ כדי להסיר אותו.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'נכון לעכשיו לא נוסף לרשימה המועדפת על המוצר. לחץ כדי להוסיף אותו.',
    'Ref: Free': 'חינם',
  },
  OrderSummary: {
    'Ref: item': 'פריט',
    'Ref: items': 'פריטים',
    'Ref: Clear': 'ברור',
    'Ref: Cart': 'עֲגָלָה',
    'Ref: Subtotal': 'תת -סיני',
    'Ref: Total': 'סך הכל',
    'Ref: Tax': 'מַס',
    'Ref: Promotion': 'קידום',
    'Ref: Total without promotion': 'סה"כ ללא קידום',
    'Ref: Number of product in cart': 'מספר המוצר בעגלה',
    'Ref: Delivery': 'מְסִירָה',
  },
  CartItemTile: { 'Ref: Vegan': 'טִבעוֹנִי', 'Ref: Vegeterian': 'צִמחוֹנִי' },
  OrderInfoCard: {
    'Ref: Header': 'הורה ל',
    'Ref: from': 'מ',
    'Ref: location': 'מקום',
    'Ref: Phone': 'מספר הטלפון שלך',
    'Ref: Instructions': 'הוראות',
    'Ref: Order type': 'סוג הזמנה',
    'Ref: Pickup': 'לאסוף',
    'Ref: Eat-in': 'לאכול',
    'Ref: Scan&Go': 'סרוק & Go',
  },
  ReorderWidget: {
    'Ref: No items': 'עדיין לא נעשו הזמנות. קנה עכשיו!',
    'Ref: Title': 'להזמין שוב',
  },
  MenusWidget: { 'Ref: no menu': 'אין תפריט כרגע', 'Ref: Modal title': 'תפריט ליום' },
  PromotionInfo: { 'Ref: Discounts available': 'הנחות זמינות' },
  PromotionInfoItem: { 'Ref: Activate': 'לְהַפְעִיל' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'ההזמנות שלך', 'Ref: Widget Title': 'ההזמנות שלי' },
  SuggestionsWidget: {
    'Ref: Modal title': 'הצעה ליום',
    'Ref: Any menu': 'כל תפריט',
    Other: 'אַחֵר',
    'Ref: Suggestions calendar button': 'לוח השנה של הצעות',
  },
  ModifierCard: {
    'Ref: Serving Size': 'גודל מנה',
    'Ref: Nutrition': 'מידע תזונתי',
    'Ref: Nutrition adults': 'מבוגרים צריכים סביב 2000 קקאל ביום',
    'Ref: Nutrition Calories': 'קלוריות',
    'Ref: Nutrition Calcium': 'סִידָן',
    'Ref: Nutrition Fat': 'שמן',
    'Ref: Nutrition Saturated Fat': 'שומן רווי',
    'Ref: Nutrition Carbohydrates': 'פחמימות',
    'Ref: Nutrition Sugar': 'סוכר',
    'Ref: Nutrition Protein': 'חלבון',
    'Ref: Nutrition Sodium': 'נתרן',
    'Ref: Nutrition cholesterol': 'סוונסטרול',
    'Ref: Nutrition dietaryFiber': 'סיבים תזונתיים',
    'Ref: Nutrition iron': 'בַּרזֶל',
    'Ref: Nutrition potassium': 'אֶשׁלָגָן',
    'Ref: Nutrition vitaminA': 'ויטמין',
    'Ref: Nutrition vitaminC': 'ויטמין סי',
    'Ref: Ingredients': 'מרכיב מתכון',
    'Ref: Allergens': 'אלרגנים',
    'Ref: Always there': 'תמיד שם',
    'Ref: Allergens selected': 'אלרגנים שנבחרו על המסנן:',
    'Ref: minimum required': 'מינימום חובה',
    'Ref: ingredients': 'רכיבים',
    'Ref: Ingredient': 'מַרכִּיב',
    'Ref: Free': 'חינם',
    'Ref: Based on customization': 'מבוסס על ההתאמה האישית שלך',
    'Ref: Additives eggs': 'ביצים',
    'Ref: Additives fish': 'דג',
    'Ref: Additives milk': 'חלב',
    'Ref: Additives nuts': 'אֱגוֹזִים',
    'Ref: Additives wheat': 'חיטה',
    'Ref: Additives peanuts': 'בוטנים',
    'Ref: Additives soy beans': 'שעועית סויה',
    'Ref: Additives sesame seeds': 'שומשום',
    'Ref: Review': 'סקירה',
    'Ref: Per portion': 'לכל חלק',
    'Ref: Per 100g': 'לכל 100 גרם',
    'Ref: Modifier item number of free items': '• {number_of_free_items} בחינם',
    'Ref: Number of free items': 'מספר הפריטים בחינם: {number_of_free_items}',
    'Ref: Allergen selected': 'אלרגן שנבחר במסנן חיפוש',
    'Ref: Nutritional info': 'מידע תזונתי',
    'Ref: Info': 'מידע',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'מבצעים' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'בחר את העבודה שלך',
    'Ref: Validate': 'לְאַמֵת',
    'Ref: Pricing Tiers dialog message': 'הוכרת בכתובת הדוא"ל שלך',
    'Ref: Please select your job in the company': 'אנא בחר את העבודה שלך בחברה',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'לאסוף',
    'Ref: Eat-in': 'לאכול',
    'Ref: Scan&Go': 'סרוק & Go',
    'Ref: Delivery': 'מְסִירָה',
    'Ref: Total': 'סך הכל',
    'Ref: How was facility name service': 'איך היה שירות {facility_name}?',
    'Ref: How do you rate the service': 'איך מדרגים את השירות?',
  },
};
export default labels;
