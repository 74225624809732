const labels = {
  ReviewForm: {
    'Ref: Page title': '點評',
    'Ref: How was dining with us': '和我們一起用餐嗎？',
    'Ref: Submit': '提交',
    'Ref: Filter range': '過濾器範圍',
    'Ref: Some information is missing': '缺少一些信息',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': '匿名發送',
    'Ref: Your email address': '您的電子郵件地址',
    'Ref: Email field placeholder': '輸入你的電子郵箱地址',
    'Ref: Invalid email format': '無效的電子郵件格式',
  },
  SuccessPage: {
    'Ref: Body': '感謝您的寶貴意見，我們將持續改善我們的服務。',
    'Ref: Go to Feedback': '轉到反饋',
    'Ref: Go to home': '轉到主頁',
    'Ref: Go to Facilities': '轉到設施',
    'Ref: Go to Product Details': '轉到產品詳情',
    'Ref: Go to Facility Details': '轉到設施詳情',
  },
  FacilityPicker: { 'Ref: Page title': '點評', 'Ref: Search facility name': '搜索設施名稱' },
};
export default labels;
