import { useDispatch, useSelector } from 'react-redux';

import { addProductToFavorites, removeProductFromFavorites } from '../../actions';
import { useAddFavoriteProductMutation, useRemoveFavoriteProductMutation } from '../../api';
import { getMenuItemById } from '../../helpers/productDetails.helper';
import { OrderState } from '../../types/orderState.types';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { UserSteps } from '@/types/userSteps.types';

export const useProductPortion = (menuItemId: number, uomId: number, siteId: string) => {
  const dispatch = useDispatch();
  const [addFavoriteProduct] = useAddFavoriteProductMutation();
  const [removeFavoriteProduct] = useRemoveFavoriteProductMutation();
  const { logUserSteps, logError } = useUserStepsInsightsLogging();

  const productPortion = useSelector((state: { Order: OrderState }) => {
    const menuItem = getMenuItemById(menuItemId, state.Order?.menus);
    if (!menuItem) return undefined;

    const productPortion = menuItem.productPortions.find((portion) => portion.uomId === uomId);
    return productPortion;
  });

  const addToFavorites = async () => {
    try {
      await addFavoriteProduct({ siteId: siteId, uomId });
      dispatch(addProductToFavorites(uomId));
      logUserSteps({ event: UserSteps.ProductAddedToFavorites, params: { uomId } });
    } catch (error) {
      console.error(error);
      logError(new Error('Error when adding product to favorites'), { uomId: uomId });
    }
  };

  const removeFromFavorites = async () => {
    try {
      await removeFavoriteProduct({ siteId: siteId, uomId });
      dispatch(removeProductFromFavorites(uomId));
      logUserSteps({ event: UserSteps.ProductRemovedFromFavorites, params: { uomId } });
    } catch (error) {
      console.error(error);
      logError(new Error('Error when removing product from favorites'), { uomId: uomId });
    }
  };

  return { productPortion, addToFavorites, removeFromFavorites };
};
