import { act, cleanup, renderHook } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import { defaultValues } from '../../../config';
import { useSuggestionsMobilePopup } from '../useSuggestionsMobilePopup';

const isRrMenu = true;
const isMenuOrderable = true;

jest.mock('../../../../../helpers/windowSize', () => {
  return {
    isDesktopViewport: () => false,
  };
});

jest.useFakeTimers();
jest.spyOn(global, 'setInterval');

describe('useSuggestionsMobilePopup hook', () => {
  const env = global.process.env;

  afterAll(() => cleanup());

  describe('When is Bite', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: APP_DISPLAY_NAME.BITE,
        REACT_APP_PORTAL_ENV_CODE: 'prod',
      };
    });

    describe('on initial render', () => {
      it('should return expected result', () => {
        const { result } = renderHook(() => useSuggestionsMobilePopup(isRrMenu, isMenuOrderable));

        expect(result.current.showSuggestionPopup).toEqual(false);
        expect(result.current.showProgressBar).toEqual(true);
        expect(result.current.isFirstItemScanned).toEqual(false);
        expect(result.current.remainingSeconds).toEqual(defaultValues.suggestionsCountdownSeconds);
      });
    });

    describe('when popup is triggered', () => {
      it('should open popup with progress bar and start countdown', () => {
        const { result } = renderHook(() => useSuggestionsMobilePopup(isRrMenu, isMenuOrderable));
        act(() => {
          result.current.openPopupAndCountDown();
        });
        expect(result.current.showSuggestionPopup).toEqual(true);
        expect(result.current.showProgressBar).toEqual(true);
        expect(setInterval).toHaveBeenCalledTimes(1);
      });
    });

    describe('when user interacts with popup', () => {
      it('countdown should stop and progress bar disappear', () => {
        const { result } = renderHook(() => useSuggestionsMobilePopup(isRrMenu, isMenuOrderable));
        act(() => {
          result.current.openPopupAndCountDown();
        });
        act(() => {
          result.current.stopCountdown();
        });
        expect(result.current.showSuggestionPopup).toEqual(true);
        expect(result.current.showProgressBar).toEqual(false);
        expect(result.current.isFirstItemScanned).toEqual(false);
      });
    });

    describe('when popup is closed', () => {
      it('popup should disappear', () => {
        const { result } = renderHook(() => useSuggestionsMobilePopup(isRrMenu, isMenuOrderable));
        act(() => {
          result.current.openPopupAndCountDown();
        });
        act(() => {
          result.current.closePopupResetTime();
        });
        expect(result.current.showSuggestionPopup).toEqual(false);
        expect(result.current.remainingSeconds).toEqual(defaultValues.suggestionsCountdownSeconds);
        expect(result.current.isFirstItemScanned).toEqual(false);
      });
    });
  });

  describe('When is Kiosk', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: APP_DISPLAY_NAME.BITEKIOSK,
        REACT_APP_PORTAL_ENV_CODE: 'prod',
      };
    });
    describe('when popup is triggered', () => {
      it('should not open popup', () => {
        const { result } = renderHook(() => useSuggestionsMobilePopup(isRrMenu, isMenuOrderable));
        act(() => {
          result.current.openPopupAndCountDown();
        });
        expect(result.current.showSuggestionPopup).toEqual(false);
      });
    });
  });
});
