import { useEffect, useState } from 'react';

import { useLazyCheckCanOrderQuery } from '../../api';
import { FacilityMenu, FacilityMenuSource } from '../../types/orderState.types';

export const useCheckCanOrder = ({
  siteId,
  orderPickupDate,
  selectedMenu,
}: {
  siteId: string | undefined;
  orderPickupDate: string | undefined;
  selectedMenu: FacilityMenu | undefined;
}) => {
  const [canOrder, setCanOrder] = useState(false);
  const [checkCanOrder, { isLoading: canOrderLoading }] = useLazyCheckCanOrderQuery();

  useEffect(() => {
    const checkCanOrderFunction = async () => {
      if (selectedMenu?.source !== FacilityMenuSource.Rr || !selectedMenu?.isOrderable) {
        setCanOrder(true);
        return;
      }

      if (siteId && selectedMenu?.id && selectedMenu?.facilityId && orderPickupDate) {
        const result = await checkCanOrder({
          siteId,
          menuId: selectedMenu.id,
          facilityId: selectedMenu.facilityId,
          orderPickupDate,
          useErrorBoundary: false,
        });

        setCanOrder(result.data?.isOrderingPossible ?? false);
      }
    };
    checkCanOrderFunction();
  }, [
    siteId,
    orderPickupDate,
    checkCanOrder,
    selectedMenu?.source,
    selectedMenu?.id,
    selectedMenu?.facilityId,
    selectedMenu?.isOrderable,
  ]);

  return { canOrder, canOrderLoading };
};
