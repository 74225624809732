import biteApi from '../../../services/api/biteApi';
import mockResponse from '../mockResponse';
import { StampCollection } from '../types/stampsState.types';

import { GetStampsArgs, RedeemStampsArgs, ScanQrCodeArgs } from './api.types';

const freeCoffeeStampsApi = biteApi
  .enhanceEndpoints({ addTagTypes: ['freeCoffeeStamps'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getStamps: build.query<StampCollection, GetStampsArgs>({
        query: ({ siteId, useErrorBoundary }: GetStampsArgs) => ({
          url: '/v1/user/stamps',
          method: 'get',
          params: { siteId },
          mockData: mockResponse.getStamps,
          opts: { useErrorBoundary },
        }),
        providesTags: ['freeCoffeeStamps'],
      }),
      scanQrCode: build.mutation<StampCollection, ScanQrCodeArgs>({
        query: ({ siteId, qrCode, useErrorBoundary }: ScanQrCodeArgs) => ({
          url: '/v1/user/qr-code/submit',
          method: 'post',
          params: { siteId, qrCode },
          mockData: mockResponse.scanQRCode,
          opts: { useErrorBoundary },
        }),
        invalidatesTags: ['freeCoffeeStamps'],
      }),
      redeemStamps: build.mutation<StampCollection, RedeemStampsArgs>({
        query: ({ siteId, useErrorBoundary }: RedeemStampsArgs) => ({
          url: '/v1/user/stamps/redeem',
          method: 'post',
          params: { siteId },
          mockData: mockResponse.redeemStamps,
          opts: { useErrorBoundary },
        }),
        invalidatesTags: ['freeCoffeeStamps'],
      }),
    }),
    overrideExisting: true,
  });
export const {
  useGetStampsQuery,
  useLazyGetStampsQuery,
  useScanQrCodeMutation,
  useRedeemStampsMutation,
} = freeCoffeeStampsApi;

export default freeCoffeeStampsApi;
