const labels = {
  All: {
    'Ref: Redeem coffee description': 'Tách cà phê miễn phí, mới pha của bạn đang chờ đợi',
    'Ref: Congratulations': 'Xin chúc mừng',
    'Ref: Get free coffee': 'Nhận cà phê miễn phí!',
    'Ref: Free coffee description': 'Đổ đầy cốc của bạn, kiếm điểm cho một loại cà phê miễn phí.',
    'Ref: Redeem': 'Chuộc lại!',
  },
  StampPage: {
    'Ref: Page title': 'Cà phê miễn phí',
    'Ref: seconds left': 'giây còn lại',
    'Ref: How it works': 'Làm thế nào nó hoạt động',
    'Ref: How it works description':
      'Mỗi lần bạn mua cà phê, nhấn nút quét và quét mã QR được cung cấp tại máy tính tiền.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Quét mã QR',
    'Ref: Collect reward': 'Thu thập phần thưởng',
    'Ref: Reward collected': 'Phần thưởng được thu thập',
    'Ref: Invalid QR Code': 'Mã QR không hợp lệ',
    'Ref: Invalid QR Code description': 'Mã QR không được công nhận',
    'Ref: Close': 'Đóng',
    'Ref: Redeem button': 'Chuộc lại',
    "Ref: The QR Code isn't recognized": 'Mã QR không được công nhận',
    'Ref: Cancel': 'Hủy bỏ',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Xin chúc mừng!',
    'Ref: Confirm redeem description':
      'Chắc chắn bạn muốn sử dụng cái này? Nếu vậy, bạn chỉ có 60 giây để hiển thị điện thoại của mình tại máy tính tiền và hoàn thành việc mua hàng của bạn',
    'Ref: Congratulations modal description': 'Lấy cà phê miễn phí của bạn ngay bây giờ!',
  },
  Widget: {
    'Ref: Free Coffee widget title': 'Cà phê miễn phí!',
    'Ref: See All': 'Nhìn thấy tất cả',
  },
};
export default labels;
