const labels = {
  All: {
    'Ref: Assembled': 'Собрано',
    'Ref: Submitted': 'Поданный',
    'Ref: Fulfilled': 'Выполнен',
    'Ref: Completed': 'Завершенный',
    'Ref: Paid': 'Оплаченный',
    'Ref: Approved': 'Одобренный',
    'Ref: Ready For Pickup': 'Готов',
    'Ref: Delivered': 'Доставленный',
    'Ref: Received': 'Полученный',
    'Ref: Rejected': 'Отклоненный',
    'Ref: Payment Error': 'Ошибка оплаты',
    'Ref: Pending Payment': 'В ожидании оплаты',
    'Ref: Preparing': 'Подготовка',
    'Ref: Refunded': 'Возвращено',
    'Ref: Cancelled By User': 'Отменено пользователем',
    'Ref: Refund Requested': 'Возврат запрошен',
    'Ref: Refund Failed': 'Возврат не удался',
    'Ref: Payment Processing': 'Процесс оплаты',
    'Ref: Not Paid': 'Не оплачено',
    'Ref: Total:': 'Общее:',
    'Ref: Pickup': 'Подбирать',
    'Ref: Eat-in': 'Еда',
    'Ref: Scan&Go': 'Сканировать и идти',
    'Ref: Fulfillment Type': 'Тип выполнения',
    'Ref: Delivery': 'Доставка',
    'Ref: You have no future orders': 'У вас нет будущих заказов',
    'Ref: You have no past orders': 'У тебя нет прошлых заказов',
    'Ref: You have no orders': 'У тебя нет заказов',
    'Ref: All orders': 'Все заказы',
    'Ref: Not past orders': 'Предстоящие заказы',
    'Ref: Past orders': 'Прошлые заказы',
    'Ref: Vegan': 'Веган',
    'Ref: Vegeterian': 'Вегетарианский',
    'Ref: See item': 'См. Пункт',
    'Ref: Activated': 'Активирован',
    'Ref: No suggestions': 'Никаких предложений не найдено',
    'Ref: Get your receipt': 'Получите квитанцию',
    'Ref: Print': 'Распечатайте квитанцию',
    'Ref: Email': 'Получите квитанцию ​​по электронной почте',
    'Ref: Receipt Modal message': 'Как бы вы хотели получить квитанцию?',
    'Ref: Confirm': 'Подтверждать',
    'Ref: Your email address': 'Ваш электронный адрес',
    Suggestion: 'Предложение',
    'Ref: Loyalty Reward': 'Награда за лояльность',
    'Ref: Equation for stamps per item': '1 Покупка = 1 штамп',
    'Ref: Paid by employer': 'Сумма, выплачиваемая вашим работодателем',
    'Ref: Paid by user': 'Сумма, выплаченная вами',
  },
  ProductsList: {
    'Ref: Page title': 'Список продуктов',
    'Ref: Facility details': 'Детали объекта',
    'Ref: Search products list': 'Поиск предметов',
    'Ref: filter label: menus': 'Меню',
    'Ref: filter label: moment': 'Момент',
    'Ref: filter label: category': 'Категория',
    'Ref: all': 'Все',
    'Ref: today': 'Cегодня',
    'Ref: tomorrow': 'Завтра',
    'Ref: see more': 'Узнать больше',
    'Ref: see less': 'Увидеть меньше',
    'Ref: reset': 'Перезагрузить',
    'Ref: View in cart': 'Просмотр в тележке',
    'Ref: You have changed location': 'Вы изменили местоположение',
    'Ref: You are now at': 'Вы сейчас в',
    'Ref: Menu card': 'Карта меню',
    'Ref: Table': 'Номер таблицы',
    'Service unavailable': 'Сервис недоступен',
    'Ref: Ordering unavailable':
      'Осторожный! Заказ на этом сайте в данный момент недоступен, попробуйте позже.',
    'Ref: Filters': 'Фильтры (аллергены и многое другое)',
  },
  ProductScanner: {
    'Ref: cart': 'Тележка',
    'Ref: Product Scanned':
      'был добавлен в корзину. Вы можете продолжать сканировать штрих -коды, чтобы добавить их в корзину.',
    'Ref: Scan or adjust':
      'был добавлен в корзину. Регулируйте количество или сканируйте другой продукт.',
    'Ref: Product Not Matched': 'Штрих -код не был признан. Пожалуйста, попробуйте еще раз',
    'Ref: Scan article code': 'Сканировать штрих -код',
    'Ref: Native error product cannot be added to cart':
      'Предмет из разных меню или дневной части не может быть добавлен в корзину',
    'Ref: product scanner placeholder':
      'Пожалуйста, сканируйте штрих -код, чтобы добавить его в корзину',
  },
  Order: { 'Ref: Page title': 'порядок' },
  MenuSelector: {
    'Ref: Menu Page title': 'меню',
    'Ref: today': 'Cегодня',
    'Ref: tomorrow': 'Завтра',
    'Ref: Search products list': 'Поиск предметов',
    'Ref: You have changed location': 'Вы изменили местоположение',
    'Ref: You are now at': 'Вы сейчас в',
  },
  ProductDetails: {
    'Ref: Page title': 'информация о продукте',
    'Ref: Serving Size': 'Размер сервировки',
    'Ref: Nutrition': 'Пищевая информация',
    'Ref: Nutrition adults': 'Взрослые нужно около 2000 ккал в день',
    'Ref: Nutrition Calories': 'Калории',
    'Ref: Nutrition Calcium': 'Кальций',
    'Ref: Nutrition Fat': 'Жир',
    'Ref: Nutrition Saturated Fat': 'Насыщенный жир',
    'Ref: Nutrition Carbohydrates': 'углеводы',
    'Ref: Nutrition Sugar': 'Сахар',
    'Ref: Nutrition Protein': 'белка',
    'Ref: Nutrition Sodium': 'натрий',
    'Ref: Nutrition cholesterol': 'Холестерин',
    'Ref: Nutrition dietaryFiber': 'Пищевое волокно',
    'Ref: Nutrition iron': 'Железо',
    'Ref: Nutrition potassium': 'Калий',
    'Ref: Nutrition vitaminA': 'Витамин А.',
    'Ref: Nutrition vitaminC': 'Витамин C',
    'Ref: Additives': 'Добавки',
    'Ref: Emission': 'Выбросы CO2',
    'Ref: This product generates': 'Этот продукт генерирует',
    'Ref: of CO2': 'CO2',
    'Ref: emissions, which equals a': 'выбросы, которые равны',
    'Ref: car trip': 'Автомобильная поездка',
    'Ref: Ingredients': 'Рецепт ингредиента',
    'Ref: Allergens': 'Аллергены',
    'Ref: Always there': 'Всегда там',
    'Ref: Allergens selected': 'Аллергены, выбранные на фильтре:',
    'Ref: minimum required': 'минимальный требуется',
    'Ref: you have to add at least': 'Вы должны добавить хотя бы',
    'Ref: ingredients': 'ингредиенты',
    'Ref: Ingredient': 'ингредиент',
    'Ref: Free': 'Бесплатно',
    'Ref: Based on customization': 'На основании вашей настройки',
    'Ref: Additives eggs': 'яйца',
    'Ref: Additives fish': 'рыбы',
    'Ref: Additives milk': 'молоко',
    'Ref: Additives nuts': 'орехи',
    'Ref: Additives wheat': 'пшеница',
    'Ref: Additives peanuts': 'арахис',
    'Ref: Additives soy beans': 'соевые бобы',
    'Ref: Additives sesame seeds': 'семена кунжута',
    'Ref: Review': 'Рассмотрение',
    'Ref: Per portion': 'За часть',
    'Ref: Per 100g': 'За 100 г',
    'Ref: Allergen selected': 'Аллерген выбран в поисковом фильтре',
    'Ref: Fitbit Log': 'Журнал в Fitbit',
    'Ref: age verification required on delivery': 'Проверка возраста, требуемая при доставке',
    'Ref: alcohol content notification':
      'Размещая и заказывая один из этих предметов, вы заявляете, что вам в возрасте 18 лет или старше. Злоупотребление алкоголем опасно для вашего здоровья.',
    'Ref: default': 'По умолчанию',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} заработано, если вы заказываете этот продукт',
    'Ref: Loyalty Card Description':
      '{stamp_form} будет добавлено в следующую лояльность {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Программа штампов лояльности позволяет собирать марки для вознаграждений.',
    'Ref: Loyalty Modal Description':
      'Когда вы покупаете <b> {product_name}, 1 Stamp </b> будет добавлен в <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'и',
    'Ref: Stamp': 'Печать',
    'Ref: Stamps': 'Марки',
    'Ref: Program': 'Программа',
    'Ref: Programs': 'Программы',
    'Ref: Loyalty Stamps': 'Штампы лояльности',
    'Ref: See Loyalty Stamps': 'Смотрите марки верности',
    Favorite: 'Любимый',
    'Ref: In favorites': 'Продукт уже в списке фаворитов. Нажмите, чтобы удалить его.',
    'Ref: Not in favorites': 'Продукт еще не в списке фаворитов. Нажмите, чтобы добавить его.',
    'Ref: Added to favorites': 'Продукт был добавлен в список фаворитов.',
    'Ref: Removed from favorites': 'Продукт был удален из списка фаворитов.',
    'Ref: From': 'Из',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Изменение в тележке',
    'Ref: Popup header for customizable item qty update': 'Этот элемент настраивается',
    'Ref: add new version': 'Добавить новую версию',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Обновите количество настраиваемого элемента прямо из вашей корзины.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Обновите количество настраиваемого элемента непосредственно из вашей корзины или добавьте новую версию.',
    'Ref: another context body':
      'Чтобы добавить предметы с этого сайта, объекта, момента/меню или даты, вам нужно очистить корзину',
    'Ref: cancel': 'Отмена',
    'Ref: add to cart': 'Добавить в корзину',
    'Ref: view in cart': 'Тележка',
    'Ref: Products in cart': 'Номер {product_name} в корзине: {product_quantity}',
    'Ref: Customize': 'Настраивать',
    'Ref: Add': 'Добавлять',
    'Ref: Free': 'Бесплатно',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Отмена',
    'Ref: notice': 'Уведомление',
    'Ref: clear cart': 'Чистая корзина',
    'Ref: Cart addition error message':
      'В настоящее время у вас есть элементы в корзине от {from_current}. Чтобы добавить элементы из {from_selected}, вам нужно очистить корзину',
  },
  Cart: {
    'Ref: Page title': 'Тележка',
    'Ref: The cart is empty': 'Тележка пуста',
    'Ref: Clear cart': 'Чистая корзина',
    'Ref: Continue shopping': 'Продолжить покупки',
    'Ref: Start shopping': 'Начните делать покупки',
    'Ref: Today': 'Сегодня',
    'Ref: Tomorrow': 'Завтра',
    'Ref: Tax:': 'Налог:',
    'Ref: Promotion:': 'Продвижение:',
    'Ref: Total without promotion:': 'Всего без продвижения:',
    'Ref: Delivery:': 'Доставка:',
    'Ref: Pickup informations': 'Информация о получении',
    'Ref: Location': 'Место нахождения',
    'Ref: Time': 'Время',
    'Ref: Phone': 'Ваш номер телефона',
    'Ref: Instructions': 'инструкции',
    'Ref: Please select': 'Пожалуйста выберите',
    'Ref: Enter special requests': 'Введите специальные запросы здесь',
    'Ref: notice': 'Уведомление',
    'Ref: Product is no more available':
      'Извините, но кажется, что некоторые продукты больше не доступны. Пожалуйста, проверьте свою корзину.',
    'Ref: ok': 'В порядке',
    'Ref: Validate': 'Проверить мой заказ',
    'Ref: Pay now': 'Заплатить сейчас',
    'Ref: Charge to my department': 'Заряжать в моем отделе',
    'Ref: Pay on pickup': 'Заплатить за эти товары при получении',
    'Ref: Accept terms of sale prefix': 'Я принимаю',
    'Ref: Accept terms of sale': 'эти условия продаж',
    'Ref: Continue': 'Продолжать',
    'Ref: Please login or sign up to place an order':
      'Пожалуйста, войдите или зарегистрируйтесь, чтобы разместить заказ',
    'Ref: Accept terms of sale suffix': ', и я прочитал и понял их.',
    'Ref: Payment modality': 'Способ оплаты',
    'Ref: Other information': 'Дополнительная информация',
    'Ref: Eat-in': 'Еда',
    'Ref: Pickup': 'Подбирать',
    'Ref: Table': 'Номер таблицы',
    'Ref: Required fields': 'Требуемые поля',
    'Service unavailable': 'Сервис недоступен',
    'Ref: Cannot order':
      'Извините, вы не можете сделать заказ на этом сайте в данный момент. Пожалуйста, свяжитесь с рестораном для получения дополнительной информации о часах открытия и доступных услуг.',
    'Ref: Order Unavailable':
      'Заказ на выбранную дату, тип и местонахождение выполнения больше недоступны!',
    'Ref: No Availability': 'Больше нет доступности',
    'Ref: Continue on your device': 'Продолжайте свое устройство',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} заработано, если вы заполните свой заказ',
    'Ref: Loyalty Card Description':
      '{stamp_form} будет добавлено в следующую лояльность {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Программа штампов лояльности позволяет собирать марки для вознаграждений.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> заработано, если вы заполните свой заказ:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> добавлен для <b> {scheme_names} </b>',
    'Ref: Stamp': 'Печать',
    'Ref: Stamps': 'Марки',
    'Ref: Program': 'Программа',
    'Ref: Programs': 'Программы',
    'Ref: Loyalty Stamps': 'Штампы лояльности',
    'Ref: See Loyalty Stamps': 'Смотрите марки верности',
    'Ref: Loyalty Info': 'Информация о лояльности',
    'Ref: Email address': 'Адрес электронной почты',
    'Ref: and': 'и',
    'Ref: Order minimum amount': 'Извините, минимальная сумма платежа равен {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Продолжайте свое устройство',
    'Ref: Scan QR to continue':
      'Пожалуйста, сканируйте QR -код, чтобы продолжить путешествие на вашем устройстве.',
  },
  OrderHistory: { 'Ref: Orders': 'Приказ', 'Ref: Page Title': 'мои заказы' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Цена за единицу',
    'Ref: Download': 'Загрузите квитанцию',
    'Ref: Download error': 'Произошла ошибка загрузки. Попробуйте скачать квитанцию ​​позже',
    'Ref: Order details title': 'Информация для заказа',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Благодарю вас! Ваш заказ теперь подтвержден',
    'Ref: Text for pickup':
      'Вы можете следовать его статусу через историю заказа. Если ваши уведомления включены, мы обновим вас, когда они будут готовы к получению.',
    'Ref: Success Order Title': 'Спасибо за ваш заказ',
    'Ref: Success Order Description':
      'Пожалуйста, заплатите при оформлении заказа, чтобы подтвердить ваш заказ.',
    'Ref: Success Order ID': 'Идентификационный заказ',
    'Ref: Success Order Action': 'Новый заказ',
    'Ref: Text for dinein':
      'Ваш заказ должен быть подан в ваш стол в ближайшее время. Просто спросите любого из нашего дружелюбного сотрудника о вашем порядке питания.',
    'Ref: Text for scan&go': 'Ваш заказ доступен в вашей истории заказа',
    'Ref: Text for delivery':
      'Вы можете отслеживать его статус в своей истории заказа. Если ваши уведомления включены, мы сообщим вам, когда ваш заказ будет готов.',
    'See details': 'Смотрите подробности',
    'Go to home': 'Иди домой',
  },
  FailOrder: {
    'Go to home': 'Иди домой',
    'Ref: Kiosk Fail Title': 'Уп! Что-то пошло не так!',
    'Ref: Kiosk Fail Description':
      'К сожалению, ваш заказ не был успешным. Пожалуйста, попробуйте еще раз или попросите сотрудника помочь вам.',
    'Ref: Kiosk Fail Back': 'Вернуться на предыдущую страницу',
    'Ref: Kiosk Fail Action': 'Очистите сеанс и заказать снова',
    'Ref: Error in cart validation':
      'Ошибка произошла во время вашего заказа. Пожалуйста, свяжитесь с объектом, чтобы решить проблему.',
    'Facility contact': 'Контакт объекта',
    'Ref: Ordering failed': 'Заказ не удался',
    'Ref: Order confirmed': 'Заказ подтвержден',
  },
  TableCheckIn: {
    'Welcome at': 'Добро пожаловать в',
    Table: 'Стол:',
    'Ref: list title': 'Последние заказы на сегодня',
    'See the menu': 'Смотрите меню',
    'Table Check-in': 'Заездье таблицы',
    'Ref #': 'Ref #',
    Checked: 'Проверял',
    'Check in': 'Регистрироваться',
    'See details': 'Смотрите подробности',
    'Ref: You have changed location': 'Вы изменили местоположение',
    'Ref: You are now at': 'Вы сейчас в',
    'Order number': 'Номер заказа',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Продвижение применяется',
    'Ref: Promotion applied description': 'Вы получаете выгоду от продвижения от вашей телеги.',
    'Ref: Promotion deleted title': 'Продвижение удалено',
    'Ref: Promotion deleted description':
      'Вы больше не пользуетесь продвижением, потому что вы больше не заполняете условия или срок действия по службе.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Сканировать и идти', 'Ref: View only': 'Просмотр только' },
  ProductTile: {
    'Ref: Allergens': 'Аллергены',
    'Ref: Presence of allergen': 'Присутствие аллергена',
    'Ref: Scan': 'Сканирование',
    'Ref: From': 'Из',
    'Ref: Already in product favorite list. Click to remove it.':
      'Уже в любимом списке продукта. Нажмите, чтобы удалить его.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'В настоящее время не добавлен в список любимых продуктов. Нажмите, чтобы добавить его.',
    'Ref: Free': 'Бесплатно',
  },
  OrderSummary: {
    'Ref: item': 'вещь',
    'Ref: items': 'Предметы',
    'Ref: Clear': 'чистый',
    'Ref: Cart': 'Тележка',
    'Ref: Subtotal': 'Промежуточный итог',
    'Ref: Total': 'Общий',
    'Ref: Tax': 'Налога',
    'Ref: Promotion': 'Продвижение',
    'Ref: Total without promotion': 'Всего без продвижения по службе',
    'Ref: Number of product in cart': 'Количество продукта в тележке',
    'Ref: Delivery': 'Доставка',
  },
  CartItemTile: { 'Ref: Vegan': 'Веганский', 'Ref: Vegeterian': 'Вегетарианский' },
  OrderInfoCard: {
    'Ref: Header': 'Заказано для',
    'Ref: from': 'из',
    'Ref: location': 'Расположение',
    'Ref: Phone': 'Ваш номер телефона',
    'Ref: Instructions': 'инструкции',
    'Ref: Order type': 'Тип заказа',
    'Ref: Pickup': 'Подбирать',
    'Ref: Eat-in': 'Еда',
    'Ref: Scan&Go': 'Сканировать и идти',
  },
  ReorderWidget: {
    'Ref: No items': 'Никаких заказов еще не сделано. Сделай покупки сейчас!',
    'Ref: Title': 'Заказ снова',
  },
  MenusWidget: { 'Ref: no menu': 'Нет меню на данный момент', 'Ref: Modal title': 'Меню на день' },
  PromotionInfo: { 'Ref: Discounts available': 'Скидки доступны' },
  PromotionInfoItem: { 'Ref: Activate': 'Активировать' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Ваши заказы', 'Ref: Widget Title': 'мои заказы' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Предложение на день',
    'Ref: Any menu': 'Любое меню',
    Other: 'Другой',
    'Ref: Suggestions calendar button': 'Календарь предложений',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Размер сервировки',
    'Ref: Nutrition': 'Пищевая информация',
    'Ref: Nutrition adults': 'Взрослые нужно около 2000 ккал в день',
    'Ref: Nutrition Calories': 'Калории',
    'Ref: Nutrition Calcium': 'Кальций',
    'Ref: Nutrition Fat': 'Жир',
    'Ref: Nutrition Saturated Fat': 'Насыщенный жир',
    'Ref: Nutrition Carbohydrates': 'углеводы',
    'Ref: Nutrition Sugar': 'Сахар',
    'Ref: Nutrition Protein': 'белка',
    'Ref: Nutrition Sodium': 'натрий',
    'Ref: Nutrition cholesterol': 'Холестерин',
    'Ref: Nutrition dietaryFiber': 'Пищевое волокно',
    'Ref: Nutrition iron': 'Железо',
    'Ref: Nutrition potassium': 'Калий',
    'Ref: Nutrition vitaminA': 'Витамин А.',
    'Ref: Nutrition vitaminC': 'Витамин C',
    'Ref: Ingredients': 'Рецепт ингредиента',
    'Ref: Allergens': 'Аллергены',
    'Ref: Always there': 'Всегда там',
    'Ref: Allergens selected': 'Аллергены, выбранные на фильтре:',
    'Ref: minimum required': 'минимальный требуется',
    'Ref: ingredients': 'ингредиенты',
    'Ref: Ingredient': 'ингредиент',
    'Ref: Free': 'Бесплатно',
    'Ref: Based on customization': 'На основании вашей настройки',
    'Ref: Additives eggs': 'яйца',
    'Ref: Additives fish': 'рыбы',
    'Ref: Additives milk': 'молоко',
    'Ref: Additives nuts': 'орехи',
    'Ref: Additives wheat': 'пшеница',
    'Ref: Additives peanuts': 'арахис',
    'Ref: Additives soy beans': 'соевые бобы',
    'Ref: Additives sesame seeds': 'семена кунжута',
    'Ref: Review': 'Рассмотрение',
    'Ref: Per portion': 'За часть',
    'Ref: Per 100g': 'За 100 г',
    'Ref: Modifier item number of free items': '• {number_of_free_items} бесплатно',
    'Ref: Number of free items': 'Количество бесплатных предметов: {number_of_free_items}',
    'Ref: Allergen selected': 'Аллерген выбран в поисковом фильтре',
    'Ref: Nutritional info': 'Питательная информация',
    'Ref: Info': 'Информация',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'промо акции' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Выберите свою работу',
    'Ref: Validate': 'Проверять',
    'Ref: Pricing Tiers dialog message': 'Вы были признаны своим адресом электронной почты',
    'Ref: Please select your job in the company': 'Пожалуйста, выберите свою работу в компании',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Подбирать',
    'Ref: Eat-in': 'Еда',
    'Ref: Scan&Go': 'Сканировать и идти',
    'Ref: Delivery': 'Доставка',
    'Ref: Total': 'Общий',
    'Ref: How was facility name service': 'Как прошел сервис {facility_name}?',
    'Ref: How do you rate the service': 'Как вы оцениваете обслуживание?',
  },
};
export default labels;
