export enum UserSteps {
  HomePage = 'HomePageViewed',
  MenuList = 'MenuListPageViewed',
  OnlyMenuRedirection = 'OnlyMenuRedirected',
  ProductList = 'ProductListPageViewed',
  FiltersApplied = 'FiltersApplied',
  ProductDetails = 'ProductDetailsPageViewed',
  AddedToCart = 'AddedToCart',
  OrderSubmitted = 'OrderSubmitted',
  OrderSuccessPage = 'OrderSuccessPageViewed',
  OrderErrorPage = 'OrderErrorPageViewed',
  OrderHistoryList = 'OrderHistoryListPageViewed',
  OrderHistoryDetails = 'OrderHistoryDetailsPageViewed',
  ShareModalOpened = 'ShareModalOpened',
  TransactionalFeedbackPopUpOpened = 'TransactionalFeedbackPopUpOpened',
  TransactionalFeedbackPopUpClosed = 'TransactionalFeedbackPopUpClosed',
  ProductAddedToFavorites = 'ProductAddedToFavorites',
  ProductRemovedFromFavorites = 'ProductRemovedFromFavorites',
  OpenedGlobalSearchModal = 'OpenedGlobalSearchModal',
  FacilityDetailsPageOpened = 'FacilityDetailsPageOpened',
  ReviewPageRedirectionTriggered = 'ReviewPageRedirectionTriggered',
  OrderPageRedirectionTriggered = 'OrderPageRedirectionTriggered',
  OpenedFacilityModal = 'OpenedFacilityModal',
  OpenedInfoModal = 'OpenedInfoModal',
  FreeCoffeePage = 'FreeCoffeePage',
  FreeCoffeeRedeemed = 'FreeCoffeeRedeemed',
  UserScannedQrCode = 'UserScannedQrCode',
  FreeCoffeeCodeScannedSuccessfully = 'FreeCoffeeCodeScannedSuccessfully',
}
