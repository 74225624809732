import { DeliveryOptionType } from '../types/cart.types';
import { FacilityMenu, FacilityMenuJson } from '../types/orderState.types';

export const toFacilityMenu = (menu: FacilityMenuJson): FacilityMenu => {
  return {
    ...menu,
    isScanAndGo:
      menu.fulfillmentTypes?.some((ft) => ft.type === DeliveryOptionType.ScanAndGo) || false,
  };
};
