import { SectionTileList } from './SectionList';
import { SimpleTileList } from './SimpleTileList';
import { TilesListProps } from './TilesList.types';

const TilesList = <T extends {}>({
  items = [],
  twoTilesColumns,
  longTileTextVisible,
  tilesStyle,
  className,
  itemRenderer,
  config,
  sections = [],
  sectionRefs,
  tileVariant,
}: TilesListProps<T>) => {
  if (!sections.length) {
    return (
      <SimpleTileList
        items={items}
        twoTilesColumns={twoTilesColumns}
        tilesStyle={tilesStyle}
        longTileTextVisible={longTileTextVisible}
        itemRenderer={itemRenderer}
        tileVariant={tileVariant}
        className={className}
      />
    );
  } else {
    return (
      <SectionTileList
        sections={sections}
        sectionRefs={sectionRefs}
        config={config}
        items={items}
        twoTilesColumns={twoTilesColumns}
        tilesStyle={tilesStyle}
        longTileTextVisible={longTileTextVisible}
        itemRenderer={itemRenderer}
        tileVariant={tileVariant}
        className={className}
      />
    );
  }
};

export default TilesList;
