import { isResponseUnsuccessful } from '../../../axios/axios.helper';
import { axiosClient } from '../../../axios/axiosClient';
import { globalRetailApiPath, shouldUseMockData } from '../../../config';
import mockResponse from '../mockResponse';
import { Suggestions } from '../types/orderState.types';

export const getProductSuggestionsQuery = async (
  input: {
    siteId: string;
    dateTime: string;
    menuId?: number;
    noOfSuggestionsToReturn?: number;
  },
  opts?: { useErrorBoundary?: boolean }
) => {
  if (shouldUseMockData) {
    return (await mockResponse.getProductSuggestions.json()) as Suggestions;
  }

  const url = `${globalRetailApiPath}/v1/menu/suggestions`;
  const response = await axiosClient.get<Suggestions>(url, {
    params: input,
    opts: { useErrorBoundary: opts?.useErrorBoundary },
  });

  if (isResponseUnsuccessful(response)) {
    throw new Error(`Error fetching suggestions`);
  }

  return response.data;
};
