const labels = {
  All: { 'Ref: Page title': 'Lieferanten' },
  SuppliersList: {
    'Ref: Supplier details': 'Siehe Einzelheiten',
    'Missing supplier list': 'Wir haben keinen Lieferanten gefunden.',
    'Try again later': 'Versuchen Sie es später noch einmal',
  },
  SupplierDetails: {
    'Address label': 'Die Anschrift:',
    'Website label': 'Webseite:',
    'Details label': 'Einzelheiten',
  },
};
export default labels;
