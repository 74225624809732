import classNames from 'classnames';
import { memo, useCallback, FunctionComponent } from 'react';

import { breakPointsInPixels } from '../../../../config';
import useIsRunningInTeams from '../../../../helpers/hooks/useIsRunningInTeams/useIsRunningInTeams';
import useContactsTranslation from '../../hooks/useContactsTranslation';
import ContactItemControl from '../ContactItemControl/ContactItemControl';

import { ContactItemProps } from './ContactItem.types';

import { SVGProps } from '@/assets/svgFactory';
import Link from '@/components/atoms/Link';
import useWindowSize from '@/helpers/hooks/useWindowSize';

import styles from './ContactItem.module.css';

const ContactItem = ({ title, items }: ContactItemProps) => {
  const { label } = useContactsTranslation('ContactList');
  const { width } = useWindowSize();
  const { isRunningInTeams } = useIsRunningInTeams();
  const contactPhoneComponent = useCallback(
    (
      Icon: FunctionComponent<SVGProps>,
      text: string,
      to: string,
      labelKey: string,
      external: boolean
    ) => {
      return external ? (
        <a href={to}>
          <ContactItemControl title={label(labelKey)} icon={Icon} text={text} />
        </a>
      ) : (
        <Link data-testid="contact-item" to={to}>
          <ContactItemControl title={label(labelKey)} icon={Icon} text={text} />
        </Link>
      );
    },
    [label]
  );

  const isLarger = width >= breakPointsInPixels.L;
  const remainGridCells = [...Array(3 - items.length).keys()].map((i) => <div key={i} />);

  return (
    <>
      <span className={styles.rowSeparator} />
      <h3
        className={classNames(styles.contactTitle, styles.area, 'bodySBold')}
        data-testid="contact-title"
      >
        {title}
      </h3>
      {!isLarger && remainGridCells}
      {items.map(({ icon: Icon, text, to, labelKey, external }) => (
        <span className={styles.area} key={labelKey}>
          {isRunningInTeams ? (
            <ContactItemControl title={label(labelKey)} icon={Icon} text={text} />
          ) : (
            contactPhoneComponent(Icon, text, to, labelKey, external!)
          )}
        </span>
      ))}
      {isLarger && remainGridCells}
    </>
  );
};

export default memo(ContactItem);
