const labels = {
  ReviewForm: {
    'Ref: Page title': 'Рассмотрение',
    'Ref: How was dining with us': 'Как ужинала с нами?',
    'Ref: Submit': 'Представлять на рассмотрение',
    'Ref: Filter range': 'Диапазон фильтров',
    'Ref: Some information is missing': 'Некоторая информация отсутствует',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Отправить анонимно',
    'Ref: Your email address': 'Ваш адрес электронной почты',
    'Ref: Email field placeholder': 'Введите ваш адрес электронной почты',
    'Ref: Invalid email format': 'Неверный формат электронной почты',
  },
  SuccessPage: {
    'Ref: Body':
      'Спасибо за завершение этого обзора, ваше время ценное, так как этот обзор поможет улучшить наши услуги.',
    'Ref: Go to Feedback': 'Перейти к обратной связи',
    'Ref: Go to home': 'Иди домой',
    'Ref: Go to Facilities': 'Перейти на объекты',
    'Ref: Go to Product Details': 'Перейти к деталям продукта',
    'Ref: Go to Facility Details': 'Перейти к деталям объекта',
  },
  FacilityPicker: {
    'Ref: Page title': 'Рассмотрение',
    'Ref: Search facility name': 'Название поиска',
  },
};
export default labels;
