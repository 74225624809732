import { useController, FieldValues } from 'react-hook-form';

import Input from './Input';
import { HookInputProps, InputProps } from './Input.types';

const HookInput = <TFieldValues extends FieldValues = FieldValues>({
  control,
  rules,
  defaultValue,
  'data-testid': dataTestId,
  ...props
}: HookInputProps<TFieldValues>) => {
  const otherProps: Partial<InputProps> = {};

  if (rules && 'required' in rules) {
    otherProps.required = true;

    // TODO requiredRule can be used here, but in order to do that, we need to change label on Input to some different name to avoid conflict with withLabel HOC which we need here so we don't prop drill everything
  }

  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name: props.name,
    control,
    rules,
    defaultValue,
  });

  if (error) {
    otherProps.hasError = true;

    if (error?.message) {
      otherProps.statusMessage = error.message.toString();
    }
  }

  return (
    <Input
      inputRef={ref}
      {...props}
      onInputChange={(val) => onChange(val)}
      onBlur={onBlur}
      {...otherProps}
      value={value}
      data-testid={`${dataTestId}-hook`}
    />
  );
};

export default HookInput;
