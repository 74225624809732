const labels = {
  All: {
    'Ref: Assembled': 'Ref: Assembled',
    'Ref: Submitted': 'Ref: Submitted',
    'Ref: Fulfilled': 'Ref: Fulfilled',
    'Ref: Completed': 'Ref: Completed',
    'Ref: Paid': 'Ref: Paid',
    'Ref: Approved': 'Ref: Approved',
    'Ref: Ready For Pickup': 'Ref: Ready For Pickup',
    'Ref: Delivered': 'Ref: Delivered',
    'Ref: Received': 'Ref: Received',
    'Ref: Rejected': 'Ref: Rejected',
    'Ref: Payment Error': 'Ref: Payment Error',
    'Ref: Pending Payment': 'Ref: Pending Payment',
    'Ref: Preparing': 'Ref: Preparing',
    'Ref: Refunded': 'Ref: Refunded',
    'Ref: Cancelled By User': 'Ref: Cancelled By User',
    'Ref: Refund Requested': 'Ref: Refund Requested',
    'Ref: Refund Failed': 'Ref: Refund Failed',
    'Ref: Payment Processing': 'Ref: Payment Processing',
    'Ref: Not Paid': 'Ref: Not Paid',
    'Ref: Total:': 'Ref: Total:',
    'Ref: Pickup': 'Ref: Pickup',
    'Ref: Eat-in': 'Ref: Eat-in',
    'Ref: Scan&Go': 'Ref: Scan&Go',
    'Ref: Fulfillment Type': 'Ref: Fulfillment Type',
    'Ref: Delivery': 'Ref: Delivery',
    'Ref: You have no future orders': 'Ref: You have no future orders',
    'Ref: You have no past orders': 'Ref: You have no past orders',
    'Ref: You have no orders': 'Ref: You have no orders',
    'Ref: All orders': 'Ref: All orders',
    'Ref: Not past orders': 'Ref: Not past orders',
    'Ref: Past orders': 'Ref: Past orders',
    'Ref: Vegan': 'Ref: Vegan',
    'Ref: Vegeterian': 'Ref: Vegeterian',
    'Ref: See item': 'Ref: See item',
    'Ref: Activated': 'Ref: Activated',
    'Ref: No suggestions': 'Ref: No suggestions',
    'Ref: Get your receipt': 'Ref: Get your receipt',
    'Ref: Print': 'Ref: Print',
    'Ref: Email': 'Ref: Email',
    'Ref: Receipt Modal message': 'Ref: Receipt Modal message',
    'Ref: Confirm': 'Ref: Confirm',
    'Ref: Your email address': 'Ref: Your email address',
    Suggestion: 'Suggestion',
    'Ref: Loyalty Reward': 'Ref: Loyalty Reward',
    'Ref: Equation for stamps per item': 'Ref: Equation for stamps per item',
    'Ref: Paid by employer': 'Ref: Paid by employer',
    'Ref: Paid by user': 'Ref: Paid by user',
  },
  ProductsList: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Facility details': 'Ref: Facility details',
    'Ref: Search products list': 'Ref: Search products list',
    'Ref: filter label: menus': 'Ref: filter label: menus',
    'Ref: filter label: moment': 'Ref: filter label: moment',
    'Ref: filter label: category': 'Ref: filter label: category',
    'Ref: all': 'Ref: all',
    'Ref: today': 'Ref: today',
    'Ref: tomorrow': 'Ref: tomorrow',
    'Ref: see more': 'Ref: see more',
    'Ref: see less': 'Ref: see less',
    'Ref: reset': 'Ref: reset',
    'Ref: View in cart': 'Ref: View in cart',
    'Ref: You have changed location': 'Ref: You have changed location',
    'Ref: You are now at': 'Ref: You are now at',
    'Ref: Menu card': 'Ref: Menu card',
    'Ref: Table': 'Ref: Table',
    'Service unavailable': 'Service unavailable',
    'Ref: Ordering unavailable': 'Ref: Ordering unavailable',
    'Ref: Filters': 'Ref: Filters',
  },
  ProductScanner: {
    'Ref: cart': 'Ref: cart',
    'Ref: Product Scanned': 'Ref: Product Scanned',
    'Ref: Scan or adjust': 'Ref: Scan or adjust',
    'Ref: Product Not Matched': 'Ref: Product Not Matched',
    'Ref: Scan article code': 'Ref: Scan article code',
    'Ref: Native error product cannot be added to cart':
      'Ref: Native error product cannot be added to cart',
    'Ref: product scanner placeholder': 'Ref: product scanner placeholder',
  },
  Order: { 'Ref: Page title': 'Ref: Page title' },
  MenuSelector: {
    'Ref: Menu Page title': 'Ref: Menu Page title',
    'Ref: today': 'Ref: today',
    'Ref: tomorrow': 'Ref: tomorrow',
    'Ref: Search products list': 'Ref: Search products list',
    'Ref: You have changed location': 'Ref: You have changed location',
    'Ref: You are now at': 'Ref: You are now at',
  },
  ProductDetails: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Serving Size': 'Ref: Serving Size',
    'Ref: Nutrition': 'Ref: Nutrition',
    'Ref: Nutrition adults': 'Ref: Nutrition adults',
    'Ref: Nutrition Calories': 'Ref: Nutrition Calories',
    'Ref: Nutrition Calcium': 'Ref: Nutrition Calcium',
    'Ref: Nutrition Fat': 'Ref: Nutrition Fat',
    'Ref: Nutrition Saturated Fat': 'Ref: Nutrition Saturated Fat',
    'Ref: Nutrition Carbohydrates': 'Ref: Nutrition Carbohydrates',
    'Ref: Nutrition Sugar': 'Ref: Nutrition Sugar',
    'Ref: Nutrition Protein': 'Ref: Nutrition Protein',
    'Ref: Nutrition Sodium': 'Ref: Nutrition Sodium',
    'Ref: Nutrition cholesterol': 'Ref: Nutrition cholesterol',
    'Ref: Nutrition dietaryFiber': 'Ref: Nutrition dietaryFiber',
    'Ref: Nutrition iron': 'Ref: Nutrition iron',
    'Ref: Nutrition potassium': 'Ref: Nutrition potassium',
    'Ref: Nutrition vitaminA': 'Ref: Nutrition vitaminA',
    'Ref: Nutrition vitaminC': 'Ref: Nutrition vitaminC',
    'Ref: Additives': 'Ref: Additives',
    'Ref: Emission': 'Ref: Emission',
    'Ref: This product generates': 'Ref: This product generates',
    'Ref: of CO2': 'Ref: of CO2',
    'Ref: emissions, which equals a': 'Ref: emissions, which equals a',
    'Ref: car trip': 'Ref: car trip',
    'Ref: Ingredients': 'Ref: Ingredients',
    'Ref: Allergens': 'Ref: Allergens',
    'Ref: Always there': 'Ref: Always there',
    'Ref: Allergens selected': 'Ref: Allergens selected',
    'Ref: minimum required': 'Ref: minimum required',
    'Ref: you have to add at least': 'Ref: you have to add at least',
    'Ref: ingredients': 'Ref: ingredients',
    'Ref: Ingredient': 'Ref: Ingredient',
    'Ref: Free': 'Ref: Free',
    'Ref: Based on customization': 'Ref: Based on customization',
    'Ref: Additives eggs': 'Ref: Additives eggs',
    'Ref: Additives fish': 'Ref: Additives fish',
    'Ref: Additives milk': 'Ref: Additives milk',
    'Ref: Additives nuts': 'Ref: Additives nuts',
    'Ref: Additives wheat': 'Ref: Additives wheat',
    'Ref: Additives peanuts': 'Ref: Additives peanuts',
    'Ref: Additives soy beans': 'Ref: Additives soy beans',
    'Ref: Additives sesame seeds': 'Ref: Additives sesame seeds',
    'Ref: Review': 'Ref: Review',
    'Ref: Per portion': 'Ref: Per portion',
    'Ref: Per 100g': 'Ref: Per 100g',
    'Ref: Allergen selected': 'Ref: Allergen selected',
    'Ref: Fitbit Log': 'Ref: Fitbit Log',
    'Ref: age verification required on delivery': 'Ref: age verification required on delivery',
    'Ref: alcohol content notification': 'Ref: alcohol content notification',
    'Ref: default': 'Ref: default',
    'Ref: Loyalty Card Title Stamp': 'Ref: Loyalty Card Title Stamp',
    'Ref: Loyalty Card Description': 'Ref: Loyalty Card Description',
    'Ref: About Loyalty stamps': 'Ref: About Loyalty stamps',
    'Ref: Loyalty Modal Description': 'Ref: Loyalty Modal Description',
    'Ref: and': 'Ref: and',
    'Ref: Stamp': 'Ref: Stamp',
    'Ref: Stamps': 'Ref: Stamps',
    'Ref: Program': 'Ref: Program',
    'Ref: Programs': 'Ref: Programs',
    'Ref: Loyalty Stamps': 'Ref: Loyalty Stamps',
    'Ref: See Loyalty Stamps': 'Ref: See Loyalty Stamps',
    Favorite: 'Favorite',
    'Ref: In favorites': 'Ref: In favorites',
    'Ref: Not in favorites': 'Ref: Not in favorites',
    'Ref: Added to favorites': 'Ref: Added to favorites',
    'Ref: Removed from favorites': 'Ref: Removed from favorites',
    'Ref: From': 'Ref: From',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Ref: change in cart',
    'Ref: Popup header for customizable item qty update':
      'Ref: Popup header for customizable item qty update',
    'Ref: add new version': 'Ref: add new version',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Ref: Popup body for customizable item qty having to be changed from the cart',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Ref: Popup body for customizable item qty having to be changed from the cart or new version created',
    'Ref: another context body': 'Ref: another context body',
    'Ref: cancel': 'Ref: cancel',
    'Ref: add to cart': 'Ref: add to cart',
    'Ref: view in cart': 'Ref: view in cart',
    'Ref: Products in cart': 'Ref: Products in cart',
    'Ref: Customize': 'Ref: Customize',
    'Ref: Add': 'Ref: Add',
    'Ref: Free': 'Ref: Free',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Ref: cancel',
    'Ref: notice': 'Ref: notice',
    'Ref: clear cart': 'Ref: clear cart',
    'Ref: Cart addition error message': 'Ref: Cart addition error message',
  },
  Cart: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: The cart is empty': 'Ref: The cart is empty',
    'Ref: Clear cart': 'Ref: Clear cart',
    'Ref: Continue shopping': 'Ref: Continue shopping',
    'Ref: Start shopping': 'Ref: Start shopping',
    'Ref: Today': 'Ref: Today',
    'Ref: Tomorrow': 'Ref: Tomorrow',
    'Ref: Tax:': 'Ref: Tax:',
    'Ref: Promotion:': 'Ref: Promotion:',
    'Ref: Total without promotion:': 'Ref: Total without promotion:',
    'Ref: Delivery:': 'Ref: Delivery:',
    'Ref: Pickup informations': 'Ref: Pickup informations',
    'Ref: Location': 'Ref: Location',
    'Ref: Time': 'Ref: Time',
    'Ref: Phone': 'Ref: Phone',
    'Ref: Instructions': 'Ref: Instructions',
    'Ref: Please select': 'Ref: Please select',
    'Ref: Enter special requests': 'Ref: Enter special requests',
    'Ref: notice': 'Ref: notice',
    'Ref: Product is no more available': 'Ref: Product is no more available',
    'Ref: ok': 'Ref: ok',
    'Ref: Validate': 'Ref: Validate',
    'Ref: Pay now': 'Ref: Pay now',
    'Ref: Charge to my department': 'Ref: Charge to my department',
    'Ref: Pay on pickup': 'Ref: Pay on pickup',
    'Ref: Accept terms of sale prefix': 'Ref: Accept terms of sale prefix',
    'Ref: Accept terms of sale': 'Ref: Accept terms of sale',
    'Ref: Continue': 'Ref: Continue',
    'Ref: Please login or sign up to place an order':
      'Ref: Please login or sign up to place an order',
    'Ref: Accept terms of sale suffix': 'Ref: Accept terms of sale suffix',
    'Ref: Payment modality': 'Ref: Payment modality',
    'Ref: Other information': 'Ref: Other information',
    'Ref: Eat-in': 'Ref: Eat-in',
    'Ref: Pickup': 'Ref: Pickup',
    'Ref: Table': 'Ref: Table',
    'Ref: Required fields': 'Ref: Required fields',
    'Service unavailable': 'Service unavailable',
    'Ref: Cannot order': 'Ref: Cannot order',
    'Ref: Order Unavailable': 'Ref: Order Unavailable',
    'Ref: No Availability': 'Ref: No Availability',
    'Ref: Continue on your device': 'Ref: Continue on your device',
    'Ref: Loyalty Card Title Stamp': 'Ref: Loyalty Card Title Stamp',
    'Ref: Loyalty Card Description': 'Ref: Loyalty Card Description',
    'Ref: About Loyalty stamps': 'Ref: About Loyalty stamps',
    'Ref: Loyalty Modal List Description': 'Ref: Loyalty Modal List Description',
    'Ref: Loyalty Modal List Item': 'Ref: Loyalty Modal List Item',
    'Ref: Stamp': 'Ref: Stamp',
    'Ref: Stamps': 'Ref: Stamps',
    'Ref: Program': 'Ref: Program',
    'Ref: Programs': 'Ref: Programs',
    'Ref: Loyalty Stamps': 'Ref: Loyalty Stamps',
    'Ref: See Loyalty Stamps': 'Ref: See Loyalty Stamps',
    'Ref: Loyalty Info': 'Ref: Loyalty Info',
    'Ref: Email address': 'Ref: Email address',
    'Ref: and': 'Ref: and',
    'Ref: Order minimum amount': 'Ref: Order minimum amount',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Ref: Continue on your device',
    'Ref: Scan QR to continue': 'Ref: Scan QR to continue',
  },
  OrderHistory: { 'Ref: Orders': 'Ref: Orders', 'Ref: Page Title': 'Ref: Page Title' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Ref: Unit price',
    'Ref: Download': 'Ref: Download',
    'Ref: Download error': 'Ref: Download error',
    'Ref: Order details title': 'Ref: Order details title',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Ref: Order confirmed',
    'Ref: Text for pickup': 'Ref: Text for pickup',
    'Ref: Success Order Title': 'Ref: Success Order Title',
    'Ref: Success Order Description': 'Ref: Success Order Description',
    'Ref: Success Order ID': 'Ref: Success Order ID',
    'Ref: Success Order Action': 'Ref: Success Order Action',
    'Ref: Text for dinein': 'Ref: Text for dinein',
    'Ref: Text for scan&go': 'Ref: Text for scan&go',
    'Ref: Text for delivery': 'Ref: Text for delivery',
    'See details': 'See details',
    'Go to home': 'Go to home',
  },
  FailOrder: {
    'Go to home': 'Go to home',
    'Ref: Kiosk Fail Title': 'Ref: Kiosk Fail Title',
    'Ref: Kiosk Fail Description': 'Ref: Kiosk Fail Description',
    'Ref: Kiosk Fail Back': 'Ref: Fail Order Back',
    'Ref: Kiosk Fail Action': 'Ref: Kiosk Fail Action',
    'Ref: Error in cart validation': 'Ref: Error in cart validation',
    'Facility contact': 'Facility contact',
    'Ref: Ordering failed': 'Ref: Ordering failed',
    'Ref: Order confirmed': 'Ref: Order confirmed',
  },
  TableCheckIn: {
    'Welcome at': 'Welcome at',
    Table: 'Table',
    'Ref: list title': 'Ref: list title',
    'See the menu': 'See the menu',
    'Table Check-in': 'Table Check-in',
    'Ref #': 'Ref #',
    Checked: 'Checked',
    'Check in': 'Check in',
    'See details': 'See details',
    'Ref: You have changed location': 'Ref: You have changed location',
    'Ref: You are now at': 'Ref: You are now at',
    'Order number': 'Order number',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Ref: Promotion applied title',
    'Ref: Promotion applied description': 'Ref: Promotion applied description',
    'Ref: Promotion deleted title': 'Ref: Promotion deleted title',
    'Ref: Promotion deleted description': 'Ref: Promotion deleted description',
  },
  MenuTile: { 'Ref: Scan&Go': 'Ref: Scan&Go', 'Ref: View only': 'Ref: View only' },
  ProductTile: {
    'Ref: Allergens': 'Ref: Allergens',
    'Ref: Presence of allergen': 'Ref: Presence of allergen',
    'Ref: Scan': 'Ref: Scan',
    'Ref: From': 'Ref: From',
    'Ref: Already in product favorite list. Click to remove it.':
      'Ref: Already in product favorite list. Click to remove it.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Ref: Currently not added to product favorite list. Click to add it.',
    'Ref: Free': 'Ref: Free',
  },
  OrderSummary: {
    'Ref: item': 'Ref: item',
    'Ref: items': 'Ref: items',
    'Ref: Clear': 'Ref: Clear',
    'Ref: Cart': 'Ref: Cart',
    'Ref: Subtotal': 'Ref: Subtotal',
    'Ref: Total': 'Ref: Total',
    'Ref: Tax': 'Ref: Tax',
    'Ref: Promotion': 'Ref: Promotion',
    'Ref: Total without promotion': 'Ref: Total without promotion',
    'Ref: Number of product in cart': 'Ref: Number of product in cart',
    'Ref: Delivery': 'Ref: Delivery',
  },
  CartItemTile: { 'Ref: Vegan': 'Ref: Vegan', 'Ref: Vegeterian': 'Ref: Vegeterian' },
  OrderInfoCard: {
    'Ref: Header': 'Ref: Header',
    'Ref: from': 'Ref: from',
    'Ref: location': 'Ref: location',
    'Ref: Phone': 'Ref: Phone',
    'Ref: Instructions': 'Ref: Instructions',
    'Ref: Order type': 'Ref: Order type',
    'Ref: Pickup': 'Ref: Pickup',
    'Ref: Eat-in': 'Ref: Eat-in',
    'Ref: Scan&Go': 'Ref: Scan&Go',
  },
  ReorderWidget: { 'Ref: No items': 'Ref: No items', 'Ref: Title': 'Ref: Title' },
  MenusWidget: { 'Ref: no menu': 'Ref: no menu', 'Ref: Modal title': 'Ref: Modal title' },
  PromotionInfo: { 'Ref: Discounts available': 'Ref: Discounts available' },
  PromotionInfoItem: { 'Ref: Activate': 'Ref: Activate' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Ref: Your Orders',
    'Ref: Widget Title': 'Ref: Widget Title',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Ref: Modal title',
    'Ref: Any menu': 'Ref: Any menu',
    Other: 'Other',
    'Ref: Suggestions calendar button': 'Ref: Suggestions calendar button',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Ref: Serving Size',
    'Ref: Nutrition': 'Ref: Nutrition',
    'Ref: Nutrition adults': 'Ref: Nutrition adults',
    'Ref: Nutrition Calories': 'Ref: Nutrition Calories',
    'Ref: Nutrition Calcium': 'Ref: Nutrition Calcium',
    'Ref: Nutrition Fat': 'Ref: Nutrition Fat',
    'Ref: Nutrition Saturated Fat': 'Ref: Nutrition Saturated Fat',
    'Ref: Nutrition Carbohydrates': 'Ref: Nutrition Carbohydrates',
    'Ref: Nutrition Sugar': 'Ref: Nutrition Sugar',
    'Ref: Nutrition Protein': 'Ref: Nutrition Protein',
    'Ref: Nutrition Sodium': 'Ref: Nutrition Sodium',
    'Ref: Nutrition cholesterol': 'Ref: Nutrition cholesterol',
    'Ref: Nutrition dietaryFiber': 'Ref: Nutrition dietaryFiber',
    'Ref: Nutrition iron': 'Ref: Nutrition iron',
    'Ref: Nutrition potassium': 'Ref: Nutrition potassium',
    'Ref: Nutrition vitaminA': 'Ref: Nutrition vitaminA',
    'Ref: Nutrition vitaminC': 'Ref: Nutrition vitaminC',
    'Ref: Ingredients': 'Ref: Ingredients',
    'Ref: Allergens': 'Ref: Allergens',
    'Ref: Always there': 'Ref: Always there',
    'Ref: Allergens selected': 'Ref: Allergens selected',
    'Ref: minimum required': 'Ref: minimum required',
    'Ref: ingredients': 'Ref: ingredients',
    'Ref: Ingredient': 'Ref: Ingredient',
    'Ref: Free': 'Ref: Free',
    'Ref: Based on customization': 'Ref: Based on customization',
    'Ref: Additives eggs': 'Ref: Additives eggs',
    'Ref: Additives fish': 'Ref: Additives fish',
    'Ref: Additives milk': 'Ref: Additives milk',
    'Ref: Additives nuts': 'Ref: Additives nuts',
    'Ref: Additives wheat': 'Ref: Additives wheat',
    'Ref: Additives peanuts': 'Ref: Additives peanuts',
    'Ref: Additives soy beans': 'Ref: Additives soy beans',
    'Ref: Additives sesame seeds': 'Ref: Additives sesame seeds',
    'Ref: Review': 'Ref: Review',
    'Ref: Per portion': 'Ref: Per portion',
    'Ref: Per 100g': 'Ref: Per 100g',
    'Ref: Modifier item number of free items': 'Ref: Modifier item number of free items',
    'Ref: Number of free items': 'Ref: Number of free items',
    'Ref: Allergen selected': 'Ref: Allergen selected',
    'Ref: Nutritional info': 'Ref: Allergen selected',
    'Ref: Info': 'Ref: Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Ref: Title' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Ref: Choose your job',
    'Ref: Validate': 'Ref: Validate',
    'Ref: Pricing Tiers dialog message': 'Ref: Pricing Tiers dialog message',
    'Ref: Please select your job in the company': 'Ref: Please select your job in the company',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Ref: Pickup',
    'Ref: Eat-in': 'Ref: Eat-in',
    'Ref: Scan&Go': 'Ref: Scan&Go',
    'Ref: Delivery': 'Ref: Delivery',
    'Ref: Total': 'Ref: Total',
    'Ref: How was facility name service': 'Ref: How was facility name service',
    'Ref: How do you rate the service': 'Ref: How do you rate the service',
  },
};
export default labels;
