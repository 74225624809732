import { useMemo } from 'react';

import { FacilitySearchResultsProps } from './FacilitySearchResult.types';

import { TileSkeleton } from '@/components/molecules/Tile';
import FacilityTile from '@/modules/Facilities/components/FacilityTile/FacilityTile';
import { mapToFacilityItems } from '@/modules/Facilities/helpers/facilities.helpers';
import { FacilityItem } from '@/modules/Facilities/types/types';
import { useFacilityMenuImage } from '@/modules/Order/hooks/useFacilityMenuImage/useFacilityMenuImage';
import { useGetMappedFacilitiesQuery } from '@/modules/Review/api';

export const FacilitySearchResults = ({
  facilities,
  siteId,
  label,
  focusedSearchResultIndex,
}: FacilitySearchResultsProps) => {
  const facilityImagesInfo = useMemo(
    () =>
      facilities.map((facility) => ({
        facilityId: facility.id,
        imageId: facility.images[0],
      })),
    [facilities]
  );

  const { getFacilityImage, isLoading: isLoadingImages } = useFacilityMenuImage(facilityImagesInfo);
  const facilityItems: FacilityItem[] = useMemo(
    () => mapToFacilityItems(facilities, getFacilityImage, label),
    [facilities, getFacilityImage, label]
  );

  const { data: mappedFacilites, isLoading: isLoadingMappedFacilities } =
    useGetMappedFacilitiesQuery(siteId);

  return (
    <>
      {facilityItems.map((item, index) => {
        return (
          <FacilityTile
            key={item.id}
            id={item.id}
            title={item.title}
            data-testid={item['data-testid']}
            facilityType={item.facilityType}
            imgInfo={item.imgInfo}
            reviewableFacilities={mappedFacilites}
            label={label}
            externalUrl={item.externalUrl}
            focusOnRender={index === focusedSearchResultIndex}
          />
        );
      })}
      {(isLoadingMappedFacilities || isLoadingImages) && (
        <>
          {[...Array(3).keys()].map((key) => (
            <TileSkeleton
              key={key}
              withoutActions
              withoutChildren={false}
              className={'mb-M'}
              data-testid={`skeleton-placeholder-${key}`}
            />
          ))}
        </>
      )}
    </>
  );
};
