import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { serviceTypes, modulesConfig } from '../config';

export const cacheVersion = '0.2';
export const moduleId = 'Order';
export const menuModuleId = 'Menu';
export const modulePath = serviceTypes[modulesConfig.Order.serviceType].path;
export const menuModulePath = serviceTypes[modulesConfig.Menu.serviceType].path;
export const orderMenuPrefix = modulePath + '/menus/';
export const redirectMenuPrefix = modulePath + '/redirect/';
export const viewableRedirectMenuPrefix = menuModulePath + '/redirect/';
export const waitBetweenScansInMs = 2000;

export const pagePaths = {
  Module: modulePath,
  MenuModule: menuModulePath,
  TableCheckInOutlet: modulePath + '/check_in/outlet/:outletId',
  MenuListWithOutlet: orderMenuPrefix + 'outlet/:outletId',
  MenuListWithLocation: orderMenuPrefix + 'location/:locationId',
  MenuListWithOutletAndLocation: orderMenuPrefix + ':locationId/:outletId',
  MenuWithBarcode: modulePath + '/scan/:locationId/:menuId/:barcode',
  MenuRedirection: redirectMenuPrefix + ':menuId/:date',
  ViewableMenuRedirection: viewableRedirectMenuPrefix + ':menuId/:date',

  ProductsList: modulePath + '/:facilityId/:menuId/:date/products',
  MenuProductsList: menuModulePath + '/:facilityId/:menuId/:date/products',
  ProductsListWithBarcode: modulePath + '/:facilityId/:menuId/:date/:barcode/products',
  ProductDetails: modulePath + '/product/:facilityId/:menuId/:date/:id/:cartItem',
  ProductDetailsRedirection: modulePath + '/product/:facilityId/:menuId/:date/:id/:cartItem',
  MenuProductDetails: menuModulePath + '/product/:facilityId/:menuId/:date/:id/:cartItem',
  RetrievedCartSession: modulePath + '/cart/:siteId/:trackingId',
  Cart: modulePath + '/cart',
  OrderHistory: modulePath + '/history',
  OrderHistoryDetails: modulePath + '/history/:id',
  OrderSuccess: modulePath + '/success',
  OrderFail: modulePath + '/fail',
  Checkout: modulePath + '/checkout/:id/:callbackType',
};

export const defaultValues = {
  maxProductQuantity: 10,
  maxModifierGroupQuantity: 9,
  maxModifierQuantity: 99,
  minCalories: 0,
  maxCalories: 1000,
  suggestionsCountdownSeconds: 10,
  maxSuggestionsDesktop: 6,
  maxSuggestionsMobile: 3,
};

export const emissionsCarTripFactor = 2.32;
export const selectedMenuFiltersCacheKey = `${moduleId}_menuSelector_filters`;
export const selectedProductListFiltersCacheKey = `${moduleId}_productList_filters`;
export const selectedAllergensCacheKey = `selected_allergens`;
export const cartCacheKey = `${moduleId}_cart`;
export const menuVisitCacheKey = `menu_visits`;
export const suggestionWidgetDateCacheKey = `suggestionWidget_selected_date`;
export const suggestionWidgetMenuCacheKey = `suggestionWidget_selected_menu`;

export const kioskHub = {
  reconnectIntervalMs: 5000,
  hubName: 'kiosksession',
  joinFacilityNotificationsHubMethod: 'joinfacilitynotifications',
  closeSessionHubMethod: 'closesession',
};

export const orderConfig = () => {
  const configuration = {
    [APP_NAME.BITEKIOSK]: {
      isDateFilteringEnabled: false,
      isMenuRedirectionEnabled: true,
      isNavbarEnabled: false,
      isResetSessionEnabled: true,
      isCartDayPartLabelShown: false,
      isCartPhoneInputEnabled: false,
      isCartDeliveryEnabled: false,
      isCartPaymentFormEnabled: false,
      isCartButtonValidateLabelEnabled: true,
      areSuggestionsEnabled: false,
      isTranslationWidgetShownInTitleBar: true,
      isResetSessionWidgetShownInTitleBar: true,
      isKioskTitleBar: true,
      arePromotionsEnabled: true,
    },
    [APP_NAME.MYVILLAGE]: {
      isDateFilteringEnabled: true,
      isMenuRedirectionEnabled: false,
      isNavbarEnabled: true,
      isResetSessionEnabled: false,
      isCartDayPartLabelShown: true,
      isCartPhoneInputEnabled: true,
      isCartDeliveryEnabled: true,
      isCartPaymentFormEnabled: true,
      isCartButtonValidateLabelEnabled: false,
      areSuggestionsEnabled: true,
      isTranslationWidgetShownInTitleBar: false,
      isResetSessionWidgetShownInTitleBar: false,
      isKioskTitleBar: false,
      arePromotionsEnabled: true,
    },
    default: {
      isDateFilteringEnabled: true,
      isMenuRedirectionEnabled: false,
      isNavbarEnabled: true,
      isResetSessionEnabled: false,
      isCartDayPartLabelShown: true,
      isCartPhoneInputEnabled: true,
      isCartDeliveryEnabled: true,
      isCartPaymentFormEnabled: true,
      isCartButtonValidateLabelEnabled: false,
      areSuggestionsEnabled: true,
      isTranslationWidgetShownInTitleBar: false,
      isResetSessionWidgetShownInTitleBar: false,
      isKioskTitleBar: false,
      arePromotionsEnabled: true,
    },
  };

  return getAppSpecificData(configuration);
};
