import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

type AutoFocusContainerProps = {
  children: React.ReactNode;
  inheritStyle?: string;
};

const AutoFocusContainer = ({ children, inheritStyle }: AutoFocusContainerProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    containerRef?.current?.focus();
  }, []);

  return (
    <div tabIndex={-1} ref={containerRef} className={classNames(inheritStyle)}>
      {children}
    </div>
  );
};

export default AutoFocusContainer;
