import { renderHook } from '@testing-library/react';
import { useSelector } from 'react-redux';

import { appInsights } from '../../../../appInsights';
import { PageType } from '../../types/menuCartActions.types';

import useProductInsightsLogging from './useProductInsightsLogging';

// Mock dependencies
jest.mock('react-redux', () => ({
  useSelector: jest.fn(),
}));

jest.mock('../../../../appInsights', () => ({
  appInsights: {
    trackTrace: jest.fn(),
  },
}));

jest.spyOn(console, 'error').mockImplementation(() => {});

const stateMock = {
  Core: {
    context: {
      site: {
        id: 'xxx-xxx-xxx',
        name: 'Test Site',
      },
    },
  },
  Order: {
    menus: [
      {
        id: 1,
        facilityId: 'xxx-xxx-xxx',
        name: 'Test Menu',
        menuItems: [
          {
            menuItemId: 1,
            name: 'Test Product',
            productPortions: [
              {
                uomId: 1,
                portion: 'Test Portion',
                price: 10,
              },
            ],
            section: 'Test Category',
            genericCategory: 'Test Category',
          },
        ],
      },
    ],
  },
  Facilities: {
    data: {
      'xxx-xxx-xxx': {
        title: 'Test Facility',
      },
    },
  },
};

describe('useProductInsightsLogging', () => {
  beforeEach(() => {
    (useSelector as jest.Mock).mockImplementation((selector) => selector(stateMock));
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  it('should log product added to cart', async () => {
    const { result } = renderHook(() => useProductInsightsLogging());
    const menuId = 1;
    const uomId = 1;
    const pageType: PageType = PageType.cart;

    await result.current.logAddingProductToCart(menuId, uomId, pageType);

    expect(appInsights?.trackTrace).toHaveBeenCalledWith(
      { message: 'Product added to cart' },
      {
        event: 'ProductAddedToCart',
        siteId: 'xxx-xxx-xxx',
        siteName: 'Test Site',
        facilityId: 'xxx-xxx-xxx',
        facilityName: 'Test Facility',
        menuId: '1',
        menuName: 'Test Menu',
        productId: '1',
        uomId: '1',
        productName: 'Test Product',
        portionName: 'Test Portion',
        category: 'Test Category',
        genericCategory: 'Test Category',
        price: '10',
        pageType: 'cart',
        isSuggestions: false,
        isMobile: false,
      }
    );
  });

  it('should not log product added to cart if menu is not found', () => {
    const { result } = renderHook(() => useProductInsightsLogging());
    const menuId = -1;
    const uomId = 1;
    const pageType: PageType = PageType.cart;

    result.current.logAddingProductToCart(menuId, uomId, pageType);

    expect(appInsights?.trackTrace).not.toHaveBeenCalled();
  });
});
