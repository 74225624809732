import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { useReviewTranslation } from '../../hooks/useReviewTranslation';
import { REDIRECTED_PAGES } from '../../types/reviewForm.types';

import NoticePage from '@/components/templates/NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const SuccessPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { label } = useReviewTranslation(__filename);
  const queryParams = new URLSearchParams(location.search);
  const refererUrl = queryParams.get('referer');
  const redirectedFrom = queryParams.get('redirectedFrom');

  const getButtonLabel = () => {
    switch (redirectedFrom) {
      case REDIRECTED_PAGES.FEEDBACK:
        return label('Ref: Go to Feedback');
      case REDIRECTED_PAGES.FACILITIES:
        return label('Ref: Go to Facilities');
      case REDIRECTED_PAGES.FACILITY_DETAILS:
        return label('Ref: Go to Facility Details');
      case REDIRECTED_PAGES.PRODUCT_DETAILS:
        return label('Ref: Go to Product Details');
      default:
        return '';
    }
  };

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('thank you', { textTransform: 'capitalize' })}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Ref: Go to home'),
          action: () => history.push('/'),
        },
        {
          label: getButtonLabel(),
          action: () => history.push(refererUrl || '/'),
        },
      ]}
    />
  );
};

export default SuccessPage;
