import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { resetLanguage } from '../../../Core/actions';
import { pagePaths } from '../../../Core/config';
import { cleanCart } from '../../actions';

export const resetFilters = (siteId: string | null = null) => {
  localStorage.removeItem('selected_allergens');

  const filtersCacheKey =
    siteId === null ? 'Order_productList_filters' : `Order_productList_filters_${siteId}`;

  Object.keys(localStorage)
    .filter((x) => x.startsWith(filtersCacheKey))
    .forEach((x) => localStorage.removeItem(x));
};

const useResetKioskSession = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const resetKioskSession = useCallback(async () => {
    resetFilters();
    dispatch(cleanCart());
    dispatch(resetLanguage());

    history.push(pagePaths.Welcome);
  }, [dispatch, history]);

  return [resetKioskSession];
};

export default useResetKioskSession;
