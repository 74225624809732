const labels = {
  All: { 'Ref: Page title': 'Dostawcy' },
  SuppliersList: {
    'Ref: Supplier details': 'Zobacz szczegóły',
    'Missing supplier list': 'Nie znaleźliśmy żadnego dostawcy.',
    'Try again later': 'Spróbuj ponownie później',
  },
  SupplierDetails: {
    'Address label': 'Adres:',
    'Website label': 'Strona internetowa:',
    'Details label': 'Szczegóły',
  },
};
export default labels;
