import { useState } from 'react';
import { useSelector } from 'react-redux';

import { searchFacilities, searchMenus, searchProducts } from '../../helpers/search.helper';
import { useGetFacilitiesForSearch } from '../useGetFacilitiesForSearch/useGetFacilitiesForSearch';
import { useGetMenusForSearch } from '../useGetMenusForSearch/useGetMenusForSearch';

import { SearchResultContainer } from './useGlobalSearchResults.types';

import { State } from '@/types/state.types';

export const useGlobalSearchResults = () => {
  const siteId = useSelector((state: State) => state.Core.context.site?.id);
  const { menus, isLoading: isLoadingMenus } = useGetMenusForSearch(siteId);
  const { facilities, isLoading: isLoadingFacilities } = useGetFacilitiesForSearch(siteId);

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const [searchResults, setSearchResults] =
    useState<SearchResultContainer[] | undefined>(undefined);

  const clearSearchResults = () => setSearchResults(undefined);

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    if (!searchTerm || searchTerm.length < 3) {
      return setSearchResults(undefined);
    }
    const currentSearch: SearchResultContainer[] = [];
    currentSearch.push(searchProducts(menus, searchTerm));
    currentSearch.push(searchMenus(menus, searchTerm));
    currentSearch.push(searchFacilities(facilities, searchTerm));

    const currentSearchWithResults = currentSearch.filter((x) => x.results.length > 0);
    setSearchResults(currentSearchWithResults);
  };

  return {
    handleSearchTermChange,
    clearSearchResults,
    searchResults,
    isLoading: isLoadingMenus || isLoadingFacilities,
    searchTerm,
  };
};
