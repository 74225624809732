import { SeverityLevel } from '@microsoft/applicationinsights-common';

import { appInsights } from '../../../appInsights';
import { DeliveryOptionType } from '../types/cart.types';
import { OrderInfo, OrderInfoListItem } from '../types/order.summary.types';

export const getOrderInfo = (label: Function, orderInfo: OrderInfo) => {
  const result: OrderInfoListItem[] = [
    {
      id: 'facilityName',
      label: label('Ref: from', {
        textTransform: 'capitalize',
      }),
      value: orderInfo.facilityName ?? '',
      'data-testid': 'order-info-facility-name',
    },
  ];

  if (orderInfo.deliveryOptionType !== DeliveryOptionType.ScanAndGo) {
    result.push({
      id: 'location',
      label: label('Ref: location', {
        textTransform: 'capitalize',
      }),
      value: orderInfo?.pickupLocation ?? '',
      'data-testid': 'order-info-location',
    });
  }

  result.push({
    id: 'orderType',
    label: label('Ref: Order type', { textTransform: 'capitalize' }),
    value: label(getFulfillmentTypeLabel(orderInfo.deliveryOptionType, orderInfo.orderId)),
    'data-testid': 'order-info-type',
  });

  if (orderInfo.phoneNumber) {
    result.push({
      id: 'phone',
      label: label('Ref: Phone', {
        textTransform: 'capitalize',
      }),
      value: orderInfo.phoneNumber,
      'data-testid': 'order-info-phone',
    });
  }

  if (orderInfo.instructions) {
    result.push({
      id: 'instructions',
      label: label('Ref: Instructions', {
        textTransform: 'capitalize',
      }),
      value: orderInfo.instructions,
      'data-testid': 'order-info-instructions',
    });
  }

  return result;
};

export const getFulfillmentTypeLabel = (
  deliveryOption: DeliveryOptionType,
  orderId: number = 0
) => {
  switch (deliveryOption) {
    case DeliveryOptionType.PickupOption:
      return 'Ref: Pickup';
    case DeliveryOptionType.DineIn:
      return 'Ref: Eat-in';
    case DeliveryOptionType.ScanAndGo:
      return 'Ref: Scan&Go';
    case DeliveryOptionType.Delivery:
      return 'Ref: Delivery';
    default:
      appInsights?.trackException({
        severityLevel: SeverityLevel.Warning,
        properties: {
          message: `deliveryOption not supported, value: ${deliveryOption}`,
          orderId: orderId,
        },
      });
      return '';
  }
};
