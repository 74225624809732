import { getByText, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';
import { act } from 'react';
import { useParams } from 'react-router';

import { orderConfig } from '../config';
import { getAllergensFromCache } from '../helpers/allergens.helper';
import {
  isFFAppSharingEnabled,
  isFFProductFavouriteEnabled,
} from '../helpers/feature.flags.helper';
import {
  hasPortionProvidingLoyaltyStamps,
  hasPortionRedeemableAsRewardInLoyaltyScheme,
} from '../helpers/productTile.helpers';
import { useLoyaltyReward } from '../hooks/useLoyaltyReward/useLoyaltyReward';
import { AllergenType, FacilityMenuSource } from '../types/orderState.types';
import { useIsSuggestionsEnabled } from '../widgets/SuggestionsWidget/useIsSuggestionsEnabled';

import ProductDetails from './ProductDetails';

import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import { isKioskApp } from '@/helpers/misc';
import { renderedComponent } from '@/helpers/tests/renderComponent';
import { useGetLoyaltySchemesQuery } from '@/modules/LoyaltyStamps/api';

jest.mock('@/modules/LoyaltyStamps/api', () => ({
  ...jest.requireActual('@/modules/LoyaltyStamps/api'),
  useGetLoyaltySchemesQuery: jest.fn(),
}));

const mockHistory = createMemoryHistory();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: jest.fn(),
  useHistory: () => mockHistory,
}));

jest.mock('../actions', () => ({
  ...jest.requireActual('../actions'),
  getMenus: () => jest.fn(),
  updateItemInCart: () => jest.fn(),
}));

jest.mock('../hooks/useLoyaltyReward/useLoyaltyReward', () => ({
  ...jest.requireActual('../hooks/useLoyaltyReward/useLoyaltyReward'),
  useLoyaltyReward: jest.fn(),
}));

jest.mock('@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled', () => ({
  useIsSetupOptionEnabled: jest.fn(),
}));

jest.mock('@/helpers/hooks/useSetupOption/useSetupOption', () => ({
  useSetupOption: jest.fn(),
}));

jest.mock('@/helpers/hooks/Analytics/useAnalytics', () => ({
  __esModule: true,
  default: () => ({
    useAnalytics: jest.fn(),
    trackPageViewWithFacilitiesEvent: jest.fn(),
    trackButtonClickEvent: jest.fn(),
  }),
}));

jest.mock('@/helpers/misc', () => ({
  ...jest.requireActual('@/helpers/misc'),
  isKioskApp: jest.fn(),
}));

jest.mock('../helpers/feature.flags.helper', () => ({
  ...jest.requireActual('../helpers/feature.flags.helper'),
  isFFProductFavouriteEnabled: jest.fn(),
  isFFAppSharingEnabled: jest.fn(),
}));

jest.mock('@/helpers/hooks/useGeoCode', () => {
  return {
    __esModule: true,
    default: () => ({ defaultGeoCode: 'PL', currentGeoCode: 'PL' }),
  };
});

jest.mock('../config', () => ({
  ...jest.requireActual('../config'),
  orderConfig: jest.fn(),
}));

jest.mock('../widgets/SuggestionsWidget/useIsSuggestionsEnabled', () => ({
  useIsSuggestionsEnabled: jest.fn(),
}));

jest.mock('../hooks/useSuggestions/useCheckNoSuggestionsToDisplay', () => ({
  useCheckNoSuggestionsToDisplay: () => ({
    suggestionsLimitDesktop: 0,
  }),
}));

jest.mock('../helpers/allergens.helper', () => ({
  ...jest.requireActual('../helpers/allergens.helper'),
  getAllergensFromCache: jest.fn(),
}));

jest.mock('../helpers/productTile.helpers', () => ({
  ...jest.requireActual('../helpers/productTile.helpers'),
  hasPortionProvidingLoyaltyStamps: jest.fn(),
  hasPortionRedeemableAsRewardInLoyaltyScheme: jest.fn(),
}));

const mockIsPortionFavorite = jest.fn();
const mockAddToFavorites = jest.fn();
const mockRemoveFromFavorites = jest.fn();
jest.mock('../hooks/useProductPortion/useProductPortion', () => ({
  useProductPortion: () => ({
    productPortion: mockIsPortionFavorite(),
    addToFavorites: mockAddToFavorites,
    removeFromFavorites: mockRemoveFromFavorites,
  }),
}));

jest.mock('@/helpers/camera', () => ({
  ...jest.requireActual('@/helpers/camera'),
  hasCameraPermission: () => true,
}));

const omsOrderableMenuId = 1;
const omsOrderableMenuItemId = 123456;

const omsScanAndGoMenuId = 11;
const omsScanAndGoMenuItemId = 654321;

const omsNonOrderableMenuId = 2;
const omsNonOrderableMenuItemId1 = 25678;
const omsNonOrderableMenuItemId2 = 25679;
const omsNonOrderableMenuItemId3 = 25680;

const nonOmsMenuId = 3;
const nonOmsMenuItemId = 3456789;

const facilityId = 'facilityId1';
const menuDate = '2024-01-31';

const allergens = [
  {
    id: AllergenType.Milk,
    name: 'Milk',
    contains: true,
  },
  {
    id: AllergenType.Celery,
    name: 'Celery',
    contains: true,
  },
  {
    id: AllergenType.Nuts,
    name: 'Nuts',
    contains: true,
  },
];

const modifier1Id = 150;
const modifier1ItemId1 = 39262;
const modifier1ItemIdName = 'Modifier item 1 name';
const modifier1ItemId2 = 39263;

const modifier2Id = 151;
const modifier2ItemId1 = 49262;
const modifier2ItemId2 = 49263;
const modifier2ItemId3 = 49264;

const dayPart = 'Breakfast';
const siteId = 'siteId';

const omsOrderableMenuMenuItem = {
  menuItemId: omsOrderableMenuItemId,
  name: 'Product 1 OMS',
  description: 'Product 1 OMS description',
  productPortions: [
    {
      uomId: 1,
      portionId: 123,
      foodItemId: 1234,
      portion: 'Portion 1',
      ingredients: 'Eggs, milk, potatoes',
      price: 10,
      isDefault: true,
      alcoholContent: 10,
      co2Value: '10',
      nutritions: {
        calories: 300,
        kilojoules: 3000,
        fat: 10,
        saturatedFat: 5,
        carbohydrates: 20,
        cholesterol: 10,
        sugar: 5,
        protein: 10,
        sodium: 5,
      },
      gramWeight: 200,
      allergens: allergens,
      modifiers: [
        {
          id: modifier1Id,
          name: 'test 1',
          description: 'test 1',
          type: 1,
          selectionLimit: 4,
          isRequired: true,
          min: 1,
          max: 6,
          firstFree: 3,
          modifierItems: [
            {
              id: modifier1ItemId1,
              name: modifier1ItemIdName,
              price: 2.0,
              priceLabel: '£2.00',
              sortOrder: 1,
              maximumQuantity: 1,
              description: 'modi1',
              min: 0,
              max: 4,
              firstFree: 2,
              isChild: true,
              nutritions: {
                calories: 30,
                kilojoules: 300,
                fat: 1,
                saturatedFat: 0.5,
                carbohydrates: 2,
                cholesterol: 1,
                sugar: 0.5,
                protein: 1,
                sodium: 0.5,
              },
              allergens: [
                {
                  id: AllergenType.Almonds,
                  name: 'Almonds',
                  contains: true,
                },
                {
                  id: AllergenType.Milk,
                  name: 'Milk',
                  contains: true,
                },
              ],
            },
            {
              id: modifier1ItemId2,
              name: 'modi2',
              price: 1.0,
              priceLabel: '£1.00',
              sortOrder: 2,
              maximumQuantity: 0,
              description: 'modi2',
              min: 0,
              max: 4,
              firstFree: 0,
              isChild: false,
              nutritions: {},
              allergens: [],
            },
          ],
        },
        {
          id: modifier2Id,
          name: 'test 2',
          description: 'test 2',
          type: 1,
          selectionLimit: 2,
          isRequired: false,
          min: 0,
          max: 2,
          firstFree: 1,
          modifierItems: [
            {
              id: modifier2ItemId1,
              name: 'Greek Salad',
              price: 3,
              priceLabel: '£3.00',
              sortOrder: 1,
              maximumQuantity: 1,
              description: 'Greek Salad',
              min: 0,
              max: 1,
              firstFree: 1,
              isChild: true,
              nutritions: {
                calories: 10,
                kilojoules: 10,
                carbohydrates: 10,
                fat: 10,
                protein: 10,
                saturatedFat: 10,
                transFat: 10,
                polyunsaturatedFat: 10,
                cholesterol: 10,
              },
              allergens: [
                {
                  id: AllergenType.Milk,
                  name: 'Milk',
                  contains: true,
                },
                {
                  id: AllergenType.Peanut,
                  name: 'Peanut',
                  contains: true,
                },
              ],
            },
            {
              id: modifier2ItemId2,
              name: 'Cezar Salad',
              price: 4,
              priceLabel: '£4.00',
              sortOrder: 2,
              maximumQuantity: 1,
              description: 'Cezar Salad',
              min: 0,
              max: 1,
              firstFree: 1,
              isChild: true,
              nutritions: {
                calories: 20,
                kilojoules: 20,
                carbohydrates: 20,
                fat: 20,
                protein: 20,
                saturatedFat: 20,
                transFat: 20,
                polyunsaturatedFat: 20,
                cholesterol: 20,
              },
              allergens: [],
            },
            {
              id: modifier2ItemId3,
              name: 'Polish Salad',
              price: 5.99,
              priceLabel: 'AUD 5.99',
              sortOrder: 2,
              maximumQuantity: 1,
              description: 'Polish Salad',
              min: 0,
              max: 1,
              firstFree: 1,
              isChild: true,
              nutritions: {
                calories: 20,
                kilojoules: 20,
                carbohydrates: 20,
                fat: 20,
                protein: 20,
                saturatedFat: 20,
                transFat: 20,
                polyunsaturatedFat: 20,
                cholesterol: 20,
              },
              allergens: [],
            },
          ],
        },
      ],
      isVegan: true,
      milkAdditive: true,
      soyBeansAdditive: true,
      eggsAdditive: true,
      sesameSeedsAdditive: true,
      peanutsAdditive: true,
      wheatAdditive: true,
      fishAdditive: true,
      nutsAdditive: true,
    },
    {
      uomId: 2,
      portionId: 234,
      foodItemId: 2345,
      portion: 'Portion 2',
      price: 11,
      isDefault: false,
      nutritions: {},
      allergens: [],
      gramWeight: 300,
    },
  ],
};

const omsScanAndGoMenuMenuItem = {
  menuItemId: omsScanAndGoMenuItemId,
  name: 'Product 1 Scan&Go OMS',
  description: 'Product 1 Scan&Go OMS description',
  productPortions: [
    {
      uomId: 11,
      portionId: 1231,
      foodItemId: 12341,
      portion: 'Product 1 Scan&Go',
      price: 10,
      isDefault: true,
      co2Value: '10',
      nutritions: {},
      allergens: allergens,
      modifiers: [],
    },
  ],
};

const omsOrderableMenu = {
  id: omsOrderableMenuId,
  facilityId: facilityId,
  name: 'OMS menu',
  isOrderable: true,
  date: menuDate,
  dayPart,
  menuItems: [omsOrderableMenuMenuItem],
  isScanAndGo: false,
  source: FacilityMenuSource.Rr,
};

const omsScanAndGoMenu = {
  id: omsScanAndGoMenuId,
  facilityId: facilityId,
  name: 'OMS Scan&Go menu',
  isOrderable: true,
  date: menuDate,
  dayPart,
  menuItems: [omsScanAndGoMenuMenuItem],
  isScanAndGo: true,
  source: FacilityMenuSource.Rr,
};

const omsNonOrderableMenu = {
  id: omsNonOrderableMenuId,
  facilityId: facilityId,
  name: 'no orderable OMS menu',
  isOrderable: false,
  date: menuDate,
  dayPart,
  menuItems: [
    {
      menuItemId: omsNonOrderableMenuItemId1,
      name: 'Product 2 no orderable OMS',
      description: 'Product 2 no orderable OMS description',
      productPortions: [
        {
          uomId: 1,
          portion: 'Portion 1',
          ingredients: 'Sand, water',
          price: 11,
          isDefault: true,
          nutritions: {},
          alcoholContent: 10,
          co2Value: '10',
          allergens: allergens,
          gramWeight: 200,
          milkAdditive: true,
          soyBeansAdditive: true,
          eggsAdditive: true,
          sesameSeedsAdditive: true,
          peanutsAdditive: true,
          wheatAdditive: true,
          fishAdditive: true,
          nutsAdditive: true,
        },
      ],
    },
    {
      menuItemId: omsNonOrderableMenuItemId2,
      name: 'Product 2 no orderable OMS',
      description: 'Product 2 no orderable OMS description',
      productPortions: [
        {
          uomId: 2,
          portion: 'Portion 2',
          price: 11,
          viewablePrices: [
            {
              name: 'price 1',
              value: 7,
            },
          ],
          isDefault: true,
          nutritions: {},
          allergens: [],
        },
      ],
    },
    {
      menuItemId: omsNonOrderableMenuItemId3,
      name: 'Product 3 no orderable OMS',
      description: 'Product 3 no orderable OMS description',
      productPortions: [
        {
          uomId: 3,
          portion: 'Non orderable portion 3',
          price: 11,
          viewablePrices: [
            {
              name: 'price 1',
              value: 7,
            },
            {
              name: 'price 1',
              value: 5,
            },
          ],
          isDefault: true,
          nutritions: {},
          allergens: [],
        },
      ],
    },
  ],
  source: FacilityMenuSource.Rr,
};

const nonOmsMenu = {
  id: nonOmsMenuId,
  facilityId: facilityId,
  name: 'non OMS menu',
  isOrderable: false,
  date: menuDate,
  dayPart,
  menuItems: [
    {
      menuItemId: nonOmsMenuItemId,
      name: 'Product 1 non OMS',
      description: 'Product 1 non OMS description',
      productPortions: [
        {
          uomId: 1,
          portion: 'Portion 1',
          price: 10,
          isDefault: true,
          nutritions: {},
          allergens: [],
        },
        {
          uomId: 2,
          portion: 'Portion 2',
          price: 11,
          isDefault: false,
          nutritions: {},
          allergens: [],
        },
      ],
    },
  ],
  source: FacilityMenuSource.Ifm,
};

const cartMenuPortionItemWithModifiersId = 'a592fadc-9469-4c9c-90d8-156154a0ec51';
const cartMenuPortionItemWithModifiers = {
  id: cartMenuPortionItemWithModifiersId,
  img: null,
  menuItemId: omsOrderableMenuMenuItem.menuItemId,
  name: omsOrderableMenuMenuItem.name,
  price: omsOrderableMenuMenuItem.productPortions[0].price,
  quantity: 2,
  uomId: omsOrderableMenuMenuItem.productPortions[0].uomId,
  foodItemId: omsOrderableMenuMenuItem.productPortions[0].foodItemId,
  modifiers: [
    {
      modifierId: modifier1Id,
      values: [
        {
          valueId: modifier1ItemId1,
          quantity: 2,
        },
      ],
      firstFree: 0,
    },
  ],
  // note this is important, modifiersDisplayText string must be exactly as from buildModifierDisplayText
  modifiersDisplayText: 'test 1:  Modifier item 1 name: 2',
};

const cartMenuPortionItemWithoutModifiersId = 'a592fadc-9469-4c9c-90d8-156154a0ec52';
const cartMenuPortionItemWithoutModifiers = {
  id: cartMenuPortionItemWithoutModifiersId,
  img: null,
  menuItemId: omsOrderableMenuMenuItem.menuItemId,
  name: omsOrderableMenuMenuItem.name,
  price: omsOrderableMenuMenuItem.productPortions[0].price,
  quantity: 5,
  uomId: omsOrderableMenuMenuItem.productPortions[0].uomId,
  foodItemId: omsOrderableMenuMenuItem.productPortions[0].foodItemId,
  modifiers: [],
  modifiersDisplayText: '',
};

const cart = {
  date: menuDate,
  facilityId: facilityId,
  submissionTrackingId: 'submissionTrackingId',
  menuId: omsOrderableMenuId,
  siteId: siteId,
  menuPortionItems: [cartMenuPortionItemWithModifiers, cartMenuPortionItemWithoutModifiers],
  moment: 'Breakfast',
};

const stateMock = {
  Core: {
    context: {
      site: {
        id: siteId,
        name: 'Test Site',
        currency: {
          isoCode: 'EUR',
          currencyName: 'Euro',
          currencySymbol: 'EUR',
          exchangeRate: 1,
          precision: 2,
        },
      },
    },
    services: {
      list: [],
    },
    user: { isGuest: false },
  },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
  },
  Order: {
    menus: [omsOrderableMenu, omsNonOrderableMenu, nonOmsMenu, omsScanAndGoMenu],
    locks: {},
  },
  Facilities: {
    data: [],
  },
};

const omsOrderableMenuItemParams = {
  id: omsOrderableMenuItemId,
  facilityId,
  date: menuDate,
  menuId: omsOrderableMenuId,
};

const omsScanAndGoMenuItemParams = {
  id: omsScanAndGoMenuItemId,
  facilityId,
  date: menuDate,
  menuId: omsScanAndGoMenuId,
};

const omsNoOrderableMenuItemParams = {
  id: omsNonOrderableMenuItemId1,
  facilityId,
  date: menuDate,
  menuId: omsNonOrderableMenuId,
};

const nonOmsMenuItemParams = {
  id: nonOmsMenuItemId,
  facilityId,
  date: menuDate,
  menuId: nonOmsMenuId,
};

const defaultSetupOption = {
  isActive: false,
  value: null,
  setupOption: undefined,
};
describe('ProductDetails', () => {
  beforeEach(() => {
    Element.prototype.scrollIntoView = jest.fn();
    (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
      data: [],
      isLoading: true,
    });
    (useLoyaltyReward as jest.Mock).mockReturnValue({
      portionsFreeItemsInfo: [],
      isLoadingLoyaltyStamps: false,
    });
    (useParams as jest.Mock).mockReturnValue(omsOrderableMenuItemParams);
    (orderConfig as jest.Mock).mockReturnValue({
      areSuggestionsEnabled: true,
      isKioskTitleBar: false,
    });
    (getAllergensFromCache as jest.Mock).mockReturnValue([]);
  });

  describe('not in cart context', () => {
    describe('on render for orderable menu portion with complete data and alcohol content', () => {
      beforeEach(async () => {
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
      });

      it('shows sections and chips', () => {
        expect(screen.getByTestId('vege-chip')).toBeTruthy();
        expect(screen.getByTestId('menuItem-tile-orderable')).toBeTruthy();
        expect(screen.getByText('Product 1 OMS')).toBeTruthy();

        expect(screen.queryByTestId('menuItem-tile-viewable')).toBeFalsy();
        expect(screen.getByTestId('dropdown-input')).toBeTruthy();
        expect(getAddToCartButton(omsOrderableMenuItemId)).toBeTruthy();

        expect(screen.getByTestId('menuItem-image')).toBeTruthy();
        expect(screen.getByTestId('menuItem-description')).toBeTruthy();
        expect(screen.getByTestId(/portion-alcohol-notification*/)).toBeTruthy();

        expect(screen.getByTestId('portion-modifiers')).toBeTruthy();
        expect(screen.getByTestId('portion-allergens')).toBeTruthy();
        expect(screen.getByTestId('portion-emissions')).toBeTruthy();
        expect(screen.getByTestId('portion-additives')).toBeTruthy();
        expect(screen.getByTestId('portion-ingridients')).toBeTruthy();
      });
    });
    describe('on render for non orderable menu portion', () => {
      beforeEach(async () => {
        (useParams as jest.Mock).mockReturnValue(omsNoOrderableMenuItemParams);
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
      });

      it('shows sections', async () => {
        expect(screen.queryByTestId('menuItem-tile-orderable')).toBeFalsy();
        expect(screen.queryByTestId('menuItem-tile-viewable')).toBeTruthy();
        expect(screen.getByText('Product 2 no orderable OMS')).toBeTruthy();

        expect(screen.getByTestId('dropdown-input')).toBeTruthy();
        expect(screen.queryByTestId(/menu-cart-actions-cart-add-.*-wrapper/)).toBeFalsy();

        expect(screen.getByTestId('menuItem-image')).toBeTruthy();
        expect(screen.getByTestId('menuItem-description')).toBeTruthy();
        expect(screen.getByTestId(/portion-alcohol-notification*/)).toBeTruthy();
        expect(screen.getByTestId('portion-nutritions')).toBeTruthy();
        expect(screen.getByTestId('portion-allergens')).toBeTruthy();
        expect(screen.getByTestId('portion-emissions')).toBeTruthy();
        expect(screen.getByTestId('portion-additives')).toBeTruthy();
        expect(screen.getByTestId('portion-ingridients')).toBeTruthy();
      });
    });
    // describe('nutrition facts', () => {
    //   beforeEach(async () => {
    //     (useParams as jest.Mock).mockReturnValue(omsNoOrderableMenuItemParams);
    //     await act(() => renderedComponent(ProductDetails, {}, stateMock));
    //   });

    // describe('nutrition facts', () => {
    //   beforeEach(async () => {
    //     (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
    //     await act(() => renderedComponent(ProductDetails, {}, stateMock));
    //   });

    //     const calories = getNutritionListItem('calories');
    //     const fat = getNutritionListItem('fat');
    //     const saturatedFat = getNutritionListItem('saturated-fat');
    //     const carbohydrates = getNutritionListItem('carbohydrates');
    //     const sugar = getNutritionListItem('sugar');
    //     const protein = getNutritionListItem('protein');
    //     const sodium = getNutritionListItem('sodium');
    //     const cholesterol = getNutritionListItem('cholesterol');

    //     expect(calories.innerHTML).toContain('1500 kJ');
    //     expect(calories.innerHTML).toContain('150 kcal');
    //     expect(fat.innerHTML).toBe('5 g');
    //     expect(saturatedFat.innerHTML).toBe('2.50 g');
    //     expect(carbohydrates.innerHTML).toBe('10 g');
    //     expect(sugar.innerHTML).toBe('2.50 g');
    //     expect(protein.innerHTML).toBe('5 g');
    //     expect(sodium.innerHTML).toBe('2.50 g');
    //     expect(cholesterol.innerHTML).toBe('5 mg');
    //     expect(per100gNutritions.classList).toContain('isSelected');
    //     expect(perPortionNutritions.classList).not.toContain('isSelected');
    //   });
    //   it('when button per portion is clicked - recalculates nutrition facts per portion', async () => {
    //     const per100gNutritions = screen.getByTestId('multi-card-select-item-per-100-g');
    //     const perPortionNutritions = screen.getByTestId('multi-card-select-item-per-portion');
    //     await userEvent.click(perPortionNutritions);

    //     const calories = getNutritionListItem('calories');
    //     const fat = getNutritionListItem('fat');
    //     const saturatedFat = getNutritionListItem('saturated-fat');
    //     const carbohydrates = getNutritionListItem('carbohydrates');
    //     const sugar = getNutritionListItem('sugar');
    //     const protein = getNutritionListItem('protein');
    //     const sodium = getNutritionListItem('sodium');
    //     const cholesterol = getNutritionListItem('cholesterol');

    //     expect(calories.innerHTML).toContain('3000 kJ');
    //     expect(calories.innerHTML).toContain('300 kcal');
    //     expect(fat.innerHTML).toBe('10 g');
    //     expect(saturatedFat.innerHTML).toBe('5 g');
    //     expect(carbohydrates.innerHTML).toBe('20 g');
    //     expect(sugar.innerHTML).toBe('5 g');
    //     expect(protein.innerHTML).toBe('10 g');
    //     expect(sodium.innerHTML).toBe('5 g');
    //     expect(cholesterol.innerHTML).toBe('10 mg');

    //     expect(perPortionNutritions.classList).toContain('isSelected');
    //     expect(per100gNutritions.classList).not.toContain('isSelected');
    //   });
    //   it('when diffent portion is selected - resets nutrition facts and shows nutrition facts per 100g', async () => {
    //     let per100gNutritions = screen.getByTestId('multi-card-select-item-per-100-g');
    //     let perPortionNutritions = screen.getByTestId('multi-card-select-item-per-portion');
    //     // click per portion nutritions button
    //     await userEvent.click(perPortionNutritions);

    //     expect(perPortionNutritions.classList).toContain('isSelected');
    //     expect(per100gNutritions.classList).not.toContain('isSelected');

    //     await selectPortion('Portion 1', 'Portion 2');

    //     per100gNutritions = screen.getByTestId('multi-card-select-item-per-100-g');
    //     perPortionNutritions = screen.getByTestId('multi-card-select-item-per-portion');
    //     expect(perPortionNutritions.classList).not.toContain('isSelected');
    //     expect(per100gNutritions.classList).toContain('isSelected');
    //   });
    //     it('when modifier is selected - adds modifier nutrition facts to total nutrition facts', async () => {
    //       const modifierWithNutritionsDecrement = getModifierDecrementButton(modifier1ItemId1);
    //       const modifierWithNutritionsIncrement = getModifierItemIncrementButton(modifier1ItemId1);
    //       await userEvent.click(modifierWithNutritionsIncrement);
    //       await userEvent.click(modifierWithNutritionsIncrement);
    //       let calories = getNutritionListItem('calories');
    //       let fat = getNutritionListItem('fat');
    //       let saturatedFat = getNutritionListItem('saturated-fat');
    //       let carbohydrates = getNutritionListItem('carbohydrates');
    //       let sugar = getNutritionListItem('sugar');
    //       let protein = getNutritionListItem('protein');
    //       let sodium = getNutritionListItem('sodium');
    //       let cholesterol = getNutritionListItem('cholesterol');
    //       expect(calories.textContent).toContain('1800 kJ');
    //       expect(calories.textContent).toContain('180 kcal');
    //       expect(fat.innerHTML).toBe('6 g');
    //       expect(saturatedFat.innerHTML).toBe('3 g');
    //       expect(carbohydrates.innerHTML).toBe('12 g');
    //       expect(sugar.innerHTML).toBe('3 g');
    //       expect(protein.innerHTML).toBe('6 g');
    //       expect(sodium.innerHTML).toBe('3 g');
    //       expect(cholesterol.innerHTML).toBe('6 mg');
    //       await userEvent.click(modifierWithNutritionsDecrement);
    //       calories = getNutritionListItem('calories');
    //       fat = getNutritionListItem('fat');
    //       saturatedFat = getNutritionListItem('saturated-fat');
    //       carbohydrates = getNutritionListItem('carbohydrates');
    //       sugar = getNutritionListItem('sugar');
    //       protein = getNutritionListItem('protein');
    //       sodium = getNutritionListItem('sodium');
    //       cholesterol = getNutritionListItem('cholesterol');
    //       expect(calories.innerHTML).toContain('1650 kJ');
    //       expect(calories.innerHTML).toContain('165 kcal');
    //       expect(fat.innerHTML).toBe('5.50 g');
    //       expect(saturatedFat.innerHTML).toBe('2.75 g');
    //       expect(carbohydrates.innerHTML).toBe('11 g');
    //       expect(sugar.innerHTML).toBe('2.75 g');
    //       expect(protein.innerHTML).toBe('5.50 g');
    //       expect(sodium.innerHTML).toBe('2.75 g');
    //       expect(cholesterol.innerHTML).toBe('5.50 mg');
    //     });
    // });
    describe('modifiers', () => {
      beforeEach(async () => {
        (getAllergensFromCache as jest.Mock).mockReturnValue([AllergenType.Almonds]);
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
      });
      it('show modifier information for required modifier with free items and allergens', () => {
        const modifier1Card = screen.getByTestId(`modifier-card-${modifier1Id}`);
        expect(getByText(modifier1Card, '(1 minimum required)')).toBeTruthy();
        expect(getByText(modifier1Card, 'Number of free items: 3')).toBeTruthy();
        const modifierItem1Container = screen.getByTestId(
          `card-modifier-list-modifier-item-quantity-setter-${modifier1ItemId1}-list-item-label`
        );
        expect(getByText(modifierItem1Container, modifier1ItemIdName)).toBeTruthy();
        const allergenIcons = modifierItem1Container.querySelectorAll('span svg');
        expect(allergenIcons.length).toBe(2);
      });
      it('higlights modifier item which contains allergens selected by user filter', () => {
        const modifierItem1WithHighlightedAllergensContainer = screen.getByTestId(
          `card-modifier-list-modifier-item-quantity-setter-${modifier1ItemId1}-list-item-label`
        );
        const modifierItem2Container = screen.getByTestId(
          `card-modifier-list-modifier-item-quantity-setter-${modifier1ItemId2}-list-item-label`
        );
        expect(modifierItem1WithHighlightedAllergensContainer.classList).toContain('warningLabel');
        expect(modifierItem1WithHighlightedAllergensContainer.classList).not.toContain(
          'nonWarningLabel'
        );
        expect(modifierItem2Container.classList).not.toContain('warningLabel');
        expect(modifierItem2Container.classList).toContain('nonWarningLabel');
      });
      it('when "info" link is clicked - shows modifiers popup', async () => {
        const nutritionsInfoButton = screen.getByTestId(`button-infos-modal-${modifier1Id}`);
        expect(screen.queryByTestId(`modal-infos-${modifier1ItemId1}`)).toBeFalsy();
        expect(screen.queryByTestId(`modal-infos-${modifier1ItemId2}`)).toBeFalsy();
        await userEvent.click(nutritionsInfoButton);
        expect(screen.queryByTestId(`modal-infos-${modifier1ItemId1}`)).toBeTruthy();
        expect(screen.queryByTestId(`modal-infos-${modifier1ItemId2}`)).toBeTruthy();
      });
      it('when minimum required is specified - portion can not be added to cart', async () => {
        const addToCartButton = getAddToCartButton(omsOrderableMenuItemId);
        let notificationTopContent = screen.queryByText('You have to add at least 1 ingredient');
        expect(notificationTopContent).toBeFalsy();
        await userEvent.click(addToCartButton);
        notificationTopContent = screen.queryByText('You have to add at least 1 ingredient');
        expect(notificationTopContent).toBeTruthy();
      });
      describe('when max number of modifier item is specified', () => {
        it('is not possible to add more than 2 modifier items when max modifier items is 2 - checkbox', async () => {
          const modifierItem1Checkbox = getModifierItemCheckbox(modifier2ItemId1);
          const modifierItem2Checkbox = getModifierItemCheckbox(modifier2ItemId2);
          let modifierItem3Checkbox = getModifierItemCheckbox(modifier2ItemId3);
          expect((modifierItem3Checkbox as HTMLIonCheckboxElement).disabled).toBeFalsy();
          await userEvent.click(modifierItem1Checkbox);
          await userEvent.click(modifierItem2Checkbox);
          modifierItem3Checkbox = getModifierItemCheckbox(modifier2ItemId3);
          expect((modifierItem3Checkbox as HTMLIonCheckboxElement).disabled).toBeTruthy();
        });
        it('is not possible to add more than 6 modifier items when modifier max amount is 6 - quantity setter', async () => {
          let addItem1Button = getModifierItemIncrementButton(modifier1ItemId1);
          let addItem2Button = getModifierItemIncrementButton(modifier1ItemId2);
          expect(addItem1Button.classList).not.toContain('disabled');
          expect(addItem2Button.classList).not.toContain('disabled');
          await userEvent.click(addItem1Button);
          await userEvent.click(addItem1Button);
          await userEvent.click(addItem1Button);
          await userEvent.click(addItem2Button);
          await userEvent.click(addItem2Button);
          await userEvent.click(addItem2Button);
          // try to add 7th modifier item
          await userEvent.click(addItem2Button);
          addItem1Button = getModifierItemIncrementButton(modifier1ItemId1);
          addItem2Button = getModifierItemIncrementButton(modifier1ItemId1);
          const modifierItem2Value = getModifierQuantitySetterValue(modifier1ItemId2);
          expect(addItem1Button.classList).toContain('disabled');
          expect(addItem2Button.classList).toContain('disabled');
          expect(modifierItem2Value.textContent).toBe('3');
        });
        it('is not possible to add more than 4 modifier items when modifier item max quantity is 4 - quantity setter', async () => {
          let addItem1Button = getModifierItemIncrementButton(modifier1ItemId1);
          expect(addItem1Button.classList).not.toContain('disabled');
          await userEvent.click(addItem1Button);
          await userEvent.click(addItem1Button);
          await userEvent.click(addItem1Button);
          await userEvent.click(addItem1Button);
          // try add 5th modifier item
          await userEvent.click(addItem1Button);
          addItem1Button = getModifierItemIncrementButton(modifier1ItemId1);
          const modifierItem2Value = getModifierQuantitySetterValue(modifier1ItemId1);
          expect(addItem1Button.classList).toContain('disabled');
          expect(modifierItem2Value.textContent).toBe('4');
        });
      });
    });
    describe('price', () => {
      describe('orderable menu item', () => {
        beforeEach(async () => {
          (useParams as jest.Mock).mockReturnValue(omsOrderableMenuItemParams);
          (useSetupOption as jest.Mock).mockReturnValue({
            isActive: false,
            value: null,
            setupOption: undefined,
          });
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
        });
        it('shows default portion price', () => {
          const menuTileOrderableContainer = screen.getByTestId('menuItem-tile-orderable');
          const addToCartButton = getAddToCartButton(omsOrderableMenuItemId);
          expect(getByText(menuTileOrderableContainer, '€10.00')).toBeTruthy();
          expect(getByText(addToCartButton, /Add to cart .* €10.00/)).toBeTruthy();
        });
        it('when portion changed - shows selected portion price', async () => {
          await selectPortion('Portion 1', 'Portion 2');
          const menuTileOrderableContainer = screen.getByTestId('menuItem-tile-orderable');
          const addToCartButton = getAddToCartButton(omsOrderableMenuItemId);
          expect(getByText(menuTileOrderableContainer, '€11.00')).toBeTruthy();
          expect(getByText(addToCartButton, /Add to cart .* €11.00/)).toBeTruthy();
        });
        it('when 3 modifier items are added and first 2 is free price includes price of 1 modifier items', async () => {
          let menuTileOrderableContainer = screen.getByTestId('menuItem-tile-orderable');
          let addToCartButton = getAddToCartButton(omsOrderableMenuItemId);
          expect(getByText(menuTileOrderableContainer, '€10.00')).toBeTruthy();
          expect(getByText(addToCartButton, /Add to cart .* €10.00/)).toBeTruthy();
          const modifierItemIncrement = getModifierItemIncrementButton(modifier1ItemId1);
          await userEvent.click(modifierItemIncrement);
          await userEvent.click(modifierItemIncrement);
          menuTileOrderableContainer = screen.getByTestId('menuItem-tile-orderable');
          addToCartButton = getAddToCartButton(omsOrderableMenuItemId);
          expect(getByText(menuTileOrderableContainer, '€10.00')).toBeTruthy();
          expect(getByText(addToCartButton, /Add to cart .* €10.00/)).toBeTruthy();
          await userEvent.click(modifierItemIncrement);
          menuTileOrderableContainer = screen.getByTestId('menuItem-tile-orderable');
          addToCartButton = getAddToCartButton(omsOrderableMenuItemId);
          expect(getByText(menuTileOrderableContainer, '€12.00')).toBeTruthy();
          expect(getByText(addToCartButton, /Add to cart .* €12.00/)).toBeTruthy();
        });
      });
      describe('non orderable menu item', () => {
        beforeEach(() => {
          (useSetupOption as jest.Mock).mockReturnValue({
            isActive: false,
            value: null,
            setupOption: undefined,
          });
        });
        it('when portion has no viewable prices, shows price', async () => {
          (useParams as jest.Mock).mockReturnValue(omsNoOrderableMenuItemParams);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          const menuTileViewableContainer = screen.getByTestId('menuItem-tile-viewable');
          expect(getByText(menuTileViewableContainer, '€11.00')).toBeTruthy();
        });
        it('when portion has single viewable price, shows lowest', async () => {
          (useParams as jest.Mock).mockReturnValue({
            ...omsNoOrderableMenuItemParams,
            id: omsNonOrderableMenuItemId2,
          });
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          const menuTileViewableContainer = screen.getByTestId('menuItem-tile-viewable');
          expect(getByText(menuTileViewableContainer, '€7.00')).toBeTruthy();
        });
        it('when portion has more than one viewable price, shows price with "from"', async () => {
          (useParams as jest.Mock).mockReturnValue({
            ...omsNoOrderableMenuItemParams,
            id: omsNonOrderableMenuItemId3,
          });
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          const menuTileViewableContainer = screen.getByTestId('menuItem-tile-viewable');
          expect(getByText(menuTileViewableContainer, 'From €5.00')).toBeTruthy();
        });
      });
    });
    describe('allergens', () => {
      beforeEach(() => {
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
      });
      it('when selected portion or added modifier has allergens selected by user in filter - shows allergens chip and allergens notification', async () => {
        (getAllergensFromCache as jest.Mock).mockReturnValue([
          AllergenType.Milk,
          AllergenType.Nuts,
          AllergenType.Almonds,
        ]);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.getByTestId('allergens-chip')).toBeTruthy();
        expect(screen.getByTestId(/allergens-warning*/)).toBeTruthy();
        let higligtedAllergens = screen.queryAllByTestId(
          /allergens-list-warning-allergen-.*-list-item-non-link/
        );
        let highligtedModifiersAllergens = screen.queryAllByTestId(
          /allergens-modifiers-list-warning-allergen-.*-list-item-non-link/
        );
        expect(higligtedAllergens.length).toBe(2);
        expect(highligtedModifiersAllergens.length).toBe(0);
        const modifierAddElement = getModifierItemIncrementButton(modifier1ItemId1);
        // add modifier with allergen
        await userEvent.click(modifierAddElement);
        higligtedAllergens = screen.queryAllByTestId(
          /allergens-list-warning-allergen-.*-list-item-non-link/
        );
        highligtedModifiersAllergens = screen.queryAllByTestId(
          /allergens-modifiers-list-warning-allergen-.*-list-item-non-link/
        );
        expect(higligtedAllergens.length).toBe(2);
        expect(highligtedModifiersAllergens.length).toBe(1);
      });
      it('when selected portion has no allergens selected by user in filter - does not shows allergens chip and allergens notification', async () => {
        (getAllergensFromCache as jest.Mock).mockReturnValue([AllergenType.Almonds]);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.queryByTestId('allergens-chip')).toBeFalsy();
        expect(screen.queryByTestId(/allergens-warning*/)).toBeFalsy();
        const higligtedAllergens = screen.queryAllByTestId(
          /allergens-list-warning-allergen-*-list-item-non-link/
        );
        expect(higligtedAllergens.length).toBe(0);
      });
    });
    describe('loyality chips', () => {
      beforeEach(() => {
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
      });
      it('shows loyality chips for portion with stamps and reward', async () => {
        (hasPortionProvidingLoyaltyStamps as jest.Mock).mockReturnValue(true);
        (hasPortionRedeemableAsRewardInLoyaltyScheme as jest.Mock).mockReturnValue(true);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.queryByTestId('loyality-points-chip')).toBeTruthy();
        expect(screen.queryByTestId('loyality-reward-chip')).toBeTruthy();
      });
      it('does not show loyality chips for portion without stamps and reward', async () => {
        (hasPortionProvidingLoyaltyStamps as jest.Mock).mockReturnValue(false);
        (hasPortionRedeemableAsRewardInLoyaltyScheme as jest.Mock).mockReturnValue(false);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.queryByTestId('loyality-points-chip')).toBeFalsy();
        expect(screen.queryByTestId('loyality-reward-chip')).toBeFalsy();
      });
    });
    describe('suggestions container', () => {
      beforeEach(() => {
        (useIsSuggestionsEnabled as jest.Mock).mockReturnValue(true);
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
      });
      it('when suggestions enabled and menuItem is orderable - shows suggestion container', async () => {
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.queryByTestId('suggestions-product-details')).toBeTruthy();
      });
      it('when menuItem is not orderable - does not show suggestion container ', async () => {
        (useParams as jest.Mock).mockReturnValue(omsNoOrderableMenuItemParams);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.queryByTestId('suggestions-product-details')).toBeFalsy();
      });
      it('when suggestions are not enable for application - does not show suggestion container ', async () => {
        (orderConfig as jest.Mock).mockReturnValue({
          areSuggestionsEnabled: false,
          isKioskTitleBar: false,
        });
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.queryByTestId('suggestions-product-details')).toBeFalsy();
      });
      it('when suggestions are not enable for product details - does not show suggestion container', async () => {
        (useIsSuggestionsEnabled as jest.Mock).mockReturnValue(false);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
        expect(screen.queryByTestId('suggestions-product-details')).toBeFalsy();
      });
    });
    describe('secondary actions', () => {
      describe('review', () => {
        beforeEach(() => {
          (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(true);
          (isKioskApp as jest.Mock).mockReturnValue(false);
          (useSetupOption as jest.Mock).mockReturnValue({
            isActive: false,
            value: null,
            setupOption: undefined,
          });
        });
        describe('when menu is OMS menu, app is not kiosk and food feedback is enabled', () => {
          beforeEach(async () => await act(() => renderedComponent(ProductDetails, {}, stateMock)));
          it('show "Review" button', () => {
            expect(screen.queryByTestId('product-details-review')).toBeTruthy();
          });
          it('when button is clicked - redirects to "Review" screen ', async () => {
            const reviewButton = screen.getByTestId('product-details-review');
            await userEvent.click(reviewButton);
            expect(mockHistory.location.pathname).toMatch('review/menuItem');
          });
        });
        it('when menu is not from OMS - does not show "Review" button ', async () => {
          (useParams as jest.Mock).mockReturnValue(nonOmsMenuItemParams);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('product-details-review')).toBeFalsy();
        });
        it('when food feedback is not enabled - does not show "Review" button', async () => {
          (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(false);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('product-details-review')).toBeFalsy();
        });
        it('when app is kiosk - does not show "Review" button ', async () => {
          (isKioskApp as jest.Mock).mockReturnValue(true);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('product-details-review')).toBeFalsy();
        });
      });
      describe('favorite', () => {
        beforeEach(() => {
          (isFFProductFavouriteEnabled as jest.Mock).mockReturnValue(true);
          (isKioskApp as jest.Mock).mockReturnValue(false);
          (useSetupOption as jest.Mock).mockReturnValue({
            isActive: false,
            value: null,
            setupOption: undefined,
          });
        });
        describe('when feature flag is enabled, logged user is not guest app is not kiosk', () => {
          it('show "Favorite" button', async () => {
            await act(() => renderedComponent(ProductDetails, {}, stateMock));
            expect(screen.queryByTestId('product-details-favorite')).toBeTruthy();
          });
          it('when portion is not in favorites and button is clicked - adds portion to favorites ', async () => {
            mockIsPortionFavorite.mockReturnValue({ isFavorite: false });
            await act(() => renderedComponent(ProductDetails, {}, stateMock));
            const favoriteButton = screen.getByTestId('product-details-favorite');
            await userEvent.click(favoriteButton);
            expect(mockAddToFavorites).toHaveBeenCalled();
            expect(mockRemoveFromFavorites).not.toHaveBeenCalled();
          });
          it('when portions is in favorites and button is clicked - removes portion from favorites ', async () => {
            mockIsPortionFavorite.mockReturnValue({ isFavorite: true });
            await act(() => renderedComponent(ProductDetails, {}, stateMock));
            const favoriteButton = screen.getByTestId('product-details-favorite');
            await userEvent.click(favoriteButton);
            expect(mockRemoveFromFavorites).toHaveBeenCalled();
            expect(mockAddToFavorites).not.toHaveBeenCalled();
          });
        });
        it('when food feedback is not enabled - does not show "Favorite" button ', async () => {
          (isFFProductFavouriteEnabled as jest.Mock).mockReturnValue(false);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('product-details-favorite')).toBeFalsy();
        });
        it('when logged in user is guest - does not show "Favorite" button ', async () => {
          const stateWithGuestUser = {
            ...stateMock,
            Core: { ...stateMock.Core, user: { isGuest: true } },
          };
          await act(() => renderedComponent(ProductDetails, {}, stateWithGuestUser));
          expect(screen.queryByTestId('product-details-favorite')).toBeFalsy();
        });
        it('when app is kiosk - does not show "Favorite" button', async () => {
          (isKioskApp as jest.Mock).mockReturnValue(true);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('product-details-favorite')).toBeFalsy();
        });
      });
      describe('share', () => {
        beforeEach(() => {
          (isFFAppSharingEnabled as jest.Mock).mockReturnValue(true);
          (useSetupOption as jest.Mock).mockReturnValue({
            isActive: true,
            value: 'shareSiteIdentifierValue',
            setupOption: undefined,
          });
          (isKioskApp as jest.Mock).mockReturnValue(false);
        });
        describe('when menu is OMS menu, app is not kiosk and share is enabled', () => {
          beforeEach(async () => await act(() => renderedComponent(ProductDetails, {}, stateMock)));
          it('show "Share" button', () => {
            expect(screen.queryByTestId('share-product-details-link')).toBeTruthy();
          });
          it('when button is clicked - shows share modal', async () => {
            const shareButton = screen.getByTestId('share-product-details-link');
            await userEvent.click(shareButton);
            await waitFor(() => expect(screen.queryByTestId('share-modal')).toBeTruthy(), {
              interval: 500,
            });
          });
        });
        it('when menu is not from OMS - does not show "Share" button', async () => {
          (useParams as jest.Mock).mockReturnValue(nonOmsMenuItemParams);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('share-product-details-link')).toBeFalsy();
        });
        it('when share is not enabled - does not show "Share" button ', async () => {
          (useSetupOption as jest.Mock).mockReturnValue({
            isActive: false,
            value: null,
            setupOption: undefined,
          });
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('share-product-details-link')).toBeFalsy();
        });
        it('when app is kiosk - does not show "Share" button', async () => {
          (isKioskApp as jest.Mock).mockReturnValue(true);
          await act(() => renderedComponent(ProductDetails, {}, stateMock));
          expect(screen.queryByTestId('share-product-details-link')).toBeFalsy();
        });
      });
    });
    describe('adding to cart', () => {
      const state = { ...stateMock, Order: { ...stateMock.Order, cart: cart } };
      beforeEach(async () => {
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
        (useParams as jest.Mock).mockReturnValue(omsOrderableMenuItemParams);
        await act(() => renderedComponent(ProductDetails, {}, state));
      });
      it('when portion in cart, shows number of items in cart', async () => {
        const quantitySetterValue = screen.getByTestId('menu-cart-actions-quantity-setter-value');
        const goToCartButton = screen.getByTestId(
          `menu-cart-actions-cart-redirect-${omsOrderableMenuItemId}`
        );
        expect(quantitySetterValue.textContent).toBe(
          cartMenuPortionItemWithoutModifiers.quantity.toString()
        );
        const expectedPrice =
          omsOrderableMenuMenuItem.productPortions[0].price *
          cartMenuPortionItemWithoutModifiers.quantity;
        expect(goToCartButton.textContent).toContain(expectedPrice);
      });
      it('when portion in cart has 2 modifiers and user selects the exact same modifiers, shows number of similar items already in cart', async () => {
        const addItem1Button = getModifierItemIncrementButton(modifier1ItemId1);
        await userEvent.click(addItem1Button);
        await userEvent.click(addItem1Button);
        const quantitySetterValue = screen.getByTestId('menu-cart-actions-quantity-setter-value');
        const goToCartButton = screen.getByTestId(
          `menu-cart-actions-cart-redirect-${omsOrderableMenuItemId}`
        );
        // expected price doesn't include price of modifiers because in mock 2 items are for free
        const expectedPrice =
          omsOrderableMenuMenuItem.productPortions[0].price *
          cartMenuPortionItemWithModifiers.quantity;
        expect(quantitySetterValue.textContent).toBe(
          cartMenuPortionItemWithModifiers.quantity.toString()
        );
        expect(goToCartButton.textContent).toContain(expectedPrice);
      });
    });
    describe('Scan&Go menu', () => {
      beforeEach(async () => {
        (useSetupOption as jest.Mock).mockReturnValue(defaultSetupOption);
        (window.IntersectionObserver as any) = jest.fn(() => ({
          observe: jest.fn(),
          unobserve: jest.fn(),
        }));
        (useParams as jest.Mock).mockReturnValue(omsScanAndGoMenuItemParams);
        await act(() => renderedComponent(ProductDetails, {}, stateMock));
      });
      it('render "Scan barcode" button', () => {
        expect(screen.getByTestId('qr-code-reader-hide-scan')).toBeTruthy();
      });
      it('when "Scan barcode" button is clicked, shows scanner modal', async () => {
        expect(screen.queryByTestId('browser-qr-code-container')).toBeFalsy();
        const scanBarcodeButton = screen.getByTestId('qr-code-reader-hide-scan');
        await userEvent.click(scanBarcodeButton);
        await waitFor(() => {
          expect(screen.getByTestId('browser-qr-code-container')).toBeTruthy();
        });
      });
    });
  });

  describe('when redirection from cart', () => {
    const cartItemWithoutModifiersParams = {
      id: omsOrderableMenuItemId,
      facilityId,
      date: menuDate,
      menuId: omsOrderableMenuId,
      cartItem: cartMenuPortionItemWithoutModifiersId,
    };

    const cartItemWithModifiersParams = {
      id: omsOrderableMenuItemId,
      facilityId,
      date: menuDate,
      menuId: omsOrderableMenuId,
      cartItem: cartMenuPortionItemWithModifiersId,
    };
    beforeEach(() => {
      (useSetupOption as jest.Mock).mockReturnValue({
        isActive: false,
        value: null,
        setupOption: undefined,
      });
    });
    it('when cart item has quantity of 5 and no modifiers, shows valid quantity', async () => {
      (useParams as jest.Mock).mockReturnValue(cartItemWithoutModifiersParams);
      const state = { ...stateMock, Order: { ...stateMock.Order, cart: cart } };
      await act(() => renderedComponent(ProductDetails, {}, state));

      const quantitySetterValue = screen.getByTestId('menu-cart-actions-quantity-setter-value');
      const goToCartButton = screen.getByTestId(
        `menu-cart-actions-cart-redirect-${omsOrderableMenuItemId}`
      );

      expect(quantitySetterValue.textContent).toBe(
        cartMenuPortionItemWithoutModifiers.quantity.toString()
      );

      const expectedPrice =
        omsOrderableMenuMenuItem.productPortions[0].price *
        cartMenuPortionItemWithoutModifiers.quantity;
      expect(goToCartButton.textContent).toContain(expectedPrice);
    });

    it('when cart item has quantity of 2 and no modifiers, shows valid quantity and selected modifiers', async () => {
      const state = { ...stateMock, Order: { ...stateMock.Order, cart: cart } };
      (useParams as jest.Mock).mockReturnValue(cartItemWithModifiersParams);

      await act(() => renderedComponent(ProductDetails, {}, state));

      const quantitySetterValue = screen.getByTestId('menu-cart-actions-quantity-setter-value');
      expect(quantitySetterValue.textContent).toBe(
        cartMenuPortionItemWithModifiers.quantity.toString()
      );

      // check if modifiers are selected
      const modifierQuantitySetterValue = getModifierQuantitySetterValue(modifier1ItemId1);
      expect(modifierQuantitySetterValue.textContent).toBe(
        cartMenuPortionItemWithModifiers.modifiers[0].values[0].quantity.toString()
      );
    });
  });
});

const selectPortion = async (selectedPortionName: string, toSelectPortionName: string) => {
  const portionSelector = screen.getByTestId('dropdown-input');
  const defaultPortion = getByText(portionSelector, selectedPortionName);
  // click on selected portion - this opens the dropdown which renders options list and adds to DOM
  await userEvent.click(defaultPortion);

  // select other portion
  const secondPortion = screen.getByText(new RegExp(`${toSelectPortionName}.*`));
  await userEvent.click(secondPortion);
};

const getAddToCartButton = (menuItemId: number) =>
  screen.getByTestId(`menu-cart-actions-cart-add-${menuItemId}`);

// const getNutritionListItem = (nutritionItemName: string) =>
//   screen.getByTestId(`product-details-nutritions-list-${nutritionItemName}-list-item-value`);

const getModifierItemCheckbox = (modifierItemId: number) =>
  screen.getByTestId(
    `card-modifier-list-modifier-item-checkbox-${modifierItemId}-list-item-checkbox-input`
  );
const getModifierItemIncrementButton = (modifierItemId: number) =>
  screen.getByTestId(`modifier-item-${modifierItemId}-quantity-setter-increment-button`);

const getModifierQuantitySetterValue = (modifierItemId: number) =>
  screen.getByTestId(`modifier-item-${modifierItemId}-quantity-setter-value`);

// const getModifierDecrementButton = (modifierItemId: number) =>
//   screen.getByTestId(`modifier-item-${modifierItemId}-quantity-setter-decrement-button`);
