const labels = {
  All: {
    'Ref: Assembled': '組裝',
    'Ref: Submitted': '提交',
    'Ref: Fulfilled': '實現',
    'Ref: Completed': '完全的',
    'Ref: Paid': '付費的',
    'Ref: Approved': '已確認',
    'Ref: Ready For Pickup': '已準備好取貨',
    'Ref: Delivered': '已送達',
    'Ref: Received': '已收到',
    'Ref: Rejected': '已拒絕',
    'Ref: Payment Error': '付款錯誤',
    'Ref: Pending Payment': '待付款',
    'Ref: Preparing': '準備中',
    'Ref: Refunded': '退款',
    'Ref: Cancelled By User': '由用戶取消',
    'Ref: Refund Requested': '要求退款',
    'Ref: Refund Failed': '退款失敗',
    'Ref: Payment Processing': '付款處理中',
    'Ref: Not Paid': '未支付',
    'Ref: Total:': '全部：',
    'Ref: Pickup': '取件',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '掃描和去',
    'Ref: Fulfillment Type': '履行類型',
    'Ref: Delivery': '送貨',
    'Ref: You have no future orders': '您沒有未來的訂單',
    'Ref: You have no past orders': '您沒有歷史訂單',
    'Ref: You have no orders': '您還沒有訂單',
    'Ref: All orders': '所有訂單',
    'Ref: Not past orders': '即將到來的訂單',
    'Ref: Past orders': '歷史訂單',
    'Ref: Vegan': '素食主義者',
    'Ref: Vegeterian': '素食者',
    'Ref: See item': '請參閱項目',
    'Ref: Activated': '已激活',
    'Ref: No suggestions': '找不到建議',
    'Ref: Get your receipt': '獲取收據',
    'Ref: Print': '打印收據',
    'Ref: Email': '通過電子郵件收到收據',
    'Ref: Receipt Modal message': '您想如何獲得收據？',
    'Ref: Confirm': '確認',
    'Ref: Your email address': '您的電子郵件地址',
    Suggestion: '建議',
    'Ref: Loyalty Reward': '忠誠度獎勵',
    'Ref: Equation for stamps per item': '1購買= 1郵票',
    'Ref: Paid by employer': '您的雇主支付的金額',
    'Ref: Paid by user': '您支付的金額',
  },
  ProductsList: {
    'Ref: Page title': '商品列表',
    'Ref: Facility details': '設施的詳細信息',
    'Ref: Search products list': '搜索項目',
    'Ref: filter label: menus': '菜單',
    'Ref: filter label: moment': '片刻',
    'Ref: filter label: category': '類別',
    'Ref: all': '所有',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: see more': '查看詳情',
    'Ref: see less': '隱藏詳情',
    'Ref: reset': '重置',
    'Ref: View in cart': '在購物車中查看',
    'Ref: You have changed location': '您已經更改了位置',
    'Ref: You are now at': '你現在在',
    'Ref: Menu card': '菜單卡',
    'Ref: Table': '表號',
    'Service unavailable': '暫停服務',
    'Ref: Ordering unavailable': '小心！目前在此站點上訂購是不可用的，請稍後再試。',
    'Ref: Filters': '過濾器（過敏原等）',
  },
  ProductScanner: {
    'Ref: cart': '大車',
    'Ref: Product Scanned': '被添加到購物車中。您可以繼續掃描條形碼以將其添加到購物車中。',
    'Ref: Scan or adjust': '被添加到購物車中。調整數量或掃描其他商品。',
    'Ref: Product Not Matched': '條形碼未被認可。請再試一次',
    'Ref: Scan article code': '掃描條形碼',
    'Ref: Native error product cannot be added to cart':
      '來自不同菜單或一天部分的項目無法添加到購物車中',
    'Ref: product scanner placeholder': '請掃描條形碼以將其添加到您的購物車中',
  },
  Order: { 'Ref: Page title': '命令' },
  MenuSelector: {
    'Ref: Menu Page title': '菜單',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: Search products list': '搜索項目',
    'Ref: You have changed location': '位置已更改',
    'Ref: You are now at': '你現在位於',
  },
  ProductDetails: {
    'Ref: Page title': '產品詳情',
    'Ref: Serving Size': '份量',
    'Ref: Nutrition': '營養信息',
    'Ref: Nutrition adults': '成年人每天需要大約2000千卡',
    'Ref: Nutrition Calories': '卡路里',
    'Ref: Nutrition Calcium': '鈣',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '飽和脂肪',
    'Ref: Nutrition Carbohydrates': '碳水化合物',
    'Ref: Nutrition Sugar': '糖',
    'Ref: Nutrition Protein': '蛋白',
    'Ref: Nutrition Sodium': '鈉',
    'Ref: Nutrition cholesterol': '膽固醇',
    'Ref: Nutrition dietaryFiber': '膳食纖維',
    'Ref: Nutrition iron': '鐵',
    'Ref: Nutrition potassium': '鉀',
    'Ref: Nutrition vitaminA': '維生素A',
    'Ref: Nutrition vitaminC': '維生素C',
    'Ref: Additives': '添加劑',
    'Ref: Emission': '二氧化碳排放量',
    'Ref: This product generates': '該產品產生',
    'Ref: of CO2': '二氧化碳',
    'Ref: emissions, which equals a': '排放，等於a',
    'Ref: car trip': '汽車旅行',
    'Ref: Ingredients': '食譜成分',
    'Ref: Allergens': '過敏原',
    'Ref: Always there': '總是在那裡',
    'Ref: Allergens selected': '在過濾器上選擇過敏原：',
    'Ref: minimum required': '最低要求',
    'Ref: you have to add at least': '您必須至少添加',
    'Ref: ingredients': '配料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '自由',
    'Ref: Based on customization': '根據您的自定義',
    'Ref: Additives eggs': '蛋',
    'Ref: Additives fish': '魚',
    'Ref: Additives milk': '牛奶',
    'Ref: Additives nuts': '堅果',
    'Ref: Additives wheat': '小麥',
    'Ref: Additives peanuts': '花生',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': '芝麻',
    'Ref: Review': '審查',
    'Ref: Per portion': '每部分',
    'Ref: Per 100g': '每100克',
    'Ref: Allergen selected': '在搜索過濾器上選擇的過敏原',
    'Ref: Fitbit Log': '登錄到Fitbit',
    'Ref: age verification required on delivery': '交貨需要的年齡驗證',
    'Ref: alcohol content notification':
      '通過為其中一個項目訂購併訂購，您聲明您年齡在18歲或以上。酗酒對您的健康是危險的。',
    'Ref: default': '默認',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}如果您訂購此產品',
    'Ref: Loyalty Card Description':
      '{stamp_form}將添加到以下忠誠度{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': '忠誠郵票程序使您可以收集郵票以獲得獎勵。',
    'Ref: Loyalty Modal Description':
      '當您購買<b> {product_name}時，將添加1張郵票</b>將添加到<b> {scheme_names} </b> {program_form}',
    'Ref: and': '和',
    'Ref: Stamp': '郵票',
    'Ref: Stamps': '郵票',
    'Ref: Program': '程序',
    'Ref: Programs': '程式',
    'Ref: Loyalty Stamps': '忠誠郵票',
    'Ref: See Loyalty Stamps': '查看忠誠郵票',
    Favorite: '最喜歡的',
    'Ref: In favorites': '該產品已經在收藏夾列表中。單擊以刪除它。',
    'Ref: Not in favorites': '該產品尚未在收藏夾列表中。單擊以添加。',
    'Ref: Added to favorites': '該產品已添加到收藏夾列表中。',
    'Ref: Removed from favorites': '該產品已從收藏夾列表中刪除。',
    'Ref: From': '從',
  },
  MenuCartActions: {
    'Ref: change in cart': '更改購物車',
    'Ref: Popup header for customizable item qty update': '此項目是可自定義的',
    'Ref: add new version': '添加新版本',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      '直接從購物車直接更新可自定義物品的數量。',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      '直接從購物車直接更新可自定義項目的數量，或添加新版本。',
    'Ref: another context body': '要從該站點、設施、時刻/菜單或日期添加項目，您需要清除購物車',
    'Ref: cancel': '取消',
    'Ref: add to cart': '添加到購物車',
    'Ref: view in cart': '購物車',
    'Ref: Products in cart': '{product_name}在購物車中的數量：{product_quantity}',
    'Ref: Customize': '定制',
    'Ref: Add': '添加',
    'Ref: Free': '自由的',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': '取消',
    'Ref: notice': '注意',
    'Ref: clear cart': '清除購物車',
    'Ref: Cart addition error message':
      '當前，您從{from_current}中的購物車中有項目。要添加{from_selected}的項目，您需要清除購物車',
  },
  Cart: {
    'Ref: Page title': '購物車',
    'Ref: The cart is empty': '購物車為空',
    'Ref: Clear cart': '清除購物車',
    'Ref: Continue shopping': '繼續購物',
    'Ref: Start shopping': '開始購物',
    'Ref: Today': '今天',
    'Ref: Tomorrow': '明天',
    'Ref: Tax:': '稅：',
    'Ref: Promotion:': '促銷：',
    'Ref: Total without promotion:': '無促銷總計：',
    'Ref: Delivery:': '交貨：',
    'Ref: Pickup informations': '打包信息',
    'Ref: Location': '地點',
    'Ref: Time': '時間',
    'Ref: Phone': '你的電話號碼',
    'Ref: Instructions': '指示',
    'Ref: Please select': '請選擇',
    'Ref: Enter special requests': '在此處輸入特殊請求',
    'Ref: notice': '注意',
    'Ref: Product is no more available': '抱歉，似乎某些商品不再可选。請檢查您的購物車。',
    'Ref: ok': '確認',
    'Ref: Validate': '驗證我的訂單',
    'Ref: Pay now': '現在付款',
    'Ref: Charge to my department': '向我的部門收費',
    'Ref: Pay on pickup': '收拾東西付這些物品',
    'Ref: Accept terms of sale prefix': '我接受',
    'Ref: Accept terms of sale': '這些銷售條款',
    'Ref: Continue': '繼續',
    'Ref: Please login or sign up to place an order': '請登錄或註冊以繼續',
    'Ref: Accept terms of sale suffix': '，我已經閱讀並理解了它們。',
    'Ref: Payment modality': '付款方式',
    'Ref: Other information': '其他信息',
    'Ref: Eat-in': '店內用餐',
    'Ref: Pickup': '打包帶走',
    'Ref: Table': '桌號',
    'Ref: Required fields': '必需的字段',
    'Service unavailable': '服務不可用',
    'Ref: Cannot order':
      '抱歉，您目前無法在此地點下單。請聯繫餐廳，以獲取有關開放時間和可用服務的更多信息。',
    'Ref: Order Unavailable': '訂購所選日期，履行類型和位置不再可用！',
    'Ref: No Availability': '沒有更多的可用性',
    'Ref: Continue on your device': '繼續使用您的設備',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}如果您完成訂單',
    'Ref: Loyalty Card Description':
      '{stamp_form}將添加到以下忠誠度{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': '忠誠郵票程序使您可以收集郵票以獲得獎勵。',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b>如果您完成訂單，則獲得了：',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b>為<b> {scheme_names} </b>添加',
    'Ref: Stamp': '郵票',
    'Ref: Stamps': '郵票',
    'Ref: Program': '程序',
    'Ref: Programs': '程式',
    'Ref: Loyalty Stamps': '忠誠郵票',
    'Ref: See Loyalty Stamps': '查看忠誠郵票',
    'Ref: Loyalty Info': '忠誠度信息',
    'Ref: Email address': '電子郵件地址',
    'Ref: and': '和',
    'Ref: Order minimum amount': '抱歉，最低付款金額為{minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': '繼續使用您的設備',
    'Ref: Scan QR to continue': '請掃描QR碼以繼續在設備上的旅程。',
  },
  OrderHistory: { 'Ref: Orders': '命令', 'Ref: Page Title': '我的訂單' },
  OrderHistoryDetails: {
    'Ref: Unit price': '單價',
    'Ref: Download': '下載收據',
    'Ref: Download error': '下載錯誤發生了。稍後嘗試下載收據',
    'Ref: Order details title': '訂單詳細信息',
  },
  SuccessOrder: {
    'Ref: Order confirmed': '謝謝！您的訂單現在已確認',
    'Ref: Text for pickup':
      '您可以通過訂單歷史記錄遵循其狀態。如果您的通知已打開，我們將在準備拾取時更新您。',
    'Ref: Success Order Title': '謝謝您的訂單',
    'Ref: Success Order Description': '請在結帳時付款以驗證您的訂單。',
    'Ref: Success Order ID': 'ID訂單',
    'Ref: Success Order Action': '新命令',
    'Ref: Text for dinein':
      '您點的菜很快就會送達您的餐桌。 只需向我們友好的工作人員詢問您的就餐訂單即可。',
    'Ref: Text for scan&go': '您的訂單可在您的訂單歷史記錄中可用',
    'Ref: Text for delivery':
      '您可以在訂單歷史記錄中跟踪其狀態。如果啟用了您的通知，我們將在訂單準備就緒時通知您。',
    'See details': '查看詳情',
    'Go to home': '返回主頁',
  },
  FailOrder: {
    'Go to home': '返回主頁',
    'Ref: Kiosk Fail Title': '哎呀！出了些問題！',
    'Ref: Kiosk Fail Description': '不幸的是，您的訂單沒有成功。請重試或要求工作人員為您提供幫助。',
    'Ref: Kiosk Fail Back': '返回上一頁',
    'Ref: Kiosk Fail Action': '清除會話並再次訂購',
    'Ref: Error in cart validation': '下單時發生錯誤。請聯繫該機構以解決問題。',
    'Facility contact': '機構聯繫方式',
    'Ref: Ordering failed': '訂購失敗',
    'Ref: Order confirmed': '訂單已確認',
  },
  TableCheckIn: {
    'Welcome at': '歡迎來',
    Table: '桌子：',
    'Ref: list title': '今天的最新訂單',
    'See the menu': '查看菜單',
    'Table Check-in': '餐桌登记',
    'Ref #': '參考＃',
    Checked: '檢查',
    'Check in': '簽到',
    'See details': '閱讀詳情',
    'Ref: You have changed location': '您的位置已更改',
    'Ref: You are now at': '您現在位於',
    'Order number': '訂單號',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': '應用促銷',
    'Ref: Promotion applied description': '您使購物車的促銷活動受益。',
    'Ref: Promotion deleted title': '促銷已刪除',
    'Ref: Promotion deleted description':
      '由于您不再满足条件或促销活动已过期，您不再享受促销活动。',
  },
  MenuTile: { 'Ref: Scan&Go': '掃描和去', 'Ref: View only': '只讀' },
  ProductTile: {
    'Ref: Allergens': '過敏原',
    'Ref: Presence of allergen': '過敏原的存在',
    'Ref: Scan': '掃描',
    'Ref: From': '從',
    'Ref: Already in product favorite list. Click to remove it.':
      '已經在產品最喜歡的列表中了。單擊以刪除它。',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '目前尚未添加到產品喜歡的列表中。單擊以添加。',
    'Ref: Free': '自由的',
  },
  OrderSummary: {
    'Ref: item': '物品',
    'Ref: items': '項目',
    'Ref: Clear': '清除',
    'Ref: Cart': '大車',
    'Ref: Subtotal': '小計',
    'Ref: Total': '全部的',
    'Ref: Tax': '稅',
    'Ref: Promotion': '促銷',
    'Ref: Total without promotion': '無促銷總計',
    'Ref: Number of product in cart': '購物車中的商品數量',
    'Ref: Delivery': '送貨',
  },
  CartItemTile: { 'Ref: Vegan': '素食主義者', 'Ref: Vegeterian': '素食主義者' },
  OrderInfoCard: {
    'Ref: Header': '訂購',
    'Ref: from': '從',
    'Ref: location': '地點',
    'Ref: Phone': '你的電話號碼',
    'Ref: Instructions': '指示',
    'Ref: Order type': '訂單類型',
    'Ref: Pickup': '打包',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '掃描和去',
  },
  ReorderWidget: { 'Ref: No items': '尚未下達命令。現在去購物！', 'Ref: Title': '再次訂購' },
  MenusWidget: { 'Ref: no menu': '目前沒有菜單', 'Ref: Modal title': '一天的菜單' },
  PromotionInfo: { 'Ref: Discounts available': '可用的折扣' },
  PromotionInfoItem: { 'Ref: Activate': '激活' },
  OrderHistoryWidget: { 'Ref: Your Orders': '您的訂單', 'Ref: Widget Title': '我的訂單' },
  SuggestionsWidget: {
    'Ref: Modal title': '一天的建議',
    'Ref: Any menu': '任何菜單',
    Other: '其他',
    'Ref: Suggestions calendar button': '建議日曆',
  },
  ModifierCard: {
    'Ref: Serving Size': '份量',
    'Ref: Nutrition': '營養信息',
    'Ref: Nutrition adults': '成年人每天需要大約2000千卡',
    'Ref: Nutrition Calories': '卡路里',
    'Ref: Nutrition Calcium': '鈣',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '飽和脂肪',
    'Ref: Nutrition Carbohydrates': '碳水化合物',
    'Ref: Nutrition Sugar': '糖',
    'Ref: Nutrition Protein': '蛋白',
    'Ref: Nutrition Sodium': '鈉',
    'Ref: Nutrition cholesterol': '膽固醇',
    'Ref: Nutrition dietaryFiber': '膳食纖維',
    'Ref: Nutrition iron': '鐵',
    'Ref: Nutrition potassium': '鉀',
    'Ref: Nutrition vitaminA': '維生素A',
    'Ref: Nutrition vitaminC': '維生素C',
    'Ref: Ingredients': '食譜成分',
    'Ref: Allergens': '過敏原',
    'Ref: Always there': '總是在那裡',
    'Ref: Allergens selected': '在過濾器上選擇過敏原：',
    'Ref: minimum required': '最低要求',
    'Ref: ingredients': '配料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '自由',
    'Ref: Based on customization': '根據您的自定義',
    'Ref: Additives eggs': '蛋',
    'Ref: Additives fish': '魚',
    'Ref: Additives milk': '牛奶',
    'Ref: Additives nuts': '堅果',
    'Ref: Additives wheat': '小麥',
    'Ref: Additives peanuts': '花生',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': '芝麻',
    'Ref: Review': '審查',
    'Ref: Per portion': '每部分',
    'Ref: Per 100g': '每100克',
    'Ref: Modifier item number of free items': '•{number_of_free_items}免費',
    'Ref: Number of free items': '免費項目數： {number_of_free_items}',
    'Ref: Allergen selected': '在搜索過濾器上選擇的過敏原',
    'Ref: Nutritional info': '營養信息',
    'Ref: Info': '信息',
  },
  PromotionsMiniWidget: { 'Ref: Title': '促銷活動' },
  DiscountTiersModal: {
    'Ref: Choose your job': '選擇你的工作',
    'Ref: Validate': '確認',
    'Ref: Pricing Tiers dialog message': '您已通過電子郵件地址認可',
    'Ref: Please select your job in the company': '請選擇您在公司的崗位',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '打包',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '掃描和去',
    'Ref: Delivery': '送貨',
    'Ref: Total': '全部的',
    'Ref: How was facility name service': '{facility_name}的服務如何？',
    'Ref: How do you rate the service': '您如何評價服務？',
  },
};
export default labels;
