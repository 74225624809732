import { buildAllergens, getDishes, getFilters } from '../productListFilters.helper';

import { Filter, FilterType } from '@/components/atoms/Filters/Filters.types';
import * as featureFlags from '@/modules/Order/helpers/feature.flags.helper';
import { Allergen, AllergenType, FacilityMenuSource } from '@/modules/Order/types/orderState.types';
import { ProductListItem, Section } from '@/modules/Order/types/productList.types';

const mockLabel = (label: string) => label;

const setHideAllergens = jest.fn((val: boolean) => {});
const setSelectedAllergens = jest.fn((allergens: AllergenType[]) => {});
const setSelectedMoment = jest.fn((moment: string) => {});
const filter = jest.fn((items: ProductListItem[]) => items);

const menuItemsList: ProductListItem[] = [
  {
    id: '1_2',
    menuItemId: 2,
    uomId: 222,
    title: '',
    description: '',
    imageUrl: '',
    price: 12,
    viewablePrices: undefined,
    calories: NaN,
    isVegan: true,
    isVegetarian: true,
    isMindful: true,
    isFavorite: false,
    category: '',
    subCategory: undefined,
    moment: 'Any',
    showAllergensWarning: true,
    allergens: [AllergenType.Alcohol, AllergenType.Almonds, AllergenType.Cashews],
    genericCategory: '',
  },
  {
    id: '2_2',
    menuItemId: 3,
    uomId: 223,
    title: '',
    description: '',
    imageUrl: '',
    price: 13,
    viewablePrices: undefined,
    calories: NaN,
    isVegan: true,
    isVegetarian: true,
    isMindful: true,
    isFavorite: false,
    category: '',
    subCategory: undefined,
    moment: 'Any',
    showAllergensWarning: false,
    allergens: [AllergenType.Cashews],
    genericCategory: '',
  },
];

const sections: Section[] = [
  { title: 'First Section', filter },
  { title: 'Second Section', filter },
];

const allergens: Allergen[] = [
  { allergen: AllergenType.Alcohol, contains: true, id: AllergenType.Alcohol, name: 'Alcohol' },
  { allergen: AllergenType.Almonds, contains: true, id: AllergenType.Almonds, name: 'Almonds' },
  { allergen: AllergenType.Cashews, contains: true, id: AllergenType.Cashews, name: 'Cashews' },
];

describe('buildCartModifiers', () => {
  it('should return cart modifiers', () => {
    const result = getDishes(mockLabel);

    expect(result[0].value).toBe('vegan');
    expect(result[0].label).toBe('Ref: Vegan');
    expect(result[0].icon).not.toBe(null);

    expect(result[1].value).toBe('vegetarian');
    expect(result[1].label).toBe('Ref: Vegeterian');
    expect(result[1].icon).not.toBe(null);

    expect(result[2].value).toBe('mindful');
    expect(result[2].label).toBe('Ref: Mindful');
    expect(result[2].icon).not.toBe(null);
  });
});

describe('getFilters', () => {
  describe('getFilters with multiple moments and multiple sections', () => {
    let result: Filter[];
    beforeAll(() => {
      jest.spyOn(featureFlags, 'isFFProductFavouriteEnabled').mockImplementation(() => true);

      result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections,
        moments: ['anytyme', 'now'],
        allergens,
        hideAllergens: false,
        isGuest: false,
        menuSource: FacilityMenuSource.Rr,
        setHideAllergens,
        selectedAllergens: [AllergenType.Alcohol],
        setSelectedAllergens,
        setSelectedMoment,
      });
    });

    it('should return proper number of filters ', () => {
      expect(result.length).toBe(7);
    });

    it('should return proper filter for Moment filtering', () => {
      expect(result[0].name).toBe('Ref: filter label: moment');
      expect(result[0].displayType).toBe('DROPDOWN');
      expect(result[0].id).toBe('filter_moment');
      expect(result[0].multiple).toBe(false);
      expect(result[0].options).toStrictEqual([
        {
          label: 'anytyme',
          value: 'anytyme',
        },
        {
          label: 'now',
          value: 'now',
        },
      ]);
      expect(result[0].position).toBe('NOT_IN_MODAL');
      expect(result[0].text).toBe(undefined);
      expect(result[0].withTitle).toBe(false);
      expect(result[0].wrapperClassName).toBe(undefined);
    });

    it('should return proper filter for Category filtering', () => {
      expect(result[1].name).toBe('Ref: filter label: category');
      expect(result[1].id).toBe('filter_category');
      expect(result[1].multiple).toBe(false);
      expect(result[1].options).toStrictEqual([
        {
          default: true,
          label: 'Ref: all',
          value: 'all',
        },
        {
          label: 'First Section',
          value: 'First Section',
        },
        {
          label: 'Second Section',
          value: 'Second Section',
        },
      ]);
      expect(result[1].position).toBe('NOT_IN_MODAL');
      expect(result[1].text).toBe(undefined);
      expect(result[1].withTitle).toBe(false);
      expect(result[1].wrapperClassName).toBe(undefined);
      expect(result[1].displayType).toBe('EXPANDED');
    });

    it('should return proper filter for Favorites filtering', () => {
      expect(result[2].name).toBe('Ref: filter label: favorites');
      expect(result[2].id).toBe('filter_favorites');
      expect(result[2].multiple).toBe(false);
      expect(result[2].options).toStrictEqual([
        {
          label: 'filter',
          value: 'favorites',
        },
      ]);
      expect(result[2].position).toBe('ALWAYS_IN_MODAL');
      expect(result[2].text).toBe(undefined);
      expect(result[2].withTitle).toBe(false);
      expect(result[2].wrapperClassName).toBe(undefined);
      expect(result[2].displayType).toBe('CHECKBOX_SINGLE');
    });

    it('should return proper filter for Dishes filtering', () => {
      const expectedOptions = getDishes(mockLabel);

      expect(result[3].name).toBe('Ref: filter label: dishes');
      expect(result[3].id).toBe('filter_dishes');
      expect(result[3].multiple).toBe(true);
      expect(result[3].options).toStrictEqual(expectedOptions);
      expect(result[3].position).toBe('ALWAYS_IN_MODAL');
      expect(result[3].text).toBe(undefined);
      expect(result[3].withTitle).toBe(undefined);
      expect(result[3].wrapperClassName).toBe(undefined);
      expect(result[3].displayType).toBe('MULTIPLE_SELECT');
    });

    it('should return proper filter for Calories filtering', () => {
      expect(result[4].name).toBe('Ref: filter label: max calories');
      expect(result[4].id).toBe('filter_calories');
      expect(result[4].multiple).toBe(false);
      expect(result[4].options).toStrictEqual(undefined);
      expect(result[4].position).toBe('ALWAYS_IN_MODAL');
      expect(result[4].text).toBe(undefined);
      expect(result[4].withTitle).toBe(undefined);
      expect(result[4].wrapperClassName).toBe(undefined);
      expect(result[4].displayType).toBe(FilterType.RANGE);
    });

    it('should return proper filter for Allergens filtering', () => {
      const expectedOptions = buildAllergens(allergens);

      expect(result[5].name).toBe('Ref: filter label: allergens');
      expect(result[5].id).toBe('filter_allergens');
      expect(result[5].multiple).toBe(true);
      expect(result[5].options).toStrictEqual(expectedOptions);
      expect(result[5].position).toBe('ALWAYS_IN_MODAL');
      expect(result[5].text).toBe(undefined);
      expect(result[5].withTitle).toBe(undefined);
      expect(result[5].wrapperClassName).toBe(undefined);
      expect(result[5].displayType).toBe(FilterType.MULTIPLE_SELECT);
    });

    it('should return proper filter for Allergen highlight / hiding', () => {
      expect(result[6].name).toBe('Ref: filter label: highlight');
      expect(result[6].id).toBe('filter_allergens_highlight');
      expect(result[6].multiple).toBe(false);
      expect(result[6].options).toStrictEqual([
        { label: 'Ref: Highlight', value: 'highlight' },
        { label: 'Ref: Hide', value: 'hide' },
      ]);
      expect(result[6].position).toBe('ALWAYS_IN_MODAL');
      expect(result[6].text).toBe('Ref: Highlight text');
      expect(result[6].withTitle).toBe(undefined);
      expect(result[6].wrapperClassName).toBe(undefined);
      expect(result[6].displayType).toBe(FilterType.MULTIPLE_SELECT);
    });
  });

  describe('getFilters - requesting with 1 moment and 1 section', () => {
    it('should return cart modifiers result position as INVISIBLE for moment & no options for category filter', () => {
      jest.spyOn(featureFlags, 'isFFProductFavouriteEnabled').mockImplementation(() => true);

      const result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections: [{ title: 'First Section', filter }],
        moments: ['anytyme'],
        allergens,
        hideAllergens: false,
        isGuest: false,
        menuSource: FacilityMenuSource.Rr,
        setHideAllergens,
        selectedAllergens: [AllergenType.Alcohol],
        setSelectedAllergens,
        setSelectedMoment,
      });

      expect(result[0].position).toBe('INVISIBLE');
      expect(result[1].options).toStrictEqual([]);
    });
  });

  describe('getFilters with guest user', () => {
    let result: Filter[];
    jest.spyOn(featureFlags, 'isFFProductFavouriteEnabled').mockImplementation(() => true);
    beforeAll(() => {
      result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections,
        moments: ['anytyme', 'now'],
        allergens,
        hideAllergens: false,
        isGuest: true,
        menuSource: FacilityMenuSource.Rr,
        setHideAllergens,
        selectedAllergens: [AllergenType.Alcohol],
        setSelectedAllergens,
        setSelectedMoment,
      });
    });

    it('should return proper number of filters ', () => {
      expect(result.length).toBe(6);
    });

    it('should not return favorites filter', () => {
      expect(result.filter((x) => x.name === 'Ref: filter label: favorites')).toHaveLength(0);
    });
  });

  describe('getFilters when FF (Feature Flag) Product Favourite is Disabled', () => {
    let result: Filter[];
    jest.spyOn(featureFlags, 'isFFProductFavouriteEnabled').mockImplementation(() => false);

    beforeAll(() => {
      result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections,
        moments: ['anytyme', 'now'],
        allergens,
        hideAllergens: false,
        isGuest: false,
        menuSource: FacilityMenuSource.Rr,
        setHideAllergens,
        selectedAllergens: [AllergenType.Alcohol],
        setSelectedAllergens,
        setSelectedMoment,
      });
    });

    it('should return proper number of filters ', () => {
      expect(result.length).toBe(6);
    });

    it('should not return favorites filter', () => {
      expect(result.filter((x) => x.name === 'Ref: filter label: favorites')).toHaveLength(0);
    });
  });

  describe('getFilters when Ifm menu', () => {
    let result: Filter[];
    beforeAll(() => {
      result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections,
        moments: ['anytyme', 'now'],
        allergens,
        hideAllergens: false,
        isGuest: false,
        menuSource: FacilityMenuSource.Ifm,
        setHideAllergens,
        selectedAllergens: [AllergenType.Alcohol],
        setSelectedAllergens,
        setSelectedMoment,
      });
    });

    it('should return proper number of filters ', () => {
      expect(result.length).toBe(4);
    });

    it('should not return dishes & max calories filters', () => {
      expect(
        result.filter(
          (x) =>
            x.name === 'Ref: filter label: dishes' || x.name === 'Ref: filter label: max calories'
        )
      ).toHaveLength(0);
    });
  });

  describe('buildCartModifiers without allergens', () => {
    let result: Filter[];
    beforeAll(() => {
      result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections,
        moments: ['anytyme', 'now'],
        allergens: [],
        hideAllergens: false,
        isGuest: false,
        menuSource: FacilityMenuSource.Ifm,
        setHideAllergens,
        selectedAllergens: [],
        setSelectedAllergens,
        setSelectedMoment,
      });
    });

    it('should return proper number of filters ', () => {
      expect(result.length).toBe(2);
    });

    it('should not return allergen filter', () => {
      expect(result.filter((x) => x.name === 'Ref: filter label: allergens')).toHaveLength(0);
    });
  });
});
