import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BarcodeIcon } from '../../../assets/icons';
import QrCodeReader from '../../../components/molecules/QrCode/QrCodeReader';
import { QRCodeReaderResponse } from '../../../components/molecules/QrCode/reader.types';
import { waitBetweenScansInMs } from '../config';
import CartButton from '../containers/CartButton';
import { tryAddScannedItemToCart } from '../helpers/productScanner.helper';
import { onProductScanned } from '../helpers/productScanner.helper';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import useProductInsightsLogging from '../hooks/useProductInsightsLogging/useProductInsightsLogging';
import { CartContextInfo, PageType } from '../types/menuCartActions.types';
import { MenuItem } from '../types/orderState.types';
import { ProductScannerProps } from '../types/productScanner.types';

import CartAnotherContextPopup from './CartAnotherContextPopup/CartAnotherContextPopup';
import ScannerProductDetail from './ScannerProductDetail';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { store } from '@/store/index';
import { UserSteps } from '@/types/userSteps.types';

import styles from './ProductScanner.module.css';

const ProductScanner = ({
  menuItems,
  facilityId,
  date,
  menuId,
  site,
  languageCode,
  isScanOpen = true,
  cartModificationDisabled,
  onDismiss,
  hideScanButton,
  onAddFirstItemToCart,
  barCode,
  ...props
}: ProductScannerProps & React.HTMLAttributes<HTMLDivElement>) => {
  const [currentCartProductId, setCurrentCartProductId] = useState<number | null | undefined>();
  const dispatch = useDispatch();
  const [scannedMenuItem, setScannedMenuItem] = useState<MenuItem | undefined>();
  const [cartContextInfo, setCartContextInfo] = useState<CartContextInfo>();
  const { logAddingProductToCart } = useProductInsightsLogging();
  const { logUserSteps } = useUserStepsInsightsLogging();
  const { label } = useOrderTranslation(__filename);

  const validateQRCodeScan = useCallback(({ response: qrCode }: QRCodeReaderResponse) => {
    const { createOrderDraft } = store.getState().Order.locks;
    if (!qrCode || createOrderDraft) return false;

    return true;
  }, []);

  const handleQRCodeScan = useCallback(
    async ({ response: qrCode }: QRCodeReaderResponse) => {
      let barcode = qrCode!;
      const qrCodeText = qrCode!.toString();
      if (qrCodeText.toLowerCase().includes('http')) {
        barcode = qrCodeText.substring(qrCodeText.lastIndexOf('/') + 1);
      }

      barcode = barcode.trim();

      const scannedProduct = menuItems?.find((x) =>
        x.productPortions.some((pp) => pp.uomBarcodes.includes(barcode))
      );

      const scannedPortion = scannedProduct?.productPortions.find((x) =>
        x.uomBarcodes.includes(barcode)
      );

      let addedToCart = false;
      setScannedMenuItem(scannedProduct);

      if (scannedProduct && scannedPortion) {
        const cartContextInfo = await tryAddScannedItemToCart({
          date,
          menuId,
          scannedProduct,
          scannedPortion,
          facilityId,
          siteId: site.id,
          dispatch,
        });

        setCartContextInfo(cartContextInfo);
        if (cartContextInfo?.isAnotherOrderContext) {
          return;
        }

        addedToCart = true;
      }

      onProductScanned({
        scannedProduct,
        addedToCart,
        onAddFirstItemToCart,
        label,
      });

      if (addedToCart && scannedPortion) {
        setCurrentCartProductId(scannedPortion.uomId);
        logAddingProductToCart(menuId, scannedPortion.uomId, PageType.scanner, false);
        logUserSteps({ event: UserSteps.AddedToCart, menuId });
      }
    },
    [
      menuItems,
      onAddFirstItemToCart,
      label,
      date,
      menuId,
      facilityId,
      site.id,
      dispatch,
      logAddingProductToCart,
      logUserSteps,
    ]
  );

  const handleDismiss = () => {
    onDismiss && onDismiss();
    setCurrentCartProductId(undefined);
  };

  useEffect(() => {
    if (barCode) handleQRCodeScan({ response: barCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCode]);

  return (
    <div className={styles.notDisplay} {...props}>
      {cartContextInfo && scannedMenuItem && (
        <CartAnotherContextPopup
          cartDifferentContextType={cartContextInfo.reason}
          menuId={menuId}
          menuDate={date}
          menuItem={scannedMenuItem}
          onDismiss={() => setCartContextInfo(undefined)}
          data-testid="cart-another-context-popup"
        ></CartAnotherContextPopup>
      )}
      <QrCodeReader
        label={label}
        buttonLabel={label('Ref: Scan article code')}
        title={label('Ref: Scan article code')}
        isScanOpen={isScanOpen}
        onDismiss={handleDismiss}
        buttonProps={{
          look: 'primary',
          affix: BarcodeIcon,
          contentCenterAlign: true,
          inheritStyle: styles.scannerBtn,
          isMultiline: false,
          'data-testid': 'product-scanner-scan-article',
        }}
        onQrCodeScan={handleQRCodeScan}
        validateQrCodeScan={validateQRCodeScan}
        preventCloseAfterScan={true}
        timeBetweenScans={waitBetweenScansInMs}
        actions={[
          <CartButton
            key="scanner-cart-button"
            isoCode={site.currency?.isoCode}
            languageCode={languageCode}
          >
            {label('Ref: cart')}
          </CartButton>,
        ]}
        hideScanButton={hideScanButton}
      >
        <ScannerProductDetail
          cartProductId={currentCartProductId ?? null}
          date={date}
          menuId={menuId}
          labelPlaceholder={label('Ref: product scanner placeholder')}
        />
      </QrCodeReader>
    </div>
  );
};
export default ProductScanner;
