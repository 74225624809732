import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { InformationIcon } from '../../../../assets/icons';
import Tooltip, { TOOLTIP_POSITION } from '../../../../components/atoms/Tooltip';
import { SETUP_OPTIONS } from '../../../../constants';
import { cleanCart } from '../../actions';
import { orderConfig } from '../../config';
import { checkIfUserIsSubsidised, getPrice } from '../../helpers/order.helper';
import { getPromotionsTooltipContent } from '../../helpers/promotions.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { DeliveryOptionType } from '../../types/cart.types';
import { OrderSummaryProps } from '../../types/orderSummary.types';
import { ClearCartButton } from '../ClearCartButton/ClearCartButton';

import { TOOLTIP_VARIANTS } from '@/components/molecules/TooltipWrapper';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { SERVICE } from '@/modules/config';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';

import styles from './OrderSummary.module.css';

const OrderSummary = ({
  subTotal,
  total,
  tax,
  subsidy,
  delivery,
  deliveryOptionType,
  promotionDiscount,
  appliedPromotions,
  productsCount,
  isCartUpToDate = true,
  showClearCart = false,
}: OrderSummaryProps) => {
  const dispatch = useDispatch();
  const { currency } = useSite()!;
  const { currentLanguageCode: languageCode } = useLanguage();
  const currencyIsoCode = currency?.isoCode ?? '';
  const { label } = useOrderTranslation(__filename);

  const { isCartDeliveryEnabled } = orderConfig();
  const subTotalPrice = getPrice(subTotal, languageCode, currencyIsoCode);
  const totalPrice = getPrice(total, languageCode, currencyIsoCode);
  const taxTotal = getPrice(tax, languageCode, currencyIsoCode);
  const deliveryTotal =
    delivery !== undefined ? getPrice(delivery, languageCode, currencyIsoCode) : undefined;
  const promotionDiscountTotal = promotionDiscount
    ? getPrice(promotionDiscount, languageCode, currencyIsoCode)
    : null;
  const totalWithoutPromotion = promotionDiscount
    ? getPrice(total + promotionDiscount, languageCode, currencyIsoCode)
    : null;

  const isUserSubsidised = checkIfUserIsSubsidised(subsidy);
  const subsidyTotal = getPrice(subsidy, languageCode, currencyIsoCode);
  const totalPriceNotLoweredBySubsidy = isUserSubsidised
    ? getPrice(total + subsidy!, languageCode, currencyIsoCode)
    : null;

  const promotionsTooltip = getPromotionsTooltipContent(appliedPromotions);

  const hideTaxAndSubtotalAmount = useIsSetupOptionEnabled(
    SETUP_OPTIONS.RETAIL.hideTaxAndSubtotalAmount,
    SERVICE.RETAIL
  );

  return (
    <div aria-live="polite" aria-atomic="true" className={classNames(styles.totalHeader)}>
      <h2 className={classNames(styles.headerTitleWrapper)}>
        <p className={classNames(styles.headlineXS, styles.headerWrap)}>
          <span>{showClearCart ? label('Ref: Cart') : label('Ref: Total')}</span> <span>•</span>
          {isCartUpToDate ? (
            <span className={classNames(styles.headlineXS)}>{productsCount}</span>
          ) : (
            <span className={styles.loading}></span>
          )}
          <span>{productsCount > 1 ? label('Ref: items') : label('Ref: item')}</span>
        </p>
        {showClearCart ? (
          <p className={classNames(styles.headlineXS)}>
            {<ClearCartButton onClick={() => dispatch(cleanCart())} title={label('Ref: Clear')} />}
          </p>
        ) : (
          <p className={classNames(styles.headlineXS)}>{totalPrice}</p>
        )}
      </h2>
      <ul className={styles.list} data-testid="order-summary-list">
        {!hideTaxAndSubtotalAmount && (
          <>
            <li
              className={classNames(styles.headerTitleWrapper)}
              data-testid="order-summary-subtotal"
            >
              <p className={classNames(styles.bodySBold)}>{label('Ref: Subtotal')}</p>
              {isCartUpToDate ? (
                <p test-id="summary-total">{subTotalPrice}</p>
              ) : (
                <span className={styles.loading}></span>
              )}
            </li>

            <li className={classNames(styles.headerTitleWrapper)} data-testid="order-summary-tax">
              <p className={classNames(styles.bodySBold)}>{label('Ref: Tax')}</p>
              {isCartUpToDate ? <p>{taxTotal}</p> : <span className={styles.loading}></span>}
            </li>
          </>
        )}
        {isCartDeliveryEnabled &&
          deliveryOptionType &&
          deliveryOptionType !== DeliveryOptionType.ScanAndGo &&
          deliveryTotal && (
            <li
              className={classNames(styles.headerTitleWrapper)}
              data-testid="order-summary-delivery"
            >
              <p className={classNames(styles.bodySBold)}>{label('Ref: Delivery')}</p>
              {isCartUpToDate ? <p>{deliveryTotal}</p> : <span className={styles.loading}></span>}
            </li>
          )}

        {promotionDiscountTotal && (
          <>
            <li
              className={classNames(styles.headerTitleWrapper, styles.divider)}
              data-testid="order-summary-total-without-promotion"
            >
              <p className={classNames(styles.bodySBold)}>
                {label('Ref: Total without promotion')}
              </p>
              {isCartUpToDate ? (
                <p>{totalWithoutPromotion}</p>
              ) : (
                <span className={styles.loading}></span>
              )}
            </li>
            <li
              className={classNames(styles.headerTitleWrapper)}
              data-testid="order-summary-promotion"
            >
              <div className={classNames(styles.bodySBold)}>
                {label('Ref: Promotion')}
                {!!promotionsTooltip && (
                  <Tooltip
                    place={TOOLTIP_POSITION.Top}
                    tooltipId={TOOLTIP_VARIANTS.DEFAULT}
                    children={<InformationIcon />}
                    content={promotionsTooltip}
                  />
                )}
              </div>
              {isCartUpToDate ? (
                <p>- {promotionDiscountTotal}</p>
              ) : (
                <span className={styles.loading}></span>
              )}
            </li>
          </>
        )}
        <li
          className={classNames(styles.headerTitleWrapper, {
            [styles.divider]: !hideTaxAndSubtotalAmount,
          })}
          data-testid="order-summary-total"
        >
          <p className={classNames(styles.bodySBold)}>{label('Ref: Total')}</p>
          {isCartUpToDate ? (
            <p className={classNames(styles.bodySBold)}>
              {isUserSubsidised ? totalPriceNotLoweredBySubsidy : totalPrice}
            </p>
          ) : (
            <span className={styles.loading}></span>
          )}
        </li>
        {isUserSubsidised && (
          <>
            <li
              className={classNames(styles.headerTitleWrapper)}
              data-testid="order-summary-paid-by-employer"
            >
              <p className={classNames(styles.bodySBold)}>{label('Ref: Paid by employer')}</p>
              {isCartUpToDate ? (
                <p className={classNames(styles.bodyS)}>{subsidyTotal}</p>
              ) : (
                <span className={styles.loading}></span>
              )}
            </li>
            <li
              className={classNames(styles.headerTitleWrapper)}
              data-testid="order-summary-paid-by-user"
            >
              <p className={classNames(styles.bodySBold)}>{label('Ref: Paid by user')}</p>
              {isCartUpToDate ? (
                <p className={classNames(styles.bodySBold)}>{totalPrice}</p>
              ) : (
                <span className={styles.loading}></span>
              )}
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default OrderSummary;
