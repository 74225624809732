const labels = {
  ReviewForm: {
    'Ref: Page title': 'レビュー',
    'Ref: How was dining with us': '食事はいかがでしたか？',
    'Ref: Submit': '送信',
    'Ref: Filter range': 'フィルター範囲',
    'Ref: Some information is missing': 'いくつかの情報がありません',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': '匿名で送信します',
    'Ref: Your email address': 'あなたのメールアドレス',
    'Ref: Email field placeholder': 'メールアドレスを入力してください',
    'Ref: Invalid email format': '無効なメール形式',
  },
  SuccessPage: {
    'Ref: Body': 'レビューの送信ありがとうございます。サービスの改善の参考にいたします。',
    'Ref: Go to Feedback': 'フィードバックに移動します',
    'Ref: Go to home': 'ホームに戻る',
    'Ref: Go to Facilities': '施設に行きます',
    'Ref: Go to Product Details': '製品の詳細に移動',
    'Ref: Go to Facility Details': '施設の詳細に移動',
  },
  FacilityPicker: {
    'Ref: Page title': 'レビュー',
    'Ref: Search facility name': '施設名を検索します',
  },
};
export default labels;
