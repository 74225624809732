const labels = {
  All: {
    'Ref: Redeem coffee description': 'Secangkir kopi gratis dan baru Anda menunggu',
    'Ref: Congratulations': 'Selamat',
    'Ref: Get free coffee': 'Dapatkan kopi gratis!',
    'Ref: Free coffee description': 'Isi cangkir Anda, dapatkan poin menuju kopi gratis.',
    'Ref: Redeem': 'Menukarkan!',
  },
  StampPage: {
    'Ref: Page title': 'Kopi gratis',
    'Ref: seconds left': 'detik tersisa',
    'Ref: How it works': 'Bagaimana itu bekerja',
    'Ref: How it works description':
      'Setiap kali Anda membeli kopi, tekan tombol pemindaian dan pindai kode QR yang disediakan di kasir.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Pindai kode QR',
    'Ref: Collect reward': 'Mengumpulkan penghargaan',
    'Ref: Reward collected': 'Hadiah dikumpulkan',
    'Ref: Invalid QR Code': 'Kode QR tidak valid',
    'Ref: Invalid QR Code description': 'Kode QR tidak dikenali',
    'Ref: Close': 'Menutup',
    'Ref: Redeem button': 'Menukarkan',
    "Ref: The QR Code isn't recognized": 'Kode QR tidak dikenali',
    'Ref: Cancel': 'Membatalkan',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Selamat!',
    'Ref: Confirm redeem description':
      'Yakin Anda ingin menggunakan ini? Jika demikian, Anda hanya memiliki 60 detik untuk menampilkan ponsel Anda di kasir dan menyelesaikan pembelian Anda',
    'Ref: Congratulations modal description': 'Ambil kopi gratis Anda sekarang!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Kopi gratis!', 'Ref: See All': 'Lihat semua' },
};
export default labels;
