import { connect } from 'react-redux';

import { State } from '../../../types/state.types';
import { convertGuestToRegisteredUsers, login, clearErrors } from '../actions';
import GuestRegistrationForm from '../components/GuestRegistrationForm';

const mapStateToProps = (state: State, { goToNext }: any) => {
  const { context } = state.Core;
  const { preferredLanguage } = state.Core.user;
  const { id: siteId, name: siteName } = context.site || {};
  const contractId = state.Core.user.contract?.id;

  const convertingGuestToRegisteredUserLocked = state.Core?.locks.convertingGuestToRegisteredUser;
  const convertingGuestToRegisteredUserError = state.Core?.errors?.convertingGuestToRegisteredUser;
  const loggingInLocked = state.Core?.locks.loggingIn;
  const getUserContextLocked = state.Core?.locks.getUserContext;

  const defaultGeoCode = state.Shared.geographies.defaultGeoCode;
  const currentGeoCode = context.currentGeoCode || defaultGeoCode;

  const currentRegionCode =
    state.Shared.geographies.list.find((geo) => geo.code === currentGeoCode)?.regionCode || '';

  return {
    currentLanguageCode: preferredLanguage?.languageCode,
    currentGeoCode,
    currentRegionCode,
    siteId,
    contractId,
    siteName,
    convertingGuestToRegisteredUserLocked,
    convertingGuestToRegisteredUserError,
    loggingInLocked,
    getUserContextLocked,
    goToNext,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    clearErrors: () => dispatch(clearErrors),
    register: (
      email?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      password?: string | null,
      contractId?: string | null,
      siteId?: string | null,
      userType?: string | null
    ) =>
      dispatch(
        convertGuestToRegisteredUsers({
          email,
          firstName,
          lastName,
          password,
          contractId,
          siteId,
          userType,
        })
      ),
    login: (
      geoCode?: string | null,
      username?: string | null,
      password?: string | null,
      currentLanguageCode?: string | null
    ) =>
      dispatch(
        login({ geoCode, username, psw: password, currentLanguageCode, scope: 'offline_access' })
      ), //this is local login, so request a refresh token
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestRegistrationForm);
