import { HubConnectionState } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { renderHook } from '@testing-library/react';
import { act } from 'react-dom/test-utils';

import { kioskHub } from '../../config';

import useKioskSessionNotification from './useKioskSessionNotification';

const mockConnection = {
  on: jest.fn(),
  off: jest.fn(),
  send: jest.fn(),
  state: HubConnectionState.Connected,
};

jest.mock('../../../../helpers/misc', () => ({
  ...jest.requireActual('../../../../helpers/misc'),
  isKiosk: true,
}));

const mockConnectionState = jest.fn();
jest.mock('../../../Core/hooks/useSignalRHubConnection', () => ({
  __esModule: true,
  default: () => ({
    connection: mockConnection,
    connectionState: mockConnectionState(),
  }),
}));

const facilityId = 'facilityId1';
jest.mock('../../../Core/helpers/login.helper', () => {
  return {
    getKioskUserFacilityId: () => facilityId,
  };
});

const mockOrderState = jest.fn();
jest.mock('../../../../store', () => ({
  store: {
    getState: () => ({
      Order: mockOrderState(),
    }),
  },
}));

const mockResetSession = jest.fn();
jest.mock('../useClearKioskSession/useResetKioskSession', () => ({
  __esModule: true,
  default: () => [mockResetSession],
}));

describe('useKioskSessionNotification hook', () => {
  const env = global.process.env;

  describe('When is Kiosk and SignalR integration enabled', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_USE_KIOSK_SIGNALR_INTEGRATION: 'true',
      };
    });

    it('Connection connected, should send and subscribe', () => {
      mockConnectionState.mockReturnValue(signalR.HubConnectionState.Connected);

      renderHook(useKioskSessionNotification);

      expect(mockConnection.send).toHaveBeenCalledWith(
        kioskHub.joinFacilityNotificationsHubMethod,
        facilityId
      );
      expect(mockConnection.on).toHaveBeenCalledWith(
        kioskHub.closeSessionHubMethod,
        expect.anything()
      );
    });

    it('Connection not connected, should unsubscribe from "closeSessionHubMethod"', () => {
      mockConnectionState.mockReturnValue(signalR.HubConnectionState.Disconnected);

      renderHook(useKioskSessionNotification);

      expect(mockConnection.off).toHaveBeenCalledWith(kioskHub.closeSessionHubMethod);
    });

    it('Session is reset when notification for active session is send', async () => {
      mockConnectionState.mockReturnValue(signalR.HubConnectionState.Connected);
      const trackingId = 'trackingId1';
      mockOrderState.mockReturnValue({
        cart: {
          submissionTrackingId: trackingId,
        },
      });

      renderHook(useKioskSessionNotification);
      act(() => {
        const closeSessionHubMethodCallback = mockConnection.on.mock.calls[0][1];
        closeSessionHubMethodCallback(trackingId);
      });

      expect(mockResetSession).toHaveBeenCalled();
    });

    it('Session is NOT reset when notification for other than active session is send', async () => {
      mockConnectionState.mockReturnValue(signalR.HubConnectionState.Connected);
      const currentActiveSessionTrackingId = 'trackingId1';
      const notificationForSessionTrackingId = 'trackingId2';
      mockOrderState.mockReturnValue({
        cart: {
          submissionTrackingId: currentActiveSessionTrackingId,
        },
      });

      renderHook(useKioskSessionNotification);

      act(() => {
        const closeSessionHubMethodCallback = mockConnection.on.mock.calls[0][1];
        closeSessionHubMethodCallback(notificationForSessionTrackingId);
      });
      expect(mockResetSession).toHaveBeenCalledTimes(0);
    });
  });
});
