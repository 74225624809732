import { SplashScreen } from '@capacitor/splash-screen';
import isEqual from 'lodash.isequal';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { reactPlugin } from '../../../appInsights';
import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '../../../components/templates/NoticePage/NoticePage.types';
import { breakPointsInPixels } from '../../../config';
import useMsTeamsContext from '../../../helpers/hooks/useMsTeamsContext/useMsTeamsContext';
import { useLanguageUpdateListener } from '../../../localization/hooks/useLanguageUpdateListener';
import { State } from '../../../types/state.types';
import useKioskSessionNotification from '../../Order/hooks/useKioskSessionNotification/useKioskSessionNotification';
import useTrackSiteVisit from '../helpers/trackSiteVisit.helper';
import useNetworkStatusListener from '../helpers/useNetworkStatusListener';
import useAppListener from '../helpers/useUrlListener';
import useErrorRedirection from '../hooks/ErrorManagement/useErrorRedirection';
import useCheckAppVersion from '../hooks/useCheckAppUpdate';
import { useCoreTranslation } from '../hooks/useCoreTranslation';
import useDataTracking from '../hooks/useDataTracking';
import useMetaViewport from '../hooks/useMetaViewport';
import useNotifications from '../hooks/useNotifications';
import { AppProps } from '../types/App.types';

import ErrorBoundary from './ErrorBoundary';

import { SidebarContextProvider } from '@/components/templates/SideBarWrapper/useSidebarContext';
import { useTrackPageView } from '@/helpers/hooks/Analytics/useTrackPageView';
import { useAuditSynchronisation } from '@/modules/Audits/hooks/useAuditSynchronisation';

import '../cssImports';

import '../../../helpers/toast.css';
import 'flag-icons/css/flag-icons.min.css';
import 'react-toastify/dist/ReactToastify.min.css';

// Params are placeholders in the URL that begin
// with a colon, like the `:id` param defined in
// the route in this example. A similar convention
// is used for matching dynamic segments in other
// popular web frameworks like Rails and Express.

const App: FC<AppProps> = ({ errors }) => {
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState<boolean>(
    window.matchMedia('(max-width: ' + breakPointsInPixels['M'] + 'px)').matches
  );
  const [isNavigationVisible, setIsNavigationVisible] = useState<boolean>(false);
  const {
    language: { currentLanguageCode: language },
  } = useSelector((state: State) => state.Shared);
  const {
    context: { site },
    user: { contactId = '', contract },
  } = useSelector((state: State) => state.Core);

  const { label } = useCoreTranslation(__filename);
  const history = useHistory();

  useTrackPageView({ isGlobal: true });
  useNetworkStatusListener();
  useAppListener();
  useLanguageUpdateListener();

  useErrorRedirection(errors);
  useDataTracking();
  useTrackSiteVisit({
    contactId,
    site,
    contract,
    language,
  });
  useMetaViewport();
  //tech debt. todo: apply only when needed and reset to default when no settings
  useCheckAppVersion({ site, contract });
  useKioskSessionNotification();
  useAuditSynchronisation(contactId);
  useMsTeamsContext();
  useNotifications(contactId);

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  const routes = (
    <Switch>
      <Route
        path="/"
        render={() => (
          <NoticePage
            type={NOTICEPAGE_TYPE.ERROR}
            title={label('This app has been retired')}
            withNavBar={false}
            withTitleBar={false}
            actions={[
              {
                label: label('Some button', { textTransform: 'capitalize' }),
                action: () => history.push('/'),
              },
            ]}
          />
        )}
      />
    </Switch>
  );

  const content = routes;

  return (
    <ErrorBoundary appInsights={reactPlugin}>
      <SidebarContextProvider
        value={{
          isSideBarCollapsed,
          setIsSideBarCollapsed,
          isNavigationVisible,
          setIsNavigationVisible,
        }}
      >
        {content}
      </SidebarContextProvider>
    </ErrorBoundary>
  );
};

export default React.memo(App, (props, nextProps) => isEqual(props, nextProps));
