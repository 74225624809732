const mockResponse = {
  getSuppliers: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        mapsAndSuppliersMapId: 1,
        name: 'testMap',
        longitude: 20.99522,
        latitude: 52.190047,
        zoomLevel: 13,
        suppliers: [
          {
            name: 'Dairy Products Supplier',
            country: 'France',
            city: 'Boulogne-Billancourt',
            postalCode: '92100',
            addressLine1: '30 Cr de lÎle Seguin',
            addressLine2: '',
            longitude: 21.007208,
            latitude: 52.1814878,
            iconUrl:
              'https://sodexoitzdevstorage.blob.core.windows.net:443/mapsandsuppliers/constantImages/Dairy_Products_3x.png',
            httpLink: 'www.dairysupplier.fr',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
          },
          {
            name: 'Vegetables Supplier',
            country: 'USA',
            city: 'New York',
            postalCode: 'NY 10005',
            addressLine1: '11 Wall Street',
            addressLine2: '',
            longitude: 20.979995,
            latitude: 52.183973,
            iconUrl:
              'https://sodexoitzdevstorage.blob.core.windows.net:443/mapsandsuppliers/constantImages/Vegetables_3x.png',
            httpLink: 'www.vegetablessupplier.com',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
          {
            name: 'Meat Supplier',
            country: 'United Kingdom',
            city: 'London',
            postalCode: 'Ba',
            addressLine1: 'My Street 123',
            addressLine2: '',
            longitude: 20.987875,
            latitude: 52.19764,
            iconUrl:
              'https://sodexoitzdevstorage.blob.core.windows.net:443/mapsandsuppliers/constantImages/meat_3x.png',
            httpLink: 'www.welovemeatsupplier.uk',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
          {
            name: 'Bakery Supplier',
            country: 'United Kingdom',
            city: 'London',
            postalCode: 'W1U 3BX',
            addressLine1: '49 Baker St',
            addressLine2: '',
            longitude: 20.978535,
            latitude: 52.204412,
            iconUrl:
              'https://sodexoitzdevstorage.blob.core.windows.net:443/mapsandsuppliers/constantImages/Baked%20Goods@3x.png',
            httpLink: 'www.swiezepieczywo.pl',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
      }),
  },
};

export default mockResponse;
