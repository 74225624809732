const labels = {
  All: {
    'Ref: Redeem coffee description': 'Az ingyenes, frissen főzött csésze kávé vár',
    'Ref: Congratulations': 'Gratulálunk',
    'Ref: Get free coffee': 'Szerezz ingyenes kávét!',
    'Ref: Free coffee description':
      'Töltse ki a csészét, keressen pontokat egy ingyenes kávé felé.',
    'Ref: Redeem': 'Változtassa meg!',
  },
  StampPage: {
    'Ref: Page title': 'Ingyenes kávé',
    'Ref: seconds left': 'másodperc balra',
    'Ref: How it works': 'Hogyan működik',
    'Ref: How it works description':
      'Minden alkalommal, amikor kávét vásárol, nyomja meg a Szkennelés gombot, és a megadott QR -kódot a pénztárgépen írja be.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'QR kód beolvasása',
    'Ref: Collect reward': 'Összegyűjti a jutalmat',
    'Ref: Reward collected': 'Összegyűjtött jutalom',
    'Ref: Invalid QR Code': 'Érvénytelen QR -kód',
    'Ref: Invalid QR Code description': 'A QR -kódot nem ismerik fel',
    'Ref: Close': 'Bezárás',
    'Ref: Redeem button': 'Visszavált',
    "Ref: The QR Code isn't recognized": 'A QR -kódot nem ismerik fel',
    'Ref: Cancel': 'Megszünteti',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Gratulálunk!',
    'Ref: Confirm redeem description':
      'Persze, hogy ezt használni akarja? Ha igen, akkor mindössze 60 másodperc van, hogy megmutassa a telefonját a pénztárgépen, és töltse ki a vásárlást',
    'Ref: Congratulations modal description': 'Fogd meg most az ingyenes kávét!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Ingyenes kávé!', 'Ref: See All': 'Lásd az összeset' },
};
export default labels;
