const labels = {
  All: {
    'Ref: Redeem coffee description': 'Sua xícara de café grátis e recém-almoçada está esperando',
    'Ref: Congratulations': 'Parabéns',
    'Ref: Get free coffee': 'Obtenha café grátis!',
    'Ref: Free coffee description': 'Encha sua xícara, ganhe pontos para um café grátis.',
    'Ref: Redeem': 'Resgatar!',
  },
  StampPage: {
    'Ref: Page title': 'Café grátis',
    'Ref: seconds left': 'segundos restantes',
    'Ref: How it works': 'Como funciona',
    'Ref: How it works description':
      'Cada vez que você compra um café, pressione o botão Digitalizar e verifique o código QR fornecido na caixa registradora.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Digitalize código QR',
    'Ref: Collect reward': 'Coletar recompensa',
    'Ref: Reward collected': 'Recompensa coletada',
    'Ref: Invalid QR Code': 'Código QR inválido',
    'Ref: Invalid QR Code description': 'O código QR não é reconhecido',
    'Ref: Close': 'Perto',
    'Ref: Redeem button': 'Resgatar',
    "Ref: The QR Code isn't recognized": 'O código QR não é reconhecido',
    'Ref: Cancel': 'Cancelar',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Parabéns!',
    'Ref: Confirm redeem description':
      'Claro que você deseja usar isso? Nesse caso, você tem apenas 60 segundos para mostrar seu telefone na caixa registradora e concluir sua compra',
    'Ref: Congratulations modal description': 'Pegue seu café grátis agora!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Café grátis!', 'Ref: See All': 'Veja tudo' },
};
export default labels;
