const labels = {
  ReviewForm: {
    'Ref: Page title': '검토',
    'Ref: How was dining with us': '우리와 함께 식사는 어땠어?',
    'Ref: Submit': '제출하다',
    'Ref: Filter range': '필터 범위',
    'Ref: Some information is missing': '일부 정보가 누락되었습니다',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': '익명으로 보내십시오',
    'Ref: Your email address': '귀하의 이메일 주소',
    'Ref: Email field placeholder': '당신의 이메일 주소를 입력 해주세요',
    'Ref: Invalid email format': '잘못된 이메일 형식',
  },
  SuccessPage: {
    'Ref: Body':
      '이 리뷰를 완료해 주셔서 감사합니다.이 리뷰가 서비스를 개선하는 데 도움이되므로 시간이 소중합니다.',
    'Ref: Go to Feedback': '피드백으로 이동하십시오',
    'Ref: Go to home': '집에 가십시오',
    'Ref: Go to Facilities': '시설로 가십시오',
    'Ref: Go to Product Details': '제품 세부 정보로 이동하십시오',
    'Ref: Go to Facility Details': '시설 세부 사항으로 이동하십시오',
  },
  FacilityPicker: { 'Ref: Page title': '검토', 'Ref: Search facility name': '검색 시설 이름' },
};
export default labels;
