const labels = {
  All: {
    'Ref: Redeem coffee description': 'Váš bezplatný, čerstvě vařený šálek kávy čeká',
    'Ref: Congratulations': 'Gratulujeme',
    'Ref: Get free coffee': 'Získejte kávu zdarma!',
    'Ref: Free coffee description': 'Naplňte svůj šálek, získejte body na kávu zdarma.',
    'Ref: Redeem': 'Vykoupit!',
  },
  StampPage: {
    'Ref: Page title': 'Káva zdarma',
    'Ref: seconds left': 'vteřiny zbývají',
    'Ref: How it works': 'Jak to funguje',
    'Ref: How it works description':
      'Pokaždé, když si zakoupíte kávu, stiskněte tlačítko skenování a naskenujte poskytnutý QR kód v pokladně.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Naskenujte QR kód',
    'Ref: Collect reward': 'Sbírejte odměnu',
    'Ref: Reward collected': 'Sbírána odměna',
    'Ref: Invalid QR Code': 'Neplatný QR kód',
    'Ref: Invalid QR Code description': 'QR kód není rozpoznán',
    'Ref: Close': 'Zavřít',
    'Ref: Redeem button': 'Vykoupit',
    "Ref: The QR Code isn't recognized": 'QR kód není rozpoznán',
    'Ref: Cancel': 'zrušení',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Gratulujeme!',
    'Ref: Confirm redeem description':
      'Určitě to chcete použít? Pokud ano, máte jen 60 sekund, abyste mohli ukázat telefon v pokladně a dokončit nákup',
    'Ref: Congratulations modal description': 'Popadněte kávu zdarma hned teď!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Káva zdarma!', 'Ref: See All': 'Vidět vše' },
};
export default labels;
