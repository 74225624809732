import classNames from 'classnames';
import { useCallback, useReducer } from 'react';
import { useDispatch } from 'react-redux';

import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import List from '../../../../components/organisms/List/List';
import { CONTROL_TYPE } from '../../../../components/organisms/List/List.types';
import Modal from '../../../../components/organisms/Modal';
import { setDiscountTier } from '../../actions';
import { menuSelectorReducer } from '../../helpers/menuSelector.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { MenuSelectorActionType } from '../../types/menuSelector.types';

import { DiscountTiersModalProps } from './DiscountTiersModal.types';

import styles from './DiscountTiersModal.module.css';

const DiscountTiersModal = ({
  siteId,
  closeTiersModal,
  postSelecitonAction,
  discountTiersList,
  isTiersModalOpen,
  tier,
}: DiscountTiersModalProps) => {
  const [, localDispatch] = useReducer(menuSelectorReducer, {
    hasMenuFetched: false,
    setDiscountTierStatus: false,
  });
  const dispatch = useDispatch();
  const { label } = useOrderTranslation(__filename);

  const handleOnClick = useCallback(async () => {
    if (!!tier) {
      await dispatch(setDiscountTier({ siteId, discountTierId: tier }));
      localDispatch({
        type: MenuSelectorActionType.UPDATE_SET_DISCOUNT_TIER_STATUS,
        setDiscountTierStatus: false,
      });
      await postSelecitonAction();
      closeTiersModal();
    }
  }, [closeTiersModal, dispatch, siteId, tier, postSelecitonAction]);

  const availableTiers = discountTiersList?.map((dc) => {
    return {
      id: dc.id.toString(),
      label: dc.name,
      control: {
        type: CONTROL_TYPE.RADIO,
        props: { checked: tier === dc.id, 'data-testid': `${dc.name}-discount-tier-radio-button` },
      },
      'data-testid': `${dc.name}-discount-tier-list`,
    };
  });

  return (
    <Modal
      isOpen={discountTiersList && isTiersModalOpen}
      title={label('Ref: Choose your job')}
      footer={
        <Button
          inheritStyle={classNames(styles.actionButton)}
          look={BUTTON_LOOK.PRIMARY}
          onClick={handleOnClick}
          data-testid="discount-tiers-modal-button"
        >
          {label('Ref: Validate')}
        </Button>
      }
      backdropDismiss={false}
      crossButtonDismiss={false}
    >
      <Title
        size={TITLE_SIZE.BODYMDEFAULT}
        tag={TITLE_TAG.H2}
        className={classNames('mb-M', styles.tierModal)}
      >
        {label('Ref: Pricing Tiers dialog message')}
      </Title>
      <Title size={TITLE_SIZE.BODYMBOLD} tag={TITLE_TAG.H2} className={classNames('mb-S')}>
        {label('Ref: Please select your job in the company')}
      </Title>
      <List
        key={tier}
        data-testid="discount-tiers-list"
        items={availableTiers}
        onChange={(_, id) => {
          localDispatch({ type: MenuSelectorActionType.UPDATE_TIER, tier: parseInt(id) });
        }}
      />
    </Modal>
  );
};

export default DiscountTiersModal;
