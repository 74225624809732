const labels = {
  ReviewForm: {
    'Ref: Page title': 'Peržiūra',
    'Ref: How was dining with us': 'Kaip vakarieniavo su mumis?',
    'Ref: Submit': 'Pateikti',
    'Ref: Filter range': 'Filtrų diapazonas',
    'Ref: Some information is missing': 'Trūksta tam tikros informacijos',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Siųsti anonimiškai',
    'Ref: Your email address': 'Jūsų elektroninio pašto adresas',
    'Ref: Email field placeholder': 'Įveskite savo elektroninio pašto adresą',
    'Ref: Invalid email format': 'Neteisingas el. Pašto formatas',
  },
  SuccessPage: {
    'Ref: Body':
      'Dėkojame, kad baigėte šią apžvalgą, jūsų laikas yra vertingas, nes ši apžvalga padės pagerinti mūsų paslaugas.',
    'Ref: Go to Feedback': 'Eikite į atsiliepimus',
    'Ref: Go to home': 'Eiti namo',
    'Ref: Go to Facilities': 'Eikite į patalpas',
    'Ref: Go to Product Details': 'Eikite į produkto informaciją',
    'Ref: Go to Facility Details': 'Eikite į objekto informaciją',
  },
  FacilityPicker: {
    'Ref: Page title': 'Peržiūra',
    'Ref: Search facility name': 'Paieškos įrenginio pavadinimas',
  },
};
export default labels;
