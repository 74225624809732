import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { defaultThemeSettings } from '../../../styles/theme.helper';
import { upsertOrderDraft } from '../actions';
import { mapCartToPostDraftOrderRequest } from '../helpers/order.helper';
import {
  clearAndReloadTimeSlots,
  isMinimumTimeToOrderError,
  setTimeSlotWhenTimeSelectionHidden,
} from '../helpers/orderDraft.helper';
import { checkAndShowPromotionNotification } from '../helpers/promotions.helper';
import { OrderType, PaymentModality } from '../types/cart.types';
import { Cart, OrderDraft, StateWithOrder } from '../types/orderState.types';

function useDraftOrder({
  cart,
  siteId,
  orderDraft,
}: {
  cart?: Cart;
  siteId: string;
  orderDraft?: OrderDraft;
}) {
  const dispatch = useDispatch();
  const { tableNumber } = useSelector((state: StateWithOrder) => state.Order);
  const { context } = useSelector((state: StateWithOrder) => state.Core);
  const theme = context.theme?.settings ?? defaultThemeSettings;

  const submitDraftOrder = useCallback(
    async ({ paymentMethod }: { paymentMethod?: PaymentModality }) => {
      if (cart?.menuPortionItems) {
        let orderType: OrderType | undefined = undefined;
        if (!!paymentMethod)
          orderType =
            paymentMethod === PaymentModality.ChargeToMyDepartment
              ? OrderType.ConferenceCatering
              : OrderType.Normal;

        const currentPromotionDiscount = orderDraft?.promotionDiscount ?? 0;
        const result = await dispatch(
          upsertOrderDraft(
            mapCartToPostDraftOrderRequest(
              cart,
              siteId,
              theme,
              orderDraft?.orderId,
              tableNumber ?? undefined,
              orderType
            )
          )
        );

        if (isMinimumTimeToOrderError(result.responseStatus, result.responseData)) {
          await clearAndReloadTimeSlots(dispatch, {
            cartDate: cart.date,
            menuId: cart.menuId,
            pickupSpotId: cart.pickupInformation?.pickupSpotId,
            siteId,
            deliveryOptionId: cart?.selectedFulfillmentType?.id,
          });

          await setTimeSlotWhenTimeSelectionHidden(dispatch);

          return false;
        }

        checkAndShowPromotionNotification({
          currentPromotionDiscount,
          newPromotionDiscount: result.responseData?.promotionDiscount,
        });
      }

      return true;
    },

    [cart, siteId, orderDraft?.orderId, orderDraft?.promotionDiscount, theme, tableNumber, dispatch]
  );

  return [submitDraftOrder];
}

export default useDraftOrder;
