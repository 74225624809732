import classNames from 'classnames';
import debounceFn from 'lodash.debounce';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import useToggle from '../../../../helpers/hooks/useToggle';
import { InputFieldProps } from '../Input.types';

import { ClearCircleIcon } from '@/assets/icons';

import styles from './InputField.module.css';

const InputField: FC<InputFieldProps> = ({
  id,
  autocomplete,
  autocorrect,
  clearInput = true,
  withBackground = true,
  clearInputSrText,
  debounce,
  disabled,
  enterkeyhint,
  inputmode,
  inputLabel,
  max,
  maxlength,
  min,
  minlength,
  multiple,
  name,
  pattern,
  placeholder,
  readOnly,
  required,
  size,
  step,
  type,
  title,
  value,
  onInputChange,
  isValid,
  hasError,
  onFocus,
  inputRef,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}) => {
  const inputId = 'input_' + id;
  const innerInputRef = useRef<HTMLInputElement>();
  const [internalInputValue, setInternalInputValue] = useState(value);
  const { state: isFocused, toggleOn: focus, toggleOff: unfocus } = useToggle(false);

  useEffect(() => {
    setInternalInputValue(value);
  }, [value]);

  // eslint-disable-next-line
  const debounced = useCallback(
    debounceFn((value: string) => {
      if (onInputChange) onInputChange(value);
    }, debounce),
    [onInputChange, debounce]
  );

  useEffect(() => () => debounced.cancel(), [debounced]);

  const updateValue = useCallback(
    (value: string) => {
      setInternalInputValue(value || '');
      debounced(value);
    },
    [debounced]
  );

  const clearValue = useCallback(() => {
    setInternalInputValue('');
    if (onInputChange) onInputChange('');

    setTimeout(() => {
      innerInputRef.current?.focus();
    }, 100);
  }, [onInputChange]);

  const onFocusFn = useCallback(() => {
    if (onFocus) onFocus();
    focus();
  }, [focus, onFocus]);

  const onBlurFn = useCallback(() => {
    unfocus();
  }, [unfocus]);

  const inputValue = debounce ? internalInputValue : value;
  const updateInputValue = debounce ? updateValue : onInputChange;

  const handleClearKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      clearValue();
    }
  };

  return (
    <>
      <input
        className={styles.inputField}
        data-cy={dataCy}
        data-testid={dataTestId}
        aria-label={placeholder ? placeholder : inputLabel}
        disabled={disabled}
        autoComplete={autocomplete}
        autoCorrect={autocorrect}
        enterKeyHint={enterkeyhint}
        inputMode={inputmode}
        min={min}
        max={max}
        id={inputId}
        minLength={minlength}
        maxLength={maxlength}
        multiple={multiple}
        name={name}
        pattern={pattern}
        placeholder={placeholder || ''}
        readOnly={readOnly}
        required={required}
        step={step}
        size={size}
        type={type}
        value={inputValue}
        title={title}
        // React hook form is providing ref as a function
        ref={(input) => {
          if (typeof inputRef === 'function') {
            inputRef(input);
          } else if (inputRef) {
            inputRef.current = input ?? undefined;
          }
          innerInputRef.current = input ?? undefined;
        }}
        onFocus={onFocusFn}
        onBlur={onBlurFn}
        onChange={(e) => updateInputValue(e.target.value)}
      />
      {clearInput && !readOnly && !disabled && !!Number(inputValue?.length) && (
        <button
          data-testid = {`${dataTestId}-reset-button`}
          aria-label="reset"
          type="button"
          className={classNames(styles.inputClearIcon, {
            [styles.inputClearIconWithBackground]: withBackground,
            [styles.inputClearIconWithoutBackground]: !withBackground,
          })}
          onPointerDown={(ev) => {
            ev.preventDefault();
          }}
          onClick={() => clearValue()}
          onKeyDown={handleClearKeyDown}
        >
          <ClearCircleIcon
            width={20}
            height={20}
            title="Reset input title"
            data-cy="input-action-reset"
            className={styles.clearIcon}
          />
          <span className="sr-only">{clearInputSrText}</span>
        </button>
      )}
      <span
        data-testid = {`${dataTestId}-styling`}
        className={classNames(
          { [styles.errorMsg]: hasError },
          { [styles.disabled]: disabled },
          { [styles.validMsg]: isValid },
          { [styles.focused]: withBackground && isFocused },
          { [styles.inputWithBackground]: withBackground },
          styles.inputStyling
        )}
      />
    </>
  );
};

export default InputField;
