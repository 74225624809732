import { act, cleanup, screen } from '@testing-library/react';

import { renderedComponent } from '../../../../helpers/tests/renderComponent';
import { SERVICE } from '../../../config';
import { useGetLoyaltySchemesQuery, useGetUserLoyaltySchemesProgressQuery } from '../../api';

import LoyaltyStamps from './LoyaltyStamps';

jest.mock('../../api', () => ({
  ...jest.requireActual('../../api'),
  useGetLoyaltySchemesQuery: jest.fn(),
  useGetUserLoyaltySchemesProgressQuery: jest.fn(),
}));

const loyaltySchemes = [
  {
    id: 1,
    name: 'Scheme 1',
    description: 'Description 1',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 2,
    name: 'Scheme 2',
    description: 'Description 2',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2100,
        foodItemId: 1982,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2100,
        foodItemId: 1982,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 3,
    name: 'Scheme 3',
    description: 'Description 3',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 9871,
        foodItemId: 1982,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 9871,
        foodItemId: 1982,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
];

const userLoyaltySchemesProgress = [
  {
    loyaltySchemeId: 1,
    stampsCollected: 23,
  },
  {
    loyaltySchemeId: 2,
    stampsCollected: 10,
  },
  {
    loyaltySchemeId: 3,
    stampsCollected: 7,
  },
];

const loyaltySchemeForTestingRedeemableProductInCart = [
  {
    id: 1,
    name: 'Scheme 1',
    description: 'Description 1',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
];

const stateWithRedeemableItemInCart = {
  Core: {
    context: {
      site: {
        id: 1,
      },
    },
    user: {
      contract: {
        name: 'name',
      },
    },
    services: {
      list: [
        {
          name: SERVICE.FOOD_ORDER,
        },
      ],
    },
    notifications: {
      items: [
        {
          notificationId: 1,
          message: 'well well well',
        },
        {
          notificationId: 2,
          message: 'Mr Bond',
        },
      ],
      total: 2,
    },
    access: { shouldSelectSiteContext: false },
  },
  Order: {
    cart: {
      date: new Date(),
      facilityId: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
      submissionTrackingId: '5d19613e-1bc7-4442-b361-6cb7742af193',
      menuId: 160,
      siteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
      menuPortionItems: [
        {
          id: '53d264ca-02d6-480b-a348-e8d4a5ce8b90',
          img: 'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122601-1579502896-Pineapple_copy.png',
          menuItemId: 16814831,
          name: 'Abacaxi pineapple',
          price: 2.4,
          quantity: 1,
          uomId: 2102,
          foodItemId: 1982,
          description: '',
          isVegan: true,
          isVegetarian: true,
          genericCategory: 'Fruit',
        },
      ],
      moment: 'Fruits and Vegetables',
      selectedFulfillmentType: {
        id: 10214,
        type: 'PickupOption',
      },
      pickupLocations: [
        {
          pickupSpotId: 2195,
          order: 0,
          name: 'bite timeset',
        },
      ],
      pickupInformation: {
        pickupSpotId: null,
        pickupSpotName: null,
        pickupTimeSlotId: null,
        pickupTime: null,
      },
    },
  },
};

describe('LoyaltyStamps', () => {
  afterAll(() => cleanup());

  describe('while fetching data', () => {
    beforeEach(async () => {
      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: true,
      });
      (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: true,
      });
      await act(async () => {
        renderedComponent(LoyaltyStamps, { label: (s: string) => s });
      });
    });

    it('should display tile skeletons', () => {
      const skeleton1 = screen.getAllByTestId('skeleton-placeholder-0');
      const skeleton2 = screen.getAllByTestId('skeleton-placeholder-1');
      const skeleton3 = screen.getAllByTestId('skeleton-placeholder-2');

      expect(skeleton1).toBeTruthy();
      expect(skeleton2).toBeTruthy();
      expect(skeleton3).toBeTruthy();
    });
  });

  describe('without data', () => {
    beforeEach(async () => {
      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({ data: [], isLoading: false });
      (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: false,
      });
      await act(async () => {
        renderedComponent(LoyaltyStamps, { label: (s: string) => s });
      });
    });

    it('should render placeholder', () => {
      const placeholder = screen.getAllByTestId('widget-placeholder');
      expect(placeholder).toBeTruthy();
    });
  });

  describe('with data', () => {
    beforeEach(async () => {
      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
        data: loyaltySchemes,
        isLoading: false,
      });
      (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
        data: userLoyaltySchemesProgress,
        isLoading: false,
      });
      await act(async () => {
        renderedComponent(LoyaltyStamps, { label: (s: string) => s });
      });
    });

    it('should render list of stamp tiles', () => {
      const tile1 = screen.getAllByTestId('StampTile-1');
      const tile2 = screen.getAllByTestId('StampTile-2');
      const tile3 = screen.getAllByTestId('StampTile-3');

      expect(tile1).toBeTruthy();
      expect(tile2).toBeTruthy();
      expect(tile3).toBeTruthy();
    });

    it('should correctly compute progress towards a stamp', () => {
      const progress1 = screen.getByText(
        `${
          userLoyaltySchemesProgress[0].stampsCollected % loyaltySchemes[0].stampsRequiredForRedeem
        }/${loyaltySchemes[0].stampsRequiredForRedeem}`
      );
      const progress2 = screen.getByText(
        `${
          userLoyaltySchemesProgress[1].stampsCollected % loyaltySchemes[1].stampsRequiredForRedeem
        }/${loyaltySchemes[1].stampsRequiredForRedeem}`
      );
      const progress3 = screen.getByText(
        `${
          userLoyaltySchemesProgress[2].stampsCollected % loyaltySchemes[2].stampsRequiredForRedeem
        }/${loyaltySchemes[2].stampsRequiredForRedeem}`
      );

      expect(progress1).toBeTruthy();
      expect(progress2).toBeTruthy();
      expect(progress3).toBeTruthy();
    });

    it('should correctly compute number of rewards', () => {
      const reward1 = screen.getByText(
        `${
          (userLoyaltySchemesProgress[0].stampsCollected -
            (userLoyaltySchemesProgress[0].stampsCollected %
              loyaltySchemes[0].stampsRequiredForRedeem)) /
          loyaltySchemes[0].stampsRequiredForRedeem
        } Rewards`
      );
      const reward2 = screen.getByText(
        `${
          (userLoyaltySchemesProgress[1].stampsCollected -
            (userLoyaltySchemesProgress[1].stampsCollected %
              loyaltySchemes[1].stampsRequiredForRedeem)) /
          loyaltySchemes[1].stampsRequiredForRedeem
        } Reward`
      );
      const reward3 = screen.queryByText(
        `${
          (userLoyaltySchemesProgress[2].stampsCollected -
            (userLoyaltySchemesProgress[2].stampsCollected %
              loyaltySchemes[2].stampsRequiredForRedeem)) /
          loyaltySchemes[2].stampsRequiredForRedeem
        } Reward`
      );

      expect(reward1).toBeTruthy();
      expect(reward2).toBeTruthy();
      expect(reward3).toBeNull();
    });
  });

  describe('with data and redeemable product in cart', () => {
    beforeEach(async () => {
      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
        data: loyaltySchemeForTestingRedeemableProductInCart,
        isLoading: false,
      });
      (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
        data: userLoyaltySchemesProgress,
        isLoading: false,
      });
      await act(async () => {
        renderedComponent(
          LoyaltyStamps,
          { label: (s: string) => s },
          stateWithRedeemableItemInCart
        );
      });
    });

    it('should correctly compute progress towards a stamp', () => {
      const progress1 = screen.getByText(
        `${
          userLoyaltySchemesProgress[0].stampsCollected % loyaltySchemes[0].stampsRequiredForRedeem
        }/${loyaltySchemes[0].stampsRequiredForRedeem}`
      );

      expect(progress1).toBeTruthy();
    });

    it('should correctly compute number of rewards', () => {
      const reward1 = screen.getByText(
        `${
          (userLoyaltySchemesProgress[0].stampsCollected -
            (userLoyaltySchemesProgress[0].stampsCollected %
              loyaltySchemes[0].stampsRequiredForRedeem)) /
            loyaltySchemes[0].stampsRequiredForRedeem -
          stateWithRedeemableItemInCart.Order.cart.menuPortionItems[0].quantity
        } Reward`
      );
      expect(reward1).toBeTruthy();
    });
  });
});
