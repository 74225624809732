import { withRouter } from 'react-router';

import withLang from '../../../_deprecated/context/withLang';

import TileComponent from './Tile';

// TODO decommission old tile
const OldTile = withRouter(withLang(undefined, [__filename])(TileComponent));

export default OldTile;
