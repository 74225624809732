import { History } from 'history';
import { useCallback } from 'react';

import { SSO_PROVIDER } from '../../../constants';
import { useLazyGetIdentityContextQuery } from '../api/account/accountApi';

import useHandleUserRegionCode from './useHandleUserRegionCode';

const useLoginViaGates = (
  login: string,
  setShouldDisplayPasswordField: (value: boolean) => void,
  showLoginError: Function,
  currentGeoCode: string,
  currentRegionCode: string,
  history: History
) => {
  const [getIdentityContext] = useLazyGetIdentityContextQuery();
  const handleUserRegionCode = useHandleUserRegionCode(
    login || '',
    currentGeoCode,
    currentRegionCode,
    showLoginError,
    history
  );

  const loginViaGates = useCallback(
    (login: string) => {
      getIdentityContext({ username: login }).then((result) => {
        if (result.data) {
          if (result.data?.provider === SSO_PROVIDER.GATES) {
            handleUserRegionCode(result.data?.tokenUrl, result.data?.useSystemBrowser);
          } else {
            setShouldDisplayPasswordField(true);
          }
        } else {
          showLoginError();
        }
      });
    },
    [getIdentityContext, handleUserRegionCode, setShouldDisplayPasswordField, showLoginError]
  );

  return loginViaGates;
};

export default useLoginViaGates;
