import classNames from 'classnames';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

import {
  getActiveLoyaltySchemes,
  getCardDescription,
  getCardTitle,
  getModalDescription,
} from './LoyaltyCard.helper';
import { LoyaltyCardProps } from './LoyaltyCard.types';

import { LoyaltyStampsCard, LoyaltyStampsRewards } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import Card from '@/components/molecules/Card/Card';
import { TILE_VARIANT, Tile } from '@/components/molecules/Tile';
import Modal from '@/components/organisms/Modal';
import { pagePaths } from '@/modules/LoyaltyStamps/config';

import styles from './LoyaltyCard.module.css';

const LoyaltyCard = ({
  menuItem,
  loyaltySchemes,
  loyaltyStampsToBeEarned,
  menuId,
  variant = TILE_VARIANT.STANDARD,
  pageType,
  label,
  modalOpen,
  openModal,
  closeModal,
}: LoyaltyCardProps) => {
  const history = useHistory();

  const activeLoyaltySchemes = getActiveLoyaltySchemes(
    menuId,
    loyaltySchemes,
    pageType,
    menuItem,
    loyaltyStampsToBeEarned
  );

  const numberOfStamps = menuItem
    ? 1 //currently one product = one stamp, will change in future
    : loyaltyStampsToBeEarned?.map((i) => i.stampsCount).reduce((sum, num) => sum + num, 0) ?? 0;

  //Reminder  - correct translations are based on the page from label comes from,
  //            to not extend the mess with the translations conditioning
  const cardTitle = getCardTitle(numberOfStamps, label);

  const cardDescription = getCardDescription(
    numberOfStamps,
    activeLoyaltySchemes.map((i) => i.name),
    label
  );

  const modalDescription = getModalDescription(
    numberOfStamps,
    pageType,
    activeLoyaltySchemes,
    label,
    loyaltyStampsToBeEarned,
    menuItem?.name
  );

  const navigateToLoyaltyStamps = useCallback(() => history?.push(pagePaths.Module), [history]);

  return (
    <>
      <Card>
        <Tile
          variant={variant}
          title={cardTitle}
          description={cardDescription}
          image={<LoyaltyStampsCard />}
          onClick={openModal}
          data-testid={`loyalty-tile`}
        />
      </Card>

      <Modal
        title={label('Ref: Loyalty Stamps', { textTransform: 'capitalize' })}
        isOpen={modalOpen}
        id="loyalty_modal"
        onDismiss={closeModal}
        data-testid={`loyalty-modal`}
        footer={
          <div className={classNames(styles.actionsWrapper)}>
            <Button
              children={label('Ref: See Loyalty Stamps')}
              onClick={navigateToLoyaltyStamps}
              data-testid={`loyalty-modal-button-see-stamps`}
            />
          </div>
        }
        children={
          <Modal.RowLayout
            image={<LoyaltyStampsRewards />}
            children={modalDescription}
            data-testid="loyalty-modal-content"
          />
        }
      />
    </>
  );
};

export default LoyaltyCard;
