const labels = {
  All: {
    'Ref: Assembled': 'Koottu',
    'Ref: Submitted': 'Toimitettu',
    'Ref: Fulfilled': 'Täyttynyt',
    'Ref: Completed': 'Valmis',
    'Ref: Paid': 'Maksettu',
    'Ref: Approved': 'Hyväksytty',
    'Ref: Ready For Pickup': 'Valmis noutoon',
    'Ref: Delivered': 'Toimitettu',
    'Ref: Received': 'Sai',
    'Ref: Rejected': 'Hylätty',
    'Ref: Payment Error': 'Maksuvirhe',
    'Ref: Pending Payment': 'odottava maksu',
    'Ref: Preparing': 'Valmistelu',
    'Ref: Refunded': 'Palautettu',
    'Ref: Cancelled By User': 'Käyttäjä peruuttaa',
    'Ref: Refund Requested': 'Palautus pyydetty',
    'Ref: Refund Failed': 'Palautus epäonnistui',
    'Ref: Payment Processing': 'Maksunkäsittely',
    'Ref: Not Paid': 'Ei maksettu',
    'Ref: Total:': 'Kaikki yhteensä:',
    'Ref: Pickup': 'Noukkia',
    'Ref: Eat-in': 'Syöminen',
    'Ref: Scan&Go': 'Skannata ja mennä',
    'Ref: Fulfillment Type': 'Täyttötyyppi',
    'Ref: Delivery': 'Toimitus',
    'Ref: You have no future orders': 'Sinulla ei ole tulevia tilauksia',
    'Ref: You have no past orders': 'Sinulla ei ole aiempia tilauksia',
    'Ref: You have no orders': 'Sinulla ei ole tilauksia',
    'Ref: All orders': 'Kaikki tilaukset',
    'Ref: Not past orders': 'Tulevat tilaukset',
    'Ref: Past orders': 'Aiemmat tilaukset',
    'Ref: Vegan': 'Vegaani',
    'Ref: Vegeterian': 'Kasvissyöjä',
    'Ref: See item': 'Katso kohde',
    'Ref: Activated': 'Aktivoitu',
    'Ref: No suggestions': 'Ehdotuksia ei löydy',
    'Ref: Get your receipt': 'Hanki kuitti',
    'Ref: Print': 'Tulosta kuitti',
    'Ref: Email': 'Vastaanota kuitti sähköpostitse',
    'Ref: Receipt Modal message': 'Kuinka haluaisit saada kuitin?',
    'Ref: Confirm': 'Vahvistaa',
    'Ref: Your email address': 'Sähköpostiosoitteesi',
    Suggestion: 'Ehdotus',
    'Ref: Loyalty Reward': 'Uskollisuuspalkinto',
    'Ref: Equation for stamps per item': '1 osto = 1 leima',
    'Ref: Paid by employer': 'Työnantajan maksama summa',
    'Ref: Paid by user': 'Maksamasi summa',
  },
  ProductsList: {
    'Ref: Page title': 'Tuotelista',
    'Ref: Facility details': 'Laitoksen yksityiskohdat',
    'Ref: Search products list': 'Etsi kohdetta',
    'Ref: filter label: menus': 'Valikko',
    'Ref: filter label: moment': 'Hetki',
    'Ref: filter label: category': 'Kategoria',
    'Ref: all': 'Kaikki',
    'Ref: today': 'Tänään',
    'Ref: tomorrow': 'Huomenna',
    'Ref: see more': 'Katso lisää',
    'Ref: see less': 'Katso vähemmän',
    'Ref: reset': 'Nollata',
    'Ref: View in cart': 'Näkymä ostoskorissa',
    'Ref: You have changed location': 'Olet vaihtanut sijaintia',
    'Ref: You are now at': 'Olet nyt',
    'Ref: Menu card': 'Valikkokortti',
    'Ref: Table': 'Taulukonumero',
    'Service unavailable': 'Palvelu ei saatavilla',
    'Ref: Ordering unavailable':
      'Varovasti! Tilaaminen tällä sivustolla ei ole tällä hetkellä käytettävissä, yritä myöhemmin.',
    'Ref: Filters': 'Suodattimet (allergeenit ja muut)',
  },
  ProductScanner: {
    'Ref: cart': 'Kärry',
    'Ref: Product Scanned':
      'lisättiin kärryyn. Voit jatkaa viivakoodien skannaamista lisätäksesi ne ostoskoriin.',
    'Ref: Scan or adjust': 'lisättiin kärryyn. Säädä määrä tai skannaa toinen tuote.',
    'Ref: Product Not Matched': 'Viivakoodia ei tunnistettu. Yritä uudelleen',
    'Ref: Scan article code': 'Skannata viivakoodia',
    'Ref: Native error product cannot be added to cart':
      'Kärryyn ei voida lisätä eri valikosta tai päiväosasta',
    'Ref: product scanner placeholder': 'Skannaa viivakoodi lisätäksesi sen ostoskoriin',
  },
  Order: { 'Ref: Page title': 'Tilaus' },
  MenuSelector: {
    'Ref: Menu Page title': 'Valikot',
    'Ref: today': 'Tänään',
    'Ref: tomorrow': 'Huomenna',
    'Ref: Search products list': 'Etsi kohdetta',
    'Ref: You have changed location': 'Olet vaihtanut sijaintia',
    'Ref: You are now at': 'Olet nyt',
  },
  ProductDetails: {
    'Ref: Page title': 'tuotteen yksityiskohdat',
    'Ref: Serving Size': 'Annoskoko',
    'Ref: Nutrition': 'Ravitsemustietoa',
    'Ref: Nutrition adults': 'Aikuiset tarvitsevat noin 2000 kcal päivässä',
    'Ref: Nutrition Calories': 'Kaloreita',
    'Ref: Nutrition Calcium': 'Kalsium',
    'Ref: Nutrition Fat': 'Rasva',
    'Ref: Nutrition Saturated Fat': 'Tyydyttynyt rasva',
    'Ref: Nutrition Carbohydrates': 'Hiilihydraatit',
    'Ref: Nutrition Sugar': 'Sokeri',
    'Ref: Nutrition Protein': 'Proteiini',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Kolesteroli',
    'Ref: Nutrition dietaryFiber': 'Ravintokuitu',
    'Ref: Nutrition iron': 'Rauta',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'A-vitamiini',
    'Ref: Nutrition vitaminC': 'C-vitamiini',
    'Ref: Additives': 'Lisäaineet',
    'Ref: Emission': 'Hiilidioksidipäästöt',
    'Ref: This product generates': 'Tämä tuote tuottaa',
    'Ref: of CO2': 'CO2: n',
    'Ref: emissions, which equals a': 'päästöt, mikä vastaa',
    'Ref: car trip': 'Automatka',
    'Ref: Ingredients': 'Resepti-ainesosa',
    'Ref: Allergens': 'Allergeenit',
    'Ref: Always there': 'Aina siellä',
    'Ref: Allergens selected': 'Suodatin valitut allergeenit:',
    'Ref: minimum required': 'Vaadittu vähimmäismäärä',
    'Ref: you have to add at least': 'Sinun on lisättävä ainakin',
    'Ref: ingredients': 'ainesosat',
    'Ref: Ingredient': 'ainesosa',
    'Ref: Free': 'Vapaa',
    'Ref: Based on customization': 'Räätälöinnin perusteella',
    'Ref: Additives eggs': 'munat',
    'Ref: Additives fish': 'kalastaa',
    'Ref: Additives milk': 'maito',
    'Ref: Additives nuts': 'pähkinä',
    'Ref: Additives wheat': 'vehnä',
    'Ref: Additives peanuts': 'maapähkinö',
    'Ref: Additives soy beans': 'soijapavut',
    'Ref: Additives sesame seeds': 'seesaminsiemeniä',
    'Ref: Review': 'Arvostelu',
    'Ref: Per portion': 'Per annos',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergeeni, joka on valittu hakasuodattimessa',
    'Ref: Fitbit Log': 'Loki Fitbit',
    'Ref: age verification required on delivery': 'Ikävarmennus vaaditaan toimituksessa',
    'Ref: alcohol content notification':
      'Asettamalla ja tilaamalla jokin näistä esineistä ilmoitat, että olet 18 -vuotias tai yli. Alkoholin väärinkäyttö on vaarallista terveydellesi.',
    'Ref: default': 'Laiminlyönti',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {vvvv} ansaitut, jos tilaat tämän tuotteen',
    'Ref: Loyalty Card Description':
      '{stamp_form} lisätään seuraavaan uskollisuuteen {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Lietaalisuusleimat -ohjelman avulla voit kerätä postimerkkejä palkintoja varten.',
    'Ref: Loyalty Modal Description':
      'Kun ostat <b> {product_name}, 1 leima </b> lisätään <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'ja',
    'Ref: Stamp': 'Leima',
    'Ref: Stamps': 'Leimat',
    'Ref: Program': 'Ohjelmoida',
    'Ref: Programs': 'Ohjelmat',
    'Ref: Loyalty Stamps': 'Kanta -asiakasleimat',
    'Ref: See Loyalty Stamps': 'Katso kanta -asiakasleimat',
    Favorite: 'Suosikki',
    'Ref: In favorites': 'Tuote on jo suosikkiluettelossa. Napsauta poistaaksesi sen.',
    'Ref: Not in favorites': 'Tuote ei ole vielä suosikkiluettelossa. Napsauta lisätäksesi sen.',
    'Ref: Added to favorites': 'Tuote on lisätty suosikkiluetteloon.',
    'Ref: Removed from favorites': 'Tuote on poistettu suosioluettelosta.',
    'Ref: From': 'Alkaen',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Muutos ostoskorissa',
    'Ref: Popup header for customizable item qty update': 'Tämä tuote on muokattavissa',
    'Ref: add new version': 'Lisää uusi versio',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Päivitä muokattavan kohteen määrä suoraan ostoskoristasi.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Päivitä muokattavan kohteen määrä suoraan ostoskoristasi tai lisää uusi versio.',
    'Ref: another context body':
      'Jos haluat lisätä kohteita tältä sivustolta, laitoksesta, hetkestä/valikosta tai päivämäärästä, sinun on tyhjennettävä ostoskori',
    'Ref: cancel': 'Peruuttaa',
    'Ref: add to cart': 'Lisää ostoskoriin',
    'Ref: view in cart': 'Kärry',
    'Ref: Products in cart': '{Tuote_nimi}',
    'Ref: Customize': 'Mukauttaa',
    'Ref: Add': 'Lisätä',
    'Ref: Free': 'Vapaa',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Peruuttaa',
    'Ref: notice': 'Ilmoitus',
    'Ref: clear cart': 'Tyhjennä ostoskori',
    'Ref: Cart addition error message':
      'Tällä hetkellä sinulla on esineitä ostoskorissa {from_current}. Jos haluat lisätä kohteita {from_selected}, sinun on puhdistettava kärry',
  },
  Cart: {
    'Ref: Page title': 'Ostoskori',
    'Ref: The cart is empty': 'Ostoskori on tyhjä',
    'Ref: Clear cart': 'Tyhjennä ostoskori',
    'Ref: Continue shopping': 'Jatka ostoksia',
    'Ref: Start shopping': 'Aloittaa ostokset',
    'Ref: Today': 'Tänään',
    'Ref: Tomorrow': 'Huomenna',
    'Ref: Tax:': 'Vero:',
    'Ref: Promotion:': 'Edistäminen:',
    'Ref: Total without promotion:': 'Yhteensä ilman ylennystä:',
    'Ref: Delivery:': 'Toimitus:',
    'Ref: Pickup informations': 'Noutotiedot',
    'Ref: Location': 'Sijainti',
    'Ref: Time': 'Aika',
    'Ref: Phone': 'Puhelinnumerosi',
    'Ref: Instructions': 'Ohjeet',
    'Ref: Please select': 'Ole hyvä ja valitse',
    'Ref: Enter special requests': 'Kirjoita erityiset pyynnöt tähän',
    'Ref: notice': 'Ilmoitus',
    'Ref: Product is no more available':
      'Anteeksi, mutta näyttää siltä, ​​että jotakin tuotetta ei ole enää saatavana. Tarkista ostoskorisi.',
    'Ref: ok': 'Hyvä',
    'Ref: Validate': 'Vahvista tilaukseni',
    'Ref: Pay now': 'Maksa nyt',
    'Ref: Charge to my department': 'Maksu osastolleni',
    'Ref: Pay on pickup': 'Maksa näistä tuotteista poiminnassa',
    'Ref: Accept terms of sale prefix': 'hyväksyn',
    'Ref: Accept terms of sale': 'Nämä myyntiehdot',
    'Ref: Continue': 'Jatkaa',
    'Ref: Please login or sign up to place an order': 'Kirjaudu sisään tehdäksesi tilauksen',
    'Ref: Accept terms of sale suffix': ', ja olen lukenut ja ymmärtänyt ne.',
    'Ref: Payment modality': 'Maksutapa',
    'Ref: Other information': 'Muita tietoja',
    'Ref: Eat-in': 'Syöminen',
    'Ref: Pickup': 'Nouda',
    'Ref: Table': 'Taulukonumero',
    'Ref: Required fields': 'Pakolliset kentät',
    'Service unavailable': 'Palvelu ei saatavilla',
    'Ref: Cannot order':
      'Valitettavasti et voi tehdä tilausta tällä sivustolla tällä hetkellä. Ota yhteyttä ravintolaan saadaksesi lisätietoja aukioloaikoista ja käytettävissä olevista palveluista.',
    'Ref: Order Unavailable':
      'Tilaus valitulle päivämäärälle, täyttymistyyppi ja sijainti ei ole enää käytettävissä!',
    'Ref: No Availability': 'Ei enää saatavuutta',
    'Ref: Continue on your device': 'Jatka laitteellasi',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {vvvv} ansaitut, jos suoritat tilauksesi',
    'Ref: Loyalty Card Description':
      '{stamp_form} lisätään seuraavaan uskollisuuteen {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Lietaalisuusleimat -ohjelman avulla voit kerätä postimerkkejä palkintoja varten.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {vvvv} </b> ansaittu, jos suoritat tilauksesi:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_formy} </b> lisätty <b> {scheme_names} </b>',
    'Ref: Stamp': 'Leima',
    'Ref: Stamps': 'Leimat',
    'Ref: Program': 'Ohjelmoida',
    'Ref: Programs': 'Ohjelmat',
    'Ref: Loyalty Stamps': 'Kanta -asiakasleimat',
    'Ref: See Loyalty Stamps': 'Katso kanta -asiakasleimat',
    'Ref: Loyalty Info': 'Uskollisuustiedot',
    'Ref: Email address': 'Sähköpostiosoite',
    'Ref: and': 'ja',
    'Ref: Order minimum amount': 'Valitettavasti vähimmäismaksumäärä on {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Jatka laitteellasi',
    'Ref: Scan QR to continue': 'Ole hyvä ja skannaa QR -koodi jatkaaksesi matkaa laitteellasi.',
  },
  OrderHistory: { 'Ref: Orders': 'Määräys', 'Ref: Page Title': 'Tilaukseni' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Yksikköhinta',
    'Ref: Download': 'Lataa kuitti',
    'Ref: Download error': 'Latausvirhe on tapahtunut. Yritä ladata kuitti myöhemmin',
    'Ref: Order details title': 'Tilauksen tiedot',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Kiitos! Tilauksesi on nyt vahvistettu',
    'Ref: Text for pickup':
      'Voit seurata tilauksesi tilaa tilaushistoriasi kautta. Jos ilmoituksesi kytketään päälle, päivitämme sinulle, kun tilaus on valmis noutoon.',
    'Ref: Success Order Title': 'Kiitos tilauksestasi',
    'Ref: Success Order Description': 'Maksa kassalla vahvistaaksesi tilauksesi.',
    'Ref: Success Order ID': 'Henkilöllisyysjärjestys',
    'Ref: Success Order Action': 'Uusi järjestys',
    'Ref: Text for dinein':
      'Tilauksesi tullaa toimittamaan pöytääsi pian. Kysy vain ystävälliseltä henkilöstöltämme tilauksestasi.',
    'Ref: Text for scan&go': 'Tilauksesi on käytettävissä tilaushistoriassasi',
    'Ref: Text for delivery':
      'Voit seurata sen tilaa tilaushistoriassasi. Jos ilmoituksesi ovat käytössä, ilmoitamme sinulle, kun tilauksesi on valmis.',
    'See details': 'Katso yksityiskohdat',
    'Go to home': 'Palaa alkuun',
  },
  FailOrder: {
    'Go to home': 'Palaa alkuun',
    'Ref: Kiosk Fail Title': 'Oho! Jotain meni pieleen!',
    'Ref: Kiosk Fail Description':
      'Valitettavasti tilauksesi ei onnistunut. Yritä uudelleen tai pyydä henkilöstöä auttamaan sinua.',
    'Ref: Kiosk Fail Back': 'Takaisin edelliselle sivulle',
    'Ref: Kiosk Fail Action': 'Tyhjennä istunto ja tilaa uudelleen',
    'Ref: Error in cart validation':
      'Tilauksen aikana tapahtui virhe. Ota yhteyttä toimittajaan ratkaistaksesi ongelma.',
    'Facility contact': 'Toimittajan yhteys',
    'Ref: Ordering failed': 'Tilaaminen epäonnistui',
    'Ref: Order confirmed': 'Tilaus vahvistettu',
  },
  TableCheckIn: {
    'Welcome at': 'Tervetuloa',
    Table: 'Pöytä:',
    'Ref: list title': 'Viimeaikaiset tilaukset tänään',
    'See the menu': 'Katso valikko',
    'Table Check-in': 'Taulukon kirja',
    'Ref #': 'Viite #',
    Checked: 'Tarkistettu',
    'Check in': 'Ilmoittaudu',
    'See details': 'Katso yksityiskohdat',
    'Ref: You have changed location': 'Olet vaihtanut sijaintia',
    'Ref: You are now at': 'Olet nyt',
    'Order number': 'Tilausnumero',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Ylennys',
    'Ref: Promotion applied description': 'Hyödyt ostoskoristasi.',
    'Ref: Promotion deleted title': 'Ylennys poistettu',
    'Ref: Promotion deleted description':
      'Et enää hyödytä ylennystä, koska et enää täytä ehtoja tai ylennys on vanhentunut.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Skannata ja mennä', 'Ref: View only': 'Vain näkymä' },
  ProductTile: {
    'Ref: Allergens': 'Allergeenit',
    'Ref: Presence of allergen': 'Allergeenin läsnäolo',
    'Ref: Scan': 'Skannata',
    'Ref: From': 'Alkaen',
    'Ref: Already in product favorite list. Click to remove it.':
      'Jo tuote -suosikkiluettelossa. Napsauta poistaaksesi sen.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Tällä hetkellä ei lisätty tuotteiden suosikkiluetteloon. Napsauta lisätäksesi sen.',
    'Ref: Free': 'Vapaa',
  },
  OrderSummary: {
    'Ref: item': 'esine',
    'Ref: items': 'kohteet',
    'Ref: Clear': 'Asia selvä',
    'Ref: Cart': 'Kärry',
    'Ref: Subtotal': 'Välisumma',
    'Ref: Total': 'Kaikki yhteensä',
    'Ref: Tax': 'Verottaa',
    'Ref: Promotion': 'Edistäminen',
    'Ref: Total without promotion': 'Yhteensä ilman ylennystä',
    'Ref: Number of product in cart': 'Tuotteen lukumäärä ostoskorissa',
    'Ref: Delivery': 'Toimitus',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegaani', 'Ref: Vegeterian': 'Kasvissyöjä' },
  OrderInfoCard: {
    'Ref: Header': 'Tilattu',
    'Ref: from': '-sta',
    'Ref: location': 'Sijainti',
    'Ref: Phone': 'Puhelinnumerosi',
    'Ref: Instructions': 'Ohjeet',
    'Ref: Order type': 'Tilaustapa',
    'Ref: Pickup': 'Noukkia',
    'Ref: Eat-in': 'Syöminen',
    'Ref: Scan&Go': 'Skannata ja mennä',
  },
  ReorderWidget: {
    'Ref: No items': 'Ei vielä tehty tilauksia. Osta nyt!',
    'Ref: Title': 'Tilata uudelleen',
  },
  MenusWidget: {
    'Ref: no menu': 'Ei tällä hetkellä valikkoa',
    'Ref: Modal title': 'Päivän valikko',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Alennukset saatavilla' },
  PromotionInfoItem: { 'Ref: Activate': 'Aktivoida' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Tilauksesi', 'Ref: Widget Title': 'Tilaukseni' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Ehdotus päiväksi',
    'Ref: Any menu': 'Mikä tahansa valikko',
    Other: 'Muut',
    'Ref: Suggestions calendar button': 'Ehdotukset kalenteri',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Annoskoko',
    'Ref: Nutrition': 'Ravitsemustietoa',
    'Ref: Nutrition adults': 'Aikuiset tarvitsevat noin 2000 kcal päivässä',
    'Ref: Nutrition Calories': 'Kaloreita',
    'Ref: Nutrition Calcium': 'Kalsium',
    'Ref: Nutrition Fat': 'Rasva',
    'Ref: Nutrition Saturated Fat': 'Tyydyttynyt rasva',
    'Ref: Nutrition Carbohydrates': 'Hiilihydraatit',
    'Ref: Nutrition Sugar': 'Sokeri',
    'Ref: Nutrition Protein': 'Proteiini',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Kolesteroli',
    'Ref: Nutrition dietaryFiber': 'Ravintokuitu',
    'Ref: Nutrition iron': 'Rauta',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'A-vitamiini',
    'Ref: Nutrition vitaminC': 'C-vitamiini',
    'Ref: Ingredients': 'Resepti-ainesosa',
    'Ref: Allergens': 'Allergeenit',
    'Ref: Always there': 'Aina siellä',
    'Ref: Allergens selected': 'Suodatin valitut allergeenit:',
    'Ref: minimum required': 'Vaadittu vähimmäismäärä',
    'Ref: ingredients': 'ainesosat',
    'Ref: Ingredient': 'ainesosa',
    'Ref: Free': 'Vapaa',
    'Ref: Based on customization': 'Räätälöinnin perusteella',
    'Ref: Additives eggs': 'munat',
    'Ref: Additives fish': 'kalastaa',
    'Ref: Additives milk': 'maito',
    'Ref: Additives nuts': 'pähkinä',
    'Ref: Additives wheat': 'vehnä',
    'Ref: Additives peanuts': 'maapähkinö',
    'Ref: Additives soy beans': 'soijapavut',
    'Ref: Additives sesame seeds': 'seesaminsiemeniä',
    'Ref: Review': 'Arvostelu',
    'Ref: Per portion': 'Per annos',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} ilmaiseksi',
    'Ref: Number of free items': 'Ilmaisten esineiden lukumäärä: {number_of_free_items}',
    'Ref: Allergen selected': 'Allergeeni, joka on valittu hakasuodattimessa',
    'Ref: Nutritional info': 'Ravitsemustiedot',
    'Ref: Info': 'Tiedot',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Kampanjat' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Valitse työsi',
    'Ref: Validate': 'Validoida',
    'Ref: Pricing Tiers dialog message': 'Sinut on tunnistettu sähköpostiosoittellasi',
    'Ref: Please select your job in the company': 'Valitse työsi yrityksestä',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Noukkia',
    'Ref: Eat-in': 'Syöminen',
    'Ref: Scan&Go': 'Skannata ja mennä',
    'Ref: Delivery': 'Toimitus',
    'Ref: Total': 'Kaikki yhteensä',
    'Ref: How was facility name service': 'Kuinka {facility_name} ´S -palvelu oli?',
    'Ref: How do you rate the service': 'Kuinka arvioit palvelun?',
  },
};
export default labels;
