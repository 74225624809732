const labels = {
  All: {
    'Ref: stamp progress': 'Pažanga link kito antspaudo',
    Reward: 'Atlygis',
    Rewards: 'Atlygis',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Antspaudai',
    'Ref: Fulfill conditions': 'Uždirbkite antspaudus, kad išpirktumėte atlygį!',
    'See Reward': 'Pamatyti atlygį',
    'Ref: No reward': 'Šiuo metu nėra atlygio',
  },
  RedeemableProducts: { 'Ref: Page title': 'Lojalumo atlygis' },
};
export default labels;
