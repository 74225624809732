import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

import { SSO_PROVIDER, IN_APP_BROWSER_FRAMELESS } from '../../../constants';
import { setUserRegionCode } from '../actions';
import { IdentityContextType } from '../types';

import { themeKeys } from './helpers';

import { store } from '@/store/store';

const getSSOReturnUrl = (email: string, geoCode: string) =>
  `/access/idp?username=${email}&geocode=${geoCode}`;

const getSSOCallbackUrl = (email: string, geoCode: string) =>
  `${process.env.REACT_APP_HOST}${getSSOReturnUrl(email, geoCode)}`;

const isSSOUser = (identityContext: IdentityContextType) =>
  identityContext.provider === SSO_PROVIDER.GATES;

const invokeSSORedirect = async (
  tokenUrl: string,
  email: string,
  geoCode: string,
  regionCode: string,
  useSystemBrowser: boolean,
  history: any
) => {
  const { platform } = await Device.getInfo();
  const isMobile = Capacitor.isNativePlatform();

  await store.dispatch(
    setUserRegionCode({
      username: email,
      regionCode: regionCode,
      callbackUrl: getSSOCallbackUrl(email, geoCode),
      isMobile,
    })
  );

  if (useSystemBrowser) {
    const theme = store.getState().Core.context.theme?.settings || [];

    // TODO see src/index.js applyTheme for more info
    const defaultThemeSettings = JSON.parse(process.env.REACT_APP_DEFAULT_THEME_JSON || '[]');

    // Get theme color from Json, or fallback to original
    const fallbackPrimaryColor =
      defaultThemeSettings.find((el: { key: string; value: string }) =>
        themeKeys.primaryColor.includes(el.key)
      )?.value || '#283897';

    // Get theme color from context or fallback to json
    const primaryColor =
      theme.find((el: { key: string; value: string }) => themeKeys.primaryColor.includes(el.key))
        ?.value || fallbackPrimaryColor;

    await Browser.open({
      url: tokenUrl,
      windowName: '_self',
      toolbarColor: primaryColor,
      presentationStyle: 'popover',
    });

    return;
  }

  const SSOInAppBrowser = InAppBrowser.create(
    tokenUrl,
    platform === 'web' ? '_self' : '_blank',
    IN_APP_BROWSER_FRAMELESS
  );
  SSOInAppBrowser.on('loadstart')?.subscribe({
    next: (evt) => {
      if (evt.url.indexOf('token-idp') > -1) {
        SSOInAppBrowser.close();
        history.push(getSSOReturnUrl(email, geoCode));
      }
    },
  });
};

export { getSSOCallbackUrl, getSSOReturnUrl, isSSOUser, invokeSSORedirect };
