const labels = {
  All: {
    'Ref: Assembled': 'Berkumpul',
    'Ref: Submitted': 'Dikirimkan',
    'Ref: Fulfilled': 'Terpenuhi',
    'Ref: Completed': 'Lengkap',
    'Ref: Paid': 'Dibayar',
    'Ref: Approved': 'Disetujui',
    'Ref: Ready For Pickup': 'Siap untuk diambil',
    'Ref: Delivered': 'Terkirim',
    'Ref: Received': 'Diterima',
    'Ref: Rejected': 'Ditolak',
    'Ref: Payment Error': 'Kesalahan pembayaran',
    'Ref: Pending Payment': 'Pembayaran yang tertunda',
    'Ref: Preparing': 'Mempersiapkan',
    'Ref: Refunded': 'Dikembalikan',
    'Ref: Cancelled By User': 'Dibatalkan oleh pengguna',
    'Ref: Refund Requested': 'Pengembalian uang diminta',
    'Ref: Refund Failed': 'Pengembalian uang gagal',
    'Ref: Payment Processing': 'Proses pembayaran',
    'Ref: Not Paid': 'Tidak dibayarkan',
    'Ref: Total:': 'Total:',
    'Ref: Pickup': 'Ambil',
    'Ref: Eat-in': 'Makan-dalam',
    'Ref: Scan&Go': 'Pindai & Pergi',
    'Ref: Fulfillment Type': 'Jenis pemenuhan',
    'Ref: Delivery': 'Pengiriman',
    'Ref: You have no future orders': 'Anda tidak memiliki pesanan di masa depan',
    'Ref: You have no past orders': 'Anda tidak memiliki pesanan masa lalu',
    'Ref: You have no orders': 'Anda tidak punya pesanan',
    'Ref: All orders': 'Semua perintah',
    'Ref: Not past orders': 'Pesanan yang akan datang',
    'Ref: Past orders': 'Pesanan masa lalu',
    'Ref: Vegan': 'Vegan',
    'Ref: Vegeterian': 'Vegetarian',
    'Ref: See item': 'Lihat item',
    'Ref: Activated': 'Diaktifkan',
    'Ref: No suggestions': 'Tidak ada saran yang ditemukan',
    'Ref: Get your receipt': 'Dapatkan tanda terima Anda',
    'Ref: Print': 'Cetak kwitansi Anda',
    'Ref: Email': 'Terima kwitansi Anda melalui email',
    'Ref: Receipt Modal message': 'Bagaimana Anda ingin mendapatkan tanda terima?',
    'Ref: Confirm': 'Mengonfirmasi',
    'Ref: Your email address': 'Alamat email anda',
    Suggestion: 'Saran',
    'Ref: Loyalty Reward': 'Hadiah loyalitas',
    'Ref: Equation for stamps per item': '1 pembelian = 1 stempel',
    'Ref: Paid by employer': 'Jumlah yang dibayarkan oleh majikan Anda',
    'Ref: Paid by user': 'Jumlah yang dibayarkan oleh Anda',
  },
  ProductsList: {
    'Ref: Page title': 'Daftar Produk',
    'Ref: Facility details': 'Detail Fasilitas',
    'Ref: Search products list': 'Cari barang',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Momen',
    'Ref: filter label: category': 'Kategori',
    'Ref: all': 'Semua',
    'Ref: today': 'Hari ini',
    'Ref: tomorrow': 'Besok',
    'Ref: see more': 'Lihat lebih',
    'Ref: see less': 'Lihat lebih sedikit',
    'Ref: reset': 'Mengatur ulang',
    'Ref: View in cart': 'Lihat di CART',
    'Ref: You have changed location': 'Anda telah mengubah lokasi',
    'Ref: You are now at': 'Anda sekarang berada di',
    'Ref: Menu card': 'Kartu menu',
    'Ref: Table': 'Nomor Tabel',
    'Service unavailable': 'Layanan tidak tersedia',
    'Ref: Ordering unavailable':
      'Hati-hati! Memesan di situs ini tidak tersedia saat ini, silakan coba nanti.',
    'Ref: Filters': 'Filter (alergen dan banyak lagi)',
  },
  ProductScanner: {
    'Ref: cart': 'Keranjang',
    'Ref: Product Scanned':
      'ditambahkan ke kereta. Anda dapat terus memindai barcode untuk menambahkannya di keranjang Anda.',
    'Ref: Scan or adjust': 'ditambahkan ke kereta. Sesuaikan kuantitas atau pindai produk lain.',
    'Ref: Product Not Matched': 'Barcode tidak dikenali. Tolong coba lagi',
    'Ref: Scan article code': 'Memindai barcode',
    'Ref: Native error product cannot be added to cart':
      'Item dari menu atau bagian hari yang berbeda tidak dapat ditambahkan ke kereta',
    'Ref: product scanner placeholder':
      'Harap pindai kode bar untuk menambahkannya ke keranjang Anda',
  },
  Order: { 'Ref: Page title': 'Memesan' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menu',
    'Ref: today': 'Hari ini',
    'Ref: tomorrow': 'Besok',
    'Ref: Search products list': 'Cari barang',
    'Ref: You have changed location': 'Anda telah mengubah lokasi',
    'Ref: You are now at': 'Anda sekarang berada di',
  },
  ProductDetails: {
    'Ref: Page title': 'Rincian Produk',
    'Ref: Serving Size': 'Ukuran porsi',
    'Ref: Nutrition': 'Informasi gizi',
    'Ref: Nutrition adults': 'Orang dewasa membutuhkan sekitar 2000 kkal sehari',
    'Ref: Nutrition Calories': 'Kalori',
    'Ref: Nutrition Calcium': 'Kalsium',
    'Ref: Nutrition Fat': 'Lemak',
    'Ref: Nutrition Saturated Fat': 'Lemak jenuh',
    'Ref: Nutrition Carbohydrates': 'Karbohidrat',
    'Ref: Nutrition Sugar': 'Gula',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Serat makanan',
    'Ref: Nutrition iron': 'Besi',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C.',
    'Ref: Additives': 'Aditif',
    'Ref: Emission': 'emisi CO2',
    'Ref: This product generates': 'Produk ini menghasilkan',
    'Ref: of CO2': 'dari CO2.',
    'Ref: emissions, which equals a': 'emisi, yang sama dengan a',
    'Ref: car trip': 'Perjalanan mobil',
    'Ref: Ingredients': 'Bahan resep.',
    'Ref: Allergens': 'Alergen',
    'Ref: Always there': 'Selalu ada',
    'Ref: Allergens selected': 'Alergen dipilih pada filter:',
    'Ref: minimum required': 'Minimum diperlukan',
    'Ref: you have to add at least': 'Anda harus menambahkan setidaknya',
    'Ref: ingredients': 'bahan',
    'Ref: Ingredient': 'bahan',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Berdasarkan kustomisasi Anda',
    'Ref: Additives eggs': 'telur',
    'Ref: Additives fish': 'ikan',
    'Ref: Additives milk': 'susu',
    'Ref: Additives nuts': 'gila',
    'Ref: Additives wheat': 'gandum',
    'Ref: Additives peanuts': 'kacang kacangan',
    'Ref: Additives soy beans': 'Kacang kedelai',
    'Ref: Additives sesame seeds': 'biji wijen',
    'Ref: Review': 'Tinjauan',
    'Ref: Per portion': 'Per bagian',
    'Ref: Per 100g': 'Per 100g',
    'Ref: Allergen selected': 'Alergen dipilih pada filter pencarian',
    'Ref: Fitbit Log': 'Log ke Fitbit',
    'Ref: age verification required on delivery': 'Verifikasi usia diperlukan saat persalinan',
    'Ref: alcohol content notification':
      'Dengan menempatkan dan memesan untuk salah satu barang ini, Anda menyatakan bahwa Anda berusia 18 atau lebih. Penyalahgunaan alkohol berbahaya bagi kesehatan Anda.',
    'Ref: default': 'Bawaan',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} diperoleh jika Anda memesan produk ini',
    'Ref: Loyalty Card Description':
      '{stamp_form} akan ditambahkan ke loyalitas berikut {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program Perangko Loyalitas memungkinkan Anda mengumpulkan perangko untuk hadiah.',
    'Ref: Loyalty Modal Description':
      'Saat Anda membeli <b> {product_name}, 1 cap </b> akan ditambahkan ke <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'Dan',
    'Ref: Stamp': 'Stempel',
    'Ref: Stamps': 'Perangko',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Program',
    'Ref: Loyalty Stamps': 'Perangko loyalitas',
    'Ref: See Loyalty Stamps': 'Lihat perangko loyalitas',
    Favorite: 'Favorit',
    'Ref: In favorites': 'Produk sudah ada dalam daftar favorit. Klik untuk menghapusnya.',
    'Ref: Not in favorites': 'Produk belum ada dalam daftar favorit. Klik untuk menambahkannya.',
    'Ref: Added to favorites': 'Produk telah ditambahkan ke daftar favorit.',
    'Ref: Removed from favorites': 'Produk telah dihapus dari daftar favorit.',
    'Ref: From': 'Dari',
  },
  MenuCartActions: {
    'Ref: change in cart': 'PERUBAHAN CART',
    'Ref: Popup header for customizable item qty update': 'Item ini dapat disesuaikan',
    'Ref: add new version': 'Tambahkan versi baru',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Perbarui jumlah item yang dapat disesuaikan langsung dari keranjang Anda.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Perbarui jumlah item yang dapat disesuaikan langsung dari keranjang Anda, atau tambahkan versi baru.',
    'Ref: another context body':
      'Untuk menambahkan item dari situs ini, fasilitas, momen/menu, atau tanggal Anda perlu menghapus keranjang',
    'Ref: cancel': 'Membatalkan',
    'Ref: add to cart': 'Masukkan ke keranjang',
    'Ref: view in cart': 'Keranjang',
    'Ref: Products in cart': 'Jumlah {product_name} di cart: {product_quantity}',
    'Ref: Customize': 'Menyesuaikan',
    'Ref: Add': 'Menambahkan',
    'Ref: Free': 'Bebas',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Membatalkan',
    'Ref: notice': 'Melihat',
    'Ref: clear cart': 'Gerobak yang jelas',
    'Ref: Cart addition error message':
      'Saat ini Anda memiliki item dalam keranjang dari {from_current}. Untuk menambahkan item dari {from_selected} Anda perlu menghapus keranjang',
  },
  Cart: {
    'Ref: Page title': 'Keranjang',
    'Ref: The cart is empty': 'Gerobaknya kosong',
    'Ref: Clear cart': 'Gerobak yang jelas',
    'Ref: Continue shopping': 'Lanjutkan Belanja',
    'Ref: Start shopping': 'Mulailah berbelanja',
    'Ref: Today': 'Hari ini',
    'Ref: Tomorrow': 'Besok',
    'Ref: Tax:': 'Pajak:',
    'Ref: Promotion:': 'Promosi:',
    'Ref: Total without promotion:': 'Total tanpa promosi:',
    'Ref: Delivery:': 'Pengiriman:',
    'Ref: Pickup informations': 'Informasi penjemputan',
    'Ref: Location': 'Lokasi',
    'Ref: Time': 'Waktu',
    'Ref: Phone': 'Nomor telepon Anda',
    'Ref: Instructions': 'Instruksi',
    'Ref: Please select': 'Silahkan pilih',
    'Ref: Enter special requests': 'Masukkan permintaan khusus di sini',
    'Ref: notice': 'Melihat',
    'Ref: Product is no more available':
      'Maaf tapi sepertinya beberapa produk tidak lagi tersedia. Silakan periksa keranjang Anda.',
    'Ref: ok': 'Oke',
    'Ref: Validate': 'Validasi pesanan saya',
    'Ref: Pay now': 'Bayar sekarang',
    'Ref: Charge to my department': 'Biaya ke departemen saya',
    'Ref: Pay on pickup': 'Bayar barang -barang ini saat diambil',
    'Ref: Accept terms of sale prefix': 'saya menerima',
    'Ref: Accept terms of sale': 'Ketentuan Penjualan ini',
    'Ref: Continue': 'Melanjutkan',
    'Ref: Please login or sign up to place an order':
      'Harap masuk atau daftar untuk melakukan pemesanan',
    'Ref: Accept terms of sale suffix': ', dan saya telah membaca dan memahaminya.',
    'Ref: Payment modality': 'Cara Pembayaran',
    'Ref: Other information': 'Informasi lainnya',
    'Ref: Eat-in': 'Makan-dalam',
    'Ref: Pickup': 'Ambil',
    'Ref: Table': 'Nomor Tabel',
    'Ref: Required fields': 'Bidang yang diperlukan',
    'Service unavailable': 'Layanan tidak tersedia',
    'Ref: Cannot order':
      'Maaf, Anda tidak dapat memesan di situs ini saat ini. Silakan hubungi restoran untuk informasi lebih lanjut tentang jam buka dan layanan yang tersedia.',
    'Ref: Order Unavailable':
      'Pesan untuk tanggal yang dipilih, jenis pemenuhan dan lokasi tidak tersedia lagi!',
    'Ref: No Availability': 'Tidak ada ketersediaan lagi',
    'Ref: Continue on your device': 'Lanjutkan di perangkat Anda',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} diperoleh jika Anda menyelesaikan pesanan Anda',
    'Ref: Loyalty Card Description':
      '{stamp_form} akan ditambahkan ke loyalitas berikut {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program Perangko Loyalitas memungkinkan Anda mengumpulkan perangko untuk hadiah.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> Diperoleh jika Anda menyelesaikan pesanan Anda:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> ditambahkan untuk <b> {scheme_names} </b>',
    'Ref: Stamp': 'Stempel',
    'Ref: Stamps': 'Perangko',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Program',
    'Ref: Loyalty Stamps': 'Perangko loyalitas',
    'Ref: See Loyalty Stamps': 'Lihat perangko loyalitas',
    'Ref: Loyalty Info': 'Info loyalitas',
    'Ref: Email address': 'Alamat email',
    'Ref: and': 'Dan',
    'Ref: Order minimum amount': 'Maaf, jumlah pembayaran minimum adalah {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Lanjutkan di perangkat Anda',
    'Ref: Scan QR to continue':
      'Tolong, pindai kode QR untuk melanjutkan perjalanan di perangkat Anda.',
  },
  OrderHistory: { 'Ref: Orders': 'Pesanan', 'Ref: Page Title': 'pesananku' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Patokan harga',
    'Ref: Download': 'Unduh tanda terima',
    'Ref: Download error': 'Kesalahan unduhan telah terjadi. Coba unduh tanda terima nanti',
    'Ref: Order details title': 'Detail pesanan',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Terima kasih! Pesanan Anda sekarang dikonfirmasi',
    'Ref: Text for pickup':
      'Anda dapat mengikuti statusnya melalui riwayat pesanan Anda. Jika pemberitahuan Anda dihidupkan, kami akan memperbarui Anda saat siap untuk diambil.',
    'Ref: Success Order Title': 'Terima kasih atas pesanan Anda',
    'Ref: Success Order Description': 'Harap bayar di checkout untuk memvalidasi pesanan Anda.',
    'Ref: Success Order ID': 'Pesanan ID',
    'Ref: Success Order Action': 'Pesanan baru',
    'Ref: Text for dinein':
      'Pesanan Anda harus segera disajikan ke meja Anda. Tanyakan saja kepada salah satu anggota staf kami yang ramah tentang pesanan makan Anda.',
    'Ref: Text for scan&go': 'Pesanan Anda tersedia dalam riwayat pesanan Anda',
    'Ref: Text for delivery':
      'Anda dapat melacak statusnya dalam riwayat pesanan Anda. Jika pemberitahuan Anda diaktifkan, kami akan memberi tahu Anda saat pesanan Anda siap.',
    'See details': 'Lihat detailnya',
    'Go to home': 'Pergi ke rumah',
  },
  FailOrder: {
    'Go to home': 'Pergi ke rumah',
    'Ref: Kiosk Fail Title': 'Ups! Ada yang salah!',
    'Ref: Kiosk Fail Description':
      'Sayangnya, pesanan Anda tidak berhasil. Silakan coba lagi atau minta anggota staf untuk membantu Anda.',
    'Ref: Kiosk Fail Back': 'kembali ke halaman sebelumnya',
    'Ref: Kiosk Fail Action': 'Hapus sesi dan pesan lagi',
    'Ref: Error in cart validation':
      'Kesalahan terjadi selama pesanan Anda. Silakan hubungi fasilitas untuk menyelesaikan masalah ini.',
    'Facility contact': 'Kontak fasilitas',
    'Ref: Ordering failed': 'Pemesanan gagal',
    'Ref: Order confirmed': 'Pesanan dikonfirmasi',
  },
  TableCheckIn: {
    'Welcome at': 'Selamat datang di',
    Table: 'Meja:',
    'Ref: list title': 'Pesanan terbaru untuk hari ini',
    'See the menu': 'Lihat menu',
    'Table Check-in': 'Check-in meja',
    'Ref #': 'Ref #',
    Checked: 'Diperiksa',
    'Check in': 'Mendaftar',
    'See details': 'Lihat detailnya',
    'Ref: You have changed location': 'Anda telah mengubah lokasi',
    'Ref: You are now at': 'Anda sekarang berada di',
    'Order number': 'Jumlah order',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promosi diterapkan',
    'Ref: Promotion applied description': 'Anda menguntungkan promosi dari keranjang Anda.',
    'Ref: Promotion deleted title': 'Promosi dihapus',
    'Ref: Promotion deleted description':
      'Anda tidak lagi menguntungkan promosi karena Anda tidak lagi mengisi kondisi atau promosi telah kedaluwarsa.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Pindai & Pergi', 'Ref: View only': 'Lihat saja' },
  ProductTile: {
    'Ref: Allergens': 'Alergen',
    'Ref: Presence of allergen': 'Kehadiran alergen',
    'Ref: Scan': 'Pindai',
    'Ref: From': 'Dari',
    'Ref: Already in product favorite list. Click to remove it.':
      'Sudah dalam daftar favorit produk. Klik untuk menghapusnya.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Saat ini tidak ditambahkan ke daftar favorit produk. Klik untuk menambahkannya.',
    'Ref: Free': 'Bebas',
  },
  OrderSummary: {
    'Ref: item': 'barang',
    'Ref: items': 'item',
    'Ref: Clear': 'Jernih',
    'Ref: Cart': 'Keranjang',
    'Ref: Subtotal': 'Subtotal',
    'Ref: Total': 'Total',
    'Ref: Tax': 'Pajak',
    'Ref: Promotion': 'Promosi',
    'Ref: Total without promotion': 'Total tanpa promosi',
    'Ref: Number of product in cart': 'Jumlah produk dalam keranjang',
    'Ref: Delivery': 'Pengiriman',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegan', 'Ref: Vegeterian': 'Vegetarian' },
  OrderInfoCard: {
    'Ref: Header': 'Dipesan untuk',
    'Ref: from': 'dari',
    'Ref: location': 'Lokasi',
    'Ref: Phone': 'Nomor telepon Anda',
    'Ref: Instructions': 'Instruksi',
    'Ref: Order type': 'Jenis pesanan',
    'Ref: Pickup': 'Ambil',
    'Ref: Eat-in': 'Makan-dalam',
    'Ref: Scan&Go': 'Pindai & Pergi',
  },
  ReorderWidget: {
    'Ref: No items': 'Belum ada pesanan. Berbelanja sekarang!',
    'Ref: Title': 'Pesan lagi',
  },
  MenusWidget: {
    'Ref: no menu': 'Tidak ada menu untuk saat ini',
    'Ref: Modal title': 'Menu untuk Hari Ini',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Diskon tersedia' },
  PromotionInfoItem: { 'Ref: Activate': 'Mengaktifkan' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Pesanan Anda', 'Ref: Widget Title': 'pesananku' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Saran untuk Hari Ini',
    'Ref: Any menu': 'Menu apapun',
    Other: 'Lainnya',
    'Ref: Suggestions calendar button': 'Kalender saran',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Ukuran porsi',
    'Ref: Nutrition': 'Informasi gizi',
    'Ref: Nutrition adults': 'Orang dewasa membutuhkan sekitar 2000 kkal sehari',
    'Ref: Nutrition Calories': 'Kalori',
    'Ref: Nutrition Calcium': 'Kalsium',
    'Ref: Nutrition Fat': 'Lemak',
    'Ref: Nutrition Saturated Fat': 'Lemak jenuh',
    'Ref: Nutrition Carbohydrates': 'Karbohidrat',
    'Ref: Nutrition Sugar': 'Gula',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Serat makanan',
    'Ref: Nutrition iron': 'Besi',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C.',
    'Ref: Ingredients': 'Bahan resep.',
    'Ref: Allergens': 'Alergen',
    'Ref: Always there': 'Selalu ada',
    'Ref: Allergens selected': 'Alergen dipilih pada filter:',
    'Ref: minimum required': 'Minimum diperlukan',
    'Ref: ingredients': 'bahan',
    'Ref: Ingredient': 'bahan',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Berdasarkan kustomisasi Anda',
    'Ref: Additives eggs': 'telur',
    'Ref: Additives fish': 'ikan',
    'Ref: Additives milk': 'susu',
    'Ref: Additives nuts': 'gila',
    'Ref: Additives wheat': 'gandum',
    'Ref: Additives peanuts': 'kacang kacangan',
    'Ref: Additives soy beans': 'Kacang kedelai',
    'Ref: Additives sesame seeds': 'biji wijen',
    'Ref: Review': 'Tinjauan',
    'Ref: Per portion': 'Per bagian',
    'Ref: Per 100g': 'Per 100g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} gratis',
    'Ref: Number of free items': 'Jumlah item gratis: {number_of_free_items}',
    'Ref: Allergen selected': 'Alergen dipilih pada filter pencarian',
    'Ref: Nutritional info': 'Info nutrisi',
    'Ref: Info': 'Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Promosi' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Pilih pekerjaan Anda',
    'Ref: Validate': 'Mengesahkan',
    'Ref: Pricing Tiers dialog message': 'Anda telah diakui dengan alamat email Anda',
    'Ref: Please select your job in the company': 'Pilih pekerjaan Anda di perusahaan',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Ambil',
    'Ref: Eat-in': 'Makan-dalam',
    'Ref: Scan&Go': 'Pindai & Pergi',
    'Ref: Delivery': 'Pengiriman',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Bagaimana {facility_name} layanan?',
    'Ref: How do you rate the service': 'Bagaimana Anda menilai layanan?',
  },
};
export default labels;
