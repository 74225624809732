import { renderHook, waitFor } from '@testing-library/react';

import { defaultCart } from '../../__mocks/mock';

import { useRetrieveKioskSession } from './useRetrieveKioskSession';

import { ISite } from '@/modules/Sites/types/sites.types';

const currentSideId = 'currentSideId';
const trackingId = 'trackingId';
const mockDispatch = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

const mockCleanCart = jest.fn();
const mockRetrieveOrderSession = jest.fn();
jest.mock('../../actions', () => ({
  ...jest.requireActual('../../actions'),
  cleanCart: () => mockCleanCart,
  retrieveOrderSession: () => mockRetrieveOrderSession,
}));

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () =>
    ({
      id: currentSideId,
    } as ISite),
}));

const mockSwitchSite = jest.fn();
const mockUseSwitchSite = jest.fn();
jest.mock('../useSiteSwitch/useSwitchSite', () => ({
  ...jest.requireActual('../useSiteSwitch/useSwitchSite'),
  useSwitchSite: () => mockUseSwitchSite(),
}));

const mockPushHistory = jest.fn();
const mockUseHistory = jest.fn();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockUseHistory(),
}));

describe('useRetrieveKioskSession', () => {
  beforeEach(() => {
    mockDispatch.mockImplementation((action) => action());
    mockUseSwitchSite.mockReturnValue({ switchSite: mockSwitchSite });
    mockUseHistory.mockReturnValue({ push: mockPushHistory });
  });

  it('should not fetch session from server when trackingId not provided', async () => {
    const { result } = renderHook(() =>
      useRetrieveKioskSession({
        cart: defaultCart,
        siteIdFromKiosk: currentSideId,
        trackingId: undefined,
      })
    );

    expect(result.current.isRetrievingOrder).toBeFalsy();

    await waitFor(() => {
      expect(result.current.isRetrievingOrder).toBeFalsy();
      expect(mockCleanCart).not.toBeCalled();
      expect(mockRetrieveOrderSession).not.toBeCalled();
    });
  });

  it('should clear existing cart', async () => {
    const { result } = renderHook(() =>
      useRetrieveKioskSession({
        cart: defaultCart,
        siteIdFromKiosk: currentSideId,
        trackingId,
      })
    );

    expect(result.current.isRetrievingOrder).toBeTruthy();

    await waitFor(() => {
      expect(result.current.isRetrievingOrder).toBeFalsy();
      expect(mockCleanCart).toBeCalled();
      expect(mockRetrieveOrderSession).toBeCalled();
    });
  });

  it('should switch site when current site is different', async () => {
    const { result } = renderHook(() =>
      useRetrieveKioskSession({
        cart: defaultCart,
        siteIdFromKiosk: 'differentSiteId',
        trackingId,
      })
    );

    expect(result.current.isRetrievingOrder).toBeTruthy();

    await waitFor(() => {
      expect(result.current.isRetrievingOrder).toBeFalsy();
      expect(mockCleanCart).toBeCalled();
      expect(mockSwitchSite).toBeCalled();
      expect(mockPushHistory).toBeCalledTimes(2);
    });
  });
});
