import { SharedActionsType } from '../../../types';
import { IContent } from '../types';

enum ACTIONS {
  RESET_STATE = 'RESET_STATE',

  CONTENT_FETCHING_HOME = 'CONTENT_FETCHING_HOME',
  CONTENT_FETCHED_HOME = 'CONTENT_FETCHED_HOME',
  CONTENT_SUCCESS_HOME = 'CONTENT_SUCCESS_HOME',

  CONTENT_RESET_ONE = 'CONTENT_RESET_ONE',
}

type ActionWithoutPayload = {
  type: ACTIONS.CONTENT_FETCHING_HOME | ACTIONS.CONTENT_FETCHED_HOME | ACTIONS.CONTENT_RESET_ONE;
};

export interface IFetchingContentHomeSuccessAction {
  type: ACTIONS.CONTENT_SUCCESS_HOME;
  list: IContent[];
  siteId: string;
}

export type ContentActionType =
  | ActionWithoutPayload
  | IFetchingContentHomeSuccessAction
  | SharedActionsType;

export { ACTIONS };
