import { serviceTypes, modulesConfig } from '../config';

export const cacheVersion = '0.1';
export const moduleId = 'Stamps';
export const modulePath = serviceTypes[modulesConfig.Stamps.serviceType].path;

export const pagePaths = {
  Module: modulePath,
};

export const defaultValues = {
  redeemTimeInSeconds: 60,
};

export const waitBetweenScansInMs = 2000;
