import biteApi from '../../../services/api/biteApi';
import mockResponse from '../mocks/api';

import { SupplierLocation } from './api.types';

export const endpoints = {
  getSuppliers: '/v1/locations/suppliers',
};

const suppliersApi = biteApi.enhanceEndpoints({ addTagTypes: ['suppliers'] }).injectEndpoints({
  endpoints: (build) => ({
    getSuppliers: build.query<SupplierLocation, string>({
      query: (siteId: string) => ({
        url: endpoints.getSuppliers + `?siteId=${siteId}`,
        method: 'get',
        mockData: mockResponse.getSuppliers,
      }),
    }),
  }),
});

export const { useGetSuppliersQuery } = suppliersApi;
export default suppliersApi;
