import mockResponse from '../mockResponse';

import { PaymentAmountInfo } from './paymentApi.types';

import paymentApi from '@/services/api/paymentApi';

const paymentInfoApi = paymentApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentMinimumAmountInfo: builder.query<
      PaymentAmountInfo,
      { pspCode: string; currency: string }
    >({
      query: ({ pspCode, currency }) => ({
        url: '/v1/paymentInfo/minimum-amount',
        method: 'get',
        data: { pspCode, currency },
        params: {
          pspCode,
          currency,
        },
        mockData: mockResponse.paymentApi.getPaymentMinimumAmountInfo,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useLazyGetPaymentMinimumAmountInfoQuery } = paymentInfoApi;

export default paymentInfoApi;
