import { useMemo } from 'react';

import { menuVisitCacheKey } from '../../config';
import { MenuVisits } from '../../types/order.types';

function useMenusVisits(siteId: string) {
  const cacheKey = useMemo(() => `${menuVisitCacheKey}_${siteId}`, [siteId]);
  const menusVisits = useMemo(
    () => JSON.parse(localStorage.getItem(cacheKey) || '[]') as MenuVisits[],
    [cacheKey]
  );

  const increaseMenuVisits = (menuId: number) => {
    const menuItemVisits = menusVisits?.find((x) => x.menuId === menuId);
    if (menuItemVisits) {
      const itemIndex = menusVisits.indexOf(menuItemVisits);
      menusVisits[itemIndex].visits = menuItemVisits.visits + 1;
      localStorage.setItem(cacheKey, JSON.stringify(menusVisits));
    } else {
      const newMenuItemVisits = { menuId, visits: 1 };
      localStorage.setItem(cacheKey, JSON.stringify([...menusVisits, newMenuItemVisits]));
    }
  };

  return { menusVisits, increaseMenuVisits };
}

export default useMenusVisits;
