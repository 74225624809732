const labels = {
  All: { 'Ref: Page title': 'Fournisseurs' },
  SuppliersList: {
    'Ref: Supplier details': 'Voir les détails',
    'Missing supplier list': "Nous n'avons trouvé aucun fournisseur.",
    'Try again later': 'Réessayez plus tard',
  },
  SupplierDetails: {
    'Address label': 'Adresse:',
    'Website label': 'Site Internet:',
    'Details label': 'Détails',
  },
};
export default labels;
