import momentjs from 'moment';
import { ReactElement, useCallback, useEffect, useReducer, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import { MenuIllustration } from '../../../assets/illustrations';
import Column from '../../../components/organisms/Column';
import ListPage from '../../../components/templates/ListPage/ListPage';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { DATE_FORMAT } from '../../../constants';
import {
  cleanCart,
  getMenus,
  getPreselectedFacility,
  getPreselectedSite,
  setSiteChanged,
  setSiteModalClosed,
} from '../actions';
import { orderConfig, pagePaths } from '../config';
import {
  cacheSelectedFilters,
  convertToMenuItems,
  filterForPreselectedFacility,
  getDateFilter,
  getDefaultFiltersFromCache,
  handleSiteRedirection,
  isSiteRedirection,
  redirectIfOnlyOneMenuAvailable,
  siteChangeNotification,
  menuSelectorReducer,
  redirectForScan,
  redirectToMenuListOrProductsList,
} from '../helpers/menuSelector.helper';
import { getMenuType } from '../helpers/order.helper';
import useDiscountTiersModal from '../hooks/useDiscountTiersModal';
import { useFacilityMenuImage } from '../hooks/useFacilityMenuImage/useFacilityMenuImage';
import useFacilityMenuList from '../hooks/useFacilityMenuList';
import useMenuSelectorFilter from '../hooks/useMenuSelectorFilter/useMenuSelectorFilter';
import useModuleContext from '../hooks/useModuleContext';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import useResetTableAfterOneHour from '../hooks/useResetTableAfterOneHour';
import { useSwitchSite } from '../hooks/useSiteSwitch/useSwitchSite';
import {
  MenuListFiltering,
  MenuListProps,
  MenuSelectorActionType,
  MenuSelectorListItem,
  MenuSelectorRouteParams,
  MenuType,
} from '../types/menuSelector.types';
import { ModuleContext } from '../types/order.types';
import { FacilityMenuSource, StateWithOrder } from '../types/orderState.types';

import DiscountTiersModal from './DiscountTiersModal/DiscountTiersModal';
import MenuTile from './MenuTile/MenuTile';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { SERVICE } from '@/modules/config';
import useSite from '@/modules/Core/hooks/useSite';
import { getAll } from '@/modules/Facilities/redux/actions';
import { UserSteps } from '@/types/userSteps.types';

import styles from './MenuSelector.module.css';

const MenuSelector = ({ facilityMenuPath, menuType }: MenuListProps): ReactElement => {
  const { isDateFilteringEnabled, isMenuRedirectionEnabled, isNavbarEnabled, isKioskTitleBar } =
    orderConfig();
  const { switchSite } = useSwitchSite();
  const { logUserSteps } = useUserStepsInsightsLogging();

  const {
    outletId,
    locationId,
    barcode,
    menuId: menuIdParam,
    date,
  } = useParams<MenuSelectorRouteParams>();

  const { path } = useRouteMatch();

  const isRedirection =
    path === pagePaths.MenuRedirection || path === pagePaths.ViewableMenuRedirection;

  const menuId = +(menuIdParam || '');

  const menuDateParams = momentjs(date, DATE_FORMAT, true);
  const menuDate =
    menuDateParams.isValid() && menuDateParams.isAfter(momentjs().add(-1, 'days'))
      ? date
      : undefined;

  const orderState = useSelector((state: StateWithOrder) => state.Order);

  const preselectedFacilityId = outletId ? orderState.preselectedFacilityId : undefined;
  const preselectedSiteId = locationId ? orderState.preselectedSiteId : undefined;
  const preselectedLocationId = locationId ? orderState.preselectedLocationId : undefined;

  const { hasSiteChanged, errors, tableNumber, tableQRScanTime, discountTiers, locks } = orderState;

  const hasFetchSiteIdFailed = errors?.fetchingSiteId;
  const discountTiersList = discountTiers?.pricingTiers ?? [];
  const isLocked =
    locks?.getMenusForFacility ||
    locks?.getDiscountTiers ||
    locks?.getTiersStatus ||
    locks?.getPreselectedFacilityId ||
    locks?.getPreselectedSiteId ||
    locks?.getPendingOrders;

  const defaultFiltersFromCache = !locationId && !outletId ? getDefaultFiltersFromCache() : null;
  const availableServices = useSelector((state: StateWithOrder) => state.Core?.services.list);
  const calculatedMenuType = getMenuType(availableServices, menuType === MenuType.NonOrderable);
  const { label } = useOrderTranslation(__filename);

  const dispatch = useDispatch();
  const site = useSite();

  const {
    menuDate: selectedDate,
    setMenuDate: updateDate,
    filtering,
    setFiltering,
  } = useMenuSelectorFilter(menuDate, defaultFiltersFromCache);

  const [state, localDispatch] = useReducer(menuSelectorReducer, {
    hasMenuFetched: false,
    setDiscountTierStatus: false,
  });

  const facilities = useFacilityMenuList();
  const facilityImagesInfo = useMemo(
    () => facilities.map((facility) => ({ facilityId: facility.id, imageId: facility.imageId })),
    [facilities]
  );
  const { getMenuImage } = useFacilityMenuImage(facilityImagesInfo);

  const [moduleContext] = useModuleContext();

  const history = useHistory();

  const listElements: MenuSelectorListItem[] = useMemo(
    () =>
      filterForPreselectedFacility(
        convertToMenuItems({
          facilities,
          menuType: calculatedMenuType,
          getMenuImage,
        }),
        preselectedFacilityId
      ),
    [facilities, preselectedFacilityId, getMenuImage, calculatedMenuType]
  );

  const refreshMenuAndHandleSiteRedirection = useCallback(async () => {
    if (
      isSiteRedirection({
        locationId: locationId,
        site,
        preselectedLocationId,
        preselectedSiteId,
      }) &&
      !hasFetchSiteIdFailed
    ) {
      await dispatch(cleanCart());
      if (preselectedSiteId)
        await dispatch(getAll({ siteId: site?.id }, { useErrorBoundary: false }));

      await handleSiteRedirection({
        getPreselectedSiteId: () => dispatch(getPreselectedSite({ locationId })),
        locationId,
        preselectedLocationId,
        preselectedSiteId,
        switchSite,
        site: site!,
      });
      await dispatch(setSiteChanged());
    } else {
      if (!isLocked && site?.id && selectedDate)
        await dispatch(
          getMenus(
            { siteId: site.id, date: selectedDate, menuType: calculatedMenuType },
            { useErrorBoundary: false }
          )
        );
      if (outletId && !isLocked && site?.id)
        await dispatch(getPreselectedFacility({ siteId: site.id, outletId }));

      localDispatch({ type: MenuSelectorActionType.UPDATE_HAS_MENU_FETCHED, hasMenuFetched: true });
      if (!locationId) await dispatch(setSiteModalClosed());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locationId,
    preselectedLocationId,
    preselectedSiteId,
    site?.id,
    selectedDate,
    outletId,
    preselectedFacilityId,
    history,
    moduleContext,
    dispatch,
  ]);

  useResetTableAfterOneHour({ tableQRScanTime });

  const { isTiersModalOpen, closeTiersModal } = useDiscountTiersModal({
    siteId: site?.id,
    localDispatch,
    postSelectionAction: refreshMenuAndHandleSiteRedirection,
  });

  useEffect(
    function redirectToMenuForPreselectedFacility() {
      if (!state.hasMenuFetched || isLocked || tableNumber) return;
      redirectIfOnlyOneMenuAvailable({
        listElements,
        selectedDate,
        facilityId: isMenuRedirectionEnabled
          ? listElements[0]?.menu.facilityId
          : preselectedFacilityId,
        history,
        isMenuModule: moduleContext === ModuleContext.MENU,
      });
    },
    [
      history,
      moduleContext,
      listElements,
      preselectedFacilityId,
      selectedDate,
      state.hasMenuFetched,
      isMenuRedirectionEnabled,
      isLocked,
      tableNumber,
    ]
  );

  useEffect(
    function redirectToMenu() {
      if (!state.hasMenuFetched) return;

      if (barcode && menuId)
        redirectForScan({
          listElements,
          selectedDate,
          menuId,
          history,
          barcode,
        });

      if (isRedirection)
        redirectToMenuListOrProductsList({
          listElements,
          selectedDate,
          menuId,
          history,
          menuType: calculatedMenuType,
        });
    },
    [
      history,
      listElements,
      menuId,
      selectedDate,
      state.hasMenuFetched,
      barcode,
      isRedirection,
      calculatedMenuType,
    ]
  );

  useEffect(() => {
    logUserSteps({ event: UserSteps.MenuList });
  }, [logUserSteps]);

  const dateFilter = useMemo(
    () => getDateFilter({ label, listElements, selectedDate, updateDate }),
    [label, listElements, selectedDate, updateDate]
  );

  const listFilters = {
    filters: dateFilter,
    initialFiltering: filtering,
    handleFilteringChange: (filtering: MenuListFiltering) => {
      setFiltering(filtering);
      cacheSelectedFilters(filtering);
    },
  };

  const filter = {
    ...(isDateFilteringEnabled ? { filter: listFilters } : {}),
  };

  const search = {
    searchableKeys: ['facilityTitle'],
    placeholder: label('Ref: Search products list'),
  };

  const topContentSiteChangeNotification = siteChangeNotification({
    hasSiteChanged,
    setSiteModalClosed,
    siteName: site?.name,
    labelFn: label,
  });

  const listTopContent =
    !hasFetchSiteIdFailed && locationId ? (
      <div className={styles.switchSiteNotification}>{topContentSiteChangeNotification}</div>
    ) : (
      <></>
    );

  const canRedirectToProductList =
    listElements.length === 1 &&
    !listElements[0].menu.isFutureOrdering &&
    listElements[0].menu.source === FacilityMenuSource.Rr &&
    listElements[0].menu.isOrderable;

  if (canRedirectToProductList) {
    const isMenuModule =
      !listElements[0].menu.isOrderable &&
      availableServices.some((x) => x.name === SERVICE.FOOD_MENU);

    const facilityPath = facilityMenuPath
      ? facilityMenuPath(
          listElements[0].menu.id,
          listElements[0].menu.facilityId,
          selectedDate,
          isMenuModule
        )
      : '';

    if (facilityMenuPath) {
      history.push(facilityPath);
      logUserSteps({ event: UserSteps.OnlyMenuRedirection });
    }
  }

  const discountTiersModal = site && (
    <DiscountTiersModal
      siteId={site.id}
      closeTiersModal={closeTiersModal}
      discountTiersList={discountTiersList}
      isTiersModalOpen={isTiersModalOpen}
      postSelecitonAction={refreshMenuAndHandleSiteRedirection}
      tier={state.tier}
    />
  );

  const facilityList: ReactElement = (
    <>
      <ListPage
        data-testid="menu-selector-list"
        hasBackLink={false}
        title={label('Ref: Menu Page title')}
        items={listElements}
        {...filter}
        withNavBar={isNavbarEnabled}
        listTopContent={listTopContent}
        hideFilterTitle={true}
        hideAccountWidget={isKioskTitleBar}
        hideNotificationsWidget={isKioskTitleBar}
        search={search}
        aside={
          <Column.Complementary>
            <MenuIllustration />
          </Column.Complementary>
        }
        itemRenderer={(item: MenuSelectorListItem) => {
          const props = {
            ...item,
            facilityMenuPath,
            selectedDate,
          };

          return <MenuTile key={item.id} {...props} />;
        }}
      />
      {discountTiersModal}
    </>
  );

  const isLoadingDueToDiscountTier = !state.setDiscountTierStatus;

  const isLoadingDueToSiteChange =
    locationId && !preselectedSiteId && isLoadingDueToDiscountTier && !hasFetchSiteIdFailed;
  const isLoadingDueToFetchingMenu = !state.hasMenuFetched && isLoadingDueToDiscountTier;

  if (isLocked || !site || isLoadingDueToFetchingMenu || isLoadingDueToSiteChange)
    return <LoadingPage />;

  return facilityList;
};

export default MenuSelector;
