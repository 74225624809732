import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import ContinueJourneyModal from './ContinueJourneyModal';

describe('ContinueJourneyModal component', () => {
  afterAll(() => cleanup());

  describe('ContinueJourneyModal shown', () => {
    const label = jest.fn();
    const hideModal = jest.fn();
    renderComponent(ContinueJourneyModal, {
      siteId: 'siteid',
      submissionTrackingId: 'trackingid',
      isModalOpen: true,
      hideModal: hideModal,
      label,
    });

    it('should display QR code price', () => {
      const qr = screen.queryByTestId('qr-code-to-continue-journey');

      expect(qr).toBeTruthy();
    });
  });
});
