import { getApiEndpoints } from '../config';
import mockResponse from '../mockResponse';
import { Facility } from '../redux/actions.types';

import { GetAllFacilitiesResponse, GetFacilitiesRequest, GetFacilityRequest } from './api.types';

import { getImageSrcFromId, getImagesSrcFromIds } from '@/helpers/images/images.helper';

const { api, getAllFacilities, getOneFacility, getFacilityParam } = getApiEndpoints();

const facilitiesApi = api.enhanceEndpoints({ addTagTypes: ['facilities'] }).injectEndpoints({
  endpoints: (build) => ({
    getFacilitiesList: build.query<GetAllFacilitiesResponse, GetFacilitiesRequest>({
      query: ({ siteId }) => ({
        url: getAllFacilities,
        method: 'get',
        params: { siteId },
        mockData: mockResponse.getAllFacilities,
      }),

      transformResponse: async (response: GetAllFacilitiesResponse, meta, args) => {
        const imageIds: string[] = response?.facilities
          ?.filter((facility: Facility) => !!facility.images?.[0])
          .map((facility: Facility) => facility.images?.[0]);

        const images = await getImagesSrcFromIds(imageIds);

        response.facilities = response?.facilities?.map((facility: Facility) => {
          const imageId = facility.images?.[0];
          if (imageId) {
            const imageInfo = images.find((img) => img.id === imageId);
            facility.imageInfo = imageInfo
              ? { src: imageInfo.content, alt: facility.title }
              : undefined;
          }
          return facility;
        });

        args.dispatch({
          type: 'FACILITIES_SUCCESS_ALL',
          facilities: response.facilities,
          siteId: args.siteId,
        });

        return response;
      },
      providesTags: ['facilities'],
    }),
    getFacilityDetails: build.query<Facility, GetFacilityRequest>({
      query: ({ facilityId }) => ({
        url: getOneFacility.replace(':id', facilityId),
        method: 'get',
        params: getFacilityParam(facilityId),
        mockData: mockResponse.getOneFacility,
      }),
      transformResponse: async (response: Facility, meta, args) => {
        const imageId = response.images?.[0];
        if (imageId) {
          const imageInfo = await getImageSrcFromId(imageId);

          response.imageInfo = imageInfo ? { src: imageInfo, alt: response.title } : undefined;
        }

        return response;
      },
      providesTags: (result) => [{ type: 'facilities', id: result?.id }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetFacilitiesListQuery, useGetFacilityDetailsQuery } = facilitiesApi;
export default facilitiesApi;
