import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { pagePaths as loyaltyStampsPaths } from '../LoyaltyStamps/config';
import { pagePaths as orderPaths } from '../Order/config';
import { pagePaths as stampsPaths } from '../Stamps/config';

import { TroubleLoggingInConfigData } from './components/TroubleLoggingIn/TroubleLoggingIn.types';
import { Language } from './types/index';

export const moduleId = 'Core';

export const coreCacheVersion = '0.4';
export const sharedCacheVersion = '0.4';

export const pagePaths = {
  Share: '/share/:siteCode',
  Account: '/account',
  GenericFailurePage: '/failed',
  GenericErrorPage: '/error',
  Home: '/',
  Language: '/account/language',
  AccountPasswordChange: '/account/password_change',
  AccountPasswordChangeSuccess: '/account/password_change/success',
  AccountPasswordChangeFailure: '/account/password_change/failure',
  AccountDelete: '/account/delete',
  PersonalInterests: '/account/personal_interests',
  FoodPref: '/account/food_preferences',
  LegalDoc: '/legal/:document_type/:version',
  Login: '/access/login',
  Idp: '/access/idp',
  ResetPasswordSuccess: '/access/password_reset/success',
  ResetPasswordFailure: '/access/password_reset/failure',
  ResetPassword: '/access/password_reset/:id',
  ResetPasswordEmailLink: '/password/reset/:id',
  ResetEmailSent: '/access/password_forgotten/email_sent',
  PasswordForgotten: '/access/password_forgotten',
  PasswordChange: '/access/password_change',
  Validation: '/access/validation',
  Logout: '/logout',
  Notifications: '/notifications',
  Registration: '/registration',
  SiteDetails: 'site/:id/details',
  Sites: '/sites',
  TroubleLoggingIn: '/access/trouble_logging_in',
  TroubleLoggingInFormSuccessPage: '/access/trouble_logging_in_success',
  Onboarding: '/onboarding',
  LetsGo: '/account/information',
  AppUpdate: '/app_update',
  OnboardingLocation: '/onboarding/location',
  OnboardingInformation: '/onboarding/information',
  GuestRegistration: '/guest/register',
  GuestRegistrationSuccess: '/guest/registration-success',
  AccountDeletionFailure: '/account/deletion/failure',
  ExternalContent: '/externalContent',
  Welcome: '/welcome',
  PublicLegalDoc: '/public_legal/:document_type',
  ModuleRedirect: '/module_redirect/:geoCode/:siteIdentifier/:module',
};

export const pagePathsAvailableWithoutSiteContext = [pagePaths.Sites, pagePaths.Account];

export const pagePathsAvailableWithoutContract = [pagePaths.Account];

//this is the list of available languages
//each language must have a name
//each language must have a backLanguageId to be used in the back. may correspond to the same language code, or not
//each language may be the default i.e will be used if we receive a 2-letter code
//names should not be translated, they are displayed as-is in the UI
export const languages: Language[] = [
  {
    code: 'id-ID',
    name: 'Bahasa Indonesia',
    backLanguageId: 'b7cf25b9-8e4f-4ea5-9e3e-fad4a97fb770',
    default: true,
  },
  {
    code: 'de-DE',
    name: 'Deutsch',
    backLanguageId: '0e3ad2c8-a9dd-4a5b-b32b-2f65a29462e0',
    default: true,
  },
  {
    code: 'en-US',
    name: 'English (United States)',
    backLanguageId: '4bba9048-623f-4d2f-aa81-64da7fac16a4',
    default: true,
  },
  {
    code: 'en-GB',
    name: 'English (United Kingdom)',
    backLanguageId: '4bba9048-623f-4d2f-aa81-64da7fac16a5', // TODO: to myWay - when changing to LCID please use value 2057 as stated https://docs.microsoft.com/en-us/openspecs/office_standards/ms-oe376/6c085406-a698-4e12-9d4d-c3b0ee3dbc4a
    default: true,
  },
  {
    code: 'es-ES',
    name: 'Español',
    backLanguageId: '62701e44-442d-4f79-ab88-f6fb34b531c2',
    default: true,
  },
  {
    code: 'fr-FR',
    name: 'Français',
    backLanguageId: '9c205eeb-d8f2-4035-8440-7ed9692b9b5c',
    default: true,
  },
  // {
  // code: 'ko',
  // name: '한국어',
  // backLanguageId: '972e035b-cc05-4a9a-ac15-e4d900b293b5',
  // default: true,
  // },
  {
    code: 'hu-HU',
    name: 'Magyar',
    backLanguageId: 'c5ee931b-cba2-46ad-84fe-2f7883e7c1b1',
    default: true,
  },
  {
    code: 'it-IT',
    name: 'Italiano',
    backLanguageId: '452a5674-b449-4d47-bbab-4791ea1b2240',
    default: true,
  },
  {
    code: 'nl-NL',
    name: 'Nederlands',
    backLanguageId: '73a78dc2-221b-4757-8c08-912d86e9e63c',
    default: true,
  },
  {
    code: 'lt-LT',
    name: 'Lietuvių',
    backLanguageId: '767e4469-16bc-425f-a556-f4a1c743bc06',
    default: true,
  },
  {
    code: 'pl-PL',
    name: 'Polski',
    backLanguageId: '87c2bc80-9246-4ce5-8c9b-264649e055cd',
    default: true,
  },
  {
    code: 'pt-PT',
    name: 'Português (Portugal)',
    backLanguageId: '10b25d7a-68be-e911-b083-0003ff4cb7fc',
    default: true,
  },
  {
    code: 'pt-BR',
    name: 'Português (Brasil)',
    backLanguageId: '10b25d7a-68be-e911-b083-0003ff4cb7fc',
    default: true,
  },
  {
    code: 'ru-RU',
    name: 'русский язык',
    backLanguageId: '6e178e9b-e5fa-4351-8456-f245c8968c3d',
    default: true,
  },
  {
    code: 'vi-VN',
    name: 'Tiếng Việt',
    backLanguageId: 'aaffd923-bb28-4f0f-97d8-64b757b1316e',
    default: true,
  },
  {
    code: 'tr-TR',
    name: 'Türkçe',
    backLanguageId: 'a618f98e-bc9e-43a5-8289-26c0d61be953',
    default: true,
  },
  {
    code: 'th-TH',
    name: 'ไทย',
    backLanguageId: '23a002b6-1528-4b16-9c07-e80bf72b3a84',
    default: true,
  },
  {
    code: 'ja-JP',
    name: '日本語',
    backLanguageId: '13740999-5f1f-4621-8ece-88268616d9ff',
    default: true,
  },
  {
    code: 'zh-CN',
    name: '中文(中国)',
    backLanguageId: '7b729860-4c54-e911-a859-000d3ab4fddf',
    default: true,
  },
  {
    code: 'zh-HK',
    name: '中文(香港特別行政區)',
    backLanguageId: '1a5faa2d-adc9-4643-b35b-38583a0c40c0',
    default: true,
  },
  {
    code: 'sv-SE',
    name: 'Svenska',
    backLanguageId: 'eeeca7e4-8c47-471d-a6f5-318a4e29792b',
    default: true,
  },
];

if (['dev', 'test', 'uat'].includes(process.env.REACT_APP_PORTAL_ENV_CODE ?? '')) {
  languages.unshift({
    code: 'keys',
    name: 'Label keys',
    backLanguageId: '4bba9048-623f-4d2f-aa81-64da7fac16a4',
    default: false,
  });
}

//default languages to be used in case the navigator language is not available
export const defaultLanguages = [
  {
    geoCode: 'EU',
    code: 'en-US',
  },
];

export const legalDocType = {
  terms_of_use: 'terms_of_use',
  privacy_policy: 'privacy_policy',
  terms_of_sales: 'terms_of_sales',
  imprint: 'imprint',
  geolocation: 'geolocation',
};

export const legalDocTypeCodesToNameMap = {
  224960000: legalDocType.terms_of_use,
  224960001: legalDocType.privacy_policy,
  224960002: legalDocType.terms_of_sales,
  224960003: legalDocType.imprint,
};

export const legalDocTypeNamesToCodeMap = {
  [legalDocType.terms_of_use]: 224960000,
  [legalDocType.privacy_policy]: 224960001,
  [legalDocType.terms_of_sales]: 224960002,
  [legalDocType.imprint]: 224960003,
};

export const communicationPreferencesCodes = {
  allow: '224960000',
  reject: '224960001',
};

const instanceCodeToInstanceName = {
  EU: 'emea',
  SG: 'apac',
  US: 'noram',
};

export const getPasswordForgottenUrl = (languageCode: string) => {
  const environment = process.env.REACT_APP_PORTAL_ENV_CODE;
  const regionCode = localStorage.getItem(
    'currentRegionCode'
  ) as keyof typeof instanceCodeToInstanceName;

  const urlBlocks = {
    default: {
      environment: environment === 'prod' ? '' : `-${environment}`,
      region: ['dev', 'test'].includes(environment ?? '')
        ? ''
        : instanceCodeToInstanceName[regionCode],
      subdomain: 'sodexonet',
      portal: 'ifmportal',
      domain: 'com',
      path: 'Account/Login/ForgotPassword',
    },
    [APP_NAME.MYVILLAGE]: {
      environment: environment === 'prod' ? '' : 'test-ifms.',
      subdomain: environment === 'prod' ? 'sodexo.' : '',
      portal: environment === 'prod' ? 'ifmsportal' : 'powerappsportals',
      domain: environment === 'prod' ? 'com.au' : 'com',
      path: 'Account/Login/ForgotPassword',
      region: regionCode,
    },
  };

  const url = getAppSpecificData(urlBlocks);

  const compiledUri = {
    [APP_NAME.MYVILLAGE]: `https://${url.environment}${url.subdomain}${url.portal}.${url.domain}/${url.path}`,
    default: `https://${url.portal}${url.region}${url.environment}.${url.subdomain}.${url.domain}/${languageCode}/${url.path}`,
  };

  return getAppSpecificData(compiledUri);
};

export const getChangePasswordConfiguration = () => {
  const fields = {
    [APP_NAME.MYVILLAGE]: { currentPassword: false },
    default: { currentPassword: true },
  };

  return getAppSpecificData(fields);
};

export const getDeleteAccountConfiguration = () => {
  const fields = {
    [APP_NAME.MYVILLAGE]: {
      name: 'Ref: Request deletion',
      message: 'Ref: Deletion description',
      confirmButtonLabel: 'confirm',
    },
    default: {
      name: 'Ref: Confirm deletion',
      message: 'Ref: Delete description',
      confirmButtonLabel: 'delete',
    },
  };

  return getAppSpecificData(fields);
};

export const defaultThemeSettings = [
  // {
  //   key: 'mainColor',
  //   value: '#283897',
  // },
  // {
  //   key: 'secondaryColorA',
  //   value: '#3862F8',
  // },
  // {
  //   key: 'secondaryColorB',
  //   value: '#53DCC8',
  // },
  // {
  //   key: 'defaultContentColorOnMainColorBackground',
  //   value: '#000000',
  // },
  // {
  //   key: 'defaultContentColorOnSecondaryAColorBackground',
  //   value: '#ffffff',
  // },
];

export const getAccountPageConfig = (config: { shouldDisplayTermsOfSalesLink?: boolean } = {}) => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      termsOfSale: {
        display: true,
        version: 'current',
      },
      supportCTA: {
        display: true,
        contact: 'myvillage.apac.au@sodexo.com',
      },
    },
    default: {
      termsOfSale: {
        display: config.shouldDisplayTermsOfSalesLink,
        version: 'acknowledged',
      },
      supportCTA: {
        display: false,
        contact: '',
      },
    },
  };

  return getAppSpecificData(configuration);
};

export const getGuestRestrictedRoutes = () => {
  const guestRestrictedRoutes = {
    [APP_NAME.MYWAY]: [stampsPaths.Module, orderPaths.OrderHistory, loyaltyStampsPaths.Module],
    [APP_NAME.BITE]: [stampsPaths.Module, orderPaths.OrderHistory, loyaltyStampsPaths.Module],
    default: [],
  };

  return getAppSpecificData(guestRestrictedRoutes);
};

export const troubleLoggingInConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      submitAsFeedback: false,
      formFields: {
        fullName: { required: true, defaultValue: '' },
        email: { required: true, defaultValue: '' },
        whatVillage: { required: false, defaultValue: '' },
        employer: { required: false, defaultValue: '' },
      },
    },
    default: {
      submitAsFeedback: true,
      formFields: {
        geography: { required: true, defaultValue: '' },
        email: { required: true, defaultValue: '' },
        phone: { required: true, defaultValue: '' },
        company: { required: true, defaultValue: '' },
        city: { required: false, defaultValue: '' },
        comment: { required: true, defaultValue: '' },
      },
    },
  };
  return getAppSpecificData<TroubleLoggingInConfigData>(configuration);
};

export const legalDocumentsConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      shouldDisplayDownloadButton: false,
    },
    default: {
      shouldDisplayDownloadButton: true,
    },
  };

  return getAppSpecificData(configuration);
};

export const getNotificationInitInfo = () => {
  const configuration = {
    [APP_NAME.BITEKIOSK]: {
      shouldRegisterAppInNotificationHub: false,
    },
    default: {
      shouldRegisterAppInNotificationHub: true,
    },
  };

  return getAppSpecificData(configuration);
};

export const useTroubleLoggingIn = () => {
  const fields = {
    [APP_NAME.WANDO]: { shouldUseTroubleLoggingIn: false },
    [APP_NAME.MYWAY]: { shouldUseTroubleLoggingIn: false },
    default: { shouldUseTroubleLoggingIn: true },
  };

  return getAppSpecificData(fields);
};

export const getCoreConfigs = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      apiVersion: 'v2',
      isResponsePaginated: false,
      showNotificationsSeeMore: false,
      showMarkAllRead: false,
    },
    default: {
      apiVersion: 'v3',
      isResponsePaginated: true,
      showNotificationsSeeMore: true,
      showMarkAllRead: true,
    },
  };
  return getAppSpecificData(configuration);
};

export const getApiEndpoints = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      getGeographies: '/v2/geolocations',
    },
    default: {
      getGeographies: '/v1/geolocation',
    },
  };
  return getAppSpecificData(configuration);
};
