import momentjs from 'moment';
import { Dispatch } from 'redux';

import { fetchJsonActionCreator, makeActionCreatorV2 } from '../../../actions';
import { apiPath, shouldUseMockData } from '../../../config';
import { PreRequestParams } from '../../../types/actionCreator.types';
import { moduleId, cacheVersion } from '../config';
import mockResponse from '../mocks/mockResponse';
import { IContent } from '../types';

import { ACTIONS } from './actions.types';

const persistLocally = { moduleId, cacheVersion };

export const getHome = (() => {
  let lockAction = { type: ACTIONS.CONTENT_FETCHING_HOME }; //
  let urlConstructor = () => apiPath + '/v2/content/homepage';

  let argConfig = {
    siteId: { toQueryString: true, toThen: true },
    startDate: {
      toQueryString: true,
      defaultValue: () => momentjs().add(-60, 'days').format('YYYY-MM-DD'),
    },
    endDate: {
      toQueryString: true,
      defaultValue: () => momentjs().add(60, 'days').format('YYYY-MM-DD'),
    },
    languageCode: {
      toQueryString: true,
    },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  let then = {
    200: async (json: { contents: IContent[] }, dispatch: Dispatch) => {
      await dispatch({
        type: ACTIONS.CONTENT_FETCHED_HOME,
      });
      await dispatch({
        type: ACTIONS.CONTENT_SUCCESS_HOME,
        list: json.contents,
      });
    },
    other: async (dispatch: Dispatch) => {
      console.log('Error fetching home content');
      await dispatch({
        type: ACTIONS.CONTENT_FETCHED_HOME,
        persistLocally,
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse['getHome'];
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const resetOne = makeActionCreatorV2(ACTIONS.CONTENT_RESET_ONE);
