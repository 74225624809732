import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useGetMappedFacilitiesQuery } from '../../api';
import { pagePaths } from '../../config';
import { useReviewTranslation } from '../../hooks/useReviewTranslation';
import { REDIRECTED_PAGES } from '../../types/reviewForm.types';

import { FeedbackIllustration } from '@/assets/illustrations';
import Column from '@/components/organisms/Column';
import ListPage from '@/components/templates/ListPage/ListPage';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import useSite from '@/modules/Core/hooks/useSite';
import { useGetFacilitiesListQuery } from '@/modules/Facilities/api/api';
import FacilityTile from '@/modules/Facilities/components/FacilityTile/FacilityTile';
import { mapToFacilityItems } from '@/modules/Facilities/helpers/facilities.helpers';
import { FacilityDataItem, FacilityItem } from '@/modules/Facilities/types/types';
import { useFacilityMenuImage } from '@/modules/Order/hooks/useFacilityMenuImage/useFacilityMenuImage';

const FacilityPicker = () => {
  const site = useSite() || { id: '' };
  const history = useHistory();
  const { label } = useReviewTranslation(__filename);

  const dispatch = useDispatch();

  const { data: facilities, isLoading: isFacilitiesLoading } = useGetFacilitiesListQuery({
    siteId: site.id,
    dispatch: dispatch,
  });

  const { data: mappedFacilities, isLoading } = useGetMappedFacilitiesQuery(site.id);
  const facilityImagesInfo = useMemo(() => {
    if (!!facilities?.facilities) {
      return facilities.facilities.map((facility) => ({
        facilityId: facility.id,
        imageId: facility.images[0],
      }));
    }
  }, [facilities]);

  const { getFacilityImage } = useFacilityMenuImage(facilityImagesInfo!);

  const onFacilitySelect = useCallback(
    (facilityId: string) => {
      history.push({
        pathname: pagePaths.ReviewForFacility.replace(':facilityId', facilityId),
        search: `${new URLSearchParams({
          referer: history.location.pathname || '',
          redirectedFrom: REDIRECTED_PAGES.FACILITIES,
        })}`,
      });
    },
    [history]
  );

  const search = {
    searchableKeys: ['title'],
    placeholder: label('Ref: Search facility name'),
  };

  const renderFacilityItem = (item: FacilityItem) => {
    return (
      <FacilityTile
        key={item.id}
        id={item.id}
        title={item.title}
        data-testid={item['data-testid']}
        facilityType={item.facilityType}
        imgInfo={item.imgInfo}
        label={label}
        onTileClickCallback={onFacilitySelect}
        showOfferingIcon={false}
      />
    );
  };

  const items: FacilityItem[] = useMemo(() => {
    if (!!facilities?.facilities) {
      return mapToFacilityItems(
        facilities?.facilities.filter((fac) =>
          mappedFacilities?.includes(fac.id)
        ) as FacilityDataItem[],
        getFacilityImage,
        label
      );
    } else {
      return [];
    }
  }, [facilities, getFacilityImage, label, mappedFacilities]);

  if (isLoading || isFacilitiesLoading) return <LoadingPage />;

  return (
    <ListPage
      data-testid="facility-picker-list"
      hasBackLink={false}
      title={label('Ref: Page title')}
      items={items}
      hideFilterTitle
      search={search}
      itemRenderer={renderFacilityItem}
      aside={
        <Column.Complementary>
          <FeedbackIllustration />
        </Column.Complementary>
      }
    />
  );
};

export default FacilityPicker;
