let checklegalDocsWorker: Worker | undefined;
export const getBackgroundDocWorkerInstance = () => {
  if (!checklegalDocsWorker)
    checklegalDocsWorker = new Worker(`${process.env.PUBLIC_URL}/workers/checkLegalDocs.js`);

  return checklegalDocsWorker;
};

export const scheduleLegalDocsBackgroundCheck = (legalDocsLastCheck: Date | undefined) => {
  const worker = getBackgroundDocWorkerInstance();
  worker.postMessage({ legalDocsLastCheck, scheduledAt: new Date() });
};

export const endBackgroundCheckForLegalDocsChange = () => {
  if (checklegalDocsWorker) {
    checklegalDocsWorker.terminate();
    checklegalDocsWorker = undefined;
  }
};
