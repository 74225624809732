import { getPasswordForgottenUrl, pagePaths } from '../config';

import { getTokenPayload } from './tokensHelpers';

const biteKioskRoleIdentifier = 'Kiosk App User - Facility Manager';

export function isUserMigrationFromOldBitesEnabled() {
  return process.env.REACT_APP_ENABLE_OLD_BITE_USER_MIGRATION === 'true';
}

export const isKioskUser = (): boolean => {
  const kioskUserRole = getKioskAppUserRole();
  return !!kioskUserRole;
};

export const getKioskUserFacilityId = () => {
  const kioskUserRole = getKioskAppUserRole();

  if (kioskUserRole) {
    // kiosk user role looks like: 'Kiosk App User - Facility Manager - xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
    var roleParts = kioskUserRole.split(' - ');
    if (roleParts.length === 3) return roleParts[2]?.trim();
  }
};

export const getForgottenUrl = (currentLanguageCode: string, username: string): string => {
  return process.env.REACT_APP_FF_WITH_NEW_EMAIL_RESET === 'true'
    ? pagePaths.PasswordForgotten
    : `${getPasswordForgottenUrl(
        currentLanguageCode === 'en-GB' ? 'en-US' : currentLanguageCode
      )}?email=${username}`;
};

const getKioskAppUserRole = (): string | undefined => {
  const { roles } = getTokenPayload();

  if (Array.isArray(roles)) {
    return roles?.find((role: string) =>
      role.toLowerCase().includes(biteKioskRoleIdentifier.toLowerCase())
    );
  }

  // string user has single role
  return roles?.toLowerCase().includes(biteKioskRoleIdentifier.toLowerCase()) ? roles : undefined;
};
