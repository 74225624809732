const labels = {
  All: {
    'Ref: stamp progress': 'ความคืบหน้าสู่ตราประทับถัดไป',
    Reward: 'รางวัล',
    Rewards: 'รางวัล',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'แสตมป์',
    'Ref: Fulfill conditions': 'รับแสตมป์เพื่อแลกรางวัล!',
    'See Reward': 'ดูรางวัล',
    'Ref: No reward': 'ไม่มีรางวัลสำหรับช่วงเวลา',
  },
  RedeemableProducts: { 'Ref: Page title': 'รางวัลความภักดี' },
};
export default labels;
