const labels = {
  All: {
    'Ref: Assembled': 'Assembled',
    'Ref: Submitted': 'Submitted',
    'Ref: Fulfilled': 'Fulfilled',
    'Ref: Completed': 'Completed',
    'Ref: Paid': 'Paid',
    'Ref: Approved': 'Approved',
    'Ref: Ready For Pickup': 'Ready For Pickup',
    'Ref: Delivered': 'Delivered',
    'Ref: Received': 'Received',
    'Ref: Rejected': 'Rejected',
    'Ref: Payment Error': 'Payment Error',
    'Ref: Pending Payment': 'Pending Payment',
    'Ref: Preparing': 'Preparing',
    'Ref: Refunded': 'Refunded',
    'Ref: Cancelled By User': 'Cancelled By User',
    'Ref: Refund Requested': 'Refund Requested',
    'Ref: Refund Failed': 'Refund Failed',
    'Ref: Payment Processing': 'Payment Processing',
    'Ref: Not Paid': 'Not Paid',
    'Ref: Total:': 'Total:',
    'Ref: Pickup': 'Pickup',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Fulfillment Type': 'Fulfillment type',
    'Ref: Delivery': 'Delivery',
    'Ref: You have no future orders': 'You have no future orders',
    'Ref: You have no past orders': 'You have no past orders',
    'Ref: You have no orders': 'You have no orders',
    'Ref: All orders': 'All orders',
    'Ref: Not past orders': 'Coming orders',
    'Ref: Past orders': 'Past orders',
    'Ref: Vegan': 'Vegan',
    'Ref: Vegeterian': 'Vegetarian',
    'Ref: See item': 'See item',
    'Ref: Activated': 'Activated',
    'Ref: No suggestions': 'No suggestions found',
    'Ref: Get your receipt': 'Get your receipt',
    'Ref: Print': 'Print your receipt',
    'Ref: Email': 'Receive your receipt by e-mail',
    'Ref: Receipt Modal message': 'How would you like to get your receipt?',
    'Ref: Confirm': 'Confirm',
    'Ref: Your email address': 'Your email address',
    Suggestion: 'Suggestion',
    'Ref: Loyalty Reward': 'Loyalty Reward',
    'Ref: Equation for stamps per item': '1 Purchase = 1 Stamp',
    'Ref: Paid by employer': 'The amount paid by your employer',
    'Ref: Paid by user': 'The amount paid by you',
  },
  ProductsList: {
    'Ref: Page title': 'Product list',
    'Ref: Facility details': 'Facility details',
    'Ref: Search products list': 'Search items',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Category',
    'Ref: all': 'All',
    'Ref: today': 'Today',
    'Ref: tomorrow': 'Tomorrow',
    'Ref: see more': 'See more',
    'Ref: see less': 'See less',
    'Ref: reset': 'Reset',
    'Ref: View in cart': 'View in cart',
    'Ref: You have changed location': 'You have changed location',
    'Ref: You are now at': 'You are now at',
    'Ref: Menu card': 'Menu card',
    'Ref: Table': 'Table number',
    'Service unavailable': 'Service unavailable',
    'Ref: Ordering unavailable':
      'Careful! Ordering at this site is unavailable at the moment, please try later.',
    'Ref: Filters': 'Filters (Allergens and more)',
  },
  ProductScanner: {
    'Ref: cart': 'Cart',
    'Ref: Product Scanned':
      'was added to cart. You can continue to scan the barcodes to add them in your cart.',
    'Ref: Scan or adjust': 'was added to cart. Adjust quantity or scan another product.',
    'Ref: Product Not Matched': 'Barcode was not recognized. Please try again',
    'Ref: Scan article code': 'Scan barcode',
    'Ref: Native error product cannot be added to cart':
      'Item from different menu or day part cannot be added to the cart',
    'Ref: product scanner placeholder': 'Please scan the barcode to add it to your cart',
  },
  Order: { 'Ref: Page title': 'Order' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menus',
    'Ref: today': 'Today',
    'Ref: tomorrow': 'Tomorrow',
    'Ref: Search products list': 'Search items',
    'Ref: You have changed location': 'You have changed location',
    'Ref: You are now at': 'You are now at',
  },
  ProductDetails: {
    'Ref: Page title': 'Product Details',
    'Ref: Serving Size': 'Serving Size',
    'Ref: Nutrition': 'Nutritional information',
    'Ref: Nutrition adults': 'Adults need around 2000 kcal a day',
    'Ref: Nutrition Calories': 'Calories',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Fat',
    'Ref: Nutrition Saturated Fat': 'Saturated fat',
    'Ref: Nutrition Carbohydrates': 'Carbohydrates',
    'Ref: Nutrition Sugar': 'Sugar',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Dietary Fiber',
    'Ref: Nutrition iron': 'Iron',
    'Ref: Nutrition potassium': 'Potassium',
    'Ref: Nutrition vitaminA': 'Vitamin A',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Additives': 'Additives',
    'Ref: Emission': 'CO2 emissions',
    'Ref: This product generates': 'This product generates',
    'Ref: of CO2': 'of CO2',
    'Ref: emissions, which equals a': 'emissions, which equals a',
    'Ref: car trip': 'car trip',
    'Ref: Ingredients': 'Recipe ingredient',
    'Ref: Allergens': 'Allergens',
    'Ref: Always there': 'Always there',
    'Ref: Allergens selected': ' allergens selected on the filter: ',
    'Ref: minimum required': 'minimum required',
    'Ref: you have to add at least': 'You have to add at least',
    'Ref: ingredients': 'ingredients',
    'Ref: Ingredient': 'ingredient',
    'Ref: Free': 'Free',
    'Ref: Based on customization': 'Based on your customization',
    'Ref: Additives eggs': 'eggs',
    'Ref: Additives fish': 'fish',
    'Ref: Additives milk': 'milk',
    'Ref: Additives nuts': 'nuts',
    'Ref: Additives wheat': 'wheat',
    'Ref: Additives peanuts': 'peanuts',
    'Ref: Additives soy beans': 'soy beans',
    'Ref: Additives sesame seeds': 'sesame seeds',
    'Ref: Review': 'Review',
    'Ref: Per portion': 'Per portion',
    'Ref: Per 100g': 'Per 100g',
    'Ref: Allergen selected': 'Allergen selected on search filter',
    'Ref: Fitbit Log': 'Log to Fitbit',
    'Ref: age verification required on delivery': 'Age Verification Required on Delivery',
    'Ref: alcohol content notification':
      'By placing and order for one of these items, you declare that you are aged 18 or over. Alcohol abuse is dangerous for your health.',
    'Ref: default': 'Default',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} earned if you order this product',
    'Ref: Loyalty Card Description':
      'The {stamp_form} will be added to the following loyalty {program_form}: <b>{scheme_names}</b>',
    'Ref: About Loyalty stamps':
      'The Loyalty Stamps program allows you to collect stamps for rewards.',
    'Ref: Loyalty Modal Description':
      'When you buy <b>{product_name}, 1 Stamp</b> will be added to the <b>{scheme_names}</b> {program_form}',
    'Ref: and': 'and',
    'Ref: Stamp': 'Stamp',
    'Ref: Stamps': 'Stamps',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programs',
    'Ref: Loyalty Stamps': 'Loyalty Stamps',
    'Ref: See Loyalty Stamps': 'See Loyalty Stamps',
    Favorite: 'Favourite',
    'Ref: In favorites': 'The product is already in the favourites list. Click to remove it.',
    'Ref: Not in favorites': 'The product is not yet in the favourites list. Click to add it.',
    'Ref: Added to favorites': 'The product has been added to the favourites list.',
    'Ref: Removed from favorites': 'The product has been removed from the favourites list.',
    'Ref: From': 'From',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Change in cart',
    'Ref: Popup header for customizable item qty update': 'This item is customizable',
    'Ref: add new version': 'Add new version',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Update the quantity of a customizable item directly from your cart.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Update the quantity of a customizable item directly from your cart, or add a new version.',
    'Ref: another context body':
      'To add items from this site, facility, moment/menu, or date you need to clear cart',
    'Ref: cancel': 'Cancel',
    'Ref: add to cart': 'Add to cart',
    'Ref: view in cart': 'Cart',
    'Ref: Products in cart': 'Number of {product_name} in cart: {product_quantity}',
    'Ref: Customize': 'Customise',
    'Ref: Add': 'Add',
    'Ref: Free': 'Free',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Cancel',
    'Ref: notice': 'Notice',
    'Ref: clear cart': 'Clear cart',
    'Ref: Cart addition error message':
      'Currently you have items in cart from {from_current}. To add items from {from_selected} you need to clear the cart',
  },
  Cart: {
    'Ref: Page title': 'Cart',
    'Ref: The cart is empty': 'The cart is empty',
    'Ref: Clear cart': 'Clear cart',
    'Ref: Continue shopping': 'Continue shopping',
    'Ref: Start shopping': 'Start shopping',
    'Ref: Today': 'Today',
    'Ref: Tomorrow': 'Tomorrow',
    'Ref: Tax:': 'Tax:',
    'Ref: Promotion:': 'Promotion:',
    'Ref: Total without promotion:': 'Total without promotion:',
    'Ref: Delivery:': 'Delivery:',
    'Ref: Pickup informations': 'Pickup information',
    'Ref: Location': 'Location',
    'Ref: Time': 'Time',
    'Ref: Phone': 'Your phone number',
    'Ref: Instructions': 'Instructions',
    'Ref: Please select': 'Please select',
    'Ref: Enter special requests': 'Enter special requests here',
    'Ref: notice': 'Notice',
    'Ref: Product is no more available':
      'Sorry but it seems that some product are no more available. Please check your cart.',
    'Ref: ok': 'Ok',
    'Ref: Validate': 'Validate my order',
    'Ref: Pay now': 'Pay now',
    'Ref: Charge to my department': 'Charge to my department',
    'Ref: Pay on pickup': 'Pay for these items on pick up',
    'Ref: Accept terms of sale prefix': 'I accept',
    'Ref: Accept terms of sale': 'these terms of sales',
    'Ref: Continue': 'Continue',
    'Ref: Please login or sign up to place an order': 'Please login or sign up to place an order',
    'Ref: Accept terms of sale suffix': ', and I have read and understood them.',
    'Ref: Payment modality': 'Payment Method',
    'Ref: Other information': 'Other information',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Pickup': 'Pickup',
    'Ref: Table': 'Table number',
    'Ref: Required fields': 'Required fields',
    'Service unavailable': 'Service unavailable',
    'Ref: Cannot order':
      'Sorry, you can not make an order at this site at the moment. Please contact the restaurant for more information about opening hours and available services.',
    'Ref: Order Unavailable':
      'Order for the chosen date, fulfillment type and location is not available anymore!',
    'Ref: No Availability': 'No more availability',
    'Ref: Continue on your device': 'Continue on your device',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} earned if you complete your order',
    'Ref: Loyalty Card Description':
      'The {stamp_form} will be added to the following loyalty {program_form}: <b>{scheme_names}</b>',
    'Ref: About Loyalty stamps':
      'The Loyalty Stamps program allows you to collect stamps for rewards.',
    'Ref: Loyalty Modal List Description':
      '<b>{no_stamps} {stamp_form}</b> earned if you complete your order:',
    'Ref: Loyalty Modal List Item':
      '<b>{no_stamps} {stamp_form}</b> added for <b>{scheme_names}</b>',
    'Ref: Stamp': 'Stamp',
    'Ref: Stamps': 'Stamps',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programs',
    'Ref: Loyalty Stamps': 'Loyalty Stamps',
    'Ref: See Loyalty Stamps': 'See Loyalty Stamps',
    'Ref: Loyalty Info': 'Loyalty Info',
    'Ref: Email address': 'Email Address',
    'Ref: and': 'and',
    'Ref: Order minimum amount': 'Sorry, minimum payment amount is {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Continue on your device',
    'Ref: Scan QR to continue': 'Please, scan the QR code to continue the journey on your device.',
  },
  OrderHistory: { 'Ref: Orders': 'Orders', 'Ref: Page Title': 'My Orders' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Unit price',
    'Ref: Download': 'Download the receipt',
    'Ref: Download error': 'A download error has occurred. Try to download the receipt later',
    'Ref: Order details title': 'Order Details',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Thank you! Your order is now confirmed',
    'Ref: Text for pickup':
      'You can follow its status through your order history. If your notifications are turned on, we will update you when it is ready for pickup.',
    'Ref: Success Order Title': 'Thank you for your order',
    'Ref: Success Order Description': 'Please pay at the checkout to validate your order.',
    'Ref: Success Order ID': 'ID Order',
    'Ref: Success Order Action': 'New Order',
    'Ref: Text for dinein':
      'Your order should be served to your table soon. Just ask any of our friendly staff member about your eat-in order.',
    'Ref: Text for scan&go': 'Your order is available in your order history',
    'Ref: Text for delivery':
      'You can track its status in your order history. If your notifications are enabled, we will inform you when your order is ready.',
    'See details': 'See details',
    'Go to home': 'Go to home',
  },
  FailOrder: {
    'Go to home': 'Go to home',
    'Ref: Kiosk Fail Title': 'Oops! Something went wrong!',
    'Ref: Kiosk Fail Description':
      'Unfortunately, your order was not successful. Please try again or ask a staff member to help you.',
    'Ref: Kiosk Fail Back': 'Back to the previous page',
    'Ref: Kiosk Fail Action': 'Clear the session and order again',
    'Ref: Error in cart validation':
      'An error occured during your order. Please contact the facility to resolve the issue.',
    'Facility contact': 'Facility contact',
    'Ref: Ordering failed': 'Ordering failed',
    'Ref: Order confirmed': 'Order confirmed',
  },
  TableCheckIn: {
    'Welcome at': 'Welcome at',
    Table: 'Table:',
    'Ref: list title': 'Recent orders for today',
    'See the menu': 'See the menu',
    'Table Check-in': 'Table Check-in',
    'Ref #': 'Ref #',
    Checked: 'Checked',
    'Check in': 'Check in',
    'See details': 'See details',
    'Ref: You have changed location': 'You have changed location',
    'Ref: You are now at': 'You are now at',
    'Order number': 'Order number',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promotion applied',
    'Ref: Promotion applied description': 'You benefit a promotion from your cart.',
    'Ref: Promotion deleted title': 'Promotion deleted',
    'Ref: Promotion deleted description':
      'You no longer benefit the promotion because you no longer fill the conditions or the promotion has expired.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Scan & Go', 'Ref: View only': 'View only' },
  ProductTile: {
    'Ref: Allergens': 'Allergens',
    'Ref: Presence of allergen': 'Presence of allergen',
    'Ref: Scan': 'Scan',
    'Ref: From': 'From',
    'Ref: Already in product favorite list. Click to remove it.':
      'Already in product favourite list. Click to remove it.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Currently not added to product favourite list. Click to add it.',
    'Ref: Free': 'Free',
  },
  OrderSummary: {
    'Ref: item': 'item',
    'Ref: items': 'items',
    'Ref: Clear': 'Clear',
    'Ref: Cart': 'Cart',
    'Ref: Subtotal': 'Subtotal',
    'Ref: Total': 'Total',
    'Ref: Tax': 'Tax',
    'Ref: Promotion': 'Promotion',
    'Ref: Total without promotion': 'Total without promotion',
    'Ref: Number of product in cart': 'Number of products in cart',
    'Ref: Delivery': 'Delivery',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegan', 'Ref: Vegeterian': 'Vegetarian' },
  OrderInfoCard: {
    'Ref: Header': 'Ordered for',
    'Ref: from': 'from',
    'Ref: location': 'Location',
    'Ref: Phone': 'Your phone number',
    'Ref: Instructions': 'Instructions',
    'Ref: Order type': 'Order type',
    'Ref: Pickup': 'Pickup',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Scan & Go',
  },
  ReorderWidget: { 'Ref: No items': 'No orders made yet. Shop now!', 'Ref: Title': 'Order again' },
  MenusWidget: { 'Ref: no menu': 'No menu for the moment', 'Ref: Modal title': 'Menu for the day' },
  PromotionInfo: { 'Ref: Discounts available': 'discounts available' },
  PromotionInfoItem: { 'Ref: Activate': 'Activate' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Your Orders', 'Ref: Widget Title': 'My Orders' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Suggestion for the day',
    'Ref: Any menu': 'Any menu',
    Other: 'Other',
    'Ref: Suggestions calendar button': 'Suggestions calendar',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Serving Size',
    'Ref: Nutrition': 'Nutritional information',
    'Ref: Nutrition adults': 'Adults need around 2000 kcal a day',
    'Ref: Nutrition Calories': 'Calories',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Fat',
    'Ref: Nutrition Saturated Fat': 'Saturated fat',
    'Ref: Nutrition Carbohydrates': 'Carbohydrates',
    'Ref: Nutrition Sugar': 'Sugar',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Dietary Fiber',
    'Ref: Nutrition iron': 'Iron',
    'Ref: Nutrition potassium': 'Potassium',
    'Ref: Nutrition vitaminA': 'Vitamin A',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Ingredients': 'Recipe ingredient',
    'Ref: Allergens': 'Allergens',
    'Ref: Always there': 'Always there',
    'Ref: Allergens selected': ' allergens selected on the filter: ',
    'Ref: minimum required': 'minimum required',
    'Ref: ingredients': 'ingredients',
    'Ref: Ingredient': 'ingredient',
    'Ref: Free': 'Free',
    'Ref: Based on customization': 'Based on your customization',
    'Ref: Additives eggs': 'eggs',
    'Ref: Additives fish': 'fish',
    'Ref: Additives milk': 'milk',
    'Ref: Additives nuts': 'nuts',
    'Ref: Additives wheat': 'wheat',
    'Ref: Additives peanuts': 'peanuts',
    'Ref: Additives soy beans': 'soy beans',
    'Ref: Additives sesame seeds': 'sesame seeds',
    'Ref: Review': 'Review',
    'Ref: Per portion': 'Per portion',
    'Ref: Per 100g': 'Per 100g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} for free',
    'Ref: Number of free items': 'Number of free items: {number_of_free_items}',
    'Ref: Allergen selected': 'Allergen selected on search filter',
    'Ref: Nutritional info': 'Nutritional info',
    'Ref: Info': 'Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Promotions' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Choose your job',
    'Ref: Validate': 'Validate',
    'Ref: Pricing Tiers dialog message': 'You have been recognized with your email address',
    'Ref: Please select your job in the company': 'Please select your job in the company',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Pickup',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Delivery': 'Delivery',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'How was {facility_name}´s service?',
    'Ref: How do you rate the service': 'How do you rate the service?',
  },
};
export default labels;
