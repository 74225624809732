const labels = {
  All: { 'Ref: stamp progress': 'Ref: stamp progress', Reward: 'Reward', Rewards: 'Rewards' },
  LoyaltyStamps: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Fulfill conditions': 'Ref: Fulfill conditions',
    'See Reward': 'See Reward',
    'Ref: No reward': 'Ref: No reward',
  },
  RedeemableProducts: { 'Ref: Page title': 'Ref: Page title' },
};
export default labels;
