import { screen } from '@testing-library/react';
import momentjs from 'moment';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { SERVICE } from '../../../config';

import Widget from './MenusWidget';

const facilities1 = {
  title: 'fac1',
  name: 'fac1',
  description: 'fac1',
  id: 'fac1',
  openingHours: [],
  isFoodFacilityType: true,
  facilityType: {
    id: '555580001',
    name: 'Food - Retail',
  },
};

const facilities2 = {
  title: 'fac2',
  name: 'fac2',
  description: 'fac2',
  id: 'fac2',
  openingHours: [],
  isFoodFacilityType: true,
  facilityType: {
    id: '555580001',
    name: 'Food - Retail',
  },
};

const siteId = 'site1';

const menu1 = {
  id: 'menu1',
  facilityId: facilities1.id,
  name: 'menu 1',
  message: 'menu message',
  isOrderable: true,
  logoUrl: 'logoUrl',
  date: momentjs().format(),
  dayPart: 'testDayPart',
  isFutureOrdering: true,
  isConferenceCatering: true,
  fulfillmentTypes: [],
  isScanAndGo: true,
  menuItems: [],
  section: 'test section',
};

const state = {
  Core: {
    context: {
      site: {
        id: siteId,
      },
    },
    services: {
      list: [
        {
          name: SERVICE.FOOD_ORDER,
        },
      ],
    },
  },
  Facilities: {
    data: {
      [facilities1.id]: facilities1,
      [facilities2.id]: facilities2,
    },
    list: {
      [siteId]: [facilities1.id, facilities2.id],
    },
    viewCounts: {},
  },
  Order: {
    menus: [menu1],
    locks: {},
  },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
  },
};

const mockDispatch = jest.fn();
const mockSelector = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
  useDispatch: () => mockDispatch,
}));

jest.mock('../../actions', () => ({
  getMenus: () => ({
    type: 'type',
  }),
  checkDiscountTiersStatus: () => ({ type: 'type' }),
}));
jest.mock('../../../Facilities/redux/actions', () => ({
  getAll: () => ({
    type: 'type',
  }),
}));

jest.mock('@/modules/Core/hooks/useLanguage', () => {
  return {
    __esModule: true,
    default: () => 'en-US',
  };
});

jest.mock('@/helpers/images/images.helper', () => ({
  ...jest.requireActual('@/helpers/images/images.helper'),
  getImagesSrcFromIds: () => [{ id: 'imageId', content: 'image' }],
}));

jest.mock('@/helpers/hooks/Analytics/useAnalytics', () => ({
  __esModule: true,
  default: () => ({
    trackButtonClickEvent: jest.fn(),
  }),
}));

describe('Menus Widget', () => {
  describe('With data', () => {
    beforeEach(() => {
      mockSelector.mockReturnValue(state);
    });

    renderComponent(Widget);

    it('should render menus', () => {
      const menuElement = screen.getAllByTestId(`MenuTile-${facilities1.id}_${menu1.id}`);
      expect(menuElement).toBeTruthy();
    });
  });

  describe('No data', () => {
    beforeEach(() => {
      mockSelector.mockReturnValue({ ...state, Facilities: { ...state.Facilities, list: {} } });
    });

    renderComponent(Widget);

    it('should render placeholder', () => {
      const menuElement = screen.getAllByTestId('widget-placeholder');
      expect(menuElement).toBeTruthy();
    });
  });

  describe('Loading data', () => {
    beforeEach(() => {
      mockSelector.mockReturnValue({
        ...state,
        Order: { ...state.Order, locks: { getMenusForFacility: true } },
      });
    });

    renderComponent(Widget);

    it('should show skeleton placeholder', () => {
      const skeletonPlaceholder = screen.getAllByTestId('skeleton-placeholder');
      expect(skeletonPlaceholder.length).toEqual(3);
    });
  });
});
