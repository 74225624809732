const labels = {
  ReviewForm: {
    'Ref: Page title': 'Recension',
    'Ref: How was dining with us': 'Hur var det med oss?',
    'Ref: Submit': 'Skicka in',
    'Ref: Filter range': 'Filter',
    'Ref: Some information is missing': 'Viss information saknas',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Skicka anonymt',
    'Ref: Your email address': 'din e-postadress',
    'Ref: Email field placeholder': 'Skriv in din mailadress',
    'Ref: Invalid email format': 'Ogiltigt e -postformat',
  },
  SuccessPage: {
    'Ref: Body':
      'Tack för att du har slutfört denna recension, din tid är värdefull eftersom denna recension kommer att förbättra våra tjänster.',
    'Ref: Go to Feedback': 'Gå till feedback',
    'Ref: Go to home': 'Gå till startsidan',
    'Ref: Go to Facilities': 'Gå till faciliteter',
    'Ref: Go to Product Details': 'Gå till produktinformation',
    'Ref: Go to Facility Details': 'Gå till anläggningsinformation',
  },
  FacilityPicker: {
    'Ref: Page title': 'Recension',
    'Ref: Search facility name': 'Sökfacilitetsnamn',
  },
};
export default labels;
