import { act, fireEvent, screen, waitFor } from '@testing-library/react';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { useDispatch } from 'react-redux';

import TableCheckIn from './TableCheckIn';

import { renderedComponent } from '@/helpers/tests/renderComponent';

const siteId = '2323-23-2-32-3-23';

// Mock useDispatch
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: jest.fn(),
}));

// history mock
const mockPush = jest.fn();
const mockHistory = createMemoryHistory();
mockHistory.push = mockPush;

const store = {
  Core: {
    access: {
      shouldSelectSiteContext: false,
    },
    context: {
      site: {
        id: siteId,
        name: 'Test Site',
        currency: {
          isoCode: 'EUR',
          currencyName: 'Euro',
          currencySymbol: 'EUR',
          exchangeRate: 1,
          precision: 2,
        },
      },
    },
    services: {
      list: [],
      visited: [],
    },
  },
  Order: {
    preselectedFacilityId: '1',
    pendingOrders: [
      {
        outletId: 1,
        pickupTime: '2022-07-21T14:00:00Z',
        orderNumber: '12345',
        orderStatus: 'pending',
        deliveryOptionId: 2,
        isCheckedIn: false,
        tableNumber: '10',
      },
      {
        outletId: 2,
        pickupTime: '2022-07-22T16:30:00Z',
        orderNumber: '67890',
        orderStatus: 'ready',
        deliveryOptionId: 3,
        isCheckedIn: true,
        tableNumber: '15',
      },
    ],
    hasSiteChanged: false,
    tableQRScanTime: null,
  },
  Facilities: {
    data: {
      '1': { title: 'Mock Facility Name' },
    },
  },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
  },
};

describe('TableCheckIn', () => {
  beforeEach(() => {
    jest.resetAllMocks();
    jest.clearAllMocks();

    const mockDispatch = jest.fn();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);
  });

  describe('Facility Name not empty', async () => {
    beforeEach(
      async () =>
        await act(() =>
          renderedComponent(TableCheckIn, {}, store, mockHistory || createBrowserHistory())
        )
    );

    it('displays the correct facility name based on preselected FacilityId', () => {
      expect(screen.getByText('Welcome at Mock Facility Name')).toBeInTheDocument();
    });

    it('should redirect to menus when clicking on see menus buttons', async () => {
      await fireEvent.click(screen.getByText('See the menu'));
      await waitFor(() => {
        expect(mockPush).toHaveBeenCalledWith(expect.stringContaining('/order/redirect/'));
      });
    });

    it('should have list of pending orders', async () => {
      expect(screen.getByText('Table: 15')).toBeInTheDocument();
      expect(screen.getByText('Ref #67890')).toBeInTheDocument();
      expect(screen.getByText('Table: 10')).toBeInTheDocument();
      expect(screen.getByText('Ref #12345')).toBeInTheDocument();
    });

    it('should check in when clicked on check in button and display "Checked" on the page', async () => {
      fireEvent.click(screen.getByTestId('order-check-in-button'));
      await waitFor(() => {
        expect(screen.getByText('Checked')).toBeInTheDocument();
      });
    });
  });

  describe('Facility name not empty and table number known', async () => {
    const newStore = {
      ...store,
      Order: {
        ...store.Order,
        tableNumber: '10',
      },
    };

    beforeEach(
      async () =>
        await act(() =>
          renderedComponent(TableCheckIn, {}, newStore, mockHistory || createBrowserHistory())
        )
    );

    it('should show table number on page', async () => {
      const tableNumbers = screen.queryAllByText('Table: 10');
      expect(tableNumbers.length).toBe(2);
    });
  });

  describe('Facility Name empty', async () => {
    const newStore = {
      ...store,
      Order: {
        preselectedFacilityId: null,
        pendingOrders: [],
        hasSiteChanged: false,
        tableQRScanTime: null,
      },
      Facilities: {
        data: {},
      },
    };

    beforeEach(async () => await act(() => renderedComponent(TableCheckIn, {}, newStore)));

    it('does not display a facility name if preselectedFacilityId is not set', () => {
      expect(screen.queryByText('Welcome at Mock Facility Name')).not.toBeInTheDocument();
    });
  });

  describe('table QR scan time is known', () => {
    const oneHourAndOneMinuteAgo = new Date(Date.now() - 61 * 60 * 1000).getTime();

    it('should reset table number after more than one hour since QR scan', async () => {
      const newStore = {
        ...store,
        Order: {
          ...store.Order,
          tableQRScanTime: oneHourAndOneMinuteAgo,
        },
      };

      const mockDispatch = jest.fn();
      (useDispatch as jest.Mock).mockReturnValue(mockDispatch);

      await act(() =>
        renderedComponent(TableCheckIn, {}, newStore, mockHistory || createBrowserHistory())
      );

      expect(mockDispatch).toHaveBeenCalledWith(expect.any(Function));
    });

    it('should not reset table number within one hour of QR scan', async () => {
      const lessThanAnHourAgo = new Date(Date.now() - 30 * 60 * 1000).getTime();
      const newStore = {
        ...store,
        Order: {
          ...store.Order,
          tableQRScanTime: lessThanAnHourAgo,
        },
      };

      const mockDispatch = jest.fn();
      (useDispatch as jest.Mock).mockReturnValue(mockDispatch);

      await act(() =>
        renderedComponent(TableCheckIn, {}, newStore, mockHistory || createBrowserHistory())
      );

      expect(mockDispatch).not.toHaveBeenCalledWith(0);
    });
  });
});
