import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { Fragment, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory } from 'react-router';

import { Calendar2Icon } from '../../../../assets/icons';
import { Woman2Illustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Hint from '../../../../components/atoms/Hint';
import Loader from '../../../../components/atoms/Loader';
import Textarea from '../../../../components/atoms/Textarea/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Calendar from '../../../../components/molecules/Calendar';
import Card from '../../../../components/molecules/Card/Card';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import FillInTheBlanks from '../../../../components/organisms/FillInTheBlanks/FillInTheBlanks';
import Modal from '../../../../components/organisms/Modal';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { FormValidationAlert, validateForm } from '../../../../components/validateForm';
import { SIZE } from '../../../../constants';
import { formatDate } from '../../../../helpers/dateTime';
import MM from '../../../../services/ModulesManager';
import { ApiType } from '../../../ServiceRequest/api';
import {
  useLazyGetFeedbackTypesQuery,
  useLazyGetServiceTypesQuery,
  useLazyGetAreaTypesQuery,
  useLazyGetSubjectTypesQuery,
  useCreateFeedbackMutation,
} from '../../api/api';
import { pagePaths } from '../../config';
import { useFeedbackTranslation } from '../../hooks/useFeedbackTranslation';

import { formatResponse, getFormValidationRules } from './CreateFlow.helper';
import { CreateFlowProps, Flow, SubmitValues, FlowStep } from './CreateFlow.types';

import useLanguage from '@/modules/Core/hooks/useLanguage';
import { useLazyGetSitesQuery } from '@/modules/Sites/api/api';

import styles from './CreateFlow.module.css';

const CreateFlow = ({ steps = [] }: CreateFlowProps) => {
  const history = useHistory();
  const { currentLanguageCode } = useLanguage();
  const { label } = useFeedbackTranslation(__filename);

  const [getFeedbackTypes] = useLazyGetFeedbackTypesQuery();
  const [getServiceTypes] = useLazyGetServiceTypesQuery();
  const [getAreaTypes] = useLazyGetAreaTypesQuery();
  const [getSubjectTypes] = useLazyGetSubjectTypesQuery();
  const [getSites] = useLazyGetSitesQuery({});
  const [createFeedback] = useCreateFeedbackMutation();

  const initialState = useMemo(
    () =>
      steps.reduce(
        (acc, step, ind) => ({
          ...acc,
          [step.id]: {
            id: step.id,
            index: ind,
            isActive: true,
            noOptions: step.noOptions,
            prefix: step.prefix,
          },
        }),
        {}
      ),
    [steps]
  );

  const [state, dispatch] = useReducer(
    (currentState: Flow, action: Flow): Flow => ({
      ...currentState,
      ...action,
    }),
    initialState
  );

  const [stepIndex, setStepIndex] = useState<number>(0);
  const [feedbackDate, setFeedbackDate] = useState<Date>();
  const [comment, setComment] = useState<string>();
  const [showFormValidationAlert, setShowFormValidationAlert] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isLoadingStep, setIsLoadingStep] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isValid: isFormValid, missingInfo: formMissingInfo } = validateForm(
    getFormValidationRules(comment, feedbackDate)
  );

  const getRequestCreationPath = MM<ApiType>().getApi('ServiceRequests', 'getRequestCreationPath');

  const actions = useMemo(
    () => ({
      getFeedbackTypes,
      getServiceTypes,
      getAreaTypes,
      getSubjectTypes,
      getSites: async () => {
        const { data: sites } = await getSites({});
        const sitesToSort = [...(sites ?? [])];

        return sitesToSort?.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      },
    }),
    [getFeedbackTypes, getServiceTypes, getAreaTypes, getSubjectTypes, getSites]
  );

  const formatOptions = useCallback(
    (data: any, id: any, filter?: any) => {
      const formattedData = formatResponse(data || []);
      const filtered = filter ? filter(state, formattedData) : formattedData;

      if (isEqual(filtered, state[id]?.filtered)) return;

      const isActive = filtered?.length > 0 || steps[stepIndex].noOptions;

      dispatch({
        [id]: {
          ...state[id],
          isActive,
          data: formattedData,
          filtered,
        },
      });

      if (!isActive) {
        setStepIndex(stepIndex + 1);
      }
    },
    [state, stepIndex, steps]
  );

  const getStepOptions = useCallback(async () => {
    const currentStep = steps[stepIndex] || steps[0];

    if (!currentStep) return;

    const { id, options: stepOptions = [], getOptions, filter } = currentStep;

    if (getOptions) {
      if (state[id]?.data?.length) return formatOptions(state[id]?.data, id, filter);
      setIsLoadingStep(true);
      const items = await getOptions(actions);
      setIsLoadingStep(false);
      return formatOptions(items, id, filter);
    }
    return formatOptions(stepOptions, id);
  }, [steps, stepIndex, formatOptions, state, actions]);

  const handleDateChange = useCallback(
    (date: Date) => {
      setFeedbackDate(date);

      if (steps.find(({ id }: { id: string }) => id === 'date')) {
        const dateStep = steps.find(({ id }: { id: string }) => id === 'date');

        dispatch({
          ...(dateStep
            ? {
                [dateStep.id]: {
                  ...state[dateStep.id],
                  value: {
                    value: formatDate(moment(date, 'DD/MM/YYYY').toDate(), currentLanguageCode),
                  },
                },
              }
            : {}),
        });
      }

      setIsCalendarVisible(false);
    },
    [currentLanguageCode, state, steps]
  );

  const handleSelection = useCallback(
    (step: number, value: string) => {
      const flowStep = Object.values(state)
        .filter(({ isActive }: FlowStep) => isActive)
        .find((_: FlowStep, i) => {
          return i === step;
        });

      const flowData = flowStep?.id
        ? {
            [flowStep.id]: {
              ...state[flowStep.id],
              value,
            },
          }
        : {};

      dispatch(flowData);

      const nextStep = step + 1;
      setStepIndex(nextStep);
    },
    [state]
  );

  const handleSubmit = useCallback(async () => {
    const values = Object.values(state)
      .filter(({ isActive }: FlowStep) => isActive)
      .reduce((acc: SubmitValues, flowStep: FlowStep) => {
        if (typeof flowStep.value === 'object') return acc;

        return {
          ...acc,
          [flowStep.id]: flowStep.value,
        };
      }, {});

    if (isFormValid) {
      const data = {
        feedbackDate,
        description: comment,
        ...values,
      };
      setIsSubmitting(true);
      const result = await createFeedback({ ...data, withAccessToken: true });
      setIsSubmitting(false);
      if (result && 'error' in result) {
        history.push(pagePaths.Failure);
      } else {
        history.push(pagePaths.Success);
      }
    }

    setShowFormValidationAlert(true);
  }, [state, isFormValid, feedbackDate, comment, createFeedback, history]);

  useEffect(() => {
    getStepOptions();
  }, [getStepOptions, stepIndex]);

  const activeFlowSteps = Object.values(state)
    .filter(({ isActive }: FlowStep) => isActive)
    .sort((a: FlowStep, b: FlowStep) => a.index - b.index);

  const generatedSentence = activeFlowSteps.reduce(
    (acc: string, { prefix = '' }: FlowStep, index: number) =>
      `${acc} ${index && !prefix ? '&' : label(prefix)} {${index}}`,
    ''
  );
  const arrOptions = activeFlowSteps.map(({ filtered }: FlowStep) => filtered);
  const selectedOptions = activeFlowSteps.map(({ value }: FlowStep) => value).filter((v) => v);

  const relevantSelection = activeFlowSteps.filter(({ noOptions }: FlowStep) => !noOptions);

  const shouldShowForm = selectedOptions.length >= relevantSelection.length;

  const describeForm = (
    <Card
      overTitle={{
        tag: TITLE_TAG.H2,
        size: TITLE_SIZE.HEADLINES,
        children: label('Ref: Provide Description'),
      }}
    >
      <div className={classNames('mb-M')}>
        <Title className={'mb-S'} size={TITLE_SIZE.BODYSBOLD}>
          {`${label('Date')} *`}
        </Title>
        <div className={classNames(styles.calendarButton)}>
          <Button
            look={'primary'}
            data-testid="filter-option-expanded-calendar"
            data-cy={'filter-option-expanded-calendar'}
            onClick={() => setIsCalendarVisible(true)}
            size={SIZE.SMALL}
            contentCenterAlign={true}
            affix={() => <Calendar2Icon />}
          >
            {feedbackDate
              ? formatDate(moment(feedbackDate, 'DD/MM/YYYY').toDate(), currentLanguageCode)
              : label('calendar', { textTransform: 'capitalize' })}
          </Button>
          <Modal
            title={label('calendar', { textTransform: 'capitalize' })}
            isOpen={isCalendarVisible}
            id="calendar_modal"
            onDismiss={() => setIsCalendarVisible(false)}
          >
            <Calendar
              maxDate={new Date()}
              onChange={handleDateChange}
              value={feedbackDate}
              minDetail="decade"
            />
          </Modal>
        </div>
      </div>

      <Textarea
        label={label('Description')}
        id="comment"
        onChange={(e) => setComment(e.detail?.value || '')}
        value={comment}
        required
        placeholder={label('Ref: Describe issue')}
        data-testid={'description-input'}
      />
      <FormValidationAlert
        msgCodes={{}}
        missingInfo={formMissingInfo}
        show={showFormValidationAlert}
        label={label}
        onDidDismiss={() => setShowFormValidationAlert(false)}
      />
    </Card>
  );

  const requestCreationPath = getRequestCreationPath();

  const bottomBarActions = [];

  /**
   * On the first feedback screen we want to display the secondary button
   * which will redirect us to the service request page.
   * So if the path is defined, display the button.
   */
  if (requestCreationPath) {
    bottomBarActions.push(
      <Fragment key="logRequest">
        <Hint
          data-testid="feedback-create-flow"
          className={classNames(styles.hint, 'mb-S')}
          text={label('Ref: Hint')}
        />
        <Button
          data-testid="feedback-create-flow-request-creation-path"
          onClick={() => history.push(requestCreationPath)}
          look={BUTTON_LOOK.SECONDARY}
        >
          {label('Ref: Log Request')}
        </Button>
      </Fragment>
    );
  }

  /**
   * When we navigate to the second step of the flow
   * we no longer want to display the button to
   * redirect to the service requests creation
   */
  if (stepIndex > 0) {
    bottomBarActions.pop();
  }

  /**
   * The button to submit the feedback is added to the bottomBar
   * when we are of the final step and the form fields are visible
   */
  if (shouldShowForm) {
    bottomBarActions.push(
      <Button
        key="submit"
        data-testid="feedback-create-flow-submit-button"
        onClick={handleSubmit}
        loading={isSubmitting}
        disabled={!isFormValid}
      >
        {label('submit')}
      </Button>
    );
  }

  return (
    <SimpleFormPage hasBackLink={false} title={label('Feedback')}>
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              tag: TITLE_TAG.H2,
              size: TITLE_SIZE.HEADLINES,
              children: label('Ref: Share your opinion header'),
            }}
          >
            <FillInTheBlanks
              sentence={generatedSentence}
              options={arrOptions}
              selectedOptions={selectedOptions}
              handleChange={handleSelection}
            />
            {isLoadingStep && (
              <div className={styles.loader}>
                <Loader data-testid="feedback-options" />
              </div>
            )}
          </Card>
          {shouldShowForm && describeForm}
        </Column.Main>
        <Column.Complementary>
          <Woman2Illustration />
        </Column.Complementary>
        {bottomBarActions.length ? <ActionsBar>{bottomBarActions}</ActionsBar> : null}
      </Container>
    </SimpleFormPage>
  );
};

export default CreateFlow;
