const labels = {
  All: {
    'Ref: stamp progress': 'Haladás a következő bélyeg felé',
    Reward: 'Jutalom',
    Rewards: 'Jutalmak',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Bélyegek',
    'Ref: Fulfill conditions': 'Keressen bélyegeket a jutalmak beváltására!',
    'See Reward': 'Lásd a jutalmat',
    'Ref: No reward': 'Jelenleg nincs jutalom',
  },
  RedeemableProducts: { 'Ref: Page title': 'Hűség jutalom' },
};
export default labels;
