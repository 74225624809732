import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';
import { getTomorrowDate } from '../helpers/helper';
import { AccommodationRequestFormFields } from '../types';

import AccommodationRequest from '@/modules/AccommodationRequest/routes/AccommodationRequest';
import CreateError from '@/modules/AccommodationRequest/routes/CreateError';
import CreateForm from '@/modules/AccommodationRequest/routes/CreateForm';
import CreateSummary from '@/modules/AccommodationRequest/routes/CreateSummary';
import CreateThankYou from '@/modules/AccommodationRequest/routes/CreateThankYou';
import Details from '@/modules/AccommodationRequest/routes/Details';
import UpdateUs from '@/modules/AccommodationRequest/routes/UpdateUs';
import { State } from '@/types/state.types';

const Routes = () => {
  const currentSiteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const formMethods = useForm<AccommodationRequestFormFields>({
    mode: 'all',
    defaultValues: {
      site: currentSiteId,
      guestFirstName: '',
      guestLastName: '',
      phoneNumber: '',
      comment: '',
      isGuestRequest: false,
      dateFields: {
        arrivalDate: new Date(),
        departureDate: new Date(getTomorrowDate(new Date()).setHours(12, 0, 0)),
      },
    },
  });
  return (
    <FormProvider {...formMethods}>
      <Switch>
        <Route path={pagePaths.Module} exact children={<AccommodationRequest />} />
        <Route path={pagePaths.CreateForm} children={<CreateForm />} />
        <Route path={pagePaths.CreateSummary} children={<CreateSummary />} />
        <Route path={pagePaths.CreateThankYou} children={<CreateThankYou />} />
        <Route path={pagePaths.CreateError} children={<CreateError />} />
        <Route path={pagePaths.Details} children={<Details />} />
        <Route path={pagePaths.UpdateUs} children={<UpdateUs />} />
      </Switch>
    </FormProvider>
  );
};

export default Routes;
