import { defaultCart } from '../__mocks/mock';

import { isCartItemLoyaltyReward, isCartItemProvidingLoyaltyStamps } from './cart.helper';

import { defaultLoyaltySchemes } from '@/modules/LoyaltyStamps/mocks/mock';

describe('isCartItemLoyaltyReward', () => {
  describe('when Cart Item is loyalty reward', () => {
    const result = isCartItemLoyaltyReward(
      { ...defaultCart!.menuPortionItems![0], uomId: 30552, foodItemId: 28509 },
      3329,
      defaultLoyaltySchemes
    );

    it('should return true', () => {
      expect(result).toBeTruthy();
    });
  });

  describe('when cart Cart Item is from menu excluded from Loyalty settings', () => {
    const result = isCartItemLoyaltyReward(
      { ...defaultCart!.menuPortionItems![0], uomId: 30552, foodItemId: 28509 },
      999999,
      defaultLoyaltySchemes
    );

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when Cart Item is not listed in loyalty reward list', () => {
    const result = isCartItemLoyaltyReward(
      { ...defaultCart!.menuPortionItems![0], uomId: 30559, foodItemId: 28510 },
      3329,
      defaultLoyaltySchemes
    );

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when loyalty stamps response is undefined', () => {
    const result = isCartItemLoyaltyReward(
      { ...defaultCart!.menuPortionItems![0], uomId: 30559, foodItemId: 28510 },
      3329
    );

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });
});

describe('isCartItemProvidingLoyaltyStamps', () => {
  describe('when Cart Item is providing stamps', () => {
    const result = isCartItemProvidingLoyaltyStamps(
      { ...defaultCart!.menuPortionItems![0], uomId: 30551, foodItemId: 28508 },
      3329,
      defaultLoyaltySchemes
    );

    it('should return true', () => {
      expect(result).toBeTruthy();
    });
  });

  describe('when Cart Item is from menu excluded from Loyalty settings', () => {
    const result = isCartItemProvidingLoyaltyStamps(
      { ...defaultCart!.menuPortionItems![0], uomId: 30552, foodItemId: 28509 },
      999999,
      defaultLoyaltySchemes
    );

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when Cart Item is not listed in list of items providing stamps', () => {
    const result = isCartItemProvidingLoyaltyStamps(
      { ...defaultCart!.menuPortionItems![0], uomId: 30559, foodItemId: 28510 },
      3329,
      defaultLoyaltySchemes
    );

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when loyalty stamps response is undefined', () => {
    const result = isCartItemProvidingLoyaltyStamps(
      { ...defaultCart!.menuPortionItems![0], uomId: 30559, foodItemId: 28510 },
      3329
    );

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });
});
