import { env } from 'process';

import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import { TransactionalFeedbackModalProps } from './TransactionalFeedbackModal.types';
import TransactionalFeedbackModalWrapper from './TransactionalFeedbackModalWrapper';

import { FACILITY_TYPE } from '@/modules/Review/types/reviewFacility.types';

const mockUseTransactionalFeedback = jest.fn();
const mockUseSaveLastTransactionalFeedbackDateMutation = jest.fn();
const mockUseGetMenusQuery = jest.fn();
const saveLastTransactionalFeedback = jest.fn();
const mockFetchFacilities = jest.fn();
const mockGetImageData = jest.fn();
const mockGetCurrentLanguageCode = jest.fn();
const mockLogUserSteps = jest.fn();

let transactionalFeedbackModalPropsMock: TransactionalFeedbackModalProps;

jest.mock('./TransactionalFeedbackModal', () => (props: any) => {
  transactionalFeedbackModalPropsMock = props;
  return <div data-testid="mocked-transactional-feedback-modal" />;
});

const dispatchMock = (value: any) => {
  return value;
};

jest.mock('react-redux', () => {
  const actual = jest.requireActual('react-redux');
  return {
    ...actual,
    useDispatch: () => {
      return dispatchMock;
    },
  };
});

jest.mock('../../api', () => {
  return {
    useGetMenusQuery: () => mockUseGetMenusQuery(),
    useSaveLastTransactionalFeedbackDateMutation: () =>
      mockUseSaveLastTransactionalFeedbackDateMutation(),
  };
});

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: mockLogUserSteps,
  }),
}));

jest.mock('@/modules/Order/hooks/useTransactionalFeedback/useTransactionalFeedback.tsx', () => ({
  useTransactionalFeedback: () => mockUseTransactionalFeedback(),
}));

jest.mock('../../../../helpers/misc', () => {
  return {
    ...jest.requireActual('../../../../helpers/misc'),
    getCurrentLanguageCode: () => mockGetCurrentLanguageCode(),
  };
});

jest.mock('../../helpers/menuImage.helper', () => {
  return {
    getImageData: () => mockGetImageData(),
  };
});

jest.mock('../../../Facilities/redux/actions', () => {
  return {
    getAll: () => mockFetchFacilities(),
  };
});

jest.mock('../../../../store', () => ({
  store: {
    getState: () => ({
      Core: {
        context: {
          site: {
            id: 1,
            currency: { isoCode: 'USD' },
          },
        },
      },
    }),
  },
}));

// data
const mockedMenus = [
  {
    id: 5,
    facilityId: 'facilityId1',
    name: 'menu 1',
    isOrderable: true,
    isScanAndGo: false,
    date: new Date().toString(),
    menuItems: [],
  },
  {
    id: 2,
    facilityId: 'facilityId2',
    name: 'menu 2',
    isOrderable: true,
    isScanAndGo: true,
    date: new Date().toString(),
    menuItems: [
      {
        menuItemId: 21,
        productPortions: [],
      },
    ],
  },
];

const mockedFacilities = [
  {
    title: '8Mile',
    name: 'facilityId1',
    id: 'facilityId1',
    sequence: 1,
    images: ['img1'],
    facilityType: { id: 'facTypeId', name: FACILITY_TYPE['Food - Retail'] },
    viewCounts: 1,
    externalUrl: null,
  },
];

const mockedLastOrder = {
  menuId: 5,
  fulfillmentType: 'DineIn',
  orderId: 16,
  orderNumber: '16',
  pickupDate: new Date().toDateString(),
  total: 9,
};

const mockedLanguageCode = 'en-US';

describe('TransactionalFeedbackModalWrapper component', () => {
  afterAll(() => cleanup());

  beforeEach(async () => {
    global.process.env = { ...env, REACT_APP_TRANSACTIONAL_FEEDBACK_ENABLED: 'true' };

    mockUseSaveLastTransactionalFeedbackDateMutation.mockReturnValue([
      saveLastTransactionalFeedback,
      { isLoading: false, isSuccess: false },
    ]);

    mockFetchFacilities.mockResolvedValue({
      ok: true,
      responseData: {
        facilities: mockedFacilities,
      },
    });

    mockGetImageData.mockReturnValue(undefined);
    mockGetCurrentLanguageCode.mockReturnValue(mockedLanguageCode);
    mockUseGetMenusQuery.mockReturnValue({ data: mockedMenus, isLoading: false });

    mockUseTransactionalFeedback.mockReturnValue({
      lastOrder: mockedLastOrder,
      shouldOpenTransactionalFeedbackModal: true,
      transactionalFeedbackTagsEnabled: true,
    });
  });

  describe('TransactionalFeedbackModalWrapper shown', () => {
    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should display modal with expected props', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');

      expect(transactionalFeedbackModalPropsMock.order).toBe(mockedLastOrder);
      expect(transactionalFeedbackModalPropsMock.facility).toBe(mockedFacilities[0]);
      expect(transactionalFeedbackModalPropsMock.languageCode).toBe(mockedLanguageCode);
      expect(transactionalFeedbackModalPropsMock.isModalOpen).toBe(true);
      expect(modal).toBeTruthy();
    });

    it('should call the SaveLastTransactionalFeedbackDate', () => {
      expect(mockUseSaveLastTransactionalFeedbackDateMutation).toHaveBeenCalled();
    });

    it('should call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalled();
    });
  });

  describe('TransactionalFeedbackModalWrapper not shown - not enabled by feature flag', () => {
    beforeEach(async () => {
      global.process.env = { ...env, REACT_APP_TRANSACTIONAL_FEEDBACK_ENABLED: 'false' };
    });
    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should not display modal', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');
      expect(modal).toBeFalsy();
    });

    it('should not call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalledTimes(0);
    });
  });

  describe('TransactionalFeedbackModalWrapper not shown - fetchFacilities response with no match in Menu Response', () => {
    beforeEach(async () => {
      let facilitiesWithoutMatch = mockedFacilities;
      facilitiesWithoutMatch[0].id = 'facilityId5';
      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: facilitiesWithoutMatch,
        },
      });
    });
    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should not display modal', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');
      expect(modal).toBeFalsy();
    });
    it('should not call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalledTimes(0);
    });
  });

  describe('TransactionalFeedbackModalWrapper not shown - empty fetchFacilities', () => {
    beforeEach(async () => {
      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: [],
        },
      });
    });
    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should not display modal', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');
      expect(modal).toBeFalsy();
    });
    it('should not call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalledTimes(0);
    });
  });

  describe('TransactionalFeedbackModalWrapper not shown - Menu Response with no match with Facilities', () => {
    beforeEach(async () => {
      let mockedMenusWithoutFacilityIdMatch = mockedMenus;
      mockedMenusWithoutFacilityIdMatch[0].facilityId = 'x';
      mockedMenusWithoutFacilityIdMatch[1].facilityId = 'y';
      mockUseGetMenusQuery.mockReturnValue({
        data: mockedMenusWithoutFacilityIdMatch,
        isLoading: false,
      });
    });
    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should not display modal', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');
      expect(modal).toBeFalsy();
    });
    it('should not call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalledTimes(0);
    });
  });

  describe('TransactionalFeedbackModalWrapper not shown - with empty Menu Response', () => {
    beforeEach(async () => {
      mockUseGetMenusQuery.mockResolvedValue({ data: [], isLoading: false });
    });
    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should not display modal', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');
      expect(modal).toBeFalsy();
    });
    it('should not call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalledTimes(0);
    });
  });

  describe('TransactionalFeedbackModalWrapper not shown - no order returned from useTransactionalFeedback', () => {
    beforeEach(async () => {
      mockUseTransactionalFeedback.mockReturnValue({
        lastOrder: {},
        shouldOpenTransactionalFeedbackModal: false,
        transactionalFeedbackTagsEnabled: false,
      });
    });
    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should not display modal', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');
      expect(modal).toBeFalsy();
    });
    it('should not call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalledTimes(0);
    });
  });

  describe('TransactionalFeedbackModalWrapper not shown - no order returned from useTransactionalFeedback', () => {
    beforeEach(async () => {
      let mockedLastOrderWithDifferentMenuId = mockedLastOrder;
      mockedLastOrderWithDifferentMenuId.menuId = 99;

      mockUseTransactionalFeedback.mockReturnValue({
        lastOrder: mockedLastOrderWithDifferentMenuId,
        shouldOpenTransactionalFeedbackModal: false,
        transactionalFeedbackTagsEnabled: false,
      });
    });

    renderComponent(TransactionalFeedbackModalWrapper, {
      site: {
        id: 'id',
        name: 'Total',
      },
    });

    it('should not display modal', () => {
      const modal = screen.queryByTestId('mocked-transactional-feedback-modal');
      expect(modal).toBeFalsy();
    });
    it('should not call the LogUserSteps', () => {
      expect(mockLogUserSteps).toHaveBeenCalledTimes(0);
    });
  });
});
