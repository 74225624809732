const labels = {
  All: {
    'Ref: stamp progress': 'Verder naar de volgende zegel',
    Reward: 'Beloning',
    Rewards: 'Beloningen',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Zegels',
    'Ref: Fulfill conditions': 'Verdien postzegels om beloningen in te wisselen!',
    'See Reward': 'Zie beloning',
    'Ref: No reward': 'Voorlopig geen beloning',
  },
  RedeemableProducts: { 'Ref: Page title': 'Loyaliteitsbeloningen' },
};
export default labels;
