const labels = {
  All: {
    'Ref: Assembled': 'Gebaut',
    'Ref: Submitted': 'Eingereicht',
    'Ref: Fulfilled': 'Erfüllt',
    'Ref: Completed': 'Vollendet',
    'Ref: Paid': 'Bezahlt',
    'Ref: Approved': 'Genehmigt',
    'Ref: Ready For Pickup': 'Abholbereit',
    'Ref: Delivered': 'Geliefert',
    'Ref: Received': 'Erhalten',
    'Ref: Rejected': 'Abgelehnt',
    'Ref: Payment Error': 'Zahlungsfehler',
    'Ref: Pending Payment': 'Ausstehende Zahlung',
    'Ref: Preparing': 'Vorbereitung',
    'Ref: Refunded': 'Erstattet',
    'Ref: Cancelled By User': 'Vom Benutzer abgebrochen',
    'Ref: Refund Requested': 'Rückerstattung beantragt',
    'Ref: Refund Failed': 'Rückerstattung fehlgeschlagen',
    'Ref: Payment Processing': 'Zahlungsabwicklung',
    'Ref: Not Paid': 'Nicht bezahlt',
    'Ref: Total:': 'Gesamt:',
    'Ref: Pickup': 'Aufsammeln',
    'Ref: Eat-in': 'Essen',
    'Ref: Scan&Go': 'Scannen und gehen',
    'Ref: Fulfillment Type': 'Erfüllungstyp',
    'Ref: Delivery': 'Lieferung',
    'Ref: You have no future orders': 'Sie haben keine zukünftigen Bestellungen',
    'Ref: You have no past orders': 'Sie haben keine vergangenen Bestellungen',
    'Ref: You have no orders': 'Sie haben keine Bestellungen',
    'Ref: All orders': 'Alle Bestellungen',
    'Ref: Not past orders': 'Kommende Bestellungen',
    'Ref: Past orders': 'Frühere Bestellungen',
    'Ref: Vegan': 'Veganer',
    'Ref: Vegeterian': 'Vegetarier',
    'Ref: See item': 'Siehe Artikel',
    'Ref: Activated': 'Aktiviert',
    'Ref: No suggestions': 'Keine Vorschläge gefunden',
    'Ref: Get your receipt': 'Holen Sie sich Ihre Quittung',
    'Ref: Print': 'Drucken Sie Ihre Quittung aus',
    'Ref: Email': 'Erhalten Sie Ihre Quittung per E-Mail',
    'Ref: Receipt Modal message': 'Wie möchten Sie Ihre Quittung erhalten?',
    'Ref: Confirm': 'Bestätigen',
    'Ref: Your email address': 'Ihre E-Mail-Adresse',
    Suggestion: 'Anregung',
    'Ref: Loyalty Reward': 'Treuebonus',
    'Ref: Equation for stamps per item': '1 Kauf = 1 Stempel',
    'Ref: Paid by employer': 'Der von Ihrem Arbeitgeber gezahlte Betrag',
    'Ref: Paid by user': 'Der von Ihnen gezahlte Betrag',
  },
  ProductsList: {
    'Ref: Page title': 'Produktliste',
    'Ref: Facility details': 'Anlagendetails',
    'Ref: Search products list': 'Suchteile',
    'Ref: filter label: menus': 'Speisekarte',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategorie',
    'Ref: all': 'Alle',
    'Ref: today': 'Heute',
    'Ref: tomorrow': 'Morgen',
    'Ref: see more': 'Mehr sehen',
    'Ref: see less': 'Weniger sehen',
    'Ref: reset': 'Zurücksetzen',
    'Ref: View in cart': 'Blick im Waren',
    'Ref: You have changed location': 'Sie haben den Standort geändert',
    'Ref: You are now at': 'Sie sind jetzt bei',
    'Ref: Menu card': 'Speisekarte',
    'Ref: Table': 'Tisch Nummer',
    'Service unavailable': 'Dienst nicht verfügbar',
    'Ref: Ordering unavailable':
      'Vorsichtig! Die Bestellung an dieser Website ist momentan nicht verfügbar, versuchen Sie es später.',
    'Ref: Filters': 'Filter (Allergene und mehr)',
  },
  ProductScanner: {
    'Ref: cart': 'Wagen',
    'Ref: Product Scanned':
      'wurde zum Warenkorb hinzugefügt. Sie können die Barcodes weiter scannen, um sie in Ihren Warenkorb hinzuzufügen.',
    'Ref: Scan or adjust':
      'wurde zum Warenkorb hinzugefügt. Stellen Sie die Menge ein oder scannen Sie ein anderes Produkt.',
    'Ref: Product Not Matched': 'Barcode wurde nicht erkannt. Bitte versuche es erneut',
    'Ref: Scan article code': 'Barcode scannen',
    'Ref: Native error product cannot be added to cart':
      'Element aus einem anderen Menü oder Tagesteil kann nicht zum Warenkorb hinzugefügt werden',
    'Ref: product scanner placeholder':
      'Bitte scannen Sie den Barcode, um ihn in Ihren Warenkorb hinzuzufügen',
  },
  Order: { 'Ref: Page title': 'Befehl' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menüs',
    'Ref: today': 'Heute',
    'Ref: tomorrow': 'Morgen',
    'Ref: Search products list': 'Suchteile',
    'Ref: You have changed location': 'Sie haben den Standort geändert',
    'Ref: You are now at': 'Sie sind jetzt bei',
  },
  ProductDetails: {
    'Ref: Page title': 'Produktdetails',
    'Ref: Serving Size': 'Serviergröße',
    'Ref: Nutrition': 'Nährwert-Information',
    'Ref: Nutrition adults': 'Erwachsene brauchen rund 2000 kcal pro Tag',
    'Ref: Nutrition Calories': 'Kalorien',
    'Ref: Nutrition Calcium': 'Kalzium',
    'Ref: Nutrition Fat': 'Fett',
    'Ref: Nutrition Saturated Fat': 'Gesättigte Fettsäuren',
    'Ref: Nutrition Carbohydrates': 'Kohlenhydrate',
    'Ref: Nutrition Sugar': 'Zucker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Cholesterin',
    'Ref: Nutrition dietaryFiber': 'Ernährungsfaser',
    'Ref: Nutrition iron': 'Eisen',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Additives': 'Additive',
    'Ref: Emission': 'CO2-Emissionen',
    'Ref: This product generates': 'Dieses Produkt erzeugt',
    'Ref: of CO2': 'von CO2',
    'Ref: emissions, which equals a': 'Emissionen, was a',
    'Ref: car trip': 'Autoreise',
    'Ref: Ingredients': 'Rezeptzutat',
    'Ref: Allergens': 'Allergenen',
    'Ref: Always there': 'Immer da',
    'Ref: Allergens selected': 'Allergene, die auf dem Filter ausgewählt wurden:',
    'Ref: minimum required': 'Minimum erforderlich',
    'Ref: you have to add at least': 'Sie müssen zumindest hinzufügen',
    'Ref: ingredients': 'Zutaten',
    'Ref: Ingredient': 'Zutat',
    'Ref: Free': 'Frei',
    'Ref: Based on customization': 'Basierend auf Ihrer Anpassung',
    'Ref: Additives eggs': 'Eier',
    'Ref: Additives fish': 'Fisch',
    'Ref: Additives milk': 'Milch',
    'Ref: Additives nuts': 'Nüsse',
    'Ref: Additives wheat': 'Weizen',
    'Ref: Additives peanuts': 'Erdnüsse',
    'Ref: Additives soy beans': 'Sojabohnen',
    'Ref: Additives sesame seeds': 'Sesamsamen',
    'Ref: Review': 'Überprüfung',
    'Ref: Per portion': 'Pro Teil',
    'Ref: Per 100g': 'Pro 100g',
    'Ref: Allergen selected': 'Allergen im Suchfilter ausgewählt',
    'Ref: Fitbit Log': 'Protokoll bei Fitbit',
    'Ref: age verification required on delivery':
      'Altersüberprüfung bei der Lieferung erforderlich',
    'Ref: alcohol content notification':
      'Wenn Sie eines dieser Artikel platzieren und bestellen, erklären Sie, dass Sie 18 Jahre alt sind. Alkoholmissbrauch ist gefährlich für Ihre Gesundheit.',
    'Ref: default': 'Standard',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} verdient, wenn Sie dieses Produkt bestellen',
    'Ref: Loyalty Card Description':
      'Die {stamp_form} wird der folgenden Loyalität hinzugefügt {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Mit dem Programm für Loyalitätsmarken können Sie Briefmarken für Belohnungen sammeln.',
    'Ref: Loyalty Modal Description':
      'Wenn Sie <b> {product_name} kaufen, wird 1 Stempel </b> zum <b> {scheme_names} </b> {program_form} hinzugefügt',
    'Ref: and': 'Und',
    'Ref: Stamp': 'Briefmarke',
    'Ref: Stamps': 'Briefmarken',
    'Ref: Program': 'Programm',
    'Ref: Programs': 'Programme',
    'Ref: Loyalty Stamps': 'Loyalitätsmarken',
    'Ref: See Loyalty Stamps': 'Siehe Loyalitätsmarken',
    Favorite: 'Favorit',
    'Ref: In favorites':
      'Das Produkt befindet sich bereits in der Favoritenliste. Klicken Sie hier, um es zu entfernen.',
    'Ref: Not in favorites':
      'Das Produkt befindet sich noch nicht in der Favoritenliste. Klicken Sie hier, um es hinzuzufügen.',
    'Ref: Added to favorites': 'Das Produkt wurde in die Favoritenliste hinzugefügt.',
    'Ref: Removed from favorites': 'Das Produkt wurde aus der Favoritenliste entfernt.',
    'Ref: From': 'Aus',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Wechsel im Karren',
    'Ref: Popup header for customizable item qty update': 'Dieser Artikel ist anpassbar',
    'Ref: add new version': 'Neue Version hinzufügen',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Aktualisieren Sie die Menge eines anpassbaren Elements direkt aus Ihrem Warenkorb.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Aktualisieren Sie die Menge eines anpassbaren Elements direkt aus Ihrem Warenkorb oder fügen Sie eine neue Version hinzu.',
    'Ref: another context body':
      'Um Artikel, Einrichtung, Moment/Menü oder Datum hinzuzufügen, müssen Sie den Wagen löschen',
    'Ref: cancel': 'Absagen',
    'Ref: add to cart': 'In den Warenkorb hinzufügen',
    'Ref: view in cart': 'Wagen',
    'Ref: Products in cart': 'Nummer von {product_name} in cart: {product_quantity}',
    'Ref: Customize': 'Anpassen',
    'Ref: Add': 'Hinzufügen',
    'Ref: Free': 'Frei',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Absagen',
    'Ref: notice': 'Notiz',
    'Ref: clear cart': 'Klaren Waren ',
    'Ref: Cart addition error message':
      'Derzeit haben Sie Elemente in Cart von {from_current}. Um Elemente von {from_selected} hinzuzufügen, müssen Sie den Wagen löschen',
  },
  Cart: {
    'Ref: Page title': 'Waren ',
    'Ref: The cart is empty': 'Der Waren ist leer',
    'Ref: Clear cart': 'Warenkorb leeren',
    'Ref: Continue shopping': 'Weiter einkaufen',
    'Ref: Start shopping': 'Einkaufen beginnen',
    'Ref: Today': 'Heute',
    'Ref: Tomorrow': 'Morgen',
    'Ref: Tax:': 'Steuer:',
    'Ref: Promotion:': 'Förderung:',
    'Ref: Total without promotion:': 'Summe ohne Beförderung:',
    'Ref: Delivery:': 'Lieferung:',
    'Ref: Pickup informations': 'Abholungsinformationen',
    'Ref: Location': 'Standort',
    'Ref: Time': 'Zeit',
    'Ref: Phone': 'Deine Telefonnummer',
    'Ref: Instructions': 'Anweisungen',
    'Ref: Please select': 'Bitte auswählen',
    'Ref: Enter special requests': 'Geben Sie hier spezielle Anfragen ein',
    'Ref: notice': 'Notiz',
    'Ref: Product is no more available':
      'Entschuldigung, aber es scheint, dass einige Produkte nicht mehr verfügbar sind. Bitte überprüfen Sie Ihren Wagen.',
    'Ref: ok': 'In Ordnung',
    'Ref: Validate': 'Validieren Sie meine Bestellung',
    'Ref: Pay now': 'Zahlen Sie jetzt',
    'Ref: Charge to my department': 'Gebühr an meine Abteilung',
    'Ref: Pay on pickup': 'Zahlen Sie für diese Artikel auf Abholung',
    'Ref: Accept terms of sale prefix': 'Ich nehme an',
    'Ref: Accept terms of sale': 'Diese Umsatzbedingungen',
    'Ref: Continue': 'Fortsetzen',
    'Ref: Please login or sign up to place an order':
      'Bitte melden Sie sich an oder melden Sie sich an, um eine Bestellung aufzugeben',
    'Ref: Accept terms of sale suffix': ', und ich habe sie gelesen und verstanden.',
    'Ref: Payment modality': 'Bezahlverfahren',
    'Ref: Other information': 'Andere Informationen',
    'Ref: Eat-in': 'Essen',
    'Ref: Pickup': 'Aufsammeln',
    'Ref: Table': 'Tisch Nummer',
    'Ref: Required fields': 'Benötigte Felder',
    'Service unavailable': 'Dienst nicht verfügbar',
    'Ref: Cannot order':
      'Entschuldigung, Sie können derzeit keine Bestellung auf dieser Seite erstellen. Bitte kontaktieren Sie das Restaurant, um weitere Informationen zu Öffnungszeiten und verfügbaren Dienstleistungen zu erhalten.',
    'Ref: Order Unavailable':
      'Bestellung für das ausgewählte Datum, die Erfüllungstyp und den Ort sind nicht mehr verfügbar!',
    'Ref: No Availability': 'Keine Verfügbarkeit mehr',
    'Ref: Continue on your device': 'Fahren Sie auf Ihrem Gerät fort',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} verdient, wenn Sie Ihre Bestellung abschließen',
    'Ref: Loyalty Card Description':
      'Die {stamp_form} wird der folgenden Loyalität hinzugefügt {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Mit dem Programm für Loyalitätsmarken können Sie Briefmarken für Belohnungen sammeln.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> verdient, wenn Sie Ihre Bestellung abschließen:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> hinzugefügt für <b> {scheme_names} </b>',
    'Ref: Stamp': 'Briefmarke',
    'Ref: Stamps': 'Briefmarken',
    'Ref: Program': 'Programm',
    'Ref: Programs': 'Programme',
    'Ref: Loyalty Stamps': 'Loyalitätsmarken',
    'Ref: See Loyalty Stamps': 'Siehe Loyalitätsmarken',
    'Ref: Loyalty Info': 'Loyalitätsinformationen',
    'Ref: Email address': 'E-Mail-Adresse',
    'Ref: and': 'Und',
    'Ref: Order minimum amount': 'Entschuldigung, der Mindestzahlungsbetrag ist {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Fahren Sie auf Ihrem Gerät fort',
    'Ref: Scan QR to continue':
      'Bitte scannen Sie den QR -Code, um die Reise auf Ihrem Gerät fortzusetzen.',
  },
  OrderHistory: { 'Ref: Orders': 'Aufträge', 'Ref: Page Title': 'Meine Bestellungen' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Einzelpreis',
    'Ref: Download': 'Laden Sie die Quittung herunter',
    'Ref: Download error':
      'Es ist ein Download -Fehler aufgetreten. Versuchen Sie später, die Quittung herunterzuladen',
    'Ref: Order details title': 'Bestelldetails',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Vielen Dank! Ihre Bestellung ist jetzt bestätigt',
    'Ref: Text for pickup':
      'Sie können seinen Status durch Ihren Bestellhistorie befolgen. Wenn Ihre Benachrichtigungen eingeschaltet sind, werden wir Sie aktualisieren, wenn sie für die Abholung bereit sind.',
    'Ref: Success Order Title': 'Vielen Dank für Ihre Bestellung',
    'Ref: Success Order Description':
      'Bitte bezahlen Sie an der Kasse, um Ihre Bestellung zu validieren.',
    'Ref: Success Order ID': 'ID -Bestellung',
    'Ref: Success Order Action': 'Neue Bestellung',
    'Ref: Text for dinein':
      'Ihre Bestellung sollte bald an Ihren Tisch zugestellt werden. Fragen Sie einfach einen unserer freundlichen Mitarbeiter nach Ihrer Einrichtung.',
    'Ref: Text for scan&go': 'Ihre Bestellung ist in Ihrer Bestellhistorie verfügbar',
    'Ref: Text for delivery':
      'Sie können seinen Status in Ihrem Bestellverlauf verfolgen. Wenn Ihre Benachrichtigungen aktiviert sind, informieren wir Sie, wenn Ihre Bestellung fertig ist.',
    'See details': 'Siehe Einzelheiten',
    'Go to home': 'Zur Startseite',
  },
  FailOrder: {
    'Go to home': 'Zur Startseite',
    'Ref: Kiosk Fail Title': 'Hoppla! Etwas ist schief gelaufen!',
    'Ref: Kiosk Fail Description':
      'Leider war Ihre Bestellung nicht erfolgreich. Bitte versuchen Sie es erneut oder bitten Sie einen Mitarbeiter, Ihnen zu helfen.',
    'Ref: Kiosk Fail Back': 'Zurück zur vorherigen Seite',
    'Ref: Kiosk Fail Action': 'Löschen Sie die Sitzung und bestellen Sie erneut',
    'Ref: Error in cart validation':
      'Während Ihrer Bestellung trat ein Fehler auf. Bitte wenden Sie sich an die Einrichtung, um das Problem zu lösen.',
    'Facility contact': 'Einrichtung Kontakt',
    'Ref: Ordering failed': 'Bestellung fehlgeschlagen',
    'Ref: Order confirmed': 'Bestellung bestätigt',
  },
  TableCheckIn: {
    'Welcome at': 'Willkommen bei',
    Table: 'Tisch:',
    'Ref: list title': 'Jüngste Bestellungen für heute',
    'See the menu': 'Siehe das Menü',
    'Table Check-in': 'Tabelle Check-in',
    'Ref #': 'Ref #',
    Checked: 'Überprüft',
    'Check in': 'Check-In',
    'See details': 'Siehe Einzelheiten',
    'Ref: You have changed location': 'Sie haben den Standort geändert',
    'Ref: You are now at': 'Sie sind jetzt bei',
    'Order number': 'Bestellnummer',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Aktion angewendet',
    'Ref: Promotion applied description': 'Sie profitieren einer Aktion aus Ihrem Warenkorb.',
    'Ref: Promotion deleted title': 'Promotion gelöscht',
    'Ref: Promotion deleted description':
      'Sie profitieren der Aktion nicht mehr, da Sie die Bedingungen nicht mehr füllen oder die Aktion abgelaufen ist.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Scannen und gehen', 'Ref: View only': 'Nur anschauen' },
  ProductTile: {
    'Ref: Allergens': 'Allergene',
    'Ref: Presence of allergen': 'Vorhandensein von Allergen',
    'Ref: Scan': 'Scan',
    'Ref: From': 'Aus',
    'Ref: Already in product favorite list. Click to remove it.':
      'Bereits in Produktfavoritenliste. Klicken Sie hier, um es zu entfernen.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Derzeit nicht zur Produktfavoritenliste hinzugefügt. Klicken Sie hier, um es hinzuzufügen.',
    'Ref: Free': 'Frei',
  },
  OrderSummary: {
    'Ref: item': 'Artikel',
    'Ref: items': 'Artikel',
    'Ref: Clear': 'Klar',
    'Ref: Cart': 'Wagen',
    'Ref: Subtotal': 'Zwischensumme',
    'Ref: Total': 'Gesamt',
    'Ref: Tax': 'Steuer',
    'Ref: Promotion': 'Förderung',
    'Ref: Total without promotion': 'Summe ohne Beförderung',
    'Ref: Number of product in cart': 'Anzahl der Produkte im Warenkorben',
    'Ref: Delivery': 'Lieferung',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegan', 'Ref: Vegeterian': 'Vegetarier' },
  OrderInfoCard: {
    'Ref: Header': 'Bestellt für',
    'Ref: from': 'aus',
    'Ref: location': 'Ort',
    'Ref: Phone': 'Deine Telefonnummer',
    'Ref: Instructions': 'Anweisungen',
    'Ref: Order type': 'Auftragsart',
    'Ref: Pickup': 'Aufsammeln',
    'Ref: Eat-in': 'Essen',
    'Ref: Scan&Go': 'Scannen und gehen',
  },
  ReorderWidget: {
    'Ref: No items': 'Noch keine Bestellungen. Jetzt einkaufen!',
    'Ref: Title': 'Bestell es nochmal',
  },
  MenusWidget: {
    'Ref: no menu': 'Kein Menü für den Moment',
    'Ref: Modal title': 'Menü für den Tag',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Rabatte verfügbar' },
  PromotionInfoItem: { 'Ref: Activate': 'aktivieren Sie' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Deine Bestellungen',
    'Ref: Widget Title': 'Meine Bestellungen',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Vorschlag für den Tag',
    'Ref: Any menu': 'Jedes Menü',
    Other: 'Andere',
    'Ref: Suggestions calendar button': 'Vorschläge Kalender',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Serviergröße',
    'Ref: Nutrition': 'Nährwert-Information',
    'Ref: Nutrition adults': 'Erwachsene brauchen rund 2000 kcal pro Tag',
    'Ref: Nutrition Calories': 'Kalorien',
    'Ref: Nutrition Calcium': 'Kalzium',
    'Ref: Nutrition Fat': 'Fett',
    'Ref: Nutrition Saturated Fat': 'Gesättigte Fettsäuren',
    'Ref: Nutrition Carbohydrates': 'Kohlenhydrate',
    'Ref: Nutrition Sugar': 'Zucker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Cholesterin',
    'Ref: Nutrition dietaryFiber': 'Ernährungsfaser',
    'Ref: Nutrition iron': 'Eisen',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Ingredients': 'Rezeptzutat',
    'Ref: Allergens': 'Allergenen',
    'Ref: Always there': 'Immer da',
    'Ref: Allergens selected': 'Allergene, die auf dem Filter ausgewählt wurden:',
    'Ref: minimum required': 'Minimum erforderlich',
    'Ref: ingredients': 'Zutaten',
    'Ref: Ingredient': 'Zutat',
    'Ref: Free': 'Frei',
    'Ref: Based on customization': 'Basierend auf Ihrer Anpassung',
    'Ref: Additives eggs': 'Eier',
    'Ref: Additives fish': 'Fisch',
    'Ref: Additives milk': 'Milch',
    'Ref: Additives nuts': 'Nüsse',
    'Ref: Additives wheat': 'Weizen',
    'Ref: Additives peanuts': 'Erdnüsse',
    'Ref: Additives soy beans': 'Sojabohnen',
    'Ref: Additives sesame seeds': 'Sesamsamen',
    'Ref: Review': 'Überprüfung',
    'Ref: Per portion': 'Pro Teil',
    'Ref: Per 100g': 'Pro 100g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} kostenlos',
    'Ref: Number of free items': 'Anzahl der kostenlosen Artikel: {number_of_free_items}',
    'Ref: Allergen selected': 'Allergen im Suchfilter ausgewählt',
    'Ref: Nutritional info': 'Ernährungsinformationen',
    'Ref: Info': 'Die Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Sonderangebote' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Wählen Sie Ihren Job',
    'Ref: Validate': 'Bestätigen',
    'Ref: Pricing Tiers dialog message': 'Sie wurden mit Ihrer E -Mail -Adresse erkannt',
    'Ref: Please select your job in the company': 'Bitte wählen Sie Ihren Job im Unternehmen aus',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Aufsammeln',
    'Ref: Eat-in': 'Essen',
    'Ref: Scan&Go': 'Scannen und gehen',
    'Ref: Delivery': 'Lieferung',
    'Ref: Total': 'Gesamt',
    'Ref: How was facility name service': 'Wie war {facility_name} ’s Service?',
    'Ref: How do you rate the service': 'Wie bewerten Sie den Service?',
  },
};
export default labels;
