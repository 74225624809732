import { LOCAL_STORAGE_KEYS } from '../../../constants';

export type PushNotificationDeviceRegistration = {
  appId: string;
  installationId: string;
  platform: string;
  pushChannel: string;
  isSuccess: boolean;
  at: Date;
  error?: Error;
};

export function savePushNotificationRegistrationData(data: PushNotificationDeviceRegistration) {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.PUSH_NOTIFICATION_REGISTRATION_DATA,
    JSON.stringify(data)
  );
}

export function getPushNotificationRegistrationData() {
  const data = localStorage.getItem(LOCAL_STORAGE_KEYS.PUSH_NOTIFICATION_REGISTRATION_DATA);
  if (!!data) return JSON.parse(data);
}
