import { useSelector } from 'react-redux';

import { State } from '../../../types/state.types';

import { SERVICE } from '@/modules/config';

export const useGetService = (serviceName: SERVICE) => {
  const { list: servicesList } = useSelector((state: State) => state.Core.services);
  return servicesList.find((el: { name: string }) => el.name === serviceName);
};
