import { TrashCanIcon } from '../../../../assets/icons';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import { SIZE } from '../../../../constants';

export const ClearCartButton = ({
  onClick,
  title,
  inheritStyle,
}: {
  onClick: () => void;
  title: string;
  inheritStyle?: string;
}) => {
  return (
    <Button
      inheritStyle={inheritStyle}
      look={BUTTON_LOOK.TERTIARY}
      size={SIZE.SMALL}
      onClick={onClick}
      affix={TrashCanIcon}
      data-testid='order-clear-cart-button'
      contentCenterAlign
    >
      {title}
    </Button>
  );
};
