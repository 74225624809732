import { screen, cleanup, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultSite } from '../../../Sites/__mocks/mocks';
import {
  defaultFacilityMenu,
  defaultProductPortion,
  defaultReorderProposition,
} from '../../__mocks/mock';
import { FacilityMenu, ReorderMenuItem } from '../../types/orderState.types';
import { ProductTileProps } from '../../types/productList.types';
import { ReorderWidgetProps } from '../../types/reorderWidget.types';

import ReorderWidget from './ReorderWidget';

import {
  useGetLoyaltySchemesQuery,
  useGetUserLoyaltySchemesProgressQuery,
} from '@/modules/LoyaltyStamps/api';

const mockPush = jest.fn();

const mockHistory = createMemoryHistory();
mockHistory.push = mockPush;

jest.mock('react-router-dom', () => ({
  ...jest.requireActual('react-router-dom'),
  useHistory: () => mockHistory,
}));

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: jest.fn(),
    logError: jest.fn(),
  }),
}));

let mockProductTileProps: ProductTileProps[] = [];
jest.mock('../../containers/ProductTile', () => (productTileProp: ProductTileProps) => {
  mockProductTileProps.push(productTileProp);
  return <></>;
});

const mockUseGetReorderPropositionQuery = jest.fn();
const mockUseGetMenusQuery = jest.fn();

jest.mock('../../api', () => {
  return {
    useGetReorderPropositionQuery: () => mockUseGetReorderPropositionQuery(),
    useGetMenusQuery: () => mockUseGetMenusQuery(),
  };
});

jest.mock('@/modules/LoyaltyStamps/api', () => ({
  ...jest.requireActual('@/modules/LoyaltyStamps/api'),
  useGetLoyaltySchemesQuery: jest.fn(),
  useGetUserLoyaltySchemesProgressQuery: jest.fn(),
}));

const mockSelectorValue = {
  locks: {},
  language: 'en-US',
};

const mockSelectorFunction = jest.fn();

jest.mock('react-redux', () => {
  const actual = jest.requireActual('react-redux');
  return {
    ...actual,
    useSelector: () => {
      return mockSelectorFunction();
    },
  };
});

const menuId = 1;
const menus: FacilityMenu[] = [
  {
    ...defaultFacilityMenu,
    id: menuId,
  },
];

const reorderProposition: ReorderMenuItem[] = [
  {
    ...defaultReorderProposition,
    menuId,
    menuItemId: 11,
    productPortions: [
      {
        ...defaultProductPortion,
        isDefault: true,
      },
    ],
  },
  {
    ...defaultReorderProposition,
    menuId,
    menuItemId: 12,
    productPortions: [
      {
        ...defaultProductPortion,
        isDefault: true,
      },
    ],
  },
  {
    ...defaultReorderProposition,
    menuId,
    menuItemId: 13,
    productPortions: [
      {
        ...defaultProductPortion,
        isDefault: false,
      },
    ],
  },
  {
    ...defaultReorderProposition,
    menuId,
    menuItemId: 14,
    productPortions: [],
  },
];

describe('ReorderWidget component', () => {
  const props: ReorderWidgetProps = {
    site: defaultSite,
  };

  afterAll(() => cleanup());

  beforeEach(() => {
    mockProductTileProps = [];
    (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
      data: [],
      isLoading: false,
    });
    (useGetUserLoyaltySchemesProgressQuery as jest.Mock).mockReturnValue({
      data: [],
      isLoading: false,
    });
  });

  describe('on render', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      mockUseGetReorderPropositionQuery.mockReturnValue({ data: [], isLoading: false });
      mockUseGetMenusQuery.mockReturnValue({ data: [], isLoading: false });
    });

    renderComponent(ReorderWidget, props);

    it('should display', () => {
      const miniwdiget = screen.getByTestId('reorderWidget');
      expect(miniwdiget).toBeTruthy();
    });

    it('should fetch reorder propositions and menus on load', () => {
      expect(mockUseGetReorderPropositionQuery).toHaveBeenCalled();
      expect(mockUseGetMenusQuery).toHaveBeenCalled();
    });
  });

  describe('on menu list and reorder items not empty', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      mockUseGetReorderPropositionQuery.mockReturnValue({
        data: reorderProposition,
        isLoading: false,
      });
      mockUseGetMenusQuery.mockReturnValue({ data: menus, isLoading: false });
    });

    renderComponent(ReorderWidget, props);

    it('should render tiles only for items having default portion and matching menu', () => {
      expect(mockProductTileProps.length).toBe(2);
      expect(mockProductTileProps[0].menuItemId).toBe(11);
    });
  });

  describe('on menu list and reorder items not empty but without matching menus', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      mockUseGetReorderPropositionQuery.mockReturnValue({
        data: reorderProposition,
        isLoading: false,
      });

      mockUseGetMenusQuery.mockReturnValue({
        data: [
          {
            ...defaultFacilityMenu,
            id: 2,
          },
        ],
      });
    });

    renderComponent(ReorderWidget, props);

    it('should render tiles only for items having default portion and matching menu', () => {
      expect(mockProductTileProps.length).toBe(0);

      const noItemsPlaceholder = screen.getByTestId('reorder-noItems');
      expect(noItemsPlaceholder).toBeTruthy();
    });
  });

  describe('on reorder proposition loading', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      mockUseGetReorderPropositionQuery.mockReturnValue({ data: [], isLoading: true });

      mockUseGetMenusQuery.mockReturnValue({ data: [], isLoading: false });
    });

    renderComponent(ReorderWidget, props);

    it('should display loader', () => {
      const tileSkeleton = screen.getByTestId('loader');
      expect(tileSkeleton).toBeTruthy();
    });
  });

  describe('on menus loading', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      mockUseGetReorderPropositionQuery.mockReturnValue({ data: [], isLoading: false });

      mockUseGetMenusQuery.mockReturnValue({ data: [], isLoading: true });
    });

    renderComponent(ReorderWidget, props);

    it('should display loader', () => {
      const tileSkeleton = screen.getByTestId('loader');
      expect(tileSkeleton).toBeTruthy();
    });
  });

  describe('on menu list empty', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      mockUseGetReorderPropositionQuery.mockReturnValue({ data: reorderProposition });

      mockUseGetMenusQuery.mockReturnValue({ data: [] });
    });

    renderComponent(ReorderWidget, props);

    it('should display placeholder text', async () => {
      await waitFor(() => {
        const noItemsPlaceholder = screen.getByTestId('reorder-noItems');
        expect(noItemsPlaceholder).toBeTruthy();
      });
    });

    it('should navigate when placeholder is clicked', async () => {
      await waitFor(() => {
        const noItemsPlaceholder = screen.getByTestId('reorder-noItems');
        userEvent.click(noItemsPlaceholder);
        expect(mockPush).toHaveBeenCalledWith('/order');
      });
    });

    it('should navigate when "Enter" or "Space" is pressed on placeholder', async () => {
      await waitFor(() => {
        const noItemsPlaceholder = screen.getByTestId('reorder-noItems');
        userEvent.type(noItemsPlaceholder, '{enter}');
        expect(mockPush).toHaveBeenCalledWith('/order');
      });
    });
  });

  describe('on fetching error', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      mockUseGetReorderPropositionQuery.mockReturnValue({
        data: [],
        isLoading: false,
        isError: true,
      });

      mockUseGetMenusQuery.mockReturnValue({ data: [], isLoading: false, isError: true });
    });

    renderComponent(ReorderWidget, props);

    it('should show the error message Something went wrong message', () => {
      const errorMessage = screen.getByText('Something went wrong, data not loaded');
      expect(errorMessage).toBeTruthy();
    });
  });
});
