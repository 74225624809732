const labels = {
  All: { 'Ref: stamp progress': 'התקדמות לכיוון הבול הבא', Reward: 'פרס', Rewards: 'תגמולים' },
  LoyaltyStamps: {
    'Ref: Page title': 'בולים',
    'Ref: Fulfill conditions': 'הרוויחו בולים כדי לממש תגמולים!',
    'See Reward': 'ראה תגמול',
    'Ref: No reward': 'אין תגמול לרגע',
  },
  RedeemableProducts: { 'Ref: Page title': 'תגמולים נאמנות' },
};
export default labels;
