import { FacilitiesActionType, Facility } from '../redux/actions.types';
import { FacilityData } from '../types/types';

export const defaultState = {
  data: {},
  list: {},
  viewCounts: {},
  locks: {},
};

const facilitiesReducer = (state = defaultState, action: FacilitiesActionType) => {
  const dataReduceFunc = (data: FacilityData, currentFacility: Facility) => {
    if (data && data[currentFacility.id])
      data[currentFacility.id] = {
        ...data[currentFacility.id],
        ...currentFacility,
      };
    else data[currentFacility.id] = currentFacility;

    return data;
  };

  switch (action.type) {
    case 'RESET_STATE':
      return defaultState;
    case 'FACILITIES_FETCHING_ALL':
      return {
        ...state,
        locks: {
          ...state.locks,
          getAll: true,
        },
      };
    case 'FACILITIES_FETCHED_ALL':
      return {
        ...state,
        locks: {
          ...state.locks,
          getAll: false,
        },
      };
    case 'FACILITIES_SUCCESS_ALL':
      return {
        ...state,
        data: action.facilities.reduce(dataReduceFunc, state.data),
        list: {
          ...state.list,
          [action.siteId]: action.facilities.map((facility) => facility.id),
        },
      };
    case 'FACILITIES_FETCHING_ONE':
      return {
        ...state,
        locks: {
          ...state.locks,
          getOne: true,
        },
      };
    case 'FACILITIES_FETCHED_ONE':
      return {
        ...state,
        locks: {
          ...state.locks,
          getOne: false,
        },
      };
    case 'FACILITIES_SUCCESS_ONE':
      return {
        ...state,
        data: {
          ...state.data,
          [action.id]: action.item,
        },
      };
    default:
      return state;
  }
};

export default facilitiesReducer;
