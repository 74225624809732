import biteApi from '../../../services/api/biteApi';
import mockResponse from '../mockResponse';
import { SubmitSurvey } from '../types/reviewForm.types';
import { Survey } from '../types/reviewState.types';

import { GetSurveyReviewQuestionsArgs } from './api.types';

const reviewApi = biteApi
  .enhanceEndpoints({ addTagTypes: ['reviewFacilitites', 'reviewQuestions'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getMappedFacilities: build.query<string[], string>({
        query: (siteId: string) => ({
          url: '/v1/locations/facilities',
          method: 'get',
          params: { siteId: siteId },
          mockData: mockResponse.getMappedFacilities,
        }),
        providesTags: ['reviewFacilitites'],
      }),
      getSurveyReviewQuestions: build.query<Survey, GetSurveyReviewQuestionsArgs>({
        query: ({ siteId, surveyType }: GetSurveyReviewQuestionsArgs) => ({
          url: '/v1/locations/feedback',
          method: 'get',
          params: { siteId, surveyType },
          mockData: mockResponse.getSurveyReviewQuestions,
        }),
        providesTags: ['reviewQuestions'],
      }),
      submitSurvey: build.mutation<void, SubmitSurvey>({
        query: (survey: SubmitSurvey) => ({
          url: '/v1/locations/feedback/submit',
          method: 'post',
          data: survey,
          mockData: mockResponse.submitSurvey,
        }),
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetMappedFacilitiesQuery,
  useGetSurveyReviewQuestionsQuery,
  useSubmitSurveyMutation,
} = reviewApi;
export default reviewApi;
