const labels = {
  All: {
    'Ref: Redeem coffee description': 'La tua tazza di caffè gratuita e appena fatta è in attesa',
    'Ref: Congratulations': 'Congratulazioni',
    'Ref: Get free coffee': 'Ottieni caffè gratuito!',
    'Ref: Free coffee description': 'Riempi la tua tazza, guadagna punti per un caffè gratuito.',
    'Ref: Redeem': 'Riscattare!',
  },
  StampPage: {
    'Ref: Page title': 'Caffè gratuito',
    'Ref: seconds left': 'secondi rimasti',
    'Ref: How it works': 'Come funziona',
    'Ref: How it works description':
      'Ogni volta che acquisti un caffè, premere il pulsante di scansione e scansionare il codice QR fornito dal registratore di cassa.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Scansiona il codice QR',
    'Ref: Collect reward': 'Raccogli la ricompensa',
    'Ref: Reward collected': 'Ricompensa raccolta',
    'Ref: Invalid QR Code': 'Codice QR non valido',
    'Ref: Invalid QR Code description': 'Il codice QR non è riconosciuto',
    'Ref: Close': 'Chiudere',
    'Ref: Redeem button': 'Riscattare',
    "Ref: The QR Code isn't recognized": 'Il codice QR non è riconosciuto',
    'Ref: Cancel': 'Annulla',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Congratulazioni!',
    'Ref: Confirm redeem description':
      "Sicuro di volerlo usare? In tal caso, hai solo 60 secondi per mostrare il tuo telefono al registratore di cassa e completare l'acquisto",
    'Ref: Congratulations modal description': 'Prendi il tuo caffè gratuito adesso!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Caffè gratuito!', 'Ref: See All': 'Vedi tutto' },
};
export default labels;
