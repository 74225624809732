import { DeliveryIcon, Restaurant, ShoppingBagIcon } from '../../../assets/icons';
import { DeliveryOptionType } from '../types/cart.types';
import { FulfillmentType, PickupLocation } from '../types/orderState.types';

export const getFulFillmentTypeIcon = (deliveryOptionType: DeliveryOptionType) => {
  switch (deliveryOptionType) {
    case DeliveryOptionType.PickupOption: {
      return ShoppingBagIcon;
    }
    case DeliveryOptionType.DineIn: {
      return Restaurant;
    }
    case DeliveryOptionType.Delivery: {
      return DeliveryIcon;
    }
    default:
      return ShoppingBagIcon;
  }
};

export const getFullfilmentTypeId = (
  selectedFulfillmentType: FulfillmentType | undefined,
  table: string | undefined,
  availableFullfilmentTypes: FulfillmentType[]
) => {
  if (selectedFulfillmentType?.id) return selectedFulfillmentType.id;

  if (table) {
    const dineInFullfilment = availableFullfilmentTypes.find(
      (fullfilment) => fullfilment.type === DeliveryOptionType.DineIn
    );

    if (dineInFullfilment) return dineInFullfilment.id;
  }

  return availableFullfilmentTypes.length > 0 ? availableFullfilmentTypes[0].id : undefined;
};

export const checkIsLocationSelectionHidden = ({
  locations,
  fullFilmentType,
}: {
  locations?: PickupLocation[];
  fullFilmentType?: DeliveryOptionType;
}) => {
  return !!locations && locations.length === 1 && fullFilmentType === DeliveryOptionType.DineIn;
};
