import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import { INPUT_TYPE } from '../../../../constants';
import { useGetAccommodationSitesListQuery } from '../../api';
import ScheduleFields from '../../components/DateTimeFields';
import StepperCreate from '../../components/StepperCreate';
import { enableGuestRequestSetupOption, pagePaths } from '../../config';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';
import { AccommodationRequestFormFields } from '../../types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import FormLine from '@/components/atoms/FormLine/FormLine';
import { EmailInput, HookInput as Input } from '@/components/atoms/Input';
import { HookSelect as Select } from '@/components/atoms/Select';
import Textarea from '@/components/atoms/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import Form from '@/components/molecules/Form';
import { requiredRule } from '@/components/molecules/Form/helpers';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { SERVICE } from '@/modules/config';

import styles from './CreateForm.module.css';

const CreateForm = () => {
  const history = useHistory();
  const { label } = useAccommodationRequestTranslation(__filename);
  const historyState = history.location.state as { from?: string };
  const isBackToSummary = historyState?.from === '/accommodation_request/create-summary';
  const { data: allSites = [] } = useGetAccommodationSitesListQuery({});
  const [isGuestRequest, setIsGuestRequest] = useState<boolean>(false);
  const accommodationEnableGuestRequest = useIsSetupOptionEnabled(
    enableGuestRequestSetupOption,
    SERVICE.ACCOMMODATION_REQUEST
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    resetField,
    formState: { isValid },
  } = useFormContext<AccommodationRequestFormFields>();

  const { isGuestRequest: isFormGuestRequest } = getValues();
  useEffect(() => {
    setIsGuestRequest(isFormGuestRequest || accommodationEnableGuestRequest);
  }, [isFormGuestRequest, accommodationEnableGuestRequest]);

  const onSubmit: SubmitHandler<AccommodationRequestFormFields> = useCallback(() => {
    history.push(pagePaths.CreateSummary);
  }, [history]);

  const formHandler = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      handleSubmit(onSubmit)();
    },
    [handleSubmit, onSubmit]
  );

  const requiredGuestFirstNameFieldRule = requiredRule(label('Ref: Guest first name'), label);
  const requiredGuestLastNameFieldRule = requiredRule(label('Ref: Guest last name'), label);
  const requiredFieldRulePhoneNumber = requiredRule(label('Ref: The phone number'), label);
  requiredFieldRulePhoneNumber.validate.match = (value: string) =>
    value.match(/^[0-9+]{6,}$/) ? true : label('Ref: PhoneNumber error msg');
  const requiredFieldRuleComment = requiredRule(label('Ref: description'), label);
  requiredFieldRuleComment.validate.match = (value: string) =>
    value.length <= 250 || label('Ref: Comment error msg');

  const setGuestRequest = () => {
    setIsGuestRequest(true);
    setValue('isGuestRequest', true);
  };
  const setMyRequest = () => {
    setIsGuestRequest(false);
    setValue('isGuestRequest', false);
    resetField('guestFirstName');
    resetField('guestLastName');
  };

  return (
    <SimpleFormPage
      title={label('Ref: Describe your request')}
      data-testid="accommodation-request-create-form"
      onBackButtonClick={isBackToSummary ? undefined : reset}
    >
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              tag: TITLE_TAG.H2,
              size: TITLE_SIZE.HEADLINES,
              children: label('Ref: Your request'),
              'data-testid': 'accommodation-request-create-form-title',
            }}
            className={styles.noPadding}
          >
            {!accommodationEnableGuestRequest && (
              <>
                <Title size={TITLE_SIZE.BODYMBOLD} className={styles.noMarginTop}>
                  {label('Ref: request for')}
                </Title>
                <div className={styles.buttonWrapper}>
                  <Button
                    className={styles.buttonSpace}
                    look={isGuestRequest ? BUTTON_LOOK.SECONDARY_INLINE : BUTTON_LOOK.PRIMARY}
                    data-testid={'button-request-for-me'}
                    onClick={setMyRequest}
                  >
                    {label('Ref: request for me')}
                  </Button>
                  <Button
                    look={isGuestRequest ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE}
                    data-testid={'button-request-for-guest'}
                    onClick={setGuestRequest}
                  >
                    {label('Ref: request for guest')}
                  </Button>
                </div>
              </>
            )}
            <div className={styles.formWrapper}>
              <Form onSubmit={formHandler}>
                <FormLine data-testid="accomodation-request-site-select">
                  <Select
                    data-testid="site-select"
                    name="site"
                    required
                    control={control}
                    label={label('Ref: site')}
                    options={allSites.map((s) => ({ label: s.name, value: s.id }))}
                    disabled={accommodationEnableGuestRequest}
                  />
                </FormLine>
                {accommodationEnableGuestRequest ? (
                  <FormLine data-testid="accomodation-request-email">
                    <EmailInput
                      id="email"
                      data-testid="accomodation-request-email"
                      name="email"
                      required
                      inputLabel={label('Ref: email of guest')}
                      inputType={INPUT_TYPE.EMAIL}
                      inputmode="email"
                      autocomplete="email"
                      control={control}
                      labelFunc={label}
                    />
                  </FormLine>
                ) : (
                  <FormLine data-testid="accomodation-request-phone">
                    <Input
                      id="phoneNumber"
                      data-testid="accomodation-request-phone"
                      name="phoneNumber"
                      required
                      inputLabel={
                        isGuestRequest
                          ? label('Ref: Guest phone number')
                          : label('Ref: Your phone number')
                      }
                      inputType={INPUT_TYPE.TEL}
                      inputmode="tel"
                      autocomplete="tel"
                      maxlength={24}
                      size={24}
                      control={control}
                      rules={requiredFieldRulePhoneNumber}
                    />
                  </FormLine>
                )}

                {isGuestRequest && (
                  <FormLine data-testid="accomodation-request-guest-name">
                    <div className={styles.guestFirstNameInputWrapper}>
                      <Input
                        id="guestFirstName"
                        data-testid="accomodation-request-guest-first-name"
                        name="guestFirstName"
                        required
                        inputLabel={label('Ref: Guest first name')}
                        inputType={INPUT_TYPE.TEXT}
                        maxlength={24}
                        size={24}
                        control={control}
                        rules={requiredGuestFirstNameFieldRule}
                      />
                    </div>
                    <div className={styles.guestLastNameInputWrapper}>
                      <Input
                        id="guestLastName"
                        data-testid="accomodation-request-guest-last-name"
                        name="guestLastName"
                        required
                        inputLabel={label('Ref: Guest last name')}
                        inputType={INPUT_TYPE.TEXT}
                        maxlength={24}
                        size={24}
                        control={control}
                        rules={requiredGuestLastNameFieldRule}
                      />
                    </div>
                    <div className={styles.clear} />
                  </FormLine>
                )}
                <FormLine data-testid="accomodation-request-date">
                  <Controller
                    name="dateFields"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ScheduleFields value={value} onChange={onChange} label={label} />
                    )}
                  />
                </FormLine>
                <FormLine data-testid="accomodation-request-comment">
                  <Textarea
                    id="comment"
                    data-testid="comment"
                    name="comment"
                    label={label('Ref: description')}
                    control={control}
                    maxlength={250}
                    required
                    rules={requiredFieldRuleComment}
                  />
                </FormLine>
              </Form>
            </div>
          </Card>
        </Column.Main>
        <Column.Side>
          <StepperCreate stepIndex={0} />
        </Column.Side>
        <ActionsBar>
          <Button
            onClick={formHandler}
            data-testid="accommodation-request-create-form-next-button"
            disabled={!isValid}
            isClickDisabled
          >
            {label('Ref: Next', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default CreateForm;
