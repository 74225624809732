import { screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createBrowserHistory, createMemoryHistory } from 'history';
import moment from 'moment';
import { act } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { checkDiscountTiersStatus, setTable } from '../actions';
import * as config from '../config';
import { isDiscountTiersEnabled } from '../helpers/feature.flags.helper';
import { MenuType } from '../types/menuSelector.types';
import { ModuleContext } from '../types/order.types';

import MenuSelector from './MenuSelector';

import { renderedComponent } from '@/helpers/tests/renderComponent';
import { SERVICE } from '@/modules/config';

const menus = [
  {
    id: 3329,
    facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    name: 'Bite Everyday',
    isOrderable: true,
    logoUrl:
      'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
    date: new Date().toISOString().split('T')[0],
    isFutureOrdering: true,
  },
  {
    id: 3330,
    facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    name: 'Bite Weekends',
    isOrderable: true,
    logoUrl:
      'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
    date: new Date().toISOString().split('T')[0],
    isFutureOrdering: true,
  },
];

jest.mock('../actions', () => ({
  ...jest.requireActual('../actions'),
  getMenus: jest.fn().mockImplementation(() => Promise.resolve()),
  setSiteModalClosed: jest.fn().mockImplementation(() => ({ type: 'SET_SITE_MODAL_CLOSED' })),
  getAll: jest.fn(),
  getPreselectedFacility: jest.fn(),
  getPreselectedSite: jest.fn(),
  cleanCart: jest.fn(),
  checkDiscountTiersStatus: jest.fn(),
}));

jest.mock('../helpers/feature.flags.helper', () => ({
  isDiscountTiersEnabled: jest.fn(),
}));

jest.mock('../hooks/useModuleContext', () => ({
  __esModule: true,
  default: jest.fn(),
}));

jest.mock('../hooks/useSiteSwitch/useSwitchSite', () => ({
  useSwitchSite: () => ({
    switchSite: jest.fn(),
  }),
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: jest.fn(),
}));

// Mock useDispatch
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: jest.fn(),
}));

// history
const mockPush = jest.fn();
const mockHistory = createMemoryHistory();
mockHistory.push = mockPush;

const defaultState = {
  Core: {
    access: {
      shouldSelectSiteContext: false,
    },
    context: {
      site: {
        id: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
      },
    },
    services: {
      list: [],
      visited: [],
    },
  },
  Order: {
    menus: menus,
    discountTiersStatus: {},
    discountTiers: {},
    locks: {
      getMenusForFacility: false,
      getAllergens: false,
    },
    hasSiteChanged: false,
    hasSiteChangeModalClosed: false,
    menusType: 1,
    errors: {
      fetchingSiteId: false,
    },
    hasMenuFetched: true,
  },
  Facilities: {
    data: {
      '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea': {
        title: 'Mess Menu Facility',
        name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston',
        id: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
      },
      '5399e8af-c9ba-4b52-9f9a-3a4499a8948f': {
        title: 'Mess Menu Facility 2',
        name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston 2',
        id: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
      },
    },
    list: {
      '5a8da332-1237-e811-a95b-000d3a2bc5c1': [
        '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
      ],
    },
  },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
  },
};

describe('MenuSelector', () => {
  beforeEach(() => {
    jest.resetAllMocks();
    jest.clearAllMocks();

    const mockDispatch = jest.fn();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);
    (useParams as jest.Mock).mockImplementation(() => ({
      outletId: undefined,
      locationId: undefined,
    }));
  });

  it('renders without crashing', () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);
    const { container } = renderedComponent(MenuSelector, {}, defaultState, createBrowserHistory());

    expect(container).toBeTruthy();
  });

  it('the user should be able to view a list of menus based on the selected site and date', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);
    renderedComponent(MenuSelector, {}, defaultState, createBrowserHistory());

    await waitFor(() => {
      expect(screen.getByText('Bite Everyday')).toBeInTheDocument();
    });
  });

  it('the user should be able to view a list of menus based on the selected site and date - only menus from food facilities', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3330,
            facilityId: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
            name: 'Non food facility menu',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
      Facilities: {
        data: {
          '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea': {
            title: 'Mess Menu Facility',
            name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston',
            id: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            isFoodFacilityType: true,
            facilityType: {
              id: '555580001',
              name: 'Food - Retail',
            },
          },
          '5399e8af-c9ba-4b52-9f9a-3a4499a8948f': {
            title: 'Mess Menu Facility 2',
            name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston 2',
            id: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
            isFoodFacilityType: false,
            facilityType: {
              id: '555580002',
              name: 'Non-food - Non-retail',
            },
          },
        },
        list: {
          '5a8da332-1237-e811-a95b-000d3a2bc5c1': [
            '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
          ],
        },
      },
    };

    renderedComponent(MenuSelector, {}, state, createBrowserHistory());

    await waitFor(() => {
      expect(screen.getByText('Bite Everyday')).toBeInTheDocument();
      expect(screen.queryByText('Non food facility menu')).not.toBeInTheDocument();
    });
  });

  it('if there is only one menu available and its not for future ordering, the user should be automatically redirected to that menu', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    jest.spyOn(config, 'orderConfig').mockImplementation(() => ({
      isDateFilteringEnabled: true,
      isMenuRedirectionEnabled: true,
      isNavbarEnabled: true,
      isResetSessionEnabled: false,
      isCartDayPartLabelShown: true,
      isCartPhoneInputEnabled: true,
      isCartDeliveryEnabled: true,
      isCartPaymentFormEnabled: true,
      isCartButtonValidateLabelEnabled: false,
      areSuggestionsEnabled: true,
      isTranslationWidgetShownInTitleBar: false,
      isResetSessionWidgetShownInTitleBar: false,
      isKioskTitleBar: false,
      arePromotionsEnabled: true,
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const todaysDate = new Date().toISOString().split('T')[0];
    const formattedDate = todaysDate.split('-').join('-');

    await waitFor(() => {
      expect(mockPush).toHaveBeenCalledWith(
        `/order/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${formattedDate}/products`
      );
    });
  });

  it('if there is only one menu available and it has future ordering, the user should not be automatically redirected to that menu', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    jest.spyOn(config, 'orderConfig').mockImplementation(() => ({
      isDateFilteringEnabled: true,
      isMenuRedirectionEnabled: true,
      isNavbarEnabled: true,
      isResetSessionEnabled: false,
      isCartDayPartLabelShown: true,
      isCartPhoneInputEnabled: true,
      isCartDeliveryEnabled: true,
      isCartPaymentFormEnabled: true,
      isCartButtonValidateLabelEnabled: false,
      areSuggestionsEnabled: true,
      isTranslationWidgetShownInTitleBar: false,
      isResetSessionWidgetShownInTitleBar: false,
      isKioskTitleBar: false,
      arePromotionsEnabled: true,
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: true,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const todaysDate = new Date().toISOString().split('T')[0];
    const formattedDate = todaysDate.split('-').join('-');

    await waitFor(() => {
      expect(mockPush).not.toHaveBeenCalledWith(
        `/order/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${formattedDate}/products`
      );
    });
  });

  it('if there is only one menu available and its not for future ordering, but redirection is disabled then user should not be automatically redirected to that menu', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    jest.spyOn(config, 'orderConfig').mockImplementation(() => ({
      isDateFilteringEnabled: true,
      isMenuRedirectionEnabled: false,
      isNavbarEnabled: true,
      isResetSessionEnabled: false,
      isCartDayPartLabelShown: true,
      isCartPhoneInputEnabled: true,
      isCartDeliveryEnabled: true,
      isCartPaymentFormEnabled: true,
      isCartButtonValidateLabelEnabled: false,
      areSuggestionsEnabled: true,
      isTranslationWidgetShownInTitleBar: false,
      isResetSessionWidgetShownInTitleBar: false,
      isKioskTitleBar: false,
      arePromotionsEnabled: true,
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const todaysDate = new Date().toISOString().split('T')[0];
    const formattedDate = todaysDate.split('-').join('-');

    await waitFor(() => {
      expect(mockPush).not.toHaveBeenCalledWith(
        `/order/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${formattedDate}/products`
      );
    });
  });

  it('if there is only one menu available and it has not future orderding and menu is viewable then user should be redirected to viewable product list', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.MENU]);

    jest.spyOn(config, 'orderConfig').mockImplementation(() => ({
      isDateFilteringEnabled: true,
      isMenuRedirectionEnabled: true,
      isNavbarEnabled: true,
      isResetSessionEnabled: false,
      isCartDayPartLabelShown: true,
      isCartPhoneInputEnabled: true,
      isCartDeliveryEnabled: true,
      isCartPaymentFormEnabled: true,
      isCartButtonValidateLabelEnabled: false,
      areSuggestionsEnabled: true,
      isTranslationWidgetShownInTitleBar: false,
      isResetSessionWidgetShownInTitleBar: false,
      isKioskTitleBar: false,
      arePromotionsEnabled: true,
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: false,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const todaysDate = new Date().toISOString().split('T')[0];
    const formattedDate = todaysDate.split('-').join('-');

    await waitFor(() => {
      expect(mockPush).toHaveBeenCalledWith(
        `/menu/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${formattedDate}/products`
      );
    });
  });

  it('if there is predefined outlet and site then user should be able to see menus only for predefined facility', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);
    (useParams as jest.Mock).mockImplementation(() => ({
      outletId: '22',
      locationId: undefined,
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        preselectedFacilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3331,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday 2',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3330,
            facilityId: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
            name: 'Bite From Another Facility',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, createBrowserHistory());

    await waitFor(() => {
      expect(screen.getByText('Bite Everyday')).toBeInTheDocument();
      expect(screen.queryByText('Bite From Another Facility')).not.toBeInTheDocument();
    });
  });

  it('if there is predefined location then site should change for user - should fetch relevant preselected site for given location id', async () => {
    const getPreselectedSiteSpy = jest.spyOn(require('../actions'), 'getPreselectedSite');
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    (useParams as jest.Mock).mockImplementation(() => ({
      locationId: '22',
      outletId: undefined,
    }));

    const state = {
      ...defaultState,
      Order: { ...defaultState.Order, preselectedSiteId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea' },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    await waitFor(() => {
      expect(getPreselectedSiteSpy).toHaveBeenCalledWith({ locationId: '22' });
    });
  });

  it('if there is predefined location then site should change for user - should switch site when side id is already known from location id', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const switchSiteSpy = jest.spyOn(
      require('../hooks/useSiteSwitch/useSwitchSite'),
      'useSwitchSite'
    );

    (useParams as jest.Mock).mockImplementation(() => ({
      locationId: '22',
      outletId: undefined,
    }));

    const state = {
      ...defaultState,
      Order: { ...defaultState.Order, preselectedSiteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1' },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    await waitFor(() => {
      expect(switchSiteSpy).toHaveBeenCalled();
    });
  });

  it('in Menu module user should be able to see only viewable menus', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.MENU]);

    const state = {
      ...defaultState,
      Core: {
        ...defaultState.Core,
        services: {
          list: [
            {
              id: '20a9295f-502b-ec11-b76a-0003ff4ccf41',
              name: SERVICE.FOOD_ORDER,
              navigation: {
                imageId: null,
                imageName: null,
                name: 'AppContent',
                isEnabled: true,
                orderIndex: 3,
              },
              setupOptions: [],
              contentPageId: null,
            },
            {
              id: '20a9295f-502b-ec11-b76a-0003ff4ccf44',
              name: SERVICE.FOOD_MENU,
              navigation: {
                imageId: null,
                imageName: null,
                name: 'AppContent',
                isEnabled: true,
                orderIndex: 3,
              },
              setupOptions: [],
              contentPageId: null,
            },
          ],
        },
      },
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3339,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Readonly menu',
            isOrderable: false,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(
      MenuSelector,
      { menuType: MenuType.NonOrderable },
      state,
      mockHistory || createBrowserHistory()
    );

    await waitFor(() => {
      expect(screen.getByText('Readonly menu')).toBeInTheDocument();
      expect(screen.queryByText('Bite Everyday')).not.toBeInTheDocument();
    });
  });

  it('in Order module user should be able to see only orderable menus', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Core: {
        ...defaultState.Core,
        services: {
          list: [
            {
              id: '20a9295f-502b-ec11-b76a-0003ff4ccf41',
              name: SERVICE.FOOD_ORDER,
              navigation: {
                imageId: null,
                imageName: null,
                name: 'AppContent',
                isEnabled: true,
                orderIndex: 3,
              },
              setupOptions: [],
              contentPageId: null,
            },
            {
              id: '20a9295f-502b-ec11-b76a-0003ff4ccf44',
              name: SERVICE.FOOD_MENU,
              navigation: {
                imageId: null,
                imageName: null,
                name: 'AppContent',
                isEnabled: true,
                orderIndex: 3,
              },
              setupOptions: [],
              contentPageId: null,
            },
          ],
        },
      },
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3339,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Readonly menu',
            isOrderable: false,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    await waitFor(() => {
      expect(screen.queryByText('Readonly menu')).not.toBeInTheDocument();
      expect(screen.queryByText('Bite Everyday')).toBeInTheDocument();
    });
  });

  it('if only Order module available then user should be able to see both orderable and nonorderable menus', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Core: {
        ...defaultState.Core,
        services: {
          list: [
            {
              id: '20a9295f-502b-ec11-b76a-0003ff4ccf41',
              name: SERVICE.FOOD_ORDER,
              navigation: {
                imageId: null,
                imageName: null,
                name: 'AppContent',
                isEnabled: true,
                orderIndex: 3,
              },
              setupOptions: [],
              contentPageId: null,
            },
          ],
        },
      },
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3339,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Readonly menu',
            isOrderable: false,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    await waitFor(() => {
      expect(screen.queryByText('Readonly menu')).toBeInTheDocument();
      expect(screen.queryByText('Bite Everyday')).toBeInTheDocument();
    });
  });

  it('when bardcode and menuId is provided in params - should redirect for scan', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    (useParams as jest.Mock).mockImplementation(() => ({
      barcode: '2222',
      menuId: 3329,
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: true,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const todaysDate = new Date().toISOString().split('T')[0];
    const formattedDate = todaysDate.split('-').join('-');

    await waitFor(() => {
      expect(mockPush).toHaveBeenCalledWith(
        `/order/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${formattedDate}/2222/products`
      );
    });
  });

  it('when bardcode is not provided in params - should not redirect for scan', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    (useParams as jest.Mock).mockImplementation(() => ({
      menuId: 3329,
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: true,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const todaysDate = new Date().toISOString().split('T')[0];
    const formattedDate = todaysDate.split('-').join('-');

    await waitFor(() => {
      expect(mockPush).not.toHaveBeenCalledWith(
        `/order/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${formattedDate}/2222/products`
      );
    });
  });

  it('when menudId is not provided in params - should not redirect for scan', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    (useParams as jest.Mock).mockImplementation(() => ({
      barcode: '2222',
    }));

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: true,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const todaysDate = new Date().toISOString().split('T')[0];
    const formattedDate = todaysDate.split('-').join('-');

    await waitFor(() => {
      expect(mockPush).not.toHaveBeenCalledWith(
        `/order/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${formattedDate}/2222/products`
      );
    });
  });

  it('user should be able to search for menus by the facility title.', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3340,
            facilityId: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
            name: 'Different menu',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    await act(async () => {
      renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());
    });
    const input = await screen.findByTestId(
      'menu-selector-list-search-bar-list-page-search-filter-bar-input-field'
    );

    await act(async () => {
      userEvent.clear(input);
      await userEvent.type(input, 'Mess Menu Facility 2', { delay: 1 });
    });

    await waitFor(() => {
      expect(screen.queryByText('Bite Everyday')).not.toBeInTheDocument();
      expect(screen.queryByText('Different menu')).toBeInTheDocument();
    });
  });

  it('user should be able to search for menus by the facility title - none of menus displayed when non-existing facility name typed', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Bite Everyday',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3340,
            facilityId: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
            name: 'Different menu',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    await act(async () => {
      renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());
    });
    const input = await screen.findByTestId(
      'menu-selector-list-search-bar-list-page-search-filter-bar-input-field'
    );

    await act(async () => {
      userEvent.clear(input);
      await userEvent.type(input, 'Mess Menu Facility 3', { delay: 1 });
    });

    await waitFor(() => {
      expect(screen.queryByText('Bite Everyday')).not.toBeInTheDocument();
      expect(screen.queryByText('Different menu')).not.toBeInTheDocument();
    });
  });

  it('user should be able to search for menus by the selecting different dates - when no other date selected then should show todays menus', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Today menu',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3340,
            facilityId: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
            name: 'Menu not for today',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .split('T')[0],
            isFutureOrdering: true,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    await waitFor(() => {
      expect(screen.queryByText('Menu not for today')).not.toBeInTheDocument();
      expect(screen.queryByText('Today menu')).toBeInTheDocument();
    });
  });

  it('user should be able to search for menus by the selecting different dates - when selected tomorrow date', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Today menu',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
          {
            id: 3340,
            facilityId: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
            name: 'Menu not for today',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date(new Date().setDate(new Date().getDate() + 1))
              .toISOString()
              .split('T')[0],
            isFutureOrdering: true,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    const tomorrowButton = await screen.findByText('Tomorrow');
    await userEvent.click(tomorrowButton);

    await waitFor(() => {
      expect(screen.queryByText('Menu not for today')).toBeInTheDocument();
      expect(screen.queryByText('Today menu')).not.toBeInTheDocument();
    });
  });

  it('discount tier modal status should be checked when feature option is enabled and user must set pricing tier', async () => {
    jest.mock('react-redux', () => {
      const originalModule = jest.requireActual('react-redux');
      return {
        ...originalModule,
        useDispatch: jest.fn(),
      };
    });

    const mockDispatch = jest.fn();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);

    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        discountTiersStatus: {
          mustSetPricingTier: true,
        },
      },
    };

    (isDiscountTiersEnabled as jest.Mock).mockReturnValue(true);

    jest.mock('../actions', () => ({
      ...jest.requireActual('../actions'),
      checkDiscountTiersStatus: jest.fn().mockReturnValue(() =>
        Promise.resolve({
          responseData: {
            mustSetPricingTier: true,
          },
        })
      ),
    }));

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    await waitFor(() => {
      expect(checkDiscountTiersStatus).toHaveBeenCalledWith({
        siteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
      });
    });
  });

  it('discount tier modal status should not be checked when feature option is disabled and user must set pricing tier', async () => {
    jest.mock('react-redux', () => {
      const originalModule = jest.requireActual('react-redux');
      return {
        ...originalModule,
        useDispatch: jest.fn(),
      };
    });

    const mockDispatch = jest.fn();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);

    (useParams as jest.Mock).mockImplementation(() => ({
      outletId: undefined,
      locactionId: undefined,
    }));

    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        discountTiersStatus: {
          mustSetPricingTier: true,
        },
      },
    };

    (isDiscountTiersEnabled as jest.Mock).mockReturnValue(false);

    jest.mock('../actions', () => ({
      ...jest.requireActual('../actions'),
      checkDiscountTiersStatus: jest.fn().mockReturnValue(() =>
        Promise.resolve({
          responseData: {
            mustSetPricingTier: true,
          },
        })
      ),
    }));

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    expect(checkDiscountTiersStatus).not.toHaveBeenCalled();
  });

  it('table should be reset after one hour of table scan - 2 hours after the table qr scan time', async () => {
    require('../hooks/useModuleContext').default.mockImplementation(() => [ModuleContext.ORDER]);
    jest.useFakeTimers();
    jest.mock('react-redux', () => {
      const originalModule = jest.requireActual('react-redux');
      return {
        ...originalModule,
        useDispatch: jest.fn(),
      };
    });

    const mockDispatch = jest.fn();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);

    // Mock the current time to be 2 hours after the tableQRScanTime
    jest.advanceTimersByTime(2 * 60 * 60 * 1000);

    const state = {
      ...defaultState,
      Order: {
        ...defaultState.Order,
        tableQRScanTime: moment().subtract(2, 'hours').valueOf(),
        menus: [
          {
            id: 3329,
            facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
            name: 'Some menu',
            isOrderable: true,
            logoUrl:
              'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
            date: new Date().toISOString().split('T')[0],
            isFutureOrdering: false,
          },
        ],
      },
    };

    renderedComponent(MenuSelector, {}, state, mockHistory || createBrowserHistory());

    // Fast-forward until all timers have been executed
    act(() => {
      jest.runAllTimers();
    });

    await waitFor(() => {
      expect(mockDispatch).toHaveBeenCalledWith(setTable());
    });

    // Clean up fake timers
    jest.useRealTimers();
  });
});

afterEach(() => {
  jest.restoreAllMocks();
  jest.resetAllMocks();
  jest.clearAllMocks();
});
