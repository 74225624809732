const labels = {
  ReviewForm: {
    'Ref: Page title': 'Revisar',
    'Ref: How was dining with us': '¿Cómo estuvo cenando con nosotros?',
    'Ref: Submit': 'Entregar',
    'Ref: Filter range': 'Rango de filtro',
    'Ref: Some information is missing': 'Falta algo de información',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Enviar anónimamente',
    'Ref: Your email address': 'Su dirección de correo electrónico',
    'Ref: Email field placeholder': 'Introduzca su dirección de correo electrónico',
    'Ref: Invalid email format': 'Formato de correo inválido',
  },
  SuccessPage: {
    'Ref: Body':
      'Gracias por completar esta revisión, su tiempo fue de gran utilidad ya que esta revisión ayudará a mejorar nuestros servicios.',
    'Ref: Go to Feedback': 'Ir a comentarios',
    'Ref: Go to home': 'Ir a la página de inicio',
    'Ref: Go to Facilities': 'Ir a las instalaciones',
    'Ref: Go to Product Details': 'Ir a los detalles del producto',
    'Ref: Go to Facility Details': 'Ir a detalles de la instalación',
  },
  FacilityPicker: {
    'Ref: Page title': 'Revisar',
    'Ref: Search facility name': 'Buscar nombre de la instalación',
  },
};
export default labels;
