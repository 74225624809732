import { useSelector } from 'react-redux';

import { useGetLastOrderForTransactionalFeedbackQuery } from '../../api';
import { useTransactionalFeedbackSetupOptions } from '../useTransactionalFeedbackSetupOptions/useTransactionalFeedbackSetupOptions';

import { State } from '@/types/state.types';

export const useTransactionalFeedback = () => {
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id ?? '');
  const enableTransactionalFeedbackFeature =
    process?.env?.REACT_APP_TRANSACTIONAL_FEEDBACK_ENABLED?.toLowerCase() === 'true';

  const { transactionalFeedbackFromEnabled, transactionalFeedbackTagsEnabled } =
    useTransactionalFeedbackSetupOptions();

  const { data: lastOrder } = useGetLastOrderForTransactionalFeedbackQuery(
    {
      siteId: siteId,
      fromDate: transactionalFeedbackFromEnabled ?? '',
    },
    { skip: !Boolean(transactionalFeedbackFromEnabled) || !enableTransactionalFeedbackFeature }
  );

  return {
    lastOrder,
    shouldOpenTransactionalFeedbackModal: lastOrder ? true : false,
    transactionalFeedbackTagsEnabled,
  };
};
