import { LOCAL_STORAGE_KEYS } from '../constants';

import { Actions } from '@/modules/Core/types/actions.types';
import { SharedState } from '@/modules/Core/types/State.types';

const defaultGeographies = {
  list: [],
  defaultGeoCode: '',
};

export const defaultState = {
  geographies: defaultGeographies,
  language: {
    backLanguages: undefined,
    currentLanguageCode: 'en-US',
    isUserSelected: false,
  },
  locks: {},
};

const sharedReducer = (state: SharedState = defaultState, action: Actions) => {
  switch (action.type) {
    case 'GEOGRAPHIES_RETURNED':
      return {
        ...state,
        geographies: {
          list: action.geographiesList,
          defaultGeoCode: action.defaultGeoCode,
        },
        locks: {
          ...state.locks,
          getGeographies: false,
        },
      };
    case 'GEOGRAPHY_SET':
      const newRegionCode = state.geographies.list.find(
        (el) => el.code === action.geoCode
      )?.regionCode;
      if (newRegionCode) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE, newRegionCode);
      }

      return {
        ...state,
        geographies: {
          ...state.geographies,
          defaultGeoCode: action.geoCode,
        },
      };
    case 'APPLY_LANGUAGE':
      return {
        ...state,
        language: {
          ...state.language,
          currentLanguageCode: action.code,
          isUserSelected: action.isUserSelected,
        },
      };
    case 'RESET_LANGUAGE':
      return {
        ...state,
        language: {
          ...state.language,
          currentLanguageCode: defaultState.language.currentLanguageCode,
          isUserSelected: defaultState.language.isUserSelected,
        },
      };
    case 'USER_CONTEXT_RETURNED':
      // when language in store is not user selected -> override language with value from context
      const languageCode =
        !state.language.isUserSelected && !!action.user.preferredLanguage?.languageCode
          ? action.user.preferredLanguage.languageCode
          : state.language.currentLanguageCode;

      return {
        ...state,
        language: {
          ...state.language,
          currentLanguageCode: languageCode,
        },
      };
    default:
      return state;
  }
};

export default sharedReducer;
