import classNames from 'classnames';
import React, { useRef, useState, ChangeEvent, FocusEvent, FC } from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';

import { DEFAULT_VALUE, MAX_HOURS, MAX_MINUTES } from './contants';
import TimeInput from './TimeInput';
import { TimePickerProps } from './TimePicker.types';

import styles from './TimePicker.module.css';

const TimePicker: FC<TimePickerProps> = ({
  id,
  value,
  minTime,
  className,
  onChange,
  'data-testid': dataTestId = 'time-picker',
}) => {
  const { label } = useCustomTranslation();

  const hoursRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const minutesRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [h = DEFAULT_VALUE, m = DEFAULT_VALUE] = (value || DEFAULT_VALUE).split(':');
  const [defaultMinHours = 0, defaultMinMinutes = 0] = (minTime || DEFAULT_VALUE)
    .split(':')
    .map((n) => parseInt(n, 10));

  const [minMinutes, setMinMinutes] = useState(defaultMinMinutes);

  const valueHours = validateNumber(parseInt(h, 10), MAX_HOURS, defaultMinHours);
  const valueMinutes = validateNumber(parseInt(m, 10), MAX_MINUTES, minMinutes);

  const [hours, setHours] = useState<number>(valueHours);
  const [minutes, setMinutes] = useState<number>(valueMinutes);

  const [inputHours, setInputHours] = useState<string>(valueToString(valueHours));
  const [inputMinutes, setInputMinutes] = useState<string>(valueToString(valueMinutes));

  const handleState = (
    e: ChangeEvent<HTMLInputElement>,
    setValue: Function,
    setInputValue: Function
  ) => {
    const { value: v } = e.target;

    if (!v) {
      setInputValue('');
      setValue(0);
      return;
    }

    const numericValue = parseInt(v, 10);
    if (numericValue === 0 || numericValue) {
      setValue(numericValue);
      setInputValue(numericValue.toString());
    }
  };

  const onChangeHours = (e: ChangeEvent<HTMLInputElement>) => {
    handleState(e, setHours, setInputHours);
    if (e.target.value.length === 2) minutesRef.current.focus();
  };
  const onChangeMinutes = (e: ChangeEvent<HTMLInputElement>) => {
    handleState(e, setMinutes, setInputMinutes);
    if (e.target.value.length === 2) minutesRef.current.blur();
  };

  const onMoreHours = () => {
    const incrementedHours = hours + 1;
    const h = setValueHour(incrementedHours);
    if (defaultMinHours < incrementedHours) {
      // if incremented hour is bigger then received min hours, then set new min m value to 00 (to be able select min from 00 - 59, otherwise the default min value would stay the same, for example 30).
      setMinMinutes(0);
    }
    handleChange([h, minutes]);
  };

  const onLessHours = () => {
    const decrementedHours = hours - 1;
    const h = setValueHour(decrementedHours);
    if (defaultMinHours === decrementedHours) {
      setMinMinutes(defaultMinMinutes);
      if (Number(inputMinutes) < defaultMinMinutes) {
        // if previously set minutes are smaller then default min m, then set the value to default min m
        setInputMinutes(defaultMinMinutes.toString());
      }
    }
    handleChange([h, minutes]);
  };

  const onMoreMinutes = () => {
    const incrementedMinutes = minutes + 1;
    const m = setValueMinute(incrementedMinutes);

    handleChange([hours, m]);
  };

  const onLessMinutes = () => {
    const decrementedMinutes = minutes - 1;
    const m = setValueMinute(decrementedMinutes);

    handleChange([hours, m]);
  };

  const onFocusHours = () => setInputHours('');
  const onFocusMinutes = () => setInputMinutes('');

  const onBlurHours = (e: FocusEvent<HTMLInputElement>) => {
    const h = setValueHour(parseInt(e.target?.value || valueToString(hours), 10));
    handleChange([h, minutes]);
  };

  const onBlurMinutes = (e: FocusEvent<HTMLInputElement>) => {
    const m = setValueMinute(parseInt(e.target?.value || valueToString(minutes), 10));
    handleChange([hours, m]);
  };

  const setValueHour = (v: number) => {
    const num = validateNumber(v, MAX_HOURS, defaultMinHours);
    setHours(num);
    setInputHours(valueToString(num));

    return num;
  };

  const setValueMinute = (v: number) => {
    const num = validateNumber(v, MAX_MINUTES, minMinutes);
    setMinutes(num);
    setInputMinutes(valueToString(num));

    return num;
  };

  const handleChange = (timeValues: number[]) =>
    onChange && onChange(`${valueToString(timeValues[0])}:${valueToString(timeValues[1])}`);

  return (
    <div className={classNames(styles.wrapper, className)} data-testid={dataTestId}>
      <label htmlFor={`${id}-hours`} className={classNames(styles.srOnly)}>
        {label('Hours')}
      </label>
      <TimeInput
        id={`${id}-hours`}
        inputRef={hoursRef}
        min={defaultMinHours}
        max={MAX_HOURS}
        value={inputHours}
        onChange={onChangeHours}
        onFocus={onFocusHours}
        onBlur={onBlurHours}
        onMore={onMoreHours}
        onLess={onLessHours}
        label={label}
        data-testid={`${dataTestId}-hours`}
      />
      <div className={classNames(styles.divider)}>:</div>

      <label htmlFor={`${id}-minutes`} className={classNames(styles.srOnly)}>
        {label('Minutes')}
      </label>

      <TimeInput
        id={`${id}-minutes`}
        inputRef={minutesRef}
        min={minMinutes}
        max={MAX_MINUTES}
        value={inputMinutes}
        onChange={onChangeMinutes}
        onFocus={onFocusMinutes}
        onBlur={onBlurMinutes}
        onMore={onMoreMinutes}
        onLess={onLessMinutes}
        label={label}
        data-testid={`${dataTestId}-minutes`}
      />
    </div>
  );
};

const validateNumber = (value: number, max: number, min: number) => {
  if (value > max) return max;
  if (value < min) return min;
  return value;
};

const valueToString = (value: number) => {
  const stringValue = value.toString();
  return stringValue.length === 1 ? `0${stringValue}` : stringValue;
};

export default TimePicker;
