import { useEffect, useState } from 'react';

import { getImagesSrcFromIds } from '@/helpers/images/images.helper';
import { isValidHttpUrl } from '@/helpers/urlValidator';
import { FacilityImage } from '@/modules/Review/types/reviewFacility.types';

export const useFacilityMenuImage = (
  facilityImagesInfo: { facilityId: string; imageId?: string }[]
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [images, setImages] = useState<FacilityImage[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setIsLoading(true);
        const imageIds = facilityImagesInfo
          .filter((imageInfo) => !!imageInfo.imageId)
          .map((imageInfo) => imageInfo.imageId!);

        const images = await getImagesSrcFromIds(imageIds);
        const imagesResult = facilityImagesInfo
          .map((imageInfo) => {
            const image = images.find((img) => img.id === imageInfo.imageId);
            return {
              facilityId: imageInfo.facilityId,
              image: image?.content,
            };
          })
          .filter((imageInfo) => !!imageInfo.image)
          .map((imageInfo) => ({ key: imageInfo.facilityId, image: imageInfo.image! }));

        setImages(imagesResult);
      } finally {
        setIsLoading(false);
      }
    };

    if (!!facilityImagesInfo) {
      fetchImages();
    }
  }, [facilityImagesInfo]);

  const getMenuImage = (facilityId: string, omsMenuLogoUrl: string | undefined) => {
    if (omsMenuLogoUrl && isValidHttpUrl(omsMenuLogoUrl)) return omsMenuLogoUrl;

    return getFacilityImage(facilityId);
  };

  const getFacilityImage = (facilityId: string) => {
    const image = images.find((x) => x.key === facilityId);
    return image?.image;
  };

  return {
    isLoading,
    getMenuImage,
    getFacilityImage,
  };
};
