import { useLocation } from 'react-router';

import { SERVICE, serviceTypes } from '../../config';
import { ModuleContext } from '../types/order.types';

const useModuleContext = () => {
  const location = useLocation();

  if (
    location.pathname.toLowerCase().startsWith(serviceTypes[SERVICE.FOOD_MENU].path.toLowerCase())
  ) {
    return [ModuleContext.MENU];
  } else {
    return [ModuleContext.ORDER];
  }
};

export default useModuleContext;
