import momentjs from 'moment';
import { useEffect, useState } from 'react';

import { useGetService } from '@/helpers/hooks/useGetService/useGetService';
import { SERVICE } from '@/modules/config';
import { useLazyGetMenusQuery } from '@/modules/Order/api';
import { MenuType } from '@/modules/Order/types/menuSelector.types';
import { FacilityMenu } from '@/modules/Order/types/orderState.types';

export const useGetMenusForSearch = (siteId: string | undefined) => {
  const [getMenus, { isLoading }] = useLazyGetMenusQuery();

  const menuService = useGetService(SERVICE.FOOD_MENU);
  const orderService = useGetService(SERVICE.FOOD_ORDER);

  const [menus, setMenus] = useState<FacilityMenu[]>([]);

  useEffect(() => {
    const loadData = async () => {
      if (menuService || orderService) {
        const menuDate = momentjs().format('YYYY-MM-DD');
        const { data } = await getMenus({
          siteId: siteId!,
          date: menuDate,
          useCache: true,
          menuType: MenuType.All,
          useErrorBoundary: false,
        });

        setMenus(data ?? []);
      }
    };

    if (siteId) loadData();
  }, [siteId, menuService, orderService, getMenus]);

  return {
    menus,
    isLoading,
  };
};
