const labels = {
  All: {
    'Ref: Redeem coffee description': '您免費的，新鮮釀造的咖啡正在等待',
    'Ref: Congratulations': '恭喜',
    'Ref: Get free coffee': '獲得免費咖啡！',
    'Ref: Free coffee description': '裝滿您的杯子，賺取積分以享用免費咖啡。',
    'Ref: Redeem': '兌換！',
  },
  StampPage: {
    'Ref: Page title': '免費咖啡',
    'Ref: seconds left': '剩下幾秒鐘',
    'Ref: How it works': '這個怎麼運作',
    'Ref: How it works description': '每次購買咖啡時，請按掃描按鈕並在收銀機上掃描提供的QR碼。',
  },
  StampActionButton: {
    'Ref: Scan QR Code': '掃描二維碼',
    'Ref: Collect reward': '收集獎勵',
    'Ref: Reward collected': '收集的獎勵',
    'Ref: Invalid QR Code': '無效的QR碼',
    'Ref: Invalid QR Code description': 'QR碼未識別',
    'Ref: Close': '關閉',
    'Ref: Redeem button': '兌換',
    "Ref: The QR Code isn't recognized": 'QR碼未識別',
    'Ref: Cancel': '取消',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': '恭喜！',
    'Ref: Confirm redeem description':
      '確定要使用這個？如果是這樣，您只有60秒鐘可以在收銀機上顯示手機並完成購買',
    'Ref: Congratulations modal description': '立即獲取您的免費咖啡！',
  },
  Widget: { 'Ref: Free Coffee widget title': '免費咖啡！', 'Ref: See All': '查看全部' },
};
export default labels;
