import { cleanup /*, screen,   fireEvent */ } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { pagePaths } from '../../config';

import SharePage from './SharePage';

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => {
    return jest.fn(() => ({
      responseStatus: 200,
      responseData: { site: { id: 'some-id', name: 'Site name' } },
    }));
  },
}));

describe('SharePage', () => {
  afterEach(() => cleanup());

  describe('on render with not logged in user', () => {
    const history = createMemoryHistory();
    renderComponent(SharePage, {}, {}, history);
    it('Redirect to login if user is not authenticated', () => {
      expect(history.location.pathname).toBe(pagePaths.Login);
    });
  });
});
