import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useGetService } from '@/helpers/hooks/useGetService/useGetService';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { SERVICE } from '@/modules/config';
import useSortedFacilityList from '@/modules/Facilities/hooks/useSortedFacilityList';
import { getAll } from '@/modules/Facilities/redux/actions';

export const useGetFacilitiesForSearch = (siteId: string | undefined) => {
  const { logError } = useUserStepsInsightsLogging();
  const facilitiesService = useGetService(SERVICE.FACILITY);
  const dispatch = useDispatch();

  const sortedFacilities = useSortedFacilityList(siteId);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (facilitiesService && sortedFacilities.length === 0) {
        try {
          setIsLoading(true);
          await dispatch(getAll({ siteId }, { useErrorBoundary: false }));
        } catch (e) {
          logError(new Error('Error when fetching facilities for site'), { siteId });
        } finally {
          setIsLoading(false);
        }
      }
    };
    if (siteId) loadData();
    // eslint-disable-next-line
  }, [siteId, facilitiesService, dispatch, logError]);

  return {
    facilities: facilitiesService ? sortedFacilities : [],
    isLoading,
  };
};
