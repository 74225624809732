const labels = {
  All: {
    'Ref: stamp progress': 'Kemajuan menuju perangko berikutnya',
    Reward: 'Hadiah',
    Rewards: 'Hadiah',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Perangko',
    'Ref: Fulfill conditions': 'Dapatkan perangko untuk menebus hadiah!',
    'See Reward': 'Lihat Hadiah',
    'Ref: No reward': 'Tidak ada hadiah untuk saat ini',
  },
  RedeemableProducts: { 'Ref: Page title': 'Hadiah Loyalitas' },
};
export default labels;
