import { IonLabel } from '@ionic/react';
import classNames from 'classnames';

import { INPUT_TYPE } from '../../../../constants';
import { useReviewTranslation } from '../../hooks/useReviewTranslation';

import { EmailAnonymousTogglerProps } from './EmailAnonymousToggler.types';

import Checkbox from '@/components/atoms/Checkbox';
import Input from '@/components/atoms/Input/Input';
import useToggle from '@/helpers/hooks/useToggle';

import styles from './EmailAnonymousToggler.module.css';

const EmailAnonymousToggler = ({
  email,
  isValidEmail,
  isAnonymous,
  profileEmail,
  onEmailChange,
  onToggleAnonymous,
}: EmailAnonymousTogglerProps) => {
  const { state: sendEmailDisable, toggle: toggleSendEmail } = useToggle(true);
  const { label } = useReviewTranslation(__filename);

  const handleToggleSendEmail = () => {
    onToggleAnonymous(!isAnonymous);
    onEmailChange(!sendEmailDisable ? '' : profileEmail);

    toggleSendEmail();
  };

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.checkboxWrapper)}>
        <Checkbox
          checked={sendEmailDisable}
          onChange={handleToggleSendEmail}
          data-testid="review-disable-send-email"
        />
        <IonLabel className={classNames(styles.label)} onClick={handleToggleSendEmail}>
          {label('Ref: Send anonymously')}
        </IonLabel>
      </div>
      <div className={classNames({ [styles.hideEmail]: sendEmailDisable })}>
        <Input
          inputLabel={label('Ref: Your email address')}
          data-cy="input-email"
          data-testid="review-anonymous-email"
          id="email"
          inputType={INPUT_TYPE.EMAIL}
          placeholder={label('Ref: Email field placeholder')}
          value={email}
          hasError={!isValidEmail}
          onInputChange={(val) => onEmailChange(val || '')}
          {...(!isValidEmail && { inputMsg: label('Ref: Invalid email format') })}
        />
      </div>
    </div>
  );
};

export default EmailAnonymousToggler;
