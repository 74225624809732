import { useSelector } from 'react-redux';

import { TrophyIcon, WarningOutlinedIcon } from '../../../../assets/icons';
import { StampsIllustration } from '../../../../assets/illustrations';
import { TileSkeleton } from '../../../../components/molecules/Tile';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import TilesList from '../../../../components/organisms/TilesList/TilesList';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { StateWithOrder } from '../../../Order/types/orderState.types';
import { useGetLoyaltySchemesQuery, useGetUserLoyaltySchemesProgressQuery } from '../../api';
import {
  GetLoyaltySchemesResponse,
  GetUserLoyaltySchemesProgressResponse,
} from '../../api/api.types';
import StampTile from '../../components/StampTile/StampTile';
import { StampPerUser } from '../../components/StampTile/StampTile.types';
import {
  getActualStamps,
  getNumberOfPotentiallyUsedStamps,
  getRewards,
} from '../../helpers/loyaltyStamps.helper';
import { useLoyaltyStampsTranslation } from '../../hooks/useLoyaltyStampsTranslation';

const LoyaltyStamps = () => {
  const { label } = useLoyaltyStampsTranslation(__filename);
  const stateWithCartAndSite = useSelector((state: StateWithOrder) => state);
  const cart = stateWithCartAndSite?.Order?.cart;
  const siteId = stateWithCartAndSite?.Core?.context?.site?.id || '';
  const { data: stamps, isLoading: isLoadingStamps } = useGetLoyaltySchemesQuery({
    siteId,
    useErrorBoundary: false,
  });
  const { data: userProgress, isLoading: isLoadingUserProgress } =
    useGetUserLoyaltySchemesProgressQuery({ siteId, useErrorBoundary: false });

  const getStampItems = (
    stamps: GetLoyaltySchemesResponse[],
    userProgress: GetUserLoyaltySchemesProgressResponse[]
  ) => {
    return stamps.map((stamp) => {
      const progressPerStamp = userProgress.find((scheme) => scheme.loyaltySchemeId === stamp.id);

      const usedStamps =
        cart?.menuPortionItems && progressPerStamp?.stampsCollected
          ? getNumberOfPotentiallyUsedStamps(
              cart?.menuPortionItems,
              stamp,
              progressPerStamp,
              cart.menuId
            )
          : 0;

      const stampsActual = progressPerStamp
        ? getActualStamps(progressPerStamp?.stampsCollected, stamp.stampsRequiredForRedeem)
        : 0;

      const rewards = progressPerStamp
        ? getRewards(
            progressPerStamp?.stampsCollected,
            usedStamps,
            stamp.stampsRequiredForRedeem,
            stampsActual
          )
        : 0;

      return {
        id: stamp.id,
        name: stamp.name,
        description: stamp.description,
        image: stamp.imageUrl,
        stampsRequired: stamp.stampsRequiredForRedeem,
        stampsActual: stampsActual,
        rewards: rewards,
        'data-testid': `stamp-${stamp.id}`,
      };
    });
  };

  const content =
    stamps && stamps.length > 0 && userProgress ? (
      <>
        <div>{label('Ref: Fulfill conditions')}</div>
        <TilesList
          items={getStampItems(stamps, userProgress)}
          itemRenderer={(item: StampPerUser) => {
            return <StampTile label={label} {...item} />;
          }}
        />
      </>
    ) : (
      <WidgetPlaceholder
        icon={stamps?.length === 0 ? <TrophyIcon /> : <WarningOutlinedIcon />}
        title={label(
          stamps?.length === 0 ? 'Ref: No reward' : 'Something went wrong, data not loaded'
        )}
      />
    );
  return (
    <SimpleFormPage title={label('Ref: Page title')} hasBackLink={false}>
      <Container>
        <Column.Main>
          {isLoadingStamps || isLoadingUserProgress ? (
            <>
              {Array.apply(null, Array(3)).map((_, i) => (
                <TileSkeleton
                  key={i}
                  withoutActions
                  withoutChildren={false}
                  className={'mb-M'}
                  data-testid={`skeleton-placeholder-${i}`}
                />
              ))}
            </>
          ) : (
            content
          )}
        </Column.Main>
        <Column.Complementary>
          <StampsIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default LoyaltyStamps;
