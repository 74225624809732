import { screen } from '@testing-library/react';

import { MenuSearchResults } from './MenuSearchResults';

import renderComponent from '@/helpers/tests/renderComponent';
import { defaultFacilityMenu } from '@/modules/Order/__mocks/mock';

const menuDate = '2023-10-19';
const menu1FacilityId = 'facilityId1';
const menu1LogoUrl = 'menu1LogoUrl';
const menu2FacilityId = 'facilityId2';
const menu2LogoUrl = 'menu1LogoUrl';
const menus = [
  {
    ...defaultFacilityMenu,
    id: 11,
    facilityId: menu1FacilityId,
    logoUrl: menu1LogoUrl,
  },
  {
    ...defaultFacilityMenu,
    id: 12,
    facilityId: menu2FacilityId,
    logoUrl: menu2LogoUrl,
  },
];

const facilitiesMap: Record<string, any> = {};
facilitiesMap[menu1FacilityId] = {
  id: menu1FacilityId,
  images: [],
};
facilitiesMap[menu2FacilityId] = {
  id: menu2FacilityId,
  images: [],
};

const mockSelectorFunction = jest.fn();
jest.mock('react-redux', () => {
  const actual = jest.requireActual('react-redux');
  return {
    ...actual,
    useSelector: () => {
      return mockSelectorFunction();
    },
  };
});

const mockGetMenuImage = jest.fn();
const mockIsLoading = jest.fn();
jest.mock('@/modules/Order/hooks/useFacilityMenuImage/useFacilityMenuImage', () => ({
  useFacilityMenuImage: () => ({ getMenuImage: mockGetMenuImage, isLoading: mockIsLoading() }),
}));

describe('MenuSearchResults component', () => {
  describe('when no menus', () => {
    renderComponent(MenuSearchResults, {
      menuDate,
      menus: [],
    });

    it('should render empty content', () => {
      const tiles = screen.queryAllByTestId(/\d+/);
      expect(tiles.length).toBe(0);
    });
  });

  describe('when loading', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(facilitiesMap);
      mockIsLoading.mockReturnValue(true);
    });

    renderComponent(MenuSearchResults, {
      menuDate,
      menus: [],
    });

    it('should render 3 tile skeletons', () => {
      const tileSkeletons = screen.queryAllByTestId(/skeleton-placeholder-\d+/);
      expect(tileSkeletons.length).toBe(3);
    });
  });

  describe('with menus', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(facilitiesMap);
      mockGetMenuImage.mockReturnValue('imageSource123');
    });

    renderComponent(MenuSearchResults, {
      menuDate,
      menus,
    });

    it('should render menu tiles', () => {
      const tiles = screen.queryAllByTestId(/\d+/);
      expect(tiles.length).toBe(2);

      expect(mockGetMenuImage).toHaveBeenCalledTimes(2);
      expect(mockGetMenuImage.mock.calls[0][0]).toBe(menu1FacilityId);
      expect(mockGetMenuImage.mock.calls[0][1]).toBe(menu1LogoUrl);
      expect(mockGetMenuImage.mock.calls[1][0]).toBe(menu2FacilityId);
      expect(mockGetMenuImage.mock.calls[1][1]).toBe(menu2LogoUrl);
    });
  });
});
