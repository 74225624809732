import { Dispatch, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useToggle from '../../../helpers/hooks/useToggle';
import { PromiseResultWithData } from '../../../types';
import { checkDiscountTiersStatus, getDiscountTiers } from '../actions';
import {
isDiscountTiersEnabled
} from '../helpers/feature.flags.helper';
import {
  DiscountTiersModel,
  DiscountTiersStatus,
  MenuSelectorAction,
  MenuSelectorActionType,
} from '../types/menuSelector.types';


const useDiscountTiersModal = ({
  siteId,
  localDispatch,
  postSelectionAction,
}: {
  siteId?: string;
  localDispatch: Dispatch<MenuSelectorAction>;
  postSelectionAction: () => void | Promise<any>;
}) => {
  const {
    state: isTiersModalOpen,
    toggleOn: openTiersModal,
    toggleOff: closeTiersModal,
  } = useToggle(false);

  const isUsingDiscountTiers = isDiscountTiersEnabled();
  const dispatch = useDispatch();

  const checkTiersStatus = useCallback(async () => {
    const result = (await dispatch(checkDiscountTiersStatus({ siteId }))) as PromiseResultWithData<
      DiscountTiersStatus | undefined
    >;

    return result?.responseData?.mustSetPricingTier;
  }, [dispatch, siteId]);

  const pickDiscountTier = useCallback(async () => {
    const result = (await dispatch(getDiscountTiers({ siteId }))) as PromiseResultWithData<
      DiscountTiersModel | undefined
    >;

    const pricingTiers = result?.responseData?.pricingTiers;

    if (!!pricingTiers && pricingTiers.length > 0) {
      localDispatch({
        type: MenuSelectorActionType.UPDATE_SET_DISCOUNT_TIER_STATUS,
        setDiscountTierStatus: true,
      });
      localDispatch({ type: MenuSelectorActionType.UPDATE_TIER, tier: pricingTiers[0].id });
      openTiersModal();
    }
  }, [dispatch, localDispatch, siteId, openTiersModal]);

  useEffect(() => {
    const handleTierAndMenuFetch = async () => {
      if (!siteId) return;

      if (isUsingDiscountTiers) {
        const hasDiscountTiers = await checkTiersStatus();

        if (hasDiscountTiers) {
          await pickDiscountTier();
        }
      }

      postSelectionAction();
    };

    handleTierAndMenuFetch();
  }, [checkTiersStatus, isUsingDiscountTiers, pickDiscountTier, postSelectionAction, siteId]);

  return { isTiersModalOpen, closeTiersModal };
};

export default useDiscountTiersModal;
