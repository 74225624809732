const labels = {
  All: { 'Ref: stamp progress': 'Framsteg mot nästa stämpel', Reward: 'Pris', Rewards: 'Belöning' },
  LoyaltyStamps: {
    'Ref: Page title': 'Stämplar',
    'Ref: Fulfill conditions': 'Tjäna frimärken för att lösa in belöningar!',
    'See Reward': 'Se belöning',
    'Ref: No reward': 'Ingen belöning för tillfället',
  },
  RedeemableProducts: { 'Ref: Page title': 'Lojalitetsbelöningar' },
};
export default labels;
