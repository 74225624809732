import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { pagePaths } from '../../config';
import { StateWithOrder } from '../../types/orderState.types';

const useCartInfo = () => {
  const stateWithCartAndSite = useSelector((state: StateWithOrder) => state);
  const cart = stateWithCartAndSite?.Order?.cart;
  const siteId = stateWithCartAndSite?.Core?.context?.site?.id;

  return useMemo(() => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (cart?.siteId !== siteId || (cart?.date && cart?.date < today))
      return { linkTo: pagePaths.Cart };

    const itemsCount = cart?.menuPortionItems?.reduce((result, item) => {
      return result + item.quantity;
    }, 0);

    return {
      itemsCount,
      linkTo: pagePaths.Cart,
    };
  }, [cart, siteId]);
};

export default useCartInfo;
