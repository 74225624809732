const labels = {
  ReviewForm: {
    'Ref: Page title': 'Beoordeling',
    'Ref: How was dining with us': 'Hoe was het dineren bij ons?',
    'Ref: Submit': 'Indienen',
    'Ref: Filter range': 'Filterbereik',
    'Ref: Some information is missing': 'Er ontbreekt enkele informatie',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Stuur anoniem',
    'Ref: Your email address': 'Uw e-mailadres',
    'Ref: Email field placeholder': 'Vul uw e-mailadres in',
    'Ref: Invalid email format': 'ongeldig email formaat',
  },
  SuccessPage: {
    'Ref: Body':
      'Bedankt voor het voltooien van deze review, uw tijd is waardevol, omdat deze beoordeling onze diensten zal helpen verbeteren.',
    'Ref: Go to Feedback': 'Ga naar feedback',
    'Ref: Go to home': 'Ga naar startpagina',
    'Ref: Go to Facilities': 'Ga naar diensten',
    'Ref: Go to Product Details': 'Ga naar productdetails',
    'Ref: Go to Facility Details': 'Ga naar faciliteitsdetails',
  },
  FacilityPicker: {
    'Ref: Page title': 'Beoordeling',
    'Ref: Search facility name': 'Naam van zoekfaciliteiten',
  },
};
export default labels;
