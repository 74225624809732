const labels = {
  All: {
    'Ref: Redeem coffee description': 'Votre tasse de café gratuite et fraîchement brutait',
    'Ref: Congratulations': 'Toutes nos félicitations',
    'Ref: Get free coffee': 'Obtenez un café gratuit!',
    'Ref: Free coffee description':
      'Remplissez votre tasse, gagnez des points vers un café gratuit.',
    'Ref: Redeem': 'Racheter!',
  },
  StampPage: {
    'Ref: Page title': 'Café gratuit',
    'Ref: seconds left': 'secondes à gauche',
    'Ref: How it works': 'Comment ça fonctionne',
    'Ref: How it works description':
      'Chaque fois que vous achetez un café, appuyez sur le bouton de numérisation et scannez le code QR fourni à la caisse enregistreuse.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Scanner le code QR',
    'Ref: Collect reward': 'Percevoir la récompense',
    'Ref: Reward collected': 'Récompense recueillie',
    'Ref: Invalid QR Code': 'Code QR non valide',
    'Ref: Invalid QR Code description': "Le code QR n'est pas reconnu",
    'Ref: Close': 'proche',
    'Ref: Redeem button': 'Racheter',
    "Ref: The QR Code isn't recognized": "Le code QR n'est pas reconnu",
    'Ref: Cancel': 'Annuler',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Toutes nos félicitations!',
    'Ref: Confirm redeem description':
      "Bien sûr, vous souhaitez l'utiliser? Si oui, vous n'avez que 60 secondes pour montrer votre téléphone à la caisse enregistreuse et terminer votre achat",
    'Ref: Congratulations modal description': 'Prenez votre café gratuit maintenant!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Café gratuit!', 'Ref: See All': 'Voir tout' },
};
export default labels;
