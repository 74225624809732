import { FacilityDataItem } from '@/modules/Facilities/types/types';
import { FacilityMenu } from '@/modules/Order/types/orderState.types';
import { ProductListItem } from '@/modules/Order/types/productList.types';

// order of this enum set order of the displayed Tabs
export enum SearchResultType {
  Products,
  Menus,
  Facilities,
}

export type ProductSearchResult = {
  item: ProductListItem;
  menu: FacilityMenu;
};
export type SearchResult = ProductSearchResult | FacilityMenu | FacilityDataItem;

export type SearchResultContainer = {
  type: SearchResultType;
  results: SearchResult[];
};
