const labels = {
  All: {
    'Ref: stamp progress': 'Tiến tới con tem tiếp theo',
    Reward: 'Phần thưởng',
    Rewards: 'Phần thưởng',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Tem',
    'Ref: Fulfill conditions': 'Kiếm tem để đổi phần thưởng!',
    'See Reward': 'Xem phần thưởng',
    'Ref: No reward': 'Không có phần thưởng cho thời điểm này',
  },
  RedeemableProducts: { 'Ref: Page title': 'Phần thưởng trung thành' },
};
export default labels;
