import { DeliveryOptionType } from './types/cart.types';

const today = new Date();
const todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

const mockData = {
  getAllergens: {
    //to be done
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          name: 'Milk',
          allergen: 'Milk',
        },
        {
          name: 'Eggs',
          allergen: 'Eggs',
        },
        {
          name: 'Treenuts',
          allergen: 'Treenuts',
        },
        {
          name: 'Peanut',
          allergen: 'Peanut',
        },
        {
          name: 'Shellfish',
          allergen: 'Shellfish',
        },
        {
          name: 'Wheat',
          allergen: 'Wheat',
        },
        {
          name: 'Soybean',
          allergen: 'Soybean',
        },
        {
          name: 'Fish',
          allergen: 'Fish',
        },
        {
          name: 'Gluten',
          allergen: 'Gluten',
        },
      ]),
  },
  getDiscountTiersStatus: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        mustSetPricingTier: false,
      }),
  },
  getDiscountTiers: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        pricingTiers: [
          {
            id: 1,
            name: 'Employee',
          },
          {
            id: 2,
            name: 'Consultant',
          },
        ],
      }),
  },
  setDiscountTier: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 1000));
    },
  },
  getMenusV2: {
    ok: true,
    status: 200,
    json: () =>
      new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              {
                id: 92,
                facilityId: '',
                isOrderable: false,
                name: 'AU - Menu - Test Data',
                date: todayDate,
                menuItems: [],
                source: 'Rr',
              },
              {
                id: 183,
                facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
                isOrderable: false,
                name: 'IFM Menu',
                date: todayDate,
                menuItems: [],
                source: 'Rr',
              },
              {
                id: 160,
                facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
                name: 'IFMS Breakfast Tavern Menu',
                date: todayDate,
                isOrderable: true,
                fulfillmentTypes: [
                  {
                    id: 5,
                    type: DeliveryOptionType.ScanAndGo,
                  },
                ],
                menuItems: [
                  {
                    name: 'BBQ Brekky Wrap',
                    mealName: 'Breakfast',
                    dayPart: 'Breakfast',
                    section: 'Breakfast Favourites',
                    foodSubCategoryDescription: null,
                    description:
                      'Tortilla wrap filled with scrambled eggs, bacon, cheese, chorizo sausage and bbq sauce',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 14.2,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '4cb91217-aa68-453c-8c03-452975cfaee1',
                        foodItemSourceKey: 'PUR16',
                        name: 'BBQ Brekky Wrap',
                        description:
                          'Tortilla wrap filled with scrambled eggs, bacon, cheese, chorizo sausage and bbq sauce',
                        ingredients: '',
                        foodItemId: 1982,
                        uomId: 2099,
                        isFavorite: true,
                        uomBarcodes: ['1111'],
                        allergens: [
                          {
                            id: 'Milk',
                            name: 'Milk',
                            contains: true,
                          },
                          {
                            id: 'Eggs',
                            name: 'Eggs',
                            contains: true,
                          },
                          {
                            id: 'Wheat',
                            name: 'Wheat',
                            contains: true,
                          },
                          {
                            id: 'Mustard',
                            name: 'Mustard',
                            contains: true,
                          },
                          {
                            id: 'Gluten',
                            name: 'Gluten',
                            contains: true,
                          },
                        ],
                        nutritions: {
                          calories: 1000,
                          kilojoules: 500,
                          carbohydrates: 100,
                          fat: 50,
                          protein: 20,
                          saturatedFat: 30,
                          transFat: 10,
                          polyunsaturatedFat: 10,
                          cholesterol: 10,
                          // sodium: '2320mg',
                          // dietaryFiber: '',
                          // sugar: '',
                          // potassium: '',
                          // iron: '',
                          // calcium: '',
                          // vitaminA: '',
                          // vitaminC: '',
                        },
                        portionSize: '355g',
                        portion: 'Each',
                        gramWeight: '355',
                        modifiers: [
                          {
                            id: 60,
                            name: 'Extra Protein',
                            description: 'Extra Protein',
                            type: 2,
                            selectionLimit: 1,
                            isRequired: true,
                            min: 0,
                            max: 1,
                            firstFree: 1,
                            modifierItems: [
                              {
                                id: 110,
                                name: 'Sliced Gammon',
                                price: 13.2,
                                priceLabel: 'AUD 13.20',
                                sortOrder: 1,
                                maximumQuantity: 1,
                                description: 'Sliced Gammon',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 100,
                                  kilojoules: 100,
                                  carbohydrates: 100,
                                  fat: 100,
                                  protein: 100,
                                  saturatedFat: 100,
                                  transFat: 100,
                                  polyunsaturatedFat: 100,
                                  cholesterol: 100,
                                },
                                allergens: [
                                  {
                                    id: 'Milk',
                                    name: 'Milk',
                                    contains: true,
                                  },
                                  {
                                    id: 'Peanut',
                                    name: 'Peanut',
                                    contains: true,
                                  },
                                  {
                                    id: 'Shellfish',
                                    name: 'Shellfish',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 111,
                                name: 'Flaked Tuna',
                                price: 11.99,
                                priceLabel: 'AUD 11.99',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Flaked Tuna',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 200,
                                  kilojoules: 200,
                                  carbohydrates: 200,
                                  fat: 200,
                                  protein: 200,
                                  saturatedFat: 200,
                                  transFat: 200,
                                  polyunsaturatedFat: 200,
                                  cholesterol: 200,
                                },
                                allergens: [
                                  {
                                    id: 'Eggs',
                                    name: 'Eggs',
                                    contains: true,
                                  },
                                  {
                                    id: 'Treenuts',
                                    name: 'Treenuts',
                                    contains: true,
                                  },
                                  {
                                    id: 'Wheat',
                                    name: 'Wheat',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 112,
                                name: 'Grated Cheddar Cheese',
                                price: 8.72,
                                priceLabel: 'AUD 8.72',
                                sortOrder: 3,
                                maximumQuantity: 1,
                                description: 'Grated Cheddar Cheese',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 300,
                                  kilojoules: 300,
                                  carbohydrates: 300,
                                  fat: 300,
                                  protein: 300,
                                  saturatedFat: 300,
                                  transFat: 300,
                                  polyunsaturatedFat: 300,
                                  cholesterol: 300,
                                },
                              },
                              {
                                id: 113,
                                name: 'Boiled Egg',
                                price: 20.9,
                                priceLabel: 'AUD 20.90',
                                sortOrder: 4,
                                maximumQuantity: 1,
                                description: 'Boiled Egg',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 400,
                                  kilojoules: 400,
                                  carbohydrates: 400,
                                  fat: 400,
                                  protein: 400,
                                  saturatedFat: 400,
                                  transFat: 400,
                                  polyunsaturatedFat: 400,
                                  cholesterol: 400,
                                },
                              },
                            ],
                          },
                          {
                            id: 61,
                            name: 'Toppings',
                            description: 'Toppings',
                            type: 1,
                            selectionLimit: 4,
                            isRequired: false,
                            min: 0,
                            max: 10,
                            firstFree: 1,
                            modifierItems: [
                              {
                                id: 200,
                                name: 'Tomato',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 1,
                                maximumQuantity: 1,
                                description: 'Fresh Tomato',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 1,
                                  kilojoules: 1,
                                  carbohydrates: 1,
                                  fat: 1,
                                  protein: 1,
                                  saturatedFat: 1,
                                  transFat: 1,
                                  polyunsaturatedFat: 1,
                                  cholesterol: 1,
                                },
                                allergens: [
                                  {
                                    id: 'Gluten',
                                    name: 'Gluten',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 201,
                                name: 'Pickles',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Fresh Pickles',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 2,
                                  kilojoules: 2,
                                  carbohydrates: 2,
                                  fat: 2,
                                  protein: 2,
                                  saturatedFat: 2,
                                  transFat: 2,
                                  polyunsaturatedFat: 2,
                                  cholesterol: 2,
                                },
                                allergens: [
                                  {
                                    id: 'Treenuts',
                                    name: 'Treenuts',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 202,
                                name: 'Olives',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 3,
                                maximumQuantity: 1,
                                description: 'Fresh Olives',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 3,
                                  kilojoules: 3,
                                  carbohydrates: 3,
                                  fat: 3,
                                  protein: 3,
                                  saturatedFat: 3,
                                  transFat: 3,
                                  polyunsaturatedFat: 3,
                                  cholesterol: 3,
                                },
                              },
                              {
                                id: 203,
                                name: 'Jalapeno',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 4,
                                maximumQuantity: 1,
                                description: 'Fresh Jalapeno',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 4,
                                  kilojoules: 4,
                                  carbohydrates: 4,
                                  fat: 4,
                                  protein: 4,
                                  saturatedFat: 4,
                                  transFat: 4,
                                  polyunsaturatedFat: 4,
                                  cholesterol: 4,
                                },
                              },
                            ],
                          },
                          {
                            id: 62,
                            name: 'Salad Base',
                            description: 'Salad Base',
                            type: 1,
                            selectionLimit: 2,
                            isRequired: true,
                            min: 1,
                            max: 2,
                            firstFree: 1,
                            modifierItems: [
                              {
                                id: 114,
                                name: 'Greek Salad',
                                price: 1.99,
                                priceLabel: 'AUD 1.99',
                                sortOrder: 1,
                                maximumQuantity: 1,
                                description: 'Greek Salad',
                                min: 0,
                                max: 1,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 10,
                                  kilojoules: 10,
                                  carbohydrates: 10,
                                  fat: 10,
                                  protein: 10,
                                  saturatedFat: 10,
                                  transFat: 10,
                                  polyunsaturatedFat: 10,
                                  cholesterol: 10,
                                },
                                allergens: [
                                  {
                                    id: 'Milk',
                                    name: 'Milk',
                                    contains: true,
                                  },
                                  {
                                    id: 'Peanut',
                                    name: 'Peanut',
                                    contains: true,
                                  },
                                  {
                                    id: 'Shellfish',
                                    name: 'Shellfish',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 115,
                                name: 'Cezar Salad',
                                price: 2.99,
                                priceLabel: 'AUD 2.99',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Cezar Salad',
                                min: 0,
                                max: 1,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 20,
                                  kilojoules: 20,
                                  carbohydrates: 20,
                                  fat: 20,
                                  protein: 20,
                                  saturatedFat: 20,
                                  transFat: 20,
                                  polyunsaturatedFat: 20,
                                  cholesterol: 20,
                                },
                              },
                              {
                                id: 116,
                                name: 'Polish Salad',
                                price: 5.99,
                                priceLabel: 'AUD 5.99',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Polish Salad',
                                min: 0,
                                max: 1,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 20,
                                  kilojoules: 20,
                                  carbohydrates: 20,
                                  fat: 20,
                                  protein: 20,
                                  saturatedFat: 20,
                                  transFat: 20,
                                  polyunsaturatedFat: 20,
                                  cholesterol: 20,
                                },
                              },
                            ],
                          },
                        ],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 14.2,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: 5,
                      },
                      {
                        portionId: 'cfbbfe71-d3e6-4aaf-8428-3e450ac10520',
                        foodItemSourceKey: 'PUR17',
                        name: 'BBQ Brekky Wrap',
                        description:
                          'Tortilla wrap filled with scrambled eggs, bacon, cheese, chorizo sausage and bbq sauce',
                        ingredients: '',
                        allergens: [],
                        foodItemId: 1982,
                        uomId: 9871,
                        nutritions: {
                          calories: 1500,
                          kilojoules: 1000,
                          carbohydrates: 100,
                          fat: 50,
                          protein: 20,
                          saturatedFat: 30,
                          transFat: 10,
                          polyunsaturatedFat: 10,
                          cholesterol: 10,
                          // sodium: '2320mg',
                          // dietaryFiber: '',
                          // sugar: '',
                          // potassium: '',
                          // iron: '',
                          // calcium: '',
                          // vitaminA: '',
                          // vitaminC: '',
                        },
                        portionSize: '555g',
                        portion: 'Huge',
                        gramWeight: '555',
                        modifiers: [
                          {
                            id: 60,
                            name: 'Extra Protein',
                            description: 'Extra Protein',
                            type: 2,
                            selectionLimit: 1,
                            isRequired: true,
                            min: 0,
                            max: 1,
                            firstFree: 1,
                            modifierItems: [
                              {
                                id: 110,
                                name: 'Sliced Gammon',
                                price: 13.2,
                                priceLabel: 'AUD 13.20',
                                sortOrder: 1,
                                maximumQuantity: 1,
                                description: 'Sliced Gammon',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 100,
                                  kilojoules: 100,
                                  carbohydrates: 100,
                                  fat: 100,
                                  protein: 100,
                                  saturatedFat: 100,
                                  transFat: 100,
                                  polyunsaturatedFat: 100,
                                  cholesterol: 100,
                                },
                                allergens: [
                                  {
                                    id: 'Milk',
                                    name: 'Milk',
                                    contains: true,
                                  },
                                  {
                                    id: 'Peanut',
                                    name: 'Peanut',
                                    contains: true,
                                  },
                                  {
                                    id: 'Shellfish',
                                    name: 'Shellfish',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 111,
                                name: 'Flaked Tuna',
                                price: 11.99,
                                priceLabel: 'AUD 11.99',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Flaked Tuna',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 200,
                                  kilojoules: 200,
                                  carbohydrates: 200,
                                  fat: 200,
                                  protein: 200,
                                  saturatedFat: 200,
                                  transFat: 200,
                                  polyunsaturatedFat: 200,
                                  cholesterol: 200,
                                },
                                allergens: [
                                  {
                                    id: 'Eggs',
                                    name: 'Eggs',
                                    contains: true,
                                  },
                                  {
                                    id: 'Treenuts',
                                    name: 'Treenuts',
                                    contains: true,
                                  },
                                  {
                                    id: 'Wheat',
                                    name: 'Wheat',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 112,
                                name: 'Grated Cheddar Cheese',
                                price: 8.72,
                                priceLabel: 'AUD 8.72',
                                sortOrder: 3,
                                maximumQuantity: 1,
                                description: 'Grated Cheddar Cheese',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 300,
                                  kilojoules: 300,
                                  carbohydrates: 300,
                                  fat: 300,
                                  protein: 300,
                                  saturatedFat: 300,
                                  transFat: 300,
                                  polyunsaturatedFat: 300,
                                  cholesterol: 300,
                                },
                              },
                              {
                                id: 113,
                                name: 'Boiled Egg',
                                price: 20.9,
                                priceLabel: 'AUD 20.90',
                                sortOrder: 4,
                                maximumQuantity: 1,
                                description: 'Boiled Egg',
                                min: 0,
                                max: 1,
                                firstFree: 0,
                                isChild: true,
                                nutritions: {
                                  calories: 400,
                                  kilojoules: 400,
                                  carbohydrates: 400,
                                  fat: 400,
                                  protein: 400,
                                  saturatedFat: 400,
                                  transFat: 400,
                                  polyunsaturatedFat: 400,
                                  cholesterol: 400,
                                },
                              },
                            ],
                          },
                          {
                            id: 61,
                            name: 'Toppings',
                            description: 'Toppings',
                            type: 1,
                            selectionLimit: 4,
                            isRequired: false,
                            min: 0,
                            max: 10,
                            firstFree: 1,
                            modifierItems: [
                              {
                                id: 200,
                                name: 'Tomato',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 1,
                                maximumQuantity: 1,
                                description: 'Fresh Tomato',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 1,
                                  kilojoules: 1,
                                  carbohydrates: 1,
                                  fat: 1,
                                  protein: 1,
                                  saturatedFat: 1,
                                  transFat: 1,
                                  polyunsaturatedFat: 1,
                                  cholesterol: 1,
                                },
                                allergens: [
                                  {
                                    id: 'Gluten',
                                    name: 'Gluten',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 201,
                                name: 'Pickles',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Fresh Pickles',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 2,
                                  kilojoules: 2,
                                  carbohydrates: 2,
                                  fat: 2,
                                  protein: 2,
                                  saturatedFat: 2,
                                  transFat: 2,
                                  polyunsaturatedFat: 2,
                                  cholesterol: 2,
                                },
                                allergens: [
                                  {
                                    id: 'Treenuts',
                                    name: 'Treenuts',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 202,
                                name: 'Olives',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 3,
                                maximumQuantity: 1,
                                description: 'Fresh Olives',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 3,
                                  kilojoules: 3,
                                  carbohydrates: 3,
                                  fat: 3,
                                  protein: 3,
                                  saturatedFat: 3,
                                  transFat: 3,
                                  polyunsaturatedFat: 3,
                                  cholesterol: 3,
                                },
                              },
                              {
                                id: 203,
                                name: 'Jalapeno',
                                price: 0.49,
                                priceLabel: 'AUD 0.49',
                                sortOrder: 4,
                                maximumQuantity: 1,
                                description: 'Fresh Jalapeno',
                                min: 0,
                                max: 4,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 4,
                                  kilojoules: 4,
                                  carbohydrates: 4,
                                  fat: 4,
                                  protein: 4,
                                  saturatedFat: 4,
                                  transFat: 4,
                                  polyunsaturatedFat: 4,
                                  cholesterol: 4,
                                },
                              },
                            ],
                          },
                          {
                            id: 62,
                            name: 'Salad Base',
                            description: 'Salad Base',
                            type: 1,
                            selectionLimit: 2,
                            isRequired: true,
                            min: 1,
                            max: 2,
                            firstFree: 1,
                            modifierItems: [
                              {
                                id: 114,
                                name: 'Greek Salad',
                                price: 1.99,
                                priceLabel: 'AUD 1.99',
                                sortOrder: 1,
                                maximumQuantity: 1,
                                description: 'Greek Salad',
                                min: 0,
                                max: 1,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 10,
                                  kilojoules: 10,
                                  carbohydrates: 10,
                                  fat: 10,
                                  protein: 10,
                                  saturatedFat: 10,
                                  transFat: 10,
                                  polyunsaturatedFat: 10,
                                  cholesterol: 10,
                                },
                                allergens: [
                                  {
                                    id: 'Milk',
                                    name: 'Milk',
                                    contains: true,
                                  },
                                  {
                                    id: 'Peanut',
                                    name: 'Peanut',
                                    contains: true,
                                  },
                                  {
                                    id: 'Shellfish',
                                    name: 'Shellfish',
                                    contains: true,
                                  },
                                ],
                              },
                              {
                                id: 115,
                                name: 'Cezar Salad',
                                price: 2.99,
                                priceLabel: 'AUD 2.99',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Cezar Salad',
                                min: 0,
                                max: 1,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 20,
                                  kilojoules: 20,
                                  carbohydrates: 20,
                                  fat: 20,
                                  protein: 20,
                                  saturatedFat: 20,
                                  transFat: 20,
                                  polyunsaturatedFat: 20,
                                  cholesterol: 20,
                                },
                              },
                              {
                                id: 116,
                                name: 'Polish Salad',
                                price: 5.99,
                                priceLabel: 'AUD 5.99',
                                sortOrder: 2,
                                maximumQuantity: 1,
                                description: 'Polish Salad',
                                min: 0,
                                max: 1,
                                firstFree: 1,
                                isChild: true,
                                nutritions: {
                                  calories: 20,
                                  kilojoules: 20,
                                  carbohydrates: 20,
                                  fat: 20,
                                  protein: 20,
                                  saturatedFat: 20,
                                  transFat: 20,
                                  polyunsaturatedFat: 20,
                                  cholesterol: 20,
                                },
                              },
                            ],
                          },
                        ],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 19.2,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: 5,
                        isMindful: false,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2408-20210827105714-foodImg.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2408-20200512012741-Breakky_Wrap.jpg',
                    menuItemId: 4400666,
                  },
                  {
                    name: 'Breakfast Burger',
                    mealName: 'Breakfast',
                    dayPart: 'Breakfast',
                    section: 'Breakfast Favourites',
                    foodSubCategoryDescription: null,
                    description:
                      'Beef burger patty on a brioche roll with bacon, egg, cheese and chipotle bbq sauce. Served with potato gems',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 14.59,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '940ecc9f-a432-4d6b-823e-90b9528e2e48',
                        foodItemSourceKey: 'PUR17',
                        name: 'Breakfast Burger',
                        description:
                          'Beef burger patty on a brioche roll with bacon, egg, cheese and chipotle bbq sauce. Served with potato gems',
                        ingredients: '',
                        allergens: [],
                        isFavorite: true,
                        nutritions: {
                          calories: 1130,
                          kilojoules: 0,
                          carbohydrates: 92,
                          fat: 58,
                          protein: 58,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 2960,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '535g',
                        portion: 'Each',
                        gramWeight: '535',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 14.59,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2100,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2409-20200512012708-Breakfast_Burger.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2409-20200512012750-Breakfast_Burger.jpg',
                    menuItemId: 4463877,
                  },
                  {
                    name: 'Loaded Potato Gems',
                    mealName: 'Breakfast',
                    dayPart: 'Breakfast',
                    section: 'Breakfast Favourites',
                    foodSubCategoryDescription: null,
                    description:
                      'Potato gems topped with crispy bacon pieces, melted cheese and sour cream',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 23.68,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'c35f7b55-d4be-44a0-8e2a-f0e11194a24c',
                        foodItemSourceKey: 'PUR18',
                        name: 'Loaded Potato Gems',
                        description:
                          'Potato gems topped with crispy bacon pieces, melted cheese and sour cream',
                        ingredients: '',
                        uomBarcodes: ['78633'],
                        allergens: [],
                        nutritions: {
                          calories: 990,
                          kilojoules: 0,
                          carbohydrates: 48,
                          fat: 72,
                          protein: 36,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 2300,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '425g',
                        portion: 'Each',
                        gramWeight: '425',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 23.68,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2101,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2410-20200512012723-Loaded_Potato_Gems.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2410-20200512012758-Loaded_Potato_Gems.jpg',
                    menuItemId: 4463878,
                  },
                  {
                    name: 'Chicken Nuggets',
                    mealName: 'Breakfast',
                    dayPart: 'Breakfast',
                    section: 'Breakfast Favourites',
                    foodSubCategoryDescription: null,
                    description: '10 Chicken nuggets served with tomato sauce',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 0.4,

                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'bd4c43a7-b311-44f7-a541-f2cdd21bf6a1',
                        foodItemSourceKey: 'PUR19',
                        name: 'Chicken Nuggets',
                        description: '10 Chicken nuggets served with tomato sauce',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '0g',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 0.4,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2102,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2411-20200512012730-Chicken_Nuggets.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2411-20200512012805-Chicken_Nuggets.jpg',
                    menuItemId: 4463879,
                  },
                ],
                message:
                  'We are sorry but we do not operate due to summer holidays. However, if you are extremely hungry then you can go to our neighbour restaurant. They also serve tasty meals. See you after holidays when we are back',
                source: 'Rr',
              },
              {
                id: 140,
                co2Value: '',
                alcoholContent: null,
                facilityId: 'b4703c0e-2874-ea11-99e5-0003ff4cdb10',
                name: 'IFMS Retail Click & Collect 2020',
                isOrderable: true,
                date: todayDate,
                message:
                  'We are sorry but we do not operate due to summer holidays. However, if you are extremely hungry then you can go to our neighbour restaurant. They also serve tasty meals. See you after holidays when we are back',
                fulfillmentTypes: [
                  {
                    id: 1,
                    type: DeliveryOptionType.PickupOption,
                  },
                  {
                    id: 4,
                    type: DeliveryOptionType.DineIn,
                  },
                ],
                menuItems: [
                  {
                    name: '12 X 500ML MT FRANKLIN WATER',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Water on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '25bbaa47-7678-41e7-83b1-e9a6000ce9a3',
                        foodItemSourceKey: 'PLU6915',
                        name: '12 X 500ML MT FRANKLIN WATER',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2877,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3186-20201030063214-SODEXO_APP_270x270_P6_CLICK___COLLECT-10.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3186-20201030063217-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-10.jpg',
                    menuItemId: 4461744,
                  },
                  {
                    name: 'COOL RIDGE LIME & CUCUMBER 750ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Water on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'f8291b3c-564b-46b3-a04d-db5108d88826',
                        foodItemSourceKey: 'PLU20597',
                        name: 'COOL RIDGE LIME & CUCUMBER 750ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2876,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3185-20201030063219-SODEXO_APP_270x270_P6_CLICK___COLLECT-2.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3185-20201030063221-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-2.jpg',
                    menuItemId: 4461753,
                  },
                  {
                    name: 'COOL RIDGE RASPBERRY 750ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Water on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'b3b18901-029c-411a-bf33-e1e9d8a67d32',
                        foodItemSourceKey: 'PLU20596',
                        name: 'COOL RIDGE RASPBERRY 750ML',
                        isFavorite: true,
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2875,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3184-20201030063223-SODEXO_APP_270x270_P6_CLICK___COLLECT-2.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3184-20201030063226-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-2.jpg',
                    menuItemId: 4461756,
                  },
                  {
                    name: 'COOL RIDGE SPRING WATER 750ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Water on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e200cfd4-392c-4cfe-82f5-7aaf0224e8ce',
                        foodItemSourceKey: 'PLU20589',
                        name: 'COOL RIDGE SPRING WATER 750ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2874,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3183-20201030063229-SODEXO_APP_270x270_P6_CLICK___COLLECT-2.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3183-20201030063231-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-2.jpg',
                    menuItemId: 4461765,
                  },
                  {
                    name: 'COCA COLA  600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'c97fb9e5-142c-430d-bb7e-9b93a9c5666c',
                        foodItemSourceKey: 'PLU4011',
                        name: 'COCA COLA  600ML',
                        description: '',
                        ingredients: '',
                        isFavorite: true,
                        allergens: [],
                        nutritions: {
                          calories: 260,
                          kilojoules: 0,
                          carbohydrates: 64,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 60,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2878,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3187-20201207182806-001397056.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3187-20201208031152-001397056.jpg',
                    menuItemId: 4461746,
                  },
                  {
                    name: 'COCA COLA  NO SUGAR 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e6d6f4eb-ed06-417d-a291-3831696ac14a',
                        foodItemSourceKey: 'PLU16567',
                        name: 'COCA COLA  NO SUGAR 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 25,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2879,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3188-20210825074906-coco_cola_no_sugar.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3188-20201030052022-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-6.jpg',
                    menuItemId: 4461755,
                  },
                  {
                    name: 'COCA COLA FANTA 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 5.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '97550ca1-e8a1-4aa6-b143-954f7b5f326d',
                        foodItemSourceKey: 'PLU601',
                        name: 'COCA COLA FANTA 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 280,
                          kilojoules: 0,
                          carbohydrates: 68,
                          fat: 1,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 5,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 5.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2884,
                      },
                    ],
                    listImage: null,
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3193-20201030054036-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-6.jpg',
                    menuItemId: 4461757,
                  },
                  {
                    name: 'COCA COLA LIFT 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '7894040d-d835-47c9-8009-d5ea5c0353bd',
                        foodItemSourceKey: 'PLU3648',
                        name: 'COCA COLA LIFT 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2882,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3191-20201030053827-SODEXO_APP_270x270_P6_CLICK___COLLECT-6.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3191-20201030054047-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-6.jpg',
                    menuItemId: 4461766,
                  },
                  {
                    name: 'COCA COLA DIET 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 4,
                    price: 10.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '1894d005-6373-4659-a6dc-c37398fda5f0',
                        foodItemSourceKey: 'PLU443',
                        name: 'COCA COLA DIET 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 90,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 10.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2880,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3189-20201030053832-SODEXO_APP_270x270_P6_CLICK___COLLECT-6.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3189-20201030054049-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-6.jpg',
                    menuItemId: 4461767,
                  },
                  {
                    name: 'COCA COLA SPRITE 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 5,
                    price: 20.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '4394a9b6-0764-469f-b3f5-77e34d77dfd2',
                        foodItemSourceKey: 'PLU469',
                        name: 'COCA COLA SPRITE 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 250,
                          kilojoules: 0,
                          carbohydrates: 61,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 70,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 20.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2883,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3192-20201030053835-SODEXO_APP_270x270_P6_CLICK___COLLECT-6.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3192-20201030054052-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-6.jpg',
                    menuItemId: 4461771,
                  },
                  {
                    name: 'COCA COLA VANILLA COKE 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 6,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'c0377074-0f52-48a4-8259-7155e79ac0e1',
                        foodItemSourceKey: 'PLU4828',
                        name: 'COCA COLA VANILLA COKE 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2881,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3190-20201030053839-SODEXO_APP_270x270_P6_CLICK___COLLECT-6.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3190-20201030054055-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-6.jpg',
                    menuItemId: 4461772,
                  },
                  {
                    name: 'MOUNTAIN DEW PET 1.25L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 7,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'c1bd1c1f-f263-40cb-b738-82e67939932b',
                        foodItemSourceKey: 'PLU20564',
                        name: 'MOUNTAIN DEW PET 1.25L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2865,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3174-20201030052136-SODEXO_APP_270x270_P6_CLICK___COLLECT-4.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3174-20201030052141-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-4.jpg',
                    menuItemId: 4461775,
                  },
                  {
                    name: 'MOUNTAIN DEW CAN 440ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 8,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '4097cac4-1913-43fd-b3f8-9eaf622a6777',
                        foodItemSourceKey: 'PLU20571',
                        name: 'MOUNTAIN DEW CAN 440ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2873,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3182-20201030054108-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3182-20201030054141-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461777,
                  },
                  {
                    name: 'PASSIONA PET 1.25L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 9,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'b9666b72-1212-4f0b-988f-e221567da850',
                        foodItemSourceKey: 'PLU20565',
                        name: 'PASSIONA PET 1.25L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2866,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3175-20201030054111-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3175-20201030054140-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461779,
                  },
                  {
                    name: 'PEPSI CAN 440ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 10,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e2ff52c6-612d-4bcd-a444-7702e670b6aa',
                        foodItemSourceKey: 'PLU20568',
                        name: 'PEPSI CAN 440ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2870,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3179-20201030054116-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3179-20201030054136-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461780,
                  },
                  {
                    name: 'PEPSI MAX CREAMING SODA CAN 440ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 11,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '0e4d2614-51d2-4575-9465-5f50ec59cc4e',
                        foodItemSourceKey: 'PLU20570',
                        name: 'PEPSI MAX CREAMING SODA CAN 440ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2872,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3181-20201030054121-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3181-20201030054131-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461783,
                  },
                  {
                    name: 'PEPSI MAX PET 1.25L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 12,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '8455c11f-aaea-492a-8ba0-015c1563d77c',
                        foodItemSourceKey: 'PLU20559',
                        name: 'PEPSI MAX PET 1.25L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2861,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3170-20201030054154-SODEXO_APP_270x270_P6_CLICK___COLLECT-4.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3170-20201030054246-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-4.jpg',
                    menuItemId: 4461784,
                  },
                  {
                    name: 'PEPSI MAX CAN 440ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 13,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '4633f2db-d191-4aa8-959b-028c3ef25c90',
                        foodItemSourceKey: 'PLU18926',
                        name: 'PEPSI MAX CAN 440ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2867,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3176-20201030054252-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3176-20201030060521-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461785,
                  },
                  {
                    name: 'PEPSI PET 1.25L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 14,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '852836ba-8bc0-4952-9667-335f2d949068',
                        foodItemSourceKey: 'PLU20561',
                        name: 'PEPSI PET 1.25L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2863,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3172-20201030054206-SODEXO_APP_270x270_P6_CLICK___COLLECT-4.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3172-20201030054241-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-4.jpg',
                    menuItemId: 4461786,
                  },
                  {
                    name: 'SCH LEMONADE PET 1.1L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 15,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'fca273d6-928b-4601-9903-b54e97199734',
                        foodItemSourceKey: 'PLU20562',
                        name: 'SCH LEMONADE PET 1.1L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2860,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3169-20201030054159-SODEXO_APP_270x270_P6_CLICK___COLLECT-4.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3169-20201030054240-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-4.jpg',
                    menuItemId: 4461787,
                  },
                  {
                    name: 'SCHW TRADS RASP CAN 440ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 16,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '1ebc879d-e418-448f-8706-77141dd932a9',
                        foodItemSourceKey: 'PLU20569',
                        name: 'SCHW TRADS RASP CAN 440ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2871,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3180-20201030054257-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3180-20201030060457-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461788,
                  },
                  {
                    name: 'SOLO LEMON CAN 440ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 17,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '7b38de3c-0846-4ada-8836-fc1d50fd47a9',
                        foodItemSourceKey: 'PLU18927',
                        name: 'SOLO LEMON CAN 440ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2868,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3177-20201030054300-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3177-20201030060458-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461789,
                  },
                  {
                    name: 'SOLO LEMON PET 1.25L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 18,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e0d76800-1dac-4ada-809f-513aebbdae9b',
                        foodItemSourceKey: 'PLU20560',
                        name: 'SOLO LEMON PET 1.25L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2862,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3171-20201030054215-SODEXO_APP_270x270_P6_CLICK___COLLECT-4.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3171-20201030054235-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-4.jpg',
                    menuItemId: 4461790,
                  },
                  {
                    name: 'SUNKIST CAN 440ML ',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 19,
                    price: 2.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '38be2c1f-5e68-4fce-9ef2-9d7363a8fef3',
                        foodItemSourceKey: 'PLU18928',
                        name: 'SUNKIST CAN 440ML ',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2869,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3178-20201030060509-SODEXO_APP_270x270_P6_CLICK___COLLECT-5.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3178-20201030060506-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-5.jpg',
                    menuItemId: 4461791,
                  },
                  {
                    name: 'SUNKIST ORANGE PET 1.25L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Soft Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 20,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '2d0dda4e-20e9-48d1-a875-9ee33c598d81',
                        foodItemSourceKey: 'PLU20563',
                        name: 'SUNKIST ORANGE PET 1.25L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2864,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3173-20201030054219-SODEXO_APP_270x270_P6_CLICK___COLLECT-4.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3173-20201030054234-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-4.jpg',
                    menuItemId: 4461792,
                  },
                  {
                    name: 'POWERADE BLACKCURRANT 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Sports Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '0e42121a-fd35-4431-b98d-880d4f452888',
                        foodItemSourceKey: 'PLU885',
                        name: 'POWERADE BLACKCURRANT 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 150,
                          kilojoules: 0,
                          carbohydrates: 35,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 170,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2887,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3196-20201030063603-SODEXO_APP_270x270_P6_CLICK___COLLECT-7.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3196-20201030063610-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-7.jpg',
                    menuItemId: 4461745,
                  },
                  {
                    name: 'POWERADE MOUNTAIN BLAST POWERFLOW 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Sports Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '41c99a6a-7296-4c71-95d5-e9fc67495504',
                        foodItemSourceKey: 'PLU604',
                        name: 'POWERADE MOUNTAIN BLAST POWERFLOW 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 150,
                          kilojoules: 0,
                          carbohydrates: 35,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 170,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2885,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3194-20201030063606-SODEXO_APP_270x270_P6_CLICK___COLLECT-7.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3194-20201030063611-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-7.jpg',
                    menuItemId: 4461751,
                  },
                  {
                    name: 'POWERADE WALLABY GOLD RUSH POWERFLOW 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Sports Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'b044528c-0379-4b10-96eb-c690cf35ff49',
                        foodItemSourceKey: 'PLU253',
                        name: 'POWERADE WALLABY GOLD RUSH POWERFLOW 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 150,
                          kilojoules: 0,
                          carbohydrates: 35,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 170,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2886,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3195-20201030063607-SODEXO_APP_270x270_P6_CLICK___COLLECT-7.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3195-20201030063613-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-7.jpg',
                    menuItemId: 4461760,
                  },
                  {
                    name: 'PRINGLES CHIPS BBQ       134GM',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Pringles on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 4.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'ea36ea2d-b5c2-4cf6-b180-e8d0c7e13506',
                        foodItemSourceKey: 'PLU15991',
                        name: 'PRINGLES CHIPS BBQ       134GM',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 4.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2894,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3203-20210826152025-frame_59_.png',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3203-20210826152005-donation_jar.png',
                    menuItemId: 4461748,
                  },
                  {
                    name: 'PRINGLES CHIPS CHICKEN   134GM',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Pringles on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 4.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e321196e-e5a3-4c55-8f62-7d533959f3c4',
                        foodItemSourceKey: 'PLU17777',
                        name: 'PRINGLES CHIPS CHICKEN   134GM',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 4.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2895,
                      },
                      {
                        portionId: '6743e26b-300b-403c-875b-4380b5cea0b5',
                        foodItemSourceKey: 'PLU17777',
                        name: 'PRINGLES CHIPS CHICKEN   134GM',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: '2',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: false,
                        price: 0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2947,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3204-20201030063243-SODEXO_APP_270x270_P6_CLICK___COLLECT-1.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3204-20201030063321-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-1.jpg',
                    menuItemId: 4461752,
                  },
                  {
                    name: 'PRINGLES CHIPS ORIG      134GM',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Pringles on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 4.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '38f25fe9-4b30-44dc-8308-2e94dc43fd9a',
                        foodItemSourceKey: 'PLU6613',
                        name: 'PRINGLES CHIPS ORIG      134GM',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 4.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2891,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3200-20201030063245-SODEXO_APP_270x270_P6_CLICK___COLLECT-1.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3200-20201030063319-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-1.jpg',
                    menuItemId: 4461759,
                  },
                  {
                    name: 'PRINGLES CHIPS S/CRM&ON  134GM',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Pringles on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 4.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'c5f55626-78cb-4bf0-a184-e45f57d55b8b',
                        foodItemSourceKey: 'PLU6614',
                        name: 'PRINGLES CHIPS S/CRM&ON  134GM',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 4.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2892,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3201-20201030063305-SODEXO_APP_270x270_P6_CLICK___COLLECT-1.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3201-20201030063314-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-1.jpg',
                    menuItemId: 4461762,
                  },
                  {
                    name: 'PRINGLES CHIPS SALT & VIN  134GM',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Pringles on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 4,
                    price: 4.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '18fde299-b204-4dac-92aa-ebf341b67195',
                        foodItemSourceKey: 'PLU6615',
                        name: 'PRINGLES CHIPS SALT & VIN  134GM',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 4.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2893,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3202-20201030063309-SODEXO_APP_270x270_P6_CLICK___COLLECT-1.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3202-20201030063312-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-1.jpg',
                    menuItemId: 4461768,
                  },
                  {
                    name: 'CAR CHARGER DUAL USB 4.8AMP',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Electronics on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 15.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e720514f-df8f-4c66-b802-be2a195e1780',
                        foodItemSourceKey: 'PLU15922',
                        name: 'CAR CHARGER DUAL USB 4.8AMP',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 15.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2896,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3205-20220203094429-energydrinks_1_2_.png',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3205-20210830140304-foodImg.jpg',
                    menuItemId: 4461749,
                  },
                  {
                    name: 'FUSE - NOISE CANCELLING HEADPHONE',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Electronics on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 60.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'b01f7a50-db86-46e8-9378-2b2200f715d7',
                        foodItemSourceKey: 'PLU19929',
                        name: 'FUSE - NOISE CANCELLING HEADPHONE',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 60.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2898,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3207-20201030063537-SODEXO_APP_270x270_P6_CLICK___COLLECT-14.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3207-20201030063540-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-14.jpg',
                    menuItemId: 4461750,
                  },
                  {
                    name: 'FUSE PREMIUM BT SPEAKER',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Electronics on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 40.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '9651cc82-a0cb-47af-b1fd-80816d4a8424',
                        foodItemSourceKey: 'PLU19928',
                        name: 'FUSE PREMIUM BT SPEAKER',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 40.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2899,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3208-20201030063543-SODEXO_APP_270x270_P6_CLICK___COLLECT-13.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3208-20201030063546-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-13.jpg',
                    menuItemId: 4461761,
                  },
                  {
                    name: 'WNT MAGNETIC MOBILE PHONE HOLDER',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Electronics on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 20.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '5877409a-bd22-4342-bee9-4482b90876b3',
                        foodItemSourceKey: 'PLU16670',
                        name: 'WNT MAGNETIC MOBILE PHONE HOLDER',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: false,
                        isDefault: true,
                        price: 20.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2897,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3206-20201030063548-SODEXO_APP_270x270_P6_CLICK___COLLECT-12.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3206-20201030063550-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-12.jpg',
                    menuItemId: 4461763,
                  },
                  {
                    name: 'RED BULL ORANGE CANS 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'f26f7a13-b53c-4e0b-8f91-93bdf4348fba',
                        foodItemSourceKey: 'PLU19303',
                        name: 'RED BULL ORANGE CANS 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2904,
                      },
                      {
                        portionId: 'a921c997-f842-4023-a35e-2f0e41276b1d',
                        foodItemSourceKey: 'PLU19303',
                        name: 'RED BULL ORANGE CANS 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: '2',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: false,
                        price: 0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2949,
                      },
                    ],
                    listImage: null,
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3213-20210831112702-Red_Bull_Orange.jpg',
                    menuItemId: 4461747,
                  },
                  {
                    name: 'RED BULL ORIGINAL 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'b79eb5ef-84ad-4b5f-819c-a7ce442272b4',
                        foodItemSourceKey: 'PLU176',
                        name: 'RED BULL ORIGINAL 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2900,
                      },
                    ],
                    listImage: null,
                    detailsImage: null,
                    menuItemId: 4461754,
                  },
                  {
                    name: 'RED BULL ORIGINAL 250ML X 4 PACK',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 10.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '0dcb710e-0b18-4f8b-aa9d-990ddb46f2f6',
                        foodItemSourceKey: 'PLU3218',
                        name: 'RED BULL ORIGINAL 250ML X 4 PACK',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 10.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2906,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3215-20201030063410-SODEXO_APP_270x270_P6_CLICK___COLLECT-9.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3215-20201030063505-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-9.jpg',
                    menuItemId: 4461758,
                  },
                  {
                    name: 'RED BULL RED (WATERMELON) 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'bbb3e7a7-f3df-40d6-a2fc-d1ac79355478',
                        foodItemSourceKey: 'PLU18777',
                        name: 'RED BULL RED (WATERMELON) 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2903,
                      },
                    ],
                    listImage: null,
                    detailsImage: null,
                    menuItemId: 4461764,
                  },
                  {
                    name: 'RED BULL S/F 250ML X 4 PACK',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 4,
                    price: 10.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e63a5297-7b40-4265-9746-088efe49ea46',
                        foodItemSourceKey: 'PLU216',
                        name: 'RED BULL S/F 250ML X 4 PACK',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 10.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2907,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3216-20201030063413-SODEXO_APP_270x270_P6_CLICK___COLLECT-9.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3216-20201030063500-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-9.jpg',
                    menuItemId: 4461769,
                  },
                  {
                    name: 'RED BULL S/FREE CANS 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 5,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'c0705df7-9c55-4194-a8aa-7f06a9ae1712',
                        foodItemSourceKey: 'PLU177',
                        name: 'RED BULL S/FREE CANS 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 5,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 105,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2901,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3210-20201030063351-SODEXO_APP_270x270_P6_CLICK___COLLECT-3.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3210-20201030063456-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-3.jpg',
                    menuItemId: 4461770,
                  },
                  {
                    name: 'RED BULL TROP  CANS 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 6,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'e644be46-91a2-4e14-936d-50d655825ebf',
                        foodItemSourceKey: 'PLU9501',
                        name: 'RED BULL TROP  CANS 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2902,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3211-20201030063355-SODEXO_APP_270x270_P6_CLICK___COLLECT-3.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3211-20201030063454-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-3.jpg',
                    menuItemId: 4461773,
                  },
                  {
                    name: 'RED BULL ZERO 250ML X 4 PACK',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 7,
                    price: 10.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '08513c23-8d00-4c6e-8a2b-3e0df2a84f28',
                        foodItemSourceKey: 'PLU3220',
                        name: 'RED BULL ZERO 250ML X 4 PACK',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 10.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2908,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3217-20201030063404-SODEXO_APP_270x270_P6_CLICK___COLLECT-9.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3217-20201030063453-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-9.jpg',
                    menuItemId: 4461774,
                  },
                  {
                    name: 'RED BULL ZERO CANS 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 8,
                    price: 3.25,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '104bec07-14ef-44d4-a66f-a0e9a0415864',
                        foodItemSourceKey: 'PLU3216',
                        name: 'RED BULL ZERO CANS 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.25,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2905,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3214-20201030063419-SODEXO_APP_270x270_P6_CLICK___COLLECT-3.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3214-20201030063449-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-3.jpg',
                    menuItemId: 4461776,
                  },
                  {
                    name: 'V BLUE 300ML 4PK CANS ',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 9,
                    price: 10.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '47f8921e-292a-4834-87e5-6ade5c6c8fdb',
                        foodItemSourceKey: 'PLU19281',
                        name: 'V BLUE 300ML 4PK CANS ',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 10.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2890,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3199-20201030063421-SODEXO_APP_270x270_P6_CLICK___COLLECT-9.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3199-20201030063447-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-9.jpg',
                    menuItemId: 4461778,
                  },
                  {
                    name: 'V GREEN 300ML 4PK CANS',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 10,
                    price: 10.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '37f3d334-8594-4ef9-99f9-7e3d298dece4',
                        foodItemSourceKey: 'PLU5515',
                        name: 'V GREEN 300ML 4PK CANS',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 10.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2888,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3197-20201030063429-SODEXO_APP_270x270_P6_CLICK___COLLECT-8.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3197-20201030063443-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-8.jpg',
                    menuItemId: 4461781,
                  },
                  {
                    name: 'V SUGAR FREE 300ML 4PK CANS ',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Promotions Nov and Dec',
                    subSection: 'Energy Drinks on Promotion',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 11,
                    price: 10.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '0a336ad7-bee3-43ec-b6da-806fa4e72747',
                        foodItemSourceKey: 'PLU19282',
                        name: 'V SUGAR FREE 300ML 4PK CANS ',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 10.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2889,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3198-20201030063432-SODEXO_APP_270x270_P6_CLICK___COLLECT-8.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/3198-20201030063434-SODEXO_APP_1120x1050_P6_CLICK___COLLECT-8.jpg',
                    menuItemId: 4461782,
                  },
                  {
                    name: 'MT FRANKLIN 600ML PET',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Water',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 4.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '115708c2-f570-4c0c-adc5-1d19fad92b27',
                        foodItemSourceKey: 'PLU1629',
                        name: 'MT FRANKLIN 600ML PET',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 4.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2112,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2421-20200618011436-SODEXO_APP_720x540_P4-6.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2421-20200618011913-SODEXO_APP_720x540_P4-6.jpg',
                    menuItemId: 4461796,
                  },
                  {
                    name: 'MT FRANKLIN 600ML PET',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Water',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '00ed0ed0-eeb8-4e4d-b8fa-b610bee1c99e',
                        foodItemSourceKey: 'PLU1629',
                        name: 'MT FRANKLIN 600ML PET',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1724,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2037-20200319041229-Mount_Franklin_600ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2037-20200319044152-Mount_Franklin_600ml.jpg',
                    menuItemId: 4461795,
                  },
                  {
                    name: 'MT/FRANK SPR/WTR NAT STIL 1.5L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Water',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 0.2,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '4e47dffc-b093-4a07-8c73-bbec592bbfdc',
                        foodItemSourceKey: 'PLU1587',
                        name: 'MT/FRANK SPR/WTR NAT STIL 1.5L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.2,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1723,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2036-20200319041223-Mount_Franklin_1.5L.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2036-20200319044149-Mount_Franklin_1.5L.jpg',
                    menuItemId: 4461804,
                  },
                  {
                    name: 'YURRAMA WATER 1.5L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Water',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 3.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '192cc1af-a30c-4495-9397-263e21bcd882',
                        foodItemSourceKey: 'PLU19539',
                        name: 'YURRAMA WATER 1.5L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2104,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2413-20200618022939-Yurrama_water-270x270.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2413-20200618022956-Yurrama_water-1120x1050.jpg',
                    menuItemId: 4461807,
                  },
                  {
                    name: 'YURRAMA WATER 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Water',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 2.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '29eb539f-4f6a-49d7-aa17-1db4a116e2d8',
                        foodItemSourceKey: 'PLU19537',
                        name: 'YURRAMA WATER 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 2.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2103,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2412-20200618014119-Yurrama_water-270x270.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2412-20200618014133-Yurrama_water-1120x1050.jpg',
                    menuItemId: 4461816,
                  },
                  {
                    name: '8 x YURRAMA WATER 1.5L (Bundle Offer)',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Water',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 16.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'ff7dc4cf-830d-4c2a-b1f0-222e0c9555b0',
                        foodItemSourceKey: 'PLU19539',
                        name: '8 x YURRAMA WATER 1.5L (Bundle Offer)',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 16.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2117,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2426-20200618011422-SODEXO_APP_720x540_P4-15.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2426-20200618011906-SODEXO_APP_720x540_P4-15.jpg',
                    menuItemId: 4461817,
                  },
                  {
                    name: 'POWERADE BERRY ICE POWERFLOW 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 23.68,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '40e3d286-7043-422b-83c2-0b421a809197',
                        foodItemSourceKey: 'PLU603',
                        name: 'POWERADE BERRY ICE POWERFLOW 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 150,
                          kilojoules: 0,
                          carbohydrates: 35,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 170,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 23.68,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1748,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2061-20200319041447-Powerade_Berry_Ice_600ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2061-20200319044601-Powerade_Berry_Ice_600ml.jpg',
                    menuItemId: 4461797,
                  },
                  {
                    name: 'POWERADE MOUNTAIN BLAST POWERFLOW 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'd08e65d4-ba71-4e17-ab28-8d7f955cb8e7',
                        foodItemSourceKey: 'PLU604',
                        name: 'POWERADE MOUNTAIN BLAST POWERFLOW 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 150,
                          kilojoules: 0,
                          carbohydrates: 35,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 170,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1746,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2059-20200319041452-Powerade_Mountain_Blast_600ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2059-20200319044607-Powerade_Mountain_Blast_600ml.jpg',
                    menuItemId: 4461805,
                  },
                  {
                    name: 'POWERADE WALLABY GOLD RUSH POWERFLOW 600ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 0.4,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '186c2546-1290-4d11-bbe3-b51f956485ea',
                        foodItemSourceKey: 'PLU253',
                        name: 'POWERADE WALLABY GOLD RUSH POWERFLOW 600ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 150,
                          kilojoules: 0,
                          carbohydrates: 35,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 170,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '600g',
                        portion: 'Each',
                        gramWeight: '600',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.4,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1747,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2060-20200319041455-Powerade_Gold_Rush_600ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2060-20200319044604-Powerade_Gold_Rush_600ml.jpg',
                    menuItemId: 4461806,
                  },
                  {
                    name: 'LUCOZADE ORIG BTL 380ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 0.4,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '08ed95ff-e196-480e-875f-58f2a524ad9c',
                        foodItemSourceKey: 'PLU3917',
                        name: 'LUCOZADE ORIG BTL 380ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 280,
                          kilojoules: 0,
                          carbohydrates: 70,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 5,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '380g',
                        portion: 'Each',
                        gramWeight: '380',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.4,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1753,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2066-20200319041556-Lucozade_Original_380ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2066-20200319044627-Lucozade_Original_380ml.jpg',
                    menuItemId: 4461813,
                  },
                  {
                    name: 'BEROCCA TWIST DRINK ORIG 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 4,
                    price: 23.68,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'b12e890f-19a9-419b-b143-4c1c8faf8e13',
                        foodItemSourceKey: 'PLU15695',
                        name: 'BEROCCA TWIST DRINK ORIG 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 15,
                          kilojoules: 0,
                          carbohydrates: 2,
                          fat: 1,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 270,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 23.68,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1752,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2065-20200319041558-Berocca_Twist_Go_Original.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2065-20200319044631-Berocca_Twist_Go_Original.jpg',
                    menuItemId: 4461820,
                  },
                  {
                    name: 'MAXIMUS BLUE 1L ',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 5,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '893615ff-c4aa-4152-8137-f6a63fc0a5bb',
                        foodItemSourceKey: 'PLU2350',
                        name: 'MAXIMUS BLUE 1L ',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 330,
                          kilojoules: 0,
                          carbohydrates: 19,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 80,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '1093g',
                        portion: 'Each',
                        gramWeight: '1093',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2182,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2491-20200819082555-SODEXO_CLICK___COLLECT_270x270_P5-16.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2491-20200819082634-SODEXO_CLICK___COLLECT_1120x1050_P5-16.jpg',
                    menuItemId: 4461828,
                  },
                  {
                    name: 'MAXIMUS BLUE 1L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 5,
                    price: 23.68,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '1c15377b-e121-483b-a918-ad9ac10e99b1',
                        foodItemSourceKey: 'PLU2350',
                        name: 'MAXIMUS BLUE 1L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 310,
                          kilojoules: 0,
                          carbohydrates: 74,
                          fat: 1,
                          protein: 1,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 300,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '1000g',
                        portion: 'Each',
                        gramWeight: '1000',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 23.68,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1749,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2062-20200319041530-Maximus_Blue_1L.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2062-20200319044618-Maximus_Blue_1L.jpg',
                    menuItemId: 4461827,
                  },
                  {
                    name: 'MAXIMUS GRAPE 1L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 6,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'd1177465-f608-4c5f-b5f7-795bae9b08ae',
                        foodItemSourceKey: 'PLU17957',
                        name: 'MAXIMUS GRAPE 1L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 310,
                          kilojoules: 0,
                          carbohydrates: 74,
                          fat: 1,
                          protein: 1,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 300,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '1000g',
                        portion: 'Each',
                        gramWeight: '1000',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1751,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2064-20200319041527-Maximus_Grape_1L.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2064-20200319044616-Maximus_Grape_1L.jpg',
                    menuItemId: 4461829,
                  },
                  {
                    name: 'MAXIMUS GRAPE 1L ',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 6,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '89819611-cc3c-4818-b49b-be14bf115bb3',
                        foodItemSourceKey: 'PLU17957',
                        name: 'MAXIMUS GRAPE 1L ',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 320,
                          kilojoules: 0,
                          carbohydrates: 18,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 75,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '1092g',
                        portion: 'Each',
                        gramWeight: '1092',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2183,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2492-20200819082558-SODEXO_CLICK___COLLECT_270x270_P5-16.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2492-20200819082631-SODEXO_CLICK___COLLECT_1120x1050_P5-16.jpg',
                    menuItemId: 4461830,
                  },
                  {
                    name: 'MAXIMUS RED 1L ',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 7,
                    price: 3.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '1ce12e02-ba11-42a1-bd4d-b2a822831385',
                        foodItemSourceKey: 'PLU2346',
                        name: 'MAXIMUS RED 1L ',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 330,
                          kilojoules: 0,
                          carbohydrates: 19,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 80,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '1093g',
                        portion: 'Each',
                        gramWeight: '1093',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: true,
                        isDefault: true,
                        price: 3.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2181,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2490-20200819082551-SODEXO_CLICK___COLLECT_270x270_P5-16.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2490-20200819082638-SODEXO_CLICK___COLLECT_1120x1050_P5-16.jpg',
                    menuItemId: 4461838,
                  },
                  {
                    name: 'MAXIMUS RED 1L',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Sports Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 7,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '4c4f0c36-a35b-438d-903e-76f6bc46ac97',
                        foodItemSourceKey: 'PLU2346',
                        name: 'MAXIMUS RED 1L',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 310,
                          kilojoules: 0,
                          carbohydrates: 74,
                          fat: 1,
                          protein: 1,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 300,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '1000g',
                        portion: 'Each',
                        gramWeight: '1000',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1750,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2063-20200319041534-Maximus_Red_1L.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2063-20200319044621-Maximus_Red_1L.jpg',
                    menuItemId: 4461837,
                  },
                  {
                    name: 'RED BULL 473ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 0,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'a6df1dd9-6c19-4230-b0ec-4fe19e0d026d',
                        foodItemSourceKey: 'PLU3224',
                        name: 'RED BULL 473ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 220,
                          kilojoules: 0,
                          carbohydrates: 51,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 200,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '473g',
                        portion: 'Each',
                        gramWeight: '473',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1733,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2046-20201207174021-snapshotimagehandler_1395935961.jpeg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2046-20201207174029-snapshotimagehandler_1395935961.jpeg',
                    menuItemId: 4461793,
                  },
                  {
                    name: 'RED BULL CANS 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 1,
                    price: 0.4,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '2649fbc9-f01b-4f5c-af6d-415b5c6cdcb7',
                        foodItemSourceKey: 'PLU176',
                        name: 'RED BULL CANS 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 120,
                          kilojoules: 0,
                          carbohydrates: 27,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 105,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.4,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1726,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2039-20200319041320-Redbull_250ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2039-20200319044455-Redbull_250ml.jpg',
                    menuItemId: 4461801,
                  },
                  {
                    name: 'RED BULL S/FREE 473ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'b0a2e286-ddd3-473f-a026-7348825305a6',
                        foodItemSourceKey: 'PLU5064',
                        name: 'RED BULL S/FREE 473ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 15,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 190,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '473g',
                        portion: 'Each',
                        gramWeight: '473',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1734,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2047-20201207174152-snapshotimagehandler_1395935961.jpeg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2047-20201207174204-snapshotimagehandler_1395935961.jpeg',
                    menuItemId: 4461810,
                  },
                  {
                    name: '2 x RED BULL 473ML SUGAR FREE',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 2,
                    price: 8.5,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'de70b2c0-19f1-4107-86fe-380ddd5804dd',
                        foodItemSourceKey: 'PLU5064',
                        name: '2 x RED BULL 473ML SUGAR FREE',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 8.5,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2114,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2423-20200618011343-SODEXO_APP_720x540_P4-8.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2423-20200618011852-SODEXO_APP_720x540_P4-8.jpg',
                    menuItemId: 4461811,
                  },
                  {
                    name: 'RED BULL S/FREE CANS 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 3,
                    price: 0.4,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: 'd6e098b0-306c-4a6b-b220-46b47ad8854e',
                        foodItemSourceKey: 'PLU177',
                        name: 'RED BULL S/FREE CANS 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 5,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 105,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '250g',
                        portion: 'Each',
                        gramWeight: '250',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.4,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1727,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2040-20200319041335-Redbull_Sugar_Free_250ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2040-20200319044503-Redbull_Sugar_Free_250ml.jpg',
                    menuItemId: 4461812,
                  },
                  {
                    name: 'V BLUE 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 4,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '1df3d054-bac6-4805-81e4-d1688152eb2d',
                        foodItemSourceKey: 'PLU2306',
                        name: 'V BLUE 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1728,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2041-20200319041406-V_Engery_Drink_Blue_250ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2041-20200319044519-V_Engery_Drink_Blue_250ml.jpg',
                    menuItemId: 4461822,
                  },
                  {
                    name: 'V BLUE 500ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 5,
                    price: 23.68,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '13df076b-2be4-4707-8070-9b62b5966042',
                        foodItemSourceKey: 'PLU2318',
                        name: 'V BLUE 500ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 23.68,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1731,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2044-20200319041409-V_Engery_Drink_Blue_500ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2044-20200319044521-V_Engery_Drink_Blue_500ml.jpg',
                    menuItemId: 4461823,
                  },
                  {
                    name: 'V BLUE 500ML CAN ',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 5,
                    price: 4.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '7da71498-07e4-459e-9b62-abd6b586c085',
                        foodItemSourceKey: 'PLU2318',
                        name: 'V BLUE 500ML CAN ',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 1220,
                          kilojoules: 0,
                          carbohydrates: 69,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 250,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '542g',
                        portion: 'Each',
                        gramWeight: '542',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: true,
                        isDefault: true,
                        price: 4.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2153,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2462-20200819080441-SODEXO_CLICK___COLLECT_270x270_P5-7.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2462-20200819080654-SODEXO_CLICK___COLLECT_1120x1050_P5-7.jpg',
                    menuItemId: 4461824,
                  },
                  {
                    name: 'V GREEN 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 6,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '5e21f645-a484-4dc5-a1df-2fc3e5396b9a',
                        foodItemSourceKey: 'PLU2298',
                        name: 'V GREEN 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1729,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2042-20200319041404-V_Energy_Drink_250ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2042-20200319044534-V_Energy_Drink_250ml.jpg',
                    menuItemId: 4461833,
                  },
                  {
                    name: 'V GREEN 500ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 7,
                    price: 0.1,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '496c19a0-d26e-43fb-a21e-1ae3bb2f6afe',
                        foodItemSourceKey: 'PLU2310',
                        name: 'V GREEN 500ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.1,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1732,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2045-20200319041417-V_Energy_Drink_500ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2045-20200319044530-V_Energy_Drink_500ml.jpg',
                    menuItemId: 4461834,
                  },
                  {
                    name: 'V GREEN 500ML CAN',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 7,
                    price: 4.0,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '5e5fde50-ddc5-4c78-86a0-32c95d0126fe',
                        foodItemSourceKey: 'PLU2310',
                        name: 'V GREEN 500ML CAN',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 980,
                          kilojoules: 0,
                          carbohydrates: 53,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 500,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '558g',
                        portion: 'Each',
                        gramWeight: '558',
                        modifiers: [],
                        isVegan: false,
                        isVegetarian: true,
                        isDefault: true,
                        price: 4.0,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 2151,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2460-20200819080422-SODEXO_CLICK___COLLECT_270x270_P5-7.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2460-20200819080649-SODEXO_CLICK___COLLECT_1120x1050_P5-7.jpg',
                    menuItemId: 4461835,
                  },
                  {
                    name: 'V SUGAR FREE 250ML',
                    mealName: 'Anytime',
                    dayPart: 'AnyTime',
                    section: 'Drinks',
                    subSection: 'Energy Drinks',
                    foodSubCategoryDescription: null,
                    description: '',
                    isOrderable: true,
                    sortOrder: 8,
                    price: 0.2,
                    startTime: today.setHours(0),
                    endTime: today.setHours(23),
                    productPortions: [
                      {
                        portionId: '0dd693af-e9e8-4270-bf4f-1a9a452d5a87',
                        foodItemSourceKey: 'PLU2300',
                        name: 'V SUGAR FREE 250ML',
                        description: '',
                        ingredients: '',
                        allergens: [],
                        nutritions: {
                          calories: 0,
                          kilojoules: 0,
                          carbohydrates: 0,
                          fat: 0,
                          protein: 0,
                          saturatedFat: 0,
                          transFat: 0,
                          polyunsaturatedFat: 0,
                          cholesterol: 0,
                          sodium: 0,
                          dietaryFiber: 0,
                          sugar: 0,
                          potassium: 0,
                          iron: 0,
                          calcium: 0,
                          vitaminA: 0,
                          vitaminC: 0,
                        },
                        portionSize: '',
                        portion: 'Each',
                        gramWeight: '0',
                        modifiers: [],
                        isVegan: true,
                        isVegetarian: true,
                        isDefault: true,
                        price: 0.2,
                        menuItemId: 0,
                        milkAdditive: false,
                        soyBeansAdditive: false,
                        eggsAdditive: false,
                        sesameSeedsAdditive: false,
                        peanutsAdditive: false,
                        wheatAdditive: false,
                        fishAdditive: false,
                        nutsAdditive: false,
                        co2Rating: '',
                        co2Value: '',
                        alcoholContent: null,
                        isMindful: false,
                        foodItemId: 1982,
                        uomId: 1730,
                      },
                    ],
                    listImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2043-20200319041424-V_Energy_Drink_Sugarfree_250ml.jpg',
                    detailsImage:
                      'https://sodexoitzstageau.blob.core.windows.net/fooditem/2043-20200319044548-V_Energy_Drink_Sugarfree_250ml.jpg',
                    menuItemId: 4461843,
                  },
                ],
                source: 'Rr',
              },
            ]),
          3000
        )
      ),
  },
  getMenuDownloadUrl: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              url: 'https://sodexoitzdepreprod.blob.core.windows.net/bitemenudownloads/0-20220518081746-Menu_1447_2022-05-16_2022-05-22.pdf',
            }),
          2000
        )
      );
    },
  },
  checkCutOffDate: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        forbidenReasons: [],
        isOrderingPossible: true,
      }),
  },
  getPickupLocations: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        pickupSpotsContent: [
          {
            pickupSpotId: 49,
            name: 'Dinner Tavern Pickup',
            order: 0,
            address: null,
            message: null,
          },
          {
            pickupSpotId: 50,
            name: 'Retail Only Tavern Pickup',
            order: 1,
            address: null,
            message: null,
          },
          {
            pickupSpotId: 51,
            name: 'Breakfast Tavern Pickup',
            order: 2,
            address: null,
            message: null,
          },
          {
            pickupSpotId: 83,
            name: 'test spot',
            order: 3,
            address: null,
            message: null,
          },
        ],
      }),
  },
  getPickupTimeSlots: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        timeSlotsContent: [
          {
            timeSlotId: 1571,
            time: '2021-09-14T23:00:00',
          },
          {
            timeSlotId: 1573,
            time: '2021-09-14T23:55:00',
          },
          {
            timeSlotId: 1591,
            time: '2021-09-14T23:59:00',
          },
        ],
      }),
  },
  submitDraftOrder: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              orderId: 1,
              subtotal: 6.0,
              tax: 0,
              total: 5.0,
              subsidy: 1.0,
              delivery: null,
              promotionalDiscountForItems: [
                {
                  uomId: 1794,
                  foodItemId: 1982,
                  discount: 1,
                  price: 5,
                },
              ],
              promotionsApplied: [
                {
                  id: 1,
                  name: 'Promotion 1',
                  discount: 2,
                },
                {
                  id: 2,
                  name: 'Promotion 2',
                  discount: 1,
                },
              ],
              redeemedFoodItems: undefined,
              loyaltyStampsEarned: undefined,
            }),
          3000
        )
      );
    },
  },
  getOrderHistory: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              {
                delivery: 2,
                tax: 1,
                facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
                isOrderable: true,
                orderId: 2223,
                orderNumber: '2122',
                pickupDate: '2022-04-29T13:01:16.8954182+02:00',
                status: 'Submitted',
                totalAmount: 324,
                instructions: 'Sample instructions 1',
                orderItems: [
                  {
                    name: 'Apple',
                    price: 2,
                    quantity: 4,
                  },
                  {
                    name: 'Chicken',
                    price: 12,
                    quantity: 1,
                  },
                  {
                    name: 'Water',
                    price: 1,
                    quantity: 7,
                  },
                ],
              },
              {
                delivery: 4,
                tax: 2,
                facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
                isOrderable: true,
                orderId: 2224,
                orderNumber: '3973',
                pickupDate: '2022-04-29T13:01:16.8987295+02:00',
                status: 'Submitted',
                totalAmount: 112,
                instructions: 'Sample instructions 2',
                orderItems: [
                  {
                    name: 'Apple',
                    price: 2,
                    quantity: 4,
                  },
                  {
                    name: 'Chicken',
                    price: 12,
                    quantity: 1,
                  },
                  {
                    name: 'Water',
                    price: 1,
                    quantity: 7,
                  },
                ],
              },
              {
                delivery: 6,
                tax: 1.5,
                facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
                isOrderable: true,
                orderId: 2226,
                orderNumber: '324324',
                pickupDate: '2022-04-29T13:01:16.898732+02:00',
                status: 'Submitted',
                totalAmount: 554,
                instructions: 'Sample instructions 3',
                orderItems: [
                  {
                    name: 'Apple',
                    price: 2,
                    quantity: 4,
                  },
                  {
                    name: 'Chicken',
                    price: 12,
                    quantity: 1,
                  },
                  {
                    name: 'Water',
                    price: 1,
                    quantity: 7,
                  },
                ],
              },
            ]),
          3000
        )
      );
    },
  },
  getOrderDetails: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              delivery: 2,
              tax: 1,
              facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
              isOrderable: true,
              orderId: 2223,
              pickupDate: '2022-04-29T13:01:16.8954182+02:00',
              status: 'Submitted',
              totalAmount: 6.15,
              subTotal: 6.15,
              instructions: 'Sample instructions 1',
              pickupLocation: 'Some location',
              deliveryOptionType: 'DineIn',
              isPaid: false,
              promotionDiscount: 3,
              orderItems: [
                {
                  name: 'Apple',
                  price: 2,
                  quantity: 4,
                },
                {
                  name: 'Chicken',
                  price: 12,
                  quantity: 1,
                },
                {
                  name: 'Water',
                  price: 1,
                  quantity: 7,
                },
              ],
              appliedPromotions: [
                {
                  id: 1,
                  name: 'Promotion 1',
                  discount: 2,
                },
                {
                  id: 2,
                  name: 'Promotion 2',
                  discount: 1,
                },
              ],
            }),
          3000
        )
      );
    },
  },
  getPendingOrders: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              {
                outletId: 18,
                pickupTime: '2022-08-26T21:00:00',
                orderNumber: '10489',
                orderStatus: 'Submitted',
                deliveryOptionId: 0,
              },

              {
                outletId: 18,
                pickupTime: '2022-08-26T21:00:00',
                orderNumber: '10490',
                orderStatus: 'Submitted',
                deliveryOptionId: 0,
              },
            ]),
          3000
        )
      );
    },
  },
  postOrder: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 3000));
    },
  },
  checkInAtTable: {
    ok: true,
    status: 204,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 3000));
    },
  },
  getFacilityId: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
            }),
          500
        )
      );
    },
  },
  getSiteId: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              siteId: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
            }),
          500
        )
      );
    },
  },
  getReorderProposition: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              {
                menuId: 103,
                menuItemId: 13679270,
                name: 'Aspretto Americano',
                mealName: 'Aspretto Menu',
                foodSubCategoryDescription: 'Other',
                description: "Maciej's description for coffee",
                price: 2.05,
                productPortions: [
                  {
                    portionId: '13679270_5034',
                    foodItemSourceKey: '51:3584',
                    name: 'Aspretto Americano',
                    description: "Maciej's description for coffee",
                    ingredients: null,
                    allergens: [],
                    nutritions: {
                      calories: 0,
                      kilojoules: 0,
                      carbohydrates: 0,
                      fat: 0,
                      protein: 0,
                      saturatedFat: 0,
                      transFat: 0,
                      polyunsaturatedFat: 0,
                      cholesterol: 0,
                      sodium: 0,
                      dietaryFiber: 0,
                      sugar: 0,
                      potassium: 0,
                      iron: 0,
                      calcium: 0,
                      vitaminA: 0,
                      vitaminC: 0,
                    },
                    portionSize: '',
                    portion: 'Large',
                    gramWeight: '0',
                    modifiers: [
                      {
                        id: 11085,
                        name: 'Sugar',
                        description: 'Sugar',
                        type: 1,
                        selectionLimit: 1,
                        isRequired: false,
                        min: 0,
                        max: 0,
                        firstFree: 1,
                        modifierItems: [
                          {
                            id: 37659,
                            name: 'Sugar Cube',
                            price: 0.0,
                            priceLabel: '123 Kcal',
                            sortOrder: 1,
                            maximumQuantity: 0,
                            description: 'Sugar Cube',
                            min: 0,
                            max: 0,
                            firstFree: 0,
                            isChild: false,
                            nutritions: {
                              calories: 0,
                              kilojoules: 0,
                              carbohydrates: 0,
                              fat: 0,
                              protein: 0,
                              saturatedFat: 0,
                              transFat: 0,
                              polyunsaturatedFat: 0,
                              cholesterol: 0,
                              sodium: 0,
                              dietaryFiber: 0,
                              sugar: 0,
                              potassium: 0,
                              iron: 0,
                              calcium: 0,
                              vitaminA: 0,
                              vitaminC: 0,
                            },
                            allergens: [],
                          },
                        ],
                      },
                    ],
                    isVegan: true,
                    isVegetarian: false,
                    isDefault: false,
                    price: 1.0,
                    viewablePrices: null,
                    milkAdditive: false,
                    soyBeansAdditive: false,
                    eggsAdditive: false,
                    sesameSeedsAdditive: false,
                    peanutsAdditive: false,
                    wheatAdditive: false,
                    fishAdditive: false,
                    nutsAdditive: false,
                    co2Rating: '',
                    co2Value: '0',
                    isMindful: false,
                    uomId: 5034,
                    foodItemId: 3584,
                    additives: null,
                    uomBarcodes: [],
                  },
                  {
                    portionId: '13679270_10595',
                    foodItemSourceKey: '51:3584',
                    name: 'Aspretto Americano',
                    description: "Maciej's description for coffee",
                    ingredients: null,
                    allergens: [],
                    nutritions: {
                      calories: 0,
                      kilojoules: 0,
                      carbohydrates: 0,
                      fat: 0,
                      protein: 0,
                      saturatedFat: 0,
                      transFat: 0,
                      polyunsaturatedFat: 0,
                      cholesterol: 0,
                      sodium: 0,
                      dietaryFiber: 0,
                      sugar: 0,
                      potassium: 0,
                      iron: 0,
                      calcium: 0,
                      vitaminA: 0,
                      vitaminC: 0,
                    },
                    portionSize: '',
                    portion: 'regular',
                    gramWeight: '0',
                    modifiers: [
                      {
                        id: 11086,
                        name: 'Sugar',
                        description: 'Sugar',
                        type: 1,
                        selectionLimit: 1,
                        isRequired: false,
                        min: 0,
                        max: 0,
                        firstFree: 1,
                        modifierItems: [
                          {
                            id: 37660,
                            name: 'Sugar Cube',
                            price: 0.0,
                            priceLabel: '123 Kcal',
                            sortOrder: 1,
                            maximumQuantity: 0,
                            description: 'Sugar Cube',
                            min: 0,
                            max: 0,
                            firstFree: 0,
                            isChild: false,
                            nutritions: {
                              calories: 0,
                              kilojoules: 0,
                              carbohydrates: 0,
                              fat: 0,
                              protein: 0,
                              saturatedFat: 0,
                              transFat: 0,
                              polyunsaturatedFat: 0,
                              cholesterol: 0,
                              sodium: 0,
                              dietaryFiber: 0,
                              sugar: 0,
                              potassium: 0,
                              iron: 0,
                              calcium: 0,
                              vitaminA: 0,
                              vitaminC: 0,
                            },
                            allergens: [],
                          },
                        ],
                      },
                    ],
                    isVegan: true,
                    isVegetarian: false,
                    isDefault: false,
                    price: 0.0,
                    viewablePrices: null,
                    milkAdditive: false,
                    soyBeansAdditive: false,
                    eggsAdditive: false,
                    sesameSeedsAdditive: false,
                    peanutsAdditive: false,
                    wheatAdditive: false,
                    fishAdditive: false,
                    nutsAdditive: false,
                    co2Rating: '',
                    co2Value: '0',
                    isMindful: false,
                    uomId: 10595,
                    foodItemId: 3584,
                    additives: null,
                    uomBarcodes: ['111111111'],
                  },
                ],
                listImage: null,
                detailsImage: null,
              },
              {
                menuId: 103,
                menuItemId: 13677456,
                name: 'Broccoli (500g)',
                mealName: 'Fruit and Veg',
                foodSubCategoryDescription: 'Other',
                description: '',
                price: 1.0,
                productPortions: [
                  {
                    portionId: '13677456_2788',
                    foodItemSourceKey: 'SSK_2804',
                    name: 'Broccoli (500g)',
                    description: '',
                    ingredients: '',
                    allergens: [],
                    nutritions: {
                      calories: 0,
                      kilojoules: 0,
                      carbohydrates: 0,
                      fat: 0,
                      protein: 0,
                      saturatedFat: 0,
                      transFat: 0,
                      polyunsaturatedFat: 0,
                      cholesterol: 0,
                      sodium: 0,
                      dietaryFiber: 0,
                      sugar: 0,
                      potassium: 0,
                      iron: 0,
                      calcium: 0,
                      vitaminA: 0,
                      vitaminC: 0,
                    },
                    portionSize: '',
                    portion: '1 Serving',
                    gramWeight: '0',
                    modifiers: [],
                    isVegan: true,
                    isVegetarian: true,
                    isDefault: true,
                    price: 1.0,
                    viewablePrices: null,
                    milkAdditive: false,
                    soyBeansAdditive: false,
                    eggsAdditive: false,
                    sesameSeedsAdditive: false,
                    peanutsAdditive: false,
                    wheatAdditive: false,
                    fishAdditive: false,
                    nutsAdditive: false,
                    co2Rating: '',
                    co2Value: '0',
                    isMindful: false,
                    uomId: 2788,
                    foodItemId: 2804,
                    additives: null,
                    uomBarcodes: [],
                  },
                ],
                listImage: null,
                detailsImage: null,
              },
              {
                menuId: 103,
                menuItemId: 13677462,
                name: 'Pot Noodle Beef & Tomato',
                mealName: 'Meals',
                foodSubCategoryDescription: 'Other',
                description: '',
                price: 1.35,
                productPortions: [
                  {
                    portionId: '13677462_2808',
                    foodItemSourceKey: 'SSK_2824',
                    name: 'Pot Noodle Beef & Tomato',
                    description: '',
                    ingredients: '',
                    allergens: [],
                    nutritions: {
                      calories: 0,
                      kilojoules: 0,
                      carbohydrates: 0,
                      fat: 0,
                      protein: 0,
                      saturatedFat: 0,
                      transFat: 0,
                      polyunsaturatedFat: 0,
                      cholesterol: 0,
                      sodium: 0,
                      dietaryFiber: 0,
                      sugar: 0,
                      potassium: 0,
                      iron: 0,
                      calcium: 0,
                      vitaminA: 0,
                      vitaminC: 0,
                    },
                    portionSize: '',
                    portion: '1 Serving',
                    gramWeight: '0',
                    modifiers: [],
                    isVegan: false,
                    isVegetarian: false,
                    isDefault: true,
                    price: 1.35,
                    viewablePrices: null,
                    milkAdditive: false,
                    soyBeansAdditive: false,
                    eggsAdditive: false,
                    sesameSeedsAdditive: false,
                    peanutsAdditive: false,
                    wheatAdditive: false,
                    fishAdditive: false,
                    nutsAdditive: false,
                    co2Rating: '',
                    co2Value: '0',
                    isMindful: false,
                    uomId: 2808,
                    foodItemId: 2824,
                    additives: null,
                    uomBarcodes: [],
                  },
                ],
                listImage: null,
                detailsImage: null,
              },
            ]),
          500
        )
      );
    },
  },
  getProductSuggestions: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              suggestionsEnabled: true,
              suggestions: [
                {
                  menuId: 160,
                  menuItemId: 4400666,
                  name: 'BBQ Brekky Wrap',
                  mealName: 'Breakfast',
                  productCategory: 'Breakfast Favourites',
                  foodSubCategoryDescription: null,
                  description:
                    'Tortilla wrap filled with scrambled eggs, bacon, cheese, chorizo sausage and bbq sauce',
                  isOrderable: true,
                  sortOrder: 0,
                  price: 14.2,
                  startTime: today.setHours(0),
                  endTime: today.setHours(23),
                  productPortions: [
                    {
                      portionId: '4cb91217-aa68-453c-8c03-452975cfaee1',
                      foodItemSourceKey: 'PUR16',
                      name: 'BBQ Brekky Wrap',
                      description:
                        'Tortilla wrap filled with scrambled eggs, bacon, cheese, chorizo sausage and bbq sauce',
                      ingredients: '',
                      foodItemId: 1982,
                      uomId: 2099,
                      uomBarcodes: ['1111'],
                      allergens: [
                        {
                          id: 'Milk',
                          name: 'Milk',
                          contains: true,
                        },
                        {
                          id: 'Eggs',
                          name: 'Eggs',
                          contains: true,
                        },
                        {
                          id: 'Wheat',
                          name: 'Wheat',
                          contains: true,
                        },
                        {
                          id: 'Mustard',
                          name: 'Mustard',
                          contains: true,
                        },
                        {
                          id: 'Gluten',
                          name: 'Gluten',
                          contains: true,
                        },
                      ],
                      nutritions: {
                        calories: 1000,
                        kilojoules: 500,
                        carbohydrates: 100,
                        fat: 50,
                        protein: 20,
                        saturatedFat: 30,
                        transFat: 10,
                        polyunsaturatedFat: 10,
                        cholesterol: 10,
                        sodium: '2320mg',
                        dietaryFiber: '',
                        sugar: '',
                        potassium: '',
                        iron: '',
                        calcium: '',
                        vitaminA: '',
                        vitaminC: '',
                      },
                      portionSize: '355g',
                      portion: 'Each',
                      gramWeight: '355',
                      modifiers: [
                        {
                          id: 60,
                          name: 'Extra Protein',
                          description: 'Extra Protein',
                          type: 2,
                          selectionLimit: 1,
                          isRequired: true,
                          min: 0,
                          max: 1,
                          firstFree: 1,
                          modifierItems: [
                            {
                              id: 110,
                              name: 'Sliced Gammon',
                              price: 13.2,
                              priceLabel: 'AUD 13.20',
                              sortOrder: 1,
                              maximumQuantity: 1,
                              description: 'Sliced Gammon',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 100,
                                kilojoules: 100,
                                carbohydrates: 100,
                                fat: 100,
                                protein: 100,
                                saturatedFat: 100,
                                transFat: 100,
                                polyunsaturatedFat: 100,
                                cholesterol: 100,
                              },
                              allergens: [
                                {
                                  id: 'Milk',
                                  name: 'Milk',
                                  contains: true,
                                },
                                {
                                  id: 'Peanut',
                                  name: 'Peanut',
                                  contains: true,
                                },
                                {
                                  id: 'Shellfish',
                                  name: 'Shellfish',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 111,
                              name: 'Flaked Tuna',
                              price: 11.99,
                              priceLabel: 'AUD 11.99',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Flaked Tuna',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 200,
                                kilojoules: 200,
                                carbohydrates: 200,
                                fat: 200,
                                protein: 200,
                                saturatedFat: 200,
                                transFat: 200,
                                polyunsaturatedFat: 200,
                                cholesterol: 200,
                              },
                              allergens: [
                                {
                                  id: 'Eggs',
                                  name: 'Eggs',
                                  contains: true,
                                },
                                {
                                  id: 'Treenuts',
                                  name: 'Treenuts',
                                  contains: true,
                                },
                                {
                                  id: 'Wheat',
                                  name: 'Wheat',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 112,
                              name: 'Grated Cheddar Cheese',
                              price: 8.72,
                              priceLabel: 'AUD 8.72',
                              sortOrder: 3,
                              maximumQuantity: 1,
                              description: 'Grated Cheddar Cheese',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 300,
                                kilojoules: 300,
                                carbohydrates: 300,
                                fat: 300,
                                protein: 300,
                                saturatedFat: 300,
                                transFat: 300,
                                polyunsaturatedFat: 300,
                                cholesterol: 300,
                              },
                            },
                            {
                              id: 113,
                              name: 'Boiled Egg',
                              price: 20.9,
                              priceLabel: 'AUD 20.90',
                              sortOrder: 4,
                              maximumQuantity: 1,
                              description: 'Boiled Egg',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 400,
                                kilojoules: 400,
                                carbohydrates: 400,
                                fat: 400,
                                protein: 400,
                                saturatedFat: 400,
                                transFat: 400,
                                polyunsaturatedFat: 400,
                                cholesterol: 400,
                              },
                            },
                          ],
                        },
                        {
                          id: 61,
                          name: 'Toppings',
                          description: 'Toppings',
                          type: 1,
                          selectionLimit: 4,
                          isRequired: false,
                          min: 0,
                          max: 10,
                          firstFree: 1,
                          modifierItems: [
                            {
                              id: 200,
                              name: 'Tomato',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 1,
                              maximumQuantity: 1,
                              description: 'Fresh Tomato',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 1,
                                kilojoules: 1,
                                carbohydrates: 1,
                                fat: 1,
                                protein: 1,
                                saturatedFat: 1,
                                transFat: 1,
                                polyunsaturatedFat: 1,
                                cholesterol: 1,
                              },
                              allergens: [
                                {
                                  id: 'Gluten',
                                  name: 'Gluten',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 201,
                              name: 'Pickles',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Fresh Pickles',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 2,
                                kilojoules: 2,
                                carbohydrates: 2,
                                fat: 2,
                                protein: 2,
                                saturatedFat: 2,
                                transFat: 2,
                                polyunsaturatedFat: 2,
                                cholesterol: 2,
                              },
                              allergens: [
                                {
                                  id: 'Treenuts',
                                  name: 'Treenuts',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 202,
                              name: 'Olives',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 3,
                              maximumQuantity: 1,
                              description: 'Fresh Olives',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 3,
                                kilojoules: 3,
                                carbohydrates: 3,
                                fat: 3,
                                protein: 3,
                                saturatedFat: 3,
                                transFat: 3,
                                polyunsaturatedFat: 3,
                                cholesterol: 3,
                              },
                            },
                            {
                              id: 203,
                              name: 'Jalapeno',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 4,
                              maximumQuantity: 1,
                              description: 'Fresh Jalapeno',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 4,
                                kilojoules: 4,
                                carbohydrates: 4,
                                fat: 4,
                                protein: 4,
                                saturatedFat: 4,
                                transFat: 4,
                                polyunsaturatedFat: 4,
                                cholesterol: 4,
                              },
                            },
                          ],
                        },
                        {
                          id: 62,
                          name: 'Salad Base',
                          description: 'Salad Base',
                          type: 1,
                          selectionLimit: 2,
                          isRequired: true,
                          min: 1,
                          max: 2,
                          firstFree: 1,
                          modifierItems: [
                            {
                              id: 114,
                              name: 'Greek Salad',
                              price: 1.99,
                              priceLabel: 'AUD 1.99',
                              sortOrder: 1,
                              maximumQuantity: 1,
                              description: 'Greek Salad',
                              min: 0,
                              max: 1,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 10,
                                kilojoules: 10,
                                carbohydrates: 10,
                                fat: 10,
                                protein: 10,
                                saturatedFat: 10,
                                transFat: 10,
                                polyunsaturatedFat: 10,
                                cholesterol: 10,
                              },
                              allergens: [
                                {
                                  id: 'Milk',
                                  name: 'Milk',
                                  contains: true,
                                },
                                {
                                  id: 'Peanut',
                                  name: 'Peanut',
                                  contains: true,
                                },
                                {
                                  id: 'Shellfish',
                                  name: 'Shellfish',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 115,
                              name: 'Cezar Salad',
                              price: 2.99,
                              priceLabel: 'AUD 2.99',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Cezar Salad',
                              min: 0,
                              max: 1,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 20,
                                kilojoules: 20,
                                carbohydrates: 20,
                                fat: 20,
                                protein: 20,
                                saturatedFat: 20,
                                transFat: 20,
                                polyunsaturatedFat: 20,
                                cholesterol: 20,
                              },
                            },
                            {
                              id: 116,
                              name: 'Polish Salad',
                              price: 5.99,
                              priceLabel: 'AUD 5.99',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Polish Salad',
                              min: 0,
                              max: 1,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 20,
                                kilojoules: 20,
                                carbohydrates: 20,
                                fat: 20,
                                protein: 20,
                                saturatedFat: 20,
                                transFat: 20,
                                polyunsaturatedFat: 20,
                                cholesterol: 20,
                              },
                            },
                          ],
                        },
                      ],
                      isVegan: false,
                      isVegetarian: false,
                      isDefault: true,
                      price: 14.2,
                      menuItemId: 0,
                      milkAdditive: false,
                      soyBeansAdditive: false,
                      eggsAdditive: false,
                      sesameSeedsAdditive: false,
                      peanutsAdditive: false,
                      wheatAdditive: false,
                      fishAdditive: false,
                      nutsAdditive: false,
                      co2Rating: '',
                      co2Value: '',
                      alcoholContent: null,
                    },
                    {
                      portionId: 'cfbbfe71-d3e6-4aaf-8428-3e450ac10520',
                      foodItemSourceKey: 'PUR17',
                      name: 'BBQ Brekky Wrap',
                      description:
                        'Tortilla wrap filled with scrambled eggs, bacon, cheese, chorizo sausage and bbq sauce',
                      ingredients: '',
                      allergens: [],
                      foodItemId: 1982,
                      uomId: 9871,
                      nutritions: {
                        calories: 1500,
                        kilojoules: 1000,
                        carbohydrates: 100,
                        fat: 50,
                        protein: 20,
                        saturatedFat: 30,
                        transFat: 10,
                        polyunsaturatedFat: 10,
                        cholesterol: 10,
                        sodium: '2320mg',
                        dietaryFiber: '',
                        sugar: '',
                        potassium: '',
                        iron: '',
                        calcium: '',
                        vitaminA: '',
                        vitaminC: '',
                      },
                      portionSize: '555g',
                      portion: 'Huge',
                      gramWeight: '555',
                      modifiers: [
                        {
                          id: 60,
                          name: 'Extra Protein',
                          description: 'Extra Protein',
                          type: 2,
                          selectionLimit: 1,
                          isRequired: true,
                          min: 0,
                          max: 1,
                          firstFree: 1,
                          modifierItems: [
                            {
                              id: 110,
                              name: 'Sliced Gammon',
                              price: 13.2,
                              priceLabel: 'AUD 13.20',
                              sortOrder: 1,
                              maximumQuantity: 1,
                              description: 'Sliced Gammon',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 100,
                                kilojoules: 100,
                                carbohydrates: 100,
                                fat: 100,
                                protein: 100,
                                saturatedFat: 100,
                                transFat: 100,
                                polyunsaturatedFat: 100,
                                cholesterol: 100,
                              },
                              allergens: [
                                {
                                  id: 'Milk',
                                  name: 'Milk',
                                  contains: true,
                                },
                                {
                                  id: 'Peanut',
                                  name: 'Peanut',
                                  contains: true,
                                },
                                {
                                  id: 'Shellfish',
                                  name: 'Shellfish',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 111,
                              name: 'Flaked Tuna',
                              price: 11.99,
                              priceLabel: 'AUD 11.99',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Flaked Tuna',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 200,
                                kilojoules: 200,
                                carbohydrates: 200,
                                fat: 200,
                                protein: 200,
                                saturatedFat: 200,
                                transFat: 200,
                                polyunsaturatedFat: 200,
                                cholesterol: 200,
                              },
                              allergens: [
                                {
                                  id: 'Eggs',
                                  name: 'Eggs',
                                  contains: true,
                                },
                                {
                                  id: 'Treenuts',
                                  name: 'Treenuts',
                                  contains: true,
                                },
                                {
                                  id: 'Wheat',
                                  name: 'Wheat',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 112,
                              name: 'Grated Cheddar Cheese',
                              price: 8.72,
                              priceLabel: 'AUD 8.72',
                              sortOrder: 3,
                              maximumQuantity: 1,
                              description: 'Grated Cheddar Cheese',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 300,
                                kilojoules: 300,
                                carbohydrates: 300,
                                fat: 300,
                                protein: 300,
                                saturatedFat: 300,
                                transFat: 300,
                                polyunsaturatedFat: 300,
                                cholesterol: 300,
                              },
                            },
                            {
                              id: 113,
                              name: 'Boiled Egg',
                              price: 20.9,
                              priceLabel: 'AUD 20.90',
                              sortOrder: 4,
                              maximumQuantity: 1,
                              description: 'Boiled Egg',
                              min: 0,
                              max: 1,
                              firstFree: 0,
                              isChild: true,
                              nutritions: {
                                calories: 400,
                                kilojoules: 400,
                                carbohydrates: 400,
                                fat: 400,
                                protein: 400,
                                saturatedFat: 400,
                                transFat: 400,
                                polyunsaturatedFat: 400,
                                cholesterol: 400,
                              },
                            },
                          ],
                        },
                        {
                          id: 61,
                          name: 'Toppings',
                          description: 'Toppings',
                          type: 1,
                          selectionLimit: 4,
                          isRequired: false,
                          min: 0,
                          max: 10,
                          firstFree: 1,
                          modifierItems: [
                            {
                              id: 200,
                              name: 'Tomato',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 1,
                              maximumQuantity: 1,
                              description: 'Fresh Tomato',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 1,
                                kilojoules: 1,
                                carbohydrates: 1,
                                fat: 1,
                                protein: 1,
                                saturatedFat: 1,
                                transFat: 1,
                                polyunsaturatedFat: 1,
                                cholesterol: 1,
                              },
                              allergens: [
                                {
                                  id: 'Gluten',
                                  name: 'Gluten',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 201,
                              name: 'Pickles',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Fresh Pickles',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 2,
                                kilojoules: 2,
                                carbohydrates: 2,
                                fat: 2,
                                protein: 2,
                                saturatedFat: 2,
                                transFat: 2,
                                polyunsaturatedFat: 2,
                                cholesterol: 2,
                              },
                              allergens: [
                                {
                                  id: 'Treenuts',
                                  name: 'Treenuts',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 202,
                              name: 'Olives',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 3,
                              maximumQuantity: 1,
                              description: 'Fresh Olives',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 3,
                                kilojoules: 3,
                                carbohydrates: 3,
                                fat: 3,
                                protein: 3,
                                saturatedFat: 3,
                                transFat: 3,
                                polyunsaturatedFat: 3,
                                cholesterol: 3,
                              },
                            },
                            {
                              id: 203,
                              name: 'Jalapeno',
                              price: 0.49,
                              priceLabel: 'AUD 0.49',
                              sortOrder: 4,
                              maximumQuantity: 1,
                              description: 'Fresh Jalapeno',
                              min: 0,
                              max: 4,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 4,
                                kilojoules: 4,
                                carbohydrates: 4,
                                fat: 4,
                                protein: 4,
                                saturatedFat: 4,
                                transFat: 4,
                                polyunsaturatedFat: 4,
                                cholesterol: 4,
                              },
                            },
                          ],
                        },
                        {
                          id: 62,
                          name: 'Salad Base',
                          description: 'Salad Base',
                          type: 1,
                          selectionLimit: 2,
                          isRequired: true,
                          min: 1,
                          max: 2,
                          firstFree: 1,
                          modifierItems: [
                            {
                              id: 114,
                              name: 'Greek Salad',
                              price: 1.99,
                              priceLabel: 'AUD 1.99',
                              sortOrder: 1,
                              maximumQuantity: 1,
                              description: 'Greek Salad',
                              min: 0,
                              max: 1,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 10,
                                kilojoules: 10,
                                carbohydrates: 10,
                                fat: 10,
                                protein: 10,
                                saturatedFat: 10,
                                transFat: 10,
                                polyunsaturatedFat: 10,
                                cholesterol: 10,
                              },
                              allergens: [
                                {
                                  id: 'Milk',
                                  name: 'Milk',
                                  contains: true,
                                },
                                {
                                  id: 'Peanut',
                                  name: 'Peanut',
                                  contains: true,
                                },
                                {
                                  id: 'Shellfish',
                                  name: 'Shellfish',
                                  contains: true,
                                },
                              ],
                            },
                            {
                              id: 115,
                              name: 'Cezar Salad',
                              price: 2.99,
                              priceLabel: 'AUD 2.99',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Cezar Salad',
                              min: 0,
                              max: 1,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 20,
                                kilojoules: 20,
                                carbohydrates: 20,
                                fat: 20,
                                protein: 20,
                                saturatedFat: 20,
                                transFat: 20,
                                polyunsaturatedFat: 20,
                                cholesterol: 20,
                              },
                            },
                            {
                              id: 116,
                              name: 'Polish Salad',
                              price: 5.99,
                              priceLabel: 'AUD 5.99',
                              sortOrder: 2,
                              maximumQuantity: 1,
                              description: 'Polish Salad',
                              min: 0,
                              max: 1,
                              firstFree: 1,
                              isChild: true,
                              nutritions: {
                                calories: 20,
                                kilojoules: 20,
                                carbohydrates: 20,
                                fat: 20,
                                protein: 20,
                                saturatedFat: 20,
                                transFat: 20,
                                polyunsaturatedFat: 20,
                                cholesterol: 20,
                              },
                            },
                          ],
                        },
                      ],
                      isVegan: false,
                      isVegetarian: false,
                      isDefault: true,
                      price: 19.2,
                      menuItemId: 0,
                      milkAdditive: false,
                      soyBeansAdditive: false,
                      eggsAdditive: false,
                      sesameSeedsAdditive: false,
                      peanutsAdditive: false,
                      wheatAdditive: false,
                      fishAdditive: false,
                      nutsAdditive: false,
                      co2Rating: '',
                      co2Value: '',
                      alcoholContent: null,
                      isMindful: false,
                    },
                  ],
                  listImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2408-20210827105714-foodImg.jpg',
                  detailsImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2408-20200512012741-Breakky_Wrap.jpg',
                },
                {
                  menuId: 160,
                  menuItemId: 4463877,
                  name: 'Breakfast Burger',
                  mealName: 'Breakfast',
                  productCategory: 'Breakfast Favourites',
                  foodSubCategoryDescription: null,
                  description:
                    'Beef burger patty on a brioche roll with bacon, egg, cheese and chipotle bbq sauce. Served with potato gems',
                  isOrderable: true,
                  sortOrder: 1,
                  price: 14.59,
                  startTime: today.setHours(0),
                  endTime: today.setHours(23),
                  productPortions: [
                    {
                      portionId: '940ecc9f-a432-4d6b-823e-90b9528e2e48',
                      foodItemSourceKey: 'PUR17',
                      name: 'Breakfast Burger',
                      description:
                        'Beef burger patty on a brioche roll with bacon, egg, cheese and chipotle bbq sauce. Served with potato gems',
                      ingredients: '',
                      allergens: [],
                      nutritions: {
                        calories: 1130,
                        kilojoules: 0,
                        carbohydrates: 92,
                        fat: 58,
                        protein: 58,
                        saturatedFat: 0,
                        transFat: 0,
                        polyunsaturatedFat: 0,
                        cholesterol: 0,
                        sodium: 2960,
                        dietaryFiber: 0,
                        sugar: 0,
                        potassium: 0,
                        iron: 0,
                        calcium: 0,
                        vitaminA: 0,
                        vitaminC: 0,
                      },
                      portionSize: '535g',
                      portion: 'Each',
                      gramWeight: '535',
                      modifiers: [],
                      isVegan: false,
                      isVegetarian: false,
                      isDefault: true,
                      price: 14.59,
                      menuItemId: 0,
                      milkAdditive: false,
                      soyBeansAdditive: false,
                      eggsAdditive: false,
                      sesameSeedsAdditive: false,
                      peanutsAdditive: false,
                      wheatAdditive: false,
                      fishAdditive: false,
                      nutsAdditive: false,
                      co2Rating: '',
                      co2Value: '',
                      alcoholContent: null,
                      isMindful: false,
                      foodItemId: 1982,
                      uomId: 2100,
                    },
                  ],
                  listImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2409-20200512012708-Breakfast_Burger.jpg',
                  detailsImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2409-20200512012750-Breakfast_Burger.jpg',
                },
                {
                  menuId: 160,
                  menuItemId: 4463878,
                  name: 'Loaded Potato Gems',
                  mealName: 'Breakfast',
                  productCategory: 'Breakfast Favourites',
                  foodSubCategoryDescription: null,
                  description:
                    'Potato gems topped with crispy bacon pieces, melted cheese and sour cream',
                  isOrderable: true,
                  sortOrder: 2,
                  price: 23.68,
                  startTime: today.setHours(0),
                  endTime: today.setHours(23),
                  productPortions: [
                    {
                      portionId: 'c35f7b55-d4be-44a0-8e2a-f0e11194a24c',
                      foodItemSourceKey: 'PUR18',
                      name: 'Loaded Potato Gems',
                      description:
                        'Potato gems topped with crispy bacon pieces, melted cheese and sour cream',
                      ingredients: '',
                      uomBarcodes: ['78633'],
                      allergens: [],
                      nutritions: {
                        calories: 990,
                        kilojoules: 0,
                        carbohydrates: 48,
                        fat: 72,
                        protein: 36,
                        saturatedFat: 0,
                        transFat: 0,
                        polyunsaturatedFat: 0,
                        cholesterol: 0,
                        sodium: 2300,
                        dietaryFiber: 0,
                        sugar: 0,
                        potassium: 0,
                        iron: 0,
                        calcium: 0,
                        vitaminA: 0,
                        vitaminC: 0,
                      },
                      portionSize: '425g',
                      portion: 'Each',
                      gramWeight: '425',
                      modifiers: [],
                      isVegan: false,
                      isVegetarian: false,
                      isDefault: true,
                      price: 23.68,
                      menuItemId: 0,
                      milkAdditive: false,
                      soyBeansAdditive: false,
                      eggsAdditive: false,
                      sesameSeedsAdditive: false,
                      peanutsAdditive: false,
                      wheatAdditive: false,
                      fishAdditive: false,
                      nutsAdditive: false,
                      co2Rating: '',
                      co2Value: '',
                      alcoholContent: null,
                      isMindful: false,
                      foodItemId: 1982,
                      uomId: 2101,
                    },
                  ],
                  listImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2410-20200512012723-Loaded_Potato_Gems.jpg',
                  detailsImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2410-20200512012758-Loaded_Potato_Gems.jpg',
                },
                {
                  menuId: 160,
                  menuItemId: 4463879,
                  name: 'Chicken Nuggets',
                  mealName: 'Breakfast',
                  productCategory: 'Breakfast Favourites',
                  foodSubCategoryDescription: null,
                  description: '10 Chicken nuggets served with tomato sauce',
                  isOrderable: true,
                  sortOrder: 3,
                  price: 0.4,
                  startTime: today.setHours(0),
                  endTime: today.setHours(23),
                  productPortions: [
                    {
                      portionId: 'bd4c43a7-b311-44f7-a541-f2cdd21bf6a1',
                      foodItemSourceKey: 'PUR19',
                      name: 'Chicken Nuggets',
                      description: '10 Chicken nuggets served with tomato sauce',
                      ingredients: '',
                      allergens: [],
                      nutritions: {
                        calories: 0,
                        kilojoules: 0,
                        carbohydrates: 0,
                        fat: 0,
                        protein: 0,
                        saturatedFat: 0,
                        transFat: 0,
                        polyunsaturatedFat: 0,
                        cholesterol: 0,
                        sodium: 0,
                        dietaryFiber: 0,
                        sugar: 0,
                        potassium: 0,
                        iron: 0,
                        calcium: 0,
                        vitaminA: 0,
                        vitaminC: 0,
                      },
                      portionSize: '0g',
                      portion: 'Each',
                      gramWeight: '0',
                      modifiers: [],
                      isVegan: false,
                      isVegetarian: false,
                      isDefault: true,
                      price: 0.4,
                      menuItemId: 0,
                      milkAdditive: false,
                      soyBeansAdditive: false,
                      eggsAdditive: false,
                      sesameSeedsAdditive: false,
                      peanutsAdditive: false,
                      wheatAdditive: false,
                      fishAdditive: false,
                      nutsAdditive: false,
                      co2Rating: '',
                      co2Value: '',
                      alcoholContent: null,
                      isMindful: false,
                      foodItemId: 1982,
                      uomId: 2102,
                    },
                  ],
                  listImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2411-20200512012730-Chicken_Nuggets.jpg',
                  detailsImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2411-20200512012805-Chicken_Nuggets.jpg',
                },
                {
                  menuId: 140,
                  menuItemId: 4461851,
                  name: 'TIMTAM ORIGINAL 200G',
                  mealName: 'Anytime',
                  productCategory: 'Snack Food',
                  foodSubCategoryDescription: null,
                  description: '',
                  isOrderable: true,
                  sortOrder: 0,
                  price: 6.0,
                  startTime: today.setHours(0),
                  endTime: today.setHours(23),
                  productPortions: [
                    {
                      portionId: '884e50b3-0946-4a47-aa59-0f09c93167bd',
                      foodItemSourceKey: 'PLU13196',
                      name: 'TIMTAM ORIGINAL 200G',
                      description: '',
                      ingredients: '',
                      allergens: [],
                      nutritions: {
                        calories: 0,
                        kilojoules: 0,
                        carbohydrates: 0,
                        fat: 0,
                        protein: 0,
                        saturatedFat: 0,
                        transFat: 0,
                        polyunsaturatedFat: 0,
                        cholesterol: 0,
                        sodium: 0,
                        dietaryFiber: 0,
                        sugar: 0,
                        potassium: 0,
                        iron: 0,
                        calcium: 0,
                        vitaminA: 0,
                        vitaminC: 0,
                      },
                      portionSize: '',
                      portion: 'Each',
                      gramWeight: '0',
                      modifiers: [],
                      isVegan: false,
                      isVegetarian: true,
                      isDefault: true,
                      price: 6.0,
                      menuItemId: 0,
                      milkAdditive: false,
                      soyBeansAdditive: false,
                      eggsAdditive: false,
                      sesameSeedsAdditive: false,
                      peanutsAdditive: false,
                      wheatAdditive: false,
                      fishAdditive: false,
                      nutsAdditive: false,
                      co2Rating: '',
                      co2Value: '',
                      alcoholContent: null,
                      isMindful: false,
                      foodItemId: 1982,
                      uomId: 1794,
                    },
                  ],
                  listImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2107-20200319050412-Tim_Tam_Original.jpg',
                  detailsImage:
                    'https://sodexoitzstageau.blob.core.windows.net/fooditem/2107-20200319050335-Tim_Tam_Original.jpg',
                },
                {
                  menuId: 140,
                  menuItemId: 4460544,
                  name: 'NOBBYS CASHEWS 50G',
                  mealName: 'Dinner',
                  productCategory: 'Snack Food',
                  foodSubCategoryDescription: null,
                  description: '',
                  isOrderable: true,
                  sortOrder: 0,
                  price: 4.4,
                  startTime: today.setHours(0),
                  endTime: today.setHours(23),
                  productPortions: [
                    {
                      portionId: '832babc9-c8e6-4e83-9358-29e0a2702d5b',
                      foodItemSourceKey: 'PLU14826',
                      name: 'NOBBYS CASHEWS 50G',
                      description: '',
                      ingredients: '',
                      allergens: [
                        {
                          id: 'Milk',
                          name: 'Milk',
                          contains: true,
                        },
                        {
                          id: 'Treenuts',
                          name: 'Treenuts',
                          contains: true,
                        },
                        {
                          id: 'Peanut',
                          name: 'Peanut',
                          contains: true,
                        },
                        {
                          id: 'Nuts',
                          name: 'Nuts',
                          contains: true,
                        },
                        {
                          id: 'Soybean',
                          name: 'Soybean',
                          contains: true,
                        },
                        {
                          id: 'Sesame',
                          name: 'Sesame',
                          contains: true,
                        },
                        {
                          id: 'Gluten',
                          name: 'Gluten',
                          contains: true,
                        },
                      ],
                      nutritions: {
                        calories: 290,
                        kilojoules: 0,
                        carbohydrates: 8,
                        fat: 26,
                        protein: 10,
                        saturatedFat: 0,
                        transFat: 0,
                        polyunsaturatedFat: 0,
                        cholesterol: 0,
                        sodium: 240,
                        dietaryFiber: 0,
                        sugar: 0,
                        potassium: 0,
                        iron: 0,
                        calcium: 0,
                        vitaminA: 0,
                        vitaminC: 0,
                      },
                      portionSize: '50g',
                      portion: 'Each',
                      gramWeight: '50',
                      modifiers: [],
                      isVegan: true,
                      isVegetarian: true,
                      isDefault: true,
                      price: 4.4,
                      menuItemId: 0,
                      milkAdditive: true,
                      soyBeansAdditive: true,
                      eggsAdditive: false,
                      sesameSeedsAdditive: true,
                      peanutsAdditive: true,
                      wheatAdditive: false,
                      fishAdditive: false,
                      nutsAdditive: true,
                      co2Rating: '',
                      co2Value: '',
                      alcoholContent: null,
                      isMindful: false,
                      foodItemId: 1982,
                      uomId: 1782,
                    },
                  ],
                  listImage:
                    "https://sodexoitzstageau.blob.core.windows.net/fooditem/2095-20200319043019-Nobby's_Salted_Cashews_50g.jpg",
                  detailsImage:
                    "https://sodexoitzstageau.blob.core.windows.net/fooditem/2095-20200319050309-Nobby's_Salted_Cashews_50g.jpg",
                },
              ],
            }),
          500
        )
      );
    },
  },
  getPromotions: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 1,
          name: 'Promotion for Aspretto Americano',
          description: 'Unique promotion for apsretto americano!',
          discount: 0,
          threshold: 2,
          items: [
            {
              discount: 0,
              uomId: 2808,
              menuIds: [112],
            },
            {
              discount: 0,
              uomId: 5034,
              menuIds: [112],
            },
            {
              discount: 0,
              uomId: 5033,
              menuIds: [112],
            },
            {
              discount: 0,
              uomId: 10595,
              menuIds: [112],
            },
          ],
        },
        {
          id: 2,
          name: 'Promotion for Aspretto Cafe',
          description: 'Unique promotion for apsretto cafe!',
          discount: 1,
          threshold: 2,
          items: [
            {
              discount: 0,
              uomId: 2099,
              menuIds: [190, 160],
            },
          ],
        },
        {
          id: 3,
          name: 'Promotion for Cart',
          description: 'Unique promotion for cart!',
          discount: 2,
          threshold: 2,
          items: [],
        },
        {
          id: 4,
          name: 'Promotion for location',
          description: 'Unique promotion for location!',
          discount: 3,
          threshold: 1,
          items: [],
        },
        {
          id: 5,
          name: 'Promotion for TimTam',
          description: 'Unique promotion for TimTam!',
          discount: 1,
          threshold: 0,
          items: [
            {
              discount: 1,
              uomId: 1794,
              menuIds: [140],
            },
          ],
        },
      ]),
  },
  getDraftOrder: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        orderId: 133544,
        siteId: '71a8dc31-1337-e811-a955-000d3a2bcac2',
        facilityId: 'be2d93ef-ebd9-ed11-9ac8-0003ff69197e',
        orderTotal: 0,
        contactId: '124c5a44-2393-ed11-aad1-6045bd8c55a5',
        contactPhoneNumber: 's4234',
        menuId: 859,
        timeSlotId: 50285,
        pickupSpot: {
          id: 73,
          name: 'Counter Pickup',
        },
        pickupDate: new Date(),
        orderList: [
          {
            cartMenuItemId: '34f7acf1-7395-4083-8aec-a267aaa4606e',
            menuItemId: 15807956,
            foodItemId: 21725,
            quantity: 4,
            modifiers: [],
            uomId: 22136,
          },
        ],
        instructions: 'sdfdsf',
        trackingId: 'def1c6f9-1e45-4fd1-8fdc-3050df84a0ef',
        theme: [],
        systemId: 'DSP',
      }),
  },
  closeKioskSession: {
    ok: true,
    status: 201,
    json: () => Promise.resolve({}),
  },
  printReceipt: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 3000));
    },
  },
  downloadReceipt: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 3000));
    },
  },
  emailReceipt: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 3000));
    },
  },
  getLastOrderForTransactionalFeedback: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        facilityId: 'be2d93ef-ebd9-ed11-9ac8-0003ff69197e',
        fulfillmentType: DeliveryOptionType.DineIn,
        orderId: 133544,
        orderNumber: '133544',
        menuId: 160,
        pickupDate: new Date().toDateString(),
        total: 12.1,
      }),
  },
  saveLastTransactionalFeedbackDate: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 3000));
    },
  },
  addProductToFavorites: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 500));
    },
  },
  removeProductFromFavorites: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) => setTimeout(() => resolve({}), 500));
    },
  },
  paymentApi: {
    getPaymentMinimumAmountInfo: {
      ok: true,
      status: 200,
      json: () => {
        return new Promise((resolve) => setTimeout(() => resolve({ minimumAmount: 1 }), 500));
      },
    },
  },
};

export default mockData;
