const labels = {
  ReviewForm: {
    'Ref: Page title': 'Review',
    'Ref: How was dining with us': 'How was dining with us?',
    'Ref: Submit': 'Submit',
    'Ref: Filter range': 'Filter range',
    'Ref: Some information is missing': 'Some information is missing',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Send anonymously',
    'Ref: Your email address': 'Your email address',
    'Ref: Email field placeholder': 'Enter your email address',
    'Ref: Invalid email format': 'Invalid email format',
  },
  SuccessPage: {
    'Ref: Body':
      'Thank you for completing this review, your time is valuable as this review will help improve our services.',
    'Ref: Go to Feedback': 'Go to Feedback',
    'Ref: Go to home': 'Go to Home',
    'Ref: Go to Facilities': 'Go to Facilities',
    'Ref: Go to Product Details': 'Go to Product Details',
    'Ref: Go to Facility Details': 'Go to Facility Details',
  },
  FacilityPicker: {
    'Ref: Page title': 'Review',
    'Ref: Search facility name': 'Search facility name',
  },
};
export default labels;
