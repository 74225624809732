import classNames from 'classnames';
import { FC } from 'react';

import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import ProgressBar from '../../../../components/atoms/ProgressBar/ProgressBar';
import { TITLE_SIZE } from '../../../../components/atoms/Title';
import Slider from '../../../../components/molecules/Slider';
import { defaultValues } from '../../config';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';

import { SuggestionSliderProps } from './SuggestionSlider.types';

import styles from './SuggestionSlider.module.css';

const SuggestionSlider: FC<SuggestionSliderProps> = ({
  id,
  rows = 2,
  isForMobile = false,
  showSuggestionPopup,
  closePopupResetTime,
  showProgressBar,
  remainingSeconds,
  stopCountdown,
  children,
  className,
  adaptiveHeight = true,
  'data-testid': dataTestId = 'suggestion-slider',
}) => {
  const { label } = useOrderTranslation(__filename);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      if (stopCountdown) stopCountdown();
    }
  };

  return (
    <div
      className={classNames(
        styles.sliderContainer,
        className,
        {
          [styles.mobileSlider]: isForMobile,
        },
        {
          [styles.hideMobileSlider]: isForMobile && !showSuggestionPopup,
        }
      )}
      data-testid={dataTestId}
      onClick={stopCountdown}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <Slider
        id={id}
        title={label('Suggestion')}
        titleSize={TITLE_SIZE.HEADLINEXS}
        slidesToScroll={1}
        slidesToShow={1}
        rows={rows}
        dots
        swipe
        onSwipe={stopCountdown}
        adaptiveHeight={adaptiveHeight}
      >
        {children}
      </Slider>
      {isForMobile && (
        <>
          <Button
            look={BUTTON_LOOK.SECONDARY}
            inheritStyle={styles.closeButton}
            contentCenterAlign={true}
            onClick={closePopupResetTime}
            data-testid={`suggestion-slider-${dataTestId}-close-button`}
          >
            {label('close')}
          </Button>
          <ProgressBar
            data-testid="suggestion-slider"
            className={classNames(styles.progressBar, {
              [styles.hideProgressBar]: !showProgressBar,
            })}
            value={remainingSeconds}
            max={defaultValues.suggestionsCountdownSeconds}
            formatProgressLabel={() => null}
          />
        </>
      )}
    </div>
  );
};

export default SuggestionSlider;
