export const isValidHttpUrl = (url: string): boolean => {
  let finalUrl;
  try {
    finalUrl = new URL(url);
  } catch (_) {
    return false;
  }

  return finalUrl.protocol === 'http:' || finalUrl.protocol === 'https:';
};
