import { FacilityDataItem } from '../../Facilities/types/types';
import {
  AllergenType,
  Cart,
  CartMenuItem,
  FacilityMenu,
  FacilityMenuSource,
  MenuItem,
  Modifier,
  NutritionFacts,
  ProductPortion,
  ReorderMenuItem,
  SuggestedMenuItem,
} from '../types/orderState.types';

export const defaultCart: Cart = {
  siteId: '',
  facilityId: '',
  submissionTrackingId: '',
  menuId: 12,
  date: new Date(),
  menuPortionItems: [
    {
      id: '',
      menuItemId: 1,
      uomId: 1,
      foodItemId: 1,
      img: '',
      name: '',
      price: 20,
      quantity: 1,
      description: '',
      isVegan: false,
      isVegetarian: false,
      genericCategory: '',
    },
  ],
  moment: '',
};

export const cartWithPortion = {
  ...defaultCart,
  menuPortionItems: [
    defaultCart.menuPortionItems?.[0]!,
    {
      ...defaultCart.menuPortionItems?.[0]!,
      menuItemId: 2,
      uomId: 222,
      foodItemId: 2,
      price: 11,
      isVegan: true,
      isVegetarian: true,
    },
  ],
};

export const defaultMenuItem: MenuItem = {
  name: '',
  mealName: '',
  foodSubCategoryDescription: '',
  description: '',
  isOrderable: true,
  price: 12,
  startTime: new Date(),
  endTime: new Date(),
  listImage: '',
  detailsImage: '',
  menuItemId: 1,
  productPortions: [],
  dayPart: null,
  section: null,
  subSection: null,
  genericCategory: '',
  minimalPrice: 10,
};

export const defaultFacilityMenu: FacilityMenu = {
  id: 238,
  facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
  name: 'Aspretto Cafe',
  message:
    "If you have a food allergy or intolerance (or someone you're ordering for has), please contact the site team. Do not order if you cannot get the allergy information you need.",
  date: '2022-11-22T00:00:00',
  isConferenceCatering: false,
  isScanAndGo: false,
  fulfillmentTypes: [],
  isOrderable: true,
  menuItems: [],
  source: FacilityMenuSource.Rr,
};

export const defaultFacilityDataItem: FacilityDataItem = {
  title: 'My facility',
  name: 'facility name',
  id: 'facility1',
  sequence: 1,
  images: [],
  facilityType: { id: 'test', name: 'Food - Retail' },
  viewCounts: 1,
  externalUrl: null,
};

export const defaultProductPortion: ProductPortion = {
  foodItemSourceKey: '',
  foodItemId: 1,
  name: '',
  description: '',
  ingredients: '',
  portionSize: '',
  portion: '',
  gramWeight: '',
  isVegan: true,
  isVegetarian: true,
  isMindful: true,
  isDefault: true,
  price: 11,
  viewablePrices: [{ name: '', value: 11 }],
  allergens: [],
  nutritions: {} as NutritionFacts,
  modifiers: [],
  portionId: '',
  co2Rating: '',
  co2Value: '',
  uomId: 111,
  uomBarcodes: [],
  alcoholContent: 1,
  isFavorite: false,
};

export const menuItemWithPortions = {
  ...defaultMenuItem,
  menuItemId: 2,
  productPortions: [
    { ...defaultProductPortion, foodItemId: 2, portionId: '22', uomId: 222 },
    { ...defaultProductPortion, foodItemId: 2, portionId: '33', uomId: 333 },
  ],
};

export const selectedProductPortion = { ...defaultProductPortion, foodItemId: 2, uomId: 222 };

export const defaultReorderProposition: ReorderMenuItem = {
  menuId: 1,
  menuItemId: 1,
  name: 'Name1',
  description: 'Description',
  price: 1,
  productPortions: [],
  genericCategory: '',
};

export const defaultSuggestion: SuggestedMenuItem = {
  ...defaultReorderProposition,
  productCategory: 'Snack',
};

export const defaultModifier: Modifier = {
  id: 16,
  description: 'modifier desc',
  firstFree: 2,
  isRequired: false,
  name: 'Toppings',
  type: 1,
  max: 9,
  min: 0,
  selectionLimit: 2,
  modifierItems: [
    {
      isChild: true,
      name: 'salted caramel',
      id: 1,
      sortOrder: 1,
      description: 'yummy',
      price: 2,
      priceLabel: '2$',
      firstFree: 8,
      max: 9,
      min: 0,
      maximumQuantity: 16,
      nutritions: {
        calcium: 10,
        calories: 11,
        sugar: 12,
        caloriesUnits: 13,
        carbohydrates: 14,
        cholesterol: 15,
        dietaryFiber: 16,
        fat: 16,
        iron: 17,
        kilojoules: 18,
        polyunsaturatedFat: 19,
        potassium: 20,
        protein: 21,
        saturatedFat: 22,
        sodium: 23,
        transFat: 24,
        vitaminA: 25,
        vitaminC: 26,
      },
      allergens: [
        {
          allergen: AllergenType.Eggs,
          contains: true,
          id: AllergenType.Eggs,
          name: 'Special eggs to salted caramel',
        },
        {
          allergen: AllergenType.Alcohol,
          contains: true,
          id: AllergenType.Alcohol,
          name: 'Special Alcohol to salted caramel',
        },
        {
          allergen: AllergenType.Milk,
          contains: true,
          id: AllergenType.Milk,
          name: 'Special Milk to salted caramel',
        },
      ],
    },
  ],
};

export const defaultNutritions = {
  calcium: 0,
  calories: 0,
  carbohydrates: 0,
  cholesterol: 0,
  dietaryFiber: 0,
  fat: 0,
  iron: 0,
  kilojoules: 0,
  polyunsaturatedFat: 0,
  potassium: 0,
  protein: 0,
  saturatedFat: 0,
  sodium: 0,
  sugar: 0,
  transFat: 0,
  vitaminA: 0,
  vitaminC: 0,
  caloriesUnits: 0,
};

export const defaultCartMenuItem: CartMenuItem = {
  id: '',
  menuItemId: 1,
  uomId: 1,
  foodItemId: 1,
  img: '',
  name: '',
  price: 20,
  quantity: 1,
  description: '',
  isVegan: false,
  isVegetarian: false,
  genericCategory: '',
};
