import { act, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory, createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { cleanCart } from '../actions';
import * as api from '../api/api';
import * as useCheckCanOrderHook from '../hooks/useCheckCanOrder/useCheckCanOrder';

import Cart from './Cart';

import { renderedComponent } from '@/helpers/tests/renderComponent';
import { SERVICE } from '@/modules/config';

jest.mock('../api/api', () => ({
  useGetAvailablePromotionsQuery: jest.fn(),
}));

jest.mock('../actions', () => ({
  ...jest.requireActual('../actions'),
  getMenus: jest.fn().mockImplementation(() => Promise.resolve()),
  getPickupLocations: jest.fn().mockImplementation(() => Promise.resolve()),
}));

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: jest.fn(),
}));

jest.mock('../helpers/orderDraft.helper', () => ({
  ...jest.requireActual('../helpers/orderDraft.helper'),
  processCartChange: jest.fn().mockImplementation(() => Promise.resolve()),
}));

jest.mock('../hooks/useModuleContext', () => ({
  __esModule: true,
  default: jest.fn(),
}));

jest.mock('../hooks/useSiteSwitch/useSwitchSite', () => ({
  useSwitchSite: () => ({
    switchSite: jest.fn(),
  }),
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: jest.fn(),
}));

jest.mock('../../Core/helpers/tokensHelpers', () => ({
  ...jest.requireActual('../../Core/helpers/tokensHelpers'),
  getTokenPayload: () => ({
    roles: ['Consumer App User - Conference Catering - Site'],
    contractId: 'someContractId',
    registrationSiteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
    userName: 'someUserName',
    tokenValidTill: 9999999999999,
    isSSO: false,
  }),
}));

jest.mock('@/helpers/hooks/Analytics/useTrackPageView', () => ({
  useTrackPageView: jest.fn(),
}));

// history
const mockPush = jest.fn();
const mockHistory = createMemoryHistory();
mockHistory.push = mockPush;

// setup options
jest.mock('../helpers/cart.helper', () => ({
  ...jest.requireActual('../helpers/cart.helper'),
  getPaymentSetupOptions: () => ({
    merchantIdSetupOption: {
      isActive: true,
      value: 'PlSodexoTEST-RetailEU(stripe)',
      option: {
        name: 'merchant',
        value: 'PlSodexoTEST-RetailEU(stripe)',
        code: 'merchant',
        isServiceType: true,
        isServicePointer: false,
      },
    },
    paymentOnPickupSetupOption: {
      isActive: true,
      value: 'true',
      option: {
        name: 'paymentOnPickup',
        value: 'true',
        code: 'paymentOnPickup',
        isServiceType: true,
        isServicePointer: false,
      },
    },
    disableContinueFlowFromKioskOnMobileSetupOption: {
      isActive: false,
    },
  }),
}));

let defaultState = {
  Core: {
    access: {
      shouldSelectSiteContext: false,
    },
    context: {
      site: {
        id: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
        currency: {
          currencyName: 'US Dollar',
          currencySymbol: '$',
          isoCode: 'USD',
          exchangeRate: 1,
          precision: 2,
        },
      },
    },
    user: {
      acknowledgements: [
        {
          documentId: 'ea5ed940-7719-ed11-b83e-000d3aad9206',
          type: {
            name: 'Privacy Policy',
            value: 224960001,
          },
          acknowledgedOn: '2024-01-30T00:00:00Z',
        },
        {
          documentId: 'af5a5c5b-c832-ea11-a813-000d3a25c7c2',
          type: {
            value: 224960001,
          },
          acknowledgedOn: '2024-1-31',
        },
      ],
      contract: {
        id: 'a05e35fd-652e-e811-a952-000d3a2bc5c1',
        code: 'SANOFI',
        name: 'Sanofi Pharmaceutical',
      },
      preferredLanguage: {
        id: '4bba9048-623f-4d2f-aa81-64da7fac16a4',
        name: 'English',
        displayName: 'English',
        systemLanguage: 1033,
        languageCode: 'en-US',
        lcid: 1033,
      },
      theme: null,
      username: 'apptestDM@bite.com',
      firstName: 'Apptest',
      qrCode: '',
      contactId: '73d96080-87bf-ee11-9079-00224884d03a',
      lastName: 'Test',
      email: 'apptestTST@bite.com',
      mobile: '',
      communicationPreferences: {
        allowContent: '',
        allowOffers: '',
      },
      preferredLocation: null,
    },
    services: {
      list: [
        {
          id: '492f2461-7c2a-ec11-b76a-0003ff4ccf48',
          name: 'Retail',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Retail for Sanofi Allston',
            isEnabled: true,
            orderIndex: 1,
          },
          setupOptions: [
            {
              name: 'suggestionsOnCart',
              value: 'true',
              code: 'suggestionsOnCart',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'suggestionsOnHome',
              value: 'true',
              code: 'suggestionsOnHome',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'suggestionsOnProductDetails',
              value: 'true',
              code: 'suggestionsOnProductDetails',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'paymentOnPickup',
              value: 'true',
              code: 'paymentOnPickup',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'suggestionsOnProductList',
              value: 'false',
              code: 'suggestionsOnProductList',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'transactionalFeedbackFrom',
              value: '2023-10-01',
              code: 'transactionalFeedbackFrom',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'hideTaxAndSubtotalAmount',
              value: 'true',
              code: 'hideTaxAndSubtotalAmount',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'merchant',
              value: 'PlSodexoTEST-RetailEU(stripe)',
              code: 'merchant',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        },
      ],
      visited: [],
    },
  },
  Order: {
    menus: [
      {
        id: 3329,
        facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        name: 'Bite Everyday',
        isOrderable: true,
        logoUrl:
          'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
        date: new Date().toISOString().split('T')[0],
        fulfillmentTypes: [
          {
            id: 28,
            type: 'DineIn',
          },
          {
            id: 10214,
            type: 'PickupOption',
          },
          {
            id: 18979,
            type: 'Delivery',
          },
        ],
        isFutureOrdering: true,
        menuItems: [
          {
            name: 'Granny Smith Applee',
            mealName: 'All day groceries',
            foodSubCategoryDescription: null,
            description:
              'The Granny Smith, also known as a green apple or sour apple, is an apple cultivar that originated in Australia in 1868. It is named after Maria Ann Smith, who propagated the cultivar from a chance seedling. The tree is thought to be a hybrid of Malus sylvestris, the European wild apple, with the domesticated apple Malus domestica as the polleniser.',
            isOrderable: true,
            sortOrder: 0,
            price: 1,
            minimalPrice: 1,
            viewablePrices: null,
            startTime: '0001-01-01T00:00:00',
            endTime: '0001-01-01T00:00:00',
            productPortions: [
              {
                portionId: '17221336_30551',
                foodItemSourceKey: '',
                name: 'Granny Smith Applee',
                description:
                  'The Granny Smith, also known as a green apple or sour apple, is an apple cultivar that originated in Australia in 1868. It is named after Maria Ann Smith, who propagated the cultivar from a chance seedling. The tree is thought to be a hybrid of Malus sylvestris, the European wild apple, with the domesticated apple Malus domestica as the polleniser.',
                ingredients: null,
                allergens: [
                  {
                    id: 'Milk',
                    name: 'Milk',
                    contains: true,
                  },
                  {
                    id: 'Eggs',
                    name: 'Eggs',
                    contains: true,
                  },
                  {
                    id: 'Treenuts',
                    name: 'Treenuts',
                    contains: true,
                  },
                  {
                    id: 'Celery',
                    name: 'Celery',
                    contains: true,
                  },
                  {
                    id: 'Sulphites',
                    name: 'Sulphites',
                    contains: true,
                  },
                  {
                    id: 'Fish',
                    name: 'Fish',
                    contains: true,
                  },
                  {
                    id: 'Peanut',
                    name: 'Peanut',
                    contains: true,
                  },
                  {
                    id: 'Nuts',
                    name: 'Nuts',
                    contains: true,
                  },
                  {
                    id: 'Shellfish',
                    name: 'Shellfish',
                    contains: true,
                  },
                  {
                    id: 'Soybean',
                    name: 'Soybean',
                    contains: true,
                  },
                  {
                    id: 'Mustard',
                    name: 'Mustard',
                    contains: true,
                  },
                  {
                    id: 'Molluscs',
                    name: 'Molluscs',
                    contains: true,
                  },
                  {
                    id: 'Sesame',
                    name: 'Sesame Seeds',
                    contains: true,
                  },
                  {
                    id: 'Crustaceans',
                    name: 'Crustaceans',
                    contains: true,
                  },
                  {
                    id: 'Lupin',
                    name: 'Lupin',
                    contains: true,
                  },
                  {
                    id: 'MSG',
                    name: 'MSG',
                    contains: true,
                  },
                ],
                nutritions: {
                  calories: 100,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 5,
                  sugar: 11,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '146g',
                portion: 'standard',
                gramWeight: '146',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: true,
                price: 1,
                viewablePrices: null,
                milkAdditive: true,
                soyBeansAdditive: true,
                eggsAdditive: true,
                sesameSeedsAdditive: true,
                peanutsAdditive: true,
                wheatAdditive: true,
                fishAdditive: true,
                nutsAdditive: true,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30640,
                foodItemId: 28508,
                additives: null,
                uomBarcodes: [],
                alcoholContent: 45,
              },
              {
                portionId: '17221336_30630',
                foodItemSourceKey: '',
                name: 'Granny Smith Applee',
                description:
                  'The Granny Smith, also known as a green apple or sour apple, is an apple cultivar that originated in Australia in 1868. It is named after Maria Ann Smith, who propagated the cultivar from a chance seedling. The tree is thought to be a hybrid of Malus sylvestris, the European wild apple, with the domesticated apple Malus domestica as the polleniser.',
                ingredients: null,
                allergens: [
                  {
                    id: 'Milk',
                    name: 'Milk',
                    contains: true,
                  },
                  {
                    id: 'Eggs',
                    name: 'Eggs',
                    contains: true,
                  },
                  {
                    id: 'Treenuts',
                    name: 'Treenuts',
                    contains: true,
                  },
                  {
                    id: 'Celery',
                    name: 'Celery',
                    contains: true,
                  },
                  {
                    id: 'Sulphites',
                    name: 'Sulphites',
                    contains: true,
                  },
                  {
                    id: 'Fish',
                    name: 'Fish',
                    contains: true,
                  },
                  {
                    id: 'Peanut',
                    name: 'Peanut',
                    contains: true,
                  },
                  {
                    id: 'Nuts',
                    name: 'Nuts',
                    contains: true,
                  },
                  {
                    id: 'Shellfish',
                    name: 'Shellfish',
                    contains: true,
                  },
                  {
                    id: 'Soybean',
                    name: 'Soybean',
                    contains: true,
                  },
                  {
                    id: 'Mustard',
                    name: 'Mustard',
                    contains: true,
                  },
                  {
                    id: 'Molluscs',
                    name: 'Molluscs',
                    contains: true,
                  },
                  {
                    id: 'Sesame',
                    name: 'Sesame Seeds',
                    contains: true,
                  },
                  {
                    id: 'Crustaceans',
                    name: 'Crustaceans',
                    contains: true,
                  },
                  {
                    id: 'Lupin',
                    name: 'Lupin',
                    contains: true,
                  },
                  {
                    id: 'MSG',
                    name: 'MSG',
                    contains: true,
                  },
                ],
                nutritions: {
                  calories: 80,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 4,
                  sugar: 9,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                portionSize: '117g',
                portion: 'small',
                gramWeight: '117',
                modifiers: [],
                isVegan: true,
                isVegetarian: true,
                isDefault: false,
                price: 1,
                viewablePrices: null,
                milkAdditive: true,
                soyBeansAdditive: true,
                eggsAdditive: true,
                sesameSeedsAdditive: true,
                peanutsAdditive: true,
                wheatAdditive: true,
                fishAdditive: true,
                nutsAdditive: true,
                co2Rating: '',
                co2Value: '0',
                isMindful: false,
                isFavorite: false,
                uomId: 30630,
                foodItemId: 28508,
                additives: null,
                uomBarcodes: [],
                alcoholContent: null,
              },
            ],
            listImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28508-20230706121748-640px-Granny_smith_and_cross_section.jpg',
            detailsImage:
              'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28508-20230706121750-640px-Granny_smith_and_cross_section.jpg',
            menuItemId: 17221337,
            dayPart: 'All day groceries',
            section: 'Fruits',
            subSection: null,
            genericCategory: 'Other',
          },
        ],
      },
      {
        id: 3330,
        facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        name: 'Bite Weekends',
        isOrderable: true,
        logoUrl:
          'https://rgukretailrangerpre9277.blob.core.windows.net/menu/20230706110929-640px-Soy-whey-protein-diet.jpg',
        date: new Date().toISOString().split('T')[0],
        isFutureOrdering: true,
      },
    ],
    discountTiersStatus: {},
    discountTiers: {},
    locks: {
      getMenusForFacility: false,
      getAllergens: false,
    },
    hasSiteChanged: false,
    hasSiteChangeModalClosed: false,
    menusType: 1,
    errors: {
      fetchingSiteId: false,
    },
    hasMenuFetched: true,
    availablePromotions: [],
    cart: {
      date: new Date().toISOString().split('T')[0],
      facilityId: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
      submissionTrackingId: '76014531-be74-4f3e-b94d-e26095619e35',
      menuId: 3329,
      siteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
      menuPortionItems: [
        {
          id: 'beb8461a-20de-4cc5-bd7a-3dba56757ec3',
          img: null,
          menuItemId: 17221337,
          name: 'Abacaxi pineapple',
          price: 2,
          quantity: 1,
          uomId: 30640,
          foodItemId: 28509,
          description: '',
          isVegan: true,
          isVegetarian: true,
          genericCategory: 'Other',
        },
      ],
      moment: 'All day groceries',
      selectedFulfillmentType: {
        id: 28,
        type: 'DineIn',
      },
      pickupLocations: [
        {
          pickupSpotId: 5298,
          order: 0,
          name: 'table 45',
        },
        {
          pickupSpotId: 6368,
          order: 0,
          name: 'Table 46',
        },
      ],
      pickupInformation: {
        pickupSpotId: null,
        pickupSpotName: null,
        pickupTimeSlotId: null,
        pickupTime: null,
      },
    },
    draft: {
      orderId: 315363,
      subtotal: 1.6,
      tax: 0,
      total: 1.6,
      promotionDiscount: 0,
      delivery: 0,
      subsidy: 0.4,
      promotionalDiscountForItems: [],
      promotionsApplied: [],
      suggestedPromotions: [],
      redeemedFoodItems: [],
      loyaltyStampsToBeEarned: [],
    },
  },
  Facilities: {
    data: {
      '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea': {
        title: 'Mess Menu Facility',
        name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston',
        id: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
      },
      '5399e8af-c9ba-4b52-9f9a-3a4499a8948f': {
        title: 'Mess Menu Facility 2',
        name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston 2',
        id: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
      },
    },
    list: {
      '5a8da332-1237-e811-a95b-000d3a2bc5c1': [
        '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
        '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
      ],
    },
  },
  Shared: {
    language: {
      currentLanguageCode: 'en-US',
    },
  },
};

const defaultStateCopy = JSON.parse(JSON.stringify(defaultState));

const mockServicesList = () => defaultState;
jest.mock('../../../store', () => ({
  store: {
    getState: () => mockServicesList(),
  },
}));

// Before your tests, mock the hook
jest.mock('../hooks/useCheckCanOrder/useCheckCanOrder', () => ({
  useCheckCanOrder: jest.fn(),
}));

describe('Cart', () => {
  beforeEach(() => {
    jest.resetAllMocks();
    jest.clearAllMocks();
    const mockDispatch = jest.fn();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);

    (useParams as jest.Mock).mockImplementation(() => ({
      sideId: null,
    }));

    (api.useGetAvailablePromotionsQuery as jest.Mock).mockReturnValue({
      data: null, // or your specific expected data for this test
      isLoading: false,
      error: null,
    });

    (useCheckCanOrderHook.useCheckCanOrder as jest.Mock).mockReturnValue({
      canOrder: true,
      canOrderLoading: false,
    });
  });

  describe('when one item in cart', async () => {
    const mockDispatch = jest.fn();

    jest.mock('../actions', () => ({
      ...jest.requireActual('../actions'),
      cleanCart: jest.fn().mockReturnValue({ type: 'CLEAN_CART' }),
    }));

    beforeEach(async () => {
      (useDispatch as jest.Mock).mockReturnValue(mockDispatch);
      await act(() =>
        renderedComponent(Cart, {}, defaultState, mockHistory || createBrowserHistory())
      );
    });

    it('should display cart item tile', () => {
      expect(screen.getByText('Abacaxi pineapple')).toBeInTheDocument();
      expect(screen.getAllByText('Vegan').length).toBeGreaterThan(0);
      expect(screen.getAllByText('$2.00').length).toBeGreaterThan(0);
    });

    it('should display correct cart summary - price, meal name and date', () => {
      expect(screen.getAllByText('$1.60').length).toBeGreaterThan(0);
      expect(screen.getByText(/Today, All day groceries - \$1.60/)).toBeInTheDocument();
    });

    it('should display total summary with relevant prices', () => {
      expect(screen.getByTestId('order-summary-total').textContent).toContain('$2.00');
      expect(screen.getByTestId('order-summary-paid-by-employer').textContent).toContain('$0.40');
      expect(screen.getByTestId('order-summary-paid-by-user').textContent).toContain('$1.60');
    });

    it('should have increase and decrease buttons next to cart item', () => {
      expect(
        screen.getByTestId('menu-cart-actions-quantity-setter-increment-button')
      ).toBeInTheDocument();
      expect(
        screen.getByTestId('menu-cart-actions-quantity-setter-decrement-button')
      ).toBeInTheDocument();
    });

    it('should display fullfilment types', () => {
      expect(screen.getAllByText('Eat-in').length).toBeGreaterThan(0);
      expect(screen.getAllByText('Pickup').length).toBeGreaterThan(0);
      expect(screen.getAllByText('Delivery').length).toBeGreaterThan(0);
    });

    it('should redirect to Shop when clicked on redirection button', async () => {
      userEvent.click(screen.getByTestId('order-buttons-pair-button-Shop-children'));

      await waitFor(() => {
        expect(mockPush).toHaveBeenCalledWith(
          `/order/0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea/3329/${
            new Date().toISOString().split('T')[0]
          }/products`
        );
      });
    });

    it('should redirect to Home when clicked on redirection button', async () => {
      userEvent.click(screen.getByTestId('order-buttons-pair-button-Home'));
      await waitFor(() => {
        expect(mockPush).toHaveBeenCalledWith(`/`);
      });
    });

    it('should clear cart when clicked on clear cart', async () => {
      await userEvent.click(screen.getByTestId('order-clear-cart-button'));
      await waitFor(() => {
        expect(mockDispatch).toHaveBeenCalledWith(cleanCart());
      });
    });
  });

  describe('when no items in cart', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: null,
          },
        };
        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('should not show any cart items', () => {
      expect(screen.queryByText('Abacaxi pineapple')).not.toBeInTheDocument();
    });

    it('should not show any fullfilment types', () => {
      expect(screen.queryByText('Delivery')).not.toBeInTheDocument();
      expect(screen.queryByText('Eat-in')).not.toBeInTheDocument();
      expect(screen.queryByText('Pickup')).not.toBeInTheDocument();
    });

    it('should show information about empty cart', () => {
      expect(screen.queryByText('The cart is empty')).toBeInTheDocument();
    });
  });

  describe('promotions available - none of them activated', async () => {
    beforeEach(async () => {
      (api.useGetAvailablePromotionsQuery as jest.Mock).mockReturnValue({
        data: [
          {
            id: 7563,
            name: 'Sanofi_MEAL Bundle % discount',
            description: '123',
            discount: 10,
            threshold: null,
            items: [
              {
                discount: 0,
                uomId: 30640,
                menuIds: [3329],
              },
              {
                discount: 0,
                uomId: 30643,
                menuIds: [3329],
              },
            ],
          },
          {
            id: 7564,
            name: 'Another promotion',
            description: '123',
            discount: 10,
            threshold: null,
            items: [
              {
                discount: 0,
                uomId: 30640,
                menuIds: [3329],
              },
              {
                discount: 0,
                uomId: 30643,
                menuIds: [3329],
              },
            ],
          },
        ],
        isLoading: false,
        error: null,
      });

      await act(() => {
        renderedComponent(Cart, {}, defaultState, mockHistory || createBrowserHistory());
      });
    });

    it('should display all available promotions', () => {
      expect(screen.getByText(/Sanofi_MEAL Bundle % discount/)).toBeInTheDocument();
      expect(screen.getByText(/Another promotion/)).toBeInTheDocument();
    });

    it('should display promotions notification', async () => {
      await waitFor(() => {
        expect(screen.getByTestId('notification-divider')).toBeInTheDocument();
      });
    });

    it('should display correct promotion quantity number', async () => {
      await waitFor(() => {
        expect(screen.getByText(/2 discounts available/)).toBeInTheDocument();
      });
    });

    it('should not display Activated info for any promotion', async () => {
      await waitFor(() => {
        expect(
          screen.queryByText(/Activated/, {
            selector: '[data-cy="notification-collapsable-content"] *',
          })
        ).not.toBeInTheDocument();
      });
    });

    it('should not display promotion amount in total summary', async () => {
      await waitFor(() => {
        expect(screen.queryByTestId('order-summary-promotion')).not.toBeInTheDocument();
      });
    });

    it('can click on see less and hide available promotions', async () => {
      await userEvent.click(screen.getByTestId('notification-collapse-btn'));
      await waitFor(() => {
        expect(screen.queryByText(/Sanofi_MEAL Bundle % discount/)).not.toBeInTheDocument();
        expect(screen.queryByText(/Another promotion/)).not.toBeInTheDocument();
      });
    });
  });

  describe('promotions available - one of them activated', async () => {
    beforeEach(async () => {
      (api.useGetAvailablePromotionsQuery as jest.Mock).mockReturnValue({
        data: [
          {
            id: 7563,
            name: 'Sanofi_MEAL Bundle % discount',
            description: '123',
            discount: 10,
            threshold: null,
            items: [
              {
                discount: 0,
                uomId: 30640,
                menuIds: [3329],
              },
              {
                discount: 0,
                uomId: 30643,
                menuIds: [3329],
              },
            ],
          },
          {
            id: 7564,
            name: 'Another promotion',
            description: '123',
            discount: 10,
            threshold: null,
            items: [
              {
                discount: 0,
                uomId: 30640,
                menuIds: [3329],
              },
              {
                discount: 0,
                uomId: 30643,
                menuIds: [3329],
              },
            ],
          },
        ],
        isLoading: false,
        error: null,
      });

      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            draft: {
              ...defaultState.Order.draft,
              promotionDiscount: 2,
              promotionsApplied: [
                {
                  id: 7564,
                  name: '$2 discount',
                  discount: 2,
                },
              ],
            },
          },
        };
        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      });
    });

    it('should display Activated info for one promotion', async () => {
      await waitFor(() => {
        expect(
          screen.queryByText(/Activated/, {
            selector: '[data-cy="notification-collapsable-content"] *',
          })
        ).toBeInTheDocument();
      });
    });

    it('should display promotion amount in total summary', async () => {
      await waitFor(() => {
        expect(screen.getByTestId('order-summary-promotion')).toHaveTextContent('$2.00');
      });
    });
  });

  describe('promotions not available', async () => {
    beforeEach(async () => {
      (api.useGetAvailablePromotionsQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: false,
        error: null,
      });

      act(() => {
        renderedComponent(Cart, {}, defaultState, mockHistory || createBrowserHistory());
      });
    });

    it('should not display any available promotions', () => {
      expect(screen.queryByTestId(/Sanofi_MEAL Bundle % discount/)).not.toBeInTheDocument();
    });

    it('should not display promotions notification', async () => {
      await waitFor(() => {
        expect(screen.queryByTestId('notification-divider')).not.toBeInTheDocument();
      });
    });
  });

  describe('subsidy available', async () => {
    beforeEach(async () => {
      await act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            draft: {
              ...defaultState.Order.draft,
              subsidy: 1,
            },
          },
        };
        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      });
    });

    it('should display subsidy amount in total summary', async () => {
      await waitFor(() => {
        expect(screen.getByTestId('order-summary-paid-by-employer')).toHaveTextContent('$1.00');
      });
    });
  });

  describe('subsidy not available', async () => {
    beforeEach(async () => {
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            draft: {
              ...defaultState.Order.draft,
              subsidy: undefined,
            },
          },
        };
        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      });
    });

    it('should not display subsidy amount in total summary', async () => {
      await waitFor(() => {
        expect(screen.queryByTestId('order-summary-paid-by-employer')).not.toBeInTheDocument();
      });
    });
  });

  describe('payment methods available: pay now and pay on pickup', async () => {
    beforeEach(async () => {
      await act(() => {
        const env = global.process.env;
        global.process.env = { ...env, REACT_APP_USE_PAYMENT_MODULE: 'true' };
        renderedComponent(Cart, {}, defaultState, mockHistory || createBrowserHistory());
      });
    });

    it('should display pay now and pay on pickup', async () => {
      await waitFor(() => {
        screen.debug(screen.getByTestId('payment-modality-list'));

        expect(
          screen.getByTestId('payment-modality-list-payment-option-PayNow-list-item-label')
        ).toBeInTheDocument();
        expect(
          screen.getByTestId('payment-modality-list-payment-option-PayOnPickup-list-item-non-link')
        ).toBeInTheDocument();
      });
    });

    it('pay now should be active when selected', async () => {
      await userEvent.click(
        screen.getByTestId('payment-modality-list-payment-option-PayNow-list-item-label')
      );
      await waitFor(() => {
        expect(screen.getByTestId('cart-action-0-content')).toHaveTextContent('Pay now');
      });
    });

    it('pay on pickup should be active when selected', async () => {
      await userEvent.click(
        screen.getByTestId('payment-modality-list-payment-option-PayOnPickup-list-item-label')
      );
      await waitFor(() => {
        expect(screen.getByTestId('cart-action-0-content')).toHaveTextContent(
          'Pay for these items on pick up'
        );
      });
    });
  });

  describe('one fulfillment type available', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState.Order.cart,
              pickupLocations: [
                {
                  pickupSpotId: 5298,
                  order: 0,
                  name: 'Place1',
                },
                {
                  pickupSpotId: 6368,
                  order: 1,
                  name: 'Place2',
                },
              ],
            },
            menus: defaultState.Order.menus.map((menu, index) =>
              index === 0
                ? {
                    ...menu,
                    fulfillmentTypes: [
                      {
                        id: 10214,
                        type: 'PickupOption',
                      },
                    ],
                    menuItems: menu.menuItems || [],
                  }
                : menu
            ),
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('should display 1 of 3 available fullfilment types', () => {
      expect(screen.getAllByText('Pickup').length).toBeGreaterThan(0);
      expect(screen.queryByText('Delivery')).not.toBeInTheDocument();
      expect(screen.queryByText('Eat-in')).not.toBeInTheDocument();
    });

    it('purchasing should be disabled when location not selected', () => {
      expect(screen.getByTestId('cart-action-0')).toHaveAttribute('aria-disabled', 'true');
    });

    it('should not display dropdown options before location input is clicked', async () => {
      expect(screen.queryByText('Place1')).not.toBeInTheDocument();
      expect(screen.queryByText('Place2')).not.toBeInTheDocument();
    });

    it('should display dropdown options when location input is clicked', async () => {
      userEvent.click(screen.getByRole('combobox', { name: /Location \*/i }));
      await waitFor(() => {
        expect(screen.getByText('Place1')).toBeInTheDocument();
        expect(screen.getByText('Place2')).toBeInTheDocument();
      });
    });
  });

  describe('delivery fullfilment type selected and setup option disableTimeSelection is true', async () => {
    beforeEach(async () =>
      act(() => {
        const services = { ...defaultState?.Core?.services };

        services.list.push({
          id: 'some-id',
          name: 'Food.Order',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Some Name',
            isEnabled: true,
            orderIndex: 0,
          },
          setupOptions: [
            {
              name: 'disableTimeSelection',
              value: 'true',
              code: 'some-code',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        });

        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState.Order.cart,
              selectedFulfillmentType: {
                id: 18979,
                type: 'Delivery',
              },
            },
          },
          Core: {
            ...defaultState.Core,
            services: services,
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    afterEach(async () => {
      defaultState = JSON.parse(JSON.stringify(defaultStateCopy));
    });

    it('only location should be possible to select', () => {
      expect(screen.getByTestId('location-picker-select-input-wrapper')).toBeInTheDocument();
      expect(screen.queryByTestId('time-picker-select-input-wrapper')).not.toBeInTheDocument();
    });
  });

  describe('delivery fullfilment type selected and setup option disableTimeSelection is false', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState.Order.cart,
              selectedFulfillmentType: {
                id: 18979,
                type: 'Delivery',
              },
            },
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('location and time should be possible to select', () => {
      expect(screen.getByTestId('location-picker-select-input-wrapper')).toBeInTheDocument();
      expect(screen.queryByTestId('time-picker-select-input-wrapper')).toBeInTheDocument();
    });
  });

  describe('delivery fullfilment type not selected and setup option disableTimeSelection is true', async () => {
    beforeEach(async () =>
      act(() => {
        const services = { ...defaultState?.Core?.services };

        services.list.push({
          id: 'some-id',
          name: 'Food.Order',
          navigation: {
            imageId: null,
            imageName: null,
            name: 'Some Name',
            isEnabled: true,
            orderIndex: 0,
          },
          setupOptions: [
            {
              name: 'disableTimeSelection',
              value: 'false',
              code: 'some-code',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
          contentPageId: null,
        });

        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
          },
          Core: {
            ...defaultState.Core,
            services: services,
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('location and time should be possible to select', () => {
      expect(screen.getByTestId('location-picker-select-input-wrapper')).toBeInTheDocument();
      expect(screen.queryByTestId('time-picker-select-input-wrapper')).toBeInTheDocument();
    });
  });

  describe('pickup slot selected, time slot not selected', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState.Order.cart,
              pickupInformation: {
                pickupSpotId: 5298,
                pickupSpotName: 'Place1',
              },
              pickupLocations: [
                {
                  pickupSpotId: 5298,
                  order: 0,
                  name: 'Place1',
                },
                {
                  pickupSpotId: 6368,
                  order: 1,
                  name: 'Place2',
                },
              ],
              pickupTimeSlots: [
                {
                  timeSlotId: 932785,
                  time: '2024-02-19T13:55:00',
                },
                {
                  timeSlotId: 932828,
                  time: '2024-02-19T14:00:00',
                },
              ],
            },
            menus: defaultState.Order.menus.map((menu, index) =>
              index === 0
                ? {
                    ...menu,
                    fulfillmentTypes: [
                      {
                        id: 10214,
                        type: 'PickupOption',
                      },
                    ],
                    menuItems: menu.menuItems || [],
                  }
                : menu
            ),
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('purchasing should be disabled when location and time not selected', () => {
      expect(screen.getByTestId('cart-action-0')).toHaveAttribute('aria-disabled', 'true');
    });

    it('should not display time slots before dropdown clicked', async () => {
      expect(screen.queryByText('01:55 PM')).not.toBeInTheDocument();
      expect(screen.queryByText('02:00 PM')).not.toBeInTheDocument();
    });

    it('should display time slots when dropdown clicked', async () => {
      userEvent.click(screen.getByRole('combobox', { name: /Time \*/i }));
      await waitFor(() => {
        expect(screen.getByText('01:55 PM')).toBeInTheDocument();
        expect(screen.getByText('02:00 PM')).toBeInTheDocument();
      });
    });
  });

  describe('pickup slot selected, time slot  selected', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState.Order.cart,
              pickupInformation: {
                pickupSpotId: 5298,
                pickupSpotName: 'Place1',
                pickupTime: '2024-02-19T13:55:00',
                pickupTimeSlotId: 932785,
              },
              pickupLocations: [
                {
                  pickupSpotId: 5298,
                  order: 0,
                  name: 'Place1',
                },
                {
                  pickupSpotId: 6368,
                  order: 1,
                  name: 'Place2',
                },
              ],
              pickupTimeSlots: [
                {
                  timeSlotId: 932785,
                  time: '2024-02-19T13:55:00',
                },
                {
                  timeSlotId: 932828,
                  time: '2024-02-19T14:00:00',
                },
              ],
            },
            menus: defaultState.Order.menus.map((menu, index) =>
              index === 0
                ? {
                    ...menu,
                    fulfillmentTypes: [
                      {
                        id: 10214,
                        type: 'PickupOption',
                      },
                    ],
                    menuItems: menu.menuItems || [],
                  }
                : menu
            ),
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('purchasing should enabled when location and time not selected', () => {
      expect(screen.getByTestId('cart-action-0')).not.toHaveAttribute('aria-disabled', 'true');
    });
  });

  describe('menu is not conference catering menu', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            menus: defaultState.Order.menus.map((menu) => ({
              ...menu,
              isConferenceCatering: false,
            })),
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('should show charge to my deparment payment method available', () => {
      expect(screen.queryByText('Charge to my department')).not.toBeInTheDocument();
    });
  });

  describe('menu is conference catering menu', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            menus: defaultState.Order.menus.map((menu) => ({
              ...menu,
              isConferenceCatering: true,
            })),
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('should show charge to my deparment payment method available', () => {
      expect(screen.getByText('Charge to my department')).toBeInTheDocument();
    });
  });

  describe('user is guest and guest ordering setup option is enabled and terms of sales popup is not required', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Core: {
            ...defaultState.Core,
            services: {
              ...defaultState.Core.services,
              list: defaultState.Core.services.list.some(
                (service) => service.name === SERVICE.FOOD_ORDER
              )
                ? defaultState.Core.services.list.map((service) =>
                    service.name === SERVICE.FOOD_ORDER
                      ? {
                          ...service,
                          setupOptions: service.setupOptions.find(
                            (option) => option.name === 'enableGuestOrdering'
                          )
                            ? service.setupOptions.map((option) =>
                                option.name === 'enableGuestOrdering'
                                  ? { ...option, value: 'true' }
                                  : option
                              )
                            : [
                                ...service.setupOptions,
                                { name: 'enableGuestOrdering', value: 'true' },
                              ],
                        }
                      : service
                  )
                : [
                    ...defaultState.Core.services.list,
                    {
                      name: SERVICE.FOOD_ORDER,
                      setupOptions: [{ name: 'enableGuestOrdering', value: 'true' }],
                    },
                  ],
            },
            user: {
              ...defaultState.Core?.user,
              isGuest: true,
            },
            access: {
              ...defaultState.Core.access,
              termsOfSaleToAcknowledge: undefined,
            },
          },
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState?.Order?.cart,
              pickupInformation: {
                pickupSpotId: 5298,
                pickupSpotName: 'Place1',
                pickupTime: '2024-02-19T13:55:00',
                pickupTimeSlotId: 932785,
              },
            },
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('email address should be displayed', () => {
      expect(screen.queryByText(/Email Address\s*\*/)).toBeInTheDocument();
    });

    it('email address should be mandatory', () => {
      expect(screen.getByTestId('cart-action-0')).toHaveAttribute('aria-disabled', 'true');
    });
  });

  describe('user is guest and guest ordering setup option is disabled', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Core: {
            ...defaultState.Core,
            services: {
              ...defaultState.Core.services,
              list: defaultState.Core.services.list.some(
                (service) => service.name === SERVICE.FOOD_ORDER
              )
                ? defaultState.Core.services.list.map((service) =>
                    service.name === SERVICE.FOOD_ORDER
                      ? {
                          ...service,
                          setupOptions: service.setupOptions.find(
                            (option) => option.name === 'enableGuestOrdering'
                          )
                            ? service.setupOptions.map((option) =>
                                option.name === 'enableGuestOrdering'
                                  ? { ...option, value: 'false' }
                                  : option
                              )
                            : [
                                ...service.setupOptions,
                                { name: 'enableGuestOrdering', value: 'false' },
                              ],
                        }
                      : service
                  )
                : [
                    ...defaultState.Core.services.list,
                    {
                      name: SERVICE.FOOD_ORDER,
                      setupOptions: [{ name: 'enableGuestOrdering', value: 'false' }],
                    },
                  ],
            },
            user: {
              ...defaultState.Core?.user,
              isGuest: true,
            },
          },
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState?.Order?.cart,
              pickupInformation: {
                pickupSpotId: 5298,
                pickupSpotName: 'Place1',
                pickupTime: '2024-02-19T13:55:00',
                pickupTimeSlotId: 932785,
              },
            },
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('email address should not be displayed', () => {
      expect(screen.queryByText(/Email Address\s*\*/)).not.toBeInTheDocument();
    });
  });

  describe('user is not guest and guest ordering setup option is enabled', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Core: {
            ...defaultState.Core,
            services: {
              ...defaultState.Core.services,
              list: defaultState.Core.services.list.some(
                (service) => service.name === SERVICE.FOOD_ORDER
              )
                ? defaultState.Core.services.list.map((service) =>
                    service.name === SERVICE.FOOD_ORDER
                      ? {
                          ...service,
                          setupOptions: service.setupOptions.find(
                            (option) => option.name === 'enableGuestOrdering'
                          )
                            ? service.setupOptions.map((option) =>
                                option.name === 'enableGuestOrdering'
                                  ? { ...option, value: 'true' }
                                  : option
                              )
                            : [
                                ...service.setupOptions,
                                { name: 'enableGuestOrdering', value: 'true' },
                              ],
                        }
                      : service
                  )
                : [
                    ...defaultState.Core.services.list,
                    {
                      name: SERVICE.FOOD_ORDER,
                      setupOptions: [{ name: 'enableGuestOrdering', value: 'true' }],
                    },
                  ],
            },
            user: {
              ...defaultState.Core?.user,
              isGuest: false,
            },
          },
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState?.Order?.cart,
              pickupInformation: {
                pickupSpotId: 5298,
                pickupSpotName: 'Place1',
                pickupTime: '2024-02-19T13:55:00',
                pickupTimeSlotId: 932785,
              },
            },
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('email address should not be displayed', () => {
      expect(screen.queryByText(/Email Address\s*\*/)).not.toBeInTheDocument();
    });
  });

  describe('user is guest and guest ordering setup option is enabled and terms of sales popup is required', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Core: {
            ...defaultState.Core,
            services: {
              ...defaultState.Core.services,
              list: defaultState.Core.services.list.some(
                (service) => service.name === SERVICE.FOOD_ORDER
              )
                ? defaultState.Core.services.list.map((service) =>
                    service.name === SERVICE.FOOD_ORDER
                      ? {
                          ...service,
                          setupOptions: service.setupOptions.find(
                            (option) => option.name === 'enableGuestOrdering'
                          )
                            ? service.setupOptions.map((option) =>
                                option.name === 'enableGuestOrdering'
                                  ? { ...option, value: 'true' }
                                  : option
                              )
                            : [
                                ...service.setupOptions,
                                { name: 'enableGuestOrdering', value: 'true' },
                              ],
                        }
                      : service
                  )
                : [
                    ...defaultState.Core.services.list,
                    {
                      name: SERVICE.FOOD_ORDER,
                      setupOptions: [{ name: 'enableGuestOrdering', value: 'true' }],
                    },
                  ],
            },
            user: {
              ...defaultState.Core?.user,
              isGuest: true,
            },
            access: {
              ...defaultState.Core.access,
              termsOfSaleToAcknowledge: '123e4567-e89b-12d3-a456-426614174000',
            },
          },
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState?.Order?.cart,
              pickupInformation: {
                pickupSpotId: 5298,
                pickupSpotName: 'Place1',
                pickupTime: '2024-02-19T13:55:00',
                pickupTimeSlotId: 932785,
              },
            },
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('terms of sales popup displayed', () => {
      expect(screen.getByTestId('cart-terms-of-sales-checkbox-container')).toBeInTheDocument();
    });
  });

  describe('when  pickup time slot which is not available anymore selected', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState.Order.cart,
              pickupTimeSlots: undefined,
              pickupInformation: {
                pickupSpotId: 5298,
                pickupSpotName: 'Place1',
                pickupTime: '2024-02-19T13:55:00',
                pickupTimeSlotId: 932785,
              },
            },
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('should display information about lack of available timeslots', () => {
      expect(
        screen.getByText(
          'Order for the chosen date, fulfillment type and location is not available anymore!'
        )
      ).toBeInTheDocument();
    });
  });

  describe('when pickup time slot is not selected', async () => {
    beforeEach(async () =>
      act(() => {
        const state = {
          ...defaultState,
          Order: {
            ...defaultState.Order,
            cart: {
              ...defaultState.Order.cart,
              pickupTimeSlots: undefined,
            },
          },
        };

        renderedComponent(Cart, {}, state, mockHistory || createBrowserHistory());
      })
    );

    it('should not display information about lack of available timeslots', () => {
      expect(
        screen.queryByText(
          'Order for the chosen date, fulfillment type and location is not available anymore!'
        )
      ).not.toBeInTheDocument();
    });
  });

  describe('when kiosk app', async () => {
    beforeEach(async () =>
      act(() => {
        const env = global.process.env;
        global.process.env = { ...env, REACT_APP_APP_NAME: 'biteKiosk' };

        renderedComponent(Cart, {}, defaultState, mockHistory || createBrowserHistory());
      })
    );

    it('it should show relevant action buttons', async () => {
      expect(await screen.findByText('Validate my order')).toBeInTheDocument();
      expect(await screen.findByText('Continue on your device')).toBeInTheDocument();
    });

    it('payment methods should not be displayed', async () => {
      expect(screen.queryByText(/Payment method\s*\*/)).not.toBeInTheDocument();
    });
  });
});
