import classNames from 'classnames';
import { FC } from 'react';

import { ChevronUpIcon } from '../../../../assets';
import { ChevronDownIcon } from '../../../../assets/icons';
import { INPUT_TYPE, SIZE } from '../../../../constants';
import Button, { BUTTON_LOOK } from '../../Button';

import { TimeInputProps } from './TimeInput.types';

import styles from './TimeInput.module.css';

const TimeInput: FC<TimeInputProps> = ({
  inputRef,
  value,
  min,
  max,
  onChange,
  onFocus,
  onBlur,
  onMore,
  onLess,
  label,
  id,
  'data-testid': dataTestId = 'time-input',
}) => {
  const maxDisabled = max === parseInt(value, 10);
  const minDisabled = min === parseInt(value, 10);
  return (
    <div className={classNames(styles.timeInput)} data-testid={dataTestId}>
      <Button
        aria-label={label('Increase hours')}
        size={SIZE.SMALL}
        look={BUTTON_LOOK.TERTIARY}
        className={classNames(styles.button)}
        disabled={maxDisabled}
        onClick={onMore}
        data-testid={`${dataTestId}-more`}
      >
        <ChevronUpIcon className={classNames(styles.icon)} />
      </Button>

      <input
        id={id}
        ref={inputRef}
        value={value}
        maxLength={2}
        type={INPUT_TYPE.TEXT}
        pattern="\d*"
        className={classNames(styles.input)}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <Button
        aria-label={label('Decrease hours')}
        size={SIZE.SMALL}
        look={BUTTON_LOOK.TERTIARY}
        className={classNames(styles.button)}
        disabled={minDisabled}
        onClick={onLess}
        data-testid={`${dataTestId}-less`}
      >
        <ChevronDownIcon className={classNames(styles.icon)} />
      </Button>
    </div>
  );
};

export default TimeInput;
