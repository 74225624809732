import { cleanup, fireEvent, screen } from '@testing-library/react';

import renderComponent from '../../../helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '../../Core/reducers/coreReducer';
import { useEmailReceiptMutation, usePrintReceiptMutation } from '../api';
import { DeliveryOptionType } from '../types/cart.types';
import { KioskOrderVariant } from '../types/KioskOrder.types';

import KioskOrder from './KioskOrder';

const props = {
  variant: KioskOrderVariant.SUCCESS,
  deliveryOption: DeliveryOptionType.Delivery,
  orderId: '123',
  site: { id: 'siteId' },
  label: (label: string) => label,
};

const printReceipt = jest.fn();
const emailReceipt = jest.fn();

jest.mock('../api', () => ({
  ...jest.requireActual('../api'),
  useEmailReceiptMutation: jest.fn(),
  usePrintReceiptMutation: jest.fn(),
}));

describe('KioskOrder component', () => {
  afterAll(() => cleanup());

  describe('show get receipt button when enabled', () => {
    const env = global.process.env;

    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_KIOSK_RECEIPT_ENABLED: 'true',
      };
    });

    beforeEach(async () => {
      (useEmailReceiptMutation as jest.Mock).mockReturnValue([emailReceipt, {}]);
      (usePrintReceiptMutation as jest.Mock).mockReturnValue([printReceipt, {}]);
    });

    renderComponent(
      KioskOrder,
      { ...props },
      {
        Core: {
          ...coreDefaultState,
        },
      }
    );

    it('should display button', () => {
      expect(screen.getByText('Ref: Get your receipt')).toBeTruthy();
    });

    it('should display modal', () => {
      expect(screen.getByText('Ref: Get your receipt')).toBeTruthy();

      const receiptButton = screen.getByText('Ref: Get your receipt');
      fireEvent.click(receiptButton);

      expect(screen.getByText('Ref: Receipt Modal message')).toBeTruthy();
      expect(screen.getByText('Ref: Print')).toBeTruthy();
      expect(screen.getByText('Ref: Email')).toBeTruthy();
      expect(screen.getByText('Ref: Confirm')).toBeTruthy();
    });
  });
});

describe('KioskOrder component', () => {
  afterAll(() => cleanup());

  describe('dont show get receipt button when enabled', () => {
    const env = global.process.env;

    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_KIOSK_RECEIPT_ENABLED: 'false',
      };
    });

    beforeEach(async () => {
      (useEmailReceiptMutation as jest.Mock).mockReturnValue([emailReceipt, {}]);
      (usePrintReceiptMutation as jest.Mock).mockReturnValue([printReceipt, {}]);
    });

    renderComponent(
      KioskOrder,
      { ...props },
      {
        Core: {
          ...coreDefaultState,
        },
      }
    );

    it('should not display', () => {
      expect(screen.queryByText('Ref: Get your receipt')).toBeNull();
    });
  });
});
