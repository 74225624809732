const labels = {
  All: { 'Ref: Page title': 'ซัพพลายเออร์' },
  SuppliersList: {
    'Ref: Supplier details': 'ดูรายละเอียด',
    'Missing supplier list': 'เราไม่พบซัพพลายเออร์ใด ๆ',
    'Try again later': 'ลองอีกครั้งในภายหลัง',
  },
  SupplierDetails: {
    'Address label': 'ที่อยู่:',
    'Website label': 'เว็บไซต์:',
    'Details label': 'รายละเอียด',
  },
};
export default labels;
