const labels = {
  ReviewForm: {
    'Ref: Page title': 'Posouzení',
    'Ref: How was dining with us': 'Jak bylo s námi stolování?',
    'Ref: Submit': 'Předložit',
    'Ref: Filter range': 'Rozsah filtru',
    'Ref: Some information is missing': 'Některé informace chybí',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Odeslat anonymně',
    'Ref: Your email address': 'Vaše emailová adresa',
    'Ref: Email field placeholder': 'vložte svou e-mailovou adresu',
    'Ref: Invalid email format': 'Neplatný formát e -mailu',
  },
  SuccessPage: {
    'Ref: Body':
      'Děkujeme za dokončení této recenze, váš čas je cenný, protože tato recenze pomůže zlepšit naše služby.',
    'Ref: Go to Feedback': 'Přejít na zpětnou vazbu',
    'Ref: Go to home': 'Jít domů',
    'Ref: Go to Facilities': 'Jít do zařízení',
    'Ref: Go to Product Details': 'Přejděte na podrobnosti o produktu',
    'Ref: Go to Facility Details': 'Přejděte na podrobnosti o zařízení',
  },
  FacilityPicker: {
    'Ref: Page title': 'Posouzení',
    'Ref: Search facility name': 'Vyhledávací název zařízení',
  },
};
export default labels;
