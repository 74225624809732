import momentjs from 'moment';
import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';

import { fetchJsonActionCreator, makeActionCreatorV2 } from '../../actions';
import { globalRetailApiPath, shouldUseMockData } from '../../config';
import { DATE_FORMAT } from '../../constants';
import { store } from '../../store';
import { PreRequestParams } from '../../types/actionCreator.types';
import { getAll } from '../Facilities/redux/actions';
import loyaltyStampsApi from '../LoyaltyStamps/api';

import orderApi, { clearKioskSessionMutation } from './api';
import { cacheVersion, moduleId } from './config';
import { toFacilityMenu } from './helpers/menus.helper';
import { mapDraftOrderResponseToCartRequestModel } from './helpers/order.helper';
import { processCartChange } from './helpers/orderDraft.helper';
import { checkAndShowPromotionNotification } from './helpers/promotions.helper';
import mockResponse from './mockResponse';
import { PickupInfoUpdate } from './types/cart.types';
import {
  DiscountTiersModel,
  DiscountTiersStatus,
  FacilityMappingResult,
  MenuType,
  SiteMappingResult,
} from './types/menuSelector.types';
import {
  Allergen,
  CartMenuItem,
  DownloadMenuUrl,
  DraftOrderResponse,
  FacilityMenu,
  FacilityMenuJson,
  OrderDraft,
  OrderState,
  PendingOrders,
  PickupInformation,
} from './types/orderState.types';

const persistLocally = { moduleId, cacheVersion };

export const initializeCart = makeActionCreatorV2('CART_INITIALIZED', { cart: 'cart' }, moduleId);

export const addItemToCart = async ({
  menuItem,
  dispatch,
}: {
  menuItem: CartMenuItem;
  dispatch: Dispatch;
}) => {
  dispatch({
    type: 'CART_ITEM_ADDED',
    moduleId,
    ...{
      menuItem,
      persistLocally,
    },
  });

  const orderState = store.getState().Order as OrderState;
  const result = await processCartChange(dispatch, orderState);

  checkAndShowPromotionNotification({
    currentPromotionDiscount: orderState?.draft?.promotionDiscount,
    newPromotionDiscount: result.responseData?.promotionDiscount,
  });
};

export const updateItemInCart = async ({
  menuItem,
  dispatch,
  processChange = true,
}: {
  menuItem: CartMenuItem;
  dispatch: Dispatch;
  processChange?: boolean;
}) => {
  dispatch({
    type: 'CART_ITEM_UPDATED',
    moduleId,
    ...{
      menuItem,
      persistLocally,
    },
  });

  if (processChange) {
    const orderState = store.getState().Order as OrderState;
    const result = await processCartChange(dispatch, orderState);

    checkAndShowPromotionNotification({
      currentPromotionDiscount: orderState?.draft?.promotionDiscount,
      newPromotionDiscount: result.responseData?.promotionDiscount,
    });
  }
};

export const increaseCartItemQuantity = async ({
  menuItemId,
  dispatch,
}: {
  menuItemId: string;
  dispatch: Dispatch;
}) => {
  dispatch({
    type: 'CART_ITEM_QUANTITY_INCREASED',
    moduleId,
    ...{
      menuItemId,
      persistLocally,
    },
  });

  const orderState = store.getState().Order as OrderState;
  const result = await processCartChange(dispatch, orderState);

  checkAndShowPromotionNotification({
    currentPromotionDiscount: orderState?.draft?.promotionDiscount,
    newPromotionDiscount: result.responseData?.promotionDiscount,
  });
};

export const decreaseCartItemQuantity = async ({
  menuItemId,
  dispatch,
}: {
  menuItemId: string;
  dispatch: Dispatch;
}) => {
  dispatch({
    type: 'CART_ITEM_QUANTITY_DECREASED',
    moduleId,
    ...{
      menuItemId,
      persistLocally,
    },
  });

  const orderState = store.getState().Order as OrderState;
  const result = await processCartChange(dispatch, orderState);

  checkAndShowPromotionNotification({
    currentPromotionDiscount: orderState?.draft?.promotionDiscount,
    newPromotionDiscount: result.responseData?.promotionDiscount,
  });
};

export const removeItemsFromCart = async ({
  menuItemsIds,
  dispatch,
  processChange = true,
}: {
  menuItemsIds: string[];
  dispatch: Dispatch;
  processChange?: boolean;
}) => {
  dispatch({
    type: 'CART_ITEMS_REMOVED',
    moduleId,
    ...{
      menuItemsIds,
      persistLocally,
    },
  });

  if (processChange) {
    const orderState = store.getState().Order as OrderState;
    const result = await processCartChange(dispatch, orderState);

    checkAndShowPromotionNotification({
      currentPromotionDiscount: orderState?.draft?.promotionDiscount,
      newPromotionDiscount: result.responseData?.promotionDiscount,
    });
  }
};

export const syncTrackingId = async ({ dispatch }: { dispatch: Dispatch }) => {
  dispatch({
    type: 'TRACKING_ID_SYNCED',
    moduleId,
    ...{
      persistLocally,
    },
  });

  const orderState = store.getState().Order as OrderState;
  const result = await processCartChange(dispatch, orderState);

  checkAndShowPromotionNotification({
    currentPromotionDiscount: orderState?.draft?.promotionDiscount,
    newPromotionDiscount: result.responseData?.promotionDiscount,
  });
};

export const cleanCart = makeActionCreatorV2('CART_CLEANED', { persistLocally }, moduleId);

export const setTable = makeActionCreatorV2(
  'TABLE_SET',
  { tableNumber: 'tableNumber', persistLocally },
  moduleId
);

export const setNewSubmissionTrackingId = makeActionCreatorV2(
  'SUBMISSION_TRACKING_ID_SET',
  { submissionTrackingId: 'submissionTrackingId', persistLocally },
  moduleId
);

export const setLastOrder = makeActionCreatorV2('LAST_ORDER_SET', { persistLocally }, moduleId);

export const setPickupInformationInStore = makeActionCreatorV2(
  'SET_PICKUP_INFORMATION',
  { pickupInformation: 'pickupInformation', persistLocally },
  moduleId
);

export const setPickupInformation = (pickupInfo?: PickupInfoUpdate) => {
  return async (dispatch: Dispatch) => {
    await dispatch(setPickupInformationInStore(pickupInfo));
    const orderState = store.getState().Order as OrderState;
    if (
      !(
        pickupInfo?.pickupInformation.pickupPhoneNumber ||
        pickupInfo?.pickupInformation.pickupInstructions ||
        pickupInfo?.pickupInformation.email
      )
    ) {
      await processCartChange(dispatch, orderState);
    }
  };
};

export const setFulfillmentType = makeActionCreatorV2(
  'FULFILLMENT_TYPE_SET',
  { fulfillmentType: 'fulfillmentType', persistLocally },
  moduleId
);

export const setSiteChanged = makeActionCreatorV2('SITE_CHANGED', {}, moduleId);

export const setSiteModalClosed = makeActionCreatorV2(
  'SITE_CHANGE_MODAL_CLOSED',
  { persistLocally },
  moduleId
);

export const clearTimeSlot = makeActionCreatorV2('CLEAR_TIMESLOT', { persistLocally }, moduleId);

export const addProductToFavorites = (uomId: number) => {
  return {
    type: 'FAVORITE_PRODUCT_ADDED',
    uomId,
    moduleId,
    persistLocally,
  };
};
export const removeProductFromFavorites = (uomId: number) => {
  return {
    type: 'FAVORITE_PRODUCT_REMOVED',
    uomId,
    moduleId,
    persistLocally,
  };
};

export const getMenus = (() => {
  const lockAction = { type: 'FETCHING_MENUS_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v2/menu`;

  const argConfig = {
    siteId: {
      toThen: true,
      toQueryString: true,
    },
    date: {
      toQueryString: true,
      toThen: true,
    },
    useCache: {
      toQueryString: true,
    },
    menuType: {
      toQueryString: true,
      toThen: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (
      json: FacilityMenuJson[],
      dispatch: Function,
      thenCustomArg: { menuType: MenuType }
    ) => {
      const { menuType } = thenCustomArg;
      const facilityMenus = json.map<FacilityMenu>(toFacilityMenu);

      await dispatch({
        type: 'FACILITY_MENUS_FETCHING_FINISHED',
        menus: facilityMenus,
        menuType,
        persistLocally,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching products');
      await dispatch({
        type: 'FACILITY_MENUS_FETCHING_FINISHED',
        menus: [],
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getMenusV2;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getAllergens = (() => {
  const lockAction = { type: 'FETCHING_ALLERGENS_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/menu/allergens`;

  const argConfig = {
    siteId: {
      toQueryString: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: Allergen[], dispatch: Function) => {
      await dispatch({
        type: 'FETCHED_ALLERGENS',
        allergens: json,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching allergens');
      await dispatch({
        type: 'FETCHED_ALLERGENS',
        allergens: [],
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getAllergens;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const downloadMenu = (() => {
  const lockAction = { type: 'FETCHING_MENU_URL_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/menu/download`;

  let argConfig = {
    siteId: {
      toQueryString: true,
    },
    menuId: {
      toQueryString: true,
    },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: DownloadMenuUrl, dispatch: Function) => {
      await dispatch({
        type: 'FETCHING_MENU_URL_FINISHED',
        url: json.url,
      });
    },
    other: () => {
      console.error('Error fetching menu download url');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getMenuDownloadUrl;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getPickupLocations = (() => {
  const lockAction = { type: 'FETCHING_PICKUP_LOCATIONS_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/order/pickup-spots`;

  const argConfig = {
    menuId: {
      toQueryString: true,
    },
    siteId: {
      toQueryString: true,
    },
    deliveryOptionId: {
      toQueryString: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: any, dispatch: Function) => {
      await dispatch({
        type: 'FETCHING_PICKUP_LOCATIONS_FINISHED',
        pickupLocations: json.pickupSpotsContent,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching pickup locations');
      await dispatch({
        type: 'FETCHING_PICKUP_LOCATIONS_FINISHED',
        pickupLocations: [],
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getPickupLocations;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getPickupTimeSlots = (() => {
  const lockAction = { type: 'FETCHING_PICKUP_TIME_SLOTS_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/order/time-slots`;

  const argConfig = {
    menuId: {
      toQueryString: true,
    },
    pickupSpotId: {
      toQueryString: true,
    },
    siteId: {
      toQueryString: true,
    },
    timeSlotDate: {
      toQueryString: true,
    },
    deliveryOptionId: {
      toQueryString: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: any, dispatch: Function) => {
      await dispatch({
        type: 'FETCHING_PICKUP_TIME_SLOTS_FINISHED',
        timeSlots: json.timeSlotsContent,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching pickup time slots');
      await dispatch({
        type: 'FETCHING_PICKUP_TIME_SLOTS_FINISHED',
        pickupTimeSlots: [],
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getPickupTimeSlots;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const upsertOrderDraft = (() => {
  const lockAction = { type: 'ORDER_DRAFT_REQUEST_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v2/order/draft`;

  const argConfig = {
    orderId: { toBody: true },
    siteId: { toQueryString: true },
    facilityId: { toBody: true },
    menuId: { toBody: true },
    pickupDate: { toBody: true },
    pickupSpotId: { toBody: true },
    pickupSpotName: { toBody: true },
    timeSlotId: { toBody: true },
    menuPortionItems: { toBody: true },
    instructions: {
      toBody: true,
      defaultValue: ({ pickupInformation }: { pickupInformation: PickupInformation }) =>
        pickupInformation?.pickupInstructions || '',
    },
    phoneNumber: {
      toBody: true,
      toThen: true,
      defaultValue: ({ pickupInformation }: { pickupInformation: PickupInformation }) =>
        pickupInformation?.pickupPhoneNumber || '',
    },
    trackingId: { toBody: true },
    theme: { toBody: true },
    deliveryOptionId: { toBody: true },
    deliveryOptionType: { toBody: true },
    table: { toBody: true },
    orderType: { toBody: true },
    email: {
      toBody: true,
      toThen: true,
      defaultValue: ({ pickupInformation }: { pickupInformation: PickupInformation }) =>
        pickupInformation?.email || '',
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    200: async (json: OrderDraft, dispatch: Function) => {
      await dispatch({
        type: 'ORDER_DRAFT_PERSISTED',
        orderDraft: json,
        persistLocally,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error posting order draft');
      await dispatch({
        type: 'ORDER_DRAFT_REQUEST_FINISHED',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.submitDraftOrder;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getPendingOrders = (() => {
  const lockAction = { type: 'PENDING_ORDERS_REQUEST_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/order/pending`;

  const argConfig = {
    siteId: {
      toQueryString: true,
    },
    facilityId: {
      toQueryString: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: PendingOrders[], dispatch: Function) => {
      await dispatch({
        type: 'PENDING_ORDERS_FETCHED',
        orders: json,
        persistLocally,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching pending orders');
      await dispatch({
        type: 'PENDING_ORDERS_REQUEST_FINISHED',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getPendingOrders;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const postOrder = (() => {
  const lockAction = { type: 'ORDER_REQUEST_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/order/submit`;

  const argConfig = {
    PaymentOnPickup: { toBody: true },
    OrderId: { toBody: true },
    FacilityId: { toBody: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    200: async (_: OrderDraft, dispatch: Function) => {
      await dispatch({
        type: 'ORDER_REQUEST_FINISHED',
        payForOrderError: false,
        persistLocally,
      });
      await dispatch({ type: 'CART_CLEANED' });
      await dispatch(setTable());
      await dispatch(loyaltyStampsApi.util.resetApiState());
      await dispatch(orderApi.util.invalidateTags(['reorder']));
    },
    other: async (dispatch: Function) => {
      console.error('Error submitting order');
      await dispatch({
        type: 'ORDER_REQUEST_FINISHED',
        payForOrderError: true,
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.postOrder;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const checkDiscountTiersStatus = (() => {
  const lockAction = { type: 'FETCHING_TIERS_STATUS_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/user/discountTier/status`;

  const argConfig = {
    siteId: {
      toThen: true,
      toQueryString: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: DiscountTiersStatus, dispatch: Function) => {
      await dispatch({
        type: 'TIERS_STATUS_FETCHING_FINISHED',
        status: json,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching products');
      await dispatch({
        type: 'TIERS_STATUS_FETCHING_FINISHED',
        status: {},
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getDiscountTiersStatus;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getDiscountTiers = (() => {
  const lockAction = { type: 'FETCHING_DISCOUNT_TIERS_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/user/discountTiers`;

  const argConfig = {
    siteId: {
      toThen: true,
      toQueryString: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: DiscountTiersModel, dispatch: Function) => {
      await dispatch({
        type: 'DISCOUNT_TIERS_FETCHING_FINISHED',
        discountTiers: json,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching discount tiers');
      await dispatch({
        type: 'DISCOUNT_TIERS_FETCHING_FINISHED',
        discountTiers: {},
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getDiscountTiers;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const setDiscountTier = (() => {
  const lockAction = { type: 'SETTING_DISCOUNT_TIER_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/user/discountTier`;

  const argConfig = {
    siteId: {
      toThen: true,
      toQueryString: true,
    },
    discountTierId: {
      toQueryString: true,
      toThen: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    },
  };

  const then = {
    200: async (_: any, dispatch: Function) => {
      await dispatch({
        type: 'SETTING_DISCOUNT_TIER_FINISHED',
        error: false,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error in setting discount tier');
      await dispatch({
        type: 'SETTING_DISCOUNT_TIER_FINISHED',
        error: true,
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getDiscountTiers;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getPreselectedFacility = (() => {
  const lockAction = { type: 'FETCHING_PRESELECTED_FACILITY_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/locations/mapping/facility`;

  const argConfig = {
    siteId: {
      toQueryString: true,
    },
    outletId: {
      toQueryString: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: FacilityMappingResult, dispatch: Function) => {
      await dispatch({
        type: 'FETCHING_PRESELECTED_FACILITY_FINISHED',
        facilityId: json.facilityId,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching facility id');
      await dispatch({
        type: 'FETCHING_PRESELECTED_FACILITY_FINISHED',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getFacilityId;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const getPreselectedSite = (() => {
  const lockAction = { type: 'FETCHING_PRESELECTED_SITE_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/locations/mapping/site`;

  const argConfig = {
    locationId: {
      toQueryString: true,
      toThen: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (
      json: SiteMappingResult,
      dispatch: Function,
      thenCustomArg: { locationId: string }
    ) => {
      await dispatch({
        type: 'FETCHING_PRESELECTED_SITE_FINISHED',
        siteId: json.siteId,
        locationId: thenCustomArg.locationId,
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching site id');
      await dispatch({
        type: 'FETCHED_EMPTY_PRESELECTED_SITE',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getSiteId;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const checkInAtTable = (() => {
  const lockAction = { type: 'CHECK_IN_AT_TABLE_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/order/check-in`;

  const argConfig = {
    SiteId: { toQueryString: true },
    OrderId: { toBody: true, toThen: true },
    DineInDeliveryOptionId: { toBody: true },
    Table: { toBody: true, toThen: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    204: async (
      json: any,
      dispatch: Function,
      thenCustomArg: { OrderId: number; Table: string }
    ) => {
      await dispatch({
        type: 'CHECK_IN_AT_TABLE_FINISHED',
        tableNumber: thenCustomArg.Table,
        orderNumber: thenCustomArg.OrderId.toString(),
      });
    },
    other: async (dispatch: Function) => {
      console.error('Error checking in');
      await dispatch({
        type: 'CHECK_IN_AT_TABLE_FINISHED',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.checkInAtTable;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const retrieveOrderSession = (() => {
  const lockAction = { type: 'CART_ORDER_SESSION_RETRIEVED_STARTED' };
  const urlConstructor = () => `${globalRetailApiPath}/v1/order/draft/:trackingId`;

  const argConfig = {
    trackingId: {
      toRoute: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: DraftOrderResponse, dispatch: Function) => {
      await dispatch(
        getAll({
          siteId: json.siteId,
        })
      );

      await dispatch(
        getMenus({
          siteId: json.siteId,
          date: momentjs().format(DATE_FORMAT),
          useCache: true,
        })
      );

      const newTrackingId = uuid();
      const draftOrderWithNewTrackingId = { ...json, trackingId: newTrackingId };
      await dispatch({
        type: 'CART_ORDER_SESSION_RETRIEVED',
        orderDraft: draftOrderWithNewTrackingId,
      });

      await clearKioskSessionMutation({ trackingId: json.trackingId });
      await dispatch(setNewSubmissionTrackingId({ submissionTrackingId: newTrackingId }));
      await dispatch(
        upsertOrderDraft(
          mapDraftOrderResponseToCartRequestModel(draftOrderWithNewTrackingId, json.siteId)
        )
      );

      if (
        draftOrderWithNewTrackingId.deliveryOptionId &&
        draftOrderWithNewTrackingId.deliveryOptionType
      ) {
        setFulfillmentType({
          id: draftOrderWithNewTrackingId.deliveryOptionId,
          type: draftOrderWithNewTrackingId.deliveryOptionType,
        });

        await dispatch(
          getPickupLocations({
            menuId: draftOrderWithNewTrackingId.menuId,
            siteId: draftOrderWithNewTrackingId.siteId,
            deliveryOptionId: draftOrderWithNewTrackingId.deliveryOptionId,
          })
        );

        if (draftOrderWithNewTrackingId.pickupSpot?.id) {
          await dispatch(
            getPickupTimeSlots({
              menuId: draftOrderWithNewTrackingId.menuId,
              siteId: draftOrderWithNewTrackingId.siteId,
              deliveryOptionId: draftOrderWithNewTrackingId.deliveryOptionId,
              pickupSpotId: draftOrderWithNewTrackingId.pickupSpot?.id,
              timeSlotDate: draftOrderWithNewTrackingId.pickupDate,
            })
          );

          setPickupInformation({
            pickupSpotId: draftOrderWithNewTrackingId.pickupSpot?.id,
            pickupSpotName: draftOrderWithNewTrackingId.pickupSpot?.name,
            pickupTimeSlotId: draftOrderWithNewTrackingId.timeSlotId,
            pickupTime: draftOrderWithNewTrackingId.pickupDate,
            pickupInstructions: draftOrderWithNewTrackingId.instructions,
            pickupPhoneNumber: draftOrderWithNewTrackingId.contactPhoneNumber,
          });
        }
      }
    },
    other: async (dispatch: Function) => {
      console.error('Error fetching order draft');
      await dispatch({
        type: 'CART_ORDER_SESSION_RETRIEVED_FAILED',
      });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getDraftOrder;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();
