import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { checkDocsToAcknowledge } from '../helpers/helpers';
import { State, Actions } from '../types/index';
import { Service, SetupOption } from '../types/State.types';

import { scheduleLegalDocsBackgroundCheck } from '@/helpers/checkLegalDocsScheduler';

let defaultAccess = {
  invalidPsw: false,
  isFirstLogin: true,
  termsOfUseToAcknowledge: undefined,
  privacyPolicyToAcknowledge: undefined,
  termsOfSaleToAcknowledge: undefined,
  shouldSelectSiteContext: undefined,
};

const defaultContext = {
  currentGeoCode: undefined,
  theme: null,
  site: undefined,
  siteIdBeforeChange: null,
};

const defaultLegalDocs = {
  list: undefined, //undefined indicates the legal docs were not fetched, [] indicates no legal docs
  detailed: {},
};

const defaultServices = {
  list: [],
  visited: [],
};

const defaultSites = {
  list: [],
  visited: {},
};

const defaultUser = {
  acknowledgements: undefined, //undefined indicates the acknowledgements were not fetched, [] indicates no acknowledgements
  contract: undefined,
  preferredLanguage: { id: '4bba9048-623f-4d2f-aa81-64da7fac16a4', languageCode: 'en-US' },
  theme: null,
  username: '',
  firstName: '',
  isGuest: false,
  isSSOUser: false,
};

const defaultIdentity = {
  client: null,
  type: null,
  provider: null,
  tokenUrl: null,
};

export const defaultState = {
  access: defaultAccess,
  context: defaultContext,
  firebase: {},
  legalDocs: defaultLegalDocs,
  locks: {},
  errors: {},
  notifications: {
    items: [],
    pageIndex: 0,
    total: 0,
    unreadNotificationCount: 0,
  },
  services: defaultServices,
  sites: defaultSites,
  user: defaultUser,
  identity: defaultIdentity,
  appVersionInfo: undefined,
};

const coreReducer = (state: State = defaultState, action: Actions) => {
  // value of these 2 is set through checkDocsToAcknowledge() below
  let docsToAcknowledge;

  switch (action.type) {
    case 'RESET_STATE':
      const currentGeoCode = state.context.currentGeoCode;
      return {
        ...defaultState,
        context: {
          ...defaultContext,
          currentGeoCode,
        },
      };
    case 'FIREBASE_TOKEN_SET_STATUS':
      return {
        ...state,
        firebase: {
          isTokenExisting: action.isTokenExisting,
        },
      };
    case 'SET_DATA_TRACKING':
      return {
        ...state,
        user: {
          ...state.user,
          useDataTracking: action.dataTracking,
        },
      };
    case 'LOAD_CORE_CACHE':
      return {
        ...state,
        ...action.cachedData,
      };
    case 'GEOGRAPHY_SET':
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, action.geoCode);
      return {
        ...state,
        context: {
          ...state.context,
          currentGeoCode: action.geoCode,
        },
      };
    case 'USERNAME_SET':
      return {
        ...state,
        user: {
          ...state.user,
          username: action.username,
        },
      };
    case 'CONTRACT_AND_SITE_FETCHING':
      return {
        ...state,
        locks: {
          ...state.locks,
          registrationContractAndSite: true,
        },
      };
    case 'CONTRACT_AND_SITE_FETCHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          registrationContractAndSite: false,
        },
      };
    case 'ONBOARDING_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          onboarding: true,
        },
      };
    case 'ONBOARDING_FINISHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          onboarding: false,
        },
      };
    case 'LOGIN_START':
      return {
        ...state,
        locks: {
          ...state.locks,
          loggingIn: true,
        },
      };
    case 'LOGIN_END':
      return {
        ...state,
        locks: {
          ...state.locks,
          loggingIn: false,
        },
      };

    case 'LOGGED_IN':
      return {
        ...defaultState,
        access: {
          ...defaultAccess,
          invalidPsw: false,
        },
        context: {
          ...defaultContext,
          currentGeoCode: action.currentGeoCode,
        },
        user: {
          ...defaultUser,
          isGuest: action.isGuest,
          isSSOUser: action.isSSOUser,
          username: action.username || state.user.username, //if refreshing the token
        },
      };
    case 'TOKEN_REFRESH_START':
      return {
        ...state,
        locks: {
          ...state.locks,
          tokenRefresh: true,
        },
      };
    case 'TOKEN_REFRESH_END':
      return {
        ...state,
        locks: {
          ...state.locks,
          tokenRefresh: false,
        },
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: {},
      };
    case 'MIGRATION_START':
      return {
        ...state,
        locks: {
          ...state.locks,
          migrateOldBiteUser: true,
        },
      };
    case 'MIGRATION_END':
      return {
        ...state,
        userMigration: action.userMigration,
        locks: {
          ...state.locks,
          migrateOldBiteUser: false,
        },
      };
    case 'USER_CONTEXT_FETCHING':
      return {
        ...state,
        locks: {
          ...state.locks,
          getUserContext: true,
        },
      };
    case 'USER_CONTEXT_FETCHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getUserContext: false,
        },
      };
    case 'USER_CONTEXT_RETURNED':
      return {
        ...state,
        access: {
          ...state.access,
          ...action.access,
        },
        user: {
          ...state.user,
          ...action.user,
          username: state.user.username, //keep the username since it's defined at login
        },
        context: {
          ...defaultContext,
          ...action.context,
          currentGeoCode: action.context.currentGeoCode || state.context.currentGeoCode,
          previousSite: { ...state.context.site },
          siteHasChanged: action.context.site?.id !== state.context.site?.id,
        },
      };
    case 'CLEAR_SITE_ID_BEFORE_CHANGE':
      return {
        ...state,
        context: {
          ...state.context,
          previousSite: null,
        },
      };
    case 'FIRST_LOGIN_COMPLETED':
      return {
        ...state,
        access: {
          ...state.access,
          isFirstLogin: false,
        },
      };
    case 'USER_CONTEXT_UPDATING':
      return {
        ...state,
        locks: {
          ...state.locks,
          updateUserContext: true,
        },
      };
    case 'USER_CONTEXT_UPDATE_FINISHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          updateUserContext: false,
        },
      };
    case 'USER_CONTEXT_UPDATE_ACCEPTED':
      const { mobile, preferredLanguageId, preferredLocation } = action;

      let newUser = state.user;
      if (mobile) newUser.mobile = mobile;
      if (preferredLanguageId) newUser.preferredLanguageId = preferredLanguageId;
      if (preferredLocation) newUser.preferredLocation = preferredLocation;

      return {
        ...state,
        locks: {
          ...state.locks,
          updateUserContext: false,
        },
        user: newUser,
      };
    case 'CLEAR_SITE_CONTEXT':
      return {
        ...state,
        context: {
          ...state.context,
          site: null,
        },
      };
    case 'ALL_LEGAL_DOCUMENTS_FETCHING':
      return {
        ...state,
        locks: {
          ...state.locks,
          getAllLegalDocuments: true,
        },
      };
    case 'ALL_LEGAL_DOCUMENTS_RETURNED':
      docsToAcknowledge = checkDocsToAcknowledge({
        documents: action.documents,
        acknowledgements: state.user.acknowledgements || [],
      });
      const legalDocsFetchedAt = new Date();
      scheduleLegalDocsBackgroundCheck(legalDocsFetchedAt);

      return {
        ...state,
        access: {
          ...state.access,
          termsOfUseToAcknowledge: docsToAcknowledge.termsOfUseToAcknowledge,
          privacyPolicyToAcknowledge: docsToAcknowledge.privacyPolicyToAcknowledge,
          termsOfSaleToAcknowledge: docsToAcknowledge.termsOfSaleToAcknowledge,
        },
        legalDocs: {
          ...state.legalDocs,
          list: action.documents,
          fetchedAt: legalDocsFetchedAt,
        },
        locks: {
          ...state.locks,
          getAllLegalDocuments: false,
        },
      };
    case 'ALL_LEGAL_DOCUMENTS_ERROR':
      return {
        ...state,
        locks: {
          ...state.locks,
          getAllLegalDocuments: false,
        },
      };
    case 'ONE_LEGAL_DOCUMENTS_FETCHING':
      return {
        ...state,
        locks: {
          ...state.locks,
          getOneLegalDocument: true,
        },
      };
    case 'ONE_LEGAL_DOCUMENT_RETURNED':
      return {
        ...state,
        legalDocs: {
          ...state.legalDocs,
          detailed: {
            ...state.legalDocs.detailed,
            [action.id]: action.data,
          },
        },
        locks: {
          ...state.locks,
          getOneLegalDocument: false,
        },
      };
    case 'ONE_LEGAL_DOCUMENTS_ERROR': {
      return {
        ...state,
        locks: {
          ...state.locks,
          getOneLegalDocument: false,
        },
      };
    }
    case 'ONE_PUBLIC_LEGAL_DOCUMENTS_FETCHING':
      return {
        ...state,
        locks: {
          ...state.locks,
          getPublicLegalDocument: true,
        },
      };
    case 'ONE_PUBLIC_LEGAL_DOCUMENT_RETURNED':
      return {
        ...state,
        legalDocs: {
          ...state.legalDocs,
          detailed: {
            ...state.legalDocs.detailed,
            [action.id]: action.data,
          },
        },
        locks: {
          ...state.locks,
          getPublicLegalDocument: false,
        },
      };
    case 'ONE_PUBLIC_LEGAL_DOCUMENTS_ERROR': {
      return {
        ...state,
        locks: {
          ...state.locks,
          getPublicLegalDocument: false,
        },
      };
    }
    case 'ACKNOWLEDGMENTS_FETCHING':
      return {
        ...state,
        locks: {
          ...state.locks,
          getAcknowledgements: true,
        },
      };
    case 'ACKNOWLEDGMENTS_RETURNED':
      docsToAcknowledge = checkDocsToAcknowledge({
        documents: state.legalDocs.list || [],
        acknowledgements: action.acknowledgements,
      });

      return {
        ...state,
        user: {
          ...state.user,
          acknowledgements: action.acknowledgements,
        },
        access: {
          ...state.access,
          termsOfUseToAcknowledge: docsToAcknowledge.termsOfUseToAcknowledge,
          privacyPolicyToAcknowledge: docsToAcknowledge.privacyPolicyToAcknowledge,
          termsOfSaleToAcknowledge: docsToAcknowledge.termsOfSaleToAcknowledge,
        },
        locks: {
          ...state.locks,
          getAcknowledgements: false,
        },
      };
    case 'ACKNOWLEDGMENTS_ERROR': {
      return {
        ...state,
        locks: {
          ...state.locks,
          getAcknowledgements: false,
        },
      };
    }
    case 'ACKNOWLEDGMENT_ACCEPTED_STARTED':
      return {
        ...state,
        locks: {
          ...state.locks,
          acknowledgmentAccept: true,
        },
      };
    case 'ACKNOWLEDGMENT_ACCEPTED': //#todo not tested
      let newAcknowledgements = state.user.acknowledgements;
      if (!newAcknowledgements?.find((el) => el.documentId === action.documentId))
        newAcknowledgements?.push({
          documentId: action.documentId,
          type: {
            value: action.documentTypeCode,
          },
          acknowledgedOn: action.acknowledgedOn,
        });

      docsToAcknowledge = checkDocsToAcknowledge({
        documents: state.legalDocs.list || [],
        acknowledgements: newAcknowledgements || [],
      });

      return {
        ...state,
        access: {
          ...state.access,
          termsOfUseToAcknowledge: docsToAcknowledge.termsOfUseToAcknowledge,
          privacyPolicyToAcknowledge: docsToAcknowledge.privacyPolicyToAcknowledge,
          termsOfSaleToAcknowledge: docsToAcknowledge.termsOfSaleToAcknowledge,
        },
        user: {
          ...state.user,
          acknowledgements: newAcknowledgements,
        },
        locks: {
          ...state.locks,
          acknowledgmentAccept: false,
        },
      };
    case 'COMMUNICATION_PREFERENCES_UPDATED':
      return {
        ...state,
        user: {
          ...state.user,
          communicationPreferences: {
            allowContent: action.allowContent,
            allowOffers: action.allowOffers,
          },
        },
      };
    case 'AVAILABLE_SERVICES_DEFINED':
      const availableServices = action.services.map((service: Service) => {
        return {
          id: service.id,
          name: service.name,
          navigation: service.navigation,
          setupOptions: service.setupOptions?.reduce(
            //clean setup options: remove empty ones, trim the others
            (setupOptions: SetupOption[], setupOption: SetupOption) => {
              if (setupOption.code)
                setupOptions.push({
                  ...setupOption,
                  code: setupOption.code.trim(),
                  value: setupOption.value.trim(),
                });
              return setupOptions;
            },
            []
          ),
          contentPageId: service.contentPageId,
        };
      });
      return {
        ...state,
        services: {
          ...state.services,
          list: availableServices,
        },
      };
    case 'THEME_RETURNED':
      return {
        ...state,
        context: {
          ...state.context,
          theme: {
            id: action.id,
            name: action.name,
            settings: action.settings,
          },
        },
      };
    // TODO: Check if this action is needed for anything.
    // It doesn't do what the name implies.
    case 'USER_REGION_CODE_SET':
      return {
        ...state,
      };

    case 'FETCHING_AVAILABLE_SITES_STARTED':
      return {
        ...state,
        locks: { getPublicSites: true },
      };
    case 'FETCHING_AVAILABLE_SITES_FINISHED':
      return {
        ...state,
        sites: {
          ...state.sites,
          list: action.sites,
        },
        locks: { getPublicSites: false },
      };
    case 'FETCHING_AVAILABLE_SITES_FAILED':
      return {
        ...state,
        locks: { getPublicSites: false },
      };

    case 'FETCHING_SITE_BY_CODE_STARTED':
      return {
        ...state,
        locks: { getSiteByCode: true },
      };
    case 'FETCHING_SITE_BY_CODE_FINISHED':
      return {
        ...state,
        locks: { getSiteByCode: false },
      };
    case 'FETCHING_SITE_BY_CODE_FAILED':
      return {
        ...state,
        locks: { getSiteByCode: false },
      };

    case 'REGISTRATION_POSTING':
      return {
        ...state,
        locks: { register: true },
      };

    case 'REGISTRATION_POSTED':
      return {
        ...state,
        locks: { register: false },
      };

    case 'CONVERT_GUEST_TO_REGISTERED_USER_POSTING':
      return {
        ...state,
        locks: { convertingGuestToRegisteredUser: true },
      };

    case 'CONVERT_GUEST_TO_REGISTERED_USER_POSTED':
      return {
        ...state,
        locks: { convertingGuestToRegisteredUser: false },
      };

    case 'CONVERT_GUEST_TO_REGISTERED_USER_ERROR':
      return {
        ...state,
        locks: { convertingGuestToRegisteredUser: false },
        errors: { convertingGuestToRegisteredUser: action.errorMessage },
      };

    case 'GUEST_USER_REGISTRATION_STARTED':
      return {
        ...state,
        locks: { registerGuestUser: true },
      };

    case 'GUEST_USER_REGISTRATION_FINISHED':
      return {
        ...state,
        user: { ...state.user },
        locks: { registerGuestUser: false },
      };

    case 'GUEST_USER_REGISTRATION_FAILED':
      return {
        ...state,
        locks: { registerGuestUser: false },
      };

    case 'ACCOUNT_DELETION':
      return {
        ...state,
        locks: {
          ...state.locks,
          deleteAccount: true,
        },
      };
    case 'ACCOUNT_DELETION_SUCCESS':
      return {
        ...state,
        locks: {
          ...state.locks,
          deleteAccount: true,
        },
      };
    case 'ACCOUNT_DELETION_FAILURE':
      return {
        ...state,
        locks: {
          ...state.locks,
          deleteAccount: false,
        },
      };
    case 'DEV_SCHEDULE_LEGAL_DOC_CHECK':
      if (process.env.REACT_APP_PORTAL_ENV_CODE === 'prod') return state;

      scheduleLegalDocsBackgroundCheck(action.date);
      return {
        ...state,
        legalDocs: {
          ...state.legalDocs,
          fetchedAt: action.date,
        },
      };

    case 'SEND_SUPPORT_EMAIL_REQUEST':
      return {
        ...state,
        locks: { ...state.locks, sendSupportEmail: true },
      };
    case 'SEND_SUPPORT_EMAIL_SUCCESS':
    case 'SEND_SUPPORT_EMAIL_FAILURE':
      return {
        ...state,
        locks: { ...state.locks, sendSupportEmail: false },
      };
    case 'SEND_FORGOTTEN_EMAIL_REQUEST':
      return {
        ...state,
        locks: { ...state.locks, sendForgottenEmail: true },
      };
    case 'SEND_FORGOTTEN_EMAIL_FAILURE':
      return {
        ...state,
        locks: { ...state.locks, sendForgottenEmail: false },
      };
    case 'SEND_FORGOTTEN_EMAIL_SUCCESS':
      return {
        ...state,
        forgottenMail: {
          email: action.email,
        },
        locks: { ...state.locks, sendForgottenEmail: false },
      };
    case 'RESET_EMAIL_REQUEST':
      return {
        ...state,
        locks: { ...state.locks, resetEmail: true },
      };
    case 'RESET_EMAIL_FAILURE':
      return {
        ...state,
        locks: { ...state.locks, resetEmail: false },
      };
    case 'RESET_EMAIL_SUCCESS':
      return {
        ...state,
        locks: { ...state.locks, resetEmail: false },
      };
    case 'GET_APP_VERSION_REQUEST':
      return {
        ...state,
        locks: { ...state.locks, getAppVersion: true },
      };
    case 'GET_APP_VERSION_SUCCESS':
      return {
        ...state,
        appVersionInfo: action.appVersionInfo,
        locks: { ...state.locks, getAppVersion: false },
      };
    case 'GET_APP_VERSION_FAILURE':
      return {
        ...state,
        locks: { ...state.locks, getAppVersion: false },
      };
    case 'IS_SSO_SET':
      return { ...state, user: { ...state.user, isSSOUser: action.isSSOUser } };
    case 'SET_MS_TEAMS_CONTEXT':
      return {
        ...state,
        msTeamsContext: {
          ...state.msTeamsContext,
          TeamsContext: action.TeamsContext,
          shouldRetryInit: action.shouldRetryInit,
        },
      };
    default:
      return state;
  }
};

export default coreReducer;
