import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { InformationIcon, WarningOutlinedIcon } from '../../../assets/icons';
import {
  CoffeeProgress2Illustration,
  CafeteriaIllustration,
  LoyaltyCoffeeIllustration,
} from '../../../assets/illustrations';
import ProgressBar from '../../../components/atoms/ProgressBar/ProgressBar';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import Card from '../../../components/molecules/Card/Card';
import Modal from '../../../components/organisms/Modal';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import useToggle from '../../../helpers/hooks/useToggle';
import { useGetStampsQuery } from '../api/api';
import { defaultValues } from '../config';
import { getStampsContent } from '../helpers/stamps.helper';
import { useStampsTranslation } from '../hooks/useStampsTranslation';

import StampActionButton from './StampActionButton';

import WidgetPlaceholder from '@/components/molecules/WidgetPlaceholder';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useSite from '@/modules/Core/hooks/useSite';
import { UserSteps } from '@/types/userSteps.types';

import styles from './StampPage.module.css';

const StampPage = () => {
  const { label } = useStampsTranslation(__filename);
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const [redeemTimer, setRedeemTimer] = useState(defaultValues.redeemTimeInSeconds);
  const [isRedeem, setIsRedeem] = useState(false);

  const { logUserSteps } = useUserStepsInsightsLogging();

  const {
    data: stampCollection,
    isLoading: isLoadingStamps,
    error: errorGetStamps,
  } = useGetStampsQuery({ siteId: site.id, useErrorBoundary: false });

  const { isRewardAvailable } = stampCollection || {};

  const { title, titleSuffix, description } = getStampsContent({
    isRewardAvailable,
    isRedeem,
  });

  const displayedTitle = label(title) + (titleSuffix ? ' ' + titleSuffix : '');

  const {
    state: isInfoModalOpen,
    toggleOn: openInfoModal,
    toggleOff: hideInfoModal,
  } = useToggle(false);

  const openInfoModalAndLog = () => {
    openInfoModal();
    logUserSteps({ event: UserSteps.OpenedInfoModal });
  };

  useEffect(() => {
    logUserSteps({ event: UserSteps.FreeCoffeePage });
  }, [logUserSteps]);

  const progressBarProps = isRedeem
    ? {
        value: redeemTimer,
        max: defaultValues.redeemTimeInSeconds,
        formatProgressLabel: (v: number) => `${v} ${label('Ref: seconds left')}`,
        'data-testid': 'coffee-stamps-redeem-timer',
      }
    : {
        value: stampCollection?.stampsCollected,
        max: stampCollection?.stampsToCollectReward,
        ...(isLoadingStamps
          ? { formatProgressLabel: () => <span className={styles.loading}></span> }
          : {}),
        'data-testid': 'coffee-stamps-collection',
      };

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      hasBackLink={false}
      actions={[]}
      actionsBarTopContent={null}
    >
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            <Title
              className={styles.title}
              size={TITLE_SIZE.HEADLINES}
              tag={TITLE_TAG.H2}
              {...(!isRedeem && !isRewardAvailable
                ? {
                    suffix: (
                      <div className={styles.iconWrapper}>
                        {<InformationIcon width={32} height={32} onClick={openInfoModalAndLog} />}
                      </div>
                    ),
                  }
                : {})}
            >
              {displayedTitle}
            </Title>
            <Title
              className={classNames('mb-M', styles.description)}
              size={TITLE_SIZE.BODYSDEFAULT}
              tag={TITLE_TAG.H3}
            >
              {label(description)}
            </Title>
            {errorGetStamps ? (
              <WidgetPlaceholder
                icon={<WarningOutlinedIcon />}
                title={label('Something went wrong, data not loaded')}
              />
            ) : (
              <Card className="mb-M">
                <div className={classNames(styles.imageWrapper)}>
                  <LoyaltyCoffeeIllustration className={styles.coffeeImage} />
                </div>
                <ProgressBar round {...progressBarProps} />
              </Card>
            )}
            <ActionsBar>
              <StampActionButton
                isRedeem={isRedeem}
                isRewardAvailable={isRewardAvailable ?? false}
                isLoadingStamps={isLoadingStamps}
                setIsRedeem={setIsRedeem}
                setRedeemTimer={setRedeemTimer}
              ></StampActionButton>
            </ActionsBar>
            <Modal
              isOpen={isInfoModalOpen}
              onDismiss={hideInfoModal}
              title={label('Ref: How it works')}
            >
              <Modal.ColumnLayout image={<CoffeeProgress2Illustration />}>
                {label('Ref: How it works description')}
              </Modal.ColumnLayout>
            </Modal>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <CafeteriaIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default StampPage;
