import biteApi from '../../../services/api/biteApi';
import { toFacilityMenu } from '../helpers/menus.helper';
import mockResponse from '../mockResponse';
import { EmailArgs, PrintArgs } from '../types/KioskOrder.types';
import {
  FacilityMenu,
  FacilityMenuJson,
  OrderDetails,
  OrderHistory,
  PromotionInfo,
  ReorderMenuItem,
  Suggestions,
} from '../types/orderState.types';

import {
  GetAvailablePromotionsArgs,
  GetLastOrderForTransactionalFeedbackArgs,
  GetLastOrderForTransactionalFeedbackResponse,
  GetMenusArgs,
  GetOrderHistoryArgs,
  GetSuggestionsArgs,
  GetReorderPropositionArgs,
  SaveLastTransactionalFeedbackDateArgs,
  CheckCanOrderArgs,
} from './api.types';

const orderApi = biteApi
  .enhanceEndpoints({ addTagTypes: ['orderReceipt', 'reorder', 'promotions'] })

  .injectEndpoints({
    endpoints: (builder) => ({
      printReceipt: builder.mutation<void, PrintArgs>({
        query: (args) => ({
          url: `/v1/order/print-receipt`,
          method: 'post',
          params: { siteId: args.siteId, orderId: args.orderId },
          mockData: mockResponse.printReceipt,
        }),
      }),
      emailReceipt: builder.mutation<void, EmailArgs>({
        query: (args) => ({
          url: `/v1/order/email-receipt`,
          method: 'post',
          params: { siteId: args.siteId, orderId: args.orderId, email: args.email },
          mockData: mockResponse.emailReceipt,
        }),
      }),
      downloadReceipt: builder.mutation<{ content: string }, { siteId: string; orderId: number }>({
        query: (args) => ({
          url: `/v1/order/${args.orderId}/receipt`,
          method: 'get',
          params: { siteId: args.siteId, orderId: args.orderId },
          mockData: mockResponse.downloadReceipt,
        }),
      }),
      getOrderDetails: builder.query<OrderDetails, { siteId: string; orderId: number }>({
        query: ({ siteId, orderId }) => ({
          url: `/v2/order/${orderId}?siteId=${siteId}`,
          method: 'get',
          mockData: mockResponse.getOrderDetails,
        }),
        keepUnusedDataFor: 5,
      }),
      getOrderHistory: builder.query<OrderHistory[], GetOrderHistoryArgs>({
        query: ({ siteId, useErrorBoundary }: GetOrderHistoryArgs) => ({
          url: `/v1/order/history?siteId=${siteId}`,
          method: 'get',
          mockData: mockResponse.getOrderHistory,
          opts: { useErrorBoundary },
        }),
        keepUnusedDataFor: 5,
      }),
      getMenus: builder.query<FacilityMenu[], GetMenusArgs>({
        query: ({ siteId, date, useCache, useErrorBoundary }: GetMenusArgs) => ({
          url: '/v2/menu',
          method: 'get',
          params: { siteId, date, useCache },
          mockData: mockResponse.getMenusV2,
          opts: { useErrorBoundary },
        }),
        transformResponse: (response: FacilityMenuJson[]) =>
          response.map<FacilityMenu>(toFacilityMenu),
      }),
      getReorderProposition: builder.query<ReorderMenuItem[], GetReorderPropositionArgs>({
        query: ({
          siteId,
          date,
          noOfItemsToReturn,
          useErrorBoundary,
        }: GetReorderPropositionArgs) => ({
          url: '/v1/menu/reorder-proposition',
          method: 'get',
          params: { siteId, date, noOfItemsToReturn },
          mockData: mockResponse.getReorderProposition,
          opts: { useErrorBoundary },
        }),
        providesTags: () => ['reorder'],
      }),
      addFavoriteProduct: builder.mutation<void, { siteId: string; uomId: number }>({
        query: (args) => ({
          url: `/v1/user/favorite-product/${args.uomId}?siteId=${args.siteId}`,
          method: 'post',
          mockData: mockResponse.addProductToFavorites,
        }),
      }),
      removeFavoriteProduct: builder.mutation<void, { siteId: string; uomId: number }>({
        query: (args) => ({
          url: `/v1/user/favorite-product/${args.uomId}?siteId=${args.siteId}`,
          method: 'delete',
          mockData: mockResponse.removeProductFromFavorites,
        }),
      }),
      getAvailablePromotions: builder.query<PromotionInfo[], GetAvailablePromotionsArgs>({
        query: ({ siteId, useErrorBoundary }: GetAvailablePromotionsArgs) => ({
          url: '/v1/locations/promotions',
          method: 'get',
          params: { siteId },
          mockData: mockResponse.getReorderProposition,
          opts: { useErrorBoundary },
        }),
        providesTags: () => ['promotions'],
      }),
      getSuggestions: builder.query<Suggestions, GetSuggestionsArgs>({
        query: ({
          siteId,
          dateTime,
          menuId,
          noOfSuggestionsToReturn,
          useErrorBoundary,
        }: GetSuggestionsArgs) => ({
          url: '/v1/menu/suggestions',
          method: 'get',
          params: { siteId, dateTime, menuId, noOfSuggestionsToReturn },
          mockData: mockResponse.getReorderProposition,
          opts: { useErrorBoundary },
        }),
      }),
      checkCanOrder: builder.query<{ isOrderingPossible: boolean }, CheckCanOrderArgs>({
        query: ({
          siteId,
          menuId,
          facilityId,
          orderPickupDate,
          useErrorBoundary,
        }: CheckCanOrderArgs) => ({
          url: '/v1/menu/can-order',
          method: 'get',
          params: { siteId, menuId, facilityId, orderPickupDate },
          mockData: mockResponse.checkCutOffDate,
          opts: { useErrorBoundary: useErrorBoundary },
        }),
      }),
    }),
    overrideExisting: true,
  })
  .enhanceEndpoints({ addTagTypes: ['transactionalFeedback'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getLastOrderForTransactionalFeedback: builder.query<
        GetLastOrderForTransactionalFeedbackResponse,
        GetLastOrderForTransactionalFeedbackArgs
      >({
        query: (args) => ({
          url: `/v1/order/feedback/to-evaluate`,
          method: 'get',
          params: { siteId: args.siteId, fromDate: args.fromDate },
          mockData: mockResponse.getLastOrderForTransactionalFeedback,
        }),
      }),
      saveLastTransactionalFeedbackDate: builder.mutation<
        void,
        SaveLastTransactionalFeedbackDateArgs
      >({
        query: (args) => ({
          url: `/v1/order/feedback/cutoff`,
          method: 'post',
          params: { siteId: args.siteId },
          mockData: mockResponse.saveLastTransactionalFeedbackDate,
          data: { orderPickupDate: args.orderPickupDate },
        }),
      }),
    }),
    overrideExisting: true,
  });

export const {
  usePrintReceiptMutation,
  useDownloadReceiptMutation,
  useEmailReceiptMutation,
  useGetOrderDetailsQuery,
  useGetOrderHistoryQuery,
  useGetLastOrderForTransactionalFeedbackQuery,
  useSaveLastTransactionalFeedbackDateMutation,
  useGetReorderPropositionQuery,
  useGetMenusQuery,
  useLazyGetMenusQuery,
  useAddFavoriteProductMutation,
  useRemoveFavoriteProductMutation,
  useGetAvailablePromotionsQuery,
  useLazyGetAvailablePromotionsQuery,
  useGetSuggestionsQuery,
  useLazyGetSuggestionsQuery,
  useLazyCheckCanOrderQuery,
} = orderApi;

export default orderApi;
