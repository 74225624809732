import { IonModal } from '@ionic/react';
import classNames from 'classnames';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import ColumnLayout from '../Modal/ColumnLayout';
import RowLayout from '../Modal/RowLayout';

import { ExpandableModalProps } from './ExpandableModal.types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';

import styles from './ExpandableModal.module.css';

export const ExpandableModal = ({
  children,
  header,
  id,
  isOpen,
  footer,
  onDismiss,
  backdropDismiss = true,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  srOnlyTextContent,
}: ExpandableModalProps) => {
  const { label } = useCustomTranslation();
  return (
    <IonModal
      id={id}
      isOpen={isOpen}
      cssClass={'modal expandable'}
      onDidDismiss={() => onDismiss && onDismiss()}
      data-cy={dataCy}
      data-testid={dataTestId}
      backdropDismiss={backdropDismiss}
      onDidPresent={() => {
        const modalElement: HTMLElement | null = document.querySelector(
          `#${id} div[role="dialog"]`
        );
        if (modalElement) {
          modalElement.setAttribute('aria-describedby', `${id}-title-label`);
          modalElement.setAttribute('aria-label', label('Ref: Global search modal'));
          modalElement.setAttribute('aria-modal', 'true');
        }
      }}
    >
      <div
        id={`${id}-header`}
        data-testid={`${dataTestId}-header`}
        className={classNames(styles.header)}
      >
        {header}
        <Button
          look={BUTTON_LOOK.SECONDARY}
          onClick={() => onDismiss && onDismiss()}
          aria-label={label('close')}
          data-testid={`${dataTestId}-button-close`}
          className={classNames(styles.closeButton)}
        >
          {backdropDismiss && label('close')}
        </Button>
      </div>
      <span className={classNames(styles.srOnly)} aria-live="polite">
        {srOnlyTextContent}
      </span>
      {children && (
        <>
          <hr className={classNames(styles.line)} />
          <div
            id={`${id}-content`}
            data-testid={`${dataTestId}-content`}
            className={classNames(styles.content)}
          >
            {children}
          </div>
        </>
      )}
      {footer && (
        <div
          id={`${id}-footer`}
          data-testid={`${dataTestId}-footer`}
          className={classNames(styles.footer)}
        >
          {footer}
        </div>
      )}
      {/*Hack for setting up .ion-page css class and defined height of modal for mobile to see entire content*/}
      <div className={classNames(styles.filler)} data-testid={`${dataTestId}-filler`}></div>
    </IonModal>
  );
};

export default Object.assign(ExpandableModal, {
  ColumnLayout: ColumnLayout,
  RowLayout: RowLayout,
});
