import { cleanup, screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultSite } from '../../../Sites/__mocks/mocks';
import { defaultProductPortion, defaultSuggestion } from '../../__mocks/mock';

import SuggestionContainer from './SuggestionContainer';

const props = {
  id: 'suggestions',
  date: new Date(),
  label: () => '',
  onScanButtonClick: () => {},
};

const menus = [
  {
    id: 1,
    facilityId: 'facilityId',
    name: 'menu 1',
    isOrderable: true,
    isScanAndGo: false,
    date: new Date().toString(),
    menuItems: [],
  },
];

const suggestions = [
  {
    ...defaultSuggestion,
    menuId: menus[0].id,
    menuItemId: 11,
    productPortions: [
      {
        ...defaultProductPortion,
        isDefault: true,
      },
    ],
  },
  {
    ...defaultSuggestion,
    menuId: menus[0].id,
    menuItemId: 12,
    productPortions: [
      {
        ...defaultProductPortion,
        isDefault: true,
      },
    ],
  },
  {
    ...defaultSuggestion,
    menuId: menus[0].id,
    menuItemId: 13,
    productPortions: [
      {
        ...defaultProductPortion,
        isDefault: true,
      },
    ],
  },
];

const state = {
  Order: {
    locks: {},
    cart: {},
  },
  Core: {
    context: {
      site: defaultSite,
    },
  },
};

jest.mock('../../hooks/useLoyaltyReward/useLoyaltyReward', () => ({
  __esModule: true,
  useLoyaltyReward: () => ({
    portionsFreeItemsInfo: [],
    isLoading: false,
  }),
}));

describe('SuggestionContainer', () => {
  afterAll(() => cleanup());

  describe('when is loading', () => {
    renderComponent(
      SuggestionContainer,
      { ...props, isLoading: true, menus: [], suggestions: [] },
      state
    );

    it('should display slider with skeleton', () => {
      const slider = screen.getByTestId('suggestion-slider');
      const skeleton = screen.getAllByTestId('skeleton-placeholder');

      expect(slider).toBeTruthy();
      expect(skeleton).toBeTruthy();
    });
  });

  describe('when suggestions are available', () => {
    renderComponent(SuggestionContainer, { ...props, isLoading: false, menus, suggestions }, state);

    it('should display slider with tiles', () => {
      const slider = screen.getByTestId('suggestion-slider');
      const suggestion1 = screen.getAllByTestId(
        `ProductTile-${suggestions[0].menuId}_${suggestions[0].menuItemId}`
      );
      const suggestion2 = screen.getAllByTestId(
        `ProductTile-${suggestions[1].menuId}_${suggestions[1].menuItemId}`
      );

      expect(slider).toBeTruthy();
      expect(suggestion1).toBeTruthy();
      expect(suggestion2).toBeTruthy();
    });
  });

  describe('when suggestions are not available', () => {
    renderComponent(
      SuggestionContainer,
      { ...props, isLoading: false, menus, suggestions: [] },
      state
    );

    it('should display slider with placeholder', () => {
      const slider = screen.getByTestId('suggestion-slider');
      const placeholder = screen.getByTestId('widget-placeholder');

      expect(slider).toBeTruthy();
      expect(placeholder).toBeTruthy();
    });
  });
});
