const labels = {
  All: {
    'Ref: stamp progress': 'Прогресс в сторону следующей марки',
    Reward: 'Награда',
    Rewards: 'Награды',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Марки',
    'Ref: Fulfill conditions': 'Заработайте марки, чтобы выкупить награды!',
    'See Reward': 'Смотрите награду',
    'Ref: No reward': 'Нет награды на данный момент',
  },
  RedeemableProducts: { 'Ref: Page title': 'Награды лояльности' },
};
export default labels;
