import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useCallback, useState } from 'react';

import { appInsights } from '../../../../appInsights';
import { useLazyGetPaymentMinimumAmountInfoQuery } from '../../api';
import { getMerchantPspCode } from '../../helpers/payment.helper';

import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import { SERVICE } from '@/modules/config';
import useSite from '@/modules/Core/hooks/useSite';

export const useCanPayOnline = () => {
  const [isMinimumAmountError, setIsMinimumAmountError] = useState(false);
  const [minimumAmount, setMinimumAmount] = useState<number>();
  const [getPaymentMinimumAmountInfo, { isLoading }] = useLazyGetPaymentMinimumAmountInfoQuery();
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const merchantSetupOption = useSetupOption('merchant', SERVICE.RETAIL).value;

  const checkIfOnlinePaymentPossible = async ({
    orderTotal,
  }: {
    orderTotal: number;
  }): Promise<boolean> => {
    setIsMinimumAmountError(false);
    setMinimumAmount(undefined);

    if (process.env.REACT_APP_USE_CHECK_PAYMENT_MIN_AMOUNT !== 'true') return true;

    if (!merchantSetupOption) {
      appInsights?.trackTrace({
        message: 'MerchantSetupOption is unknown',
        severityLevel: SeverityLevel.Error,
      });

      return true;
    }

    if (!site.currency?.isoCode) {
      appInsights?.trackTrace({
        message: 'Site currency iso code is unknown',
        severityLevel: SeverityLevel.Error,
      });

      return true;
    }

    const pspCode = getMerchantPspCode(merchantSetupOption);
    if (!pspCode) {
      appInsights?.trackTrace({
        message: 'MerchantSetupOption is invalid',
        severityLevel: SeverityLevel.Error,
      });

      return true;
    }

    const minimumPaymentAmountInfo = await getPaymentMinimumAmountInfo({
      pspCode,
      currency: site.currency?.isoCode,
    });

    const minimumPaymentAmount = minimumPaymentAmountInfo.data?.minimumAmount;
    setMinimumAmount(minimumPaymentAmount);

    if (minimumPaymentAmount && minimumPaymentAmount > orderTotal) {
      setIsMinimumAmountError(true);
      return false;
    }

    return true;
  };

  const clearMinimumAmountError = useCallback(() => {
    setIsMinimumAmountError(false);
    setMinimumAmount(undefined);
  }, []);

  return {
    checkIfOnlinePaymentPossible,
    isLoading,
    isMinimumAmountError,
    minimumAmount,
    clearMinimumAmountError,
  };
};
