const labels = {
  All: {
    'Ref: Assembled': 'Zmontowane',
    'Ref: Submitted': 'Złożone',
    'Ref: Fulfilled': 'Przygotowane',
    'Ref: Completed': 'Zrealizowane',
    'Ref: Paid': 'Opłacone',
    'Ref: Approved': 'Zatwierdzone',
    'Ref: Ready For Pickup': 'Gotowe do odbioru',
    'Ref: Delivered': 'Dostarczone',
    'Ref: Received': 'Otrzymane',
    'Ref: Rejected': 'Odrzucone',
    'Ref: Payment Error': 'Błąd płatności',
    'Ref: Pending Payment': 'Oczekująca płatność',
    'Ref: Preparing': 'W przygotowaniu',
    'Ref: Refunded': 'Zwrócone',
    'Ref: Cancelled By User': 'Anulowane przez użytkownika',
    'Ref: Refund Requested': 'Zawnioskowano o zwrot',
    'Ref: Refund Failed': 'Zwrot nie powiódł się',
    'Ref: Payment Processing': 'Przetwarzanie płatności',
    'Ref: Not Paid': 'Nieopłacone',
    'Ref: Total:': 'Suma:',
    'Ref: Pickup': 'Na wynos',
    'Ref: Eat-in': 'Na miejscu',
    'Ref: Scan&Go': 'Zeskanuj i kup',
    'Ref: Fulfillment Type': 'Sposób odbioru',
    'Ref: Delivery': 'Dostawa',
    'Ref: You have no future orders': 'Nie masz przyszłych zamówień',
    'Ref: You have no past orders': 'Nie masz wcześniejszych zamówień',
    'Ref: You have no orders': 'Nie masz zamówień',
    'Ref: All orders': 'Wszystkie zamówienia',
    'Ref: Not past orders': 'Nadchodzące zamówienia',
    'Ref: Past orders': 'Historia zamówień',
    'Ref: Vegan': 'Wegańskie',
    'Ref: Vegeterian': 'Wegetariańskie',
    'Ref: See item': 'Szczegóły',
    'Ref: Activated': 'Aktywowany',
    'Ref: No suggestions': 'Nie znaleziono sugestii',
    'Ref: Get your receipt': 'Otrzymaj paragon',
    'Ref: Print': 'Wydrukuj paragon',
    'Ref: Email': 'Otrzymaj paragon na e-mail',
    'Ref: Receipt Modal message': 'Jak chciałbyś otrzymać swój paragon?',
    'Ref: Confirm': 'Potwierdź',
    'Ref: Your email address': 'Twój adres email',
    Suggestion: 'Sugerowane',
    'Ref: Loyalty Reward': 'Nagroda lojalnościowa',
    'Ref: Equation for stamps per item': '1 zakup = 1 pieczątka',
    'Ref: Paid by employer': 'Kwota opłacona przez Twojego pracodawcę',
    'Ref: Paid by user': 'Kwota opłacona przez Ciebie',
  },
  ProductsList: {
    'Ref: Page title': 'Lista produktów',
    'Ref: Facility details': 'Szczegóły dotyczące obiektu',
    'Ref: Search products list': 'Wyszukaj produkty',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategoria',
    'Ref: all': 'Wszystkie',
    'Ref: today': 'Dziś',
    'Ref: tomorrow': 'Jutro',
    'Ref: see more': 'Zobacz więcej',
    'Ref: see less': 'Zobacz mniej',
    'Ref: reset': 'Zresetuj',
    'Ref: View in cart': 'Zobacz w koszyku',
    'Ref: You have changed location': 'Zmieniłeś lokalizację',
    'Ref: You are now at': 'Jesteś teraz w',
    'Ref: Menu card': 'Menu',
    'Ref: Table': 'Numer stolika',
    'Service unavailable': 'Usługa niedostępna',
    'Ref: Ordering unavailable':
      'Uwaga! Zamówienie w tej lokalizacji jest w tej chwili niedostępne, spróbuj później.',
    'Ref: Filters': 'Filtry (alergeny i więcej)',
  },
  ProductScanner: {
    'Ref: cart': 'Koszyk',
    'Ref: Product Scanned':
      'został dodany do koszyka. Możesz nadal skanować produkty, aby dodać je do koszyka.',
    'Ref: Scan or adjust': 'został dodany do koszyka. Dostosuj ilość lub zeskanuj inny produkt.',
    'Ref: Product Not Matched': 'Kod nie został rozpoznany. Spróbuj później',
    'Ref: Scan article code': 'Zeskanuj kod',
    'Ref: Native error product cannot be added to cart':
      'Pozycja z różnego menu lub momentu nie może zostać dodana do koszyka',
    'Ref: product scanner placeholder': 'Zeskanuj kod, aby dodać do koszyka',
  },
  Order: { 'Ref: Page title': 'Zamów' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menu',
    'Ref: today': 'Dziś',
    'Ref: tomorrow': 'Jutro',
    'Ref: Search products list': 'Wyszukaj menu po nazwie',
    'Ref: You have changed location': 'Zmieniłeś lokalizację',
    'Ref: You are now at': 'Jesteś teraz w',
  },
  ProductDetails: {
    'Ref: Page title': 'Szczegóły Produktu',
    'Ref: Serving Size': 'Wielkość porcji',
    'Ref: Nutrition': 'Informacje żywieniowe',
    'Ref: Nutrition adults': 'Dorośli potrzebują około 2000 kcal dziennie',
    'Ref: Nutrition Calories': 'Kalorie',
    'Ref: Nutrition Calcium': 'Wapń',
    'Ref: Nutrition Fat': 'Tłuszcze',
    'Ref: Nutrition Saturated Fat': 'Tłuszcz nasycony',
    'Ref: Nutrition Carbohydrates': 'Węglowodany',
    'Ref: Nutrition Sugar': 'Cukier',
    'Ref: Nutrition Protein': 'Białko',
    'Ref: Nutrition Sodium': 'Sód',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Błonnik pokarmowy',
    'Ref: Nutrition iron': 'Żelazo',
    'Ref: Nutrition potassium': 'Potas',
    'Ref: Nutrition vitaminA': 'Witamina A.',
    'Ref: Nutrition vitaminC': 'Witamina C',
    'Ref: Additives': 'Dodatki',
    'Ref: Emission': 'Emisje CO2.',
    'Ref: This product generates': 'Ten produkt generuje',
    'Ref: of CO2': 'CO2.',
    'Ref: emissions, which equals a': 'emisje, które są równe',
    'Ref: car trip': 'podróży autem',
    'Ref: Ingredients': 'Składnik przepisu',
    'Ref: Allergens': 'Alergeny',
    'Ref: Always there': 'Zawsze występują',
    'Ref: Allergens selected': 'Alergeny wybrane na filtrze:',
    'Ref: minimum required': 'wymagane minimum',
    'Ref: you have to add at least': 'Musisz przynajmniej dodać',
    'Ref: ingredients': 'Składniki',
    'Ref: Ingredient': 'składnik',
    'Ref: Free': 'Darmowy',
    'Ref: Based on customization': 'Na podstawie Twoich preferencji',
    'Ref: Additives eggs': 'jajka',
    'Ref: Additives fish': 'ryba',
    'Ref: Additives milk': 'mleko',
    'Ref: Additives nuts': 'orzechy',
    'Ref: Additives wheat': 'pszenica',
    'Ref: Additives peanuts': 'orzeszki ziemne',
    'Ref: Additives soy beans': 'fasola sojowa',
    'Ref: Additives sesame seeds': 'ziarenka sezamu',
    'Ref: Review': 'Recenzja',
    'Ref: Per portion': 'Na porcję',
    'Ref: Per 100g': 'Na 100 g',
    'Ref: Allergen selected': 'Alergen wybrany w filtrach wyszukiwania',
    'Ref: Fitbit Log': 'Zaloguj się do Fitbit',
    'Ref: age verification required on delivery': 'Weryfikacja wieku wymagana podczas dostawy',
    'Ref: alcohol content notification':
      'Zamawiając jeden z tych produktów, oświadczasz, że masz 18 lat lub więcej. Nadużywanie alkoholu jest niebezpieczne dla Twojego zdrowia.',
    'Ref: default': 'Domyślnie',
    'Ref: Loyalty Card Title Stamp':
      'Uzyskasz {no_stamps} {stamp_form}, jeśli zamówisz ten produkt',
    'Ref: Loyalty Card Description':
      '{stamp_form} zostanie dodane do programu stałego klienta {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program znaczków lojalnościowych pozwala zbierać pieczątki i wymieniać je na nagrody.',
    'Ref: Loyalty Modal Description':
      'Kiedy kupisz <b> {product_name}, 1 pieczątka </b> zostanie dodany do <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'i',
    'Ref: Stamp': 'Pieczątka',
    'Ref: Stamps': 'Pieczątki',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programy',
    'Ref: Loyalty Stamps': 'Pieczątki lojalnościowe',
    'Ref: See Loyalty Stamps': 'Zobacz pieczątki lojalnościowe',
    Favorite: 'Ulubione',
    'Ref: In favorites': 'Produkt jest już na liście ulubionych. Kliknij, aby go usunąć.',
    'Ref: Not in favorites':
      'Produkt nie jest jeszcze na liście ulubionych. Kliknij, aby go dodać.',
    'Ref: Added to favorites': 'Produkt został dodany do listy ulubionych.',
    'Ref: Removed from favorites': 'Produkt został usunięty z listy ulubionych.',
    'Ref: From': 'Od',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Zmień w koszyku',
    'Ref: Popup header for customizable item qty update': 'Ten produkt jest konfigurowalny',
    'Ref: add new version': 'Dodaj nową wersję',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Zaktualizuj ilość konfigurowalnego produktu bezpośrednio z koszyka.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Zaktualizuj ilość konfigurowalnego produktu bezpośrednio z koszyka lub dodaj nową wersję.',
    'Ref: another context body':
      'Aby dodać produkty z tej lokalizacji, obiektu, momentu/menu lub daty, musisz wyczyścić koszyk',
    'Ref: cancel': 'Anuluj',
    'Ref: add to cart': 'Dodaj do koszyka',
    'Ref: view in cart': 'Koszyk',
    'Ref: Products in cart': 'Liczba {Product_name} w koszyku: {Product_quantity}',
    'Ref: Customize': 'Wybierz opcje',
    'Ref: Add': 'Dodaj',
    'Ref: Free': 'Bezpłatny',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Anuluj',
    'Ref: notice': 'Ogłoszenie',
    'Ref: clear cart': 'Wyczyść koszyk',
    'Ref: Cart addition error message':
      'Obecnie masz produkty w koszyku z {from_current}. Aby dodać produkty z {from_selected}, musisz wyczyścić koszyk',
  },
  Cart: {
    'Ref: Page title': 'Koszyk',
    'Ref: The cart is empty': 'Koszyk jest pusty',
    'Ref: Clear cart': 'Opróżnij koszyk',
    'Ref: Continue shopping': 'Kontynuuj zakupy',
    'Ref: Start shopping': 'Zacznij robić zakupy',
    'Ref: Today': 'Dziś',
    'Ref: Tomorrow': 'Jutro',
    'Ref: Tax:': 'Podatek:',
    'Ref: Promotion:': 'Promocja:',
    'Ref: Total without promotion:': 'Razem bez promocji:',
    'Ref: Delivery:': 'Dostawa:',
    'Ref: Pickup informations': 'Informacje o odbiorze',
    'Ref: Location': 'Punkt odbioru',
    'Ref: Time': 'Czas',
    'Ref: Phone': 'Twój numer telefonu',
    'Ref: Instructions': 'Instrukcje',
    'Ref: Please select': 'Proszę wybrać',
    'Ref: Enter special requests': 'Wprowadź tutaj specjalne życzenia',
    'Ref: notice': 'Ogłoszenie',
    'Ref: Product is no more available':
      'Przepraszam, ale wydaje się, że niektóre produkt nie są już dostępne. Sprawdź swój koszyk.',
    'Ref: ok': 'Ok',
    'Ref: Validate': 'Sprawdź moje zamówienie',
    'Ref: Pay now': 'Zapłać teraz',
    'Ref: Charge to my department': 'Opłata do mojego działu',
    'Ref: Pay on pickup': 'Zapłać przy odbiorze zamówienia',
    'Ref: Accept terms of sale prefix': 'Akceptuję',
    'Ref: Accept terms of sale': 'niniejsze warunki sprzedaży',
    'Ref: Continue': 'Kontynuuj',
    'Ref: Please login or sign up to place an order':
      'Zaloguj się lub zarejestruj się, aby złożyć zamówienie',
    'Ref: Accept terms of sale suffix': ', przeczytałem i zrozumiałem.',
    'Ref: Payment modality': 'Metoda płatności',
    'Ref: Other information': 'Inne informacje',
    'Ref: Eat-in': 'Na miejscu',
    'Ref: Pickup': 'Na wynos',
    'Ref: Table': 'Numer stolika',
    'Ref: Required fields': 'Wymagane pola',
    'Service unavailable': 'Serwis niedostępny',
    'Ref: Cannot order':
      'Przepraszamy, w tej chwili nie można złożyć zamówienia w tym miejscu. Aby uzyskać więcej informacji na temat godzin otwarcia i dostępnych usług, prosimy o kontakt z restauracją.',
    'Ref: Order Unavailable':
      'Zamówienia na wybraną datę, sposób odbioru i lokalizację nie są już dostępne!',
    'Ref: No Availability': 'Niedostępne',
    'Ref: Continue on your device': 'Kontynuuj na swoim urządzeniu',
    'Ref: Loyalty Card Title Stamp': 'Otrzymasz {no_stamps} {stamp_form}, jeśli złożysz zamówienie',
    'Ref: Loyalty Card Description':
      '{stamp_form} zostanie dodane do następującego programu lojalnościowego {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program lojalnościowy pozwala zbierać pieczątki i wymieniać je na nagrody.',
    'Ref: Loyalty Modal List Description':
      'Otrzymasz <b> {no_stamps} {stamp_form} </b>, jeśli wypełnisz zamówienie:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> dodane dla <b> {scheme_names} </b>',
    'Ref: Stamp': 'Pieczątka',
    'Ref: Stamps': 'Pieczątki',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programy',
    'Ref: Loyalty Stamps': 'Pieczątki programu lojalnościowego',
    'Ref: See Loyalty Stamps': 'Zobacz pieczątki lojalnościowe',
    'Ref: Loyalty Info': 'Informacje o programie lojalnościowym',
    'Ref: Email address': 'Adres email',
    'Ref: and': 'i',
    'Ref: Order minimum amount': 'Przepraszam, minimalna kwota płatności to {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Kontynuuj na swoim urządzeniu',
    'Ref: Scan QR to continue': 'Proszę zeskanuj kod QR, aby kontynuować podróż na urządzeniu.',
  },
  OrderHistory: { 'Ref: Orders': 'Zamówienia', 'Ref: Page Title': 'Moje zamówienia' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Cena jednostkowa',
    'Ref: Download': 'Pobierz paragon',
    'Ref: Download error': 'Wystąpił błąd pobierania. Spróbuj pobrać paragon później',
    'Ref: Order details title': 'Szczegóły zamówienia',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Dziękujemy! Twoje zamówienie zostało potwierdzone',
    'Ref: Text for pickup':
      'Status można śledzić poprzez historię zamówień. Jeśli masz włączone powiadomienia, poinformujemy Cię, gdy będzie gotowe do odbioru.',
    'Ref: Success Order Title': 'Dziękuję za Twoje zamówienie',
    'Ref: Success Order Description': 'Zapłać przy kasie, aby potwierdzić zamówienie.',
    'Ref: Success Order ID': 'Identyfikator zamówienia',
    'Ref: Success Order Action': 'Nowe zamówienie',
    'Ref: Text for dinein':
      'Twoje zamówienie powinno zostać wkrótce podane do Twojego stolika. Zapytaj kogoś z naszego personelu o zamówiony posiłek.',
    'Ref: Text for scan&go': 'Twoje zamówienie jest dostępne w historii zamówienia',
    'Ref: Text for delivery':
      'Możesz śledzić status w historii zamówienia. Jeśli twoje powiadomienia są włączone, poinformujemy Cię, kiedy zamówienie będzie gotowe.',
    'See details': 'Zobacz szczegóły',
    'Go to home': 'Wróć do strony głównej',
  },
  FailOrder: {
    'Go to home': 'Wróć do strony głównej',
    'Ref: Kiosk Fail Title': 'Ups! Coś poszło nie tak!',
    'Ref: Kiosk Fail Description':
      'Niestety, Twoje zamówienie się nie powiodło. Spróbuj ponownie lub poproś pracownika o pomoc.',
    'Ref: Kiosk Fail Back': 'Powrót do poprzedniej strony',
    'Ref: Kiosk Fail Action': 'Wyczyść sesję i zamów ponownie',
    'Ref: Error in cart validation':
      'Wystapił błąd podczas zamówienia. Skontaktuj się z obiektem, aby rozwiązać problem.',
    'Facility contact': 'Kontakt z obiektem',
    'Ref: Ordering failed': 'Zamówienie się nie powiodło',
    'Ref: Order confirmed': 'Zamówienie potwierdzone',
  },
  TableCheckIn: {
    'Welcome at': 'Witaj w',
    Table: 'Stolik:',
    'Ref: list title': 'Najnowsze zamówienia na dziś',
    'See the menu': 'Zobacz menu',
    'Table Check-in': 'Check-in przy stole',
    'Ref #': 'Ref #',
    Checked: 'Sprawdzone',
    'Check in': 'Check-in',
    'See details': 'Zobacz szczegóły',
    'Ref: You have changed location': 'Zmieniłeś lokalizację',
    'Ref: You are now at': 'Jesteś teraz w',
    'Order number': 'Numer zamówienia',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promocja aktywowana',
    'Ref: Promotion applied description': 'Korzystasz z rabatu z koszyka.',
    'Ref: Promotion deleted title': 'Usunięto promocję',
    'Ref: Promotion deleted description':
      'Nie korzystasz już z promocji, ponieważ nie wypełniasz już warunków lub promocja wygasła.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Zeskanuj i kup', 'Ref: View only': 'Tylko podgląd' },
  ProductTile: {
    'Ref: Allergens': 'Alergeny',
    'Ref: Presence of allergen': 'Obecność alergenu',
    'Ref: Scan': 'Skanowanie',
    'Ref: From': 'Od',
    'Ref: Already in product favorite list. Click to remove it.':
      'Produkt jest na liście ulubionych. Kliknij, aby go usunąć.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Obecnie produkt nie jest dodany do listy ulubionych. Kliknij, aby go dodać.',
    'Ref: Free': 'Bezpłatny',
  },
  OrderSummary: {
    'Ref: item': 'produkt',
    'Ref: items': 'produktów',
    'Ref: Clear': 'Wyczyść',
    'Ref: Cart': 'Koszyk',
    'Ref: Subtotal': 'Suma częściowa',
    'Ref: Total': 'Suma',
    'Ref: Tax': 'Podatek',
    'Ref: Promotion': 'Promocja',
    'Ref: Total without promotion': 'Łącznie bez promocji',
    'Ref: Number of product in cart': 'Liczba produktów w koszyku',
    'Ref: Delivery': 'Dostawa',
  },
  CartItemTile: { 'Ref: Vegan': 'Wegańskie', 'Ref: Vegeterian': 'Wegetariańskie' },
  OrderInfoCard: {
    'Ref: Header': 'Zamówienie na',
    'Ref: from': 'z',
    'Ref: location': 'Punkt odbioru',
    'Ref: Phone': 'Twój numer telefonu',
    'Ref: Instructions': 'Instrukcje',
    'Ref: Order type': 'Sposób odbioru',
    'Ref: Pickup': 'Odbiór osobisty',
    'Ref: Eat-in': 'Na miejscu',
    'Ref: Scan&Go': 'Zeskanuj i kup',
  },
  ReorderWidget: {
    'Ref: No items': 'Brak zrealizowanych zamówień. Kup teraz!',
    'Ref: Title': 'Zamów ponownie',
  },
  MenusWidget: {
    'Ref: no menu': 'Brak dostępnych menu dla tego momentu',
    'Ref: Modal title': 'Menu na dzień',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Dostępne zniżki' },
  PromotionInfoItem: { 'Ref: Activate': 'Aktywuj' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Twoje zamówienia',
    'Ref: Widget Title': 'Moje zamówienia',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Sugestia na ten dzień',
    'Ref: Any menu': 'Dowolne menu',
    Other: 'Inne',
    'Ref: Suggestions calendar button': 'Kalendarz sugestii',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Wielkość porcji',
    'Ref: Nutrition': 'Informacje żywieniowe.',
    'Ref: Nutrition adults': 'Dorośli potrzebują około 2000 kcal dziennie',
    'Ref: Nutrition Calories': 'Kalorie',
    'Ref: Nutrition Calcium': 'Wapń',
    'Ref: Nutrition Fat': 'Tłuszcze',
    'Ref: Nutrition Saturated Fat': 'Tłuszcz nasycony',
    'Ref: Nutrition Carbohydrates': 'Węglowodany',
    'Ref: Nutrition Sugar': 'Cukier',
    'Ref: Nutrition Protein': 'Białko',
    'Ref: Nutrition Sodium': 'Sód',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Błonnik pokarmowy',
    'Ref: Nutrition iron': 'Żelazo',
    'Ref: Nutrition potassium': 'Potas',
    'Ref: Nutrition vitaminA': 'Witamina A.',
    'Ref: Nutrition vitaminC': 'Witamina C',
    'Ref: Ingredients': 'Składnik przepisu',
    'Ref: Allergens': 'Alergeny',
    'Ref: Always there': 'Zawsze występuje',
    'Ref: Allergens selected': 'Alergeny wybrane na filtrze:',
    'Ref: minimum required': 'wymagane minimum',
    'Ref: ingredients': 'Składniki',
    'Ref: Ingredient': 'składnik',
    'Ref: Free': 'Darmowy',
    'Ref: Based on customization': 'Na podstawie Twoich preferencji',
    'Ref: Additives eggs': 'jajka',
    'Ref: Additives fish': 'ryba',
    'Ref: Additives milk': 'mleko',
    'Ref: Additives nuts': 'orzechy',
    'Ref: Additives wheat': 'pszenica',
    'Ref: Additives peanuts': 'orzeszki ziemne',
    'Ref: Additives soy beans': 'fasola sojowa',
    'Ref: Additives sesame seeds': 'ziarenka sezamu',
    'Ref: Review': 'Recenzja',
    'Ref: Per portion': 'Na porcję',
    'Ref: Per 100g': 'Na 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} za darmo',
    'Ref: Number of free items': 'Liczba darmowych produktów: {number_of_free_items}',
    'Ref: Allergen selected': 'Alergen wybrany w filtrach wyszukiwania',
    'Ref: Nutritional info': 'Informacje o wartości odżywczej',
    'Ref: Info': 'Informacje',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Promocje' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Wybierz swoją rolę',
    'Ref: Validate': 'Potwierdź',
    'Ref: Pricing Tiers dialog message':
      'Twoje konto zostało rozpoznane za pomocą swojego adresu e-mail',
    'Ref: Please select your job in the company': 'Wybierz swoją rolę w firmie',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Odbiór osobisty',
    'Ref: Eat-in': 'Na miejscu',
    'Ref: Scan&Go': 'Zeskanuj i kup',
    'Ref: Delivery': 'Dostawa',
    'Ref: Total': 'Suma',
    'Ref: How was facility name service': 'Jaka była usługa {facility_name}?',
    'Ref: How do you rate the service': 'Jak oceniasz usługę?',
  },
};
export default labels;
