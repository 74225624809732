import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { pagePaths } from '../config';

interface MatchParams {
  id: string;
  callbackType: string;
}

type CheckoutProps = {
  cleanCart: () => void;
};

const Checkout = ({ cleanCart }: CheckoutProps) => {
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();

  const { callbackType } = match.params;
  const isSuccessCheckout = ['success', 'complete'].includes(callbackType.toLowerCase());

  useEffect(() => {
    if (isSuccessCheckout) {
      cleanCart();
      history.replace(pagePaths.OrderSuccess);
    } else {
      history.replace(pagePaths.OrderFail);
    }
  }, [cleanCart, history, isSuccessCheckout]);

  return <LoadingPage />;
};

export default Checkout;
