const labels = {
  All: {
    'Ref: Assembled': '組み立てられました',
    'Ref: Submitted': '提出',
    'Ref: Fulfilled': '満たされた',
    'Ref: Completed': '完了しました',
    'Ref: Paid': '有料',
    'Ref: Approved': '承認済み',
    'Ref: Ready For Pickup': 'ピックアップの準備ができて',
    'Ref: Delivered': '配達',
    'Ref: Received': '受け取った',
    'Ref: Rejected': '拒否',
    'Ref: Payment Error': '支払いエラー',
    'Ref: Pending Payment': '支払い待ち',
    'Ref: Preparing': '準備',
    'Ref: Refunded': '返金',
    'Ref: Cancelled By User': 'ユーザーによってキャンセルされました',
    'Ref: Refund Requested': '払い戻しが要求されました',
    'Ref: Refund Failed': '払い戻しは失敗しました',
    'Ref: Payment Processing': '支払い手続き',
    'Ref: Not Paid': '支払われていません',
    'Ref: Total:': '合計：',
    'Ref: Pickup': '選び出す',
    'Ref: Eat-in': 'イートイン',
    'Ref: Scan&Go': 'スキャン＆ゴー',
    'Ref: Fulfillment Type': 'フルフィルメントタイプ',
    'Ref: Delivery': '配達',
    'Ref: You have no future orders': '予定された注文はありません',
    'Ref: You have no past orders': '過去の注文はありません',
    'Ref: You have no orders': '注文はありません',
    'Ref: All orders': 'すべての注文',
    'Ref: Not past orders': '今後予定の注文',
    'Ref: Past orders': '過去の注文',
    'Ref: Vegan': 'ヴィーガン',
    'Ref: Vegeterian': 'ベジタリアン',
    'Ref: See item': 'アイテムを参照してください',
    'Ref: Activated': '活性化',
    'Ref: No suggestions': '提案は見つかりませんでした',
    'Ref: Get your receipt': '領収書を入手してください',
    'Ref: Print': '領収書を印刷します',
    'Ref: Email': '電子メールで領収書を受け取ります',
    'Ref: Receipt Modal message': '領収書をどのように入手しますか？',
    'Ref: Confirm': '確認',
    'Ref: Your email address': 'あなたのメールアドレス',
    Suggestion: '提案',
    'Ref: Loyalty Reward': 'ロイヤルティ報酬',
    'Ref: Equation for stamps per item': '1購入= 1スタンプ',
    'Ref: Paid by employer': '雇用主が支払った金額',
    'Ref: Paid by user': 'あなたが支払った金額',
  },
  ProductsList: {
    'Ref: Page title': '製品リスト',
    'Ref: Facility details': '施設の詳細',
    'Ref: Search products list': 'アイテムを検索',
    'Ref: filter label: menus': 'メニュー',
    'Ref: filter label: moment': '時刻',
    'Ref: filter label: category': 'カテゴリー',
    'Ref: all': '全て',
    'Ref: today': '今日',
    'Ref: tomorrow': '明日',
    'Ref: see more': '更に表示',
    'Ref: see less': '表示しない',
    'Ref: reset': 'リセットする',
    'Ref: View in cart': 'カートを見る',
    'Ref: You have changed location': '場所を変更しました',
    'Ref: You are now at': '現在ここにいます',
    'Ref: Menu card': 'メニューカード',
    'Ref: Table': 'テーブル番号',
    'Service unavailable': 'サービスは利用できません',
    'Ref: Ordering unavailable': '現在利用できません。時間を空けてお試しください。',
    'Ref: Filters': 'フィルター（アレルゲン以上）',
  },
  ProductScanner: {
    'Ref: cart': 'カート',
    'Ref: Product Scanned':
      'カートに追加されました。バーコードをスキャンしてカートに追加できます。',
    'Ref: Scan or adjust': 'カートに追加されました。数量を調整するか、別の商品をスキャンします。',
    'Ref: Product Not Matched': 'バーコードは認識されませんでした。もう一度やり直してください',
    'Ref: Scan article code': 'バーコードをスキャンします',
    'Ref: Native error product cannot be added to cart':
      'さまざまなメニューや日の部品からのアイテムをカートに追加できません',
    'Ref: product scanner placeholder': 'バーコードをスキャンしてカートに追加してください',
  },
  Order: { 'Ref: Page title': '注文' },
  MenuSelector: {
    'Ref: Menu Page title': 'メニュー',
    'Ref: today': '今日',
    'Ref: tomorrow': '明日',
    'Ref: Search products list': '商品を検索',
    'Ref: You have changed location': '場所を変更しました',
    'Ref: You are now at': '現在ここにいます',
  },
  ProductDetails: {
    'Ref: Page title': '製品詳細',
    'Ref: Serving Size': 'サービングのサイズ',
    'Ref: Nutrition': '栄養成分表示',
    'Ref: Nutrition adults': '大人は1日約2000kcal必要です。',
    'Ref: Nutrition Calories': 'カロリー',
    'Ref: Nutrition Calcium': 'カルシウム',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '飽和脂肪酸',
    'Ref: Nutrition Carbohydrates': '炭水化物',
    'Ref: Nutrition Sugar': '糖質',
    'Ref: Nutrition Protein': 'タンパク質',
    'Ref: Nutrition Sodium': 'ナトリウム',
    'Ref: Nutrition cholesterol': 'コレステロール',
    'Ref: Nutrition dietaryFiber': '食物繊維',
    'Ref: Nutrition iron': '鉄',
    'Ref: Nutrition potassium': 'カリウム',
    'Ref: Nutrition vitaminA': 'ビタミンA',
    'Ref: Nutrition vitaminC': 'ビタミンC',
    'Ref: Additives': '添加物',
    'Ref: Emission': 'CO2排出量',
    'Ref: This product generates': 'この商品はCO2を生成します',
    'Ref: of CO2': 'CO2の',
    'Ref: emissions, which equals a': 'Aに等しい排出量',
    'Ref: car trip': '車に換算',
    'Ref: Ingredients': 'レシピ成分',
    'Ref: Allergens': 'アレルギー',
    'Ref: Always there': '常にそこに',
    'Ref: Allergens selected': 'フィルター上で選択されたアレルゲン：',
    'Ref: minimum required': '最低限必要です',
    'Ref: you have to add at least': '追加する必要があります',
    'Ref: ingredients': '材料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '無料',
    'Ref: Based on customization': 'カスタマイズに基づいています',
    'Ref: Additives eggs': '卵',
    'Ref: Additives fish': '魚',
    'Ref: Additives milk': 'ミルク',
    'Ref: Additives nuts': 'ナッツ',
    'Ref: Additives wheat': '小麦',
    'Ref: Additives peanuts': 'ピーナッツ',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': 'ゴマ',
    'Ref: Review': 'レビュー',
    'Ref: Per portion': '部分ごと',
    'Ref: Per 100g': '100gあたり',
    'Ref: Allergen selected': '検索フィルターで選択されたアレルゲン',
    'Ref: Fitbit Log': 'Fitbitにログを記録します',
    'Ref: age verification required on delivery': '配達時に必要な年齢検証',
    'Ref: alcohol content notification':
      'これらのアイテムのいずれかを配置して注文することにより、18歳以上であることを宣言します。アルコール乱用はあなたの健康にとって危険です。',
    'Ref: default': 'デフォルト',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}この製品を注文すると獲得しました',
    'Ref: Loyalty Card Description':
      '{stamp_form}は、次のロイヤルティに追加されます{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Loyalty Stampsプログラムを使用すると、報酬のためにスタンプを収集できます。',
    'Ref: Loyalty Modal Description':
      '<b> {product_name}を購入すると、1つのスタンプ</b>が<b> {scheme_names} </b> {program_form}に追加されます。',
    'Ref: and': 'と',
    'Ref: Stamp': 'スタンプ',
    'Ref: Stamps': 'スタンプ',
    'Ref: Program': 'プログラム',
    'Ref: Programs': 'プログラム',
    'Ref: Loyalty Stamps': 'ロイヤルティスタンプ',
    'Ref: See Loyalty Stamps': 'ロイヤルティスタンプを参照してください',
    Favorite: 'お気に入り',
    'Ref: In favorites': '製品はすでにお気に入りのリストにあります。クリックして削除します。',
    'Ref: Not in favorites': '製品はまだお気に入りのリストにありません。クリックして追加します。',
    'Ref: Added to favorites': '製品はお気に入りリストに追加されています。',
    'Ref: Removed from favorites': '製品はお気に入りのリストから削除されました。',
    'Ref: From': 'から',
  },
  MenuCartActions: {
    'Ref: change in cart': 'カートの変更',
    'Ref: Popup header for customizable item qty update': 'このアイテムはカスタマイズ可能です',
    'Ref: add new version': '新しいバージョンを追加します',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'カートからアイテムの量を更新します。',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'カートからアイテムの数量を直接更新するか、新しいバージョンを追加します。',
    'Ref: another context body':
      'このサイト、施設、モーメント/メニュー、または日付からアイテムを追加するには、カートをクリアする必要があります',
    'Ref: cancel': 'キャンセル',
    'Ref: add to cart': 'カートに追加',
    'Ref: view in cart': 'カート',
    'Ref: Products in cart': 'カート内の{product_name}の数：{product_quantity}',
    'Ref: Customize': 'カスタマイズ',
    'Ref: Add': '追加',
    'Ref: Free': '無料',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'キャンセル',
    'Ref: notice': 'お知らせ',
    'Ref: clear cart': 'カートのクリア',
    'Ref: Cart addition error message':
      '現在、{from_current}からカートにアイテムがあります。 {from_selected}からアイテムを追加するには、カートをクリアする必要があります',
  },
  Cart: {
    'Ref: Page title': 'カート',
    'Ref: The cart is empty': 'カートは空です',
    'Ref: Clear cart': 'カートのクリア',
    'Ref: Continue shopping': '買い物を続ける',
    'Ref: Start shopping': '買い物を始めます',
    'Ref: Today': '今日',
    'Ref: Tomorrow': '明日',
    'Ref: Tax:': '税：',
    'Ref: Promotion:': 'プロモーション：',
    'Ref: Total without promotion:': 'プロモーションなしの合計：',
    'Ref: Delivery:': '配達：',
    'Ref: Pickup informations': 'ピックアップ情報',
    'Ref: Location': '位置',
    'Ref: Time': '時間',
    'Ref: Phone': '電話番号',
    'Ref: Instructions': '指示',
    'Ref: Please select': '選んでください',
    'Ref: Enter special requests': 'ここに特別なリクエストを入力してください',
    'Ref: notice': 'お知らせ',
    'Ref: Product is no more available': '申し訳ございません。一部の商品は利用できません。',
    'Ref: ok': 'Ok',
    'Ref: Validate': '注文を検証します',
    'Ref: Pay now': '支払う',
    'Ref: Charge to my department': '私の部門に請求します',
    'Ref: Pay on pickup': 'ピックアップでこれらのアイテムの支払いを行います',
    'Ref: Accept terms of sale prefix': '承諾します',
    'Ref: Accept terms of sale': '販売条件',
    'Ref: Continue': '継続する',
    'Ref: Please login or sign up to place an order':
      'ログインまたはサインアップして注文してください',
    'Ref: Accept terms of sale suffix': '内容を理解しました。',
    'Ref: Payment modality': '支払方法',
    'Ref: Other information': 'その他の情報',
    'Ref: Eat-in': 'イートイン',
    'Ref: Pickup': 'ピックアップ',
    'Ref: Table': 'テーブル番号',
    'Ref: Required fields': '必要なフィールド',
    'Service unavailable': 'サービスは利用できません',
    'Ref: Cannot order':
      '申し訳ございません。現在注文することはできません。営業時間と利用可能なサービスについては、レストランにお問い合わせください。',
    'Ref: Order Unavailable':
      '選択された日付の注文、フルフィルメントの種類と場所はもう利用できません！',
    'Ref: No Availability': 'これ以上可用性はありません',
    'Ref: Continue on your device': 'デバイスを続けます',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}注文を完了した場合に獲得しました',
    'Ref: Loyalty Card Description':
      '{stamp_form}は、次のロイヤルティに追加されます{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Loyalty Stampsプログラムを使用すると、報酬のためにスタンプを収集できます。',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b>注文を完了した場合に獲得しました：',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> <b> {scheme_names} </b>に追加されました',
    'Ref: Stamp': 'スタンプ',
    'Ref: Stamps': 'スタンプ',
    'Ref: Program': 'プログラム',
    'Ref: Programs': 'プログラム',
    'Ref: Loyalty Stamps': 'ロイヤルティスタンプ',
    'Ref: See Loyalty Stamps': 'ロイヤルティスタンプを参照してください',
    'Ref: Loyalty Info': 'ロイヤルティ情報',
    'Ref: Email address': '電子メールアドレス',
    'Ref: and': 'と',
    'Ref: Order minimum amount': '申し訳ありませんが、最低支払い額は{minimumAmount}です',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'デバイスを続けます',
    'Ref: Scan QR to continue': 'QRコードをスキャンして、デバイスの旅を続けてください。',
  },
  OrderHistory: { 'Ref: Orders': '注文', 'Ref: Page Title': '私の注文' },
  OrderHistoryDetails: {
    'Ref: Unit price': '単価',
    'Ref: Download': '領収書をダウンロードします',
    'Ref: Download error':
      'ダウンロードエラーが発生しました。後で領収書をダウンロードしてみてください',
    'Ref: Order details title': '注文詳細',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'ありがとうございました！ご注文を確認しました',
    'Ref: Text for pickup': '通知がオンになっている場合、ピックアップの準備ができたら更新します。',
    'Ref: Success Order Title': '注文いただきありがとうございます',
    'Ref: Success Order Description': 'ご注文を検証するには、チェックアウトで支払います。',
    'Ref: Success Order ID': 'ID注文',
    'Ref: Success Order Action': '新規注文',
    'Ref: Text for dinein':
      'ご注文はすぐに提供されます。またイートインのご注文についてはお近くのスタッフにお尋ねください。',
    'Ref: Text for scan&go': 'ご注文の履歴はご利用いただけます',
    'Ref: Text for delivery':
      '注文履歴でそのステータスを追跡できます。通知が有効になっている場合は、ご注文の準備ができたらお知らせします。',
    'See details': '詳細を見る',
    'Go to home': '家に行きます',
  },
  FailOrder: {
    'Go to home': '家に行きます',
    'Ref: Kiosk Fail Title': 'おっとっと！何かがうまくいかなかった！',
    'Ref: Kiosk Fail Description':
      '残念ながら、ご注文は成功しませんでした。もう一度やり直すか、スタッフにあなたを助けるように頼んでください。',
    'Ref: Kiosk Fail Back': '前のページに戻ります',
    'Ref: Kiosk Fail Action': 'セッションをクリアし、もう一度注文してください',
    'Ref: Error in cart validation':
      '注文中にエラーが発生しました。問題を解決するには、施設にご連絡ください。',
    'Facility contact': '施設の連絡先',
    'Ref: Ordering failed': '注文に失敗しました',
    'Ref: Order confirmed': '確認された注文',
  },
  TableCheckIn: {
    'Welcome at': 'ようこそ',
    Table: 'テーブル：',
    'Ref: list title': '最近の注文',
    'See the menu': 'メニューを参照してください',
    'Table Check-in': 'テーブルチェックイン',
    'Ref #': 'ref＃',
    Checked: 'チェックしました',
    'Check in': 'チェックイン',
    'See details': '詳細を見る',
    'Ref: You have changed location': 'あなたは場所を変えました',
    'Ref: You are now at': '現在ここにいます',
    'Order number': '注文番号',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'プロモーションが適用されます',
    'Ref: Promotion applied description':
      'あなたはあなたのカートからのプロモーションに利益をもたらします。',
    'Ref: Promotion deleted title': 'プロモーションが削除されました',
    'Ref: Promotion deleted description':
      '条件に合わないか、プロモーションが期限切れになっているため反映されません。',
  },
  MenuTile: { 'Ref: Scan&Go': 'スキャン＆ゴー', 'Ref: View only': '表示のみ' },
  ProductTile: {
    'Ref: Allergens': 'アレルゲン',
    'Ref: Presence of allergen': 'アレルゲンの存在',
    'Ref: Scan': 'スキャン',
    'Ref: From': 'から',
    'Ref: Already in product favorite list. Click to remove it.':
      'すでに製品のお気に入りのリストに載っています。クリックして削除します。',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '現在、製品のお気に入りリストに追加されていません。クリックして追加します。',
    'Ref: Free': '無料',
  },
  OrderSummary: {
    'Ref: item': 'アイテム',
    'Ref: items': 'アイテム',
    'Ref: Clear': 'クリア',
    'Ref: Cart': 'カート',
    'Ref: Subtotal': '小計',
    'Ref: Total': '合計',
    'Ref: Tax': '税',
    'Ref: Promotion': 'プロモーション',
    'Ref: Total without promotion': 'プロモーションなしで合計',
    'Ref: Number of product in cart': 'カート内の商品数',
    'Ref: Delivery': '配達',
  },
  CartItemTile: { 'Ref: Vegan': 'ビーガン', 'Ref: Vegeterian': 'ベジタリアン' },
  OrderInfoCard: {
    'Ref: Header': '注文',
    'Ref: from': 'から',
    'Ref: location': '位置',
    'Ref: Phone': 'あなたの電話番号',
    'Ref: Instructions': '指示',
    'Ref: Order type': '注文タイプ',
    'Ref: Pickup': '選び出す',
    'Ref: Eat-in': 'イートイン',
    'Ref: Scan&Go': 'スキャン＆ゴー',
  },
  ReorderWidget: {
    'Ref: No items': 'まだ注文はありません。今すぐ買い物を！',
    'Ref: Title': 'もう一度注文してください',
  },
  MenusWidget: {
    'Ref: no menu': '今のところメニューはありません',
    'Ref: Modal title': 'その日のメニュー',
  },
  PromotionInfo: { 'Ref: Discounts available': '利用可能な割引' },
  PromotionInfoItem: { 'Ref: Activate': '活性化' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'ご注文', 'Ref: Widget Title': '私の注文' },
  SuggestionsWidget: {
    'Ref: Modal title': 'その日の提案',
    'Ref: Any menu': '任意のメニュー',
    Other: '他の',
    'Ref: Suggestions calendar button': '提案カレンダー',
  },
  ModifierCard: {
    'Ref: Serving Size': 'サービングのサイズ',
    'Ref: Nutrition': '栄養成分表示',
    'Ref: Nutrition adults': '大人は1日約2000kcal必要です。',
    'Ref: Nutrition Calories': 'カロリー',
    'Ref: Nutrition Calcium': 'カルシウム',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '飽和脂肪酸',
    'Ref: Nutrition Carbohydrates': '炭水化物',
    'Ref: Nutrition Sugar': '糖質',
    'Ref: Nutrition Protein': 'タンパク質',
    'Ref: Nutrition Sodium': 'ナトリウム',
    'Ref: Nutrition cholesterol': 'コレステロール',
    'Ref: Nutrition dietaryFiber': '食物繊維',
    'Ref: Nutrition iron': '鉄',
    'Ref: Nutrition potassium': 'カリウム',
    'Ref: Nutrition vitaminA': 'ビタミンA',
    'Ref: Nutrition vitaminC': 'ビタミンC',
    'Ref: Ingredients': 'レシピ成分',
    'Ref: Allergens': 'アレルギー',
    'Ref: Always there': '常にそこに',
    'Ref: Allergens selected': 'フィルター上で選択されたアレルゲン：',
    'Ref: minimum required': '最低限必要です',
    'Ref: ingredients': '材料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '無料',
    'Ref: Based on customization': 'カスタマイズに基づいています',
    'Ref: Additives eggs': '卵',
    'Ref: Additives fish': '魚',
    'Ref: Additives milk': 'ミルク',
    'Ref: Additives nuts': 'ナッツ',
    'Ref: Additives wheat': '小麦',
    'Ref: Additives peanuts': 'ピーナッツ',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': 'ゴマ',
    'Ref: Review': 'レビュー',
    'Ref: Per portion': '部分ごと',
    'Ref: Per 100g': '100gあたり',
    'Ref: Modifier item number of free items': '•{number_of_free_items}無料',
    'Ref: Number of free items': '無料アイテムの数：  {number_of_free_items}',
    'Ref: Allergen selected': '検索フィルターで選択されたアレルゲン',
    'Ref: Nutritional info': '栄養情報',
    'Ref: Info': '情報',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'プロモーション' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'あなたの仕事を選んでください',
    'Ref: Validate': '検証',
    'Ref: Pricing Tiers dialog message': 'メールアドレスで認識します',
    'Ref: Please select your job in the company': '会社でのあなたの仕事を選択してください',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '選び出す',
    'Ref: Eat-in': 'イートイン',
    'Ref: Scan&Go': 'スキャン＆ゴー',
    'Ref: Delivery': '配達',
    'Ref: Total': '合計',
    'Ref: How was facility name service': '{facility_name}サービスはどうでしたか？',
    'Ref: How do you rate the service': 'サービスをどのように評価しますか？',
  },
};
export default labels;
