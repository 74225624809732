import { useSelector } from 'react-redux';

import { getNumberOfFreeItems } from '../../../LoyaltyStamps/helpers/loyaltyStamps.helper';
import { StateWithOrder } from '../../types/orderState.types';
import { ProductPortionId } from '../../types/useLoyaltyReward.types';
import { useFetchLoyaltySchemes } from '../useFetchLoyaltySchemes';
import { useFetchLoyaltyStampsProgress } from '../useFetchLoyaltyStampsProgress';

import { GetLoyaltySchemesResponse } from '@/modules/LoyaltyStamps/api/api.types';

export const useLoyaltyReward = (
  portions: ProductPortionId[],
  menuId: number,
  isGuest: boolean
) => {
  const stateWithCartAndSite = useSelector((state: StateWithOrder) => state);
  const cart = stateWithCartAndSite?.Order?.cart;
  const siteId = stateWithCartAndSite?.Core?.context?.site?.id || '';

  const { schemes, isLoading: isLoadingLoyaltySchemes } = useFetchLoyaltySchemes(siteId, isGuest);

  const { userProgress, isLoading: isLoadingSchemesProgress } = useFetchLoyaltyStampsProgress(
    siteId,
    isGuest
  );

  const isLoading = isLoadingLoyaltySchemes || isLoadingSchemesProgress;

  const portionsFreeItemsInfo = portions.map((portion) => {
    return {
      portion,
      numberOfFreeItems: getNumberOfFreeItems(
        portion.foodItemId,
        portion.uomId,
        menuId,
        cart,
        schemes,
        userProgress
      ),
    };
  });

  const isPortionProvidingLoyaltyStamps = ({
    productPortionIds,
    menuId,
    loyaltySchemes,
  }: {
    productPortionIds?: ProductPortionId[];
    menuId?: number;
    loyaltySchemes?: GetLoyaltySchemesResponse[];
  }) => {
    return loyaltySchemes && menuId && productPortionIds
      ? loyaltySchemes.some(
          (item) =>
            item.menuIdsProvidingProductsRedeemable.some((i) => i === menuId) &&
            item.productsProvidingStamp.some(
              (p) =>
                productPortionIds.some((x) => x.foodItemId === p.foodItemId) &&
                productPortionIds.some((x) => x.uomId === p.uomId)
            )
        )
      : false;
  };

  const isPortionRedeemableAsRewardInLoyaltyScheme = ({
    productPortionIds,
    menuId,
    loyaltySchemes,
  }: {
    productPortionIds?: ProductPortionId[];
    menuId?: number;
    loyaltySchemes?: GetLoyaltySchemesResponse[];
  }) => {
    return loyaltySchemes && menuId && productPortionIds
      ? loyaltySchemes.some(
          (item) =>
            item.menuIdsProvidingProductsRedeemable.some((i) => i === menuId) &&
            item.productsRedeemable.some(
              (p) =>
                productPortionIds.some((x) => x.foodItemId === p.foodItemId) &&
                productPortionIds.some((x) => x.uomId === p.uomId)
            )
        )
      : false;
  };

  return {
    portionsFreeItemsInfo,
    isLoading,
    isPortionProvidingStamp: isPortionProvidingLoyaltyStamps({
      productPortionIds: portions,
      menuId,
      loyaltySchemes: schemes,
    }),
    isPortionRedeemableAsReward: isPortionRedeemableAsRewardInLoyaltyScheme({
      productPortionIds: portions,
      menuId,
      loyaltySchemes: schemes,
    }),
  };
};
