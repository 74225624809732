import { Tag } from '../../../components/molecules/Tile';
import { FacilityDataItem } from '../../Facilities/types/types';

import { OrderHistory } from './orderState.types';

export interface OrdersListItem {
  id: string;
  label: string;
  value: string;
}

export enum HistoricalOrderType {
  All = 'All',
  CurrentAndFuture = 'CurrentAndFuture',
  Past = 'Past',
}

export type PriceFormatter = { format: (val: number) => string };

export const orderTypeFilterId = 'order_type_filter';

export type OrdersFiltering = {
  [orderTypeFilterId]: OrdersTypeFilter;
};

type OrdersTypeFilter = {
  [HistoricalOrderType.CurrentAndFuture]: boolean;
  [HistoricalOrderType.Past]: boolean;
};

export type OrderHistoryTile = {
  id: string;
  facilityId: string;
  description: string;
  linkPath: string;
  title: string;
  status: string;
};

export type OrderDetailsTile = {
  id: number;
  title: string;
  description: string;
  childText: string;
  strikethroughChildText?: string;
  chips: Tag[];
  imageUrl?: string;
  genericCategory?: string;
};

export type OrderHistoryTileProps = {
  order: OrderHistory;
  currentLanguageCode: string;
  facilities: FacilityDataItem[] | undefined;
  image?: JSX.Element;
};
