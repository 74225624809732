import { SETUP_OPTIONS } from '../../../../constants';
import { SERVICE } from '../../../config';
import { DeliveryOptionType } from '../../types/cart.types';

import { isTimeSelectionVisible } from './timeSelectionVisible.helper';

const mockServicesList = jest.fn();
jest.mock('../../../../store', () => ({
  store: {
    getState: () => ({
      Core: {
        services: {
          list: mockServicesList(),
        },
      },
    }),
  },
}));

const disableTimeSelectionOptionTrue = {
  name: SETUP_OPTIONS.FOOD_ORDER.disableTimeSelection,
  value: 'true',
};

const disableTimeSelectionOptionFalse = {
  name: SETUP_OPTIONS.FOOD_ORDER.disableTimeSelection,
  value: 'false',
};

describe('isTimeSelectionVisible', () => {
  it('returns false when setup option set and fullfilmentType is delivery', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.FOOD_ORDER,
      setupOptions: [disableTimeSelectionOptionTrue],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);

    const result = isTimeSelectionVisible(DeliveryOptionType.Delivery);
    expect(result).toBe(false);
  });

  it('returns true when setup option set and fullfilmentType is not delivery', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.FOOD_ORDER,
      setupOptions: [disableTimeSelectionOptionTrue],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);

    const result = isTimeSelectionVisible(DeliveryOptionType.PickupOption);
    expect(result).toBe(true);
  });

  it('returns false when setup option is set to false', async () => {
    const serviceOrderWithOptionSet = {
      name: SERVICE.FOOD_ORDER,
      setupOptions: [disableTimeSelectionOptionFalse],
    };
    mockServicesList.mockReturnValue([serviceOrderWithOptionSet]);

    const result = isTimeSelectionVisible(DeliveryOptionType.Delivery);
    expect(result).toBe(true);
  });

  it('returns false when setup option not set', async () => {
    const serviceOrderWithoutSetupOptionSet = {
      name: SERVICE.FOOD_ORDER,
      setupOptions: [],
    };
    mockServicesList.mockReturnValue([serviceOrderWithoutSetupOptionSet]);

    const result = isTimeSelectionVisible(DeliveryOptionType.Delivery);
    expect(result).toBe(true);
  });
});
