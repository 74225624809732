import { useMemo } from 'react';

import { defaultValues } from '../../config';
import { SuggestedMenuItem } from '../../types/orderState.types';

export const useCheckNoSuggestionsToDisplay = (suggestions: SuggestedMenuItem[]) => {
  const checkNoSuggestionsToDisplay = (count: number, max: number) => {
    if (count < max) {
      return count;
    } else {
      return max;
    }
  };

  const suggestionsLimitMobile = useMemo(
    () => checkNoSuggestionsToDisplay(suggestions.length, defaultValues.maxSuggestionsMobile),
    [suggestions.length]
  );

  const suggestionsLimitDesktop = useMemo(
    () => checkNoSuggestionsToDisplay(suggestions.length, defaultValues.maxSuggestionsDesktop),
    [suggestions.length]
  );

  return { suggestionsLimitMobile, suggestionsLimitDesktop };
};
