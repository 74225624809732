import momentjs from 'moment';
import { toast, ToastOptions } from 'react-toastify';

import { WarningIcon } from '../../../assets/icons';
import PromotionNotification, {
  PromotionNotificationType,
} from '../components/PromotionNotification';
import { pagePaths } from '../config';
import { AppliedPromotion, OrderDraft, PromotionInfo } from '../types/orderState.types';
import { PromotionInfoItem } from '../types/promotions.types';

export const checkAndShowPromotionNotification = ({
  currentPromotionDiscount,
  newPromotionDiscount,
}: {
  currentPromotionDiscount?: number;
  newPromotionDiscount?: number;
}) => {
  newPromotionDiscount = newPromotionDiscount ?? 0;
  currentPromotionDiscount = currentPromotionDiscount ?? 0;
  if (newPromotionDiscount !== currentPromotionDiscount) {
    const toastOptions: ToastOptions<{}> = {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    };

    if (newPromotionDiscount > 0 && currentPromotionDiscount === 0) {
      toast.success(<PromotionNotification type={PromotionNotificationType.Added} />, {
        ...toastOptions,
        icon: false,
      });
    }

    if (currentPromotionDiscount > 0 && newPromotionDiscount === 0) {
      toast.error(<PromotionNotification type={PromotionNotificationType.Removed} />, {
        ...toastOptions,
        icon: <WarningIcon />,
      });
    }
  }
};

export const getProductRedirectUrl = (items: PromotionInfoItem[] | undefined) => {
  if (!items || !items[0]) return '';

  const date = items[0].date ? momentjs(items[0].date).format('YYYY-MM-DD') : '';
  const menuId = items[0].menuId?.toString() || '';
  if (!items[0].menuItemId || !items[0].date)
    return pagePaths.ProductsList.replace(':facilityId', items[0].facilityId || '')
      .replace(':menuId', menuId)
      .replace(':date', date);

  return pagePaths.ProductDetails.replace(':facilityId', items[0].facilityId || '')
    .replace(':menuId', menuId)
    .replace(':date', date)
    .replace(':id', items[0].menuItemId.toString())
    .replace(':cartItem', 'no-cart');
};

export const getSortedPromotions = (
  availablePromotions: PromotionInfo[] | undefined,
  draft: OrderDraft | undefined,
  getMenuItemsForPromotion: (promotion: PromotionInfo) =>
    | {
        facilityId: string | undefined;
        menuId: number | undefined;
        menuItemId: number | undefined;
        date: string | undefined;
      }[]
    | undefined
) => {
  const availablePromotionsToSort = availablePromotions ? [...availablePromotions] : [];
  return availablePromotionsToSort?.sort((a, b) => {
    if (isPromotionActive(a.id, draft?.promotionsApplied)) return -1;
    if (isPromotionActive(b.id, draft?.promotionsApplied)) return 1;
    if (getMenuItemsForPromotion(a)?.length === 1) return -1;
    if (getMenuItemsForPromotion(b)?.length === 1) return 1;
    return 0;
  });
};

export const getPromotionsTooltipContent = (appliedPromotions?: { name?: string }[]) => {
  const appliedPromotionNames = appliedPromotions?.map((promotion) => promotion.name!) ?? [];

  return appliedPromotionNames.length > 0 ? (
    <>{appliedPromotionNames.map((promotionName) => promotionName)}</>
  ) : null;
};

export const isPromotionActive = (
  promotionId: number,
  promotionsApplied: AppliedPromotion[] | undefined
) => {
  const isActive = promotionsApplied?.some((promotion) => promotion.id === promotionId);

  return !!isActive;
};
