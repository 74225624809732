import classNames from 'classnames';

import { getQuestionComponent, getQuestionLabel } from '../../helpers/reviewForm.helper';

import { SurveyQuestionsProps } from './SurveyQuestions.types';

import Title, { TITLE_SIZE } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';

import styles from './SurveyQuestions.module.css';

const SurveyQuestions = ({ survey, values, onChange, label }: SurveyQuestionsProps) => {
  if (!survey) return <div />;

  const { questions } = survey;

  return (
    <>
      {questions.map(({ surveyQuestionId, questionText, questionType }, i) => (
        <Card className={classNames('mb-M')} key={surveyQuestionId}>
          <Title size={TITLE_SIZE.BODYSBOLD}>
            {getQuestionLabel({
              questionText,
              value: values[surveyQuestionId]?.answer,
              questionType,
              required: values[surveyQuestionId]?.required,
            })}
          </Title>
          <div className={styles.question}>
            {getQuestionComponent(
              surveyQuestionId,
              questionType,
              values[surveyQuestionId]?.answer,
              (answer) => onChange({ question: surveyQuestionId, answer, questionType }),
              label
            )}
          </div>
        </Card>
      ))}
    </>
  );
};

export default SurveyQuestions;
