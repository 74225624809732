import { renderHook } from '@testing-library/react';

import useSortedFacilityList from './useSortedFacilityList';

const mockSelector = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
}));

describe('useSortedFacilityList', () => {
  const siteId = '123';

  const facility1 = { id: '1', name: 'Facility 1', viewCounts: 10 };
  const facility2 = { id: '2', name: 'Facility 2', viewCounts: 20 };
  const facilitiesList = { [siteId]: [facility1.id, facility2.id] };
  const data = { [facility1.id]: facility1, [facility2.id]: facility2 };
  const viewCounts = { [facility1.id]: 10, [facility2.id]: 20 };

  describe('when siteId is undefined', () => {
    it('returns an empty array ', () => {
      const state = { Facilities: { data, list: facilitiesList, viewCounts } };
      mockSelector.mockReturnValue(state);

      const { result } = renderHook(() => useSortedFacilityList(undefined));
      expect(result.current).toEqual([]);
    });
  });

  describe('when facilitiesList is undefined', () => {
    it('returns an empty array ', () => {
      const state = { Facilities: { data, list: undefined, viewCounts } };
      mockSelector.mockReturnValue(state);
      const { result } = renderHook(() => useSortedFacilityList(siteId));
      expect(result.current).toEqual([]);
    });
  });

  describe('when facilitiesList has facilities', () => {
    it('returns an array of facilities sorted by viewCounts in descending order', () => {
      const state = { Facilities: { data, list: facilitiesList, viewCounts } };
      mockSelector.mockReturnValue(state);
      const { result } = renderHook(() => useSortedFacilityList(siteId));
      expect(result.current).toEqual([facility2, facility1]);
    });

    it('recomputes the sorted facilities list when the dependencies change', async () => {
      const state = { Facilities: { data, list: facilitiesList, viewCounts } };
      mockSelector.mockReturnValue(state);
      const { result, rerender } = renderHook(() => useSortedFacilityList(siteId));
      expect(result.current).toEqual([facility2, facility1]);

      const updatedState = {
        Facilities: {
          data,
          list: facilitiesList,
          viewCounts: { [facility1.id]: 30, [facility2.id]: 20 },
        },
      };
      mockSelector.mockReturnValue(updatedState);

      rerender({ siteId });

      expect(result.current).toEqual([{ ...facility1, viewCounts: 30 }, facility2]);
    });
  });
});
