import biteApi from '../../../services/api/biteApi';
import mockResponse from '../mocks/api';

import { Faq } from './api.types';

const faqApi = biteApi.enhanceEndpoints({ addTagTypes: ['faq'] }).injectEndpoints({
  endpoints: (build) => ({
    getFaq: build.query<Faq, string>({
      query: (siteId: string) => ({
        url: `/v1/content/faq?siteId=${siteId}`,
        method: 'get',
        mockData: mockResponse.getFaq,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetFaqQuery } = faqApi;
export default faqApi;
