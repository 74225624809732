import { Cart, CartMenuItem } from '../../Order/types/orderState.types';
import { GetLoyaltySchemesResponse, GetUserLoyaltySchemesProgressResponse } from '../api/api.types';

export const getNumberOfPotentiallyUsedStamps = (
  cartMenuItems: CartMenuItem[],
  scheme: GetLoyaltySchemesResponse,
  progress: GetUserLoyaltySchemesProgressResponse,
  menuId: number
) => {
  const actualStamps = getActualStamps(progress.stampsCollected, scheme.stampsRequiredForRedeem);
  const maxNumberOfRewards =
    (progress.stampsCollected - actualStamps) / scheme.stampsRequiredForRedeem;

  let numberOfRedeemableItemsInCart = 0;
  let redeemedPortions = cartMenuItems?.filter((portion) =>
    scheme.productsRedeemable.some(
      (item) =>
        item.foodItemId === portion.foodItemId &&
        item.uomId === portion.uomId &&
        (scheme.menuIdsProvidingProductsRedeemable.length === 0 ||
          scheme.menuIdsProvidingProductsRedeemable.includes(menuId))
    )
  );
  redeemedPortions?.forEach((item) => {
    numberOfRedeemableItemsInCart += item.quantity;
  });

  const numberOfUsedRewards =
    numberOfRedeemableItemsInCart - maxNumberOfRewards >= 0
      ? maxNumberOfRewards
      : numberOfRedeemableItemsInCart;

  return numberOfUsedRewards * (scheme?.stampsRequiredForRedeem ?? 0);
};

export const getActualStamps = (stampsCollected: number, stampsRequiredForRedeem: number) => {
  return stampsCollected % stampsRequiredForRedeem;
};

export const getRewards = (
  stampsCollected: number,
  usedStamps: number,
  stampsRequiredForRedeem: number,
  stampsActual: number
) => {
  return (stampsCollected - usedStamps - stampsActual) / stampsRequiredForRedeem;
};

export const getNumberOfFreeItems = (
  foodItemId: number,
  uomId: number,
  menuId?: number,
  cart?: Cart,
  schemes?: GetLoyaltySchemesResponse[],
  progresses?: GetUserLoyaltySchemesProgressResponse[]
) => {
  let numberOfFreeItems = 0;

  let possibleSchemes = schemes?.filter((s) =>
    s.productsRedeemable.some((p) => p.foodItemId === foodItemId && p.uomId === uomId)
  );

  menuId &&
    (possibleSchemes = possibleSchemes?.filter(
      (scheme) =>
        scheme.menuIdsProvidingProductsRedeemable.length === 0 ||
        scheme.menuIdsProvidingProductsRedeemable.includes(menuId)
    ));

  let numberOfUsedStamps = 0;

  possibleSchemes &&
    possibleSchemes.forEach((scheme) => {
      const schemeUserProgress = progresses?.find((p) => p.loyaltySchemeId === scheme.id);
      cart?.menuPortionItems &&
        menuId &&
        (numberOfUsedStamps = schemeUserProgress
          ? getNumberOfPotentiallyUsedStamps(
              cart?.menuPortionItems,
              scheme,
              schemeUserProgress,
              menuId
            )
          : 0);
      let collectedPoints =
        progresses?.find((p) => p.loyaltySchemeId === scheme.id)?.stampsCollected ?? 0;
      numberOfFreeItems += Math.floor(
        (collectedPoints - numberOfUsedStamps) / scheme.stampsRequiredForRedeem
      );
    });

  return numberOfFreeItems;
};
