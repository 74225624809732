import { Capacitor } from '@capacitor/core';
import { useEffect, useRef } from 'react';

import { APP_NAME } from '../../../constants';

const createMetaTag = (platform: string, nativeViewportScale: number) => {
  document.querySelector("meta[name='viewport']")?.remove();
  const metaTag = document.createElement('meta');
  metaTag.name = 'viewport';

  if (platform === 'web') {
    metaTag.content =
      'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0';
  } else {
    metaTag.content = `viewport-fit=cover, width=device-width, initial-scale=${nativeViewportScale}, minimum-scale=${nativeViewportScale}, maximum-scale=${nativeViewportScale}`;
  }

  document.getElementsByTagName('head')[0].appendChild(metaTag);
};

const useMetaViewport = () => {
  const currentScaleRef = useRef(1);

  useEffect(() => {
    const kioskMaxSupportedWidth = 1024;
    const platform = Capacitor.getPlatform();
    const appName = process.env.REACT_APP_APP_NAME?.toLowerCase();

    function handleResize() {
      const width = window.innerWidth;
      if (platform === 'web') return;

      if (width < kioskMaxSupportedWidth) {
        if (currentScaleRef.current > 1) {
          //resolution changed because of screen orientation change, check is scalling still needed
          // e.g. tablet screen in landscape is 1280 so scalling by 1.26 needed
          // switching to portrait width is 800 - remove scalling
          const widthWithoutRescaling = Math.round(width * currentScaleRef.current);
          if (widthWithoutRescaling < kioskMaxSupportedWidth) {
            currentScaleRef.current = 1;
            console.info(
              `Viewport width without scalling ${widthWithoutRescaling}, set initial scale ${currentScaleRef.current}`
            );
            createMetaTag(platform, currentScaleRef.current);
          }
        }

        return;
      }

      let nativeViewportScale = Math.round((width / kioskMaxSupportedWidth) * 100) / 100;
      while (width / nativeViewportScale >= kioskMaxSupportedWidth) {
        nativeViewportScale += 0.01;
      }

      currentScaleRef.current = nativeViewportScale;
      console.info(`Viewport width ${width}, scaling by ${currentScaleRef.current}`);
      createMetaTag(platform, currentScaleRef.current);
    }

    if (appName === APP_NAME.BITEKIOSK.toLowerCase()) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    createMetaTag(platform, currentScaleRef.current);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
};

export default useMetaViewport;
