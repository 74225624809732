const labels = {
  All: {
    'Ref: stamp progress': 'Progreso hacia el próximo sello',
    Reward: 'Premio',
    Rewards: 'Recompensas',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Sellos',
    'Ref: Fulfill conditions': '¡Gane sellos para canjear recompensas!',
    'See Reward': 'Ver recompensa',
    'Ref: No reward': 'Sin recompensa por el momento',
  },
  RedeemableProducts: { 'Ref: Page title': 'Recompensas de lealtad' },
};
export default labels;
