const labels = {
  All: {
    'Ref: Redeem coffee description': 'Your free, freshly-brewed cup of coffee is waiting',
    'Ref: Congratulations': 'Congratulations',
    'Ref: Get free coffee': 'Get free coffee!',
    'Ref: Free coffee description': 'Fill your cup, earn points towards a free coffee.',
    'Ref: Redeem': 'Redeem!',
  },
  StampPage: {
    'Ref: Page title': 'Free Coffee',
    'Ref: seconds left': 'seconds left',
    'Ref: How it works': 'How it works',
    'Ref: How it works description':
      'Each time you purchase a coffee, press the scan button and scan the provided QR code at the cash register.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Scan QR Code',
    'Ref: Collect reward': 'Collect reward',
    'Ref: Reward collected': 'Reward collected',
    'Ref: Invalid QR Code': 'Invalid QR Code',
    'Ref: Invalid QR Code description': "The QR Code isn't recognized",
    'Ref: Close': 'Close',
    'Ref: Redeem button': 'Redeem',
    "Ref: The QR Code isn't recognized": "The QR Code isn't recognized",
    'Ref: Cancel': 'Cancel',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Congratulations!',
    'Ref: Confirm redeem description':
      'Sure you want to use this? If so, you have just 60 seconds to show your phone at the cash register and complete your purchase',
    'Ref: Congratulations modal description': 'Grab your free coffee now!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Free coffee!', 'Ref: See All': 'See All' },
};
export default labels;
