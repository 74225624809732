export enum ModuleContext {
  MENU,
  ORDER,
}

export type MenuVisits = { menuId: number; visits: number };

export type PickupTimeSlotsRequest = {
  cartDate: Date | undefined;
  menuId: number | undefined;
  pickupSpotId: number | undefined | null;
  siteId: string | undefined;
  deliveryOptionId: number | undefined;
};

export enum CreateDraftOrderError {
  MinimumTimeToOrder = 'MinimumTimeToOrder',
}
