import { FC } from 'react';

import useWindowSize from '../../../helpers/hooks/useWindowSize';
import AccountWidget from '../../../modules/Core/components/TitleBarWidgets/AccountWidget/AccountWidget';
import NotificationsWidget from '../../../modules/Core/components/TitleBarWidgets/NotificationsWidget';
import CartWidget from '../../../modules/Order/components/TitleBarWidgets/CartWidget/CartWidget';
import ResetSessionWidget from '../../../modules/Order/components/TitleBarWidgets/ResetSessionWidget/ResetSessionWidget';
import TranslationWidget from '../../../modules/Order/components/TitleBarWidgets/TranslationWidget';
import ActionsBar from '../../organisms/ActionsBarV2';
import Column from '../../organisms/Column';
import Container from '../../organisms/Container';
import ContentDetailsHeader from '../../organisms/ContentDetailsHeader';
import TitleBar from '../../organisms/TitleBar';
import { TITLEBAR_CAPTION_TYPE } from '../../organisms/TitleBar/TitleBar.types';
import DefaultPage from '../DefaultPage/DefaultPage';

import {
  ContentDetailsPageProps,
  ContentDetailsPageWithAdditionalSectionsProps,
  ContentDetailsPageWithNotificationProps,
  ContentDetailsPageWithSideBottomSectionProps,
} from './ContentDetailsPage.types';

import styles from './ContentDetailsPage.module.css';

const PageWrapper: FC<
  Pick<
    ContentDetailsPageProps,
    | 'title'
    | 'tabTitle'
    | 'hideBackButton'
    | 'children'
    | 'className'
    | 'data-testid'
    | 'hideDefaultTitleBarWidgets'
    | 'withNavBar'
    | 'backButtonCustomPath'
  >
> = ({
  title,
  tabTitle,
  hideBackButton = false,
  children,
  className,
  'data-testid': dataTestId,
  hideDefaultTitleBarWidgets,
  withNavBar = true,
  backButtonCustomPath,
}) => {
  return (
    <DefaultPage
      titleBar={
        <TitleBar>
          <TitleBar.Navigation
            caption={
              title !== undefined
                ? {
                    title,
                    hideBackButton,
                    captionType: TITLEBAR_CAPTION_TYPE.DISPLAY_PAGE_TITLE,
                    backButtonCustomPath,
                  }
                : undefined
            }
            tabTitle={tabTitle}
          />
          <TitleBar.Widgets>
            <NotificationsWidget hide={hideDefaultTitleBarWidgets} />
            <CartWidget />
            <AccountWidget hide={hideDefaultTitleBarWidgets} />
            <TranslationWidget />
            <ResetSessionWidget />
          </TitleBar.Widgets>
        </TitleBar>
      }
      withNavBar={withNavBar}
      data-testid={dataTestId}
      className={className}
    >
      {children}
    </DefaultPage>
  );
};

const ContentDetailsPage: FC<ContentDetailsPageProps> = ({
  title,
  tabTitle,
  hideBackButton,
  hideDefaultTitleBarWidgets,
  header,
  sideChildren,
  children,
  actions,
  className,
  'data-testid': dataTestId = 'content-details-page',
  ...rest
}) => {
  return (
    <PageWrapper
      title={title}
      data-testid={dataTestId}
      className={className}
      hideBackButton={hideBackButton}
      hideDefaultTitleBarWidgets={hideDefaultTitleBarWidgets}
      tabTitle={tabTitle}
      {...rest}
    >
      <Container>
        <Column.Side>
          <ContentDetailsHeader {...header} />
          {sideChildren}
        </Column.Side>
        <Column.Main>{children}</Column.Main>
        {actions ? <ActionsBar>{actions}</ActionsBar> : null}
      </Container>
    </PageWrapper>
  );
};
ContentDetailsPage.displayName = 'ContentDetailsPage';

const ContentDetailsPageWithSideBottomSection: FC<ContentDetailsPageWithSideBottomSectionProps> = ({
  title,
  tabTitle,
  hideBackButton,
  hideDefaultTitleBarWidgets,
  header,
  sideChildren,
  sideBottomSection,
  children,
  actions,
  className,
  withNavBar = true,
  'data-testid': dataTestId = 'content-details-page',
  ...rest
}) => {
  return (
    <PageWrapper
      title={title}
      data-testid={dataTestId}
      className={className}
      hideBackButton={hideBackButton}
      hideDefaultTitleBarWidgets={hideDefaultTitleBarWidgets}
      tabTitle={tabTitle}
      withNavBar={withNavBar}
      {...rest}
    >
      <Container.WithSideBottomSection sideBottomSection={sideBottomSection}>
        <Column.Side>
          <ContentDetailsHeader {...header} />
          {sideChildren}
        </Column.Side>
        <Column.Main>
          {children}
          {actions ? (
            <ActionsBar inMainColumn className={styles.actions}>
              {actions}
            </ActionsBar>
          ) : null}
        </Column.Main>
      </Container.WithSideBottomSection>
    </PageWrapper>
  );
};
ContentDetailsPageWithSideBottomSection.displayName = 'ContentDetailsPage.WithSideBottomSection';

const ContentDetailsPageWithNotification: FC<ContentDetailsPageWithNotificationProps> = ({
  title,
  tabTitle,
  hideBackButton,
  header,
  sideChildren,
  children,
  actions,
  className,
  notificationContent,
  hideDefaultTitleBarWidgets,
  withNavBar = true,
  'data-testid': dataTestId = 'content-details-page',
  ...rest
}) => {
  const { width } = useWindowSize(); // TODO: tech debt - I want this ready for tomorrows demo
  const isMediumAndSmaller = width < 1024;

  return (
    <PageWrapper
      title={title}
      data-testid={dataTestId}
      className={className}
      hideBackButton={hideBackButton}
      hideDefaultTitleBarWidgets={hideDefaultTitleBarWidgets}
      tabTitle={tabTitle}
      withNavBar={withNavBar}
      {...rest}
    >
      <Container>
        <Column.Side>
          {isMediumAndSmaller && notificationContent}
          <ContentDetailsHeader {...header} />
          {sideChildren}
        </Column.Side>
        <Column.Main>
          {!isMediumAndSmaller && notificationContent}
          {children}
        </Column.Main>
        {actions ? <ActionsBar>{actions}</ActionsBar> : null}
      </Container>
    </PageWrapper>
  );
};
ContentDetailsPageWithNotification.displayName = 'ContentDetailsPage.WithNotification';

const ContentDetailsPageWithAdditionalSections: FC<ContentDetailsPageWithAdditionalSectionsProps> =
  ({
    title,
    tabTitle,
    hideBackButton,
    hideDefaultTitleBarWidgets,
    header,
    sideChildren,
    notificationContent,
    sideBottomSection,
    children,
    actions,
    className,
    withNavBar,
    'data-testid': dataTestId = 'content-details-page',
    ...rest
  }) => {
    const { width } = useWindowSize(); // TODO: tech debt - I want this ready for tomorrows demo
    const isMediumAndSmaller = width < 1024;

    return (
      <PageWrapper
        title={title}
        data-testid={dataTestId}
        className={className}
        hideBackButton={hideBackButton}
        hideDefaultTitleBarWidgets={hideDefaultTitleBarWidgets}
        tabTitle={tabTitle}
        withNavBar={withNavBar}
        {...rest}
      >
        <Container.WithAdditionalSections sideBottomSection={sideBottomSection}>
          <Column.Side>
            {isMediumAndSmaller && notificationContent}
            <ContentDetailsHeader {...header} />
            {sideChildren}
          </Column.Side>
          <Column.Main>
            {!isMediumAndSmaller && notificationContent}
            {children}
          </Column.Main>
          {actions ? <ActionsBar>{actions}</ActionsBar> : null}
        </Container.WithAdditionalSections>
      </PageWrapper>
    );
  };
ContentDetailsPageWithAdditionalSections.displayName = 'ContentDetailsPage.WithAdditionalSections';

export default Object.assign(ContentDetailsPage, {
  WithNotification: ContentDetailsPageWithNotification,
  WithSideBottomSection: ContentDetailsPageWithSideBottomSection,
  WithAdditionalSections: ContentDetailsPageWithAdditionalSections,
});
