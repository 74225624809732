const labels = {
  All: {
    'Ref: Assembled': 'Geassembleerd',
    'Ref: Submitted': 'Ingediend',
    'Ref: Fulfilled': 'Vervuld',
    'Ref: Completed': 'Voltooid',
    'Ref: Paid': 'Betaald',
    'Ref: Approved': 'Goedgekeurd',
    'Ref: Ready For Pickup': 'Klaar om op te halen',
    'Ref: Delivered': 'Geleverd',
    'Ref: Received': 'Ontvangen',
    'Ref: Rejected': 'Geweigerd',
    'Ref: Payment Error': 'Betalingsfout',
    'Ref: Pending Payment': 'In afwachting van betaling',
    'Ref: Preparing': 'in voorbereiding',
    'Ref: Refunded': 'Terugbetaald',
    'Ref: Cancelled By User': 'Geannuleerd door gebruiker',
    'Ref: Refund Requested': 'terugbetaling aangevraagd',
    'Ref: Refund Failed': 'Terugbetaling mislukt',
    'Ref: Payment Processing': 'Verwerking van betalingen',
    'Ref: Not Paid': 'Niet betaald',
    'Ref: Total:': 'Totaal:',
    'Ref: Pickup': 'Afhalen',
    'Ref: Eat-in': 'Ter plaatse consummeren',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Fulfillment Type': 'Vervullingstype',
    'Ref: Delivery': 'Levering',
    'Ref: You have no future orders': 'Je hebt geen toekomstige bestellingen',
    'Ref: You have no past orders': 'Je hebt geen bestellingen uit het verleden',
    'Ref: You have no orders': 'Je hebt geen bestellingen',
    'Ref: All orders': 'Alle bestellingen',
    'Ref: Not past orders': 'Komende bestellingen',
    'Ref: Past orders': 'Vroegere bestellingen',
    'Ref: Vegan': 'Veganistisch',
    'Ref: Vegeterian': 'Vegetarisch',
    'Ref: See item': 'Zie item',
    'Ref: Activated': 'Geactiveerd',
    'Ref: No suggestions': 'Geen suggesties gevonden',
    'Ref: Get your receipt': 'Krijg uw ontvangstbewijs',
    'Ref: Print': 'Druk uw ontvangstbewijs af',
    'Ref: Email': 'Ontvang uw ontvangstbewijs per e-mail',
    'Ref: Receipt Modal message': 'Hoe zou u uw ontvangstbewijs willen krijgen?',
    'Ref: Confirm': 'Bevestigen',
    'Ref: Your email address': 'uw e-mailadres',
    Suggestion: 'Suggestie',
    'Ref: Loyalty Reward': 'Loyaliteitsbeloning',
    'Ref: Equation for stamps per item': '1 aankoop = 1 stempel',
    'Ref: Paid by employer': 'Het bedrag dat door uw werkgever wordt betaald',
    'Ref: Paid by user': 'Het door u betaalde bedrag',
  },
  ProductsList: {
    'Ref: Page title': 'Product lijst',
    'Ref: Facility details': 'Details van de diensten',
    'Ref: Search products list': 'Zoekopdrachten',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Categorie',
    'Ref: all': 'Alle',
    'Ref: today': 'Vandaag',
    'Ref: tomorrow': 'Morgen',
    'Ref: see more': 'Bekijk meer',
    'Ref: see less': 'Minder',
    'Ref: reset': 'Reset',
    'Ref: View in cart': 'Bekijk in winkelwagen',
    'Ref: You have changed location': 'Je hebt van locatie veranderd',
    'Ref: You are now at': 'Je bent nu bij',
    'Ref: Menu card': 'Menukaart',
    'Ref: Table': 'Tafelnummer',
    'Service unavailable': 'Service onbeschikbaar',
    'Ref: Ordering unavailable':
      'Opgelet! Bestellen op deze site is momenteel niet beschikbaar, probeer het later opnieuw.',
    'Ref: Filters': 'Filters (allergenen en meer)',
  },
  ProductScanner: {
    'Ref: cart': 'Winkelkar',
    'Ref: Product Scanned':
      'werd toegevoegd aan winkelwagen. U kunt de barcodes blijven scannen om ze in uw winkelwagen toe te voegen.',
    'Ref: Scan or adjust':
      'werd toegevoegd aan winkelkar. Pas de hoeveelheid aan of scan een ander product.',
    'Ref: Product Not Matched': 'Barcode werd niet herkend. Probeer het opnieuw',
    'Ref: Scan article code': 'Scan barcode',
    'Ref: Native error product cannot be added to cart':
      'Item uit een ander menu kan niet aan de kar worden toegevoegd',
    'Ref: product scanner placeholder': 'Scan de barcode om deze aan uw winkelwagen toe te voegen',
  },
  Order: { 'Ref: Page title': 'Bestelling' },
  MenuSelector: {
    'Ref: Menu Page title': "Menu's",
    'Ref: today': 'Vandaag',
    'Ref: tomorrow': 'Morgen',
    'Ref: Search products list': 'Zoekopdrachten',
    'Ref: You have changed location': 'Je bent van locatie veranderd',
    'Ref: You are now at': 'Je bent nu bij',
  },
  ProductDetails: {
    'Ref: Page title': 'Productdetails',
    'Ref: Serving Size': 'Serveergrootte',
    'Ref: Nutrition': 'Voedingsinformatie',
    'Ref: Nutrition adults': 'Volwassenen hebben ongeveer 2000 kcal per dag nodig',
    'Ref: Nutrition Calories': 'Calorieën',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Vet',
    'Ref: Nutrition Saturated Fat': 'Verzadigd vet',
    'Ref: Nutrition Carbohydrates': 'koolhydraten',
    'Ref: Nutrition Sugar': 'Suiker',
    'Ref: Nutrition Protein': 'Eiwit',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Voedingsvezels',
    'Ref: Nutrition iron': 'Ijzer',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamine A',
    'Ref: Nutrition vitaminC': 'Vitamine C',
    'Ref: Additives': 'Additieven',
    'Ref: Emission': 'CO2 uitstoot',
    'Ref: This product generates': 'Dit product genereert',
    'Ref: of CO2': 'van CO2',
    'Ref: emissions, which equals a': 'emissies, die gelijk is aan a',
    'Ref: car trip': 'autorit',
    'Ref: Ingredients': 'Recept ingrediënt',
    'Ref: Allergens': 'Allergenen',
    'Ref: Always there': 'Altijd daar',
    'Ref: Allergens selected': 'Allergenen geselecteerd op het filter:',
    'Ref: minimum required': 'Minimaal vereist',
    'Ref: you have to add at least': 'Je moet tenminste toevoegen',
    'Ref: ingredients': 'ingrediënten',
    'Ref: Ingredient': 'ingrediënt',
    'Ref: Free': 'Vrij',
    'Ref: Based on customization': 'Op basis van uw aanpassing',
    'Ref: Additives eggs': 'eieren',
    'Ref: Additives fish': 'vis',
    'Ref: Additives milk': 'melk',
    'Ref: Additives nuts': 'noten',
    'Ref: Additives wheat': 'tarwe',
    'Ref: Additives peanuts': "pinda's",
    'Ref: Additives soy beans': 'soja bonen',
    'Ref: Additives sesame seeds': 'Sesam zaden',
    'Ref: Review': 'Beoordeling',
    'Ref: Per portion': 'Per portie',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergeen geselecteerd op zoekfilter',
    'Ref: Fitbit Log': 'Log naar Fitbit',
    'Ref: age verification required on delivery': 'Leeftijdsverificatie vereist bij levering',
    'Ref: alcohol content notification':
      'Door een van deze items te bestellen, verklaart u dat u 18 jaar of ouder bent. Alcoholmisbruik is gevaarlijk voor uw gezondheid.',
    'Ref: default': 'Standaard',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} verdiend als u dit product bestelt',
    'Ref: Loyalty Card Description':
      'De {stamp_form} wordt toegevoegd aan de volgende loyaliteit {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Met het Loyalty Stamps -programma kunt u verzamelen voor beloningen.',
    'Ref: Loyalty Modal Description':
      'Wanneer u <b> {product_name} koopt, wordt 1 stempel </b> toegevoegd aan de <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'En',
    'Ref: Stamp': 'Zegel',
    'Ref: Stamps': 'Zegels',
    'Ref: Program': 'Programma',
    'Ref: Programs': "Programma's",
    'Ref: Loyalty Stamps': 'Loyaliteitstzegels',
    'Ref: See Loyalty Stamps': 'Zie loyaliteitszegels',
    Favorite: 'Favoriet',
    'Ref: In favorites': 'Het product staat al in de favorietenlijst. Klik om het te verwijderen.',
    'Ref: Not in favorites':
      'Het product staat nog niet in de favorietenlijst. Klik om het toe te voegen.',
    'Ref: Added to favorites': 'Het product is toegevoegd aan de lijst met favorieten.',
    'Ref: Removed from favorites': 'Het product is verwijderd uit de favorietenlijst.',
    'Ref: From': 'Van',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Verander in winkelkar',
    'Ref: Popup header for customizable item qty update': 'Dit item is aanpasbaar',
    'Ref: add new version': 'Voeg nieuwe versie toe',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Werk de hoeveelheid van een aanpasbaar item rechtstreeks bij vanuit uw winkelwagen.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Werk de hoeveelheid van een aanpasbaar item rechtstreeks bij vanuit uw winkelwagen of voeg een nieuwe versie toe.',
    'Ref: another context body':
      'Om items toe te voegen van deze site, faciliteit, moment/menu of datum, moet u uw winkelkar wissen.',
    'Ref: cancel': 'Annuleren',
    'Ref: add to cart': 'Voeg toe aan winkelkar',
    'Ref: view in cart': 'Winkelkar',
    'Ref: Products in cart': 'Aantal {product_name} in winkelkar: {product_quantity}',
    'Ref: Customize': 'Aanpassen',
    'Ref: Add': 'Toevoegen',
    'Ref: Free': 'Vrij',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Annuleren',
    'Ref: notice': 'Merk op',
    'Ref: clear cart': 'Winkelkar wissen.',
    'Ref: Cart addition error message':
      'Momenteel heb je items in je winkelkar van {from_current}. Om items van {from_selected} toe te voegen, moet u de winkelkar wissen',
  },
  Cart: {
    'Ref: Page title': 'Winkelkar',
    'Ref: The cart is empty': 'De winkelkar is leeg',
    'Ref: Clear cart': 'Winkelkar wissen.',
    'Ref: Continue shopping': 'Doorgaan met winkelen',
    'Ref: Start shopping': 'Begin met winkelen',
    'Ref: Today': 'Vandaag',
    'Ref: Tomorrow': 'Morgen',
    'Ref: Tax:': 'Tax:',
    'Ref: Promotion:': 'Promotie:',
    'Ref: Total without promotion:': 'Totaal zonder promotie:',
    'Ref: Delivery:': 'Levering:',
    'Ref: Pickup informations': 'Afhaalinformatie',
    'Ref: Location': 'Plaats',
    'Ref: Time': 'Tijd',
    'Ref: Phone': 'Uw telefoon nummer',
    'Ref: Instructions': 'Instructies',
    'Ref: Please select': 'Selecteer alstublieft',
    'Ref: Enter special requests': 'Voer hier speciale verzoeken in',
    'Ref: notice': 'Merk op',
    'Ref: Product is no more available':
      'Sorry, maar het lijkt erop dat een product niet meer beschikbaar is. Controleer uw winkelkar.',
    'Ref: ok': 'OK',
    'Ref: Validate': 'Valideer mijn bestelling',
    'Ref: Pay now': 'Nu betalen',
    'Ref: Charge to my department': 'Reken af via mijn afdeling',
    'Ref: Pay on pickup': 'Betaal voor deze items bij afhaling',
    'Ref: Accept terms of sale prefix': 'ik aanvaard',
    'Ref: Accept terms of sale': 'Deze verkoopvoorwaarden',
    'Ref: Continue': 'Doorgaan',
    'Ref: Please login or sign up to place an order':
      'Log in of meld u aan om een ​​bestelling te plaatsen',
    'Ref: Accept terms of sale suffix': ', en ik heb ze gelezen en begrepen.',
    'Ref: Payment modality': 'Betalingsmiddel',
    'Ref: Other information': 'Andere informatie',
    'Ref: Eat-in': 'Ter plaatse consummeren',
    'Ref: Pickup': 'Afhalen',
    'Ref: Table': 'Tafelnummer',
    'Ref: Required fields': 'Verplichte velden',
    'Service unavailable': 'Service onbeschikbaar',
    'Ref: Cannot order':
      'Sorry, u kunt momenteel geen bestelling plaatsen op deze site. Neem contact op met het restaurant voor meer informatie over openingstijden en beschikbare diensten.',
    'Ref: Order Unavailable':
      'Bestel voor de gekozen datum, het uitvoeringstype en de locatie is niet meer beschikbaar!',
    'Ref: No Availability': 'Geen beschikbaarheid meer',
    'Ref: Continue on your device': 'Ga verder op uw toestel',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} verdiend als u uw bestelling voltooit',
    'Ref: Loyalty Card Description':
      'De {stamp_form} wordt toegevoegd aan de volgende loyaliteit {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Met het Loyaltyzegels-programma kunt u zegels verzamelen voor beloningen.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> verdiend als u uw bestelling voltooit:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> toegevoegd voor <b> {scheme_names} </b>',
    'Ref: Stamp': 'Zegel',
    'Ref: Stamps': 'Zegels',
    'Ref: Program': 'Programma',
    'Ref: Programs': "Programma's",
    'Ref: Loyalty Stamps': 'Loyaliteitstzegels',
    'Ref: See Loyalty Stamps': 'Zie loyaliteitstempels',
    'Ref: Loyalty Info': 'Loyaliteitsinformatie',
    'Ref: Email address': 'E-mailadres',
    'Ref: and': 'En',
    'Ref: Order minimum amount': 'Sorry, minimaal betalingsbedrag is {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Ga verder op uw toestel',
    'Ref: Scan QR to continue': 'Scan de QR -code om verder te gaan op uw toestel.',
  },
  OrderHistory: { 'Ref: Orders': 'Bestellingen', 'Ref: Page Title': 'mijn bestellingen' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Eenheidsprijs',
    'Ref: Download': 'Download het ontvangst',
    'Ref: Download error':
      'Er is een downloadfout opgetreden. Probeer de ontvangst later te downloaden',
    'Ref: Order details title': 'Besteldetails',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Dank je! Uw bestelling is nu bevestigd',
    'Ref: Text for pickup':
      'U kunt de status volgen via bestelgeschiedenis. Als uw meldingen zijn ingeschakeld, zullen we u melden wanneer deze klaar is voor ophalen.',
    'Ref: Success Order Title': 'Bedankt voor uw bestelling',
    'Ref: Success Order Description': 'Betaal bij de kassa om uw bestelling te valideren.',
    'Ref: Success Order ID': 'ID -bestelling',
    'Ref: Success Order Action': 'Nieuwe bestelling',
    'Ref: Text for dinein':
      'Uw bestelling wordt binnenkort aan uw tafel geserveerd. Vraag aan één van onze vriendelijke medewerkers naar uw bestelling.',
    'Ref: Text for scan&go': 'Uw bestelling is beschikbaar in uw bestelgeschiedenis',
    'Ref: Text for delivery':
      'U kunt de status bijhouden in uw bestelgeschiedenis. Als uw meldingen zijn ingeschakeld, zullen we u melden wanneer uw bestelling klaar is.',
    'See details': 'Zie de details',
    'Go to home': 'Ga naar startpagina',
  },
  FailOrder: {
    'Go to home': 'Ga naar startpagina',
    'Ref: Kiosk Fail Title': 'Oeps! Er is iets fout gegaan!',
    'Ref: Kiosk Fail Description':
      'Helaas was uw bestelling niet succesvol. Probeer het opnieuw of vraag een medewerker om u te helpen.',
    'Ref: Kiosk Fail Back': 'Terug naar de vorige pagina',
    'Ref: Kiosk Fail Action': 'Wis de sessie en bestel opnieuw',
    'Ref: Error in cart validation':
      'Er is een fout opgetreden tijdens uw bestelling. Neem contact op met de dienstverlener om het probleem op te lossen.',
    'Facility contact': 'Contact dienstverlener',
    'Ref: Ordering failed': 'Bestellen is mislukt',
    'Ref: Order confirmed': 'Order bevestigd',
  },
  TableCheckIn: {
    'Welcome at': 'Welkom bij',
    Table: 'Tafel:',
    'Ref: list title': 'Recente bestellingen voor vandaag',
    'See the menu': 'Zie het menu',
    'Table Check-in': 'Tafel inchecken',
    'Ref #': 'REF #',
    Checked: 'Gecontroleerd',
    'Check in': 'Check in',
    'See details': 'Zie de details',
    'Ref: You have changed location': 'Je hebt van locatie veranderd',
    'Ref: You are now at': 'Je bent nu bij',
    'Order number': 'Bestellingsnummer',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promotie toegepast',
    'Ref: Promotion applied description': 'U geniet van een promotie voor uw winkelkar.',
    'Ref: Promotion deleted title': 'Promotie verwijderd',
    'Ref: Promotion deleted description':
      'U profiteert niet langer van de promotie omdat u niet langer aan de voorwaarden voldoet, of de promotie is verlopen.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Scan & Go', 'Ref: View only': 'Alleen bekijken' },
  ProductTile: {
    'Ref: Allergens': 'Allergenen',
    'Ref: Presence of allergen': 'Aanwezigheid van allergeen',
    'Ref: Scan': 'Scannen',
    'Ref: From': 'Van',
    'Ref: Already in product favorite list. Click to remove it.':
      'Al in de favoriete lijst van product. Klik om het te verwijderen.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Momenteel niet toegevoegd aan de favoriete lijst met product. Klik om het toe te voegen.',
    'Ref: Free': 'Vrij',
  },
  OrderSummary: {
    'Ref: item': 'item',
    'Ref: items': 'items',
    'Ref: Clear': 'Wis',
    'Ref: Cart': 'Winkelkar',
    'Ref: Subtotal': 'Subtotaal',
    'Ref: Total': 'Totaal',
    'Ref: Tax': 'Tax',
    'Ref: Promotion': 'Promotie',
    'Ref: Total without promotion': 'Totaal zonder promotie',
    'Ref: Number of product in cart': 'Aantal producten in winkelkar',
    'Ref: Delivery': 'Levering',
  },
  CartItemTile: { 'Ref: Vegan': 'Veganistisch', 'Ref: Vegeterian': 'Vegetarisch' },
  OrderInfoCard: {
    'Ref: Header': 'Besteld voor',
    'Ref: from': 'van',
    'Ref: location': 'Plaats',
    'Ref: Phone': 'Uw telefoon nummer',
    'Ref: Instructions': 'Instructies',
    'Ref: Order type': 'Order type',
    'Ref: Pickup': 'Afhalen',
    'Ref: Eat-in': 'Ter plaatse consummeren',
    'Ref: Scan&Go': 'Scan & Go',
  },
  ReorderWidget: {
    'Ref: No items': 'Nog geen bestellingen. Winkel nu!',
    'Ref: Title': 'Opnieuw bestellen',
  },
  MenusWidget: { 'Ref: no menu': 'Geen menu voorlopig', 'Ref: Modal title': 'Menu voor de dag' },
  PromotionInfo: { 'Ref: Discounts available': 'Kortingen beschikbaar' },
  PromotionInfoItem: { 'Ref: Activate': 'Activeren' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Uw bestellingen',
    'Ref: Widget Title': 'mijn bestellingen',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Suggestie voor de dag',
    'Ref: Any menu': 'Elk menu',
    Other: 'Ander',
    'Ref: Suggestions calendar button': 'Suggesties Kalender',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Serveergrootte',
    'Ref: Nutrition': 'Voedingsinformatie',
    'Ref: Nutrition adults': 'Volwassenen hebben ongeveer 2000 kcal per dag nodig',
    'Ref: Nutrition Calories': 'calorieën',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Vet',
    'Ref: Nutrition Saturated Fat': 'Verzadigd vet',
    'Ref: Nutrition Carbohydrates': 'koolhydraten',
    'Ref: Nutrition Sugar': 'Suiker',
    'Ref: Nutrition Protein': 'Eiwit',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Voedingsvezels',
    'Ref: Nutrition iron': 'Ijzer',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamine A',
    'Ref: Nutrition vitaminC': 'Vitamine C',
    'Ref: Ingredients': 'Recept ingrediënt',
    'Ref: Allergens': 'Allergenen',
    'Ref: Always there': 'Altijd daar',
    'Ref: Allergens selected': 'Allergenen geselecteerd met de filter:',
    'Ref: minimum required': 'Minimaal vereist',
    'Ref: ingredients': 'ingrediënten',
    'Ref: Ingredient': 'ingrediënt',
    'Ref: Free': 'Vrij',
    'Ref: Based on customization': 'Op basis van uw aanpassing',
    'Ref: Additives eggs': 'eieren',
    'Ref: Additives fish': 'vis',
    'Ref: Additives milk': 'melk',
    'Ref: Additives nuts': 'noten',
    'Ref: Additives wheat': 'tarwe',
    'Ref: Additives peanuts': "pinda's",
    'Ref: Additives soy beans': 'soja bonen',
    'Ref: Additives sesame seeds': 'Sesam zaden',
    'Ref: Review': 'Beoordeling',
    'Ref: Per portion': 'Per portie',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} gratis',
    'Ref: Number of free items': 'Aantal gratis items: {number_of_free_items}',
    'Ref: Allergen selected': 'Allergeen geselecteerd op zoekfilter',
    'Ref: Nutritional info': 'Voedingsinformatie',
    'Ref: Info': 'Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Promoties' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Kies uw baan',
    'Ref: Validate': 'Bevestigen',
    'Ref: Pricing Tiers dialog message': 'U bent herkend met uw e -mailadres',
    'Ref: Please select your job in the company': 'Selecteer uw functie in het bedrijf',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Afhalen',
    'Ref: Eat-in': 'Ter plaatse consummeren',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Delivery': 'Levering',
    'Ref: Total': 'Totaal',
    'Ref: How was facility name service': 'Hoe was {facility_name} ´s service?',
    'Ref: How do you rate the service': 'Hoe beoordeelt u de service?',
  },
};
export default labels;
