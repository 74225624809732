import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { isEmpty } from 'lodash';
import md5 from 'md5';
import match from 'minimatch';
import { Action, AnyAction, Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import { appInsights } from './appInsights';
import { axiosClient } from './axios/axiosClient';
import { apiPath, cacheableUrls } from './config';
import { CacheService } from './services/CacheService';
import {
  ArgType,
  CacheKeyParams,
  CustomResponse,
  FetchJsonActionCreatorOptions,
  PreRequestParams,
  RequestData,
  ThenConfiguration,
} from './types/actionCreator.types';

const DEFAULT_CACHE_POLICY_IN_MS = false;

const shouldCacheRequest = (cacheKeyParams: CacheKeyParams) => {
  const relativeUrl = cacheKeyParams.url.replace(apiPath, '').split('?')[0];

  // We never cache post requests, they content is too big
  // and normally depends on the token + submission, plus
  // it often varies every time.
  if (!['HEAD', 'OPTIONS', 'GET'].includes(cacheKeyParams.method))
    return DEFAULT_CACHE_POLICY_IN_MS;

  // We explicitly don't want to cache it, since it's not included
  // in our configuration, return false
  for (let [k, v] of Object.entries(cacheableUrls)) if (match(relativeUrl, k)) return v;

  return DEFAULT_CACHE_POLICY_IN_MS;
};

/**
 * This function deletes the same URLs from the cache upon a write query.
 *
 * @param cacheKeyParams
 * @returns
 */
export const cacheInvalidateSameUrls = async (
  cacheKeyParams: Pick<CacheKeyParams, 'method' | 'url'>
) => {
  // We don't invalidate same URLs on READ requests
  if (cacheKeyParams.method && ['HEAD', 'OPTIONS', 'GET'].includes(cacheKeyParams.method)) return;

  console.log('[CACHE] Invalidating same URLs as ' + cacheKeyParams.url);

  // Derive relative url form request
  const relativeUrl = cacheKeyParams.url.replace(apiPath, '').split('?')[0];

  // Iterate over cache items, and delete them even
  // if they're not expired, since data might be stale

  const cacheKeys = await CacheService.getInstance().keys();
  const cacheValues = await Promise.all(
    cacheKeys.map(async (k) => ({ key: k, value: await CacheService.getInstance().getValue(k) }))
  );

  const deleteKeys = cacheValues
    .filter(({ value }) => value && value.url?.includes(relativeUrl))
    .map(({ key }) => key);

  const promises = [];
  for (let k of deleteKeys) promises.push(CacheService.getInstance().remove(k));

  return Promise.all(promises);
};

const keyFromRequest = (cacheKeyParams: CacheKeyParams) => {
  const headersString = Object.entries(cacheKeyParams.headers)
    .map(([headerName, headerValue]) => `${headerName}:${headerValue}`)
    .join('/');

  const queryString = cacheKeyParams.queryString
    ? '/query' +
      Object.entries(cacheKeyParams.queryString)
        .map(([queryStringName, queryStringValue]) => `${queryStringName}:${queryStringValue}`)
        .join('/')
    : '';

  const stringToHash = `${cacheKeyParams.url}/headers/${headersString}${queryString}`;
  return md5(stringToHash);
};

const retrieveResponseFromCache = async (cacheKeyParams: CacheKeyParams) => {
  if (!shouldCacheRequest(cacheKeyParams)) return undefined;

  const cacheKey = keyFromRequest(cacheKeyParams);
  const cachedResponse = await CacheService.getInstance().getValue(cacheKey);
  if (!cachedResponse) return undefined;

  return { ...cachedResponse, cached: true } as CustomResponse;
};

const storeResponseInCache = async (cacheKeyParams: CacheKeyParams, response: CustomResponse) => {
  const ttl = shouldCacheRequest(cacheKeyParams);
  if (!ttl) {
    // Ensure that in case of a write request
    // we invalidate the same URLS in the cache
    return await cacheInvalidateSameUrls(cacheKeyParams);
  }

  const cacheKey = keyFromRequest(cacheKeyParams);
  return await CacheService.getInstance().setValue(cacheKey, response, ttl);
};

const removeRequestFromCache = async (cacheKeyParams: CacheKeyParams) => {
  const cacheKey = keyFromRequest(cacheKeyParams);
  return await CacheService.getInstance().remove(cacheKey);
};

//just to store the calls in progress so we don't run parallel calls for the same stuff
const callsInProgress = new Map<string, Promise<any>>();

//todo: at some point, migrate all makeActionCreator to makeActionCreatorV2
//returns an object
export const makeActionCreator = (type: string, ...argNames: any) => {
  return function (...args: any) {
    const action: AnyAction = { type };
    argNames.forEach((_: any, index: any) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
};

//returns a function
export const makeActionCreatorV2 = (
  type: string,
  fieldsConfig?: any,
  moduleId?: string | undefined
) => {
  const keys = Object.keys(fieldsConfig || {});

  return (param?: { [x: string]: any }) => {
    const keyVals: any = {};
    for (const key of keys) {
      let val = param ? param[key] : undefined;
      if (val === undefined && fieldsConfig[key].defaultValue)
        val = fieldsConfig[key].defaultValue(param);
      keyVals[key] = val;
    }

    return {
      ...keyVals,
      type,
      moduleId,
      persistLocally: fieldsConfig?.persistLocally,
    };
  };
};

/* parameters:
    argConfig = {
        argName: {
            defaultValue, //function
            toQueryString, //bool, default false
            toBody, //bool, default false
            toThen //bool, default false
        },
        ...
    },
    preRequest = {
        urlConstructor: ({attrA, attrB,...}) => do sth and return the url, // attributes are defined in argConfig
        init: { an init object that can be passed to a request constructor},
        bodyContent: { key value pairs}
    },
    lockAction: {type: 'ACTION_TYPE'}, // just the action to be dispatched before fetching, if any
    then = { a list of status codes with a function that should be executed in this case, plus an optional 'other' case that is called as a default
        '200': (response) => response.json().then(do sth),
        'other': () => do sth
    }
*/
export const fetchJsonActionCreator = ({
  argConfig,
  preRequest,
  lockAction,
  then,
  mock,
  withAccessToken = true,
}: {
  argConfig: Record<string, ArgType>;
  preRequest: PreRequestParams;
  lockAction?: Action;
  then: ThenConfiguration;
  mock: any;
  withAccessToken?: boolean;
}) => {
  const actionFunc = function (
    params: Record<string, any> = {},
    opts?: FetchJsonActionCreatorOptions,
  ): any {
    //no network, stop right there
    if (!window.navigator.onLine) return () => Promise.resolve({ ok: false });

    const {
      paramValues,
      lockActionArgs,
      headersArgs,
      bodyContent,
      queryStringContent,
      thenCustomArg,
    } = structureDataForFetch(argConfig, params);

    let init = { ...preRequest.init };
    // const headers = prepareHeadersForFetch(init.headers, headersArgs);
    const headers = { ...init.headers, ...headersArgs };

    //put together the url
    const url = prepareUrlForFetch(preRequest.urlConstructor, argConfig, paramValues);

    //put together the body and assamble the request
    const request: RequestData = {
      url,
      method: init.method,
      headers,
      body: bodyContent,
      queryString: queryStringContent,
    };

    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_INSIGHTS_KEY) {
      appInsights?.trackTrace(
        { message: 'External call prepared: ' + request.url },
        {
          requestMethod: request.method,
          body: JSON.stringify(bodyContent),
          paramValues: JSON.stringify(paramValues),
        }
      );
    }

    return async (dispatch: Dispatch) => {
      const responseFunc = getFetchResponseFunc(dispatch, then, thenCustomArg, request);

      //if we already have the same call in progress, we plug into it (this is especially useful when requesting a refreshed access token, as the refresh token is only valid once)
      const requestKey = keyFromRequest(request);
      if (callsInProgress.has(requestKey)) {
        return callsInProgress.get(requestKey);
      } else if (lockAction) {
        dispatch({ ...lockAction, ...lockActionArgs });
      }

      if (mock) {
        let mockData = mock;
        if (typeof mock === 'function') {
          mockData = mock(params);
        }

        const mockedResponse: CustomResponse = {
          ok: mockData.ok,
          responseStatus: mockData.status,
          cached: false,
          responseData: await mockData.json(),
        };

        await responseFunc(mockedResponse);
        return mockedResponse;
      }

      let retrieveResponseNew: CustomResponse | undefined;
      try {
        retrieveResponseNew = await retrieveResponseFromCache(request);
        if (!retrieveResponseNew) {
          const httpCallPromise = performHttpCall({
            request,
            withAccessToken: withAccessToken ?? opts?.withAccessToken,
            useErrorBoundary: opts?.useErrorBoundary,
          });
          callsInProgress.set(requestKey, httpCallPromise);

          retrieveResponseNew = await httpCallPromise;
        }
        await responseFunc(retrieveResponseNew);
      } catch (error) {
        await cacheInvalidateSameUrls(request);
        console.error('Fetch error', error);
        retrieveResponseNew = {
          ok: false,
          cached: false,
          responseStatus: 0,
          responseData: error,
        };
      } finally {
        callsInProgress.delete(requestKey);
      }

      return retrieveResponseNew;
    };
  };

  return actionFunc;
};

const structureDataForFetch = (argConfig: Record<string, ArgType>, params: Record<string, any>) => {
  //loop through argConfig and:
  //create an object of paramValues from the argConfig, with default values, and the parameters passed to this function
  //collect and structure the data to pass on later
  const paramValues: Record<string, any> = {};
  const lockActionArgs: Record<string, any> = {};
  const headersArgs: Record<string, any> = {};
  const bodyContent: Record<string, any> = {};
  const queryStringContent: Record<string, any> = {};
  const thenCustomArg: Record<string, any> = {};

  for (const keyName of Object.keys(argConfig)) {
    const applicableName = argConfig[keyName].rename || keyName; //rename if relevant

    let val = params[keyName];
    if (val === undefined) {
      let fDefaultValue = argConfig[keyName].defaultValue;
      if (fDefaultValue !== undefined) {
        val = fDefaultValue(params);
      }
    }

    if (val !== undefined) {
      paramValues[applicableName] = val;

      if (argConfig[keyName].toThen) thenCustomArg[applicableName] = val;

      if (argConfig[keyName].toBody) bodyContent[applicableName] = val;

      if (argConfig[keyName].toLockAction) lockActionArgs[applicableName] = val;

      if (argConfig[keyName].toHeaders) headersArgs[applicableName] = val;

      if (argConfig[keyName].toQueryString) queryStringContent[applicableName] = val;
    }
  }

  return {
    paramValues,
    lockActionArgs,
    headersArgs,
    bodyContent,
    queryStringContent,
    thenCustomArg,
  };
};

const prepareUrlForFetch = (
  urlConstructor: (params: Record<string, any>) => string,
  argConfig: Record<string, ArgType>,
  paramValues: Record<string, any>
) => {
  let url = urlConstructor(paramValues);

  Object.keys(argConfig).forEach((keyName) => {
    if (argConfig[keyName].toRoute) {
      url = url.replace(':' + keyName, paramValues[keyName]);
    }
  });

  return url;
};

const getFetchResponseFunc =
  (
    dispatch: Dispatch,
    then: ThenConfiguration,
    thenCustomArg: Record<string, any>,
    request: RequestData
  ) =>
  async (response: CustomResponse) => {
    if (!response) return;

    if (response.responseStatus in then || 'other' in then) {
      if (!response.ok) {
        await removeRequestFromCache(request);
      }

      if (!response.cached && response.ok) {
        await storeResponseInCache(request, response);
      }

      if (response.responseStatus in then) {
        await then[response.responseStatus](response.responseData, dispatch, thenCustomArg);
      } else if (then['other']) {
        await then['other'](dispatch, thenCustomArg, response.responseData);
      }

      if (response.responseStatus >= 400 && process.env.REACT_APP_INSIGHTS_KEY) {
        appInsights?.trackException(
          {
            id: uuidv4(),
            severityLevel: SeverityLevel.Error,
            properties: {
              message: 'External call failed',
            },
          },
          {
            requestMethod: request.method,
            responseUrl: request.url,
            responseQueryString: `${JSON.stringify(request?.queryString)}`,
            responseStatus: response.responseStatus,
          }
        );
      } else if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_INSIGHTS_KEY) {
        appInsights?.trackTrace(
          { message: 'External call succeded: ' + request.url },
          {
            requestMethod: request.method,
            responseStatus: response.responseStatus,
          }
        );
      }
    } else {
      await removeRequestFromCache(request);
      console.log('Unhandled fetch response code. Response: '); //dev
      console.log(response); //dev
    }
  };

const performHttpCall = async ({
  request,
  withAccessToken,
  useErrorBoundary,
}: {
  request: RequestData;
  withAccessToken: boolean;
  useErrorBoundary?: boolean;
}): Promise<CustomResponse> => {
  try {
    const response = await performAxiosCall({
      axios: axiosClient,
      request,
      withoutAccessToken: !withAccessToken,
      useErrorBoundary,
    });
    return {
      ok: response.status < 300,
      cached: false,
      responseStatus: response.status,
      responseData: response.data,
    };
  } catch (err) {
    await cacheInvalidateSameUrls(request);

    const axiosError = err as AxiosError;
    return {
      ok: false,
      cached: false,
      responseStatus: axiosError.response?.status ?? 0,
      responseData: axiosError.response?.data || {},
    };
  }
};

const performAxiosCall = ({
  axios,
  request,
  withoutAccessToken,
  useErrorBoundary,
}: {
  axios: AxiosInstance;
  request: RequestData;
  withoutAccessToken: boolean;
  useErrorBoundary?: boolean;
}) => {
  const config: AxiosRequestConfig = {
    headers: request.headers,
    params: request.queryString,
    opts: { useErrorBoundary, withoutAccessToken},
  };

  if (request.method === 'GET') {
    return axios.get(request.url, config);
  }

  if (request.method === 'POST') {
    return axios.post(request.url, request.body, config);
  }

  if (request.method === 'PUT') {
    return axios.put(request.url, request.body, config);
  }

  if (request.method === 'DELETE') {
    const data = isEmpty(request.body) ? {} : { data: request.body };
    return axios.delete(request.url, { ...config, ...data });
  }

  throw new Error(`Method ${request.method} not supported`);
};
