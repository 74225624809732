import { Route, Switch } from 'react-router-dom';

import FaqCategories from '../components/FaqCategories';
import FaqCategoryDetails from '../components/FaqCategoryDetails';
import { pagePaths } from '../config';

const Routes = () => {
  return (
    <Switch>
      <Route exact path={pagePaths.Module} children={<FaqCategories />} />
      <Route exact path={pagePaths.FaqCategory} children={<FaqCategoryDetails />} />
    </Switch>
  );
};

export default Routes;
