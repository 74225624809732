import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTable } from '../actions';

type UseResetTableAfterOneHourProps = {
  tableQRScanTime?: number | null;
};

const useResetTableAfterOneHour = ({ tableQRScanTime }: UseResetTableAfterOneHourProps) => {
  const dispatch = useDispatch();

  useEffect(
    function resetTableAfterOneHour() {
      const scannedMoreThanHourAgo =
        tableQRScanTime && moment().diff(moment(tableQRScanTime), 'hours') > 1;
      if (scannedMoreThanHourAgo) {
        dispatch(setTable());
      }
    },
    [tableQRScanTime, dispatch]
  );
};

export default useResetTableAfterOneHour;
