import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { pagePaths as corePagePaths } from '../../../Core/config';
import { cleanCart, retrieveOrderSession } from '../../actions';
import { pagePaths } from '../../config';
import { Cart } from '../../types/orderState.types';
import { useSwitchSite } from '../useSiteSwitch/useSwitchSite';

import useSite from '@/modules/Core/hooks/useSite';

export const useRetrieveKioskSession = ({
  siteIdFromKiosk,
  trackingId,
  cart,
}: {
  siteIdFromKiosk: string;
  trackingId: string | undefined;
  cart: Cart | undefined;
}) => {
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const { switchSite } = useSwitchSite();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isRetrievingOrder, setIsRetrievingOrder] = useState<boolean>(false);

  useEffect(() => {
    const retrieveKioskSession = async () => {
      if (!!trackingId) {
        setIsRetrievingOrder(true);

        try {
          if (!!cart) {
            await dispatch(cleanCart());
          }
          if (site.id !== siteIdFromKiosk) {
            switchSite(siteIdFromKiosk);
          }

          await dispatch(retrieveOrderSession({ trackingId }));
          history.push(corePagePaths.Home); // hack to have Homepage as goBack
          history.push(pagePaths.Cart);
        } finally {
          setIsRetrievingOrder(false);
        }
      }
    };

    retrieveKioskSession();
  }, [cart, dispatch, history, site.id, siteIdFromKiosk, switchSite, trackingId]);

  return { isRetrievingOrder };
};
