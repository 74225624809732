const labels = {
  All: { 'Ref: Page title': 'Tedarikçiler' },
  SuppliersList: {
    'Ref: Supplier details': 'Ayrıntılara bakınız',
    'Missing supplier list': 'Herhangi bir tedarikçi bulamadık.',
    'Try again later': 'Daha sonra tekrar deneyin',
  },
  SupplierDetails: {
    'Address label': 'Adres:',
    'Website label': 'İnternet sitesi:',
    'Details label': 'Detaylar',
  },
};
export default labels;
