const labels = {
  All: {
    'Ref: Assembled': 'Sestaven',
    'Ref: Submitted': 'Předloženo',
    'Ref: Fulfilled': 'Splnil',
    'Ref: Completed': 'Dokončeno',
    'Ref: Paid': 'Zaplaceno',
    'Ref: Approved': 'Schválený',
    'Ref: Ready For Pickup': 'Připravený k vyzvednutí',
    'Ref: Delivered': 'Doručené',
    'Ref: Received': 'Přijat',
    'Ref: Rejected': 'Odmítnuto',
    'Ref: Payment Error': 'Chyba platby',
    'Ref: Pending Payment': 'Čekající platba',
    'Ref: Preparing': 'Připravuje se',
    'Ref: Refunded': 'Vráceno',
    'Ref: Cancelled By User': 'Zrušeno uživatelem',
    'Ref: Refund Requested': 'Požadováno vrácení peněz',
    'Ref: Refund Failed': 'Vrácení peněz selhalo',
    'Ref: Payment Processing': 'Platba je v procesu zpracování',
    'Ref: Not Paid': 'Neplaceno',
    'Ref: Total:': 'Celkový:',
    'Ref: Pickup': 'Vyzvednout',
    'Ref: Eat-in': 'Jíst',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Fulfillment Type': 'Typ plnění',
    'Ref: Delivery': 'dodávka',
    'Ref: You have no future orders': 'Nemáte žádné budoucí objednávky',
    'Ref: You have no past orders': 'Nemáte žádné minulé objednávky',
    'Ref: You have no orders': 'Nemáte žádné objednávky',
    'Ref: All orders': 'Všechny objednávky',
    'Ref: Not past orders': 'Přicházející rozkazy',
    'Ref: Past orders': 'Minulé objednávky',
    'Ref: Vegan': 'Veganský',
    'Ref: Vegeterian': 'Vegetariánský',
    'Ref: See item': 'Viz položka',
    'Ref: Activated': 'Aktivováno',
    'Ref: No suggestions': 'Nebyly nalezeny žádné návrhy',
    'Ref: Get your receipt': 'Získejte potvrzení',
    'Ref: Print': 'Vytiskněte potvrzení',
    'Ref: Email': 'Obdržíte potvrzení e-mailem',
    'Ref: Receipt Modal message': 'Jak byste chtěli získat potvrzení?',
    'Ref: Confirm': 'Potvrdit',
    'Ref: Your email address': 'Vaše emailová adresa',
    Suggestion: 'Návrh',
    'Ref: Loyalty Reward': 'Věrnostní odměna',
    'Ref: Equation for stamps per item': '1 nákup = 1 razítko',
    'Ref: Paid by employer': 'Částka zaplacená vašim zaměstnavatelem',
    'Ref: Paid by user': 'Částka zaplacená vami',
  },
  ProductsList: {
    'Ref: Page title': 'Seznam produktů',
    'Ref: Facility details': 'Podrobnosti zařízení',
    'Ref: Search products list': 'Vyhledávání položek',
    'Ref: filter label: menus': 'Jídelní lístek',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategorie',
    'Ref: all': 'Všechno',
    'Ref: today': 'Dnes',
    'Ref: tomorrow': 'Zítra',
    'Ref: see more': 'Vidět víc',
    'Ref: see less': 'Vidět méně.',
    'Ref: reset': 'Resetovat',
    'Ref: View in cart': 'Zobrazit v košíku',
    'Ref: You have changed location': 'Změnili jste umístění',
    'Ref: You are now at': 'Nyní jste na',
    'Ref: Menu card': 'Karta nabídky',
    'Ref: Table': 'Číslo tabulky',
    'Service unavailable': 'Služba je nedostupná',
    'Ref: Ordering unavailable':
      'Opatrně! Objednávání na tomto webu není v tuto chvíli k dispozici, zkuste to později.',
    'Ref: Filters': 'Filtry (alergeny a další)',
  },
  ProductScanner: {
    'Ref: cart': 'Vozík',
    'Ref: Product Scanned':
      'byl přidán do košíku. Můžete pokračovat ve skenování čárových kódů a přidat je do košíku.',
    'Ref: Scan or adjust': 'byl přidán do košíku. Upravte množství nebo naskenujte jiný produkt.',
    'Ref: Product Not Matched': 'Čárový kód nebyl rozpoznán. Prosím zkuste to znovu',
    'Ref: Scan article code': 'Skenovat čárový kód',
    'Ref: Native error product cannot be added to cart':
      'Položka z různých nabídek nebo denní části nelze přidat do košíku',
    'Ref: product scanner placeholder': 'Naskenujte prosím čárový kód a přidejte jej do košíku',
  },
  Order: { 'Ref: Page title': 'Objednat' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menu',
    'Ref: today': 'Dnes',
    'Ref: tomorrow': 'Zítra',
    'Ref: Search products list': 'Vyhledávání položek',
    'Ref: You have changed location': 'Změnili jste umístění',
    'Ref: You are now at': 'Nyní jste na',
  },
  ProductDetails: {
    'Ref: Page title': 'Detaily produktu',
    'Ref: Serving Size': 'Velikost porce',
    'Ref: Nutrition': 'Nutriční informace',
    'Ref: Nutrition adults': 'Dospělí potřebují kolem roku 2000 kcal denně',
    'Ref: Nutrition Calories': 'Kalorie',
    'Ref: Nutrition Calcium': 'Vápník',
    'Ref: Nutrition Fat': 'Tlustý',
    'Ref: Nutrition Saturated Fat': 'Nasycený tuk',
    'Ref: Nutrition Carbohydrates': 'Sacharidy',
    'Ref: Nutrition Sugar': 'Cukr',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodík',
    'Ref: Nutrition cholesterol': 'Cholesterol.',
    'Ref: Nutrition dietaryFiber': 'Vláknina',
    'Ref: Nutrition iron': 'Žehlička',
    'Ref: Nutrition potassium': 'Draslík',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamín C',
    'Ref: Additives': 'Aditiva',
    'Ref: Emission': 'emise CO2',
    'Ref: This product generates': 'Tento výrobek generuje',
    'Ref: of CO2': 'CO2.',
    'Ref: emissions, which equals a': 'emise, které se rovná',
    'Ref: car trip': 'výlet do auta',
    'Ref: Ingredients': 'Receptová ingredience',
    'Ref: Allergens': 'Alergens',
    'Ref: Always there': 'Vždy tam',
    'Ref: Allergens selected': 'Alergeny vybrané na filtru:',
    'Ref: minimum required': 'minimální požadované',
    'Ref: you have to add at least': 'Musíte přidat alespoň',
    'Ref: ingredients': 'ingredience',
    'Ref: Ingredient': 'přísada',
    'Ref: Free': 'Volný, uvolnit',
    'Ref: Based on customization': 'Na základě vašeho přizpůsobení',
    'Ref: Additives eggs': 'vejce',
    'Ref: Additives fish': 'Ryba',
    'Ref: Additives milk': 'mléko',
    'Ref: Additives nuts': 'ořechy',
    'Ref: Additives wheat': 'pšenice',
    'Ref: Additives peanuts': 'arašídy',
    'Ref: Additives soy beans': 'sójové fazole',
    'Ref: Additives sesame seeds': 'sezamová semínka',
    'Ref: Review': 'Posouzení',
    'Ref: Per portion': 'Na část',
    'Ref: Per 100g': 'Na 100 g',
    'Ref: Allergen selected': 'Alergen vybraný na vyhledávacím filtru',
    'Ref: Fitbit Log': 'Přihlaste se k Fitbit',
    'Ref: age verification required on delivery': 'Při dodání je nutné ověření věku',
    'Ref: alcohol content notification':
      'Umístěním a objednáním na jednu z těchto položek prohlašujete, že jste ve věku 18 a více let. Zneužívání alkoholu je pro vaše zdraví nebezpečné.',
    'Ref: default': 'Výchozí',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} vydělal, pokud si objednáte tento produkt',
    'Ref: Loyalty Card Description':
      '{stamp_form} bude přidán k následující loajalitě {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program věrnostních známek umožňuje shromažďovat známky pro odměny.',
    'Ref: Loyalty Modal Description':
      'Když si koupíte <b> {product_name}, bude 1 razítko </b> přidáno do <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'a',
    'Ref: Stamp': 'Razítko',
    'Ref: Stamps': 'Známky',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programy',
    'Ref: Loyalty Stamps': 'Věrnostní známky',
    'Ref: See Loyalty Stamps': 'Viz věrnostní známky',
    Favorite: 'Oblíbený',
    'Ref: In favorites': 'Produkt je již v seznamu oblíbených. Kliknutím jej odstraníte.',
    'Ref: Not in favorites': 'Produkt ještě není v seznamu oblíbených. Kliknutím přidejte jej.',
    'Ref: Added to favorites': 'Produkt byl přidán do seznamu oblíbených.',
    'Ref: Removed from favorites': 'Produkt byl odstraněn ze seznamu oblíbených.',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Změna v košíku',
    'Ref: Popup header for customizable item qty update': 'Tato položka je přizpůsobitelná',
    'Ref: add new version': 'Přidejte novou verzi',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Aktualizujte množství přizpůsobitelné položky přímo z košíku.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Aktualizujte množství přizpůsobitelné položky přímo z košíku nebo přidejte novou verzi.',
    'Ref: another context body':
      'Chcete -li přidat položky z tohoto webu, zařízení, momentu/nabídky nebo datum, musíte vyčistit košík',
    'Ref: cancel': 'zrušení',
    'Ref: add to cart': 'Přidat do košíku',
    'Ref: view in cart': 'Vozík',
    'Ref: Products in cart': 'Číslo {product_name} v vozíku: {product_quantity}',
    'Ref: Customize': 'Přizpůsobit',
    'Ref: Add': 'Přidat',
    'Ref: Free': 'Volný, uvolnit',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'zrušení',
    'Ref: notice': 'Oznámení',
    'Ref: clear cart': 'Jasný vozík',
    'Ref: Cart addition error message':
      'V současné době máte položky v košíku z {from_current}. Chcete -li přidat položky z {from_selected}, musíte vyčistit košík',
  },
  Cart: {
    'Ref: Page title': 'Vozík',
    'Ref: The cart is empty': 'Košík je prázdný',
    'Ref: Clear cart': 'Jasný vozík',
    'Ref: Continue shopping': 'Pokračovat v nákupu',
    'Ref: Start shopping': 'Začněte nakupovat',
    'Ref: Today': 'Dnes',
    'Ref: Tomorrow': 'Zítra',
    'Ref: Tax:': 'Daň:',
    'Ref: Promotion:': 'Povýšení:',
    'Ref: Total without promotion:': 'Celkem bez propagace:',
    'Ref: Delivery:': 'Dodávka:',
    'Ref: Pickup informations': 'Informace o vyzvednutí',
    'Ref: Location': 'Umístění',
    'Ref: Time': 'Čas',
    'Ref: Phone': 'Vaše telefonní číslo',
    'Ref: Instructions': 'Instrukce',
    'Ref: Please select': 'Prosím vyberte',
    'Ref: Enter special requests': 'Zde zadejte speciální požadavky',
    'Ref: notice': 'Oznámení',
    'Ref: Product is no more available':
      'Omlouvám se, ale zdá se, že žádný produkt už není k dispozici. Zkontrolujte prosím svůj vozík.',
    'Ref: ok': 'OK',
    'Ref: Validate': 'Ověřit moji objednávku',
    'Ref: Pay now': 'Zaplať teď',
    'Ref: Charge to my department': 'Nabijte mému oddělení',
    'Ref: Pay on pickup': 'Zaplaťte za tyto položky na vyzvednutí',
    'Ref: Accept terms of sale prefix': 'přijímám',
    'Ref: Accept terms of sale': 'Tyto podmínky prodeje',
    'Ref: Continue': 'Pokračovat',
    'Ref: Please login or sign up to place an order':
      'Přihlaste se nebo se zaregistrujte a zadejte objednávku',
    'Ref: Accept terms of sale suffix': ', a já jsem je četl a rozuměl.',
    'Ref: Payment modality': 'Způsob platby',
    'Ref: Other information': 'Jiná informace',
    'Ref: Eat-in': 'Jíst',
    'Ref: Pickup': 'Vyzvednout',
    'Ref: Table': 'Číslo tabulky',
    'Ref: Required fields': 'Požadovaná pole',
    'Service unavailable': 'Služba je nedostupná',
    'Ref: Cannot order':
      'Je nám líto, v tuto chvíli nemůžete na tomto webu udělat objednávku. Pro více informací o otevíracích hodinách a dostupných službách se prosím obraťte na restauraci.',
    'Ref: Order Unavailable':
      'Objednávka pro vybrané datum, typ plnění a umístění již nejsou k dispozici!',
    'Ref: No Availability': 'Už žádná dostupnost',
    'Ref: Continue on your device': 'Pokračujte ve svém zařízení',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}, pokud dokončíte objednávku',
    'Ref: Loyalty Card Description':
      '{stamp_form} bude přidán k následující loajalitě {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program věrnostních známek umožňuje shromažďovat známky pro odměny.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> vydělal, pokud dokončíte objednávku:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> přidán pro <b> {scheme_names} </b>',
    'Ref: Stamp': 'Razítko',
    'Ref: Stamps': 'Známky',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programy',
    'Ref: Loyalty Stamps': 'Věrnostní známky',
    'Ref: See Loyalty Stamps': 'Viz věrnostní známky',
    'Ref: Loyalty Info': 'Věrnostní informace',
    'Ref: Email address': 'Emailová adresa',
    'Ref: and': 'a',
    'Ref: Order minimum amount': 'Omlouvám se, minimální částka plateb je {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Pokračujte ve svém zařízení',
    'Ref: Scan QR to continue': 'Chcete -li pokračovat po cestě na zařízení, prohledejte kód QR.',
  },
  OrderHistory: { 'Ref: Orders': 'Objednávky', 'Ref: Page Title': 'Mé objednávky' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Jednotková cena',
    'Ref: Download': 'Stáhněte si potvrzení',
    'Ref: Download error': 'Došlo k chybě stahování. Zkuste si potvrdit později',
    'Ref: Order details title': 'Podrobnosti o objednávce',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Děkuji! Vaše objednávka je nyní potvrzena',
    'Ref: Text for pickup':
      'Jeho status můžete sledovat prostřednictvím historie objednávky. Pokud jsou vaše oznámení zapnutá, aktualizujeme vás, až bude připravena na vyzvednutí.',
    'Ref: Success Order Title': 'Děkujeme za Vaši objednávku',
    'Ref: Success Order Description': 'Zaplaťte prosím při pokladně ověření vaší objednávky.',
    'Ref: Success Order ID': 'ID objednávka',
    'Ref: Success Order Action': 'Nová objednávka',
    'Ref: Text for dinein':
      'Vaše objednávka by měla být brzy doručena. Jen se zeptejte některého z našich přátelských zaměstnanců o vaší objednávce jídla.',
    'Ref: Text for scan&go': 'Vaše objednávka je k dispozici v historii vaší objednávky',
    'Ref: Text for delivery':
      'Jeho stav můžete sledovat v historii objednávky. Pokud jsou vaše oznámení povolena, budeme vás informovat, až bude vaše objednávka připravena.',
    'See details': 'Zobrazit podrobnosti',
    'Go to home': 'Jít domů',
  },
  FailOrder: {
    'Go to home': 'Jít domů',
    'Ref: Kiosk Fail Title': 'Jejda! Něco se pokazilo!',
    'Ref: Kiosk Fail Description':
      'Vaše objednávka bohužel nebyla úspěšná. Zkuste to prosím znovu nebo požádejte zaměstnance, aby vám pomohl.',
    'Ref: Kiosk Fail Back': 'Zpět na předchozí stránku',
    'Ref: Kiosk Fail Action': 'Vyčistěte relaci a objednejte si znovu',
    'Ref: Error in cart validation':
      'Během vaší objednávky došlo k chybě. Chcete -li problém vyřešit, kontaktujte zařízení.',
    'Facility contact': 'Kontakt zařízení',
    'Ref: Ordering failed': 'Objednávání selhalo',
    'Ref: Order confirmed': 'Objednávka potvrzena',
  },
  TableCheckIn: {
    'Welcome at': 'Vítejte na',
    Table: 'Stůl:',
    'Ref: list title': 'Nedávné objednávky pro dnešek',
    'See the menu': 'Podívejte se na nabídku',
    'Table Check-in': 'Check-in tabulky',
    'Ref #': 'Ref #',
    Checked: 'Kontrolovány',
    'Check in': 'Přihlásit se',
    'See details': 'Zobrazit podrobnosti',
    'Ref: You have changed location': 'Změnili jste umístění',
    'Ref: You are now at': 'Nyní jste na',
    'Order number': 'Číslo objednávky',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Aplikována propagace',
    'Ref: Promotion applied description': 'Využijete propagaci z košíku.',
    'Ref: Promotion deleted title': 'Propagace smazána',
    'Ref: Promotion deleted description':
      'Propagaci již nemáte prospěch, protože již nevyplňujete podmínky nebo vypršela propagace.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Scan & Go', 'Ref: View only': 'Zobrazit pouze' },
  ProductTile: {
    'Ref: Allergens': 'Alergeny',
    'Ref: Presence of allergen': 'Přítomnost alergenu',
    'Ref: Scan': 'Skenovat',
    'Ref: From': 'Z',
    'Ref: Already in product favorite list. Click to remove it.':
      'Již v seznamu oblíbených produktů. Kliknutím jej odstraníte.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'V současné době není přidáno do seznamu oblíbených produktů. Kliknutím přidejte jej.',
    'Ref: Free': 'Volný, uvolnit',
  },
  OrderSummary: {
    'Ref: item': 'položka',
    'Ref: items': 'položky',
    'Ref: Clear': 'Průhledná',
    'Ref: Cart': 'Vozík',
    'Ref: Subtotal': 'Subtotál',
    'Ref: Total': 'Celkový',
    'Ref: Tax': 'Daň',
    'Ref: Promotion': 'povýšení',
    'Ref: Total without promotion': 'Celkem bez propagace',
    'Ref: Number of product in cart': 'Počet produktu v košíku',
    'Ref: Delivery': 'dodávka',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegan', 'Ref: Vegeterian': 'Vegetariánský' },
  OrderInfoCard: {
    'Ref: Header': 'Objednáno na',
    'Ref: from': 'z',
    'Ref: location': 'Umístění',
    'Ref: Phone': 'Vaše telefonní číslo',
    'Ref: Instructions': 'Instrukce',
    'Ref: Order type': 'Typ objednávky',
    'Ref: Pickup': 'Vyzvednout',
    'Ref: Eat-in': 'Jíst',
    'Ref: Scan&Go': 'Scan & Go',
  },
  ReorderWidget: {
    'Ref: No items': 'Zatím žádné objednávky. Kupuj teď!',
    'Ref: Title': 'Objednat znovu',
  },
  MenusWidget: {
    'Ref: no menu': 'Žádné menu pro tuto chvíli',
    'Ref: Modal title': 'Nabídka pro den',
  },
  PromotionInfo: { 'Ref: Discounts available': 'K dispozici jsou slevy' },
  PromotionInfoItem: { 'Ref: Activate': 'aktivovat' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Vaše objednávky',
    'Ref: Widget Title': 'Mé objednávky',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Návrh na den',
    'Ref: Any menu': 'Jakékoli menu',
    Other: 'Jiný',
    'Ref: Suggestions calendar button': 'Kalendář návrhů',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Velikost porce',
    'Ref: Nutrition': 'Nutriční informace',
    'Ref: Nutrition adults': 'Dospělí potřebují kolem roku 2000 kcal denně',
    'Ref: Nutrition Calories': 'Kalorie',
    'Ref: Nutrition Calcium': 'Vápník',
    'Ref: Nutrition Fat': 'Tlustý',
    'Ref: Nutrition Saturated Fat': 'Nasycený tuk',
    'Ref: Nutrition Carbohydrates': 'Sacharidy',
    'Ref: Nutrition Sugar': 'Cukr',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodík',
    'Ref: Nutrition cholesterol': 'Cholesterol.',
    'Ref: Nutrition dietaryFiber': 'Vláknina',
    'Ref: Nutrition iron': 'Žehlička',
    'Ref: Nutrition potassium': 'Draslík',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamín C',
    'Ref: Ingredients': 'Receptová ingredience',
    'Ref: Allergens': 'Alergens',
    'Ref: Always there': 'Vždy tam',
    'Ref: Allergens selected': 'Alergeny vybrané na filtru:',
    'Ref: minimum required': 'minimální požadované',
    'Ref: ingredients': 'ingredience',
    'Ref: Ingredient': 'přísada',
    'Ref: Free': 'Volný, uvolnit',
    'Ref: Based on customization': 'Na základě vašeho přizpůsobení',
    'Ref: Additives eggs': 'vejce',
    'Ref: Additives fish': 'Ryba',
    'Ref: Additives milk': 'mléko',
    'Ref: Additives nuts': 'ořechy',
    'Ref: Additives wheat': 'pšenice',
    'Ref: Additives peanuts': 'arašídy',
    'Ref: Additives soy beans': 'sójové fazole',
    'Ref: Additives sesame seeds': 'sezamová semínka',
    'Ref: Review': 'Posouzení',
    'Ref: Per portion': 'Na část',
    'Ref: Per 100g': 'Na 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} zdarma',
    'Ref: Number of free items': 'Počet bezplatných položek: {number_of_free_items}',
    'Ref: Allergen selected': 'Alergen vybraný na vyhledávacím filtru',
    'Ref: Nutritional info': 'Nutriční informace',
    'Ref: Info': 'Informace',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Zprávy a články' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Vyberte si svou práci',
    'Ref: Validate': 'Ověřit',
    'Ref: Pricing Tiers dialog message': 'Byli jste uznáni s vaší e -mailovou adresou',
    'Ref: Please select your job in the company': 'Vyberte svou práci ve společnosti',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Vyzvednout',
    'Ref: Eat-in': 'Jíst',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Delivery': 'dodávka',
    'Ref: Total': 'Celkový',
    'Ref: How was facility name service': 'Jaká byla služba {facility_name}?',
    'Ref: How do you rate the service': 'Jak hodnotíte službu?',
  },
};
export default labels;
