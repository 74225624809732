import { defaultProductPortion } from '../__mocks/mock';

import {
  hasPortionProvidingLoyaltyStamps,
  hasPortionRedeemableAsRewardInLoyaltyScheme,
  buildLoyaltyRewardChip,
  buildLoyaltyPointsForOrderingChip,
} from './productTile.helpers';

import { defaultLoyaltySchemes } from '@/modules/LoyaltyStamps/mocks/mock';

describe('hasPortionProvidingLoyaltyStamps', () => {
  describe('when there is not a portion providing stamps in menuItem', () => {
    const result = hasPortionProvidingLoyaltyStamps({
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
          foodItemId: 28508,
          uomId: 30551,
        },
      ],
      menuId: 114,
      loyaltySchemes: defaultLoyaltySchemes,
    });

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when there is a portion providing stamps in menuItem but from menu which is not listed in loyaltySchemes', () => {
    const result = hasPortionProvidingLoyaltyStamps({
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
          foodItemId: 28508,
          uomId: 30551,
        },
      ],
      menuId: 3330,
      loyaltySchemes: defaultLoyaltySchemes,
    });

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when there is a portion providing stamps in menuItem from menu listed in loyaltySchemes', () => {
    const result = hasPortionProvidingLoyaltyStamps({
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
          uomId: 30551,
          foodItemId: 28508,
        },
      ],
      menuId: 3329,
      loyaltySchemes: defaultLoyaltySchemes,
    });

    it('should return true', () => {
      expect(result).toBeTruthy();
    });
  });
});

describe('hasPortionRedeemableAsRewardInLoyaltyScheme', () => {
  describe('when there is not redeemable portion in menuItem', () => {
    const result = hasPortionRedeemableAsRewardInLoyaltyScheme({
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
          foodItemId: 28508,
          uomId: 30551,
        },
      ],
      menuId: 114,
      loyaltySchemes: defaultLoyaltySchemes,
    });

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when there is a redeemable portion in menuItem but from menu which is not listed in loyaltySchemes', () => {
    const result = hasPortionRedeemableAsRewardInLoyaltyScheme({
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
          uomId: 30635,
          foodItemId: 28586,
        },
      ],
      menuId: 3330,
      loyaltySchemes: defaultLoyaltySchemes,
    });

    it('should return false', () => {
      expect(result).toBeFalsy();
    });
  });

  describe('when there is a portion providing stamps in menuItem from menu listed in loyaltySchemes', () => {
    const result = hasPortionRedeemableAsRewardInLoyaltyScheme({
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
          uomId: 30635,
          foodItemId: 28586,
        },
      ],
      menuId: 3329,
      loyaltySchemes: defaultLoyaltySchemes,
    });

    it('should return true', () => {
      expect(result).toBeTruthy();
    });
  });
});

describe('buildLoyaltyRewardChip', () => {
  describe('when there is showLoyaltyRewardChip set to true', () => {
    const result = buildLoyaltyRewardChip({
      showLoyaltyRewardChip: true,
      label: 'text label',
      ariaLabel: 'aria label text',
    });

    it('should return Tag', () => {
      expect(result?.name).toBe('text label');
      expect(result?.icon).toBeTruthy();
    });
  });

  describe('when there is showLoyaltyRewardChip set to false', () => {
    const result = buildLoyaltyRewardChip({
      showLoyaltyRewardChip: false,
      label: 'text label',
      ariaLabel: 'aria label text',
    });

    it('should return Tag', () => {
      expect(result?.icon).toBeFalsy();
    });
  });
});

describe('buildLoyaltyPointsForOrderingChip', () => {
  describe('when there is showLoyaltyPointsForOrderingChip set to true', () => {
    const result = buildLoyaltyPointsForOrderingChip({
      showLoyaltyPointsForOrderingChip: true,
      label: 'text label',
    });

    it('should return Tag', () => {
      expect(result?.name).toBe('text label');
      expect(result?.icon).toBeFalsy();
    });
  });

  describe('when there is showLoyaltyPointsForOrderingChip set to false', () => {
    const result = buildLoyaltyPointsForOrderingChip({
      showLoyaltyPointsForOrderingChip: false,
      label: 'text label',
    });

    it('should return Tag', () => {
      expect(result).toBeFalsy();
    });
  });
});
