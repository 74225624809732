import classNames from 'classnames';
import momentjs from 'moment';

import { Restaurant } from '../../../../assets/icons';
import { TileSkeleton, TILE_VARIANT } from '../../../../components/molecules/Tile';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder';
import { DATE_FORMAT } from '../../../../constants';
import ProductTile from '../../containers/ProductTile';
import { mapSuggestionItemToProductItem } from '../ProductsList/productList.helper';
import SuggestionSlider from '../SuggestionSlider/SuggestionSlider';

import { SuggestionContainerProps } from './SuggestionContainer.types';

import styles from './SuggestionContainer.module.css';

const SuggestionContainer = ({
  id,
  label,
  isLoading,
  suggestions,
  date,
  menus,
  highlightAllergens,
  selectedAllergens,
  onScanButtonClick,
  pageType,
  'data-testid': dataTestId,
}: SuggestionContainerProps) => {
  return (
    <SuggestionSlider id={id} data-testid={dataTestId}>
      {isLoading ? (
        <>
          <TileSkeleton
            withoutActions
            withoutChildren={false}
            className={classNames('mb-M')}
            data-testid="skeleton-placeholder"
            key="skeleton1"
          />
          <TileSkeleton
            withoutActions
            withoutChildren={false}
            data-testid="skeleton-placeholder"
            key="skeleton2"
          />
        </>
      ) : suggestions.length > 0 ? (
        suggestions.map((item) => {
          const productItem = mapSuggestionItemToProductItem(
            item,
            highlightAllergens,
            selectedAllergens
          );

          const props = {
            ...productItem,
            date: momentjs(date).format(DATE_FORMAT),
            menu: menus?.find((menu) => menu.id === item.menuId),
            variant: TILE_VARIANT.STANDARD,
            oneLine: false,
            canAddToFavorite: true,
          };

          return (
            <div
              key={props.id}
              className={styles.containerWithPadding}
              data-testid={`suggestion-product-tile-container-${props.id}`}
            >
              <ProductTile
                key={props.id}
                isSuggestion={true}
                pageType={pageType}
                {...props}
                onScanButtonClick={() => onScanButtonClick(item)}
              />
            </div>
          );
        })
      ) : (
        <WidgetPlaceholder icon={<Restaurant />} title={label('Ref: No suggestions')} />
      )}
    </SuggestionSlider>
  );
};

export default SuggestionContainer;
