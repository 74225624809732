import classNames from 'classnames';
import { useState } from 'react';

import RoundProgressBar from '../../../../components/atoms/RoundProgressBar/RoundProgressBar';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Widget from '../../../../components/organisms/Widget';
import { useGetStampsQuery } from '../../api/api';
import StampActionButton from '../../components/StampActionButton';
import { defaultValues, pagePaths } from '../../config';
import { getStampsContent } from '../../helpers/stamps.helper';
import { useStampsTranslation } from '../../hooks/useStampsTranslation';

import { WarningOutlinedIcon } from '@/assets/icons';
import { BackgroundWidgetFreeCoffeeIllustration } from '@/assets/illustrations';
import WidgetPlaceholder from '@/components/molecules/WidgetPlaceholder';
import useSite from '@/modules/Core/hooks/useSite';

import styles from './Widget.module.css';

const StampsWidget = () => {
  const { label } = useStampsTranslation(__filename);
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const {
    data: stampCollection,
    isLoading: isLoadingStamps,
    isError: errorGetStamps,
  } = useGetStampsQuery({ siteId: site.id, useErrorBoundary: false });

  const [redeemTimer, setRedeemTimer] = useState(defaultValues.redeemTimeInSeconds);
  const [isRedeem, setIsRedeem] = useState(false);
  const { isRewardAvailable } = stampCollection || {};

  const progressBarProps = isRedeem
    ? {
        progress: redeemTimer,
        totalSteps: defaultValues.redeemTimeInSeconds,
      }
    : {
        progress: stampCollection?.stampsCollected,
        totalSteps: stampCollection?.stampsToCollectReward,
      };

  const { title, titleSuffix, description } = getStampsContent({
    isRewardAvailable,
    isRedeem,
  });

  return (
    <Widget id="stamps-widget">
      <Widget.Header illustration={<BackgroundWidgetFreeCoffeeIllustration />}>
        <Widget.Title>
          <Title>{label('Ref: Free Coffee widget title')}</Title>
          <Widget.Link to={pagePaths.Module}>{label('Ref: See All')}</Widget.Link>
        </Widget.Title>
      </Widget.Header>
      {errorGetStamps ? (
        <Widget.Body>
          <WidgetPlaceholder
            icon={<WarningOutlinedIcon />}
            title={label('Something went wrong, data not loaded')}
          />
        </Widget.Body>
      ) : (
        <Widget.Body className={classNames(styles.widgetBody)}>
          <>
            <div>
              <Title tag={TITLE_TAG.H6} size={TITLE_SIZE.BODYMBOLD}>
                {label(title) + (titleSuffix ? ' ' + titleSuffix : '')}
              </Title>
              {label(description)}
            </div>
            <RoundProgressBar data-testid="widget-round-progress-bar" {...progressBarProps} />
          </>
        </Widget.Body>
      )}
      <Widget.Footer>
        <StampActionButton
          isRedeem={isRedeem}
          isRewardAvailable={isRewardAvailable ?? false}
          isLoadingStamps={isLoadingStamps}
          setIsRedeem={setIsRedeem}
          setRedeemTimer={setRedeemTimer}
          componentIdToScroll={'stamps-widget'}
        />
      </Widget.Footer>
    </Widget>
  );
};

export default StampsWidget;
