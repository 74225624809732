import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../helpers/tests/renderComponent';
import { defaultSite } from '../../Sites/__mocks/mocks';

import PriceWithDiscount from './PriceWithDiscount';

describe('PriceWithDiscount component', () => {
  afterAll(() => cleanup());

  const languageCode = 'pl-PL';

  describe('no discount price provided', () => {
    renderComponent(PriceWithDiscount, {
      originalPrice: 1000,
      site: defaultSite,
      languageCode,
    });

    it('should display original price', () => {
      const priceDiscount = screen.queryByTestId('price-discount');
      const originalPrice = screen.queryByText('1000,00 USD');

      expect(priceDiscount).toBeFalsy();
      expect(originalPrice).toBeTruthy();
    });
  });

  describe('discount price provided', () => {
    renderComponent(PriceWithDiscount, {
      discountPrice: 800,
      originalPrice: 1000,
      site: defaultSite,
      languageCode,
    });

    it('should display original and discount price', () => {
      const priceDiscount = screen.queryByText('800,00 USD');
      const originalPrice = screen.queryByText('1000,00 USD');

      expect(priceDiscount).toBeTruthy();
      expect(originalPrice).toBeTruthy();
      expect(originalPrice).toHaveClass('priceWithoutDiscounts');
    });
  });
});
