import { screen } from '@testing-library/react';

import { FacilitySearchResults } from './FacilitySearchResult';

import renderComponent from '@/helpers/tests/renderComponent';
import { defaultFacilityDataItem } from '@/modules/Order/__mocks/mock';

const facility1ImageId = 'imageId1';

const facility1 = {
  ...defaultFacilityDataItem,
  id: 'facility1',
  images: [facility1ImageId],
};

const facility2 = {
  ...defaultFacilityDataItem,
  id: 'facility2',
};

const mockGetFacilityImage = jest.fn();
const mockIsLoadingFacilityImages = jest.fn();
jest.mock('@/modules/Order/hooks/useFacilityMenuImage/useFacilityMenuImage', () => ({
  useFacilityMenuImage: () => ({
    getFacilityImage: mockGetFacilityImage,
    isLoading: mockIsLoadingFacilityImages(),
  }),
}));

const mockIsLoadingMappedFacilities = jest.fn();
jest.mock('@/modules/Review/api', () => ({
  useGetMappedFacilitiesQuery: () => ({ data: [], isLoading: mockIsLoadingMappedFacilities() }),
}));

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: jest.fn(),
    logError: jest.fn(),
  }),
}));

describe('FacilitySearchResults component', () => {
  describe('when no facilities', () => {
    renderComponent(FacilitySearchResults, {
      facilities: [],
    });

    it('should render empty content', () => {
      const tiles = screen.queryAllByTestId(/\d+/);
      expect(tiles.length).toBe(0);
    });
  });

  describe('when loading facility images', () => {
    beforeEach(() => {
      mockIsLoadingFacilityImages.mockReturnValue(true);
    });

    renderComponent(FacilitySearchResults, {
      facilities: [],
    });

    it('should render 3 tile skeletons', () => {
      const tileSkeletons = screen.queryAllByTestId(/skeleton-placeholder-\d+/);
      expect(tileSkeletons.length).toBe(3);
    });
  });

  describe('when loading mapped facilities', () => {
    beforeEach(() => {
      mockIsLoadingMappedFacilities.mockReturnValue(true);
    });

    renderComponent(FacilitySearchResults, {
      facilities: [],
    });

    it('should render 3 tile skeletons', () => {
      const tileSkeletons = screen.queryAllByTestId(/skeleton-placeholder-\d+/);
      expect(tileSkeletons.length).toBe(3);
    });
  });

  describe('with facilities', () => {
    beforeEach(() => {
      mockGetFacilityImage.mockReturnValueOnce(undefined);
    });

    renderComponent(FacilitySearchResults, {
      facilities: [facility1, facility2],
      label: jest.fn(),
    });

    it('should render facilities tiles', () => {
      const tiles = screen.queryAllByTestId(/facility-tile-facility\d+$/);
      expect(tiles.length).toBe(2);

      expect(mockGetFacilityImage).toHaveBeenCalledTimes(2);
      expect(mockGetFacilityImage.mock.calls[0][0]).toBe(facility1.id);
      expect(mockGetFacilityImage.mock.calls[1][0]).toBe(facility2.id);
    });
  });
});
