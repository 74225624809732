const labels = {
  All: {
    'Ref: Redeem coffee description': 'Ücretsiz, taze demlenmiş bir fincan kahveniz sizi bekliyor',
    'Ref: Congratulations': 'Tebrikler',
    'Ref: Get free coffee': 'Bedava kahve alın!',
    'Ref: Free coffee description': 'Bardağınızı doldurun, ücretsiz bir kahve için puan kazanın.',
    'Ref: Redeem': 'Kullanın!',
  },
  StampPage: {
    'Ref: Page title': 'Ücretsiz Kahve',
    'Ref: seconds left': 'saniye kaldı',
    'Ref: How it works': 'Nasıl çalışır',
    'Ref: How it works description':
      'Her kahve satın aldığınızda, tarama düğmesine basın ve kasada verilen QR kodunu tarayın.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'QR Kodunu Tarayın',
    'Ref: Collect reward': 'Ödül toplayın',
    'Ref: Reward collected': 'Toplanan ödül',
    'Ref: Invalid QR Code': 'Geçersiz QR Kodu',
    'Ref: Invalid QR Code description': 'QR Kodu tanınmıyor',
    'Ref: Close': 'Kapat',
    'Ref: Redeem button': 'Kurtarmak',
    "Ref: The QR Code isn't recognized": 'QR Kodu tanınmıyor',
    'Ref: Cancel': 'İptal',
    'Ref: Bite': 'Isırık',
    'Ref: Congratulations modal title': 'Tebrikler!',
    'Ref: Confirm redeem description':
      'Bunu kullanmak istediğinizden emin misiniz? Öyleyse, telefonunuzu kasada göstermek ve satın alma işleminizi tamamlamak için sadece 60 saniyeniz var',
    'Ref: Congratulations modal description': 'Bedava kahvenizi şimdi alın!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Bedava kahve!', 'Ref: See All': 'Tümünü Gör' },
};
export default labels;
