const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Ihre Anfrage',
    'Ref: Summary': 'Zusammenfassung',
    'Ref: Request category': 'Anfragekategorie',
    'Ref: Person affected': 'Die von dieser Anfrage betroffene Person',
    'Ref: Your phone number': 'Telefonnummer',
    'Ref: Description': 'Beschreibung',
    'Ref: Service Requests': 'Serviceanfragen',
    'Ref: Request Service': 'Service -Dienst anfordern',
    'Ref: Success title': 'Ihre Anfrage wurde erstellt',
    'Ref: Error title': 'Hoppla!',
    'Ref: Error subtitle': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    'Ref: Try again': 'Versuchen Sie es erneut',
    'Ref: you pronoun': '(Sie)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Hier finden Sie Unterstützung rund um Ihren Arbeitsplatz',
    'Ref: Tutorial: tabname': 'Anfragen',
    'Ref: Tutorial: body':
      'Wir sind hier, um Ihnen zu helfen. Bitte erfassen Sie eine Anfrage pro Eintrag.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Sie fordern Dienst auf der Site {siteName} an.',
    'Ref: Building': 'Gebäude',
    'Ref: Room': 'Zimmer',
    'Ref: Description help text':
      'Je detaillierter Sie sind, desto besser können wir Sie unterstützen.',
    'Ref: Next': 'Nächste',
    'Ref: Your request': 'Ihre Anfrage',
    'Ref: PhoneNumber error msg': 'Die Telefonnummer ist nicht gültig',
    'Ref: No affected person results': 'Keine Person übereinstimmte angeforderte Kriterien',
    'Ref: Required': '* Erforderlich',
  },
  Summary: {
    'Ref: Location': 'Standort',
    'Ref: Attachment': 'Anhänge',
    'Ref: Submit': 'Einreichen',
    'Ref: No affected person results': 'Keine Person, die den gewünschten Kriterien entspricht',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Service-Anfrage',
    'Ref: createdOn': 'Erstellt am',
    'Ref: summary': 'Zusammenfassung',
    'Ref: category': 'Anfragekategorie',
    'Ref: request raised for': 'Die von dieser Anfrage betroffene Person',
    'Ref: phoneNumber': 'Telefonnummer',
    'Ref: location': 'Telefonnummer',
    'Ref: description': 'Beschreibung',
    'Ref: attachments': 'Anhänge',
  },
};
export default labels;
