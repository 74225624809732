import { screen, cleanup } from '@testing-library/react';

import OrderSummary from './OrderSummary';

import renderComponent from '@/helpers/tests/renderComponent';
import { defaultSite } from '@/modules/Sites/__mocks/mocks';
import { ISite } from '@/modules/Sites/types/sites.types';

const props = {
  subTotal: 4,
  total: 4.8,
  tax: 0.8,
  productsCount: 1,
  languageCode: 'en-US',
  site: defaultSite,
};

const propsWithDelivery = {
  ...props,
  deliveryOptionType: 'Delivery',
  delivery: 0.4,
  total: 5.2,
};

const propsWithPromotion = {
  ...props,
  total: 1.8,
  promotionDiscount: 3,
  appliedPromotions: [
    {
      id: 1,
      name: 'Promotion 1',
      discount: 2,
    },
    {
      id: 2,
      name: 'Promotion 2',
      discount: 1,
    },
  ],
};

const sideId = 'sideId';
jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () =>
    ({
      id: sideId,
      currency: {
        isoCode: 'EUR',
      },
    } as ISite),
}));

jest.mock('@/modules/Core/hooks/useLanguage', () => ({
  __esModule: true,
  default: () => ({
    currentLanguageCode: 'en-GB',
  }),
}));

describe('OrderSummary component', () => {
  afterAll(() => cleanup());

  describe('When no delivery, promotion or subsidy is applied', () => {
    renderComponent(OrderSummary, { ...props });
    it('default info is displayed', () => {
      const subtotal = screen.getByTestId('order-summary-subtotal');
      const tax = screen.getByTestId('order-summary-tax');
      const total = screen.getByTestId('order-summary-total');

      const delivery = screen.queryByTestId('order-summary-delivery');
      const totalWithoutPromotion = screen.queryByTestId('order-summary-total-without-promotion');
      const promotion = screen.queryByTestId('order-summary-promotion');
      const paidByEmployer = screen.queryByTestId('order-summary-paid-by-employer');
      const paidByUser = screen.queryByTestId('order-summary-paid-by-user');
      const clearCartButton = screen.queryByTestId('order-clear-cart-button');

      expect(subtotal).toBeTruthy();
      expect(tax).toBeTruthy();
      expect(total).toBeTruthy();

      expect(delivery).toBeNull();
      expect(totalWithoutPromotion).toBeNull();
      expect(promotion).toBeNull();
      expect(paidByEmployer).toBeNull();
      expect(paidByUser).toBeNull();
      expect(clearCartButton).toBeNull();
    });
  });

  describe('When there is cost of delivery', () => {
    renderComponent(OrderSummary, { ...propsWithDelivery });

    it('info about delivery is provided', () => {
      const delivery = screen.getByTestId('order-summary-delivery');
      expect(delivery).toBeTruthy();
    });
  });

  describe('When promotion is applied', () => {
    renderComponent(OrderSummary, { ...propsWithPromotion });

    it('info about promotion is provided', () => {
      const totalWithoutPromotion = screen.getByTestId('order-summary-total-without-promotion');
      const promotion = screen.getByTestId('order-summary-promotion');

      expect(totalWithoutPromotion).toBeTruthy();
      expect(promotion).toBeTruthy();
    });
  });

  describe('When user is assigned to a subsidised program', () => {
    renderComponent(OrderSummary, { ...props, subsidy: 2.6 });

    it('info about subsidy is provided', () => {
      const paidByEmployer = screen.getByTestId('order-summary-paid-by-employer');
      const paidByUser = screen.getByTestId('order-summary-paid-by-user');

      expect(paidByEmployer).toBeTruthy();
      expect(paidByUser).toBeTruthy();
    });
  });

  describe('When displayed on cart page', () => {
    renderComponent(OrderSummary, { ...props, showClearCart: true });

    it('clear cart button is displayed', () => {
      const clearCartButton = screen.getByTestId('order-clear-cart-button');
      expect(clearCartButton).toBeTruthy();
    });
  });
});
