import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../helpers/tests/renderComponent';
import { defaultState } from '../../Core/reducers/coreReducer';
import { defaultSite } from '../../Sites/__mocks/mocks';
import { defaultCart, defaultMenuItem } from '../__mocks/mock';
import { Cart } from '../types/orderState.types';

import ProductCornerAction from './ProductCornerAction';

jest.mock('./MenuCartActions', () => () => <div data-testid="menu-cart-action" />);
jest.mock('../../../components/atoms/Button', () => () => <div data-testid="scan-button" />);

describe('ProductCornerAction component', () => {
  afterAll(() => cleanup());

  const menuItemIdInCart = 123;
  const menuItemIdNotInCart = 124;
  const cart: Cart = {
    ...defaultCart,
    menuPortionItems: [
      {
        ...defaultCart.menuPortionItems![0],
        menuItemId: menuItemIdInCart,
      },
    ],
  };

  const state = {
    Core: {
      ...defaultState,
      context: {
        site: defaultSite,
      },
    },
  };

  describe('with scan and go menu and item not in cart', () => {
    renderComponent(ProductCornerAction, {
      menuItem: {
        ...defaultMenuItem,
        menuItemId: menuItemIdNotInCart,
      },
      facilityId: '',
      date: new Date(),
      menuId: 1,
      ...defaultSite,
      saveScrollPosition: () => jest.fn(),
      isScanAndGo: true,
      cart,
    });

    it('should render scan button', () => {
      const scanButton = screen.queryByTestId('scan-button');
      const menuCartAction = screen.queryByTestId('menu-cart-action');

      expect(scanButton).toBeTruthy();
      expect(menuCartAction).toBeFalsy();
    });
  });

  describe('with scan and go menu and item in cart', () => {
    renderComponent(
      ProductCornerAction,
      {
        menuItem: {
          ...defaultMenuItem,
          menuItemId: menuItemIdInCart,
        },
        facilityId: '',
        date: new Date(),
        menuId: 1,
        ...defaultSite,
        saveScrollPosition: () => jest.fn(),
        isScanAndGo: true,
        cart,
      },
      state
    );

    it('should render menu cart action', () => {
      const scanButton = screen.queryByTestId('scan-button');
      const menuCartAction = screen.queryByTestId('menu-cart-action');

      expect(scanButton).toBeFalsy();
      expect(menuCartAction).toBeTruthy();
    });
  });

  describe('with no scan and go menu', () => {
    renderComponent(
      ProductCornerAction,
      {
        menuItem: {
          ...defaultMenuItem,
          menuItemId: menuItemIdInCart,
        },
        facilityId: '',
        date: new Date(),
        menuId: 1,
        ...defaultSite,
        saveScrollPosition: () => jest.fn(),
        isScanAndGo: true,
        cart,
      },
      state
    );

    it('should render menu cart action', () => {
      const scanButton = screen.queryByTestId('scan-button');
      const menuCartAction = screen.queryByTestId('menu-cart-action');

      expect(scanButton).toBeFalsy();
      expect(menuCartAction).toBeTruthy();
    });
  });
});
