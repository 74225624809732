import classNames from 'classnames';
import { ReactElement } from 'react';
import { useLocation } from 'react-router';

import Accordion from '../../../components/atoms/Accordion/Accordion';
import Card from '../../../components/molecules/Card/Card';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import { isSmallViewport } from '../../../helpers/windowSize';
import { useGetFaqQuery } from '../api/api';
import { FaqCategory, FaqQuestion } from '../api/api.types';
import { useFaqTranslation } from '../hooks/useFaqTranslation';

import { ServiceRequestIllustration } from '@/assets/illustrations';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import useSite from '@/modules/Core/hooks/useSite';

import styles from './Faq.module.css';

const FaqCategoryDetails = (): ReactElement => {
  const location = useLocation();
  const pathname = location.pathname;
  const faqCategoryId = pathname.substring(pathname.lastIndexOf('/') + 1);
  const { label } = useFaqTranslation(__filename);

  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;
  const { data: { categories = [] } = {} } = useGetFaqQuery(siteId);
  const category = categories.find(
    (category: FaqCategory) => category.faqCategoryId === +faqCategoryId
  );

  const title = (
    <div className={classNames({ [styles.questionHeaderContainer]: isSmallViewport() })}>
      <h2 className={classNames(styles.title)}>{category?.name}</h2>
    </div>
  );

  const questions = category?.questions.map((question: FaqQuestion) => {
    return (
      <Card className="mb-M" key={question.faqQuestionId}>
        <Accordion
          titleCustomClass={classNames(styles.questionTitle)}
          data-testid={`faqQuestions`}
          sections={[
            {
              id: question.faqQuestionId.toString(),
              'data-testid': `faqQuestion-${question.faqQuestionId}`,
              title: question.question,
              content: <div className={classNames(styles.questionAnswer)}>{question.answer}</div>,
            },
          ]}
          defaultOpenedSections={
            question.isExpanded ? [question.faqQuestionId.toString()] : undefined
          }
        />
      </Card>
    );
  });

  return (
    <SimpleFormPage title={label('Ref: Page title')} hasBackLink={true}>
      <Container>
        <Column.Main className={styles.mainColumn}>
          <Title
            tag={TITLE_TAG.H2}
            size={TITLE_SIZE.HEADLINES}
            data-testid={`question-title-${title}`}
          >
            {title}
          </Title>
          {questions}
        </Column.Main>
        <Column.Complementary>
          <ServiceRequestIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default FaqCategoryDetails;
