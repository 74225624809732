import { apiPath, dspApiPath } from '../../config';
import { APP_NAME } from '../../constants';

import { LegalDocsConfigType, CoreConfigType, NotificationsConfigType } from './types/api.types';

import { getAppSpecificData } from '@/helpers/misc';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export const coreConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      baseUrl: apiPath,
      contextUrl: '/v2/user/context',
      themeUrl: '/v2/user/theme',
      availableServicesUrl: '/v2/site/availableservices',
      availableServicesArgConfig: { siteId: { toQueryString: true } },
      imagesUrl: '/v2/image',
      getSiteByCodeUrl: '/v4/registrations/codes',
      updateSiteAndContractUrl: '/v4/users/activesite',
      updateSiteAndContractParams: {
        siteId: {
          toBody: true,
          toThen: true,
        },
      },
    },
    default: {
      api: dspApi,
      baseUrl: dspApiPath,
      contextUrl: '/v1/user/context',
      themeUrl: '/v1/user/theme',
      availableServicesUrl: `/v1/sites/:siteId/available-services`,
      availableServicesArgConfig: { siteId: { toRoute: true } },
      imagesUrl: '/v1/images',
      getSiteByCodeUrl: '/v1/sites/by-code',
      updateSiteAndContractUrl: '/v1/sites/switch/:siteId',
      updateSiteAndContractParams: {
        siteId: {
          toRoute: true,
          toThen: true,
        },
      },
    },
  };

  return getAppSpecificData<CoreConfigType>(configuration);
};

export const getLegalDocsConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      baseUrlWithVersion: `${apiPath}/v3`,
      acknownledgeSuccessStatusCode: 202,
    },
    default: {
      baseUrlWithVersion: `${dspApiPath}/v1`,
      acknownledgeSuccessStatusCode: 201,
    },
  };

  return getAppSpecificData<LegalDocsConfigType>(configuration);
};

export const getNotificationsConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      baseUrl: apiPath,
      getListBaseUrl: '/v2/notification/inapplist',
      closeNotificationBaseUrl: (_id: string) => '/v2/notification/close',
      closeNotificationParam: (id: string) => ({ id }),
    },
    default: {
      api: dspApi,
      baseUrl: dspApiPath,
      getListBaseUrl: '/v1/notifications',
      closeNotificationBaseUrl: (id: string) => `/v1/notifications/${id}/close`,
      closeNotificationParam: (_id: string) => undefined,
    },
  };

  return getAppSpecificData<NotificationsConfigType>(configuration);
};
