const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Ref: ServiceRequestLightTitle',
    'Ref: Summary': 'Ref: Summary',
    'Ref: Request category': 'Ref: Request category',
    'Ref: Person affected': 'Ref: Person affected',
    'Ref: Your phone number': 'Ref: Your phone number',
    'Ref: Description': 'Ref: Description',
    'Ref: Service Requests': 'Ref: Service Requests',
    'Ref: Request Service': 'Ref: Request Service',
    'Ref: Success title': 'Ref: Success title',
    'Ref: Error title': 'Ref: Error title',
    'Ref: Error subtitle': 'Ref: Error subtitle',
    'Ref: Try again': 'Ref: Try again',
    'Ref: you pronoun': 'Ref: you pronoun',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Ref: Tutorial: title',
    'Ref: Tutorial: tabname': 'Ref: Tutorial: tabname',
    'Ref: Tutorial: body': 'Ref: Tutorial: body',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Ref: You are requesting service on the site {siteName}.',
    'Ref: Building': 'Ref: Building',
    'Ref: Room': 'Ref: Room',
    'Ref: Description help text': 'Ref: Description help text',
    'Ref: Next': 'Ref: Next',
    'Ref: Your request': 'Ref: Your request',
    'Ref: PhoneNumber error msg': 'Ref: PhoneNumber error msg',
    'Ref: No affected person results': 'Ref: No affected person results',
    'Ref: Required': 'Ref: Required',
  },
  Summary: {
    'Ref: Location': 'Ref: Location',
    'Ref: Attachment': 'Ref: Attachment',
    'Ref: Submit': 'Ref: Submit',
    'Ref: No affected person results': 'Ref: No affected person results',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Ref: ServiceRequestLightDetails',
    'Ref: createdOn': 'Ref: createdOn',
    'Ref: summary': 'Ref: summary',
    'Ref: category': 'Ref: category',
    'Ref: request raised for': 'Ref: request raised for',
    'Ref: phoneNumber': 'Ref: phoneNumber',
    'Ref: location': 'Ref: location',
    'Ref: description': 'Ref: description',
    'Ref: attachments': 'Ref: attachments',
  },
};
export default labels;
