const labels = {
  All: { 'Ref: stamp progress': '走向下一張郵票', Reward: '報酬', Rewards: '獎勵' },
  LoyaltyStamps: {
    'Ref: Page title': '郵票',
    'Ref: Fulfill conditions': '賺取郵票以兌換獎勵！',
    'See Reward': '見獎勵',
    'Ref: No reward': '目前沒有獎勵',
  },
  RedeemableProducts: { 'Ref: Page title': '忠誠度獎勵' },
};
export default labels;
