export const isFFProductFavouriteEnabled = () =>
  process.env?.REACT_APP_PRODUCT_FAVORITE_ENABLED === 'true';

export const isFFAppSharingEnabled = () => process.env.REACT_APP_SHARING_ENABLED === 'true';

export const isDiscountTiersEnabled = () =>
  process.env?.REACT_APP_DISCOUNT_TIERS_ENABLED === 'true';


  
