import { SETUP_OPTIONS } from '../../../../constants';
import { store } from '../../../../store';
import { State } from '../../../../types/state.types';
import { SERVICE } from '../../../config';
import { DeliveryOptionType } from '../../types/cart.types';

export const isTimeSelectionVisible = (deliveryOptionType?: DeliveryOptionType) => {
  const state = store.getState() as State;
  const service = state.Core.services.list.find(
    (el: { name: string }) => el.name === SERVICE.FOOD_ORDER
  );

  const timeSelectionSetupOptionDisabled =
    service?.setupOptions?.find(
      (el: { name: string }) => el.name === SETUP_OPTIONS.FOOD_ORDER.disableTimeSelection
    )?.value === 'true' || false;
  const isDeliveryFullfimentType = deliveryOptionType === DeliveryOptionType.Delivery;

  return !isDeliveryFullfimentType || !timeSelectionSetupOptionDisabled;
};
