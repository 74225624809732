import { Supplier } from '../api/api.types';

export const getSupplierAddress = (supplier: Supplier) => {
  if (supplier.addressLine2) {
    return `${supplier.addressLine1}
      ${supplier.addressLine2}, 
      ${supplier.postalCode} ${supplier?.city}`;
  } else {
    return `${supplier.addressLine1},
      ${supplier.postalCode} ${supplier?.city}`;
  }
};

export const getSupplierId = (supplier: Supplier) => {
  return `${supplier.latitude.toString()}${supplier.latitude.toString()}`;
};
