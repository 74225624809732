import { cleanup, fireEvent, screen } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { TAG_COLOR, TileProps } from '../../../../components/molecules/Tile';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultSite } from '../../../Sites/__mocks/mocks';
import { defaultFacilityMenu } from '../../__mocks/mock';
import { CartItemTileProps } from '../../types/cart.types';
import { PageType } from '../../types/menuCartActions.types';

import CartItemTile from './CartItemTile';

let tilePropsMock: TileProps;
jest.mock('../../../../components/molecules/Tile', () => {
  const actual = jest.requireActual('../../../../components/molecules/Tile');
  return {
    ...actual,
    Tile: (props: any) => {
      tilePropsMock = props;
      return <actual.Tile {...props} />;
    },
  };
});

jest.mock('../MenuCartActions', () => () => <></>);

describe('CartItemTile component', () => {
  afterAll(() => cleanup());
  const languageCode = 'pl-PL';

  const foodItemId = 13;
  const uomId = 12;
  const props: CartItemTileProps = {
    id: '123',
    menuItemId: 11,
    uomId: uomId,
    foodItemId,
    img: '',
    name: 'name',
    price: 10,
    quantity: 2,
    description: 'some description',
    isVegan: false,
    isVegetarian: false,
    menu: defaultFacilityMenu,
    site: defaultSite,
    date: '2022-12-05',
    pageType: PageType.cart,
    languageCode,
    isLoyaltyReward: false,
    isProvidingLoyaltyStamps: false,
    genericCategory: 'Fruit',
    isKiosk: false,
  };

  describe('on render', () => {
    renderComponent(CartItemTile, {
      ...props,
      showAllergensWarning: true,
      isVegan: true,
      isVegetarian: true,
    });

    it('should have basic props', () => {
      expect(tilePropsMock.id).toBe(props.id);
      expect(tilePropsMock.title).toBe(props.name);
      expect(tilePropsMock.description).toBe(props.description);
    });
  });

  describe('cart item is vegan', () => {
    renderComponent(CartItemTile, { ...props, isVegan: true, isVegetarian: true });

    it('should have vegan chip', () => {
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('Vegan');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SUCCESS);
    });
  });

  describe('cart item is vegetarian', () => {
    renderComponent(CartItemTile, { ...props, isVegan: false, isVegetarian: true });

    it('should have vegetarian chip', () => {
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('Vegetarian');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SUCCESS);
    });
  });

  describe('cart item is loyalty reward', () => {
    renderComponent(CartItemTile, {
      ...props,
      isLoyaltyReward: true,
    });

    it('should have loyalty reward chip', () => {
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('Loyalty Reward');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SECONDARY);
    });
  });

  describe('cart item is providing loyalty stamps', () => {
    renderComponent(CartItemTile, {
      ...props,
      isProvidingLoyaltyStamps: true,
    });

    it('should have providing stamps chip', () => {
      expect(tilePropsMock.chips).toHaveLength(1);
      expect(tilePropsMock!.chips![0].name).toContain('1 Purchase = 1 Stamp');
      expect(tilePropsMock!.chips![0].color).toBe(TAG_COLOR.SECONDARY);
    });
  });

  describe('has promotion for menu item', () => {
    renderComponent(CartItemTile, {
      ...props,
      promotionalDiscounts: [
        {
          uomId: uomId,
          price: 8,
          discount: 2,
          promotedItemModifiers: [],
        },
        {
          uomId: uomId,
          price: 8,
          discount: 2,
          promotedItemModifiers: [],
        },
      ],
    });

    it('should have price without discount and price with discount', () => {
      expect(tilePropsMock!.childText).toContain('16');
      expect(tilePropsMock!.strikethroughChildText).toContain('20');
    });
  });

  describe('has no promotion for menu item', () => {
    renderComponent(CartItemTile, {
      ...props,
      promotionalDiscounts: [],
    });

    it('should have price with discount undefined', () => {
      expect(tilePropsMock!.childText).toContain('20');
      expect(tilePropsMock!.strikethroughChildText).toBeUndefined();
    });
  });

  describe('has redeemed 2 portions', () => {
    renderComponent(CartItemTile, {
      ...props,
      quantity: 5,
      redeemedFoodItems: [
        {
          uomId,
          quantity: 2,
        },
      ],
    });

    it('should have total price only for 3 items', () => {
      expect(tilePropsMock!.childText).toContain('30');
    });
  });

  describe('onClick redirect url', () => {
    const history = createMemoryHistory();
    renderComponent(
      CartItemTile,
      {
        ...props,
        pageType: PageType.productsList,
      },
      undefined,
      history
    );

    it('should have correct url', () => {
      fireEvent.click(screen.getByTestId(`CartItemTile-${props.id}`));

      expect(history.location.pathname).toContain(`${props.date}/${props.menuItemId}/${props.id}`);
    });
  });
});
