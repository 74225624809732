import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ProductSearchResult } from '../hooks/useGlobalSearchResults/useGlobalSearchResults.types';

import { ProductSearchResultsProps } from './ProductSearchResults.types';

import { Restaurant } from '@/assets/icons';
import { TILE_VARIANT, TileSkeleton } from '@/components/molecules/Tile';
import { isKiosk } from '@/helpers/misc';
import { useGetFilterPreferencesQuery } from '@/modules/Core/api/account/accountPreferencesApi';
import { pagePaths } from '@/modules/Order/config';
import ProductTile from '@/modules/Order/containers/ProductTile';
import { PageType } from '@/modules/Order/types/menuCartActions.types';
import { AllergenType } from '@/modules/Order/types/orderState.types';
import { State } from '@/types/state.types';

export const ProductSearchResults = ({
  products,
  menuDate,
  siteId,
  focusedSearchResultIndex,
}: ProductSearchResultsProps) => {
  const history = useHistory();

  const { data: filterPreferences, isLoading: isLoadingPreferences } = useGetFilterPreferencesQuery(
    { siteId, useErrorBoundary: false }
  );

  const isGuest = useSelector((state: State) => !!state.Core.user.isGuest);
  const selectedAllergens = filterPreferences?.selectedAllergens ?? undefined;

  const handleSetScanSelected = useCallback(
    (productItem: ProductSearchResult) => {
      const menuItemPath = pagePaths.ProductsList.replace(
        ':facilityId',
        productItem.menu.facilityId
      )
        .replace(':menuId', productItem.menu.id.toString())
        .replace(':date', menuDate);

      history.push(menuItemPath);
    },
    [history, menuDate]
  );

  if (isLoadingPreferences)
    return (
      <>
        {[...Array(3).keys()].map((key) => (
          <TileSkeleton
            key={key}
            withoutActions
            withoutChildren={false}
            className={'mb-M'}
            data-testid={`skeleton-placeholder-${key}`}
          />
        ))}
      </>
    );

  return (
    <>
      {products.map((productItem, index) => {
        const props = {
          ...productItem.item,
          date: menuDate,
          menu: productItem.menu,
          variant: TILE_VARIANT.STANDARD,
          oneLine: false,
          showAllergensWarning: selectedAllergens?.some((allergen: AllergenType) =>
            productItem.item.allergens?.includes(allergen)
          ),
          canAddToFavorite: true,
        };
        return (
          <ProductTile
            key={props.id}
            pageType={PageType.globalSearchModal}
            {...props}
            tags={[{ name: productItem.menu.name, icon: <Restaurant /> }]}
            onScanButtonClick={() => handleSetScanSelected(productItem)}
            isGuest={isGuest}
            isKiosk={isKiosk}
            focusOnRender={index === focusedSearchResultIndex}
          />
        );
      })}
    </>
  );
};
