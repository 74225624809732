import React, { useState } from 'react';

import { FilterIcon } from '../../../assets/icons';
import { SIZE } from '../../../constants';
import Button, { BADGE_PLACEMENT, BADGE_POSITION } from '../../atoms/Button';
import FilterWrapper from '../../atoms/Filters/FilterWrapper';

import { ButtonModalFiltersProps } from './Filters.types';
import ModalFilters from './ModalFilters';

export const ButtonModalFilters: React.FC<ButtonModalFiltersProps> = ({
  label,
  srOnlyLabel,
  hasFilters,
  filters,
  filtering,
  uncommitedFiltering,
  handleFilteringChange,
  selectedNum,
  badgePosition = BADGE_POSITION.RIGHT,
  badgePlacement = BADGE_PLACEMENT.INNER,
  look = 'secondary',
  className,
  languageCode = 'en-US',
  onCommitFilter,
  onDismissModal,
  onResetModalFilters,
  'data-testid': testId,
}) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const setFilterModalOpen = (isModalOpen: boolean) => {
    setIsFilterModalOpen(isModalOpen);
  };

  return (
    <div data-testid={testId}>
      <FilterWrapper isButton className={className} data-testid={`${testId}-open-filter-wrapper`}>
        <Button
          look={look}
          onClick={() => {
            setFilterModalOpen(true);
          }}
          affix={() => <FilterIcon />}
          badge={selectedNum ? selectedNum.toString() : ''}
          badgePosition={badgePosition}
          badgePlacement={badgePlacement}
          size={SIZE.SMALL}
          data-testid={`${testId}-open-filter-modal`}
        >
          <span>{label}</span>
          <span className="sr-only">{srOnlyLabel}</span>
        </Button>
      </FilterWrapper>
      {hasFilters ? (
        <ModalFilters
          filters={filters}
          setModalState={setIsFilterModalOpen}
          isModalOpen={isFilterModalOpen}
          filtering={filtering}
          uncommitedFiltering={uncommitedFiltering}
          languageCode={languageCode}
          onCommitFilter={onCommitFilter}
          onDismissModal={onDismissModal}
          onResetModalFilters={onResetModalFilters}
          handleChange={handleFilteringChange}
          data-testid={`${testId}-filter-modal`}
        />
      ) : null}
    </div>
  );
};
