import { ApiType } from '../../modules/Core/api';
import MM from '../../services/ModulesManager';
import { store } from '../../store';

import { moduleId } from './config';
import facilitiesReducer from './reducers/facilitiesReducer';

const getFacilityById = (id: string) => {
  const siteId = (MM<ApiType>().getApi('Core', 'getActiveSiteId') as ApiType['getActiveSiteId'])();
  if (!siteId) return;

  const state = store.getState();
  const facility = state[moduleId]?.data[id];
  if (facility) return facility;

  const siteFacilitiesId = state[moduleId]?.list[siteId];
  if (siteFacilitiesId) return false; //should be there if it existed

  //otherwise: load
  loadFacilities();

  return undefined; //not loaded
};

const getFacilities = (siteId: string | undefined) => {
  let activeSiteId = siteId!;
  if (!activeSiteId) {
    activeSiteId = (
      MM<ApiType>().getApi('Core', 'getActiveSiteId') as ApiType['getActiveSiteId']
    )();
  }
  const state = store.getState();
  const facilitiesList = state[moduleId]?.list[activeSiteId];
  if (!facilitiesList) return undefined;

  return facilitiesList.map((facilityId: string) => state[moduleId].data[facilityId]);
};

const loadFacilities = (reload = false) => {
  const state = store.getState();
  if (!state[moduleId]) store.injectReducer(moduleId, facilitiesReducer);
  //warning todo: change to load from cache as per withReducer, wait for completion, and then only fetch
  else if (!store.getState()[moduleId].locks.getAll) {
    //todo: review
    // const siteId = MM<ApiType>().getApi('Core', 'getActiveSiteId')();
    // store.dispatch(getAll({siteId}));
  }
};

const api = {
  getFacilityById,
  getFacilities,
  loadFacilities,
};

export default api;
