import { renderHook } from '@testing-library/react';
import { useSelector } from 'react-redux';

import { pagePaths } from '../../config';

import useCartInfo from './useCartInfo';

jest.mock('react-redux', () => ({
  useSelector: jest.fn(),
}));

describe('useCartInfo hook', () => {
  it('should return the correct cart info when the cart exists and the siteId matches', () => {
    (useSelector as jest.Mock).mockReturnValueOnce({
      Order: {
        cart: {
          siteId: 1,
          menuPortionItems: [{ quantity: 2 }, { quantity: 3 }],
        },
      },
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
    });

    const { result } = renderHook(() => useCartInfo());
    expect(result.current).toEqual({
      itemsCount: 5,
      linkTo: pagePaths.Cart,
    });
  });

  it('should return the correct cart info when the cart exists and the date is today', () => {
    (useSelector as jest.Mock).mockReturnValueOnce({
      Order: {
        cart: {
          siteId: 1,
          date: new Date(),
          menuPortionItems: [{ quantity: 2 }, { quantity: 3 }],
        },
      },
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
    });

    const { result } = renderHook(() => useCartInfo());
    expect(result.current).toEqual({
      itemsCount: 5,
      linkTo: pagePaths.Cart,
    });
  });

  it('should return the correct cart info when the cart exists and the date is in the future', () => {
    (useSelector as jest.Mock).mockReturnValueOnce({
      Order: {
        cart: {
          siteId: 1,
          date: new Date(Date.now() + 86400000),
          menuPortionItems: [{ quantity: 2 }, { quantity: 3 }],
        },
      },
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
    });

    const { result } = renderHook(() => useCartInfo());
    expect(result.current).toEqual({
      itemsCount: 5,
      linkTo: pagePaths.Cart,
    });
  });

  it('should return the correct cart info when the cart exists and the date is in the past', () => {
    (useSelector as jest.Mock).mockReturnValueOnce({
      Order: {
        cart: {
          siteId: 1,
          date: new Date(Date.now() - 86400000),
          menuPortionItems: [{ quantity: 2 }, { quantity: 3 }],
        },
      },
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
    });

    const { result } = renderHook(() => useCartInfo());
    expect(result.current).toEqual({
      linkTo: pagePaths.Cart,
    });
  });

  it('should return the correct cart info when user is logged out', () => {
    (useSelector as jest.Mock).mockReturnValueOnce({
      Order: {
        cart: {
          siteId: 1,
          date: new Date(Date.now() - 86400000),
          menuPortionItems: [{ quantity: 2 }, { quantity: 3 }],
        },
      },
      Core: {
        context: {},
      },
    });

    const { result } = renderHook(() => useCartInfo());
    expect(result.current).toEqual({
      linkTo: pagePaths.Cart,
    });
  });
});
