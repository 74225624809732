import { WIDGET_TYPE } from '../../../constants';
import { WidgetType } from '../../../types';

import MenusWidget from './MenusWidget';
import OrderHistoryWidget from './OrderHistoryWidget/OrderHistoryWidget';
import PromotionsMiniWidget from './PromotionsMiniWidget/PromotionsMiniWidget';
import ReorderWidget from './ReorderWidget/ReorderWidget';
import SuggestionsWidget from './SuggestionsWidget/SuggestionsWidget';

export const widgets: WidgetType[] = [
  {
    id: 'PromotionsMiniWidget',
    type: WIDGET_TYPE.MINI,
    component: PromotionsMiniWidget,
    supportedServices: ['Food.Order'],
  },
  {
    id: 'ReorderWidget',
    type: WIDGET_TYPE.MINI,
    component: ReorderWidget,
    supportedServices: ['Food.Order'],
  },
  {
    id: 'MenusWidget',
    type: WIDGET_TYPE.NORMAL,
    component: MenusWidget,
    supportedServices: ['Food.Menu', 'Food.Order'],
  },
  {
    id: 'SuggestionsWidget',
    type: WIDGET_TYPE.NORMAL,
    component: SuggestionsWidget,
    supportedServices: ['Food.Order'],
  },
  {
    id: 'OrderHistoryWidget',
    type: WIDGET_TYPE.NORMAL,
    component: OrderHistoryWidget,
    supportedServices: ['Food.Orders'],
  },
];
