import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import LoyaltyStamps from './LoyaltyStamps';
import RedeemableProducts from './RedeemableProducts';

const Routes = () => {
  return (
    <Switch>
      <Route path={pagePaths.Module} exact children={<LoyaltyStamps />} />
      <Route path={pagePaths.RedeemableProducts} exact children={<RedeemableProducts />} />
    </Switch>
  );
};

export default Routes;
