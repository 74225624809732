const labels = {
  All: { 'Ref: Page title': 'Proveedores' },
  SuppliersList: {
    'Ref: Supplier details': 'Ver detalles',
    'Missing supplier list': 'No hemos encontrado ningún proveedor',
    'Try again later': 'Inténtalo de nuevo más tarde',
  },
  SupplierDetails: {
    'Address label': 'Dirección:',
    'Website label': 'Sitio web:',
    'Details label': 'Detalles',
  },
};
export default labels;
