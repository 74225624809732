import { useEffect, useState } from 'react';

import { NutritionsAndAllergensIcon } from '../../../../assets/icons';
import Button from '../../../../components/atoms/Button';
import { TITLE_TAG } from '../../../../components/atoms/Title/Title.types';
import Card from '../../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import List from '../../../../components/organisms/List/List';
import Modal from '../../../../components/organisms/Modal';
import { SIZE } from '../../../../constants';
import useToggle from '../../../../helpers/hooks/useToggle';
import { getModifierItems } from '../../helpers/modifiers.helper';
import {
  buildAllergensList,
  buildModifiersNutritionList,
  displayAllergensTag,
} from '../../helpers/productDetails.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { FirstFreeItem } from '../../types/modifiers.types';
import { ModifierSelections } from '../../types/productDetails.types';

import { ModifierCardProps } from './ModifierCard.types';

import styles from './ModifierCard.module.css';

const ModifierCard = ({
  modifier,
  modifierErrors,
  selectedModifiers,
  selectedAllergens,
  languageCode,
  isoCode,
  handleChange,
  'data-testid': dataTestId,
}: ModifierCardProps) => {
  const { state: modalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggle(false);
  const [usedFirstFree, setUsedFirstFree] = useState(0);
  const [usedFirstFreeItems, setUsedFirstFreeItems] = useState<FirstFreeItem[]>([]);
  const { label } = useOrderTranslation(__filename);

  const { min, firstFree } = modifier;
  const { errorMessage } = (modifierErrors && modifierErrors[modifier.id]) || {};
  const freeItems = usedFirstFree ? firstFree - usedFirstFree : firstFree;

  const firstFreeItemsHandler = (id: number, value: number) => {
    setUsedFirstFreeItems((prevItems) => {
      const itemIndex = prevItems.findIndex((item) => item.id === id);
      if (itemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems[itemIndex] = { usedFirstFreeCount: value ?? 0, id };
        return updatedItems;
      }

      const newItem = { usedFirstFreeCount: value ?? 0, id };

      return [...prevItems, newItem];
    });
  };

  const onChangeHandler = (selectedItems: any[], id: string) => {
    const selectedItem = selectedItems.find((item) => item.id === id);

    const selectedQuantityFirstFree = selectedItems.reduce((acc, item) => {
      if (acc === modifier?.firstFree) {
        return acc;
      }
      if ((item.firstFree as number) > 0) {
        acc +=
          (item?.firstFree ?? 0) >= (item?.quantity ?? 0)
            ? item.quantity ?? 0
            : item.firstFree ?? 0;
      }

      return acc;
    }, 0);

    if ((selectedItem?.firstFree ?? 0) >= 0) {
      firstFreeItemsHandler(Number(id), selectedItem?.quantity ?? 0);
    }
    const usedFirstFree = Math.min(selectedQuantityFirstFree, modifier.firstFree);
    setUsedFirstFree(usedFirstFree);
    handleChange(modifier.id, modifier.name, selectedItems, id);
  };

  useEffect(() => {
    const selectedModifier = selectedModifiers.find(
      (mod) => mod.modifierId === modifier.id
    ) as ModifierSelections;

    const itemQuantities = selectedModifier?.itemQuantities
      ? Object.entries(selectedModifier?.itemQuantities)
      : null;

    const totalQuantityWithFirstFree = itemQuantities?.reduce((acc, item) => {
      const foundItem = modifier.modifierItems.find((el) => el.id === Number(item[0]));

      if ((foundItem?.firstFree as number) > 0) {
        firstFreeItemsHandler(Number(item[0]), item[1]);
        return acc + item[1];
      }
      return acc;
    }, 0) as number;

    const usedFirstFree = Math.min(totalQuantityWithFirstFree, modifier.firstFree);
    setUsedFirstFree(usedFirstFree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card
        title={{
          tag: TITLE_TAG.H5,
          className: 'headlineXS',
          children: label(modifier.name),
          suffix: (
            <Button
              look={'tertiary'}
              data-cy={`button-to-expand-infos-modal-${modifier.id}`}
              data-testid={`button-infos-modal-${modifier.id}`}
              onClick={openModal}
              size={SIZE.SMALL}
              affix={NutritionsAndAllergensIcon}
            >
              {label('Ref: Info')}
            </Button>
          ),
          ...(min && { small: `(${min} ${label('Ref: minimum required')})` }),
        }}
        className="inlineContainer"
        data-testid={dataTestId}
      >
        {errorMessage && (
          <div className={styles.notificationWrapper}>
            <Notification look={NOTIFICATION_LOOK.ERROR} title={errorMessage} />
          </div>
        )}

        {Boolean(freeItems) && (
          <div className={styles.notificationWrapper}>
            <Notification
              look={NOTIFICATION_LOOK.SUCCESS}
              title={label('Ref: Number of free items', {
                replace: {
                  number_of_free_items: freeItems.toString(),
                },
              })}
            />
          </div>
        )}

        <List
          key={modifier.id}
          data-testid="card-modifier-list"
          items={getModifierItems(
            label,
            { ...modifier, usedFirstFree: usedFirstFree },
            selectedModifiers,
            languageCode,
            isoCode,
            selectedAllergens,
            usedFirstFreeItems
          )}
          twoColumn
          onChange={(selectedItems, id) => {
            onChangeHandler(selectedItems, id);
          }}
        />
      </Card>

      <Modal
        title={label('Ref: Nutritional info', { textTransform: 'capitalize' })}
        isOpen={modalOpen}
        id="nutritions_modal"
        onDismiss={closeModal}
        contentClassName={styles.modalContent}
      >
        {modifier.modifierItems.map((item) => {
          const hasAllergen = item.allergens?.some((allergen) =>
            selectedAllergens.includes(allergen.id)
          );
          return (
            <Card
              title={{
                tag: TITLE_TAG.H6,
                className: hasAllergen ? styles.warningLabel : styles.nonWarningLabel,
                children: (
                  <>
                    {item.name} {buildAllergensList(item.allergens, selectedAllergens)}
                  </>
                ),
              }}
              className="mb-M"
              data-testid={`modal-infos-${item.id}`}
            >
              <>
                {displayAllergensTag(item.allergens, selectedAllergens, label)}

                <List
                  data-testid={`card-modifier-nutrition-list-${item.id}`}
                  items={buildModifiersNutritionList(label, item.nutritions)}
                />
              </>
            </Card>
          );
        })}
      </Modal>
    </>
  );
};

export default ModifierCard;
