const mockResponse = {
  getFaq: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          categories: [
            {
              faqCategoryId: 1,
              name: 'Sed lorem',
              questions: [
                {
                  faqQuestionId: 1,
                  answer:
                    'Blandit aliquip ut et blandit consequat vel accusam nam ut dolor magna accusam stet amet accusam.',
                  question: 'Magna magna dolore stet clita ?',
                  isExpanded: true,
                },
                {
                  faqQuestionId: 2,
                  answer:
                    'Et clita ullamcorper ea justo sit rebum takimata aliquip elitr dolor veniam kasd est labore takimata te. Gubergren amet adipiscing kasd eos et vero aliquyam dolores.',
                  question: 'Ex in nonumy duo vero ?',
                  isExpanded: false,
                },
                {
                  faqQuestionId: 3,
                  answer:
                    'Stet takimata lorem takimata blandit et quis amet lorem qui. At dolore diam volutpat tation amet erat magna clita gubergren magna vel adipiscing molestie dolores in dolore.',
                  question: 'Diam est consequat dolor duis eleifend dolor ?',
                  isExpanded: false,
                },
              ],
            },
            {
              faqCategoryId: 2,
              name: 'Volutpat facilisi labore',
              questions: [
                {
                  faqQuestionId: 4,
                  answer: 'Tempor takimata tation erat erat dolor erat eirmod sit sadipscing.',
                  question: 'Nonumy lorem laoreet eos amet ut amet ?',
                  isExpanded: false,
                },
                {
                  faqQuestionId: 5,
                  answer:
                    'Et ut consectetuer accumsan clita dolor aliquam sit lorem quod vero justo ea. Sit dolor nonummy. Takimata diam labore ea gubergren in est kasd dolor.',
                  question: 'Vero kasd lorem ipsum sed rebum diam ?',
                  isExpanded: false,
                },
                {
                  faqQuestionId: 6,
                  answer:
                    'Exerci lorem vero ullamcorper sed dolor dolore diam dolor tempor amet justo ea takimata facilisis. Invidunt augue elit consetetur vero wisi invidunt sed dolores kasd laoreet duo te consequat rebum. Elitr lorem hendrerit at vulputate lorem erat amet dignissim diam suscipit facer aliquyam lorem magna. Dolor accusam nonumy minim erat ut sed sadipscing suscipit et aliquyam aliquyam consequat rebum vero aliquam dolor consequat. Dolores delenit et lorem nobis ut.',
                  question: 'Magna commodo soluta in et stet eos ?',
                  isExpanded: false,
                },
                {
                  faqQuestionId: 1,
                  answer:
                    'Blandit aliquip ut et blandit consequat vel accusam nam ut dolor magna accusam stet amet accusam.',
                  question: 'Magna magna dolore stet clita ?',
                  isExpanded: true,
                },
              ],
            },
            {
              faqCategoryId: 3,
              name: 'Dolore lorem dolor',
              questions: [
                {
                  faqQuestionId: 7,
                  answer:
                    'Blandit aliquip ut et blandit consequat vel accusam nam ut dolor magna accusam stet amet accusam.',
                  question: 'Magna magna dolore stet clita ?',
                  isExpanded: true,
                },
                {
                  faqQuestionId: 8,
                  answer: 'Tempor takimata tation erat erat dolor erat eirmod sit sadipscing.',
                  question: 'Nonumy lorem laoreet eos amet ut amet ?',
                  isExpanded: false,
                },
                {
                  faqQuestionId: 9,
                  answer:
                    'Stet takimata lorem takimata blandit et quis amet lorem qui. At dolore diam volutpat tation amet erat magna clita gubergren magna vel adipiscing molestie dolores in dolore.',
                  question: 'Diam est consequat dolor duis eleifend dolor ?',
                  isExpanded: false,
                },
                {
                  faqQuestionId: 10,
                  answer:
                    'Et clita ullamcorper ea justo sit rebum takimata aliquip elitr dolor veniam kasd est labore takimata te. Gubergren amet adipiscing kasd eos et vero aliquyam dolores.',
                  question: 'Ex in nonumy duo vero ?',
                  isExpanded: false,
                },
              ],
            },
          ],
        },
      ]),
  },
  // createAuditResponse: {
  //   ok: true,
  //   status: 200,
  //   json: () => Promise.resolve(),
  // },
};

export default mockResponse;
