const labels = {
  All: {
    'Ref: stamp progress': 'Progressi verso il prossimo timbro',
    Reward: 'Ricompensa',
    Rewards: 'Premi',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Francobolli',
    'Ref: Fulfill conditions': 'Guadagna francobolli per riscattare i premi!',
    'See Reward': 'Vedi ricompensa',
    'Ref: No reward': 'Nessuna ricompensa per il momento',
  },
  RedeemableProducts: { 'Ref: Page title': 'Premi fedeltà' },
};
export default labels;
