import { CreateDraftOrderError } from '../types/order.types';

import { getTimeSlotDate, isMinimumTimeToOrderError } from './orderDraft.helper';

describe('getTimeSlotDate', () => {
  describe('when cart date is for today', () => {
    const result = getTimeSlotDate(new Date());

    it('should return empty value', () => {
      expect(result).toBe('');
    });
  });

  describe('when cart date is not for today', () => {
    const dateInFuture = '2050-01-01';
    const result = getTimeSlotDate(new Date(dateInFuture));

    it('should return date', () => {
      expect(result).toBe(dateInFuture);
    });
  });
});

describe('isMinimumTimeToOrderError', () => {
  describe('when error is OrderDraft = MinimumTimeToOrder and status code 400', () => {
    const result = isMinimumTimeToOrderError(400, {
      errors: { OrderDraft: CreateDraftOrderError.MinimumTimeToOrder },
    });
    it('should return true', () => {
      expect(result).toBe(true);
    });
  });

  describe('when error is OrderDraft = MinimumTimeToOrder and status code not 400', () => {
    const result = isMinimumTimeToOrderError(422, {
      errors: { OrderDraft: CreateDraftOrderError.MinimumTimeToOrder },
    });
    it('should return false', () => {
      expect(result).toBe(false);
    });
  });

  describe('when error is OrderDraft = XXXXXXXXXX and status code 400', () => {
    const result = isMinimumTimeToOrderError(400, {
      errors: { OrderDraft: 'XXXXXXXXXX' as CreateDraftOrderError },
    });
    it('should return true', () => {
      expect(result).toBe(false);
    });
  });

  describe('when error is XXXXXXXXXX = YYYYYYYYY and status code 400', () => {
    const result = isMinimumTimeToOrderError(400, {
      errors: { XXXXXXXXXX: 'YYYYYYYYY' as CreateDraftOrderError },
    });
    it('should return true', () => {
      expect(result).toBe(false);
    });
  });
});
