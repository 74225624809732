const labels = {
  All: { 'Ref: Page title': 'Suppliers' },
  SuppliersList: {
    'Ref: Supplier details': 'See details',
    'Missing supplier list': 'We did not find any supplier.',
    'Try again later': 'Try again later',
  },
  SupplierDetails: {
    'Address label': 'Address:',
    'Website label': 'Website:',
    'Details label': 'Details',
  },
};
export default labels;
