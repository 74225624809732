import { MenuSelectorListItem } from '../../types/menuSelector.types';
import { MenuVisits } from '../../types/order.types';

const sortMenusVisitsDescending = (menusVisits: MenuVisits[]) => {
  const sortedMenusVisits = [...menusVisits].sort((m1, m2) => {
    if (m1.visits < m2.visits) {
      return 1;
    } else if (m1.visits > m2.visits) {
      return -1;
    } else {
      return 0;
    }
  });

  return sortedMenusVisits;
};

const sortFacilitiesByMenusVisits = (
  unorderedFacilities: MenuSelectorListItem[],
  sortedMenusVisits: MenuVisits[]
) => {
  const facilitiesSortedByMenuVisits = [...unorderedFacilities].sort((facilityA, facilityB) => {
    const visitedA = sortedMenusVisits.findIndex((menu) => menu.menuId === facilityA.menu.id);
    const visitedB = sortedMenusVisits.findIndex((menu) => menu.menuId === facilityB.menu.id);
    if (visitedA === -1 && visitedB === -1) {
      return 0;
    }
    if (visitedA === -1) {
      return 1;
    }
    if (visitedB === -1) {
      return -1;
    }
    return visitedA - visitedB;
  });
  return facilitiesSortedByMenuVisits;
};

export const sortFacilitiesByMenuTypeAndVisits = (
  unorderedFacilities: MenuSelectorListItem[],
  menusVisits: MenuVisits[]
) => {
  if (!!menusVisits.length) {
    const facilitiesWithOrderableMenus = unorderedFacilities.filter(
      (facility) => facility.menu.isOrderable === true
    );
    const facilitiesWithViewableMenus = unorderedFacilities.filter(
      (facility) => facility.menu.isOrderable === false
    );

    const sortedMenusVisits = sortMenusVisitsDescending(menusVisits);
    const orderableSorted = sortFacilitiesByMenusVisits(
      facilitiesWithOrderableMenus,
      sortedMenusVisits
    );
    const viewableSorted = sortFacilitiesByMenusVisits(
      facilitiesWithViewableMenus,
      sortedMenusVisits
    );
    return [...orderableSorted, ...viewableSorted];
  }

  const facilitiesSortedByMenuType = [...unorderedFacilities].sort(
    (a, b) => Number(b.menu.isOrderable) - Number(a.menu.isOrderable)
  );
  return facilitiesSortedByMenuType;
};
