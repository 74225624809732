const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Yêu cầu của bạn',
    'Ref: Summary': 'Bản tóm tắt',
    'Ref: Request category': 'Danh mục yêu cầu',
    'Ref: Person affected': 'Người bị ảnh hưởng bởi yêu cầu này',
    'Ref: Your phone number': 'Số điện thoại',
    'Ref: Description': 'Sự miêu tả',
    'Ref: Service Requests': 'Yêu cầu dịch vụ',
    'Ref: Request Service': 'Yêu cầu dịch vụ',
    'Ref: Success title': 'Yêu cầu của bạn đã được tạo ra',
    'Ref: Error title': 'Ối!',
    'Ref: Error subtitle': 'Có một lỗi đã xảy ra. Xin hãy thử lại.',
    'Ref: Try again': 'Thử lại',
    'Ref: you pronoun': '(bạn)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Đây là HUB hỗ trợ nơi làm việc của bạn',
    'Ref: Tutorial: tabname': 'Yêu cầu',
    'Ref: Tutorial: body':
      'Chúng tôi đang ở đây để giúp bạn. Vui lòng đăng nhập một yêu cầu cho mỗi mục nhập.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Bạn đang yêu cầu dịch vụ trên trang web {Sitename}.',
    'Ref: Building': 'Xây dựng',
    'Ref: Room': 'Phòng',
    'Ref: Description help text': 'Bạn càng chi tiết, chúng tôi càng có thể hỗ trợ bạn tốt hơn.',
    'Ref: Next': 'Kế tiếp',
    'Ref: Your request': 'Yêu cầu của bạn',
    'Ref: PhoneNumber error msg': 'Số điện thoại không hợp lệ',
    'Ref: No affected person results': 'Không ai phù hợp với tiêu chí được yêu cầu',
    'Ref: Required': '* Yêu cầu',
  },
  Summary: {
    'Ref: Location': 'Vị trí',
    'Ref: Attachment': 'Đính kèm',
    'Ref: Submit': 'Nộp',
    'Ref: No affected person results': 'Không ai phù hợp với tiêu chí được yêu cầu',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Yêu cầu dịch vụ',
    'Ref: createdOn': 'Được tạo ra',
    'Ref: summary': 'Bản tóm tắt',
    'Ref: category': 'Danh mục yêu cầu',
    'Ref: request raised for': 'Người bị ảnh hưởng bởi yêu cầu này',
    'Ref: phoneNumber': 'Số điện thoại',
    'Ref: location': 'Số điện thoại',
    'Ref: description': 'Sự miêu tả',
    'Ref: attachments': 'Đính kèm',
  },
};
export default labels;
