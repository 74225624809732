const labels = {
  ReviewForm: {
    'Ref: Page title': 'Tinjauan',
    'Ref: How was dining with us': 'Bagaimana makan bersama kami?',
    'Ref: Submit': 'Kirim',
    'Ref: Filter range': 'Rentang filter',
    'Ref: Some information is missing': 'Beberapa informasi hilang',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Kirim secara anonim',
    'Ref: Your email address': 'Alamat email anda',
    'Ref: Email field placeholder': 'Masukkan alamat email Anda',
    'Ref: Invalid email format': 'format email salah',
  },
  SuccessPage: {
    'Ref: Body':
      'Terima kasih telah menyelesaikan ulasan ini, waktu Anda sangat berharga karena ulasan ini akan membantu meningkatkan layanan kami.',
    'Ref: Go to Feedback': 'Pergi ke umpan balik',
    'Ref: Go to home': 'Pergi ke rumah',
    'Ref: Go to Facilities': 'Pergi ke fasilitas',
    'Ref: Go to Product Details': 'Pergi ke detail produk',
    'Ref: Go to Facility Details': 'Pergi ke Detail Fasilitas',
  },
  FacilityPicker: {
    'Ref: Page title': 'Tinjauan',
    'Ref: Search facility name': 'Nama fasilitas pencarian',
  },
};
export default labels;
