import { useSelector } from 'react-redux';

import { State } from '../../../../types/state.types';
import { SERVICE } from '../../../config';

import { SuggestPlace } from './SuggestionsWidget.types';

export const useIsSuggestionsEnabled = (place: SuggestPlace): boolean => {
  const isEnabled = useSelector((state: State) => {
    const service = state.Core.services.list.find(
      (el: { name: string }) => el.name === SERVICE.RETAIL || el.name === SERVICE.FOOD_ORDER
    );

    return service?.setupOptions?.find((el: { name: string }) => el.name === place)?.value || false;
  });

  return isEnabled !== 'false';
};
