import classNames from 'classnames';
import { useState } from 'react';

import SideBarHeader from '../../atoms/SideBarHeader';
import SideBarSection from '../../molecules/SideBarSection';
import SideBarSectionSkeleton from '../../molecules/SideBarSection/SideBarSectionSkeleton';
import SideBarSite from '../../molecules/SideBarSite';

import { SideBarMobileProps } from './SideBar.types';

import { BuildingIcon, Close20Icon } from '@/assets/icons';

import styles from './MobileSideBar.module.css';

const MobileSideBar = ({
  site,
  homeSection,
  recentSection,
  servicesSection,
  title,
  'data-testid': dataTestId = 'sidebar',
  closed,
  label,
  closeSidebar,
  isLoading,
  tooltipId,
}: SideBarMobileProps) => {
  const siteItem = {
    icon: <BuildingIcon />,
    title: site.name,
    linkTo: site.linkTo,
    active: site.active,
  };

  const [touchStart, setTouchStart] = useState<number>(0);
  const [touchEnd, setTouchEnd] = useState<number>(0);

  const handleTouchStart = (e: TouchEvent) => {
    e.preventDefault();
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    e.preventDefault();
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = (e: TouchEvent) => {
    e.preventDefault();
    if (touchStart - touchEnd > 150) {
      closeSidebar();
    }

    setTouchStart(0);
    setTouchEnd(0);
  };

  return (
    <>
      <div
        className={classNames(styles.blurred, !closed && styles.open)}
        onTouchStart={(touchStartEvent) =>
          handleTouchStart(touchStartEvent as unknown as TouchEvent)
        }
        onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent as unknown as TouchEvent)}
        onTouchEnd={(touchEndEvent) => handleTouchEnd(touchEndEvent as unknown as TouchEvent)}
      />
      <div
        className={classNames(styles.mobileWrapper, !closed && styles.open)}
        data-testid={dataTestId}
      >
        <SideBarHeader
          title={title}
          cornerAction={closeSidebar}
          toggleAriaLabel={label('close menu')}
          collapsed={false}
          actionIcon={<Close20Icon />}
          isLoading={isLoading}
          isMobile={true}
        />
        <SideBarSite
          collapsed={false}
          sectionTitle={site.title}
          icon={siteItem.icon}
          title={siteItem.title}
          linkTo={siteItem.linkTo}
          isLoading={isLoading}
          active={siteItem.active}
          onClick={closeSidebar}
        />
        {isLoading ? (
          <>
            <SideBarSectionSkeleton titleSkeleton={false} itemsCount={1} />
            <SideBarSectionSkeleton titleSkeleton={true} itemsCount={5} />
          </>
        ) : (
          <>
            <SideBarSection
              tooltipId={tooltipId}
              collapsed={false}
              items={[homeSection]}
              onItemClick={closeSidebar}
            />
            <SideBarSection
              tooltipId={tooltipId}
              collapsed={false}
              title={recentSection.sectionTitle}
              items={recentSection.items}
              onItemClick={closeSidebar}
            />
            <SideBarSection
              tooltipId={tooltipId}
              collapsed={false}
              title={servicesSection.sectionTitle}
              items={servicesSection.items}
              scrollable={true}
              onItemClick={closeSidebar}
            />
          </>
        )}
      </div>
    </>
  );
};
MobileSideBar.displayName = 'SideBar.Mobile';
export default MobileSideBar;
