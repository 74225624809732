import classNames from 'classnames';

import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import { SIZE } from '../../../../constants';

import styles from './ButtonsPair.module.css';

export const ButtonsPair = ({
  buttons,
  className,
}: {
  buttons: {
    onClick: () => void;
    title: string;
    icon: React.ComponentType<{
      className?: string;
    }>;
  }[];
  className?: string;
}) => {
  return (
    <div className={classNames(styles.buttonsWrapper, className)}>
      {buttons.map((button, _) => (
        <Button
          key={_}
          look={BUTTON_LOOK.SECONDARY}
          size={SIZE.SMALL}
          onClick={button.onClick}
          affix={button.icon}
          data-testid={`order-buttons-pair-button-${button.title}`}
          contentCenterAlign
          fullWidth
        >
          {button.title}
        </Button>
      ))}
    </div>
  );
};
