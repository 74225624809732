const labels = {
  All: { 'Ref: Page title': 'Beszállítók' },
  SuppliersList: {
    'Ref: Supplier details': 'Lásd a részleteket',
    'Missing supplier list': 'Nem találtunk szállítót.',
    'Try again later': 'Próbáld újra később',
  },
  SupplierDetails: {
    'Address label': 'Cím:',
    'Website label': 'Weboldal:',
    'Details label': 'Részletek',
  },
};
export default labels;
