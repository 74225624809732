import moment from 'moment';

import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { getImagesSrcFromIds } from '../../../helpers/images/images.helper';
import { IContent, IContentType } from '../../Content/types';
import { surveysConfig } from '../config';
import mockResponse from '../mockResponse';
import { Survey as SurveyDetail } from '../types/survey.types';
import { Survey } from '../types/surveysList.types';

import { GetSurveyArgs, GetSurveysArgs, PostSurveyArgs } from './api.types';

import { contentConfig } from '@/modules/Content/config';

const {
  api,
  apiBaseUrl,
  getSurveysListApiPath,
  getSurveyDetailsRequestData,
  submitSurveyRequestData,
} = surveysConfig();

const { gethomeUrl, getHomeParams } = contentConfig();

const surveysApi = api.enhanceEndpoints({ addTagTypes: ['surveys'] }).injectEndpoints({
  endpoints: (build) => ({
    /**
     * GET /v2/content/homepage - get surveys from content
     * GET /v2/survey/list or /v1/surveys - get custom surveys
     */
    getSurveys: build.query<Survey[], GetSurveysArgs>({
      queryFn: async (args: GetSurveysArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });
        const contentBaseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });

        const contentResponse = await contentBaseQuery({
          url: gethomeUrl,
          method: 'get',
          params: getHomeParams(
            moment().add(-240, 'days').format('YYYY-MM-DD'),
            moment().add(240, 'days').format('YYYY-MM-DD'),
            args.siteId,
            args.languageCode,
            args.contractId
          ),
          mockData: mockResponse.getContents,
        });

        const surveyResponse = await baseQuery({
          url: getSurveysListApiPath,
          method: 'get',
          params: {
            siteId: args.siteId,
          },
          mockData: mockResponse.getSurveys,
        });

        const { data: { contents } = {} } = contentResponse;

        const filteredContent = contents.filter(
          (contentPiece: IContent) => contentPiece.type === IContentType.INMOMENT_SURVEY
        );

        const imageIds: string[] = filteredContent
          .filter((content: IContent) => !!content.images?.[0])
          .map((content: IContent) => content.images[0] as string);

        const images = await getImagesSrcFromIds(imageIds);

        const mergedResult: Survey[] = surveyResponse.data ? surveyResponse.data : [];

        filteredContent.forEach((content: IContent) => {
          const imageId = content.images?.[0] as string;
          const image = images.find((img) => img.id === imageId);

          mergedResult.push({
            description: content.details,
            expiryDate: content.expiryDate,
            id: content.id,
            image: content.images[0],
            publishedDate: content.publishedDate,
            name: content.title,
            inviteId: '',
            isInvited: false,
            type: IContentType.INMOMENT_SURVEY,
            url: content.link.url,
            imgInfo: image ? { src: image.content, alt: content.title } : undefined,
          });
        });

        return {
          data: mergedResult,
        };
      },
      providesTags: (result, error, { siteId, contractId }) => [
        { type: 'surveys', siteId, contractId },
      ],
    }),
    /**
     * GET /v2/survey or /v1/surveys/:surveyId
     */
    getSurvey: build.query<SurveyDetail, GetSurveyArgs>({
      queryFn: async ({ id, siteId }: GetSurveyArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });

        const { apiPath, params, filterResponse } = getSurveyDetailsRequestData({
          siteId: siteId,
          surveyId: id,
        });

        const response = await baseQuery({
          url: apiPath,
          method: 'get',
          params: params,
          mockData: mockResponse.getSurvey,
        });

        return {
          data: filterResponse
            ? response.data.find((s: SurveyDetail) => s.id === id)
            : response.data,
        };
      },
      providesTags: (result) => [{ type: 'surveys', id: result?.id }],
    }),
    /**
     * POST /v2/survey or v1/{surveyId}/respond
     */
    postSurveyData: build.mutation<void, PostSurveyArgs>({
      queryFn: async ({ questionResponses, survey }: PostSurveyArgs) => {
        const { apiPath, data } = submitSurveyRequestData({ questionResponses, survey });
        const baseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });

        const response = await baseQuery({
          url: apiPath,
          method: 'post',
          data: data,
          mockData: mockResponse.submitSurvey,
        });

        return response;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetSurveysQuery, useGetSurveyQuery, usePostSurveyDataMutation } = surveysApi;
export default surveysApi;
