import { useMemo } from 'react';

import { SuggestedMenuItem } from '../../types/orderState.types';

export const useSuggestionsToDisplay = (
  menuBasedSuggestions: SuggestedMenuItem[] = [],
  lastDisplayedIndex: number
) => {
  return useMemo(() => {
    const count = 3;
    let index = lastDisplayedIndex;
    return [
      ...menuBasedSuggestions.slice(index, (index += count)),
      ...(index >= menuBasedSuggestions.length && menuBasedSuggestions.length >= count
        ? menuBasedSuggestions.slice(0, index % menuBasedSuggestions.length)
        : []),
    ];
  }, [menuBasedSuggestions, lastDisplayedIndex]);
};
