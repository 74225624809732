import { modulesConfig, serviceTypes } from '../../config';

export const moduleId = 'Suppliers';
export const cacheVersion = '0.1';
export const modulePath = serviceTypes[modulesConfig.Suppliers.serviceType].path;

export const pagePaths = {
  Module: modulePath,
  SupplierDetails: modulePath + '/:id/details',
};
