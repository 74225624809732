import { useGetLoyaltySchemesQuery } from '@/modules/LoyaltyStamps/api';

export const useFetchLoyaltySchemes = (siteId: string, isGuest: boolean) => {
  const { data: schemes, isLoading } = useGetLoyaltySchemesQuery(
    { siteId, useErrorBoundary: false },
    { skip: isGuest }
  );

  return {
    schemes,
    isLoading,
  };
};
