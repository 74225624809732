const labels = {
  All: { 'Ref: Page title': '공급자' },
  SuppliersList: {
    'Ref: Supplier details': '자세히보다',
    'Missing supplier list': '우리는 어떤 공급자도 찾지 못했습니다.',
    'Try again later': '나중에 다시 시도하십시오',
  },
  SupplierDetails: {
    'Address label': '주소:',
    'Website label': '웹 사이트 :',
    'Details label': '세부',
  },
};
export default labels;
