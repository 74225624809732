const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Su petición',
    'Ref: Summary': 'Resumen',
    'Ref: Request category': 'Categoría de solicitud',
    'Ref: Person affected': 'La persona afectada por esta solicitud',
    'Ref: Your phone number': 'Número de teléfono',
    'Ref: Description': 'Descripción',
    'Ref: Service Requests': 'Solicitudes de servicio',
    'Ref: Request Service': 'Servicio de solicitud',
    'Ref: Success title': 'Su solicitud ha sido creada',
    'Ref: Error title': '¡Ups!',
    'Ref: Error subtitle': 'Ha ocurrido un error. Por favor intente otra vez.',
    'Ref: Try again': 'Intentar otra vez',
    'Ref: you pronoun': '(usted)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Este es su centro de soporte',
    'Ref: Tutorial: tabname': 'Peticiones',
    'Ref: Tutorial: body': 'Estamos aquí para ayudarte. Registre una solicitud por entrada.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Está solicitando el servicio en el sitio {Sitename}.',
    'Ref: Building': 'Edificio',
    'Ref: Room': 'Habitación',
    'Ref: Description help text': 'Cuanto más detallado sea, mejor podemos apoyarlo.',
    'Ref: Next': 'Próximo',
    'Ref: Your request': 'Tu petición',
    'Ref: PhoneNumber error msg': 'El número de teléfono no es válido',
    'Ref: No affected person results': 'Ninguna persona que coincida con los criterios solicitados',
    'Ref: Required': '* Requerido',
  },
  Summary: {
    'Ref: Location': 'Ubicación',
    'Ref: Attachment': 'Archivos adjuntos',
    'Ref: Submit': 'Entregar',
    'Ref: No affected person results':
      'No hay ninguna persona que coincida con los criterios solicitados',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Petición de servicio',
    'Ref: createdOn': 'Creado en',
    'Ref: summary': 'Resumen',
    'Ref: category': 'Categoría de solicitud',
    'Ref: request raised for': 'La persona afectada por esta solicitud',
    'Ref: phoneNumber': 'Número de teléfono',
    'Ref: location': 'Número de teléfono',
    'Ref: description': 'Descripción',
    'Ref: attachments': 'Archivos adjuntos',
  },
};
export default labels;
