import { SetStateAction } from 'react';

import { ISite } from '../../../Sites/types/sites.types';
import {
  FacilityMenu,
  PromotionalDiscountForItem,
  SuggestedMenuItem,
} from '../../types/orderState.types';

export type SuggestionsWidgetProps = { site: ISite };

export type useSuggestedProductTilesProps = {
  menus: FacilityMenu[];
  promotionalDiscounts?: PromotionalDiscountForItem[];
  selectedDate: string;
  suggestionsToDisplay: SuggestedMenuItem[];
  setScanSelected: (value: SetStateAction<SuggestedMenuItem | undefined>) => void;
};

export enum SuggestPlace {
  PRODUCT_LIST = 'suggestionsOnProductList',
  PRODUCT_DETAILS = 'suggestionsOnProductDetails',
  CART = 'suggestionsOnCart',
  HOME = 'suggestionsOnHome',
}
