import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { useGetSuppliersQuery } from '../../api/api';
import { Supplier } from '../../api/api.types';
import { pagePaths } from '../../config';
import { getSupplierAddress, getSupplierId } from '../../helpers/supplier.helper';
import { useSuppliersTranslation } from '../../hooks/useSuppliersTranslation';

import { RefreshIcon } from '@/assets/icons';
import { SiteIllustration } from '@/assets/illustrations';
import { SuppliersIcon } from '@/assets/index';
import Button from '@/components/atoms/Button/Button';
import { IconSize, MapCoordinates, MapMarker, MarkerIcon } from '@/components/atoms/Map/Map.types';
import MapWithMarkerDetails, {
  MarkerData,
} from '@/components/molecules/MapWithMarkerDetails/MapWithMarkerDetails';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { State } from '@/types/state.types';

import styles from './SuppliersList.module.css';

const MILES_TO_SHOW_ON_MAP = 20;

const SuppliersList = () => {
  const { label } = useSuppliersTranslation(__filename);
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const { data, isLoading, refetch } = useGetSuppliersQuery(siteId);

  let center: MapCoordinates | undefined;
  if (data) {
    center = {
      latitude: data.latitude,
      longitude: data.longitude,
    };
  }

  const createMapMarker = (supplier: Supplier): MapMarker<MarkerData> => {
    return {
      coordinates: {
        longitude: supplier.longitude,
        latitude: supplier.latitude,
      },
      icon: new MarkerIcon(supplier.iconUrl, new IconSize(48, 48)),
      id: getSupplierId(supplier),
      data: {
        title: supplier.name,
        description: getSupplierAddress(supplier),
        label: label,
        id: getSupplierId(supplier),
        'data-testid': `supplier-list-item-${getSupplierId(supplier)}`,
        imgInfo: {
          src: supplier.iconUrl,
          alt: '',
          bgColor: '#2A295C',
          padding: '24px',
        },
        addendum: (
          <a
            onClick={(e) => {
              e.stopPropagation();
            }}
            href={`${pagePaths.SupplierDetails}`.replace(':id', getSupplierId(supplier))}
          >
            {label('Ref: Supplier details')}
          </a>
        ),
      },
    };
  };

  const mapMarkers = data?.suppliers?.map(createMapMarker);

  const showMap = !!data?.latitude && !!data?.longitude;

  const refresh = () => {
    refetch();
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <SimpleFormPage
      hasBackLink={false}
      title={label('Ref: Page title')}
      actionsBarTopContent={null}
    >
      <Container>
        <Column.Main>
          {showMap && center && mapMarkers ? (
            <MapWithMarkerDetails
              markers={mapMarkers}
              center={center}
              milesToShowOnMap={MILES_TO_SHOW_ON_MAP}
              label={label}
            ></MapWithMarkerDetails>
          ) : (
            <div className={classNames(styles.container)}>
              <SuppliersIcon />
              <div className={classNames(styles.text)}>
                <div className={classNames(styles.info)}>{label('Missing supplier list')}</div>
                <div className={classNames(styles.info)}>{label('Try again later')}</div>
              </div>
              <Button
                look="secondary"
                affix={RefreshIcon}
                onClick={refresh}
                data-testid="supplier-list-refresh"
              >
                {label('Reload')}
              </Button>
            </div>
          )}
        </Column.Main>
        <Column.Complementary>
          <SiteIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default SuppliersList;
