import { Dispatch, SetStateAction } from 'react';

import { QRCodeReaderResponse } from '../../../../components/molecules/QrCode/reader.types';
import { TranslationProps } from '../../../../localization/localization.types';
import { CodeErrors } from '../../../Core/components/AccountOnboardingLocation';
import { ISite } from '../../types/sites.types';

export type ILocationListPageProps = {
  withNavBar?: boolean;
  hideAllWidgets?: boolean;
  hasBackLink?: boolean;
  title?: string;
  sites: ISite[];
  visited?: ISite[];
  layoutOptions?: any;
  pagePaths: { [pathName: string]: string };
  isLocked?: boolean;
  isSiteCodeLocked?: boolean;
  loading?: boolean;
  siteCodeError?: string;
  onSiteSelection?: (site: ISite) => void;
  onValidateSiteCode?: (code: string, closeModal: () => void) => void;
  onScanSiteCode?: (response: QRCodeReaderResponse, openErrorModal: () => void) => void;
  handleCodeError?: Dispatch<SetStateAction<CodeErrors | undefined>>;
  defaultDisplayType?: LOCATION_LIST_TYPE;
  isMapEnabled?: boolean;
  enableUserLocation: boolean;
} & TranslationProps;

export enum LOCATION_LIST_TYPE {
  MAP = 'map',
  LIST = 'list',
}
