import biteApi from '../../../services/api/biteApi';
import mockResponse from '../mocks/api';

import {
  GetLoyaltySchemesArgs,
  GetLoyaltySchemesResponse,
  GetUserLoyaltySchemesProgressArgs,
  GetUserLoyaltySchemesProgressResponse,
} from './api.types';

const loyaltyStampsApi = biteApi
  .enhanceEndpoints({ addTagTypes: ['loyaltyStamps'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getLoyaltySchemes: build.query<GetLoyaltySchemesResponse[], GetLoyaltySchemesArgs>({
        query: ({ siteId, useErrorBoundary }: GetLoyaltySchemesArgs) => ({
          url: '/v1/menu/loyalty-schemes',
          method: 'get',
          params: { siteId },
          mockData: mockResponse.getLoyaltySchemes,
          opts: { useErrorBoundary },
        }),
        providesTags: ['loyaltyStamps'],
      }),

      getUserLoyaltySchemesProgress: build.query<
        GetUserLoyaltySchemesProgressResponse[],
        GetUserLoyaltySchemesProgressArgs
      >({
        query: ({ siteId, useErrorBoundary }: GetUserLoyaltySchemesProgressArgs) => ({
          url: '/v1/user/loyalty-schemes-progress',
          method: 'get',
          params: { siteId },
          mockData: mockResponse.getUserLoyaltySchemesProgress,
          opts: { useErrorBoundary },
        }),
        providesTags: ['loyaltyStamps'],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetLoyaltySchemesQuery,
  useGetUserLoyaltySchemesProgressQuery,
  useLazyGetLoyaltySchemesQuery,
  useLazyGetUserLoyaltySchemesProgressQuery,
} = loyaltyStampsApi;

export default loyaltyStampsApi;
