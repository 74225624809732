const labels = {
  All: {
    'Ref: stamp progress': 'Progresso em direção ao próximo selo',
    Reward: 'Recompensa',
    Rewards: 'Recompensas',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Selos',
    'Ref: Fulfill conditions': 'Ganhe selos para resgatar recompensas!',
    'See Reward': 'Veja a recompensa',
    'Ref: No reward': 'Sem recompensa no momento',
  },
  RedeemableProducts: { 'Ref: Page title': 'Recompensas de lealdade' },
};
export default labels;
