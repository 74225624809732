import momentjs from 'moment';
import { useEffect, useState } from 'react';

import { TIME_FORMAT } from '../../../../constants';
import { getProductSuggestionsQuery } from '../../api';
import { orderConfig } from '../../config';
import { SuggestedMenuItem } from '../../types/orderState.types';

export const useFetchSuggestions = (
  siteId: string,
  selectedDate: string,
  menuId?: number,
  isRrMenu?: boolean,
  isMenuOrderable?: boolean,
  noOfSuggestionsToReturn?: number
) => {
  const { areSuggestionsEnabled } = orderConfig();
  const [suggestions, setSuggestions] = useState<SuggestedMenuItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async (
    siteId: string,
    date: string,
    menuId?: number,
    noOfSuggestionsToReturn?: number
  ) => {
    try {
      setIsLoading(true);

      const response = await getProductSuggestionsQuery(
        {
          siteId,
          dateTime: `${date}T${momentjs().format(TIME_FORMAT)}`,
          menuId,
          noOfSuggestionsToReturn,
        },
        { useErrorBoundary: false }
      );
      setSuggestions(response.suggestions);
    } catch {
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (areSuggestionsEnabled) {
      if (!!menuId && isRrMenu && isMenuOrderable) {
        fetchData(siteId, selectedDate, menuId, noOfSuggestionsToReturn);
      } else if (!menuId) {
        fetchData(siteId, selectedDate, noOfSuggestionsToReturn);
      } else {
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  }, [
    areSuggestionsEnabled,
    siteId,
    selectedDate,
    menuId,
    isRrMenu,
    isMenuOrderable,
    noOfSuggestionsToReturn,
  ]);

  return {
    suggestions,
    isLoading,
  };
};
