import { renderHook } from '@testing-library/react';
import { useSelector } from 'react-redux';

import { useGetLastOrderForTransactionalFeedbackQuery } from '../../api';
import { useTransactionalFeedbackSetupOptions } from '../useTransactionalFeedbackSetupOptions/useTransactionalFeedbackSetupOptions';

import { useTransactionalFeedback } from './useTransactionalFeedback';

jest.mock(
  '@/modules/Order/hooks/useTransactionalFeedbackSetupOptions/useTransactionalFeedbackSetupOptions',
  () => ({
    ...jest.requireActual(
      '@/modules/Order/hooks/useTransactionalFeedbackSetupOptions/useTransactionalFeedbackSetupOptions'
    ),
    useTransactionalFeedbackSetupOptions: jest.fn(),
  })
);

jest.mock('@/modules/Order/api/api.ts', () => ({
  ...jest.requireActual('@/modules/Order/api/api.ts'),
  useGetLastOrderForTransactionalFeedbackQuery: jest.fn(),
}));

jest.mock('react-redux', () => ({
  useSelector: jest.fn(),
}));

describe('useTransactionalFeedback with enabled feature flag and setup options', () => {
  beforeEach(() => {
    jest.resetAllMocks();
  });

  const env = global.process.env;

  beforeAll(() => {
    global.process.env = {
      ...env,
      REACT_APP_TRANSACTIONAL_FEEDBACK_ENABLED: 'true',
    };
  });

  beforeEach(async () => {
    (useTransactionalFeedbackSetupOptions as jest.Mock).mockReturnValue({
      transactionalFeedbackFromEnabled: '2022-05-14',
      transactionalFeedbackTagsEnabled: true,
    });
    (useSelector as jest.Mock).mockReturnValueOnce({
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
    });
    (useGetLastOrderForTransactionalFeedbackQuery as jest.Mock).mockReturnValue({
      data: {
        menuId: 1,
        fulfillmentType: 'DineIn',
        orderId: 16,
        orderNumber: '16',
        pickupDate: new Date().toDateString(),
        total: 12,
      },
      isLoading: false,
    });
  });

  it('should return order data and modal to be opened', () => {
    const { result } = renderHook(() => useTransactionalFeedback());

    expect(useTransactionalFeedbackSetupOptions).toHaveBeenCalledTimes(1);

    expect(result.current.shouldOpenTransactionalFeedbackModal).toBe(true);
    expect(result.current.transactionalFeedbackTagsEnabled).toBe(true);

    expect(result.current.lastOrder?.menuId).toBe(1);
    expect(result.current.lastOrder?.fulfillmentType).toBe('DineIn');
    expect(result.current.lastOrder?.orderId).toBe(16);
    expect(result.current.lastOrder?.orderNumber).toBe('16');
    expect(result.current.lastOrder?.total).toBe(12);
  });
});

describe('useTransactionalFeedback with not-enabled setup options', () => {
  beforeEach(() => {
    jest.resetAllMocks();
  });

  const env = global.process.env;

  beforeAll(() => {
    global.process.env = {
      ...env,
      REACT_APP_TRANSACTIONAL_FEEDBACK_ENABLED: 'true',
    };
  });

  beforeEach(async () => {
    (useTransactionalFeedbackSetupOptions as jest.Mock).mockReturnValue({
      transactionalFeedbackFromEnabled: undefined,
      transactionalFeedbackTagsEnabled: false,
    });
    (useSelector as jest.Mock).mockReturnValueOnce({
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
    });
    (useGetLastOrderForTransactionalFeedbackQuery as jest.Mock).mockReturnValue({});
  });

  it('should skip call to-evaluate ', () => {
    const { result } = renderHook(() => useTransactionalFeedback());

    expect(useGetLastOrderForTransactionalFeedbackQuery).toHaveBeenCalledWith(
      {
        fromDate: '',
        siteId: {
          Core: {
            context: {
              site: {
                id: 1,
              },
            },
          },
        },
      },
      {
        skip: true,
      }
    );

    expect(result.current.transactionalFeedbackTagsEnabled).toBe(false);
  });
});

describe('useTransactionalFeedback with not-enabled feature flag', () => {
  beforeEach(() => {
    jest.resetAllMocks();
  });

  const env = global.process.env;

  beforeAll(() => {
    global.process.env = {
      ...env,
      REACT_APP_TRANSACTIONAL_FEEDBACK_ENABLED: 'false',
    };
  });

  beforeEach(async () => {
    (useTransactionalFeedbackSetupOptions as jest.Mock).mockReturnValue({
      transactionalFeedbackFromEnabled: '2022-05-14',
      transactionalFeedbackTagsEnabled: true,
    });
    (useSelector as jest.Mock).mockReturnValueOnce({
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
    });
    (useGetLastOrderForTransactionalFeedbackQuery as jest.Mock).mockReturnValue({});
  });

  it('should skip call to-evaluate ', () => {
    const { result } = renderHook(() => useTransactionalFeedback());

    expect(useGetLastOrderForTransactionalFeedbackQuery).toHaveBeenCalledWith(
      {
        fromDate: '2022-05-14',
        siteId: {
          Core: {
            context: {
              site: {
                id: 1,
              },
            },
          },
        },
      },
      {
        skip: true,
      }
    );

    expect(result.current.transactionalFeedbackTagsEnabled).toBe(true);
  });
});
