import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FACILITY_TYPE } from '../../Facilities/types/types';
import { FacilityMenuItem, FacilityType } from '../types/menuSelector.types';
import { StateWithOrder } from '../types/orderState.types';

import useSortedFacilityList from '@/modules/Facilities/hooks/useSortedFacilityList';

const useFacilityMenuList = (): FacilityMenuItem[] => {
  const siteId = useSelector((state: StateWithOrder) => state.Core?.context?.site?.id);
  const menus = useSelector((state: StateWithOrder) => state.Order?.menus);

  const sortedFacilitiesList = useSortedFacilityList(siteId);

  return useMemo(() => {
    const facilities = sortedFacilitiesList
      .map((fac) => {
        return {
          id: fac.id,
          title: fac.title,
          name: fac.name,
          sequence: fac.sequence,
          imageId: fac.images?.[0],
          type: FACILITY_TYPE[fac?.facilityType?.name] || FacilityType.Undefined,
        };
      })
      .filter(
        (fac) =>
          fac.type === FacilityType.FoodNonRetail ||
          fac.type === FacilityType.FoodRetail ||
          fac.type === FacilityType.ConferenceCatering
      );

    return menus?.reduce((result: FacilityMenuItem[], menu) => {
      const menuFacility = facilities.find((fac) => fac.id === menu.facilityId);

      if (menuFacility) {
        result = [
          ...result,
          {
            ...menuFacility,
            menu: menu,
          },
        ];
      }
      return result;
    }, []);
  }, [sortedFacilitiesList, menus]);
};

export default useFacilityMenuList;
