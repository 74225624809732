const labels = {
  ReviewForm: {
    'Ref: Page title': 'La revue',
    'Ref: How was dining with us': 'Comment était de manger avec nous?',
    'Ref: Submit': 'Soumettre',
    'Ref: Filter range': 'Plage de filtre',
    'Ref: Some information is missing': 'Certaines informations sont manquantes',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Envoyer de manière anonyme',
    'Ref: Your email address': 'Votre adresse email',
    'Ref: Email field placeholder': 'Entrez votre adresse email',
    'Ref: Invalid email format': "Format d'email invalide",
  },
  SuccessPage: {
    'Ref: Body':
      "Merci d'avoir terminé cet examen, votre temps est précieux car cette revue aidera à améliorer nos services.",
    'Ref: Go to Feedback': 'Aller à la rétroaction',
    'Ref: Go to home': 'Aller à la maison',
    'Ref: Go to Facilities': 'Aller dans les installations',
    'Ref: Go to Product Details': 'Accédez aux détails du produit',
    'Ref: Go to Facility Details': "Accédez aux détails de l'installation",
  },
  FacilityPicker: {
    'Ref: Page title': 'La revue',
    'Ref: Search facility name': "Nom de l'installation de recherche",
  },
};
export default labels;
