import { TestingPropsV2 } from '../../../types';

import { FacilityMenu } from './orderState.types';

export type MenuListProps = {
  facilityMenuPath?: (
    menuId: number,
    facilityId: string,
    selectedDate: string,
    isMenuModule: boolean
  ) => string;
  menuType?: MenuType;
};

export type FacilityMenuItem = {
  id: string;
  title: string;
  name: string;
  sequence: number;
  imageId?: string;
  type: FacilityType;
  menu: FacilityMenu;
};

export enum FacilityType {
  Undefined,
  FoodRetail,
  FoodNonRetail,
  NonFoodNonRetail,
  NonFoodRetail,
  ConferenceCatering,
}

export type MenuListFiltering = {
  filter_date?: FilteringItem;
};

export type FilteringItem = {
  [key: string]: boolean;
};

export type MenuSelectorListItem = {
  id: string;
  facilityId: string;
  facilityTitle: string;
  menu: FacilityMenu;
  menuImageSource?: string;
} & TestingPropsV2;

export type MenuTileProps = {
  selectedDate: string;
  focusOnRender?: boolean;
  facilityMenuPath?: (
    menuId: number,
    facilityId: string,
    selectedDate: string,
    isMenuModule: boolean
  ) => string;
} & MenuSelectorListItem;

export type MenuSelectorListItemAction = {
  element: JSX.Element;
  action: () => void | (() => void);
};

export type MenuSelectorListItemImgInfo = {
  src?: string;
  alt: string;
};

export type DiscountTiersStatus = {
  mustSetPricingTier: boolean | undefined;
};

export type DiscountTiersModel = {
  pricingTiers: DiscountTier[] | undefined;
};

export type DiscountTier = {
  id: number;
  name: string;
};

export type FacilityMappingResult = {
  facilityId: string;
};

export type SiteMappingResult = {
  siteId: string;
};

export type IMenuSelectorState = {
  tier?: number;
  hasMenuFetched: boolean;
  setDiscountTierStatus: boolean;
};

export enum MenuSelectorActionType {
  UPDATE_TIER = 'UPDATE_TIER',
  UPDATE_HAS_MENU_FETCHED = 'UPDATE_HAS_MENU_FETCHED',
  UPDATE_SET_DISCOUNT_TIER_STATUS = 'UPDATE_SET_DISCOUNT_TIER_STATUS',
}

export enum MenuType {
  All,
  Orderable,
  NonOrderable,
}

type IMenuSelectorUpdateTierAction = {
  tier: number;
  type: MenuSelectorActionType.UPDATE_TIER;
};

type IMenuSelectorUpdateHasMenuFetchedAction = {
  hasMenuFetched: boolean;
  type: MenuSelectorActionType.UPDATE_HAS_MENU_FETCHED;
};

type IMenuSelectorUpdateSetDiscountTierStatusAction = {
  setDiscountTierStatus: boolean;
  type: MenuSelectorActionType.UPDATE_SET_DISCOUNT_TIER_STATUS;
};

export type MenuSelectorAction =
  | IMenuSelectorUpdateTierAction
  | IMenuSelectorUpdateHasMenuFetchedAction
  | IMenuSelectorUpdateSetDiscountTierStatusAction;

export type BasicMenuInfo = {
  id: number;
  date: string;
  name: string;
};

export interface IDateFilter {
  menu: BasicMenuInfo;
  id: string;
}

export type MenuSelectorRouteParams = {
  locationId?: string;
  outletId?: string;
  barcode?: string;
  menuId?: string;
  date?: string;
};
