import { renderHook } from '@testing-library/react';
import { useSelector } from 'react-redux';

import { useFetchLoyaltySchemes } from '../useFetchLoyaltySchemes';
import { useFetchLoyaltyStampsProgress } from '../useFetchLoyaltyStampsProgress';

import { useLoyaltyReward } from './useLoyaltyReward';

jest.mock('@/modules/Order/hooks/useFetchLoyaltySchemes', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltySchemes'),
  useFetchLoyaltySchemes: jest.fn(),
}));

jest.mock('@/modules/Order/hooks/useFetchLoyaltyStampsProgress', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltyStampsProgress'),
  useFetchLoyaltyStampsProgress: jest.fn(),
}));

jest.mock('react-redux', () => ({
  useSelector: jest.fn(),
}));

const menuId = 160;

const loyaltySchemes = [
  {
    id: 1,
    name: 'Scheme 1',
    description: 'Description 1',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 2,
    name: 'Scheme 2',
    description: 'Description 2',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2103,
        foodItemId: 1983,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2103,
        foodItemId: 1983,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 3,
    name: 'Scheme 3',
    description: 'Description 3',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 4,
    name: 'Scheme 4',
    description: 'Description 4',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
  },
  {
    id: 5,
    name: 'Scheme 5',
    description: 'Scheme with no restriced menus to redeem from',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2105,
        foodItemId: 1985,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2105,
        foodItemId: 1985,
      },
    ],
    menuIdsProvidingProductsRedeemable: [],
    menuIdsProvidingProductsProvidingStamp: [],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
  },
];

const userLoyaltySchemesProgress = [
  {
    loyaltySchemeId: 1,
    stampsCollected: 23,
  },
  {
    loyaltySchemeId: 2,
    stampsCollected: 7,
  },
  {
    loyaltySchemeId: 3,
    stampsCollected: 11,
  },
  {
    loyaltySchemeId: 4,
    stampsCollected: 11,
  },
  {
    loyaltySchemeId: 5,
    stampsCollected: 11,
  },
];

describe('useLoyaltyReward hook with empty cart', () => {
  beforeEach(() => {
    jest.resetAllMocks();
  });

  beforeEach(async () => {
    (useFetchLoyaltySchemes as jest.Mock).mockReturnValue({
      schemes: loyaltySchemes,
      isLoading: false,
    });
    (useFetchLoyaltyStampsProgress as jest.Mock).mockReturnValue({
      userProgress: userLoyaltySchemesProgress,
      isLoading: false,
    });
    (useSelector as jest.Mock).mockReturnValueOnce({
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
      Order: {
        cart: {
          date: '2023-07-17T22:00:00.000Z',
          facilityId: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
          submissionTrackingId: '4d3146d5-50e8-4d42-ae4b-c2e8ebe22721',
          menuId: menuId,
          siteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
          menuPortionItems: [
            {
              id: 'a41f5e0d-d7eb-4331-a0f1-9cdda3d865e4',
              img: 'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122601-1579502896-Pineapple_copy.png',
              menuItemId: 16819050,
              name: 'Abacaxi pineapple',
              price: 2.4,
              quantity: 2,
              uomId: 30552,
              foodItemId: 28509,
              description: '',
              isVegan: true,
              isVegetarian: true,
              genericCategory: 'Fruit',
            },
          ],
          moment: 'All day groceries',
        },
      },
    });
  });

  it('should return two free items available to redeem from mocks (scheme 1)', () => {
    const productPortionId = { uomId: 2102, foodItemId: 1982 };
    const { result } = renderHook(() => useLoyaltyReward([productPortionId], menuId, false));

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(1);
    expect(portionsFreeItemsInfo[0].portion).toBe(productPortionId);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toBe(2);
  });

  it('should return 0 redeemable item for item with unsufficient fund (scheme 2)', () => {
    const productPortionId = { uomId: 2103, foodItemId: 1983 };
    const { result } = renderHook(() => useLoyaltyReward([productPortionId], menuId, false));

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(1);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toBe(0);
  });

  it('should return 2 redeemable items gained from two different schemes (3&4)', () => {
    const productPortionId = { uomId: 2103, foodItemId: 1983 };
    const { result } = renderHook(() => useLoyaltyReward([productPortionId], menuId, false));

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(1);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toBe(0);
  });

  it('should return zero to redeem from mocks (scheme 6) for scheme without progress', () => {
    const productPortionId = { uomId: 2106, foodItemId: 1986 };
    const { result } = renderHook(() => useLoyaltyReward([productPortionId], menuId, false));

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(1);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toBe(0);
  });

  it('should return redeemable info for more than one portion', () => {
    const productPortionId1 = { uomId: 2102, foodItemId: 1982 };
    const productPortionId2 = { uomId: 2103, foodItemId: 1983 };

    const { result } = renderHook(() =>
      useLoyaltyReward([productPortionId1, productPortionId2], menuId, false)
    );

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(2);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toBe(2);
    expect(portionsFreeItemsInfo[1].numberOfFreeItems).toBe(0);
  });
});

describe('useLoyaltyReward hook with redeemable item in cart', () => {
  beforeEach(() => {
    jest.resetAllMocks();
  });

  beforeEach(async () => {
    (useFetchLoyaltySchemes as jest.Mock).mockReturnValue({
      schemes: loyaltySchemes,
      isLoading: false,
    });
    (useFetchLoyaltyStampsProgress as jest.Mock).mockReturnValue({
      userProgress: userLoyaltySchemesProgress,
      isLoading: false,
    });
    (useSelector as jest.Mock).mockReturnValueOnce({
      Core: {
        context: {
          site: {
            id: 1,
          },
        },
      },
      Order: {
        cart: {
          date: '2023-07-11T22:00:00.000Z',
          facilityId: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
          submissionTrackingId: '5d19613e-1bc7-4442-b361-6cb7742af193',
          menuId: 160,
          siteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
          menuPortionItems: [
            {
              id: '53d264ca-02d6-480b-a348-e8d4a5ce8b90',
              img: 'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122601-1579502896-Pineapple_copy.png',
              menuItemId: 16814831,
              name: 'Abacaxi pineapple',
              price: 2.4,
              quantity: 1,
              uomId: 2102,
              foodItemId: 1982,
              description: '',
              isVegan: true,
              isVegetarian: true,
              genericCategory: 'Fruit',
            },
          ],
          moment: 'Fruits and Vegetables',
          selectedFulfillmentType: {
            id: 10214,
            type: 'PickupOption',
          },
          pickupLocations: [
            {
              pickupSpotId: 2195,
              order: 0,
              name: 'bite timeset',
            },
          ],
          pickupInformation: {
            pickupSpotId: null,
            pickupSpotName: null,
            pickupTimeSlotId: null,
            pickupTime: null,
          },
        },
      },
    });
  });

  it('should return one free item available to redeem from mocks (scheme 1)', () => {
    const productPortionId = { uomId: 2102, foodItemId: 1982 };
    const { result } = renderHook(() => useLoyaltyReward([productPortionId], menuId, false));

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(1);
    expect(portionsFreeItemsInfo[0].portion).toBe(productPortionId);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toBe(1);
  });

  it('should return zero free item available to redeem from mocks (scheme 1) for menuId not listed in menuIdsProvidingProductsRedeemable', () => {
    const productPortionId = { uomId: 2102, foodItemId: 1982 };
    const { result } = renderHook(() => useLoyaltyReward([productPortionId], 987, false));

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(1);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toEqual(0);
  });

  it('should return 1 to redeem from mocks (scheme 5) for scheme with empty menuIdsProvidingProductsRedeemable', () => {
    const productPortionId = { uomId: 2105, foodItemId: 1985 };
    const { result } = renderHook(() => useLoyaltyReward([productPortionId], menuId, false));

    const portionsFreeItemsInfo = result.current.portionsFreeItemsInfo;
    expect(portionsFreeItemsInfo.length).toBe(1);
    expect(portionsFreeItemsInfo[0].numberOfFreeItems).toEqual(1);
  });
});
