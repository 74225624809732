import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MenuSearchResultsProps } from './MenuSearchResults.types';

import { TileSkeleton } from '@/components/molecules/Tile';
import MenuTile from '@/modules/Order/components/MenuTile/MenuTile';
import { getFacilityMenuPath } from '@/modules/Order/helpers/order.helper';
import { useFacilityMenuImage } from '@/modules/Order/hooks/useFacilityMenuImage/useFacilityMenuImage';
import { StateWithOrder } from '@/modules/Order/types/orderState.types';

export const MenuSearchResults = ({
  menus,
  label,
  menuDate,
  focusedSearchResultIndex,
}: MenuSearchResultsProps) => {
  const facilitiesMap = useSelector((state: StateWithOrder) => state.Facilities?.data);

  const facilityImagesInfo = useMemo(() => {
    const menuFacilities = menus
      .map((menu) => facilitiesMap[menu.facilityId])
      .filter((facility) => !!facility);

    return menuFacilities.map((facility) => ({
      facilityId: facility.id,
      imageId: facility.images[0],
    }));
  }, [menus, facilitiesMap]);

  const { getMenuImage, isLoading: isLoadingImages } = useFacilityMenuImage(facilityImagesInfo);

  return (
    <>
      {menus.map((menu, index) => {
        const props = {
          id: menu.id.toString(),
          facilityId: menu.facilityId,
          facilityTitle: facilitiesMap[menu.facilityId]?.title,
          menu: menu,
          label: label,
          facilityMenuPath: getFacilityMenuPath,
          selectedDate: menuDate,
          menuImageSource: getMenuImage(menu.facilityId, menu.logoUrl),
          focusOnRender: index === focusedSearchResultIndex,
        };
        return <MenuTile data-testid={menu.id.toString()} key={menu.id} {...props} />;
      })}
      {isLoadingImages && (
        <>
          {[...Array(3).keys()].map((key) => (
            <TileSkeleton
              key={key}
              withoutActions
              withoutChildren={false}
              className={'mb-M'}
              data-testid={`skeleton-placeholder-${key}`}
            />
          ))}
        </>
      )}
    </>
  );
};
