import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../components/atoms/Button';
import { pagePaths } from '../config';
import { getPrice } from '../helpers/order.helper';
import { Cart } from '../types/orderState.types';

import styles from './CartButton.module.css';

const CartButton = ({
  cart,
  cartTotalPrice,
  children,
  languageCode,
  isoCode,
}: {
  cart?: Cart;
  cartTotalPrice?: number;
  languageCode: string;
  isoCode?: string;
  children?: React.ReactNode;
}) => {
  const history = useHistory();

  const { count } = useMemo(() => {
    const count = cart?.menuPortionItems?.reduce((result, item) => {
      return result + item.quantity;
    }, 0);

    return { count: count || 0 };
  }, [cart]);

  const handleRedirectToCart = useCallback(() => {
    history.push(pagePaths.Cart);
  }, [history]);

  const menuItemsTotalPrice = getPrice(cartTotalPrice || 0, languageCode, isoCode!);

  return (
    <Button
      onClick={handleRedirectToCart}
      isMultiline={false}
      data-testid='cart-redirect'
      {...(count < 1 ? { disabled: true } : {})}
    >
      <span className={styles.cartButtonLabelWrapper}>
        <span>{children}</span>
        {menuItemsTotalPrice && count > 0 && (
          <>
            <span>•</span>
            <span>{menuItemsTotalPrice}</span>
          </>
        )}
      </span>
    </Button>
  );
};

export default CartButton;
