import { ContentState, IContentType } from '../types';

import { ACTIONS, ContentActionType } from './actions.types';

const defaultState = {
  data: {
    responseData: undefined,
    responseStatus: undefined,
  },
  list: [],
  locks: {
    getList: false,
    getOne: false,
    postReaction: false,
  },
};

const contentReducer = (state: ContentState = defaultState, action: ContentActionType) => {
  switch (action.type) {
    case ACTIONS.RESET_STATE:
      return defaultState;
    case ACTIONS.CONTENT_FETCHING_HOME:
      return {
        ...state,
        locks: {
          ...state.locks,
          getList: true,
        },
      };
    case ACTIONS.CONTENT_FETCHED_HOME:
      return {
        ...state,
        locks: {
          ...state.locks,
          getList: false,
        },
      };
    case ACTIONS.CONTENT_SUCCESS_HOME: //todo: review, as home is used for feed
      return {
        ...state,
        list: action.list.filter(
          (contentPiece) => contentPiece.type !== IContentType.INMOMENT_SURVEY
        ),
      };
    default:
      return state;
  }
};

export default contentReducer;
