import { getImageSrcFromId } from '@/helpers/images/images.helper';
import { isValidHttpUrl } from '@/helpers/urlValidator';
import { FacilityImage } from '@/modules/Review/types/reviewFacility.types';

export const getImageData = async (
  menuName: string,
  menuLogoUrl: string | undefined,
  facilityId: string,
  facilityImageId: string | undefined
): Promise<FacilityImage | undefined> => {
  if (menuLogoUrl && isValidHttpUrl(menuLogoUrl)) {
    return {
      image: menuLogoUrl,
      key: menuName,
    };
  }

  if (facilityImageId) {
    const imageData = await getImageSrcFromId(facilityImageId);

    return {
      image: imageData,
      key: facilityId,
    };
  }
};
