const labels = {
  All: {
    'Ref: Assembled': 'Assemblato',
    'Ref: Submitted': 'Presentato',
    'Ref: Fulfilled': 'Soddisfatto',
    'Ref: Completed': 'Completato',
    'Ref: Paid': 'Pagato',
    'Ref: Approved': 'Approvato',
    'Ref: Ready For Pickup': 'Pronto per essere ritirato',
    'Ref: Delivered': 'Consegnato',
    'Ref: Received': 'Ricevuto',
    'Ref: Rejected': 'Respinto',
    'Ref: Payment Error': 'Errore di pagamento',
    'Ref: Pending Payment': 'In attesa di Pagamento',
    'Ref: Preparing': 'Preparazione',
    'Ref: Refunded': 'Rimborsato',
    'Ref: Cancelled By User': "Annullato dall'utente",
    'Ref: Refund Requested': 'Rimborso richiesto',
    'Ref: Refund Failed': 'Rimborso fallito',
    'Ref: Payment Processing': 'Processo di pagamento',
    'Ref: Not Paid': 'Non pagato',
    'Ref: Total:': 'Totale:',
    'Ref: Pickup': 'Raccogliere',
    'Ref: Eat-in': 'Mangiare in',
    'Ref: Scan&Go': 'Scansionare e andare',
    'Ref: Fulfillment Type': 'Tipo di adempimento',
    'Ref: Delivery': 'Consegna',
    'Ref: You have no future orders': 'Non hai ordini futuri',
    'Ref: You have no past orders': 'Non hai ordini passati',
    'Ref: You have no orders': 'Non hai ordini',
    'Ref: All orders': 'Tutti gli ordini',
    'Ref: Not past orders': 'Ordini in arrivo',
    'Ref: Past orders': 'Ordini passati',
    'Ref: Vegan': 'Vegano',
    'Ref: Vegeterian': 'Vegetariano',
    'Ref: See item': "Vedi l'oggetto",
    'Ref: Activated': 'Attivato',
    'Ref: No suggestions': 'Nessun suggerimento trovato',
    'Ref: Get your receipt': 'Ottieni la ricevuta',
    'Ref: Print': 'Stampa la ricevuta',
    'Ref: Email': 'Ricevi la ricevuta via e-mail',
    'Ref: Receipt Modal message': 'Come vorresti ottenere la ricevuta?',
    'Ref: Confirm': 'Confermare',
    'Ref: Your email address': 'Il tuo indirizzo di posta elettronica',
    Suggestion: 'Suggerimento',
    'Ref: Loyalty Reward': 'Ricompensa di lealtà',
    'Ref: Equation for stamps per item': '1 acquisto = 1 timbro',
    'Ref: Paid by employer': "L'importo pagato dal tuo datore di lavoro",
    'Ref: Paid by user': "L'importo pagato da te",
  },
  ProductsList: {
    'Ref: Page title': 'Elenco dei prodotti',
    'Ref: Facility details': 'Dettagli della struttura',
    'Ref: Search products list': 'Articoli di ricerca',
    'Ref: filter label: menus': 'Menù',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoria',
    'Ref: all': 'Tutti',
    'Ref: today': 'Oggi',
    'Ref: tomorrow': 'Domani',
    'Ref: see more': 'Vedi altro',
    'Ref: see less': 'Vedere di meno',
    'Ref: reset': 'Ripristina',
    'Ref: View in cart': 'Visualizza nel carrello',
    'Ref: You have changed location': 'Hai cambiato posizione',
    'Ref: You are now at': 'Ora sei a',
    'Ref: Menu card': 'Menu',
    'Ref: Table': 'Numero della tabella',
    'Service unavailable': 'Servizio non disponibile',
    'Ref: Ordering unavailable':
      "Attento! L'ordinazione in questo sito non è disponibile al momento, prova più tardi.",
    'Ref: Filters': 'Filtri (allergeni e altro)',
  },
  ProductScanner: {
    'Ref: cart': 'Carrello',
    'Ref: Product Scanned':
      'è stato aggiunto al carrello. Puoi continuare a scansionare i codici a barre per aggiungerli nel carrello.',
    'Ref: Scan or adjust':
      'è stato aggiunto al carrello. Regolare la quantità o scansionare un altro prodotto.',
    'Ref: Product Not Matched': 'Il codice a barre non è stato riconosciuto. Per favore riprova',
    'Ref: Scan article code': 'Ccode a barre di scansione',
    'Ref: Native error product cannot be added to cart':
      "L'elemento da un menu diverso o dalla parte diurna non può essere aggiunto al carrello",
    'Ref: product scanner placeholder':
      'Si prega di scansionare il codice a barre per aggiungerlo al carrello',
  },
  Order: { 'Ref: Page title': 'Ordine' },
  MenuSelector: {
    'Ref: Menu Page title': 'menu',
    'Ref: today': 'Oggi',
    'Ref: tomorrow': 'Domani',
    'Ref: Search products list': 'Articoli di ricerca',
    'Ref: You have changed location': 'Hai cambiato posizione',
    'Ref: You are now at': 'Ora sei a',
  },
  ProductDetails: {
    'Ref: Page title': 'Dettagli del prodotto',
    'Ref: Serving Size': 'Porzioni',
    'Ref: Nutrition': 'Informazioni nutrizionali',
    'Ref: Nutrition adults': 'Gli adulti hanno bisogno di circa 2000 kcal al giorno',
    'Ref: Nutrition Calories': 'calorie',
    'Ref: Nutrition Calcium': 'Calcio',
    'Ref: Nutrition Fat': 'Grasso',
    'Ref: Nutrition Saturated Fat': 'Grassi saturi',
    'Ref: Nutrition Carbohydrates': 'carboidrati',
    'Ref: Nutrition Sugar': 'Zucchero',
    'Ref: Nutrition Protein': 'Proteina',
    'Ref: Nutrition Sodium': 'Sodio',
    'Ref: Nutrition cholesterol': 'Colesterolo',
    'Ref: Nutrition dietaryFiber': 'Fibra alimentare',
    'Ref: Nutrition iron': 'Ferro da stiro',
    'Ref: Nutrition potassium': 'Potassio',
    'Ref: Nutrition vitaminA': 'Vitamina A.',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Additives': 'Additivi',
    'Ref: Emission': 'emissioni di CO2',
    'Ref: This product generates': 'Questo prodotto genera',
    'Ref: of CO2': 'di CO2.',
    'Ref: emissions, which equals a': 'emissioni, che equivalgono a a',
    'Ref: car trip': "viaggio d'auto",
    'Ref: Ingredients': 'Ingredienti della ricetta',
    'Ref: Allergens': 'Allergeni',
    'Ref: Always there': 'Sempre lì',
    'Ref: Allergens selected': 'Allergeni selezionati sul filtro:',
    'Ref: minimum required': 'minimo richiesto',
    'Ref: you have to add at least': 'Devi aggiungere almeno',
    'Ref: ingredients': 'ingredienti',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Libero',
    'Ref: Based on customization': 'Basato sulla tua personalizzazione',
    'Ref: Additives eggs': 'uova',
    'Ref: Additives fish': 'pesce',
    'Ref: Additives milk': 'latte',
    'Ref: Additives nuts': 'noccioline',
    'Ref: Additives wheat': 'Grano',
    'Ref: Additives peanuts': 'arachidi',
    'Ref: Additives soy beans': 'fagioli di soia',
    'Ref: Additives sesame seeds': 'semi di sesamo',
    'Ref: Review': 'Revisione',
    'Ref: Per portion': 'Per porzione',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergene selezionato sul filtro di ricerca',
    'Ref: Fitbit Log': 'Registro a fitbit',
    'Ref: age verification required on delivery': "Verifica dell'età richiesta alla consegna",
    'Ref: alcohol content notification':
      "Posizionando e ordina per uno di questi articoli, dichiari di avere 18 anni o più. L'abuso di alcol è pericoloso per la tua salute.",
    'Ref: default': 'Predefinito',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} guadagnato se ordini questo prodotto',
    'Ref: Loyalty Card Description':
      'Il {stamp_form} verrà aggiunto alla seguente lealtà {program_form}: <b> {zzz} </b>',
    'Ref: About Loyalty stamps':
      'Il programma di fedeltà ti consente di raccogliere francobolli per premi.',
    'Ref: Loyalty Modal Description':
      'Quando acquisti <b> {product_name}, 1 timbro </b> verrà aggiunto al <b> {zzz} </b> {program_form}',
    'Ref: and': 'E',
    'Ref: Stamp': 'Francobollo',
    'Ref: Stamps': 'Francobolli',
    'Ref: Program': 'Programma',
    'Ref: Programs': 'Programmi',
    'Ref: Loyalty Stamps': 'Francobolli fedeltà',
    'Ref: See Loyalty Stamps': 'Vedi francobolli fedeltà',
    Favorite: 'Preferito',
    'Ref: In favorites': "Il prodotto è già nell'elenco dei preferiti. Fare clic per rimuoverlo.",
    'Ref: Not in favorites':
      "Il prodotto non è ancora nell'elenco dei preferiti. Fare clic per aggiungerlo.",
    'Ref: Added to favorites': "Il prodotto è stato aggiunto all'elenco dei preferiti.",
    'Ref: Removed from favorites': "Il prodotto è stato rimosso dall'elenco dei preferiti.",
    'Ref: From': 'Da',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Cambiamento nel carrello',
    'Ref: Popup header for customizable item qty update': 'Questo articolo è personalizzabile',
    'Ref: add new version': 'Aggiungi nuova versione',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Aggiorna la quantità di un elemento personalizzabile direttamente dal carrello.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Aggiorna la quantità di un elemento personalizzabile direttamente dal carrello o aggiungi una nuova versione.',
    'Ref: another context body':
      'Per aggiungere elementi da questo sito, struttura, momento/menu o data è necessario cancellare il carrello',
    'Ref: cancel': 'Annulla',
    'Ref: add to cart': 'Aggiungi al carrello',
    'Ref: view in cart': 'Carrello',
    'Ref: Products in cart': 'Numero di {Product_Name} in CART: {Product_Quantity}',
    'Ref: Customize': 'personalizzare',
    'Ref: Add': 'Aggiungere',
    'Ref: Free': 'Gratuito',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Annulla',
    'Ref: notice': 'Avviso',
    'Ref: clear cart': 'Cancella il carrello',
    'Ref: Cart addition error message':
      'Attualmente hai elementi nel carrello da {from_current}. Per aggiungere elementi da {from_selected} è necessario cancellare il carrello',
  },
  Cart: {
    'Ref: Page title': 'Carrello',
    'Ref: The cart is empty': 'Il carrello è vuoto',
    'Ref: Clear cart': 'Cancella il carrello',
    'Ref: Continue shopping': 'Continua a fare acquisti',
    'Ref: Start shopping': 'Inizia a fare shopping',
    'Ref: Today': 'In data odierna',
    'Ref: Tomorrow': 'Domani',
    'Ref: Tax:': 'Imposta:',
    'Ref: Promotion:': 'Promozione:',
    'Ref: Total without promotion:': 'Totale senza promozione:',
    'Ref: Delivery:': 'Consegna:',
    'Ref: Pickup informations': 'Informazioni di raccolta',
    'Ref: Location': 'Posizione',
    'Ref: Time': 'Tempo',
    'Ref: Phone': 'Il tuo numero di telefono',
    'Ref: Instructions': 'Istruzioni',
    'Ref: Please select': 'Seleziona',
    'Ref: Enter special requests': 'Inserisci qui richieste speciali',
    'Ref: notice': 'Avviso',
    'Ref: Product is no more available':
      'Mi dispiace ma sembra che alcuni prodotti non siano più disponibili. Per favore controlla il tuo carrello.',
    'Ref: ok': 'Ok',
    'Ref: Validate': 'Convalida il mio ordine',
    'Ref: Pay now': 'Paga ora',
    'Ref: Charge to my department': 'Accusa al mio dipartimento',
    'Ref: Pay on pickup': 'Paga questi articoli al ritiro',
    'Ref: Accept terms of sale prefix': 'accetto',
    'Ref: Accept terms of sale': 'Questi termini di vendita',
    'Ref: Continue': 'Continua',
    'Ref: Please login or sign up to place an order': 'Accedi o iscriviti per effettuare un ordine',
    'Ref: Accept terms of sale suffix': 'e ho letto e capito.',
    'Ref: Payment modality': 'Metodo di pagamento',
    'Ref: Other information': 'Altre informazioni',
    'Ref: Eat-in': 'Mangiare in',
    'Ref: Pickup': 'Raccogliere',
    'Ref: Table': 'Numero della tabella',
    'Ref: Required fields': 'Campi richiesti',
    'Service unavailable': 'Servizio non disponibile',
    'Ref: Cannot order':
      'Mi dispiace, al momento non puoi fare un ordine in questo sito. Si prega di contattare il ristorante per ulteriori informazioni sugli orari di apertura e sui servizi disponibili.',
    'Ref: Order Unavailable':
      'Ordine per la data scelta, il tipo di adempimento e la posizione non sono più disponibili!',
    'Ref: No Availability': 'Niente più disponibilità',
    'Ref: Continue on your device': 'Continua sul tuo dispositivo',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} guadagnato se completi il ​​tuo ordine',
    'Ref: Loyalty Card Description':
      'Il {stamp_form} verrà aggiunto alla seguente lealtà {program_form}: <b> {zzz} </b>',
    'Ref: About Loyalty stamps':
      'Il programma di fedeltà ti consente di raccogliere francobolli per premi.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> guadagnato se completi il ​​tuo ordine:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> Aggiunto per <b> {scheme_names} </b>',
    'Ref: Stamp': 'Francobollo',
    'Ref: Stamps': 'Francobolli',
    'Ref: Program': 'Programma',
    'Ref: Programs': 'Programmi',
    'Ref: Loyalty Stamps': 'Francobolli fedeltà',
    'Ref: See Loyalty Stamps': 'Vedi francobolli fedeltà',
    'Ref: Loyalty Info': 'Informazioni sulla fidelizzazione',
    'Ref: Email address': 'Indirizzo e-mail',
    'Ref: and': 'E',
    'Ref: Order minimum amount': 'Scusa, importo minimo di pagamento è {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Continua sul tuo dispositivo',
    'Ref: Scan QR to continue':
      'Per favore, scansiona il codice QR per continuare il viaggio sul dispositivo.',
  },
  OrderHistory: { 'Ref: Orders': 'Ordini', 'Ref: Page Title': 'i miei ordini' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Prezzo unitario',
    'Ref: Download': 'Scarica la ricevuta',
    'Ref: Download error':
      'Si è verificato un errore di download. Prova a scaricare la ricevuta più tardi',
    'Ref: Order details title': "Dettagli dell'ordine",
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Grazie! Il tuo ordine è ora confermato',
    'Ref: Text for pickup':
      "Puoi seguire il suo stato attraverso la cronologia dell'ordine. Se le tue notifiche sono attivate, ti aggiorneremo quando è pronto per il ritiro.",
    'Ref: Success Order Title': 'Grazie per il vostro ordine',
    'Ref: Success Order Description':
      'Si prega di pagare al checkout per convalidare il tuo ordine.',
    'Ref: Success Order ID': 'Ordine id',
    'Ref: Success Order Action': 'Nuovo ordine',
    'Ref: Text for dinein':
      'Il tuo ordine dovrebbe essere servito presto al tuo tavolo. Basta chiedere a qualsiasi membro del nostro personale amichevole il tuo ordine per mangiare.',
    'Ref: Text for scan&go': "Il tuo ordine è disponibile nella cronologia dell'ordine",
    'Ref: Text for delivery':
      "Puoi monitorare il suo stato nella cronologia dell'ordine. Se le tue notifiche sono abilitate, ti informeremo quando il tuo ordine è pronto.",
    'See details': 'Guarda i detagli',
    'Go to home': 'Vai a casa',
  },
  FailOrder: {
    'Go to home': 'Vai a casa',
    'Ref: Kiosk Fail Title': 'Oops! Qualcosa è andato storto!',
    'Ref: Kiosk Fail Description':
      'Sfortunatamente, il tuo ordine non ha avuto successo. Riprova o chiedi a un membro dello staff di aiutarti.',
    'Ref: Kiosk Fail Back': 'torna alla PAGINA precedente',
    'Ref: Kiosk Fail Action': 'Cancella la sessione e ordina di nuovo',
    'Ref: Error in cart validation':
      "Si è verificato un errore durante l'ordine. Si prega di contattare la struttura per risolvere il problema.",
    'Facility contact': 'Contatto della struttura',
    'Ref: Ordering failed': "L'ordinamento non è riuscito",
    'Ref: Order confirmed': 'Ordine confermato',
  },
  TableCheckIn: {
    'Welcome at': 'Benvenuto a',
    Table: 'Tavolo:',
    'Ref: list title': 'Ordini recenti per oggi',
    'See the menu': 'Vedere il menu',
    'Table Check-in': 'Check-in della tabella',
    'Ref #': 'Ref #',
    Checked: 'Controllato',
    'Check in': 'Registrare',
    'See details': 'Guarda i detagli',
    'Ref: You have changed location': 'Hai cambiato posizione',
    'Ref: You are now at': 'Ora sei a',
    'Order number': "Numero d'ordine",
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promozione applicata',
    'Ref: Promotion applied description': 'Beneficiate una promozione dal tuo carrello.',
    'Ref: Promotion deleted title': 'Promozione eliminata',
    'Ref: Promotion deleted description':
      'Non beneficiate più la promozione perché non riempi più le condizioni o la promozione è scaduta.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Scansionare e andare', 'Ref: View only': 'Visualizzare solamente' },
  ProductTile: {
    'Ref: Allergens': 'Allergeni',
    'Ref: Presence of allergen': 'Presenza di allergene',
    'Ref: Scan': 'Scansione',
    'Ref: From': 'Da',
    'Ref: Already in product favorite list. Click to remove it.':
      "Già nell'elenco preferito del prodotto. Fare clic per rimuoverlo.",
    'Ref: Currently not added to product favorite list. Click to add it.':
      "Attualmente non aggiunto all'elenco preferito del prodotto. Fare clic per aggiungerlo.",
    'Ref: Free': 'Gratuito',
  },
  OrderSummary: {
    'Ref: item': 'elemento',
    'Ref: items': 'Oggetti',
    'Ref: Clear': 'Chiaro',
    'Ref: Cart': 'Carrello',
    'Ref: Subtotal': 'totale parziale',
    'Ref: Total': 'Totale',
    'Ref: Tax': 'Imposta',
    'Ref: Promotion': 'Promozione',
    'Ref: Total without promotion': 'Totale senza promozione',
    'Ref: Number of product in cart': 'numero di prodotti nel carrello',
    'Ref: Delivery': 'Consegna',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegano', 'Ref: Vegeterian': 'Vegetariano' },
  OrderInfoCard: {
    'Ref: Header': 'Ordinato per',
    'Ref: from': 'da',
    'Ref: location': 'Posizione',
    'Ref: Phone': 'Il tuo numero di telefono',
    'Ref: Instructions': 'Istruzioni',
    'Ref: Order type': "Tipo d'ordine",
    'Ref: Pickup': 'Raccogliere',
    'Ref: Eat-in': 'Mangiare in',
    'Ref: Scan&Go': 'Scansionare e andare',
  },
  ReorderWidget: {
    'Ref: No items': 'Nessun ordine ancora fatto. Acquistare ora!',
    'Ref: Title': 'Ordina di nuovo',
  },
  MenusWidget: {
    'Ref: no menu': 'Nessun menu per il momento',
    'Ref: Modal title': 'Menu per la giornata',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Sconti disponibili' },
  PromotionInfoItem: { 'Ref: Activate': 'Attivare' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'I tuoi ordini', 'Ref: Widget Title': 'i miei ordini' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Suggerimento per la giornata',
    'Ref: Any menu': 'Qualsiasi menu',
    Other: 'Altro',
    'Ref: Suggestions calendar button': 'Calendario dei suggerimenti',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Porzioni',
    'Ref: Nutrition': 'Informazioni nutrizionali',
    'Ref: Nutrition adults': 'Gli adulti hanno bisogno di circa 2000 kcal al giorno',
    'Ref: Nutrition Calories': 'calorie',
    'Ref: Nutrition Calcium': 'Calcio',
    'Ref: Nutrition Fat': 'Grasso',
    'Ref: Nutrition Saturated Fat': 'Grassi saturi',
    'Ref: Nutrition Carbohydrates': 'carboidrati',
    'Ref: Nutrition Sugar': 'Zucchero',
    'Ref: Nutrition Protein': 'Proteina',
    'Ref: Nutrition Sodium': 'Sodio',
    'Ref: Nutrition cholesterol': 'Colesterolo',
    'Ref: Nutrition dietaryFiber': 'Fibra alimentare',
    'Ref: Nutrition iron': 'Ferro da stiro',
    'Ref: Nutrition potassium': 'Potassio',
    'Ref: Nutrition vitaminA': 'Vitamina A.',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Ingredients': 'Ingredienti della ricetta',
    'Ref: Allergens': 'Allergeni',
    'Ref: Always there': 'Sempre lì',
    'Ref: Allergens selected': 'Allergeni selezionati sul filtro:',
    'Ref: minimum required': 'minimo richiesto',
    'Ref: ingredients': 'ingredienti',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Libero',
    'Ref: Based on customization': 'Basato sulla tua personalizzazione',
    'Ref: Additives eggs': 'uova',
    'Ref: Additives fish': 'pesce',
    'Ref: Additives milk': 'latte',
    'Ref: Additives nuts': 'noccioline',
    'Ref: Additives wheat': 'Grano',
    'Ref: Additives peanuts': 'arachidi',
    'Ref: Additives soy beans': 'fagioli di soia',
    'Ref: Additives sesame seeds': 'semi di sesamo',
    'Ref: Review': 'Revisione',
    'Ref: Per portion': 'Per porzione',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} gratuitamente',
    'Ref: Number of free items': 'Numero di articoli gratuiti: {number_of_free_items}',
    'Ref: Allergen selected': 'Allergene selezionato sul filtro di ricerca',
    'Ref: Nutritional info': 'Informazioni nutrizionali',
    'Ref: Info': 'Informazioni',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'promozioni' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Scegli il tuo lavoro',
    'Ref: Validate': 'Convalidare',
    'Ref: Pricing Tiers dialog message': 'Sei stato riconosciuto con il tuo indirizzo email',
    'Ref: Please select your job in the company': 'Seleziona il tuo lavoro in azienda',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Raccogliere',
    'Ref: Eat-in': 'Mangiare in',
    'Ref: Scan&Go': 'Scansionare e andare',
    'Ref: Delivery': 'Consegna',
    'Ref: Total': 'Totale',
    'Ref: How was facility name service': 'Come è stato il servizio {facility_name}?',
    'Ref: How do you rate the service': 'Come valuti il ​​servizio?',
  },
};
export default labels;
