import { screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { useGetAvailablePromotionsQuery } from '../../api';

import PromotionsMiniWidget from './PromotionsMiniWidget';

const state = {
  Order: {
    draft: {
      promotionsApplied: [
        {
          id: 2,
        },
      ],
    },
    menus: [
      {
        id: 160,
        facilityId: '764c9169-465c-ea11-b698-0003ff4ca494',
        name: 'IFMS Breakfast Tavern Menu',
        date: '2023-01-03',
        isOrderable: true,
        menuItems: [
          {
            name: 'BBQ Brekky Wrap',
            startTime: 1672702252016,
            endTime: 1672785052016,
            dayPart: 'Breakfast',
            section: 'Breakfast Favourites',
            productPortions: [
              {
                portionId: '4cb91217-aa68-453c-8c03-452975cfaee1',
                uomId: 2099,
              },
            ],
            menuItemId: 4400666,
          },
        ],
      },
    ],
  },
  Shared: { language: { currentLanguageCode: 'en' } },
};

const availablePromotions = [
  {
    id: 1,
    name: 'Promotion 1',
    description: 'Description 1',
    discount: 1,
    threshold: 1,
    items: [
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
    ],
  },
  {
    id: 2,
    name: 'Promotion 2',
    description: 'Description 2',
    discount: 1,
    threshold: 1,
    items: [
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
    ],
  },
  {
    id: 3,
    name: 'Promotion 3',
    description: 'Description 3',
    discount: 1,
    threshold: 1,
    items: [
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
      {
        discount: 0,
        uomId: 2099,
        menuIds: [120, 160],
      },
    ],
  },
];

const mockDispatch = jest.fn();
const mockSelector = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
  useDispatch: () => mockDispatch,
}));

jest.mock('@/modules/Order/api/api', () => ({
  useGetAvailablePromotionsQuery: jest.fn(),
}));

describe('PromotionsMiniWidget', () => {
  const site = { id: 'site1' };
  const label = jest.fn().mockReturnValue('Promotions');
  beforeEach(() => {
    mockSelector.mockReturnValue(state);

    (useGetAvailablePromotionsQuery as jest.Mock).mockReturnValue({
      data: availablePromotions,
    });
  });

  describe('with data', () => {
    renderComponent(PromotionsMiniWidget, { label, site }, state);

    it('renders without error', () => {
      expect(screen.getByTestId('promotions-mini-widget')).toBeInTheDocument();
    });

    it('renders the correct number of promotion items', async () => {
      expect(screen.getByText('Promotion 1')).toBeTruthy();
      expect(screen.getByText('Promotion 2')).toBeTruthy();
      expect(screen.getByText('Promotion 3')).toBeTruthy();
    });

    it('renders correct activated label for promotion', async () => {
      const activated = screen.getAllByText('Activated');
      expect(activated.length).toBe(1);
    });

    it('renders correct See item label for promotion', async () => {
      const seeItem = screen.getAllByText('See item');
      expect(seeItem.length).toBe(1);
    });

    it('renders promotions in correct order', async () => {
      const promotions = await screen.findAllByText(/Promotion/);
      const filteredPromotions = await Promise.all(
        promotions.map(async (promotion) => {
          const text = await screen.getByText(promotion.innerHTML);
          return text.innerHTML !== 'Promotions' ? promotion : null;
        })
      );

      const filteredPromotionsWithoutNulls = filteredPromotions.filter(Boolean) as NonNullable<
        typeof filteredPromotions[number]
      >[];

      expect(filteredPromotionsWithoutNulls[0].innerHTML).toBe('Promotion 2');
      expect(filteredPromotionsWithoutNulls[1].innerHTML).toBe('Promotion 1');
    });

    it('displays the correct promotion title', () => {
      expect(screen.getByText('Promotion 1')).toBeInTheDocument();
    });

    it('displays the correct promotion description', () => {
      expect(screen.getByText('Description 1')).toBeInTheDocument();
    });
  });
  describe('with no data', () => {
    it('does not render when data is empty', async () => {
      expect(screen.queryByTestId('promotions-mini-widget')).not.toBeInTheDocument();
    });
  });

  describe('with fetching error', () => {
    beforeEach(() => {
      mockSelector.mockReturnValue(state);

      (useGetAvailablePromotionsQuery as jest.Mock).mockReturnValue({
        data: [],
        isError: true,
      });
    });

    it('does not render when there is fetching error', async () => {
      expect(screen.queryByTestId('promotions-mini-widget')).not.toBeInTheDocument();
    });
  });
});
