const labels = {
  All: {
    'Ref: Redeem coffee description': 'Twoja darmowa, świeżo zaparzona kawa czeka.',
    'Ref: Congratulations': 'Gratulacje',
    'Ref: Get free coffee': 'Zdobądź darmową kawę!',
    'Ref: Free coffee description': 'Napełnij swój kubek zbierając punkty na darmową kawę.',
    'Ref: Redeem': 'Zrealizuj!',
  },
  StampPage: {
    'Ref: Page title': 'Darmowa kawa',
    'Ref: seconds left': 'pozostały sekundy',
    'Ref: How it works': 'Jak to działa?',
    'Ref: How it works description':
      'Za każdym razem, gdy kupujesz kawę, naciśnij przycisk skanowania i zeskanuj dostarczony kod QR przy kasie.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Zeskanuj kod QR',
    'Ref: Collect reward': 'Odbierz nagrodę',
    'Ref: Reward collected': 'Nagroda odebrana',
    'Ref: Invalid QR Code': 'Nieważny kod QR',
    'Ref: Invalid QR Code description': 'Kod QR nie został rozpoznany.',
    'Ref: Close': 'Zamknij stronę',
    'Ref: Redeem button': 'Skorzystaj z',
    "Ref: The QR Code isn't recognized": 'Kod QR nie został rozpoznany',
    'Ref: Cancel': 'Anuluj',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Gratulacje!',
    'Ref: Confirm redeem description':
      'Jesteś pewien, że chcesz skorzystać? Jeśli tak, masz tylko 60 sekund, aby pokazać telefon przy kasie i odebrać swoją darmową kawę.',
    'Ref: Congratulations modal description': 'Odbierz teraz swoją darmową kawę!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Darmowa kawa!', 'Ref: See All': 'Zobacz wszystko' },
};
export default labels;
