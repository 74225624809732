const labels = {
  ReviewForm: {
    'Ref: Page title': 'Felülvizsgálat',
    'Ref: How was dining with us': 'Milyen volt velünk az étkezés?',
    'Ref: Submit': 'Beküldés',
    'Ref: Filter range': 'Szűrőtartomány',
    'Ref: Some information is missing': 'Néhány információ hiányzik',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Küldjön névtelenül',
    'Ref: Your email address': 'az email címed',
    'Ref: Email field placeholder': 'írd be az email címed',
    'Ref: Invalid email format': 'érvénytelen e-mail formátum',
  },
  SuccessPage: {
    'Ref: Body':
      'Köszönjük, hogy kitöltötte ezt a felülvizsgálatot, az ideje értékes, mivel ez a felülvizsgálat segít javítani szolgáltatásainkat.',
    'Ref: Go to Feedback': 'Menj vissza visszajelzésre',
    'Ref: Go to home': 'Menj haza',
    'Ref: Go to Facilities': 'Menjen a létesítményekbe',
    'Ref: Go to Product Details': 'Lépjen a termék részleteire',
    'Ref: Go to Facility Details': 'Lépjen a létesítmény részleteire',
  },
  FacilityPicker: {
    'Ref: Page title': 'Felülvizsgálat',
    'Ref: Search facility name': 'Keresési létesítmény neve',
  },
};
export default labels;
