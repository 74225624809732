import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { useGetSuppliersQuery } from '../../api';
import { Supplier } from '../../api/api.types';
import { pagePaths } from '../../config';
import { getSupplierAddress, getSupplierId } from '../../helpers/supplier.helper';
import { useSuppliersTranslation } from '../../hooks/useSuppliersTranslation';

import Map from '@/components/atoms/Map/Map';
import { IconSize, MarkerIcon } from '@/components/atoms/Map/Map.types';
import { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Title from '@/components/atoms/Title/Title';
import Card from '@/components/molecules/Card/Card';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { State } from '@/types/state.types';

import styles from './SupplierDetails.module.css';

const SupplierDetails = () => {
  const { label } = useSuppliersTranslation(__filename);
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetSuppliersQuery(siteId);

  const MAP_HEIGHT: string = '332px';
  const MAP_ZOOM: number = 14;

  if (isLoading || !data) {
    return <LoadingPage />;
  }

  const supplier: Supplier | undefined = data?.suppliers?.find(
    (item: Supplier) => getSupplierId(item) === id
  );

  if (!supplier) {
    history.push(pagePaths.Module);
    return null;
  }

  const items = [
    {
      id: 'address',
      label: label('Address label'),
      value: getSupplierAddress(supplier),
      'data-testid': `supplier-address-item-${supplier.name}`,
    },
    {
      id: 'website',
      label: label('Website label'),
      value: supplier.httpLink,
      'data-testid': `supplier-website-item-${supplier.name}`,
    },
  ];

  const mapMarker = (supplier: Supplier) => {
    return {
      coordinates: {
        longitude: supplier.longitude,
        latitude: supplier.latitude,
      },
      icon: new MarkerIcon(supplier.iconUrl, new IconSize(48, 48)),
    };
  };

  return (
    <SimpleFormPage hasBackLink={true} title={label('Ref: Page title')} actionsBarTopContent={null}>
      <Container>
        <Column.Side className={styles.sideColumn}>
          <Map
            data-testid="supplier-details"
            center={{
              longitude: supplier.longitude,
              latitude: supplier.latitude,
            }}
            markers={[mapMarker(supplier)]}
            zoom={MAP_ZOOM}
            height={MAP_HEIGHT}
          />
          <div className={classNames(styles.titleContainer)}>
            <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES}>
              {supplier.name}
            </Title>
          </div>
        </Column.Side>
        <Column.Main className={styles.mainColumn}>
          <Card className="mb-M">
            <List data-testid="suppliers-list" items={items}></List>
          </Card>
          {supplier.description && (
            <Card
              title={{
                tag: TITLE_TAG.H1,
                size: TITLE_SIZE.HEADLINEXS,
                children: <Title>{label('Details label')}</Title>,
              }}
            >
              <span>{supplier.description}</span>
            </Card>
          )}
        </Column.Main>
      </Container>
    </SimpleFormPage>
  );
};

export default SupplierDetails;
