import momentjs from 'moment';
import { Dispatch } from 'redux';

import { DATE_FORMAT } from '../../../constants';
import { store } from '../../../store';
import { defaultThemeSettings } from '../../Core/config';
import { BiteProblemDetailsResponse } from '../../Core/types';
import { CoreState } from '../../Core/types/State.types';
import {
  clearTimeSlot,
  getPickupTimeSlots,
  setPickupInformationInStore,
  upsertOrderDraft,
} from '../actions';
import { isTimeSelectionVisible } from '../hooks/useIsTimeSelectionVisible/timeSelectionVisible.helper';
import { CreateDraftOrderError, PickupTimeSlotsRequest } from '../types/order.types';
import { OrderState } from '../types/orderState.types';

import { mapCartToPostDraftOrderRequest } from './order.helper';

export const processCartChange = async (dispatch: Dispatch, orderState: OrderState) => {
  const result = await dispatch(upsertOrderDraft(prepareDraftOrderRequest(orderState)));

  if (isMinimumTimeToOrderError(result.responseStatus, result.responseData)) {
    await clearAndReloadTimeSlots(dispatch, {
      cartDate: orderState.cart!.date,
      menuId: orderState.cart!.menuId,
      pickupSpotId: orderState.cart?.pickupInformation?.pickupSpotId,
      siteId: orderState.cart?.siteId,
      deliveryOptionId: orderState.cart?.selectedFulfillmentType?.id,
    });

    const orderStateAfterUpdate = store.getState().Order as OrderState;
    const result = await dispatch(
      upsertOrderDraft(prepareDraftOrderRequest(orderStateAfterUpdate))
    );

    await setTimeSlotWhenTimeSelectionHidden(dispatch);
    return result;
  }

  return result;
};

export const getTimeSlotDate = (cartDate?: Date) => {
  const isOrderForToday = cartDate && momentjs(cartDate).isSame(new Date(), 'day');
  return isOrderForToday ? '' : momentjs(cartDate).format(DATE_FORMAT);
};

export const isMinimumTimeToOrderError = (
  responseStatus: number,
  errorResult: BiteProblemDetailsResponse<CreateDraftOrderError> | undefined
) => {
  return (
    responseStatus === 400 &&
    errorResult?.errors?.OrderDraft === CreateDraftOrderError.MinimumTimeToOrder
  );
};

export const clearAndReloadTimeSlots = async (
  dispatch: Dispatch,
  { cartDate, menuId, pickupSpotId, siteId, deliveryOptionId }: PickupTimeSlotsRequest
) => {
  await dispatch(clearTimeSlot());

  const timeSlotDate = getTimeSlotDate(cartDate);
  await dispatch(
    getPickupTimeSlots({
      menuId,
      pickupSpotId,
      siteId,
      timeSlotDate,
      deliveryOptionId,
    })
  );
};

export const setTimeSlotWhenTimeSelectionHidden = async (dispatch: Dispatch) => {
  const orderState = store.getState().Order as OrderState;
  const timeSelectionVisible = isTimeSelectionVisible(
    orderState.cart?.selectedFulfillmentType?.type
  );

  if (
    !timeSelectionVisible &&
    orderState.cart?.pickupInformation?.pickupSpotId &&
    orderState.cart?.pickupTimeSlots &&
    orderState.cart?.pickupTimeSlots.length > 0
  ) {
    const firstTimeSlot = orderState.cart.pickupTimeSlots[0];

    await dispatch(
      setPickupInformationInStore({
        pickupInformation: {
          pickupTimeSlotId: firstTimeSlot.timeSlotId.toString(),
          pickupTime: firstTimeSlot.time,
        },
      })
    );
  }
};

export const prepareDraftOrderRequest = (orderState: OrderState) => {
  const coreState = store.getState().Core as CoreState;
  const cart = orderState.cart!;
  const orderId = orderState.draft?.orderId;
  const table = orderState.tableNumber ?? undefined;
  const theme = coreState.context.theme?.settings ?? defaultThemeSettings;
  const siteId = coreState.context.site!.id;

  return mapCartToPostDraftOrderRequest(cart, siteId, theme, orderId, table);
};
