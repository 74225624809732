import { act, cleanup, fireEvent, screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import SuggestionSlider from './SuggestionSlider';

const popupProps = {
  id: 'mobile-slider',
  rows: 1,
  isForMobile: true,
  showSuggestionPopup: true,
  closePopupResetTime: jest.fn(),
  showProgressBar: true,
  remainingSeconds: 8,
  stopCountdown: jest.fn(),
  children: ['suggestion1', 'suggestion2', 'suggestion3'],
};

const hiddenProgressBarPopupProps = {
  ...popupProps,
  showProgressBar: false,
};

const hiddenPopupProps = {
  ...popupProps,
  showSuggestionPopup: false,
};

describe('SuggestionSlider', () => {
  afterAll(() => cleanup());

  describe('when used for desktop', () => {
    renderComponent(SuggestionSlider, {
      id: 'slider',
    });

    it('should display suggestions slider but not button nor progressbar', () => {
      expect(screen.getByTestId('suggestion-slider')).toBeTruthy();
      expect(screen.getByTestId('slider')).toBeTruthy();
      expect(screen.queryByText('Close')).toBeFalsy();
      expect(screen.queryByTestId('suggestion-slider-progressbar-wrapper')).toBeFalsy();
    });
  });

  describe('when used as mobile popup', () => {
    describe('on render', () => {
      renderComponent(SuggestionSlider, popupProps);

      it('should display popup with slider, button and progressbar', () => {
        expect(screen.getByTestId('suggestion-slider')).toBeTruthy();
        expect(
          screen.queryByTestId('suggestion-slider')?.classList?.contains('mobileSlider')
        ).toBeTruthy();
        expect(screen.getByTestId('slider')).toBeTruthy();
        expect(screen.getByText('Close')).toBeTruthy();
        expect(screen.getByTestId('suggestion-slider-progressbar-wrapper')).toBeTruthy();
      });
    });

    describe('on clicking popup', () => {
      renderComponent(SuggestionSlider, popupProps);

      beforeEach(() => {
        act(() => {
          fireEvent.click(screen.getByTestId('suggestion-slider'));
        });
      });

      it('stopCountdown should have been called', () => {
        expect(popupProps.stopCountdown).toHaveBeenCalled();
      });
    });

    describe('if showProgressBar is false ', () => {
      renderComponent(SuggestionSlider, hiddenProgressBarPopupProps);

      it('progressbar is hidden', () => {
        expect(
          screen.queryByTestId('suggestion-slider-progressbar-wrapper')?.classList?.contains('hideProgressBar')
        ).toBeTruthy();
      });
    });

    describe('on clicking close button', () => {
      renderComponent(SuggestionSlider, popupProps);

      beforeEach(() => {
        act(() => {
          fireEvent.click(screen.getByText('Close'));
        });
      });

      it('closePopupResetTime should have been called', () => {
        expect(popupProps.closePopupResetTime).toHaveBeenCalled();
      });
    });

    describe('if showSuggestionPopup is false', () => {
      renderComponent(SuggestionSlider, hiddenPopupProps);

      it('popup is hidden', () => {
        expect(
          screen.queryByTestId('suggestion-slider')?.classList?.contains('hideMobileSlider')
        ).toBeTruthy();
      });
    });
  });
});
