const labels = {
  PrivacyPolicy: 'นโยบายความเป็นส่วนตัว',
  TermsOfSale: 'เงื่อนไขการขาย',
  TermsOfUse: 'ข้อตกลงและเงื่อนไข',
  home: 'บ้าน',
  anytime: 'ทุกที่ทุกเวลา',
  anywhere: 'ที่ไหนก็ได้',
  Download: 'การดาวน์โหลด',
  scrollRightButton: 'หมวดหมู่ม้วนไปทางขวา',
  scrollLeftButton: 'เลื่อนหมวดหมู่ทางด้านซ้าย',
  calendar: 'ปฏิทิน',
  'See more': 'ดูเพิ่มเติม',
  'See less': 'ดูน้อยลง',
  'from (followed by a date, or date and time)': 'จาก',
  'to (followed by a date, or date and time)': 'ถึง',
  'on (followed by a date)': 'บน',
  'at (followed by time)': 'ที่',
  'from (followed by a location)': 'จาก',
  'to (followed by a location)': 'ถึง',
  'on (followed by a site name)': 'บน',
  'from (followed by a person)': 'จาก',
  previous: 'ก่อน',
  next: 'ต่อไป',
  success: 'ความสำเร็จ',
  failure: 'ความล้มเหลว',
  today: 'ในวันนี้',
  tomorrow: 'วันพรุ่งนี้',
  Monday: 'วันจันทร์',
  Tuesday: 'วันอังคาร',
  Wednesday: 'วันพุธ',
  Thursday: 'วันพฤหัสบดี',
  Friday: 'วันศุกร์',
  Saturday: 'วันเสาร์',
  Sunday: 'วันอาทิตย์',
  everyday: 'ทุกวัน',
  'open all day': 'เปิดทั้งวัน',
  'opening hours': 'เวลาทำการ',
  status: 'สถานะ',
  'to be confirmed': 'เพื่อยืนยัน',
  'only (as in filter only this)': 'เท่านั้น',
  edit: 'แก้ไข',
  submit: 'เสนอ',
  cancel: 'ยกเลิก',
  confirm: 'เพื่อยืนยัน',
  reset: 'รีเซ็ต',
  Delete: 'ลบ',
  select: 'เลือก',
  switch: 'สวิตซ์',
  close: 'ปิด',
  clear: 'แจ่มใส',
  remove: 'ลบ',
  logout: 'ออกจากระบบ',
  go: 'ไป',
  'see (as in consult this page or document)': 'ดู',
  'see all (as in see all items)': 'ดูทุกสิ่ง',
  'see more (as in see more details for this item)': 'ดูเพิ่มเติม',
  retry: 'ลองอีกครั้ง',
  'Ref: ok': 'ตกลง',
  'yes (as in yes I accept)': 'ใช่',
  'no (as in no I do not accept)': 'ไม่ใช่',
  you: 'คุณ',
  'thank you': 'ขอบคุณ',
  'sorry (standalone)': 'ขอโทษ',
  'to home (as in navigate to the homepage)': 'สู่หน้าแรก',
  map: 'แผนที่',
  list: 'รายการ',
  'Loading...': 'กำลังโหลด ...',
  'Refreshing...': 'กำลังอัปเดต...',
  'Please select': 'โปรดเลือก',
  'Switch site': 'สลับไซต์ที่นี่',
  'File size cannot exceed {max file size}': 'ขนาดไฟล์ต้องไม่เกิน {max file size}',
  Filters: 'ฟิลเตอร์',
  'Add attachment': 'เพิ่มไฟล์แนบ',
  Search: 'ค้นหา',
  'More (as in: see more info)': 'มากกว่า',
  'You can attach a maximum of {max files number} files.':
    'คุณสามารถแนบไฟล์ได้สูงสุด {max files number} ไฟล์',
  'There are no messages to display.': 'ไม่มีข้อความที่จะแสดง',
  'Your selection did not return any result.': 'การเลือกของคุณไม่ส่งคืนผลลัพธ์ใดๆ',
  'Ref: Popup header for missing required fields in a form': 'กรุณาให้ข้อมูลเพิ่มเติม',
  'Ref: Required field': '{ref} เป็นฟิลด์บังคับ',
  email: 'อีเมล',
  phone: 'หมายเลขโทรศัพท์',
  Account: 'บัญชีของฉัน',
  Contacts: 'รายชื่อผู้ติดต่อ',
  Content: 'เนื้อหา',
  Events: 'เหตุการณ์ที่เกิดขึ้น',
  Facilities: 'สิ่งอำนวยความสะดวก',
  Shop: 'ร้านค้า',
  Menu: 'เมนู',
  Menus: 'เมนู',
  Order: 'คำสั่ง',
  Orders: 'ประวัติการสั่งซื้อ',
  Info: 'ข้อมูล',
  FAQ: 'คำถามที่พบบ่อย',
  Feedback: 'ความคิดเห็น',
  Review: 'ทบทวน',
  Requests: 'การร้องขอ',
  'Request Helpdesk': 'ขอความช่วยเหลือ',
  Location: 'ที่ตั้ง',
  Safety: 'ความปลอดภัย',
  FreeCoffee: 'กาแฟฟรี',
  Newsfeed: 'ข่าวใหม่',
  MyOrders: 'คำสั่งซื้อของฉัน',
  LoyaltyStamps: 'แสตมป์ความภักดี',
  Stamps: 'แสตมป์',
  Stays: 'การเข้าพัก',
  Suppliers: 'ซัพพลายเออร์',
  Surveys: 'การสำรวจ',
  notifications: 'การแจ้งเตือน',
  cart: 'รถเข็น',
  'Ref: Reset your order': 'รีเซ็ตคำสั่งซื้อของคุณ',
  'Ref: My language': 'ภาษาของฉัน',
  Validate: 'ตรวจสอบความถูกต้อง',
  'contact us': 'ติดต่อเรา',
  'Ref: Start typing': 'เริ่มพิมพ์',
  Occupancy: 'การเข้าพัก',
  'see less': 'เห็นน้อยลง',
  'see more': 'ดูเพิ่มเติม',
  contacts: 'ผู้ติดต่อ',
  Imprint: 'รอยประทับ',
  'Ref: camera access request title': 'อนุญาตให้เข้าถึงกล้อง',
  'Ref: camera access request body':
    'คุณต้องเปิดใช้งานการใช้กล้องในเว็บไซต์นี้เพื่อใช้เครื่องสแกนรหัส QR',
  or: 'หรือ',
  'Ref: Input required': 'จำเป็นต้องใช้ {input_name}',
  'Enter a valid email address': 'ใส่อีเมล์ที่ถูกต้อง',
  'Ref: Input error E-mail already exists':
    'มีบัญชีพร้อมอีเมลนี้ที่คุณป้อนอยู่แล้ว กรุณาเข้าสู่ระบบแทน',
  'Password must contain': 'รหัสผ่านต้องมี:',
  'Ref: Input password error strength':
    'รหัสผ่านต้องมีอย่างน้อย 8 อักขระ, 1 หมายเลข, 1 ตัวอักษรและ 1 อักขระพิเศษ',
  Password: 'รหัสผ่าน',
  'New password': 'รหัสผ่านใหม่',
  'Confirm password': 'ยืนยันรหัสผ่าน',
  'Ref: Password does not match': 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน',
  'Ref: Old password does not match': 'รหัสผ่านเก่าไม่ตรงกัน',
  'Ref: Password validation min8Characters': 'อย่างน้อย 8 อักขระ',
  'Ref: Password validation min1Number': 'อย่างน้อย 1 หลัก',
  'Ref: Password validation min1Letter': 'อย่างน้อย 1 จดหมาย',
  'Ref: Password validation min1SpecialChar': 'อย่างน้อย 1 ตัวละครพิเศษ',
  Continue: 'ดำเนินการต่อ',
  'Enter a valid phone number': 'ป้อนหมายเลขโทรศัพท์ที่ถูกต้อง',
  'Phone is required': 'ต้องใช้โทรศัพท์',
  'Go to home': 'กลับบ้าน',
  'Something went wrong': 'บางอย่างผิดพลาด',
  'Something went wrong, data not loaded': 'มีบางอย่างผิดปกติข้อมูลไม่ได้โหลด',
  'Change password': 'เปลี่ยนรหัสผ่าน',
  'remove one': 'ลบหนึ่ง',
  'add one': 'เพิ่มหนึ่ง',
  'Confirm Filters': 'ยืนยันตัวกรอง',
  'Reset Filters': 'รีเซ็ตตัวกรอง',
  '{selectedNum} active filters': '{selectedNum} ตัวกรองที่ใช้งานอยู่',
  'Wrong input icon': 'ไอคอนอินพุตผิด',
  'Correct input icon': 'แก้ไขไอคอนอินพุต',
  'Show input text icon': 'แสดงไอคอนข้อความอินพุต',
  'Hide input text icon': 'ซ่อนไอคอนข้อความอินพุต',
  'Required fields info': '* ช่องที่ต้องเติม',
  'Ref: Select site on the map': 'เลือกไซต์ของคุณบนแผนที่',
  'Ref: Geolocation disabled':
    'การวางตำแหน่งทางภูมิศาสตร์ถูกปิดใช้งาน หากต้องการดูเว็บไซต์ใกล้เคียงโปรดเปิดใช้งาน',
  'Ref: Reaction - LIKE': 'เช่น',
  'Ref: Reaction - LOVE': 'รัก',
  'Ref: Reaction - INTERESTING': 'น่าสนใจ',
  'Ref: Reaction - CONGRATE': 'แสดงความยินดี',
  'Ref: Share': 'แบ่งปัน',
  'Ref: Send by Email': 'ส่งโดยอีเมล์',
  'Ref: Copy the link': 'คัดลอกลิงค์',
  'Ref: Link was copied': 'มีการคัดลอกลิงก์',
  'Ref: unable to copy url': 'ไม่สามารถคัดลอก URL',
  'Ref: External link': 'การเชื่อมโยงภายนอก',
  'Ref: Step': 'ขั้นตอน',
  'input field': 'ฟิลด์อินพุต',
  'Ref: Zoom': 'ซูม',
  'Increase hours': 'เพิ่มชั่วโมง',
  'Decrease hours': 'ลดชั่วโมง',
  Hours: 'ชั่วโมง',
  Minutes: 'นาที',
  'Ref: filter label: favorites': 'แสดงรายการโปรดของฉันเท่านั้น',
  'Ref: filter label: max calories': 'แคลอรี่สูงสุด',
  'Ref: filter label: dishes': 'จาน',
  'Ref: filter label: allergens': 'ภูมิแพ้',
  'Ref: filter label: highlight': 'ไฮไลต์หรือซ่อนตัวกรองด้วยอาหาร',
  'Ref: Highlight': 'จุดเด่น',
  'Ref: Hide': 'ซ่อน',
  'Ref: Vegan': 'มังสวิรัติ',
  'Ref: Vegeterian': 'มังสวิรัติ',
  'Ref: Mindful': 'มีสติ',
  'Ref: Highlight text':
    'ที่นี่คุณสามารถเลือกที่จะ hightlight หรือซ่อนจานบนเมนูที่มีสารก่อภูมิแพ้บางอย่าง',
  'Ref: Just now': 'ตอนนี้',
  'Ref: {amountMinutes} minutes ago': '{amountMinutes} นาทีที่ผ่านมา',
  'Ref: 1 hour ago': '1 ชั่วโมงที่แล้ว',
  'Ref: {amountHours} hours ago': '{amountHours} ชั่วโมงที่ผ่านมา',
  'Ref: Yesterday at {time}': 'เมื่อวานนี้ที่ {time}',
  'Ref: {date} at {time}': '{date} ที่ {time}',
  'Ref: filter label: date': 'วันที่',
  'Ref: expand menu': 'ขยายเมนู',
  'Ref: collapse menu': 'เมนูยุบ',
  'Ref: My site': 'เว็บไซต์ของฉัน',
  'Ref: Recent': 'ล่าสุด',
  'Ref: Services': 'บริการ',
  'Ref: products in cart': 'จำนวนผลิตภัณฑ์ในรถเข็น',
  'Ref: no product in cart': 'ไม่มีผลิตภัณฑ์ในรถเข็น',
  'Ref: Survey': 'สำรวจ',
  'Access to camera was denied. Please allow access and try again':
    'การเข้าถึงกล้องถูกปฏิเสธ โปรดอนุญาตให้เข้าถึงและลองอีกครั้ง',
  'Add an attachment': 'เพิ่มไฟล์แนบ',
  'Take a picture': 'ถ่ายภาพ',
  'Add a file from your device': 'เพิ่มไฟล์จากอุปกรณ์ของคุณ',
  'Ref: Loading page content, please wait': 'กำลังโหลดเนื้อหาหน้าโปรดรอ',
  Star: 'ดาว',
  Stars: 'ดารา',
  'Accommodation Request': 'คำขอที่พัก',
  'Ref: Guest Accomodation Request': 'คำขอของแขก',
  'Ref: Search a menu, product or facility': 'ค้นหาเมนูผลิตภัณฑ์หรือสิ่งอำนวยความสะดวก',
  ConnectionLost: 'การเชื่อมต่อเครือข่ายหายไป',
  ConnectionRestored: 'กู้คืนการเชื่อมต่อเครือข่าย',
  'Ref: Skip to main content': 'ข้ามไปยังเนื้อหาหลัก',
  'Ref: No options': 'ไม่มีตัวเลือก',
  'Ref: Global search modal': 'Global Search Modal',
  'Ref: Search a facility': 'ค้นหาสิ่งอำนวยความสะดวก',
  'Ref: Search a menu or product': 'ค้นหาเมนูหรือผลิตภัณฑ์',
  'Ref: Reaction': 'ปฏิกิริยา',
  'Ref: times': 'เวลา',
  'Ref: reaction. Change reaction': 'ปฏิกิริยา. การเปลี่ยนแปลงปฏิกิริยา',
  'Ref: No reaction. React': 'ไม่มีปฏิกิริยา ตอบโต้',
  'Ref: LIKE': 'ชอบ',
  'Ref: LOVE': 'รัก',
  'Ref: INTERESTING': 'น่าสนใจ',
  'Ref: CONGRATE': 'เต็มใจ',
  'Ref: Open search': 'เปิดช่องค้นหา',
  'Ref: Close search': 'ปิดช่องค้นหา',
  'Ref: Total file size msg': 'ขนาดไฟล์ทั้งหมดไม่เกิน {max file size}',
  'Ref: Oops Title': 'อ๊ะ!',
  'Ref: Well Done': 'ทำได้ดี {party_emoji}',
  'Ref: Navigation arrow - Continue': 'Navigation Arrow - ดำเนินการต่อ',
  'Ref: Reload': 'โหลดใหม่',
  'Ref: All Dates': 'วันที่ทั้งหมด',
  'Ref: Today': 'วันนี้',
  'Ref: Validate': 'ตรวจสอบความถูกต้อง',
  'Ref: toast error title': 'อ๊ะ!',
  'Ref: toast error subtitle': 'เกิดข้อผิดพลาดโปรดลองอีกครั้ง',
  'Ref: Calendar': 'ปฏิทิน',
  'Ref: Selected': 'ที่เลือก',
  'Ref: Previous Month': 'เดือนที่แล้ว',
  'Ref: Next Month': 'เดือนหน้า',
};
export default labels;
