import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

import { useGetMappedFacilitiesQuery } from '../../../Review/api';
import { pagePaths as reviewPagePaths } from '../../../Review/config';
import { REDIRECTED_PAGES } from '../../../Review/types/reviewForm.types';
import { ISite } from '../../../Sites/types/sites.types';
import { useGetFacilityDetailsQuery } from '../../api/index';
import { getOfferingPathAndRedirect } from '../../helpers/facilities.helpers';
import { useFacilitiesTranslation } from '../../hooks/useFacilitiesTranslation';
import { FACILITY_TYPE, FacilityType } from '../../types/types';

import Button from '@/components/atoms/Button';
import Title, { TITLE_TAG, TITLE_SIZE } from '@/components/atoms/Title';
import FacilityContact from '@/components/ecosystems/Facility/FacilityContact/FacilityContact';
import FacilityDescription from '@/components/ecosystems/Facility/FacilityDescription/FacilityDescription';
import OpeningHours from '@/components/ecosystems/Facility/OpeningHours/OpeningHours';
import Card from '@/components/molecules/Card/Card';
import ContentTitle from '@/components/molecules/ContentTitle/ContentTitle';
import ContentDetailsPage from '@/components/templates/ContentDetailsPage';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import { useTrackPageView } from '@/helpers/hooks/Analytics/useTrackPageView';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { SERVICE } from '@/modules/config';
import useSite from '@/modules/Core/hooks/useSite';
import { State } from '@/types/state.types';

import styles from './FacilityDetails.module.css';

const FacilityDetails = () => {
  const { label } = useFacilitiesTranslation(__filename);
  const history = useHistory();
  const site = useSite() as ISite;
  const {
    params: { id: facilityId },
  } = useRouteMatch<FacilityIdMatch>();

  useTrackPageView({ isGlobal: false, facilityId });

  const enabledServices = useSelector((state: State) => state.Core.services.list);
  const facilityFeedbackEnabled = useIsSetupOptionEnabled('facilityFeedback', SERVICE.REVIEW);
  const { data: mappedFacilites, isLoading } = useGetMappedFacilitiesQuery(site.id);

  const { data: facility, isLoading: isFacilitiesLoading } = useGetFacilityDetailsQuery({
    facilityId,
  });

  const getTitle = useCallback(
    (className: string) => (
      <ContentTitle
        data-testid="content-tile"
        title={
          <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
            {facility?.title}
          </Title>
        }
        className={className}
      />
    ),
    [facility?.title]
  );

  const actions = useMemo(() => {
    const resultMemo: React.ReactNode[] = [];
    if (!facility) {
      return resultMemo;
    }
    const isNonFoodNonRetail =
      FACILITY_TYPE[facility.facilityType?.name] === FacilityType.NonFoodNonRetail;

    if (facility?.externalUrl || (facility?.facilityType && !isNonFoodNonRetail)) {
      resultMemo.push(
        <Button
          data-testid="facility-details-see-offering"
          onClick={() =>
            getOfferingPathAndRedirect({
              facilityId,
              history,
              services: enabledServices,
              facilityType: FACILITY_TYPE[facility?.facilityType?.name],
              externalUrl: facility?.externalUrl,
            })
          }
        >
          {label('See the offering')}
        </Button>
      );
    }

    if (facilityFeedbackEnabled && mappedFacilites?.includes(facilityId)) {
      const facilityReviewPage = reviewPagePaths.ReviewForFacility.replace(
        ':facilityId',
        facilityId
      );
      resultMemo.push(
        <Button
          data-testid="facility-details-review"
          onClick={() =>
            history.push({
              pathname: facilityReviewPage,
              search: `${new URLSearchParams({
                referer: history.location.pathname || '',
                redirectedFrom: REDIRECTED_PAGES.FACILITY_DETAILS,
              })}`,
            })
          }
        >
          {label('Ref: Review')}
        </Button>
      );
    }

    return resultMemo;
    // TODO: Facility exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility, facilityFeedbackEnabled, mappedFacilites]);

  if (isFacilitiesLoading || isLoading) return <LoadingPage />;

  const notice = facility?.menuNote ? (
    <Card>
      <div className="highlight">{facility.menuNote}</div>
    </Card>
  ) : undefined;

  return (
    <ContentDetailsPage
      title={facility?.title ?? ''}
      header={{
        imageSrc: facility?.imageInfo?.src,
        imageAlt: facility?.title,
        children: getTitle(styles.titleSide),
      }}
      actions={actions.length > 0 && actions}
      tabTitle={facility?.title}
    >
      {getTitle(styles.titleMain)}
      {notice}
      {facility ? (
        <>
          <FacilityContact facility={facility} label={label} />
          <OpeningHours facility={facility} label={label} />
          <FacilityDescription facility={facility} label={label} />
        </>
      ) : null}
    </ContentDetailsPage>
  );
};

export type FacilityIdMatch = { id: string };

export default FacilityDetails;
