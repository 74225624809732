import { act, cleanup, renderHook } from '@testing-library/react';
import { useSelector } from 'react-redux';

import { useGetFacilitiesForSearch } from '../useGetFacilitiesForSearch/useGetFacilitiesForSearch';
import { useGetMenusForSearch } from '../useGetMenusForSearch/useGetMenusForSearch';

import { useGlobalSearchResults } from './useGlobalSearchResults';
import { SearchResultType } from './useGlobalSearchResults.types';

import { defaultFacilityDataItem } from '@/modules/Order/__mocks/mock';
import { FacilityMenuSource } from '@/modules/Order/types/orderState.types';

jest.mock('react-redux', () => ({
  useSelector: jest.fn(),
}));

jest.mock('../useGetMenusForSearch/useGetMenusForSearch', () => ({
  useGetMenusForSearch: jest.fn(),
}));

jest.mock('../useGetFacilitiesForSearch/useGetFacilitiesForSearch', () => ({
  useGetFacilitiesForSearch: jest.fn(),
}));

const onlyMenus = [
  {
    id: 1,
    facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
    name: 'Aspretto Cafe',
    message:
      "If you have a food allergy or intolerance (or someone you're ordering for has), please contact the site team. Do not order if you cannot get the allergy information you need.",
    date: '2022-11-22T00:00:00',
    isConferenceCatering: false,
    isScanAndGo: false,
    fulfillmentTypes: [],
    isOrderable: true,
    menuItems: [],
    source: FacilityMenuSource.Rr,
  },
  {
    id: 2,
    facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
    name: 'Aspretto Bar',
    message:
      "If you have a food allergy or intolerance (or someone you're ordering for has), please contact the site team. Do not order if you cannot get the allergy information you need.",
    date: '2022-11-22T00:00:00',
    isConferenceCatering: false,
    isScanAndGo: false,
    fulfillmentTypes: [],
    isOrderable: true,
    menuItems: [],
    source: FacilityMenuSource.Rr,
  },
];

const menusWithProducts = [
  {
    id: 1,
    facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
    name: 'Aspretto Cafe',
    message:
      "If you have a food allergy or intolerance (or someone you're ordering for has), please contact the site team. Do not order if you cannot get the allergy information you need.",
    date: '2022-11-22T00:00:00',
    isConferenceCatering: false,
    isScanAndGo: false,
    fulfillmentTypes: [],
    isOrderable: true,
    menuItems: [
      {
        menuItemId: 1,
        name: 'Aspretto Burger',
        isOrderable: true,
        productPortions: [
          {
            uomId: 1,
            portion: 'Test Portion',
            price: 10,
            isDefault: true,
            allergens: [],
            nutritions: {
              calories: 100,
            },
          },
        ],
        section: 'Test Category',
        genericCategory: 'Test Category',
      },
      {
        menuItemId: 2,
        name: 'Different Burger not to be filetered',
        isOrderable: true,
        productPortions: [
          {
            uomId: 1,
            portion: 'Test Portion',
            price: 10,
            allergens: [],
            isDefault: false,
            nutritions: {
              calories: 100,
            },
          },
        ],
        section: 'Test Category',
        genericCategory: 'Test Category',
      },
    ],
    source: FacilityMenuSource.Rr,
  },
  {
    id: 2,
    facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
    name: 'Aspretto Bar',
    message:
      "If you have a food allergy or intolerance (or someone you're ordering for has), please contact the site team. Do not order if you cannot get the allergy information you need.",
    date: '2022-11-22T00:00:00',
    isConferenceCatering: false,
    isScanAndGo: false,
    fulfillmentTypes: [],
    isOrderable: true,
    menuItems: [],
    source: FacilityMenuSource.Rr,
  },
  {
    id: 3,
    facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
    name: 'Some different menu not to be found',
    message:
      "If you have a food allergy or intolerance (or someone you're ordering for has), please contact the site team. Do not order if you cannot get the allergy information you need.",
    date: '2022-11-22T00:00:00',
    isConferenceCatering: false,
    isScanAndGo: false,
    fulfillmentTypes: [],
    isOrderable: false,
    menuItems: [
      {
        menuItemId: 1,
        name: 'Aspretto Item from not found non-orderable menu',
        isOrderable: false,
        productPortions: [
          {
            uomId: 3,
            portion: 'Test Portion',
            price: 10,
            isDefault: true,
            allergens: [],
            nutritions: {
              calories: 100,
            },
          },
        ],
        section: 'Test Category',
        genericCategory: 'Test Category',
      },
      {
        menuItemId: 4,
        name: 'Different item from non-orderable menu',
        isOrderable: true,
        productPortions: [
          {
            uomId: 1,
            portion: 'Test Portion',
            price: 10,
            allergens: [],
            isDefault: false,
            nutritions: {
              calories: 100,
            },
          },
        ],
        section: 'Test Category',
        genericCategory: 'Test Category',
      },
    ],
    source: FacilityMenuSource.Rr,
  },
];

const facilities = [
  {
    ...defaultFacilityDataItem,
    id: 1,
    name: 'Aspretto facility',
  },
  {
    ...defaultFacilityDataItem,
    id: 2,
  },
];

describe('useGlobalSearchResults', () => {
  beforeEach(() => {
    (useSelector as jest.Mock).mockReturnValue({ context: { site: { id: 'testSiteId' } } });
    (useGetMenusForSearch as jest.Mock).mockReturnValue({ menus: onlyMenus, isLoading: false });
    (useGetFacilitiesForSearch as jest.Mock).mockReturnValue({ facilities, isLoading: false });
  });

  it('should handle search term change and update search results for menus only', () => {
    const { result } = renderHook(() => useGlobalSearchResults());

    const searchTerm = 'Aspretto';

    act(() => {
      result.current.handleSearchTermChange(searchTerm);
    });

    expect(
      result.current.searchResults?.find((x) => x.type === SearchResultType.Menus)?.results.length
    ).toEqual(2);

    expect(
      result.current.searchResults?.find((x) => x.type === SearchResultType.Products)?.results
        .length
    ).toEqual(undefined);

    expect(result.current.searchTerm).toEqual(searchTerm);
  });

  it('should not update search results for short search terms', () => {
    const { result } = renderHook(() => useGlobalSearchResults());

    // Search term with less than 3 characters
    act(() => {
      result.current.handleSearchTermChange('ab');
    });

    expect(result.current.searchResults).toEqual(undefined);
  });

  it('should clear search results when search term is empty', () => {
    const { result } = renderHook(() => useGlobalSearchResults());

    act(() => {
      result.current.handleSearchTermChange('Burger');
    });

    act(() => {
      result.current.handleSearchTermChange('');
    });

    expect(result.current.searchResults).toEqual(undefined);
    expect(result.current.searchTerm).toEqual('');
  });
});

describe('useGlobalSearchResults', () => {
  afterAll(() => cleanup());
  beforeEach(() => {
    (useSelector as jest.Mock).mockReturnValue({ context: { site: { id: 'testSiteId' } } });
    (useGetMenusForSearch as jest.Mock).mockReturnValue({
      menus: menusWithProducts,
      isLoading: false,
    });
    (useGetFacilitiesForSearch as jest.Mock).mockReturnValue({ facilities, isLoading: false });
  });

  it('should handle search term change and update search results for menus and products and filter out orderable and non-orderable items', () => {
    const { result } = renderHook(() => useGlobalSearchResults());

    const searchTerm = 'Aspretto';

    act(() => {
      result.current.handleSearchTermChange(searchTerm);
    });

    expect(
      result.current.searchResults?.find((x) => x.type === SearchResultType.Menus)?.results.length
    ).toEqual(2);

    expect(
      result.current.searchResults?.find((x) => x.type === SearchResultType.Products)?.results
        .length
    ).toEqual(2);

    expect(
      result.current.searchResults?.find((x) => x.type === SearchResultType.Facilities)?.results
        .length
    ).toEqual(1);

    expect(result.current.searchTerm).toEqual(searchTerm);
  });
});
