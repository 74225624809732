import { cleanup, screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import StampTile from './StampTile';
import { StampPerUser } from './StampTile.types';

describe('StampTile', () => {
  afterEach(() => cleanup());

  const props: StampPerUser = {
    id: 1,
    name: 'Stamp Title',
    description: 'Stamp Description',
    image: '',
    stampsRequired: 10,
    stampsActual: 3,
    rewards: 2,
  };

  describe('on render', () => {
    renderComponent(StampTile, {
      ...props,
      label: (s: string) => s,
    });

    it('should display tile with all information', () => {
      expect(screen.getByText('Stamp Title')).toBeTruthy();
      expect(screen.getByText('Stamp Description')).toBeTruthy();
      expect(screen.getByText('3/10')).toBeTruthy();
      expect(screen.getByText('2 Rewards')).toBeTruthy();
      expect(screen.getByTestId('stamp-tile-progressbar-wrapper')).toBeTruthy();
      expect(screen.getByText('See Reward')).toBeTruthy();
    });
  });
});
