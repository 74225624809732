import { SiteProps } from '../../../../types/common.types';
import { AllergenType } from '../../../Order/types/orderState.types';

import { ISite } from '@/modules/Sites/types/sites.types';
import { Acknowledgement } from '@/types/user.types';

export type UpdateUserContextArgs = {
  mobile?: string;
  preferredLanguageCode?: string;
  allowContent?: string;
  allowOffers?: string;
  preferredLocationId?: string;
  preferredLocation?: {
    id: string;
    name: string;
    title: string;
    site?: SiteProps;
  };
};
export type GetUserContextArgs = { currentLanguageCode?: string };

export type GetUserContextRes = {
  acknowledgements?: Acknowledgement[];
  contract?: {
    id: string;
    code: string;
    name: string;
  };
  preferredLanguage: {
    id: string;
    name?: string;
    displayName?: string;
    systemLanguage?: number;
    languageCode: string;
    lcid?: number;
  };
  preferredLanguageId?: string;
  theme: {
    id: string;
    name: string;
    lastModifiedOn: string;
  } | null;
  communicationPreferences?: {
    allowContent: string;
    allowOffers: string;
  };
  username?: string;
  isGuest?: boolean;
  isSSOUser?: boolean;
  contactId?: string;
  mobile?: string;
  firstName: string;
  lastName?: string;
  email?: string;
  qrCode?: string;
  allowContent: string;
  allowOffers: string;
  preferredLocation?: {
    id: string;
    name: string;
    title: string;
    site?: SiteProps;
  };
  useDataTracking?: boolean;
  id?: string;
  site?: ISite;
};

export type GetIdentityContextArgs = { username: string };

export type GetIdentityContextRes = {
  client: string;
  provider: string;
  tokenUrl: string;
  tokenUrlV2: string;
  type: string;
  useSystemBrowser: boolean;
};

export type IdentityContext = {
  client: string;
  provider: string;
  tokenUrl: string;
  type: string;
  useSystemBrowser: boolean;
};

export type FiltersModel = {
  selectedAllergens: AllergenType[] | null;
  selectedDietaries: DietaryType[] | null;
  maxCalories: number | null;
  hideFilteredDishes: boolean;
  displayFavoritesOnly: boolean;
};

export type SaveFilterPreferences = {
  siteId: string;
  filters: FiltersModel;
};

export enum DietaryType {
  Vegan = 'vegan',
  Vegetarian = 'vegetarian',
  Mindful = 'mindful',
}
