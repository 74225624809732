import { renderHook } from '@testing-library/react';
import { Provider } from 'react-redux';

import { SERVICE } from '../../../config';

import { SuggestPlace } from './SuggestionsWidget.types';
import { useIsSuggestionsEnabled } from './useIsSuggestionsEnabled';

const createMockStore = (servicesList: any[]) => {
  const store = {
    getState: () => ({
      Core: {
        services: {
          list: servicesList,
        },
      },
    }),
    subscribe: () => {},
    dispatch: () => {},
  };
  return store;
};

const renderHookWithStore = (store: any) => {
  const wrapper = ({ children }: { children: React.ReactNode }) => (
    <Provider store={store}>{children}</Provider>
  );
  return renderHook(() => useIsSuggestionsEnabled(SuggestPlace.PRODUCT_LIST), { wrapper });
};

describe('useIsSuggestionsEnabled', () => {
  it('should return true when suggestions are enabled', () => {
    const servicesList = [
      {
        name: SERVICE.RETAIL,
        setupOptions: [
          {
            name: SuggestPlace.PRODUCT_LIST,
            value: 'true',
          },
        ],
      },
    ];
    const mockStore = createMockStore(servicesList);
    const { result } = renderHookWithStore(mockStore);
    expect(result.current).toBe(true);
  });

  it('should return false when suggestions are disabled', () => {
    const servicesList = [
      {
        name: SERVICE.RETAIL,
        setupOptions: [
          {
            name: SuggestPlace.PRODUCT_LIST,
            value: 'false',
          },
        ],
      },
    ];
    const mockStore = createMockStore(servicesList);
    const { result } = renderHookWithStore(mockStore);
    expect(result.current).toBe(false);
  });

  it('should return true when the setup options is not defined', () => {
    const servicesList = [
      {
        name: 'ANOTHER_SERVICE',
        setupOptions: [],
      },
    ];
    const mockStore = createMockStore(servicesList);
    const { result } = renderHookWithStore(mockStore);
    expect(result.current).toBe(true);
  });
});
