import * as signalR from '@microsoft/signalr';
import { useEffect } from 'react';

import { globalRetailApiHost } from '../../../../config';
import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import { isKiosk } from '../../../../helpers/misc';
import { store } from '../../../../store';
import { getKioskUserFacilityId } from '../../../Core/helpers/login.helper';
import useSignalRHubConnection from '../../../Core/hooks/useSignalRHubConnection';
import { kioskHub } from '../../config';
import { OrderState } from '../../types/orderState.types';
import useResetKioskSession from '../useClearKioskSession/useResetKioskSession';

const kioskHubConnectionBuilder = new signalR.HubConnectionBuilder()
  .withUrl(`${globalRetailApiHost}/api/v1/${kioskHub.hubName}`, {
    accessTokenFactory: () => localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)!,
    headers: { 'ocp-apim-subscription-key': process.env.REACT_APP_BITE_SUBSCRIPTION_KEY! },
    withCredentials: false,
  })
  .configureLogging(signalR.LogLevel.Information);

const useKioskSessionNotification = () => {
  const [resetSession] = useResetKioskSession();

  const kioskSignalRIntegrationEnabled =
    process.env.REACT_APP_USE_KIOSK_SIGNALR_INTEGRATION === 'true';
  const connectToHub = isKiosk && kioskSignalRIntegrationEnabled;

  const { connection, connectionState } = useSignalRHubConnection(
    connectToHub,
    kioskHubConnectionBuilder,
    kioskHub.reconnectIntervalMs
  );

  const closesession = async (trackingId: string) => {
    const orderStoreState: OrderState = store.getState()['Order'];
    if (trackingId === orderStoreState.cart?.submissionTrackingId) {
      await resetSession();
    }
  };

  const facilityId = connectToHub ? getKioskUserFacilityId() : undefined;
  useEffect(
    () => {
      if (connection && connectionState === signalR.HubConnectionState.Connected && facilityId) {
        connection.send(kioskHub.joinFacilityNotificationsHubMethod, facilityId);
        connection.on(kioskHub.closeSessionHubMethod, closesession);
      } else {
        connection?.off(kioskHub.closeSessionHubMethod);
      }
    },
    // eslint-disable-next-line
    [connection, connectionState, facilityId, resetSession]
  );
};

export default useKioskSessionNotification;
