import { GetLoyaltySchemesResponse } from '../api/api.types';

export const defaultLoyaltySchemes: GetLoyaltySchemesResponse[] = [
  {
    id: 1064,
    name: 'Exotic Summer Deal',
    description:
      'Collect 5 stamps for buying Banana, Potato or Granny Smith Apple and get one Pineapple, Strawberries or Onion for free. ',
    productsProvidingStamp: [
      {
        uomId: 30551,
        foodItemId: 28508,
      },
    ],
    productsRedeemable: [
      {
        uomId: 30552,
        foodItemId: 28509,
      },
      {
        uomId: 30635,
        foodItemId: 28586,
      },
    ],
    imageUrl:
      'https://rgukretailrangerpre9277.blob.core.windows.net/loyalty/20230706115314-pineapple.jpg',
    stampsRequiredForRedeem: 5,
    menuIdsProvidingProductsRedeemable: [3329],
    menuIdsProvidingProductsProvidingStamp: [3329],
  },
];
