import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';

import { useGetSurveyReviewQuestionsQuery, useSubmitSurveyMutation } from '../../api';
import EmailOrAnonymousToggler from '../../components/EmailAnonymousToggler';
import SurveyQuestions from '../../components/SurveyQuestions';
import { defaultValues, pagePaths } from '../../config';
import { checkIsInvalidForm, checkIsValidEmail } from '../../helpers/reviewForm.helper';
import { useReviewTranslation } from '../../hooks/useReviewTranslation';
import {
  FormAction,
  FormState,
  ReviewUrlParams,
  PATH_REVIEW_TYPES,
  SURVEY_TYPES,
  REVIEW_QUESTION_TYPES,
} from '../../types/reviewForm.types';
import { OnChangeSurveyQuestionPayload } from '../../types/reviewState.types';

import { FeedbackIllustration } from '@/assets/illustrations';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import useSite from '@/modules/Core/hooks/useSite';
import { State } from '@/types/state.types';

import styles from './ReviewForm.module.css';

const SET_INITIAL_FORM = 'SET_INITIAL_FORM';
const UPDATE_FORM = 'UPDATE_FORM';
const CLEAR_FORM = 'CLEAR_FORM';

const ReviewForm = () => {
  const { reviewType, facilityId, menuId, foodItemId } = useParams<ReviewUrlParams>();
  const location = useLocation();
  const history = useHistory();
  const site = useSite() || { id: '' };
  const actionsDispatch = useDispatch();
  const { label } = useReviewTranslation(__filename);

  const surveyType =
    reviewType === PATH_REVIEW_TYPES.MENU_ITEM ? SURVEY_TYPES.FOOD_ITEM : SURVEY_TYPES.OUTLET;

  const { data: survey, isLoading: isSurveyLocked } = useGetSurveyReviewQuestionsQuery(
    { siteId: site.id, surveyType },
    { skip: !Boolean(site.id || surveyType) }
  );

  const [submitSurvey, { isLoading: isSurveySubmitLocked, error: isError }] =
    useSubmitSurveyMutation();

  /**
   * Redux state
   */
  const { user } = useSelector((state: State) => state.Core);
  const { isAnonymous, email } = useSelector((state: State) => state.Review);
  const { email: profileEmail } = user;

  /**
   * Redux actions
   */
  const updateEmail = useCallback(
    (email: string) => actionsDispatch({ type: 'UPDATE_EMAIL', email }),
    [actionsDispatch]
  );
  const toggleAnonymous = useCallback(
    (isAnonymous: boolean) => actionsDispatch({ type: 'TOGGLE_ANONYMOUS', isAnonymous }),
    [actionsDispatch]
  );

  const queryParams = new URLSearchParams(location.search);
  const refererUrl = queryParams.get('referer');
  const redirectedFrom = queryParams.get('redirectedFrom');

  const [state, dispatch] = useReducer(formReducer, {});

  const onChangeAnswer = useCallback(
    ({ question, answer, questionType }: OnChangeSurveyQuestionPayload) => {
      dispatch({ type: UPDATE_FORM, question, answer, questionType });
    },
    []
  );

  const onEmailChange = useCallback(
    (email: string) => {
      updateEmail(email);
    },
    [updateEmail]
  );

  const afterSurveySubmited = useCallback(() => {
    dispatch({ type: CLEAR_FORM });
    history.push({
      pathname: pagePaths.SubmitSuccess,
      search: `${new URLSearchParams({
        referer: refererUrl || '',
        redirectedFrom: redirectedFrom || '',
      })}`,
    });
  }, [history, refererUrl, redirectedFrom]);

  const handleSubmit = useCallback(async () => {
    if (!survey) return;

    const answers = Object.entries(state).map(([key, { answer, type }]) => ({
      surveyQuestionId: Number(key),
      questionType: type,
      answer: answer?.toString(),
    }));

    const submitResult = await submitSurvey({
      surveyId: survey?.surveyId,
      surveyType: survey?.surveyType,
      siteId: site.id,
      facilityId,
      menuId: Number(menuId),
      foodItemId: Number(foodItemId),
      questionResponses: answers,
      contacts: { email },
    });

    const { error } = submitResult as { error: { status: string } };

    if (error?.status) {
      history.push('/error');
      return;
    }

    afterSurveySubmited();
  }, [
    survey,
    state,
    submitSurvey,
    site.id,
    facilityId,
    menuId,
    foodItemId,
    email,
    afterSurveySubmited,
    history,
  ]);

  const isLocked = useMemo(
    () => isSurveyLocked || isSurveySubmitLocked,
    [isSurveyLocked, isSurveySubmitLocked]
  );

  useEffect(() => {
    const initialState =
      survey?.questions.reduce((acc, { surveyQuestionId, questionType }) => {
        switch (questionType) {
          case REVIEW_QUESTION_TYPES.SLIDER:
            if (defaultValues.initialQuestionRange)
              acc[surveyQuestionId] = {
                answer: defaultValues.initialQuestionRange,
                type: questionType,
                required: false,
              };
            break;
          case REVIEW_QUESTION_TYPES.TEXT:
            acc[surveyQuestionId] = {
              answer: '',
              type: questionType,
              required: false,
            };
            break;
          case REVIEW_QUESTION_TYPES.STARS:
            acc[surveyQuestionId] = {
              type: questionType,
              required: true,
            };
            break;
          default:
            break;
        }
        return acc;
      }, {} as FormState) || {};

    dispatch({ type: SET_INITIAL_FORM, initialState });
  }, [survey?.questions]);

  useEffect(() => {
    return () => {
      toggleAnonymous(true);
      updateEmail('');
    };
  }, [toggleAnonymous, updateEmail]);

  const isValidEmail = checkIsValidEmail(email);
  const isDisabled = checkIsInvalidForm(state, isValidEmail, isAnonymous);

  if (isLocked) return <LoadingPage />;

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      hasBackLink={true}
      actions={[]}
      actionsBarTopContent={null}
    >
      <Container className={styles.containerFlex}>
        <Column.Main className={styles.mainColumn}>
          <Title className="mb-M" size={TITLE_SIZE.HEADLINES}>
            {label('Ref: Page title')}
          </Title>

          {isError && (
            <Notification
              inheritStyle={classNames('mb-M')}
              look={NOTIFICATION_LOOK.ERROR}
              title={label('Ref: Some information is missing')}
            />
          )}

          <SurveyQuestions survey={survey} values={state} onChange={onChangeAnswer} label={label} />
        </Column.Main>
        <Column.Side>
          <FeedbackIllustration className={styles.illustration} />
          <EmailOrAnonymousToggler
            email={email}
            isValidEmail={isValidEmail}
            isAnonymous={isAnonymous}
            onEmailChange={onEmailChange}
            onToggleAnonymous={toggleAnonymous}
            profileEmail={profileEmail || ''}
          />
          <ActionsBar className={styles.actionButton}>
            <Button
              inheritStyle={styles.button}
              disabled={isDisabled}
              look={BUTTON_LOOK.PRIMARY}
              onClick={handleSubmit}
              data-testid="review-submit-survey-button"
            >
              {label('Ref: Submit')}
            </Button>
          </ActionsBar>
        </Column.Side>
      </Container>
    </SimpleFormPage>
  );
};

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case SET_INITIAL_FORM:
      return action.initialState || state;
    case UPDATE_FORM:
      return {
        ...state,
        ...(action.question
          ? {
              [action.question]: {
                answer: action.answer,
                type: action.questionType,
                ...(action.required !== undefined
                  ? { required: action.required }
                  : { required: state[action.question].required }),
              },
            }
          : {}),
      };
    case CLEAR_FORM:
      return {};
    default:
      return state;
  }
}

export default ReviewForm;
