import { SectionType } from '../../../../components/organisms/TilesList/TilesList.types';
import {
  getPortionAllergens,
  checkIfContainsAllergens,
} from '../../../Order/components/ProductsList/productList.helper';
import { FacilityMenu } from '../../../Order/types/orderState.types';
import { GetLoyaltySchemesResponse } from '../../api/api.types';

import { RedeemableMenuItem, RedeemableProductListItem } from './RedeemableProducts.types';

export const buildSections = (redeemableMenuItems: RedeemableProductListItem[]) => {
  return redeemableMenuItems.reduce<SectionType<RedeemableProductListItem>[]>(
    (sectionResult, redeemableMenuItem) => {
      if (sectionResult.find((x) => x.key === redeemableMenuItem.category)) return sectionResult;
      return [
        ...sectionResult,
        {
          title: redeemableMenuItem.category,
          key: redeemableMenuItem.category,
          filter: (items) => items.filter((item) => item.category === redeemableMenuItem.category),
        },
      ];
    },
    []
  );
};

export const getRedeemableMenuItems = (
  loyalty: GetLoyaltySchemesResponse,
  orderableMenus: FacilityMenu[]
): RedeemableMenuItem[] => {
  const loyaltyRedeemableMenuIds =
    loyalty.menuIdsProvidingProductsRedeemable?.length > 0
      ? loyalty.menuIdsProvidingProductsRedeemable
      : orderableMenus.map((x) => x.id);

  return loyaltyRedeemableMenuIds.flatMap((menuId) => {
    const menu = orderableMenus.find((menu) => menu.id === menuId);
    if (!menu || !menu.isOrderable) return [];

    const menuItemsWithRedeemablePortions = menu.menuItems.filter(
      (item) =>
        item.productPortions.some((portion) =>
          loyalty.productsRedeemable.some(
            (redeemable) =>
              redeemable.foodItemId === portion.foodItemId && redeemable.uomId === portion.uomId
          )
        ) && item.isOrderable
    );

    return menuItemsWithRedeemablePortions.map((menuItem) => {
      return { item: menuItem, menu: { id: menu.id, name: menu.name, date: menu.date } };
    });
  });
};

export const mapToListModel = ({
  redeemableMenuItems,
  selectedAllergens,
}: {
  redeemableMenuItems: RedeemableMenuItem[];
  selectedAllergens?: string[];
  loyaltySchemes?: GetLoyaltySchemesResponse[];
}): RedeemableProductListItem[] => {
  const listItems: RedeemableProductListItem[] = [];

  for (const redeemableItem of redeemableMenuItems) {
    const productItem = mapRedeemableProductToListItem(redeemableItem, selectedAllergens);
    if (productItem) listItems.push(productItem);
  }

  return listItems;
};

const mapRedeemableProductToListItem = (
  menuItem: RedeemableMenuItem,
  selectedAllergens?: string[]
): RedeemableProductListItem | null => {
  const defaultPortion = menuItem.item.productPortions.find((x) => x.isDefault === true);
  if (!defaultPortion) return null;

  const allergens = getPortionAllergens(defaultPortion);

  const showAllergensWarning =
    !!allergens.length && checkIfContainsAllergens(allergens, selectedAllergens ?? []);

  return {
    menuId: menuItem.menu.id,
    id: `${menuItem.menu.id}_${menuItem.item.name}_${defaultPortion.portion}`,
    menuItemId: menuItem.item.menuItemId,
    menu: menuItem.menu,
    uomId: defaultPortion.uomId,
    title: menuItem.item.name,
    description: menuItem.item.description,
    imageUrl: menuItem.item.listImage,
    price: menuItem.item.price,
    viewablePrices: menuItem.item.viewablePrices,
    calories: +defaultPortion.nutritions.calories,
    isVegan: defaultPortion.isVegan,
    isVegetarian: defaultPortion.isVegetarian,
    isMindful: defaultPortion.isMindful,
    isFavorite: defaultPortion.isFavorite,
    category: `${menuItem.menu.name} • ${menuItem.item.dayPart}`,
    subCategory: undefined,
    genericCategory: menuItem.item.genericCategory ?? '',
    moment: undefined,
    allergens: undefined,
    showAllergensWarning: showAllergensWarning ?? false,
    productCategory: undefined,
    'data-testid': `redeemable-product-${menuItem.item.menuItemId}`,
  };
};
