const labels = {
  All: {
    'Ref: stamp progress': 'Pokrok směrem k dalšímu známce',
    Reward: 'Odměna',
    Rewards: 'Odměny',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Známky',
    'Ref: Fulfill conditions': 'Získejte známky za účelem odměny!',
    'See Reward': 'Viz odměna',
    'Ref: No reward': 'Pro tuto chvíli žádná odměna',
  },
  RedeemableProducts: { 'Ref: Page title': 'Věrnostní odměny' },
};
export default labels;
