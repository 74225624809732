const labels = {
  All: { 'Ref: Page title': 'Поставщики' },
  SuppliersList: {
    'Ref: Supplier details': 'Смотрите подробности',
    'Missing supplier list': 'Мы не нашли поставщика.',
    'Try again later': 'Попробуйте позже',
  },
  SupplierDetails: {
    'Address label': 'Адрес:',
    'Website label': 'Интернет сайт:',
    'Details label': 'Детали',
  },
};
export default labels;
