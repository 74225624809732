const labels = {
  All: {
    'Ref: stamp progress': 'Postęp w kierunku następnej pieczątki',
    Reward: 'Nagroda',
    Rewards: 'Nagrody',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Pieczątki',
    'Ref: Fulfill conditions': 'Zdobądź pieczątki, aby wymienić je na nagrody!',
    'See Reward': 'Zobacz nagrodę',
    'Ref: No reward': 'Brak nagród na ten moment',
  },
  RedeemableProducts: { 'Ref: Page title': 'Nagrody lojalnościowe' },
};
export default labels;
