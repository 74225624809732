import { useIdleTimer } from 'react-idle-timer';

import { orderConfig } from '../../config';

import useResetKioskSession from './useResetKioskSession';

const useResetKioskSessionAfterTwoMin = () => {
  const [resetSession] = useResetKioskSession();
  const { isResetSessionEnabled } = orderConfig();

  const onIdle = () => {
    resetSession();
  };

  useIdleTimer({
    onIdle,
    timeout: 12000_000,
    throttle: 500,
    startManually: !isResetSessionEnabled,
  });
};

export default useResetKioskSessionAfterTwoMin;
