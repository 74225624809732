const labels = {
  All: { 'Ref: Page title': 'Dodavatelé' },
  SuppliersList: {
    'Ref: Supplier details': 'Prohlednout detaily',
    'Missing supplier list': 'Nenašli jsme žádný dodavatel.',
    'Try again later': 'Zkuste to později znovu',
  },
  SupplierDetails: {
    'Address label': 'Adresa:',
    'Website label': 'Webová stránka:',
    'Details label': 'Podrobnosti',
  },
};
export default labels;
