import { connect } from 'react-redux';

import FailOrder from '../components/FailOrder';
import { StateWithOrder } from '../types/orderState.types';

const mapStateToProps = (state: StateWithOrder) => {
  const facilityId = state.Order.lastOrder?.facilityId;
  return { facilityId };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FailOrder);
