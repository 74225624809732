import classNames from 'classnames';
import last from 'lodash/last';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, match, useHistory } from 'react-router';

import { INPUT_TYPE } from '../../../../constants';
import { SIZE } from '../../../../constants';
import useStateWithProps from '../../../../helpers/hooks/useStateWithProps';
import SwitchSite from '../../../Sites/components/SwitchSite';
import { useGetLocationsQuery, useLazyValidateQrCodeQuery } from '../../api/index';
import { pagePaths } from '../../config';
import {
  useServiceRequestSetupOption,
  useServiceRequestSetupOptionValue,
} from '../../hooks/useServiceRequestSetupOption';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import { renderLocationItem, renderLocationSections } from '../LocationItem/LocateLocationItem';

import LocateSkeleton from './LocateSkeleton';

import { QrIcon, LocationOffIcon } from '@/assets/icons';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import SearchBar from '@/components/atoms/SearchBar/SearchBar';
import Stepper from '@/components/atoms/Stepper';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import { Alert } from '@/components/molecules/Alert';
import { ContentList } from '@/components/molecules/ContentList';
import { InfoMessageProps } from '@/components/molecules/InfoMessage';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import QrCodeReader from '@/components/molecules/QrCode/QrCodeReader';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import OldTile from '@/components/organisms/Tile';
import { useFiltering } from '@/components/templates/ListPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { useGetAccountUserContextQuery } from '@/modules/Core/api/account/userContextApi';
import { isMyVillage } from '@/modules/Core/helpers/helpers';
import { LocateProps } from '@/modules/ServiceRequest/components/Locate';
import { LocationProps } from '@/modules/ServiceRequest/types/types';
import { State } from '@/types/state.types';

import './Locate.css';

type QrInfo = {
  scanProcessing: boolean;
  scanError: boolean;
  isSiteChanged: boolean;
  scanQRCode?: {
    siteId: string;
    locationId: string;
  };
};

const Locate = ({ goToNext, handleChange, myLocation }: LocateProps) => {
  const { label } = useServiceRequestTranslation(__filename);
  const dispatch = useDispatch();
  const history = useHistory();
  const [qrInfo, setQrInfo] = useState<QrInfo>({
    scanProcessing: false,
    scanError: false,
    isSiteChanged: false,
    scanQRCode: undefined,
  });

  const [checkQrInfo] = useLazyValidateQrCodeQuery();
  const { id: siteId, name: siteName } = useSelector(
    (state: State) => state.Core.context.site || { id: '', name: '' }
  );
  const { data: userData } = useGetAccountUserContextQuery({});

  const { data: searchableLocations = [], isFetching: locationsLoading } = useGetLocationsQuery({
    siteId,
  });

  useEffect(() => {
    const tmpProposedLocations: LocationProps[] = [];
    if (
      userData?.preferredLocation?.site?.id === siteId &&
      userData?.preferredLocation?.id !== myLocation?.id
    ) {
      tmpProposedLocations.push(userData?.preferredLocation);
    }
    if (tmpProposedLocations.length) {
      setProposedLocations(tmpProposedLocations || []);
    } else if (searchableLocations.length) {
      setProposedLocations(searchableLocations || []);
    }
  }, [userData, siteId, myLocation, searchableLocations]);

  const [proposedLocations, setProposedLocations] = useState<LocationProps[]>([]);
  const [parentLocations, setParentLocations] = useState<LocationProps[]>([]);
  const [proposeLocations, setProposeLocations] = useState<boolean>(false);
  const [proposedLocationsList, setProposedLocationsList] = useStateWithProps(proposedLocations);
  const [manualLocationModalIsOpen, setManualLocationModalIsOpen] = useState<boolean>(false);
  const [searchKeyWord, setSearchKeyWord] = useState<string>('');
  const [isSearchResultLimited, setIsSearchResultLimited] = useState(false);

  const canScanLocationQrOption = useServiceRequestSetupOption('canScanLocationQr');

  const canScanLocationQr = isMyVillage() || canScanLocationQrOption;

  const disableManualLocation = useServiceRequestSetupOption('disableManualLocation');
  const locationSearchResultLimitOption = useServiceRequestSetupOptionValue(
    'locationSearchResultLimit'
  )?.value;
  const locationSearchResultLimit = locationSearchResultLimitOption
    ? Number(locationSearchResultLimitOption)
    : undefined;
  const locationSearchKeywordLengthOption = useServiceRequestSetupOptionValue(
    'locationSearchKeywordLength'
  )?.value;
  const locationSearchKeywordLength = locationSearchKeywordLengthOption
    ? Number(locationSearchKeywordLengthOption)
    : undefined;

  const search = useMemo(
    () => ({
      searchableKeys: ['title', 'searchableTitle'],
      placeholder: label('Ref: search location by keywords', { textTransform: 'capitalize' }),
    }),
    [label]
  );

  const allowLocationSelectionFromLevelOption = useServiceRequestSetupOptionValue(
    'allowLocationSelectionFromLevel'
  )?.value;

  const allowLocationSelectionFromLevel = allowLocationSelectionFromLevelOption
    ? parseInt(allowLocationSelectionFromLevelOption)
    : 3;

  const drillDownLocation = useCallback(
    (parentLocation: LocationProps) => {
      const filterProposedLocations = searchableLocations.filter(
        (el) => el.parentId === parentLocation.id
      );

      const tempParentLocations: LocationProps[] = [...parentLocations];
      tempParentLocations.push(parentLocation);

      setParentLocations(tempParentLocations);
      setProposeLocations(true);
      setProposedLocationsList(filterProposedLocations);
    },
    [parentLocations, searchableLocations, setProposedLocationsList]
  );

  const upOneLocation = useCallback(() => {
    const tempParentLocations: LocationProps[] = [...parentLocations];
    const removedLocation: LocationProps | undefined = tempParentLocations.pop();

    if (removedLocation) {
      const filteredProposedLocations = searchableLocations.filter(
        (el) => el.parentId === removedLocation.parentId
      );
      setParentLocations(tempParentLocations);
      setProposeLocations(true);
      setProposedLocationsList(filteredProposedLocations);
    }
  }, [parentLocations, searchableLocations, setProposedLocationsList]);

  const locationMapper = useCallback(
    (location: LocationProps, prefferedLocation: boolean = false) => {
      const primaryAction = () => {
        handleChange &&
          handleChange({
            myLocation: location,
            roomText: '',
          });
        goToNext();
      };
      const sencondaryAction = () => drillDownLocation(location);

      const lastItem = last(parentLocations);
      const disableSecondaryAction = lastItem?.id === location.id;

      return renderLocationItem(
        location,
        allowLocationSelectionFromLevel,
        primaryAction,
        sencondaryAction,
        label,
        prefferedLocation,
        disableSecondaryAction
      );
    },
    [
      allowLocationSelectionFromLevel,
      parentLocations,
      drillDownLocation,
      goToNext,
      handleChange,
      label,
    ]
  );

  useEffect(() => {
    if (history.location.state) {
      const historyState: { qrInfo?: QrInfo } = history.location.state as { qrInfo?: QrInfo };
      if (historyState.qrInfo) {
        setQrInfo(historyState.qrInfo);
        history.push(history.location);
      }
    }
  }, [history]);

  const openManualLocationModal = () => {
    setManualLocationModalIsOpen(true);
  };

  const closeManualLocationModal = () => {
    setManualLocationModalIsOpen(false);
  };

  const renderManualLocationModal = useCallback(() => {
    const cySelector = 'cy-alert-manual-location';
    return (
      <Alert
        isOpen={manualLocationModalIsOpen}
        onDismiss={closeManualLocationModal}
        className={cySelector}
        header={label('Ref: Enter Location name')}
        data-testid="service-request-manual-location-modal"
        inputs={[
          {
            name: 'roomText',
            type: INPUT_TYPE.TEXT,
            placeholder: label('Ref: Location name'),
            attributes: { title: label('Ref: Location name') },
          },
        ]}
        buttons={[
          {
            text: label('cancel', { textTransform: 'capitalize' }),
            role: 'cancel',
            className: `secondary ${cySelector}-button-cancel`,
            'data-testid': 'service-request-manual-location-cancel-action',
          },
          {
            text: label('confirm', { textTransform: 'capitalize' }),
            handler: (data) => {
              if (data['roomText'].match(/^[^\s]+(\s.*)?$/)) {
                handleChange && handleChange({ myLocation: undefined, roomText: data['roomText'] });
                goToNext();
              }
            },
            className: `${cySelector}-button-confirm`,
            'data-testid': 'service-request-manual-location-confirm-action',
          },
        ]}
      />
    );
  }, [goToNext, handleChange, label, manualLocationModalIsOpen]);

  const renderTreeNavigation = useCallback(() => {
    if (parentLocations.length > 0) {
      const locationTree = parentLocations.map((loc) => loc.name);

      const notificationActions = [
        {
          children: label('close'),
          onClick: () => {
            setProposeLocations(false);
            setParentLocations([]);
          },
          'data-testid': 'service-request-location-tree-close-action',
        },
      ];

      // Deeper than first level
      if (parentLocations.length > 1) {
        notificationActions.unshift({
          children: label('Ref: Up one level'),
          onClick: () => {
            upOneLocation();
          },
          'data-testid': 'service-request-location-tree-up-one-level-action',
        });
      }

      return (
        <Notification
          title={label('Displaying locations under', { end: 'colon' })}
          data-testid="service-request-location-tree"
          actions={notificationActions}
        >
          {locationTree.join(' / ')}
        </Notification>
      );
    }
  }, [label, parentLocations, upOneLocation]);

  const handleQRCodeScan = useCallback(
    async ({ response: qrCode }: { response: string }) => {
      setQrInfo({ ...qrInfo, scanProcessing: true });
      try {
        const parsedUrl = new URL(qrCode);
        const match: match<{ siteId: string; locationId: string }> | null = matchPath(
          parsedUrl.pathname,
          {
            path: '/service_request/qr/:siteId/:locationId',
            exact: false,
            strict: false,
          }
        );
        if (match?.params && match.params.siteId && match.params.locationId) {
          const qrSiteId = match.params.siteId;
          const qrLocationId = match.params.locationId;
          const res = await checkQrInfo({
            siteId: qrSiteId,
            locationId: qrLocationId,
            currentSiteId: siteId,
            useErrorBoundary: false,
            dispatch,
          });
          if (res.isSuccess) {
            history.push(history.location.pathname, {
              qrInfo: {
                ...qrInfo,
                scanProcessing: false,
                isSiteChanged: res.data?.isSiteChanged,
                scanQRCode: { locationId: qrLocationId, siteId: qrSiteId },
              },
            });
          } else if (res.isError) {
            setQrInfo({ ...qrInfo, scanProcessing: false, scanError: true });
          }
        } else {
          setQrInfo({ ...qrInfo, scanError: true });
        }
      } catch (error) {
        setQrInfo({ ...qrInfo, scanError: true });
      }
    },
    [checkQrInfo, siteId, setQrInfo, qrInfo, dispatch, history]
  );

  const renderQrCodeButton = useMemo(() => {
    return (
      <div className="qrButtonWrapper" key="qrButtonWrapper">
        <QrCodeReader
          label={label}
          buttonLabel={label('Ref: Scan QR Code')}
          title={label('Ref: Scan QR Code')}
          buttonProps={{
            look: 'secondary',
            size: SIZE.SMALL,
            loading: qrInfo.scanProcessing,
            affix: QrIcon,
            contentCenterAlign: true,
            'data-testid': 'service-request-qr-code',
          }}
          onQrCodeScan={(response) => handleQRCodeScan({ response: response?.response! })}
          onOpen={() => {
            setQrInfo({
              scanProcessing: false,
              scanError: false,
              scanQRCode: undefined,
              isSiteChanged: false,
            });
          }}
        />
        <Alert
          isOpen={qrInfo.scanError}
          onDismiss={() => {
            setQrInfo({ ...qrInfo, scanError: false, scanProcessing: false });
          }}
          className="popup-warning"
          header={label('Ref: Scanner error header')}
          message={label('Ref: Error body')}
          buttons={[label('Ref: ok')]}
          data-testid="service-request-qr-code-error"
        ></Alert>
      </div>
    );
  }, [handleQRCodeScan, label, qrInfo, setQrInfo]);

  const searchableLocationsWithTestingId = useMemo(
    () =>
      searchableLocations.map((loc) => ({
        ...loc,
        'data-testid': loc.id,
      })),
    [searchableLocations]
  );

  const { filterItems, searchString, handleSearchChange } = useFiltering({
    items: searchableLocationsWithTestingId,
    filter: undefined,
    search: search,
    useMetadataSet: true,
    resultLimit: locationSearchResultLimit,
  });

  // 1st load - buildings only
  // navigate - parents - all children only
  // search - all results
  // reset - buildings only
  const items = useMemo(() => {
    const itemsList: LocationProps[] = [];
    if (filterItems.length > 0) {
      setIsSearchResultLimited(
        (locationSearchResultLimit &&
          searchKeyWord.trim().length > 0 &&
          filterItems.length >= locationSearchResultLimit) ||
          false
      );

      if (proposeLocations) {
        itemsList.push(...proposedLocationsList);
      } else {
        if (searchKeyWord.trim().length === 0) {
          itemsList.push(...filterItems.filter((loc) => loc.level === 1));
        } else {
          itemsList.push(...filterItems);
        }
      }
    }
    return itemsList.map((location) => locationMapper(location));
  }, [
    filterItems,
    locationMapper,
    locationSearchResultLimit,
    proposeLocations,
    proposedLocationsList,
    searchKeyWord,
  ]);

  const handleSearchLocationsChange = useCallback(
    (searchString: string) => {
      const minKeywordLength = locationSearchKeywordLength ? locationSearchKeywordLength : 1;
      // convert to ASCII
      const asciiSearchString = searchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      setSearchKeyWord(asciiSearchString);
      if (
        asciiSearchString.trim().length >= minKeywordLength ||
        asciiSearchString.trim().length === 0
      ) {
        handleSearchChange(asciiSearchString);
      }
    },
    [handleSearchChange, locationSearchKeywordLength]
  );

  // TODO: workaround to wait for new locations when switching site.
  useEffect(() => {
    if (!qrInfo.scanProcessing && qrInfo.scanQRCode && !qrInfo.scanError && !locationsLoading) {
      const matchedLocation = searchableLocations.find(
        (el) => el.id === qrInfo.scanQRCode?.locationId
      );
      if (matchedLocation?.title) {
        handleSearchLocationsChange(matchedLocation.name);
      } else {
        handleSearchLocationsChange(qrInfo.scanQRCode?.locationId);
      }
      setQrInfo({
        ...qrInfo,
        scanProcessing: false,
        scanError: false,
        scanQRCode: undefined,
      });
    }
  }, [searchableLocations, qrInfo, handleSearchLocationsChange, setQrInfo, locationsLoading]);

  const getSections = useCallback(
    () =>
      renderLocationSections(
        label,
        myLocation ? locationMapper(myLocation) : undefined,
        userData?.preferredLocation ? locationMapper(userData.preferredLocation, true) : undefined,
        searchString.trim(),
        parentLocations
      ),
    [label, locationMapper, myLocation, parentLocations, userData, searchString]
  );

  const noResults: InfoMessageProps = useMemo(
    () => ({
      icon: <LocationOffIcon />,
      title: label('Ref: No results'),
    }),
    [label]
  );

  const config = {
    collapsibleSections: true,
    disableInfiniteScroll: true,
    displaySectionItemNumber: true,
  };

  const topContent = useMemo(
    () => (
      <>
        {qrInfo.isSiteChanged && (
          <Notification
            look={NOTIFICATION_LOOK.SUCCESS}
            title={label('Ref: Notification title')}
            inheritStyle={classNames('mb-L')}
            dismissable
            onDismiss={() =>
              setQrInfo({
                ...qrInfo,
                isSiteChanged: false,
              })
            }
          >
            {siteName &&
              label('Ref: Notification info', {
                replace: { 'site name': siteName },
              })}
          </Notification>
        )}
        <SwitchSite
          sentence={label('Ref: Switch site component intro sentence')}
          redirectTo={pagePaths['Create'] + '/locate'}
        />
        {parentLocations.length === 0 && (
          <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
            {label('Ref: Page title')}
          </Title>
        )}
        {renderTreeNavigation()}
      </>
    ),
    [qrInfo, label, parentLocations.length, renderTreeNavigation, siteName, setQrInfo]
  );

  const manualLocation = useMemo(
    () =>
      parentLocations.length === 0 && !disableManualLocation ? (
        <ActionsBar inMainColumn>
          <Button
            onClick={() => {
              openManualLocationModal();
            }}
            look={BUTTON_LOOK.TERTIARY}
            data-testid="service-request-locate-manual-location-modal-action"
          >
            {label('Ref: Enter manual location')}
          </Button>
        </ActionsBar>
      ) : null,
    [disableManualLocation, label, parentLocations.length]
  );

  const searchBar = useMemo(
    () => (
      <div key={`sr_searchbar_locate`}>
        <SearchBar
          {...search}
          withCardWrapper
          collapsible={!!renderQrCodeButton}
          right={canScanLocationQr ? [renderQrCodeButton] : []}
          searchString={searchString}
          handleChange={handleSearchLocationsChange}
          data-testid="locate-search-bar"
          srOnlyText={label('Ref: Open the location search field')}
        />
        <br />
        {locationSearchResultLimit && isSearchResultLimited && (
          <Notification
            look={NOTIFICATION_LOOK.DETAILS}
            title={label('Ref: Search Result is limited to:', {
              replace: { limit: locationSearchResultLimit.toString() },
            })}
          />
        )}
      </div>
    ),
    [
      search,
      renderQrCodeButton,
      canScanLocationQr,
      searchString,
      handleSearchLocationsChange,
      locationSearchResultLimit,
      isSearchResultLimited,
      label,
    ]
  );

  const itemsWithTestingId = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        'data-testid': `locate-${item.id}`,
      })),
    [items]
  );

  return (
    <SimpleFormPage title={label('Ref: Top bar title')}>
      <Container>
        <Column.Main>
          {topContent}
          {parentLocations.length === 0 ? searchBar : undefined}
          <ContentList
            items={itemsWithTestingId}
            itemRenderer={(item) => <OldTile key={item.id} {...item} />}
            config={config}
            sections={getSections()}
            noResults={noResults}
            className="skeletonItems"
            isLoading={locationsLoading}
            longTileTextVisible
          />
          {locationsLoading ? (
            <div className="skeletonItems">
              {[1, 2].map((row) => (
                <LocateSkeleton key={row} />
              ))}
            </div>
          ) : (
            parentLocations.length === 0 && !disableManualLocation && renderManualLocationModal()
          )}
          {manualLocation}
        </Column.Main>
        <Column.Side>
          <Stepper />
        </Column.Side>
      </Container>
    </SimpleFormPage>
  );
};

export default Locate;
