import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { BackButtonProps } from './BackButton.types';

import { ArrowLeftIcon } from '@/assets/icons';

import './BackButton.css';

const BackButton = ({ 'data-testid': testId, customPath, onBeforeBack }: BackButtonProps) => {
  const [previousPaths, setPreviousPaths] = useState<string[]>([]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPaths([...previousPaths, location.pathname]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const goBack = () => {
    if (onBeforeBack) {
      onBeforeBack();
    }

    if (customPath) {
      history.push(customPath);
      return;
    }

    const pathHistory = previousPaths;
    let lastPath = pathHistory.pop();

    if (lastPath !== location.pathname || previousPaths?.length === 0) {
      setPreviousPaths([...pathHistory]);
      history.goBack();
    } else {
      let samePathSet = 0;
      while (lastPath === location.pathname) {
        lastPath = pathHistory.pop();
        samePathSet++;
      }
      setPreviousPaths([...pathHistory]);
      history.go(-samePathSet);
    }
  };

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && goBack()}
      onClick={goBack}
      role="button"
      id="BackButton"
      data-testid={testId}
    >
      <ArrowLeftIcon name="chevron-back" data-cy="back-button" />
      <span className="sr-only">Return to the previous page</span>
    </div>
  );
};

export default BackButton;
