import { connect } from 'react-redux';

import CartItemTile from '../components/CartItemTile/CartItemTile';
import { StateWithOrder } from '../types/orderState.types';

const mapStateToProps = (state: StateWithOrder, { menuId }: { menuId: number }) => {
  return {
    languageCode: state.Shared?.language?.currentLanguageCode,
    promotionalDiscounts: state.Order.draft?.promotionalDiscountForItems,
    redeemedFoodItems: state.Order.draft?.redeemedFoodItems,
    menu: state.Order.menus.find((menu) => menu.id === menuId),
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CartItemTile);
