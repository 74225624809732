const labels = {
  All: { 'Ref: Page title': 'Fornecedores' },
  SuppliersList: {
    'Ref: Supplier details': 'Veja detalhes',
    'Missing supplier list': 'Nós não encontramos nenhum fornecedor.',
    'Try again later': 'Tente mais tarde',
  },
  SupplierDetails: {
    'Address label': 'Endereço:',
    'Website label': 'Local na rede Internet:',
    'Details label': 'detalhes',
  },
};
export default labels;
