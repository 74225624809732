const labels = {
  All: {
    'Ref: Redeem coffee description': 'Uw gratis, vers gebrouwen kopje koffie wacht',
    'Ref: Congratulations': 'Gefeliciteerd',
    'Ref: Get free coffee': 'Krijg gratis koffie!',
    'Ref: Free coffee description': 'Vul je beker, verdien punten voor een gratis koffie.',
    'Ref: Redeem': 'Inwisselen!',
  },
  StampPage: {
    'Ref: Page title': 'Gratis koffie',
    'Ref: seconds left': 'seconden over',
    'Ref: How it works': 'Hoe het werkt',
    'Ref: How it works description':
      'Telkens wanneer u een koffie koopt, drukt u op de scanknop en scant u de verstrekte QR -code bij de kassa.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Scan QR-code',
    'Ref: Collect reward': 'Verzamel beloning',
    'Ref: Reward collected': 'Beloning verzameld',
    'Ref: Invalid QR Code': 'Ongeldige QR -code',
    'Ref: Invalid QR Code description': 'De QR -code wordt niet herkend',
    'Ref: Close': 'Sluiten',
    'Ref: Redeem button': 'Inwisselen',
    "Ref: The QR Code isn't recognized": 'De QR -code wordt niet herkend',
    'Ref: Cancel': 'Annuleren',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Gefeliciteerd!',
    'Ref: Confirm redeem description':
      'Weet u zeker dat u dit wilt gebruiken? Als dat zo is, hebt u slechts 60 seconden om uw telefoon bij de kassa te laten zien en uw aankoop te voltooien',
    'Ref: Congratulations modal description': 'Neem nu je gratis koffie!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Gratis koffie!', 'Ref: See All': 'Alles zien' },
};
export default labels;
