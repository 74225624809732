import { connect } from 'react-redux';

import Menu from '../components/Order';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
