import { cleanup, renderHook } from '@testing-library/react';

import { defaultSuggestion } from '../../../__mocks/mock';
import { useMomentBasedSuggestions } from '../useMomentBasedSuggestions';

describe('useMomentBasedSuggestions hook', () => {
  afterAll(() => cleanup());

  const suggestioms = [
    { ...defaultSuggestion, mealName: 'moment1' },
    { ...defaultSuggestion, mealName: 'moment2' },
    { ...defaultSuggestion, mealName: 'moment2' },
    { ...defaultSuggestion, mealName: 'moment3' },
  ];

  const suggestioms2 = [
    { ...defaultSuggestion, mealName: 'moment' },
    { ...defaultSuggestion, mealName: 'moment' },
    { ...defaultSuggestion, mealName: 'moment' },
    { ...defaultSuggestion, mealName: 'moment' },
  ];

  const moments = ['moment1', 'moment2', 'moment3'];

  describe('if multiple moments available', () => {
    it('should filter suggestions for selected moment', () => {
      const render = renderHook(() => useMomentBasedSuggestions(suggestioms, 'moment2', moments));
      expect(render.result.current.length).toBe(2);
    });
  });

  describe('if only one moment available', () => {
    it('should return all suggestions', () => {
      const render = renderHook(() =>
        useMomentBasedSuggestions(suggestioms2, 'moment', ['moment'])
      );
      expect(render.result.current.length).toBe(4);
    });
  });
});
