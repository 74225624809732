import { IonList } from '@ionic/react';
import React, { useState, useEffect, useCallback } from 'react';

import { Section } from './Section';
import { SectionListProps, SectionType } from './TilesList.types';

import styles from './TilesList.module.css';

const addOrRemove = (array: any[], item: any) => {
  const exists = array.includes(item);

  if (exists) {
    return array.filter((c) => c !== item);
  } else {
    return [...array, item];
  }
};

export const SectionTileList = <T extends {}>({
  items,
  config,
  twoTilesColumns = false,
  sections = [],
  sectionRefs,
  longTileTextVisible,
  tilesStyle,
  itemRenderer,
  tileVariant,
  className,
}: SectionListProps<T>) => {
  const [tileSections, setTileSections] = useState<SectionType<T>[]>([]);
  const [expandedSections, setExpandedSections] = useState<(string | number)[]>([]);

  useEffect(() => {
    const expanded: number[] = [];
    let isSameArray = sections.length === tileSections.length;
    const filteredTileSections: SectionType<T>[] = sections.map((section, indexSection) => {
      let subSections: SectionType<T>[] = [];
      if (section?.sections?.length) {
        subSections = section.sections
          .filter((el) => el.filter(items).length)
          .map((subSection, indexSubSection) => {
            return { ...subSection, key: `${indexSection}-${indexSubSection}` };
          });
      }

      if (!section.isCollapsedByDefault && !(config?.singleExpandedSection && indexSection !== 0)) {
        expanded.push(indexSection);
      }

      if (isSameArray) {
        // because we got some deps which are the same but different instance, check if the array is really different
        // TODO - need to find why instance is different - this is a rabbit hole
        const ogItem = tileSections[indexSection];
        isSameArray = indexSection === ogItem.key && section.title === ogItem.title;
      }

      return { ...section, sections: subSections, key: indexSection };
    });

    if (!isSameArray) {
      setExpandedSections(expanded);
    }

    setTileSections(filteredTileSections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTileSections, setExpandedSections, sections, config, items]);

  const toggle = useCallback(
    (
      sectionId: string | number,
      parentSectionId: string | number | null,
      value: boolean,
      onChange?: () => void
    ) => {
      const updatingTileSections = [...tileSections];
      let updatingSection = null;
      if (parentSectionId !== null) {
        const parentSection = updatingTileSections.find((s) => s.key === parentSectionId);
        updatingSection = parentSection?.sections?.find((s) => s.key === sectionId);
      } else {
        updatingSection = updatingTileSections.find((s) => s.key === sectionId);
      }

      let newExpandedSections = addOrRemove(expandedSections, updatingSection?.key);

      if (config?.singleExpandedSection && value) {
        newExpandedSections = [updatingSection?.key];
      }

      setExpandedSections(newExpandedSections);
      onChange && onChange();
    },
    [config?.singleExpandedSection, expandedSections, tileSections]
  );

  return (
    <IonList className={(styles.tilesList, className)} data-cy="tiles-list">
      {tileSections.map((section, i) => (
        <Section
          key={section.key}
          reference={sectionRefs && sectionRefs.current ? sectionRefs.current[i] : undefined}
          section={section}
          sectionItems={[...section.filter(items)]}
          expanded={expandedSections.includes(section.key)}
          onExpand={toggle}
          config={config}
          twoTilesColumns={twoTilesColumns}
          longTileTextVisible={longTileTextVisible}
          tilesStyle={tilesStyle}
          itemRenderer={itemRenderer}
          tileVariant={tileVariant}
          emptyBottomSpace={i === tileSections.length - 1}
        />
      ))}
    </IonList>
  );
};
