import { TextareaChangeEventDetail } from '@ionic/core';
import { IonTextarea } from '@ionic/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { ErrorCircleIcon } from '../../../assets/icons';
import useStateWithProps from '../../../helpers/hooks/useStateWithProps';

import { TextareaProps } from './Textarea.types';

import styles from './Textarea.module.css';

const Textarea = ({
  label,
  id,
  name,
  placeholder,
  value,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  required,
  disabled,
  isValid,
  hasError,
  comment,
  maxlength,
  onChange,
  ...rest
}: TextareaProps) => {
  const [initialState, setInitialState] = useState<boolean>(true);
  const [textareaValue, setTextareaValue] = useState<string | undefined | null>(value);
  const [textareaCount, setTextareaCount] = useState<number>(value?.length || 0);
  const [isInvalid, setIsInvalid] = useStateWithProps<boolean | undefined>(hasError);

  useEffect(() => {
    if (value !== textareaValue) {
      setTextareaValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (maxlength !== undefined) {
      textareaCount > maxlength || (initialState === false && textareaCount === 0)
        ? setIsInvalid(true)
        : setIsInvalid(false);
    }
  }, [textareaCount, initialState, maxlength, setIsInvalid]);

  const handleTextareaChange = (e: CustomEvent<TextareaChangeEventDetail>) => {
    setTextareaValue(e.detail?.value || undefined);
    setTextareaCount(e.detail?.value?.length || 0);
    setInitialState(false);
    if (onChange) {
      onChange(e, e.detail?.value);
    }
  };

  return (
    <div className={classNames({ [styles.isValid]: isValid }, { [styles.isInvalid]: isInvalid })}>
      {(label || maxlength) && (
        <div className={classNames(styles.headline, 'mb-S', { [styles.withoutLabel]: !label })}>
          {label && (
            <label
              id={`${id}-label`}
              htmlFor={id}
              className={classNames(styles.label, { [styles.disabledLabel]: disabled })}
            >
              {label}
              {required && ' *'}
            </label>
          )}
          {maxlength !== undefined && (
            <span className={classNames(styles.counter)}>
              {textareaCount} / {maxlength}
            </span>
          )}
        </div>
      )}
      <IonTextarea
        className={classNames(styles.textarea)}
        id={id}
        name={name}
        data-cy={dataCy}
        data-testid={dataTestId}
        disabled={disabled}
        placeholder={placeholder}
        onIonChange={handleTextareaChange}
        value={textareaValue}
        title={placeholder || label}
        aria-labelledby={label ? `${id}-label` : undefined}
        {...rest}
      >
        {hasError && (
          <ErrorCircleIcon className={classNames('icon_danger_filled', styles.textareaIcon)} />
        )}
      </IonTextarea>
      {comment && (
        <p
          className={classNames(styles.msg)}
          data-testid={`${dataTestId}-message`}
          aria-label={label ? `${id}-label-message` : undefined}
        >
          {comment}
        </p>
      )}
    </div>
  );
};

export default Textarea;
