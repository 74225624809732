const labels = {
  All: {
    'Ref: Redeem coffee description': 'כוס הקפה החינמית והטרייה שלך מחכה',
    'Ref: Congratulations': 'מזל טוב',
    'Ref: Get free coffee': 'קבל קפה בחינם!',
    'Ref: Free coffee description': 'מלא את הספל שלך, הרוויח נקודות לעבר קפה בחינם.',
    'Ref: Redeem': 'לִפְדוֹת!',
  },
  StampPage: {
    'Ref: Page title': 'קפה חינם',
    'Ref: seconds left': 'שניות נותרו',
    'Ref: How it works': 'איך זה עובד',
    'Ref: How it works description':
      'בכל פעם שאתה רוכש קפה, לחץ על כפתור הסריקה וסרוק את קוד ה- QR שסופק בפנקס המזומנים.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'סרוק קוד QR',
    'Ref: Collect reward': 'לאסוף תגמול',
    'Ref: Reward collected': 'תגמול שנאסף',
    'Ref: Invalid QR Code': 'קוד QR לא חוקי',
    'Ref: Invalid QR Code description': 'קוד ה- QR אינו מוכר',
    'Ref: Close': 'סגור',
    'Ref: Redeem button': 'לִפְדוֹת',
    "Ref: The QR Code isn't recognized": 'קוד ה- QR אינו מוכר',
    'Ref: Cancel': 'לְבַטֵל',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'מזל טוב!',
    'Ref: Confirm redeem description':
      'בטוח שאתה רוצה להשתמש בזה? אם כן, יש לך רק 60 שניות להציג את הטלפון שלך בקופה ולהשלים את הרכישה שלך',
    'Ref: Congratulations modal description': 'תפוס את הקפה החינמי שלך עכשיו!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'קפה חינם!', 'Ref: See All': 'ראה הכל' },
};
export default labels;
