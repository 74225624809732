import { screen, cleanup } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultModifier } from '../../__mocks/mock';
import { AllergenType } from '../../types/orderState.types';

import ModifierCard from './ModifierCard';

describe('ModifierCard component', () => {
  afterAll(() => cleanup());

  const selectedAllergens: AllergenType[] = [AllergenType.Milk];

  describe('modifier card gets modifiers data and selected allergens', () => {
    const handleChange = jest.fn();

    renderComponent(ModifierCard, {
      modifier: defaultModifier,
      isoCode: 'USD',
      languageCode: 'en-US',
      modifierErrors: {},
      selectedAllergens: selectedAllergens,
      selectedModifiers: [
        { itemQuantities: {}, itemIds: ['x'], modifierId: 1, displayText: 'Selected' },
      ],
      handleChange,
    });

    it('should display button with correct id and text', () => {
      const buttonText = screen.queryByTestId('button-infos-modal-16')?.textContent;

      expect(buttonText).toContain('Info');
    });

    it('should display modal with proper values', async () => {
      const button = screen.getByTestId('button-infos-modal-16');
      await userEvent.click(button);

      const openedModal = screen.getByTestId('modal-infos-1');
      expect(openedModal).toHaveClass('mb-M');

      expect(screen.getByText('Sugar')).toBeTruthy();
      expect(screen.getByText('12 g')).toBeTruthy();
      expect(screen.getByText('Fat')).toBeTruthy();
      expect(screen.getByText('16 g')).toBeTruthy();
      expect(
        screen.getByTestId('card-modifier-nutrition-list-1-modifiers-calories-list-item-value')
          .textContent
      ).toContain('11 kJ');
    });

    it('should display allergen alert', async () => {
      const button = screen.getByTestId('button-infos-modal-16');
      await userEvent.click(button);

      const openedModal = screen.getByTestId('modal-infos-1');
      expect(openedModal.getElementsByClassName('warningAllergenIcon')).toBeTruthy();
      expect(openedModal.getElementsByTagName('svg').length).toEqual(4);
    });

    it('should display the correct number of free items', async () => {
      expect(screen.queryByText('Number of free items: 2')).toBeInTheDocument();
      expect(screen.getByText('• 2 for free')).toBeInTheDocument();

      const increaseButton = screen.getByTestId('modifier-item-1-quantity-setter-increment-button');
      await userEvent.click(increaseButton);

      expect(screen.queryByText('Number of free items: 1')).toBeInTheDocument();
      expect(screen.queryByText('• 1 for free')).toBeInTheDocument();

      const decreaseButton = screen.getByTestId('modifier-item-1-quantity-setter-decrement-button');
      await userEvent.click(decreaseButton);

      expect(screen.queryByText('Number of free items: 2')).toBeInTheDocument();
      expect(screen.queryByText('• 2 for free')).toBeInTheDocument();
    });
  });
});
