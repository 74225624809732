import { render } from '@testing-library/react';

import {
  buildConfig,
  buildFilteringState,
  getCartInfo,
  getFilteredSuggestions,
  getProductIllustration,
  mapSuggestionItemToProductItem,
  mapToListModel,
} from '../productList.helper';

import {
  defaultCart,
  defaultFacilityMenu,
  defaultProductPortion,
  menuItemWithPortions,
} from '@/modules/Order/__mocks/mock';
import {
  Allergen,
  AllergenType,
  Cart,
  FacilityMenu,
  MenuItem,
  OrderDraft,
  SuggestedMenuItem,
} from '@/modules/Order/types/orderState.types';

const allergens: Allergen[] = [
  { allergen: AllergenType.Alcohol, contains: true, id: AllergenType.Alcohol, name: 'Alcohol' },
  { allergen: AllergenType.Almonds, contains: true, id: AllergenType.Almonds, name: 'Almonds' },
  { allergen: AllergenType.Cashews, contains: true, id: AllergenType.Cashews, name: 'Cashews' },
];
const selectedAllergens: AllergenType[] = [AllergenType.Milk];

let suggestedMenuItem: SuggestedMenuItem = {
  productCategory: 'Cakes',
  menuId: 9504,
  menuItemId: 17202110,
  genericCategory: 'Other',
  name: 'Bite cake',
  mealName: 'Breakfast',
  foodSubCategoryDescription: undefined,
  description: 'Bite cake description',
  price: 3,
  productPortions: [
    {
      portionId: '17202110_37952',
      foodItemSourceKey: '',
      name: 'Bite cake',
      description: 'Bite cake description',
      ingredients: '',
      allergens: allergens,
      nutritions: {
        calories: 0,
        kilojoules: 0,
        carbohydrates: 0,
        fat: 0,
        protein: 0,
        saturatedFat: 0,
        transFat: 0,
        polyunsaturatedFat: 0,
        cholesterol: 0,
        sodium: 0,
        dietaryFiber: 0,
        sugar: 0,
        potassium: 0,
        iron: 0,
        calcium: 0,
        vitaminA: 0,
        vitaminC: 0,
        caloriesUnits: 0,
      },
      portionSize: '',
      portion: 'standard',
      gramWeight: '0',
      modifiers: [],
      isVegan: false,
      isVegetarian: false,
      isDefault: true,
      price: 3,
      viewablePrices: [{ name: 'X', value: 4 }],
      milkAdditive: true,
      soyBeansAdditive: false,
      eggsAdditive: true,
      sesameSeedsAdditive: false,
      peanutsAdditive: false,
      wheatAdditive: false,
      fishAdditive: false,
      nutsAdditive: true,
      co2Rating: '',
      co2Value: '0',
      isMindful: false,
      isFavorite: false,
      uomId: 37952,
      foodItemId: 37014,
      additives: undefined,
      uomBarcodes: [],
      alcoholContent: null,
    },
  ],
  listImage: null,
  detailsImage: null,
};

const menuId = 1;
const menuItemOne: MenuItem = {
  ...menuItemWithPortions,
  dayPart: 'Any',
  productPortions: [
    { ...defaultProductPortion, foodItemId: 2, portionId: '22', uomId: 222, allergens: allergens },
  ],
};
const menuItemTwo: MenuItem = { ...menuItemWithPortions, dayPart: 'Other' };
const menuItemNotOrderable: MenuItem = {
  ...menuItemWithPortions,
  dayPart: 'Other',
  isOrderable: false,
};
const menuItemWithoutDefaultPortion: MenuItem = {
  ...menuItemWithPortions,
  dayPart: 'Other',
  productPortions: [
    { ...defaultProductPortion, foodItemId: 2, portionId: '22', uomId: 222, isDefault: false },
  ],
};
const menu: FacilityMenu = {
  ...defaultFacilityMenu,
  id: menuId,
  menuItems: [menuItemOne, menuItemTwo, menuItemNotOrderable, menuItemWithoutDefaultPortion],
};
const menuItemIdInCart = 123;
const foodItemId = 223;
const uomId = 323;
const cart: Cart = {
  ...defaultCart,
  siteId: '1234',
  menuPortionItems: [
    {
      ...defaultCart.menuPortionItems![0],
      menuItemId: menuItemIdInCart,
      foodItemId: foodItemId,
      uomId: uomId,
    },
  ],
};

const orderDraft: OrderDraft = {
  delivery: 0,
  orderId: 1456,
  total: 5,
  subtotal: 4,
  promotionDiscount: 1,
  tax: 1,
  subsidy: undefined,
  promotionalDiscountForItems: [],
};

const defaultFilters = {
  filter_allergens: { Milk: true, Eggs: true },
  filter_dishes: { vegan: true },
  filter_calories: { '466': true },
  filter_allergens_highlight: { hide: true },
  filter_favorites: { favorites: false },
  filter_moment: { Any: true },
  filter_category: { Some: true },
};

describe('mapSuggestionItemToProductItem', () => {
  it('should return cart mapped suggestion item to Product Item', () => {
    const result = mapSuggestionItemToProductItem(suggestedMenuItem, true, selectedAllergens);
    expect(result?.id).toEqual(`${suggestedMenuItem.menuId}_${suggestedMenuItem.menuItemId}`);
    expect(result?.menuItemId).toEqual(suggestedMenuItem.menuItemId);
    expect(result?.uomId).toEqual(suggestedMenuItem.productPortions[0].uomId);
    expect(result?.description).toEqual(suggestedMenuItem.description);
    expect(result?.imageUrl).toEqual('');
    expect(result?.price).toEqual(suggestedMenuItem.price);
    expect(result?.isVegan).toEqual(suggestedMenuItem.productPortions[0].isVegan);
    expect(result?.isVegetarian).toEqual(suggestedMenuItem.productPortions[0].isVegetarian);
    expect(result?.showAllergensWarning).toEqual(false);
    expect(result?.genericCategory).toEqual(suggestedMenuItem.genericCategory);
  });

  it('should return showAllergensWarning = true when allergen is met and highlight option set to true ', () => {
    suggestedMenuItem.productPortions[0].allergens = allergens;
    const result = mapSuggestionItemToProductItem(suggestedMenuItem, true, [AllergenType.Almonds]);
    expect(result?.showAllergensWarning).toEqual(true);
  });

  it('should return showAllergensWarning false when allergen is met and highlight option set to false ', () => {
    suggestedMenuItem.productPortions[0].allergens = allergens;
    const result = mapSuggestionItemToProductItem(suggestedMenuItem, false, [AllergenType.Almonds]);
    expect(result?.showAllergensWarning).toEqual(false);
  });
});

describe('mapToListModel', () => {
  it('map first item from selected moment and skip the other one', () => {
    const result = mapToListModel({
      menu: menu,
      highlightAllergens: false,
      selectedAllergens: selectedAllergens || [],
      selectedMoment: 'Any',
    });
    expect(result[0].description).toEqual(menuItemOne.description);
    expect(result[0].showAllergensWarning).toEqual(false);
    expect(result.length).toEqual(1);
  });

  it('should map only orderable items when no selected moment provided and should not map item without default portion', () => {
    const result = mapToListModel({
      menu: menu,
      highlightAllergens: false,
      selectedAllergens: selectedAllergens,
      selectedMoment: undefined,
    });
    expect(result.length).toEqual(2);
  });

  it('should map item with show allergen warning', () => {
    const result = mapToListModel({
      menu: menu,
      highlightAllergens: true,
      selectedAllergens: [AllergenType.Alcohol],
      selectedMoment: 'Any',
    });
    expect(result.length).toEqual(1);
    expect(result[0].showAllergensWarning).toEqual(true);
  });
});

describe('getCartInfo', () => {
  it('should return undefined when no data provided', () => {
    const result = getCartInfo();
    expect(result).toEqual(undefined);
  });

  it('should return undefined when only cart provided', () => {
    const result = getCartInfo(cart);
    expect(result).toEqual(undefined);
  });

  it('should return cartInfo when only cart & orderDraft are provided', () => {
    const result = getCartInfo(cart, orderDraft);
    expect(result?.cartItems).toEqual(1);
    expect(result?.price).toEqual(5);
    expect(result?.priceWithoutPromotionDiscounts).toEqual(6);
    expect(result?.siteId).toEqual('1234');
  });
});

describe('getFilteredSuggestions', () => {
  it('should return filtered suggestions when allergens did not met', () => {
    const result = getFilteredSuggestions([suggestedMenuItem], selectedAllergens, true);
    expect(result).toEqual([suggestedMenuItem]);
  });
  it('should return empty array when allergens are met and hideAllergens is set to true', () => {
    const result = getFilteredSuggestions([suggestedMenuItem], [AllergenType.Alcohol], true);
    expect(result).toEqual([]);
  });
  it('should return filtered suggestion when allergens are met and hideAllergens is set to false', () => {
    const result = getFilteredSuggestions([suggestedMenuItem], [AllergenType.Alcohol], false);
    expect(result).toEqual([suggestedMenuItem]);
  });
});

describe('buildConfig', () => {
  it('should return filtering data (numOfSections = 0)', () => {
    const result = buildConfig(0);
    expect(result).toEqual({ displayedItemsNum: 0 });
  });

  it('should return filtering data (numOfSections = 4)', () => {
    const result = buildConfig(7);
    expect(result).toEqual({
      collapsibleSections: false,
      disableInfiniteScroll: true,
      displayedItemsNum: 0,
      singleExpandedSection: true,
    });
  });
});

describe('buildFilteringState', () => {
  it('should return filtering data', () => {
    const result = buildFilteringState({
      moments: ['Any', 'Other'],
      defaultFilters: null,
      hideAllergens: false,
      selectedAllergens: selectedAllergens,
    });
    expect(result.filter_allergens_highlight).toEqual({ highlight: true });
    expect(result.filter_category).toEqual(undefined);
    expect(result.filter_date).toEqual(undefined);
    expect(result.filter_menu).toEqual(undefined);
    expect(result.filter_moment).toEqual({ Any: true });
  });

  it('should return filtering data with hideAllergens=true', () => {
    const result = buildFilteringState({
      moments: ['Any', 'Other'],
      defaultFilters: null,
      hideAllergens: true,
      selectedAllergens: selectedAllergens,
    });
    expect(result.filter_allergens_highlight).toEqual({ hide: true });
    expect(result.filter_category).toEqual(undefined);
    expect(result.filter_date).toEqual(undefined);
    expect(result.filter_menu).toEqual(undefined);
    expect(result.filter_moment).toEqual({ Any: true });
  });

  it('should return filters when defaultFilters provided', () => {
    const result = buildFilteringState({
      moments: ['Any', 'Other'],
      defaultFilters: defaultFilters,
      hideAllergens: true,
      selectedAllergens: selectedAllergens,
    });
    expect(result.filter_allergens_highlight).toEqual({ hide: true });
    expect(result.filter_category).toEqual({ Some: true });
    expect(result.filter_date).toEqual(undefined);
    expect(result.filter_menu).toEqual(undefined);
    expect(result.filter_moment).toEqual({ Any: true });
  });
});

describe('getProductIllustration for all categories', () => {
  const categories = [
    'Burger',
    'Pizza',
    'Kebab',
    'Pasta',
    'Noodle',
    'Soup',
    'Drink',
    'Vegetable',
    'Salad',
    'Snacks',
    'Chicken',
    'Breakfast',
    'Fries',
    'Wrap',
    'Bowl',
    'Chocolate',
    'Quesadilla',
    'Caramel',
    'Turkey',
    'Burrito',
    'Tea',
    'Dish',
    'Coke',
    'Other',
  ];

  categories.forEach((category) => {
    it(`should return the correct illustration for ${category}`, () => {
      const { getByText } = render(getProductIllustration(category));
      expect(getByText(`${category}.svg`)).toBeInTheDocument();
    });
  });
});
