import { useOrderTranslation } from '../hooks/useOrderTranslation';

export enum PromotionNotificationType {
  Added,
  Removed,
}

const PromotionNotification = ({ type }: { type: PromotionNotificationType }) => {
  const { label } = useOrderTranslation(__filename);

  let title: string;
  let description: string;
  switch (type) {
    case PromotionNotificationType.Added:
      title = label('Ref: Promotion applied title');
      description = label('Ref: Promotion applied description');
      break;
    case PromotionNotificationType.Removed:
      title = label('Ref: Promotion deleted title');
      description = label('Ref: Promotion deleted description');
      break;
  }

  return (
    <>
      {title}
      <br />
      {description}
    </>
  );
};

export default PromotionNotification;
