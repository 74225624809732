import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { defaultState as facilityDefaultState } from '@/modules/Facilities/reducers/facilitiesReducer';
import { FacilityDataItem, FacilityState } from '@/modules/Facilities/types/types';

const useSortedFacilityList = (siteId: string | undefined): FacilityDataItem[] => {
  const {
    data,
    list: facilitiesList,
    viewCounts,
  } = useSelector((state: { Facilities: FacilityState }) => {
    return state.Facilities ?? facilityDefaultState;
  });

  const facilities = useMemo(() => {
    const siteFacilities = (facilitiesList && facilitiesList[siteId || '']) ?? [];

    const stateFacilityList = siteFacilities.map((facilityId) => ({
      ...data[facilityId],
      viewCounts: viewCounts?.[facilityId] || 0,
    }));

    const sortedFacilitiesList = [...stateFacilityList].sort((a, b) => b.viewCounts - a.viewCounts);

    return sortedFacilitiesList;
  }, [data, facilitiesList, siteId, viewCounts]);

  return facilities;
};

export default useSortedFacilityList;
