export const getStampsContent = ({
  isRewardAvailable,
  isRedeem,
}: {
  isRewardAvailable?: boolean;
  isRedeem?: boolean;
}) => {
  if (isRedeem) {
    return {
      title: 'Ref: Redeem',
      description: 'Ref: Redeem coffee description',
    };
  }

  if (isRewardAvailable) {
    return {
      title: 'Ref: Congratulations',
      titleSuffix: '🎉',
      description: 'Ref: Free coffee description',
    };
  }
  return {
    title: 'Ref: Get free coffee',
    description: 'Ref: Free coffee description',
  };
};

export const getStampsButtonLabel = (isRewardAvailable?: boolean, isRedeem?: boolean): string => {
  if (isRedeem) {
    return 'Ref: Reward collected';
  }

  if (isRewardAvailable) {
    return 'Ref: Collect reward';
  }

  return 'Ref: Scan QR Code';
};
