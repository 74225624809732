import momentjs from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DATE_FORMAT } from '../../../../constants';
import { Result } from '../../../../types/common.types';
import { getAll as fetchFacilities } from '../../../Facilities/redux/actions';
import { useGetMenusQuery, useSaveLastTransactionalFeedbackDateMutation } from '../../api';
import { getImageData } from '../../helpers/menuImage.helper';
import { useTransactionalFeedback } from '../../hooks/useTransactionalFeedback/useTransactionalFeedback';
import { MenuType } from '../../types/menuSelector.types';
import { FacilitiesResponse } from '../../types/orderHistoryWidget.types';

import TransactionalFeedbackModal from './TransactionalFeedbackModal';
import { TransactionalFeedbackModalWrapperProps } from './TransactionalFeedbackModal.types';

import useToggle from '@/helpers/hooks/useToggle';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { getCurrentLanguageCode } from '@/helpers/misc';
import { FacilityDataItem } from '@/modules/Facilities/types/types';
import { FacilityImage } from '@/modules/Review/types/reviewFacility.types';
import { UserSteps } from '@/types/userSteps.types';

const TransactionalFeedbackModalWrapper = ({ site }: TransactionalFeedbackModalWrapperProps) => {
  const [saveLastTransactionalFeedbackDate] = useSaveLastTransactionalFeedbackDateMutation();

  const { logUserSteps } = useUserStepsInsightsLogging();

  const {
    state: isTransactionalFeedbackModalOpen,
    toggleOn: openTransactionalFeedbackModal,
    toggleOff: closeTransactionalFeedbackModal,
  } = useToggle(false);

  const handleClosingModal = () => {
    closeTransactionalFeedbackModal();
    logUserSteps({ event: UserSteps.TransactionalFeedbackPopUpClosed });
  };

  const { lastOrder, shouldOpenTransactionalFeedbackModal } = useTransactionalFeedback();
  const dispatch = useDispatch();

  const enableTransactionalFeedbackFeature =
    process?.env?.REACT_APP_TRANSACTIONAL_FEEDBACK_ENABLED?.toLowerCase() === 'true';

  const [facility, setFacility] = useState<FacilityDataItem | undefined>();
  const [image, setImage] = useState<FacilityImage | undefined>();

  const menuDate = momentjs().format(DATE_FORMAT);

  const { data: menus = [] } = useGetMenusQuery({
    date: menuDate,
    menuType: MenuType.Orderable,
    siteId: site.id,
    useCache: false,
    useErrorBoundary: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!lastOrder) return;

      const responses = await Promise.all([
        dispatch(fetchFacilities({ siteId: site.id }, { useErrorBoundary: false })),
      ]);

      const facilitiesResponse: Result<FacilitiesResponse> = responses[0];

      const menu = menus.find((menu) => menu.id === lastOrder.menuId);

      if (!menu || !facilitiesResponse.ok) return;

      const menuFacility = facilitiesResponse.responseData.facilities.find(
        (facility) => facility.id === menu.facilityId
      );

      if (!menuFacility) return;

      const imageData = await getImageData(
        menu.name,
        menu.logoUrl,
        menuFacility.id,
        menuFacility.images[0]
      );

      setFacility(menuFacility);
      setImage(imageData);
    };

    fetchData();
  }, [lastOrder, dispatch, menuDate, site.id, menus]);

  useEffect(() => {
    if (shouldOpenTransactionalFeedbackModal && !isTransactionalFeedbackModalOpen) {
      openTransactionalFeedbackModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTransactionalFeedbackModal, shouldOpenTransactionalFeedbackModal]); // isTransactionalFeedbackModalOpen dependency will cause infinite loop

  const showModal =
    lastOrder && facility && enableTransactionalFeedbackFeature && isTransactionalFeedbackModalOpen;

  useEffect(() => {
    if (showModal) {
      saveLastTransactionalFeedbackDate({
        siteId: site.id,
        orderPickupDate: lastOrder.pickupDate,
      });
      logUserSteps({ event: UserSteps.TransactionalFeedbackPopUpOpened });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, saveLastTransactionalFeedbackDate, site.id]); //logUserSteps dependency will cause duplicate calls

  return showModal ? (
    <TransactionalFeedbackModal
      order={lastOrder}
      isoCode={site.currency?.isoCode ?? ''}
      languageCode={getCurrentLanguageCode()}
      isModalOpen={isTransactionalFeedbackModalOpen}
      hideModal={handleClosingModal}
      facility={facility}
      imageData={image}
    />
  ) : null;
};

export default TransactionalFeedbackModalWrapper;
