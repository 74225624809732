import { act, renderHook } from '@testing-library/react';

import { useFacilityMenuImage } from './useFacilityMenuImage';

import { getImagesSrcFromIds } from '@/helpers/images/images.helper';

jest.mock('@/modules/Review/helpers/facilityPicker.helper', () => ({
  ...jest.requireActual('@/modules/Review/helpers/facilityPicker.helper'),
  buildPromiseForImage: jest.fn(),
}));

jest.mock('@/helpers/images/images.helper', () => ({
  ...jest.requireActual('@/helpers/images/images.helper'),
  getImagesSrcFromIds: jest.fn(),
}));

const facilityIdWithImage = 'facility1';
const facilityIdWithoutImage = 'facility2';

const imageId1 = 'imageId1';
const imageId2 = 'imageId2';
const imageData = 'imageData';

const facilitiesImageInfo = [
  { facilityId: facilityIdWithImage, imageId: imageId1 },
  { facilityId: facilityIdWithImage, imageId: imageId2 },
  { facilityId: facilityIdWithoutImage },
];

describe('useFacilityMenuImage hook', () => {
  describe('when loading images', () => {
    beforeEach(() => {
      const getImagesSrcFromIdsMock = getImagesSrcFromIds as jest.Mock;
      getImagesSrcFromIdsMock.mockReturnValue(new Promise((x) => {}));
    });

    it('should return loadingImages equals true', async () => {
      const { result } = await act(() =>
        renderHook(() => useFacilityMenuImage(facilitiesImageInfo))
      );
      expect(result.current.isLoading).toBe(true);
    });
  });

  describe('when images loaded', () => {
    beforeEach(() => {
      const getImagesSrcFromIdsMock = getImagesSrcFromIds as jest.Mock;
      getImagesSrcFromIdsMock.mockResolvedValue([
        {
          id: imageId1,
          content: imageData,
        },
      ]);
    });

    it('should return loadingImages equals false', async () => {
      const { result } = await act(() =>
        renderHook(() => useFacilityMenuImage(facilitiesImageInfo))
      );
      expect(result.current.isLoading).toBe(false);
    });

    it('should load each images', async () => {
      await act(() => renderHook(() => useFacilityMenuImage(facilitiesImageInfo)));

      const getImagesSrcFromIdsMock = getImagesSrcFromIds as jest.Mock;
      expect(getImagesSrcFromIdsMock).toHaveBeenCalledTimes(1);

      const imageIds = getImagesSrcFromIdsMock.mock.calls[0][0];
      expect(imageIds).toEqual([imageId1, imageId2]);
    });

    it('getMenuImage - should return OMS image when OMS image url exists and valid url', async () => {
      const { result } = await act(() =>
        renderHook(() => useFacilityMenuImage(facilitiesImageInfo))
      );

      const omsImageUrl =
        'https://rgukretailrangerpre9277.blob.core.windows.net/loyalty/20230630070551-2023-06-30_09h05_36.png';
      const imageSource = result.current.getMenuImage(facilityIdWithImage, omsImageUrl);

      expect(imageSource).toBe(omsImageUrl);
    });

    it('getMenuImage - should return facility image when OMS image url exists but is NOT valid url', async () => {
      const renderResult = await act(() =>
        renderHook(() => useFacilityMenuImage(facilitiesImageInfo))
      );

      const omsImageUrl = 'notValidUrl';
      const imageSource = renderResult.result.current.getMenuImage(
        facilityIdWithImage,
        omsImageUrl
      );

      expect(imageSource).toBe(imageData);
    });

    it('getFacilityImage - should return facility image when OMS image url is not set', async () => {
      const { result } = await act(() =>
        renderHook(() => useFacilityMenuImage(facilitiesImageInfo))
      );

      const imageSource = result.current.getFacilityImage(facilityIdWithImage);

      expect(imageSource).toBe(imageData);
    });

    it('getFacilityImage - should return undefined when no facility and OMS image', async () => {
      const { result } = await act(() =>
        renderHook(() => useFacilityMenuImage(facilitiesImageInfo))
      );

      const imageSource = result.current.getFacilityImage(facilityIdWithoutImage);

      expect(imageSource).toBeUndefined();
    });
  });
});
