import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { Merchant } from '../../../assets/illustrations';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../components/atoms/Title';
import Notification, { NOTIFICATION_LOOK } from '../../../components/molecules/Notification';
import TilesList from '../../../components/organisms/TilesList/TilesList';
import { BAR_POSITION } from '../../../components/templates/Layout/BottomBar';
import Layout, { MAIN_WIDTH } from '../../../components/templates/Layout/Layout';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import useToggle from '../../../helpers/hooks/useToggle';
import { getPendingOrders, setSiteModalClosed, checkInAtTable } from '../actions';
import { pagePaths } from '../config';
import { siteChangeNotification } from '../helpers/menuSelector.helper';
import { mapPendingOrders } from '../helpers/tableCheckIn.helper';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import useResetTableAfterOneHour from '../hooks/useResetTableAfterOneHour';

import OldTile from '@/components/organisms/Tile';
import { getOrderModuleRedirectionPath } from '@/modules/Core/components/ModuleRedirectPage/ModuleRedirectPage.helper';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { defaultState as facilityDefaultState } from '@/modules/Facilities/reducers/facilitiesReducer';
import { ISite } from '@/modules/Sites/types/sites.types';
import { State } from '@/types/state.types';

const TableCheckIn = (): JSX.Element => {
  const [checkedInOrders, setCheckedInOrders] = useState<number[]>([]);
  const [checkingIn, setCheckingIn] = useState('');
  const { state: isLoadingPendingOrders, toggleOff: stopPendingOrderLoader } = useToggle(true);

  const history = useHistory();
  const location = useLocation();
  const site = useSite() as ISite;
  const order = useSelector((state: State) => state.Order);
  const facilities = useSelector((state: State) => state.Facilities || facilityDefaultState);
  const language = useLanguage();
  const dispatch = useDispatch();
  const { label } = useOrderTranslation(__filename);

  const facilityName = order.preselectedFacilityId
    ? facilities.data[order.preselectedFacilityId].title
    : undefined;

  const setSiteModalClosedFunction = () => dispatch(setSiteModalClosed());
  const checkInAtTableFunction = useCallback(
    (siteId: string, orderId: number, dineInDeliveryOptionId: number, table?: string | null) =>
      dispatch(
        checkInAtTable({
          SiteId: siteId,
          OrderId: orderId,
          DineInDeliveryOptionId: dineInDeliveryOptionId,
          Table: table,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    if (!facilityName && !order.preselectedFacilityId && !site) history.push(pagePaths.Module);

    async function loadPendingOrders() {
      await dispatch(
        getPendingOrders({ siteId: site.id, facilityName: order.preselectedFacilityId })
      );
      stopPendingOrderLoader();
    }
    loadPendingOrders();
  }, [
    dispatch,
    facilityName,
    history,
    order.preselectedFacilityId,
    site,
    site?.id,
    stopPendingOrderLoader,
  ]);

  useResetTableAfterOneHour({ tableQRScanTime: order.tableQRScanTime });

  const checkInAtTableProcess = useCallback(
    async (
      siteId: string,
      orderId: number,
      dineInDeliveryOptionId: number,
      table?: string | null
    ) => {
      setCheckingIn(orderId.toString());
      const result = await checkInAtTableFunction(siteId, orderId, dineInDeliveryOptionId, table);

      if (result.ok) {
        setCheckedInOrders((prevState) => [...prevState, orderId]);
        setCheckingIn('');
      }
    },
    [checkInAtTableFunction]
  );

  const topContentSiteChangeNotification = siteChangeNotification({
    hasSiteChanged: order.hasSiteChanged,
    setSiteModalClosed: setSiteModalClosedFunction,
    siteName: site.name,
    labelFn: label,
  });

  const orders = mapPendingOrders({
    label,
    pendingOrders: order.pendingOrders,
    history,
    checkInAtTableProcess,
    checkingIn,
    tableNumber: order.tableNumber,
    siteId: site.id,
    checkedInOrders,
    isLoadingPendingOrders,
    currentLanguageCode: language.currentLanguageCode,
  });

  const ordersWithTestingId = useMemo(() => {
    return orders?.map((order, index) => ({
      ...order,
      'data-testid': `order-check-in-item-${index}`,
    }));
  }, [orders]);

  const content = (
    <>
      <Notification
        look={NOTIFICATION_LOOK.MESSAGE}
        title={`${label('Welcome at')} ${facilityName}`}
        children={`${label('Table')} ${order.tableNumber}`}
        img={<Merchant />}
        inheritStyle="mb-L"
      />
      <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES} className="mb-M">
        {label('Ref: list title')}
      </Title>
      <TilesList
        items={ordersWithTestingId}
        itemRenderer={(item) => (
          <OldTile
            {...item}
            secondTitleLine={item.secondTitleLine ? item.secondTitleLine : undefined}
          />
        )}
      />
    </>
  );

  const action = [
    {
      label: label('See the menu'),
      action: () =>
        history.push(
          `${getOrderModuleRedirectionPath(new URLSearchParams(location.search))}?dineInOutletId=${
            location.pathname.split('outlet/').pop()?.split('?')[0]
          }`
        ),
      look: 'secondary',
    },
  ];

  if (!order.pendingOrders) return <LoadingPage />;

  return (
    <SimpleFormPage
      title={label('Table Check-in')}
      hasBackLink={true}
      actions={[]}
      actionsBarTopContent={null}
    >
      <Layout
        mainWidth={MAIN_WIDTH.WIDE}
        topContent={topContentSiteChangeNotification}
        bottomBar={{
          type: 'actionsBar',
          actions: action,
          position: BAR_POSITION.DYNAMIC,
        }}
      >
        {content}
      </Layout>
    </SimpleFormPage>
  );
};

export default TableCheckIn;
