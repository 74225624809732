import classNames from 'classnames';
import { FC } from 'react';

import { RoundProgressBarProps } from './RoundProgressBar.types';

import styles from './RoundProgressBar.module.css';

const RoundProgressBar: FC<RoundProgressBarProps> = ({
  progress = 0,
  totalSteps = 1,
  'data-testid': testId,
}) => {
  const progressValue = progress > 0 ? progress : 0;
  const maxValue = totalSteps >= progress ? totalSteps : progress;

  const emptyPercentage = (((maxValue - progressValue) / maxValue) * 100).toFixed(2);

  return (
    <div
      className={classNames(styles.pie)}
      data-testid={testId}
      style={{ '--empty-percentage': emptyPercentage } as React.CSSProperties}
    >
      {progressValue}/{maxValue}
    </div>
  );
};

export default RoundProgressBar;
