const labels = {
  ReviewForm: {
    'Ref: Page title': 'Xét lại',
    'Ref: How was dining with us': 'Làm thế nào là ăn uống với chúng tôi?',
    'Ref: Submit': 'Nộp',
    'Ref: Filter range': 'Phạm vi bộ lọc',
    'Ref: Some information is missing': 'Một số thông tin bị thiếu',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Gửi ẩn danh',
    'Ref: Your email address': 'địa chỉ email của bạn',
    'Ref: Email field placeholder': 'nhập địa chỉ email của bạn',
    'Ref: Invalid email format': 'Định dạng email không hợp lệ',
  },
  SuccessPage: {
    'Ref: Body':
      'Cảm ơn bạn đã hoàn thành đánh giá này, thời gian của bạn là có giá trị vì đánh giá này sẽ giúp cải thiện dịch vụ của chúng tôi.',
    'Ref: Go to Feedback': 'Đi đến phản hồi',
    'Ref: Go to home': 'Đi về nhà',
    'Ref: Go to Facilities': 'Đi đến các cơ sở',
    'Ref: Go to Product Details': 'Đi đến chi tiết sản phẩm',
    'Ref: Go to Facility Details': 'Đi đến chi tiết cơ sở',
  },
  FacilityPicker: {
    'Ref: Page title': 'Xét lại',
    'Ref: Search facility name': 'Tên cơ sở tìm kiếm',
  },
};
export default labels;
