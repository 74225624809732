import { connect } from 'react-redux';

import ProductTile from '../components/ProductTile/ProductTile';
import { StateWithOrder } from '../types/orderState.types';

const mapStateToProps = (state: StateWithOrder) => {
  return {
    languageCode: state.Shared?.language?.currentLanguageCode,
    cart: state.Order.cart,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTile);
