const labels = {
  ReviewForm: {
    'Ref: Page title': '点评',
    'Ref: How was dining with us': '和我们一起用餐吗？',
    'Ref: Submit': '提交',
    'Ref: Filter range': '过滤器范围',
    'Ref: Some information is missing': '缺少一些信息',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': '匿名发送',
    'Ref: Your email address': '您的电子邮件地址',
    'Ref: Email field placeholder': '输入你的电子邮箱地址',
    'Ref: Invalid email format': '无效的电子邮件格式',
  },
  SuccessPage: {
    'Ref: Body': '感谢您的宝贵意见，我们将持续改善我们的服务。',
    'Ref: Go to Feedback': '转到反馈',
    'Ref: Go to home': '转到主页',
    'Ref: Go to Facilities': '转到设施',
    'Ref: Go to Product Details': '转到产品详情',
    'Ref: Go to Facility Details': '转到设施详情',
  },
  FacilityPicker: { 'Ref: Page title': '点评', 'Ref: Search facility name': '搜索设施名称' },
};
export default labels;
