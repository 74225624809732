import { SETUP_OPTIONS } from '../../../../constants';
import { store } from '../../../../store';
import { State } from '../../../../types/state.types';
import { SERVICE } from '../../../config';

export const useTransactionalFeedbackSetupOptions = () => {
  const state = store.getState() as State;
  const service = state.Core.services.list.find(
    (el: { name: string }) => el.name === SERVICE.RETAIL
  );

  const findOptionByName = (name: string) =>
    service?.setupOptions?.find((el: { name: string }) => el.name === name)?.value;

  const transactionalFeedbackFrom = findOptionByName(
    SETUP_OPTIONS.RETAIL.transactionalFeedbackFrom
  );

  const transactionalFeedbackFromEnabled =
    typeof transactionalFeedbackFrom === 'string' &&
    transactionalFeedbackFrom.trim() !== '' &&
    /^\d{4}-\d{2}-\d{2}$/.test(transactionalFeedbackFrom)
      ? transactionalFeedbackFrom
      : undefined;

  const transactionalFeedbackTagsEnabled =
    findOptionByName(SETUP_OPTIONS.RETAIL.transactionalFeedbackTags) === 'true' || false;

  return { transactionalFeedbackFromEnabled, transactionalFeedbackTagsEnabled };
};
