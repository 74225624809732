import { cleanup } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultFacilityMenu } from '../../__mocks/mock';

import MenuTile from './MenuTile';

const state = {
  Core: { services: { list: [] } },
  Shared: { language: { currentLanguageCode: 'en' } },
};

let tilePropsMock: any;
jest.mock('../../../../components/molecules/Tile', () => {
  return {
    Tile: (props: any) => {
      tilePropsMock = props;
      return <>{props.image}</>;
    },
  };
});

const mockSelector = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
}));

let imageWithFallbackPropsMock: any;
jest.mock(
  '../../../../components/atoms/ImageWithFallback/ImageWithFallback',
  () => (props: any) => {
    imageWithFallbackPropsMock = props;
    return <></>;
  }
);

describe('MenuTile component', () => {
  beforeEach(() => {
    mockSelector.mockReturnValue(state);
  });
  afterAll(() => cleanup());

  describe('with scan and go menu', () => {
    renderComponent(MenuTile, {
      id: '123',
      facilityTitle: 'Facility title',
      menu: {
        ...defaultFacilityMenu,
        isScanAndGo: true,
      },
    });

    it('should render title and description', () => {
      expect(tilePropsMock.title).toBe('Facility title');
      expect(tilePropsMock.title).toBe('Facility title');
    });

    it('should render Scan&Go tag', () => {
      expect(tilePropsMock.tags).toHaveLength(1);
    });
  });

  describe('with no scan and go menu', () => {
    renderComponent(MenuTile, {
      id: '123',
      facilityTitle: 'Facility title',
      menu: {
        ...defaultFacilityMenu,
        isScanAndGo: false,
      },
    });

    it('should not render Scan&Go tag', () => {
      expect(tilePropsMock.tags).toHaveLength(0);
    });
  });

  describe('with menu image', () => {
    renderComponent(MenuTile, {
      id: '123',
      facilityTitle: 'Facility title',
      menu: {
        ...defaultFacilityMenu,
        isScanAndGo: false,
      },
      menuImageSource: 'imageContent',
    });

    it('should render it', () => {
      expect(imageWithFallbackPropsMock.imgInfo).toBeDefined();
      expect(imageWithFallbackPropsMock.imgInfo.src).toBe('imageContent');
      expect(imageWithFallbackPropsMock.imgInfo.alt).toBe('Facility title');
    });
  });

  describe('with non-orderable menu', () => {
    renderComponent(MenuTile, {
      id: '123',
      facilityTitle: 'Facility title',
      menu: {
        ...defaultFacilityMenu,
        isOrderable: false,
      },
    });

    it('should render title and description', () => {
      expect(tilePropsMock.title).toBe('Facility title');
      expect(tilePropsMock.title).toBe('Facility title');
    });

    it('should render View only tag', () => {
      expect(tilePropsMock.tags[0].name).toBe('View only');
    });
  });

  describe('with no non-orderable menu', () => {
    renderComponent(MenuTile, {
      id: '123',
      facilityTitle: 'Facility title',
      menu: {
        ...defaultFacilityMenu,
        isScanAndGo: false,
        isOrderable: true,
      },
    });

    it('should not render View only tag', () => {
      expect(tilePropsMock.tags).toHaveLength(0);
    });
  });

  describe('without facility image', () => {
    renderComponent(MenuTile, {
      id: '123',
      facilityTitle: 'Facility title',
      menu: {
        ...defaultFacilityMenu,
        isScanAndGo: false,
      },
    });

    it('should render default image', () => {
      expect(imageWithFallbackPropsMock.imgInfo).toBeUndefined();
      expect(imageWithFallbackPropsMock.imgElement).toBeDefined();
    });
  });
});
