const labels = {
  All: {
    'Ref: Assembled': 'Birleştirilmiş',
    'Ref: Submitted': 'Başvurulmuş',
    'Ref: Fulfilled': 'Yerine getirilmiştir',
    'Ref: Completed': 'Tamamlanmış',
    'Ref: Paid': 'Ücretli',
    'Ref: Approved': 'Onaylandı',
    'Ref: Ready For Pickup': 'Teslim Almaya Hazır',
    'Ref: Delivered': 'Teslim edildi',
    'Ref: Received': 'Alındı',
    'Ref: Rejected': 'Reddedildi',
    'Ref: Payment Error': 'Ödeme Hatası',
    'Ref: Pending Payment': 'Ödeme Bekleniyor',
    'Ref: Preparing': 'Hazırlık',
    'Ref: Refunded': 'İade Edildi',
    'Ref: Cancelled By User': 'Kullanıcı Tarafından İptal Edildi',
    'Ref: Refund Requested': 'İade Talep Edildi',
    'Ref: Refund Failed': 'Para İadesi Başarısız',
    'Ref: Payment Processing': 'Ödeme İşlemleri',
    'Ref: Not Paid': 'Ödenmedi',
    'Ref: Total:': 'Toplam:',
    'Ref: Pickup': 'Pikap',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Tarama ve Git',
    'Ref: Fulfillment Type': 'Yerine getirme türü',
    'Ref: Delivery': 'Teslimat',
    'Ref: You have no future orders': 'Gelecek siparişleriniz yok',
    'Ref: You have no past orders': 'Geçmiş emirlerin yok',
    'Ref: You have no orders': 'Emirleriniz yok.',
    'Ref: All orders': 'Tüm siparişler',
    'Ref: Not past orders': 'Siparişler geliyor',
    'Ref: Past orders': 'Geçmiş siparişler',
    'Ref: Vegan': 'Vegan',
    'Ref: Vegeterian': 'Vejetaryen',
    'Ref: See item': 'Öğeye bakın',
    'Ref: Activated': 'Etkinleştirilmiş',
    'Ref: No suggestions': 'Hiçbir öneri bulunamadı',
    'Ref: Get your receipt': 'Makbuzunuzu Alın',
    'Ref: Print': 'Makbuzunuzu yazdırın',
    'Ref: Email': 'Makbuzunuzu e-posta ile alın',
    'Ref: Receipt Modal message': 'Makbuzunuzu nasıl almak istersiniz?',
    'Ref: Confirm': 'Onaylamak',
    'Ref: Your email address': 'E -posta adresiniz',
    Suggestion: 'Öneri',
    'Ref: Loyalty Reward': 'Sadakat ödülü',
    'Ref: Equation for stamps per item': '1 satın alma = 1 damga',
    'Ref: Paid by employer': 'İşvereniniz tarafından ödenen miktar',
    'Ref: Paid by user': 'Sizin tarafınızdan ödenen miktar',
  },
  ProductsList: {
    'Ref: Page title': 'Ürün listesi',
    'Ref: Facility details': 'Tesis detayları',
    'Ref: Search products list': 'Arama öğeleri',
    'Ref: filter label: menus': 'Menü',
    'Ref: filter label: moment': 'An',
    'Ref: filter label: category': 'Kategori',
    'Ref: all': 'Tümü',
    'Ref: today': 'Bugün',
    'Ref: tomorrow': 'Yarın',
    'Ref: see more': 'Daha fazlasını görün',
    'Ref: see less': 'Daha az görün',
    'Ref: reset': 'Sıfırla',
    'Ref: View in cart': 'Sepette görüntüle',
    'Ref: You have changed location': 'Konumunuzu değiştirdiniz',
    'Ref: You are now at': 'Şu anda',
    'Ref: Menu card': 'Menü kartı',
    'Ref: Table': 'Tablo numarası',
    'Service unavailable': 'Servis mevcut değil',
    'Ref: Ordering unavailable':
      'Dikkatli olun! Bu siteden şu anda sipariş verilememektedir, lütfen daha sonra deneyin.',
    'Ref: Filters': 'Filtreler (Alerjenler ve daha fazlası)',
  },
  ProductScanner: {
    'Ref: cart': 'Araba',
    'Ref: Product Scanned':
      'arabaya eklendi. Barkodları sepetinize eklemek için taramaya devam edebilirsiniz.',
    'Ref: Scan or adjust': 'sepete eklendi. Miktarı ayarlayın veya başka bir ürün tarayın.',
    'Ref: Product Not Matched': 'Barkod tanınmadı. Lütfen tekrar deneyin',
    'Ref: Scan article code': 'Barkod tarama',
    'Ref: Native error product cannot be added to cart':
      'Farklı menüden veya gündüz parçasından öğe arabaya eklenemez',
    'Ref: product scanner placeholder': 'Sepetinize eklemek için lütfen barkodayı tarayın',
  },
  Order: { 'Ref: Page title': 'Sipariş' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menüler',
    'Ref: today': 'Bugün',
    'Ref: tomorrow': 'Yarın',
    'Ref: Search products list': 'Arama öğeleri',
    'Ref: You have changed location': 'Konumunuzu değiştirdiniz',
    'Ref: You are now at': 'Şu anda',
  },
  ProductDetails: {
    'Ref: Page title': 'Ürün Detayları',
    'Ref: Serving Size': 'Porsiyon Büyüklüğü',
    'Ref: Nutrition': 'Beslenme bilgileri',
    'Ref: Nutrition adults': "Yetişkinlerin günde yaklaşık 2000 kcal'ye ihtiyacı vardır",
    'Ref: Nutrition Calories': 'Kalori',
    'Ref: Nutrition Calcium': 'Kalsiyum',
    'Ref: Nutrition Fat': 'Şişman',
    'Ref: Nutrition Saturated Fat': 'Doymuş yağ',
    'Ref: Nutrition Carbohydrates': 'Karbonhidratlar',
    'Ref: Nutrition Sugar': 'Şeker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodyum',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Diyet Lifleri',
    'Ref: Nutrition iron': 'Demir',
    'Ref: Nutrition potassium': 'Potasyum',
    'Ref: Nutrition vitaminA': 'A Vitamini',
    'Ref: Nutrition vitaminC': 'C Vitamini',
    'Ref: Additives': 'Katkı maddeleri',
    'Ref: Emission': 'CO2 emisyonları',
    'Ref: This product generates': 'Bu ürün şunları üretir',
    'Ref: of CO2': "CO2'nin",
    'Ref: emissions, which equals a': 'emisyonlarına eşittir.',
    'Ref: car trip': 'araba yolculuğu',
    'Ref: Ingredients': 'Tarif malzemesi',
    'Ref: Allergens': 'Alerjenler',
    'Ref: Always there': 'Her zaman orada',
    'Ref: Allergens selected': ' filtre üzerinde seçilen alerjenler: ',
    'Ref: minimum required': 'minimum gerekli',
    'Ref: you have to add at least': 'En azından şunları eklemelisiniz',
    'Ref: ingredients': 'malzemeler',
    'Ref: Ingredient': 'içerik',
    'Ref: Free': 'Ücretsiz',
    'Ref: Based on customization': 'Özelleştirmenize göre',
    'Ref: Additives eggs': 'yumurta',
    'Ref: Additives fish': 'Balık',
    'Ref: Additives milk': 'süt',
    'Ref: Additives nuts': 'fındık',
    'Ref: Additives wheat': 'Buğday',
    'Ref: Additives peanuts': 'yer fıstığı',
    'Ref: Additives soy beans': 'soya fasulyesi',
    'Ref: Additives sesame seeds': 'susam tohumları',
    'Ref: Review': 'İnceleme',
    'Ref: Per portion': 'Porsiyon başına',
    'Ref: Per 100g': '100g Başına',
    'Ref: Allergen selected': 'Arama filtresinde seçilen alerjen',
    'Ref: Fitbit Log': "Fitbit'e giriş yapın",
    'Ref: age verification required on delivery': 'Teslimatta gerekli yaş doğrulaması',
    'Ref: alcohol content notification':
      'Bu eşyalardan birini yerleştirip sipariş ederek, 18 yaş ve üstü olduğunuzu beyan edersiniz. Alkol kötüye kullanımı sağlığınız için tehlikelidir.',
    'Ref: default': 'Varsayılan',
    'Ref: Loyalty Card Title Stamp':
      'Bu ürünü sipariş ederseniz {no_stamps} {stamp_form} kazanıldı',
    'Ref: Loyalty Card Description':
      '{stamp_form} aşağıdaki sadakat eklenecektir {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'Sadakat Pulları programı, ödüller için pul toplamanızı sağlar.',
    'Ref: Loyalty Modal Description':
      '<b> {product_name} satın aldığınızda, <b> {scheme_names} </b> {program_form} için 1 damga </b> eklenecektir.',
    'Ref: and': 'Ve',
    'Ref: Stamp': 'Damga vurmak',
    'Ref: Stamps': 'Pullar',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programlar',
    'Ref: Loyalty Stamps': 'Sadakat pulları',
    'Ref: See Loyalty Stamps': 'Sadakat Pullarına bakın',
    Favorite: 'Favori',
    'Ref: In favorites': 'Ürün zaten favoriler listesinde. Kaldırmak için tıklayın.',
    'Ref: Not in favorites': 'Ürün henüz favoriler listesinde değil. Eklemek için tıklayın.',
    'Ref: Added to favorites': 'Ürün favoriler listesine eklendi.',
    'Ref: Removed from favorites': 'Ürün favoriler listesinden kaldırıldı.',
    'Ref: From': 'Kimden',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Arabada değişiklik',
    'Ref: Popup header for customizable item qty update': 'Bu ürün özelleştirilebilir',
    'Ref: add new version': 'Yeni sürüm ekle',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Özelleştirilebilir bir ürünün miktarını doğrudan sepetinizden güncelleyin.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Özelleştirilebilir bir öğenin miktarını doğrudan sepetinizden güncelleyin veya yeni bir sürüm ekleyin.',
    'Ref: another context body':
      'Bu siteden, tesisten, andan/menüden veya tarihten öğe eklemek için sepeti temizlemeniz gerekir',
    'Ref: cancel': 'İptal',
    'Ref: add to cart': 'Sepete ekle',
    'Ref: view in cart': 'Araba',
    'Ref: Products in cart': 'Sepetteki {ürün_adı} sayısı: {ürün_miktarı}',
    'Ref: Customize': 'Özelleştirme',
    'Ref: Add': 'Ekle',
    'Ref: Free': 'Özgür',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'İptal',
    'Ref: notice': 'Uyarı',
    'Ref: clear cart': 'Sepeti temizle',
    'Ref: Cart addition error message':
      "Şu anda {from_current} 'dan sepet içinde eşyalarınız var. {from_selected} 'den öğe eklemek için arabayı temizlemeniz gerekir",
  },
  Cart: {
    'Ref: Page title': 'Araba',
    'Ref: The cart is empty': 'Sepet boş',
    'Ref: Clear cart': 'Sepeti temizle',
    'Ref: Continue shopping': 'Alışverişe devam et',
    'Ref: Start shopping': 'Alışverişe başlayın',
    'Ref: Today': 'Bugün',
    'Ref: Tomorrow': 'Yarın',
    'Ref: Tax:': 'Vergi:',
    'Ref: Promotion:': 'Promosyon:',
    'Ref: Total without promotion:': 'Promosyonsuz toplam:',
    'Ref: Delivery:': 'Teslimat',
    'Ref: Pickup informations': 'Teslim alma bilgileri',
    'Ref: Location': 'Konum',
    'Ref: Time': 'Zaman',
    'Ref: Phone': 'Telefon numaranız',
    'Ref: Instructions': 'Talimatlar',
    'Ref: Please select': 'Lütfen seçiniz',
    'Ref: Enter special requests': 'Özel isteklerinizi buraya girin',
    'Ref: notice': 'Uyarı',
    'Ref: Product is no more available':
      'Üzgünüz, ancak bazı ürünler artık mevcut değil gibi görünüyor. Lütfen sepetinizi kontrol edin.',
    'Ref: ok': 'Tamam',
    'Ref: Validate': 'Siparişimi doğrulayın',
    'Ref: Pay now': 'Şimdi öde',
    'Ref: Charge to my department': 'Departmanıma ücretlendirme',
    'Ref: Pay on pickup': 'Bu eşyaları pick up için ödeyin',
    'Ref: Accept terms of sale prefix': 'Kabul ediyorum.',
    'Ref: Accept terms of sale': 'bu satış koşulları',
    'Ref: Continue': 'Devam et',
    'Ref: Please login or sign up to place an order':
      'Sipariş vermek için lütfen giriş yapın veya kaydolun',
    'Ref: Accept terms of sale suffix': 've bunları okudum ve anladım.',
    'Ref: Payment modality': 'Ödeme yöntemi',
    'Ref: Other information': 'Diğer bilgiler',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Pickup': 'Pikap',
    'Ref: Table': 'Tablo numarası',
    'Ref: Required fields': 'Gerekli alanlar',
    'Service unavailable': 'Servis mevcut değil',
    'Ref: Cannot order':
      'Üzgünüz, şu anda bu siteden sipariş veremezsiniz. Çalışma saatleri ve mevcut hizmetler hakkında daha fazla bilgi için lütfen restoranla iletişime geçin.',
    'Ref: Order Unavailable':
      'Seçilen tarih, yerine getirme türü ve konumu için sipariş artık mevcut değil!',
    'Ref: No Availability': 'Artık kullanılabilirlik yok',
    'Ref: Continue on your device': 'Cihazınızda devam edin',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} Kazandı Siparişinizi tamamlarsanız',
    'Ref: Loyalty Card Description':
      '{stamp_form} aşağıdaki sadakat eklenecektir {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'Sadakat Pulları programı, ödüller için pul toplamanızı sağlar.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> Siparişinizi tamamlarsanız kazanıldı:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> <b> {scheme_names} için eklendi </b>',
    'Ref: Stamp': 'Damga vurmak',
    'Ref: Stamps': 'Pullar',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programlar',
    'Ref: Loyalty Stamps': 'Sadakat pulları',
    'Ref: See Loyalty Stamps': 'Sadakat Pullarına bakın',
    'Ref: Loyalty Info': 'Sadakat bilgisi',
    'Ref: Email address': 'E -posta adresi',
    'Ref: and': 'Ve',
    'Ref: Order minimum amount': 'Üzgünüm, minimum ödeme tutarı {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Cihazınızda devam edin',
    'Ref: Scan QR to continue': 'Yolculuğa cihazınızda devam etmek için lütfen QR kodunu tarayın.',
  },
  OrderHistory: { 'Ref: Orders': 'Siparişler', 'Ref: Page Title': 'Siparişlerim' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Birim fiyat',
    'Ref: Download': 'Makbuzu indirin',
    'Ref: Download error': 'Bir indirme hatası oluştu. Makbuzu daha sonra indirmeye çalışın',
    'Ref: Order details title': 'Sipariş Detayları',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Teşekkürler! Siparişiniz şimdi onaylandı',
    'Ref: Text for pickup':
      'Durumunu sipariş geçmişinizden takip edebilirsiniz. Bildirimleriniz açıksa, teslim almaya hazır olduğunda sizi bilgilendireceğiz.',
    'Ref: Success Order Title': 'Siparişiniz için teşekkür ederiz',
    'Ref: Success Order Description':
      'Siparişinizi onaylamak için lütfen ödeme sırasında ödeme yapın.',
    'Ref: Success Order ID': 'Kimlik Siparişi',
    'Ref: Success Order Action': 'Yeni Düzen',
    'Ref: Text for dinein':
      'Siparişiniz yakında masanıza servis edilecektir. Herhangi bir güler yüzlü personelimize yemek siparişiniz hakkında soru sormanız yeterlidir.',
    'Ref: Text for scan&go': 'Siparişiniz sipariş geçmişinizde mevcuttur',
    'Ref: Text for delivery':
      'Durumunu sipariş geçmişinizde izleyebilirsiniz. Bildirimleriniz etkinleştirilirse, siparişiniz hazır olduğunda sizi bilgilendiririz.',
    'See details': 'Ayrıntılara bakın',
    'Go to home': 'Eve git',
  },
  FailOrder: {
    'Go to home': 'Eve git',
    'Ref: Kiosk Fail Title': 'Oops! Bir şeyler yanlış gitti!',
    'Ref: Kiosk Fail Description':
      'Maalesef siparişiniz başarılı olmadı. Lütfen tekrar deneyin veya bir personelden size yardımcı olmasını isteyin.',
    'Ref: Kiosk Fail Back': 'Önceki sayfaya geri dön',
    'Ref: Kiosk Fail Action': 'Oturumu temizleyin ve yeniden sipariş verin',
    'Ref: Error in cart validation':
      'Siparişiniz sırasında bir hata oluştu. Sorunu çözmek için lütfen tesisle iletişime geçin.',
    'Facility contact': 'Tesis iletişim',
    'Ref: Ordering failed': 'Sipariş başarısız oldu',
    'Ref: Order confirmed': 'Sipariş onaylandı',
  },
  TableCheckIn: {
    'Welcome at': 'Hoş geldiniz',
    Table: 'Masa:',
    'Ref: list title': 'Bugün için son siparişler',
    'See the menu': 'Menüye bakın',
    'Table Check-in': 'Masa Girişi',
    'Ref #': 'Ref #',
    Checked: 'Kontrol edildi',
    'Check in': 'Giriş yapın',
    'See details': 'Ayrıntılara bakın',
    'Ref: You have changed location': 'Konumunuzu değiştirdiniz',
    'Ref: You are now at': 'Şu anda',
    'Order number': 'Sipariş numarası',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promosyon uygulandı',
    'Ref: Promotion applied description': 'Sepetinizden bir promosyondan yararlanıyorsunuz.',
    'Ref: Promotion deleted title': 'Promosyon silindi',
    'Ref: Promotion deleted description':
      'Artık koşulları yerine getirmediğiniz veya promosyonun süresi dolduğu için promosyondan yararlanamazsınız.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Tarama ve Git', 'Ref: View only': 'Sadece görüntüle' },
  ProductTile: {
    'Ref: Allergens': 'Alerjenler',
    'Ref: Presence of allergen': 'Alerjen varlığı',
    'Ref: Scan': 'Tarama',
    'Ref: From': 'Kimden',
    'Ref: Already in product favorite list. Click to remove it.':
      'Zaten ürün favori listesinde. Kaldırmak için tıklayın.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Şu anda ürün favori listesine eklenmedi. Eklemek için tıklayın.',
    'Ref: Free': 'Özgür',
  },
  OrderSummary: {
    'Ref: item': 'Öğe',
    'Ref: items': 'öğeler',
    'Ref: Clear': 'Temiz',
    'Ref: Cart': 'Araba',
    'Ref: Subtotal': 'Ara Toplam',
    'Ref: Total': 'Toplam',
    'Ref: Tax': 'Vergi',
    'Ref: Promotion': 'Promosyon',
    'Ref: Total without promotion': 'Terfisiz toplam',
    'Ref: Number of product in cart': 'Sepetteki ürün sayısı',
    'Ref: Delivery': 'Teslimat',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegan', 'Ref: Vegeterian': 'Vejetaryen' },
  OrderInfoCard: {
    'Ref: Header': 'Emredilmiş',
    'Ref: from': 'gelen',
    'Ref: location': 'Konum',
    'Ref: Phone': 'Telefon numaranız',
    'Ref: Instructions': 'Talimatlar',
    'Ref: Order type': 'Sipariş türü',
    'Ref: Pickup': 'Pikap',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Tarama ve Git',
  },
  ReorderWidget: {
    'Ref: No items': 'Henüz sipariş verilmedi. Şimdi satın al!',
    'Ref: Title': 'Tekrar sipariş verin',
  },
  MenusWidget: { 'Ref: no menu': 'Şu an için menü yok', 'Ref: Modal title': 'Günün menüsü' },
  PromotionInfo: { 'Ref: Discounts available': 'indirimler mevcut' },
  PromotionInfoItem: { 'Ref: Activate': 'Etkinleştir' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Siparişleriniz', 'Ref: Widget Title': 'Siparişlerim' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Gün için öneri',
    'Ref: Any menu': 'Herhangi bir menü',
    Other: 'Diğer',
    'Ref: Suggestions calendar button': 'Öneriler Takvimi',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Porsiyon Büyüklüğü',
    'Ref: Nutrition': 'Beslenme bilgileri',
    'Ref: Nutrition adults': "Yetişkinlerin günde yaklaşık 2000 kcal'ye ihtiyacı vardır",
    'Ref: Nutrition Calories': 'Kalori',
    'Ref: Nutrition Calcium': 'Kalsiyum',
    'Ref: Nutrition Fat': 'Şişman',
    'Ref: Nutrition Saturated Fat': 'Doymuş yağ',
    'Ref: Nutrition Carbohydrates': 'Karbonhidratlar',
    'Ref: Nutrition Sugar': 'Şeker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodyum',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Diyet Lifleri',
    'Ref: Nutrition iron': 'Demir',
    'Ref: Nutrition potassium': 'Potasyum',
    'Ref: Nutrition vitaminA': 'A Vitamini',
    'Ref: Nutrition vitaminC': 'C Vitamini',
    'Ref: Ingredients': 'Tarif malzemesi',
    'Ref: Allergens': 'Allerjiye sebep olabilenler',
    'Ref: Always there': 'Her zaman orada',
    'Ref: Allergens selected': 'Filtrede seçilen alerjenler:',
    'Ref: minimum required': 'Minimum gerekli',
    'Ref: ingredients': 'içindekiler',
    'Ref: Ingredient': 'bileşen',
    'Ref: Free': 'Özgür',
    'Ref: Based on customization': 'Özelleştirmenize göre',
    'Ref: Additives eggs': 'yumurtalar',
    'Ref: Additives fish': 'balık',
    'Ref: Additives milk': 'Süt',
    'Ref: Additives nuts': 'Fındık',
    'Ref: Additives wheat': 'buğday',
    'Ref: Additives peanuts': 'yer fıstığı',
    'Ref: Additives soy beans': 'soya fasulyesi',
    'Ref: Additives sesame seeds': 'Susam taneleri',
    'Ref: Review': 'Gözden geçirmek',
    'Ref: Per portion': 'Kısım başına',
    'Ref: Per 100g': '100g başına',
    'Ref: Modifier item number of free items': '• {number_of_free_items} ücretsiz',
    'Ref: Number of free items': 'Ücretsiz ürün sayısı: {number_of_free_items}',
    'Ref: Allergen selected': 'Arama filtresinde seçilen alerjen',
    'Ref: Nutritional info': 'Beslenme Bilgisi',
    'Ref: Info': 'Bilgi',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Promosyonlar' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'İşinizi Seçin',
    'Ref: Validate': 'Doğrulamak',
    'Ref: Pricing Tiers dialog message': 'E -posta adresinizle tanındınız',
    'Ref: Please select your job in the company': 'Lütfen şirketteki işinizi seçin',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Pikap',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Tarama ve Git',
    'Ref: Delivery': 'Teslimat',
    'Ref: Total': 'Toplam',
    'Ref: How was facility name service': '{facility_name} ´s hizmeti nasıldı?',
    'Ref: How do you rate the service': 'Hizmeti nasıl değerlendiriyorsunuz?',
  },
};
export default labels;
