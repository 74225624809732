import { MarkerProps } from '@react-google-maps/api';

import { TestingPropsV2 } from '../../../types';

export type MapProps<T> = {
  positionCoordinates?: MapCoordinates;
  center: MapCoordinates;
  markers?: MapMarker<T>[];
  height?: string;
  width?: string;
  onMarkerClick?: (data: T) => void;
  fitMapToMarkers?: boolean;
  zoom?: number;
} & TestingPropsV2;

export type MapMarker<T> = {
  coordinates: MapCoordinates;
  data?: T;
  id?: string;
  icon: google.maps.Icon | string;
};

export type MapCoordinates = {
  longitude: number;
  latitude: number;
};

export type MapCustomMarkerPros<T> = MarkerProps &
  TestingPropsV2 & {
    data?: T;
  };

export class IconSize {
  constructor(width: number, height: number, widthUnit?: string, heightUnit?: string) {
    this.width = width;
    this.height = height;
    this.widthUnit = widthUnit;
    this.heightUnit = heightUnit;
  }

  width: number;
  height: number;
  widthUnit?: string;
  heightUnit?: string;

  equals(other: google.maps.Size | null): boolean {
    return other?.height === this.height && other?.width === this.width;
  }
}
export class MarkerIcon {
  constructor(url: string, scaledSize: IconSize) {
    this.url = url;
    this.scaledSize = scaledSize;
  }

  url: string;
  scaledSize: IconSize;
}
