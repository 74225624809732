import { SearchResultType } from '../hooks/useGlobalSearchResults/useGlobalSearchResults.types';

import { searchMenus } from './search.helper';

const menus: Array<any> = [
  {
    name: 'Coffee AW23xx',
  },
  {
    name: 'Coffee AW24xx',
  },
];

describe('global search helper functions', () => {
  describe('search menus', () => {
    it('should return menu search result with 1 result, when menu exists', () => {
      const actual = searchMenus(menus, 'aw24');

      expect(actual.type).toBe(SearchResultType.Menus);
      expect(actual.results.length).toBe(1);
    });

    it('should return menu search result with 0 results, when menu exists', () => {
      const actual = searchMenus(menus, 'aw25');

      expect(actual.type).toBe(SearchResultType.Menus);
      expect(actual.results.length).toBe(0);
    });
  });
});
