const labels = {
  ReviewForm: {
    'Ref: Page title': 'Revisione',
    'Ref: How was dining with us': "Com'è stato cenare con noi?",
    'Ref: Submit': 'Invia',
    'Ref: Filter range': 'Gamma di filtri',
    'Ref: Some information is missing': 'Mancano alcune informazioni',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Invia in modo anonimo',
    'Ref: Your email address': 'Il tuo indirizzo di posta elettronica',
    'Ref: Email field placeholder': 'Inserisci il tuo indirizzo email',
    'Ref: Invalid email format': 'formato email non valido',
  },
  SuccessPage: {
    'Ref: Body':
      'Grazie per aver completato questa recensione, il tuo tempo è prezioso in quanto questa recensione ci aiuterà a migliorare i nostri servizi.',
    'Ref: Go to Feedback': 'Vai al feedback',
    'Ref: Go to home': "Torna all'inizio",
    'Ref: Go to Facilities': 'Vai alle strutture',
    'Ref: Go to Product Details': 'Vai ai dettagli del prodotto',
    'Ref: Go to Facility Details': 'Vai ai dettagli della struttura',
  },
  FacilityPicker: {
    'Ref: Page title': 'Revisione',
    'Ref: Search facility name': 'Nome della funzione di ricerca',
  },
};
export default labels;
