const labels = {
  ReviewForm: {
    'Ref: Page title': 'Análise',
    'Ref: How was dining with us': 'Como foi jantar conosco?',
    'Ref: Submit': 'Enviar',
    'Ref: Filter range': 'Faixa de filtro',
    'Ref: Some information is missing': 'Algumas informações estão faltando',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Envie anonimamente',
    'Ref: Your email address': 'Seu endereço de email',
    'Ref: Email field placeholder': 'Insira o seu endereço de email',
    'Ref: Invalid email format': 'Formato de email inválido',
  },
  SuccessPage: {
    'Ref: Body':
      'Obrigado por concluir esta revisão, seu tempo é valioso, pois esta revisão ajudará a melhorar nossos serviços.',
    'Ref: Go to Feedback': 'Vá para o feedback',
    'Ref: Go to home': 'Vá para casa',
    'Ref: Go to Facilities': 'Vá para instalações',
    'Ref: Go to Product Details': 'Vá para os detalhes do produto',
    'Ref: Go to Facility Details': 'Vá para detalhes da instalação',
  },
  FacilityPicker: {
    'Ref: Page title': 'Análise',
    'Ref: Search facility name': 'Nome da instalação de pesquisa',
  },
};
export default labels;
