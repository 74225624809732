import { env } from 'process';

import { act, cleanup, fireEvent, screen, waitFor } from '@testing-library/react';

import GlobalSearchWidget from './GlobalSearchWidget';

import renderComponent from '@/helpers/tests/renderComponent';
import { useGetFilterPreferencesQuery } from '@/modules/Core/api/account/accountPreferencesApi';
import { SearchResultType } from '@/modules/GlobalSearch/hooks/useGlobalSearchResults/useGlobalSearchResults.types';
import { defaultFacilityDataItem } from '@/modules/Order/__mocks/mock';
import { FacilityMenuSource } from '@/modules/Order/types/orderState.types';

const mockUseGetMenusQuery = jest.fn();
const mockLogUserSteps = jest.fn();
const mockUseGlobalSearchResults = jest.fn();

const mockedLabel = (s: string) => s;

jest.mock('@/modules/Order/containers/ProductTile', () => (props: any) => {
  return <div data-testid={`ProductTile-${props.id}`}>MOCKED PRODUCT TILE</div>;
});

jest.mock('../../../../store', () => ({
  store: {
    getState: () => ({
      Core: {
        context: {
          site: {
            id: 1,
            currency: { isoCode: 'USD' },
          },
        },
        services: {
          list: [{ name: 'Food.Menu' }, { name: 'Food.Order' }, { name: 'Facilities' }],
        },
      },
      Order: {
        locks: { createOrderDraft: false },
        cart: {},
      },
    }),
  },
}));

jest.mock('@/modules/Order/api', () => {
  return {
    useGetMenusQuery: () => mockUseGetMenusQuery(),
  };
});

jest.mock('@/modules/GlobalSearch/hooks/useGlobalSearchResults/useGlobalSearchResults', () => ({
  useGlobalSearchResults: () => mockUseGlobalSearchResults(),
}));

jest.mock('../../../Core/api/account/accountPreferencesApi', () => ({
  ...jest.requireActual('../../../Core/api/account/accountPreferencesApi'),
  useGetFilterPreferencesQuery: jest.fn(),
}));

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: mockLogUserSteps,
  }),
}));

// data
const mockedMenus = [
  {
    id: 5,
    facilityId: 'facilityId1',
    name: 'menu 1',
    isOrderable: true,
    isScanAndGo: false,
    date: new Date().toString(),
    menuItems: [],
  },
  {
    id: 2,
    facilityId: 'facilityId2',
    name: 'menu 2',
    isOrderable: true,
    isScanAndGo: true,
    date: new Date().toString(),
    menuItems: [
      {
        menuItemId: 21,
        productPortions: [],
      },
    ],
  },
];

const productsSearchResults = Array.apply(null, Array(8)).map((_, i) => {
  return {
    menu: {
      name: `menu-name-${i}`,
    },
    item: {
      id: `item-id${i}`,
      menuItemId: 16856686 + i,
      uomId: 30551 + i,
      title: 'Burger',
      description: 'Tasty burger with everything inside',
      imageUrl: '',
      price: 11 + i,
      isVegan: false,
      isVegetarian: false,
      showAllergensWarning: false,
      isLoyaltyReward: false,
      isProvidingLoyaltyStamps: false,
      genericCategory: 'Burgers',
      key: i,
    },
  };
});

const searchResults = [
  {
    type: SearchResultType.Products,
    results: productsSearchResults,
  },
  {
    type: SearchResultType.Menus,
    results: [
      {
        id: `item-id`,
        facilityId: `facilityId`,
        name: `Facility Name `,
        date: '2023-10-26',
        isConferenceCatering: false,
        isOrderable: true,
        fulfillmentTypes: [],
        menuItems: [],
        source: FacilityMenuSource.Rr,
      },
    ],
  },
  {
    type: SearchResultType.Facilities,
    results: [defaultFacilityDataItem],
  },
];

describe('GlobalSearchWidget component', () => {
  afterAll(() => cleanup());

  beforeEach(async () => {
    global.process.env = { ...env, REACT_APP_FF_GLOBAL_SEARCH: 'true' };

    mockUseGetMenusQuery.mockReturnValue({ data: mockedMenus, isLoading: false });

    (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
      data: [],
      isLoading: false,
    });

    mockUseGlobalSearchResults.mockReturnValue({
      handleSearchTermChange: jest.fn(),
      searchResults: searchResults,
      clearSearchResults: jest.fn(),
      isLoading: false,
    });
  });

  describe('on render', () => {
    renderComponent(GlobalSearchWidget, {
      label: mockedLabel,
    });

    it('should display global search widget', async () => {
      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        expect(widget).toBeTruthy();
      });
    });

    it('should display modal', async () => {
      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        fireEvent.click(widget);
      });

      const modal = screen.getByTestId('global-search-modal');

      await waitFor(() => expect(modal).toBeTruthy());
    });

    it('should display header', async () => {
      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        fireEvent.click(widget);
      });

      const header = screen.getByTestId('global-search-modal-header');
      expect(header).toBeTruthy();
      const closeButton = screen.getByTestId('global-search-modal-button-close');
      expect(closeButton).toBeTruthy();
    });

    it('should render tab section', async () => {
      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        fireEvent.click(widget);
      });

      const tab = screen.getByTestId('button-search-result-type-products');
      expect(tab).toBeTruthy();
      const productsTabText = screen.getByText('Ref: Products (8)');
      expect(productsTabText).toBeTruthy();

      const menusTabText = screen.getByText('Ref: Menus (1)');
      expect(menusTabText).toBeTruthy();

      const facilitiesTabText = screen.getByText('Ref: Facilities (1)');
      expect(facilitiesTabText).toBeTruthy();

      const srOnlyText = screen.getByText('Ref: products button aria');
      expect(srOnlyText).toBeTruthy();
    });

    it('should display initial 6 product tiles', async () => {
      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        fireEvent.click(widget);
      });

      const firstProductTile = screen.getByTestId('ProductTile-item-id0');

      const productTiles = screen.getAllByText('MOCKED PRODUCT TILE');
      expect(productTiles.length).toBe(6);

      expect(firstProductTile).toBeTruthy();
    });

    it('should display Show more button and its functionality', async () => {
      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        fireEvent.click(widget);
      });

      const seeMoreButton = screen.getByTestId('global-search-modal-see-more-button');
      expect(seeMoreButton).toBeTruthy();
      fireEvent.click(seeMoreButton);

      const productTiles = screen.getAllByText('MOCKED PRODUCT TILE');
      expect(productTiles.length).toBe(8);

      // check wheter See More is hidden
      const showMoreText = screen.queryByTestId('global-search-modal-see-more-button');
      expect(showMoreText).toBeNull();
    });

    it('should display tile skeletons when loading', async () => {
      mockUseGlobalSearchResults.mockReturnValue({
        handleSearchTermChange: jest.fn(),
        searchResults: searchResults,
        clearSearchResults: jest.fn(),
        isLoading: true,
      });

      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        fireEvent.click(widget);
      });

      const thirdSkeletonTile = screen.getByTestId('global-search-modal-skeleton-placeholder-2');
      expect(thirdSkeletonTile).toBeTruthy();
    });

    it('should display No Results placeholder when no results', async () => {
      mockUseGlobalSearchResults.mockReturnValue({
        handleSearchTermChange: jest.fn(),
        searchResults: [],
        clearSearchResults: jest.fn(),
        isLoading: false,
      });

      await act(async () => {
        const widget = screen.getByTestId('global-search-search-input-wrapper');
        fireEvent.click(widget);
      });

      const noResultsTitle = screen.getByTestId('global-search-modal-no-results-title');
      expect(noResultsTitle).toBeTruthy();

      const noResultsBody = screen.getByTestId('global-search-modal-no-results-body');
      expect(noResultsBody).toBeTruthy();
    });
  });
});
