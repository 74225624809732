const labels = {
  All: {
    'Ref: stamp progress': 'Progresser vers le timbre suivant',
    Reward: 'Récompense',
    Rewards: 'Récompenses',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Timbres',
    'Ref: Fulfill conditions': 'Gagnez des timbres pour racheter les récompenses!',
    'See Reward': 'Voir la récompense',
    'Ref: No reward': 'Pas de récompense pour le moment',
  },
  RedeemableProducts: { 'Ref: Page title': 'Récompenses de fidélité' },
};
export default labels;
