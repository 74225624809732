const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': '당신의 요청',
    'Ref: Summary': '요약',
    'Ref: Request category': '요청 범주',
    'Ref: Person affected': '이 요청의 영향을받는 사람',
    'Ref: Your phone number': '전화 번호',
    'Ref: Description': '설명',
    'Ref: Service Requests': '서비스 요청',
    'Ref: Request Service': '요청 서비스',
    'Ref: Success title': '귀하의 요청이 작성되었습니다',
    'Ref: Error title': '죄송합니다!',
    'Ref: Error subtitle': '에러가 발생했습니다. 다시 시도 해주세요.',
    'Ref: Try again': '다시 시도하십시오',
    'Ref: you pronoun': '(너)',
  },
  Tutorials: {
    'Ref: Tutorial: title': '이것은 당신의 직장 지원 허브입니다',
    'Ref: Tutorial: tabname': '요청',
    'Ref: Tutorial: body':
      '우리는 당신을 돕기 위해 여기 있습니다. 항목 당 하나의 요청을 기록하십시오.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      '사이트 {SiteName}에서 서비스를 요청하고 있습니다.',
    'Ref: Building': '건물',
    'Ref: Room': '방',
    'Ref: Description help text': '당신이 더 상세할수록 우리는 당신을 더 잘 지원할 수 있습니다.',
    'Ref: Next': '다음',
    'Ref: Your request': '당신의 요청',
    'Ref: PhoneNumber error msg': '전화 번호는 유효하지 않습니다',
    'Ref: No affected person results': '일치하는 사람은 요청 기준을 요청하지 않았습니다',
    'Ref: Required': '* 필수의',
  },
  Summary: {
    'Ref: Location': '위치',
    'Ref: Attachment': '첨부 파일',
    'Ref: Submit': '제출하다',
    'Ref: No affected person results': '일치하는 사람은 요청 기준을 요청하지 않았습니다',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': '서비스 요청',
    'Ref: createdOn': '에 만든',
    'Ref: summary': '요약',
    'Ref: category': '요청 범주',
    'Ref: request raised for': '이 요청의 영향을받는 사람',
    'Ref: phoneNumber': '전화 번호',
    'Ref: location': '전화 번호',
    'Ref: description': '설명',
    'Ref: attachments': '첨부 파일',
  },
};
export default labels;
