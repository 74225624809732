import { cleanup, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultSite } from '../../../Sites/__mocks/mocks';
import { defaultProductPortion, defaultSuggestion } from '../../__mocks/mock';
import { useFetchLoyaltySchemes } from '../../hooks/useFetchLoyaltySchemes';
import { useFetchLoyaltyStampsProgress } from '../../hooks/useFetchLoyaltyStampsProgress';
import { PromotionalDiscountForItem, Suggestions } from '../../types/orderState.types';

import SuggestionsWidget from './SuggestionsWidget';
import { SuggestionsWidgetProps } from './SuggestionsWidget.types';

import { useGetLoyaltySchemesQuery } from '@/modules/LoyaltyStamps/api';

jest.setTimeout(100000);

jest.mock('../../components/MenuCartActions', () => () => <div data-testid="menu-cart-action" />);

jest.mock('@/modules/LoyaltyStamps/api', () => ({
  ...jest.requireActual('@/modules/LoyaltyStamps/api'),
  useGetLoyaltySchemesQuery: jest.fn(),
}));

jest.mock('../../hooks/useProductPortion/useProductPortion', () => ({
  useProductPortion: () => ({
    productPortion: { isFavorite: false },
    addToFavorites: jest.fn(),
    removeFromFavorites: jest.fn(),
  }),
}));

jest.mock('@/modules/Order/hooks/useFetchLoyaltySchemes', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltySchemes'),
  useFetchLoyaltySchemes: jest.fn(),
}));

jest.mock('@/modules/Order/hooks/useFetchLoyaltyStampsProgress', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltyStampsProgress'),
  useFetchLoyaltyStampsProgress: jest.fn(),
}));

const state = {
  Order: {
    locks: {},
    cart: {},
  },
  Core: {
    context: {
      site: defaultSite,
    },
  },
};

const dispatchMock = (value: any) => {
  return value;
};

const mockSelectorValue = 'en-US';
const mockPromotions: PromotionalDiscountForItem[] | undefined = [];

const mockSelectorFunction = jest.fn();

jest.mock('react-redux', () => {
  const actual = jest.requireActual('react-redux');
  return {
    ...actual,
    useDispatch: () => {
      return dispatchMock;
    },
    useSelector: () => {
      return mockSelectorFunction();
    },
  };
});

const mockUseGetMenusQuery = jest.fn();
const mockUseGetSuggestionsQuery = jest.fn();

jest.mock('../../api/api', () => {
  return {
    useGetMenusQuery: () => mockUseGetMenusQuery(),
    useGetSuggestionsQuery: () => mockUseGetSuggestionsQuery(),
  };
});

const menus = [
  {
    id: 1,
    facilityId: 'facilityId',
    name: 'menu 1',
    isOrderable: true,
    isScanAndGo: false,
    date: new Date().toString(),
    menuItems: [],
  },
  {
    id: 2,
    facilityId: 'facilityId',
    name: 'menu 2',
    isOrderable: true,
    isScanAndGo: true,
    date: new Date().toString(),
    menuItems: [
      {
        menuItemId: 21,
        productPortions: [],
      },
    ],
  },
];

const suggestionsEnabledWithData: Suggestions = {
  suggestionsEnabled: true,
  suggestions: [
    {
      ...defaultSuggestion,
      menuId: menus[0].id,
      menuItemId: 11,
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
        },
      ],
    },
    {
      ...defaultSuggestion,
      menuId: menus[0].id,
      menuItemId: 12,
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
        },
      ],
    },
    {
      ...defaultSuggestion,
      menuId: menus[0].id,
      menuItemId: 13,
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
        },
      ],
    },
    {
      ...defaultSuggestion,
      menuId: menus[0].id,
      menuItemId: 14,
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
        },
      ],
    },
    {
      ...defaultSuggestion,
      menuId: menus[1].id,
      menuItemId: 21,
      productPortions: [
        {
          ...defaultProductPortion,
          isDefault: true,
        },
      ],
    },
  ],
};

const suggestionsEnabledWithoutData: Suggestions = {
  suggestionsEnabled: true,
  suggestions: [],
};

describe('SuggestionsWidget', () => {
  const props: SuggestionsWidgetProps = {
    site: defaultSite,
  };

  afterAll(() => cleanup());

  describe('with data', () => {
    describe('on rendering', () => {
      beforeEach(() => {
        mockSelectorFunction.mockReturnValue(mockSelectorValue);
        mockSelectorFunction.mockReturnValue(mockPromotions);

        mockUseGetMenusQuery.mockReturnValue({ data: menus });
        mockUseGetSuggestionsQuery.mockReturnValue({
          data: suggestionsEnabledWithData,
          isLoading: false,
          isError: false,
        });

        (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
          data: [],
          isLoading: false,
        });
        (useFetchLoyaltySchemes as jest.Mock).mockReturnValue({
          schemes: [],
          isLoading: false,
        });
        (useFetchLoyaltyStampsProgress as jest.Mock).mockReturnValue({
          userProgress: [],
          isLoading: false,
        });
      });

      renderComponent(SuggestionsWidget, props, state);

      it('should display widget', () => {
        const widget = screen.queryByTestId('suggestions-widget');
        expect(widget).toBeTruthy();
      });

      it('should display dropdown', () => {
        const dropdown = screen.getByText('Any menu');
        expect(dropdown).toBeTruthy();
      });

      it('should display first three suggestions', () => {
        const suggestionElement1 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[0].menuId}_${suggestionsEnabledWithData.suggestions[0].menuItemId}`
        );
        const suggestionElement2 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[1].menuId}_${suggestionsEnabledWithData.suggestions[1].menuItemId}`
        );
        const suggestionElement3 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[2].menuId}_${suggestionsEnabledWithData.suggestions[2].menuItemId}`
        );
        const suggestionElement4 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[3].menuId}_${suggestionsEnabledWithData.suggestions[3].menuItemId}`
        );

        expect(suggestionElement1).toBeTruthy();
        expect(suggestionElement2).toBeTruthy();
        expect(suggestionElement3).toBeTruthy();
        expect(suggestionElement4).toBeFalsy();
      });

      it('should display Other button', () => {
        const otherButton = screen.getByText('Other');
        expect(otherButton).toBeTruthy();
      });
    });

    describe('on clicking Other button', () => {
      beforeEach(() => {
        mockSelectorFunction.mockReturnValue(mockSelectorValue);
        mockSelectorFunction.mockReturnValue(mockPromotions);

        mockUseGetMenusQuery.mockReturnValue({ data: menus });
        mockUseGetSuggestionsQuery.mockReturnValue({
          data: suggestionsEnabledWithData,
          isLoading: false,
          isError: false,
        });
        (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
          data: [],
          isLoading: false,
        });
        (useFetchLoyaltySchemes as jest.Mock).mockReturnValue({
          schemes: [],
          isLoading: false,
        });
        (useFetchLoyaltyStampsProgress as jest.Mock).mockReturnValue({
          userProgress: [],
          isLoading: false,
        });
      });

      renderComponent(SuggestionsWidget, props, state);

      beforeEach(async () => {
        const otherButton = screen.getByText('Other');
        await userEvent.click(otherButton);
      });

      it('should display new suggestions', () => {
        const suggestionElement1 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[3].menuId}_${suggestionsEnabledWithData.suggestions[3].menuItemId}`
        );
        const suggestionElement2 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[4].menuId}_${suggestionsEnabledWithData.suggestions[4].menuItemId}`
        );
        const suggestionElement3 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[0].menuId}_${suggestionsEnabledWithData.suggestions[0].menuItemId}`
        );
        const suggestionElement4 = screen.queryByTestId(
          `ProductTile-${suggestionsEnabledWithData.suggestions[1].menuId}_${suggestionsEnabledWithData.suggestions[1].menuItemId}`
        );

        expect(suggestionElement1).toBeTruthy();
        expect(suggestionElement2).toBeTruthy();
        expect(suggestionElement3).toBeTruthy();
        expect(suggestionElement4).toBeFalsy();
      });
    });

    describe('on selecting menu with just one suggestion', () => {
      beforeEach(() => {
        mockSelectorFunction.mockReturnValue(mockSelectorValue);
        mockSelectorFunction.mockReturnValue(mockPromotions);

        mockUseGetMenusQuery.mockReturnValue({ data: menus });
        mockUseGetSuggestionsQuery.mockReturnValue({
          data: suggestionsEnabledWithData,
          isLoading: false,
          isError: false,
        });

        (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
          data: [],
          isLoading: false,
        });
        (useFetchLoyaltySchemes as jest.Mock).mockReturnValue({
          schemes: [],
          isLoading: false,
        });
        (useFetchLoyaltyStampsProgress as jest.Mock).mockReturnValue({
          userProgress: [],
          isLoading: false,
        });
      });

      renderComponent(SuggestionsWidget, props, state);

      beforeEach(async () => {
        const dropdown = screen.getByText('Any menu');
        await userEvent.click(dropdown);
        const dropdownOption = screen.getByText(menus[1].name);
        await userEvent.click(dropdownOption);
      });

      it('should display that suggestion and disable Other button', async () => {
        await waitFor(() => {
          const fifthSuggestionTestId = `ProductTile-${suggestionsEnabledWithData.suggestions[4].menuId}_${suggestionsEnabledWithData.suggestions[4].menuItemId}`;
          const firstSuggestionTestId = `ProductTile-${suggestionsEnabledWithData.suggestions[0].menuId}_${suggestionsEnabledWithData.suggestions[0].menuItemId}`;

          expect(screen.queryByTestId(fifthSuggestionTestId)).toBeTruthy();
          expect(screen.queryByTestId(firstSuggestionTestId)).toBeFalsy();
          expect(screen.getByText('Other').closest('ion-button')).toHaveAttribute(
            'aria-disabled',
            'true'
          );
        });
      });
    });
  });
  describe('without data', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);
      mockSelectorFunction.mockReturnValue(mockPromotions);

      mockUseGetMenusQuery.mockReturnValue({ data: [] });
      mockUseGetSuggestionsQuery.mockReturnValue({
        data: suggestionsEnabledWithoutData,
        isLoading: false,
        isError: false,
      });

      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: false,
      });
    });

    renderComponent(SuggestionsWidget, props, state);

    it('should render placeholder', () => {
      const menuElement = screen.getAllByTestId('widget-placeholder');
      expect(menuElement).toBeTruthy();
    });

    it('should not render Other button', () => {
      const otherButton = screen.queryByText('Other');
      expect(otherButton).toBeFalsy();
    });
  });

  describe('with fetching error', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);
      mockSelectorFunction.mockReturnValue(mockPromotions);

      mockUseGetMenusQuery.mockReturnValue({ data: [], isError: true });
      mockUseGetSuggestionsQuery.mockReturnValue({
        data: [],
        isLoading: false,
        isError: true,
      });

      (useGetLoyaltySchemesQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: false,
      });
    });

    renderComponent(SuggestionsWidget, props, state);

    it('should render placeholder with Something went wrong message', () => {
      const widgetPlaceholder = screen.getAllByTestId('widget-placeholder');
      expect(widgetPlaceholder).toBeTruthy();
      expect(widgetPlaceholder[0]).toHaveTextContent('Something went wrong, data not loaded');
    });
  });
});
