const labels = {
  All: {
    'Ref: stamp progress': 'Fortschritt in Richtung der nächsten Stempel',
    Reward: 'Belohnung',
    Rewards: 'Belohnung',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Briefmarken',
    'Ref: Fulfill conditions': 'Verdienen Sie Briefmarken, um Belohnungen einzulegen!',
    'See Reward': 'Siehe Belohnung',
    'Ref: No reward': 'Keine Belohnung für den Moment',
  },
  RedeemableProducts: { 'Ref: Page title': 'Loyalitätsbelohnungen' },
};
export default labels;
