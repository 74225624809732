import { axiosClient } from '../../../axios/axiosClient';
import { globalRetailApiPath, shouldUseMockData } from '../../../config';
import mockResponse from '../mockResponse';

export const clearKioskSessionMutation = async (input: { trackingId: string }) => {
  if (shouldUseMockData) return await mockResponse.closeKioskSession.json();

  const url = `${globalRetailApiPath}/v1/order/close-kiosk-session`;
  await axiosClient.post(url, input);
};
