import {
  AllergenAlcoholIcon,
  AllergenArrachideIcon,
  AllergenBeefIcon,
  AllergenCeleryIcon,
  AllergenCrustaceanIcon,
  AllergenEggIcon,
  AllergenFishIcon,
  AllergenGlutamateIcon,
  AllergenLupinIcon,
  AllergenMilkIcon,
  AllergenMolluscIcon,
  AllergenMustardIcon,
  AllergenNutsIcon,
  AllergenPorcIcon,
  AllergenPoultryIcon,
  AllergenSesameIcon,
  AllergenSO2Icon,
  AllergenSoyBeanIcon,
  WarningAllergens,
  Gluten,
} from '../../../assets/icons';
import { FiltersModel } from '../../Core/api/account/accountApi.types';
import { selectedAllergensCacheKey } from '../config';
import { Allergen, AllergenType, ModifierItem, ProductPortion } from '../types/orderState.types';
import { ModifierSelections } from '../types/productDetails.types';

import styles from '../components/ProductDetails.module.css';

const AllergenIconSize = 24;

export const displayAllergen = (
  allergen: AllergenType,
  width: number = 32,
  height: number = 32,
  className: string = styles.nonWarningAllergenIcon
): React.ReactElement => {
  let Icon;
  switch (allergen) {
    case AllergenType.Peanut:
      Icon = AllergenArrachideIcon;
      break;
    case AllergenType.Celery:
      Icon = AllergenCeleryIcon;
      break;
    case AllergenType.Crustaceans:
      Icon = AllergenCrustaceanIcon;
      break;
    case AllergenType.Eggs:
      Icon = AllergenEggIcon;
      break;
    case AllergenType.Fish:
      Icon = AllergenFishIcon;
      break;
    case AllergenType.Gluten:
    case AllergenType.Wheat:
    case AllergenType.Rye:
    case AllergenType.Oats:
      Icon = Gluten;
      break;
    case AllergenType.Lupin:
      Icon = AllergenLupinIcon;
      break;
    case AllergenType.Milk:
      Icon = AllergenMilkIcon;
      break;
    case AllergenType.Molluscs:
    case AllergenType.Shellfish:
      Icon = AllergenMolluscIcon;
      break;
    case AllergenType.Mustard:
      Icon = AllergenMustardIcon;
      break;
    case AllergenType.Nuts:
    case AllergenType.Treenuts:
    case AllergenType.PecanNuts:
    case AllergenType.Almonds:
    case AllergenType.Hazelnuts:
    case AllergenType.Walnuts:
    case AllergenType.Cashews:
    case AllergenType.MacadamiaNuts:
    case AllergenType.BrazilNuts:
    case AllergenType.PistachioNuts:
    case AllergenType.QueenslandNuts:
      Icon = AllergenNutsIcon;
      break;
    case AllergenType.Sesame:
    case AllergenType.Kamut:
    case AllergenType.Spelt:
    case AllergenType.Barley:
      Icon = AllergenSesameIcon;
      break;
    case AllergenType.Sulphites:
      Icon = AllergenSO2Icon;
      break;
    case AllergenType.Soybean:
      Icon = AllergenSoyBeanIcon;
      break;
    case AllergenType.Pork:
      Icon = AllergenPorcIcon;
      break;
    case AllergenType.Poultry:
      Icon = AllergenPoultryIcon;
      break;
    case AllergenType.Beef:
      Icon = AllergenBeefIcon;
      break;
    case AllergenType.Alcohol:
      Icon = AllergenAlcoholIcon;
      break;
    case AllergenType.MSG:
      Icon = AllergenGlutamateIcon;
      break;
    default:
      Icon = WarningAllergens;
  }

  return Icon ? <Icon width={width} height={height} className={className} /> : <></>;
};

export const cacheSelectedAllergens = (allergens: AllergenType[]) =>
  localStorage.setItem(selectedAllergensCacheKey, JSON.stringify(allergens));

export const getAllergensFromCache = (filterPreferences: FiltersModel | undefined = undefined) => {
  const allergensFromCache = localStorage.getItem(selectedAllergensCacheKey);
  let allergens;
  if (!!allergensFromCache) {
    allergens = JSON.parse(allergensFromCache) as AllergenType[];
  } else {
    allergens = !!filterPreferences ? filterPreferences.selectedAllergens : null;
  }

  return allergens;
};

export const buildAllergenList = (
  allergens: Allergen[],
  selectedAllergens: AllergenType[],
  ariaLabel: string
) => {
  const allergensList = allergens
    .filter((allergen) => allergen.contains)
    .map((element) => {
      const isSelectedAllergen = selectedAllergens.some(
        (selectedAllergen) => selectedAllergen === element.id
      );
      return {
        id: element.id,
        label: element.name,
        value: element.allergen,
        labelClassName: isSelectedAllergen ? styles.warningLabel : styles.nonWarningLabel,
        'aria-label': isSelectedAllergen ? ariaLabel : undefined,
        'data-testid': isSelectedAllergen
          ? `warning-allergen-${element.name}`
          : `non-warning-allergen-${element.name}`,
        icons: [
          {
            icon: () =>
              displayAllergen(
                element.id,
                AllergenIconSize,
                AllergenIconSize,
                isSelectedAllergen ? styles.warningAllergenIcon : styles.nonWarningAllergenIcon
              ),
          },
        ],
      };
    });

  return allergensList.length > 0 ? allergensList : null;
};

export const buildAllergenListFromModifiers = (
  portion: ProductPortion | null,
  selectedModifiers: ModifierSelections[],
  selectedAllergens: AllergenType[],
  ariaLabel: string
) => {
  if (!portion) return null;
  const uniqueModifierAllergens = getUniqueModifiersAllergens(portion, selectedModifiers);

  const newAllergens = uniqueModifierAllergens.filter(
    (allergen) => !portion.allergens.find((all) => all.id === allergen.id)
  );

  if (newAllergens.length === 0) {
    return null;
  }
  return buildAllergenList(newAllergens, selectedAllergens, ariaLabel);
};

export const getPortionAndSelectedModifiersAllergens = (
  portion: ProductPortion | null,
  selectedModifiers: ModifierSelections[]
) => {
  const selectedModifiersAllergens = getUniqueModifiersAllergens(portion, selectedModifiers);
  const portionAllergens = portion?.allergens ?? [];

  const uniqueAllergens = [
    ...new Map(
      [...selectedModifiersAllergens, ...portionAllergens].map((item) => [item.id, item])
    ).values(),
  ];

  return uniqueAllergens;
};

export const filterBySelectedAllergens = (
  allergens: Allergen[],
  selectedAllergens: AllergenType[]
) => {
  return allergens.filter((allergens) =>
    selectedAllergens.some((selectedAllergen) => selectedAllergen === allergens.id)
  );
};

const getUniqueModifiersAllergens = (
  portion: ProductPortion | null,
  selectedModifiers: ModifierSelections[]
) => {
  if (!portion?.modifiers) {
    return [];
  }

  const modifierList = selectedModifiers.map((element) =>
    portion.modifiers
      .find((modifier) => modifier.id === element.modifierId)
      ?.modifierItems.filter((modifierItem) => element.itemIds.includes(modifierItem.id.toString()))
  );

  const modifierItems: ModifierItem[] = modifierList
    .flat(1)
    .filter((x) => x !== undefined) as ModifierItem[];

  const modifierAllergens = modifierItems
    .filter((x) => x.allergens)
    .map((x) => x.allergens)
    .flat(1);

  //deduplicated modifierAllergens
  const uniqueModifierAllergens = [
    ...new Map(modifierAllergens.map((item) => [item.id, item])).values(),
  ];

  return uniqueModifierAllergens;
};
