import momentjs from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { DATE_FORMAT } from '../../../../constants';
import { useGetFilterPreferencesQuery } from '../../../Core/api/account/accountPreferencesApi';
import { CoreState } from '../../../Core/types/State.types';
import { useGetMenusQuery } from '../../../Order/api';
import { buildConfig } from '../../../Order/components/ProductsList/productList.helper';
import ProductTile from '../../../Order/containers/ProductTile';
import { getDateFilter } from '../../../Order/helpers/menuSelector.helper';
import { MenuListFiltering, MenuType } from '../../../Order/types/menuSelector.types';
import {
  OrderState,
  ScannableMenuItem,
  SharedState,
  StateWithOrder,
} from '../../../Order/types/orderState.types';
import { useScannerComponent } from '../../../Order/widgets/ReorderWidget/useScannerComponent';
import { useGetLoyaltySchemesQuery, useGetUserLoyaltySchemesProgressQuery } from '../../api';
import {
  getActualStamps,
  getNumberOfPotentiallyUsedStamps as getNumberOfStampsForItemsInCart,
  getRewards,
} from '../../helpers/loyaltyStamps.helper';
import { useLoyaltyStampsTranslation } from '../../hooks/useLoyaltyStampsTranslation';

import { buildSections, getRedeemableMenuItems, mapToListModel } from './RedeemableProducts.helper';
import { RedeemableProductListItem } from './RedeemableProducts.types';

import { RewardIcon } from '@/assets/icons';
import { GiftIllustration } from '@/assets/illustrations';
import ImageWithFallback from '@/components/atoms/ImageWithFallback/ImageWithFallback';
import ProgressBar from '@/components/atoms/ProgressBar/ProgressBar';
import { SkeletonSquare } from '@/components/atoms/Skeleton';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import { TAG_COLOR, TILE_VARIANT, TileSkeleton } from '@/components/molecules/Tile';
import { Tag } from '@/components/molecules/Tile/Tag';
import Column from '@/components/organisms/Column';
import ListPage from '@/components/templates/ListPage/ListPage';
import { isValidHttpUrl } from '@/helpers/urlValidator';

import styles from './RedeemableProducts.module.css';

const RedeemableProducts = () => {
  const { label } = useLoyaltyStampsTranslation(__filename);
  const { id } = useParams<{ id: string }>();
  const site = useSelector((state: { Core: CoreState }) => state.Core?.context?.site);
  const cart = useSelector((state: { Order: OrderState }) => state.Order?.cart);
  const { currentLanguageCode } = useSelector(
    (state: { Shared: SharedState }) => state.Shared?.language
  );

  const siteId = site?.id || '';
  const [scanSelected, setScanSelected] = useState<ScannableMenuItem | undefined>();

  const createOrderDraft = useSelector(
    (state: StateWithOrder) => state.Order?.locks?.createOrderDraft
  );

  const redeemableProductsListDate = cart?.date
    ? momentjs(cart.date).format(DATE_FORMAT)
    : momentjs().format(DATE_FORMAT);

  const [selectedDate, setSelectedDate] = useState<string>(redeemableProductsListDate);
  const [filtering, setFiltering] = useState<MenuListFiltering>({
    filter_date: {
      [selectedDate]: true,
    },
  });
  const {
    data: stamps,
    isLoading: isLoadingStamps,
    isError: errorGetLoyaltySchemes,
  } = useGetLoyaltySchemesQuery({ siteId, useErrorBoundary: false });
  const { data: userProgress, isLoading: isLoadingUserProgress } =
    useGetUserLoyaltySchemesProgressQuery({ siteId, useErrorBoundary: false });
  const { data: filterPreferences, isLoading: isLoadingPreferences } = useGetFilterPreferencesQuery(
    { siteId, useErrorBoundary: false }
  );

  const {
    data: orderableMenus = [],
    isLoading: isMenuLoading,
    isError: errorGetMenusQuery,
  } = useGetMenusQuery({
    siteId,
    useCache: true,
    date: selectedDate,
    menuType: MenuType.Orderable,
    useErrorBoundary: false,
  });

  const errorFetchingData = errorGetMenusQuery || errorGetLoyaltySchemes;

  const isLoading =
    isLoadingStamps || isLoadingUserProgress || isMenuLoading || isLoadingPreferences;

  const scannerComponent = useScannerComponent({
    scanSelected,
    menus: orderableMenus || [],
    menuDate: selectedDate,
    site: { id: siteId, currency: site?.currency },
    currentLanguageCode: currentLanguageCode,
    createOrderDraft,
    setScanSelected,
  });

  const loyalty =
    !!stamps && stamps.length > 0 ? stamps.find((x) => x.id === Number(id)) : undefined;

  const redeemableMenuItems = useMemo(() => {
    if (!!loyalty && !!orderableMenus && orderableMenus.length > 0) {
      return getRedeemableMenuItems(loyalty, orderableMenus);
    }

    return [];
  }, [loyalty, orderableMenus]);

  const selectedAllergens =
    !!filterPreferences && filterPreferences.selectedAllergens
      ? filterPreferences.selectedAllergens
      : undefined;

  const listItems: RedeemableProductListItem[] = useMemo(
    () =>
      redeemableMenuItems
        ? mapToListModel({
            redeemableMenuItems,
            selectedAllergens: selectedAllergens,
            loyaltySchemes: stamps,
          })
        : [],
    [redeemableMenuItems, selectedAllergens, stamps]
  );

  const progressPerStamp = !!userProgress
    ? userProgress.find((scheme) => scheme.loyaltySchemeId === Number(id))
    : undefined;

  const usedStamps =
    !!loyalty && cart?.menuPortionItems && progressPerStamp
      ? getNumberOfStampsForItemsInCart(
          cart?.menuPortionItems,
          loyalty,
          progressPerStamp,
          cart?.menuId
        )
      : 0;

  const stampsActual =
    loyalty && progressPerStamp
      ? getActualStamps(progressPerStamp?.stampsCollected, loyalty.stampsRequiredForRedeem)
      : 0;

  const rewards =
    loyalty && progressPerStamp
      ? getRewards(
          progressPerStamp?.stampsCollected,
          usedStamps,
          loyalty.stampsRequiredForRedeem,
          stampsActual
        )
      : 0;

  const imgInfo =
    loyalty?.imageUrl && isValidHttpUrl(loyalty?.imageUrl)
      ? {
          src: loyalty?.imageUrl,
          alt: '',
        }
      : undefined;

  const titleAndProgress = (
    <div className={styles.facilityProgressWrapper}>
      <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES}>
        {loyalty?.name}
      </Title>
      <div className={styles.progressWrapper}>
        <ProgressBar
          data-testid="loyalty-scheme"
          value={stampsActual}
          max={loyalty?.stampsRequiredForRedeem}
          round
          labelOnRight
          className={styles.progressWidth}
          srOnlyText={label('Ref: stamp progress')}
        />
        <div aria-live="polite" aria-atomic="true" className={styles.rewardWrapper}>
          {rewards > 0 ? (
            <Tag
              key={'reward'}
              name={
                rewards === 1 ? `${rewards} ${label('Reward')}` : `${rewards} ${label('Rewards')}`
              }
              icon={<RewardIcon />}
              color={TAG_COLOR.SECONDARY}
            />
          ) : (
            <p className="sr-only">{`0 ${label('Rewards')}`}</p>
          )}
        </div>
      </div>
    </div>
  );

  const description = <p className={'paragraph'}>{loyalty?.description}</p>;

  const sections = buildSections(listItems);

  const listConfig = useMemo(
    () => (sections?.length ? buildConfig(sections?.length) : {}),
    [sections?.length]
  );

  const updateDate = useCallback((date: string) => {
    setSelectedDate(date);
  }, []);

  const dateFilter = useMemo(
    () => getDateFilter({ label, listElements: listItems, selectedDate, updateDate }),
    [label, listItems, selectedDate, updateDate]
  );

  const listFilters = {
    filters: dateFilter,
    initialFiltering: filtering,
    handleFilteringChange: (filtering: MenuListFiltering) => {
      setFiltering(filtering);
    },
  };

  const productItems = (item: RedeemableProductListItem) => {
    const props = {
      ...item,
      date: selectedDate,
      menu: orderableMenus?.find((menu) => menu.id === item.menu.id),
    };
    return <ProductTile key={item.id} {...props} onScanButtonClick={() => setScanSelected(item)} />;
  };

  const schemeImage = (
    <Column.Complementary>
      {isLoadingStamps ? (
        <SkeletonSquare />
      ) : (
        <ImageWithFallback
          data-testid="redeemable-products"
          imgInfo={imgInfo}
          imgElement={<GiftIllustration />}
        />
      )}
    </Column.Complementary>
  );

  const tilesPlaceholder = (
    <>
      {Array.apply(null, Array(3)).map((_, i) => (
        <TileSkeleton
          key={i}
          withoutActions
          withoutChildren={false}
          className={'mb-M'}
          data-testid={`skeleton-placeholder-${i}`}
        />
      ))}
    </>
  );

  return (
    <ListPage
      data-testid="redeemable-products-list-page"
      hasBackLink
      title={label('Ref: Page title')}
      listTopContent={titleAndProgress}
      filter={listFilters}
      hideFilterTitle
      description={description}
      sections={sections}
      config={listConfig}
      twoTilesColumns
      tileVariant={TILE_VARIANT.BIG}
      items={listItems}
      itemRenderer={productItems}
      isLoading={isLoading}
      aside={schemeImage}
      errorWithFetchingData={errorFetchingData}
    >
      {scannerComponent}
      {isLoading && tilesPlaceholder}
    </ListPage>
  );
};

export default RedeemableProducts;
