import { cleanup, screen } from '@testing-library/react';

import { ProductSearchResult } from '../hooks/useGlobalSearchResults/useGlobalSearchResults.types';

import { ProductSearchResults } from './ProductSearchResults';

import renderComponent from '@/helpers/tests/renderComponent';
import { useGetFilterPreferencesQuery } from '@/modules/Core/api/account/accountPreferencesApi';
import { useFetchLoyaltySchemes } from '@/modules/Order/hooks/useFetchLoyaltySchemes';
import { useFetchLoyaltyStampsProgress } from '@/modules/Order/hooks/useFetchLoyaltyStampsProgress';
import { FacilityMenuSource } from '@/modules/Order/types/orderState.types';

const state = {
  Core: {
    context: {
      site: {
        id: 1,
        currency: { isoCode: 'USD' },
      },
    },
    user: {
      isGuest: false,
    },
  },
  Order: {
    locks: { createOrderDraft: false },
    cart: {},
  },
};

const mockHistory = {
  push: jest.fn(),
};

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
}));

jest.mock('@/modules/Core/api/account/accountPreferencesApi', () => ({
  ...jest.requireActual('@/modules/Core/api/account/accountPreferencesApi'),
  useGetFilterPreferencesQuery: jest.fn(),
}));

jest.mock('@/modules/Order/hooks/useFetchLoyaltyStampsProgress', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltyStampsProgress'),
  useFetchLoyaltyStampsProgress: jest.fn(),
}));

jest.mock('@/modules/Order/hooks/useFetchLoyaltySchemes', () => ({
  ...jest.requireActual('@/modules/Order/hooks/useFetchLoyaltySchemes'),
  useFetchLoyaltySchemes: jest.fn(),
}));

describe('ProductSearchResults', () => {
  const products: ProductSearchResult[] = [
    {
      item: {
        calories: 100,
        isMindful: false,
        category: 'Main Dishes',
        subCategory: 'Pizza',
        genericCategory: 'Pizza',
        moment: undefined,
        allergens: [],
        id: '1',
        menuItemId: 1,
        uomId: 1,
        title: 'Hawai Pizza',
        description: 'Pizza with cheese, ham and pinapple',
        imageUrl: 'testImageUrl',
        price: 9.99,
        viewablePrices: undefined,
        isVegan: false,
        isVegetarian: false,
        showAllergensWarning: false,
        productCategory: null,
      },
      menu: {
        id: 1,
        facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
        name: 'Aspretto Cafe',
        message:
          "If you have a food allergy or intolerance (or someone you're ordering for has), please contact the site team. Do not order if you cannot get the allergy information you need.",
        date: '2022-11-22T00:00:00',
        isConferenceCatering: false,
        isScanAndGo: false,
        fulfillmentTypes: [],
        isOrderable: true,
        menuItems: [
          {
            menuItemId: 1,
            name: 'Hawai Pizza',
            isOrderable: true,
            mealName: 'mealName',
            foodSubCategoryDescription: 'desc',
            description: 'desc',
            price: 9.99,
            startTime: new Date(),
            endTime: new Date(),
            listImage: 'listImage',
            detailsImage: 'detailImage',
            dayPart: 'daypart',
            subSection: 'subsection',
            minimalPrice: 9.99,
            productPortions: [
              {
                portionId: '7894040d-d835-47c9-8009-d5ea5c0353bd',
                foodItemSourceKey: 'PLU3648',
                name: 'COCA COLA LIFT 600ML',
                description: '',
                ingredients: '',
                allergens: [],
                nutritions: {
                  calories: 0,
                  caloriesUnits: 0,
                  kilojoules: 0,
                  carbohydrates: 0,
                  fat: 0,
                  protein: 0,
                  saturatedFat: 0,
                  transFat: 0,
                  polyunsaturatedFat: 0,
                  cholesterol: 0,
                  sodium: 0,
                  dietaryFiber: 0,
                  sugar: 0,
                  potassium: 0,
                  iron: 0,
                  calcium: 0,
                  vitaminA: 0,
                  vitaminC: 0,
                },
                viewablePrices: [{ name: 'standard price', value: 9.99 }],
                isFavorite: false,
                uomBarcodes: [],
                portionSize: '600g',
                portion: 'Standard',
                gramWeight: '600',
                modifiers: [],
                isVegan: false,
                isVegetarian: false,
                isDefault: true,
                price: 9.99,
                milkAdditive: false,
                soyBeansAdditive: false,
                eggsAdditive: false,
                sesameSeedsAdditive: false,
                peanutsAdditive: false,
                wheatAdditive: false,
                fishAdditive: false,
                nutsAdditive: false,
                co2Rating: '',
                co2Value: '',
                alcoholContent: null,
                isMindful: false,
                foodItemId: 1,
                uomId: 1,
              },
            ],
            section: 'Pizza',
            genericCategory: 'Pizza',
          },
        ],
        source: FacilityMenuSource.Rr,
      },
    },
  ];

  const menuDate = '2023-10-25';
  const siteId = 'mockedSiteId';

  describe('ProductSearchResults component', () => {
    afterAll(() => cleanup());
    beforeEach(() => {
      (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
        data: [],
        isLoading: false,
      });
      (useFetchLoyaltySchemes as jest.Mock).mockReturnValue({
        schemes: [],
        isLoading: false,
      });
      (useFetchLoyaltyStampsProgress as jest.Mock).mockReturnValue({
        userProgress: [],
        isLoading: false,
      });
    });

    describe('when no products', () => {
      renderComponent(ProductSearchResults, {
        menuDate,
        siteId,
        products: [],
      });

      it('should render empty content', () => {
        const tiles = screen.queryAllByTestId(/\d+/);
        expect(tiles.length).toBe(0);
      });
    });

    describe('with products', () => {
      beforeEach(() => {
        (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
          data: [],
          isLoading: false,
        });
      });
      renderComponent(
        ProductSearchResults,
        {
          menuDate,
          siteId,
          products: products,
        },
        state
      );

      it('should render product tiles', () => {
        const tiles = screen.queryAllByTestId('ProductTile-1');
        expect(tiles.length).toBeTruthy();
      });
    });

    describe('when loading', () => {
      beforeEach(() => {
        (useGetFilterPreferencesQuery as jest.Mock).mockReturnValue({
          data: [],
          isLoading: true,
        });
      });

      renderComponent(ProductSearchResults, {
        menuDate,
        siteId,
        products: [],
      });

      it('should render loader', () => {
        const loader = screen.queryByTestId('skeleton-placeholder-0');
        expect(loader).toBeInTheDocument();
      });
    });
  });
});
