const labels = {
  All: { 'Ref: Page title': '供应商' },
  SuppliersList: {
    'Ref: Supplier details': '查看详细信息',
    'Missing supplier list': '我们没有找到任何供应商。',
    'Try again later': '稍后再试',
  },
  SupplierDetails: {
    'Address label': '地址：',
    'Website label': '网站：',
    'Details label': '详情',
  },
};
export default labels;
