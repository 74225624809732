import classNames from 'classnames';
import QRCode from 'react-qr-code';

import Loader from '../../../../components/atoms/Loader';
import Modal from '../../../../components/organisms/Modal';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';

import { ContinueJourneyModalProps } from './ContinueJourneyModal.types';

import styles from './ContinueJourneyModal.module.css';

const ContinueJourneyModal = ({
  siteId,
  submissionTrackingId,
  isModalOpen,
  hideModal,
  isLoading,
}: ContinueJourneyModalProps) => {
  const { label } = useOrderTranslation(__filename);
  return (
    <Modal
      isOpen={isModalOpen}
      id="continue-journey-modal"
      data-testid="continue-journey-modal"
      onDismiss={hideModal}
      title={label('Ref: Continue on your device')}
      aria-modal="true"
    >
      <Modal.ColumnLayout>
        {label('Ref: Scan QR to continue')}
        {isLoading ? (
          <div className={styles.loading}>
            <Loader data-testid="qr-code"/>
          </div>
        ) : (
          <QRCode
            className={classNames(styles.continueJourneyQRCode)}
            value={`${process.env.REACT_APP_BITE_HOST}/order/cart/${siteId}/${submissionTrackingId}`}
            data-testid="qr-code-to-continue-journey"
          />
        )}
      </Modal.ColumnLayout>
    </Modal>
  );
};

export default ContinueJourneyModal;
