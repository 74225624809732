import { useGetUserLoyaltySchemesProgressQuery } from '@/modules/LoyaltyStamps/api';

export const useFetchLoyaltyStampsProgress = (siteId: string, isGuest: boolean) => {
  const { data: userProgress, isLoading } = useGetUserLoyaltySchemesProgressQuery(
    { siteId, useErrorBoundary: false },
    { skip: isGuest }
  );

  return {
    userProgress,
    isLoading,
  };
};
