import { getMerchantPspCode } from './payment.helper';

describe('getMerchantPspCode', () => {
  it('returns pspCode when merchantSetupOtion is correct', () => {
    const pspCode = getMerchantPspCode('PlSodexoTEST-RetailEU(stripe)');

    expect(pspCode).toBe('stripe');
  });

  it('returns undefined when merchantSetupOtion is invalid 1', () => {
    const pspCode = getMerchantPspCode('PlSodexoTEST-RetailEU-stripe');

    expect(pspCode).toBeUndefined();
  });

  it('returns undefined when merchantSetupOtion is invalid 2', () => {
    const pspCode = getMerchantPspCode('PlSodexoTEST-RetailEU(stripe');

    expect(pspCode).toBeUndefined();
  });

  it('returns undefined when merchantSetupOtion is invalid 3', () => {
    const pspCode = getMerchantPspCode('PlSodexoTEST-RetailEU-stripe)');

    expect(pspCode).toBeUndefined();
  });
});
