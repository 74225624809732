import { act, renderHook } from '@testing-library/react';
import { useDispatch, useSelector } from 'react-redux';

import { addProductToFavorites, removeProductFromFavorites } from '../../actions';

import { useProductPortion } from './useProductPortion';

jest.mock('react-redux', () => ({
  useDispatch: jest.fn(),
  useSelector: jest.fn(),
}));

jest.mock('../../api', () => ({
  useAddFavoriteProductMutation: () => [jest.fn().mockResolvedValue({})],
  useRemoveFavoriteProductMutation: () => [jest.fn().mockResolvedValue({})],
}));

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: jest.fn(),
    logError: jest.fn(),
  }),
}));

describe('useProductPortion', () => {
  const mockDispatch = jest.fn();
  beforeEach(() => {
    jest.resetAllMocks();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);
  });

  it('should return the correct product portion', () => {
    const mockState = {
      Order: {
        menus: [
          {
            id: 1,
            menuItems: [
              {
                menuItemId: 1,
                productPortions: [
                  {
                    uomId: 1,
                    portion: 'large',
                  },
                ],
              },
            ],
          },
        ],
      },
    };

    (useSelector as jest.Mock).mockImplementation((selector) => selector(mockState));

    const { result } = renderHook(() => useProductPortion(1, 1, 1, 'site1'));

    expect(result.current.productPortion).toEqual({ uomId: 1, portion: 'large' });
    expect(typeof result.current.addToFavorites).toBe('function');
    expect(typeof result.current.removeFromFavorites).toBe('function');
  });

  it('should return undefined if no matching product portion is found', () => {
    const mockState = {
      Order: {
        menus: [
          {
            id: 1,
            menuItems: [
              {
                menuItemId: 1,
                productPortions: [
                  {
                    uomId: 2,
                    portion: 'small',
                  },
                ],
              },
            ],
          },
        ],
      },
    };

    (useSelector as jest.Mock).mockImplementation((selector) => selector(mockState));

    const { result } = renderHook(() => useProductPortion(1, 1, 1, 'site1'));

    expect(result.current.productPortion).toBeUndefined();
    expect(typeof result.current.addToFavorites).toBe('function');
    expect(typeof result.current.removeFromFavorites).toBe('function');
  });

  it('should add to favorites', async () => {
    const { result } = renderHook(() => useProductPortion(1, 1, 1, 'site1'));

    await act(async () => {
      await result.current.addToFavorites();
    });

    expect(mockDispatch).toHaveBeenCalledWith(addProductToFavorites(1));
  });

  it('should remove from favorites', async () => {
    const { result } = renderHook(() => useProductPortion(1, 1, 1, 'site1'));

    await act(async () => {
      await result.current.removeFromFavorites();
    });

    expect(mockDispatch).toHaveBeenCalledWith(removeProductFromFavorites(1));
  });
});
