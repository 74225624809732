import { act, renderHook } from '@testing-library/react';

import { FacilityMenuSource } from '../../types/orderState.types';

import { useCheckCanOrder } from './useCheckCanOrder';

const input = {
  siteId: 'siteId',
  selectedMenu: {
    id: 1,
    facilityId: 'facilityId',
    source: FacilityMenuSource.Rr,
    name: 'menuName',
    date: '2024-01-01',
    isConferenceCatering: false,
    isOrderable: true,
    fulfillmentTypes: [],
    menuItems: [],
    isScanAndGo: false,
  },
  orderPickupDate: '2024-01-01',
};

const ifmMenuInput = {
  siteId: 'siteId',
  selectedMenu: {
    id: 1,
    facilityId: 'facilityId',
    source: FacilityMenuSource.Ifm,
    name: 'menuName',
    date: '2024-01-01',
    isConferenceCatering: false,
    isOrderable: true,
    fulfillmentTypes: [],
    menuItems: [],
    isScanAndGo: false,
  },
  orderPickupDate: '2024-01-01',
};

const mockCheckCanOrder = jest.fn();
const mockCheckCanOrderResult = jest.fn();
jest.mock('../../api', () => ({
  ...jest.requireActual('../../api'),
  useLazyCheckCanOrderQuery: () => [mockCheckCanOrder, mockCheckCanOrderResult],
}));

describe('useCheckCanOrder', () => {
  beforeEach(() => {});

  it('should not issue request when parameters not valid', async () => {
    const { result } = await act(() =>
      renderHook(() => useCheckCanOrder({ ...input, orderPickupDate: undefined }))
    );
    expect(result.current.canOrder).toBeFalsy();
    expect(mockCheckCanOrder).not.toBeCalled();
  });

  it('should return canOrder set to true when response from server is that ordering possible', async () => {
    mockCheckCanOrder.mockResolvedValue({ data: { isOrderingPossible: true } });

    const { result } = await act(() => renderHook(() => useCheckCanOrder(input)));

    expect(result.current.canOrder).toBeTruthy();
    expect(mockCheckCanOrder).toBeCalled();
  });

  it('should return canOrder set to false when response from server is that ordering not possible', async () => {
    mockCheckCanOrder.mockResolvedValue({ data: { isOrderingPossible: false } });

    const { result } = await act(() => renderHook(() => useCheckCanOrder(input)));

    expect(result.current.canOrder).toBeFalsy();
    expect(mockCheckCanOrder).toBeCalled();
  });

  it('should return canOrder set to true when menu is non-RR', async () => {
    mockCheckCanOrder.mockResolvedValue({ data: { isOrderingPossible: true } });

    const { result } = await act(() => renderHook(() => useCheckCanOrder(ifmMenuInput)));

    expect(result.current.canOrder).toBeTruthy();
  });
});
