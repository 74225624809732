import { screen, cleanup } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultSite } from '../../../Sites/__mocks/mocks';
import { useGetOrderHistoryQuery } from '../../api';
import { DeliveryOptionType } from '../../types/cart.types';
import { HistoricalOrderType, OrderHistoryTileProps } from '../../types/orderHistory.types';
import { OrderHistoryWidgetProps } from '../../types/orderHistoryWidget.types';
import { OrderHistory } from '../../types/orderState.types';

import OrderHistoryWidget from './OrderHistoryWidget';

let mockOrderHistoryTileProps: OrderHistoryTileProps[] = [];

jest.mock(
  '../../components/OrderHistoryTile/OrderHistoryTile',
  () => (orderHistoryTileProps: OrderHistoryTileProps) => {
    mockOrderHistoryTileProps.push(orderHistoryTileProps);
    return <></>;
  }
);

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: jest.fn(),
    logError: jest.fn(),
  }),
}));

const orderHistoryList = [
  {
    delivery: 0,
    tax: 0,
    facilityId: 'facility1',
    orderId: 11,
    pickupDate: '2022-12-28T12:30:00',
    status: 'Submitted',
    totalAmount: 9.99,
    orderType: HistoricalOrderType.CurrentAndFuture,
    fulfillmentType: DeliveryOptionType.PickupOption,
    isPaid: false,
  },
  {
    delivery: 0,
    tax: 0,
    facilityId: 'facility2',
    orderId: 12,
    pickupDate: '2099-12-31T12:30:00',
    status: 'Paid',
    totalAmount: 21.59,
    orderType: HistoricalOrderType.Past,
    fulfillmentType: DeliveryOptionType.DineIn,
    isPaid: true,
  },
];

const mockFetchFacilities = jest.fn();

jest.mock('../../api', () => ({
  useGetOrderHistoryQuery: jest.fn(),
}));

jest.mock('../../../Facilities/redux/actions', () => {
  return {
    getAll: () => mockFetchFacilities(),
  };
});

const dispatchMock = (value: any) => {
  return value;
};
const mockSelectorValue = 'en-US';

const mockSelectorEmptyOrders: OrderHistory[] = [];

const mockSelectorFunction = jest.fn();

jest.mock('react-redux', () => {
  const actual = jest.requireActual('react-redux');
  return {
    ...actual,
    useDispatch: () => {
      return dispatchMock;
    },
    useSelector: () => {
      return mockSelectorFunction();
    },
  };
});

describe('OrderHistoryWidget component', () => {
  beforeEach(() => {
    mockOrderHistoryTileProps = [];
  });

  const props: OrderHistoryWidgetProps = {
    site: defaultSite,
    label: () => '',
  };

  afterAll(() => cleanup());

  describe('on render', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      (useGetOrderHistoryQuery as jest.Mock).mockReturnValue({
        data: [],
        isError: false,
        isLoading: false,
      });

      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: [],
        },
      });
    });

    renderComponent(OrderHistoryWidget, props);

    it('should display', () => {
      const orderHistoryWidget = screen.getByTestId('orderHistoryWidget');
      expect(orderHistoryWidget).toBeTruthy();
    });

    it('should fetch order history on load', () => {
      expect(useGetOrderHistoryQuery).toHaveBeenCalled();
    });
  });

  describe('on order history list empty', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue([]);

      (useGetOrderHistoryQuery as jest.Mock).mockReturnValue({
        data: [],
        isError: false,
        isLoading: false,
      });

      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: [],
        },
      });
    });

    renderComponent(OrderHistoryWidget, props);

    it('should display "no orders placeholder"', () => {
      const noItemsPlaceholder = screen.getByText('You have no orders');
      expect(noItemsPlaceholder).toBeTruthy();
    });
  });

  describe('on order history list not empty', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      (useGetOrderHistoryQuery as jest.Mock).mockReturnValue({
        data: orderHistoryList,
        isError: false,
        isLoading: false,
      });

      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: [],
        },
      });
    });

    renderComponent(OrderHistoryWidget, props);

    it('should display', async () => {
      expect(mockOrderHistoryTileProps.length).toBe(2);
      expect(screen.getByText('All orders')).toBeTruthy();
    });
  });

  describe('on order history list not empty', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorValue);

      (useGetOrderHistoryQuery as jest.Mock).mockReturnValue({
        data: orderHistoryList,
        isError: false,
        isLoading: false,
      });

      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: [],
        },
      });

      mockOrderHistoryTileProps = [];
    });

    renderComponent(OrderHistoryWidget, props);

    it('should only future order', async () => {
      const dropdownElement = screen.getByText('All orders');
      expect(dropdownElement).toBeTruthy();

      await userEvent.click(dropdownElement);

      const dropdownOption = screen.getByText('Coming orders');
      expect(dropdownOption).toBeTruthy();

      mockOrderHistoryTileProps = [];
      await userEvent.click(dropdownOption);
      expect(mockOrderHistoryTileProps.length).toBe(1);
    });
  });

  describe('on order history list empty', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorEmptyOrders);

      (useGetOrderHistoryQuery as jest.Mock).mockReturnValue({
        data: [],
        isError: false,
        isLoading: false,
      });

      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: [],
        },
      });
    });

    renderComponent(OrderHistoryWidget, props);

    it('should show widget placeholder', async () => {
      expect(screen.getByText('You have no orders')).toBeTruthy();

      const dropdownElement = screen.getByText('All orders');
      expect(dropdownElement).toBeTruthy();
      await userEvent.click(dropdownElement);

      const dropdownOption = screen.getByText('Coming orders');
      expect(dropdownOption).toBeTruthy();

      await userEvent.click(dropdownOption);
      expect(screen.getByText('You have no future orders')).toBeTruthy();
    });
  });

  describe('with fetching error', () => {
    beforeEach(() => {
      mockSelectorFunction.mockReturnValue(mockSelectorEmptyOrders);

      (useGetOrderHistoryQuery as jest.Mock).mockReturnValue({
        data: [],
        isError: true,
        isLoading: false,
      });

      mockFetchFacilities.mockResolvedValue({
        ok: true,
        responseData: {
          facilities: [],
        },
      });
    });

    renderComponent(OrderHistoryWidget, props);

    it('should render placeholder with Something went wrong message', async () => {
      const widgetPlaceholder = screen.getAllByTestId('widget-placeholder');
      expect(widgetPlaceholder).toBeTruthy();
      expect(widgetPlaceholder[0]).toHaveTextContent('Something went wrong, data not loaded');
    });
  });
});
