import { renderHook } from '@testing-library/react';
import { act } from 'react-dom/test-utils';

import { useGetFacilitiesForSearch } from './useGetFacilitiesForSearch';

import { defaultFacilityDataItem } from '@/modules/Order/__mocks/mock';

const siteId = 'siteId1';
const mockUseGetService = jest.fn();
jest.mock('@/helpers/hooks/useGetService/useGetService', () => ({
  useGetService: () => mockUseGetService(),
}));

const mockUseSortedFacilityList = jest.fn();
jest.mock('@/modules/Facilities/hooks/useSortedFacilityList', () => ({
  __esModule: true,
  default: () => mockUseSortedFacilityList(),
}));

const mockDispatch = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

const mockLogError = jest.fn();
jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logError: mockLogError,
  }),
}));

describe('useGetFacilitiesForSearch', () => {
  describe('when facility service not available', () => {
    beforeEach(() => {
      mockUseGetService.mockReturnValue(undefined);
    });

    it('must not load facilities', async () => {
      const { result } = await act(async () => renderHook(() => useGetFacilitiesForSearch(siteId)));

      expect(result.current.isLoading).toBe(false);
      expect(result.current.facilities.length).toBe(0);
      expect(mockDispatch).not.toBeCalled();
    });
  });

  describe('when facility service available', () => {
    beforeEach(() => {
      mockUseGetService.mockReturnValue({});
    });

    it('should load facilities when sortedFacilities list empty', async () => {
      mockUseSortedFacilityList.mockReturnValue([]);

      const { result } = await act(async () => renderHook(() => useGetFacilitiesForSearch(siteId)));

      expect(result.current.isLoading).toBe(false);
      expect(mockDispatch).toBeCalled();
    });

    it('should not load facilities and return facilities list', async () => {
      mockUseSortedFacilityList.mockReturnValue([defaultFacilityDataItem]);

      const { result } = await act(async () => renderHook(() => useGetFacilitiesForSearch(siteId)));

      expect(result.current.isLoading).toBe(false);
      expect(result.current.facilities).toEqual([defaultFacilityDataItem]);
      expect(mockDispatch).not.toBeCalled();
    });
  });
});
