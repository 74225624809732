const labels = {
  All: {
    'Ref: Redeem coffee description': 'Ilmainen, vastavalmistettu kuppi kahvia odottaa',
    'Ref: Congratulations': 'Onnittelut',
    'Ref: Get free coffee': 'Hanki ilmainen kahvi!',
    'Ref: Free coffee description': 'Täytä kuppi, ansaitse pisteitä kohti ilmaista kahvia.',
    'Ref: Redeem': 'Lunasta!',
  },
  StampPage: {
    'Ref: Page title': 'Ilmainen kahvi',
    'Ref: seconds left': 'sekuntia jäljellä',
    'Ref: How it works': 'Kuinka se toimii',
    'Ref: How it works description':
      'Joka kerta kun ostat kahvin, paina skannauspainiketta ja skannaa QR -koodi kassalla.',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'Skannaa QR -koodi',
    'Ref: Collect reward': 'Kerää palkkio',
    'Ref: Reward collected': 'Palkkio kerätty',
    'Ref: Invalid QR Code': 'Virheellinen QR -koodi',
    'Ref: Invalid QR Code description': 'QR -koodia ei tunnisteta',
    'Ref: Close': 'kiinni',
    'Ref: Redeem button': 'Lunasta',
    "Ref: The QR Code isn't recognized": 'QR -koodia ei tunnisteta',
    'Ref: Cancel': 'Peruuttaa',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Onnittelut!',
    'Ref: Confirm redeem description':
      'Haluatko varmasti käyttää tätä? Jos näin on, sinulla on vain 60 sekuntia näyttää puhelimesi kassalla ja suorittaa ostoksesi',
    'Ref: Congratulations modal description': 'Tartu ilmaiseen kahviin nyt!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Ilmainen kahvi!', 'Ref: See All': 'Näytä kaikki' },
};
export default labels;
