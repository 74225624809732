import { Capacitor } from '@capacitor/core';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { appInsights } from '../../../../src/appInsights';
import useAnalytics from '../Analytics/useAnalytics';

import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

export const useUserStepsInsightsLogging = () => {
  const state = useSelector((state: State) => state);
  const [loggedStep, setLoggedStep] = useState('');
  const { trackPageViewEvent } = useAnalytics();

  const logUserSteps = useCallback(
    ({
      event,
      facilityId,
      facilityName,
      menuId,
      params,
    }: {
      event: UserSteps;
      facilityId?: string;
      facilityName?: string;
      menuId?: number;
      params?: { [key: string]: string | number | boolean | undefined };
    }) => {
      const site = state.Core.context.site;
      const siteId = site?.id;
      const siteName = site?.name;
      let facilityID;
      let facilityTitle;

      if (!!facilityId) {
        facilityID = facilityId;
      } else if (!!menuId) {
        const menu = state.Order?.menus.find((x) => x.id === menuId);
        facilityID = menu?.facilityId;
      } else {
        facilityID = '';
      }

      if (!!facilityName) {
        facilityTitle = facilityName;
      } else if (!!facilityID) {
        facilityTitle = state.Facilities?.data[facilityID]?.title;
      } else {
        facilityTitle = '';
      }

      const currentStep = {
        name: event,
        properties: {
          siteId: siteId,
          siteName: siteName,
          facilityId: facilityID,
          facilityName: facilityTitle,
          isMobile: Capacitor.isNativePlatform(),
          ...params,
        },
      };

      const actionsThatMayRepeat: string[] = [
        UserSteps.FiltersApplied,
        UserSteps.AddedToCart,
        UserSteps.OrderSubmitted,
      ];

      if (
        JSON.stringify(currentStep) !== loggedStep ||
        actionsThatMayRepeat.includes(currentStep.name)
      ) {
        if (facilityID) trackPageViewEvent(window.location?.pathname || '', false, facilityID);

        appInsights?.trackEvent(currentStep);
        setLoggedStep(JSON.stringify(currentStep));
      }
    },
    [
      state.Core.context.site,
      state.Order?.menus,
      state.Facilities?.data,
      loggedStep,
      trackPageViewEvent,
    ]
  );

  const logError = useCallback(
    (error: Error, params?: { [key: string]: string | number | boolean | undefined }) => {
      const site = state.Core.context.site;
      const siteId = site?.id;
      const isMobile = Capacitor.isNativePlatform();

      appInsights?.trackException({
        exception: error,
        properties: {
          siteId: siteId,
          isMobile: isMobile,
          ...params,
        },
      });
    },
    [state.Core?.context.site]
  );

  return { logUserSteps, logError };
};

export default useUserStepsInsightsLogging;
