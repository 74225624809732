import { connect } from 'react-redux';

import CartButton from '../components/CartButton';
import { moduleId } from '../config';
import { OrderState } from '../types/orderState.types';

const mapStateToProps = (state: { [moduleId]: OrderState }) => {
  const cart = state[moduleId]?.cart;
  const cartTotalPrice = state[moduleId]?.draft?.total;

  return { cart, cartTotalPrice };
};

export default connect(mapStateToProps)(CartButton);
