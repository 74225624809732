const labels = {
  ReviewForm: {
    'Ref: Page title': 'ทบทวน',
    'Ref: How was dining with us': 'การรับประทานอาหารกับเราเป็นอย่างไรบ้าง?',
    'Ref: Submit': 'ส่ง',
    'Ref: Filter range': 'ช่วงตัวกรอง',
    'Ref: Some information is missing': 'ข้อมูลบางอย่างหายไป',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'ส่งโดยไม่ระบุชื่อ',
    'Ref: Your email address': 'ที่อยู่อีเมลของคุณ',
    'Ref: Email field placeholder': 'กรอกที่อยู่อีเมล์ของคุณ',
    'Ref: Invalid email format': 'รูปแบบอีเมลที่ไม่ถูกต้อง',
  },
  SuccessPage: {
    'Ref: Body':
      'ขอขอบคุณสำหรับการตรวจสอบนี้เวลาของคุณมีค่าเนื่องจากการตรวจสอบนี้จะช่วยปรับปรุงบริการของเรา',
    'Ref: Go to Feedback': 'ไปที่ข้อเสนอแนะ',
    'Ref: Go to home': 'กลับบ้าน',
    'Ref: Go to Facilities': 'ไปที่สิ่งอำนวยความสะดวก',
    'Ref: Go to Product Details': 'ไปที่รายละเอียดผลิตภัณฑ์',
    'Ref: Go to Facility Details': 'ไปที่รายละเอียดสิ่งอำนวยความสะดวก',
  },
  FacilityPicker: { 'Ref: Page title': 'ทบทวน', 'Ref: Search facility name': 'ชื่อสถานที่ค้นหา' },
};
export default labels;
