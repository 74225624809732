const labels = {
  All: {
    'Ref: Redeem coffee description': 'Ihre gratis, frisch gebrühte Tasse Kaffee wartet',
    'Ref: Congratulations': 'Herzliche Glückwünsche',
    'Ref: Get free coffee': 'Holen Sie sich gratis Kaffee!',
    'Ref: Free coffee description':
      'Füllen Sie Ihre Tasse und verdienen Sie Punkte für einen kostenlosen Kaffee.',
    'Ref: Redeem': 'Einlösen!',
  },
  StampPage: {
    'Ref: Page title': 'Kostenloser Kaffee',
    'Ref: seconds left': 'Sekunden übrig',
    'Ref: How it works': 'Wie es funktioniert',
    'Ref: How it works description':
      'Jedes Mal, wenn Sie einen Kaffee kaufen, drücken Sie die Scan-Taste und scannen den bereitgestellten QR-Code an der Kasse',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'QR-Code scannen',
    'Ref: Collect reward': 'Belohnung sammeln',
    'Ref: Reward collected': 'Belohnung gesammelt',
    'Ref: Invalid QR Code': 'Ungültiger QR -Code',
    'Ref: Invalid QR Code description': 'Der QR -Code wird nicht erkannt',
    'Ref: Close': 'Schließen',
    'Ref: Redeem button': 'Einlösen',
    "Ref: The QR Code isn't recognized": 'Der QR -Code wird nicht erkannt',
    'Ref: Cancel': 'Absagen',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'Gratulation!',
    'Ref: Confirm redeem description':
      'Sind Sie sicher, dass Sie das nutzen wollen? Wenn ja, haben Sie nur 60 Sekunden Zeit, Ihr Handy an der Kasse vorzuzeigen und Ihren Einkauf abzuschließen',
    'Ref: Congratulations modal description': 'Holen Sie sich jetzt Ihren kostenlosen Kaffee!',
  },
  Widget: { 'Ref: Free Coffee widget title': 'Kostenloser Kaffee!', 'Ref: See All': 'Alles sehen' },
};
export default labels;
