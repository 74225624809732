import { useCallback, useRef, useState } from 'react';

import useToggle from '../../../../helpers/hooks/useToggle';
import { isDesktopViewport } from '../../../../helpers/windowSize';
import { defaultValues, orderConfig } from '../../config';

export const useSuggestionsMobilePopup = (isRrMenu: boolean, isMenuOrderable: boolean) => {
  const isDesktopScreen = isDesktopViewport();
  const { areSuggestionsEnabled } = orderConfig();
  const intervalRef: { current: NodeJS.Timeout | undefined } = useRef(undefined);
  const [remainingSeconds, setRemainingSeconds] = useState<number>(
    defaultValues.suggestionsCountdownSeconds
  );

  const {
    state: showSuggestionPopup,
    toggleOn: openSuggestionPopup,
    toggleOff: hideSuggestionPopup,
  } = useToggle(false);

  const {
    state: showProgressBar,
    toggleOn: displayProgressBar,
    toggleOff: hideProgressBar,
  } = useToggle(true);

  const {
    state: isFirstItemScanned,
    toggleOn: setIsFirstItemScanned,
    toggleOff: resetIsFirstItemScanned,
  } = useToggle(false);

  const closePopupResetTime = useCallback(() => {
    hideSuggestionPopup();
    setRemainingSeconds(defaultValues.suggestionsCountdownSeconds);
    resetIsFirstItemScanned();
  }, [hideSuggestionPopup, resetIsFirstItemScanned]);

  const startCountdown = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    let timer = defaultValues.suggestionsCountdownSeconds;

    const countdown = setInterval(() => {
      timer -= 1;
      setRemainingSeconds(timer);

      if (timer === 0) {
        clearInterval(countdown);
        closePopupResetTime();
      }
    }, 1000);

    intervalRef.current = countdown;
  }, [setRemainingSeconds, closePopupResetTime]);

  const openPopupAndCountDown = () => {
    if (areSuggestionsEnabled && isRrMenu && isMenuOrderable && !isDesktopScreen) {
      displayProgressBar();
      openSuggestionPopup();
      startCountdown();
    }
  };

  const stopCountdown = () => {
    clearInterval(intervalRef.current);
    hideProgressBar();
    resetIsFirstItemScanned();
  };

  return {
    remainingSeconds,
    showSuggestionPopup,
    showProgressBar,
    openPopupAndCountDown,
    isFirstItemScanned,
    setIsFirstItemScanned,
    closePopupResetTime,
    stopCountdown,
  };
};
