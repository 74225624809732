const labels = {
  All: {
    'Ref: Assembled': 'Surinktas',
    'Ref: Submitted': 'Pateiktas',
    'Ref: Fulfilled': 'Įvykdė',
    'Ref: Completed': 'Baigta',
    'Ref: Paid': 'Mokama',
    'Ref: Approved': 'Patvirtinta',
    'Ref: Ready For Pickup': 'Paruoštas pikapui',
    'Ref: Delivered': 'Pristatyta',
    'Ref: Received': 'Gauta',
    'Ref: Rejected': 'Atstumtas',
    'Ref: Payment Error': 'Mokėjimo klaida',
    'Ref: Pending Payment': 'Laukiamas mokėjimas',
    'Ref: Preparing': 'Rengia',
    'Ref: Refunded': 'Grąžinta',
    'Ref: Cancelled By User': 'Atšaukė vartotojas',
    'Ref: Refund Requested': 'Grąžinimas paprašė',
    'Ref: Refund Failed': 'Grąžinimas nepavyko',
    'Ref: Payment Processing': 'Mokėjimo apdorojimas',
    'Ref: Not Paid': 'Nesumoketas',
    'Ref: Total:': 'Iš viso:',
    'Ref: Pickup': 'Paimti',
    'Ref: Eat-in': 'Valgymas',
    'Ref: Scan&Go': 'Nuskaityti ir eiti',
    'Ref: Fulfillment Type': 'Įvykdymo tipas',
    'Ref: Delivery': 'Pristatymas',
    'Ref: You have no future orders': 'Jūs neturite ateities užsakymų',
    'Ref: You have no past orders': 'Jūs neturite ankstesnių užsakymų',
    'Ref: You have no orders': 'Jūs neturite užsakymų',
    'Ref: All orders': 'Visi užsakymai',
    'Ref: Not past orders': 'Ateinantys įsakymai',
    'Ref: Past orders': 'Praeities įsakymai',
    'Ref: Vegan': 'Vegan.  T',
    'Ref: Vegeterian': 'Vegetaras',
    'Ref: See item': 'Žiūrėkite daiktą',
    'Ref: Activated': 'Suaktyvinta',
    'Ref: No suggestions': 'Jokių pasiūlymų nerasta',
    'Ref: Get your receipt': 'Gaukite kvitą',
    'Ref: Print': 'Atspausdinkite kvitą',
    'Ref: Email': 'Gaukite kvitą el. Paštu',
    'Ref: Receipt Modal message': 'Kaip norėtumėte gauti kvitą?',
    'Ref: Confirm': 'Patvirtinti',
    'Ref: Your email address': 'Jūsų elektroninio pašto adresas',
    Suggestion: 'Pasiūlymas',
    'Ref: Loyalty Reward': 'Lojalumo atlygis',
    'Ref: Equation for stamps per item': '1 pirkinys = 1 antspaudas',
    'Ref: Paid by employer': 'Jūsų darbdavio sumokėta suma',
    'Ref: Paid by user': 'Suma, kurią sumokėjote',
  },
  ProductsList: {
    'Ref: Page title': 'Produktų sąrašas',
    'Ref: Facility details': 'Išsami informacija',
    'Ref: Search products list': 'Ieškoti prekių',
    'Ref: filter label: menus': 'Meniu',
    'Ref: filter label: moment': 'Momentas',
    'Ref: filter label: category': 'Kategorija',
    'Ref: all': 'visi',
    'Ref: today': 'šiandien',
    'Ref: tomorrow': 'rytoj',
    'Ref: see more': 'Žiūrėti daugiau',
    'Ref: see less': 'Žiūrėkite mažiau',
    'Ref: reset': 'Atstatyti.  T',
    'Ref: View in cart': 'Vaizdas krepšyje',
    'Ref: You have changed location': 'Jūs pakeitėte vietą',
    'Ref: You are now at': 'Dabar esate',
    'Ref: Menu card': 'Meniu kortelė',
    'Ref: Table': 'Lentelės numeris',
    'Service unavailable': 'Aptarnavimas nepasiekiamas',
    'Ref: Ordering unavailable':
      'Atsargiai! Užsakymas šioje svetainėje šiuo metu nėra prieinamas, pabandykite vėliau.',
    'Ref: Filters': 'Filtrai (alergenai ir dar daugiau)',
  },
  ProductScanner: {
    'Ref: cart': 'Krepšelis',
    'Ref: Product Scanned':
      'buvo pridėtas į krepšį. Galite toliau nuskaityti brūkšninius kodus, kad pridėtumėte juos į savo krepšelį.',
    'Ref: Scan or adjust':
      'buvo pridėtas į krepšį. Sureguliuokite kiekį arba nuskaitykite kitą produktą.',
    'Ref: Product Not Matched':
      'Brūkšninis kodas nebuvo pripažintas. Prašau, pabandykite dar kartą',
    'Ref: Scan article code': 'Nuskaitykite brūkšninį kodą',
    'Ref: Native error product cannot be added to cart':
      'Prekės iš skirtingo meniu ar dienos dalies negalima pridėti prie krepšelio',
    'Ref: product scanner placeholder':
      'Nuskaitykite brūkšninį kodą, kad pridėtumėte jį prie savo krepšelio',
  },
  Order: { 'Ref: Page title': 'Įsakymas' },
  MenuSelector: {
    'Ref: Menu Page title': 'meniu',
    'Ref: today': 'šiandien',
    'Ref: tomorrow': 'rytoj',
    'Ref: Search products list': 'Ieškoti prekių',
    'Ref: You have changed location': 'Jūs pakeitėte vietą',
    'Ref: You are now at': 'Dabar esate',
  },
  ProductDetails: {
    'Ref: Page title': 'Produkto Aprašymas',
    'Ref: Serving Size': 'Savo dydį',
    'Ref: Nutrition': 'Mitybos informacija',
    'Ref: Nutrition adults': 'Suaugusiesiems reikia maždaug 2000 kcal per dieną',
    'Ref: Nutrition Calories': 'Kalorijos',
    'Ref: Nutrition Calcium': 'Kalcis.',
    'Ref: Nutrition Fat': 'riebalai',
    'Ref: Nutrition Saturated Fat': 'Sotieji riebalai',
    'Ref: Nutrition Carbohydrates': 'angliavandeniai',
    'Ref: Nutrition Sugar': 'Cukrus',
    'Ref: Nutrition Protein': 'Baltymas',
    'Ref: Nutrition Sodium': 'natris',
    'Ref: Nutrition cholesterol': 'Cholesterolio kiekis',
    'Ref: Nutrition dietaryFiber': 'Maistinė lasteliena',
    'Ref: Nutrition iron': 'Iron.',
    'Ref: Nutrition potassium': 'Kalio',
    'Ref: Nutrition vitaminA': 'Vitaminas A.',
    'Ref: Nutrition vitaminC': 'Vitamino C',
    'Ref: Additives': 'Priedai.  T',
    'Ref: Emission': 'CO2 emisija',
    'Ref: This product generates': 'Šis produktas generuoja',
    'Ref: of CO2': 'CO2.',
    'Ref: emissions, which equals a': 'emisijos, kurios yra a',
    'Ref: car trip': 'Automobilių kelionė',
    'Ref: Ingredients': 'Receptų ingredientas',
    'Ref: Allergens': 'alergenai',
    'Ref: Always there': 'Visada ten',
    'Ref: Allergens selected': 'Alergenai, parinkti ant filtro:',
    'Ref: minimum required': 'būtinas minimumas',
    'Ref: you have to add at least': 'Jūs turite bent pridėti',
    'Ref: ingredients': 'ingridientai',
    'Ref: Ingredient': 'ingredientas',
    'Ref: Free': 'Laisvas',
    'Ref: Based on customization': 'Remiantis jūsų pritaikymu',
    'Ref: Additives eggs': 'kiaušiniai',
    'Ref: Additives fish': 'žuvis',
    'Ref: Additives milk': 'pienas',
    'Ref: Additives nuts': 'riešutai',
    'Ref: Additives wheat': 'kvieciai',
    'Ref: Additives peanuts': 'riešutai',
    'Ref: Additives soy beans': 'sojos pupelės',
    'Ref: Additives sesame seeds': 'sezamo sėklos',
    'Ref: Review': 'Peržiūra',
    'Ref: Per portion': 'Už porciją',
    'Ref: Per 100g': 'Už 100 g',
    'Ref: Allergen selected': 'Alergenas, pasirinktas paieškos filtre',
    'Ref: Fitbit Log': 'Prisijunkite prie „Fitbit“',
    'Ref: age verification required on delivery': 'Reikalingas amžių tikrinimas',
    'Ref: alcohol content notification':
      'Pateikdami ir užsisakydami vieną iš šių daiktų, jūs sakote, kad esate 18 metų ar vyresnis. Piktnaudžiavimas alkoholiu yra pavojingas jūsų sveikatai.',
    'Ref: default': 'Numatytas',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} uždirbo, jei užsisakote šį produktą',
    'Ref: Loyalty Card Description':
      '{stamp_form} bus pridėtas prie šios lojalumo {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Programa „Lojalumo antspaudai“ leidžia rinkti antspaudus apdovanojimams.',
    'Ref: Loyalty Modal Description':
      'Kai perkate <b> {product_name}, 1 antspaudas </b> bus pridėtas prie <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'ir',
    'Ref: Stamp': 'Antspaudas',
    'Ref: Stamps': 'Antspaudai',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programos',
    'Ref: Loyalty Stamps': 'Lojalumo antspaudai',
    'Ref: See Loyalty Stamps': 'Žr. Lojalumo antspaudus',
    Favorite: 'Mėgstamiausia',
    'Ref: In favorites':
      'Produktas jau yra mėgstamiausiųjų sąraše. Spustelėkite, kad pašalintumėte.',
    'Ref: Not in favorites':
      'Produktas dar nėra mėgstamiausiųjų sąraše. Spustelėkite, jei norite pridėti.',
    'Ref: Added to favorites': 'Produktas buvo įtrauktas į mėgstamiausių sąrašą.',
    'Ref: Removed from favorites': 'Produktas buvo pašalintas iš mėgstamiausių sąrašo.',
    'Ref: From': 'Nuo',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Krepšelio pakeitimas',
    'Ref: Popup header for customizable item qty update': 'Šį elementą galima pritaikyti',
    'Ref: add new version': 'Pridėkite naują versiją',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Atnaujinkite pritaikomo elemento kiekį tiesiai iš savo krepšelio.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Atnaujinkite pritaikomo elemento kiekį tiesiai iš savo krepšelio arba pridėkite naują versiją.',
    'Ref: another context body':
      'Norėdami pridėti elementų iš šios svetainės, įrenginio, momento/meniu ar datos, turite išvalyti krepšelį',
    'Ref: cancel': 'Atšaukti',
    'Ref: add to cart': 'Į krepšelį',
    'Ref: view in cart': 'Krepšelis',
    'Ref: Products in cart': '{Product_name} skaičius krepšelyje: {Product_quantity}',
    'Ref: Customize': 'Pritaikyti',
    'Ref: Add': 'Papildyti',
    'Ref: Free': 'Laisvas',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Atšaukti',
    'Ref: notice': 'Pastebėti',
    'Ref: clear cart': 'Skaidrus krepšelis',
    'Ref: Cart addition error message':
      'Šiuo metu turite daiktų krepšyje iš {from_current}. Norėdami pridėti elementų iš {from_selected}, turite išvalyti krepšelį',
  },
  Cart: {
    'Ref: Page title': 'Krepšelis',
    'Ref: The cart is empty': 'Krepšelis tuščias',
    'Ref: Clear cart': 'Skaidrus krepšelis',
    'Ref: Continue shopping': 'Tęsti apsipirkimą',
    'Ref: Start shopping': 'Pradėkite apsipirkti',
    'Ref: Today': 'Šiandien',
    'Ref: Tomorrow': 'Rytoj',
    'Ref: Tax:': 'Mokestis:',
    'Ref: Promotion:': 'Skatinimas:',
    'Ref: Total without promotion:': 'Iš viso be paaukštinimo:',
    'Ref: Delivery:': 'Pristatymas:',
    'Ref: Pickup informations': 'Pikapo informacija',
    'Ref: Location': 'Vieta',
    'Ref: Time': 'Laikas',
    'Ref: Phone': 'Jūsų telefono numeris',
    'Ref: Instructions': 'Instrukcijos',
    'Ref: Please select': 'Prašome pasirinkti',
    'Ref: Enter special requests': 'Įveskite specialias užklausas čia',
    'Ref: notice': 'Pastebėti',
    'Ref: Product is no more available':
      'Atsiprašome, bet atrodo, kad kai kurių produktų nėra daugiau. Patikrinkite savo krepšelį.',
    'Ref: ok': 'Gerai',
    'Ref: Validate': 'Patvirtinkite mano užsakymą',
    'Ref: Pay now': 'Sumokėk dabar',
    'Ref: Charge to my department': 'Mokestis mano skyriui',
    'Ref: Pay on pickup': 'Sumokėkite už šiuos daiktus už pasiėmimą',
    'Ref: Accept terms of sale prefix': 'Aš priimu',
    'Ref: Accept terms of sale': 'Šios pardavimo sąlygos',
    'Ref: Continue': 'Tęsti',
    'Ref: Please login or sign up to place an order':
      'Prisijunkite arba prisiregistruokite pateikti užsakymą',
    'Ref: Accept terms of sale suffix': ', ir aš juos perskaičiau ir supratau.',
    'Ref: Payment modality': 'Mokėjimo būdas',
    'Ref: Other information': 'Kita informacija',
    'Ref: Eat-in': 'Valgymas',
    'Ref: Pickup': 'Paimti',
    'Ref: Table': 'Lentelės numeris',
    'Ref: Required fields': 'Privalomi laukai',
    'Service unavailable': 'Aptarnavimas nepasiekiamas',
    'Ref: Cannot order':
      'Atsiprašome, šiuo metu šioje svetainėje negalite pateikti užsakymo. Norėdami gauti daugiau informacijos apie darbo valandas ir prieinamas paslaugas, susisiekite su restoranu.',
    'Ref: Order Unavailable': 'Užsakymo pasirinktos datos, įvykdymo tipo ir vietos nebėra!',
    'Ref: No Availability': 'Daugiau prieinamumo',
    'Ref: Continue on your device': 'Tęskite savo įrenginį',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} uždirbo, jei užpildysite užsakymą',
    'Ref: Loyalty Card Description':
      '{stamp_form} bus pridėtas prie šios lojalumo {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Programa „Lojalumo antspaudai“ leidžia rinkti antspaudus apdovanojimams.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> uždirbta, jei užpildysite užsakymą:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> pridėta <b> {scheme_names} </b>',
    'Ref: Stamp': 'Antspaudas',
    'Ref: Stamps': 'Antspaudai',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programos',
    'Ref: Loyalty Stamps': 'Lojalumo antspaudai',
    'Ref: See Loyalty Stamps': 'Žr. Lojalumo antspaudus',
    'Ref: Loyalty Info': 'Lojalumo informacija',
    'Ref: Email address': 'Elektroninio pašto adresas',
    'Ref: and': 'ir',
    'Ref: Order minimum amount': 'Deja, minimali mokėjimo suma yra {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Tęskite savo įrenginį',
    'Ref: Scan QR to continue':
      'Prašome nuskaityti QR kodą, kad tęstumėte kelionę savo įrenginyje.',
  },
  OrderHistory: { 'Ref: Orders': 'Įsakymai', 'Ref: Page Title': 'Mano įsakymai' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Vieneto kaina',
    'Ref: Download': 'Atsisiųskite kvitą',
    'Ref: Download error': 'Įvyko atsisiuntimo klaida. Pabandykite atsisiųsti kvitą vėliau',
    'Ref: Order details title': 'Užsakymo informacija',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Ačiū! Jūsų užsakymas dabar patvirtintas',
    'Ref: Text for pickup':
      'Per savo užsakymo istoriją galite sekti jo būseną. Jei jūsų pranešimai bus įjungti, atnaujinsime jus, kai jie bus paruošti pasiimti.',
    'Ref: Success Order Title': 'Ačiū už jūsų užsakymą',
    'Ref: Success Order Description': 'Prašome sumokėti kasoje, kad patvirtintumėte užsakymą.',
    'Ref: Success Order ID': 'ID užsakymas',
    'Ref: Success Order Action': 'Naujas užsakymas',
    'Ref: Text for dinein':
      'Jūsų užsakymas netrukus turėtų būti įteiktas prie jūsų stalo. Tiesiog paklauskite bet kurio iš mūsų draugiškų darbuotojų apie jūsų užsakymą.',
    'Ref: Text for scan&go': 'Jūsų užsakymas yra jūsų užsakymo istorijoje',
    'Ref: Text for delivery':
      'Galite sekti jo būseną savo užsakymo istorijoje. Jei jūsų pranešimai bus įjungti, mes informuosime jus, kai jūsų užsakymas bus paruoštas.',
    'See details': 'Žr. Išsamią informaciją',
    'Go to home': 'Eiti namo',
  },
  FailOrder: {
    'Go to home': 'Eiti namo',
    'Ref: Kiosk Fail Title': 'Oi! Kažkas nutiko ne taip!',
    'Ref: Kiosk Fail Description':
      'Deja, jūsų užsakymas nebuvo sėkmingas. Pabandykite dar kartą arba paprašykite personalo nario jums padėti.',
    'Ref: Kiosk Fail Back': 'Atgal į ankstesnį puslapį',
    'Ref: Kiosk Fail Action': 'Išvalykite sesiją ir vėl užsisakykite',
    'Ref: Error in cart validation':
      'Jūsų užsakymo metu įvyko klaida. Norėdami išspręsti problemą, susisiekite su įstaiga.',
    'Facility contact': 'Objekto kontaktas',
    'Ref: Ordering failed': 'Užsakymas nepavyko',
    'Ref: Order confirmed': 'Užsakymas patvirtintas',
  },
  TableCheckIn: {
    'Welcome at': 'Sveiki atvykę',
    Table: 'Lentelė:',
    'Ref: list title': 'Naujausi šiandienos užsakymai',
    'See the menu': 'Pamatyti meniu',
    'Table Check-in': 'Stalo registracija',
    'Ref #': 'Nuoroda #',
    Checked: 'Patikrinta',
    'Check in': 'Įsiregistruoti',
    'See details': 'Žr. Išsamią informaciją',
    'Ref: You have changed location': 'Jūs pakeitėte vietą',
    'Ref: You are now at': 'Dabar esate',
    'Order number': 'Užsakymo numeris',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Taikoma paaukštinimas',
    'Ref: Promotion applied description': 'Jums naudinga reklama iš savo krepšelio.',
    'Ref: Promotion deleted title': 'Reklama ištrinta',
    'Ref: Promotion deleted description':
      'Jums nebereikia naudos reklamavimui, nes nebeatlikote sąlygų ar paaukštinimo galiojimo laikas.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Nuskaityti ir eiti', 'Ref: View only': 'Tik vaizdas' },
  ProductTile: {
    'Ref: Allergens': 'Alergenai',
    'Ref: Presence of allergen': 'Alergeno buvimas',
    'Ref: Scan': 'Nuskaitymas',
    'Ref: From': 'Nuo',
    'Ref: Already in product favorite list. Click to remove it.':
      'Jau mėgstamiausių produktų sąraše. Spustelėkite, kad pašalintumėte.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Šiuo metu nėra įtraukta į mėgstamų produktų sąrašą. Spustelėkite, jei norite pridėti.',
    'Ref: Free': 'Laisvas',
  },
  OrderSummary: {
    'Ref: item': 'daiktas',
    'Ref: items': 'daiktai',
    'Ref: Clear': 'Aišku',
    'Ref: Cart': 'Krepšelis',
    'Ref: Subtotal': 'Subtorinis',
    'Ref: Total': 'Iš viso',
    'Ref: Tax': 'Mokestis',
    'Ref: Promotion': 'Skatinimas',
    'Ref: Total without promotion': 'Iš viso be paaukštinimo',
    'Ref: Number of product in cart': 'Produkto skaičius krepšelyje',
    'Ref: Delivery': 'Pristatymas',
  },
  CartItemTile: { 'Ref: Vegan': 'Veganas', 'Ref: Vegeterian': 'Vegetariškas' },
  OrderInfoCard: {
    'Ref: Header': 'Užsakyta',
    'Ref: from': 'nuo',
    'Ref: location': 'Vieta',
    'Ref: Phone': 'Jūsų telefono numeris',
    'Ref: Instructions': 'Instrukcijos',
    'Ref: Order type': 'Užsakymo tipas',
    'Ref: Pickup': 'Paimti',
    'Ref: Eat-in': 'Valgymas',
    'Ref: Scan&Go': 'Nuskaityti ir eiti',
  },
  ReorderWidget: {
    'Ref: No items': 'Dar nėra pateikti užsakymų. Apsipirk dabar!',
    'Ref: Title': 'Vėl užsisakyti',
  },
  MenusWidget: { 'Ref: no menu': 'Šiuo metu nėra meniu', 'Ref: Modal title': 'Dienos meniu' },
  PromotionInfo: { 'Ref: Discounts available': 'Galimos nuolaidos' },
  PromotionInfoItem: { 'Ref: Activate': 'Suaktyvinti' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'Jūsų užsakymai',
    'Ref: Widget Title': 'Mano įsakymai',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'Dienos pasiūlymas',
    'Ref: Any menu': 'Bet koks meniu',
    Other: 'Kita',
    'Ref: Suggestions calendar button': 'Pasiūlymų kalendorius',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Savo dydį',
    'Ref: Nutrition': 'Mitybos informacija',
    'Ref: Nutrition adults': 'Suaugusiesiems reikia maždaug 2000 kcal per dieną',
    'Ref: Nutrition Calories': 'Kalorijos',
    'Ref: Nutrition Calcium': 'Kalcis.',
    'Ref: Nutrition Fat': 'riebalai',
    'Ref: Nutrition Saturated Fat': 'Sotieji riebalai',
    'Ref: Nutrition Carbohydrates': 'angliavandeniai',
    'Ref: Nutrition Sugar': 'Cukrus',
    'Ref: Nutrition Protein': 'Baltymas',
    'Ref: Nutrition Sodium': 'natris',
    'Ref: Nutrition cholesterol': 'Cholesterolio kiekis',
    'Ref: Nutrition dietaryFiber': 'Maistinė lasteliena',
    'Ref: Nutrition iron': 'Iron.',
    'Ref: Nutrition potassium': 'Kalio',
    'Ref: Nutrition vitaminA': 'Vitaminas A.',
    'Ref: Nutrition vitaminC': 'Vitamino C',
    'Ref: Ingredients': 'Receptų ingredientas',
    'Ref: Allergens': 'alergenai',
    'Ref: Always there': 'Visada ten',
    'Ref: Allergens selected': 'Alergenai, parinkti ant filtro:',
    'Ref: minimum required': 'būtinas minimumas',
    'Ref: ingredients': 'ingridientai',
    'Ref: Ingredient': 'ingredientas',
    'Ref: Free': 'Laisvas',
    'Ref: Based on customization': 'Remiantis jūsų pritaikymu',
    'Ref: Additives eggs': 'kiaušiniai',
    'Ref: Additives fish': 'žuvis',
    'Ref: Additives milk': 'pienas',
    'Ref: Additives nuts': 'riešutai',
    'Ref: Additives wheat': 'kvieciai',
    'Ref: Additives peanuts': 'riešutai',
    'Ref: Additives soy beans': 'sojos pupelės',
    'Ref: Additives sesame seeds': 'sezamo sėklos',
    'Ref: Review': 'Peržiūra',
    'Ref: Per portion': 'Už porciją',
    'Ref: Per 100g': 'Už 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} nemokamai',
    'Ref: Number of free items': 'Nemokamų daiktų skaičius: {number_of_free_items}',
    'Ref: Allergen selected': 'Alergenas, pasirinktas paieškos filtre',
    'Ref: Nutritional info': 'Mitybos informacija',
    'Ref: Info': 'Informacija',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Akcijos' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Pasirinkite savo darbą',
    'Ref: Validate': 'Patvirtinti',
    'Ref: Pricing Tiers dialog message': 'Jūs buvote pripažintas savo el. Pašto adresu',
    'Ref: Please select your job in the company': 'Pasirinkite savo darbą įmonėje',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Paimti',
    'Ref: Eat-in': 'Valgymas',
    'Ref: Scan&Go': 'Nuskaityti ir eiti',
    'Ref: Delivery': 'Pristatymas',
    'Ref: Total': 'Iš viso',
    'Ref: How was facility name service': 'Kaip buvo {facility_name} paslauga?',
    'Ref: How do you rate the service': 'Kaip vertinate paslaugą?',
  },
};
export default labels;
