import { IonCard } from '@ionic/react';
import classNames from 'classnames';
import { decode } from 'html-entities';
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

import { StarOutlinedIcon, StarFilledIcon } from '../../../assets';
import { BannerIcon } from '../../../assets/icons';
import clamp from '../../../libraries/clamp';
import Title, { TITLE_TAG } from '../../atoms/Title';

import { TileProps } from './Tile.types';

import './Tile.css';

function checkUrl(url?: string): undefined | false | string {
  if (url?.includes('javascript')) return false;
  return url;
}

class OldTile extends React.Component<TileProps> {
  reasonablyUniqueId: string;

  constructor(props: TileProps) {
    super(props);
    this.clampAll = this.clampAll.bind(this);
    this.getBanner = this.getBanner.bind(this);
    this.getImg = this.getImg.bind(this);
    this.getRight = this.getRight.bind(this);
    this.getCorner = this.getCorner.bind(this);
    this.reasonablyUniqueId = 'tile_' + Math.random().toString().substr(2);
  }

  componentDidMount() {
    this.clampAll();
  }

  componentDidUpdate() {
    this.clampAll();
  }

  clampAll() {
    //todo: review for perf
    const nodes = document.querySelectorAll('#' + this.reasonablyUniqueId + ' .clamp');
    for (const elem of Array.from(nodes)) {
      clamp(elem as HTMLElement, { clamp: 2 });
    }

    /**
     * I'm sorry for this piece of code.
     * Actually Safari doesn't want to apply multiline clamp on first renderer, so we need
     * to trigger another paint to make it works.
     * If you decide to waste some time on this bug, please increment the time wasted here.
     * Time wasted: 2.5 hours
     */
    setTimeout(() => {
      const title = document.getElementById(this.reasonablyUniqueId + '-clampedTitle');
      title?.classList.add('clampedTitle');
    }, 100);
  }

  getBanner() {
    const { bannerInfo } = this.props;

    if (!bannerInfo) return;

    let toggle;
    let bannerInsideComponent;
    const bannerInfoToggle = bannerInfo.toggle;
    if (bannerInfoToggle) {
      let BannerInsideIcon;
      if (bannerInfo.starType === 'filled') {
        toggle = () => bannerInfoToggle(false);
        BannerInsideIcon = StarFilledIcon;
      } else {
        toggle = () => bannerInfoToggle(true);
        BannerInsideIcon = StarOutlinedIcon;
      }
      bannerInsideComponent = (
        <BannerInsideIcon
          className="tile_banner_star"
          role="image"
          aria-label={'banner: ' + bannerInfo.alt}
          onClick={toggle}
        />
      );
    }

    return (
      <div className="tile_banner">
        <BannerIcon
          className={classNames('tile_banner_background', bannerInfo.look)}
          role="image"
          aria-label={'banner: ' + bannerInfo.alt}
          onClick={toggle}
        />
        {bannerInfo.innerElement}
        {bannerInsideComponent}
      </div>
    );
  }

  getImg(
    navigateToLinkPath: React.MouseEventHandler<HTMLImageElement> | undefined,
    banner: React.ReactNode
  ) {
    const { imgInfo, imgElement } = this.props;

    let img;
    if (imgInfo) {
      img = (
        <div className="img_container">
          {banner}
          <img
            src={imgInfo.src}
            alt={''}
            onClick={navigateToLinkPath}
            style={{
              backgroundColor: imgInfo.bgColor ? imgInfo.bgColor : '',
              padding: imgInfo.padding ?? '',
            }}
            onError={(e) => {
              if (imgInfo.onError) imgInfo.onError(e);
              else {
                //TODO: handle this using React ?
                // @ts-ignore
                e.target.parentNode.style.display = 'none';
              }
            }} //on error, hide the thumbnail unless some custom error function is defined (e.g. use default image
          />
        </div>
      );
      return img;
    }

    if (imgElement) {
      img = (
        <div className="img_container" onClick={navigateToLinkPath}>
          {banner}
          {imgElement}
        </div>
      );
      return img;
    }
  }

  getRight() {
    const { highlightedActions, highlightedActionsInheritStyle, label } = this.props;

    if (!highlightedActions) return;

    let right;

    for (const highlightedAction of highlightedActions) {
      //can be an icon or/and a label, or an element, but not both, and one of them is mandatory
      let rightAction: React.ReactNode = false;
      if (highlightedAction.element) {
        rightAction = highlightedAction.element;
      } else if (highlightedAction.iconPath || highlightedAction.text) {
        rightAction = (
          <React.Fragment>
            {highlightedAction.iconPath ? (
              <img
                src={highlightedAction.iconPath}
                alt={(() => {
                  if (highlightedAction.iconAlt) return highlightedAction.iconAlt;
                  if (highlightedAction.text) return highlightedAction.text;
                  return label('go') as string;
                })()}
                className="action_icon"
              />
            ) : (
              ''
            )}
            {highlightedAction.text ? <div>{highlightedAction.text}</div> : ''}
          </React.Fragment>
        );
      } else continue;

      //wrap to handle onClick action if needed
      rightAction = highlightedAction.action ? (
        <span className="action" onClick={highlightedAction.action} tabIndex={0} role="button">
          {rightAction}
        </span>
      ) : (
        rightAction
      );

      //wrap with a Link if there is a link path
      rightAction = highlightedAction.linkPath ? (
        <Link to={highlightedAction.linkPath}>{rightAction}</Link>
      ) : (
        rightAction
      );

      //wrap with a "a" if there is an external link
      const checkedUrl = checkUrl(highlightedAction.externalLink);
      rightAction = checkedUrl ? <a href={checkedUrl}>{rightAction}</a> : rightAction;

      //add the result to right
      right = (
        <React.Fragment>
          {right}
          {rightAction}
        </React.Fragment>
      );
    }

    //wrap
    return (
      <div className={classNames('actions', highlightedActionsInheritStyle)} data-cy="tile-actions">
        {right}
      </div>
    );
  }

  getCorner() {
    const { cornerAction } = this.props;

    if (!cornerAction) return;

    const cornerElemement = (
      <>
        {!cornerAction.withoutCornerBackground && (
          <span className={classNames('corner', { corner_inactive: cornerAction.isInactive })} />
        )}
        {cornerAction.element}
      </>
    );

    if (cornerAction.link) {
      return (
        <Link {...cornerAction.link} className={classNames('corner_action')}>
          {cornerElemement}
        </Link>
      );
    }

    return <div className="corner_action">{cornerElemement}</div>;
  }

  render() {
    const {
      title, //if we don't have a title, no default height
      secondTitleLine,
      centeredTitleY, //align title vertically ( Y axis )
      tileAction,
      description,
      addendum,
      linkPath,
      history,
      type = 'default',
      look = 'default',
      longTileTextVisible = false,
      onClickCapture,
      onMouseDownCapture,
      'data-testid': testId,
    } = this.props;

    let navigateToLinkPath: MouseEventHandler | undefined;
    if (linkPath)
      navigateToLinkPath = (e) => {
        // This is needed to take into account e.preventDefault()
        if (!e.isDefaultPrevented()) {
          history.push(linkPath);
        }
      };

    const banner = this.getBanner();
    const img = this.getImg(navigateToLinkPath, banner);
    const right = this.getRight();
    const corner = this.getCorner();
    // If the text is only a title, and has no description and addendum we automatically apply the centeredTitleY style
    const centeredTitle = centeredTitleY || (title && !description && !addendum);

    const mainContentClasses = classNames('main_content', {
      main_content_default_height: Boolean(title),
      longTextVisibleContent: longTileTextVisible,
    });

    const commentClasses = classNames('comment');

    const commentClampClasses = classNames(addendum ? 'ellipsis' : 'clamp');

    const cardClasses = classNames(
      'tile tile_' + type,
      'tile_look_' + look,
      tileAction ? 'tile_clickable' : ''
    );

    let main = (
      <div
        className={classNames(mainContentClasses, { tile_clickable: linkPath })}
        onClick={navigateToLinkPath}
        onMouseDownCapture={onMouseDownCapture}
        onClickCapture={onClickCapture}
        data-testid={`tile-${testId}`}
        {...(linkPath && { tabIndex: 0, role: 'button' })}
      >
        {title ? (
          <div
            className={centeredTitle ? 'centered_title_y' : ''}
            data-testid={`tile-${testId}-title`}
          >
            <Title
              tag={TITLE_TAG.SPAN}
              id={this.reasonablyUniqueId + '-clampedTitle'}
              className={classNames('bodyMBold', 'title', {
                ellipsis: addendum && description,
                twoLinesEllipsis: (!addendum || !description) && !longTileTextVisible,
                clamp: !addendum && !description,
              })}
              data-cy="tile-title"
            >
              {decode(title)}
            </Title>
          </div>
        ) : (
          false
        )}
        {secondTitleLine ? (
          <div
            className={centeredTitle ? 'centered_title_y' : ''}
            data-testid={`second-title-${testId}`}
          >
            <Title
              tag={TITLE_TAG.SPAN}
              id={this.reasonablyUniqueId + '-clampedTitle'}
              className={classNames('bodyMBold', 'title', {
                clamp: !addendum && !description,
              })}
              data-cy="secondtile-title"
            >
              {decode(secondTitleLine)}
            </Title>
          </div>
        ) : (
          false
        )}
        {(() => {
          if (description)
            return (
              <div className={commentClasses}>
                <p
                  className={commentClampClasses}
                  data-cy="tile-comment"
                  data-testid={`tile-${testId}-comment`}
                >
                  {typeof description === 'string' ? decode(description) : description}
                </p>
              </div>
            );
        })()}
        {(() => {
          if (addendum)
            return (
              <div
                className={`addendum highlight ${corner ? 'with_corner' : ''}`}
                data-cy="tile-addendum"
                data-testid={`tile-${testId}-addendum`}
              >
                {typeof addendum === 'string' ? (
                  <p className={'addendumText'}>{decode(addendum)}</p>
                ) : (
                  addendum
                )}
              </div>
            );
        })()}
      </div>
    );

    const textWrapper = classNames('main_wrapper', { longTextVisibleWrapper: longTileTextVisible });

    return (
      <IonCard
        data-cy={this.props['data-cy'] || 'default-tile'}
        data-testid={this.props['data-testid'] || 'default-tile'}
        id={this.reasonablyUniqueId}
        className={cardClasses}
        onClick={tileAction}
      >
        {
          img ||
            banner /*if image, the banner is already included (if any), otherwise must be added independently */
        }
        <div
          className={textWrapper}
          data-testid={`tile-${testId}-wrapper`}
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          {main}
          {right}
        </div>
        {corner}
      </IonCard>
    );
  }
}

export default OldTile;
