const labels = {
  All: {
    'Ref: Redeem coffee description': '無料のコーヒーと引き換えましょう',
    'Ref: Congratulations': 'おめでとうございます！',
    'Ref: Get free coffee': '無料のコーヒーを手に入れよう！',
    'Ref: Free coffee description': 'ポイントを獲得して無料のコーヒーを手に入れましょう',
    'Ref: Redeem': '引き換え',
  },
  StampPage: {
    'Ref: Page title': '無料のコーヒー',
    'Ref: seconds left': '残り時間',
    'Ref: How it works': '使い方',
    'Ref: How it works description':
      'コーヒーを購入する際、ボタンを押してレジにあるQRコードをスキャンします。',
  },
  StampActionButton: {
    'Ref: Scan QR Code': 'QRコードをスキャンします',
    'Ref: Collect reward': 'ポイントを集めます',
    'Ref: Reward collected': '集めたポイント',
    'Ref: Invalid QR Code': '無効なQRコード',
    'Ref: Invalid QR Code description': 'QRコードを認識できません',
    'Ref: Close': '選ぶ',
    'Ref: Redeem button': '引き換え',
    "Ref: The QR Code isn't recognized": 'QRコードを認識できません',
    'Ref: Cancel': 'キャンセル',
    'Ref: Bite': 'Bite',
    'Ref: Congratulations modal title': 'おめでとうございます！',
    'Ref: Confirm redeem description':
      '引き換えますか？その際は60秒以内にレジで購入を完了してください。',
    'Ref: Congratulations modal description': '今すぐ無料のコーヒーを手に入れましょう！',
  },
  Widget: { 'Ref: Free Coffee widget title': '無料のコーヒー！', 'Ref: See All': 'すべてを見る' },
};
export default labels;
