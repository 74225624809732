const labels = {
  ReviewForm: {
    'Ref: Page title': 'İnceleme',
    'Ref: How was dining with us': 'Bizimle yemek yemek nasıldı?',
    'Ref: Submit': 'Gönder',
    'Ref: Filter range': 'Filtre aralığı',
    'Ref: Some information is missing': 'Bazı bilgiler eksik',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Anonim olarak gönderin',
    'Ref: Your email address': 'E-posta adresiniz',
    'Ref: Email field placeholder': 'E-posta adresinizi girin',
    'Ref: Invalid email format': 'Geçersiz e-posta biçimi',
  },
  SuccessPage: {
    'Ref: Body':
      'Bu incelemeyi tamamladığınız için teşekkür ederiz, bu inceleme hizmetlerimizin iyileştirilmesine yardımcı olacağından zamanınız değerlidir.',
    'Ref: Go to Feedback': 'Geri Bildirime Git',
    'Ref: Go to home': 'Ana Sayfaya Git',
    'Ref: Go to Facilities': 'Tesislere Git',
    'Ref: Go to Product Details': 'Ürün Detaylarına Git',
    'Ref: Go to Facility Details': 'Tesis Ayrıntılarına Git',
  },
  FacilityPicker: { 'Ref: Page title': 'İnceleme', 'Ref: Search facility name': 'Tesis adını ara' },
};
export default labels;
