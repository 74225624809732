const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Votre demande',
    'Ref: Summary': 'Résumé',
    'Ref: Request category': 'Catégorie de demande',
    'Ref: Person affected': 'La personne touchée par cette demande',
    'Ref: Your phone number': 'Numéro de téléphone',
    'Ref: Description': 'Description',
    'Ref: Service Requests': 'Demandes de service',
    'Ref: Request Service': 'Demander des services',
    'Ref: Success title': 'Votre demande a été créée',
    'Ref: Error title': 'Oops!',
    'Ref: Error subtitle': "Une erreur s'est produite, veuillez réessayer.",
    'Ref: Try again': 'Essayer à nouveau',
    'Ref: you pronoun': '(vous)',
  },
  Tutorials: {
    'Ref: Tutorial: title': "Bienvenue sur votre centre d'assistance au travail",
    'Ref: Tutorial: tabname': 'Demandes',
    'Ref: Tutorial: body':
      'Nous sommes là pour vous aider. Veuillez enregistrer une demande par entrée.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Vous demandez un service sur le site {siteName}.',
    'Ref: Building': 'Bâtiment',
    'Ref: Room': 'Chambre',
    'Ref: Description help text': 'Plus vous êtes détaillé, mieux nous pouvons vous soutenir.',
    'Ref: Next': 'Suivant',
    'Ref: Your request': 'Votre demande',
    'Ref: PhoneNumber error msg': "Le numéro de téléphone n'est pas valide",
    'Ref: No affected person results': 'Personne ne correspondant aux critères demandés',
    'Ref: Required': '* Requis',
  },
  Summary: {
    'Ref: Location': 'Emplacement',
    'Ref: Attachment': 'Attachements',
    'Ref: Submit': 'Soumettre',
    'Ref: No affected person results': 'La personne ne correspond aux critères demandés',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Demande de service',
    'Ref: createdOn': 'Créé sur',
    'Ref: summary': 'Résumé',
    'Ref: category': 'Catégorie de demande',
    'Ref: request raised for': 'La personne touchée par cette demande',
    'Ref: phoneNumber': 'Numéro de téléphone',
    'Ref: location': 'Numéro de téléphone',
    'Ref: description': 'Description',
    'Ref: attachments': 'Attachements',
  },
};
export default labels;
