const labels = {
  ReviewForm: {
    'Ref: Page title': 'Recenzja',
    'Ref: How was dining with us': 'Jak wyglądało spożywanie posiłków u nas',
    'Ref: Submit': 'Zatwierdź',
    'Ref: Filter range': 'Zakres filtru',
    'Ref: Some information is missing': 'Brakuje niektórych informacji',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Wyślij anonimowo',
    'Ref: Your email address': 'Twój adres email',
    'Ref: Email field placeholder': 'Wprowadź swój adres e -mail',
    'Ref: Invalid email format': 'Niewłaściwy format wiadomości email',
  },
  SuccessPage: {
    'Ref: Body': 'Dziękujemy za wypełnienie tej recenzji. Pomoże nam ona poprawić nasze usługi.',
    'Ref: Go to Feedback': 'Przejdź do recenzji',
    'Ref: Go to home': 'Przejdź do strony głównej',
    'Ref: Go to Facilities': 'Przejdź do listy obiektów',
    'Ref: Go to Product Details': 'Przejdź do szczegółów produktu',
    'Ref: Go to Facility Details': 'Przejdź do szczegółów obiektu',
  },
  FacilityPicker: {
    'Ref: Page title': 'Recenzja',
    'Ref: Search facility name': 'Wyszukaj nazwę obiektu',
  },
};
export default labels;
