import { Device } from '@capacitor/device';
import { useEffect, useState } from 'react';

const useIsNative = () => {
  const [isNative, setIsNative] = useState(false);
  useEffect(() => {
    async function getDeviceInfo() {
      const deviceInfo = await Device.getInfo();
      setIsNative(deviceInfo.platform !== 'web');
    }

    getDeviceInfo();
  }, []);

  return { isNative };
};

export default useIsNative;
