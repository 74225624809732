const labels = {
  All: {
    'Ref: Assembled': 'Assemblé',
    'Ref: Submitted': 'Soumis',
    'Ref: Fulfilled': 'Réalisé',
    'Ref: Completed': 'Complété',
    'Ref: Paid': 'Payé',
    'Ref: Approved': 'Approuvé',
    'Ref: Ready For Pickup': 'Prêt à être récupéré',
    'Ref: Delivered': 'Livré',
    'Ref: Received': 'Reçu',
    'Ref: Rejected': 'Rejeté',
    'Ref: Payment Error': 'Erreur de paiement',
    'Ref: Pending Payment': 'En attente de paiement',
    'Ref: Preparing': 'En cours de préparation',
    'Ref: Refunded': 'Remboursé',
    'Ref: Cancelled By User': "Annulé par l'utilisateur",
    'Ref: Refund Requested': 'Remboursement demandé',
    'Ref: Refund Failed': 'Le remboursement a échoué',
    'Ref: Payment Processing': 'Traitement des paiements',
    'Ref: Not Paid': 'Impayé',
    'Ref: Total:': 'Total:',
    'Ref: Pickup': 'Récupérer',
    'Ref: Eat-in': 'Faire un repas',
    'Ref: Scan&Go': 'Scan et aller',
    'Ref: Fulfillment Type': 'Type de réalisation',
    'Ref: Delivery': 'Livraison',
    'Ref: You have no future orders': "Vous n'avez pas de commandes futures",
    'Ref: You have no past orders': "Vous n'avez pas de commandes passées",
    'Ref: You have no orders': "Vous n'avez pas de commandes",
    'Ref: All orders': 'Toutes les commandes',
    'Ref: Not past orders': 'Commandes à venir',
    'Ref: Past orders': 'Commandes passées',
    'Ref: Vegan': 'Végétalien',
    'Ref: Vegeterian': 'Végétarien',
    'Ref: See item': "Voir l'article",
    'Ref: Activated': 'Activé',
    'Ref: No suggestions': 'Aucune suggestion trouvée',
    'Ref: Get your receipt': 'Obtenez votre reçu',
    'Ref: Print': 'Imprimez votre reçu',
    'Ref: Email': 'Recevez votre reçu par e-mail',
    'Ref: Receipt Modal message': 'Comment aimeriez-vous obtenir votre reçu?',
    'Ref: Confirm': 'Confirmer',
    'Ref: Your email address': 'Votre adresse e-mail',
    Suggestion: 'Suggestion',
    'Ref: Loyalty Reward': 'Récompense de fidélité',
    'Ref: Equation for stamps per item': '1 achat = 1 timbre',
    'Ref: Paid by employer': 'Le montant payé par votre employeur',
    'Ref: Paid by user': 'Le montant payé par vous',
  },
  ProductsList: {
    'Ref: Page title': 'Liste de produits',
    'Ref: Facility details': 'Informations sur le point de vente',
    'Ref: Search products list': 'Rechercher des produits',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Catégorie',
    'Ref: all': 'toutes',
    'Ref: today': "Aujourd'hui",
    'Ref: tomorrow': 'Demain',
    'Ref: see more': 'Plus',
    'Ref: see less': 'Voir moins',
    'Ref: reset': 'Réinitialiser',
    'Ref: View in cart': 'Voir le panier',
    'Ref: You have changed location': "Vous avez changé l'emplacement",
    'Ref: You are now at': 'Vous êtes maintenant à',
    'Ref: Menu card': 'La carte du menu',
    'Ref: Table': 'Numéro de table',
    'Service unavailable': 'Service indisponible',
    'Ref: Ordering unavailable':
      "Attention! La commande sur ce site n'est pas disponible pour le moment, veuillez essayer plus tard.",
    'Ref: Filters': 'Filtres (allergènes et plus)',
  },
  ProductScanner: {
    'Ref: cart': 'Panier',
    'Ref: Product Scanned':
      'a été ajouté à Cart. Vous pouvez continuer à scanner les codes à barres pour les ajouter dans votre panier.',
    'Ref: Scan or adjust': 'a été ajouté à Cart. Ajustez la quantité ou scannez un autre produit.',
    'Ref: Product Not Matched': "Le code-barres n'a pas été reconnu. Veuillez réessayer",
    'Ref: Scan article code': 'Numéro de code-barres',
    'Ref: Native error product cannot be added to cart':
      "L'article de différent menu ou pièce de jour ne peut pas être ajouté au chariot",
    'Ref: product scanner placeholder':
      "Veuillez scanner le code-barres pour l'ajouter à votre panier",
  },
  Order: { 'Ref: Page title': 'Commande' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menus',
    'Ref: today': "Aujourd'hui",
    'Ref: tomorrow': 'Demain',
    'Ref: Search products list': 'Articles de recherche',
    'Ref: You have changed location': "Vous avez changé l'emplacement",
    'Ref: You are now at': 'Vous êtes maintenant à',
  },
  ProductDetails: {
    'Ref: Page title': 'détails du produit',
    'Ref: Serving Size': 'Portion',
    'Ref: Nutrition': 'Informations nutritionnelles',
    'Ref: Nutrition adults': "Les adultes ont besoin d'environ 2000 kcal par jour",
    'Ref: Nutrition Calories': 'Calories',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Lipides',
    'Ref: Nutrition Saturated Fat': 'Gras saturé',
    'Ref: Nutrition Carbohydrates': 'Glucides',
    'Ref: Nutrition Sugar': 'Sucre',
    'Ref: Nutrition Protein': 'Protéines',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Cholestérol',
    'Ref: Nutrition dietaryFiber': 'Fibre alimentaire',
    'Ref: Nutrition iron': 'Fer',
    'Ref: Nutrition potassium': 'Potassium',
    'Ref: Nutrition vitaminA': 'Vitamine A',
    'Ref: Nutrition vitaminC': 'Vitamine C',
    'Ref: Additives': 'Additifs',
    'Ref: Emission': 'Emissions de CO2',
    'Ref: This product generates': 'Ce produit génère',
    'Ref: of CO2': 'de CO2',
    'Ref: emissions, which equals a': 'émissions, ce qui équivaut à',
    'Ref: car trip': 'voyage en voiture',
    'Ref: Ingredients': 'Ingrédient de la recette',
    'Ref: Allergens': 'Allergènes',
    'Ref: Always there': 'Toujours là',
    'Ref: Allergens selected': 'allergènes sélectionnés sur le filtre',
    'Ref: minimum required': 'minimum requis',
    'Ref: you have to add at least': 'Vous devez ajouter au moins',
    'Ref: ingredients': 'Ingrédients',
    'Ref: Ingredient': 'ingrédient',
    'Ref: Free': 'Gratuit',
    'Ref: Based on customization': 'En fonction de votre personnalisation',
    'Ref: Additives eggs': 'des œufs',
    'Ref: Additives fish': 'poisson',
    'Ref: Additives milk': 'Le Lait',
    'Ref: Additives nuts': 'des noisettes',
    'Ref: Additives wheat': 'du blé',
    'Ref: Additives peanuts': 'cacahuètes',
    'Ref: Additives soy beans': 'soja',
    'Ref: Additives sesame seeds': 'graines de sésame',
    'Ref: Review': 'La revue',
    'Ref: Per portion': 'Par portion',
    'Ref: Per 100g': 'Par 100g',
    'Ref: Allergen selected': 'Allergène sélectionné sur le filtre de recherche',
    'Ref: Fitbit Log': 'Journal sur fitbit',
    'Ref: age verification required on delivery': "Vérification de l'âge requise à la livraison",
    'Ref: alcohol content notification':
      "En plaçant et en commande pour l'un de ces articles, vous déclarez que vous avez 18 ans ou plus. L'abus d'alcool est dangereux pour votre santé.",
    'Ref: default': 'Défaut',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} gagné si vous commandez ce produit',
    'Ref: Loyalty Card Description':
      'Le {stamp_form} sera ajouté à la fidélité suivante {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Le programme de timbres de fidélité vous permet de collecter des timbres pour les récompenses.',
    'Ref: Loyalty Modal Description':
      'Lorsque vous achetez <b> {product_name}, 1 timbre </b> sera ajouté au <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'et',
    'Ref: Stamp': 'Timbre',
    'Ref: Stamps': 'Timbres',
    'Ref: Program': 'Programme',
    'Ref: Programs': 'Programmes',
    'Ref: Loyalty Stamps': 'Timbres de fidélité',
    'Ref: See Loyalty Stamps': 'Voir les timbres de fidélité',
    Favorite: 'Préféré',
    'Ref: In favorites':
      'Le produit est déjà dans la liste des favoris. Cliquez pour le supprimer.',
    'Ref: Not in favorites':
      "Le produit n'est pas encore dans la liste des favoris. Cliquez pour l'ajouter.",
    'Ref: Added to favorites': 'Le produit a été ajouté à la liste des favoris.',
    'Ref: Removed from favorites': 'Le produit a été supprimé de la liste des favoris.',
    'Ref: From': 'De',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Changement dans le panier',
    'Ref: Popup header for customizable item qty update': 'Cet article est personnalisable',
    'Ref: add new version': 'Ajouter une nouvelle version',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      "Mettez à jour la quantité d'un élément personnalisable directement à partir de votre panier.",
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      "Mettez à jour la quantité d'un élément personnalisable directement à partir de votre panier ou ajoutez une nouvelle version.",
    'Ref: another context body':
      'Pour ajouter des éléments de ce site, de ce point de vente, de ce menu ou de ce moment, vous devez vider le panier',
    'Ref: cancel': 'Annuler',
    'Ref: add to cart': 'Ajouter au panier',
    'Ref: view in cart': 'Panier',
    'Ref: Products in cart': 'Nombre de {product_name} dans le panier: {product_quantity}',
    'Ref: Customize': 'Personnaliser',
    'Ref: Add': 'Ajouter',
    'Ref: Free': 'Gratuit',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Annuler',
    'Ref: notice': 'Avis',
    'Ref: clear cart': 'Vider le panier',
    'Ref: Cart addition error message':
      'Actuellement, vous avez des éléments dans le panier de {from_current}. Pour ajouter des éléments de {from_selected}, vous devez effacer le chariot',
  },
  Cart: {
    'Ref: Page title': 'Panier',
    'Ref: The cart is empty': 'Le panier est vide',
    'Ref: Clear cart': 'Vider le panier',
    'Ref: Continue shopping': 'Continuer vos achats',
    'Ref: Start shopping': 'Commencer à faire du shopping',
    'Ref: Today': "Aujourd'hui",
    'Ref: Tomorrow': 'Demain',
    'Ref: Tax:': 'Taxes:',
    'Ref: Promotion:': 'Promotion:',
    'Ref: Total without promotion:': 'Total sans promotion:',
    'Ref: Delivery:': 'Livraison:',
    'Ref: Pickup informations': 'Informations de collecte',
    'Ref: Location': 'Emplacement',
    'Ref: Time': 'Temps',
    'Ref: Phone': 'Votre numéro de téléphone',
    'Ref: Instructions': 'Instructions',
    'Ref: Please select': 'Veuillez sélectionner',
    'Ref: Enter special requests': 'Entrez les demandes spéciales ici',
    'Ref: notice': 'Avis',
    'Ref: Product is no more available':
      'Désolé mais il semble que certains produits ne soient plus disponibles. Veuillez vérifier votre panier.',
    'Ref: ok': "D'accord",
    'Ref: Validate': 'Valider ma commande',
    'Ref: Pay now': 'Payez maintenant',
    'Ref: Charge to my department': 'Charger à mon département',
    'Ref: Pay on pickup': 'Payer ces articles sur ramassage',
    'Ref: Accept terms of sale prefix': "J'accepte",
    'Ref: Accept terms of sale': 'Ces termes de vente',
    'Ref: Continue': 'Continuer',
    'Ref: Please login or sign up to place an order':
      'Veuillez vous connecter ou vous inscrire pour passer une commande',
    'Ref: Accept terms of sale suffix': ', et je les ai lus et compris.',
    'Ref: Payment modality': 'Mode de paiement',
    'Ref: Other information': 'Les autres informations',
    'Ref: Eat-in': 'Manger',
    'Ref: Pickup': 'Collecter',
    'Ref: Table': 'Numéro de table',
    'Ref: Required fields': 'Champs obligatoires',
    'Service unavailable': 'Service indisponible',
    'Ref: Cannot order':
      "Désolé, vous ne pouvez pas passer de commande sur ce site pour le moment. Veuillez contacter le restaurant pour plus d'informations sur les heures d'ouverture et les services disponibles.",
    'Ref: Order Unavailable':
      "La commande pour la date choisie, le type de réalisation et l'emplacement ne sont plus disponibles!",
    'Ref: No Availability': 'Plus de disponibilité',
    'Ref: Continue on your device': 'Continuez sur votre appareil',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} gagné si vous terminez votre commande',
    'Ref: Loyalty Card Description':
      'Le {stamp_form} sera ajouté à la fidélité suivante {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Le programme de timbres de fidélité vous permet de collecter des timbres pour les récompenses.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> Géré si vous terminez votre commande:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> ajouté pour <b> {scheme_names} </b>',
    'Ref: Stamp': 'Timbre',
    'Ref: Stamps': 'Timbres',
    'Ref: Program': 'Programme',
    'Ref: Programs': 'Programmes',
    'Ref: Loyalty Stamps': 'Timbres de fidélité',
    'Ref: See Loyalty Stamps': 'Voir les timbres de fidélité',
    'Ref: Loyalty Info': 'Informations de fidélité',
    'Ref: Email address': 'Adresse e-mail',
    'Ref: and': 'et',
    'Ref: Order minimum amount': 'Désolé, le montant minimum de paiement est {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Continuez sur votre appareil',
    'Ref: Scan QR to continue':
      'Veuillez numériser le code QR pour continuer le voyage sur votre appareil.',
  },
  OrderHistory: { 'Ref: Orders': 'Ordres', 'Ref: Page Title': 'Mes commandes' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Prix ​​unitaire',
    'Ref: Download': 'Téléchargez le reçu',
    'Ref: Download error':
      "Une erreur de téléchargement s'est produite. Essayez de télécharger le reçu plus tard",
    'Ref: Order details title': 'détails de la commande',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'Merci! Votre commande est maintenant confirmée',
    'Ref: Text for pickup':
      "Vous pouvez suivre son statut via l'historique de votre commande. Si vos notifications sont activées, nous vous mettrons à jour lorsqu'il sera prêt pour le ramassage.",
    'Ref: Success Order Title': 'Nous vous remercions de votre commande',
    'Ref: Success Order Description': 'Veuillez payer à la caisse pour valider votre commande.',
    'Ref: Success Order ID': "Commande d'identité",
    'Ref: Success Order Action': 'Nouvel ordre',
    'Ref: Text for dinein':
      'Votre commande doit être servie bientôt à votre table. Vous pouvez vous adresser à un membre du personnel',
    'Ref: Text for scan&go': "Votre commande est disponible dans l'historique de votre commande",
    'Ref: Text for delivery':
      "Vous pouvez suivre son statut dans l'historique de votre commande. Si vos notifications sont activées, nous vous informerons lorsque votre commande sera prête.",
    'See details': 'Voir les détails',
    'Go to home': "Retour à l'accueil",
  },
  FailOrder: {
    'Go to home': "Retour à l'accueil",
    'Ref: Kiosk Fail Title': "Oops! Quelque chose s'est mal passé!",
    'Ref: Kiosk Fail Description':
      "Malheureusement, votre commande n'a pas réussi. Veuillez réessayer ou demander à un membre du personnel de vous aider.",
    'Ref: Kiosk Fail Back': 'retour à la page précédente',
    'Ref: Kiosk Fail Action': 'Effacer la session et commander à nouveau',
    'Ref: Error in cart validation':
      "Une erreur s'est produite pendant votre commande. Veuillez contacter le point de vente pour résoudre le problème.",
    'Facility contact': 'Contact du point de vente',
    'Ref: Ordering failed': 'Échec de la commande',
    'Ref: Order confirmed': 'Commande confirmée',
  },
  TableCheckIn: {
    'Welcome at': 'Bienvenue à',
    Table: 'Tableau:',
    'Ref: list title': 'Commandes récentes du jour',
    'See the menu': 'Voir le menu',
    'Table Check-in': 'Enregistrement de la table',
    'Ref #': 'Réf #',
    Checked: 'Vérifié',
    'Check in': 'Enregistrement',
    'See details': 'Voir les détails',
    'Ref: You have changed location': "Vous avez changé l'emplacement",
    'Ref: You are now at': 'Vous êtes maintenant à',
    'Order number': 'Numéro de commande',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promotion appliquée',
    'Ref: Promotion applied description': "Vous bénéficiez d'une promotion sur votre panier.",
    'Ref: Promotion deleted title': 'Promotion supprimée',
    'Ref: Promotion deleted description':
      'Vous ne profitez plus de la promotion car vous ne remplissez plus les conditions ou la promotion a expirée.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Scan et aller', 'Ref: View only': 'Voir uniquement' },
  ProductTile: {
    'Ref: Allergens': 'Allergènes',
    'Ref: Presence of allergen': "Présence d'allergènes",
    'Ref: Scan': 'Analyse',
    'Ref: From': 'De',
    'Ref: Already in product favorite list. Click to remove it.':
      'Déjà dans la liste préférée du produit. Cliquez pour le supprimer.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      "Actuellement non ajouté à la liste préférée du produit. Cliquez pour l'ajouter.",
    'Ref: Free': 'Gratuit',
  },
  OrderSummary: {
    'Ref: item': 'Objet',
    'Ref: items': 'éléments',
    'Ref: Clear': 'Dégager',
    'Ref: Cart': 'Panier',
    'Ref: Subtotal': 'Total',
    'Ref: Total': 'Total',
    'Ref: Tax': 'Taxe',
    'Ref: Promotion': 'Promotion',
    'Ref: Total without promotion': 'Total sans promotion',
    'Ref: Number of product in cart': 'Nombre de produits dans le panier',
    'Ref: Delivery': 'Livraison',
  },
  CartItemTile: { 'Ref: Vegan': 'Végétalien', 'Ref: Vegeterian': 'Végétarien' },
  OrderInfoCard: {
    'Ref: Header': 'Commandé pour',
    'Ref: from': 'de',
    'Ref: location': 'Emplacement',
    'Ref: Phone': 'Votre numéro de téléphone',
    'Ref: Instructions': 'Des instructions',
    'Ref: Order type': 'Type de commande',
    'Ref: Pickup': 'Récupérer',
    'Ref: Eat-in': 'Faire un repas',
    'Ref: Scan&Go': 'Scan et aller',
  },
  ReorderWidget: {
    'Ref: No items': "Aucune commande n'est pas encore passée. Achetez maintenant!",
    'Ref: Title': 'Commander à nouveau',
  },
  MenusWidget: {
    'Ref: no menu': 'Pas de menu pour le moment',
    'Ref: Modal title': 'Menu pour la journée',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Remises disponibles' },
  PromotionInfoItem: { 'Ref: Activate': 'Activer' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Vos commandes', 'Ref: Widget Title': 'Mes commandes' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Suggestion pour la journée',
    'Ref: Any menu': "N'importe quel menu",
    Other: 'Autre',
    'Ref: Suggestions calendar button': 'Calendrier des suggestions',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Portion',
    'Ref: Nutrition': 'Informations nutritionnelles',
    'Ref: Nutrition adults': "Les adultes ont besoin d'environ 2000 kcal par jour",
    'Ref: Nutrition Calories': 'Calories',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Lipides',
    'Ref: Nutrition Saturated Fat': 'Gras saturé',
    'Ref: Nutrition Carbohydrates': 'Glucides',
    'Ref: Nutrition Sugar': 'Sucre',
    'Ref: Nutrition Protein': 'Protéines',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Cholestérol',
    'Ref: Nutrition dietaryFiber': 'Fibre alimentaire',
    'Ref: Nutrition iron': 'Fer',
    'Ref: Nutrition potassium': 'Potassium',
    'Ref: Nutrition vitaminA': 'Vitamine A',
    'Ref: Nutrition vitaminC': 'Vitamine C',
    'Ref: Ingredients': 'Ingrédient de la recette',
    'Ref: Allergens': 'Allergènes',
    'Ref: Always there': 'Toujours là',
    'Ref: Allergens selected': 'allergènes sélectionnés sur le filtre',
    'Ref: minimum required': 'minimum requis',
    'Ref: ingredients': 'Ingrédients',
    'Ref: Ingredient': 'ingrédient',
    'Ref: Free': 'Gratuit',
    'Ref: Based on customization': 'En fonction de votre personnalisation',
    'Ref: Additives eggs': 'des œufs',
    'Ref: Additives fish': 'poisson',
    'Ref: Additives milk': 'Le Lait',
    'Ref: Additives nuts': 'des noisettes',
    'Ref: Additives wheat': 'du blé',
    'Ref: Additives peanuts': 'cacahuètes',
    'Ref: Additives soy beans': 'soja',
    'Ref: Additives sesame seeds': 'graines de sésame',
    'Ref: Review': 'La revue',
    'Ref: Per portion': 'Par portion',
    'Ref: Per 100g': 'Par 100g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} gratuitement',
    'Ref: Number of free items': "Nombre d'articles gratuits: {number_of_free_items}",
    'Ref: Allergen selected': 'Allergène sélectionné sur le filtre de recherche',
    'Ref: Nutritional info': 'Informations nutritionnelles',
    'Ref: Info': 'Info',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Promotions' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Choisissez votre travail',
    'Ref: Validate': 'Valider',
    'Ref: Pricing Tiers dialog message': 'Vous avez été reconnu avec votre adresse e-mail',
    'Ref: Please select your job in the company':
      "Veuillez sélectionner votre emploi dans l'entreprise",
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Récupérer',
    'Ref: Eat-in': 'Faire un repas',
    'Ref: Scan&Go': 'Scan et aller',
    'Ref: Delivery': 'Livraison',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Comment était le service {facility_name}}?',
    'Ref: How do you rate the service': 'Comment évaluez-vous le service?',
  },
};
export default labels;
