const labels = {
  All: { 'Ref: stamp progress': '다음 스탬프를 향해 진행', Reward: '보상', Rewards: '보상' },
  LoyaltyStamps: {
    'Ref: Page title': '우표',
    'Ref: Fulfill conditions': '보상을 상환하기위한 우표를 얻으십시오!',
    'See Reward': '보상을 참조하십시오',
    'Ref: No reward': '지금은 보상이 없습니다',
  },
  RedeemableProducts: { 'Ref: Page title': '충성도 보상' },
};
export default labels;
