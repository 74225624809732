const labels = {
  All: { 'Ref: Page title': 'Pemasok' },
  SuppliersList: {
    'Ref: Supplier details': 'Lihat detailnya',
    'Missing supplier list': 'Kami tidak menemukan pemasok.',
    'Try again later': 'Coba lagi nanti',
  },
  SupplierDetails: {
    'Address label': 'Alamat:',
    'Website label': 'Situs web:',
    'Details label': 'Detail',
  },
};
export default labels;
