const mockData = {
  getStamps: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              stampsCollected: 2,
              stampsToCollectReward: 2,
              isRewardAvailable: true,
            }),
          1000
        )
      );
    },
  },
  scanQRCode: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              stampsCollected: 2,
              stampsToCollectReward: 2,
              isRewardAvailable: true,
            }),
          1000
        )
      );
    },
  },
  redeemStamps: {
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              stampsCollected: 0,
              stampsToCollectReward: 2,
              isRewardAvailable: false,
            }),
          1000
        )
      );
    },
  },
};

export default mockData;
