import momentjs from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BarcodeIcon } from '../../../assets/icons';
import CartItemTile from '../containers/CartItemTile';
import { isCartItemLoyaltyReward, isCartItemProvidingLoyaltyStamps } from '../helpers/cart.helper';
import { PageType } from '../types/menuCartActions.types';
import { CartMenuItem, StateWithOrder } from '../types/orderState.types';

import useSite from '@/modules/Core/hooks/useSite';
import { useLazyGetLoyaltySchemesQuery } from '@/modules/LoyaltyStamps/api';
import { GetLoyaltySchemesResponse } from '@/modules/LoyaltyStamps/api/api.types';

import styles from './ScannerProductDetail.module.css';

const ScannerProductDetail = ({
  cartProductId,
  menuId,
  date,
  labelPlaceholder,
}: {
  cartProductId: number | null;
  menuId: number;
  date: Date;
  labelPlaceholder: string;
}) => {
  const [currentCartProduct, setCurrentCartProduct] = useState<CartMenuItem>();
  const [schemes, setSchemes] = useState<GetLoyaltySchemesResponse[] | undefined>(undefined);

  const [getLoyaltySchemes, { isLoading }] = useLazyGetLoyaltySchemesQuery();
  const { id: siteId, currency } = useSite({ throwWhenNoActiveSite: true })!;
  const { cart, menus } = useSelector((state: StateWithOrder) => state.Order);

  const cartItem = cart?.menuPortionItems?.find((item) => item.uomId === cartProductId);
  const menu = menus?.find((menu) => menu.id === menuId);

  const fetchData = useCallback(async () => {
    const response = await getLoyaltySchemes({ siteId, useErrorBoundary: false });
    setSchemes(response.data);
  }, [getLoyaltySchemes, siteId]);

  useEffect(() => {
    if (cartItem) {
      fetchData();
      setCurrentCartProduct(cartItem);
    }
  }, [cart, cartItem, cartProductId, fetchData]);

  if (!currentCartProduct || !menu || !currency?.isoCode || !cartItem) {
    return (
      <div className={styles.productDetailPlaceholder}>
        <BarcodeIcon />
        <p>{labelPlaceholder}</p>
      </div>
    );
  }

  const props = {
    ...currentCartProduct,
    description: currentCartProduct.modifiersDisplayText || currentCartProduct.description,
    menuId: menu.id,
    date: momentjs(date).format('YYYY-MM-DD'),
    pageType: PageType.scanner,
    isLoyaltyReward: isCartItemLoyaltyReward(cartItem, menu?.id, schemes),
    isProvidingLoyaltyStamps: isCartItemProvidingLoyaltyStamps(cartItem, menu?.id, schemes),
  };

  return (
    <div className="d-grid">
      {cartItem && !isLoading && <CartItemTile key={currentCartProduct.id} {...props} />}
    </div>
  );
};

export default ScannerProductDetail;
