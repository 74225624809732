const labels = {
  ReviewForm: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: How was dining with us': 'Ref: How was dining with us',
    'Ref: Submit': 'Ref: Submit',
    'Ref: Filter range': 'Ref: Filter range',
    'Ref: Some information is missing': 'Ref: Some information is missing',
  },
  EmailAnonymousToggler: {
    'Ref: Send anonymously': 'Ref: Send anonymously',
    'Ref: Your email address': 'Ref: Your email address',
    'Ref: Email field placeholder': 'Ref: Email field placeholder',
    'Ref: Invalid email format': 'Ref: Invalid email format',
  },
  SuccessPage: {
    'Ref: Body': 'Ref: Body',
    'Ref: Go to Feedback': 'Ref: Go to Feedback',
    'Ref: Go to home': 'Ref: Go to home',
    'Ref: Go to Facilities': 'Ref: Go to Facilities',
    'Ref: Go to Product Details': 'Ref: Go to Product Details',
    'Ref: Go to Facility Details': 'Ref: Go to Facility Details',
  },
  FacilityPicker: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Search facility name': 'Ref: Search facility name',
  },
};
export default labels;
