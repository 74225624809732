import moment from 'moment';

import { DATE_FORMAT } from '../../../constants';
import { suggestionWidgetDateCacheKey } from '../config';

import { getSuggestionWidgetMenuDate } from './suggestionsWidget.helper';

const siteId = '123';

describe('getSuggestionWidgetMenuDate', () => {
  beforeEach(() => {
    localStorage.removeItem(`${suggestionWidgetDateCacheKey}_${siteId}`);
  });

  it('should return cached date if it is not from the past', () => {
    const futureDate = moment().add(1, 'day').format(DATE_FORMAT);
    localStorage.setItem(`${suggestionWidgetDateCacheKey}_${siteId}`, futureDate);

    const result = getSuggestionWidgetMenuDate(siteId);

    expect(result).toEqual(futureDate);
  });

  it('should return current date if there is no cached date', () => {
    const result = getSuggestionWidgetMenuDate(siteId);

    expect(result).toEqual(moment().format(DATE_FORMAT));
  });

  it('should return current date if cached date is from the past', () => {
    const pastDate = moment().subtract(2, 'day').format(DATE_FORMAT);
    localStorage.setItem(`${suggestionWidgetDateCacheKey}_${siteId}`, pastDate);

    const result = getSuggestionWidgetMenuDate(siteId);

    expect(result).toEqual(moment().format(DATE_FORMAT));
  });
});
