const labels = {
  All: {
    'Ref: Assembled': 'ล้อม',
    'Ref: Submitted': 'ส่ง',
    'Ref: Fulfilled': 'สำเร็จ',
    'Ref: Completed': 'สมบูรณ์',
    'Ref: Paid': 'จ่าย',
    'Ref: Approved': 'ที่ได้รับการอนุมัติ',
    'Ref: Ready For Pickup': 'พร้อมสำหรับรถกระบะ',
    'Ref: Delivered': 'ส่ง',
    'Ref: Received': 'ได้รับ',
    'Ref: Rejected': 'ถูกปฏิเสธ',
    'Ref: Payment Error': 'ข้อผิดพลาดในการชำระเงิน',
    'Ref: Pending Payment': 'ที่รอดำเนินการชำระเงิน',
    'Ref: Preparing': 'การเตรียมความพร้อม',
    'Ref: Refunded': 'ได้รับเงินคืน',
    'Ref: Cancelled By User': 'ยกเลิกโดยผู้ใช้',
    'Ref: Refund Requested': 'ขอคืนเงิน',
    'Ref: Refund Failed': 'การคืนเงินล้มเหลว',
    'Ref: Payment Processing': 'การประมวลผลการชำระเงิน',
    'Ref: Not Paid': 'ไม่จ่ายเงิน',
    'Ref: Total:': 'ทั้งหมด:',
    'Ref: Pickup': 'ไปรับ',
    'Ref: Eat-in': 'กิน',
    'Ref: Scan&Go': 'สแกนและไป',
    'Ref: Fulfillment Type': 'ประเภทการปฏิบัติตาม',
    'Ref: Delivery': 'จัดส่ง',
    'Ref: You have no future orders': 'คุณไม่มีคำสั่งซื้อในอนาคต',
    'Ref: You have no past orders': 'คุณไม่มีคำสั่งที่ผ่านมา',
    'Ref: You have no orders': 'คุณไม่มีคำสั่งซื้อ',
    'Ref: All orders': 'คำสั่งซื้อทั้งหมด',
    'Ref: Not past orders': 'คำสั่งซื้อที่กำลังจะมา',
    'Ref: Past orders': 'คำสั่งที่ผ่านมา',
    'Ref: Vegan': 'มังสวิรัติ',
    'Ref: Vegeterian': 'มังสวิรัติ',
    'Ref: See item': 'ดูรายการ',
    'Ref: Activated': 'ที่เปิดใช้งาน',
    'Ref: No suggestions': 'ไม่พบคำแนะนำ',
    'Ref: Get your receipt': 'รับใบเสร็จของคุณ',
    'Ref: Print': 'พิมพ์ใบเสร็จของคุณ',
    'Ref: Email': 'รับใบเสร็จรับเงินของคุณทางอีเมล',
    'Ref: Receipt Modal message': 'คุณต้องการรับใบเสร็จของคุณอย่างไร?',
    'Ref: Confirm': 'ยืนยัน',
    'Ref: Your email address': 'ที่อยู่อีเมลของคุณ',
    Suggestion: 'คำแนะนำ',
    'Ref: Loyalty Reward': 'รางวัลความภักดี',
    'Ref: Equation for stamps per item': '1 ซื้อ = 1 แสตมป์',
    'Ref: Paid by employer': 'จำนวนเงินที่นายจ้างจ่าย',
    'Ref: Paid by user': 'จำนวนเงินที่คุณจ่าย',
  },
  ProductsList: {
    'Ref: Page title': 'รายการสินค้า',
    'Ref: Facility details': 'รายละเอียดสิ่งอำนวยความสะดวก',
    'Ref: Search products list': 'รายการค้นหา',
    'Ref: filter label: menus': 'เมนู',
    'Ref: filter label: moment': 'ช่วงเวลา',
    'Ref: filter label: category': 'หมวดหมู่',
    'Ref: all': 'ทั้งหมด',
    'Ref: today': 'ในวันนี้',
    'Ref: tomorrow': 'วันพรุ่งนี้',
    'Ref: see more': 'ดูเพิ่มเติม',
    'Ref: see less': 'ดูน้อยลง',
    'Ref: reset': 'รีเซ็ต',
    'Ref: View in cart': 'ดูในรถเข็น',
    'Ref: You have changed location': 'คุณเปลี่ยนตำแหน่ง',
    'Ref: You are now at': 'ตอนนี้คุณอยู่ที่',
    'Ref: Menu card': 'การ์ดเมนู',
    'Ref: Table': 'หมายเลขตาราง',
    'Service unavailable': 'ไม่สามารถให้บริการได้',
    'Ref: Ordering unavailable':
      'ระมัดระวัง! การสั่งซื้อที่ไซต์นี้ไม่สามารถใช้งานได้ในขณะนี้โปรดลองในภายหลัง',
    'Ref: Filters': 'ตัวกรอง (สารก่อภูมิแพ้และอื่น ๆ )',
  },
  ProductScanner: {
    'Ref: cart': 'รถเข็น',
    'Ref: Product Scanned': 'ถูกเพิ่มเข้าไปในรถเข็น คุณสามารถสแกนบาร์โค้ดเพื่อเพิ่มในรถเข็นของคุณ',
    'Ref: Scan or adjust': 'ถูกเพิ่มเข้าไปในรถเข็น ปรับปริมาณหรือสแกนผลิตภัณฑ์อื่น',
    'Ref: Product Not Matched': 'ไม่รู้จักบาร์โค้ด กรุณาลองอีกครั้ง',
    'Ref: Scan article code': 'สแกนบาร์โค้ด',
    'Ref: Native error product cannot be added to cart':
      'รายการจากเมนูหรือส่วนวันที่แตกต่างกันไม่สามารถเพิ่มลงในรถเข็น',
    'Ref: product scanner placeholder': 'กรุณาสแกนบาร์โค้ดเพื่อเพิ่มลงในรถเข็นของคุณ',
  },
  Order: { 'Ref: Page title': 'คำสั่ง' },
  MenuSelector: {
    'Ref: Menu Page title': 'เมนู',
    'Ref: today': 'ในวันนี้',
    'Ref: tomorrow': 'วันพรุ่งนี้',
    'Ref: Search products list': 'รายการค้นหา',
    'Ref: You have changed location': 'คุณเปลี่ยนตำแหน่ง',
    'Ref: You are now at': 'ตอนนี้คุณอยู่ที่',
  },
  ProductDetails: {
    'Ref: Page title': 'รายละเอียดสินค้า',
    'Ref: Serving Size': 'ขนาดที่ให้บริการ',
    'Ref: Nutrition': 'ข้อมูลทางโภชนาการ',
    'Ref: Nutrition adults': 'ผู้ใหญ่ต้องการเวลาประมาณ 2,000 kcal ต่อวัน',
    'Ref: Nutrition Calories': 'แคลอรี่',
    'Ref: Nutrition Calcium': 'แคลเซียม',
    'Ref: Nutrition Fat': 'อ้วน',
    'Ref: Nutrition Saturated Fat': 'ไขมันอิ่มตัว',
    'Ref: Nutrition Carbohydrates': 'คาร์โบไฮเดรต',
    'Ref: Nutrition Sugar': 'น้ำตาล',
    'Ref: Nutrition Protein': 'โปรตีน',
    'Ref: Nutrition Sodium': 'โซเดียม',
    'Ref: Nutrition cholesterol': 'คอเลสเตอรอล',
    'Ref: Nutrition dietaryFiber': 'เส้นใยอาหาร',
    'Ref: Nutrition iron': 'เหล็ก',
    'Ref: Nutrition potassium': 'โพแทสเซียม',
    'Ref: Nutrition vitaminA': 'วิตามินเอ',
    'Ref: Nutrition vitaminC': 'วิตามินซี',
    'Ref: Additives': 'สารเติมแต่ง',
    'Ref: Emission': 'การปล่อย CO2',
    'Ref: This product generates': 'ผลิตภัณฑ์นี้สร้างขึ้น',
    'Ref: of CO2': 'ของ co2',
    'Ref: emissions, which equals a': 'การปล่อยมลพิษซึ่งเท่ากับ',
    'Ref: car trip': 'รถเดินทาง',
    'Ref: Ingredients': 'สูตรส่วนผสม',
    'Ref: Allergens': 'ภูมิแพ้',
    'Ref: Always there': 'ที่นั่นเสมอ',
    'Ref: Allergens selected': 'สารก่อภูมิแพ้ที่เลือกในตัวกรอง:',
    'Ref: minimum required': 'ต้องการขั้นต่ำ',
    'Ref: you have to add at least': 'คุณต้องเพิ่มอย่างน้อย',
    'Ref: ingredients': 'ส่วนผสม',
    'Ref: Ingredient': 'วัตถุดิบ',
    'Ref: Free': 'ฟรี',
    'Ref: Based on customization': 'ขึ้นอยู่กับการปรับแต่งของคุณ',
    'Ref: Additives eggs': 'ไข่',
    'Ref: Additives fish': 'ปลา',
    'Ref: Additives milk': 'นม',
    'Ref: Additives nuts': 'ถั่ว',
    'Ref: Additives wheat': 'ข้าวสาลี',
    'Ref: Additives peanuts': 'ถั่ว',
    'Ref: Additives soy beans': 'ถั่วเหลือง',
    'Ref: Additives sesame seeds': 'เมล็ดงา',
    'Ref: Review': 'ทบทวน',
    'Ref: Per portion': 'ต่อส่วน',
    'Ref: Per 100g': 'ต่อ 100 กรัม',
    'Ref: Allergen selected': 'สารก่อภูมิแพ้ที่เลือกบนตัวกรองการค้นหา',
    'Ref: Fitbit Log': 'บันทึกเพื่อ Fitbit',
    'Ref: age verification required on delivery': 'การตรวจสอบอายุที่จำเป็นในการจัดส่ง',
    'Ref: alcohol content notification':
      'โดยการวางและสั่งซื้อหนึ่งในรายการเหล่านี้คุณประกาศว่าคุณมีอายุ 18 ปีขึ้นไป การใช้แอลกอฮอล์ในทางที่ผิดเป็นอันตรายต่อสุขภาพของคุณ',
    'Ref: default': 'ค่าเริ่มต้น',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ได้รับถ้าคุณสั่งซื้อผลิตภัณฑ์นี้',
    'Ref: Loyalty Card Description':
      '{stamp_form} จะถูกเพิ่มลงในความภักดีต่อไปนี้ {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'โปรแกรมแสตมป์ความภักดีช่วยให้คุณสามารถรวบรวมแสตมป์เพื่อรับรางวัล',
    'Ref: Loyalty Modal Description':
      'เมื่อคุณซื้อ <b> {product_name}, 1 แสตมป์ </b> จะถูกเพิ่มลงใน <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'และ',
    'Ref: Stamp': 'ประทับ',
    'Ref: Stamps': 'แสตมป์',
    'Ref: Program': 'โปรแกรม',
    'Ref: Programs': 'โปรแกรม',
    'Ref: Loyalty Stamps': 'แสตมป์ความภักดี',
    'Ref: See Loyalty Stamps': 'ดูแสตมป์ความภักดี',
    Favorite: 'ที่ชื่นชอบ',
    'Ref: In favorites': 'ผลิตภัณฑ์อยู่ในรายการโปรดแล้ว คลิกเพื่อลบออก',
    'Ref: Not in favorites': 'ผลิตภัณฑ์ยังไม่ได้อยู่ในรายการโปรด คลิกเพื่อเพิ่ม',
    'Ref: Added to favorites': 'ผลิตภัณฑ์ได้รับการเพิ่มในรายการโปรด',
    'Ref: Removed from favorites': 'ผลิตภัณฑ์ถูกลบออกจากรายการโปรด',
  },
  MenuCartActions: {
    'Ref: change in cart': 'เปลี่ยนเกวียน',
    'Ref: Popup header for customizable item qty update': 'รายการนี้สามารถปรับแต่งได้',
    'Ref: add new version': 'เพิ่มเวอร์ชันใหม่',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'อัปเดตปริมาณของรายการที่ปรับแต่งได้โดยตรงจากรถเข็นของคุณ',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'อัปเดตปริมาณของรายการที่ปรับแต่งได้โดยตรงจากรถเข็นของคุณหรือเพิ่มเวอร์ชันใหม่',
    'Ref: another context body':
      'ในการเพิ่มรายการจากเว็บไซต์นี้สิ่งอำนวยความสะดวกช่วงเวลา/เมนูหรือวันที่ที่คุณต้องล้างรถเข็น',
    'Ref: cancel': 'ยกเลิก',
    'Ref: add to cart': 'เพิ่มลงในรถเข็น',
    'Ref: view in cart': 'รถเข็น',
    'Ref: Products in cart': 'จำนวน {product_name} ในรถเข็น: {product_quantity}',
    'Ref: Customize': 'ปรับแต่ง',
    'Ref: Add': 'เพิ่ม',
    'Ref: Free': 'ฟรี',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'ยกเลิก',
    'Ref: notice': 'สังเกต',
    'Ref: clear cart': 'เกวียนล้าง',
    'Ref: Cart addition error message':
      'ขณะนี้คุณมีรายการในรถเข็นจาก {from_current} ในการเพิ่มรายการจาก {from_selected} คุณต้องล้างรถเข็น',
  },
  Cart: {
    'Ref: Page title': 'รถเข็น',
    'Ref: The cart is empty': 'รถเข็นว่างเปล่า',
    'Ref: Clear cart': 'เกวียนล้าง',
    'Ref: Continue shopping': 'ช้อปปิ้งต่อไป',
    'Ref: Start shopping': 'เริ่มช้อปปิ้ง',
    'Ref: Today': 'วันนี้',
    'Ref: Tomorrow': 'พรุ่งนี้',
    'Ref: Tax:': 'ภาษี:',
    'Ref: Promotion:': 'การส่งเสริม:',
    'Ref: Total without promotion:': 'รวมโดยไม่มีโปรโมชั่น:',
    'Ref: Delivery:': 'จัดส่ง:',
    'Ref: Pickup informations': 'ข้อมูลรถกระบะ',
    'Ref: Location': 'ที่ตั้ง',
    'Ref: Time': 'เวลา',
    'Ref: Phone': 'หมายเลขโทรศัพท์ของคุณ',
    'Ref: Instructions': 'คำแนะนำ',
    'Ref: Please select': 'โปรดเลือก',
    'Ref: Enter special requests': 'ป้อนคำขอพิเศษที่นี่',
    'Ref: notice': 'สังเกต',
    'Ref: Product is no more available':
      'ขออภัย แต่ดูเหมือนว่าผลิตภัณฑ์บางอย่างจะไม่มีให้บริการอีกต่อไป โปรดตรวจสอบรถเข็นของคุณ',
    'Ref: ok': 'ตกลง',
    'Ref: Validate': 'ตรวจสอบคำสั่งซื้อของฉัน',
    'Ref: Pay now': 'จ่ายตอนนี้',
    'Ref: Charge to my department': 'เรียกเก็บเงินจากแผนกของฉัน',
    'Ref: Pay on pickup': 'ชำระเงินสำหรับรายการเหล่านี้เมื่อรับ',
    'Ref: Accept terms of sale prefix': 'ฉันยอมรับ',
    'Ref: Accept terms of sale': 'ข้อกำหนดการขายเหล่านี้',
    'Ref: Continue': 'ดำเนินการต่อ',
    'Ref: Please login or sign up to place an order': 'กรุณาเข้าสู่ระบบหรือลงทะเบียนเพื่อสั่งซื้อ',
    'Ref: Accept terms of sale suffix': 'และฉันได้อ่านและเข้าใจพวกเขา',
    'Ref: Payment modality': 'วิธีการชำระเงิน',
    'Ref: Other information': 'ข้อมูลอื่น ๆ',
    'Ref: Eat-in': 'กิน',
    'Ref: Pickup': 'ไปรับ',
    'Ref: Table': 'หมายเลขตาราง',
    'Ref: Required fields': 'ช่องที่ต้องเติม',
    'Service unavailable': 'ไม่สามารถให้บริการได้',
    'Ref: Cannot order':
      'ขออภัยคุณไม่สามารถสั่งซื้อได้ที่เว็บไซต์นี้ในขณะนี้ กรุณาติดต่อร้านอาหารสำหรับข้อมูลเพิ่มเติมเกี่ยวกับเวลาเปิดทำการและบริการที่มีอยู่',
    'Ref: Order Unavailable':
      'สั่งซื้อสำหรับวันที่ที่เลือกประเภทการปฏิบัติตามและสถานที่ไม่สามารถใช้ได้อีกต่อไป!',
    'Ref: No Availability': 'ไม่มีความพร้อมใช้งานอีกต่อไป',
    'Ref: Continue on your device': 'ดำเนินการต่อบนอุปกรณ์ของคุณ',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ได้รับถ้าคุณสั่งซื้อเสร็จสิ้น',
    'Ref: Loyalty Card Description':
      '{stamp_form} จะถูกเพิ่มลงในความภักดีต่อไปนี้ {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'โปรแกรมแสตมป์ความภักดีช่วยให้คุณสามารถรวบรวมแสตมป์เพื่อรับรางวัล',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> ได้รับถ้าคุณสั่งซื้อเสร็จ:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> เพิ่มสำหรับ <b> {scheme_names} </b>',
    'Ref: Stamp': 'ประทับ',
    'Ref: Stamps': 'แสตมป์',
    'Ref: Program': 'โปรแกรม',
    'Ref: Programs': 'โปรแกรม',
    'Ref: Loyalty Stamps': 'แสตมป์ความภักดี',
    'Ref: See Loyalty Stamps': 'ดูแสตมป์ความภักดี',
    'Ref: Loyalty Info': 'ข้อมูลความภักดี',
    'Ref: Email address': 'ที่อยู่อีเมล',
    'Ref: and': 'และ',
    'Ref: Order minimum amount': 'ขออภัยจำนวนเงินขั้นต่ำคือ {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'ดำเนินการต่อบนอุปกรณ์ของคุณ',
    'Ref: Scan QR to continue': 'กรุณาสแกนรหัส QR เพื่อเดินทางต่อบนอุปกรณ์ของคุณต่อไป',
  },
  OrderHistory: { 'Ref: Orders': 'คำสั่งซื้อ', 'Ref: Page Title': 'คำสั่งซื้อของฉัน' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'ราคาต่อหน่วย',
    'Ref: Download': 'ดาวน์โหลดใบเสร็จรับเงิน',
    'Ref: Download error': 'เกิดข้อผิดพลาดในการดาวน์โหลด ลองดาวน์โหลดใบเสร็จรับเงินในภายหลัง',
    'Ref: Order details title': 'รายละเอียดสั่งซื้อ',
  },
  SuccessOrder: {
    'Ref: Order confirmed': 'ขอขอบคุณ! คำสั่งซื้อของคุณได้รับการยืนยันแล้ว',
    'Ref: Text for pickup':
      'คุณสามารถติดตามสถานะของมันผ่านประวัติคำสั่งซื้อของคุณ หากเปิดการแจ้งเตือนของคุณเราจะอัปเดตคุณเมื่อพร้อมสำหรับการรับ',
    'Ref: Success Order Title': 'ขอขอบคุณสำหรับการสั่งซื้อของคุณ',
    'Ref: Success Order Description': 'โปรดชำระเงินที่เช็คเอาต์เพื่อตรวจสอบคำสั่งซื้อของคุณ',
    'Ref: Success Order ID': 'คำสั่งรหัส',
    'Ref: Success Order Action': 'คำสั่งใหม่',
    'Ref: Text for dinein':
      'คำสั่งซื้อของคุณควรเสิร์ฟบนโต๊ะของคุณเร็ว ๆ นี้ เพียงแค่ถามพนักงานที่เป็นมิตรของเราเกี่ยวกับการสั่งซื้อของคุณ',
    'Ref: Text for scan&go': 'คำสั่งซื้อของคุณมีอยู่ในประวัติคำสั่งซื้อของคุณ',
    'Ref: Text for delivery':
      'คุณสามารถติดตามสถานะของมันในประวัติคำสั่งซื้อของคุณ หากเปิดใช้งานการแจ้งเตือนของคุณเราจะแจ้งให้คุณทราบเมื่อคำสั่งซื้อของคุณพร้อม',
    'See details': 'ดูรายละเอียด',
    'Go to home': 'กลับบ้าน',
  },
  FailOrder: {
    'Go to home': 'กลับบ้าน',
    'Ref: Kiosk Fail Title': 'อ๊ะ! บางอย่างผิดพลาด!',
    'Ref: Kiosk Fail Description':
      'น่าเสียดายที่คำสั่งซื้อของคุณไม่ประสบความสำเร็จ โปรดลองอีกครั้งหรือขอให้พนักงานช่วยคุณ',
    'Ref: Kiosk Fail Back': 'กลับไปที่หน้าก่อนหน้า',
    'Ref: Kiosk Fail Action': 'ล้างเซสชันและสั่งซื้ออีกครั้ง',
    'Ref: Error in cart validation':
      'เกิดข้อผิดพลาดในระหว่างการสั่งซื้อของคุณ กรุณาติดต่อสิ่งอำนวยความสะดวกเพื่อแก้ไขปัญหา',
    'Facility contact': 'การติดต่อสิ่งอำนวยความสะดวก',
    'Ref: Ordering failed': 'การสั่งซื้อล้มเหลว',
    'Ref: Order confirmed': 'ได้รับการยืนยันการสั่งซื้อ',
  },
  TableCheckIn: {
    'Welcome at': 'ยินดีต้อนรับที่',
    Table: 'โต๊ะ:',
    'Ref: list title': 'คำสั่งซื้อล่าสุดสำหรับวันนี้',
    'See the menu': 'ดูเมนู',
    'Table Check-in': 'การเช็คอินตาราง',
    'Ref #': 'อ้างอิง #',
    Checked: 'ตรวจสอบแล้ว',
    'Check in': 'เช็คอิน',
    'See details': 'ดูรายละเอียด',
    'Ref: You have changed location': 'คุณเปลี่ยนตำแหน่ง',
    'Ref: You are now at': 'ตอนนี้คุณอยู่ที่',
    'Order number': 'หมายเลขสั่งซื้อ',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'ใช้โปรโมชั่น',
    'Ref: Promotion applied description': 'คุณได้รับประโยชน์จากการโปรโมตจากรถเข็นของคุณ',
    'Ref: Promotion deleted title': 'ลบโปรโมชั่น',
    'Ref: Promotion deleted description':
      'คุณจะไม่ได้รับประโยชน์จากการส่งเสริมการขายอีกต่อไปเพราะคุณไม่ได้เติมเต็มเงื่อนไขอีกต่อไปหรือการส่งเสริมการขายหมดอายุอีกต่อไป',
  },
  MenuTile: { 'Ref: Scan&Go': 'สแกนและไป', 'Ref: View only': 'สำหรับดูเท่านั้น' },
  ProductTile: {
    'Ref: Allergens': 'สารก่อภูมิแพ้',
    'Ref: Presence of allergen': 'การปรากฏตัวของสารก่อภูมิแพ้',
    'Ref: Scan': 'สแกน',
    'Ref: From': 'จาก',
    'Ref: Already in product favorite list. Click to remove it.':
      'อยู่ในรายการผลิตภัณฑ์โปรดของผลิตภัณฑ์แล้ว คลิกเพื่อลบออก',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'ปัจจุบันยังไม่ได้เพิ่มในรายการผลิตภัณฑ์โปรดของผลิตภัณฑ์ คลิกเพื่อเพิ่ม',
    'Ref: Free': 'ฟรี',
  },
  OrderSummary: {
    'Ref: item': 'สิ่งของ',
    'Ref: items': 'รายการ',
    'Ref: Clear': 'ชัดเจน',
    'Ref: Cart': 'รถเข็น',
    'Ref: Subtotal': 'ผลรวมย่อย',
    'Ref: Total': 'ทั้งหมด',
    'Ref: Tax': 'ภาษี',
    'Ref: Promotion': 'การส่งเสริม',
    'Ref: Total without promotion': 'รวมโดยไม่ต้องโปรโมต',
    'Ref: Number of product in cart': 'จำนวนผลิตภัณฑ์ในรถเข็น',
    'Ref: Delivery': 'จัดส่ง',
  },
  CartItemTile: { 'Ref: Vegan': 'มังสวิรัติ', 'Ref: Vegeterian': 'มังสวิรัติ' },
  OrderInfoCard: {
    'Ref: Header': 'สั่งซื้อ',
    'Ref: from': 'จาก',
    'Ref: location': 'ที่ตั้ง',
    'Ref: Phone': 'หมายเลขโทรศัพท์ของคุณ',
    'Ref: Instructions': 'คำแนะนำ',
    'Ref: Order type': 'ประเภทสั่งซื้อ',
    'Ref: Pickup': 'ไปรับ',
    'Ref: Eat-in': 'กิน',
    'Ref: Scan&Go': 'สแกนและไป',
  },
  ReorderWidget: {
    'Ref: No items': 'ยังไม่มีคำสั่งซื้อ ช็อปตอนนี้!',
    'Ref: Title': 'สั่งซื้ออีกครั้ง',
  },
  MenusWidget: { 'Ref: no menu': 'ไม่มีเมนูในขณะนี้', 'Ref: Modal title': 'เมนูสำหรับวัน' },
  PromotionInfo: { 'Ref: Discounts available': 'มีส่วนลด' },
  PromotionInfoItem: { 'Ref: Activate': 'เปิดใช้งาน' },
  OrderHistoryWidget: {
    'Ref: Your Orders': 'คำสั่งซื้อของคุณ',
    'Ref: Widget Title': 'คำสั่งซื้อของฉัน',
  },
  SuggestionsWidget: {
    'Ref: Modal title': 'ข้อเสนอแนะสำหรับวันนี้',
    'Ref: Any menu': 'เมนูใด ๆ',
    Other: 'อื่น',
    'Ref: Suggestions calendar button': 'ปฏิทินคำแนะนำ',
  },
  ModifierCard: {
    'Ref: Serving Size': 'ขนาดที่ให้บริการ',
    'Ref: Nutrition': 'ข้อมูลทางโภชนาการ',
    'Ref: Nutrition adults': 'ผู้ใหญ่ต้องการเวลาประมาณ 2,000 kcal ต่อวัน',
    'Ref: Nutrition Calories': 'แคลอรี่',
    'Ref: Nutrition Calcium': 'แคลเซียม',
    'Ref: Nutrition Fat': 'อ้วน',
    'Ref: Nutrition Saturated Fat': 'ไขมันอิ่มตัว',
    'Ref: Nutrition Carbohydrates': 'คาร์โบไฮเดรต',
    'Ref: Nutrition Sugar': 'น้ำตาล',
    'Ref: Nutrition Protein': 'โปรตีน',
    'Ref: Nutrition Sodium': 'โซเดียม',
    'Ref: Nutrition cholesterol': 'คอเลสเตอรอล',
    'Ref: Nutrition dietaryFiber': 'เส้นใยอาหาร',
    'Ref: Nutrition iron': 'เหล็ก',
    'Ref: Nutrition potassium': 'โพแทสเซียม',
    'Ref: Nutrition vitaminA': 'วิตามินเอ',
    'Ref: Nutrition vitaminC': 'วิตามินซี',
    'Ref: Ingredients': 'สูตรส่วนผสม',
    'Ref: Allergens': 'ภูมิแพ้',
    'Ref: Always there': 'ที่นั่นเสมอ',
    'Ref: Allergens selected': 'สารก่อภูมิแพ้ที่เลือกในตัวกรอง:',
    'Ref: minimum required': 'ต้องการขั้นต่ำ',
    'Ref: ingredients': 'ส่วนผสม',
    'Ref: Ingredient': 'วัตถุดิบ',
    'Ref: Free': 'ฟรี',
    'Ref: Based on customization': 'ขึ้นอยู่กับการปรับแต่งของคุณ',
    'Ref: Additives eggs': 'ไข่',
    'Ref: Additives fish': 'ปลา',
    'Ref: Additives milk': 'นม',
    'Ref: Additives nuts': 'ถั่ว',
    'Ref: Additives wheat': 'ข้าวสาลี',
    'Ref: Additives peanuts': 'ถั่ว',
    'Ref: Additives soy beans': 'ถั่วเหลือง',
    'Ref: Additives sesame seeds': 'เมล็ดงา',
    'Ref: Review': 'ทบทวน',
    'Ref: Per portion': 'ต่อส่วน',
    'Ref: Per 100g': 'ต่อ 100 กรัม',
    'Ref: Modifier item number of free items': '• {number_of_free_items} ฟรี',
    'Ref: Number of free items': 'จำนวนรายการฟรี: {number_of_free_items}',
    'Ref: Allergen selected': 'สารก่อภูมิแพ้ที่เลือกบนตัวกรองการค้นหา',
    'Ref: Nutritional info': 'ข้อมูลโภชนาการ',
    'Ref: Info': 'ข้อมูล',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'โปรโมชั่น' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'เลือกงานของคุณ',
    'Ref: Validate': 'ตรวจสอบความถูกต้อง',
    'Ref: Pricing Tiers dialog message': 'คุณได้รับการยอมรับจากที่อยู่อีเมลของคุณ',
    'Ref: Please select your job in the company': 'โปรดเลือกงานของคุณใน บริษัท',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'ไปรับ',
    'Ref: Eat-in': 'กิน',
    'Ref: Scan&Go': 'สแกนและไป',
    'Ref: Delivery': 'จัดส่ง',
    'Ref: Total': 'ทั้งหมด',
    'Ref: How was facility name service': 'บริการ {facility_name} เป็นอย่างไร?',
    'Ref: How do you rate the service': 'คุณให้คะแนนบริการอย่างไร?',
  },
};
export default labels;
