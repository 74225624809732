import {
  SearchResultContainer,
  SearchResultType,
} from '../hooks/useGlobalSearchResults/useGlobalSearchResults.types';

import { FacilityDataItem } from '@/modules/Facilities/types/types';
import { mapToListModel } from '@/modules/Order/components/ProductsList/productList.helper';
import { FacilityMenu } from '@/modules/Order/types/orderState.types';

export const searchMenus = (menus: FacilityMenu[], searchTerm: string): SearchResultContainer => {
  const results = menus.filter((menu) =>
    menu.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return { type: SearchResultType.Menus, results };
};

export const searchProducts = (
  menus: FacilityMenu[],
  searchTerm: string
): SearchResultContainer => {
  const filteredFacilityMenus = filterFacilityMenusByMenuItemName(menus, searchTerm);

  const result = filteredFacilityMenus.flatMap((filteredMenu) => ({
    items: mapToListModel({
      menu: filteredMenu,
      highlightAllergens: false,
      selectedAllergens: [],
      selectedMoment: undefined,
    }),
    menu: filteredMenu,
  }));

  const searchResults = result.flatMap(({ items, menu }) => items.map((item) => ({ item, menu })));

  return { type: SearchResultType.Products, results: searchResults };
};

export const searchFacilities = (facilities: FacilityDataItem[], searchTerm: string) => {
  const results = facilities.filter((facility) =>
    facility.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return { type: SearchResultType.Facilities, results };
};

const filterFacilityMenusByMenuItemName = (
  menus: FacilityMenu[],
  searchTerm: string
): FacilityMenu[] => {
  let facilityMenusWithMatchingItems: FacilityMenu[] = [];

  menus.forEach((facilityMenu) => {
    const filteredMenuItems = facilityMenu.menuItems.filter((menuItem) =>
      menuItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredMenuItems.length > 0) {
      facilityMenusWithMatchingItems.push({
        ...facilityMenu,
        menuItems: filteredMenuItems,
      });
    }
  });

  return facilityMenusWithMatchingItems;
};
