const labels = {
  All: {
    'Ref: stamp progress': 'Progress towards the next stamp',
    Reward: 'Reward',
    Rewards: 'Rewards',
  },
  LoyaltyStamps: {
    'Ref: Page title': 'Stamps',
    'Ref: Fulfill conditions': 'Earn stamps to redeem rewards!',
    'See Reward': 'See Reward',
    'Ref: No reward': 'No reward for the moment',
  },
  RedeemableProducts: { 'Ref: Page title': 'Loyalty Rewards' },
};
export default labels;
