import { serviceTypes, modulesConfig } from '../config';

import { PATH_REVIEW_TYPES } from './types/reviewForm.types';

export const cacheVersion = '0.1';
export const moduleId = 'Review';
export const modulePath = serviceTypes[modulesConfig.Review.serviceType].path;

export const pagePaths = {
  Module: modulePath,
  ReviewForm: `${modulePath}/:reviewType/:facilityId/:menuId?/:foodItemId?`,
  ReviewForFacility: `${modulePath}/${PATH_REVIEW_TYPES.FACILITY}/:facilityId`,
  ReviewForOutlet: `${modulePath}/${PATH_REVIEW_TYPES.OUTLET}/:outletId`,
  SubmitSuccess: `${modulePath}/success`,
  FacilityReview: `${modulePath}/facilities`,
};

export const defaultValues = {
  minQuestionRange: 0,
  maxQuestionRange: 10,
  initialQuestionRange: 5,
  textAreaMaxLength: 2000,
};
