import { cleanup, renderHook } from '@testing-library/react';

import { defaultSuggestion } from '../../../__mocks/mock';
import { defaultValues } from '../../../config';
import { useCheckNoSuggestionsToDisplay } from '../useCheckNoSuggestionsToDisplay';

describe('useCheckNoSuggestionsToDisplay hook', () => {
  afterAll(() => cleanup());

  const suggestioms = [
    { ...defaultSuggestion },
    { ...defaultSuggestion },
    { ...defaultSuggestion },
    { ...defaultSuggestion },
    { ...defaultSuggestion },
    { ...defaultSuggestion },
    { ...defaultSuggestion },
  ];

  const suggestioms2 = [
    { ...defaultSuggestion },
    { ...defaultSuggestion },
    { ...defaultSuggestion },
    { ...defaultSuggestion },
  ];

  describe('if more suggestions than max limit', () => {
    it('number of suggestions displayed is equal to the limit', () => {
      const render = renderHook(() => useCheckNoSuggestionsToDisplay(suggestioms));
      const { suggestionsLimitMobile, suggestionsLimitDesktop } = render.result.current;
      expect(suggestionsLimitDesktop).toBe(defaultValues.maxSuggestionsDesktop);
      expect(suggestionsLimitMobile).toBe(defaultValues.maxSuggestionsMobile);
    });
  });

  describe('if less suggestions than max limit', () => {
    it('number of suggestions displayed is less than the limit', () => {
      const render = renderHook(() => useCheckNoSuggestionsToDisplay(suggestioms2));
      const { suggestionsLimitMobile, suggestionsLimitDesktop } = render.result.current;
      expect(suggestionsLimitDesktop).toBe(suggestioms2.length);
      expect(suggestionsLimitMobile).toBe(defaultValues.maxSuggestionsMobile);
    });
  });
});
