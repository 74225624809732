import { useMemo } from 'react';

import { CheckIcon } from '../../../../assets/icons';
import { getProductRedirectUrl } from '../../helpers/promotions.helper';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import { PromotionInfoItemProps } from '../../types/promotions.types';

import styles from './PromotionInfoItem.module.css';
const PromotionInfoItem = ({ id, description, name, isActive, items }: PromotionInfoItemProps) => {
  const productRedirectUrl = useMemo(() => getProductRedirectUrl(items), [items]);
  const { label } = useOrderTranslation(__filename);

  const redirectLink =
    items?.length === 1 && items[0].menuId && productRedirectUrl ? (
      <span className={styles.promotionItemAction}>
        <a href={productRedirectUrl}>{label('Ref: See item')}</a>
      </span>
    ) : null;

  const promotionItemAction =
    isActive || redirectLink ? (
      <div className={styles.promotionItemAction}>
        {isActive ? (
          <span className={styles.promotionItemActivated}>
            <CheckIcon /> {label('Ref: Activated')}
          </span>
        ) : (
          redirectLink
        )}
      </div>
    ) : null;

  return (
    <div key={`promotionInfoItem_${id.toString()}`} className={styles.promotionItemContainer}>
      <div className={promotionItemAction !== null ? styles.promotionItemInfoContainer : ''}>
        <div className={styles.promotionItemTitle}>{name}</div>
        <div className={promotionItemAction !== null ? styles.promotionItemDescription : ''}>
          {description}
        </div>
      </div>
      {promotionItemAction}
    </div>
  );
};

export default PromotionInfoItem;
