const mockResponse = {
  getLoyaltySchemes: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 1,
          name: 'Chicken Nuggets Pyramid Game',
          description: 'Collect 10 stamps for buying Chicken Nuggets and get your reward',
          productsProvidingStamp: [
            {
              uomId: 2102,
              foodItemId: 1982,
            },
          ],
          productsRedeemable: [
            {
              uomId: 2102,
              foodItemId: 1982,
            },
          ],
          imageUrl:
            'https://sodexoitzstageau.blob.core.windows.net/fooditem/2411-20200512012805-Chicken_Nuggets.jpg',
          stampsRequiredForRedeem: 1,
          menuIdsProvidingProductsRedeemable: [160],
          menuIdsProvidingProductsProvidingStamp: [160],
        },
        {
          id: 2,
          name: 'Burger Collector',
          description:
            'Collect stamp for each of our meaty beauty burger and get your favourite for free.',
          productsProvidingStamp: [
            {
              uomId: 2100,
              foodItemId: 1982,
            },
          ],
          productsRedeemable: [
            {
              uomId: 2100,
              foodItemId: 1982,
            },
          ],
          imageUrl:
            'https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/Hamburger_%28black_bg%29.jpg/1280px-Hamburger_%28black_bg%29.jpg',
          stampsRequiredForRedeem: 10,
          menuIdsProvidingProductsRedeemable: [160],
          menuIdsProvidingProductsProvidingStamp: [160],
        },
        {
          id: 3,
          name: 'Tortilla wrap',
          description:
            'Choose to eat like a champion with our super protein dishes, and get your reward.',
          productsProvidingStamp: [
            {
              uomId: 9871,
              foodItemId: 1982,
            },
          ],
          productsRedeemable: [
            {
              uomId: 9871,
              foodItemId: 1982,
            },
            {
              uomId: 2877,
              foodItemId: 1982,
            },
          ],
          imageUrl: '',
          stampsRequiredForRedeem: 5,
          menuIdsProvidingProductsRedeemable: [160, 140],
          menuIdsProvidingProductsProvidingStamp: [160],
        },
      ]),
  },
  getUserLoyaltySchemesProgress: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          loyaltySchemeId: 1,
          stampsCollected: 0,
        },
        {
          loyaltySchemeId: 2,
          stampsCollected: 10,
        },
        {
          loyaltySchemeId: 3,
          stampsCollected: 13,
        },
      ]),
  },
};

export default mockResponse;
