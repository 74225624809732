import momentjs from 'moment';

import { DATE_FORMAT } from '../../../constants';
import { suggestionWidgetDateCacheKey } from '../config';

export const getSuggestionWidgetMenuDate = (siteId: string) => {
  const dateFromCache = localStorage.getItem(`${suggestionWidgetDateCacheKey}_${siteId}`);
  const isCachedDateFromPast = momentjs(dateFromCache).isBefore(momentjs().format(DATE_FORMAT));

  return dateFromCache && !isCachedDateFromPast ? dateFromCache : momentjs().format(DATE_FORMAT);
};
