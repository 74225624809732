import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from '../../axios/axiosBaseQuery';
import { globalRetailApiPath } from '../../config';

const biteApi = createApi({
  /**
   * `baseQuery` defines the method that will handle queries, it works in combination
   * with the `query` option in the endpoints definition
   * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#customizing-queries-with-basequery
   */
  reducerPath: 'biteApi',
  baseQuery: axiosBaseQuery && axiosBaseQuery({ baseUrl: globalRetailApiPath }),
  endpoints: () => ({}),
});

export default biteApi;
