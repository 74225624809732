const labels = {
  All: { 'Ref: Page title': 'Ref: Page title' },
  SuppliersList: {
    'Ref: Supplier details': 'Ref: Supplier details',
    'Missing supplier list': 'Missing supplier list',
    'Try again later': 'Try again later',
  },
  SupplierDetails: {
    'Address label': 'Address label',
    'Website label': 'Website label',
    'Details label': 'Details label',
  },
};
export default labels;
