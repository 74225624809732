const labels = {
  All: {
    'Ref: Assembled': 'Ensamblado',
    'Ref: Submitted': 'Presentada',
    'Ref: Fulfilled': 'Satisfecho',
    'Ref: Completed': 'Terminado',
    'Ref: Paid': 'Pagado',
    'Ref: Approved': 'Aprobado',
    'Ref: Ready For Pickup': 'Listo para recojer',
    'Ref: Delivered': 'Entregado',
    'Ref: Received': 'Recibió',
    'Ref: Rejected': 'Rechazado',
    'Ref: Payment Error': 'Error en el pago',
    'Ref: Pending Payment': 'Pago pendiente',
    'Ref: Preparing': 'Preparando',
    'Ref: Refunded': 'Reintegrado',
    'Ref: Cancelled By User': 'Cancelado por usuario',
    'Ref: Refund Requested': 'reembolso requerido',
    'Ref: Refund Failed': 'El reembolso falló',
    'Ref: Payment Processing': 'Procesando pago',
    'Ref: Not Paid': 'No pagado',
    'Ref: Total:': 'Total:',
    'Ref: Pickup': 'Levantar',
    'Ref: Eat-in': 'Comedor',
    'Ref: Scan&Go': 'Escanear y ir',
    'Ref: Fulfillment Type': 'Tipo de cumplimiento',
    'Ref: Delivery': 'Entrega',
    'Ref: You have no future orders': 'No tienes pedidos futuros',
    'Ref: You have no past orders': 'No tienes órdenes pasadas',
    'Ref: You have no orders': 'No tienes pedidos',
    'Ref: All orders': 'Todas las órdenes',
    'Ref: Not past orders': 'Órdenes próximas',
    'Ref: Past orders': 'Órdenes pasadas',
    'Ref: Vegan': 'Vegano',
    'Ref: Vegeterian': 'Vegetariano',
    'Ref: See item': 'Ver artículo',
    'Ref: Activated': 'Activado',
    'Ref: No suggestions': 'No se encontraron sugerencias',
    'Ref: Get your receipt': 'Obtenga su recibo',
    'Ref: Print': 'Imprima su recibo',
    'Ref: Email': 'Reciba su recibo por correo electrónico',
    'Ref: Receipt Modal message': '¿Cómo le gustaría obtener su recibo?',
    'Ref: Confirm': 'Confirmar',
    'Ref: Your email address': 'Su dirección de correo electrónico',
    Suggestion: 'Sugerencia',
    'Ref: Loyalty Reward': 'Recompensa de lealtad',
    'Ref: Equation for stamps per item': '1 compra = 1 sello',
    'Ref: Paid by employer': 'La cantidad pagada por su empleador',
    'Ref: Paid by user': 'La cantidad pagada por usted',
  },
  ProductsList: {
    'Ref: Page title': 'Lista de productos',
    'Ref: Facility details': 'Detalles de la instalación',
    'Ref: Search products list': 'Artículos de búsqueda',
    'Ref: filter label: menus': 'Menú',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoría',
    'Ref: all': 'Todo',
    'Ref: today': 'Hoy',
    'Ref: tomorrow': 'Mañana',
    'Ref: see more': 'Ver más',
    'Ref: see less': 'Ver menos',
    'Ref: reset': 'Reiniciar',
    'Ref: View in cart': 'Ver en el carrito',
    'Ref: You have changed location': 'Has cambiado la ubicación',
    'Ref: You are now at': 'Ahora estas en',
    'Ref: Menu card': 'Tarjeta de menú',
    'Ref: Table': 'Número de tabla',
    'Service unavailable': 'Servicio no disponible',
    'Ref: Ordering unavailable':
      '¡Cuidadoso! Pedir en este sitio no está disponible en este momento, intente más tarde.',
    'Ref: Filters': 'Filtros (alérgenos y más)',
  },
  ProductScanner: {
    'Ref: cart': 'Carro',
    'Ref: Product Scanned':
      'fue agregado al carrito. Puede continuar escaneando los códigos de barras para agregarlos en su carrito.',
    'Ref: Scan or adjust': 'fue agregado al carro. Ajuste la cantidad o escanee otro producto.',
    'Ref: Product Not Matched': 'El código de barras no fue reconocido. Inténtalo de nuevo',
    'Ref: Scan article code': 'Escanear código de barras',
    'Ref: Native error product cannot be added to cart':
      'El elemento de diferentes menú o parte del día no se puede agregar al carrito',
    'Ref: product scanner placeholder': 'Escanee el código de barras para agregarlo a su carrito',
  },
  Order: { 'Ref: Page title': 'Pedido' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menús',
    'Ref: today': 'Hoy',
    'Ref: tomorrow': 'Mañana',
    'Ref: Search products list': 'Artículos de búsqueda',
    'Ref: You have changed location': 'Has cambiado la ubicación',
    'Ref: You are now at': 'Ahora estas en',
  },
  ProductDetails: {
    'Ref: Page title': 'Detalles de producto',
    'Ref: Serving Size': 'Tamaño de la porción',
    'Ref: Nutrition': 'Información nutricional',
    'Ref: Nutrition adults': 'Los adultos necesitan alrededor de 2000 KCAL al día.',
    'Ref: Nutrition Calories': 'Calorías',
    'Ref: Nutrition Calcium': 'Calcio',
    'Ref: Nutrition Fat': 'Grasa',
    'Ref: Nutrition Saturated Fat': 'Grasa saturada',
    'Ref: Nutrition Carbohydrates': 'Carbohidratos',
    'Ref: Nutrition Sugar': 'Azúcar',
    'Ref: Nutrition Protein': 'Proteína',
    'Ref: Nutrition Sodium': 'Sodio',
    'Ref: Nutrition cholesterol': 'Colesterol',
    'Ref: Nutrition dietaryFiber': 'Fibra dietética',
    'Ref: Nutrition iron': 'Hierro',
    'Ref: Nutrition potassium': 'Potasio',
    'Ref: Nutrition vitaminA': 'Vitamina A',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Additives': 'Aditivos',
    'Ref: Emission': 'emisiones de CO2',
    'Ref: This product generates': 'Este producto genera',
    'Ref: of CO2': 'de co2',
    'Ref: emissions, which equals a': 'Emisiones, que igualan a un',
    'Ref: car trip': 'viaje en coche',
    'Ref: Ingredients': 'Receta ingrediente',
    'Ref: Allergens': 'Alérgenos',
    'Ref: Always there': 'Siempre allí',
    'Ref: Allergens selected': 'Alérgenos seleccionados en el filtro:',
    'Ref: minimum required': 'mínimo requerido',
    'Ref: you have to add at least': 'Tienes que agregar al menos',
    'Ref: ingredients': 'ingredientes',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Basado en su personalización',
    'Ref: Additives eggs': 'huevos',
    'Ref: Additives fish': 'pescado',
    'Ref: Additives milk': 'Leche',
    'Ref: Additives nuts': 'nueces',
    'Ref: Additives wheat': 'trigo',
    'Ref: Additives peanuts': 'miseria',
    'Ref: Additives soy beans': 'frijoles de soya',
    'Ref: Additives sesame seeds': 'semillas de sésamo',
    'Ref: Review': 'Revisar',
    'Ref: Per portion': 'Por porción',
    'Ref: Per 100g': 'Por 100 g',
    'Ref: Allergen selected': 'Alergeno seleccionado en el filtro de búsqueda',
    'Ref: Fitbit Log': 'Registro a Fitbit',
    'Ref: age verification required on delivery': 'Verificación de edad requerida en el parto',
    'Ref: alcohol content notification':
      'Al colocar y ordenar uno de estos artículos, declara que tiene 18 años o más. El abuso de alcohol es peligroso para su salud.',
    'Ref: default': 'Defecto',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ganado si solicita este producto',
    'Ref: Loyalty Card Description':
      'El {stamp_form} se agregará a la siguiente lealtad {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'El programa de sellos de lealtad le permite recolectar sellos para recompensas.',
    'Ref: Loyalty Modal Description':
      'Cuando compre <b> {product_name}, se agregará 1 sello </b> al <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'y',
    'Ref: Stamp': 'Estampilla',
    'Ref: Stamps': 'Sellos',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programas',
    'Ref: Loyalty Stamps': 'Sellos de lealtad',
    'Ref: See Loyalty Stamps': 'Ver sellos de lealtad',
    Favorite: 'Favorito',
    'Ref: In favorites': 'El producto ya está en la lista de favoritos. Haga clic para eliminarlo.',
    'Ref: Not in favorites':
      'El producto aún no está en la lista de favoritos. Haga clic para agregarlo.',
    'Ref: Added to favorites': 'El producto se ha agregado a la lista de favoritos.',
    'Ref: Removed from favorites': 'El producto ha sido eliminado de la lista de favoritos.',
    'Ref: From': 'De',
  },
  MenuCartActions: {
    'Ref: change in cart': 'Cambio en el carrito',
    'Ref: Popup header for customizable item qty update': 'Este artículo es personalizable',
    'Ref: add new version': 'Agregar nueva versión',
    'Ref: Popup body for customizable item qty having to be changed from the cart':
      'Actualice la cantidad de un artículo personalizable directamente desde su carrito.',
    'Ref: Popup body for customizable item qty having to be changed from the cart or new version created':
      'Actualice la cantidad de un elemento personalizable directamente desde su carrito o agregue una nueva versión.',
    'Ref: another context body':
      'Para agregar elementos de este sitio, instalación, momento/menú o fecha, debe borrar el carrito',
    'Ref: cancel': 'Cancelar',
    'Ref: add to cart': 'Añadir a la cesta',
    'Ref: view in cart': 'Carro',
    'Ref: Products in cart': 'Número de {product_name} en CART: {product_quantity}',
    'Ref: Customize': 'Personalizar',
    'Ref: Add': 'Agregar',
    'Ref: Free': 'Gratis',
  },
  CartAnotherContextPopup: {
    'Ref: cancel': 'Cancelar',
    'Ref: notice': 'darse cuenta',
    'Ref: clear cart': 'Vaciar carrito',
    'Ref: Cart addition error message':
      'Actualmente tiene elementos en CART de {from_current}. Para agregar elementos de {from_selected}, debe borrar el carrito',
  },
  Cart: {
    'Ref: Page title': 'Carro',
    'Ref: The cart is empty': 'El carro esta vacío',
    'Ref: Clear cart': 'Vaciar carrito',
    'Ref: Continue shopping': 'Seguir comprando',
    'Ref: Start shopping': 'Empieza a comprar',
    'Ref: Today': 'Hoy dia',
    'Ref: Tomorrow': 'Mañana',
    'Ref: Tax:': 'Impuesto:',
    'Ref: Promotion:': 'Promoción:',
    'Ref: Total without promotion:': 'Total sin promoción:',
    'Ref: Delivery:': 'Entrega:',
    'Ref: Pickup informations': 'Información de recogida',
    'Ref: Location': 'Localización',
    'Ref: Time': 'Tiempo',
    'Ref: Phone': 'Su número de teléfono',
    'Ref: Instructions': 'Instrucciones',
    'Ref: Please select': 'Seleccione',
    'Ref: Enter special requests': 'Ingrese solicitudes especiales aquí',
    'Ref: notice': 'darse cuenta',
    'Ref: Product is no more available':
      'Lo siento, pero parece que algunos productos ya no están disponibles. Por favor revise su carrito.',
    'Ref: ok': 'De acuerdo',
    'Ref: Validate': 'Validar mi pedido',
    'Ref: Pay now': 'Pagar ahora',
    'Ref: Charge to my department': 'Cobrar a mi departamento',
    'Ref: Pay on pickup': 'Pagar estos artículos en recoger',
    'Ref: Accept terms of sale prefix': 'acepto',
    'Ref: Accept terms of sale': 'Estos términos de ventas',
    'Ref: Continue': 'Continuar',
    'Ref: Please login or sign up to place an order':
      'Inicie sesión o regístrese para realizar un pedido',
    'Ref: Accept terms of sale suffix': ', y los he leído y entendido.',
    'Ref: Payment modality': 'Método de pago',
    'Ref: Other information': 'Otra información',
    'Ref: Eat-in': 'Comedor',
    'Ref: Pickup': 'Levantar',
    'Ref: Table': 'Número de tabla',
    'Ref: Required fields': 'Campos requeridos',
    'Service unavailable': 'Servicio no disponible',
    'Ref: Cannot order':
      'Lo siento, no puede hacer un pedido en este sitio en este momento. Comuníquese con el restaurante para obtener más información sobre el horario de apertura y los servicios disponibles.',
    'Ref: Order Unavailable':
      '¡El pedido de la fecha elegida, el tipo de cumplimiento y la ubicación ya no están disponibles!',
    'Ref: No Availability': 'No más disponibilidad',
    'Ref: Continue on your device': 'Continúa en tu dispositivo',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ganado si completa su pedido',
    'Ref: Loyalty Card Description':
      'El {stamp_form} se agregará a la siguiente lealtad {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'El programa de sellos de lealtad le permite recolectar sellos para recompensas.',
    'Ref: Loyalty Modal List Description':
      '<b> {no_stamps} {stamp_form} </b> ganado si completa su pedido:',
    'Ref: Loyalty Modal List Item':
      '<b> {no_stamps} {stamp_form} </b> agregado para <b> {scheme_names} </b>',
    'Ref: Stamp': 'Estampilla',
    'Ref: Stamps': 'Sellos',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programas',
    'Ref: Loyalty Stamps': 'Sellos de lealtad',
    'Ref: See Loyalty Stamps': 'Ver sellos de lealtad',
    'Ref: Loyalty Info': 'Información de lealtad',
    'Ref: Email address': 'Dirección de correo electrónico',
    'Ref: and': 'y',
    'Ref: Order minimum amount': 'Lo sentimos, el monto del pago mínimo es {minimumAmount}',
  },
  ContinueJourneyModal: {
    'Ref: Continue on your device': 'Continúa en tu dispositivo',
    'Ref: Scan QR to continue':
      'Por favor, escanee el código QR para continuar el viaje en su dispositivo.',
  },
  OrderHistory: { 'Ref: Orders': 'Pedidos', 'Ref: Page Title': 'Mis ordenes' },
  OrderHistoryDetails: {
    'Ref: Unit price': 'Precio unitario',
    'Ref: Download': 'Descargar el recibo',
    'Ref: Download error':
      'Se ha producido un error de descarga. Intenta descargar el recibo más tarde',
    'Ref: Order details title': 'Detalles del pedido',
  },
  SuccessOrder: {
    'Ref: Order confirmed': '¡Gracias! Su pedido ahora está confirmado',
    'Ref: Text for pickup':
      'Puede seguir su estado a través de su historial de pedidos. Si sus notificaciones están activadas, lo actualizaremos cuando esté listo para la recolección.',
    'Ref: Success Order Title': 'Gracias por su orden',
    'Ref: Success Order Description': 'Pague en el pago para validar su pedido.',
    'Ref: Success Order ID': 'Pedido de identificación',
    'Ref: Success Order Action': 'Nuevo orden',
    'Ref: Text for dinein':
      'Su pedido debe ser servido en su mesa pronto. Simplemente pregunte a cualquiera de nuestro amable miembro del personal sobre su pedido de comida.',
    'Ref: Text for scan&go': 'Su pedido está disponible en su historial de pedidos',
    'Ref: Text for delivery':
      'Puede rastrear su estado en su historial de pedidos. Si sus notificaciones están habilitadas, le informaremos cuando su pedido esté listo.',
    'See details': 'Ver detalles',
    'Go to home': 'Ir a casa',
  },
  FailOrder: {
    'Go to home': 'Ir a casa',
    'Ref: Kiosk Fail Title': '¡Ups! ¡Algo salió mal!',
    'Ref: Kiosk Fail Description':
      'Desafortunadamente, su pedido no fue exitoso. Vuelva a intentarlo o solicite a un miembro del personal que lo ayude.',
    'Ref: Kiosk Fail Back': 'Volver a la página anterior',
    'Ref: Kiosk Fail Action': 'Borrar la sesión y ordenar de nuevo',
    'Ref: Error in cart validation':
      'Se produjo un error durante su pedido. Póngase en contacto con la instalación para resolver el problema.',
    'Facility contact': 'Contacto de la instalación',
    'Ref: Ordering failed': 'Pedido fallido',
    'Ref: Order confirmed': 'Orden confirmada',
  },
  TableCheckIn: {
    'Welcome at': 'Bienvenido a',
    Table: 'Mesa:',
    'Ref: list title': 'Pedidos recientes para hoy',
    'See the menu': 'Ver el menú',
    'Table Check-in': 'Check-in de la tabla',
    'Ref #': 'Ref #',
    Checked: 'Comprobado',
    'Check in': 'Registrarse',
    'See details': 'Ver detalles',
    'Ref: You have changed location': 'Has cambiado la ubicación',
    'Ref: You are now at': 'Ahora estas en',
    'Order number': 'Número de orden',
  },
  PromotionNotification: {
    'Ref: Promotion applied title': 'Promocion aplicada',
    'Ref: Promotion applied description': 'Usted beneficia a una promoción de su carrito.',
    'Ref: Promotion deleted title': 'Promoción eliminada',
    'Ref: Promotion deleted description':
      'Ya no beneficia la promoción porque ya no llena las condiciones o la promoción ha expirado.',
  },
  MenuTile: { 'Ref: Scan&Go': 'Escanear y ir', 'Ref: View only': 'Sólo vista' },
  ProductTile: {
    'Ref: Allergens': 'Alérgenos',
    'Ref: Presence of allergen': 'Presencia de alérgeno',
    'Ref: Scan': 'Escanear',
    'Ref: From': 'De',
    'Ref: Already in product favorite list. Click to remove it.':
      'Ya en la lista favorita del producto. Haga clic para eliminarlo.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Actualmente no se agregó a la lista favorita del producto. Haga clic para agregarlo.',
    'Ref: Free': 'Gratis',
  },
  OrderSummary: {
    'Ref: item': 'artículo',
    'Ref: items': 'elementos',
    'Ref: Clear': 'Claro',
    'Ref: Cart': 'Carro',
    'Ref: Subtotal': 'Total parcial',
    'Ref: Total': 'Total',
    'Ref: Tax': 'Impuesto',
    'Ref: Promotion': 'Promoción',
    'Ref: Total without promotion': 'Total sin promoción',
    'Ref: Number of product in cart': 'Número de productos en el carrito',
    'Ref: Delivery': 'Entrega',
  },
  CartItemTile: { 'Ref: Vegan': 'Vegano', 'Ref: Vegeterian': 'Vegetariano' },
  OrderInfoCard: {
    'Ref: Header': 'Ordenado para',
    'Ref: from': 'de',
    'Ref: location': 'Ubicación',
    'Ref: Phone': 'Su número de teléfono',
    'Ref: Instructions': 'Instrucciones',
    'Ref: Order type': 'Tipo de orden',
    'Ref: Pickup': 'Levantar',
    'Ref: Eat-in': 'Comedor',
    'Ref: Scan&Go': 'Escanear y ir',
  },
  ReorderWidget: {
    'Ref: No items': 'No hay pedidos hechos todavía. ¡Compra ahora!',
    'Ref: Title': 'Ordenar de nuevo',
  },
  MenusWidget: {
    'Ref: no menu': 'No hay menú por el momento',
    'Ref: Modal title': 'Menú para el día',
  },
  PromotionInfo: { 'Ref: Discounts available': 'Descuentos disponibles' },
  PromotionInfoItem: { 'Ref: Activate': 'Activar' },
  OrderHistoryWidget: { 'Ref: Your Orders': 'Tus ordenes', 'Ref: Widget Title': 'Mis ordenes' },
  SuggestionsWidget: {
    'Ref: Modal title': 'Sugerencia para el día',
    'Ref: Any menu': 'Cualquier menú',
    Other: 'Otro',
    'Ref: Suggestions calendar button': 'Calendario de sugerencias',
  },
  ModifierCard: {
    'Ref: Serving Size': 'Tamaño de la porción',
    'Ref: Nutrition': 'Información nutricional',
    'Ref: Nutrition adults': 'Los adultos necesitan alrededor de 2000 KCAL al día.',
    'Ref: Nutrition Calories': 'Calorías',
    'Ref: Nutrition Calcium': 'Calcio',
    'Ref: Nutrition Fat': 'Grasa',
    'Ref: Nutrition Saturated Fat': 'Grasa saturada',
    'Ref: Nutrition Carbohydrates': 'Carbohidratos',
    'Ref: Nutrition Sugar': 'Azúcar',
    'Ref: Nutrition Protein': 'Proteína',
    'Ref: Nutrition Sodium': 'Sodio',
    'Ref: Nutrition cholesterol': 'Colesterol',
    'Ref: Nutrition dietaryFiber': 'Fibra dietética',
    'Ref: Nutrition iron': 'Hierro',
    'Ref: Nutrition potassium': 'Potasio',
    'Ref: Nutrition vitaminA': 'Vitamina A',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Ingredients': 'Receta ingrediente',
    'Ref: Allergens': 'Alérgenos',
    'Ref: Always there': 'Siempre allí',
    'Ref: Allergens selected': 'Alérgenos seleccionados en el filtro:',
    'Ref: minimum required': 'mínimo requerido',
    'Ref: ingredients': 'ingredientes',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Basado en su personalización',
    'Ref: Additives eggs': 'huevos',
    'Ref: Additives fish': 'pescado',
    'Ref: Additives milk': 'Leche',
    'Ref: Additives nuts': 'nueces',
    'Ref: Additives wheat': 'trigo',
    'Ref: Additives peanuts': 'miseria',
    'Ref: Additives soy beans': 'frijoles de soya',
    'Ref: Additives sesame seeds': 'semillas de sésamo',
    'Ref: Review': 'Revisar',
    'Ref: Per portion': 'Por porción',
    'Ref: Per 100g': 'Por 100 g',
    'Ref: Modifier item number of free items': '• {number_of_free_items} gratis',
    'Ref: Number of free items': 'Número de artículos gratuitos: {number_of_free_items}',
    'Ref: Allergen selected': 'Alergeno seleccionado en el filtro de búsqueda',
    'Ref: Nutritional info': 'Información nutricional',
    'Ref: Info': 'Información',
  },
  PromotionsMiniWidget: { 'Ref: Title': 'Promociones' },
  DiscountTiersModal: {
    'Ref: Choose your job': 'Elige tu trabajo',
    'Ref: Validate': 'Validar',
    'Ref: Pricing Tiers dialog message':
      'Usted ha sido reconocido con su dirección de correo electrónico',
    'Ref: Please select your job in the company': 'Seleccione su trabajo en la empresa',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Levantar',
    'Ref: Eat-in': 'Comedor',
    'Ref: Scan&Go': 'Escanear y ir',
    'Ref: Delivery': 'Entrega',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': '¿Cómo fue el servicio {facility_name} ´?',
    'Ref: How do you rate the service': '¿Cómo se califica el servicio?',
  },
};
export default labels;
