import { cleanup, screen, waitFor } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { FacilityDataItem } from '../../../Facilities/types/types';
import { DeliveryOptionType } from '../../types/cart.types';
import { HistoricalOrderType } from '../../types/orderHistory.types';
import { OrderHistory } from '../../types/orderState.types';

import OrderHistoryTile from './OrderHistoryTile';

jest.mock('../../../../services/ModulesManager', () => ({
  __esModule: true,
  default: () => ({ getApi: () => () => ({ format: (val: any) => val }) }),
}));

describe('OrderHistoryTile component', () => {
  afterAll(() => cleanup());

  const currentLanguageCode = 'en-US';

  const order: OrderHistory = {
    delivery: 0,
    tax: 0,
    facilityId: 'facility1',
    orderId: 123,
    pickupDate: '2022-12-29T12:30:00',
    status: 'Submitted',
    totalAmount: 9.99,
    orderType: HistoricalOrderType.CurrentAndFuture,
    deliveryOptionType: DeliveryOptionType.PickupOption,
    isPaid: false,
    subsidy: undefined,
  };

  const facilities: FacilityDataItem[] = [
    {
      title: 'My facility',
      name: 'facility name',
      id: 'facility1',
      sequence: 1,
      images: [],
      facilityType: { id: 'test', name: 'Food - Retail' },
      viewCounts: 1,
      externalUrl: null,
    },
  ];

  describe('on render', () => {
    renderComponent(OrderHistoryTile, {
      order,
      facilities,
      currentLanguageCode,
    });

    it('should have basic props', async () => {
      expect(screen.getByTestId('order-history-tile-123')).toBeTruthy();

      await waitFor(() => {
        expect(
          screen.getByText(
            `#${order.orderId.toString()} • Pickup • ${facilities[0].title.toString()}`
          )
        ).toBeTruthy();
        expect(screen.getByText(`${order.status.toString()}`)).toBeTruthy();
        expect(screen.getByText(`Total: ${order.totalAmount.toString()}`)).toBeTruthy();
      });
    });
  });
});
