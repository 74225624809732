import { WarningIcon } from '../../../../assets/icons';
import {
  BowlIllustration,
  BreakfastIllustration,
  BurgerIllustration,
  BurritoIllustration,
  CaramelIllustration,
  ChickenIllustration,
  ChocolateIllustration,
  CoffeeIllustration,
  CokeIllustration,
  DishIllustration,
  DrinkIllustration,
  EggIllustration,
  FriesIllustration,
  FruitsIllustration,
  KebabIllustration,
  NoodleIllustration,
  OtherIllustration,
  PastaIllustration,
  PizzaIllustration,
  QuesadillaIllustration,
  SaladIllustration,
  SandwishIllustration,
  SnacksIllustration,
  SoupIllustration,
  TeaIllustration,
  TurkeyIllustration,
  VegetableIllustration,
  WrapIllustration,
} from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import { SectionType } from '../../../../components/organisms/TilesList/TilesList.types';
import { selectedProductListFiltersCacheKey } from '../../config';
import { getCartDiscountTotal, getCartOriginalTotal } from '../../helpers/cart.helper';
import {
  AllergenType,
  Cart,
  FacilityMenu,
  MenuItem,
  OrderDraft,
  ProductPortion,
  SuggestedMenuItem,
} from '../../types/orderState.types';
import {
  HightlightAllergenFilterValues,
  ProductCategory,
  ProductItem,
  ProductListConfig,
  ProductListFiltering,
  ProductListItem,
} from '../../types/productList.types';
import PriceWithDiscount from '../PriceWithDiscount';

import { TestingPropsV2 } from 'src/types';

export const mapSuggestionItemToProductItem = (
  item: SuggestedMenuItem,
  highlightAllergens?: boolean,
  selectedAllergens?: string[]
): ProductItem | null => {
  const defaultPortion = getDefaultPortion(item.productPortions);
  if (!defaultPortion) return null;

  const allergens = getPortionAllergens(defaultPortion);

  const showAllergensWarning =
    !!allergens.length &&
    checkIfContainsAllergens(allergens, selectedAllergens ?? []) &&
    highlightAllergens;

  return {
    id: `${item.menuId}_${item.menuItemId}`,
    menuItemId: item.menuItemId,
    uomId: defaultPortion.uomId,
    title: item.name,
    description: item.description,
    imageUrl: item.listImage ?? '',
    price: item.price,
    isVegan: defaultPortion.isVegan,
    isVegetarian: defaultPortion.isVegetarian,
    isMindful: defaultPortion.isMindful,
    showAllergensWarning: showAllergensWarning ?? false,
    genericCategory: item?.genericCategory ?? '',
  };
};

const mapProductItemToListItem = (
  menuItem: MenuItem,
  menu: FacilityMenu,
  dayPart: string | null,
  section: string | null,
  highlightAllergens?: boolean,
  selectedAllergens?: string[],
  subSection?: string | null
): ProductListItem | null => {
  const defaultPortion = getDefaultPortion(menuItem.productPortions);
  if (!defaultPortion) return null;
  if (menu.isOrderable && !menuItem.isOrderable) return null;

  const allergens = getPortionAllergens(defaultPortion);

  const showAllergensWarning =
    !!allergens.length &&
    checkIfContainsAllergens(allergens, selectedAllergens ?? []) &&
    highlightAllergens;
  return {
    id: `${menu.id}_${menuItem.menuItemId}`,
    menuItemId: menuItem.menuItemId,
    uomId: defaultPortion.uomId,
    title: menuItem.name,
    description: menuItem.description,
    imageUrl: menuItem.listImage,
    price: menuItem.price,
    viewablePrices: menuItem.viewablePrices,
    calories: +defaultPortion.nutritions.calories,
    isVegan: defaultPortion.isVegan,
    isVegetarian: defaultPortion.isVegetarian,
    isMindful: defaultPortion.isMindful,
    isFavorite: defaultPortion.isFavorite,
    category: section ?? '',
    subCategory: subSection ?? undefined,
    moment: dayPart ?? '',
    showAllergensWarning: showAllergensWarning ?? false,
    allergens: allergens,
    genericCategory: menuItem?.genericCategory ?? '',
  };
};

export const getFilteredSuggestions = (
  suggestions: SuggestedMenuItem[],
  selectedAllergens: AllergenType[],
  hideAllergens: boolean
) => {
  let filteredSuggestions: SuggestedMenuItem[] = [];
  suggestions.forEach((element) => {
    const defaultPortion = getDefaultPortion(element.productPortions);
    if (!defaultPortion) return null;

    const allergens = getPortionAllergens(defaultPortion);

    const isAlergenInItem =
      !!allergens.length && checkIfContainsAllergens(allergens, selectedAllergens ?? []);

    if (isAlergenInItem && hideAllergens) {
      return;
    }
    filteredSuggestions.push(element);
  });

  return filteredSuggestions;
};

export const checkIfContainsAllergens = (allergens: string[], selectedAllergens: string[]) => {
  return allergens?.some((a) => selectedAllergens.includes(a));
};

export const mapToListModel = ({
  menu,
  highlightAllergens,
  selectedAllergens,
  selectedMoment,
}: {
  menu: FacilityMenu;
  highlightAllergens: boolean;
  selectedAllergens: string[];
  selectedMoment: string | undefined;
}): ProductListItem[] => {
  const menuListResult: ProductListItem[] = [];

  for (const menuItem of menu?.menuItems) {
    if (selectedMoment && menuItem?.dayPart?.toLowerCase() !== selectedMoment.toLowerCase()) {
      continue;
    }

    const productItem = mapProductItemToListItem(
      menuItem,
      menu,
      menuItem.dayPart,
      menuItem.section,
      highlightAllergens,
      selectedAllergens,
      menuItem.subSection
    );
    if (productItem) menuListResult.push(productItem);
  }

  return menuListResult;
};

export const buildSections = (menuItems: ProductListItem[]) => {
  return menuItems.reduce<SectionType<ProductListItem & TestingPropsV2>[]>(
    (sectionResult, menuItem) => {
      if (sectionResult.find((x) => x.title === menuItem.category)) return sectionResult;
      return [
        ...sectionResult,
        {
          title: menuItem.category,
          key: menuItem.category as string,
          filter: (items) => items.filter((item) => item.category === menuItem.category),
          'data-testid': `section-${menuItem.category}`,
          sections: buildSubSections(
            menuItems.filter((item) => item.category === menuItem.category && item.subCategory),
            menuItem.category
          ),
        },
      ];
    },
    []
  );
};

const getDefaultPortion = (productPortions: ProductPortion[]) => {
  return productPortions.find((x) => x.isDefault === true);
};

export const getPortionAllergens = (productPortion: ProductPortion) => {
  return productPortion.allergens
    .filter((allergen) => allergen.contains)
    .map((allergen) => allergen.id);
};

const buildSubSections = (sectionItems: ProductListItem[], section: string) => {
  return sectionItems.reduce<SectionType<ProductListItem & TestingPropsV2>[]>(
    (subsectionResult, menuItem) => {
      if (subsectionResult.find((x) => x.title === menuItem.subCategory)) return subsectionResult;
      return [
        ...subsectionResult,
        {
          title: menuItem.subCategory as string,
          key: menuItem.subCategory as string,
          'data-testid': `section-${menuItem.subCategory}`,
          filter: (items) =>
            items.filter(
              (item) => item.category === section && item.subCategory === menuItem.subCategory
            ),
        },
      ];
    },
    []
  );
};

export const buildConfig = (numOfSections: number): ProductListConfig => {
  const config: ProductListConfig = {
    displayedItemsNum: 0,
  };
  if (numOfSections) {
    config.collapsibleSections = false;
    config.singleExpandedSection = true;
    config.disableInfiniteScroll = true;
  }

  return config;
};

const getCacheKey = (siteId: string, menu: string, date: string) =>
  `${selectedProductListFiltersCacheKey}_${siteId}_${menu}_${date}`;

export const cacheSelectedFilters = (
  filters: ProductListFiltering,
  siteId: string,
  menu: string,
  date: string
) => localStorage.setItem(getCacheKey(siteId, menu, date), JSON.stringify(filters));

export const getDefaultFiltersFromCache = (siteId: string, menu: string, date: string) => {
  const defaultFiltersCache = localStorage.getItem(getCacheKey(siteId, menu, date));
  const defaultFilters = defaultFiltersCache
    ? (JSON.parse(defaultFiltersCache) as ProductListFiltering)
    : null;

  return defaultFilters;
};

export const buildFilteringState = ({
  moments,
  selectedAllergens,
  hideAllergens,
  defaultFilters,
}: {
  moments: string[];
  selectedAllergens: AllergenType[];
  hideAllergens: boolean;
  defaultFilters: ProductListFiltering | null;
}) => {
  const momentFilter = {
    filter_moment: moments?.length ? { [moments[0]]: true } : {},
  };

  const allergenHighlightFilter = {
    filter_allergens_highlight: {
      [hideAllergens
        ? HightlightAllergenFilterValues.Hide
        : HightlightAllergenFilterValues.Hightlight]: true,
    },
  };

  const allergenFilterList = selectedAllergens.reduce(
    (prev, cur) => ({ ...prev, [cur]: true }),
    {}
  );
  const filterAllergens = {
    filter_allergens: allergenFilterList,
  };

  return defaultFilters
    ? defaultFilters
    : { ...momentFilter, ...allergenHighlightFilter, ...filterAllergens };
};

export const getCartInfo = (cart?: Cart, orderDraft?: OrderDraft) => {
  if (cart?.menuPortionItems && orderDraft && orderDraft?.total >= 0) {
    return {
      price: orderDraft?.total,
      cartItems: cart.menuPortionItems.reduce((prev, curr) => prev + curr.quantity, 0),
      siteId: cart.siteId,
      priceWithoutPromotionDiscounts:
        orderDraft?.promotionDiscount &&
        orderDraft?.total &&
        orderDraft?.total + orderDraft?.promotionDiscount,
    };
  }
};

export const getViewCartAction = ({
  label,
  languageCode,
  cartInfo,
  redirectToCart,
  siteId,
  productScanner,
  isOrderableMenu,
}: {
  label: Function;
  languageCode: string;
  cartInfo?: {
    price: number;
    cartItems: number;
    siteId: string;
    priceWithoutPromotionDiscounts: number | undefined;
  };
  redirectToCart: Function;
  siteId: string;
  productScanner?: JSX.Element;
  isOrderableMenu: boolean;
}) => {
  const showCartActionBtn = cartInfo?.cartItems && cartInfo.siteId === siteId && isOrderableMenu;
  const showScanActionBtn = !!productScanner && isOrderableMenu;

  if (!showCartActionBtn && !showScanActionBtn) return;

  const cartTotalWithDiscount = getCartDiscountTotal(cartInfo);
  const cartTotalWithoutDiscount = getCartOriginalTotal(cartInfo);

  return (
    <>
      {showCartActionBtn ? (
        <Button
          onClick={() => redirectToCart()}
          affix={cartInfo?.cartItems.toString()}
          data-testid="product-list-view-in-cart"
          suffix={() => (
            <PriceWithDiscount
              discountPrice={cartTotalWithDiscount}
              originalPrice={cartTotalWithoutDiscount}
              languageCode={languageCode}
            />
          )}
        >
          {label('Ref: View in cart')}
        </Button>
      ) : null}
      {showScanActionBtn ? productScanner : null}
    </>
  );
};

export const siteChangeNotification = ({
  hasSiteChanged,
  setSiteModalClosed,
  siteName,
  labelFn,
}: {
  hasSiteChanged: boolean;
  setSiteModalClosed: Function;
  siteName: string;
  labelFn: Function;
}) => {
  return hasSiteChanged ? (
    <Notification
      look={NOTIFICATION_LOOK.SUCCESS}
      dismissable={true}
      title={labelFn('Ref: You have changed location')}
      onDismiss={() => setSiteModalClosed()}
      data-testid="product-list-site-change-notification"
    >
      {labelFn('Ref: You are now at')} {siteName}
    </Notification>
  ) : null;
};

export const cutOffDateNotification = ({
  isOrderingPossible,
  isOrderingPossibleLoading,
  labelFn,
}: {
  isOrderingPossible: boolean;
  isOrderingPossibleLoading: boolean;
  labelFn: Function;
}) => {
  return !isOrderingPossible && !isOrderingPossibleLoading ? (
    <Notification
      look={NOTIFICATION_LOOK.INFO}
      icon={<WarningIcon />}
      title={labelFn('Service unavailable')}
      data-testid="product-list-cut-off-date-notification"
    >
      {labelFn('Ref: Ordering unavailable')}
    </Notification>
  ) : null;
};

export const getProductIllustration = (category: string): JSX.Element => {
  switch (category as ProductCategory) {
    case 'Coffee':
      return <CoffeeIllustration />;
    case 'Burger':
      return <BurgerIllustration />;
    case 'Pizza':
      return <PizzaIllustration />;
    case 'Kebab':
      return <KebabIllustration />;
    case 'Pasta':
      return <PastaIllustration />;
    case 'Sandwich':
      return <SandwishIllustration />;
    case 'Noodle':
      return <NoodleIllustration />;
    case 'Soup':
      return <SoupIllustration />;
    case 'Drink':
      return <DrinkIllustration />;
    case 'Vegetable':
      return <VegetableIllustration />;
    case 'Fruit':
      return <FruitsIllustration />;
    case 'Salad':
      return <SaladIllustration />;
    case 'Snacks':
      return <SnacksIllustration />;
    case 'Chicken':
      return <ChickenIllustration />;
    case 'Breakfast':
      return <BreakfastIllustration />;
    case 'Fries':
      return <FriesIllustration />;
    case 'Wrap':
      return <WrapIllustration />;
    case 'Bowl':
      return <BowlIllustration />;
    case 'Chocolate':
      return <ChocolateIllustration />;
    case 'Quesadilla':
      return <QuesadillaIllustration />;
    case 'Caramel':
      return <CaramelIllustration />;
    case 'Eggs':
      return <EggIllustration />;
    case 'Turkey':
      return <TurkeyIllustration />;
    case 'Burrito':
      return <BurritoIllustration />;
    case 'Tea':
      return <TeaIllustration />;
    case 'Dish':
      return <DishIllustration />;
    case 'Coke':
      return <CokeIllustration />;
    default:
      return <OtherIllustration />;
  }
};
