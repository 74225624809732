import baseApi from '../../../../services/api/baseApi';
import mockResponse from '../../mocks/accountApi';

import {
  GetIdentityContextArgs,
  GetIdentityContextRes,
  IdentityContext,
} from '@/modules/Core/api/account/accountApi.types';

const accountApi = baseApi.enhanceEndpoints({ addTagTypes: ['account'] }).injectEndpoints({
  endpoints: (build) => ({
    getIdentityContext: build.query<IdentityContext, GetIdentityContextArgs>({
      query: (args) => ({
        url: `/v2/identity/context`,
        method: 'get',
        params: { ...args },
        mockData: mockResponse.getIdentityContext,
      }),
      transformResponse: (response: GetIdentityContextRes) => {
        return {
          client: response.client,
          type: response.type,
          provider: response.provider,
          tokenUrl: response.tokenUrlV2,
          useSystemBrowser: response.useSystemBrowser,
        };
      },
      providesTags: ['account'],
    }),
    deleteAccount: build.mutation<{ httpStatusCode: number | undefined }, {}>({
      query: () => ({
        url: `/v2/user/deleteuser`,
        method: 'delete',
      }),
      transformResponse: (response) => {
        return { httpStatusCode: response.status };
      },
      transformErrorResponse: (response) => {
        return { httpStatusCode: response.status };
      },
      invalidatesTags: ['account'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useDeleteAccountMutation,
  useLazyGetIdentityContextQuery,
  useGetIdentityContextQuery,
} = accountApi;
export default accountApi;
