import { modulesConfig, serviceTypes } from '../../config';

export const cacheVersion = '0';
export const moduleId = 'Faq';
export const modulePath = serviceTypes[modulesConfig.Faq.serviceType].path;

export const pagePaths = {
  Module: modulePath,
  FaqCategory: modulePath + '/:faqCategoryId',
};
