import { getNumberOfFreeItems, getNumberOfPotentiallyUsedStamps } from './loyaltyStamps.helper';

const menuId = 160;
const loyaltySchemes = [
  {
    id: 1,
    name: 'Scheme 1',
    description: 'Description 1',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2102,
        foodItemId: 1982,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 2,
    name: 'Scheme 2',
    description: 'Description 2',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2103,
        foodItemId: 1983,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2103,
        foodItemId: 1983,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 3,
    name: 'Scheme 3',
    description: 'Description 3',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
  {
    id: 4,
    name: 'Scheme 4',
    description: 'Description 4',
    productsProvidingStamp: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    productsRedeemable: [
      {
        menuId: 160,
        uomId: 2104,
        foodItemId: 1984,
      },
    ],
    imageUrl: '',
    stampsRequiredForRedeem: 10,
    menuIdsProvidingProductsRedeemable: [160],
    menuIdsProvidingProductsProvidingStamp: [160],
  },
];

const userLoyaltySchemesProgress = [
  {
    loyaltySchemeId: 1,
    stampsCollected: 23,
  },
  {
    loyaltySchemeId: 2,
    stampsCollected: 7,
  },
  {
    loyaltySchemeId: 3,
    stampsCollected: 11,
  },
  {
    loyaltySchemeId: 4,
    stampsCollected: 11,
  },
];

const cart = {
  date: new Date(),
  facilityId: '0d465323-4f67-ed11-97b0-0003ff4ccd9a',
  submissionTrackingId: '5d19613e-1bc7-4442-b361-6cb7742af193',
  menuId: 160,
  siteId: '5a8da332-1237-e811-a95b-000d3a2bc5c1',
  menuPortionItems: [
    {
      id: '53d264ca-02d6-480b-a348-e8d4a5ce8b90',
      img: 'https://rgukretailrangerpre9277.blob.core.windows.net/fooditem/28509-20230706122601-1579502896-Pineapple_copy.png',
      menuItemId: 16814831,
      name: 'Abacaxi pineapple',
      price: 2.4,
      quantity: 1,
      uomId: 2102,
      foodItemId: 1982,
      description: '',
      isVegan: true,
      isVegetarian: true,
      genericCategory: 'Fruit',
    },
  ],
  moment: 'Fruits and Vegetables',
  pickupLocations: [
    {
      pickupSpotId: 2195,
      order: 0,
      name: 'bite timeset',
    },
  ],
  pickupInformation: {
    pickupSpotId: null,
    pickupSpotName: null,
    pickupTimeSlotId: null,
    pickupTime: null,
  },
};

describe('getNumberOfUsedStamps', () => {
  it('should return 10 used stamps', () => {
    const loyaltyScheme = loyaltySchemes!.at(0);
    const progress = userLoyaltySchemesProgress!.at(0)!;
    const result =
      loyaltyScheme &&
      getNumberOfPotentiallyUsedStamps(cart.menuPortionItems, loyaltyScheme, progress, menuId);
    expect(result).toEqual(10);
  });
});

describe('getNumberOfUsedStamps', () => {
  it('it should return 1 for uomId 2102 and foodItemId 1982, 1 was redeemed', () => {
    const result = getNumberOfFreeItems(
      1982,
      2102,
      menuId,
      cart,
      loyaltySchemes,
      userLoyaltySchemesProgress
    );
    expect(result).toEqual(1);
  });
});

describe('getNumberOfUsedStamps', () => {
  it('it should return 0 for uomId 2103 and foodItemId 1983, nothing to redeem', () => {
    const result = getNumberOfFreeItems(
      1983,
      2103,
      menuId,
      cart,
      loyaltySchemes,
      userLoyaltySchemesProgress
    );
    expect(result).toEqual(0);
  });
});

describe('getNumberOfUsedStamps', () => {
  it('it should return 2 from 2 schemes, for uomId 2104 and foodItemId 1984, nothing to redeem', () => {
    const result = getNumberOfFreeItems(
      1984,
      2104,
      menuId,
      cart,
      loyaltySchemes,
      userLoyaltySchemesProgress
    );
    expect(result).toEqual(2);
  });
});

describe('getNumberOfUsedStamps', () => {
  it('it should return 0 for item from menu not included in scheme', () => {
    const result = getNumberOfFreeItems(
      1982,
      2102,
      menuId,
      cart,
      loyaltySchemes,
      userLoyaltySchemesProgress
    );
    expect(result).toEqual(1);
  });
});
