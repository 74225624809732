const labels = {
  All: { 'Ref: Page title': 'Leverantör' },
  SuppliersList: {
    'Ref: Supplier details': 'Se detaljer',
    'Missing supplier list': 'Vi hittade ingen leverantör.',
    'Try again later': 'Försök igen senare',
  },
  SupplierDetails: {
    'Address label': 'Adress:',
    'Website label': 'Hemsida:',
    'Details label': 'Information',
  },
};
export default labels;
