const labels = {
  All: { 'Ref: stamp progress': '次のスタンプに向かって進みます', Reward: '褒美', Rewards: '報酬' },
  LoyaltyStamps: {
    'Ref: Page title': 'スタンプ',
    'Ref: Fulfill conditions': '報酬を引き換えるためにスタンプを獲得してください！',
    'See Reward': '報酬を参照してください',
    'Ref: No reward': '今のところ報酬はありません',
  },
  RedeemableProducts: { 'Ref: Page title': '忠誠心の報酬' },
};
export default labels;
